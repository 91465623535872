import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { CommentableType, RuumAction, RuumActionTypes } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppStoreWrapper } from '../../../../app/common/appStoreWrapper.service';
import { RuumAlertService } from '../../../../app/common/components/alert/alert.service';
import { ServiceHelper } from '../../../../app/common/serviceHelper';
import { deepEqual } from '../../../../app/common/utils.service';
import { AuthService } from '../../../auth/auth.service';
import { OrderedListParams, PaginatedList } from '../../../common/connectors/paginatedList.model';
import { ReadModelBackendConnector } from '../../../common/connectors/readModelConnector.service';
import { DocumentScrollService } from '../../documentScroll.service';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ChatsListFilters, ChatsListItem, ChatsListOrderBy } from './chat-list.model';
import { CHATS_LIST_ACTIONS } from './chats-list.reducer';

export const MAXIMUM_WAIT = 2 * 60 * 1000;
export const DEFAULT_BACK_OFF = 2000;

class ProjectChatService extends PaginatedListLoader<ChatsListItem, ChatsListFilters, ChatsListOrderBy> {
    readonly text$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly projectId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.getListObservable().subscribe((page) => {
            this.serviceHelper.dispatchWithoutPersisting(CHATS_LIST_ACTIONS.PROJECT_CHAT_LOADED, {
                projectChat: page,
            });
        });
    }
    getListObservable(): Observable<PaginatedList<ChatsListItem>> {
        return super.getListObservable();
    }
    getData(
        page: number,
        filters: ChatsListFilters,
        orderBy: OrderedListParams<ChatsListOrderBy>,
    ): Observable<PaginatedList<ChatsListItem>> {
        return this.readModelConnector.getChats(
            { page: 1, pageSize: 1 },
            {
                ...filters,
                objectType: ['project'],
            },
            orderBy,
        );
    }

    getFilters$(): Observable<ChatsListFilters> {
        return combineLatest([this.text$, this.projectId$]).pipe(
            map<any, ChatsListFilters>(([text, projectId]) => {
                return {
                    projectId,
                    commentText: text,
                };
            }),
            distinctUntilChanged((a, b) => deepEqual(a, b)),
        );
    }

    filterByProjectId(projectId: string) {
        this.projectId$.next(projectId);
    }
    filterByText(text: string) {
        this.text$.next(text);
    }
    resetFilters() {
        this.text$.next(undefined);
        this.projectId$.next(undefined);
    }
    getStoreData$() {
        return this.appStoreWrapper.chats().pipe(map((chats) => chats.list));
    }
}

class CanvasChatService extends PaginatedListLoader<ChatsListItem, ChatsListFilters, ChatsListOrderBy> {
    readonly text$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly projectId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);

        this.getListObservable().subscribe((canvasChats) => {
            this.serviceHelper.dispatchWithoutPersisting(CHATS_LIST_ACTIONS.CHATS_LIST_LOADED, {
                list: canvasChats,
            });
        });
    }

    getListObservable(): Observable<PaginatedList<ChatsListItem>> {
        return super.getListObservable();
    }
    getData(
        page: number,
        filters: ChatsListFilters,
        orderBy: OrderedListParams<ChatsListOrderBy>,
    ): Observable<PaginatedList<ChatsListItem>> {
        const allExceptProject: CommentableType[] = [
            'attachment',
            'customStatus',
            'email',
            'poll',
            'processBar',
            'section',
            'task',
            'approval',
        ];
        return this.readModelConnector.getChats(
            { page, pageSize: 25 },
            {
                ...filters,
                objectType: allExceptProject,
            },
            orderBy,
        );
    }

    getFilters$(): Observable<ChatsListFilters> {
        return combineLatest([this.text$, this.projectId$]).pipe(
            map<any, ChatsListFilters>(([text, projectId]) => {
                return {
                    projectId,
                    commentText: text,
                };
            }),
            distinctUntilChanged((a, b) => deepEqual(a, b)),
        );
    }

    getStoreData$() {
        return this.appStoreWrapper.chats().pipe(map((chats) => chats.list));
    }
}

@Injectable({ providedIn: 'root' })
export class ChatListService {
    private projectId: string;
    private projectChatService: ProjectChatService;
    private canvasChatService: CanvasChatService;

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        private appStoreWrapper: AppStoreWrapper,
        private actions: ActionsSubject,
        private alertService: RuumAlertService,
        private authService: AuthService,
        private documentScrollService: DocumentScrollService,
    ) {
        this.projectChatService = new ProjectChatService(
            appStoreWrapper,
            readModelConnector,
            serviceHelper,
            alertService,
            authService,
        );
        this.canvasChatService = new CanvasChatService(
            appStoreWrapper,
            readModelConnector,
            serviceHelper,
            alertService,
            authService,
        );

        this.actions.subscribe((action: RuumAction) => {
            if (action.type === RuumActionTypes.ADD_COMMENT) {
                const { onObjectId, onObjectType } = action.payload;
                const projectId = action.entityId;
                this.replaceChat(onObjectId, onObjectType, projectId);
            } else if (action.type === RuumActionTypes.SHOW_COMMENTS_OF_OBJECT) {
                const projectId = action.entityId;
                this.loadUnseenCommentsCounter(projectId);
            }
        });
    }

    private replaceChat(objectId: string, objectType: CommentableType, projectId: string) {
        this.readModelConnector
            .getChats(
                { page: 1 },
                {
                    projectId,
                    objectId,
                    objectType: [objectType],
                },
                { by: 'createdAt', direction: 'desc' },
            )
            .toPromise()
            .then((list) => {
                if (list.rows.length > 0) {
                    const chat = list.rows[0];

                    if (objectType === 'project') {
                        this.serviceHelper.dispatchWithoutPersisting(CHATS_LIST_ACTIONS.PROJECT_CHAT_LOADED, {
                            projectChat: list.rows,
                        });
                    } else {
                        this.serviceHelper.dispatchWithoutPersisting(CHATS_LIST_ACTIONS.REPLACE_CHAT, { chat });
                    }
                }
            });
    }

    resetFilters() {
        this.projectChatService.text$.next(undefined);
        this.canvasChatService.text$.next(undefined);
    }

    reload() {
        this.projectChatService.reload();
        this.canvasChatService.reload();
    }

    loadChats(projectId: string) {
        this.projectId = projectId;

        this.projectChatService.projectId$.next(projectId);
        this.canvasChatService.projectId$.next(projectId);

        this.projectChatService.loadList();
        this.canvasChatService.loadList();

        this.loadUnseenCommentsCounter(projectId);
    }

    stopLoading() {
        this.projectChatService.stopLoadingList();
        this.canvasChatService.stopLoadingList();
    }

    loadUnseenCommentsCounter(projectId: string) {
        this.readModelConnector
            .getComments(
                { page: 1, pageSize: 1 },
                {
                    unseen: true,
                    projectId,
                },
                { by: 'createdAt' },
            )
            .toPromise()
            .then((page) => {
                this.serviceHelper.dispatchWithoutPersisting(CHATS_LIST_ACTIONS.UNSEEN_COMMENTS_COUNTER_LOADED, {
                    unseenComments: page.totalItems,
                });
            });
    }

    resetUnseenCommentsCounter() {
        this.serviceHelper.dispatchWithoutPersisting(CHATS_LIST_ACTIONS.UNSEEN_COMMENTS_COUNTER_LOADED, {
            unseenComments: 0,
        });
    }

    filterByText(text: string) {
        this.projectChatService.text$.next(text);
        this.canvasChatService.text$.next(text);
    }

    projectChat(): Observable<ChatsListItem[]> {
        return this.appStoreWrapper.chats().pipe(map((chats) => chats.projectChat.rows));
    }

    rows(): Observable<ChatsListItem[]> {
        return this.appStoreWrapper.chats().pipe(map((chats) => chats.list.rows));
    }

    unseenComments(): Observable<number> {
        return this.appStoreWrapper.chats().pipe(map((chats) => chats.unseenComments));
    }

    getShowLoading() {
        return this.canvasChatService.showLoading$;
    }

    maybeGoToNextPage() {
        this.canvasChatService.maybeGoToNextPage();
    }
}
