import { animate, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { fadeInRightSmall, fadeOutRightSmall, timingInMedium, timingOutMedium } from '../../animation';
import { NavbarItem } from '../navbar.model';
import { SecondaryNavbarService, UnseenChangesCounters } from '../secondary-navbar/secondary-navbar.service';

@Component({
    selector: 'ruum-secondary-navbar-top',
    template: `
        <nav
            role="navigation"
            aria-label="Secondary"
            class="navbar-secondary navbar navbar-light navbar-expand bg-white d-flex justify-content-center flex-fill"
        >
            <div class="d-flex flex-fill px-2 px-sm-4 px-md-5 px-lg-8">
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mr-auto">
                        <li
                            navbar-tab-top
                            *ngFor="let tab of tabs"
                            [id]="tab.id"
                            [name]="tab.name"
                            [icon]="tab.icon"
                            [tooltip]="tab.name"
                            [ngbTooltip]="tabTooltipContent"
                            [disableTooltip]="!tab.tooltipText"
                            [active]="tab.id === activeTab.id"
                            (click)="clickTab.emit(tab)"
                            (keydown.space)="clickTab.emit(tab)"
                            (keydown.enter)="clickTab.emit(tab)"
                        >
                            <ng-template #tabTooltipContent>
                                <div class="text-small font-weight-bold">{{ tab.tooltipText }}</div>
                            </ng-template>
                        </li>
                    </ul>

                    <ul
                        class="navbar-nav ml-auto ruum-navbar-buttons"
                        [@navbarButtonsAnimation]
                        data-help-id="team-invite-button"
                    >
                        <ng-template ngFor let-button [ngForOf]="buttons">
                            <li>
                                <!-- team button -->
                                <ng-container *ngIf="button.id === 'team'">
                                    <ng-content select="[data-content='ruum-team-invite-button']"></ng-content>
                                </ng-container>
                                <!-- other buttons -->

                                <div
                                    class="d-flex"
                                    [ngbTooltip]="buttonTooltipContent"
                                    [disableTooltip]="!button.tooltipText"
                                >
                                    <button
                                        *ngIf="button.id !== 'team'"
                                        class="btn btn-icon border-0 ml-2"
                                        [class.btn-link-secondary]="activeButton?.id !== button.id"
                                        [class.btn-primary-light]="activeButton?.id === button.id"
                                        type="button"
                                        [title]="button.name"
                                        [attr.aria-label]="'Open Sidepanel: ' + button.name"
                                        [disabled]="button.disabled"
                                        (click)="clickButton.emit(button)"
                                    >
                                        <i class="icon {{ button.icon }}"></i>
                                        <unseen-item-counter
                                            [counter]="
                                                unseenChangesCounters$ | async | unseenChangesCounterOf: button.id
                                            "
                                        ></unseen-item-counter>
                                        <span *ngIf="button.hasTitle" class="ml-2">{{ button.name }}</span>
                                    </button>
                                </div>
                                <ng-template #buttonTooltipContent>
                                    <div class="text-small font-weight-bold">{{ button.tooltipText }}</div>
                                </ng-template>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </nav>
    `,
    animations: [
        trigger('navbarButtonsAnimation', [
            transition(':enter', animate(timingInMedium, fadeInRightSmall)),
            transition(':leave', animate(timingOutMedium, fadeOutRightSmall)),
        ]),
    ],
})
export class SecondaryNavbarTopComponent implements OnInit {
    @Input() tabs: NavbarItem[] = [];

    @Input() activeTab: NavbarItem;

    @Input() buttons: NavbarItem[] = [];

    @Input() activeButton: NavbarItem = null;

    @Output() clickTab = new EventEmitter<NavbarItem>();

    @Output() clickButton = new EventEmitter();

    unseenChangesCounters$: Observable<UnseenChangesCounters>;

    constructor(private secondaryNavbarService: SecondaryNavbarService) {}

    ngOnInit() {
        this.unseenChangesCounters$ = this.secondaryNavbarService.getUnseenChangesCounters();
    }
}
