import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Day, TaskStatus, User } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InvolvedUsersService } from '../../../../app/common/connectors/users/users.service';
import { FunctionalRoleListItem } from '../../connectors/functionalRoles/functionalRoles.model';
import { FunctionalRolesService } from '../../connectors/functionalRoles/functionalRoles.service';
import { UserListItem } from '../../connectors/readModelConnector.service';
import { IsOverduePipe } from '../../pipe/isOverdue.pipe';

@Component({
    selector: 'ruum-task-details-subtask-item',
    template: `
        <div
            class="d-flex flex-fill minw-0 hov hov-bg-extra-light border-gray-16 cursor-pointer pr-2 hov"
            [class.border-bottom]="!last"
            (click)="onShowSubtaskDetails(id)"
            (keydown.space)="onShowSubtaskDetails(id)"
            (keydown.enter)="onShowSubtaskDetails(id)"
        >
            <div class="d-flex align-items-start">
                <ng-content></ng-content>
                <ruum-task-status-menu
                    *ngIf="!isMilestone"
                    [statusId]="status"
                    (statusChanged)="onChangeStatus($event)"
                ></ruum-task-status-menu>
                <ruum-milestone-status-menu
                    *ngIf="isMilestone"
                    [statusId]="status"
                    (statusChanged)="onChangeStatus($event)"
                ></ruum-milestone-status-menu>
            </div>
            <div class="d-flex flex-column flex-fill minw-0 pl-1">
                <div class="d-flex flex-fill minw-0">
                    <div class="d-flex flex-fill minw-0">
                        <div class="py-1 ruum-task-name-small text-truncate">{{ name }}</div>
                    </div>
                </div>
                <div class="d-flex flex-fill minw-0">
                    <div class="d-flex flex-column flex-fill minw-0">
                        <div class="d-flex flex-wrap">
                            <div class="d-flex mr-2">
                                <ruum-task-start-date
                                    [startDate]="startDate"
                                    [size]="'xs'"
                                    [componentClass]="'px-0'"
                                ></ruum-task-start-date>
                                <ruum-task-dates-divider
                                    [startDate]="startDate"
                                    [dueDate]="dueDate"
                                    [size]="'xs'"
                                    [componentClass]="'px-0 minw-0 no-hover'"
                                ></ruum-task-dates-divider>
                                <ruum-task-due-date
                                    [dueDate]="dueDate"
                                    [isOverDueDate]="isOverDueDate(status, dueDate)"
                                    [size]="'xs'"
                                    [componentClass]="'px-0'"
                                ></ruum-task-due-date>
                            </div>

                            <div class="d-flex mr-2">
                                <ruum-task-assignees
                                    [members]="members$ | async"
                                    [roles]="roles$ | async"
                                ></ruum-task-assignees>
                            </div>

                            <div class="d-flex mr-2">
                                <ruum-task-priority
                                    [priorityId]="priority"
                                    [theme]="priorityTheme"
                                ></ruum-task-priority>
                            </div>

                            <div class="d-flex mr-2">
                                <show-comments-button
                                    [theme]="'gray-24'"
                                    [commentableId]="id"
                                    [commentableType]="'task'"
                                    [hideWhenEmpty]="false"
                                ></show-comments-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDetailsSubtaskItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill flex-column minw-0';

    @Input() id: string;

    @Input() name: string;

    @Input() status: TaskStatus;

    @Input() assignees: string[];

    @Input() isMilestone = false;

    @Input() startDate;

    @Input() dueDate;

    @Input() last: boolean;

    @Input()
    set memberIds(value: string[]) {
        this.membersSubject$.next(value || []);
    }

    get memberIds(): string[] {
        return this.membersSubject$.getValue();
    }

    @Input()
    set roleIds(value: string[]) {
        this.rolesSubject$.next(value || []);
    }

    get roleIds(): string[] {
        return this.rolesSubject$.getValue();
    }

    @Input() priority;

    @Input() children;

    @Input() comments = [];

    @Input() projectParticipants: User[];

    @Output() changeStatus = new EventEmitter<TaskStatus>();

    @Output() showSubtaskDetails = new EventEmitter();

    members$: Observable<UserListItem[]>;

    roles$: Observable<FunctionalRoleListItem[]>;

    private membersSubject$ = new BehaviorSubject<string[]>([]);

    private rolesSubject$ = new BehaviorSubject<string[]>([]);

    constructor(
        private usersService: InvolvedUsersService,
        private functionalRolesService: FunctionalRolesService,
        private dateIsOverdue: IsOverduePipe,
    ) {}

    ngOnInit() {
        this.members$ = this.membersSubject$
            .asObservable()
            .pipe(switchMap((memberIds) => this.usersService.dataList(memberIds)));

        this.roles$ = this.rolesSubject$
            .asObservable()
            .pipe(switchMap((roleIds) => this.functionalRolesService.dataList(roleIds)));
    }

    get priorityTheme() {
        return this.priority ? '' : 'gray-24';
    }

    onChangeStatus(status: TaskStatus) {
        this.changeStatus.emit(status);
    }

    onShowSubtaskDetails(id: string) {
        this.showSubtaskDetails.emit(id);
    }

    getTaskAssignees(assignees: string[]): Observable<UserListItem[]> {
        return this.usersService.dataList(assignees);
    }

    isOverDueDate(status, dueDate) {
        return status !== 'DONE' && this.dateIsOverdue.transform(dueDate);
    }

    formatDate(day: Day): string {
        return day && new Date(day.year, day.month - 1, day.day, 0, 0, 0, 0).format('DD MMM');
    }
}
