import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { SystemConnectorSelectComponent } from './system-connector-select.component';

@NgModule({
    declarations: [SystemConnectorSelectComponent],
    imports: [CommonModule, SelectModule],
    exports: [SystemConnectorSelectComponent],
})
export class SystemConnectorSelectModule {}
