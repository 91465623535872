import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AppState } from '../../common/app.store';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { OrderedListParams, PaginatedList } from '../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../common/connectors/paginatedListLoader';
import { ServiceHelper } from '../../common/serviceHelper';
import {
    SingleWebhookLoadedAction,
    WebhookCreatedAction,
    WebhookDeletedAction,
    WebhooksLoadedAction,
    WebhookUpdatedAction,
    WEBHOOK_LIST_ACTION_TYPES,
} from './webhook-list.reducer';
import { WebhookConfigurationFilters, WebhooksConnector } from './webhooks.connector';
import { WebhookConfiguration, WebhookConfigurationListItem } from './webhooks.model';

export type WebhookConfigurationOrderBy = '';

@Injectable({ providedIn: 'root' })
export class WebhookListService extends PaginatedListLoader<
    WebhookConfigurationListItem,
    WebhookConfigurationFilters,
    WebhookConfigurationOrderBy
> {
    private enterpriseId$: Observable<string>;

    constructor(
        protected authService: AuthService,
        protected alertService: RuumAlertService,
        private webhooksConnector: WebhooksConnector,
        private serviceHelper: ServiceHelper,
        private store: Store<AppState>,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {
        super(alertService, authService);

        this.enterpriseId$ = this.selectedEnterpriseService.selectedEnterpriseId();

        this.setUpObservables();
    }

    async create(data: WebhookConfiguration): Promise<WebhookConfigurationListItem> {
        const webhook = await this.webhooksConnector.createWebhook(data).toPromise();
        this.serviceHelper.dispatchWithoutPersisting<WebhookCreatedAction>(WEBHOOK_LIST_ACTION_TYPES.WEBHOOK_CREATED, {
            webhook,
        });

        return data;
    }

    async update(data: WebhookConfiguration): Promise<WebhookConfigurationListItem> {
        const payload = { ...data };
        if (!data.password) {
            delete payload.password;
        }
        await this.webhooksConnector.updateWebhook(payload).toPromise();
        data.password = null; // Remove from store
        this.serviceHelper.dispatchWithoutPersisting<WebhookUpdatedAction>(WEBHOOK_LIST_ACTION_TYPES.WEBHOOK_UPDATED, {
            webhook: <WebhookConfigurationListItem>data,
        });

        return data;
    }

    async delete(data: WebhookConfiguration): Promise<void> {
        await this.webhooksConnector.delete(data);
        this.serviceHelper.dispatchWithoutPersisting<WebhookDeletedAction>(WEBHOOK_LIST_ACTION_TYPES.WEBHOOK_DELETED, {
            webhookId: data.id,
        });
    }

    async getByKeys(
        enterpriseId: string,
        webhookId: string,
        forceLoad: boolean = false,
    ): Promise<WebhookConfigurationListItem> {
        try {
            // checkStore
            let webhook;
            if (!forceLoad) {
                webhook = await this.getStoreData$()
                    .pipe(
                        take(1),
                        map((paginatedList) => {
                            return paginatedList.rows.find((el) => el.id === webhookId);
                        }),
                    )
                    .toPromise();
            }

            if (!webhook) {
                webhook = await this.webhooksConnector
                    .getWebhookConfigurationByKeys(enterpriseId, webhookId)
                    .toPromise();
                if (webhook) {
                    this.serviceHelper.dispatchWithoutPersisting<SingleWebhookLoadedAction>(
                        WEBHOOK_LIST_ACTION_TYPES.SINGLE_WEBHOOK_LOADED,
                        { webhook },
                    );
                }
            }

            return webhook;
        } catch (e) {
            // Not found
            return null;
        }
    }

    getStoreData$() {
        // Move to enterprise once we create proper reducers
        return this.store.select('webhookList');
    }

    protected getFilters$(): Observable<WebhookConfigurationFilters> {
        return combineLatest([this.enterpriseId$]).pipe(
            map<any, WebhookConfigurationFilters>(([enterpriseId]) => ({
                enterpriseId,
            })),
        );
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<WebhooksLoadedAction>(
                WEBHOOK_LIST_ACTION_TYPES.WEBHOOKS_LOADED,
                { page },
            );
        });
    }

    protected getData(
        page: number,
        filters: WebhookConfigurationFilters,
        orderBy: OrderedListParams<WebhookConfigurationOrderBy>,
    ): Observable<PaginatedList<WebhookConfigurationListItem>> {
        return this.webhooksConnector.getWebhookConfigurations({ page }, filters, orderBy);
    }
}
