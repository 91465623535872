import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
    AuthenticationAction,
    AuthenticationFlow,
    AuthTokenInfo,
    CompanySize,
    LoggedUser,
    PhaseID,
} from '../common/connectors/auth/authentication.model';
import { AuthBackendConnector } from '../common/connectors/authServiceConnector.service';
import {
    ProfileExistsInfo,
    ProjectServiceBackendConnector,
} from '../common/connectors/projectServiceConnector.service';
import { AppState } from './../common/app.store';

interface AuthResponse {
    navigateToRoute?: any[];
    redirectToURL?: string;
    errorId?: string;
    errorMessage?: string;
}

export enum AuthErrorIds {
    OFFLINE,
    UNAUTHORIZED,
    SAP_NOT_FOUND,
    NOT_FOUND,
    BLOCKED_DOMAIN,
    NOT_VERIFIED,
    UNKNOWN,
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private loggedUser: LoggedUser = { id: undefined };
    private loggedUserId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private profileExistsInfo$: BehaviorSubject<ProfileExistsInfo> = new BehaviorSubject<ProfileExistsInfo>(
        {} as ProfileExistsInfo,
    );

    private emailSource = new BehaviorSubject<string>('');

    private OFFLINE_ERROR_MESSAGE = 'Request failed. Could not reach ruum servers.';
    private UNAUTHORIZED_ERROR_MESSAGE = 'Password or email incorrect.';
    private NOT_FOUND_ERROR_MESSAGE = 'Please sign up. An account with that email address is not registered.';
    private SAP_NOT_FOUND_ERROR_MESSAGE =
        'Dear SAP colleague, please sign up in order to proceed to your Ruum. This is a technical requirement. Please do NOT create a ticket.';
    private UNKNOWN_ERROR_MESSAGE = 'An unknown error occured.';

    constructor(
        private authBackendConnector: AuthBackendConnector,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
        private store: Store<AppState>,
    ) {
        this.observeEmail();
    }

    setLoggedUserId(userId: string) {
        this.loggedUser = {
            id: userId,
        };
        this.loggedUserId$.next(userId);
    }

    getLoggedUser(): LoggedUser {
        return this.loggedUser;
    }

    getLoggedUserId(): string {
        return this.loggedUser && this.loggedUser.id;
    }

    loggedUserId(): Observable<string> {
        return this.loggedUserId$.asObservable().pipe(
            filter((userId) => !!userId),
            distinctUntilChanged(),
        );
    }

    getEmailValue(): string {
        return this.emailSource.getValue();
    }

    profileExists(email: string): Promise<AuthResponse> {
        this.setEmail(email);

        return this.authenticationFlow()
            .pipe(take(1))
            .toPromise()
            .then((authenticationFlow) => {
                return this.profileExistsProjectServiceFlow(email, authenticationFlow.redirectUrl); // TODO: send trakingId
            });
    }

    login(email: string, password: string): Promise<AuthResponse> {
        return this.authenticationFlow()
            .pipe(take(1))
            .toPromise()
            .then((authenticationFlow) => {
                const redirectUrl = authenticationFlow.redirectUrl;

                return this.loginProjectServiceFlow(email, password, redirectUrl);
            });
    }

    async getCAPTCHAToken(): Promise<string> {
        const _greCAPTCHA = (<any>window.top).grecaptcha;

        return new Promise((resolve: Function, reject: Function) => {
            _greCAPTCHA.ready(async () => {
                try {
                    const token = await _greCAPTCHA.execute(environment.GRECAPTCHA_SITE_KEY, { action: 'submit' });
                    if (token) {
                        resolve(token);
                    } else {
                        reject(new Error(`greCAPTCHA did not return any token`));
                    }
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    signup(
        email: string,
        password: string,
        isEnterpriseUser: boolean,
        isSSOUser: boolean,
        firstName: string,
        lastName: string,
        trackingId: string,
        touVersion: string,
        marketingEmailsAllowed: boolean,
        companySize?: CompanySize,
        companyRole?: string,
        companyFunction?: string,
    ): Promise<AuthResponse> {
        return Promise.all([this.authenticationFlow().pipe(take(1)).toPromise(), this.getCAPTCHAToken()])
            .then(([authenticationFlow, greCAPTCHAToken]) => {
                return this.signupProjectServiceFlow(
                    email,
                    password,
                    isEnterpriseUser,
                    isSSOUser,
                    firstName,
                    lastName,
                    trackingId,
                    touVersion,
                    marketingEmailsAllowed,
                    companySize,
                    companyRole,
                    companyFunction,
                    authenticationFlow,
                    greCAPTCHAToken,
                );
            })
            .catch((error) => {
                if (this.isOffline(error)) {
                    return this.throwOfflineError();
                }
                if (this.isNotFound(error)) {
                    return this.throwNotFoundError();
                }

                if (this.isBlockedDomain(error)) {
                    return this.throwBlockedDomainError();
                }

                if (error.errorMessage) {
                    return Promise.reject(error);
                }
                return this.throwUnknownError();
            });
    }

    verifySessions(): Promise<void> {
        return this.projectServiceBackendConnector
            .verifySessions()
            .then((loggedUserId) => {
                return this.setLoggedUserId(loggedUserId);
            })
            .catch((error) => {
                this.loggedUserId$.next(undefined);
                this.loggedUser = undefined;
                return Promise.reject(error);
            });
    }

    loginWithToken(token: string): Promise<AuthResponse> {
        return Promise.all([
            this.authBackendConnector.loginToAuthWithTokenService(token).toPromise(),
            this.authenticationFlow().pipe(take(1)).toPromise(),
        ]).then(([, authenticationFlow]) => {
            return this.navigateToApplication(authenticationFlow.redirectUrl);
        });
    }

    logout(): Promise<any> {
        return this.projectServiceBackendConnector.logout();
    }

    requestPasswordReset(email: string) {
        return this.getCAPTCHAToken()
            .then((greCAPTCHAToken) => this.requestPasswordResetProjectServiceFlow(email, greCAPTCHAToken))
            .then(this.navigateToResetPasswordConfirm)
            .catch((error) => {
                if (this.isOffline(error)) {
                    return this.throwOfflineError();
                }
                return this.throwUnknownError();
            });
    }

    resetPassword(email: string, password: string, token: string): Promise<any> {
        return this.projectServiceBackendConnector.resetPassword(email, password, token);
    }

    verifyEmail(username: string, token: string) {
        return this.authBackendConnector.verifyEmail(username, token).toPromise();
    }

    requestMailVerificationDelete(username: string) {
        return this.projectServiceBackendConnector.requestMailVerificationDelete(username);
    }

    /**
     * Should only be used by the goToPhase method in Authentication controller.
     * To go to a different phase use router.navigateByUrl instead.
     */
    goToPhase(phaseId: PhaseID) {
        const action: AuthenticationAction = {
            type: 'GO_TO_PHASE',
            payload: { phaseId },
        };
        this.store.dispatch(action);
    }

    setEmail(email: string) {
        this.setEmailToStorage(email);
        const action: AuthenticationAction = {
            type: 'SET_EMAIL',
            payload: { email },
        };
        this.store.dispatch(action);
    }

    authenticationFlow(): Observable<AuthenticationFlow> {
        return this.store.select('authentication');
    }

    setRedirectUrl(redirectUrl: string) {
        if (redirectUrl.startsWith('/auth')) {
            return;
        }
        const action: AuthenticationAction = {
            type: 'SET_REDIRECT_URL',
            payload: { redirectUrl },
        };
        this.store.dispatch(action);
    }

    setToken(token: string, tokenType: keyof AuthTokenInfo) {
        const action: AuthenticationAction = {
            type: 'SET_TOKEN',
            payload: {
                tokenType,
                token,
            },
        };
        this.store.dispatch(action);
    }

    setAdditionalAuthenticationInfo(additionalInfo) {
        const action: AuthenticationAction = {
            type: 'SET_ADDITIONAL_INFO',
            payload: additionalInfo,
        };
        this.store.dispatch(action);
    }

    getProfileExistsInfo(): Observable<ProfileExistsInfo> {
        return this.profileExistsInfo$.asObservable();
    }

    isEmailVerified(): Observable<boolean> {
        return this.profileExistsInfo$.pipe(
            map((profileExistsInfo) => {
                return !profileExistsInfo.emailNeedsVerification;
            }),
            distinctUntilChanged(),
        );
    }

    isEmailBounced(): Observable<boolean> {
        return this.store.select('userProfile').pipe(
            filter((profile) => !!profile.id),
            map((profile) => !!profile.bounced),
            distinctUntilChanged(),
        );
    }

    private setEmailToStorage(email: string): void {
        localStorage.setItem('ruumMailAddress', email);
    }

    private getEmailFromStorage(): string | undefined {
        return localStorage.getItem('ruumMailAddress');
    }

    private observeEmail(): Subscription {
        return combineLatest([this.authenticationFlow()])
            .pipe(
                map(([authenticationFlow]) => authenticationFlow.email),
                filter(Boolean),
                startWith(this.getEmailFromStorage() || ''),
                distinctUntilChanged(),
            )
            .subscribe((email: string) => this.emailSource.next(email));
    }

    private profileExistsProjectServiceFlow(email: string, redirectUrl: string): Promise<AuthResponse> {
        return this.projectServiceBackendConnector
            .profileExists(email)
            .then((profileExistsInfo: ProfileExistsInfo) => {
                this.profileExistsInfo$.next(profileExistsInfo);

                if (profileExistsInfo.exists) {
                    if (profileExistsInfo.ssoEnabled) {
                        // TODO: ckech if we can reuse authInfo.redirectUrl
                        return this.navigateToSSOLoginProjectServiceFlow(email, redirectUrl);
                    }

                    if (profileExistsInfo.emailNeedsVerification) {
                        // return this.throwNotVerifiedError();
                        return this.navigateToEmailVerificattion();
                    }

                    return this.navigateToNextLoginPhase();
                } else {
                    if (profileExistsInfo.ssoEnabled) {
                        this.setAdditionalAuthenticationInfo({
                            isEnterpriseUser: true,
                            isSSOUser: true,
                            canSignUp: true,
                            redirectUrl,
                        });
                    } else {
                        this.setAdditionalAuthenticationInfo({
                            isEnterpriseUser: false,
                            isSSOUser: false,
                            canSignUp: profileExistsInfo.canSignUp,
                        });
                    }

                    if (email.endsWith('@sap.com')) {
                        return this.throwSAPNotFoundError();
                    } else {
                        return this.throwNotFoundError();
                    }
                }
            })
            .catch((error) => {
                if (this.isOffline(error)) {
                    return this.throwOfflineError();
                }

                if (this.isBlockedDomain(error)) {
                    return this.throwBlockedDomainError();
                }

                if (error.errorMessage) {
                    return Promise.reject(error);
                }

                return this.throwUnknownError();
            });
    }

    private loginProjectServiceFlow(email: string, password: string, redirectUrl: string): Promise<AuthResponse> {
        return this.projectServiceBackendConnector.login(email, password, redirectUrl).then(
            () => {
                return this.navigateToApplication(redirectUrl);
            },
            (error) => {
                if (this.isOffline(error)) {
                    return this.throwOfflineError();
                }

                if (this.isUnauthorized(error)) {
                    return this.throwUnauthorizedError();
                }

                return this.throwUnknownError();
            },
        );
    }

    private signupProjectServiceFlow(
        email: string,
        password: string,
        isEnterpriseUser: boolean,
        isSSOUser: boolean,
        firstName: string,
        lastName: string,
        trackingId: string,
        touVersion: string,
        marketingEmailsAllowed: boolean,
        companySize: CompanySize,
        companyRole: string,
        companyFunction: string,
        authenticationFlow: AuthenticationFlow,
        greCAPTCHAToken: string,
    ): Promise<AuthResponse> {
        return this.projectServiceBackendConnector
            .signup(
                email,
                password,
                isEnterpriseUser,
                isSSOUser,
                firstName,
                lastName,
                trackingId,
                touVersion,
                marketingEmailsAllowed,
                greCAPTCHAToken,
                companySize,
                companyRole,
                companyFunction,
            )
            .then((result: { loginUrl: string }) => {
                /** The server can't return 303 as a result of a CORS ajax request, so we have to manually redirect. */
                if (result.loginUrl) {
                    return this.navigateToSSOLoginProjectServiceFlow(email, authenticationFlow.redirectUrl);
                } else {
                    return this.navigateToEmailVerificattion();
                }
            })
            .catch((error) => {
                if (error.errorMessage) {
                    return Promise.reject(error);
                }
                return this.throwUnknownError();
            });
    }

    private requestPasswordResetProjectServiceFlow(email: string, greCAPTCHAToken: string) {
        return this.projectServiceBackendConnector.requestPasswordReset(email, greCAPTCHAToken);
    }

    private isOffline(err): boolean {
        return err.status === 0;
    }

    private isUnauthorized(err) {
        return err.status === 401;
    }

    private isNotFound(err): boolean {
        return err.status === 404;
    }

    private isBlockedDomain(err): boolean {
        return err.status === 412;
    }

    private navigateToApplication(redirectUrl: string): Promise<AuthResponse> {
        if (redirectUrl && redirectUrl.startsWith('http')) {
            return Promise.resolve({ redirectToURL: redirectUrl });
        }

        if (redirectUrl) {
            return Promise.resolve({ navigateToRoute: [redirectUrl] });
        }

        return Promise.resolve({ navigateToRoute: ['home'] });
    }

    private navigateToNextLoginPhase(): Promise<AuthResponse> {
        return Promise.resolve({ navigateToRoute: ['auth', 'login-password'] });
    }

    private navigateToSSOLoginProjectServiceFlow(email: string, redirectUrl): Promise<AuthResponse> {
        return this.projectServiceBackendConnector.loginSSO(email, redirectUrl).then(
            (sso) => {
                return Promise.resolve({ redirectToURL: sso.loginUrl });
            },
            () => this.throwUnknownError(),
        );
    }

    private navigateToEmailVerificattion(): Promise<AuthResponse> {
        return Promise.resolve({ navigateToRoute: ['auth', 'email-verification'] });
    }

    private navigateToResetPasswordConfirm(): Promise<AuthResponse> {
        return Promise.resolve({ navigateToRoute: ['auth', 'reset-confirmation'] });
    }

    private throwOfflineError(): Promise<AuthResponse> {
        return Promise.reject({ errorId: AuthErrorIds.OFFLINE, errorMessage: this.OFFLINE_ERROR_MESSAGE });
    }

    private throwUnauthorizedError() {
        return Promise.reject({ errorId: AuthErrorIds.UNAUTHORIZED, errorMessage: this.UNAUTHORIZED_ERROR_MESSAGE });
    }

    private throwSAPNotFoundError(): Promise<AuthResponse> {
        return Promise.reject({ errorId: AuthErrorIds.SAP_NOT_FOUND, errorMessage: this.SAP_NOT_FOUND_ERROR_MESSAGE });
    }

    private throwNotFoundError(): Promise<AuthResponse> {
        return Promise.reject({ errorId: AuthErrorIds.NOT_FOUND, errorMessage: this.NOT_FOUND_ERROR_MESSAGE });
    }

    private throwBlockedDomainError(): Promise<AuthResponse> {
        return Promise.reject({ errorId: AuthErrorIds.BLOCKED_DOMAIN, errorMessage: this.NOT_FOUND_ERROR_MESSAGE });
    }

    private throwUnknownError(): Promise<AuthResponse> {
        return Promise.reject({ errorId: AuthErrorIds.UNKNOWN, errorMessage: this.UNKNOWN_ERROR_MESSAGE });
    }
}
