import { RuumAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../paginatedList.model';
import { TASK_LIST_ACTIONS } from './task-list.actions';
import { TaskListItem } from './task-list.model';

/**
 * parentId = The parent of all tasks in "currentState"
 */
export function TaskListReducer(currentState: PaginatedList<string>, action: RuumAction): PaginatedList<string> {
    if (!TASK_LIST_ACTIONS[action.type]) {
        return currentState;
    }

    switch (action.type) {
        case TASK_LIST_ACTIONS.TASK_LIST_LOADED:
            return tasksLoaded(currentState, action.payload.list);
        default:
            return currentState;
    }
}

function tasksLoaded(currentState: PaginatedList<string>, data: PaginatedList<TaskListItem>): PaginatedList<string> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows.map(({ projectId, id }) => `${projectId}-${id}`)),
        };
    } else {
        return {
            ...data,
            rows: data.rows.map(({ projectId, id }) => `${projectId}-${id}`),
        };
    }
}
