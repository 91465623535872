import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AccountType, EnterpriseRole } from '@ruum/ruum-reducers';
import { take } from 'rxjs/operators';
import { EnterprisesAccess, ProfileService } from '../../../../common/connectors/profile/profile.service';
import {
    RuumDropdownItem,
    RuumDropdownList,
    RuumDropdownSectionList,
} from '../../../../shared/ui-components/dropdown/dropdown.model';
import { SettingsNavigationTarget } from '../settings-navigation-target';
import { SettingsView } from '../settings.view';

@Component({
    selector: 'ruum-settings-popover',
    template: `
        <div class="w-100">
            <div class="pr-2 pl-3 pt-3 pb-2">
                <div class="d-flex flex-fill">
                    <div class="d-flex flex-column d-flex flex-fill justify-content-center">
                        <h3>{{ view.name }}</h3>
                    </div>
                    <button
                        class="btn btn-round btn-avatar btn-lg btn-without-hover ruum-profile-avatar"
                        type="button"
                        title="{{ view.initials }}"
                        tabindex="-1"
                    >
                        <span class="text-small font-weight-bold">{{ initials }}</span>
                    </button>
                </div>
                <div class="d-flex mt-0 text-break-all">
                    <span class="text-small text-secondary">{{ email }}</span>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <p
                            class="d-block mb-0 text-small text-info"
                            [class.ruum-free-plan]="view.isBasic"
                            [class.ruum-pro-plan]="view.isProfessional"
                            [class.ruum-ent-plan]="view.isEnterprise"
                        >
                            <span class="text-small">{{ view.accountTypeLabel }}</span>
                            <span class="d-block mt-0 text-tiny text-secondary" *ngIf="view.daysLeft">
                                {{ view.daysLeft }} days left
                            </span>
                        </p>
                        <button *ngIf="view.showUpgradeButton" class="btn btn-primary" (click)="view.upgrade()">
                            Upgrade
                        </button>
                    </div>
                </div>
            </div>

            <div class="ruum-divider"></div>

            <ruum-dropdown-section-list [sectionList]="dropdownSectionList" (selectItem)="select($event)">
            </ruum-dropdown-section-list>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                min-width: 270px;
            }

            h3 {
                max-width: 198px;
            }
        `,
    ],
    providers: [SettingsView],
})
export class SettingsPopoverComponent implements OnInit, OnChanges {
    @Input() name: string;

    @Input() initials: string;

    @Input() email: string;

    @Input() accountType: AccountType;

    @Input() isTrial: boolean;

    @Input() daysLeft?: number;

    @Output() navigate = new EventEmitter<SettingsNavigationTarget>();

    dropdownSectionList: RuumDropdownSectionList;

    settingsOptions: RuumDropdownList = [
        { id: 'PROFILE_SETTINGS', name: 'Profile Settings', icon: 'icon-user' },
        { id: 'NOTIFICATIONS_SHORTCUTS', name: 'Notifications Settings', icon: 'icon-notification' },
    ];

    logoutOptions: RuumDropdownList = [{ id: 'LOGOUT', name: 'Logout', icon: 'icon-leave-ruum icon-rotate-180' }];

    constructor(public view: SettingsView, private profileService: ProfileService) {}

    ngOnInit() {
        this.profileService
            .getAvailableUserEnterprises$()
            .pipe(take(1))
            .subscribe((availableUserEnterprises: EnterprisesAccess[]) => {
                const isAdminInEnterprise =
                    availableUserEnterprises.findIndex(
                        (enterprise: EnterprisesAccess) =>
                            enterprise.role === EnterpriseRole.EnterpriseAdmin ||
                            enterprise.role === EnterpriseRole.EnterpriseIntegrationAdmin,
                    ) !== -1;
                if (isAdminInEnterprise) {
                    this.settingsOptions = [
                        ...this.settingsOptions,
                        { id: 'ADMIN_PANEL', name: 'Admin Panel', icon: 'icon-admin-dashboard' },
                    ];
                }
                this.dropdownSectionList = [this.settingsOptions, this.logoutOptions];
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.view.onEnclosingComponentChange(
            this.name,
            this.initials,
            this.email,
            this.accountType,
            this.isTrial,
            this.daysLeft,
        );
    }

    select(item: RuumDropdownItem) {
        switch (item.id) {
            case 'PROFILE_SETTINGS':
                return this.goTo(SettingsNavigationTarget.PROFILE);
            case 'NOTIFICATIONS_SHORTCUTS':
                return this.goTo(SettingsNavigationTarget.NOTIFICATIONS);
                return this.goTo(SettingsNavigationTarget.PROFILE);
            case 'ADMIN_PANEL':
                return this.goTo(SettingsNavigationTarget.ADMIN);
            case 'LOGOUT':
                return this.goTo(SettingsNavigationTarget.LOGOUT);
            default:
                return;
        }
    }

    goTo(settingsNavigationTarget: SettingsNavigationTarget): void {
        this.navigate.emit(settingsNavigationTarget);
    }
}
