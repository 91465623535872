import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldValue } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-custom-field-text',
    template: `
        <ng-container *ngIf="!isReadOnly; else readOnlyTemplate">
            <input
                class="form-control form-control-sm"
                aria-label="Custom Field Text"
                [formControl]="formControl"
                [placeholder]="placeholder"
                (blur)="onValueChange()"
                aria-label="Enter text"
            />
        </ng-container>

        <ng-template #readOnlyTemplate>
            <span class="form-control form-control-sm disabled" [class.text-light]="!customField.value"
                >{{ customField.value || placeholder }}
            </span>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldTextComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Input()
    set customField(customField: CustomFieldValue) {
        this._customField = customField;
        if (customField) {
            const value = customField.value ? (customField.value as string) : '';
            // this is needed because sometimes the customField ref changed but not the value
            if (value !== this.value) {
                this.value = value;
                this.formControl.setValue(value);
            }
        }
    }
    get customField(): CustomFieldValue {
        return this._customField;
    }

    @Input() isReadOnly = false;

    @Output() valueChange = new EventEmitter<CustomFieldValue>();

    get placeholder(): string {
        return this.customField.placeholder ? this.customField.placeholder : 'Enter text';
    }

    formControl = new FormControl('');

    private _customField: CustomFieldValue;
    private value = '';

    constructor() {}

    ngOnInit() {}

    onValueChange() {
        const value = this.formControl.value;
        if (this.customField.value !== value) {
            const changedField = { ...this.customField, value };
            this.valueChange.emit(changedField);
        }
    }
}
