import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ODataEnterpriseOAuthClient } from '../../common/odata/odata.model';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { EnterpriseSettingsService } from '../common/enterpriseSettings.service';

interface OAuthOption {
    id: string;
    text: string;
}

@Component({
    selector: 'security-oauth-tab',
    template: `
        <ruum-admin-widget class="page">
            <div class="body">
                <table class="ruum-table table" *ngIf="(oauthConfigurations$ | async)?.length !== 0">
                    <thead>
                        <tr class="position-static">
                            <th scope="col">Client Id</th>
                            <th scope="col">App Name</th>
                            <th scope="col">App Description</th>
                            <th scope="col">Configure</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let oauthConfigurationInfo of oauthConfigurations$ | async; let index">
                            <td class="text-truncate">
                                <a routerLink="../{{ oauthConfigurationInfo.clientId }}">
                                    {{ oauthConfigurationInfo.clientId }}
                                </a>
                            </td>
                            <td>{{ oauthConfigurationInfo.appName }}</td>
                            <td class="text-truncate">{{ oauthConfigurationInfo.appDescription }}</td>
                            <td>
                                <i
                                    class="icon icon-contextual-menu"
                                    span
                                    *ngIf="(menuOpenIndex$ | async) !== index"
                                    (click)="setMenuVisible(index)"
                                    (keydown.space)="setMenuVisible(index)"
                                    (keydown.enter)="setMenuVisible(index)"
                                ></i>
                                <dropdown-popup
                                    *ngIf="(menuOpenIndex$ | async) === index"
                                    [options]="getMenuOptions()"
                                    (onClose)="setMenuVisible(undefined)"
                                    (onSelect)="selectMenuOption($event, oauthConfigurationInfo.clientId)"
                                >
                                </dropdown-popup>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    class="d-inline-block w-100 mt-8 text-center text-secondary"
                    *ngIf="(oauthConfigurations$ | async)?.length === 0"
                >
                    No OAuth Clients configured
                </div>
            </div>

            <div class="footer">
                <button class="btn btn-primary float-right" (click)="createNew()">New OAuth Client</button>
            </div>
        </ruum-admin-widget>
    `,
})
export class SecurityOauthTabComponent implements OnInit {
    oauthConfigurations$: Observable<ODataEnterpriseOAuthClient[]>;
    options: OAuthOption[] = [{ id: 'delete', text: 'Delete' }];
    menuOpenIndex$: Subject<number> = new Subject<number>();

    constructor(
        private profileService: ProfileService,
        private route: ActivatedRoute,
        private router: Router,
        private enterpriseSettingsService: EnterpriseSettingsService,
    ) { }

    async ngOnInit() {
        const enterpriseId = await this.profileService
            .getCurrentUserEnterpriseId$()
            .pipe(take(1))
            .toPromise();
        this.enterpriseSettingsService.reloadBasicEnterpriseConfiguration(enterpriseId);
        this.oauthConfigurations$ = this.enterpriseSettingsService.getOauthClients();
    }

    getMenuOptions() {
        return this.options.map((option) => option.text);
    }

    async selectMenuOption(optionIndex: number, clientId: string) {
        switch (this.options[optionIndex].id) {
            case 'delete': {
                await this.deleteOauthClient(clientId);
            }
        }
    }

    setMenuVisible(index: number) {
        this.menuOpenIndex$.next(index);
    }

    async deleteOauthClient(clientId: string) {
        const enterpriseId = await this.profileService
            .getCurrentUserEnterpriseId$()
            .pipe(take(1))
            .toPromise();
        await this.enterpriseSettingsService.deleteOauthClient(clientId);
        await this.enterpriseSettingsService.reloadBasicEnterpriseConfiguration(enterpriseId);
    }

    createNew() {
        this.router.navigate(['./new'], { relativeTo: this.route });
    }
}
