import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ExternalSystem, SystemConnector } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';
import { ExternalSystemStore } from '../../../common/connectors/externalSystem/externalSystem.store';
import { OrderedListParams, SortDirection } from '../../../common/connectors/paginatedList.model';
import { GenericDataSource } from '../../../common/connectors/paginatedListLoader';
import { SystemConnectorListSortBy } from '../../../common/connectors/system-connectors/system-connectors-list.model';
import { SystemConnectorListStore } from '../../../common/connectors/system-connectors/system-connectors-list.store';
import { SystemConnectorsService } from '../../../common/connectors/system-connectors/system-connectors.service';
import { SystemConnectorDialogEditComponent } from '../../../shared/system-connector/system-connector-dialog/system-connector-dialog-edit.component';
import { createIOObjectFromArray } from '../../../shared/system-connector/system-connector-dialog/system-connector-dialog.utils';
import { ModalService } from '../../common/modal/modal.service';
import { SystemConnectorDialogCreateComponent } from './../../../shared/system-connector/system-connector-dialog/system-connector-dialog-create.component';

export type SystemConnectorTableData = SystemConnector & { system?: ExternalSystem };

@Component({
    selector: 'ruum-admin-system-connector-list',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <!-- view controles -->
                <ruum-view-controls>
                    <div class="w-25">
                        <ruum-search
                            [size]="'sm'"
                            ariaLabel="Search for system connector"
                            [value]="searchValue"
                            (changed)="search($event)"
                        ></ruum-search>
                    </div>
                    <div class="d-flex flex-fill justify-content-end">
                        <button
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-primary float-right"
                            type="button"
                            (click)="openCreateDialog()"
                        >
                            <i class="mr-1 icon icon-add"></i>
                            <span>New</span>
                        </button>
                    </div>
                </ruum-view-controls>

                <!-- empty state: no items yet -->
                <!-- empty state: no search result -->
                <ruum-empty-state
                    *ngIf="(data$ | async)?.length === 0 && (isLoadingFirstPage$ | async) !== true"
                    [searchApplied]="(searchValue$ | async)?.length > 0"
                    [items]="[]"
                    entityName="System Connectors"
                >
                    <ruum-illustration-system-connectors
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-system-connectors>
                </ruum-empty-state>

                <!-- view list -->
                <div
                    *ngIf="(data$ | async)?.length > 0"
                    class="d-flex flex-column px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5"
                >
                    <table class="w-100" cdk-table [dataSource]="dataSource" [trackBy]="trackByFunction">
                        <!-- Title Column -->
                        <ng-container cdkColumnDef="title">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'title'"
                                    [label]="'Name'"
                                    [sorting]="isSortBy('title')"
                                    [direction]="getSortDirection('title')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.title }}</span>
                            </td>
                        </ng-container>

                        <!-- System Column -->
                        <ng-container cdkColumnDef="system">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'systemTitle'"
                                    [label]="'System'"
                                    [sorting]="isSortBy('systemTitle')"
                                    [direction]="getSortDirection('systemTitle')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.system?.title }}</span>
                            </td>
                        </ng-container>

                        <!-- Path Column -->
                        <ng-container cdkColumnDef="path">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'path'"
                                    [label]="'Path'"
                                    [sorting]="isSortBy('path')"
                                    [direction]="getSortDirection('path')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.path }}</span>
                            </td>
                        </ng-container>

                        <!-- Type Column -->
                        <ng-container cdkColumnDef="type">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <h5 class="text-secondary">Type</h5>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.type }}</span>
                            </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container cdkColumnDef="actions">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3"></th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 text-right">
                                <button class="btn btn-icon btn-link-secondary ml-1" (click)="editItem(element)">
                                    <i class="icon icon-settings"></i>
                                </button>
                                <button class="btn btn-icon btn-link-danger ml-1" (click)="deleteItem(element)">
                                    <i class="icon icon-delete"></i>
                                </button>
                            </td>
                        </ng-container>

                        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns"
                            class="border-bottom border-light hov hov-bg-primary-light"
                        ></tr>
                    </table>

                    <!-- spinner: initial fetching -->
                    <ruum-load-spinner
                        classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                        *ngIf="isLoadingFirstPage$ | async"
                    ></ruum-load-spinner>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="loadMore()"
                    ></ruum-load-more>
                </div>
            </div>
        </ruum-max-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSystemConnectorListComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column w-100 mw-100 h-100 mh-100 overflow-y';

    searchValue = '';
    searchValue$: Observable<string>;

    displayedColumns = ['title', 'system', 'path', 'type', 'actions'];
    dataSource: GenericDataSource<SystemConnectorTableData>;

    isLoadingFirstPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;

    sortValue: OrderedListParams<SystemConnectorListSortBy> = { by: 'title', direction: 'asc' };
    isCurrentUserSuperAdmin$: Observable<boolean>;

    data$: Observable<SystemConnectorTableData[]>;

    constructor(
        private selectedEnterpriseService: SelectedEnterpriseService,
        private modalService: ModalService,
        private elementRef: ElementRef,
        private systemConnectorsService: SystemConnectorsService,
        private systemConnectorListStore: SystemConnectorListStore,
        private externalSystemStore: ExternalSystemStore,
    ) {}

    ngOnInit() {
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();
        this.createTableObserver();
        this.dataSource = new GenericDataSource<SystemConnectorTableData>(this.data$);
        this.isLoadingFirstPage$ = this.systemConnectorListStore.isLoadingFirstPage$;
        this.showLoading$ = this.systemConnectorListStore.showLoading$;
        this.searchValue$ = this.systemConnectorListStore.search$.asObservable();
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }

    ngOnDestroy() {}

    trackByFunction(index: number, item: SystemConnector): string {
        if (!item) {
            return;
        }
        return item.id;
    }

    openCreateDialog() {
        const instance = this.modalService.open(SystemConnectorDialogCreateComponent, {
            size: 'lg',
            backdrop: 'static',
        });

        instance.result.then(
            (data) => {
                data.input = createIOObjectFromArray(data.input);
                data.output = createIOObjectFromArray(data.output);
                return this.systemConnectorsService.createSystemConnector(data);
            },
            () => {},
        );
    }

    editItem(systemConnector: SystemConnectorTableData) {
        const instance = this.modalService.open(SystemConnectorDialogEditComponent, {
            size: 'lg',
            backdrop: 'static',
        });

        instance.componentInstance.systemConnector = systemConnector;

        instance.result.then(
            (data) => {
                data.input = createIOObjectFromArray(data.input);
                data.output = createIOObjectFromArray(data.output);

                data = this.cleanupDialogPayload(data);

                return this.systemConnectorsService.updateSystemConnector(systemConnector.id, data);
            },
            () => {},
        );
    }

    /**
     * Cleanup additional properties that are not accepted by the reducer
     */
    private cleanupDialogPayload(data: any): any {
        const result = { ...data };

        switch (result.type) {
            case 'standard':
                delete result.xtoken;
                delete result.definitionId;
                break;
            case 'rpa_bot':
                delete result.definitionId;
                break;
            case 'scp_workflow':
                delete result.xtoken;
                break;
        }

        return result;
    }

    deleteItem(systemConnector: SystemConnectorTableData) {
        this.systemConnectorsService.deleteSystemConnector(systemConnector.id);
    }

    search(value: string): void {
        this.systemConnectorListStore.search$.next(value);
    }

    loadMore() {
        this.systemConnectorListStore.maybeGoToNextPage();
    }

    isSortBy(sortBy: SystemConnectorListSortBy): boolean {
        if (this.sortValue && this.sortValue.by === sortBy) {
            return true;
        }
        return false;
    }

    getSortDirection(sortBy: SystemConnectorListSortBy): SortDirection {
        if (this.sortValue && this.sortValue.by === sortBy) {
            return this.sortValue.direction;
        }
        return 'asc';
    }

    sort(sortChange: OrderedListParams<SystemConnectorListSortBy>) {
        this.sortValue = sortChange;
        this.systemConnectorListStore.orderBy(sortChange.by as SystemConnectorListSortBy, sortChange.direction);
    }

    private createTableObserver() {
        this.data$ = this.systemConnectorListStore.getStoreRows$().pipe(
            switchMap<SystemConnector[], Observable<SystemConnectorTableData[]>>((systemConnectorList) => {
                const systemIdList = systemConnectorList.map((systemConnector) => systemConnector.systemId);
                return this.externalSystemStore.dataList(systemIdList).pipe(
                    map((externalSystemList) => {
                        const systemMap = new Map<string, ExternalSystem>();
                        for (const item of externalSystemList) {
                            systemMap.set(item.id, item);
                        }
                        return systemConnectorList.map((systemConnector) => {
                            const system = systemMap.get(systemConnector.systemId);
                            return { ...systemConnector, system };
                        }) as SystemConnectorTableData[];
                    }),
                );
            }),
        );

        this.systemConnectorListStore.loadList();
    }
}
