import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnterpriseAction, Entity, FileUploadOptions, User } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { UserListItem } from '../../common/connectors/readModelConnector.service';
import { ODataEnterpriseFileUploadOptions } from '../../common/odata/odata.model';
import { ODataConnector } from './../../common/odata/ODataConnector.service';
import { EnterpriseReport } from './../dashboard/dashboard.model';
import { ChangeAuthenticationProviderConfiguration } from './../user-configuration/configuration.model';
export interface BasicEnterprise extends Entity {
    name: string;
    createdAt: number;
    patterns: string[];
    participants?: PaginatedList<UserListItem>;
    fileUploadOptions?: FileUploadOptions;
}
@Injectable()
export class BackendConnector {
    private PROJECT_SERVICE_URL: string = environment.PROJECT_SERVICE_URL;

    constructor(
        private http: HttpClient,
        private oDataConnector: ODataConnector,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private sanitizer: DomSanitizer,
    ) {}

    /** ENTERPRISE USER */

    getUsersOfEnterpriseId(enterpriseId: string): Observable<User[]> {
        return this.http.get<User[]>(`${this.PROJECT_SERVICE_URL}/v1/admin/${enterpriseId}/users`, {
            withCredentials: true,
        });
    }

    changeUserRole(enterpriseId, userId, role, add): Observable<void> {
        const url = `${this.PROJECT_SERVICE_URL}/v1/admin/${enterpriseId}/admins/${userId}/${role}`;
        if (add) {
            return this.http.post<void>(url, {}, { withCredentials: true });
        } else {
            return this.http.delete<void>(url, { withCredentials: true });
        }
    }

    /** CONFIGURATION */

    /** New Enterprise Entity from Postgres */
    getEnterpriseConfigurationById(): Observable<BasicEnterprise> {
        const selectedEnterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();

        return this.oDataConnector.getOne<BasicEnterprise>({
            serviceRoot: 'RuumInternal.Svc',
            path: `Enterprises('${selectedEnterpriseId}')`,
        });
    }

    getEnterpriseFileUploadOptions(): Observable<FileUploadOptions> {
        const selectedEnterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();

        return this.oDataConnector.getOne<ODataEnterpriseFileUploadOptions>({
            serviceRoot: 'RuumInternal.Svc',
            path: `Enterprises('${selectedEnterpriseId}')/fileUploadOptions`,
        });
    }

    getConfigurationOfCurrentEnterprise(): Observable<ChangeAuthenticationProviderConfiguration> {
        const selectedEnterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();

        return this.http.get<ChangeAuthenticationProviderConfiguration>(
            `${this.PROJECT_SERVICE_URL}/v1/admin/${selectedEnterpriseId}/configuration`,
            { withCredentials: true },
        );
    }

    checkConfigurationOfEnterpriseId(
        enterpriseId: string,
        configuration: ChangeAuthenticationProviderConfiguration,
    ): Observable<any> {
        return this.http.post<any>(
            `${this.PROJECT_SERVICE_URL}/v1/admin/${enterpriseId}/configuration/check`,
            configuration,
            {
                withCredentials: true,
            },
        );
    }

    setConfigurationOfEnterpriseId(
        enterpriseId: string,
        configuration: ChangeAuthenticationProviderConfiguration,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.PROJECT_SERVICE_URL}/v1/admin/${enterpriseId}/configuration`,
            configuration,
            {
                withCredentials: true,
            },
        );
    }

    updateEnterpriseFileUploadOptions(updateFileUploadOptionsAction: EnterpriseAction): Promise<void> {
        const safeURL = this.sanitizer.sanitize(SecurityContext.URL, window.location.href);
        const urlPath = new URL(safeURL);

        const headers = new HttpHeaders({ 'RUUM-PATH': urlPath.pathname });

        const selectedEnterprise = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http
            .put<void>(
                `${this.PROJECT_SERVICE_URL}/v1/enterprises/${selectedEnterprise}/actions`,
                [
                    {
                        ...updateFileUploadOptionsAction,
                    },
                ],
                { withCredentials: true, headers },
            )
            .toPromise();
    }

    updateEnterpriseConfiguration(updateConfigurationAction: EnterpriseAction): Promise<void> {
        const safeURL = this.sanitizer.sanitize(SecurityContext.URL, window.location.href);
        const urlPath = new URL(safeURL);

        const headers = new HttpHeaders({ 'RUUM-PATH': urlPath.pathname });
        const selectedEnterprise = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http
            .put<void>(
                `${this.PROJECT_SERVICE_URL}/v1/enterprises/${selectedEnterprise}/actions`,
                [
                    {
                        ...updateConfigurationAction,
                    },
                ],
                { withCredentials: true, headers },
            )
            .toPromise();
    }

    /** ENTERPRISE REPORT */

    getEnterpriseReport(enterpriseId: string): Promise<EnterpriseReport> {
        return this.http
            .get<EnterpriseReport>(`${this.PROJECT_SERVICE_URL}/v1/enterprises/${enterpriseId}/report`, {
                withCredentials: true,
            })
            .toPromise();
    }

    /** WEBHOOKS */

    /** GENERAL */
}
