import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { RuumPopupService } from '../popup/ruum-popup-container.service';
import { isMobile } from '../utils.service';
import { RuumDropdownPopupMobileComponent } from './ruum-dropdown-popup-mobile.component';
import {
    RuumDropDownOption,
    RuumDropDownPopupAlign,
    RuumDropdownPopupComponent,
} from './ruum-dropdown-popup.component';

@Component({
    selector: 'action-buttons',
    template: `
        <div class="ruum-dropdown">
            <button
                *ngIf="buttonType === 'more'"
                class="btn btn-link-secondary btn-icon"
                type="button"
                (click)="openOptions($event)"
            >
                <i class="icon icon-contextual-menu"></i>
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumActionButtonsComponent {
    @Input() actions: RuumDropDownOption[] = [];
    @Input() buttonType = 'more';
    @Input() align: RuumDropDownPopupAlign = 'right';

    @Output() selectChange = new EventEmitter<RuumDropDownOption>();

    constructor(private elem: ElementRef, private ruumPopupService: RuumPopupService) {}

    selectOption(selectedOption) {
        this.selectChange.emit(selectedOption);
    }

    openOptions(event) {
        /*
            Propagating clicks was causing troubles when this component was used inside clickable containers such as the Participant card.
        */

        event.stopPropagation();
        const elem: HTMLElement = event.currentTarget;
        elem.dispatchEvent(new CustomEvent('popup-focus', { bubbles: true }));
        this.ruumPopupService
            .showPopup(isMobile() ? RuumDropdownPopupMobileComponent : RuumDropdownPopupComponent, {
                options: this.actions,
                pos: this.elem.nativeElement.getClientRects()[0],
                // title: this.placeholder,
                align: this.align,
            })
            .subscribe((selectedOption) => {
                elem.dispatchEvent(new CustomEvent('popup-blur', { bubbles: true }));
                if (selectedOption) {
                    this.selectOption(selectedOption);
                }
            });
    }
}
