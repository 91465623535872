import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppStoreWrapper } from '../appStoreWrapper.service';
import { RuumAlertService } from '../components/alert/alert.service';

@Injectable({ providedIn: 'root' })
export class AuthBackendConnector {
    private AUTH = environment.AUTH_SERVICE_URL;
    private APP_URL = environment.APP_URL;
    private LOGIN_URL = this.AUTH + '/v1/login';
    private MAIL_VERIFICATION_URL = this.AUTH + '/v1/emailVerification';

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private appStoreWrapper: AppStoreWrapper,
        private sanitizer: DomSanitizer,
        private alertService: RuumAlertService,
    ) {}

    loginToAuthWithTokenService(token: string) {
        return this.http.post(this.LOGIN_URL, { token }, { withCredentials: true });
    }

    verifyEmail(userMail: string, token: string) {
        return this.http.post(
            this.MAIL_VERIFICATION_URL,
            {
                userMail,
                token,
            },
            { withCredentials: true },
        );
    }

    extractObject(body) {
        return body === undefined ? {} : body;
    }

    extractArray(body) {
        return body || [];
    }

    // TODO: remove it. it should be handled by authGuard
    handleError(error: any, caught: Observable<any>) {
        if (this.shouldRedirectToRuumList(error)) {
            this.appStoreWrapper
                .msTeamsStage()
                .pipe(take(1))
                .subscribe((msTeamsStage) => {
                    if (msTeamsStage === 'ruum') {
                        const safeURL = this.sanitizer.sanitize(SecurityContext.URL, window.location.href);

                        const ruumId: string = safeURL.match(new RegExp(this.APP_URL + '/ruums/(ruum_[\\d\\w]*)'))[1];
                        window.location.href = `${this.APP_URL}/msteams/tab-access-denied.html?ruum=${ruumId}`;
                    } else {
                        // TODO: add an error/alert/whatever state and use this to show alert message
                        this.alertService.warning(`Sorry, you don\'t have access to this Ruum.`);
                        this.injector.get(Router).navigateByUrl('/home');
                    }
                });
        } else {
            if (this.shouldShowErrorPopup(error)) {
                this.alertService.warning(
                    'Something went wrong. Please provide this request ID to the support: ' + error.requestId,
                );
            }
        }

        throw error;
    }

    private shouldShowErrorPopup(err) {
        return (
            err.status !== 401 && err.status !== 403 // Prevent popup before logging in & when accessing workspaces / groups of shared Ruums
        );
    }

    private shouldRedirectToRuumList(err) {
        return (err.status === 404 || err.status === 403) && window.location.pathname.startsWith('/ruums/');
    }
}

interface LoginInfo {
    id: string;
    unverified?: boolean;
}
