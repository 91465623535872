import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { ParticipantsService } from '../../ruum/participant/participants.service';
import { hexColorToRgba } from '../utils.service';

@Component({
    selector: 'ruum-team-members-list-item',
    template: `
        <div
            class="d-flex flex-fill rounded p-2"
            [class.cursor-pointer]="selectable"
            [style.background]="selectable ? getBackgroundColor() : 'transparent'"
            (click)="selectable && selectParticipant()"
            (keydown.space)="selectable && selectParticipant()"
            (keydown.enter)="selectable && selectParticipant()"
        >
            <div class="d-flex">
                <ruum-profile-member [name]="fullName" [theme]="color | ruumProfileHexToTheme"></ruum-profile-member>
            </div>
            <div class="d-flex px-2 flex-column flex-fill minw-0">
                <div *ngIf="fullName" class="mt-1 text-truncate" [title]="fullName">
                    {{ !isYou ? fullName : 'You' }}
                </div>
                <div *ngIf="email" class="text-small text-light text-truncate" [title]="email">{{ email }}</div>
                <div *ngIf="visitedAt > 0" class="mt-1 text-small text-secondary text-truncate">
                    Visited {{ visitedAt | timeAgo }}
                </div>
                <div *ngIf="visitedAt <= 0" class="mt-1 text-small text-secondary text-truncate">
                    Never Visited
                </div>
            </div>

            <div *ngIf="exceptionMessage" class="d-flex align-items-start">
                <button
                    class="btn btn-sm btn-icon btn-link-danger"
                    type="button"
                    [ngbTooltip]="tooltipContent"
                    tooltipClass="team-member-tooltip"
                >
                    <i class="icon icon-info"></i>
                </button>

                <ng-template #tooltipContent>
                    <div class="text-small font-weight-bold">{{ exceptionMessage }}</div>
                </ng-template>
            </div>
            <div class="d-flex align-items-start">
                <ng-content select="[data-content='team-member-role-dropdown']"> </ng-content>
            </div>
        </div>
    `,
})
export class TeamMembersListItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill flex-column minw-0';

    constructor(private participantsService: ParticipantsService) {}
    @Input()
    id: string;

    @Input()
    fullName: string;

    @Input()
    color: string;

    @Input()
    email: string;

    @Input()
    selectable: boolean;

    @Input()
    readOnly: boolean;

    @Input()
    selected: boolean;

    @Input()
    visitedAt: number;

    @Input()
    exceptionMessage = '';

    @Input()
    isYou = false;

    hovered = false;

    @HostListener('mouseover', [])
    onMouseover() {
        this.hovered = true;
    }

    @HostListener('mouseleave', [])
    onMouseleave() {
        this.hovered = false;
    }

    ngOnInit() {}

    getBackgroundColor() {
        const hoveredColor = this.hovered ? hexColorToRgba(this.color, 0.08) : 'transparent';
        return this.selected ? hexColorToRgba(this.color, 0.16) : hoveredColor;
    }

    selectParticipant() {
        this.participantsService.blameUser(this.id, !this.selected);
    }
}
