import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { WebhookListService } from './webhook-list.service';
import { WebhookConfigurationListItem } from './webhooks.model';

@Injectable({
    providedIn: 'root',
})
export class GetWebhookByIdResolver implements Resolve<WebhookConfigurationListItem> {
    constructor(private webhookListService: WebhookListService, private router: Router) {}

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<WebhookConfigurationListItem> {
        const enterpriseId = route.paramMap.get('enterpriseId');
        const webhookId = route.paramMap.get('webhookId');

        const webhook = await this.webhookListService.getByKeys(enterpriseId, webhookId);
        if (webhook) {
            return webhook;
        } else {
            this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/webhooks`);
        }
    }
}
