import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStoreWrapper } from '../../../../common/appStoreWrapper.service';
import { FocusState, FOCUS_ACTION_TYPES } from '../../../../common/focus/focus.reducer';
import { ServiceHelper } from '../../../../common/serviceHelper';
import { componentHelper } from '../../../ui-components/ui-components.helper';
@Injectable({ providedIn: 'root' })
export class FocusService {
    readonly focusId$: Observable<FocusState>;

    constructor(private appStoreWrapper: AppStoreWrapper, private serviceHelper: ServiceHelper) {
        this.focusId$ = this.appStoreWrapper.focusState();
    }

    setFocus(focusId: string, previousFocusId: string) {
        this.serviceHelper.dispatchWithoutPersisting(FOCUS_ACTION_TYPES.SET_FOCUS, {
            focusId,
            previousFocusId,
        });
    }

    focusNextElement(el: ElementRef<HTMLElement>) {
        const nextElement = componentHelper.getKeyboardFocusableElements(el.nativeElement)[0];
        if (nextElement) {
            (nextElement as HTMLElement).focus();
        }
    }

    getFocusTargetTemplateName(el: ElementRef) {
        return el.nativeElement.tagName.toLowerCase();
    }
}
