import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterLogicalOperator } from '../../../../common/connectors/savedView/saved-views.model';
import { SelectOptionComponent } from '../../../ui-components/select/select-option/select-option.component';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-filter-operator-select',
    template: `
        <ruum-select
            [select]="selectedLogicalOperatorId$ | async"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [lightBackground]="false"
            [search]="showSearch"
            [placement]="placement"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-placeholder>
                {{ placeholder }}
            </ruum-select-placeholder>
            <ruum-select-option
                *ngFor="let logicalOperator of logicalOperators$ | async; trackBy: trackByOption"
                [value]="logicalOperator.id"
                [content]="logicalOperator.name"
            ></ruum-select-option>
        </ruum-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterOperatorSelectComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex', this.componentClass]);
    }

    @Input() set selectedLogicalOperatorId(value: string) {
        this.selectedLogicalOperatorIdSource.next(value);
    }

    get selectedLogicalOperatorId(): string {
        return this.selectedLogicalOperatorIdSource.getValue();
    }

    @Input() set logicalOperators(value: FilterLogicalOperator[]) {
        this.logicalOperatorsSource.next(value);
    }

    get logicalOperators(): FilterLogicalOperator[] {
        return this.logicalOperatorsSource.getValue();
    }

    @Input() placeholder = 'Select Logical Operator';
    @Input() showSearch = false;
    @Input() placement = ['bottom-left', 'top-left'];

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    get selectedLogicalOperatorId$(): Observable<string> {
        return this.selectedLogicalOperatorIdSource.asObservable();
    }
    get logicalOperators$(): Observable<FilterLogicalOperator[]> {
        return this.logicalOperatorsSource.asObservable();
    }

    private selectedLogicalOperatorIdSource = new BehaviorSubject('');
    private logicalOperatorsSource = new BehaviorSubject([]);

    constructor() {}

    ngOnInit() {}

    selectChange(event) {
        this.changed.emit(event);
    }

    trackByOption(index: number, item: SelectOptionComponent) {
        return item.value;
    }
}
