import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TaskListItem } from '../../../common/connectors/tasks/task-list.model';
import { ExtendedTask } from '../../../common/task-list/task-list-container.component';

@Component({
    selector: 'ruum-task-list-cell-subtasks',
    template: `
        <div class="ruum-task-subtasks d-flex justify-content-end align-items-center">
            <div *ngIf="task.children.length" class="d-flex pr-4 mr-4">
                <button class="btn btn-link-light px-0" (click)="toggleSubtasks($event)">
                    <i class="icon icon-subtask"></i>
                    <span class="pl-1 small">{{ task.children.length }}</span>
                    <i class="icon icon-cevron-down" *ngIf="task.children.length > 0 && subtasksAreVisible"></i>
                    <i class="icon icon-cevron-right" *ngIf="task.children.length > 0 && !subtasksAreVisible"></i>
                </button>
            </div>
        </div>
    `,
})
export class TaskListCellSubtasksComponent implements OnInit {
    @HostBinding() class = 'd-flex';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }
    @Input() showingSubtasks: (task: ExtendedTask) => boolean;

    @Output() subtasksVisibilityToggled = new EventEmitter<{ subtasksAreVisible: boolean; task: TaskListItem }>();

    task: any;
    subtasksAreVisible = false;

    constructor() {}

    ngOnInit() {
        this.task = this.data;
        this.subtasksAreVisible = this.showingSubtasks(this.task);
    }

    toggleSubtasks(event) {
        event.stopPropagation();
        const subtasksAreVisibleNewValue = !this.subtasksAreVisible;
        this.subtasksAreVisible = subtasksAreVisibleNewValue;

        this.subtasksVisibilityToggled.emit({ subtasksAreVisible: subtasksAreVisibleNewValue, task: this.task });
    }
}
