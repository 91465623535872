import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagModule } from '../../ui-components/tag/tag.module';
import { TaskListItemCustomFieldDateComponent } from './task-list-item-custom-field-date.component';
import { TaskListItemCustomFieldSelectionListComponent } from './task-list-item-custom-field-selection-list.component';
import { TaskListItemCustomFieldTextComponent } from './task-list-item-custom-field-text.component';
import { TaskListItemCustomFieldComponent } from './task-list-item-custom-field.component';

@NgModule({
    declarations: [
        TaskListItemCustomFieldComponent,
        TaskListItemCustomFieldTextComponent,
        TaskListItemCustomFieldDateComponent,
        TaskListItemCustomFieldSelectionListComponent,
    ],
    imports: [CommonModule, TagModule],
    exports: [TaskListItemCustomFieldComponent],
})
export class TaskListItemCustomFieldModule {}
