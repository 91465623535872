import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommentableType } from '@ruum/ruum-reducers';
import { Observable, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ShowCommentService } from '../../../shared/comments/showComment.service';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { ChatsListItem } from '../../connectors/chats/chat-list.model';
import { ChatListService } from '../../connectors/chats/chats-list.service';
import { SelectedProjectService } from '../../connectors/project/selectedProject.service';
import { NavbarService } from '../../navbar/navbar.service';
import { ServiceHelper } from '../../serviceHelper';

@Component({
    selector: 'ruum-project-comments-overview',
    template: `
        <ruum-sidepanel-container>
            <div class="d-lg-flex d-none flex-fill">
                <ruum-sidepanel-title>
                    <h3 class="interface py-2">
                        {{ (isInProject$ | async) !== true ? (title$ | async) : 'All Comments' }}
                    </h3>
                </ruum-sidepanel-title>
            </div>

            <div class="d-flex flex-fill px-4 pb-2">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend" ruumInputHighlight>
                        <span class="input-group-text">
                            <i class="icon icon-search"></i>
                        </span>
                    </div>
                    <input
                        class="form-control form-control-sm"
                        id="search"
                        name="search"
                        type="text"
                        ruumInputHighlight
                        [placeholder]="'Search'"
                        [(ngModel)]="searchQuery"
                        (keyup)="search()"
                    />
                    <div
                        *ngIf="searchQuery"
                        class="input-group-append"
                        ruumInputHighlight
                        (click)="clearSearch()"
                        (keydown.space)="clearSearch()"
                        (keydown.enter)="clearSearch()"
                    >
                        <span class="input-group-text">
                            <i class="icon icon-cancel"></i>
                        </span>
                    </div>
                </div>
            </div>

            <ruum-project-chats-overview-list
                class="d-flex flex-fill flex-column"
                [chatsListItems]="projectChatItem$ | async"
                [isProjectChat]="true"
                [searchQuery]="searchQuery"
                (openComment)="onOpenComment($event)"
                (openChat)="openProjectChat()"
            >
            </ruum-project-chats-overview-list>
            <ruum-project-chats-overview-list
                class="d-flex flex-fill flex-column"
                [chatsListItems]="objectChatItems$ | async"
                [isProjectChat]="false"
                (openComment)="onOpenComment($event)"
                (openChat)="openProjectChat()"
            >
            </ruum-project-chats-overview-list>
            <ruum-load-more *ngIf="showLoading$ | async" (loadMore)="loadMore()"></ruum-load-more>
        </ruum-sidepanel-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsOverviewComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill h-100';
    projectChatItem$: Observable<ChatsListItem[]>;
    objectChatItems$: Observable<ChatsListItem[]>;
    searchQuery = '';
    showLoading$: Observable<boolean>;
    title$: Observable<string>;
    isInProject$: Observable<boolean>;
    subscriptions: Subscription[] = [];
    projectId: string;

    constructor(
        private chatListService: ChatListService,
        private navbarService: NavbarService,
        private route: ActivatedRoute,
        private appStoreWrapper: AppStoreWrapper,
        private ruumCommentsService: ShowCommentService,
        private serviceHelper: ServiceHelper,
        private router: Router,
        private selectedProjectService: SelectedProjectService,
    ) {}

    ngOnInit() {
        if (this.route.snapshot.params.hasOwnProperty('templateId')) {
            this.projectId = this.selectedProjectService.getSelectedProjectId();
        } else {
            this.projectId = this.route.snapshot.params.projectId;
        }
        this.loadTitle();

        this.isInProject$ = this.appStoreWrapper.selectedRuum().pipe(map((project) => (project.id ? true : false)));
        this.chatListService.reload();
        this.projectChatItem$ = this.chatListService.projectChat();
        this.objectChatItems$ = this.chatListService.rows();
        this.chatListService.resetFilters();
        this.showLoading$ = this.chatListService.getShowLoading();
        this.navbarService.setProjectId(this.projectId);
        const subscription = this.title$.subscribe((title) => this.navbarService.setBackButtonTitile(title));
        this.subscriptions.push(subscription);
    }

    loadTitle() {
        const subscription = this.route.params
            .pipe(
                filter((routerParams: Params) => Boolean(routerParams.projectId)),
                map<Params, string>((routerParams: Params) => routerParams.projectId),
                switchMap(
                    (projectId) =>
                        (this.title$ = this.appStoreWrapper
                            .lobbyList()
                            .pipe(
                                concatMap((list) =>
                                    list.rows.filter((item) => item.id === projectId).map((item) => item.name),
                                ),
                            )),
                ),
                distinctUntilChanged(),
            )
            .subscribe();
        this.subscriptions.push(subscription);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.navbarService.setProjectId(null);
        this.navbarService.setBackButtonTitile(null);
        this.chatListService.stopLoading();
    }

    search() {
        this.chatListService.filterByText(this.searchQuery);
    }

    clearSearch() {
        this.searchQuery = '';
        this.chatListService.filterByText(undefined);
    }

    loadMore() {
        this.chatListService.maybeGoToNextPage();
    }

    onOpenComment(chatsListItem: ChatsListItem) {
        if (chatsListItem.objectType === 'project') {
            this.openProjectChat();
        } else {
            const routeContext = this.route.parent.snapshot.url[0].path;
            if (this.isInLobby(routeContext)) {
                this.openCommentFromLobby(chatsListItem.projectId, chatsListItem.objectType, chatsListItem.objectId);
            } else {
                this.ruumCommentsService.showCommentsOfObject(chatsListItem.objectId, chatsListItem.objectType);
            }
            setTimeout(() => this.serviceHelper.scrollToNodeId('comment', chatsListItem.commentId, false), 500);
            setTimeout(() => this.serviceHelper.scrollToNodeId('canvas', chatsListItem.objectId, true), 2000);
        }
    }

    openCommentFromLobby(projectId: string, objectType: CommentableType, objectId: string) {
        return this.router.navigate(
            [{ outlets: { sidepanel: ['projects', projectId, `${objectType}s`, objectId, 'comments'] } }],
            {
                relativeTo: this.route.parent,
            },
        );
    }

    openProjectChat() {
        const routeContext = this.route.parent.snapshot.url[0].path;
        let projectId: string;
        if (this.isInLobby(routeContext)) {
            projectId = this.route.snapshot.params.projectId;
        } else {
            projectId = this.projectId;
        }
        this.router.navigate([{ outlets: { sidepanel: ['projects', projectId, 'comments'] } }], {
            relativeTo: this.route.parent,
        });
    }

    isInLobby(routeContext) {
        return routeContext === 'home' || routeContext.includes('workspace') || routeContext.includes('projectgroup');
    }
}
