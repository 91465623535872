import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';
import { ExternalSystemStore } from '../../../common/connectors/externalSystem/externalSystem.store';
import { SelectedWorkspaceService } from '../../../common/connectors/workspace/selected-workspace.service';
import { ExternalSystemActionsService } from '../../external-system/external-system-actions.service';
import { SystemSelectListLoader } from './system-select.listloader';

@Component({
    selector: 'ruum-system-select',
    template: `
        <ruum-select-controller
            placeholder="Select the system you want to connect with..."
            [select]="select"
            [disabled]="disabled"
            [storeLoader]="storeLoader"
            [listLoader]="listLoader"
            [actionOptions]="actionOptions"
            [withUnselect]="false"
            (actionItemClick)="addNewSystem($event)"
            (selectChange)="onSelectChange($event)"
            (searchChange)="onSearchChange($event)"
        ></ruum-select-controller>
    `,
    providers: [
        SystemSelectListLoader, // dont remove this, because every custom field select needs his own
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SystemSelectComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @HostBinding('class') hostClassList = 'd-block w-100 mw-100';

    @Input() select: string;
    @Input() disabled = false;

    @Output() selectChange = new EventEmitter<string>();
    @Output() selectDataChange = new EventEmitter<any>();

    actionOptions = [
        {
            id: 'ADD_SYSTEM',
            title: 'Add a System',
        },
    ];

    storeLoader: ExternalSystemStore;
    listLoader: SystemSelectListLoader;

    private onChangeFunction;
    private onTouchedFunction;

    constructor(
        private externalSystemStore: ExternalSystemStore,
        private externalSystemActionsService: ExternalSystemActionsService,
        private systemSelectListLoader: SystemSelectListLoader,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.systemSelectListLoader.setWorkspaceId(this.selectedWorkspaceService.getSelectedWorkspaceId()); // TODO: set undefined
        this.systemSelectListLoader.setEnterpriseId(this.selectedEnterpriseService.getSelectedEnterpriseId());
    }

    ngOnInit() {
        this.storeLoader = this.externalSystemStore;
        this.listLoader = this.systemSelectListLoader;
    }

    ngOnDestroy(): void {
        this.systemSelectListLoader.ngDestroy();
    }

    writeValue(obj: any): void {
        this.select = obj;
        this.selectChange.emit(this.select);
        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFunction = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onSelectChange(event: string) {
        if (this.select === event) {
            return;
        }
        if (this.onChangeFunction) {
            this.onTouchedFunction();
            this.onChangeFunction(event);
        }
        this.selectChange.emit(event);
    }

    onSearchChange(search: string) {
        this.systemSelectListLoader.search(search);
    }

    addNewSystem(event) {
        if (event === 'ADD_SYSTEM') {
            this.externalSystemActionsService.createExternalSystem();
        }
    }
}
