import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { CustomFieldSelectComponent } from './custom-field-select.component';

@NgModule({
    declarations: [CustomFieldSelectComponent],
    imports: [CommonModule, SelectModule],
    exports: [CustomFieldSelectComponent],
})
export class CustomFieldSelectModule {}
