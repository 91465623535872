import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from '../../../common/common.service';
import { FeatureFlagService } from '../../../common/connectors/featureFlags/featureFlags.service';
import { WorkspaceListItemService } from '../../../common/connectors/workspace/workspace-list-item.service';
import { WorkspaceListService } from '../../../common/connectors/workspace/workspace-list.service';
import {
    filterDropdownList,
    RuumDropdownItem,
    RuumDropdownItemFilters,
    RuumDropdownSectionList,
} from '../../../shared/ui-components/dropdown/dropdown.model';
import { WorkspaceRenameComponent } from '../workspace-rename/workspace-rename.component';

const RENAME_WORKSPACE = 'RENAME_WORKSPACE';
const MANAGE_CUSTOM_FIELDS = 'MANAGE_CUSTOM_FIELDS';
const MANAGE_SYSTEM_CONNECTORS = 'MANAGE_SYSTEM_CONNECTORS';
const MANAGE_FUNCITONAL_ROLES = 'MANAGE_FUNCITONAL_ROLES';

@Component({
    selector: 'ruum-workspace-menu',
    template: `
        <ruum-dropdown
            *ngIf="!showNewMenu"
            [placement]="placement"
            [sections]="sections$ | async"
            [width]="'250px'"
            (selectItem)="onSelect($event)"
        >
            <button
                class="btn btn-icon"
                data-content="ruum-dropdown-button-desktop"
                aria-label="Show workspace specific options"
                type="button"
                [class.btn-link-secondary]="theme === 'secondary'"
                [class.btn-link-white-64]="theme === 'white-64'"
                [class.btn-sm]="size === 'sm'"
                [class.btn-xs]="size === 'xs'"
                [attr.tabindex]="tabindex"
            >
                <i class="icon" [ngClass]="icon"></i>
            </button>
            <button
                class="btn btn-icon btn-link-{{ theme }}"
                data-content="ruum-dropdown-button-mobile"
                type="button"
                [class.btn-link-secondary]="theme === 'secondary'"
                [class.btn-link-white-64]="theme === 'white-64'"
                [class.btn-sm]="size === 'sm'"
                [class.btn-xs]="size === 'xs'"
                [attr.tabindex]="tabindex"
            >
                <i class="icon" [ngClass]="icon"></i>
            </button>
        </ruum-dropdown>

        <ruum-menu *ngIf="showNewMenu" [placement]="placement" [width]="'250px'">
            <ruum-menu-trigger>
                <button
                    class="btn btn-icon"
                    data-content="ruum-dropdown-button-desktop"
                    aria-label="Show workspace specific options"
                    type="button"
                    [class.btn-link-secondary]="theme === 'secondary'"
                    [class.btn-link-white-64]="theme === 'white-64'"
                    [class.btn-sm]="size === 'sm'"
                    [class.btn-xs]="size === 'xs'"
                    [attr.tabindex]="tabindex"
                >
                    <i class="icon" [ngClass]="icon"></i>
                </button>
            </ruum-menu-trigger>
            <ng-container *ngFor="let section of sections$ | async; let last = last">
                <ng-container *ngFor="let option of section">
                    <ruum-menu-option
                        (click)="onSelect(option)"
                        [theme]="option.theme"
                        [icon]="option.icon"
                        [disabled]="option.disabled"
                        [disableTooltip]="!option.disabled"
                        [ngbTooltip]="option.tooltip?.title"
                    >
                        {{ option.name }}
                    </ruum-menu-option>
                </ng-container>
                <ruum-menu-devider *ngIf="!last"></ruum-menu-devider>
            </ng-container>
        </ruum-menu>
    `,
})
export class WorkspaceMenuComponent implements OnInit, OnChanges {
    showNewMenu = true;

    @Input() workspaceId: string;
    @Input() workspaceName: string;
    @Input() isAdmin: boolean;
    @Input() placement: string | string[] = ['bottom'];
    @Input() icon = 'icon-contextual-menu';
    @Input() theme = 'secondary';
    @Input() size = 'sm';
    @Input() tabindex = '0';

    @Output() workspaceNameChanged = new EventEmitter();

    sections$: Observable<RuumDropdownSectionList>;
    isAdminChanged$ = new Subject();

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private commonService: CommonService,
        private workspaceListItemService: WorkspaceListItemService,
        private workspaceListService: WorkspaceListService,
        private featureFlagsService: FeatureFlagService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isAdmin && changes.isAdmin.currentValue !== changes.isAdmin.previousValue) {
            this.isAdminChanged$.next();
        }
    }

    ngOnInit() {
        const editOptions = [{ id: RENAME_WORKSPACE, name: 'Rename Workspace', icon: 'icon-edit' }];

        const defaultConfigureOptions = [
            { id: MANAGE_CUSTOM_FIELDS, name: 'Manage Custom Fields', icon: 'icon-custom-fields' },
            { id: MANAGE_FUNCITONAL_ROLES, name: 'Manage Roles', icon: 'icon-role' },
        ];
        const extendedConfigureOptions = [
            ...defaultConfigureOptions,
            { id: MANAGE_SYSTEM_CONNECTORS, name: 'Manage System Connectors', icon: 'icon-connector' },
        ];
        this.sections$ = combineLatest([
            this.featureFlagsService.isExternalSystemsEnabled(this.workspaceId),
            this.workspaceListService.getWorkspaceListItemById(this.workspaceId),
            this.isAdminChanged$.pipe(startWith(false)),
        ]).pipe(
            map(([isEnabled, workspaceListItem]) => {
                const configureOptions =
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    isEnabled && workspaceListItem && !!workspaceListItem['enterpriseId']
                        ? extendedConfigureOptions
                        : defaultConfigureOptions;
                return [editOptions, configureOptions];
            }),
            map((allOptions) => {
                const dropdownSettings = this.getDropdownSettings();
                return allOptions
                    .map((options) => {
                        return filterDropdownList(options, dropdownSettings);
                    })
                    .filter((options) => options.length);
            }),
        );
    }

    onSelect(item: RuumDropdownItem): void {
        switch (item.id) {
            case RENAME_WORKSPACE:
                return this.renameWorkspace();
            case MANAGE_CUSTOM_FIELDS:
                return this.goToWorkspaceSettings('custom-fields');
            case MANAGE_FUNCITONAL_ROLES:
                return this.goToWorkspaceSettings('functional-roles');
            case MANAGE_SYSTEM_CONNECTORS:
                return this.goToWorkspaceSettings('system-connectors');
        }
    }

    getDropdownSettings(): RuumDropdownItemFilters {
        return [
            {
                id: RENAME_WORKSPACE,
                isDisabled: () => !this.isAdmin,
                disabledTooltip: { title: 'Only Workspace Admins can do it.', placement: ['left', 'right'] },
            },
            {
                id: MANAGE_CUSTOM_FIELDS,
                isDisabled: () => !this.isAdmin,
                disabledTooltip: { title: 'Only Workspace Admins can do it.', placement: ['left', 'right'] },
            },
            {
                id: MANAGE_SYSTEM_CONNECTORS,
                isDisabled: () => !this.isAdmin,
                disabledTooltip: { title: 'Only Workspace Admins can do it.', placement: ['left', 'right'] },
            },
            {
                id: MANAGE_FUNCITONAL_ROLES,
                isDisabled: () => !this.isAdmin,
                disabledTooltip: { title: 'Only Ruum Admins can do it.', placement: ['left', 'right'] },
            },
        ];
    }

    private renameWorkspace() {
        const modalRef: NgbModalRef = this.modalService.open(WorkspaceRenameComponent);
        modalRef.componentInstance.name = this.workspaceName;
        modalRef.result.then(
            (name: string) => {
                this.commonService.setBusy(true);
                return this.workspaceListItemService.renameWorkspace(this.workspaceId, name).then(
                    () => {
                        // when callback is passed for renaming workapce
                        if (this.workspaceNameChanged.observers.length > 0) {
                            this.workspaceNameChanged.emit();
                        }

                        this.commonService.setBusy(false);
                    },
                    () => this.commonService.setBusy(false),
                );
            },
            () => {},
        );
    }

    private goToWorkspaceSettings(innerLink: string): void {
        this.router.navigate(['workspace-settings', this.workspaceId, innerLink]);
    }
}
