import { ProjectAction, Ruum } from '@ruum/ruum-reducers';

export const PROJECT_WEB_APP_ACTION_TYPES = {
    SELECT_PROJECT: 'SELECT_PROJECT',
    BLAME_USER: 'BLAME_USER',
    HIGHLIGHT_UNSEEN_CANVAS_CHANGES: 'HIGHLIGHT_UNSEEN_CANVAS_CHANGES',
    SET_CANVAS_HAS_UNSEEN_CHANGES: 'SET_CANVAS_HAS_UNSEEN_CHANGES',
    SHOW_IMAGE_PREVIEW: 'SHOW_IMAGE_PREVIEW',
    HIDE_IMAGE_PREVIEW: 'HIDE_IMAGE_PREVIEW',
};

export type SelectProjectAction = ProjectAction<'SELECT_PROJECT', Ruum>;

export type BlameUserAction = ProjectAction<
    'BLAME_USER',
    {
        userId: string;
        blame: boolean;
    }
>;

export type HighlightUnseenCanvasChangesAction = ProjectAction<
    'HIGHLIGHT_UNSEEN_CANVAS_CHANGES',
    {
        shouldHighlight: boolean;
    }
>;

export type SetCanvasHasUnseenChangesAction = ProjectAction<
    'SET_CANVAS_HAS_UNSEEN_CHANGES',
    {
        hasChanges: boolean;
    }
>;

export type ShowImagePreviewAction = ProjectAction<
    'SHOW_IMAGE_PREVIEW',
    {
        imagePreviewId: string;
    }
>;

export type HideImagePreviewAction = ProjectAction<'HIDE_IMAGE_PREVIEW', {}>;
