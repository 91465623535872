import { editItemWithId, removeItemWithId, RuumAction } from '@ruum/ruum-reducers';
import { LOBBY_LIST_ACTIONS } from '../../../lobby/shared/lobby-list/lobby-list.actions';
import { ACTION_TYPES } from '../../actions';
import { PaginatedList } from '../paginatedList.model';
import { LobbyListItem } from './lobby-list.model';

export function LobbyListReducer(
    currentState: PaginatedList<LobbyListItem>,
    action: RuumAction,
): PaginatedList<LobbyListItem> {
    if (!LOBBY_LIST_ACTIONS[action.type]) {
        return currentState;
    }

    switch (action.type) {
        case LOBBY_LIST_ACTIONS.LOBBY_LIST_LOADED:
            return ruumsLoaded(currentState, action.payload.list);
        default:
            return {
                ...currentState,
                rows: LobbyListItemReducer(currentState.rows, action),
            };
    }
}

function ruumsLoaded(
    currentState: PaginatedList<LobbyListItem>,
    data: PaginatedList<LobbyListItem>,
): PaginatedList<LobbyListItem> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return data;
    }
}

export function LobbyListItemReducer(currentState: LobbyListItem[] = [], action: RuumAction): LobbyListItem[] {
    switch (action.type) {
        case LOBBY_LIST_ACTIONS.DELETE_USER_FROM_ITEM:
            return removeItemWithId(currentState, action.payload);
        case LOBBY_LIST_ACTIONS.RENAME_LIST_ITEM:
            return editItemWithId(currentState, action.payload.itemId, {
                name: action.payload.name,
                description: action.payload.description,
            });
        case LOBBY_LIST_ACTIONS.MOVE_PROJECT_TO_GROUP:
            currentState = removeItemWithId(currentState, action.payload.projectId);
            return increaseGroupNumberOfProjects(currentState, action);
        case LOBBY_LIST_ACTIONS.ADD_TAG_TO_ITEM:
        case LOBBY_LIST_ACTIONS.REMOVE_TAG_FROM_ITEM:
            return updateRuumTags(currentState, action);
        case LOBBY_LIST_ACTIONS.SET_ITEM_STATUS:
            return editItemWithId(currentState, action.payload.itemId, { status: action.payload.statusId });
        case LOBBY_LIST_ACTIONS.ARCHIVE_ITEM:
            return removeItemWithId(currentState, action.payload.itemId);
        case LOBBY_LIST_ACTIONS.UNARCHIVE_ITEM:
            return removeItemWithId(currentState, action.payload.itemId);
        case LOBBY_LIST_ACTIONS.SET_AS_FAVORITE:
            return currentState.map((ruum) => {
                if (ruum.id === action.payload.itemId) {
                    return {
                        ...ruum,
                        favorite: action.payload.favorite,
                    };
                } else {
                    return ruum;
                }
            });
        default:
            return currentState;
    }
}

function updateRuumTags(currentState: LobbyListItem[], action: RuumAction): any[] {
    const itemId: string = action.payload.itemId;
    const tag: string = action.payload.tag;
    const addingTag: boolean = action.type === ACTION_TYPES.ADD_TAG_TO_ITEM;
    if (!itemId || !tag) {
        return currentState;
    }
    const ruum: LobbyListItem = currentState.find((currentStateRuum) => currentStateRuum.id === itemId);
    if (!ruum) {
        return currentState;
    }
    const ruumTags: string[] = ruum.tags || [];
    let newTags: string[];
    if (addingTag) {
        newTags = [...(ruumTags || []), tag];
    } else {
        const tagIndex: number = ruumTags.findIndex((oldTag) => oldTag === tag);
        if (tagIndex !== -1) {
            newTags = [...ruumTags.slice(0, tagIndex), ...ruumTags.slice(tagIndex + 1)];
        } else {
            newTags = ruumTags;
        }
    }
    return editItemWithId(currentState, itemId, {
        tags: newTags,
    });
}

function increaseGroupNumberOfProjects(currentState: any[], action: RuumAction) {
    return currentState.map((item) => {
        if (item.id !== action.payload.groupId) {
            return { ...item };
        } else {
            return { ...item, numberOfProjects: item.numberOfProjects + 1 };
        }
    });
}
