import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectService } from '../select.service';

@Component({
    selector: 'ruum-select-none',
    template: `
        <span class="text-light">None</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectNoneComponent implements OnInit {
    @HostBinding('class')
    hostClassList = 'ruum-select-option align-items-center';

    @HostBinding('class.disabled')
    @Input()
    disabled = false;

    @HostBinding('style.margin-bottom.px') marginBottom = 2;

    private ngOnDestroy$ = new Subject<void>();

    constructor(private selectService: SelectService, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.selectService.search$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((search) => {
            const currentHostClass = this.hostClassList;
            if (!search) {
                this.hostClassList = 'ruum-select-option';
            } else {
                this.hostClassList = 'd-none';
            }
            if (currentHostClass !== this.hostClassList) {
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    @HostListener('click', ['$event'])
    onMenuClick(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.selectService.reset();
        this.selectService.onEvent(event);
    }
}
