import { getValueRecordKeyStrings, TableRow } from '@ruum/ruum-reducers';
import { Observable, of } from 'rxjs';
import { map, switchAll } from 'rxjs/operators';
import { TableDataRecordStore } from '../../../../../common/connectors/tableData/tableDataRecords.store';
import { CustomFieldRecordData } from './getCustomFieldRecordData';

export function listenToSelectedRecordChange(
    tableDataRecordStore: TableDataRecordStore,
    data$: Observable<CustomFieldRecordData>,
): Observable<TableDataRecordListItemDisplay[]> {
    return data$.pipe(
        map(({ currentValue, isAuthenticated, tableDefinition }) => {
            if (isAuthenticated) {
                const keys = getValueRecordKeyStrings(currentValue, tableDefinition);
                return tableDataRecordStore.dataList(keys).pipe(
                    map((records) => {
                        const titleColumn = tableDefinition.columns.find((column) => column.isTitle);
                        return records.map((record) => ({
                            ...record,
                            displayText: record[titleColumn.name],
                        }));
                    }),
                );
            } else {
                return of([]);
            }
        }),
        switchAll(),
    );
}

export interface TableDataRecordListItemDisplay extends TableRow {
    displayText: string;
}
