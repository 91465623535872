import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthBackendConnector } from '../authServiceConnector.service';
import { FeatureFlagId, FeatureFlags, FeatureFlagType } from './featureFlags.model';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;
    private readonly permanentFlags: FeatureFlags = {};
    private shortTermFlags: { [key: string]: BehaviorSubject<boolean> } = {
        /** `feature_` prefix is required */
        feature_template_library_v2: new BehaviorSubject(false),
        feature_transition_notification_message: new BehaviorSubject(false),
        feature_process_section_roles: new BehaviorSubject(false),
    };

    constructor(
        private http: HttpClient,
        private backendConnector: AuthBackendConnector,
        private activatedRoute: ActivatedRoute,
    ) {
        this.observeRouteQueryParams();
    }

    getFeatureFlag(flagId: FeatureFlagId): Observable<FeatureFlagType> {
        if (this.shortTermFlags[flagId]) {
            return this.shortTermFlags[flagId].asObservable();
        }

        if (!this.permanentFlags[flagId]) {
            this.permanentFlags[flagId] = this.http
                .get<FeatureFlagType>(`${this.PROJECT_SERVICE_URL}/v1/featureflags/${flagId}`, {
                    withCredentials: true,
                })
                .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)), shareReplay(1));
        }
        return this.permanentFlags[flagId];
    }

    isExternalSystemsEnabled(workspaceId: string): Observable<boolean> {
        return this.stringListFeatureFlag('external_system_workspace_white_list', workspaceId);
    }

    getIsExternalSystemsEnabled(workspaceId: string): boolean {
        return this.getStringListFlag('process_workspace_white_list', workspaceId);
    }

    isProcessEnabled(workspaceId: string): Observable<boolean> {
        return this.stringListFeatureFlag('process_workspace_white_list', workspaceId);
    }

    getIsProcessEnabled(workspaceId: string): boolean {
        return this.getStringListFlag('process_workspace_white_list', workspaceId);
    }

    /**
     * If 'id' is inside the string list of the feature flag 'flag'
     */
    private getStringListFlag(flag: FeatureFlagId, id: string): boolean {
        let val: boolean;
        this.stringListFeatureFlag(flag, id)
            .pipe(take(1))
            .subscribe((v) => (val = v));
        return val;
    }

    /**
     * If 'id' is inside the string list of the feature flag 'flag'
     */
    private stringListFeatureFlag(flag: FeatureFlagId, id: string): Observable<boolean> {
        if (!id) {
            return of(false);
        }
        return this.getFeatureFlag(flag).pipe(
            map((whiteList: string[]) => whiteList.indexOf(id) !== -1),
            catchError((err) => of(false)),
        );
    }

    /**
     * If 'str' matches one of the regex of the feature flag 'flag'
     */
    private regexListFeatureFlag(flag: FeatureFlagId, str: string): Observable<boolean> {
        if (!str) {
            return of(false);
        }
        return this.getFeatureFlag(flag).pipe(
            map((allowList: string[]) => allowList.some((regex) => RegExp(regex).test(str))),
            catchError((err) => of(false)),
        );
    }

    private observeRouteQueryParams() {
        this.activatedRoute.queryParams
            .pipe(
                map((queryParams) => {
                    const queryParamKeys = Object.keys(queryParams).filter((key) => !!key.match('feature_'));
                    queryParamKeys.forEach((key) => {
                        if (this.shortTermFlags[key]) {
                            this.shortTermFlags[key].next(queryParams[key] === 'true');
                        }
                    });
                }),
            )
            .subscribe();
    }
}
