import { Injectable } from '@angular/core';
import { PaywallRejectStrategy } from '../paywall-reject-strategy';

@Injectable()
export class NoopRejectStrategy implements PaywallRejectStrategy {
    readonly id = 'noop';

    onRejectInitial() {}

    onRejectHostDestroy() {}

    onRejectTrigger() {}
}
