import { PaywallPrivilege } from '../../../paywall/paywall-privilege';
import { PaywallRejectStrategyId } from '../../../paywall/paywall-reject-strategy';
export type RuumDropdownItemDefaultTheme =
    | 'primary'
    | 'secondary'
    | 'light'
    | 'danger'
    | 'warning'
    | 'info'
    | 'success'
    | 'accent'
    | 'dark';
export type RuumDropdownItemLightTheme =
    | 'danger-light'
    | 'warning-light'
    | 'info-light'
    | 'success-light'
    | 'accent-light';
export type RuumDropdownItemSize = 'xs' | 'sm' | 'md' | 'lg';
export type RuumDropdownItemTheme = RuumDropdownItemDefaultTheme | RuumDropdownItemLightTheme;
type Placement =
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-top'
    | 'left-bottom'
    | 'right'
    | 'right-top'
    | 'right-bottom'
    | 'auto';
export type RuumDropdownPlacement = Placement | Placement[];

export interface RuumDropdownItemTooltip {
    placement: RuumDropdownPlacement;
    title: string;
    description?: string;
}

export interface RuumDropdownItem {
    id: any;
    name: string;
    description?: string;
    icon?: string;
    theme?: RuumDropdownItemTheme;
    tooltip?: RuumDropdownItemTooltip;
    disabled?: boolean;
    hidden?: boolean;
    iconSide?: 'left' | 'right';

    paywallPrivilege?: PaywallPrivilege;
    paywallRejectStrategy?: PaywallRejectStrategyId;
}

// dropdown items with the special id 'ruum-dropdown_unselect' might be added anywhere to the dropdown list.
// the purpose of the unselect dropdown item is to reset the dropdown component to no value.
// this special dropdown item 'UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE' might be used as it is, or as base for own "unselect items"
export const UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE: RuumDropdownItem = {
    id: 'ruum-dropdown_unselect', // required to stay as it is
    name: 'None', // might be changed
};

export function isUnselectDropdownItem(dropdownItem: RuumDropdownItem): boolean {
    return dropdownItem.id === UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE.id;
}

export type RuumDropdownList = RuumDropdownItem[];

export type RuumDropdownSectionList = RuumDropdownList[]; // @deprecated
export type RuumDropdownSections = RuumDropdownList[]; // @deprecated

export interface RuumDropdownItemFilter {
    id: string;
    isHidden?: () => boolean;
    isDisabled?: () => boolean;
    disabledTooltip?: RuumDropdownItemTooltip;
}

export type RuumDropdownItemFilters = RuumDropdownItemFilter[];

/**
 *
 * Receives a RuumDropdownList and filters it and/or sets the 'disabled', 'hidden' and 'tooltip' property based on the list of RuumDropdownItemFilter provided.
 * If there is more than one RuumDropdownItemFilters for a given ID, it will give precedence to the first one where 'isDisabled()' or 'isHidden()' returns true.
 */
export const filterDropdownList = (list: RuumDropdownList, filters: RuumDropdownItemFilters) => {
    return list
        .map((item) => {
            const disabledFilter = filters.find((f) => f.id === item.id && isDisabledOrHidden(f));
            if (disabledFilter) {
                return getItem(item, disabledFilter);
            } else {
                const filter = filters.find((f) => f.id === item.id);
                if (filter) {
                    return getItem(item, filter);
                } else {
                    return item;
                }
            }
        })
        .filter((item) => !item.hidden);
};

function getItem(item: RuumDropdownItem, filter: RuumDropdownItemFilter): RuumDropdownItem {
    const newItem = {
        ...item,
        disabled: !!(filter.isDisabled && filter.isDisabled()),
        hidden: !!(filter.isHidden && filter.isHidden()),
    };

    if (newItem.disabled && filter.disabledTooltip) {
        newItem.tooltip = filter.disabledTooltip;
    }

    return newItem;
}

function isDisabledOrHidden(filter: RuumDropdownItemFilter): boolean {
    return (filter.isDisabled && filter.isDisabled()) || (filter.isHidden && filter.isHidden());
}
