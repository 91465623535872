import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SelectService } from '../select.service';

@Component({
    selector: 'ruum-select-popover-mobile',
    template: `
        <div class="popover-content-wrapper mh-100 rounded px-2">
            <div class="d-flex align-items-center py-2 pr-2 pl-4 bg-white first-child">
                <div class="d-flex flex-fill minw-0 align-middle mr-2">
                    <span class="text-tiny text-uppercase text-truncate text-dark">{{ selectTitle }}</span>
                </div>
                <button class="btn btn-lg btn-primary" (click)="doneClick()">Done</button>
            </div>
            <div class="bg-white pb-2">
                <ng-container *ngIf="isMultiSelect">
                    <ng-content select="ruum-select-tag-list"></ng-content>
                </ng-container>
                <div *ngIf="isSearch">
                    <ng-content select="ruum-select-search"></ng-content>
                </div>
            </div>
            <div class="bg-white popover-options-container p-2 last-child border-top border-secondary">
                <ng-template [cdkPortalOutlet]="optionsTempaltePortal"></ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPopoverMobileComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block shadow rounded py-2 w-100 popover-container is-mobile';

    @HostBinding('class.multi-select')
    @Input()
    isMultiSelect = false;

    @Input() isSearch = false;

    @Input() selectTitle: string;

    @Input() optionsTempaltePortal: TemplatePortal;

    @Output() done = new EventEmitter<void>();

    constructor(private selectService: SelectService) {}

    ngOnInit() {}

    @HostListener('document:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown') {
            this.selectService.focusNextItem();
        } else if (event.key === 'ArrowUp') {
            this.selectService.focusPrevItem();
        }
    }

    doneClick() {
        this.done.emit();
    }
}
