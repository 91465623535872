import { Inject, InjectionToken, RendererFactory2 } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { RuumPopupService } from '../../common/popup/ruum-popup-container.service';
import {
    PaywallRejectStrategy,
    PaywallRejectStrategyContext,
    PaywallRejectStrategyData,
} from '../paywall-reject-strategy';
import { TrialService, TrialStatus } from '../trial/trial.service';
import { UpgradeDialogComponent } from '../upgrade-dialog.component';

export const FALLBACK_PAYWALL_REJECT_STRATEGY = new InjectionToken<string>('ruum-fallbackPaywallRejectStrategy');

export interface BasicRejectStrategyData extends PaywallRejectStrategyData {
    featureName: string;
}

export class BasicRejectStrategy implements PaywallRejectStrategy {
    static readonly STYLE_CLASS = 'ruum-paid-feature';

    readonly id = 'basic';

    private subscription = new Subscription();
    private currentTrialStatus?: TrialStatus;

    constructor(
        private trialService: TrialService,
        private rendererFactory: RendererFactory2,
        private popupService: RuumPopupService,
        private modalService: NgbModal,
        @Inject(FALLBACK_PAYWALL_REJECT_STRATEGY) private fallbackRejectStrategy: PaywallRejectStrategy,
    ) {}

    onRejectInitial(context: PaywallRejectStrategyContext) {
        const trackTrialStatusSubscription = this.trialService.trackTrialStatus().subscribe((trialStatus) => {
            this.currentTrialStatus = trialStatus;
            if (!this.isSupportedTrialStatus()) {
                this.fallbackRejectStrategy.onRejectInitial(context);
            }
        });
        this.subscription.add(trackTrialStatusSubscription);
        this.addStyleClass(context);
    }

    onRejectHostDestroy(context: PaywallRejectStrategyContext) {
        if (!this.isSupportedTrialStatus()) {
            this.fallbackRejectStrategy.onRejectHostDestroy(context);
        }
        // TODO: maintain the subscribtions
        // this.subscription.unsubscribe();
        this.removeStyleClass(context);
    }

    onRejectTrigger(context: PaywallRejectStrategyContext, event?: Event) {
        this.popupService.closePopup();
        this.modalService.dismissAll();
        if (this.currentTrialStatus.status === 'not_available') {
            this.showUpgradePage(context);
        } else {
            this.fallbackRejectStrategy.onRejectTrigger(context, event);
        }
    }

    private showUpgradePage(context: PaywallRejectStrategyContext): void {
        const modalRef: NgbModalRef = this.modalService.open(UpgradeDialogComponent, {
            centered: true,
            size: 'lg',
        });
        modalRef.componentInstance.featureName = context.rejectStrategyData && context.rejectStrategyData.featureName;
    }

    // is the current trial status handled by this reject strategy or should we forward to the fallback reject strategy?
    private isSupportedTrialStatus(): boolean {
        if (!this.currentTrialStatus) {
            return false;
        }

        return this.currentTrialStatus.status === 'not_available';
    }

    private addStyleClass(context: PaywallRejectStrategyContext): void {
        if (!context.elementRef) {
            return;
        }

        const renderer = this.rendererFactory.createRenderer(context.elementRef.nativeElement, null);
        renderer.addClass(context.elementRef.nativeElement, BasicRejectStrategy.STYLE_CLASS);
    }

    private removeStyleClass(context: PaywallRejectStrategyContext): void {
        if (!context.elementRef) {
            return;
        }

        const renderer = this.rendererFactory.createRenderer(context.elementRef.nativeElement, null);
        renderer.addClass(context.elementRef.nativeElement, BasicRejectStrategy.STYLE_CLASS);
    }
}
