import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { BaseFormComponent } from '../common/components/form.component';
import { ModalService } from '../common/modal/modal.service';
import { TechnicalUserListService } from './technical-user-list.service';

@Component({
    selector: 'ruum-admin-edit-technical-user',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <div class="header">
                    <nav class="navbar navbar-light navbar-expand-lg">
                        <h3 class="ml-3 navbar-text">Maintain Technical User</h3>

                        <button
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-outline-danger position-absolute right-0"
                            (click)="delete()"
                        >
                            Delete Account
                        </button>
                    </nav>
                </div>

                <div class="body">
                    <technical-user-form [form]="form"></technical-user-form>
                </div>

                <div class="footer">
                    <button class="btn btn-primary float-right" (click)="save()">Save</button>
                    <button class="btn btn-outline-secondary float-right" (click)="back()">Cancel</button>
                </div>
            </div>
            <ruum-max-content> </ruum-max-content
        ></ruum-max-content>
    `,
    styles: [``],
})
export class AdminEditTechnicalUserComponent extends BaseFormComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList =
        'd-flex flex-column w-100 mw-100 h-100 mh-100 px-2 px-sm-4 px-md-5 px-lg-8 overflow-y';

    isCurrentUserSuperAdmin$: Observable<boolean>;
    private subscriptions: Subscription[] = [];

    constructor(
        private technicalUserListService: TechnicalUserListService,
        private modalService: ModalService,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super();

        this.form = this.fb.group({
            userId: ['', Validators.required],
            name: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', Validators.required],
            enterpriseId: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();
        this.technicalUserListService.loadList();
        this.subscriptions.push(
            this.route.data.subscribe((data) => {
                const technicalUser = data.technicalUser;
                this.form.get('userId').setValue(technicalUser.userId);
                this.form.get('enterpriseId').setValue(technicalUser.enterpriseId);
                this.form.get('name').setValue(technicalUser.name);
                this.form.get('email').setValue(technicalUser.email);
                this.form.markAsPristine();
            }),
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    async save() {
        try {
            this.technicalUserListService.update(this.form.getRawValue());
        } catch (error) {
            console.log(error);
            this.modalService.openErrorDialog(error.toString());
        }
    }

    async delete() {
        try {
            const doYouWantToDelete: boolean = await this.modalService.openGenericConfirmationDialog({
                title: 'Are you sure you want to delete this Technical User?',
                message: 'There is no way to revert this action.',
            });
            const enterpriseId = await this.selectedEnterpriseService.getSelectedEnterpriseId();

            if (doYouWantToDelete) {
                const data = this.form.getRawValue();
                await this.technicalUserListService.delete(data);
                this.form.markAsPristine();

                this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/technical-users`);
            }
        } catch (error) {
            console.log(error);
            this.modalService.openErrorDialog(error.toString());
        }
    }
}
