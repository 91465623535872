import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { CanvasBackendConnector } from '../../../ruum/canvas/canvas.backendConnector';
import { AppState } from '../../app.store';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { CommonService } from '../../common.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { DocumentScrollService } from '../../documentScroll.service';
import { ServiceHelper } from '../../serviceHelper';
import { TrackingConnector } from '../../trackingConnector.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { APPROVAL_LIST_ACTIONS } from './approval-list.actions';
import { ApprovalListConnector } from './approval-list.connector';
import {
    ApprovalItemListItem,
    ApprovalListFilters,
    ApprovalListOrderBy,
    ApprovalListWrapper,
    ApprovalStatus,
} from './approval.model';

@Injectable({ providedIn: 'root' })
export class ApprovalListService extends ApprovalListConnector {
    constructor(
        private appStoreWrapper: AppStoreWrapper,
        protected readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected canvasConnector: CanvasBackendConnector,
        protected trackingConnector: TrackingConnector,
        protected authService: AuthService,
        private serviceHelper: ServiceHelper,
        private documentScrollService: DocumentScrollService,
        private store: Store<AppState>,
        private commonService: CommonService,
    ) {
        super(
            readModelConnector,
            alertService,
            projectServiceConnector,
            canvasConnector,
            trackingConnector,
            authService,
        );
        this.setUpObservables();
    }

    setUpObservables() {
        this.getListObservable().subscribe((page) => {
            this.serviceHelper.dispatchWithoutPersisting(APPROVAL_LIST_ACTIONS.APPROVAL_LIST_LOADED, { list: page });

            if (page.currentPage === 1) {
                this.maybeGoToNextPage();
            }
        });

        this.loadIndicatorObs().subscribe((loading) => {
            this.commonService.setBusy(loading);
        });
    }

    loadIndicatorObs(): Observable<boolean> {
        return this.isLoadingFirstPage$.pipe(debounceTime(200));
    }

    approvalsList(): Observable<any> {
        return this.approvalListRoot().pipe(map((plist) => plist.rows));
    }

    approvalListRoot(): Observable<PaginatedList<ApprovalItemListItem>> {
        return this.wrapper().pipe(
            map((wrapper) => wrapper.root),
            distinctUntilChanged(),
        );
    }

    getStoreData$() {
        return this.wrapper().pipe(map((wrapper) => wrapper.root));
    }

    filters$(): Observable<ApprovalListFilters> {
        return this.getFilters$();
    }

    filterByGroupId(groupId: string) {
        this.groupId$.next(groupId);
    }

    filterByWorkspaceId(workspaceId: string) {
        this.workspaceId$.next(workspaceId);
    }

    filterByProjectId(projectId: string) {
        this.projectId$.next(projectId);
    }

    filterByProjectName(projectName: string) {
        this.projectName$.next(projectName);
    }

    filterByCreatedBy(createdBy: string) {
        this.createdBy$.next(createdBy);
    }

    filterByApproverIds(ids: string[]) {
        this.approverIds$.next(ids);
    }

    filterByResponsibleIds(ids: string[]) {
        this.responsibleIds$.next(ids);
    }

    filterByApproverRoleIds(ids: string[]) {
        this.approverRoleIds$.next(ids);
    }

    filterByStatus(statusList: ApprovalStatus[]) {
        this.status$.next(statusList);
    }

    refreshApprovalList() {
        this.reload();
    }

    filterByCustomFields(customFields: { [key: string]: string }) {
        this.customFields$.next(JSON.stringify(customFields));
    }

    showLoading(): Observable<boolean> {
        return combineLatest([this.hasMore$, this.isLoadingAnotherPage$]).pipe(
            map(([hasMore, isLoadingAnotherPage]) => hasMore || isLoadingAnotherPage),
        );
    }

    loadMore() {
        this.maybeGoToNextPage();
    }

    orderListBy(by: ApprovalListOrderBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
        sessionStorage.setItem('approvalListSortBy', by);
        sessionStorage.setItem('approvalListSortDirection', direction);
    }

    orderBy(): Observable<OrderedListParams<ApprovalListOrderBy>> {
        return this.orderBy$;
    }

    getOrderBy(): OrderedListParams<ApprovalListOrderBy> {
        let orderBy: OrderedListParams<ApprovalListOrderBy>;
        this.orderBy()
            .pipe(take(1))
            .subscribe((val) => (orderBy = val));
        return orderBy;
    }

    protected getOrderBy$() {
        return this.orderBy$;
    }

    private wrapper(): Observable<ApprovalListWrapper> {
        return this.store.select('approvalListWrapper').pipe(distinctUntilChanged());
    }
}
