import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkspaceListItem } from '../../../common/connectors/readModelConnector.service';
import { WorkspaceListService } from '../../../common/connectors/workspace/workspace-list.service';
import { InputValidator } from '../../../common/validators/inputValidator.service';
import { RuumDropdownItem } from '../../ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-template-project-create',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">Create Template</h2>

            <form [formGroup]="form">
                <div class="d-flex flex-fill align-items-end">
                    <div class="d-flex flex-fill flex-column">
                        <div class="form-group w-100 mb-0">
                            <label class="form-default-label uppercase" for="templateName">Template Name</label>
                            <div class="input">
                                <input
                                    id="templateName"
                                    class="form-control form-control-name"
                                    type="text"
                                    placeholder="Name your template..."
                                    formControlName="templateName"
                                    ruumInputHighlight
                                    required
                                    ngbAutofocus
                                    (ngModelChange)="validateName()"
                                    [ngClass]="{ 'border-danger': nameErrorMessage !== undefined }"
                                />
                                <div class="d-flex align-items-center" *ngIf="nameErrorMessage !== undefined">
                                    <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                                        <i class="icon icon-cancel"></i>
                                    </button>
                                    <div class="text-tiny text-danger">{{ nameErrorMessage }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group w-100 mb-0 mt-6">
                    <label class="form-default-label uppercase" for="templateDescription">Template Description</label>
                    <textarea
                        id="templateDescription"
                        class="form-control"
                        rows="3"
                        placeholder="What is this template about? How does it help your team?"
                        formControlName="templateDescription"
                        (ngModelChange)="validateDescription()"
                        [ngClass]="{ 'border-danger': descriptionErrorMessage !== undefined }"
                    >
                    </textarea>
                    <div class="d-flex align-items-center" *ngIf="descriptionErrorMessage !== undefined">
                        <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                            <i class="icon icon-cancel"></i>
                        </button>
                        <div class="text-tiny text-danger">{{ descriptionErrorMessage }}</div>
                    </div>
                </div>

                <div *ngIf="workspaceList$ | async as workspaceList" class="form-group w-100 mb-0 mt-6">
                    <label class="form-default-label uppercase" for="selectWorkspace">Workspace</label>
                    <select
                        class="form-control"
                        id="selectWorkspace"
                        placeholder="Select Workspace"
                        formControlName="templateWorkspace"
                    >
                        <option value="{{ undefined }}">None</option>
                        <option *ngFor="let workspace of workspaceList" value="{{ workspace.id }}">
                            {{ workspace.name }}
                        </option>
                    </select>
                </div>

                <div class="d-flex justify-content-end mt-6">
                    <button
                        class="btn btn-lg btn-primary"
                        type="button"
                        (click)="createTemplate()"
                        [disabled]="!form.valid"
                    >
                        Next
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
})
export class CreateProjectTemplateComponent implements OnInit {
    selectedItem: RuumDropdownItem;
    workspaceList$: Observable<WorkspaceListItem[]>;
    form: FormGroup;
    nameErrorMessage = undefined;
    nameLimit: number;
    descriptionErrorMessage = undefined;
    descriptionLimit: number;

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private workspaceListService: WorkspaceListService,
        private inputValidator: InputValidator,
    ) {
        this.nameLimit = inputValidator.shortLimit;
        this.descriptionLimit = inputValidator.mediumLimit;
        this.form = this.formBuilder.group({
            templateName: ['', [Validators.required, Validators.maxLength(this.nameLimit)]],
            templateDescription: ['', [Validators.maxLength(this.descriptionLimit)]],
            templateWorkspace: [''],
        });
    }

    ngOnInit() {
        this.workspaceList$ = this.workspaceListService.list$.pipe(
            map((list) => list.filter((item) => item.role && item.role !== 'WorkspaceViewer')),
        );
    }

    createTemplate(): void {
        const templateName = this.form.controls.templateName.value;
        const templateDescription = this.form.controls.templateDescription.value;
        const templateWorkspace =
            this.form.controls.templateWorkspace.value && this.form.controls.templateWorkspace.value !== ''
                ? this.form.controls.templateWorkspace.value
                : undefined;
        const templateSettings = { templateName, templateDescription, templateWorkspace };
        this.activeModal.close(templateSettings);
    }

    validateName(): void {
        const nameControl = this.form.controls.templateName;
        this.nameErrorMessage = this.inputValidator.getFormControlErrorMessage(nameControl, this.nameLimit);
    }

    validateDescription(): void {
        const descriptionControl = this.form.controls.templateDescription;
        this.descriptionErrorMessage = this.inputValidator.getFormControlErrorMessage(
            descriptionControl,
            this.descriptionLimit,
        );
    }
}
