import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionTypeAndPayload } from '../../actions';
import { AppState } from '../../app.store';
import { ReadModelBackendConnector, UserListItem } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';
import { AddOrReplaceInvolvedUsersAction, UsersMap } from './users.reducer';

@Injectable({ providedIn: 'root' })
export class InvolvedUsersService extends StoreLoader<UserListItem> {
    constructor(protected store: Store<AppState>, private readModelBackendConnector: ReadModelBackendConnector) {
        super();
    }

    allUsersMap(): Observable<UsersMap> {
        return this.store.select('users');
    }

    allInvolvedUsers(): Observable<UserListItem[]> {
        return this.store.select('users').pipe(map((userMap: UsersMap) => Object.values(userMap)));
    }

    protected getData(ids: string[]): Observable<UserListItem[]> {
        return this.readModelBackendConnector
            .getInvolvedUsers({ page: 1, pageSize: ids.length }, { userIds: ids })
            .pipe(map((page) => page.rows));
    }

    protected storeData(users: UserListItem[]): void {
        const add: ActionTypeAndPayload<AddOrReplaceInvolvedUsersAction> = {
            type: 'ADD_OR_REPLACE_INVOLVED_USERS',
            payload: {
                users,
            },
        };
        this.store.dispatch(add);
    }

    /** Called when loading an ID from the server fails. */
    protected failedToLoad(id: string): void {
        const add: ActionTypeAndPayload<AddOrReplaceInvolvedUsersAction> = {
            type: 'ADD_OR_REPLACE_INVOLVED_USERS',
            payload: {
                users: [
                    {
                        id,
                        color: 'gray',
                        fullName: 'Failed to load',
                        initials: 'FAIL',
                        mail: '',
                        userId: id,
                        displayName: 'Failed to load',
                    },
                ],
            },
        };
        this.store.dispatch(add);
    }

    protected getStoreData(): Observable<{ [id: string]: UserListItem }> {
        return this.store.select('users');
    }

    async loadInvolvedUsers(entityId: string): Promise<any> {
        const page = await this.readModelBackendConnector
            .getInvolvedUsers({ page: 1, pageSize: 200 }, { entityId })
            .toPromise();
        const add: ActionTypeAndPayload<AddOrReplaceInvolvedUsersAction> = {
            type: 'ADD_OR_REPLACE_INVOLVED_USERS',
            payload: {
                users: page.rows,
            },
        };
        this.store.dispatch(add);
    }
}
