import { RuumAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../paginatedList.model';
import { APPROVAL_LIST_ACTIONS } from './approval-list.actions';
import { ApprovalItemListItem } from './approval.model';

export function ApprovalListReducer(
    currentState: PaginatedList<ApprovalItemListItem>,
    action: RuumAction,
): PaginatedList<ApprovalItemListItem> {
    if (!APPROVAL_LIST_ACTIONS[action.type]) {
        return currentState;
    }

    switch (action.type) {
        case APPROVAL_LIST_ACTIONS.APPROVAL_LIST_LOADED:
            return approvalListLoaded(currentState, action.payload.list);
        default:
            return currentState;
    }
}

function approvalListLoaded(
    currentState: PaginatedList<ApprovalItemListItem>,
    data: PaginatedList<ApprovalItemListItem>,
): PaginatedList<ApprovalItemListItem> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return data;
    }
}
