import { RuumAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../../common/connectors/paginatedList.model';

export function handleEntitiesLoaded<T, A extends RuumAction>(
    currentState: PaginatedList<T>,
    action: A,
): PaginatedList<T> {
    const data = action.payload.page;
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}

export function handleSingleEntityLoaded<T, A extends RuumAction>(
    currentState: PaginatedList<T>,
    action: A,
    isSameEntity: (a: T, b: T) => boolean,
    getEntity: (action: A) => T,
): PaginatedList<T> {
    const loadedEntity: T = getEntity(action);
    let added = false;

    // Try to replace
    const rows = currentState.rows.map((existingEntity) => {
        if (isSameEntity(existingEntity, loadedEntity)) {
            added = true;
            return loadedEntity;
        } else {
            return existingEntity;
        }
    });

    // If not replaced, add to the end
    if (!added) {
        rows.push(loadedEntity);
    }

    return {
        ...currentState,
        rows,
    };
}

export function handleEntityCreated<T, A extends RuumAction>(
    currentState: PaginatedList<T>,
    action: A,
    getEntity: (action: A) => T,
): PaginatedList<T> {
    return {
        ...currentState,
        rows: currentState.rows.concat(getEntity(action)),
    };
}

export function handleEntityUpdated<T, A extends RuumAction>(
    currentState: PaginatedList<T>,
    action: A,
    isSameEntity: (a: T, b: T) => boolean,
    getEntity: (action: A) => T,
): PaginatedList<T> {
    const updatedEntity: T = getEntity(action);
    return {
        ...currentState,
        rows: currentState.rows.map((existingEntity) => {
            if (isSameEntity(existingEntity, updatedEntity)) {
                return updatedEntity;
            } else {
                return existingEntity;
            }
        }),
    };
}

export function handleEntityDeleted<T, A extends RuumAction>(
    currentState: PaginatedList<T>,
    action: A,
    isSameEntity: (a: T, b: T) => boolean,
): PaginatedList<T> {
    return {
        ...currentState,
        rows: currentState.rows.filter((entity) => isSameEntity(entity, action.payload)),
    };
}
