import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TaskPriority } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-task-list-cell-priority',
    template: `
        <ruum-task-priority-menu [priorityId]="id" [disabled]="isReadOnly" (priorityChanged)="onChange($event)">
            <ruum-task-list-priority-button tabindex="0" data-content="desktop-button" [id]="id">
            </ruum-task-list-priority-button>
            <ruum-task-list-priority-button data-content="mobile-button" [id]="id"> </ruum-task-list-priority-button>
        </ruum-task-priority-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListCellPriorityComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() isReadOnly = false;

    @Input()
    id: TaskPriority;

    @Output()
    priorityChanged = new EventEmitter();

    ngOnInit() {}

    onChange(event) {
        this.priorityChanged.emit(event);
    }
}
