import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from '../../ui-components/dropdown/dropdown.module';
import { MenuModule } from '../../ui-components/menu/menu.module';
import { WorkspaceRenameModule } from '../workspace-rename/workspace-rename.menu';
import { WorkspaceMenuComponent } from './workspace-menu.component';

@NgModule({
    imports: [CommonModule, DropdownModule, WorkspaceRenameModule, MenuModule, NgbTooltipModule],
    declarations: [WorkspaceMenuComponent],
    exports: [WorkspaceMenuComponent],
})
export class WorkspaceMenuModule {}
