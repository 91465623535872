import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    SetTemplateSharingSettingsAction,
    TemplateActionTypes,
    TemplateParticipant,
    TemplateSharingSettings,
} from '@ruum/ruum-reducers';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { CanvasSectionsService } from '../../../ruum/canvas/sections/canvasSections.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { SelectedProjectService } from '../project/selectedProject.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { TEMPLATE_WEB_APP_ACTION_TYPES } from './selectedTemplate.reducer';
import { SelectedTemplateService } from './selectedTemplate.service';
import { TemplateListItem } from './template-list.model';
import { TEMPLATE_LIST_ACTION_TYPES } from './template-list.reducer';

@Injectable({ providedIn: 'root' })
export class TemplateListItemService {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private serviceHelper: ServiceHelper,
        private alertService: RuumAlertService,
        private selectedProjectService: SelectedProjectService,
        private selectedTemplateService: SelectedTemplateService,
        private canvasSectionsService: CanvasSectionsService,
        private readModelBackendConnector: ReadModelBackendConnector,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
    ) {}

    changeName(id: string, name: string): Promise<void> {
        return this.serviceHelper
            .dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS, { id, name })
            .then(() =>
                this.projectServiceBackendConnector.addActionToTemplate(id, TemplateActionTypes.EDIT_TEMPLATE_FIELDS, {
                    name,
                }),
            );
    }

    changeDescription(id: string, description: string) {
        this.serviceHelper
            .dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS, {
                id,
                description,
            })
            .then(() =>
                this.projectServiceBackendConnector.addActionToTemplate(id, TemplateActionTypes.EDIT_TEMPLATE_FIELDS, {
                    description,
                }),
            );
    }

    changeCoverIcon(id: string, icon: string) {
        this.serviceHelper
            .dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS, { id, icon })
            .then(() =>
                this.projectServiceBackendConnector.addActionToTemplate(id, TemplateActionTypes.EDIT_TEMPLATE_FIELDS, {
                    icon,
                }),
            );
    }

    changeCoverColor(id: string, colorTheme: string) {
        this.serviceHelper
            .dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS, {
                id,
                colorTheme,
            })
            .then(() =>
                this.projectServiceBackendConnector.addActionToTemplate(id, TemplateActionTypes.EDIT_TEMPLATE_FIELDS, {
                    colorTheme,
                }),
            );
    }

    changeTemplateFields(id: string, fields): Promise<any> {
        return this.serviceHelper
            .dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS, {
                id,
                ...fields,
            })
            .then(() =>
                this.projectServiceBackendConnector.addActionToTemplate(id, TemplateActionTypes.EDIT_TEMPLATE_FIELDS, {
                    ...fields,
                }),
            );
    }

    moveTemplateToWorkspace(templateId: string, workspaceId: string) {
        return this.projectServiceBackendConnector
            .moveTemplateToWorkspace(templateId, workspaceId)
            .then(() => {
                return this.readModelBackendConnector.getTemplate(templateId).toPromise();
            })
            .then((template: TemplateListItem) => {
                this.serviceHelper.dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS, {
                    id: template.id,
                    ...template,
                });
                if (this.selectedTemplateService.getSelectedTemplateId() === template.id) {
                    this.serviceHelper.dispatchWithoutPersisting(TEMPLATE_WEB_APP_ACTION_TYPES.UPDATE_TEMPLATE, {
                        id: template.id,
                        ...template,
                    });
                }
                return template;
            });
    }

    leaveTemplate(templateId: string) {
        const id = this.authService.getLoggedUserId();
        return this.projectServiceBackendConnector
            .addActionToTemplate(templateId, TemplateActionTypes.REMOVE_PARTICIPANT_FROM_TEMPLATE, { id })
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.LEAVE_TEMPLATE, {
                    id: templateId,
                });
            });
    }

    deleteTemplate(templateId: string) {
        return this.projectServiceBackendConnector.deleteTemplate(templateId).then(() => {
            return this.serviceHelper.dispatchWithoutPersisting(TEMPLATE_LIST_ACTION_TYPES.DELETE_TEMPLATE, {
                id: templateId,
            });
        });
    }

    async createSectionTemplateFromDeprecated(sectionId: string): Promise<string> {
        /**
         * We know that a section template created from scratch will have one section with id == 'section_1'.
         * We create a empty section template and then we duplicate the section to that template on top of section_1.
         * That means the section template ruum will have two sections, the second one (section_1) will always be ignored.
         */
        const selectedProject = this.selectedProjectService.getSelectedProject();
        const selectedSectionTitle = await this.canvasSectionsService
            .getSectionText(sectionId)
            .pipe(take(1))
            .toPromise();
        const sectionTemplateRuum = await this.projectServiceBackendConnector.createTemplate(
            selectedSectionTitle,
            undefined,
            selectedProject.workspaceId,
            'section',
        );
        await this.duplicateSectionDeprecated(
            selectedProject.id,
            sectionId,
            sectionTemplateRuum.draftProjectId,
            'section_1',
        );
        return sectionTemplateRuum.id;
    }

    private duplicateSectionDeprecated(
        fromRuumId: string,
        sectionId: string,
        toRuumId: string,
        onTopOfSectionId: string,
    ) {
        return this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/duplicatesection`,
                {
                    fromRuumId,
                    sectionId,
                    toRuumId,
                    onTopOfSectionId,
                },
                { withCredentials: true },
            )
            .toPromise()
            .catch((err) => {
                this.alertService.warning('Error duplicating section', err);
                throw err;
            });
    }

    isDraft(draftProjectId: string, publishedProjectId: string): boolean {
        return this.getTemplateStatus(draftProjectId, publishedProjectId) === 'DRAFT';
    }

    isEditing(draftProjectId: string, publishedProjectId: string): boolean {
        return this.getTemplateStatus(draftProjectId, publishedProjectId) === 'EDITING';
    }

    isPublished(draftProjectId: string, publishedProjectId: string): boolean {
        return this.getTemplateStatus(draftProjectId, publishedProjectId) === 'PUBLISHED';
    }

    private getTemplateStatus(draftProjectId: string, publishedProjectId: string): string {
        if (!publishedProjectId) {
            return 'DRAFT';
        } else if (draftProjectId) {
            return 'EDITING';
        } else {
            return 'PUBLISHED';
        }
    }

    getIsOnlyAdmin(participants: TemplateParticipant[]): boolean {
        const loggedUserId = this.authService.getLoggedUser().id;
        const templateAdmins = (participants || []).filter(
            (user: TemplateParticipant) => user.role === 'TemplateAdmin',
        );
        return !!templateAdmins.find((user) => user.id === loggedUserId) && templateAdmins.length === 1;
    }

    triggerSharingLink(id: string, templateSharingSettings: TemplateSharingSettings) {
        return this.selectedTemplateService.persistAction<SetTemplateSharingSettingsAction>(
            'SET_TEMPLATE_SHARING_SETTINGS',
            templateSharingSettings,
        );
    }
}
