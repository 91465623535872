import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ruum-system-connector-io-item',
    template: `
        <ng-container [formGroup]="formGroup">
            <div class="d-table-cell align-top form-group form-white border-bottom border-light">
                <input
                    class="form-control form-control-sm"
                    placeholder="Enter property name..."
                    type="text"
                    formControlName="name"
                    id="io-name"
                />
            </div>
            <div class="d-table-cell align-top form-group border-bottom border-light">
                <ruum-select
                    formControlName="type"
                    [lightBackground]="false"
                    [type]="'link'"
                    [style.height.px]="32"
                    id="io-type"
                >
                    <ruum-select-option value="string" content="String"></ruum-select-option>
                    <ruum-select-option value="number" content="Number"></ruum-select-option>
                    <ruum-select-option value="boolean" content="Boolean"></ruum-select-option>
                    <ruum-select-option value="date" content="Date"></ruum-select-option>
                    <ruum-select-option value="record" content="Record"></ruum-select-option>
                </ruum-select>
            </div>
            <div class="d-table-cell align-top form-group form-white border-bottom border-light">
                <input
                    class="form-control form-control-sm"
                    placeholder="Enter Label..."
                    type="text"
                    formControlName="label"
                    id="io-label"
                />
            </div>
            <div class="d-table-cell align-middle form-group border-bottom border-light text-right">
                <button class="btn btn-link-danger btn-icon" (click)="remove.emit()">
                    <i class="icon icon-cancel"></i>
                </button>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemConnectorIOItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-table-row align-top';

    @Input() formGroup: FormGroup;

    @Output() remove = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}
}
