import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'dropdown-popup',
    template: `
        <div class="mask" (click)="close()" (keydown.space)="close()" (keydown.enter)="close()"></div>
        <ul class="container">
            <li *ngFor="let option of options; let i = index" (click)="selectOption(i)">
                {{ option }}
            </li>
        </ul>
    `,
    styles: [
        `
            :host {
                position: relative;
            }
            .mask,
            .container {
                position: fixed;
            }
            .mask {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                color: rgba(0, 0, 0, 0.1);
            }
            .container {
                z-index: 2;
                box-shadow: 0 2px 7px 0 rgba(161, 161, 161, 0.5);
                border-radius: 4px;
                margin: -8px 0 0;
                list-style-type: none;
                padding: 4px;
                background-color: white;
                position: absolute;
                width: max-content;
            }
            li {
                text-align: left;
                padding: 8px;
                color: #455a6a;
                border-radius: 4px;
                font-weight: bold;
                font-size: 16px;
                cursor: pointer;
            }
            li:hover {
                background-color: #edeff1;
            }
        `,
    ],
})
export class DrodownPopupComponent {
    @Input() options: string[];
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClose: EventEmitter<void> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelect: EventEmitter<number> = new EventEmitter();

    close() {
        this.onClose.emit();
    }

    selectOption(optionIndex: number) {
        this.onSelect.emit(optionIndex);
        this.close();
    }
}
