import { clone, EntityAction, RuumAction, Template, TemplateType } from '@ruum/ruum-reducers';
import { PaginatedList } from '../paginatedList.model';
import { TemplateListItem, TemplateState } from './template-list.model';

const initialState: TemplateState = {
    selectedTemplateType: <TemplateType>'ruum',
    categoryTemplates: [],
    categorySectionTemplates: [],
};

export const TEMPLATE_LIST_ACTION_TYPES = {
    LOAD_NEXT_TEMPLATES: 'LOAD_NEXT_TEMPLATES',
    TEMPLATES_LOADED: 'TEMPLATES_LOADED',
    TEMPLATES_LOADED_FOR_NEW_OVERVIEW: 'TEMPLATES_LOADED_FOR_NEW_OVERVIEW',
    TEMPLATE_EDIT_FIELDS: 'TEMPLATE_EDIT_FIELDS',
    LEAVE_TEMPLATE: 'LEAVE_TEMPLATE',
    DELETE_TEMPLATE: 'DELETE_TEMPLATE',
    TRIGGER_SHARING_LINK: 'TRIGGER_SHARING_LINK',
};

export type TemplatesLoadedAction = EntityAction<
    'TEMPLATES_LOADED',
    {
        page: PaginatedList<Template>;
    }
>;
export type TemplatesLoadedForNewOverviewAction = EntityAction<
    'TEMPLATES_LOADED_FOR_NEW_OVERVIEW',
    {
        page: PaginatedList<Template>;
    }
>;

export function TemplateListReducer(currentState: TemplateState = initialState, action: RuumAction): TemplateState {
    switch (action.type) {
        case TEMPLATE_LIST_ACTION_TYPES.TEMPLATES_LOADED:
            return templatesLoaded(currentState, action.payload.page);
        case TEMPLATE_LIST_ACTION_TYPES.TEMPLATE_EDIT_FIELDS:
            return templateEditFields(currentState, action);
        case TEMPLATE_LIST_ACTION_TYPES.LEAVE_TEMPLATE:
        case TEMPLATE_LIST_ACTION_TYPES.DELETE_TEMPLATE:
            return removeTemplate(currentState, action);
        default:
            return currentState;
    }
}

function templatesLoaded(currentState: TemplateState, page: PaginatedList<TemplateListItem>): TemplateState {
    const newState: TemplateState = clone(currentState);
    const templateType: TemplateType =
        page.rows && page.rows[0] && page.rows[0].type !== 'section' ? 'ruum' : 'section';
    const listOfTemplates = templateType === 'ruum' ? newState.categoryTemplates : newState.categorySectionTemplates;

    page.rows.forEach((templateToAdd) => {
        const existingCategory = listOfTemplates.find((category) => category.id === templateToAdd.templateCategory.id);

        if (existingCategory) {
            const index = existingCategory.templates.findIndex(
                (existingTemplate) => templateToAdd.id === existingTemplate.id,
            );
            if (index !== -1) {
                existingCategory.templates[index] = templateToAdd;
            } else {
                existingCategory.templates.push(templateToAdd);
            }
        } else {
            listOfTemplates.push({
                name: templateToAdd.templateCategory.name,
                templates: [templateToAdd],
                id: templateToAdd.templateCategory.id,
            });
        }
    });
    return newState;
}

function templateEditFields(currentState: TemplateState, action: RuumAction): TemplateState {
    return {
        ...currentState,
        categoryTemplates: currentState.categoryTemplates.map((category) => {
            return {
                ...category,
                templates: category.templates.map((template) => {
                    if (template.id === action.payload.id) {
                        return { ...template, ...action.payload };
                    }
                    return template;
                }),
            };
        }),
        categorySectionTemplates: currentState.categorySectionTemplates.map((category) => {
            return {
                ...category,
                templates: category.templates.map((template) => {
                    if (template.id === action.payload.id) {
                        return { ...template, ...action.payload };
                    }
                    return template;
                }),
            };
        }),
    };
}

function removeTemplate(currentState: TemplateState, action: RuumAction): TemplateState {
    return {
        ...currentState,
        categoryTemplates: currentState.categoryTemplates.map((category) => {
            return {
                ...category,
                templates: category.templates.filter((template) => template.id !== action.payload.id),
            };
        }),
        categorySectionTemplates: currentState.categorySectionTemplates.map((category) => {
            return {
                ...category,
                templates: category.templates.filter((template) => template.id !== action.payload.id),
            };
        }),
    };
}
