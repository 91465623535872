import { Injectable } from '@angular/core';
import { WorkspaceActionsTypes } from '@ruum/ruum-reducers';
import { ServiceHelper } from '../../serviceHelper';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceListItemService {
    constructor(
        private serviceHelper: ServiceHelper,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
    ) {}

    // TODO: update workspace list
    // TODO: update selected workspace
    renameWorkspace(workspaceId: string, workspaceName: string): Promise<any> {
        return this.projectServiceBackendConnector
            .addActionToWorkspace(workspaceId, WorkspaceActionsTypes.CHANGE_WORKSPACE_NAME, {
                name: workspaceName,
            })
            .then(() => {
                this.serviceHelper.dispatchWithoutPersisting(WorkspaceActionsTypes.CHANGE_WORKSPACE_NAME, {
                    id: workspaceId,
                    name: workspaceName,
                });
            });
    }
}
