import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuModule } from './../../shared/ui-components/menu/menu.module';
import { ProfileModule } from './../../shared/ui-components/profile/profile.module';
import { AdminProfileMenuComponent } from './profile-menu.component';

@NgModule({
    imports: [CommonModule, MenuModule, ProfileModule],
    declarations: [AdminProfileMenuComponent],
    exports: [AdminProfileMenuComponent],
})
export class AdminProfileMenuModule {}
