import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { canvasSchema, CanvasStep, TrackingState, UsersCanvasChanges } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthBackendConnector } from '../../common/connectors/authServiceConnector.service';

@Injectable({ providedIn: 'root' })
export class CanvasBackendConnector {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;

    constructor(private http: HttpClient, private backendConnector: AuthBackendConnector) {}

    getCurrentCanvasState(ruumId: string): Promise<any> {
        return this.http
            .get(`${this.PROJECT_SERVICE_URL}/v1/document/${ruumId}`, { withCredentials: true })
            .pipe(
                map(this.backendConnector.extractObject),
                catchError(this.backendConnector.handleError.bind(this.backendConnector)),
            )
            .toPromise();
    }

    getStepsSince(ruumId: string, version: number): Promise<CanvasStep[]> {
        return this.http
            .get(`${this.PROJECT_SERVICE_URL}/v1/document/${ruumId}/stepssince/${version}`, {
                withCredentials: true,
            })
            .pipe(timeout(12 * 1000))
            .toPromise() as Promise<CanvasStep[]>;
    }

    sendSteps(ruumId: string, data) {
        return this.http
            .post(`${this.PROJECT_SERVICE_URL}/v1/document/${ruumId}/steps`, data, {
                withCredentials: true,
            })
            .pipe(timeout(7 * 1000))
            .toPromise();
    }

    getTimeTracking(ruumId: string): Promise<TrackingState> {
        return this.http
            .get(`${this.PROJECT_SERVICE_URL}/v1/document/${ruumId}/timetracking`, {
                withCredentials: true,
            })
            .pipe(
                map(this.backendConnector.extractObject),
                catchError(this.backendConnector.handleError.bind(this.backendConnector)),
            )
            .toPromise();
    }

    getCanvasLatestChanges(ruumId: string): Observable<UsersCanvasChanges> {
        return this.http
            .get(`${this.PROJECT_SERVICE_URL}/v1/document/${ruumId}/changes`, { withCredentials: true })
            .pipe(
                map(this.backendConnector.extractObject),
                catchError(this.backendConnector.handleError.bind(this.backendConnector)),
            );
    }

    deleteNodeWithId(documentId: string, nodeId: string): Observable<any> {
        return this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/document/${documentId}/nodes/${nodeId}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    addTaskToTheBottomOf(documentId: string, sectionId: string, taskId: string) {
        const taskNode = canvasSchema.nodes.task.create({ id: taskId });
        return this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/document/${documentId}/sections/${sectionId}/nodes`,
                taskNode.toJSON(),
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }
}
