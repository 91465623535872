import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ruum-modal-dialog-mobile',
    template: `
        <div class="modal-body bg-white rounded p-1">
            <ng-content></ng-content>
        </div>
        <div class="modal-footer px-0">
            <button
                *ngIf="cancel"
                class="btn btn-lg btn-block btn-cancel py-1 mt-0"
                type="button"
                (click)="dismiss.emit($event)"
            >
                Cancel
            </button>

            <button
                *ngIf="apply"
                class="btn btn-lg btn-block btn-primary btn-apply py-1 mt-0"
                type="button"
                (click)="close.emit($event)"
            >
                Apply
            </button>
        </div>
    `,
    styles: [
        `
            .btn-cancel {
                background: #3f5869;
                color: white;
                height: 48px;
            }

            .btn-apply {
                height: 48px;
            }
        `,
    ],
})
export class ModalDialogMobileComponent {
    @Input()
    cancel = true;

    @Input()
    apply = false;

    @Output()
    dismiss = new EventEmitter();

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output()
    close = new EventEmitter();
}
