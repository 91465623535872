import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumNavbarModule } from '../../common/navbar/navbar.module';
import { CanvasSectionContextMenuModule } from '../../shared/canvas/context-menu/canvas-section-context-menu.module';
import { SelectModule } from '../../shared/ui-components/select/select.module';
import { WorkspaceMenuModule } from '../../shared/workspace/workspace-menu/workspace-menu.module';
import { RuumCommonModule } from '../common.module';
import { AdminMainLayoutModule } from './../../admin/layout/main-layout.module';
import { MainNavPanelNodeItemComponent } from './main-nav-panel-node-item/main-nav-panel-node-item.component';
import { MainNavPanelNodeComponent } from './main-nav-panel-node/main-nav-panel-node.component';
import { MainNavPanelSectionComponent } from './main-nav-panel-section/main-nav-panel-section.component';
import { RuumProjectMainNavComponent } from './project/project-main-nav.component';
import { SidenavContentComponent } from './sidenav-content.component';
import { SidenavLobbyListItemComponent } from './sidenav-lobby-list-item.component';
import { SidenavLobbyListComponent } from './sidenav-lobby-list.component';
import { SidenavPrimaryListItemComponent } from './sidenav-primary-list-item.component';
import { SidenavPrimaryListComponent } from './sidenav-primary-list.component';
import { SidenavProjectSectionsComponent } from './sidenav-project-sections.component';
import { SidenavWorkspaceListItemComponent } from './sidenav-workspace-list-item.component';
import { SidenavWorkspaceListComponent } from './sidenav-workspace-list.component';
import { MainNavPanelCanvasComponent } from './sidenav.component';

@NgModule({
    imports: [
        CommonModule,
        A11yModule,
        RuumCommonModule,
        RuumNavbarModule,
        WorkspaceMenuModule,
        AdminMainLayoutModule,
        CanvasSectionContextMenuModule,
        SelectModule,
    ],
    declarations: [
        SidenavContentComponent,
        SidenavPrimaryListComponent,
        SidenavPrimaryListItemComponent,
        SidenavLobbyListComponent,
        SidenavLobbyListItemComponent,
        SidenavWorkspaceListComponent,
        SidenavWorkspaceListItemComponent,
        SidenavProjectSectionsComponent,
        MainNavPanelCanvasComponent,
        MainNavPanelSectionComponent,
        MainNavPanelNodeComponent,
        MainNavPanelNodeItemComponent,
        RuumProjectMainNavComponent,
    ],
    exports: [
        MainNavPanelCanvasComponent,
        MainNavPanelSectionComponent,
        MainNavPanelNodeComponent,
        MainNavPanelNodeItemComponent,
        RuumProjectMainNavComponent,
    ],
})
export class SidenavModule {}
