import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ownerName' })
export class OwnerNamePipe implements PipeTransform {
    transform(name): string {
        if (name) {
            if (name === 'You') {
                return 'You';
            } else {
                const splittedName: string[] = name.split(' ');
                const firstName = splittedName[0];
                if (splittedName.length > 1) {
                    const lastNameLetter = splittedName[1].substring(0, 1);
                    return `${firstName} ${lastNameLetter}`;
                } else {
                    return firstName;
                }
            }
        }
    }
}
@NgModule({
    imports: [],
    declarations: [OwnerNamePipe],
    exports: [OwnerNamePipe],
})
export class RuumOwnerNamePipeModule {}
