import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../app.store';
import { isEmpty, isEqual, xorWith } from '../../utils.service';
import { SavedViewValue } from './saved-views.model';
import { SavedViewsState } from './saved-views.reducer';

@Injectable({ providedIn: 'root' })
export class SavedViewsService {
    private savedViewsState = createFeatureSelector<SavedViewsState>('savedViews');

    private savedViewValuesMap = createSelector(this.savedViewsState, (state: SavedViewsState) => {
        return state.entities;
    });

    constructor(private store: Store<AppState>) {}

    workspaceId = '';
    groupId = '';
    projectId = '';

    getSelectedWorkspaceId(): string {
        return this.workspaceId;
    }

    getSelectedGroupId(): string {
        return this.groupId;
    }

    getSelectedProjectId(): string {
        return this.projectId;
    }

    getAllSavedViewsMap(): Observable<{ [key: string]: SavedViewValue }> {
        return this.store.pipe(select(this.savedViewValuesMap));
    }

    // Deep equal comparator
    isArraysEqual(x: any, y: any) {
        return isEmpty(xorWith(x, y, isEqual));
    }
}
