import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { AppState } from '../../../../common/app.store';
import { RuumAlertService } from '../../../../common/components/alert/alert.service';
import { OrderedListParams, PaginatedList } from '../../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../../common/connectors/paginatedListLoader';
import { ServiceHelper } from '../../../../common/serviceHelper';
import { WebhookEntityEventFilters, WebhooksConnector } from '../../webhooks.connector';
import { WebhookEntityEventListItem } from '../../webhooks.model';
import {
    WebhookEntityEventsLoadedAction,
    WEBHOOK_ENTITY_EVENTS_LIST_ACTION_TYPES,
} from './webhook-entity-events-list.reducer';

export type WebhookEntityEventsOrderBy = 'entityVersion';

@Injectable({ providedIn: 'root' })
export class WebhookEntityEventsListService extends PaginatedListLoader<
    WebhookEntityEventListItem,
    WebhookEntityEventFilters,
    WebhookEntityEventsOrderBy
> {
    private webhookId$: BehaviorSubject<string> = new BehaviorSubject(null);
    private entityId$: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        protected authService: AuthService,
        protected alertService: RuumAlertService,
        private webhooksConnector: WebhooksConnector,
        private serviceHelper: ServiceHelper,
        private store: Store<AppState>,
    ) {
        super(alertService, authService);
        this.loadList();
        this.setUpObservables();
    }

    filterByWebhookId(webhookId: string) {
        this.webhookId$.next(webhookId);
    }
    filterByEntityId(entityId: string) {
        this.entityId$.next(entityId);
    }

    // TODO: @rxjs: fix type
    getStoreData$(): any {
        return combineLatest([this.store.select('webhookList'), this.webhookId$, this.entityId$]).pipe(
            filter(
                ([webhooks, selectedWebhookId, entityId]) =>
                    webhooks && selectedWebhookId !== null && entityId !== null,
            ),
            map(([webhooks, selectedWebhookId, selectedEntityId]) => {
                const webhook = webhooks.rows.find((wh) => wh.id === selectedWebhookId);

                if (webhook && webhook.entities) {
                    const entity = webhook.entities.rows.find((e) => e.entityId === selectedEntityId);
                    return entity ? entity.events : [];
                }
            }),
        );
    }

    protected getFilters$(): Observable<WebhookEntityEventFilters> {
        return combineLatest([this.webhookId$, this.entityId$]).pipe(
            filter(([webhookId, entityId]) => {
                return !!webhookId && !!entityId;
            }),
            map(([webhookId, entityId]) => ({
                webhookId,
                entityId,
            })),
        );
    }

    private setUpObservables() {
        this.getListObservable()
            .pipe(
                withLatestFrom(this.webhookId$, this.entityId$),
                filter(([page, webhookId, entityId]) => {
                    return !!webhookId && !!entityId;
                }),
            )
            .subscribe(([page, webhookId, entityId]) => {
                this.serviceHelper.dispatchWithoutPersisting<WebhookEntityEventsLoadedAction>(
                    WEBHOOK_ENTITY_EVENTS_LIST_ACTION_TYPES.WEBHOOK_ENTITY_EVENTS_LOADED,
                    { page, webhookId, entityId },
                );
            });

        combineLatest([this.getListObservable(), this.webhookId$]);
    }

    protected getData(
        page: number,
        filters: WebhookEntityEventFilters,
        orderBy: OrderedListParams<WebhookEntityEventsOrderBy>,
    ): Observable<PaginatedList<WebhookEntityEventListItem>> {
        return this.webhooksConnector.getSyncedEntityEvents({ page }, filters, orderBy);
    }
}
