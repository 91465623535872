import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-template-common-setting',
    template: `
        <div class="d-flex flex-fill" [ngClass]="rowClass">
            <div class="form-group w-100 mb-0">
                <label class="form-default-label uppercase" for="templateName">Template Name</label>
                <div class="input">
                    <input
                        id="templateName"
                        class="form-control form-control-name"
                        type="text"
                        placeholder="Template name"
                        ruumInputHighlight
                        required
                        [disabled]="disabled"
                        [ngModel]="name"
                        (ngModelChange)="changeName($event)"
                        ngbAutoFocus
                        [ngClass]="{ 'border-danger': nameErrorMessage !== undefined }"
                    />
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center mt-0 ml-5" *ngIf="nameErrorMessage !== undefined">
            <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                <i class="icon icon-cancel"></i>
            </button>
            <div class="text-tiny text-danger">{{ nameErrorMessage }}</div>
        </div>
        <div class="d-flex flex-fill mt-5 border-bottom border-light" [ngClass]="rowClass">
            <div class="form-group w-100 mb-0">
                <label class="form-default-label uppercase" for="description">Description</label>
                <div class="input">
                    <textarea
                        id="description"
                        class="form-control p-2"
                        type="text"
                        placeholder="Provide some details about this template..."
                        ruumInputHighlight
                        [disabled]="disabled"
                        [ngModel]="description"
                        (ngModelChange)="changeDescription($event)"
                        [ngClass]="{ 'border-danger': descriptionErrorMessage !== undefined }"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center mt-0 ml-5" *ngIf="descriptionErrorMessage !== undefined">
            <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                <i class="icon icon-cancel"></i>
            </button>
            <div class="text-tiny text-danger">{{ descriptionErrorMessage }}</div>
        </div>

        <div class="d-flex flex-fill py-4 pb-5 border-bottom border-light" [ngClass]="rowClass">
            <div class="d-flex flex-fill align-items-center">
                <span class="text-dark text-small mr-2">Cover color</span>
            </div>
            <ruum-template-cover-color-picker
                [theme]="coverColor"
                [disabled]="disabled"
                (changed)="changeCoverColor($event)"
            ></ruum-template-cover-color-picker>
        </div>

        <div class="d-flex flex-fill py-4 border-bottom border-light" [ngClass]="rowClass">
            <div class="d-flex flex-fill align-items-center">
                <span class="text-dark text-small mr-2">Cover icon</span>
            </div>
            <ruum-template-cover-icon-picker
                [icon]="coverIcon | ruumTemplateIcon"
                [disabled]="disabled"
                (changed)="changeCoverIcon($event)"
            ></ruum-template-cover-icon-picker>
        </div>

        <ng-container *ngIf="showProcessSettings">
            <h4 class="px-5 pt-4 pb-0">Process</h4>

            <div class="d-flex flex-fill pt-5 border-bottom border-light" [ngClass]="rowClass">
                <div>
                    <label class="text-dark text-small" for="startingSection">Starting Section</label>
                    <ruum-select-controller
                        id="startingSection"
                        size="sm"
                        selectTitle="Starting Section"
                        placeholder="Select Starting Section"
                        [disabled]="disabled"
                        [options]="sections"
                        [search]="true"
                        [withUnselect]="false"
                        [ngModel]="processStartSection"
                        (ngModelChange)="changeProcessStartSection($event)"
                    ></ruum-select-controller>
                    <p class="mt-1 text-small text-secondary">
                        Select the section, instances of this template will start with
                    </p>
                </div>
            </div>

            <div class="d-flex flex-fill py-4 border-bottom border-light px-5" [ngClass]="rowClass">
                <button class="btn btn-sm btn-link-primary px-0 w-100" (click)="navigateToStartSettings()">
                    <span class="w-100 d-flex">Start Process by Trigger</span>
                    <i class="icon icon-cevron-right"></i>
                </button>
            </div>
        </ng-container>
        <div *ngIf="!editCoverOnly">
            <h4 class="px-5 pt-4 pb-0">Template Instances</h4>

            <div class="d-flex flex-fill pt-5" [ngClass]="rowClass">
                <div>
                    <div class="checkbox form-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            [disabled]="disabled"
                            [ngModel]="blockName"
                            (ngModelChange)="changeBlockTitle($event)"
                        />
                        <label class="form-check-label text-small btn-link-dark" for="blockName">Lock Title</label>
                    </div>
                    <p class="mt-1 text-small text-secondary">
                        The title of Ruums created from this Template will be locked. A unique number will be added
                        after this Template's title.
                    </p>
                    <p class="mt-1 text-small text-secondary">e.g. {{ name }} - 01</p>
                </div>
            </div>

            <div class="d-flex flex-fill pt-2 border-bottom border-light" [ngClass]="rowClass">
                <div>
                    <div class="checkbox form-check">
                        <input
                            id="blockSection"
                            type="checkbox"
                            class="form-check-input"
                            [disabled]="disabled"
                            [ngModel]="blockSection"
                            (ngModelChange)="changeBlockSection($event)"
                        />
                        <label class="form-check-label text-small btn-link-dark" for="blockSection"
                            >Block adding and deleting Sections</label
                        >
                    </div>
                    <p class="mt-1 text-small text-secondary">
                        No sections can be added or deleted in Ruums created from this Template.
                    </p>
                </div>
            </div>
            <div class="d-flex flex-fill pt-2 border-bottom border-light" [ngClass]="rowClass">
                <div>
                    <div class="checkbox form-check">
                        <input
                            id="blockProjectInstanceInvites"
                            type="checkbox"
                            class="form-check-input"
                            [disabled]="disabled"
                            [ngModel]="blockProjectInstanceInvites"
                            (ngModelChange)="changeBlockInvite($event)"
                        />
                        <label class="form-check-label text-small btn-link-dark" for="blockProjectInstanceInvites"
                            >Block Editors from inviting new Participants</label
                        >
                    </div>
                    <p class="mt-1 text-small text-secondary">
                        When checked, only Admins of a ruum created from this template can invite new participants.
                    </p>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateCommonSettingsComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([this.componentClass]);
    }

    @Input() name: string;
    @Input() description: string;
    @Input() coverColor: string;
    @Input() coverIcon: string;
    @Input() blockProjectInstanceInvites: boolean;
    @Input() showProcessSettings = false;
    @Input() blockName = false;
    @Input() blockSection = false;

    @Input() disabled: boolean;
    @Input() componentClass = '';
    @Input() rowClass = 'px-5';
    @Input() sections: { id: string; title: string }[] = [];
    @Input() processStartSection: string;

    @Input() editCoverOnly: boolean;

    @Input() nameErrorMessage: string | undefined;
    @Input() descriptionErrorMessage: string | undefined;

    @Output() nameChanged = new EventEmitter<string>();
    @Output() descriptionChanged = new EventEmitter<string>();
    @Output() coverColorChanged = new EventEmitter<string>();
    @Output() coverIconChanged = new EventEmitter<string>();
    @Output() blockProjectInstanceInvitesChanged = new EventEmitter<boolean>();
    @Output() blockNameChange = new EventEmitter<boolean>();
    @Output() blockSectionChange = new EventEmitter<boolean>();
    @Output() processStartSectionChange = new EventEmitter<object>();

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {}

    changeName(event: string) {
        this.nameChanged.emit(event);
    }

    changeDescription(event: string) {
        this.descriptionChanged.emit(event);
    }

    changeCoverColor(event: string) {
        this.coverColorChanged.emit(event);
    }

    changeCoverIcon(event: string) {
        this.coverIconChanged.emit(event);
    }

    changeBlockInvite(isBlockInvite: boolean) {
        this.blockProjectInstanceInvitesChanged.emit(isBlockInvite);
    }

    changeBlockTitle(blockName: boolean) {
        this.blockNameChange.emit(blockName);
    }

    changeBlockSection(blockSection: boolean) {
        this.blockSectionChange.emit(blockSection);
    }

    changeProcessStartSection(sectionId: string) {
        this.processStartSectionChange.emit(this.sections.find((s) => s.id === sectionId));
    }

    radioButtonGroupSelectionChanged(evt: string) {
        console.log(`Radio Button Group Output Event: Button-Id: ${evt}`);
    }

    navigateToStartSettings() {
        this.router.navigate([{ outlets: { sidepanel: ['start-settings'] } }], {
            relativeTo: this.route.root.firstChild.firstChild,
        });
    }
}
