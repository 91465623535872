import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AddNewItemModule } from './add-new-item/add-new-item.module';
import { MaxContentComponent } from './max-content.component';
import { ModalDialogModule } from './modal-dialog/modal-dialog.module';
import { ViewControlsModule } from './view-controls/view-controls.module';

@NgModule({
    declarations: [MaxContentComponent],
    imports: [CommonModule, NgbModalModule, AddNewItemModule, ViewControlsModule, ModalDialogModule],
    exports: [AddNewItemModule, ViewControlsModule, ModalDialogModule, MaxContentComponent],
})
export class LayoutsModule {}
