import { EntityAction, RuumAction } from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../paginatedList.model';
import { WorkspaceListItem } from '../readModelConnector.service';

export const WORKSPACE_LIST_ACTION_TYPES = {
    WORKSPACE_LIST_LOADED: 'WORKSPACE_LIST_LOADED',
    CHANGE_WORKSPACE_NAME: 'CHANGE_WORKSPACE_NAME',
};

export type WorkspaceListLoadedAction = EntityAction<
    'WORKSPACE_LIST_LOADED',
    {
        page: PaginatedList<WorkspaceListItem>;
    }
>;

export function WorkspaceListReducer(
    currentState: PaginatedList<WorkspaceListItem> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<WorkspaceListItem> {
    switch (action.type) {
        case WORKSPACE_LIST_ACTION_TYPES.WORKSPACE_LIST_LOADED:
            return listLoaded(currentState, action.payload.page);
        case WORKSPACE_LIST_ACTION_TYPES.CHANGE_WORKSPACE_NAME:
            return renameWorkspace(currentState, action);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<WorkspaceListItem>,
    page: PaginatedList<WorkspaceListItem>,
): PaginatedList<WorkspaceListItem> {
    if (parseInt(page.currentPage as any, 10) > 1) {
        return {
            ...page,
            rows: currentState.rows.concat(page.rows),
        };
    } else {
        return page;
    }
}

function renameWorkspace(
    currentState: PaginatedList<WorkspaceListItem>,
    action: RuumAction,
): PaginatedList<WorkspaceListItem> {
    const payload = action.payload;
    const rows = currentState.rows.map((row) => {
        if (row.id === payload.id) {
            return {
                ...row,
                name: payload.name,
            };
        } else {
            return row;
        }
    });

    return {
        ...currentState,
        rows,
    };
}
