import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AppState } from '../../common/app.store';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { ProjectServiceBackendConnector } from '../../common/connectors/projectServiceConnector.service';
import { ReadModelBackendConnector } from '../../common/connectors/readModelConnector.service';
import { TaskListConnector } from '../../common/connectors/tasks/task-list.connector';
import { TrackingConnector } from '../../common/trackingConnector.service';
import { CanvasBackendConnector } from '../../ruum/canvas/canvas.backendConnector';

export class KanbanGroupTaskConnector extends TaskListConnector {
    constructor(
        protected readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected canvasConnector: CanvasBackendConnector,
        protected trackingConnector: TrackingConnector,
        protected authService: AuthService,
        private store: Store<AppState>,
        private kanbanGroupId: string,
    ) {
        super(
            readModelConnector,
            alertService,
            projectServiceConnector,
            canvasConnector,
            trackingConnector,
            authService,
        );
    }

    getStoreData$(): Observable<PaginatedList<string>> {
        return this.store.select('kanban').pipe(map((kanban) => kanban[this.kanbanGroupId]));
    }
}
