import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SavedViewColumn } from '../../../common/connectors/savedView/saved-views.model';

@Component({
    selector: 'ruum-task-list-item-custom-field-date',
    template: `
        {{ value }}
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListItemCustomFieldDateComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex py-2';

    @Input() column: SavedViewColumn;

    @Input() taskListItem;

    get value() {
        const customFieldsValues = this.taskListItem.customFieldsValues || {};
        const date = customFieldsValues[this.column.fieldId];
        if (date) {
            return new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0).format('DD MMM YYYY');
        } else {
            return '';
        }
    }

    constructor() {}

    ngOnInit() {}
}
