import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssignedCustomField, CustomFieldValue, CustomFieldValueSelectionList } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { AppState } from '../../../app.store';
import { RuumAlertService } from '../../../components/alert/alert.service';
import { ServiceHelper } from '../../../serviceHelper';
import { CustomFieldsService } from '../../customFields/customFields.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../../paginatedList.model';
import { PaginatedListLoader } from '../../paginatedListLoader';
import { ReadModelBackendConnector } from '../../readModelConnector.service';
import { CustomFieldListSortBy } from '../../workspace/customFieldsList/customFieldsList.service';
import { SelectedWorkspaceService } from '../../workspace/selected-workspace.service';
import { ProjectCustomFieldsValuesFilters, ProjectCustomFieldValue } from './customFieldsValues.model';
import { CustomFieldsValuesLoadedAction } from './customFieldsValues.reducer';

interface FieldValueMap {
    [id: string]: ProjectCustomFieldValue;
}

@Injectable({ providedIn: 'root' })
export class ProjectCustomFieldsValuesService extends PaginatedListLoader<
    ProjectCustomFieldValue,
    ProjectCustomFieldsValuesFilters,
    CustomFieldListSortBy
> {
    readonly projectId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly search$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    readonly assignedCustomFieldsToProjectTask$: Observable<AssignedCustomField[]>;
    readonly assignedCustomFieldsToProject$: Observable<AssignedCustomField[]>;
    readonly customFieldValue$: Observable<CustomFieldValue[]>;
    readonly assignedCustomFieldsValue$: Observable<CustomFieldValue[]>;

    private orderBy$ = new BehaviorSubject<OrderedListParams<CustomFieldListSortBy>>(undefined);

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        private customFieldsService: CustomFieldsService,
        protected authService: AuthService,
        private selectedWorkspaceService: SelectedWorkspaceService,
    ) {
        super(alertService, authService);
        this.setUpObservables();

        this.customFieldValue$ = this.customFieldsValues().pipe(
            switchMap((values) => {
                const fieldMap: FieldValueMap = {};
                values.forEach((val) => (fieldMap[val.id] = val));
                return this.getFieldDefinitionWithValue(fieldMap);
            }),
        );
        this.assignedCustomFieldsToProject$ = this.store
            .select('selectedProject')
            .pipe(map((ruum) => ruum.project.configuration.projectCustomFields));
        this.assignedCustomFieldsToProjectTask$ = this.store
            .select('selectedProject')
            .pipe(map((ruum) => ruum.project.configuration.taskCustomFields));
        this.assignedCustomFieldsValue$ = combineLatest([
            this.customFieldValue$.pipe(debounceTime(200)), // the debounce is needed because customFieldValue on change is empty
            this.assignedCustomFieldsToProject$,
        ]).pipe(
            map(([customFields, assignedCustomFields]) => {
                const fieldIds: string[] = [];
                const isMultiSelectMap = {};
                for (const assignedCustomField of assignedCustomFields) {
                    fieldIds.push(assignedCustomField.id);
                    if (assignedCustomField.isMultiSelect) {
                        isMultiSelectMap[assignedCustomField.id] = assignedCustomField.isMultiSelect;
                    }
                }
                return customFields
                    .filter((customfield) => fieldIds.includes(customfield.id))
                    .map<CustomFieldValue>((customfield) => {
                        if (customfield.type === 'selection_list' && isMultiSelectMap[customfield.id]) {
                            return {
                                ...customfield,
                                isMultiSelect: isMultiSelectMap[customfield.id],
                            } as CustomFieldValueSelectionList;
                        }
                        return customfield;
                    });
            }),
        );
    }

    customFieldsValues(): Observable<ProjectCustomFieldValue[]> {
        return this.store.select('projectCustomFieldsValues').pipe(map((page) => page.rows));
    }

    orderBy(by: CustomFieldListSortBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
    }

    getFullCustomFieldList() {
        return this.readModelConnector
            .getCustomFields(
                { page: 1, pageSize: 200 },
                { workspaceId: this.selectedWorkspaceService.getSelectedWorkspaceId() },
                { by: 'title' },
            )
            .pipe(map((page) => page.rows))
            .toPromise();
    }

    protected getData(
        page: number,
        filters: ProjectCustomFieldsValuesFilters,
        orderBy: OrderedListParams<CustomFieldListSortBy>,
    ): Observable<PaginatedList<ProjectCustomFieldValue>> {
        return this.readModelConnector.getProjectCustomFieldsValues({ page }, filters, orderBy);
    }

    protected getFilters$(): Observable<ProjectCustomFieldsValuesFilters> {
        return combineLatest([this.projectId$, this.search$]).pipe(
            map(([projectId, search]) => {
                return {
                    projectId,
                    search,
                };
            }),
        );
    }

    protected getOrderBy$(): Observable<OrderedListParams<CustomFieldListSortBy>> {
        return this.orderBy$;
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page) => {
            this.serviceHelper.dispatchWithoutPersisting<CustomFieldsValuesLoadedAction>(
                'PROJECT_CUSTOM_FIELDS_VALUES_LOADED',
                {
                    page,
                },
            );
        });
    }

    private getFieldDefinitionWithValue(fieldMap: FieldValueMap): Observable<CustomFieldValue[]> {
        const fieldIds = Object.keys(fieldMap);
        return this.customFieldsService.dataList(fieldIds).pipe(
            map((fields) =>
                fields.map((field) => {
                    return <CustomFieldValue>{
                        ...field,
                        value: fieldMap[field.id].value,
                    };
                }),
            ),
        );
    }

    getStoreData$(): Observable<PaginatedList<ProjectCustomFieldValue>> {
        return this.store.select('projectCustomFieldsValues');
    }
}
