import { Pipe, PipeTransform } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';

@Pipe({
    name: 'customFieldDate',
})
export class CustomFieldDatePipe implements PipeTransform {
    transform(value: Day, ...args: any[]): any {
        if (value) {
            return new Date(value.year, value.month - 1, value.day);
        }
        return null;
    }
}
