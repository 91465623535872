import { RuumAction, RuumStatusValues } from '@ruum/ruum-reducers';
import { LobbyListItem } from '../common/connectors/lobbyList/lobby-list.model';
import { LobbyListReducer } from '../common/connectors/lobbyList/lobby-list.reducer';
import { PaginatedList } from '../common/connectors/paginatedList.model';
import { ACTION_TYPES } from './../common/actions';
import { Lobby, SelectableLobbyListItemStatus, SelectableTag } from './lobby.model';

const getDefaultLobby = (): Lobby => {
    const webappSuportedStatus = [...RuumStatusValues, { statusText: 'No Status' }];

    return {
        lobbyList: getDefaultPaginatedList<LobbyListItem>(),
        tagList: [],
        statusList: webappSuportedStatus.map((status) => ({ statusId: status.statusText })),
    };
};

export function getDefaultPaginatedList<T>(): PaginatedList<T> {
    return {
        currentPage: 1,
        pageSize: 25,
        rows: [],
        totalItems: 0,
    };
}

export function LobbyReducer(currentState: Lobby = getDefaultLobby(), action: RuumAction): Lobby {
    switch (action.type) {
        case ACTION_TYPES.LOBBY_LIST_TAGS_LOADED:
            return { ...currentState, tagList: action.payload.tags.map((tag) => ({ tag, selected: false })) };
        case ACTION_TYPES.ADD_TAG_TO_ITEM:
            return forwardToChildren(addTagToRuum(currentState, action.payload.tag), action);
        default:
            return forwardToChildren(currentState, action);
    }
}

function addTagToRuum(currentState: Lobby, tag: string): Lobby {
    if (currentState.tagList.find((t) => t.tag === tag)) {
        return currentState;
    } else {
        return {
            ...currentState,
            tagList: [...currentState.tagList, { tag, selected: false }],
        };
    }
}

function forwardToChildren(currentState: Lobby, action: RuumAction): Lobby {
    return {
        ...currentState,
        ...{
            lobbyList: LobbyListReducer(currentState.lobbyList, action),
            statusList: StatusListReducer(currentState.statusList, action),
            tagList: TagListReducer(currentState.tagList, action),
        },
    };
}

function StatusListReducer(
    currentState: SelectableLobbyListItemStatus[] = [],
    action: RuumAction,
): SelectableLobbyListItemStatus[] {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_LOBBY_LIST_STATUS_FILTER:
            return currentState.map((status) => {
                // set the selected state of Lobby status field to true to enable,
                // else false to disable the Lobby status field
                if (action.payload.values.indexOf(status.statusId) !== -1) {
                    return {
                        ...status,
                        selected: action.payload.isSelected,
                    };
                } else {
                    return {
                        ...status,
                        selected: !action.payload.isSelected,
                    };
                }
            });
        case ACTION_TYPES.UNSET_STATUS_FILTER:
            return currentState.map((status) => Object.assign(status, { selected: false }));
        default:
            return currentState;
    }
}

function TagListReducer(currentState: SelectableTag[] = [], action: RuumAction): SelectableTag[] {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_LOBBY_LIST_TAG_FILTER:
            // set the selected state of Lobby status field to true to enable,
            // else false to disable the Lobby status field
            return currentState.map((tagInfo) => {
                if (action.payload.values.indexOf(tagInfo.tag) !== -1) {
                    return {
                        ...tagInfo,
                        selected: action.payload.isSelected,
                    };
                } else {
                    return {
                        ...tagInfo,
                        selected: !action.payload.isSelected,
                    };
                }
            });
        case ACTION_TYPES.UNSET_LOBBY_LIST_TAG_FILTER:
            return currentState.map((tag) => ({ ...tag, selected: false }));
        default:
            return currentState;
    }
}
