import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FunctionalRole, ProjectGroup, Ruum, RuumTask, Template, Workspace } from '@ruum/ruum-reducers';
import { TemplateCollaboratorsComponent } from '../../shared/templates/template-sharing/invite-collaborators-dialog.component';
import { RuumDropdownList } from '../../shared/ui-components/dropdown/dropdown.model';
import { RuumAlertService } from '../components/alert/alert.service';
import { SelectedTemplateService } from '../connectors/templates/selectedTemplate.service';
import { ProjectServiceBackendConnector } from './../connectors/projectServiceConnector.service';
import { RuumTeamInviteDialogComponent } from './team-invite-dialog.component';

@Injectable({ providedIn: 'root' })
export class TeamInviteDialogService {
    constructor(
        private ruumAlertService: RuumAlertService,
        private modalService: NgbModal,
        private selectedTemplate: SelectedTemplateService,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
    ) {}

    inviteProjectMembers(
        project: Ruum,
        roles: RuumDropdownList,
        emails: string[] = [],
        functionalRole?: FunctionalRole,
        task?: RuumTask,
    ): Promise<any> {
        const modalRef: NgbModalRef = this.modalService.open(RuumTeamInviteDialogComponent);
        modalRef.componentInstance.id = project.id;
        modalRef.componentInstance.name = project.name;
        modalRef.componentInstance.roles = roles;
        modalRef.componentInstance.preallocatedEmail = emails.join(',');
        modalRef.componentInstance.functionalRole = functionalRole;
        modalRef.componentInstance.task = task;

        return modalRef.result.then(
            ({ participants, personalMessage }) => {
                const inviteesWithRole = participants.map((participant) => ({
                    ...participant,
                    ...(functionalRole && { functionalRoles: [functionalRole.id] }),
                }));
                this.projectServiceBackendConnector
                    .inviteToProject(project.id, inviteesWithRole, personalMessage, task ? task.id : null)
                    .catch((err) => {
                        return this.ruumAlertService.warning({
                            title: `${err.error.message || 'Error inviting users'}`,
                            actionText: 'Ok',
                        });
                    });
            },
            (error) => {
                return Promise.reject(error);
            },
        );
    }

    inviteProjectGroupMembers(group: ProjectGroup, roles: RuumDropdownList): Promise<any> {
        const modalRef: NgbModalRef = this.modalService.open(RuumTeamInviteDialogComponent);
        modalRef.componentInstance.id = group.id;
        modalRef.componentInstance.name = group.name;
        modalRef.componentInstance.roles = roles;
        return modalRef.result.then(
            ({ participants, personalMessage }) => {
                this.projectServiceBackendConnector.addParticipantsToProjectGroup(
                    group.id,
                    participants,
                    personalMessage,
                );
            },
            (error) => {
                return Promise.reject(error);
            },
        );
    }

    inviteWorkspaceMembers(workspace: Workspace, roles: RuumDropdownList, emails: string[] = []): Promise<any> {
        const modalRef: NgbModalRef = this.modalService.open(RuumTeamInviteDialogComponent);
        modalRef.componentInstance.id = workspace.id;
        modalRef.componentInstance.name = workspace.name;
        modalRef.componentInstance.preallocatedEmail = emails.join(',');
        modalRef.componentInstance.roles = roles;
        return modalRef.result.then(
            ({ participants, personalMessage }) => {
                this.projectServiceBackendConnector.inviteToWorkspace(workspace.id, participants, personalMessage);
            },
            (error) => {
                return Promise.reject(error);
            },
        );
    }

    inviteTemplateMembers(template: Template, roles): Promise<any> {
        const modalRef: NgbModalRef = this.modalService.open(TemplateCollaboratorsComponent);
        modalRef.componentInstance.id = template.id;
        modalRef.componentInstance.workspaceId = template.workspaceId;
        modalRef.componentInstance.name = template.name;
        modalRef.componentInstance.roles = roles;
        return modalRef.result.then(
            ({ participants, personalMessage }) => {
                this.selectedTemplate.inviteUserToTemplate(template.id, participants, personalMessage).catch((err) => {
                    return this.ruumAlertService.warning({
                        title: `${err.error.message || 'Error inviting users'}`,
                        actionText: 'Ok',
                    });
                });
            },
            (error) => {
                return Promise.reject(error);
            },
        );
    }
}
