import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { AddCommentService } from '../addComment.service';
import { AddCommentData } from './add-comment-text-input.component';

@Component({
    selector: 'add-comment',
    template: `
        <at-mention-list></at-mention-list>
        <add-comment-text-input (add)="add.emit($event)" (errorMessage)="emitErrorMessage($event)">
        </add-comment-text-input>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCommentComponent implements OnChanges {
    @HostBinding('class') hostClassList = 'd-flex flex-fill pl-4 pb-1 pr-4 bg-white';
    @Input() projectId: string;
    @Output() add = new EventEmitter<AddCommentData>();
    @Output() errorMessage = new EventEmitter();

    constructor(private addCommentService: AddCommentService) {}

    emitErrorMessage($event) {
        this.errorMessage.emit($event);
    }

    ngOnChanges() {
        this.addCommentService.projectId = this.projectId;
    }
}
