import { Pipe, PipeTransform } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';

@Pipe({ name: 'ruumDatepickerDate' })
export class DatepickerDatePipe implements PipeTransform {
    transform(day?: Day): Date {
        if (day) {
            return new Date(day.year, day.month - 1, day.day);
        } else {
            return new Date();
        }
    }
}
