import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { BackendConnector } from './../common/backendConnector.service';
import { EnterpriseReport } from './dashboard.model';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    reportData$: BehaviorSubject<EnterpriseReport> = new BehaviorSubject<EnterpriseReport>(undefined);

    private subscriptions: Subscription[] = [];

    constructor(
        private backendConnector: BackendConnector,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {
        this.subscriptions = [
            this.selectedEnterpriseService.selectedEnterpriseId().subscribe((enterpriseId) => {
                if (enterpriseId) {
                    this.loadReport(enterpriseId);
                }
            }),
        ];
    }

    getReport(): BehaviorSubject<EnterpriseReport> {
        return this.reportData$;
    }

    private loadReport(enterpriseId: string) {
        this.backendConnector
            .getEnterpriseReport(enterpriseId)
            .then((reportData) => this.reportData$.next(reportData))
            .catch(() => this.reportData$.next(undefined));
    }
}
