import {
    RuumAction,
    FileSystemConfiguration,
    replaceItemAtIndex,
    ProjectReducerDecoratorsActionTypes,
} from '@ruum/ruum-reducers';
import { SharepointConfiguration } from '../ruum.model';

export function ExternalFileSystemConfigurationsReducerDecorator(
    fileSystemConfigurations: FileSystemConfiguration[] = [],
    action: RuumAction,
): FileSystemConfiguration[] {
    let newState: FileSystemConfiguration[];

    switch (action.type) {
        case ProjectReducerDecoratorsActionTypes.SET_MS_ACCESS_TOKEN:
            newState = fileSystemConfigurations.map((config) => {
                if (config.type !== 'sharepoint') {
                    return config;
                }
                const sharepointConfig: SharepointConfiguration = config as SharepointConfiguration;
                return { ...sharepointConfig, ...{ msAccessToken: action.payload.msAccessToken } };
            });
            break;
        default:
            newState = fileSystemConfigurations;
    }

    return newState;
}

function getConfigurationIndex(
    configuration: FileSystemConfiguration,
    allConfigurations: FileSystemConfiguration[],
): number {
    if (configuration.type === 'sharepoint') {
        const sharepointConfiguration: SharepointConfiguration = configuration as SharepointConfiguration;
        return allConfigurations
            .map((config: FileSystemConfiguration) =>
                config.type === 'sharepoint' ? (config as SharepointConfiguration) : undefined,
            )
            .findIndex((config: SharepointConfiguration) =>
                config ? config.parentItemId === sharepointConfiguration.parentItemId : false,
            );
    }
    return -1;
}
