import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, MemoizedSelector, select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../app.store';
import { ServiceHelper } from '../../serviceHelper';
import { SelectedProjectService } from '../project/selectedProject.service';
import { SelectedProjectGroupService } from '../projectGroup/selectedProjectGroup.service';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { SavedViewFieldsService } from './fields/saved-view-fields.service';
import { SavedViewColumnsService } from './saved-view-columns.service';
import { SavedViewFiltersService } from './saved-view-filters.service';
import { SavedViewVisualizationsService } from './saved-view-visualizations.service';
import {
    SavedVieCloneViewAction,
    SavedViewAddNewViewAction,
    SavedViewSelectAction,
    SavedViewUnselectAction,
    SavedViewUpdateColumnsAction,
    SavedViewUpdateFiltersAction,
    SAVED_VIEW_ADD_NEW_VIEW,
    SAVED_VIEW_CLONE_VIEW,
    SAVED_VIEW_SELECT,
    SAVED_VIEW_UNSELECT,
    SAVED_VIEW_UPDATE_COLUMNS,
    SAVED_VIEW_UPDATE_FILTERS,
} from './saved-views.action';
import {
    SavedView,
    SavedViewColumnValue,
    SavedViewFieldDefinition,
    SavedViewFilter,
    SavedViewFilterValue,
    SavedViewType,
    SavedViewValue,
} from './saved-views.model';
import { SavedViewsState } from './saved-views.reducer';

@Injectable({ providedIn: 'root' })
export class SelectedSavedViewService {
    private workspaceId = undefined;
    private groupId = undefined;
    private projectId = undefined;
    private savedViewsState = createFeatureSelector<SavedViewsState>('savedViews');

    private selectedSavedViewValues: MemoizedSelector<AppState, SavedViewValue> = createSelector(
        this.savedViewsState,
        (state: SavedViewsState) => {
            return {
                ...state.entities[state.selectedSavedViewId],
            };
        },
    );

    selectedSavedViewId: string;

    constructor(
        private store: Store<AppState>,
        private serviceHelper: ServiceHelper,
        private savedViewFieldsService: SavedViewFieldsService,
        private savedViewVisualizationsService: SavedViewVisualizationsService,
        private savedViewFiltersService: SavedViewFiltersService,
        private savedViewColumnsService: SavedViewColumnsService,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private selectedProjectGroupService: SelectedProjectGroupService,
        private selectedProjectService: SelectedProjectService,
    ) {
        this.getSelectedSavedView()
            .pipe(filter((savedView) => !!savedView.id))
            .subscribe((savedView) => {
                this.applyFilters(savedView.filters, savedView.viewType);
            });
    }

    selectSavedView(selectedSavedViewId: string): Promise<void> {
        this.selectedSavedViewId = selectedSavedViewId;

        return this.serviceHelper.dispatchWithoutPersisting<SavedViewSelectAction>(SAVED_VIEW_SELECT, {
            selectedSavedViewId,
        });
    }

    unselectSavedView(): Promise<void> {
        this.selectedSavedViewId = null;
        return this.serviceHelper.dispatchWithoutPersisting<SavedViewUnselectAction>(SAVED_VIEW_UNSELECT, {
            selectedSavedViewId: null,
        });
    }

    createSavedViewIfNotExists(id: string, templateId: string) {
        return this.serviceHelper.dispatchWithoutPersisting<SavedVieCloneViewAction>(SAVED_VIEW_CLONE_VIEW, {
            id,
            templateId,
        });
    }

    addSavedView(savedView: SavedViewValue) {
        const { id, name, filterValues, columnValues, viewType: viewType } = { ...savedView };
        return this.serviceHelper.dispatchWithoutPersisting<SavedViewAddNewViewAction>(SAVED_VIEW_ADD_NEW_VIEW, {
            id,
            name,
            filterValues,
            columnValues,
            viewType,
        });
    }

    /** Get data of view data */
    getSelectedSavedView(): Observable<SavedView> {
        return combineLatest([this.getSelectedSavedViewValue()]).pipe(
            switchMap(([savedViewValue]) => {
                const columnsFieldIds = savedViewValue.columnValues.map((column) => column.fieldId);
                const filterFieldIds = savedViewValue.filterValues.map((column) => column.fieldId);
                const fieldIds = [...columnsFieldIds, ...filterFieldIds];

                return combineLatest([of(savedViewValue), this.savedViewFieldsService.getFieldsMap(fieldIds)]);
            }),
            switchMap(([savedViewValue, fieldsMap]) => {
                return combineLatest([
                    this.getSavedViewColumns(savedViewValue.columnValues, fieldsMap),
                    this.getSavedViewFilters(savedViewValue.filterValues, fieldsMap),
                ]).pipe(
                    map(([columns, filters]) => {
                        const { id, name, viewType: viewType } = savedViewValue;
                        return { id, name, viewType, columns, filters };
                    }),
                );
            }),
            distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        );
    }

    applyFilters(filters: SavedViewFilter[], viewType: SavedViewType) {
        const workspaceId = this.selectedWorkspaceService.getSelectedWorkspaceId() || this.getWorkspaceId();
        const groupId = this.selectedProjectGroupService.getSelectedGroupId() || this.getGroupId();
        const projectId = this.selectedProjectService.getSelectedProjectId() || this.getProjectId();

        if (['homeLobbyList', 'workspaceLobbyList', 'groupLobbyList'].indexOf(viewType) !== -1) {
            this.savedViewFiltersService.applyLobbyListFilters(filters, workspaceId, groupId);
        }
        if (['homeTaskList', 'workspaceTaskList', 'groupTaskList', 'projectTaskList'].indexOf(viewType) !== -1) {
            this.savedViewFiltersService.applyTaskListFilters(filters, workspaceId, groupId, projectId);
        }

        if (['projectKanban'].indexOf(viewType) !== -1) {
            this.savedViewFiltersService.applyKanbanFilters(filters, workspaceId, groupId, projectId);
        }

        /** applying filter to approval list in Lobby, workspace, group */
        if (['homeApprovalList', 'workspaceApprovalList', 'groupApprovalList'].indexOf(viewType) !== -1) {
            this.savedViewFiltersService.applyApprovalListFilters(filters, workspaceId, groupId, projectId);
        }
    }

    getAllAvailableColumns() {
        return this.savedViewFieldsService.getFields$({ excludedIds: [], relativeTo: 'columns' }).pipe(
            switchMap((fields) => {
                const typeIds = fields.map((field) => field.typeId);

                return combineLatest([of(fields), this.savedViewColumnsService.getColumnsMapByType(typeIds)]);
            }),
            map(([fields, columnsMap]) => {
                return fields.map((field) => {
                    return {
                        ...field,
                        ...columnsMap[field.typeId],
                    };
                });
            }),
        );
    }

    updateFilters(filters: SavedViewFilterValue[]) {
        return this.serviceHelper.dispatchWithoutPersisting<SavedViewUpdateFiltersAction>(SAVED_VIEW_UPDATE_FILTERS, {
            id: this.selectedSavedViewId,
            filters,
        });
    }

    updateColumns(columns: SavedViewColumnValue[]) {
        return this.serviceHelper.dispatchWithoutPersisting<SavedViewUpdateColumnsAction>(SAVED_VIEW_UPDATE_COLUMNS, {
            id: this.selectedSavedViewId,
            columns,
        });
    }

    getColumnsByViewType(viewId: string): SavedViewColumnValue[] {
        const columnFieldIds = this.savedViewVisualizationsService.getColumnFieldIdsByViewType(viewId);

        return columnFieldIds.map((fieldId) => ({
            fieldId,
        }));
    }

    getSelectedSavedViewValue(): Observable<SavedViewValue> {
        return this.store.pipe(
            select(this.selectedSavedViewValues),
            filter((state) => !!state.id),
        );
    }

    setWorkspaceId(workspaceId: string) {
        this.workspaceId = workspaceId;
    }

    getWorkspaceId(): string {
        return this.workspaceId;
    }

    setGroupId(groupId: string) {
        this.groupId = groupId;
    }

    getGroupId(): string {
        return this.groupId;
    }

    setProjectId(projectId: string) {
        this.projectId = projectId;
    }

    getProjectId(): string {
        return this.projectId;
    }

    private getSavedViewFilters(
        filterValues: SavedViewFilterValue[],
        fieldsMap: { [key: string]: SavedViewFieldDefinition },
    ) {
        const fieldIds = filterValues.map((filterValue) => filterValue.fieldId);
        const typeIds = fieldIds.filter((fieldId) => !!fieldsMap[fieldId]).map((fieldId) => fieldsMap[fieldId].typeId);

        return combineLatest([this.savedViewFiltersService.getFiltersMapByType(typeIds)]).pipe(
            map(([filtersMap]) => {
                return filterValues
                    .filter((filterValue) => !!fieldsMap[filterValue.fieldId])
                    .map((filterValue) => {
                        const field = fieldsMap[filterValue.fieldId];
                        return {
                            ...field,
                            ...filtersMap[field.typeId],
                            // TODO: add filter value here
                            ...filterValue,
                        };
                    });
            }),
        );
    }

    private getSavedViewColumns(
        columnValues: SavedViewColumnValue[],
        fieldsMap: { [key: string]: SavedViewFieldDefinition },
    ) {
        const fieldIds = columnValues.map((columnValue) => columnValue.fieldId);
        const typeIds = fieldIds.filter((fieldId) => !!fieldsMap[fieldId]).map((fieldId) => fieldsMap[fieldId].typeId);

        return combineLatest([this.savedViewColumnsService.getColumnsMapByType(typeIds)]).pipe(
            map(([columnsMap]) => {
                return columnValues.map((columnValue) => {
                    const field = fieldsMap[columnValue.fieldId]; // TODO: what if value does not exist?
                    return {
                        ...field,
                        ...columnsMap[field.typeId],
                        width: columnValue.width || columnsMap[field.typeId].initialWidth,
                    };
                });
            }),
        );
    }
}
