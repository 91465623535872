import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    ListViewItem,
    SavedViewSettingsDialogComponent,
} from '../saved-view-settings-dialog/saved-view-settings-dialog.component';
import { TrackingConnector } from './../../../common/trackingConnector.service';
import { SavedViewColumnsService } from './saved-view-columns.service';

@Component({
    selector: 'ruum-saved-view-settings-dialog-button',
    template: `
        <button class="btn btn-sm btn-outline-light mr-3" (click)="showCustomizeColumnsModal()">
            <span>Customize Columns</span>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedViewSettingsDialogButtonComponent implements OnDestroy {
    @HostBinding('class.d-flex') className = true;

    @Input()
    set columns(value: ListViewItem[]) {
        this._columns$.next(value);
    }
    get columns(): ListViewItem[] {
        return this._columns$.getValue();
    }
    @Input() hiddenColumns: string[] = [];
    @Input() dialogTitle = 'Customize Columns';

    @Input()
    set hasMoreUncheckedItems(value: boolean) {
        this._hasMoreUncheckedItems$.next(value);
    }
    get hasMoreUncheckedItems(): boolean {
        return this._hasMoreUncheckedItems$.getValue();
    }

    @Output() updateColumns = new EventEmitter<ListViewItem[]>();
    @Output() searchQueryChange = new EventEmitter<string>();
    @Output() cancelSettings = new EventEmitter<void>();
    @Output() loadMore = new EventEmitter<void>();

    private _columns$ = new BehaviorSubject<ListViewItem[]>([]);
    private _hasMoreUncheckedItems$ = new BehaviorSubject<boolean>(false);
    subscriptions: Subscription[] = [];

    constructor(
        private modalService: NgbModal,
        private savedViewColumnsService: SavedViewColumnsService,
        private trackingConnector: TrackingConnector,
    ) {}

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    showCustomizeColumnsModal(): void {
        this.trackingConnector.trackEvent('saved_view', 'customize_columns_menu', 'opened');

        const instance: NgbModalRef = this.modalService.open(SavedViewSettingsDialogComponent, {
            centered: true,
            size: 'lg',
        });

        instance.componentInstance.title = this.dialogTitle;
        instance.componentInstance.hiddenColumns = this.hiddenColumns;
        instance.componentInstance.listItems = this.columns;
        instance.componentInstance.searchQueryChange = this.searchQueryChange;
        instance.componentInstance.hasMoreUncheckedItems = this.hasMoreUncheckedItems;
        instance.componentInstance.loadMore = this.loadMore;
        this.initItemsSubscription(instance);
        this.initHasMoreItemsSubscription(instance);

        this.savedViewColumnsService.initLoading();

        instance.result
            .then(
                (columns: ListViewItem[]) => {
                    this.savedViewColumnsService.stopLoading();
                    this.trackingConnector.trackEvent('saved_view', 'columns', 'applied');
                    this.updateColumns.emit(columns);
                },
                () => {
                    this.trackingConnector.trackEvent('saved_view', 'columns', 'canceled');
                    this.cancelSettings.emit();
                },
            )
            .then(() => {
                this.subscriptions.forEach((s) => s.unsubscribe());
                this.savedViewColumnsService.stopLoading();
                this.trackingConnector.trackEvent('saved_view', 'columns_menu', 'closed');
            });
    }

    initItemsSubscription(instance: NgbModalRef) {
        this.subscriptions.push(
            this._columns$.subscribe((newColumns) => {
                instance.componentInstance.listItems = newColumns;
            }),
        );
    }

    initHasMoreItemsSubscription(instance: NgbModalRef) {
        this.subscriptions.push(
            this._hasMoreUncheckedItems$.subscribe((hasMore) => {
                instance.componentInstance.hasMoreUncheckedItems = hasMore;
            }),
        );
    }
}
