import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import { PaginationParams } from '../../../common/connectors/lobbyList/lobby-list.model';
import { OrderedListParams, PaginatedList } from '../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../common/connectors/paginatedListLoader';
import {
    FunctionalRolesFilters,
    ReadModelBackendConnector,
} from '../../../common/connectors/readModelConnector.service';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../ui-components/ui-components.type';

type FunctionalRolesOrderBy = any;

const emptyPage = {
    pageSize: 25,
    currentPage: 1,
    totalItems: 0,
    rows: [],
};

@Component({
    selector: 'ruum-functional-role-select',
    template: `
        <ruum-select
            [select]="select"
            [search]="true"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [loading]="loading$ | async"
            [lightBackground]="false"
            [multiSelect]="multiSelect"
            [disabled]="disabled"
            (loadMoreOptions)="loadMore()"
            (isOpenChange)="isOpenChange($event)"
            (searchChange)="searchChange($event)"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-content>
                <ruum-select-placeholder *ngIf="!(selectedOptions$ | async)?.length; else selectedOptionsTemplate">
                    {{ placeholder }}
                </ruum-select-placeholder>
            </ruum-select-content>
            <ruum-select-option
                *ngFor="let option of options$ | async"
                [value]="option.id"
                [content]="option.name"
            ></ruum-select-option>
        </ruum-select>

        <ng-template #selectedOptionsTemplate>
            <ng-container *ngIf="multiSelect; else selectedSingleSelect">
                <div
                    class="ruum-select-single-value align-items-center pl-2"
                    *ngFor="let selectedOption of selectedOptions$ | async"
                >
                    <div
                        class="text-truncate font-weight-normal"
                        [class.text-small]="size === 'sm'"
                        [class.text-body]="theme === 'light'"
                    >
                        {{ selectedOption.name }}
                    </div>
                </div>
            </ng-container>
        </ng-template>

        <ng-template #selectedSingleSelect>
            <ruum-profile-role [name]="selectedRoleName$ | async" [size]="size" [showName]="true"></ruum-profile-role>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionalRoleSelectComponent
    extends PaginatedListLoader<FunctionalRoleListItem, FunctionalRolesFilters, FunctionalRolesOrderBy>
    implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    @Input() set selectedOptionIds(ids: string[]) {
        if (!ids) {
            this.selectedOptionIdsSubject.next([]);
        } else {
            this.selectedOptionIdsSubject.next(ids);
        }
    }

    @Input() set workspaceId(value: string) {
        this.workspaceIdSubject.next(value);
    }

    @Input() set projectId(value: string) {
        this.projectIdSubject.next(value);
    }
    @Input() excludedIds: string[] = [];

    @Input() placeholder = 'Select Value';
    @Input() multiSelect = false;
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIdsSubject.getValue();
        } else {
            return this.selectedOptionIdsSubject.getValue()[0];
        }
    }

    selectedOptions$: Observable<FunctionalRoleListItem[]>;
    selectedRoleName$: Observable<string>;
    options$: Observable<FunctionalRoleListItem[]>;
    loading$: Observable<boolean>;

    private selectedOptionIdsSubject = new BehaviorSubject([]);
    private workspaceIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private projectIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private orderBySubject = new BehaviorSubject<OrderedListParams<FunctionalRolesOrderBy>>(undefined);
    private dataStoreSubject = new BehaviorSubject<PaginatedList<FunctionalRoleListItem[]>>(emptyPage);
    private ngOnDestroySubject = new Subject<void>();

    constructor(
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        private readModelBackendConnector: ReadModelBackendConnector,
    ) {
        super(alertService, authService);
    }

    ngOnInit(): void {
        this.setUpObservables();
        this.selectedOptions$ = this.getSelectedOptions();
        this.selectedRoleName$ = this.selectedOptions$.pipe(
            map((roles) => (roles && roles.length ? roles[0].name : '')),
        );
        this.options$ = this.getOptions();
        this.loading$ = this.getLoading();
    }

    ngOnDestroy(): void {
        this.stopLoadingList();
    }

    trackByOption(index: number, item: FunctionalRoleListItem): string {
        return item.id;
    }

    isOpenChange(open: boolean): void {
        if (open) {
            this.reload();
            this.dataStoreSubject.next(emptyPage);
            this.searchSubject.next('');
            this.loadList();
        } else {
            this.stopLoadingList();
        }
    }

    searchChange(search): void {
        this.reload();
        this.dataStoreSubject.next(emptyPage);
        this.searchSubject.next(search);
    }

    loadMore(): void {
        this.maybeGoToNextPage();
    }

    selectChange(event): void {
        this.changed.emit(event);
    }

    protected getData(
        page: number,
        filters: FunctionalRolesFilters,
        orderBy: OrderedListParams<FunctionalRolesOrderBy>,
    ): Observable<PaginatedList<FunctionalRoleListItem>> {
        const pagination: PaginationParams = { page, pageSize: 25 };
        return this.readModelBackendConnector.getFunctionalRoles(pagination, filters);
    }

    protected getFilters$(): Observable<FunctionalRolesFilters> {
        return combineLatest([this.searchSubject, this.workspaceIdSubject, this.projectIdSubject]).pipe(
            map<any, FunctionalRolesFilters>(([search, workspaceId, projectId]) => ({
                search,
                workspaceId,
                relativeToProjectId: projectId,
            })),
        );
    }

    protected getOrderBy$(): Observable<OrderedListParams<FunctionalRolesOrderBy>> {
        return this.orderBySubject;
    }

    protected getStoreData$(): Observable<PaginatedList<any>> {
        return this.dataStoreSubject.asObservable();
    }

    private setUpObservables(): void {
        this.getListObservable()
            .pipe(takeUntil(this.ngOnDestroySubject))
            .subscribe((page: any) => {
                const currentState = this.dataStoreSubject.getValue();
                this.dataStoreSubject.next({
                    ...page,
                    rows: [...currentState.rows, ...page.rows],
                });
            });
    }

    private getOptions(): Observable<FunctionalRoleListItem[]> {
        return this.dataStoreSubject.pipe(
            map((currentState: any) =>
                currentState.rows.filter((row: FunctionalRoleListItem) => this.excludedIds.indexOf(row.id) === -1),
            ),
        );
    }

    private getSelectedOptions(): Observable<FunctionalRoleListItem[]> {
        return combineLatest([this.selectedOptionIdsSubject.asObservable()]).pipe(
            filter(([ids]) => ids.length !== 0),
            switchMap(([ids]) => {
                return this.getDataByIds(ids);
            }),
        );
    }

    private getDataByIds(ids: string[]): Observable<FunctionalRoleListItem[]> {
        const pagination: PaginationParams = { page: 1, pageSize: ids.length };
        const filters: FunctionalRolesFilters = { id: ids };
        return this.readModelBackendConnector.getFunctionalRoles(pagination, filters).pipe(map((page) => page.rows));
    }

    private getLoading(): Observable<boolean> {
        return this.hasMore$.asObservable().pipe(startWith(true));
    }
}
