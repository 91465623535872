import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PaginationParams } from '../../../../common/connectors/lobbyList/lobby-list.model';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';
import { SelectOption } from './../../../../common/connectors/savedView/saved-views.model';
import { SavedViewsService } from './../../../../common/connectors/savedView/saved-views.service';

@Component({
    selector: 'ruum-filter-field-value-list',
    template: `
        <ng-container>
            <ruum-select
                *ngIf="options$ | async as options"
                [size]="size"
                [select]="select"
                [multiSelect]="multiSelect"
                [theme]="theme"
                [type]="'default'"
                [lightBackground]="false"
                [search]="showSearch"
                [placement]="placement"
                (selectChange)="onSelectchange($event)"
                (searchChange)="onSearchChanged($event)"
            >
                <ruum-select-placeholder>
                    {{ placeholder }}
                </ruum-select-placeholder>
                <ruum-select-option
                    *ngFor="let option of options"
                    [value]="option.fieldId"
                    [content]="option.title"
                ></ruum-select-option>
            </ruum-select>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldValueListComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    // Component properties
    @Input() set selectedOptionIds(ids: string[]) {
        this.selectedOptionIdsSource.next(ids);
    }

    get selectedOptionIds(): string[] {
        return this.selectedOptionIdsSource.getValue();
    }

    @Input() placeholder = 'Select Value';
    @Input() optionsApiRef: any;
    @Input() staticOptions: SelectOption[] = [];
    @Input() showSearch = true;
    @Input() placement = ['bottom-left', 'top-left'];
    @Input() multiSelect = false;

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIds;
        } else {
            return this.selectedOptionIds[0];
        }
    }

    options$: Observable<any>;
    fetching$: Observable<boolean>;
    searchValue: string;

    private fetchingSource = new BehaviorSubject(false);
    private searchSource = new BehaviorSubject('');
    private selectedOptionIdsSource = new BehaviorSubject([]);

    constructor(private savedViewsService: SavedViewsService) {}

    ngOnInit() {
        // disabling search for static options list
        this.showSearch = !!this.staticOptions && this.staticOptions.length === 0;
        this.options$ = this.staticOptions.length > 0 ? of(this.staticOptions) : this.getOptions();
        if (!!this.optionsApiRef) {
            this.fetching$ = this.fetchingSource.asObservable();
            this.searchSource.next('');
            this.fetchingSource.next(true);
        }
    }

    onSelectchange(event) {
        this.changed.emit(event);
    }

    onSearchChanged(searchText: string) {
        if (!!this.optionsApiRef) {
            this.searchSource.next(searchText);
            this.fetchingSource.next(true);
        }
    }

    private getOptions() {
        return combineLatest([this.fetchingSource.asObservable(), this.searchSource.asObservable()]).pipe(
            filter(([fetching, search]) => {
                return !!fetching;
            }),
            switchMap(([fetching, search]) => {
                const pagination: PaginationParams = { page: 1, pageSize: 100 };
                const apiRef = this.optionsApiRef();
                const filters = {
                    workspaceId: this.savedViewsService.getSelectedWorkspaceId(),
                    groupId: this.savedViewsService.getSelectedGroupId(),
                    name: search,
                };
                return apiRef(pagination, filters).pipe(
                    map((records: any[]) => records),
                    tap(() => this.fetchingSource.next(false)),
                );
            }),
        );
    }
}
