import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';
import { WebhookEntitySyncStateListItem } from '../webhooks.model';
import { WebhookEntitiesListService } from './webhook-entities-list.service';
@Component({
    selector: 'webhook-entities-list',
    template: `
        <ruum-admin-widget class="page">
            <div class="header">
                <div class="navbar navbar-light navbar-expand-lg">
                    <h3 class="ml-3 navbar-text">Synchronized Entities</h3>
                </div>
            </div>

            <div class="body mt-4 mb-4" scrollDetectionDirective (scrolledToBottom)="scrolledToBottom()">
                <table class="table ruum-table" *ngIf="(entities$ | async)?.length !== 0">
                    <thead>
                        <tr>
                            <th scope="col">Entity</th>
                            <th scope="col">Status</th>
                            <th scope="col">Current Entity Version</th>
                            <th scope="col">Last Entity Version</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let entity of entities$ | async"
                            [ngClass]="{ error: entity.currentState === 'ERROR' }"
                        >
                            <td class="text-truncate">
                                <a
                                    routerLink="/admin/enterprise/{{
                                        selectedEnterpriseService.selectedEnterpriseId() | async
                                    }}/webhooks/{{ webhookId }}/entities/{{ entity.entityId }}/events"
                                >
                                    {{ entity.entityId }}
                                </a>
                            </td>
                            <td class="text-truncate">{{ entity.currentState }}</td>
                            <td class="text-truncate">{{ entity.currentEntityVersion }}</td>
                            <td class="text-truncate">{{ entity.lastEntityVersion }}</td>
                        </tr>
                        <!-- Loading another page -->
                        <tr *ngIf="(isLoadingAnotherPage$ | async) === true && (isLoadingFirstPage$ | async) !== true">
                            <td colspan="4">
                                <img
                                    class="ml-4 p-2"
                                    width="60"
                                    src="./../../assets/img/spinner.svg"
                                    alt="Loading Spinner"
                                />
                            </td>
                        </tr>
                        <tr
                            *ngIf="
                                (webhookEntitiesListService.hasMore$ | async) &&
                                (isLoadingAnotherPage$ | async) === false
                            "
                        >
                            <td colspan="4">
                                <button class="btn btn-link w-100" (click)="scrolledToBottom($event)">Load More</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    class="d-inline-block w-100 mt-8 text-center text-secondary"
                    *ngIf="(entities$ | async)?.length === 0"
                >
                    No entities synchronized yet
                </div>
            </div>

            <div class="footer">
                <button class="btn btn-primary float-right" type="button" (click)="refresh()">
                    Refresh
                </button>
                <button class="btn btn-outline-secondary float-right" type="button" (click)="back()">
                    Back
                </button>
            </div>
        </ruum-admin-widget>
    `,
    styles: [``],
})
export class WebhookEntitiesListComponent implements OnInit, OnDestroy {
    entities$: Observable<WebhookEntitySyncStateListItem[]> = of([]);
    subscriptions: Subscription[] = [];
    isLoadingFirstPage$: Observable<boolean>;
    isLoadingAnotherPage$: Observable<boolean>;

    webhookId: string;

    constructor(
        private route: ActivatedRoute,
        private webhookEntitiesListService: WebhookEntitiesListService,
        public selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            combineLatest([this.route.params, this.route.data])
                .pipe(filter(([routeParams, data]) => !!routeParams && !!data))
                .subscribe(([routeParams, data]) => {
                    this.entities$ = data.entities$;
                    this.webhookId = routeParams.webhookId;
                }),
        );

        this.isLoadingFirstPage$ = this.webhookEntitiesListService.isLoadingFirstPage$;
        this.isLoadingAnotherPage$ = this.webhookEntitiesListService.isLoadingAnotherPage$;
        this.webhookEntitiesListService.reload();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subs) => subs.unsubscribe());
    }

    back() {
        history.back();
    }

    refresh() {
        this.webhookEntitiesListService.reload();
    }

    scrolledToBottom() {
        this.webhookEntitiesListService.maybeGoToNextPage();
    }
}
