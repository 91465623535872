import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-select-template-shared-placeholder',
    template: `
        <h3 class="text-truncate mb-2">Shared with you</h3>
        <div class="d-flex flex-fill flex-column justify-content-center align-items-center mb-7 p-3 border rounded">
            <h4 class="text-secondary">No shared templates here yet</h4>
            <span class="text-small text-secondary py-2"
                >When someone shares a shared template with you, it appears here.
            </span>
        </div>
    `,
    styles: [
        `
            .border {
                border-style: dashed !important;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTemplateSharedPlaceholderComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill flex-column', this.componentClass]);
    }

    @Input() componentClass: string;

    constructor() {}

    ngOnInit() {}
}
