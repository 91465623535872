import { ODataEntityMetadata } from './entities/ODataEntities';

/**
 * creates a path from Entity and property
 * @param parentMetadata param from ODataEntityMetadata on missing it will take the root entity
 * @param property propertie as string
 */
export function generatePath(parentMetadata: ODataEntityMetadata, property: string): string {
    if (parentMetadata) {
        return `${parentMetadata.fullPath}/${property}`;
    } else {
        const rootPath = property.charAt(0).toUpperCase() + property.slice(1); // This is a root entity, make first letter upper case
        return rootPath;
    }
}

/**
 * creates a odata query out of object and retuns the query as string,
 * @param keys object for query parameter
 */
export function keysToString(keys: object): string {
    const keyProperties = Object.keys(keys);
    if (keyProperties.length === 1) {
        return `'${keys[keyProperties[0]]}'`; // Just return the key value
    } else {
        return keyProperties
            .map((property) => {
                if (typeof keys[property] === 'undefined') {
                    return '';
                }
                return `${property}='${keys[property]}'`;
            })
            .filter(Boolean)
            .join(',');
    }
}

/**
 * generates a odata query with path of entity, special property and params as query
 * @param parentMetadata param from ODataEntityMetadata on missing it will take the root entity
 * @param property propertie as string
 * @param keys object for query parameter
 */
export function generateMetadata(
    parentMetadata: ODataEntityMetadata,
    property: string,
    keys: object,
): ODataEntityMetadata {
    const path = generatePath(parentMetadata, property);
    let calculatedParams = '';
    if (keys) {
        const params = keysToString(keys);
        calculatedParams = params ? `(${params})` : '';
    }
    return {
        fullPath: `${path}${calculatedParams}`,
    };
}

export function accumulateFilterQuery(
    parameters: string[] = [],
    searchValue: string,
    concatType: 'and' | 'or' = 'and',
): string {
    if (!parameters || parameters.length === 0) {
        return '';
    }
    const filterString: string = parameters.reduce((filterStr, columnName) => {
        return `${filterStr} ${concatType} contains(tolower(${columnName}),'${searchValue}')`;
    }, '');
    return `${filterString.substring(filterString.indexOf('contains'))}`;
}
