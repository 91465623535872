import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RuumBootstrapModule } from '../../../common/ruum-bootstrap/ruum-bootstrap.module';
import { SearchComponent } from './search.component';

@NgModule({
    imports: [CommonModule, FormsModule, RuumBootstrapModule],
    declarations: [SearchComponent],
    exports: [SearchComponent],
})
export class SearchModule {}
