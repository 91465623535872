import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-menu-option-description',
    template: `
        <ng-content></ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuOptionDescriptionComponent implements OnInit {
    @HostBinding('class') hostClassList =
        'd-block font-weight-normal text-secondary text-small text-wrap pt-2 pl-0 pb-4';

    @HostBinding('style') hostStyle = 'white-space: normal; margin-top: -4px;';

    constructor() {}

    ngOnInit() {}
}
