import { Validators } from '@angular/forms';
import { isUri } from '@ruum/ruum-reducers';

export class CustomValidator {
    static uriValidator(uri): any {
        if (uri.pristine) {
            return null;
        }
        uri.markAsTouched();
        if (isUri(uri.value)) {
            return null;
        }
        return {
            invalidUrl: true,
        };
    }
}
