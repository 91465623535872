import { RuumAction } from '@ruum/ruum-reducers';

export const SELECTED_TASK_ACTIONS = {
    SELECT_TASK: 'SELECT_TASK',
};

export interface SelectTaskAction extends RuumAction {
    type: 'SELECT_TASK';
    payload: {
        /** project */
        taskCompositeId: string;
    };
}

export function SelectedTaskReducer(currentState: string, action: RuumAction): string {
    switch (action.type) {
        case SELECTED_TASK_ACTIONS.SELECT_TASK:
            return selectTask(action);
        default:
            return currentState;
    }
}

function selectTask(action: SelectTaskAction): string {
    return action.payload.taskCompositeId;
}
