import { EntityAction, RuumAction } from '@ruum/ruum-reducers';

export interface FocusState {
    focusId?: string;
    previousFocusId?: string;
}

export enum FOCUS_ACTION_TYPES {
    SET_FOCUS = 'SET_FOCUS',
}

export type SetFocusAction = EntityAction<
    'SET_FOCUS',
    {
        focusId: string;
        previousFocusId: string;
    }
>;

export function FocusListReducer(currentState: FocusState = {}, action: RuumAction): FocusState {
    switch (action.type) {
        case FOCUS_ACTION_TYPES.SET_FOCUS:
            return {
                ...currentState,
                focusId: action.payload.focusId,
                previousFocusId: action.payload.previousFocusId,
            };
        default:
            return currentState;
    }
}
