import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'error-dialog',
    template: `
        <div class="modal-header">
            <h1 class="modal-title">Error</h1>
        </div>
        <div class="modal-body">
            <div *ngFor="let message of messages">
                {{ message }}
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="close()">Ok</button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
    @Input() messages: string[];

    constructor(private activeModal: NgbActiveModal) {}

    close() {
        this.activeModal.close();
    }
}
