import { Component, Input } from '@angular/core';

@Component({
    selector: 'dashboard-widget',
    template: `
        <ruum-admin-widget>
            <div class="d-flex flex-fill align-items-center">
                <h3 class="d-flex flex-fill">{{ title }}</h3>
                <a class="btn btn-icon btn-link-secondary" routerLink="{{ url }}"><i class="icon icon-forward"></i></a>
            </div>

            <div class="divider"></div>

            <div class="summary">
                <ng-content select="[summary]"></ng-content>
            </div>

            <div class="divider"></div>

            <div class="details">
                <ng-content select="[details]"></ng-content>
            </div>
        </ruum-admin-widget>
    `,
})
export class DashboardWidgetComponent {
    @Input() title: string;
    @Input() url: string;
}
