import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SavedViewSettingsDialogModule } from '../saved-view-settings-dialog/saved-view-settings-dialog.module';
import { TaskListCustomizeColumnsComponent } from './task-list-customize-columns.component';

@NgModule({
    declarations: [TaskListCustomizeColumnsComponent],
    imports: [CommonModule, SavedViewSettingsDialogModule],
    exports: [TaskListCustomizeColumnsComponent],
})
export class SavedViewTaskListModule {}
