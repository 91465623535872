import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { CustomFieldValue } from '@ruum/ruum-reducers';
import { DatepickerComponent } from '../../../ui-components/datepicker/datepicker.component';

@Component({
    selector: 'ruum-custom-field-date',
    template: `
        <ng-container *ngIf="!isReadOnly; else readOnlyTemplate">
            <div class="input-group input-group-sm">
                <div class="d-flex" [style.width.px]="0">
                    <ruum-datepicker
                        #datepicker
                        [showButton]="false"
                        [isStartDay]="true"
                        [date]="customField.value"
                        [placeholder]="placeholder"
                        fullDateFormat="false"
                        customDateFormat="WWW. dd mmm"
                        (dateChanged)="onChange($event)"
                        (resetDates)="onChange(null)"
                    >
                    </ruum-datepicker>
                </div>
                <div
                    class="input-group input-group-sm cursor-pointer hov"
                    (click)="onFocus()"
                    (keydown.space)="onFocus()"
                    (keydown.enter)="onFocus()"
                >
                    <input
                        readonly
                        aria-label="Custom Field Date"
                        class="form-control form-control-sm"
                        type="text"
                        [placeholder]="placeholder || 'Select a date'"
                        [value]="customField.value | customFieldDate | date: 'mediumDate'"
                        ruumInputHighlight
                        aria-label="Select a date"
                    />
                    <div class="input-group-append" ruumInputHighlight>
                        <span class="input-group-text text-dark">
                            <i class="icon icon-date hov-child"></i>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #readOnlyTemplate>
            <div class="input-group input-group-sm hov disabled">
                <span class="form-control form-control-sm disabled" [class.text-light]="!customField.value">{{
                    (customField.value | customFieldDate | date: 'mediumDate') || placeholder
                }}</span>
                <div class="input-group-append" ruumInputHighlight>
                    <span class="input-group-text disabled">
                        <i class="icon icon-date hov-child"></i>
                    </span>
                </div>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldDateComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @ViewChild('datepicker', { static: false }) datepicker: DatepickerComponent;

    @Input() customField: CustomFieldValue;

    @Input() isReadOnly = false;

    @Output() valueChange = new EventEmitter<CustomFieldValue>();

    get placeholder(): string {
        return this.customField.placeholder ? this.customField.placeholder : 'Select a date';
    }

    constructor() {}

    ngOnInit() {}

    onFocus() {
        this.datepicker.openDatepicker();
    }

    onChange(value) {
        if (this.customField.value !== value) {
            const changedField = { ...this.customField, value };
            this.valueChange.emit(changedField);
        }
    }
}
