import { EntityAction, RuumAction, SystemConnector } from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../paginatedList.model';

export const SYSTEM_CONNECTOR_LIST_ACTION_TYPES = {
    SYSTEM_CONNECTOR_LOADED: 'SYSTEM_CONNECTOR_LOADED',
    ADD_SYSTEM_CONNECTOR_TO_LIST: 'ADD_SYSTEM_CONNECTOR_TO_LIST',
    UPDATE_SYSTEM_CONNECTOR_TO_LIST: 'UPDATE_SYSTEM_CONNECTOR_TO_LIST',
    DELETE_SYSTEM_CONNECTOR_FROM_LIST: 'DELETE_SYSTEM_CONNECTOR_FROM_LIST',
};

export type SystemConnectorsLoadedAction = EntityAction<
    'SYSTEM_CONNECTOR_LOADED',
    {
        page: PaginatedList<SystemConnector>;
    }
>;

export function SystemConnectorListReducer(
    currentState: PaginatedList<SystemConnector> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<SystemConnector> {
    switch (action.type) {
        case SYSTEM_CONNECTOR_LIST_ACTION_TYPES.SYSTEM_CONNECTOR_LOADED:
            return listLoaded(currentState, action.payload.page);
        case SYSTEM_CONNECTOR_LIST_ACTION_TYPES.ADD_SYSTEM_CONNECTOR_TO_LIST:
            return add(currentState, action.payload.systemConnector);
        case SYSTEM_CONNECTOR_LIST_ACTION_TYPES.UPDATE_SYSTEM_CONNECTOR_TO_LIST:
            return update(currentState, action.payload.systemConnector);
        case SYSTEM_CONNECTOR_LIST_ACTION_TYPES.DELETE_SYSTEM_CONNECTOR_FROM_LIST:
            return remove(currentState, action.payload.systemConnector);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<SystemConnector>,
    data: PaginatedList<SystemConnector>,
): PaginatedList<SystemConnector> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}

function add(
    currentState: PaginatedList<SystemConnector>,
    systemConnector: SystemConnector,
): PaginatedList<SystemConnector> {
    return {
        ...currentState,
        rows: [...currentState.rows, systemConnector],
    };
}

function update(
    currentState: PaginatedList<SystemConnector>,
    systemConnector: SystemConnector,
): PaginatedList<SystemConnector> {
    return {
        ...currentState,
        rows: currentState.rows.map((el) => {
            if (el.id === systemConnector.id) {
                return systemConnector;
            } else {
                return el;
            }
        }),
    };
}

function remove(
    currentState: PaginatedList<SystemConnector>,
    systemConnector: SystemConnector,
): PaginatedList<SystemConnector> {
    return {
        ...currentState,
        rows: currentState.rows.filter((el) => el.id !== systemConnector.id),
    };
}
