import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkspaceListItem } from '../../connectors/readModelConnector.service';
import { WorkspaceListService } from '../../connectors/workspace/workspace-list.service';

@Component({
    selector: 'ruum-select-workspace',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5">{{ headerText }}</h2>
            <form [formGroup]="form" (ngSubmit)="moveToWorkspace()" *ngIf="workspaces$ | async as workspaces">
                <div>
                    <div class="card-list form-group">
                        <label class="form-default-label" for="selectWorkspace">Select workspace</label>
                        <div
                            id="selectWorkspace"
                            *ngFor="let group of workspaces"
                            class="card d-flex align-items-center rounded mb-1"
                            [class.active]="group.id === form.get('selectedWorkspaceId').value"
                            (click)="select(group)"
                            (keyup.Space)="select(group)"
                            (keyup.Enter)="select(group)"
                            tabindex="0"
                            ngbAutofocus
                        >
                            <div class="card-name text-small font-weight-bold px-3" [title]="group.name">
                                {{ group.name }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="workspaces.length === 0"
                    class="d-flex flex-fill flex-column justify-content-center align-items-center"
                >
                    <h3 class="mt-7 text-secondary">No Workspaces here yet!</h3>
                    <p class="text-secondary">You don't have write permission to any Workspace</p>
                </div>
                <div class="d-flex justify-content-end mt-6">
                    <button
                        *ngIf="form.invalid; else validButton"
                        class="btn btn-lg btn-primary disabled"
                        type="button"
                        [ngbTooltip]="disabledTooltip"
                    >
                        Move
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>

        <!--
            needed to do it this way, because tooltip will not shown up on disabled buttons
        -->
        <ng-template #validButton>
            <button class="btn btn-lg btn-primary" type="submit">
                Move
            </button>
        </ng-template>

        <ng-template #disabledTooltip>
            <div class="text-small font-weight-bold">Select a Workspace</div>
        </ng-template>
    `,
    styles: [
        `
            .card-list {
                max-height: 264px;
                overflow: auto;
            }

            .card {
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #ffffff;
                border-left: 4px solid #46a6f5;
                border-right: 1px solid rgba(37, 44, 66, 0.08);
                border-top: 1px solid rgba(37, 44, 66, 0.08);
                border-bottom: 1px solid rgba(37, 44, 66, 0.08);
                cursor: pointer;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
            }

            .card:hover {
                border-right-color: #46a6f5;
                border-top-color: #46a6f5;
                border-bottom-color: #46a6f5;
            }
            .card.active {
                background-color: rgba(70, 166, 245, 0.24);
                border-right-color: transparent;
                border-top-color: transparent;
                border-bottom-color: transparent;
            }
            .card.active:hover {
                background-color: rgba(70, 166, 245, 0.16);
                border-right-color: rgba(70, 166, 245, 0.48);
                border-top-color: rgba(70, 166, 245, 0.48);
                border-bottom-color: rgba(70, 166, 245, 0.48);
            }

            .card-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        `,
    ],
})
export class SelectWorkspaceComponent implements OnInit {
    @Input() headerText: string;
    workspaces$: Observable<WorkspaceListItem[]>;

    form: FormGroup = this.formBuilder.group({
        searchQuery: '',
        selectedWorkspaceId: ['', Validators.required],
    });

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private workspaceListService: WorkspaceListService,
    ) {}

    ngOnInit() {
        this.workspaces$ = this.workspaceListService.list$.pipe(
            map((list) => list.filter((item) => item.role && item.role !== 'WorkspaceViewer')),
        );
    }

    select(item: WorkspaceListItem) {
        const selected = this.form.get('selectedWorkspaceId').value;
        if (selected === item.id) {
            this.form.get('selectedWorkspaceId').setValue('');
        } else {
            this.form.get('selectedWorkspaceId').setValue(item.id);
        }
    }

    moveToWorkspace() {
        const selectedWorkspaceId = this.form.get('selectedWorkspaceId').value;
        this.activeModal.close(selectedWorkspaceId);
    }
}
