import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, RendererFactory2 } from '@angular/core';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RuumPopupService } from '../common/popup/ruum-popup-container.service';
import { IllustrationModule } from '../shared/ui-components/illustration/illustration.module';
import { PAYWALL_REJECT_STRATEGY } from './paywall-reject-strategy';
import { PaywallService } from './paywall.service';
import { RequiredPrivilegeDirective } from './required-privilege.directive';
import { BasicRejectStrategy, FALLBACK_PAYWALL_REJECT_STRATEGY } from './strategies/basic.reject-strategy';
import { DisableRejectStrategy } from './strategies/disable.reject-strategy';
import { HideRejectStrategy } from './strategies/hide.reject-strategy';
import { NoopRejectStrategy } from './strategies/noop.reject-strategy';
import { TrialStatusbarComponent } from './trial/trial-statusbar/trial-statusbar.component';
import { TrialService } from './trial/trial.service';
import { UpgradeDialogComponent } from './upgrade-dialog.component';

@NgModule({
    imports: [CommonModule, HttpClientModule, NgbModalModule, IllustrationModule],
    declarations: [RequiredPrivilegeDirective, TrialStatusbarComponent, UpgradeDialogComponent],
    providers: [
        PaywallService,
        {
            provide: PAYWALL_REJECT_STRATEGY,
            multi: true,
            useClass: DisableRejectStrategy,
            deps: [RendererFactory2],
        },
        {
            provide: PAYWALL_REJECT_STRATEGY,
            multi: true,
            useClass: HideRejectStrategy,
            deps: [RendererFactory2],
        },
        {
            provide: PAYWALL_REJECT_STRATEGY,
            multi: true,
            useClass: NoopRejectStrategy,
        },
        {
            provide: PAYWALL_REJECT_STRATEGY,
            multi: true,
            useClass: BasicRejectStrategy,
            deps: [TrialService, RendererFactory2, RuumPopupService, NgbModal, FALLBACK_PAYWALL_REJECT_STRATEGY],
        },
        {
            provide: FALLBACK_PAYWALL_REJECT_STRATEGY,
            useClass: NoopRejectStrategy,
        },
    ],
    exports: [RequiredPrivilegeDirective, TrialStatusbarComponent],
})
export class RuumPaywallModule {}
