import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabOption } from './../../common/components/tabs.component';
import { DashboardService } from './../dashboard.service';

@Component({
    selector: 'dashboard-api-info-widget',
    template: `
        <dashboard-widget [title]="'API'" [url]="'/api'">
            <div summary>
                <div class="dashboard-widget-title">Integrations</div>
                <div class="integration-counter">{{ integrationCounter$ | async }}</div>
            </div>

            <div details>
                <div class="title">
                    Ruums Created
                </div>

                <ruum-tabs [options]="options" (tabChanged)="timespanChanged($event)"></ruum-tabs>

                <api-usage-graph [days]="timespan$ | async"></api-usage-graph>
            </div>
        </dashboard-widget>
    `,
    styles: [
        `
            .title {
                font-size: 18px;
                margin: 32px 0;
            }
            .integration-counter {
                font-size: 50px;
                font-weight: bold;
                height: 79px;
            }
            /** TODO: replace this (temporary) solution with an actual grid solution **/
            @media (max-width: 2020px) {
                dashboard-widget {
                    margin-top: 32px;
                }
            }
        `,
    ],
})
export class DashboardAPIInfoWidgetComponent implements OnInit {
    timespan$: BehaviorSubject<number> = new BehaviorSubject<number>(7);
    options: TabOption[] = [
        {
            id: 7,
            text: 'Last 7 days',
        },
        {
            id: 30,
            text: 'Last 30 days',
        },
        {
            id: 90,
            text: 'Last 3 months',
        },
    ];
    integrationCounter$: Observable<number>;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit() {
        this.integrationCounter$ = this.dashboardService.getReport().pipe(
            map((reportData) => {
                return reportData && reportData.integrationAccounts ? reportData.integrationAccounts.length : 0;
            }),
        );
    }

    timespanChanged(newValue: number) {
        this.timespan$.next(newValue);
    }
}
