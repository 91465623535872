import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentTheme } from '../../../shared/ui-components/ui-components.type';

@Component({
    selector: 'ruum-breadcrumb-separator',
    template: `
        <div class="text-{{ theme }} mx-2">/</div>
    `,
})
export class BreadcrumbSeparatorComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() theme: ComponentTheme = 'white-64';

    constructor() {}

    ngOnInit() {}
}
