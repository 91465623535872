import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { RuumDropdownDesktopComponent } from '../../ui-components/dropdown/dropdown-desktop.component';
import { RuumDropdownMobileComponent } from '../../ui-components/dropdown/dropdown-mobile.component';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ComponentTheme } from '../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-template-cover-color-picker',
    template: `
        <ruum-dropdown-wrapper>
            <ruum-dropdown-desktop #desktopDropdown [placement]="placement" [isReadOnly]="disabled">
                <div class="d-flex" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                    <ng-container *ngTemplateOutlet="colorPickerButton"></ng-container>
                </div>
                <div
                    class="d-flex flex-wrap flex-column p-2"
                    [style.width.px]="144"
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                >
                    <div class="d-flex flex-wrap">
                        <div
                            *ngFor="let theme of themes"
                            class="btn btn-xs btn-icon btn-{{ theme }} m-1"
                            (click)="onSelect(theme, desktopDropdown)"
                            (keydown.space)="onSelect(theme, desktopDropdown)"
                            (keydown.enter)="onSelect(theme, desktopDropdown)"
                        >
                            <i *ngIf="theme === selectedTheme" class="icon icon-done"></i>
                        </div>
                    </div>
                </div>
            </ruum-dropdown-desktop>
            <ruum-dropdown-mobile #mobileDropdown [isReadOnly]="disabled">
                <div class="d-flex" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                    <ng-container *ngTemplateOutlet="colorPickerButton"></ng-container>
                </div>
                <div
                    class="d-flex flex-fill flex-column p-1"
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                >
                    <div
                        *ngFor="let theme of themes"
                        class="list-group-item list-group-item-action d-flex align-items-center rounded cursor-pointer px-2 py-1 hov hov-bg-light"
                        (click)="onSelect(theme, mobileDropdown)"
                        (keydown.space)="onSelect(theme, mobileDropdown)"
                        (keydown.enter)="onSelect(theme, mobileDropdown)"
                    >
                        <div class="btn btn-sm btn-icon btn-{{ theme }}">
                            <i *ngIf="theme === selectedTheme" class="icon icon-done"></i>
                        </div>
                        <div class="flex-fill pl-2 text-truncate text-capitalize">
                            {{ theme | ruumTemplateColorName }}
                        </div>
                        <div *ngIf="theme === selectedTheme" class="btn btn-sm btn-icon btn-link-secondary">
                            <i class="icon icon-done"></i>
                        </div>
                    </div>
                </div>
            </ruum-dropdown-mobile>
        </ruum-dropdown-wrapper>

        <ng-template #colorPickerButton>
            <button class="btn btn-sm btn-outline-secondary pr-1" [style.padding-left.px]="5">
                <span
                    class="rounded mr-1"
                    [style.width.px]="20"
                    [style.height.px]="20"
                    [ngClass]="rectangleBackgraundColor"
                ></span>
                <i class="icon icon-cevron-down"></i>
            </button>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateCoverColorPickerComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            'd-flex',
            componentHelper.getCursorClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @Input() theme: ComponentTheme = 'primary';
    @Input() disabled: boolean;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter<ComponentTheme>();

    placement = ['bottom-right', 'top-right'];

    themes = ['orange', 'berry', 'magenta', 'lilac', 'indigo', 'primary', 'cyan', 'pear'];

    constructor() {}

    ngOnInit() {}

    // TODO: create angular pipe
    get selectedTheme() {
        const templateTheme = this.theme || 'primary-light';
        const themeName = templateTheme.replace('-light', '');
        return themeName;
    }

    // TODO: create angular pipe
    get rectangleBackgraundColor() {
        const templateTheme = this.theme || 'primary-light';
        const themeName = templateTheme.replace('-light', '');
        return `bg-${themeName}`;
    }

    onSelect(theme: ComponentTheme, dropdown: RuumDropdownDesktopComponent | RuumDropdownMobileComponent) {
        dropdown.close();
        this.theme = theme;
        const lightTheme = `${theme}-light`;
        this.changed.emit(lightTheme as ComponentTheme);
    }
}
