import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectedProjectService } from '../connectors/project/selectedProject.service';

@Component({
    selector: 'readonly-hint',
    template: `
        <div *ngIf="isReadOnly$ | async" class="d-flex align-items-center w-100 bg-info">
            <button class="btn btn-sm btn-icon btn-without-hover btn-link-secondary ml-2 ml-md-5">
                <i class="icon icon-read-only text-white"></i>
            </button>
            <div class="text-white mx-2 mx-md-5">
                Read only
            </div>
            <div class="text-white text-small d-md-flex d-none">
                You cannot modify or adjust content of this ruum.
            </div>
        </div>
    `,
})
export class ReadOnlyComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    isReadOnly$: Observable<boolean>;

    constructor(private selectedProjectService: SelectedProjectService) {}

    ngOnInit() {
        this.isReadOnly$ = this.selectedProjectService.isReadOnly();
    }
}
