import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { GroupSelectComponent } from './group-select.component';

@NgModule({
    imports: [CommonModule, SelectModule],
    declarations: [GroupSelectComponent],
    exports: [GroupSelectComponent],
})
export class GroupSelectModule {}
