import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ApprovalReducer, isApprovalReducerAction, RuumAction } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedProjectService } from '../project/selectedProject.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';
import { ApprovalListItem } from './approval.model';

@Injectable({ providedIn: 'root' })
export class ApprovalStore extends StoreLoader<ApprovalListItem> {
    private map = new BehaviorSubject<ApprovalsMap>({});

    constructor(
        private readModelBackendConnector: ReadModelBackendConnector,
        private projectService: SelectedProjectService,
        private actions$: ActionsSubject,
    ) {
        super();
        this.listenToApprovalActions();
    }

    approval(projectId: string, approvalId: string): Observable<ApprovalListItem> {
        return this.data(`${projectId}-${approvalId}`);
    }

    reloadApproval(projectId: string, approvalId: string) {
        this.reload(`${projectId}-${approvalId}`);
    }

    protected getData(compositeIds: string[]): Observable<ApprovalListItem[]> {
        return this.readModelBackendConnector
            .getApprovals(
                { page: 1, pageSize: compositeIds.length },
                {
                    compositeIds,
                },
                {},
            )
            .pipe(map((page) => page.rows));
    }

    protected storeData(list: ApprovalListItem[]): void {
        const change: ApprovalsMap = {};
        list.forEach((item) => {
            change[`${item.projectId}-${item.id}`] = item;
        });
        this.map.next({
            ...this.map.value,
            ...change,
        });
    }

    protected failedToLoad(id: string): void {
        this.map.next({
            ...this.map.value,
            [id]: {
                id,
                items: {},
                activities: [],
                policy: undefined,
                sectionId: undefined,
                rejectionMessageMandatory: undefined,
                projectId: undefined,
                status: undefined,
            },
        });
    }

    protected getStoreData(): Observable<ApprovalsMap> {
        return this.map.asObservable();
    }

    protected getId(approval: ApprovalListItem): string {
        return `${approval.projectId}-${approval.id}`;
    }

    private listenToApprovalActions() {
        this.actions$.subscribe((action: RuumAction) => {
            if (!isApprovalReducerAction(action && action.type)) {
                return;
            }
            const project = this.projectService.getSelectedProject();
            const approvalId = action.payload.approvalId;
            const compositeId = `${project.id}-${approvalId}`;
            let approval = this.map.value[compositeId];
            if (!approval) {
                return;
            }
            approval = <ApprovalListItem>ApprovalReducer(approval, action, {
                isTemplate: !!project.belongsToTemplateId,
                processes: project.processes,
            });

            this.map.next({
                ...this.map.value,
                [compositeId]: approval,
            });
        });
    }
}

interface ApprovalsMap {
    [approvalId: string]: ApprovalListItem;
}
