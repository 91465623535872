import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AddCommentActionPayload,
    CommentableType,
    DeleteCommentActionPayload,
    EditCommentActionPayload,
} from '@ruum/ruum-reducers';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { RuumUIStateService } from '../../../../app/ruum/ui-state/ruumUIState.service';
import { CommentListItem } from '../../connectors/commentable/commentable.reducer';
import { CommentableInfo } from '../../connectors/commentable/commentable.service';
import { CommentListService } from '../../connectors/comments/commentList.service';
import { getAddCommentActionPayload } from '../comments.helpers';
import { AddCommentData } from './add-comment-text-input.component';

@Component({
    selector: 'common-project-comments',
    template: `
        <busy-state componentId="comment-list"></busy-state>
        <ruum-sidepanel-container>
            <div class="d-lg-flex d-none flex-fill">
                <ruum-sidepanel-back-button [name]="'All Comments'" (click)="goToCommentsOverview()" class="flex-fill">
                </ruum-sidepanel-back-button>
            </div>
            <ruum-sidepanel-title>
                <div class="d-flex overflow-x w-100">
                    <button class="btn btn-xs btn-icon btn-link-light btn-without-hover">
                        <i class="icon {{ (commentableParams$ | async).objectType | ruumObjectTypeToIcon }}"></i>
                    </button>
                    <ruum-max-content>
                        <div title="{{ title$ | async }}" class="font-weight-bold pl-2 text-truncate">
                            {{ title$ | async }}
                        </div>
                    </ruum-max-content>
                </div>
            </ruum-sidepanel-title>

            <ruum-load-more *ngIf="showLoading$ | async" (loadMore)="loadMore()" [scrollTop]="true"></ruum-load-more>
            <comment-list-wrapper
                [comments]="comments$ | async"
                [commentableType]="(commentableParams$ | async).objectType"
                [commentableId]="(commentableParams$ | async).objectId"
                [projectId]="(commentableParams$ | async).projectId"
                [isReadOnly]="isOffline$ | async"
                (edit)="onEditComment($event.id, $event.text)"
                (delete)="onDeleteComment($event)"
                (add)="onAddComment($event)"
            >
            </comment-list-wrapper>
        </ruum-sidepanel-container>
    `,
    styles: [``],
})
export class CommonCommentsComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill h-100';

    @Input() comments$: Observable<CommentListItem[]>;
    @Input() commentableParams$: Observable<CommentableInfo>;
    @Input() title$: Observable<string>;

    @Output() addComment = new EventEmitter<AddCommentActionPayload>();
    @Output() editComment = new EventEmitter<EditCommentActionPayload>();
    @Output() deleteComment = new EventEmitter<DeleteCommentActionPayload>();
    @Output() showCommentsOverview = new EventEmitter();

    isOffline$: Observable<boolean>;
    objectType: CommentableType;
    showLoading$: Observable<boolean>;
    private subscriptions: Subscription[] = [];

    constructor(private commentListService: CommentListService, private uiStateService: RuumUIStateService) {
        this.isOffline$ = this.uiStateService.offline();
    }

    ngOnInit() {
        this.showLoading$ = this.commentListService.showLoading$;
    }

    onEditComment(commentId: string, text: string) {
        const { projectId, objectType, objectId } = this.getParams();

        const payload: EditCommentActionPayload = {
            id: commentId,
            onObjectId: objectId,
            onObjectType: objectType,
            newText: text,
        };
        this.editComment.emit(payload);
    }

    onDeleteComment(commentId: string) {
        const { projectId, objectType, objectId } = this.getParams();
        const payload: DeleteCommentActionPayload = {
            id: commentId,
            onObjectId: objectId,
            onObjectType: objectType,
        };
        this.deleteComment.emit(payload);
    }

    onAddComment(data: AddCommentData) {
        const { projectId, objectType, objectId } = this.getParams();
        const payload = getAddCommentActionPayload(data.atMentions, data.text, objectId, objectType);
        this.addComment.emit(payload);
    }

    private getParams(): CommentableInfo {
        let obj: CommentableInfo;
        const s = this.commentableParams$.pipe(take(1)).subscribe(
            (t) =>
                (obj = {
                    projectId: t.projectId,
                    objectType: t.objectType,
                    objectId: t.objectId,
                }),
        );
        this.subscriptions.push(s);
        return obj;
    }

    goToCommentsOverview() {
        this.showCommentsOverview.emit();
    }

    loadMore() {
        this.commentListService.maybeGoToNextPage();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
