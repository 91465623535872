import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { RuumDropdownItem, RuumDropdownList } from '../../shared/ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-team-members-header',
    template: `
        <button class="btn btn-sm btn-icon btn-link-secondary">
            <i class="icon" [class.icon-cevron-down]="expanded" [class.icon-cevron-right]="!expanded"></i>
        </button>
        <ng-container *ngIf="selectedPermissionOption?.id !== noAccess">
            <div class="d-flex align-items-center flex-fill minw-0">
                <div class="text-truncate cursor-pointer">{{ title }}</div>
                <div class="ml-1 text-tiny text-secondary flex-fill cursor-pointer">({{ membersCount }})</div>
            </div>
            <ruum-single-select
                *ngIf="showMembersPermissions"
                [placement]="['bottom-right', 'top-right', 'auto']"
                [selectedOption]="selectedPermissionOption"
                [options]="permissionOptions"
                [width]="'276px'"
                [ariaLabel]="'Select a team member'"
                (selectChange)="onSelectPermission($event)"
            >
            </ruum-single-select>
        </ng-container>
        <ng-container *ngIf="selectedPermissionOption?.id === noAccess">
            <div class="d-flex align-items-center flex-fill minw-0 cursor-pointer">
                <div class="text-small text-light text-truncate">{{ title }} can't access</div>
            </div>
            <button
                *ngIf="showMembersPermissions"
                class="btn btn-sm btn-link-primary px-0"
                [disabled]="selectedPermissionOption.disabled"
                (click)="onEnableDefaultPermission($event)"
            >
                Enable
            </button>
        </ng-container>
        <ng-container>
            <div class="d-flex align-items-right minw-0 cursor-pointer" *ngIf="!readOnly">
                <ng-content select="[data-content='ruum-task-assignees-dropdown']"></ng-content>
                <ng-content select="[data-content='item-contextual-menu']"></ng-content>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMembersHeaderComponent implements OnInit {
    @HostBinding('class')
    hostClassList = 'd-flex align-items-center px-5 py-3';

    @Input()
    expanded = false;

    @Input()
    title = '';

    @Input()
    membersCount = 0;

    @Input()
    readOnly = false;

    @Input()
    showMembersPermissions = true;

    @Input()
    selectedPermissionOption: RuumDropdownItem;

    @Input()
    permissionOptions: RuumDropdownList[] = [];

    @Output()
    selectPermission = new EventEmitter<RuumDropdownItem['id']>();

    @Output()
    enableDefaultPermission = new EventEmitter();

    noAccess = 'no_access';

    constructor() {}

    ngOnInit() {}

    onSelectPermission(item: RuumDropdownItem) {
        this.selectPermission.emit(item.id);
    }

    onEnableDefaultPermission(event: MouseEvent) {
        event.stopPropagation();
        this.enableDefaultPermission.emit();
    }
}
