import { Injectable } from '@angular/core';

interface DialogSettings {
    [key: string]: boolean;
}

@Injectable({ providedIn: 'root' })
export class ModalDialogService {
    constructor() {}

    setShowDialogAgain(dialogName: string, show: boolean) {
        let settings = this.getSettings();
        const dialogKey = this.getDialogKey(dialogName);
        settings = {
            ...settings,
            [dialogKey]: show,
        };
        this.setSettings(settings);
    }

    getShowDialogAgain(dialogName: string): boolean {
        const settings = this.getSettings();
        const dialogKey = this.getDialogKey(dialogName);
        const showAgain = settings[dialogKey];

        if (showAgain === false) {
            return false;
        } else {
            return true;
        }
    }

    private getStorageKey(): string {
        const userEmail = localStorage.getItem('ruumMailAddress');
        const settingsKey = `${userEmail}-dialog-preferences`;
        return settingsKey;
    }

    private getDialogKey(dialogName: string): string {
        return `show${dialogName}`;
    }

    private getSettings(): DialogSettings {
        const key = this.getStorageKey();
        const settings = JSON.parse(localStorage.getItem(key)) || {};
        return settings;
    }

    private setSettings(value): void {
        const key = this.getStorageKey();
        localStorage.setItem(key, JSON.stringify(value));
    }
}
