import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';

function formatDate(day: Day): string {
    return new Date(day.year, day.month - 1, day.day, 0, 0, 0, 0).format('DD MMM');
}

@Pipe({ name: 'formateDate' })
export class FormateDatePipe implements PipeTransform {
    transform(days: Day[]): string {
        const [startDay, dueDay] = days;
        if (!startDay && dueDay) {
            return `Due ${formatDate(dueDay)}`;
        }
        if (startDay && !dueDay) {
            return `Starts ${formatDate(startDay)}`;
        }
        if (startDay && dueDay) {
            return `${formatDate(startDay)} – ${formatDate(dueDay)}`;
        }

        return '';
    }
}
@NgModule({
    imports: [],
    declarations: [FormateDatePipe],
    exports: [FormateDatePipe],
})
export class FormatDatePipeModule {}
