import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SAPRUUMLogoComponent } from './sap-ruum-logo.component';

@NgModule({
    imports: [CommonModule],
    declarations: [SAPRUUMLogoComponent],
    exports: [SAPRUUMLogoComponent],
})
export class LogotypeModule {}
