import { Injectable } from '@angular/core';
import { ActivationEnd, ChildActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RuumSidepanelService {
    readonly isSidepanelOpen$: Observable<boolean>;
    readonly isPanelOverlay$: Observable<boolean>;
    readonly params$: Observable<object>;
    readonly index$: Observable<string>;

    private _isSidepanelOpen$ = new BehaviorSubject<boolean>(false);
    private _isPanelOverlay$ = new BehaviorSubject<boolean>(false);
    private _params$ = new BehaviorSubject<object>(null);
    private _index$ = new BehaviorSubject<string>(null);

    constructor(private router: Router) {
        this.isSidepanelOpen$ = this._isSidepanelOpen$;
        this.isPanelOverlay$ = this._isPanelOverlay$;
        this.params$ = this._params$;
        this.index$ = this._index$;
        this.registerObservers();
    }

    setParams(params: object) {
        this._params$.next(params);
    }
    setIndex(index: string) {
        this._index$.next(index);
    }
    addParam(key: string, value: string) {
        const params = this._params$.value;
        if (params) {
            this._params$.next({ ...params, [key]: value });
        } else {
            this._params$.next({ [key]: value });
        }
    }
    removeParam(key: string) {
        const params = this._params$.value;
        if (params) {
            const withoutKey = { ...params };
            delete withoutKey[key];
            this._params$.next(withoutKey);
        }
    }

    removeParams() {
        this._params$.next(null);
    }
    removeIndex() {
        this._index$.next(null);
    }
    resetParamsAndIndex() {
        this.removeParams();
        this.removeIndex();
    }

    isActiveSidepanelParams$(objectParams: object): Observable<boolean> {
        return this.params$.pipe(
            map((params) => {
                if (!objectParams || !params) {
                    return false;
                }
                const keys = Object.keys(objectParams);
                for (const key of keys) {
                    if (objectParams[key] !== params[key]) {
                        return false;
                    }
                }
                return true;
            }),
            distinctUntilChanged(),
        );
    }

    isActiveSidepanelIndex$(objectIndex: string): Observable<boolean> {
        return this.index$.pipe(
            map((index) => {
                if (!objectIndex || !index) {
                    return false;
                }
                return objectIndex === index;
            }),
            distinctUntilChanged(),
        );
    }

    isActiveSidePanel$(objectIndex, objectParams): Observable<boolean> {
        return this.isActiveSidepanelIndex$(objectIndex).pipe(
            switchMap((hasIndex) => {
                return hasIndex ? this.isActiveSidepanelParams$(objectParams) : of(false);
            }),
            distinctUntilChanged(),
        );
    }

    private registerObservers() {
        this.router.events
            .pipe(
                filter<ActivationEnd | ChildActivationEnd>(
                    (event) =>
                        event instanceof ActivationEnd && (event.snapshot.data.tabId || event.snapshot.data.buttonId),
                ),
                map<ActivationEnd, boolean>((event) => {
                    return Boolean(event.snapshot.data.buttonId);
                }),
                debounceTime(10),
            )
            .subscribe(this._isSidepanelOpen$);

        this.router.events
            .pipe(
                filter<ActivationEnd>((event) => event instanceof ActivationEnd && event.snapshot.data.tabId),
                map<ActivationEnd, boolean>((event) => {
                    return event.snapshot.data.panelOverlay;
                }),
                debounceTime(10),
            )
            .subscribe(this._isPanelOverlay$);
    }
}
