export const BOOTSTRAP_BREAKPOINTS = {
    XSmall: '(max-width: 575.99px)',
    Small: '(min-width: 576px) and (max-width: 767.99px)',
    Medium: '(min-width: 768px) and (max-width: 991.99px)',
    Large: '(min-width: 992px) and (max-width: 1279.99px)',
    XLarge: '(min-width: 1280px)',
    // ,
    // Handset: '(max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape)',
    // Tablet:
    //     '(min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape)',
    // Web: '(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)',
    // HandsetPortrait: '(max-width: 599.99px) and (orientation: portrait)',
    // TabletPortrait: '(min-width: 600px) and (max-width: 839.99px) and (orientation: portrait)',
    // WebPortrait: '(min-width: 840px) and (orientation: portrait)',
    // HandsetLandscape: '(max-width: 959.99px) and (orientation: landscape)',
    // TabletLandscape: '(min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape)',
    // WebLandscape: '(min-width: 1280px) and (orientation: landscape)'
};
