import { Component, HostBinding, Input } from '@angular/core';
import { Navbar } from './../common/navbar/navbar.model';

@Component({
    selector: 'ruum-admin-navbar',
    template: `
        <ruum-navbar [isHome]="true" applicationPart="lobby" data-content="navbar">
            <ruum-admin-navbar-breadcrumb class="navbar-breadcrumb"></ruum-admin-navbar-breadcrumb>
            <div class="navbar-secondary-top">
                <nav
                    role="navigation"
                    aria-label="Secondary"
                    class="navbar-secondary navbar navbar-light navbar-expand bg-white d-flex justify-content-center flex-fill"
                >
                    <div class="d-flex flex-fill px-2 px-sm-4 px-md-5 px-lg-8">
                        <div class="collapse navbar-collapse" [style.min-height.px]="48"></div>
                    </div>
                </nav>
            </div>
            <div class="navbar-secondary-bottom"></div>
        </ruum-navbar>
    `,
})
export class AdminNavbarComponent {
    @HostBinding('class') hostClassList = 'd-block';

    @Input() navbar: Navbar;
}
