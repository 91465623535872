import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { ProjectGroupCreateComponent } from './project-group-create.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalDialogModule],
    declarations: [ProjectGroupCreateComponent],
    exports: [ProjectGroupCreateComponent],
})
export class ProjectGroupCreateModule {}
