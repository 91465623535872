import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentTheme } from '../../../shared/ui-components/ui-components.type';

@Component({
    selector: 'ruum-navbar-back-button',
    template: `
        <button class="btn btn-sm btn-icon btn-link-{{ theme }} mr-2" (click)="back()">
            <i class="icon icon-back"></i>
        </button>
    `,
})
export class NavbarBackButtonComponent implements OnInit {
    constructor(private router: Router) {}

    @Input() theme: ComponentTheme = 'white-64';

    ngOnInit() {}

    back() {
        const currentHref = window.location.href;
        window.history.back();
        setTimeout(() => {
            const newHref = window.location.href;
            if (currentHref === newHref) {
                this.router.navigate(['home']);
            }
        }, 200);
    }
}
