import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ProfileService } from '../../../common/connectors/profile/profile.service';

@Injectable({ providedIn: 'root' })
export class AdminWebappSessionGuard implements CanActivate {
    constructor(private profileService: ProfileService, private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> {
        return from(this.profileService.loadMyProfile()).pipe(
            switchMap(() =>
                this.profileService.getUserAdminEnterprises().pipe(
                    map((enterprises) => {
                        if (enterprises?.length > 0) {
                            return true;
                        } else {
                            return this.router.parseUrl('/home/projects');
                        }
                    }),
                ),
            ),
        );
    }
}
