import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { WorkspaceRenameComponent } from './workspace-rename.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalDialogModule],
    declarations: [WorkspaceRenameComponent],
    exports: [WorkspaceRenameComponent],
})
export class WorkspaceRenameModule {}
