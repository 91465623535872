/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { ElementRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PaywallPrivilege } from './paywall-privilege';
import {
    PaywallRejectStrategy,
    PaywallRejectStrategyContext,
    PaywallRejectStrategyData,
    PaywallRejectStrategyId,
    PAYWALL_REJECT_STRATEGY,
} from './paywall-reject-strategy';
import { PaywallService } from './paywall.service';

export enum RejectionOnMissingPrivilege {
    REJECTED_ON_MISSING_PRIVILEGE,
    OPERATION_GRANTED,
}

// this controller is meant to be used by the required-privilege directive (add it to the components providers: [])
// in some cases, it might be useful to use this injectable without the directive
@Injectable()
export class RequiredPrivilegeController implements OnDestroy {
    private _rejectStrategies: PaywallRejectStrategy[] | undefined;
    private readonly _subscriptions = new Subscription();
    private readonly _hasPrivilege$ = new BehaviorSubject<boolean>(true);

    private _requiredPrivilege?: PaywallPrivilege;
    private _rejectStrategyId?: PaywallRejectStrategyId;
    private _rejectStrategyData?: PaywallRejectStrategyData;
    private _elementRef?: ElementRef;

    constructor(private readonly _paywallService: PaywallService, private readonly _injector: Injector) {}

    // yes, that's right: onDestroy is available on injectables:
    // https://angular.io/api/core/OnDestroy
    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    findRejectStrategyById(rejectStrategyId: PaywallRejectStrategyId): PaywallRejectStrategy | undefined {
        const applicableRejectStrategies = this.rejectStrategies.filter(
            ({ id: currId }) => currId === rejectStrategyId,
        );
        if (applicableRejectStrategies.length > 0) {
            return applicableRejectStrategies[0];
        }

        return undefined;
    }

    initRejectByStrategyOnMissingPrivilege(
        requiredPrivilege: PaywallPrivilege | undefined,
        rejectStrategyId: PaywallRejectStrategyId,
        rejectStrategyData?: PaywallRejectStrategyData,
        elementRef?: ElementRef,
    ): Observable<RejectionOnMissingPrivilege> {
        this._requiredPrivilege = requiredPrivilege;
        this._rejectStrategyId = rejectStrategyId;
        this._rejectStrategyData = rejectStrategyData;
        this._elementRef = elementRef;

        const subscription = this._paywallService
            .grantedPrivileges()
            .pipe(map((grantedPrivileges) => grantedPrivileges.indexOf(requiredPrivilege) >= 0))
            .subscribe(this._hasPrivilege$);
        this._subscriptions.unsubscribe();
        this._subscriptions.add(subscription);

        return this._hasPrivilege$.pipe(
            tap((hasPrivilege) => {
                if (!hasPrivilege) {
                    const currentRejectStrategy = this.findRejectStrategyById(this._rejectStrategyId);
                    if (currentRejectStrategy) {
                        currentRejectStrategy.onRejectInitial(this.rejectStategyContext());
                    }
                }
            }),
            map((hasPrivilege) =>
                hasPrivilege
                    ? RejectionOnMissingPrivilege.OPERATION_GRANTED
                    : RejectionOnMissingPrivilege.REJECTED_ON_MISSING_PRIVILEGE,
            ),
            take(1),
        );
    }

    rejectByStrategyOnMissingPrivilege(): Observable<RejectionOnMissingPrivilege> {
        return this._hasPrivilege$.pipe(
            tap((hasPrivilege) => {
                if (!hasPrivilege) {
                    const currentRejectStrategy = this.findRejectStrategyById(this._rejectStrategyId);
                    if (currentRejectStrategy) {
                        currentRejectStrategy.onRejectTrigger(this.rejectStategyContext());
                    }
                }
            }),
            map((hasPrivilege) =>
                hasPrivilege
                    ? RejectionOnMissingPrivilege.OPERATION_GRANTED
                    : RejectionOnMissingPrivilege.REJECTED_ON_MISSING_PRIVILEGE,
            ),
            take(1),
        );
    }

    destroyRejectByStrategyOnMissingPrivilege(): Observable<RejectionOnMissingPrivilege> {
        return this._hasPrivilege$.pipe(
            tap((hasPrivilege) => {
                if (!hasPrivilege) {
                    const currentRejectStrategy = this.findRejectStrategyById(this._rejectStrategyId);
                    if (currentRejectStrategy) {
                        currentRejectStrategy.onRejectHostDestroy(this.rejectStategyContext());
                    }
                }
            }),
            map((hasPrivilege) =>
                hasPrivilege
                    ? RejectionOnMissingPrivilege.OPERATION_GRANTED
                    : RejectionOnMissingPrivilege.REJECTED_ON_MISSING_PRIVILEGE,
            ),
            take(1),
        );
    }

    // this method is not bound to the directive itself.
    // there is no need for calling init* and destroy* because these methods are called implicitly
    rejectByStrategyOnMissingPrivilegeCombined(
        requiredPrivilege: PaywallPrivilege | undefined,
        rejectStrategyId: PaywallRejectStrategyId,
        rejectStrategyData?: PaywallRejectStrategyData,
        elementRef?: ElementRef,
    ): Observable<RejectionOnMissingPrivilege> {
        // here: code structure above performance... maybe this should be changed in future?!
        return this.initRejectByStrategyOnMissingPrivilege(
            requiredPrivilege,
            rejectStrategyId,
            rejectStrategyData,
            elementRef,
        ).pipe(
            switchMap(() => this.rejectByStrategyOnMissingPrivilege()),
            switchMap(() => this.destroyRejectByStrategyOnMissingPrivilege()),
        );
    }

    private get rejectStrategies(): PaywallRejectStrategy[] {
        if (!this._rejectStrategies) {
            this._rejectStrategies = this._injector.get<PaywallRejectStrategy[]>(PAYWALL_REJECT_STRATEGY);
        }
        return this._rejectStrategies;
    }

    private rejectStategyContext(): PaywallRejectStrategyContext {
        return {
            privilege: this._requiredPrivilege,
            rejectStrategyData: this._rejectStrategyData,
            elementRef: this._elementRef,
        };
    }
}
