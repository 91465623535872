import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ListViewItem } from './saved-view-settings-dialog.component';

@Component({
    selector: 'ruum-sortable-list',
    template: `
        <div cdkDropList (cdkDropListDropped)="dropItem($event)">
            <div
                *ngFor="let listItem of listItems; let i = index"
                class="d-flex align-items-center justify-content-between ruum-sortable-list-item pr-1"
                [class.mb-1]="!isLastItem(i)"
                [class.hov]="hasHover"
                [class.hov-child]="hasHover"
                [class.hov-bg-extra-light]="hasHover"
                [class.rounded]="hasHover"
                cdkDrag
                [cdkDragDisabled]="listItem.isFixed"
            >
                <button
                    *ngIf="!dragDisabled"
                    cdkDragHandle
                    class="btn text-gray-200 px-0 float-left drag-handle hov-child"
                    tabindex="-1"
                    type="button"
                    [class.invisible]="listItem.isFixed"
                >
                    <i class="icon icon-drag"></i>
                </button>

                <div
                    class="d-flex flex-fill justify-content-between align-items-center text-truncate selection-area"
                    (click)="onClick(listItem)"
                    (keydown.space)="onClick(listItem)"
                    (keydown.enter)="onClick(listItem)"
                >
                    <div class="d-flex align-items-center pl-0 ml-1 btn btn-sm">
                        <i
                            class="icon d-inline-flex"
                            [ngClass]="getColumnButtonIcon(listItem)"
                            [class.text-gray-600]="!listItem.isFixed && !listItem.isChecked"
                            [class.text-black]="listItem.isFixed || listItem.isChecked"
                        ></i>
                        <span
                            class="pl-2 text-small text-truncate"
                            [class.font-weight-normal]="!listItem.isFixed && !listItem.isChecked"
                            [class.text-gray-600]="!listItem.isFixed && !listItem.isChecked"
                            [class.text-black]="listItem.isFixed || listItem.isChecked"
                        >
                            {{ listItem.name }}
                        </span>
                    </div>

                    <button
                        *ngIf="hasVisibilityIcon"
                        class="btn btn-sm btn-icon hov"
                        [disabled]="listItem.isFixed"
                        [class.btn-link-primary]="!listItem.isFixed && listItem.isChecked"
                        [class.btn-link-light]="!listItem.isChecked"
                        [class.btn-link-dark]="listItem.isFixed"
                        [attr.aria-label]="getAriaLabelForButton(listItem)"
                    >
                        <i
                            class="icon text-gray-400 hov-child"
                            [class.icon-visibility-on]="listItem.isFixed || listItem.isChecked"
                            [class.icon-visibility-off]="!listItem.isChecked"
                        ></i>
                    </button>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .ruum-sortable-list-item {
                height: 36px;
            }

            .drag-handle {
                cursor: grab !important;
            }

            .cdk-drop-list-dragging {
                cursor: grabbing !important;
            }

            .cdk-drop-list-dragging .drag-handle {
                cursor: grabbing !important;
            }

            .drag-handle:hover {
                color: rgba(37, 44, 66, 0.48) !important;
            }

            .selection-area {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableListComponent {
    @Input() listItems: ListViewItem[] = [];
    @Input() hasVisibilityIcon = true;
    @Input() hasHover = true;
    @Input() dragDisabled = false;

    @Output() itemClicked = new EventEmitter<ListViewItem>();
    @Output() sortingOrderChanged = new EventEmitter<ListViewItem[]>();

    constructor() {}

    getColumnButtonIcon(item: ListViewItem) {
        return {
            [item.icon]: true,
        };
    }

    dropItem(event: CdkDragDrop<string[]>) {
        const newListItems = [...this.listItems];

        moveItemInArray(newListItems, event.previousIndex, event.currentIndex);

        this.sortingOrderChanged.emit(newListItems);
    }

    onClick(listItem: ListViewItem): void {
        this.itemClicked.emit(listItem);
    }

    isLastItem(index: number) {
        return index === this.listItems.length - 1;
    }

    getAriaLabelForButton(listItem: ListViewItem) {
        return !listItem.isChecked
            ? `Click to add ${listItem.name} column to table`
            : `Click to remove ${listItem.name} column from table`;
    }
}
