import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ViewControlsComponent } from './view-controls.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ViewControlsComponent],
    exports: [ViewControlsComponent],
})
export class ViewControlsModule {}
