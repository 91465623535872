import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SavedViewColumn } from '../../../common/connectors/savedView/saved-views.model';

@Component({
    selector: 'ruum-task-list-item-custom-field',
    template: `
        <ng-container *ngIf="column" [ngSwitch]="column?.typeId">
            <!-- CF: selection list -->
            <ruum-task-list-item-custom-field-selection-list
                *ngSwitchCase="'selection_list'"
                [column]="column"
                [taskListItem]="taskListItem"
            >
            </ruum-task-list-item-custom-field-selection-list>

            <!-- CF: date -->
            <ruum-task-list-item-custom-field-date
                *ngSwitchCase="'date'"
                [column]="column"
                [taskListItem]="taskListItem"
            ></ruum-task-list-item-custom-field-date>

            <!-- CF: other -->
            <ruum-task-list-item-custom-field-text
                *ngSwitchDefault
                [column]="column"
                [taskListItem]="taskListItem"
            ></ruum-task-list-item-custom-field-text>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListItemCustomFieldComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-wrap';

    @Input() column: SavedViewColumn;

    @Input() taskListItem;

    get customFieldType(): string {
        return this.column.typeId;
    }

    constructor() {}

    ngOnInit() {}
}
