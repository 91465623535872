import { RuumAction } from '@ruum/ruum-reducers';

export const KANBAN_ACTIONS = {
    KANBAN_LOADED: 'KANBAN_LOADED',
    KANBAN_CLEAR: 'KANBAN_CLEAR',
    KANBAN_EMPTY: 'KANBAN_EMPTY',
};

export interface KanbanLoadedActionPayload extends RuumAction {
    kanbanGroupId: string;
}

export interface KanbanClearActionPayload extends RuumAction {
    kanbanGroupId?: string;
}

export interface KanbanEmptyActionPayload extends RuumAction {
    kanbanGroupId?: string;
}
