import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RuumCommonModule } from '../../../common/common.module';
import { RecordLookupDialogModule } from '../record-lookup-dialog/record-lookup-dialog.module';
import { TableDefinitionDialogComponent } from './table-definition-dialog.component';

@NgModule({
    declarations: [TableDefinitionDialogComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RuumCommonModule, RecordLookupDialogModule],
    exports: [TableDefinitionDialogComponent],
})
export class TableDefinitionDialogModule {}
