import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GenericConfirmationDialogComponent } from '../../../common/modal/GenericConfirmationDialog';
import { ModalService } from '../../../common/modal/modal.service';
import { WebhooksConnector } from '../../webhooks.connector';
import { WebhookEntityEventListItem, WebhookEntitySyncStateListItem } from '../../webhooks.model';
import { WebhookEntitiesListService } from '../webhook-entities-list.service';
import { PayloadViewerDialogComponent } from './payload-viewer-dialog.component';
import { WebhookEntityEventsListService } from './webhook-entity-events-list.service';

@Component({
    selector: 'webhook-entity-events-list',
    template: `
        <ruum-admin-widget class="page">
            <div class="header">
                <div class="navbar navbar-light navbar-expand-lg">
                    <h3 class="ml-3 navbar-text">Events for: {{ entityId }}</h3>
                </div>
                <h6 *ngIf="(entity$ | async)?.lastEntityVersion">
                    Last version is: <b>{{ (entity$ | async)?.lastEntityVersion }}</b>
                </h6>
            </div>

            <div class="body mt-4 mb-4" scrollDetectionDirective (scrolledToBottom)="scrolledToBottom()">
                <table class="table ruum-table" *ngIf="(events$ | async)?.length > 0">
                    <thead>
                        <tr>
                            <th scope="col">Entity Version</th>
                            <th scope="col">Status</th>
                            <th scope="col">Payload</th>
                            <th scope="col">Status Code</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Changed By</th>
                            <th scope="col">Changed At</th>
                            <th scope="col" class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let event of events$ | async" [ngClass]="{ error: event.status === 'ERROR' }">
                            <td class="text-truncate">{{ event.entityVersion }}</td>
                            <td class="text-truncate">{{ event.status }}</td>
                            <td>
                                <div
                                    class="text-info cursor-pointer active"
                                    (click)="viewPayload(event)"
                                    (keydown.space)="viewPayload(event)"
                                    (keydown.enter)="viewPayload(event)"
                                >
                                    View
                                </div>
                            </td>
                            <td class="text-truncate">{{ event.statusCode }}</td>
                            <td class="text-truncate">{{ event.createdBy }}</td>
                            <td>{{ event.createdAt | date: 'dd/MM/yy h:mm:ss a' }}</td>
                            <td class="text-truncate">{{ event.changedBy }}</td>
                            <td>{{ event.changedAt | date: 'dd/MM/yy h:mm:ss a' }}</td>
                            <td class="text-center">
                                <div class="d-flex" *ngIf="event.status === 'ERROR' || event.status === null">
                                    <button
                                        [disabled]="cooldown$ | async"
                                        class="btn btn-sm btn-outline-primary mb-1"
                                        (click)="retry(event)"
                                    >
                                        Retry
                                    </button>
                                    <button
                                        [disabled]="cooldown$ | async"
                                        *ngIf="event.entityVersion < (entity$ | async)?.lastEntityVersion"
                                        class="btn btn-sm btn-outline-danger ml-1 mb-1"
                                        (click)="skip(event)"
                                    >
                                        Skip
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <!-- Loading another page -->
                        <tr *ngIf="(isLoadingAnotherPage$ | async) === true && (isLoadingFirstPage$ | async) !== true">
                            <td colspan="9">
                                <img
                                    class="ml-4 p-2"
                                    width="60"
                                    src="./../../assets/img/spinner.svg"
                                    alt="Loading Spinner"
                                />
                            </td>
                        </tr>
                        <tr
                            *ngIf="
                                (webhookEntityEventsListService.hasMore$ | async) &&
                                (isLoadingAnotherPage$ | async) === false
                            "
                        >
                            <td colspan="9">
                                <button class="btn btn-link w-100" (click)="scrolledToBottom($event)">Load More</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    class="d-inline-block w-100 mt-8 text-center text-secondary"
                    *ngIf="(events$ | async)?.length === 0"
                >
                    No events have been emitted yet
                </div>
            </div>

            <div class="footer">
                <button
                    class="btn btn-primary float-right"
                    type="button"
                    (click)="refresh()"
                    [disabled]="cooldown$ | async"
                >
                    Refresh
                </button>
                <button class="btn btn-outline-secondary float-right" type="button" (click)="back()">
                    Back
                </button>
            </div>
        </ruum-admin-widget>
    `,
    styles: [
        `
            .retry-menu {
                min-width: auto;
            }
        `,
    ],
})
export class WebhookSyncedEntityEventsComponent implements OnInit, OnDestroy {
    events$: Observable<WebhookEntityEventListItem[]> = of([]);
    webhookId: string;
    entityId: string;
    isLoadingFirstPage$: Observable<boolean>;
    isLoadingAnotherPage$: Observable<boolean>;

    entity$: Observable<WebhookEntitySyncStateListItem> = of(null);
    subscriptions: Subscription[] = [];

    private cooldownMS = 1500;
    cooldown$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private route: ActivatedRoute,
        private webhooksConnector: WebhooksConnector,
        private webhookEntitiesListService: WebhookEntitiesListService,
        private webhookEntityEventsListService: WebhookEntityEventsListService,
        private modalService: ModalService,
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            combineLatest([this.route.params, this.route.data])
                .pipe(filter(([routeParams, data]) => !!routeParams && !!data))
                .subscribe(([routeParams, data]) => {
                    this.entity$ = data.entity$;
                    this.events$ = data.events$;

                    this.webhookId = routeParams.webhookId;
                    this.entityId = routeParams.entityId;
                }),
        );

        this.isLoadingFirstPage$ = this.webhookEntityEventsListService.isLoadingFirstPage$;
        this.isLoadingAnotherPage$ = this.webhookEntityEventsListService.isLoadingAnotherPage$;
        this.webhookEntityEventsListService.reload();
    }

    triggerCooldown() {
        this.cooldown$.next(true);
        setTimeout(() => {
            this.cooldown$.next(false);
        }, this.cooldownMS);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subs) => subs.unsubscribe());
    }

    back() {
        history.back();
    }

    async refresh() {
        this.triggerCooldown();
        await this.webhookEntitiesListService.getByKeys(this.webhookId, this.entityId, true);
        this.webhookEntityEventsListService.reload();
    }

    delayedRefresh() {
        this.triggerCooldown();
        setTimeout(() => {
            this.refresh();
        }, this.cooldownMS);
    }

    scrolledToBottom() {
        this.webhookEntityEventsListService.maybeGoToNextPage();
    }

    async retry(lineItem) {
        this.webhooksConnector.retry(this.webhookId, this.entityId, lineItem.entityVersion);
        this.delayedRefresh();
    }

    async skip(lineItem) {
        const dialog = this.modalService.open(GenericConfirmationDialogComponent, { backdrop: 'static' });
        dialog.componentInstance.config = {
            title: 'Are you sure you want to skip this webhook event?',
            message:
                'There is no way to automatically revert this decision and skipping events can cause the entities in both systems to get out of sync.',
            acceptText: 'Skip',
            rejectText: 'Cancel',
        };

        const wantsToRetry: boolean = await dialog.result;

        if (wantsToRetry) {
            this.triggerCooldown();
            this.webhooksConnector.retry(this.webhookId, this.entityId, lineItem.entityVersion, true);
        }
        this.delayedRefresh();
    }

    viewPayload(entity) {
        const instance = this.modalService.open(PayloadViewerDialogComponent, {
            windowClass: 'ruum-code-preview-modal',
            backdrop: 'static',
        });
        instance.componentInstance.data = {
            ...entity,
        };
    }
}
