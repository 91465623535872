import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import {
    ExternalSystemInfo,
    ExternalSystemListFilters,
    ExternalSystemListSortBy,
} from '../../../common/connectors/externalSystem/external-system-list.model';
import { OrderedListParams, PaginatedList } from '../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../common/connectors/paginatedListLoader';
import { ReadModelBackendConnector } from '../../../common/connectors/readModelConnector.service';
import { SelectedWorkspaceService } from '../../../common/connectors/workspace/selected-workspace.service';

const emptyPage = {
    pageSize: 25,
    currentPage: 1,
    totalItems: 0,
    rows: [],
};

@Injectable({ providedIn: 'root' })
export class SystemSelectListLoader extends PaginatedListLoader<
    ExternalSystemInfo,
    ExternalSystemListFilters,
    ExternalSystemListSortBy
> {
    private data$ = new BehaviorSubject<PaginatedList<ExternalSystemInfo[]>>(emptyPage);
    private search$ = new BehaviorSubject<string>(undefined);
    private workspaceId$ = new BehaviorSubject<string>(undefined);
    private enterpriseId$ = new BehaviorSubject<string>(undefined);
    private ngOnDestroy$ = new Subject<void>();

    constructor(
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        private readModelBackendConnector: ReadModelBackendConnector,
        private selectedWorkspaceService: SelectedWorkspaceService,
    ) {
        super(alertService, authService);
        const workspace = this.selectedWorkspaceService.getSelectedWorkspace();
        if (workspace) {
            // this.enterpriseId$.next(workspace.enterpriseId);
            this.workspaceId$.next(workspace.id);
        }
        this.getListObservable()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((page: any) => {
                const currentState = this.data$.getValue();
                this.data$.next({
                    ...page,
                    rows: [...currentState.rows, ...page.rows],
                });
            });
    }

    setWorkspaceId(workspaceId: string) {
        this.workspaceId$.next(workspaceId);
    }

    setEnterpriseId(enterpriseId: string) {
        this.enterpriseId$.next(enterpriseId);
    }

    search(search: string) {
        this.reload();
        this.data$.next(emptyPage);
        this.search$.next(search);
    }

    ngDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    protected getData(
        page: number,
        filters: ExternalSystemListFilters,
        orderBy: OrderedListParams<ExternalSystemListSortBy>,
    ): Observable<PaginatedList<ExternalSystemInfo>> {
        return this.readModelBackendConnector.getExternalSystems({ page }, filters);
    }

    protected getFilters$(): Observable<ExternalSystemListFilters> {
        return combineLatest([this.enterpriseId$, this.workspaceId$, this.search$]).pipe(
            map<any, ExternalSystemListFilters>(([enterpriseId, workspaceId, search]) => ({
                enterpriseId,
                workspaceId,
                search,
            })),
        );
    }

    protected getStoreData$(): Observable<PaginatedList<any>> {
        return this.data$.asObservable();
    }
}
