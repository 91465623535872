import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileUploadOptions } from '@ruum/ruum-reducers';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EnterpriseSettingsService } from '../common/enterpriseSettings.service';

@Component({
    selector: 'ruum-admin-storage-widget',
    template: `
        <div class="py-2 py-lg-5">
            <ruum-admin-widget class="page">
                <div class="header">
                    <h3 class="ml-3 navbar-text">Storage Settings</h3>
                </div>
                <div class="body">
                    <div class="divider"></div>
                    Please choose which upload options users of your enterprise are allowed to use.
                    <form class="mt-5 mb-8 text-sm text-right" [formGroup]="form">
                        <div class="form-group row" *ngFor="let formField of formFields">
                            <label for="ruum" class="col-sm-3 col-form-label col-form-label-sm">{{
                                formField.label
                            }}</label>
                            <div class="col-sm-1 my-auto">
                                <button class="btn btn-sm btn-icon" (click)="toggleOption(formField.name)">
                                    <i
                                        class="icon"
                                        [className]="
                                            form.getRawValue()[formField.name]
                                                ? 'icon icon-checkbox-checked btn-link-primary'
                                                : 'icon icon-checkbox-unchecked btn-link-light'
                                        "
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="footer">
                    <button class="btn btn-outline-secondary float-left" type="button" (click)="resetOptions$.emit()">
                        Reset
                    </button>
                    <button [disabled]="!hasChanged" class="btn btn-primary float-right" type="button" (click)="save()">
                        Save
                    </button>
                </div>
            </ruum-admin-widget>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminStorageSettingsComponent implements OnDestroy, OnInit {
    form: FormGroup;
    fileUploadOptions: FileUploadOptions;
    fileUploadOptions$: Observable<FileUploadOptions>;
    resetOptions$: EventEmitter<void> = new EventEmitter();
    hasChanged = false;

    subscriptions: Subscription[] = [];

    formFields = [
        { name: 'ruum', label: 'My Device' },
        { name: 'external', label: 'Link' },
        { name: 'onedrive', label: 'OneDrive / Sharepoint' },
        { name: 'google_drive', label: 'Google Drive' },
        { name: 'box', label: 'Box' },
        { name: 'youtube', label: 'Youtube' },
    ];

    constructor(
        private formBuilder: FormBuilder,
        private enterpriseSettingsService: EnterpriseSettingsService,
        private cdr: ChangeDetectorRef,
    ) {
        this.form = this.formBuilder.group({
            ruum: true,
            external: true,
            onedrive: true,
            google_drive: true,
            box: true,
            youtube: true,
        });
    }

    ngOnInit() {
        this.fileUploadOptions$ = combineLatest([
            this.enterpriseSettingsService.reloadFileUploadOption(),
            this.enterpriseSettingsService.getFileUploadOptions(),
            this.resetOptions$,
        ]).pipe(
            switchMap(([loadedOptions, fileUploadOptions, _]: [FileUploadOptions, FileUploadOptions, void]) => {
                this.fileUploadOptions = loadedOptions;
                return of(fileUploadOptions);
            }),
        );

        this.subscriptions.push(
            this.fileUploadOptions$.subscribe((fileUploadOptions) => {
                if (fileUploadOptions) {
                    this.form.patchValue(fileUploadOptions);
                    this.hasChanged = false;
                    this.cdr.detectChanges();
                }
            }),
        );

        this.resetOptions$.emit();
    }

    ngOnDestroy() {
        this.subscriptions.map((sub) => sub.unsubscribe());
    }

    save() {
        const newFileUploadOptions: FileUploadOptions = this.form.getRawValue();
        of(this.enterpriseSettingsService.updateFileUploadOptions(newFileUploadOptions)).subscribe(() => {
            this.hasChanged = false;
        });
    }

    toggleOption(toggleOption: string) {
        const beforeFileUploadOptions: FileUploadOptions = this.form.getRawValue();

        const newFileUploadOptions = {
            ...beforeFileUploadOptions,
            [toggleOption]: !beforeFileUploadOptions[toggleOption],
        };

        this.hasChanged = JSON.stringify(this.fileUploadOptions) !== JSON.stringify(newFileUploadOptions);
        this.form.patchValue(newFileUploadOptions);
    }
}
