import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileHexToThemePipe } from './profile-hex-to-theme.pipe';
import { ProfileInitialsPipe } from './profile-initials.pipe';
import { ProfileMemberGroupComponent } from './profile-member-group.component';
import { ProfileMemberComponent } from './profile-member.component';
import { ProfileOthersCounterComponent } from './profile-others-counter.component';
import { ProfileRoleGroupComponent } from './profile-role-group.component';
import { ProfileRoleComponent } from './profile-role.component';
import { ProfileSizePipe } from './profile-size.pipe';
import { ProfileTagComponent } from './profile-tag.component';
import { ProfileTemplateComponent } from './profile-template.component';
import { ProfileWorkspaceComponent } from './profile-workspace.component';
import { ProfileComponent } from './profile.component';
import { TextSizePipe } from './text-size.pipe';

@NgModule({
    imports: [CommonModule, NgbTooltipModule],
    declarations: [
        ProfileComponent,
        ProfileInitialsPipe,
        ProfileSizePipe, // it should be internal
        TextSizePipe,
        ProfileHexToThemePipe,
        ProfileMemberComponent,
        ProfileMemberGroupComponent,
        ProfileRoleComponent,
        ProfileRoleGroupComponent,
        ProfileWorkspaceComponent,
        ProfileTagComponent,
        ProfileOthersCounterComponent,
        ProfileTemplateComponent,
    ],
    exports: [
        ProfileInitialsPipe,
        ProfileHexToThemePipe,
        ProfileMemberComponent,
        ProfileMemberGroupComponent,
        ProfileRoleComponent,
        ProfileRoleGroupComponent,
        ProfileWorkspaceComponent,
        ProfileTagComponent,
        ProfileOthersCounterComponent,
        ProfileTemplateComponent,
    ],
})
export class ProfileModule {}
