import { ProcessApprovalStep, ProcessCanvasStep, Processes, ProcessSystemConnectorStep } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ProcessCanvasStepWebApp extends ProcessCanvasStep {
    processId: string;
    processActiveStepId: string;
    active: boolean;
}

export interface ProcessSystemConnectorStepWebApp extends ProcessSystemConnectorStep {
    processId: string;
    processActiveStepId: string;
    active: boolean;
}

export interface ProcessApprovalStepWebApp extends ProcessApprovalStep {
    processId: string;
    processActiveStepId: string;
    active: boolean;
}

export type ProcessStepWebApp = ProcessCanvasStepWebApp | ProcessSystemConnectorStepWebApp | ProcessApprovalStepWebApp;

export interface ObjectsProcessStep {
    /**
     * objectId = 'sectionId', 'systemConnectorConfigurationId' or 'approvalId'
     */
    [objectId: string]: ProcessCanvasStepWebApp | ProcessSystemConnectorStepWebApp | ProcessApprovalStepWebApp;
}

export function objectsProcessStep(processes$: Observable<Processes>): Observable<ObjectsProcessStep> {
    return processes$.pipe(map((processes) => buildObjectsProcessStep(processes)));
}

function buildObjectsProcessStep(processes: Processes = {}): ObjectsProcessStep {
    const objects: ObjectsProcessStep = {};
    Object.entries(processes).forEach(([processId, process]) => {
        Object.entries(process.steps).forEach(([stepId, step]) => {
            if (step.type === 'canvas') {
                const active = process.activeStepId === stepId;
                objects[step.sectionId] = {
                    ...step,
                    active,
                    processId,
                    processActiveStepId: process.activeStepId,
                };
            }
            if (step.type === 'system_connector') {
                const active = process.activeStepId === stepId;
                objects[step.systemConnectorConfigurationId] = {
                    ...step,
                    active,
                    processId,
                    processActiveStepId: process.activeStepId,
                };
            }
            if (step.type === 'approval') {
                const active = process.activeStepId === stepId;
                const stepWebApp: ProcessApprovalStepWebApp = {
                    ...step,
                    active,
                    processId,
                    processActiveStepId: process.activeStepId,
                };
                objects[step.approvalId] = stepWebApp;
                objects[step.sectionId] = stepWebApp;
            }
        });
    });
    return objects;
}
