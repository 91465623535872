import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShowCommentsComponent } from './show-comments.component';

@NgModule({
    declarations: [ShowCommentsComponent],
    imports: [CommonModule],
    exports: [ShowCommentsComponent],
})
export class CommentsModule {}
