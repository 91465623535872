import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TrackingConnector } from '../../trackingConnector.service';

@Component({
    selector: 'ruum-getting-started',
    template: `
        <ruum-modal-dialog [dismissOnClose]="false">
            <div class="text-center">
                <h2 class="mb-5">
                    {{ title }}
                </h2>
                <div class="mb-6 mx-8 px-3">
                    {{ description }}
                </div>
                <iframe
                    [src]="sanitizedVideoUrl"
                    frameborder="0"
                    allow="autoplay"
                    allowfullscreen
                    width="605"
                    height="340"
                ></iframe>
                <button class="btn btn-link-primary px-4 py-3 mt-4" type="button" (click)="goToHelpCenter()">
                    Learn more on our Help Portal
                </button>
            </div>
        </ruum-modal-dialog>
    `,
})
export class GettingStartedComponent {
    sanitizedVideoUrl: SafeResourceUrl;
    plainVideoUrl: string;
    @Input()
    set videoUrl(videoUrl: string) {
        this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
        this.plainVideoUrl = videoUrl;
    }
    @Input() title;
    @Input() description;

    constructor(private sanitizer: DomSanitizer, private trackingConnector: TrackingConnector) {}

    goToHelpCenter() {
        this.trackingConnector.trackEventInferCategoryFromUrl('getting_started_helpcenter', 'opened');
        window.open('https://help.sap.com/viewer/product/RUUM/Latest/en-US', undefined, 'noopener');
    }
}
