import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SystemConnector } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../app.store';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { SelectedEnterpriseService } from '../enterprise/selected-enterprise.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { SystemConnectorListFilters, SystemConnectorListSortBy } from './system-connectors-list.model';
import { SystemConnectorsLoadedAction } from './system-connectors-list.reducer';

@Injectable({ providedIn: 'root' })
export class SystemConnectorListStore extends PaginatedListLoader<
    SystemConnector,
    SystemConnectorListFilters,
    SystemConnectorListSortBy
> {
    readonly enterpriseId$: Observable<string> = new Observable<string>(undefined);
    readonly search$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private orderBy$ = new BehaviorSubject<OrderedListParams<SystemConnectorListSortBy>>(undefined);

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        private appStore: AppStoreWrapper,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.enterpriseId$ = this.appStore.enterpriseId();
        this.setUpObservables();
    }

    protected getFilters$(): Observable<SystemConnectorListFilters> {
        return combineLatest([this.enterpriseId$, this.search$]).pipe(
            map<any, SystemConnectorListFilters>(([enterpriseId, search]) => ({
                enterpriseId,
                search,
            })),
        );
    }

    resetFilters() {
        this.search$.next(undefined);
    }

    orderBy(by: SystemConnectorListSortBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
    }

    protected getData(
        page: number,
        filters: SystemConnectorListFilters,
        orderBy: OrderedListParams<SystemConnectorListSortBy>,
    ): Observable<PaginatedList<SystemConnector>> {
        return this.readModelConnector.getSystemConnectors({ page, pageSize: 25 }, filters, orderBy);
    }

    protected getOrderBy$(): Observable<OrderedListParams<SystemConnectorListSortBy>> {
        return this.orderBy$;
    }

    getSystemConnectorById(id: string): Observable<SystemConnector> {
        return this.getStoreRows$().pipe(
            map<SystemConnector[], SystemConnector>((systemConnectors) =>
                systemConnectors.find((systemConnector) => systemConnector.id === id),
            ),
        );
    }

    getSystemConnectorList(): SystemConnector[] {
        let list = [];
        this.getStoreRows$()
            .pipe(take(1))
            .subscribe((systemConnector) => (list = systemConnector));
        return list;
    }

    getStoreRows$(): Observable<SystemConnector[]> {
        return this.store.select('systemConnectorList').pipe(map((data) => data.rows));
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<SystemConnectorsLoadedAction>('SYSTEM_CONNECTOR_LOADED', {
                page,
            });
        });
    }

    getStoreData$() {
        return this.store.select('systemConnectorList');
    }
}
