export interface ODataExpandParams {
    [property: string]: ODataQueryParams;
}
export interface ODataQueryParams {
    top?: number;
    skip?: number;
    select?: string;
    expand?: ODataExpandParams;
    filter?: string;
    orderby?: string;
}

export class ODataQueryFactory {
    static create(params: ODataQueryParams): ODataQuery {
        return new ODataQuery(params);
    }
}

export interface ODataExpandQuery {
    [property: string]: ODataQuery;
}

export class ODataQuery {
    readonly queriedAt: number = Date.now(); // Workaround to make listloader use this query (change detection does nt work for some reason...)
    private params: {
        top?: number;
        skip?: number;
        select?: string;
        expand?: ODataExpandQuery;
        filter?: string;
        orderby?: string;
    } = {};

    constructor(params: ODataQueryParams) {
        this.params.top = params.top;
        this.params.skip = params.skip;
        this.params.select = params.select;
        this.params.orderby = params.orderby;
        this.params.filter = params.filter;
        this.processExpands(params.expand);
    }

    private processExpands(params: ODataExpandParams = {}) {
        const properties = Object.keys(params);
        if (Array.isArray(properties) && properties.length > 0) {
            this.params.expand = properties.reduce((expands: ODataExpandQuery, property: string) => {
                expands[property] = new ODataQuery(params[property]);
                return expands;
            }, {});
        }
    }

    orderBy(orderby: string): ODataQuery {
        this.params.orderby = orderby;
        return this;
    }

    top(top: number): ODataQuery {
        this.params.top = top;
        return this;
    }

    getTop(): number {
        return this.params.top;
    }

    skip(skip: number): ODataQuery {
        this.params.skip = skip;
        return this;
    }

    getSkip(): number {
        return this.params.skip;
    }

    expand(params: ODataExpandParams): ODataQuery {
        this.processExpands(params);
        return this;
    }

    getExpands(): ODataExpandQuery {
        return this.params.expand || {};
    }

    select(select: string): ODataQuery {
        this.params.select = select;
        return this;
    }

    filter(filter: string): ODataQuery {
        this.params.filter = filter;
        return this;
    }

    clear(): ODataQuery {
        Object.keys(this.params).forEach((prop) => {
            this.params[prop] = null;
        });
        return this;
    }

    clone(): ODataQuery {
        return new ODataQuery(this.toParams());
    }

    toParams(): ODataQueryParams {
        return {
            ...this.params,
            expand: Object.keys(this.getExpands()).reduce((expands: ODataExpandParams, expand: string) => {
                expands[expand] = this.getExpands()[expand].toParams();
                return expands;
            }, {}),
        };
    }

    private getThisLevelQuery(joinBy: string): string {
        return Object.keys(this.params)
            .filter((property) => !!this.params[property] && property !== 'expand') // Remove all properties that do not have value and the expand
            .map((property) => {
                return `$${property}=${this.params[property]}`;
            })
            .join(joinBy);
    }

    private getExpandsQuery(): string {
        return Object.keys(this.getExpands())
            .map((property) => {
                const expandQuery = this.getExpands()[property].toQuery(true);
                if (expandQuery) {
                    return `${property}(${expandQuery})`;
                } else {
                    return `${property}`;
                }
            })
            .join(',');
    }

    toQuery(isExpand: boolean = false): string {
        const joinBy: string = isExpand ? ';' : '&';

        const thisLevelQueries = this.getThisLevelQuery(joinBy);
        const expands = this.getExpandsQuery();

        let querystr;
        const prefix = isExpand ? '' : '?';
        if (thisLevelQueries) {
            if (expands) {
                querystr = `${prefix}$expand=${expands}${joinBy}${thisLevelQueries}`;
            } else {
                querystr = `${thisLevelQueries}`;
            }
        } else if (expands) {
            querystr = `${prefix}$expand=${expands}`;
        }

        return querystr || '';
    }
}
