import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'unseen-item-counter',
    template: `
        <div class="unseen-counter" *ngIf="counter">
            <span class="counter bg-info text-white">{{ counter <= 99 ? counter : '99+' }}</span>
        </div>
    `,
    styles: [
        `
            .unseen-counter {
                position: relative;
            }
            .counter {
                position: absolute;
                bottom: 2px;
                left: -8px;
                height: 22px;
                padding: 0 3px 0 3px;
                padding-bottom: 2px;
                border-radius: 12px;
                font-size: 10px;
                line-height: 2;
                text-align: center;
                padding-left: 6px;
                padding-right: 6px;
                border: solid 2px #ffffff;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnseenItemsCounterComponent implements OnInit {
    @Input() counter: number;

    ngOnInit() {}
}
