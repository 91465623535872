import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MaskAnimType, PopupAnimType, RuumPopupBase } from '../../common/popup/ruum-popup-base';
import { mixColor } from '../utils.service';
import { RuumDropDownOption } from './ruum-dropdown-popup.component';

@Component({
    selector: 'task-dropdown-popup-mobile',
    template: `
        <div class="dropdown-popup">
            <div class="dropdown round-block scrollable" [style.max-height]="blockHeight()">
                <div class="label" *ngIf="title">{{ title }}</div>
                <div class="options">
                    <div
                        class="option"
                        [style.background-color]="getBackgroundColor(option)"
                        [style.color]="option.color"
                        [class.text-danger]="option.icon === 'icon-delete'"
                        [requiredPrivilege]="option.paywallPrivilege"
                        [rejectStrategy]="option.paywallRejectStrategy"
                        [rejectStrategyData]="{ featureName: option.text }"
                        (requiredPrivilege.click)="clickOnOption(option); $event.stopPropagation()"
                        *ngFor="let option of options"
                        [class.disabled]="option.isDisabled"
                    >
                        <span
                            class="text"
                            [ngClass]="'text-' + option.theme"
                            [attr.data-badge]="option.badgeText || ''"
                            >{{ option.text }}</span
                        >
                    </div>
                </div>
            </div>
            <div
                class="popup-footer cancel round-block"
                (click)="clickOnCancel()"
                (keydown.space)="clickOnCancel()"
                (keydown.enter)="clickOnCancel()"
            >
                Cancel
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                padding: 15px;
                text-align: center;
            }
            .round-block {
                background: white;
            }
            .dropdown {
                border-radius: 16px;
                padding: 10px;
                box-shadow: 0px 16px 32px rgba(44, 46, 47, 0.16);
                margin-bottom: 15px;
            }

            .popup-footer {
                position: relative;
            }

            .cancel {
                line-height: 48px;
            }
            .label {
                font-size: 13px;
                line-height: 2;
                margin-bottom: 30px;
            }
            .option,
            .cancel {
                font-size: 18px;
                border-radius: 8px;
            }
            .option {
                line-height: 42px;
            }
            .option.selected {
                background: rgba(0, 0, 0, 0.05);
            }
            .text {
                display: inline-block;
                position: relative;
            }
            .text:not([data-badge='']):after {
                content: attr(data-badge);
                position: absolute;
                color: white;
                display: block;
                top: 0;
                right: 0;
                transform: translate(75%, 0);
                background: #0a6ed1;
                border-radius: 10px;
                font-size: 14px;
                height: 20px;
                line-height: 20px;
                min-width: 20px;
                text-align: center;
                font-style: normal;
                padding: 0 5px;
                box-sizing: border-box;
            }
            .disabled {
                color: #d3d3d3;
                pointer-events: none;
            }
        `,
    ],
})
export class RuumDropdownPopupMobileComponent extends RuumPopupBase {
    @Input() options: RuumDropDownOption[] = [];
    @Input() selectedOption: RuumDropDownOption;
    @Input() title?: string;
    @Input() mobileFocusHelper?: (option: RuumDropDownOption) => void;

    constructor() {
        super();
    }

    clickOnOption(option) {
        if (this.mobileFocusHelper) {
            this.mobileFocusHelper(option);
        }
        this.close(option);
    }

    clickOnCancel() {
        this.close();
    }

    getMaskAnim(): MaskAnimType {
        return 'fade';
    }

    getPopupAnim(): PopupAnimType {
        return 'slideBottom';
    }

    getPos() {
        return new BehaviorSubject({ left: 0, bottom: 0, right: 0 });
    }

    blockHeight() {
        return `${window.innerHeight - 200}px`;
    }

    getBackgroundColor(option: RuumDropDownOption): string {
        if (this.selectedOption === option) {
            if (option.color) {
                return mixColor(option.color, '#FFFFFF', 0.1);
            } else {
                return 'rgba(0,0,0,0.05)';
            }
        } else {
            return 'white';
        }
    }
}
