import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-select-content',
    template: `
        <ng-content></ng-content>
    `,
    styles: [],
})
export class SelectContentComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';

    constructor() {}

    ngOnInit() {}
}
