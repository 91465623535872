import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminNavbarGuard } from './admin-navbar.guard';
import { AdminComponent } from './admin.component';
import { AdminWebappSessionGuard } from './auth/guards/AdminWebappSessionGuard';
import { DeveloperRoleGuard } from './auth/guards/DeveloperRoleGuard';
import { SelectDefaultEnterpriseGuard } from './auth/guards/SelectDefaultEnterpriseGuard';
import { SelectedEnterpriseChildrenViewsGuard } from './auth/guards/SelectedEnterpriseChildrenViewsGuard';
import { SuperAdminRoleGuard } from './auth/guards/SuperAdminRoleGuard';
import { CanDeactivateGuard } from './common/modal/CanDeactivateGuard';
import { AdminDashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home.component';
import { AdminExternalSystemsComponent } from './external-systems/external-systems.component';
import { CreateOauthClientComponent } from './security/create-oauth-client.component';
import { SecurityWidgetComponent } from './security/security-widget.component';
import { AdminStorageSettingsComponent } from './storage/storage-widget.component';
import { SupportWidgetComponent } from './support/support-widget.component';
import { AdminSystemConnectorListComponent } from './system-connector/system-connector-list/system-connector-list.component';
import { AdminTableDefinitionsComponent } from './table-definitions/table-definitions.component';
import { AdminCreateTechnicalUserComponent } from './technical-users/create-technical-user.component';
import { AdminEditTechnicalUserComponent } from './technical-users/edit-technical-user.component';
import { GetTechnicalUserByIdResolver } from './technical-users/getTechnicalUserById.resolver';
import { AdminTechnicalUserListComponent } from './technical-users/technical-user-list.component';
import { AdminEnterpriseParticipantsListComponent } from './user-list/enterprise-participant-list.component';
import { CreateWebhookComponent } from './webhooks/create-webhook.component';
import { EditWebhookComponent } from './webhooks/edit-webhook.component';
import { GetWebhookEntityByIdResolver } from './webhooks/entities/events/getWebhookEntityById.resolver';
import { GetWebhookEntityEventsResolver } from './webhooks/entities/events/getWebhookEntityEvents.resolver';
import { WebhookSyncedEntityEventsComponent } from './webhooks/entities/events/webhook-entity-events-list.component';
import { GetWebhookEntitiesListResolver } from './webhooks/entities/getWebhookEntitiesList.resolver';
import { WebhookEntitiesListComponent } from './webhooks/entities/webhook-entities-list.component';
import { GetWebhookByIdResolver } from './webhooks/getWebhookById.resolver';
import { WebhookListComponent } from './webhooks/webhook-list.component';

const routes: Routes = [
    {
        path: 'enterprise',
        component: AdminComponent,
        canActivate: [AdminWebappSessionGuard],
        canDeactivate: [SelectDefaultEnterpriseGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: HomeComponent,
            },
            {
                path: ':enterpriseId',
                canActivateChild: [SelectedEnterpriseChildrenViewsGuard, AdminNavbarGuard],
                data: { tabId: 'enterprise' },
                children: [
                    {
                        path: 'home',
                        component: HomeComponent,
                        canActivate: [SuperAdminRoleGuard],
                        data: { title: 'Home' },
                    },
                    {
                        path: 'overview',
                        component: AdminDashboardComponent,
                        canActivate: [SuperAdminRoleGuard],
                        data: { title: 'Dashboard' },
                    },
                    {
                        path: 'users',
                        component: AdminEnterpriseParticipantsListComponent,
                        canActivate: [SuperAdminRoleGuard],
                        data: { title: 'Users' },
                    },
                    {
                        path: 'technical-users',
                        canActivate: [DeveloperRoleGuard],
                        data: { title: 'Technical Users' },
                        children: [
                            {
                                path: '',
                                component: AdminTechnicalUserListComponent,
                                canActivate: [DeveloperRoleGuard],
                            },
                            {
                                path: 'new',
                                component: AdminCreateTechnicalUserComponent,
                                canActivate: [DeveloperRoleGuard],
                                canDeactivate: [CanDeactivateGuard],
                            },
                            {
                                path: ':technicalUserId',
                                component: AdminEditTechnicalUserComponent,
                                canActivate: [DeveloperRoleGuard],
                                canDeactivate: [CanDeactivateGuard],
                                resolve: {
                                    technicalUser: GetTechnicalUserByIdResolver,
                                },
                            },
                        ],
                    },
                    {
                        path: 'oauth',
                        canActivate: [SuperAdminRoleGuard],
                        data: { title: 'OAuth' },
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                component: SecurityWidgetComponent,
                                canActivate: [SuperAdminRoleGuard],
                            },
                            {
                                path: 'new',
                                component: CreateOauthClientComponent,
                                canActivate: [SuperAdminRoleGuard],
                            },
                            {
                                path: ':clientKey',
                                component: CreateOauthClientComponent,
                                canActivate: [SuperAdminRoleGuard],
                            },
                        ],
                    },
                    {
                        path: 'storage',
                        component: AdminStorageSettingsComponent,
                        canActivate: [SuperAdminRoleGuard],
                        data: { title: 'Storage' },
                    },
                    { path: 'support', component: SupportWidgetComponent, data: { title: 'Support' } },
                    {
                        path: 'systems',
                        canActivate: [DeveloperRoleGuard],
                        data: { title: 'External Systems' },
                        children: [
                            {
                                path: '',
                                component: AdminExternalSystemsComponent,
                                canActivate: [DeveloperRoleGuard],
                            },
                        ],
                    },
                    {
                        path: 'connectors',
                        canActivate: [DeveloperRoleGuard],
                        data: { title: 'System Connectors' },
                        children: [
                            {
                                path: '',
                                component: AdminSystemConnectorListComponent,
                                canActivate: [DeveloperRoleGuard],
                            },
                        ],
                    },
                    {
                        path: 'api-mapping',
                        canActivate: [DeveloperRoleGuard],
                        data: { title: 'API Mapping' },
                        children: [
                            {
                                path: '',
                                component: AdminTableDefinitionsComponent,
                                canActivate: [DeveloperRoleGuard],
                            },
                        ],
                    },
                    {
                        path: 'webhooks',
                        canActivate: [DeveloperRoleGuard],
                        data: { title: 'Webhooks' },
                        children: [
                            {
                                path: '',
                                component: WebhookListComponent,
                                canActivate: [DeveloperRoleGuard],
                            },
                            {
                                path: 'new',
                                component: CreateWebhookComponent,
                                canActivate: [DeveloperRoleGuard],
                                canDeactivate: [CanDeactivateGuard],
                            },
                            {
                                path: ':webhookId',
                                component: EditWebhookComponent,
                                canActivate: [DeveloperRoleGuard],
                                canDeactivate: [CanDeactivateGuard],
                                resolve: {
                                    webhook: GetWebhookByIdResolver,
                                },
                            },
                            {
                                path: ':webhookId/entities',
                                component: WebhookEntitiesListComponent,
                                canActivate: [DeveloperRoleGuard],
                                resolve: {
                                    webhook: GetWebhookByIdResolver,
                                    entities$: GetWebhookEntitiesListResolver,
                                },
                            },
                            {
                                path: ':webhookId/entities/:entityId/events',
                                component: WebhookSyncedEntityEventsComponent,
                                canActivate: [DeveloperRoleGuard],
                                resolve: {
                                    webhook: GetWebhookByIdResolver,
                                    entity$: GetWebhookEntityByIdResolver,
                                    events$: GetWebhookEntityEventsResolver,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'admin',
        pathMatch: 'full',
        redirectTo: '/admin/enterprise',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {}
