import { Component, HostBinding, Input } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-task-list-cell-start-date',
    template: `
        <div *ngIf="task.startDate && !task.isMilestone" class="d-flex small text-dark py-2">
            {{ formatDate(task.startDate) }}
        </div>
    `,
})
export class TaskListCellStartDateComponent {
    @HostBinding() class = 'd-flex align-items-center';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }

    task: any = {};

    constructor() {}

    formatDate(day: Day): string {
        return day && new Date(day.year, day.month - 1, day.day, 0, 0, 0, 0).format('DD MMM YYYY');
    }
}
