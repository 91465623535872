import { Injectable } from '@angular/core';
import { EnterpriseRole } from '@ruum/ruum-reducers';
import { BaseRoleGuard } from './BaseRoleGuard';

@Injectable({ providedIn: 'root' })
export class DeveloperRoleGuard extends BaseRoleGuard {
    protected getAllowedRoles(): string[] {
        return [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin];
    }
}
