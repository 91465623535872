import { EnterpriseOAuthClient, FileUploadOptions } from '@ruum/ruum-reducers';
import { PaginationParams } from '../connectors/lobbyList/lobby-list.model';
import { ODataQuery } from './ODataQuery';

// Generic
export enum ODATA_SERVICE_NAME {
    INTERNAL = 'RuumInternal.svc',
}

export const DEFAULT_ODATA_PAGE_SIZE = 20;

export interface RuumJWTUserData {
    userId: string;
    fullName: string;
    email: string;
}

export interface ODataResponse {
    '@odata.context'?: string;
}
export interface ODataListResponse extends ODataResponse {
    '@odata.nextLink'?: string;
    value: ODataListItemResponse[];
    '@odata.count'?: number;
}

export interface ODataListItemResponse {
    '@odata.id'?: string;
    '@odata.editLink'?: string;
}
export interface ODataRequestParams {
    pagination?: PaginationParams; // Required for list loader... Maybe enhance the query builder and add it there?
    path: string;
    serviceRoot: string;
    query?: ODataQuery;
}

// Entities
export interface ODataEnterpriseParticipant extends ODataListItemResponse {
    enterpriseId: string;
    userId: string;
    name: string;
    initials: string;
    color: string;
    email: string;
    role: string;
}
export interface ODataEnterpriseTechnicalUser extends ODataListItemResponse {
    enterpriseId: string;
    userId: string;
    name: string;
    initials: string;
    color: string;
    email: string;
}

export interface ODataEnterpriseOAuthClient extends ODataListItemResponse, EnterpriseOAuthClient {
    enterpriseId?: string;
    // Add more props heres
}

export interface ODataEnterprise extends ODataResponse {
    enterpriseId: string;
    name: string;
    createdAt: string;
    patterns: string[];
}

export interface ODataEnterpriseFileUploadOptions extends ODataResponse, FileUploadOptions {
    enterpriseId?: string;
    // inherits the other stuff from the FileUploadOptions
}

export interface ODataRequestParams {
    pagination?: PaginationParams; // Required for list loader... Maybe enhance the query builder and add it there?
    path: string;
    serviceRoot: string;
    query?: ODataQuery;
}
export interface ODataSystem extends ODataResponse {
    systemId: string;
    enterpriseId: string;
    name: string;
    protocol: string;
    hostname: string;
    port: number;
    authType: string;
}
