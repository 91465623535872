import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ruum-dropdown-mobile',
    template: `
        <div
            class="d-flex flex-fill minw-0 w-100"
            (click)="isReadOnly || openModal($event, modalContent)"
            (keydown.space)="isReadOnly || openModal($event, modalContent)"
            (keydown.enter)="isReadOnly || openModal($event, modalContent)"
            [class.read-only]="isReadOnly"
        >
            <ng-content select="[data-content='ruum-dropdown-button']"> </ng-content>
        </div>
        <ng-template #modalContent let-modal>
            <ruum-modal-dialog-mobile
                [cancel]="showCancelButton"
                [apply]="showApplyButton"
                (dismiss)="modal.dismiss()"
                (close)="modal.close()"
            >
                <ng-content select="[data-content='ruum-dropdown-menu']"> </ng-content>
            </ruum-modal-dialog-mobile>
        </ng-template>
    `,
    styles: [
        `
            .read-only {
                pointer-events: none;
                cursor: default;
            }
        `,
    ],
})
export class RuumDropdownMobileComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';

    @Input()
    stopPropagation = true;

    @Input()
    showCancelButton = true;

    @Input()
    showApplyButton = false;

    @Input()
    isReadOnly = false;

    @Output()
    openMenu = new EventEmitter();

    @Output()
    closeMenu = new EventEmitter<string>();

    private openedModal: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    ngOnInit() {}

    openModal(event, content) {
        if (this.stopPropagation) {
            event.stopPropagation();
        }
        this.openMenu.emit();
        this.openedModal = this.modalService.open(content, { windowClass: 'bs-mobile' });
        this.openedModal.result.then(
            () => {
                this.closeMenu.emit('close');
            },
            () => {
                this.closeMenu.emit('dismiss');
            },
        );
    }

    close() {
        this.openedModal.close();
    }
}
