import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AppStoreWrapper } from '../../common/appStoreWrapper.service';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { ODataEnterpriseParticipant } from '../../common/odata/odata.model';
import { ODataConnector } from '../../common/odata/ODataConnector.service';
import { ODataPaginatedListLoader } from '../../common/odata/ODataPaginatedListLoader';
import { ODataQuery } from '../../common/odata/ODataQuery';
import { ServiceHelper } from '../../common/serviceHelper';
import {
    EnterpriseParticipantsLoadedAction,
    ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES,
} from './enterprise-participant-list.reducer';
export type UserListOrderBy = 'name' | 'mail | role';

export interface UserListFilters {
    name: string;
}
@Injectable({ providedIn: 'root' })
export class EnterpriseParticipantsListService extends ODataPaginatedListLoader<ODataEnterpriseParticipant> {
    readonly enterpriseParticipantList$: Observable<ODataEnterpriseParticipant[]>;

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private oDataConnector: ODataConnector,
        private serviceHelper: ServiceHelper,
        private profileService: ProfileService,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        protected selectedEnterpriseService: SelectedEnterpriseService,
    ) {
        super(alertService, authService);
        this.query({ top: 25 });
        this.setUpObservables();
        this.enterpriseParticipantList$ = this.enterpriseParticipantList();
        this.loadList();
    }

    getListObservable(): Observable<PaginatedList<ODataEnterpriseParticipant>> {
        return super.getListObservable();
    }

    protected getRecords(query: ODataQuery): Observable<PaginatedList<ODataEnterpriseParticipant>> {
        const selectedEnterprise = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.oDataConnector.getList<ODataEnterpriseParticipant>({
            serviceRoot: 'RuumInternal.Svc',
            path: `Enterprises('${selectedEnterprise}')/participants?`,
            query,
        });
    }

    private enterpriseParticipantList(): Observable<ODataEnterpriseParticipant[]> {
        return this.getStoreData$().pipe(map((page: PaginatedList<ODataEnterpriseParticipant>) => page.rows));
    }

    private setUpObservables() {
        combineLatest([this.profileService.getCurrentUserEnterpriseId$(), this.getListObservable()])
            .pipe(distinctUntilChanged())
            .subscribe(([enterpriseId, page]) => {
                this.serviceHelper.dispatchWithoutPersisting<EnterpriseParticipantsLoadedAction>(
                    ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES.ENTERPRISE_PARTICIPANTS_LOADED,
                    { enterpriseId, page },
                );
            });
    }

    getStoreData$(): Observable<PaginatedList<ODataEnterpriseParticipant>> {
        return this.appStoreWrapper.enterpriseParticipants();
    }
}
