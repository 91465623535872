import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, switchAll } from 'rxjs/operators';
import { ReadModelBackendConnector } from '../connectors/readModelConnector.service';
import { TrackingConnector } from '../trackingConnector.service';

interface SearchableItem {
    id: string;
    name: string;
    [any: string]: any;
}
@Component({
    selector: 'select-section',
    template: `
        <div class="position-relative">
            <input
                class="form-control form-control-sm"
                placeholder="Select or search for a Section"
                ruumInputHighlight
                autocomplete="off"
                [ngbTypeahead]="sectionItems"
                [(ngModel)]="selectedSection"
                (selectItem)="onSelectSection($event)"
                [inputFormatter]="formatter"
                [resultTemplate]="itemTemplate"
                (click)="sectionSearchClick()"
                (focus)="sectionSearchClick()"
                [disabled]="!projectId"
                data-test="ruum-select-section"
                ngbAutofocus
            />

            <ng-template #itemTemplate let-item="result" let-t="term">
                <ng-container *ngIf="item.contentLocked">
                    <div [ngbTooltip]="tooltipContent" [placement]="['top-left', 'bottom-left']" [container]="'body'">
                        <button class="btn btn-link-secondary minh-0 py-0 px-0" [disabled]="true">
                            <i class="icon icon-lock-locked"></i>
                            <span class="pl-2 text-secondary font-weight-normal">{{ item.name }}</span>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!item.contentLocked">
                    <span>{{ item.name }}</span>
                </ng-container>
            </ng-template>

            <ng-template #tooltipContent>
                <div class="text-small font-weight-bold">This Section is locked.</div>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSectionComponent implements OnInit {
    @Input() projectId: string;
    @Output() selectSection = new EventEmitter<string>();

    sectionItems$: Observable<SearchableItem[]>;
    showSections$ = new Subject<void>();
    selectedSection: SearchableItem;

    constructor(private trackingConnector: TrackingConnector, private readModelConnector: ReadModelBackendConnector) {}

    ngOnInit() {}

    sectionItems = (text$: Observable<string>): Observable<SearchableItem[]> => {
        return combineLatest([this.showSections$, text$.pipe(startWith(''))]).pipe(
            map(([_, search]) => this.getSections(search)),
            switchAll(),
            map((data) => data.result),
        );
    };

    sectionSearchClick() {
        this.showSections$.next();
    }

    formatter = (item: SearchableItem): string => {
        return item.locked ? '' : item.name;
    };

    onSelectSection(data) {
        if (data.item.locked) {
            this.selectSection.emit(undefined);
        } else {
            this.selectSection.emit(data.item.id);
            this.trackingConnector.trackEventInferCategoryFromUrl('section', 'selected', data.item.id);
        }
    }

    private getSections(search: string): Observable<{ search: string; result: SearchableItem[] }> {
        return this.readModelConnector.getSections(this.projectId, { name: search }).pipe(
            map((page) => {
                return {
                    search,
                    result: page.rows,
                };
            }),
        );
    }
}
