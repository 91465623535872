import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { TaskStatus } from '@ruum/ruum-reducers';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../ui-components/ui-components.type';
import { TaskStatusLightThemePipe } from './task-status-light-theme.pipe';
import { TaskStatusService } from './task-status.service';

@Component({
    selector: 'ruum-task-status',
    template: `
        <i class="icon" [ngClass]="status.icon"></i>
        <span *ngIf="showName" class="pl-1">{{ status.name }}</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStatusComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            componentHelper.getDefaultClassName(),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getDisabledClassName(this.disabled),
            componentHelper.getCursorClassName(this.disabled),
            this.getIconClassName(this.showName),
            this.getSizeClassName(this.size),
            this.getThemeClassName(this.theme, this.type, this.status, this.showName),
            this.componentClass,
        ]);
    }

    @HostBinding('attr.tabindex') get tabIndex() {
        return this.keyTabIndex;
    }

    // Component properties
    @Input() statusId: TaskStatus;
    @Input() showName: boolean;
    @Input() keyTabIndex = 0;

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme;
    @Input() type: ComponentType;
    @Input() rounded = true;
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    private taskStatusLightThemePipe = new TaskStatusLightThemePipe();

    constructor(private taskStatusService: TaskStatusService) {}

    ngOnInit() {}

    get status() {
        return this.taskStatusService.getStatusById(this.statusId);
    }

    private getIconClassName(showName): string {
        return showName ? '' : 'btn-icon';
    }

    private getSizeClassName(size: string): string {
        return ['btn', size].filter((el) => !!el).join('-');
    }

    private getThemeClassName(theme: ComponentTheme, type: ComponentType, status, showName): string {
        const defaultTheme = theme || status.theme;
        const defaultType = type || 'link';

        const themeValue = showName ? this.taskStatusLightThemePipe.transform(defaultTheme) : defaultTheme;
        const typeValue = showName ? 'default' : defaultType;

        return componentHelper.getThemeClassName(themeValue, typeValue);
    }
}
