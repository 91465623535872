import { Injectable } from '@angular/core';
import { User } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, merge, scan, switchAll, withLatestFrom } from 'rxjs/operators';
import { ProjectParticipantWithAllRoles, ReadModelBackendConnector } from '../connectors/readModelConnector.service';
interface ItemOperation {
    type: 'set' | 'prev' | 'next' | 'enter';
    value?: number;
}

export interface SearcheableParticipant extends User {
    searchParam: string;
}

@Injectable({ providedIn: 'root' })
export class AddCommentService {
    projectId: string;
    private participantToMention$ = new Subject<ProjectParticipantWithAllRoles>();
    private atMentionSearch$ = new BehaviorSubject<string>(undefined);
    /**
     * NaN for no selection. The index may be out of the range.
     */
    private atMentionItemOperation$ = new BehaviorSubject<ItemOperation>({ type: 'set', value: NaN });
    private atMentionList$: BehaviorSubject<ProjectParticipantWithAllRoles[]> = new BehaviorSubject<
        ProjectParticipantWithAllRoles[]
    >([]);
    private atMentionFocusIndex$: Subject<number> = new Subject<number>();

    constructor(private readModelBackendConnector: ReadModelBackendConnector) {
        this.listenToAtMentionList();
        this.initFocusItem();
    }

    searchAtMention(searchTerm: string) {
        this.atMentionSearch$.next(searchTerm);
    }

    atMentionSearch() {
        return this.atMentionList$.asObservable();
    }

    mentionParticipant(participant: ProjectParticipantWithAllRoles) {
        this.participantToMention$.next(participant);
    }

    participantToMention() {
        return this.participantToMention$;
    }

    setAtMentionFocusIndex(index: number) {
        this.atMentionItemOperation$.next({ type: 'set', value: index });
    }
    atMentionFocusIndex(): Observable<number> {
        return this.atMentionFocusIndex$;
    }

    previousAtMentionFocus() {
        this.atMentionItemOperation$.next({ type: 'prev' });
    }

    nextAtMentionFocus() {
        this.atMentionItemOperation$.next({ type: 'next' });
    }

    enterOnMention() {
        this.atMentionItemOperation$.next({ type: 'enter' });
    }

    atMentionEnter(): Observable<null> {
        return this.atMentionItemOperation$.pipe(
            filter((op) => op.type === 'enter'),
            map(() => null),
        );
    }

    private listenToAtMentionList(): void {
        this.atMentionSearch$
            .pipe(
                distinctUntilChanged(),
                map((search) => {
                    if (!search && search !== '') {
                        return of([]);
                    }
                    return this.readModelBackendConnector
                        .getProjectAccess(
                            { page: 1, pageSize: 10 },
                            this.projectId,
                            {
                                search,
                                isTechnicalUser: false,
                            },
                            { by: 'access' },
                        )
                        .pipe(map((data) => data.rows));
                }),
                switchAll(),
            )
            .subscribe((list) => {
                this.atMentionList$.next(list);
            });
    }

    private initFocusItem() {
        this.atMentionItemOperation$
            .pipe(
                filter((op) => op.type !== 'enter'),
                // eslint-disable-next-line import/no-deprecated
                merge(
                    this.atMentionSearch$.pipe(
                        distinctUntilChanged(),
                        map<string, ItemOperation>(() => ({ type: 'set', value: NaN })),
                    ),
                ),
                scan<any, any>((last, operation) => {
                    if (operation.type === 'set') {
                        return operation.value;
                    } else if (operation.type === 'prev') {
                        return isNaN(last) ? -1 : last - 1;
                    } else {
                        return isNaN(last) ? 0 : last + 1;
                    }
                }, NaN),
                withLatestFrom(this.atMentionList$.pipe(map((list) => list.length)), (index, length) => {
                    if (length === 0) {
                        return NaN;
                    } else if (index > 0) {
                        return index % length;
                    } else {
                        return (length + (index % length)) % length;
                    }
                }),
            )
            .subscribe((index) => {
                this.atMentionFocusIndex$.next(index);
            });
    }
}
