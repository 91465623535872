import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RuumSelectOptionType } from '../select.model';
import { SelectService } from '../select.service';

@Component({
    selector: 'ruum-select-option',
    template: `
        <div class="d-flex flex-fill align-items-start minw-0">
            <div *ngIf="icon" class="btn btn-xs btn-icon btn-link-secondary mr-1 my-1">
                <i class="icon" [ngClass]="icon"></i>
            </div>
            <div class="d-flex flex-column flex-fill minw-0">
                <div class="flex-fill text-small text-truncate pt-2 pb-1" [title]="content">
                    {{ content }}
                </div>
                <div *ngIf="description" class="text-secondary flex-fill text-tiny text-truncate pb-1">
                    {{ description }}
                </div>
            </div>
            <ng-container *ngIf="isMultiSelect">
                <div
                    class="btn btn-icon btn-xs btn-without-hover my-1"
                    [class.btn-link-gray-24]="!selected"
                    [class.btn-link-primary]="selected"
                >
                    <i
                        class="icon"
                        [class.icon-checkbox-checked]="selected"
                        [class.icon-checkbox-unchecked]="!selected"
                    ></i>
                </div>
            </ng-container>
            <ng-container *ngIf="!isMultiSelect">
                <div class="btn btn-link-secondary btn-icon btn-xs btn-without-hover my-1">
                    <i *ngIf="selected" class="icon icon-done"></i>
                </div>
            </ng-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionComponent implements OnInit, OnDestroy {
    @HostBinding('class.ruum-select-option')
    hostClassList = true;

    @HostBinding('class.d-none') get invisible() {
        return !this.isVisible;
    }

    @HostBinding('class.disabled')
    @Input()
    disabled = false;

    @HostBinding('attr.tabindex') tabIndex = 0;

    @HostBinding('style.margin-bottom.px') marginBottom = 2;

    @HostBinding('class.active')
    @Input()
    set selected(selected: boolean) {
        if (this.selected !== selected) {
            this.changeDetectorRef.markForCheck();
        }
        this._selected = selected;
    }
    get selected(): boolean {
        return this._selected;
    }

    @Input() value: string;
    @Input() theme: string;
    @Input() icon: string;
    @Input() description: string;

    @Input()
    set content(content: string) {
        if (this.content !== content && this._selected) {
            this.selectService.updateContent(this.value, content);
        }
        this._content = content;
    }
    get content(): string {
        return this._content;
    }

    isVisible = true;
    isMultiSelect = false;

    optionType: RuumSelectOptionType = 'default';

    private _selected = false;
    private _content: string;
    private ngOnDestroy$ = new Subject<void>();

    constructor(
        private selectService: SelectService,
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        if (this.value) {
            // this line will update the content if select was set before option created
            // because of lazy loading options this is needed
            if (this.selectService.isSelected(this.value)) {
                this.selectService.updateContent(this.value, this._content);
            }
            this.selectService.registerOption(this.value, this);
        }
        this.selectService.isMultiSelect$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((isMultiSelect) => (this.isMultiSelect = isMultiSelect));
        this.selectService.search$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((search) => {
            let isVisible = this.isVisible;
            if (!search) {
                isVisible = true;
            } else {
                if (this.content.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                    isVisible = true;
                } else {
                    isVisible = false;
                }
            }
            if (this.isVisible !== isVisible) {
                this.isVisible = isVisible;
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    ngOnDestroy() {
        if (this.value) {
            this.selectService.unRegisterOption(this.value);
        }
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    @HostListener('click', ['$event'])
    onMenuClick(event: MouseEvent) {
        this.toggleOption(event);
    }

    @HostListener('keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            this.toggleOption(event);
        }
    }

    focusElement() {
        (this.elementRef.nativeElement as HTMLElement).focus();
    }

    private toggleOption(event: MouseEvent | KeyboardEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        if (!this.value) {
            return;
        }
        // dont unselect on single Select
        if (!this.isMultiSelect && this._selected) {
            return;
        }
        this._selected = !this._selected;
        if (this._selected) {
            this.selectService.setSelect(this.value, this.content);
        } else {
            this.selectService.setUnSelect(this.value);
        }
        this.selectService.onEvent(event);
    }
}
