import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomFieldCommonModule } from './custom-field-common/custom-field-common.module';
import { CustomFieldDialogModule } from './custom-field-dialog/custom-field-dialog.module';
import { CustomFieldListModule } from './custom-field-list/custom-field-list.module';
import { CustomFieldSelectModule } from './custom-field-select/custom-field-select.module';
import { CustomFieldValueModule } from './custom-field-value/custom-field-value.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        CustomFieldListModule,
        CustomFieldValueModule,
        CustomFieldDialogModule,
        CustomFieldCommonModule,
        CustomFieldSelectModule,
    ],
    exports: [CustomFieldListModule, CustomFieldValueModule, CustomFieldCommonModule, CustomFieldSelectModule],
})
export class CustomFieldModule {}
