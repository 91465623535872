import { FormGroup } from '@angular/forms';

export class BaseFormComponent {
    form: FormGroup;

    isDirty(): boolean {
        return this.form ? this.form.dirty : false;
    }

    back() {
        history.back();
    }
}
