import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { Enterprise } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProfileService } from '../../../common/connectors/profile/profile.service';
import { SelectedEnterpriseService } from './../../../common/connectors/enterprise/selected-enterprise.service';
@Injectable({ providedIn: 'root' })
export class SelectedEnterpriseChildrenViewsGuard implements CanActivateChild {
    constructor(
        private router: Router,
        private profileService: ProfileService,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        const enterpriseId = route.params.enterpriseId;

        return combineLatest([
            this.profileService.isCurrentUserIntegrationAdmin(enterpriseId),
            this.profileService.isCurrentUserSuperAdmin(enterpriseId),
        ]).pipe(
            switchMap(([isIntegrationAdmin, isAdmin]) => {
                if (isIntegrationAdmin || isAdmin) {
                    return this.selectedEnterpriseService
                        .selectEnterprise(enterpriseId)
                        .then((enterprise: Enterprise) => !!enterprise?.id);
                } else {
                    this.router.navigateByUrl('admin/enterprise');
                }
            }),
        );
    }
}
