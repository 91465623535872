import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RuumPaywallModule } from '../../../paywall/paywall.module';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { RuumDropdownDesktopComponent } from './dropdown-desktop.component';
import { RuumDropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { RuumDropdownListComponent } from './dropdown-list/dropdown-list.component';
import { RuumDropdownMobileComponent } from './dropdown-mobile.component';
import { RuumDropdownSectionListComponent } from './dropdown-section-list/dropdown-section-list.component';
import { RuumDropdownWrapperComponent } from './dropdown-wrapper.component';
import { RuumDropdownComponent } from './dropdown.component';
import { DropdownSelectButtonComponent } from './select/dropdown-select-button.component';

const COMPONENTS = [
    RuumDropdownComponent,
    RuumDropdownWrapperComponent,
    RuumDropdownMobileComponent,
    RuumDropdownDesktopComponent,
    DropdownSelectButtonComponent,
    RuumDropdownSectionListComponent,
    RuumDropdownListComponent,
    RuumDropdownItemComponent,
];

@NgModule({
    imports: [
        CommonModule,
        NgbModalModule,
        NgbPopoverModule,
        NgbTooltipModule,
        LayoutModule,
        ModalDialogModule,
        RuumPaywallModule,
    ],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
})
export class DropdownModule {}
