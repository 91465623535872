import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    EnableMarketingEmailsAction,
    EnterpriseAuthenticationType,
    EnterpriseRole,
    ProfileActionTypes,
    RuumAction,
    SetHasSeenWelcomeMessageAction,
    SetNameAction,
    SetNotificationSettingsAction,
    SetTimezoneAction,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { ACTION_TYPES } from '../../actions';
import { ServiceHelper } from '../../serviceHelper';
import { AuthBackendConnector } from '../authServiceConnector.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { AppState } from './../../app.store';
import { ReadModelBackendConnector } from './../readModelConnector.service';
import { ProfileListItem } from './profile.model';

export interface EnterprisesAccess {
    enterpriseId: string;
    role: EnterpriseRole;
    authenticationType: EnterpriseAuthenticationType;
    oidcProviderId: string;
}

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private AUTH = environment.AUTH_SERVICE_URL;
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;
    private currentUserProfile$: BehaviorSubject<ProfileListItem> = new BehaviorSubject(null);

    constructor(
        private serviceHelper: ServiceHelper,
        private http: HttpClient,
        private authService: AuthService,
        private store: Store<AppState>,
        private readModelBackendConnector: ReadModelBackendConnector,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
        private authBackendConnector: AuthBackendConnector,
    ) {}

    async changeSettings(newSettings, previousSettings) {
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.EDIT_USER_SETTINGS, newSettings);
        try {
            await this.persistAction<SetNotificationSettingsAction>(
                ProfileActionTypes.SET_NOTIFICATION_SETTINGS,
                newSettings,
            );
        } catch (err) {
            this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.EDIT_USER_SETTINGS, previousSettings);
        }
    }

    getCurrentUserProfile(): Observable<ProfileListItem> {
        return this.currentUserProfile$.pipe(filter((profile) => !!profile));
    }

    setCurrentUserProfile(profile: ProfileListItem) {
        this.currentUserProfile$.next(profile);
    }

    getCurrentUserProfileValue(): ProfileListItem {
        return this.currentUserProfile$.getValue();
    }

    getCurrentUserEnterpriseRole$(enterpriseId?: string): Observable<EnterpriseRole> {
        if (enterpriseId) {
            return this.getAvailableUserEnterprises$().pipe(
                map(
                    (enterprises: EnterprisesAccess[]): EnterpriseRole =>
                        enterprises.find((enterprise: EnterprisesAccess) => enterprise.enterpriseId === enterpriseId)
                            ?.role,
                ),
            );
        }
        return this.getCurrentUserProfile().pipe(pluck('enterpriseRole'));
    }

    getCurrentUserEnterpriseId$(): Observable<string> {
        return this.getCurrentUserProfile().pipe(pluck('enterpriseId'));
    }

    getAvailableUserEnterprises$(): Observable<EnterprisesAccess[]> {
        return this.getCurrentUserProfile().pipe(pluck('enterprises'));
    }

    getUserAdminEnterprises(): Observable<EnterprisesAccess[]> {
        return this.getAvailableUserEnterprises$().pipe(
            map((enterprises: EnterprisesAccess[]) =>
                enterprises.filter(
                    (enterprise) =>
                        enterprise.role === EnterpriseRole.EnterpriseAdmin ||
                        enterprise.role === EnterpriseRole.EnterpriseIntegrationAdmin,
                ),
            ),
        );
    }

    isCurrentUserSuperAdmin(enterpriseId?: string): Observable<boolean> {
        return this.getCurrentUserEnterpriseRole$(enterpriseId).pipe(
            map((role: EnterpriseRole) => {
                return role === EnterpriseRole.EnterpriseAdmin;
            }),
        );
    }

    isCurrentUserIntegrationAdmin(enterpriseId?: string): Observable<boolean> {
        return this.getCurrentUserEnterpriseRole$(enterpriseId).pipe(
            map((role: EnterpriseRole) => {
                return role === EnterpriseRole.EnterpriseIntegrationAdmin;
            }),
        );
    }

    async dismissWelcomeMessage() {
        await this.persistAction<SetHasSeenWelcomeMessageAction>(ProfileActionTypes.SET_HAS_SEEN_WELCOME_MESSAGE, {});
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.USER_SEEN_WELCOME_MESSAGE, {});
    }

    updateTimezone(currentTimezone: string) {
        const probableTimezone: string = Date.timezone();
        if (currentTimezone !== probableTimezone) {
            this.persistAction<SetTimezoneAction>(ProfileActionTypes.SET_TIMEZONE, {
                timezone: probableTimezone,
            });
        }
        return probableTimezone;
    }

    loadMyProfile(): Promise<ProfileListItem> {
        return this.readModelBackendConnector
            .getUserProfile()
            .toPromise()
            .then((profile: ProfileListItem) => {
                this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.USER_PROFILE_LOADED, profile);
                this.setCurrentUserProfile(profile);
                this.authService.setLoggedUserId(profile.id);
                this.authService.setEmail(profile.email);

                profile.timezone = this.updateTimezone(profile.timezone);
                return profile;
            });
    }

    changeName(firstName: string, lastName: string): Promise<any> {
        return Promise.all([
            this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                field: 'firstName',
                value: firstName,
            }),
            this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                field: 'lastName',
                value: lastName,
            }),
            this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                field: 'fullName',
                value: `${firstName} ${lastName}`,
            }),
        ]).then(() => {
            return this.persistAction<SetNameAction>(ProfileActionTypes.SET_NAME, {
                firstName,
                lastName,
            });
        });
    }

    changeMail(newMail: string, password) {
        return this.http
            .post(
                this.AUTH + '/v1/requestMailChange',
                { newMailAddress: newMail.toLowerCase(), password },
                { withCredentials: true },
            )
            .toPromise()
            .then(() =>
                this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                    field: 'pendingMailAddress',
                    value: newMail,
                }),
            );
    }

    requestMailVerificationDelete() {
        return this.http
            .delete(this.AUTH + '/v1/requestEmailVerification', { withCredentials: true })
            .toPromise()
            .then(() =>
                this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                    field: 'pendingMailAddress',
                }),
            );
    }

    verifyEmail(username: string, token: string) {
        return this.authService.verifyEmail(username, token).then((result) => {
            return this.serviceHelper
                .dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                    field: 'emailVerified',
                    value: true,
                })
                .then(() => result);
        });
    }

    updateEmailInProfile(userMail: string) {
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
            field: 'email',
            value: userMail,
        });
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
            field: 'pendingMailAddress',
        });
    }

    async setMarketingEmailsAllowed(enabled: boolean) {
        await this.persistAction<EnableMarketingEmailsAction>(ProfileActionTypes.ENABLE_MARKETING_MAILS, {
            enabled,
        });
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
            field: 'marketingEmailsAllowed',
            value: enabled,
        });
    }

    deleteMyself(comment?: string) {
        const userId = this.authService.getLoggedUser().id;
        return this.http
            .delete(this.PROJECT_SERVICE_URL + `/v1/profiles/${userId}`, { withCredentials: true })
            .toPromise()
            .then(() => {
                try {
                    localStorage.removeItem('ruumMailAddress');
                } catch (err) {}
                this.logout();
            });
    }

    logout() {
        this.currentUserProfile$.next(undefined);
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.USER_PROFILE_LOADED, {});
    }

    agreeToNewPrivacyStatement(privacyStatement: string) {
        return this.http
            .post(this.PROJECT_SERVICE_URL + '/v1/profile/privacy', { privacyStatement }, { withCredentials: true })
            .toPromise()
            .then((result: { updatedPrivacyLink: string }) => {
                this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.UPDATE_USER_PROFILE, {
                    field: 'privacyLink',
                    value: result.updatedPrivacyLink,
                });
            });
    }

    userProfile(): Observable<ProfileListItem> {
        return this.store.select('userProfile');
    }

    persistAction<T extends RuumAction>(type: T['type'], payload: T['payload']): Promise<any> {
        const profile = this.getCurrentUserProfileValue();
        if (!profile?.id) {
            return Promise.reject(`Logged user ID is undefined. action ${type}`);
        } else {
            return this.projectServiceBackendConnector.addActionToProfile<T>(profile.id, type, payload);
        }
    }

    userNotificationSettings() {
        return this.userProfile().pipe(map((profile) => profile.notificationSettings));
    }

    userUnseenReleases() {
        // TODO: is already removed from profile entity --> can go as well
        return this.userProfile().pipe(map((profile) => []));
    }

    userShouldSeeGettingStartedMessage() {
        return this.userProfile().pipe(
            map((profile) => {
                if (!profile.id) {
                    return false;
                }
                // return !profile.hasSeenWelcomeMessage;
                return false; // disable the welcome popup
            }),
            distinctUntilChanged(),
        );
    }

    userMailBounced() {
        return this.userProfile().pipe(
            filter((profile) => !!profile.id),
            map((profile) => {
                if (!profile.id) {
                    return undefined;
                }
                return profile.bounced;
            }),
            distinctUntilChanged(),
        );
    }
}
