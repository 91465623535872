import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-assigned-item-list',
    template: `
        <ng-container *ngFor="let assignee of assignees | slice: 0:maxShownAssignees; let last = last">
            <ruum-profile-member
                *ngIf="!isRole"
                [name]="assignee.name"
                [theme]="assignee.color | ruumProfileHexToTheme"
                [size]="avatarSize"
                [componentClass]="last ? '' : 'mr-1'"
            >
            </ruum-profile-member>

            <ruum-profile-role
                *ngIf="isRole"
                [name]="assignee.name"
                [size]="avatarSize"
                [componentClass]="last ? '' : 'mr-1'"
            >
            </ruum-profile-role>
        </ng-container>

        <ruum-profile-others-counter
            *ngIf="assignees.length > maxShownAssignees"
            [quantity]="assignees.length - maxShownAssignees"
            [nameList]="getOtherAssignees(assignees, maxShownAssignees)"
            [size]="avatarSize"
        ></ruum-profile-others-counter>

        <ng-container *ngIf="showFullNameOfFirstAssignee && assignees.length === 1">
            <span
                *ngFor="let assignee of assignees | slice: 0:1"
                class="text-dark text-small text-nowrap pl-1 text-truncate"
                >{{ assignee.name }}
            </span>
        </ng-container>
    `,
})
export class AssignedItemListComponent {
    @HostBinding('class') hostClassList = 'd-flex align-items-center pr-1';

    @Input() assignees: AssignedItem[] = [];
    @Input() maxShownAssignees = 5;
    @Input() showFullNameOfFirstAssignee = true;
    @Input() avatarSize = 'sm';
    @Input() rounded = true;
    @Input() theme;
    @Input() isRole: boolean;

    getOtherAssignees(allAssignees: AssignedItem[], maxShownAssignees: number) {
        return allAssignees.slice(maxShownAssignees, allAssignees.length).map((el) => el.name);
    }
}

/** It can be a user or a functional role. */
export interface AssignedItem {
    id: string;
    initials: string;
    name: string;
    color: string;
}
