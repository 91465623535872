const VALID_EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const CONTAINS_LOWERCASE = /^(?=.*[a-z]).+$/;
const CONTAINS_UPPERCASE = /^(?=.*[A-Z]).+$/;
const CONTAINS_NUMBER = /^(?=.*[0-9]).+$/;
const CONTAINS_TWO_NUMBERS = /^(?=.*\d.*\d.*).+$/;
const MIN_8_CHARACTERS = /^(?=.{8,}).+$/;

export function isValidEmail(email: string): boolean {
    return VALID_EMAIL_REGEX.test(email);
}
export function containsLowercase(password: string): boolean {
    return CONTAINS_LOWERCASE.test(password);
}
export function containsUppercase(password: string): boolean {
    return CONTAINS_UPPERCASE.test(password);
}
export function containsNumber(password: string): boolean {
    return CONTAINS_NUMBER.test(password);
}
export function containsTwoNumbers(password: string): boolean {
    return CONTAINS_TWO_NUMBERS.test(password);
}
export function containsMin8Chars(password: string): boolean {
    return MIN_8_CHARACTERS.test(password);
}
