import { RuumAction } from '@ruum/ruum-reducers';
import {
    SavedVieCloneViewAction,
    SavedViewAddNewViewAction,
    SavedViewSelectAction,
    SavedViewUnselectAction,
    SavedViewUpdateColumnsAction,
    SavedViewUpdateFiltersAction,
    SAVED_VIEW_ADD_NEW_VIEW,
    SAVED_VIEW_CLONE_VIEW,
    SAVED_VIEW_SELECT,
    SAVED_VIEW_UNSELECT,
    SAVED_VIEW_UPDATE_COLUMNS,
    SAVED_VIEW_UPDATE_FILTERS,
} from './saved-views.action';
import { SavedViewValue } from './saved-views.model';

export interface SavedViewsState {
    entities: { [key: string]: SavedViewValue };
    selectedSavedViewId: string;
}

/** reducer */
export function SavedViewsReducer(
    currentState: SavedViewsState = getInitialState(),
    action: RuumAction,
): SavedViewsState {
    switch (action.type) {
        case SAVED_VIEW_SELECT:
            return selectSavedView(currentState, action.payload);
        case SAVED_VIEW_UNSELECT:
            return unselectSavedView(currentState, action.payload);
        case SAVED_VIEW_UPDATE_COLUMNS:
            return updateColumns(currentState, action.payload);
        case SAVED_VIEW_UPDATE_FILTERS:
            return updateFilters(currentState, action.payload);
        case SAVED_VIEW_ADD_NEW_VIEW:
            return addNewView(currentState, action.payload);
        case SAVED_VIEW_CLONE_VIEW:
            return cloneSavedView(currentState, action.payload);
        default:
            return currentState;
    }
}

/** Initial State */
const standardViews: { [key: string]: SavedViewValue } = {
    saved_view_home_ruums: {
        id: 'saved_view_home_ruums',
        name: 'Home Ruums',
        viewType: 'homeLobbyList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
            {
                fieldId: 'standard_field_changed_at',
            },
            {
                fieldId: 'standard_field_created_by',
            },
            {
                fieldId: 'standard_field_tags',
            },
            {
                fieldId: 'standard_field_group',
            },
        ],
    },
    saved_view_home_tasks: {
        id: 'saved_view_home_tasks',
        name: 'Home Tasks',
        viewType: 'homeTaskList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_task_list_status',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_task_list_name',
            },
            {
                fieldId: 'standard_field_task_list_subtasks',
            },
            {
                fieldId: 'standard_field_group',
            },
            {
                fieldId: 'standard_field_project',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_priority',
            },
            {
                fieldId: 'standard_field_startdate',
            },
            {
                fieldId: 'standard_field_duedate',
            },
            {
                fieldId: 'standard_field_assignees',
            },
            {
                fieldId: 'standard_field_roles',
            },
        ],
    },
    saved_view_home_approvals: {
        id: 'saved_view_home_approvals',
        name: 'Home Approvals',
        viewType: 'homeApprovalList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_approval_list_status',
            },
            {
                fieldId: 'standard_field_approval_list_approver',
            },
            {
                fieldId: 'standard_field_approval_list_actions',
            },
            {
                fieldId: 'standard_field_project',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_changed_at',
            },
            {
                fieldId: 'standard_field_requested_by',
            },
            {
                fieldId: 'standard_field_requested_on',
            },
            {
                fieldId: 'standard_field_workspace',
            },
        ],
    },
    saved_view_group_approvals: {
        id: 'saved_view_group_approvals',
        name: 'Group Approvals',
        viewType: 'groupApprovalList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_approval_list_status',
            },
            {
                fieldId: 'standard_field_approval_list_approver',
            },
            {
                fieldId: 'standard_field_approval_list_actions',
            },
            {
                fieldId: 'standard_field_project',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_changed_at',
            },
            {
                fieldId: 'standard_field_requested_by',
            },
            {
                fieldId: 'standard_field_requested_on',
            },
            {
                fieldId: 'standard_field_workspace',
            },
        ],
    },
    saved_view_workspace_approvals: {
        id: 'saved_view_workspace_approvals',
        name: 'Workspace Approvals',
        viewType: 'workspaceApprovalList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_approval_list_status',
            },
            {
                fieldId: 'standard_field_approval_list_approver',
            },
            {
                fieldId: 'standard_field_approval_list_actions',
            },
            {
                fieldId: 'standard_field_project',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_changed_at',
            },
            {
                fieldId: 'standard_field_requested_by',
            },
            {
                fieldId: 'standard_field_requested_on',
            },
        ],
    },
    saved_view_group_ruums: {
        id: 'saved_view_group_ruums',
        name: 'Group Ruums',
        viewType: 'groupLobbyList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
            {
                fieldId: 'standard_field_changed_at',
            },
            {
                fieldId: 'standard_field_created_by',
            },
            {
                fieldId: 'standard_field_tags',
            },
        ],
    },
    saved_view_group_tasks: {
        id: 'saved_view_group_tasks',
        name: 'Group Tasks',
        viewType: 'groupTaskList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_task_list_status',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_task_list_name',
            },
            {
                fieldId: 'standard_field_task_list_subtasks',
            },
            {
                fieldId: 'standard_field_project',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_priority',
            },
            {
                fieldId: 'standard_field_startdate',
            },
            {
                fieldId: 'standard_field_duedate',
            },
            {
                fieldId: 'standard_field_assignees',
            },
            {
                fieldId: 'standard_field_roles',
            },
        ],
    },
    saved_view_workspace_ruums: {
        id: 'saved_view_workspace_ruums',
        name: 'Workspace Ruums',
        viewType: 'workspaceLobbyList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
            {
                fieldId: 'standard_field_changed_at',
            },
            {
                fieldId: 'standard_field_created_by',
            },
            {
                fieldId: 'standard_field_tags',
            },
            {
                fieldId: 'standard_field_group',
            },
        ],
    },
    saved_view_workspace_tasks: {
        id: 'saved_view_workspace_tasks',
        name: 'Workspace Tasks',
        viewType: 'workspaceTaskList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_task_list_status',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_task_list_name',
            },
            {
                fieldId: 'standard_field_task_list_subtasks',
            },
            {
                fieldId: 'standard_field_group',
            },
            {
                fieldId: 'standard_field_project',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_priority',
            },
            {
                fieldId: 'standard_field_startdate',
            },
            {
                fieldId: 'standard_field_duedate',
            },
            {
                fieldId: 'standard_field_assignees',
            },
            {
                fieldId: 'standard_field_roles',
            },
        ],
    },
    saved_view_project_canvas: {
        id: 'saved_view_project_canvas',
        name: 'Project Canvas',
        viewType: 'projectCanvas',
        filterValues: [],
        columnValues: [],
    },
    saved_view_project_tasks: {
        id: 'saved_view_project_tasks',
        name: 'Project Tasks',
        viewType: 'projectTaskList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_task_list_status',
            },
            {
                fieldId: 'standard_field_comments',
            },
            {
                fieldId: 'standard_field_task_list_name',
            },
            {
                fieldId: 'standard_field_task_list_subtasks',
            },
            {
                fieldId: 'standard_field_section',
            },
            {
                fieldId: 'standard_field_priority',
            },
            {
                fieldId: 'standard_field_startdate',
            },
            {
                fieldId: 'standard_field_duedate',
            },
            {
                fieldId: 'standard_field_assignees',
            },
            {
                fieldId: 'standard_field_roles',
            },
        ],
    },
    saved_view_project_kanban: {
        id: 'saved_view_project_kanban',
        name: 'Project Kanban',
        viewType: 'projectKanban',
        filterValues: [],
        columnValues: [],
    },
};

const savedViews: { [key: string]: SavedViewValue } = {
    saved_view_1111: {
        id: 'saved_view_1111',
        name: 'Home Ruums',
        viewType: 'homeLobbyList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
        ],
    },
    saved_view_2222: {
        id: 'saved_view_2222',
        name: 'Home Ruums',
        viewType: 'homeLobbyList',
        filterValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
                selectedLogic: 'IS',
                selectedOptions: ['On Track'],
            },
        ],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
        ],
    },
    saved_view_3333: {
        id: 'saved_view_3333',
        name: 'Home Ruums',
        viewType: 'homeLobbyList',
        filterValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
                selectedLogic: 'IS',
                selectedOptions: ['Completed'],
            },
        ],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
        ],
    },
    saved_view_4444: {
        id: 'saved_view_4444',
        name: 'SV with CF columns',
        viewType: 'homeLobbyList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
            {
                fieldId: 'standard_field_created_by',
            },
            {
                fieldId: 'standard_field_tags',
            },
            {
                fieldId: 'custom_field_1589748742490_5pn73rue',
            },
            {
                fieldId: 'custom_field_1589748755933_yn1uawg5',
            },
            {
                fieldId: 'custom_field_1589748769442_tl8pwkfg',
            },
            {
                fieldId: 'custom_field_1589748798295_c9i81vg2',
            },
            {
                fieldId: 'custom_field_1589748812088_t3847uta',
            },
            {
                fieldId: 'custom_field_1589748829376_w6nry0bl',
            },
            {
                fieldId: 'custom_field_1589748845901_e7iwkm20',
            },
            {
                fieldId: 'custom_field_1589748894470_a2rsr8th',
            },
        ],
    },
    saved_view_5555: {
        id: 'saved_view_5555',
        name: 'PO Collection Flow',
        viewType: 'homeLobbyList',
        filterValues: [],
        columnValues: [
            {
                fieldId: 'standard_field_lobby_list_status',
            },
            {
                fieldId: 'standard_field_favorite',
            },
            {
                fieldId: 'standard_field_lobby_list_name',
            },
            {
                fieldId: 'custom_field_1589396813757_6sw8xmya',
            },
            {
                fieldId: 'custom_field_1589396828338_aodadh78',
            },
            {
                fieldId: 'custom_field_1589396844626_34zuvymm',
            },
            {
                fieldId: 'custom_field_1589396861670_qiui7akk',
            },
        ],
    },
};

function getInitialState(): SavedViewsState {
    return {
        entities: {
            ...standardViews,
            ...savedViews,
        },
        selectedSavedViewId: null,
    };
}

/** reducer pure functions */
function selectSavedView(currentState: SavedViewsState, payload: SavedViewSelectAction['payload']): SavedViewsState {
    return {
        ...currentState,
        selectedSavedViewId: payload.selectedSavedViewId,
    };
}

function unselectSavedView(
    currentState: SavedViewsState,
    payload: SavedViewUnselectAction['payload'],
): SavedViewsState {
    return {
        ...currentState,
        selectedSavedViewId: payload.selectedSavedViewId,
    };
}

function updateFilters(
    currentState: SavedViewsState,
    payload: SavedViewUpdateFiltersAction['payload'],
): SavedViewsState {
    return {
        ...currentState,
        entities: {
            ...currentState.entities,
            [payload.id]: {
                ...currentState.entities[payload.id],
                filterValues: [...payload.filters],
            },
        },
    };
}

function addNewView(currentState: SavedViewsState, payload: SavedViewAddNewViewAction['payload']): SavedViewsState {
    let savedView = currentState.entities[payload.id];
    savedView = savedView || payload;
    return {
        ...currentState,
        entities: {
            ...currentState.entities,
            [payload.id]: savedView,
        },
    };
}

function cloneSavedView(currentState: SavedViewsState, payload: SavedVieCloneViewAction['payload']): SavedViewsState {
    if (!!currentState.entities[payload.id]) {
        return { ...currentState };
    } else {
        const newId = `${payload.id}`;
        const newSavedView = {
            ...currentState.entities[payload.templateId],
            id: newId,
        };
        return {
            ...currentState,
            entities: {
                ...currentState.entities,
                [newId]: newSavedView,
            },
        };
    }
}

function updateColumns(
    currentState: SavedViewsState,
    payload: SavedViewUpdateColumnsAction['payload'],
): SavedViewsState {
    const savedView = { ...currentState.entities[payload.id], columnValues: [...payload.columns] };
    return {
        ...currentState,
        entities: {
            ...currentState.entities,
            [payload.id]: savedView,
        },
    };
}
