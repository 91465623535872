import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { OrderedListParams } from '../../../common/connectors/paginatedList.model';

type Direction = 'desc' | 'asc';
@Component({
    selector: 'sortable-column',
    template: `
        <button
            class="btn btn-xs px-0"
            [class.btn-link-light]="!sorting"
            [class.btn-link-secondary]="sorting"
            (mouseenter)="hovering = true"
            (mouseleave)="hovering = false"
            (click)="onSortClick()"
            [attr.aria-label]="name"
        >
            <h5>{{ label || name }}</h5>
            <i
                class="icon btn-icon-append"
                [class.icon-sort-asc]="hasArrowAsc()"
                [class.icon-sort-des]="hasArrowDesc()"
            >
            </i>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableColumnComponent {
    @HostBinding('class') hostClassList = 'd-flex';
    /** if the list is being sorted by this column */
    @Input() sorting = false;
    @Input() name: string;
    @Input() label: string;
    @Input()
    set direction(value: Direction) {
        if (value && value !== this.direction) {
            this._direction = value;
        }
    }
    get direction(): Direction {
        return this._direction;
    }
    @Output() sort = new EventEmitter<OrderedListParams<string>>();

    hovering = false;
    private _direction: Direction = 'asc';

    onSortClick() {
        let direction: Direction = 'asc';
        if (this.sorting) {
            direction = this.direction === 'asc' ? 'desc' : 'asc';
        }
        const by = this.name;
        this.sort.emit({ by, direction });
    }

    hasArrowAsc() {
        return this.isSortingInDirection('asc') || this.isHoveringInDirection('asc');
    }

    hasArrowDesc() {
        return this.isSortingInDirection('desc') || this.isHoveringInDirection('desc');
    }

    isSortingInDirection(direction: Direction): boolean {
        return this.sorting && this.direction === direction;
    }

    isHoveringInDirection(direction: Direction): boolean {
        return !this.sorting && this.direction === direction && this.hovering;
    }
}
