import { Component, Input, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { AppStoreWrapper } from './../appStoreWrapper.service';

@Component({
    selector: 'busy-state',
    template: `
        <div *ngIf="isBusyObs | async" class="busy-container" [class.spanPage]="spanPage">
            <div class="busy">
                <img alt="Loading or Busy State" [attr.width]="spinnerSize" [attr.src]="imageSrc" />
            </div>
        </div>
    `,
    styles: [
        `
            .busy-container {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1005;
                height: 100%;
                width: 100%;
                text-align: center;
                display: table;
            }
            .busy-container.spanpage {
                position: absolute;
                background: rgba(255, 255, 255, 0.5);
            }
            .busy {
                vertical-align: middle;
                display: table-cell;
            }
        `,
    ],
})
export class BusyStateComponent implements OnInit {
    @Input() spanPage = true;
    @Input() componentId: string;
    @Input() spinnerSize = 60;
    @Input() white = false;

    isBusyObs: Observable<boolean>;

    constructor(private appStoreWrapper: AppStoreWrapper) {}

    ngOnInit() {
        this.isBusyObs = this.appStoreWrapper.busyState().pipe(
            map((busyState) => busyState[this.componentId || 'application']),
            /* waits 300ms before showing the spinner and immediately stop showing it.*/
            debounce((busyState) => (busyState ? timer(300) : timer(1))),
        );
    }

    get imageSrc(): string {
        return this.white ? 'assets/img/spinner-white.svg' : 'assets/img/spinner.svg';
    }
}
