import { ElementRef, RendererFactory2 } from '@angular/core';
import { PaywallRejectStrategy, PaywallRejectStrategyContext } from '../paywall-reject-strategy';

export class DisableRejectStrategy implements PaywallRejectStrategy {
    private static readonly DISABLED_CSS_CLASS = 'disabled';
    private static readonly DISABLED_ATTRIBUTE_KEY = 'disabled';
    private static readonly DISABLED_ATTRIBUTE_VALUE = 'disabled';

    readonly id = 'disable';

    constructor(private rendererFactory: RendererFactory2) {}

    onRejectInitial({ elementRef }: PaywallRejectStrategyContext) {
        // TODO: handle case where host element already has the disabled attribute or class
        this.setDisabledAttribute(elementRef, true);
        this.setDisabledClass(elementRef, true);
    }

    onRejectHostDestroy({ elementRef }: PaywallRejectStrategyContext) {
        // TODO: handle case where host element already has the disabled attribute or class
        this.setDisabledAttribute(elementRef, false);
        this.setDisabledClass(elementRef, false);
    }

    onRejectTrigger(_: PaywallRejectStrategyContext, event?: Event) {
        if (event) {
            // this doesn't work for host elements with a (click)="fn()", but it's not required in this case.
            event.stopPropagation();
        }
    }

    private setDisabledClass(elementRef: ElementRef<any>, value: boolean): void {
        const renderer = this.rendererFactory.createRenderer(elementRef.nativeElement, null);
        if (value) {
            renderer.addClass(elementRef.nativeElement, DisableRejectStrategy.DISABLED_CSS_CLASS);
        } else {
            renderer.removeClass(elementRef.nativeElement, DisableRejectStrategy.DISABLED_CSS_CLASS);
        }
    }

    private setDisabledAttribute(elementRef: ElementRef<any>, value: boolean): void {
        const renderer = this.rendererFactory.createRenderer(elementRef.nativeElement, null);
        if (value) {
            renderer.setAttribute(
                elementRef.nativeElement,
                DisableRejectStrategy.DISABLED_ATTRIBUTE_KEY,
                DisableRejectStrategy.DISABLED_ATTRIBUTE_VALUE,
            );
        } else {
            renderer.removeAttribute(elementRef.nativeElement, DisableRejectStrategy.DISABLED_ATTRIBUTE_KEY);
        }
    }
}
