import {
    AddExternalSystemAction,
    DeleteExternalSystemAction,
    EntityAction,
    RuumAction,
    UpdateExternalSystemAction,
} from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../paginatedList.model';
import { ExternalSystemInfo } from './external-system-list.model';

export const EXTERNAL_SYSTEM_LIST_ACTION_TYPES = {
    EXTERNAL_SYSTEM_LOADED: 'EXTERNAL_SYSTEM_LOADED',
    ADD_EXTERNAL_SYSTEM_TO_LIST: 'ADD_EXTERNAL_SYSTEM_TO_LIST',
    UPDATE_EXTERNAL_SYSTEM_TO_LIST: 'UPDATE_EXTERNAL_SYSTEM_TO_LIST',
    DELETE_EXTERNAL_SYSTEM_FROM_LIST: 'DELETE_EXTERNAL_SYSTEM_FROM_LIST',
};

export type ExternalSystemLoadedAction = EntityAction<
    'EXTERNAL_SYSTEM_LOADED',
    {
        page: PaginatedList<ExternalSystemInfo>;
    }
>;

export function ExternalSystemListReducer(
    currentState: PaginatedList<ExternalSystemInfo> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<ExternalSystemInfo> {
    switch (action.type) {
        case EXTERNAL_SYSTEM_LIST_ACTION_TYPES.EXTERNAL_SYSTEM_LOADED:
            return listLoaded(currentState, action.payload.page);
        case EXTERNAL_SYSTEM_LIST_ACTION_TYPES.ADD_EXTERNAL_SYSTEM_TO_LIST:
            return addExternalSystem(currentState, action.payload);
        case EXTERNAL_SYSTEM_LIST_ACTION_TYPES.UPDATE_EXTERNAL_SYSTEM_TO_LIST:
            return updateExternalSystem(currentState, action.payload);
        case EXTERNAL_SYSTEM_LIST_ACTION_TYPES.DELETE_EXTERNAL_SYSTEM_FROM_LIST:
            return deleteExternalSystem(currentState, action.payload);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<ExternalSystemInfo>,
    data: PaginatedList<ExternalSystemInfo>,
): PaginatedList<ExternalSystemInfo> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}

function addExternalSystem(
    currentState: PaginatedList<ExternalSystemInfo>,
    payload: AddExternalSystemAction['payload'],
): PaginatedList<ExternalSystemInfo> {
    const row: ExternalSystemInfo = {
        id: payload.systemId,
        title: payload.title,
        hostname: payload.hostname,
        port: payload.port,
        outboundAuthenticationType: payload.outboundAuthentication.type,
    };
    return {
        ...currentState,
        rows: [...currentState.rows, row],
    };
}

function updateExternalSystem(
    currentState: PaginatedList<ExternalSystemInfo>,
    payload: UpdateExternalSystemAction['payload'],
): PaginatedList<ExternalSystemInfo> {
    return {
        ...currentState,
        rows: currentState.rows.map((el) => {
            if (el.id === payload.systemId) {
                return {
                    ...el,
                    ...payload.editFields,
                };
            } else {
                return el;
            }
        }),
    };
}

function deleteExternalSystem(
    currentState: PaginatedList<ExternalSystemInfo>,
    payload: DeleteExternalSystemAction['payload'],
): PaginatedList<ExternalSystemInfo> {
    return {
        ...currentState,
        rows: currentState.rows.filter((el) => el.id !== payload.systemId),
    };
}
