import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { RuumDropdownDesktopComponent } from '../../ui-components/dropdown/dropdown-desktop.component';
import { RuumDropdownMobileComponent } from '../../ui-components/dropdown/dropdown-mobile.component';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-template-cover-icon-picker',
    template: `
        <ruum-dropdown-wrapper>
            <ruum-dropdown-desktop #desktopDropdown [placement]="placement" [isReadOnly]="disabled">
                <div class="d-flex" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                    <ng-container *ngTemplateOutlet="iconPickerButton"></ng-container>
                </div>
                <div
                    class="d-flex flex-wrap flex-column p-2"
                    [style.width.px]="144"
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                >
                    <div class="d-flex flex-wrap">
                        <button
                            *ngFor="let iconClass of icons"
                            class="btn btn-xs btn-icon m-1"
                            [class.btn-outline-secondary]="icon !== iconClass"
                            [class.btn-outline-primary]="icon === iconClass"
                            (click)="onSelect(iconClass, desktopDropdown)"
                        >
                            <i class="icon" [ngClass]="iconClass"></i>
                        </button>
                    </div>
                </div>
            </ruum-dropdown-desktop>
            <ruum-dropdown-mobile #mobileDropdown [isReadOnly]="disabled">
                <div class="d-flex" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                    <ng-container *ngTemplateOutlet="iconPickerButton"></ng-container>
                </div>
                <div
                    class="d-flex flex-fill flex-column p-1"
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                >
                    <div
                        *ngFor="let iconClass of icons"
                        class="list-group-item list-group-item-action d-flex align-items-center rounded cursor-pointer px-2 py-0 hov hov-bg-light"
                        (click)="onSelect(iconClass, mobileDropdown)"
                        (keydown.space)="onSelect(iconClass, mobileDropdown)"
                        (keydown.enter)="onSelect(iconClass, mobileDropdown)"
                    >
                        <button class="btn btn-sm btn-icon btn-link-secondary m-1">
                            <i class="icon" [ngClass]="iconClass"></i>
                        </button>
                        <div class="flex-fill pl-1 text-truncate text-capitalize">
                            {{ iconClass | slice: 5:iconClass.length }}
                        </div>
                        <div *ngIf="iconClass === icon" class="btn btn-sm btn-icon btn-link-secondary">
                            <i class="icon icon-done"></i>
                        </div>
                    </div>
                </div>
            </ruum-dropdown-mobile>
        </ruum-dropdown-wrapper>

        <ng-template #iconPickerButton>
            <button class="btn btn-sm btn-outline-secondary pr-1" [style.padding-left.px]="5">
                <span class="mr-1" [style.width.px]="20" [style.height.px]="20">
                    <i class="icon" [ngClass]="icon"></i
                ></span>
                <i class="icon icon-cevron-down"></i>
            </button>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateCoverIconPickerComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            componentHelper.getCursorClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @Input() icon: string;
    @Input() disabled: boolean;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter<string>();

    placement = ['bottom-right', 'top-right'];

    icons = [
        'icon-template',
        'icon-activity',
        'icon-canvas',
        'icon-hourglass',
        'icon-desktopn-pc',
        'icon-data',
        'icon-notification',
        'icon-date',
        'icon-send',
        'icon-kanban',
        'icon-time',
        'icon-alarm',
        'icon-megaphone',
        'icon-team',
        'icon-tasks',
        'icon-bulb',
    ];

    constructor() {}

    ngOnInit() {}

    onSelect(icon: string, dropdown: RuumDropdownDesktopComponent | RuumDropdownMobileComponent) {
        dropdown.close();
        this.icon = icon;
        const iconShortName = icon.replace('icon-', '');
        this.changed.emit(iconShortName);
    }
}
