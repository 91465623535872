import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gdpr-checkboxes',
    template: `
        <div class="form-group form-check gdpr-field mt-4 mx-0">
            <input
                class="form-check-input"
                type="checkbox"
                id="marketing-mails"
                [checked]="marketingEmailsAllowed"
                (click)="marketingEmailsAllowedChange.emit($event.target.checked)"
            />
            <label class="form-check-label font-weight-bold ml-1" for="marketing-mails"
                >Feature Updates and Productivity Tips</label
            >
            <small class="d-flex text-secondary ml-1"
                >Allow us to update you with new feature announcements and productivity tips from time to time.</small
            >
        </div>
    `,
    styles: [
        `
            .gdpr-field {
                max-width: 417px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GDPRCheckBoxesComponent {
    @Input() marketingEmailsAllowed: boolean;
    @Output() marketingEmailsAllowedChange = new EventEmitter<boolean>();
}
