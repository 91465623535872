import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ODataEnterpriseTechnicalUser } from '../../common/odata/odata.model';
import { TechnicalUserListService } from './technical-user-list.service';

@Injectable({
    providedIn: 'root',
})
export class GetTechnicalUserByIdResolver implements Resolve<any> {
    constructor(private technicalUserListService: TechnicalUserListService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ODataEnterpriseTechnicalUser> {
        this.technicalUserListService.loadList();

        const enterpriseId = route.paramMap.get('enterpriseId');
        const technicalUserId = route.paramMap.get('technicalUserId');

        return this.technicalUserListService.getByKeys(enterpriseId, technicalUserId);
    }
}
