import { Injectable } from '@angular/core';
import { TableAPIType, TableDefinition } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';

@Injectable({ providedIn: 'root' })
export class TableDefinitionStore extends StoreLoader<TableDefinition> {
    private map = new BehaviorSubject<TableDefinitionMap>({});

    constructor(private readModelBackendConnector: ReadModelBackendConnector) {
        super();
    }

    protected getData(ids: string[]): Observable<TableDefinition[]> {
        return this.readModelBackendConnector
            .getTableDefinitions({ page: 1, pageSize: ids.length }, { ids })
            .pipe(map((page) => page.rows));
    }

    protected storeData(list: TableDefinition[]): void {
        const change: TableDefinitionMap = {};
        list.forEach((table) => {
            change[table.id] = table;
        });
        this.map.next({
            ...this.map.value,
            ...change,
        });
    }

    protected failedToLoad(id: string): void {
        this.map.next({
            ...this.map.value,
            [id]: {
                id,
                columns: [],
                name: 'Failed to Load',
                type: 'external_table',
                // workspaceId: undefined,
                apiType: TableAPIType.ODATA_V4,
                systemId: undefined,
                path: '',
            },
        });
    }

    protected getStoreData(): Observable<{ [id: string]: TableDefinition }> {
        return this.map.asObservable();
    }
}

export interface TableDefinitionMap {
    [tableId: string]: TableDefinition;
}
