import { ElementRef, InjectionToken } from '@angular/core';
import { PaywallPrivilege } from './paywall-privilege';

export const PAYWALL_REJECT_STRATEGY = new InjectionToken<string>('ruum-paywallRejectStrategy');

export interface PaywallRejectStrategyData {
    readonly [key: string]: any;
}

export interface PaywallRejectStrategyContext {
    readonly elementRef?: ElementRef;
    readonly privilege: PaywallPrivilege;
    readonly rejectStrategyData?: PaywallRejectStrategyData;
}

export type PaywallRejectStrategyId = string;

export interface PaywallRejectStrategy {
    readonly id: PaywallRejectStrategyId;

    onRejectInitial(context: PaywallRejectStrategyContext);
    onRejectTrigger(context: PaywallRejectStrategyContext, event?: Event);
    onRejectHostDestroy(context: PaywallRejectStrategyContext);
}
