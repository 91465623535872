import { Injectable } from '@angular/core';
import { convertDateToDay, Day, isDayBefore, TaskStatus } from '@ruum/ruum-reducers';
import { RuumDropdownItem, RuumDropdownList } from '../../ui-components/dropdown/dropdown.model';

@Injectable({ providedIn: 'root' })
export class TaskStatusService {
    constructor() {}

    getStatusById(id: TaskStatus): RuumDropdownItem {
        switch (id) {
            case 'OPEN':
                return { id: 'OPEN', name: 'Open', icon: 'icon-task-open', theme: 'secondary' };
            case 'WORKING':
                return { id: 'WORKING', name: 'Working on it', icon: 'icon-task-progress', theme: 'info' };
            case 'AT_RISK':
                return { id: 'AT_RISK', name: 'At Risk', icon: 'icon-task-blocked-filled', theme: 'warning' };
            case 'BLOCKED':
                return { id: 'BLOCKED', name: 'Blocked', icon: 'icon-task-blocked-filled', theme: 'danger' };
            case 'DONE':
                return { id: 'DONE', name: 'Done', icon: 'icon-task-completed-filled', theme: 'success' };
            default:
                return { id: 'OPEN', name: 'Open', icon: 'icon-task-open', theme: 'secondary' };
        }
    }

    getStatusList(): RuumDropdownList {
        const ids: TaskStatus[] = ['OPEN', 'WORKING', 'AT_RISK', 'BLOCKED', 'DONE'];
        const list = ids.map((id) => this.getStatusById(id));
        return list;
    }

    getMilestoneStatusById(id: TaskStatus): RuumDropdownItem {
        switch (id) {
            case 'FUTURE':
                return { id: 'FUTURE', name: 'Future', icon: 'icon-milestone', theme: 'success' };
            case 'REACHED':
                return { id: 'REACHED', name: 'Reached', icon: 'icon-milestone-filled', theme: 'success' };
            case 'MISSED':
                return { id: 'MISSED', name: 'Missed', icon: 'icon-milestone', theme: 'danger' };
            default:
                return { id: 'FUTURE', name: 'Future', icon: 'icon-milestone', theme: 'success' };
        }
    }

    getMilestoneStatusList(taskDueDate: Day): RuumDropdownList {
        const statuses: TaskStatus[] = [];
        if (taskDueDate) {
            const today: Day = convertDateToDay(new Date());
            if (isDayBefore(taskDueDate, today)) {
                statuses.push('REACHED');
                statuses.push('MISSED');
            } else {
                statuses.push('FUTURE');
                statuses.push('REACHED');
            }
        } else {
            statuses.push('FUTURE');
            statuses.push('REACHED');
            statuses.push('MISSED');
        }

        return statuses.map((status) => this.getMilestoneStatusById(status));
    }
}
