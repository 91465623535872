import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownModule } from '../../ui-components/dropdown/dropdown.module';
import { TaskPriorityMenuComponent } from './task-priority-menu.component';
import { TaskPriorityTagComponent } from './task-priority-tag.component';
import { TaskPriorityComponent } from './task-priority.component';

@NgModule({
    declarations: [TaskPriorityComponent, TaskPriorityMenuComponent, TaskPriorityTagComponent],
    imports: [CommonModule, DropdownModule],
    exports: [TaskPriorityComponent, TaskPriorityMenuComponent, TaskPriorityTagComponent],
})
export class TaskPriorityModule {}
