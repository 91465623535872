import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentableType, CommentActionPayload, RuumActionTypes, RuumComment } from '@ruum/ruum-reducers';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { ProfileListItem } from '../../connectors/profile/profile.model';
import { ProjectServiceBackendConnector } from '../../connectors/projectServiceConnector.service';

@Component({
    selector: 'comment-list',
    template: `
        <div class="d-flex flex-fill">
            <div class="d-flex flex-column flex-fill align-items-center p-7" *ngIf="comments?.length === 0">
                <ruum-illustration-comments [width]="140" [componentClass]="'mb-6'"></ruum-illustration-comments>
                <div class="text-small text-center font-weight-bold mb-3">No Comments yet</div>
                <div class="text-small text-center text-secondary mb-3" [style.line-height.px]="18">
                    @mentions trigger an email notification
                </div>
            </div>

            <div class="d-flex flex-column flex-fill" *ngIf="comments?.length !== 0" #commentList>
                <ruum-comment
                    class="mb-1 flex-column"
                    *ngFor="let comment of comments"
                    [comment]="comment"
                    [isReadOnly]="isReadOnly"
                    id="comment-{{ comment.id }}"
                    (edit)="edit.emit({ id: comment.id, text: $event })"
                    (delete)="delete.emit(comment.id)"
                >
                </ruum-comment>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                margin-bottom: 80px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentListComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column';

    @ViewChild('commentList', { static: false }) private commentList: ElementRef;

    @Input() commentableId: string;
    @Input() comments: RuumComment[] = [];
    @Input() isReadOnly = false;
    @Input() projectId: string;
    @Input() commentableType: CommentableType;

    @Output() edit = new EventEmitter<{ id: string; text: string }>();
    @Output() delete = new EventEmitter<string>();

    currUser: string;
    userProfile$: Observable<ProfileListItem>;

    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private projectServiceConnector: ProjectServiceBackendConnector,
    ) {
        this.currUser = this.authService.getLoggedUser().id;
    }

    ngOnInit() {
        this.scrollBasedOnRoute();
    }

    ngOnChanges(change) {
        /**
         * - so that is scrolls down as the user inserts comments.
         * - handles pagination of comments -> should be improved https://dev.to/dhilipkmr/creating-infinite-scroll-with-15-elements-4dg4
         */
        if (change.comments && !change.comments.firstChange && !change.commentableId) {
            const { previousValue, currentValue } = change.comments;
            if (previousValue.length + 1 === currentValue.length) {
                setTimeout(() => this.scrollToTheBottomOfTheList(), 100);
            } else if (previousValue.length > 0) {
                setTimeout(() => this.scrollToCommentId(previousValue[0].id, false), 100);
            }

            if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
                this.recordCommentsWereSeen();
            }
        }
    }

    private recordCommentsWereSeen() {
        const payload: CommentActionPayload = {
            id: this.commentableId,
            onObjectId: this.commentableId,
            onObjectType: this.commentableType,
        };
        this.projectServiceConnector.addActionToRuum(this.projectId, RuumActionTypes.SHOW_COMMENTS_OF_OBJECT, payload);
    }
    /**
     * If the route ends with /comments it will scroll to the bottomm of the list.
     * If the route ends with /comments/:commentId it will scroll to that comment.
     */
    private scrollBasedOnRoute() {
        const s = combineLatest([this.route.url, this.route.params])
            .pipe(debounceTime(10))
            .subscribe(([url, params]) => {
                const comments = url[url.length - 2];
                const commentId = url[url.length - 1];
                if (comments && comments.path === 'comments') {
                    setTimeout(() => this.scrollToCommentId(commentId.path, true), 500);
                } else if (commentId && commentId.path === 'comments') {
                    setTimeout(() => this.scrollToTheBottomOfTheList(), 500);
                }
            });

        this.subscriptions.push(s);
    }

    scrollToCommentId(commentId: string, blink: boolean) {
        const index = this.comments.findIndex((c) => c.id === commentId);
        if (index >= 0) {
            const comment = this.commentList.nativeElement.children[index];
            comment.scrollIntoView();
            if (blink) {
                this.blinkNode(comment);
            }
        } else {
            this.scrollToTheBottomOfTheList();
        }
    }

    private blinkNode(nodeDOM) {
        nodeDOM.classList.add('highlight-flashing');
        setTimeout(() => nodeDOM.classList.remove('highlight-flashing'), 800);
    }

    scrollToTheBottomOfTheList() {
        if (this.commentList && this.commentList.nativeElement) {
            const lastComment = this.commentList.nativeElement.children[
                this.commentList.nativeElement.children.length - 1
            ];
            if (lastComment) {
                lastComment.scrollIntoView();
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
