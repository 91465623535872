import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[ruumInputHighlight]',
})
export class InputHighlightDirective {
    constructor(private element: ElementRef) {}

    @HostListener('mouseenter', [])
    onMouseover() {
        this.element.nativeElement.parentElement.classList.add('input-group-hover');
    }

    @HostListener('mouseleave', [])
    onMouseleave() {
        this.element.nativeElement.parentElement.classList.remove('input-group-hover');
    }

    @HostListener('focus', [])
    onFocus() {
        this.element.nativeElement.parentElement.classList.add('input-group-focus');
    }

    @HostListener('blur', [])
    onBlur() {
        this.element.nativeElement.parentElement.classList.remove('input-group-focus');
    }
}
