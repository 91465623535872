import { Injectable } from '@angular/core';
import {
    CreateSystemConnectorAction,
    DeleteSystemConnectorAction,
    SystemConnector,
    UpdateSystemConnectorAction,
} from '@ruum/ruum-reducers';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { getRandomId } from '../../utils.service';
import { SelectedEnterpriseService } from '../enterprise/selected-enterprise.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { SYSTEM_CONNECTOR_LIST_ACTION_TYPES } from './system-connectors-list.reducer';
@Injectable({ providedIn: 'root' })
export class SystemConnectorsService {
    constructor(
        private selectedEnterpriseService: SelectedEnterpriseService,
        private serviceHelper: ServiceHelper,
        private alertService: RuumAlertService,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
    ) {}

    createSystemConnector(systemConnector: SystemConnector) {
        const id = getRandomId('system_connector_');

        const payload: CreateSystemConnectorAction['payload'] = {
            id,
            ...systemConnector,
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<CreateSystemConnectorAction>(enterpriseId, 'CREATE_SYSTEM_CONNECTOR', payload)
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    SYSTEM_CONNECTOR_LIST_ACTION_TYPES.ADD_SYSTEM_CONNECTOR_TO_LIST,
                    {
                        systemConnector: {
                            id,
                            ...payload,
                        },
                    },
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }

    updateSystemConnector(id: string, systemConnector: SystemConnector) {
        const payload: UpdateSystemConnectorAction['payload'] = {
            systemConnectorId: id,
            editFields: systemConnector,
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<UpdateSystemConnectorAction>(enterpriseId, 'UPDATE_SYSTEM_CONNECTOR', payload)
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    SYSTEM_CONNECTOR_LIST_ACTION_TYPES.UPDATE_SYSTEM_CONNECTOR_TO_LIST,
                    {
                        systemConnector: {
                            id,
                            ...systemConnector,
                        },
                    },
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }

    deleteSystemConnector(id: string) {
        const payload: DeleteSystemConnectorAction['payload'] = { systemConnectorId: id };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<DeleteSystemConnectorAction>(enterpriseId, 'DELETE_SYSTEM_CONNECTOR', payload)
            .then((data) => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    SYSTEM_CONNECTOR_LIST_ACTION_TYPES.DELETE_SYSTEM_CONNECTOR_FROM_LIST,
                    {
                        systemConnector: {
                            id,
                        },
                    },
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }
}
