import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';
import { isMobile } from '../../../common/utils.service';
import { IMyDate, IMyOptions, MyDatePickerComponent } from './my-date-picker';

@Component({
    selector: 'ruum-datepicker',
    template: `
        <my-date-picker
            #datepicker
            name="mydate"
            [disableSince]="disableSince"
            [disableUntil]="disableUntil"
            [options]="myDatePickerOptions"
            [(ngModel)]="model"
            [defaultMonth]="
                defaultDate
                    ? (defaultDate.month > 9 ? defaultDate.month : '0' + defaultDate.month) + '/' + defaultDate.year
                    : null
            "
            [placeholder]="placeholder"
            [selectionPrefix]="selectionPrefix"
            [showButton]="showButton"
            (dateChanged)="changeDate($event)"
            (resetDates)="resetDate($event)"
            (calendarToggle)="calendarToggle.emit($event)"
            [isStartDay]="isStartDay"
            [class.read-only]="isReadOnly"
            [class.overdue]="isOverdue"
        >
        </my-date-picker>
        <input *ngIf="isMobile()" type="date" [value]="getISODate()" (change)="updateByISODate($event)" />
    `,
    styles: [
        `
            :host {
                display: block;
                position: relative;
            }
            input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            .read-only {
                pointer-events: none;
                cursor: default;
            }
        `,
    ],
})
export class DatepickerComponent implements OnChanges {
    @Input() date: Day;
    @Input() defaultDate: Day;
    @Input() disableSince: IMyDate;
    @Input() disableUntil: IMyDate;
    @Input() fullDateFormat: boolean;
    @Input() customDateFormat?: string;
    @Input() showButton = true;
    @Input() isReadOnly = false;
    @Input() isStartDay: boolean;
    @Input() isOverdue = false;
    /**
     * Text which will appear when no date is selected.
     * e.g. Due Date
     */
    @Input() placeholder: string;
    /**
     * Text which will appear before the date.
     * e.g. Due
     */
    @Input() selectionPrefix: string;

    @Output() dateChanged = new EventEmitter<Day>();
    @Output() resetDates = new EventEmitter<boolean>();
    @Output() calendarToggle: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('datepicker', { static: true })
    datepicker: MyDatePickerComponent;

    myDatePickerOptions: IMyOptions = {
        showClearDateBtn: false,
        dateFormat: 'dd mmm',
    };
    model: any = { date: undefined };

    ngOnChanges() {
        this.setDate(this.date);
        if (this.customDateFormat) {
            this.myDatePickerOptions.dateFormat = this.customDateFormat;
        } else if (this.fullDateFormat) {
            this.myDatePickerOptions.dateFormat = 'WWW. dd mmm, yyyy'; // weird date format string handled by my-date-picker.
        }
    }

    openDatepicker() {
        this.datepicker.toggleDatepicker(null);
    }

    changeDate(event) {
        if (event.date.year === 0) {
            /** my date picker represent no date as {year: 0, month: 0, day: 0} */
            this.dateChanged.emit(undefined);
        } else {
            this.dateChanged.emit(event.date);
        }
    }
    resetDate(event) {
        this.resetDates.emit(!!event);
    }

    setDate(date: Day) {
        if (!date) {
            this.model = '';
        } else {
            this.model = { date };
        }
    }

    isMobile() {
        return isMobile();
    }

    getISODate(): string {
        if (this.date) {
            return new Date(this.date.year, this.date.month - 1, this.date.day).format('YYYY-MM-DD');
        } else {
            return '';
        }
    }

    updateByISODate(event: UIEvent): void {
        const value = (event.target as HTMLInputElement).value;
        if (value === '') {
            this.changeDate({ date: { year: 0, month: 0, day: 0 } });
        } else {
            const date = new Date(value);
            this.changeDate({
                date: {
                    year: date.year(),
                    month: date.month() + 1,
                    day: date.date(),
                },
            });
        }
    }
}
