import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SelectedProjectService } from '../../../../common/connectors/project/selectedProject.service';
import { SelectedProjectGroupService } from '../../../../common/connectors/projectGroup/selectedProjectGroup.service';
import { SavedViewFieldsService } from '../../../../common/connectors/savedView/fields/saved-view-fields.service';
import { SavedViewFiltersService } from '../../../../common/connectors/savedView/saved-view-filters.service';
import { SelectedWorkspaceService } from '../../../../common/connectors/workspace/selected-workspace.service';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';
import {
    SavedViewFieldDefinition,
    SavedViewFilterDefinition,
    SelectionAttr,
} from './../../../../common/connectors/savedView/saved-views.model';

@Component({
    selector: 'ruum-filter-field-value-select',
    template: `
        <ng-container *ngIf="selectionAttrs$ | async as selectionAttrs" [ngSwitch]="selectionAttrs.type">
            <div class="d-flex flex-fill w-100" *ngSwitchCase="'select'">
                <ruum-filter-field-value-list
                    [staticOptions]="selectionAttrs.optionsList"
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-filter-field-value-list>
            </div>

            <div class="d-flex flex-fill w-100" *ngSwitchCase="'datepicker'">
                <ruum-filter-field-value-datepicker
                    [selectedOptions]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-filter-field-value-datepicker>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'tag-select'">
                <ruum-filter-tag-select
                    [workspaceId]="workspaceId"
                    [groupId]="groupId"
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-filter-tag-select>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'workspace-select'">
                <ruum-workspace-select
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-workspace-select>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'group-select'">
                <ruum-group-select
                    [workspaceId]="workspaceId"
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-group-select>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'project-select'">
                <ruum-project-select
                    [workspaceId]="workspaceId"
                    [groupId]="groupId"
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-project-select>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'members-select'">
                <ruum-filter-members-select
                    [workspaceId]="workspaceId"
                    [groupId]="groupId"
                    [projectId]="projectId"
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-filter-members-select>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'roles-select'">
                <ruum-functional-role-select
                    [workspaceId]="workspaceId"
                    [projectId]="projectId"
                    [multiSelect]="multiSelect"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-functional-role-select>
            </div>

            <div class="d-flex flex-fill minw-0" *ngSwitchCase="'custom-field-list-select'">
                <ruum-filter-custom-field-list-select
                    [multiSelect]="multiSelect"
                    [selectedFieldId]="selectedFieldId"
                    [selectedOptionIds]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-filter-custom-field-list-select>
            </div>

            <div class="d-flex flex-fill" *ngSwitchCase="'search'">
                <ruum-filter-field-value-search
                    [selectedOptions]="selectedOptions"
                    (changed)="changed.emit($event)"
                ></ruum-filter-field-value-search>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldValueSelectComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill', this.componentClass]);
    }

    @Input() set selectedFieldId(value: string) {
        this.selectedFieldIdSource.next(value);
    }

    get selectedFieldId(): string {
        return this.selectedFieldIdSource.getValue();
    }

    @Input() set selectedLogicalOperatorId(value: string) {
        this.selectedOptionIdSource.next(value);
    }

    get selectedLogicalOperatorId(): string {
        return this.selectedOptionIdSource.getValue();
    }
    @Input() selectedOptions: string[] = [];
    @Input() multiSelect = false;

    @Input() placeholder = 'Select Field';
    @Input() showSearch = true;
    @Input() placement = ['bottom-left', 'top-left'];

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    selectionAttrs$: Observable<SelectionAttr>;

    get workspaceId(): string {
        return this.selectedWorkspaceService.getSelectedWorkspaceId();
    }

    get groupId(): string {
        return this.selectedProjectGroupService.getSelectedGroupId();
    }

    get projectId(): string {
        return this.selectedProjectService.getSelectedProjectId();
    }

    private selectedFieldIdSource = new BehaviorSubject('');
    private selectedOptionIdSource = new BehaviorSubject('');

    constructor(
        private savedViewFieldsService: SavedViewFieldsService,
        private savedViewFiltersService: SavedViewFiltersService,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private selectedProjectGroupService: SelectedProjectGroupService,
        private selectedProjectService: SelectedProjectService,
    ) {}

    ngOnInit() {
        this.selectionAttrs$ = combineLatest([this.selectedFieldIdSource.asObservable()]).pipe(
            switchMap(([selectedFieldId]) => {
                return this.savedViewFieldsService.getFieldById(selectedFieldId);
            }),
            filter((field) => !!field),
            switchMap((field: SavedViewFieldDefinition) => {
                return this.savedViewFiltersService.getFilterByTypeId(field.typeId);
            }),
            map((fld: SavedViewFilterDefinition) => {
                return fld.selectionAttrs;
            }),
        );
    }

    selectChange(event) {
        this.changed.emit(event);
    }
}
