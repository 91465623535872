import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { RuumDropdownPlacement } from './dropdown.model';
import { RuumDropdownService } from './dropdown.service';

@Component({
    selector: 'ruum-dropdown-desktop',
    template: `
        <div
            class="d-flex flex-fill minw-0 cursor-pointer"
            #popover="ngbPopover"
            [ngbPopover]="popoverContent"
            [popoverClass]="popoverClass"
            [placement]="placement"
            [autoClose]="autoClose"
            [container]="container"
            [triggers]="'manual'"
            [class.read-only]="isReadOnly"
            [class.cursor-not-allowed]="isReadOnly"
            [class.cursor-pointer]="!isReadOnly"
            (click)="toggleDropdownMenu($event)"
            (keydown.space)="toggleDropdownMenu($event)"
            (keydown.enter)="toggleDropdownMenu($event)"
        >
            <!-- dropdown button -->
            <ng-content select="[data-content='ruum-dropdown-button']"> </ng-content>
        </div>

        <ng-template #popoverContent>
            <!-- dropdown menu -->
            <ng-content select="[data-content='ruum-dropdown-menu']"> </ng-content>
        </ng-template>
    `,
    styles: [
        `
            .read-only {
                pointer-events: none;
                cursor: default;
            }
        `,
    ],
})
export class RuumDropdownDesktopComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';

    @Input()
    placement: RuumDropdownPlacement = ['auto'];

    @Input()
    popoverClass = '';

    @Input()
    autoClose: boolean | 'inside' | 'outside' = 'outside';

    @Input()
    container = 'body';

    @Input()
    stopPropagation = true;

    @Input()
    isReadOnly = false;

    @Input()
    openAssigneeDropdown$: Observable<Event> = new Observable();

    @Output()
    openMenu = new EventEmitter();

    @Output()
    closeMenu = new EventEmitter();

    @ViewChild('popover', { static: false })
    popover: NgbPopover;

    constructor(private dropdownService: RuumDropdownService) {}

    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.subscriptions.push(
            this.openAssigneeDropdown$.subscribe((event) => {
                if (event) {
                    this.toggleDropdownMenu(event);
                }
            }),
        );
    }

    ngOnDestroy() {
        // this.dropdownService.closeAllPopovers();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    toggleDropdownMenu(event) {
        if (this.stopPropagation) {
            event.stopPropagation();
        }
        if (this.popover.isOpen()) {
            this.popover.close();
            this.closeMenu.emit();
        } else {
            this.dropdownService.closeAllPopovers();
            this.dropdownService.registerPopover(this.popover);
            this.popover.open();
            this.openMenu.emit();
        }
    }

    close() {
        this.popover.close();
    }
}
