import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabOption } from './../../common/components/tabs.component';

@Component({
    selector: 'dashboard-usage-info-widget',
    template: `
        <dashboard-widget [title]="'Users'" [url]="'../users'">
            <div summary>
                <dashboard-user-info-licenses></dashboard-user-info-licenses>
            </div>

            <div details>
                <div class="title">
                    Active Users
                </div>

                <ruum-tabs [options]="options" (tabChanged)="timespanChanged($event)"></ruum-tabs>

                <user-usage-graph [days]="timespan$ | async"></user-usage-graph>
            </div>
        </dashboard-widget>
    `,
    styles: [
        `
            .title {
                font-size: 18px;
                margin: 32px 0;
            }
        `,
    ],
})
export class DashboardUsageInfoWidgetComponent {
    timespan$: BehaviorSubject<number> = new BehaviorSubject<number>(7);
    options: TabOption[] = [
        {
            id: 7,
            text: 'Last 7 days',
        },
        {
            id: 30,
            text: 'Last 30 days',
        },
        {
            id: 90,
            text: 'Last 3 months',
        },
    ];

    timespanChanged(newValue: number) {
        this.timespan$.next(newValue);
    }
}
