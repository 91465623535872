import { Injectable } from '@angular/core';
import { TaskPriority } from '@ruum/ruum-reducers';
import { RuumDropdownItem, RuumDropdownList } from '../../ui-components/dropdown/dropdown.model';

@Injectable({ providedIn: 'root' })
export class TaskPriorityService {
    getPriorityById(id: TaskPriority): RuumDropdownItem {
        switch (id) {
            case 'HIGH':
                return { id: 'HIGH', name: 'High', icon: 'icon-priority-filled', theme: 'danger' };
            case 'MEDIUM':
                return { id: 'MEDIUM', name: 'Medium', icon: 'icon-priority-filled', theme: 'warning' };
            case 'LOW':
                return { id: 'LOW', name: 'Low', icon: 'icon-priority-filled', theme: 'secondary' };
            default:
                return { id: null, name: 'No Priority', icon: 'icon-priority-outlined', theme: 'secondary' };
        }
    }

    getPriorityList(): RuumDropdownList {
        const ids: TaskPriority[] = ['HIGH', 'MEDIUM', 'LOW', null];
        const list = ids.map((id) => this.getPriorityById(id));
        return list;
    }
}
