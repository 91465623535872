import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../../../common/common.module';
import { CustomFieldSharedModule } from '../../shared/custom-field/custom-field-shared.module';
import { CustomFieldValueModule } from '../custom-field-value/custom-field-value.module';
import { CustomFieldEmptyStateComponent } from './custom-field-empty-state/custom-field-empty-state.component';
import { CustomFieldListComponent } from './custom-field-list/custom-field-list.component';

@NgModule({
    declarations: [CustomFieldListComponent, CustomFieldEmptyStateComponent],
    imports: [CommonModule, CustomFieldSharedModule, CdkTableModule, RuumCommonModule, CustomFieldValueModule],
    exports: [CustomFieldListComponent, CustomFieldEmptyStateComponent],
})
export class CustomFieldListModule {}
