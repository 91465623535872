import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrialService } from '../trial.service';
import { Subscription } from 'rxjs';
import { PaywallService } from '../../paywall.service';

@Component({
    selector: 'ruum-trial-statusbar',
    templateUrl: './trial-statusbar.component.html',
})
export class TrialStatusbarComponent implements OnInit, OnDestroy {
    visible = false;
    daysLeft = 0;

    private subscription = new Subscription();

    constructor(private readonly trialService: TrialService, private readonly paywallService: PaywallService) {}

    ngOnInit(): void {
        const trackStatusSubscription = this.trialService.trackTrialStatus().subscribe((trialStatus) => {
            if (trialStatus.status === 'running') {
                this.visible = true;
                this.daysLeft = trialStatus.daysLeft;
            } else {
                this.visible = false;
                this.daysLeft = 0;
            }
        });
        this.subscription.add(trackStatusSubscription);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    upgrade(): void {
        this.paywallService.navigateToUpgradeView();
    }
}
