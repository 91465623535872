import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardHeaderComponent } from '../../../../projects/ruum-ui-kit/src/app/shared/card/card-header.component';
import { RuumA11yModule } from '../../common/a11y/a11y.module';
import { ApproversModule } from './approvers/approvers.module';
import { ButtonToggleModule } from './btn-toggle/btn-toggle.module';
import { DatepickerModule } from './datepicker/datepicker.module';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { IconModule } from './icon/icon.module';
import { IllustrationModule } from './illustration/illustration.module';
import { InputModule } from './input/input.module';
import { LoadMoreModule } from './load-more/load-mode.module';
import { LoadSpinnerModule } from './load-spinner/load-spinner.module';
import { LogotypeModule } from './logotype/logotype.module';
import { MenuModule } from './menu/menu.module';
import { ProfileModule } from './profile/profile.module';
import { RadioModule } from './radio/radio.module';
import { RuumStatusModule } from './ruum-status/ruum-status.module';
import { SearchModule } from './search/search.module';
import { SelectModule } from './select/select.module';
import { ShortModule } from './short-number/short-number.module';
import { SortableColumnComponent } from './sortable-column/sortable-column.component';
import { HoveredRowTrackedDirective } from './table/hovered-row-tracked.directive';
import { ResizableColumnDirective } from './table/resizable-column.directive';
import { ResizableRowDirective } from './table/resizable-row.directive';
import { RowCellWidthDirective } from './table/row-cell-width.directive';
import { TagModule } from './tag/tag.module';

const UI_COMPONENTS = [SortableColumnComponent, EmptyStateComponent, CardHeaderComponent];
const UI_DIRECTIVES = [
    ResizableColumnDirective,
    ResizableRowDirective,
    RowCellWidthDirective,
    HoveredRowTrackedDirective,
];
const UI_MODULES = [
    LogotypeModule,
    IllustrationModule,
    IconModule,
    MenuModule,
    LoadSpinnerModule,
    LoadMoreModule,
    InputModule,
    TagModule,
    SelectModule,
    ProfileModule,
    ApproversModule,
    DatepickerModule,
    ButtonToggleModule,
    RadioModule,
    SearchModule,
    ShortModule,
    RuumStatusModule,
    RuumA11yModule,
];

@NgModule({
    declarations: [...UI_COMPONENTS, ...UI_DIRECTIVES],
    imports: [CommonModule, ...UI_MODULES],
    exports: [...UI_COMPONENTS, ...UI_MODULES, ...UI_DIRECTIVES],
})
export class UiComponentsModule {}
