import { BreakpointObserver } from '@angular/cdk/layout';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { BOOTSTRAP_BREAKPOINTS } from '../../../common/ruum-bootstrap/breakpoints';
import { componentHelper } from '../ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../ui-components.type';
import { SelectContentComponent } from './select-content/select-content.component';
import { RuumSelectData } from './select.model';
import { SelectService } from './select.service';

const selectMaxHeight = 32 * 11 + 12;

export type SelectPlacement =
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-top'
    | 'left-bottom'
    | 'right'
    | 'right-top'
    | 'right-bottom';

const topPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
};

const topLeftPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
};

const topRightPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
};

const rightPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
};

const rightTopPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom',
};

const rightBottomPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
};

const bottomPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
};

const bottomLeftPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
};

const bottomRightPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
};

const leftPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
};

const leftTopPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom',
};

const leftBottomPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
};

const positionMap = {
    top: topPosition,
    'top-left': topLeftPosition,
    'top-right': topRightPosition,
    bottom: bottomPosition,
    'bottom-left': bottomLeftPosition,
    'bottom-right': bottomRightPosition,
    left: leftPosition,
    'left-top': leftTopPosition,
    'left-bottom': leftBottomPosition,
    right: rightPosition,
    'right-top': rightTopPosition,
    'right-bottom': rightBottomPosition,
};
@Component({
    selector: 'ruum-select',
    template: `
        <div
            class="d-flex flex-fill minw-0"
            (click)="toggleOpen()"
            (keydown.space)="toggleOpen()"
            (keydown.enter)="toggleOpen()"
            tabindex="0"
        >
            <div class="d-flex flex-fill align-self-center minw-0" [class.cursor-not-allowed]="disabled">
                <ng-container *ngIf="!selectContentComponent">
                    <ng-container *ngIf="hasSelectedOptions$ | async; else placeholderTemplate">
                        <ng-container *ngIf="multiSelect; else singleSelectContent">
                            <ng-container *ngIf="selectedOptions$ | async as selectedOptions">
                                <ng-container
                                    *ngFor="
                                        let selectedOption of selectedOptions | slice: 0:maxDisplayedSelectedOptions;
                                        let last = last
                                    "
                                >
                                    <ruum-tag
                                        *ngIf="selectedOption.type === 'default'"
                                        [name]="selectedOption.content"
                                        [icon]="selectedOption.icon"
                                        [hover]="false"
                                        [theme]="theme"
                                        [componentClass]="getTagMarging(size, last)"
                                    ></ruum-tag>

                                    <ruum-profile-member
                                        *ngIf="selectedOption.type === 'member'"
                                        [name]="selectedOption.content"
                                        [hover]="false"
                                        [theme]="selectedOption.theme"
                                        [size]="'sm'"
                                        [componentClass]="getTagMarging(size, last)"
                                    ></ruum-profile-member>

                                    <ruum-profile-role
                                        *ngIf="selectedOption.type === 'role'"
                                        [name]="selectedOption.content"
                                        [hover]="false"
                                        [size]="'sm'"
                                        [componentClass]="getTagMarging(size, last)"
                                    ></ruum-profile-role>
                                </ng-container>

                                <ruum-tag
                                    *ngIf="selectedOptions.length > maxDisplayedSelectedOptions"
                                    [name]="getOtherTagsNumber(selectedOptions.length, maxDisplayedSelectedOptions)"
                                    [type]="'link'"
                                    [hover]="false"
                                    [componentClass]="'px-0'"
                                ></ruum-tag>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-content select="ruum-select-content"></ng-content>
            </div>
            <div
                #openPopup
                *ngIf="!hideCevron"
                class="icon-arrow-container btn btn-icon"
                [ngClass]="cevronClassName"
                [class.is-open]="isOpen"
                [class.disabled]="disabled"
                tabindex="-1"
            >
                <i class="icon icon-cevron-down"></i>
            </div>
        </div>

        <ng-template #popupTemplate>
            <!-- desktop popover -->
            <ruum-select-popover-desktop
                *ngIf="!isMobile"
                [isMultiSelect]="multiSelect"
                [isSearch]="search"
                [placement]="placement"
                [optionsTempaltePortal]="optionsTempaltePortal"
                [loading]="loading"
                (loadMoreOptions)="loadMoreOptions.emit()"
                cdkTrapFocus
            >
                <ruum-select-tag-list
                    [selectedOptions]="selectedOptions$ | async"
                    (removeOption)="removeOption($event)"
                >
                    <ruum-select-search
                        *ngIf="search"
                        (searchChange)="onSearch($event)"
                        (selectFirstItem)="selectFirstItem()"
                        (removeLastItem)="removeLastItem()"
                    ></ruum-select-search>
                </ruum-select-tag-list>
                <ruum-select-search
                    *ngIf="!multiSelect && search"
                    class="d-block"
                    (searchChange)="onSearch($event)"
                    (selectFirstItem)="selectFirstItem()"
                ></ruum-select-search>
            </ruum-select-popover-desktop>

            <!-- mobile popover -->
            <ruum-select-popover-mobile
                *ngIf="isMobile"
                [selectTitle]="selectTitle"
                [isMultiSelect]="multiSelect"
                [isSearch]="search"
                [optionsTempaltePortal]="optionsTempaltePortal"
                (done)="toggleOpen()"
            >
                <ruum-select-tag-list
                    [selectedOptions]="selectedOptions$ | async"
                    (removeOption)="removeOption($event)"
                >
                </ruum-select-tag-list>
                <ruum-select-search
                    class="d-block mx-2"
                    (searchChange)="onSearch($event)"
                    (selectFirstItem)="selectFirstItem()"
                ></ruum-select-search>
            </ruum-select-popover-mobile>
        </ng-template>

        <ng-template #placeholderTemplate>
            <ng-content select="ruum-select-placeholder"></ng-content>
        </ng-template>

        <ng-template #singleSelectContent>
            <div
                class="ruum-select-single-value align-items-center pl-2"
                *ngFor="let selectedOption of selectedOptions$ | async"
            >
                <div
                    *ngIf="selectedOption.icon"
                    class="btn btn-sm btn-icon minw-0 px-0 mr-2"
                    [ngClass]="selectedOptionIconClassName"
                    [style.width.px]="20"
                >
                    <i class="icon" [ngClass]="selectedOption.icon"></i>
                </div>
                <div
                    class="text-truncate font-weight-normal"
                    [class.text-small]="size === 'sm'"
                    [class.text-body]="theme === 'light'"
                >
                    {{ selectedOption.content }}
                </div>
            </div>
        </ng-template>

        <ng-template #optionsTemplate>
            <ng-content select="ruum-select-none"></ng-content>
            <ng-content select="ruum-select-option"></ng-content>
            <ng-content select="ruum-select-member-option"></ng-content>
            <ng-content select="ruum-select-role-option"></ng-content>
            <ng-content select="ruum-select-action-option"></ng-content>
        </ng-template>
    `,
    styles: [
        `
            :host {
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
        `,
    ],
    providers: [
        SelectService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, ControlValueAccessor {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            'd-flex flex-fill minw-0',
            componentHelper.getDefaultClassName(),
            componentHelper.getThemeClassName(this.theme, this.type),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getDisabledClassName(this.disabled),
            this.getSizeClassName(this.size),
            this.getBackgroundClassName(this.lightBackground),
            this.getPaddingClassName(this.size, this.multiSelect),
            this.componentClass,
        ]);
    }

    @ViewChild('openPopup', { static: false }) focusInput: ElementRef;

    /* it overrides opacity of .disable className */
    @HostBinding('style.opacity') opacityStyle = 1;

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'outline';
    @Input() maxDisplayedSelectedOptions = 1;
    @Input() lightBackground = true;
    @Input() rounded = false;
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() loading = false;
    @Input() forceFocus = false;
    @Input() componentClass = '';
    @ContentChild(SelectContentComponent, { static: false }) selectContentComponent: SelectContentComponent;

    @ViewChild('popupTemplate', { static: false }) popupTemplate: TemplateRef<any>;
    @ViewChild('optionsTemplate', { static: false }) optionsTemplate: TemplateRef<any>;

    @Input() autoClose: boolean = null;
    @Input() search = false;
    @Input() hideCevron = false;
    @Input() placement: SelectPlacement[] = ['bottom', 'top'];

    @Input()
    set isOpen(isOpen: boolean) {
        this.changeOpen(isOpen);
    }
    get isOpen(): boolean {
        return this._isOpen;
    }

    @Input()
    set isMobile(isMobile: boolean) {
        this._isMobile = isMobile;
        this.overlayConfig = isMobile ? this.createOverlayConfigMobile() : this.createOverlayConfig();
    }
    get isMobile(): boolean {
        return this._isMobile;
    }

    @Input()
    set select(data: string | string[]) {
        this.setOptionsByData(data);
    }
    get select(): string | string[] {
        return this._select;
    }

    @Input() selectTitle: string;

    @Input()
    set multiSelect(multiSelect: boolean) {
        this._multiSelect = multiSelect;
        this.selectService.setIsMultiSelect(!!multiSelect);
    }
    get multiSelect(): boolean {
        return this._multiSelect;
    }

    @Output() loadMoreOptions = new EventEmitter<void>();
    @Output() isOpenChange = new EventEmitter<boolean>();
    @Output() searchChange = new EventEmitter<string>();
    @Output() selectChange = new EventEmitter<string | string[]>();
    @Output() selectDataChange = new EventEmitter<RuumSelectData | RuumSelectData[]>();

    hasSelectedOptions$: Observable<boolean>;
    selectedOptions$: Observable<RuumSelectData[]>;
    optionsTempaltePortal: TemplatePortal;
    isMenuOpen = false;

    private _isOpen = false;
    private _isMobile = null;
    private _select: string | string[] = null;
    private _multiSelect = false;
    private isAfterViewInit = false;
    private isAfterContentInit = false;
    private popupTemplatePortal: TemplatePortal;
    private overlayRef: OverlayRef;
    private overlayConfig: OverlayConfig;
    private ngOnDestroy$ = new Subject<void>();
    private popoverDestroy$ = new Subject<void>();
    private preDefineSelect: string | string[] = null;
    private onChangeFunction;
    private onTouchedFunction;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private breakpointObserver: BreakpointObserver,
        private selectService: SelectService,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        // if auto close set explisitly it will be taken otherwise close on singleselect
        const caluclatedAutoClose = this.autoClose === null ? !this.multiSelect : this.autoClose;
        if (caluclatedAutoClose) {
            this.selectService.onSelectOptionEvent$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
                this.changeOpen(false);
            });
        }
        if (this.isMobile === null) {
            this.breakpointObserver
                .observe([BOOTSTRAP_BREAKPOINTS.XSmall, BOOTSTRAP_BREAKPOINTS.Small])
                .pipe(
                    takeUntil(this.ngOnDestroy$),
                    map((result) => result.matches),
                )
                .subscribe((isMobile) => {
                    this.isMobile = isMobile;
                    this.overlayConfig = isMobile ? this.createOverlayConfigMobile() : this.createOverlayConfig();
                    this.changeDetectorRef.markForCheck();
                    if (this._isOpen) {
                        this.changeOpen(false);
                    }
                });
        }
        this.hasSelectedOptions$ = this.selectService.hasSelectedOptions$;
        this.selectedOptions$ = this.selectService.selectedOptionsContent$;
        this.selectedOptions$
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map((selectedData) => {
                    if (!this.multiSelect) {
                        return selectedData ? selectedData[0] : null;
                    }
                    return selectedData;
                }),
            )
            .subscribe(this.selectDataChange);
    }

    ngAfterViewInit() {
        combineLatest([this.selectService.selectedOptionIds$, this.selectService.isMultiSelect$])
            .pipe(
                takeUntil(this.ngOnDestroy$),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            )
            .subscribe(([data]) => this.emitData(data));
        this.popupTemplatePortal = new TemplatePortal<any>(this.popupTemplate, this.viewContainerRef);
        this.optionsTempaltePortal = new TemplatePortal<any>(this.optionsTemplate, this.viewContainerRef);
        this.overlayConfig = this.isMobile ? this.createOverlayConfigMobile() : this.createOverlayConfig();
        this.isAfterViewInit = true;
        if (this._isOpen) {
            this.openPopOver();
        }
        if (this.forceFocus) {
            this.focusInput.nativeElement.focus();
        }
    }

    ngAfterContentInit() {
        this.isAfterContentInit = true;
        this.setOptionsByData(this.preDefineSelect);
        this.preDefineSelect = null;
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    writeValue(obj: any): void {
        this.select = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFunction = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    toggleOpen() {
        this.changeOpen(!this._isOpen);
    }

    removeOption(optionId: string) {
        this.selectService.setUnSelect(optionId);
    }

    onSearch(search: string) {
        this.selectService.setSearch(search);
        this.searchChange.emit(search);
    }

    selectFirstItem() {
        this.selectService.selectFirstItem();
        if (!this.multiSelect) {
            this.toggleOpen();
        }
    }

    removeLastItem() {
        this.selectService.removeLastItem();
    }

    getOtherTagsNumber(all: number, shown: number): string {
        const value = all - shown;
        return `+${value > 99 ? 99 : value}`;
    }

    getTagMarging(size, isLast) {
        const mr = size === 'sm' ? 'mr-1' : 'mr-2';
        return isLast ? 'mr-0' : mr;
    }

    get cevronClassName() {
        const theme = this.theme === 'light' ? 'secondary' : this.theme;
        return componentHelper.getThemeClassName(theme, 'link');
    }

    get selectedOptionIconClassName() {
        const theme = this.theme === 'light' ? 'secondary' : this.theme;
        return componentHelper.getThemeClassName(theme, 'link');
    }

    private changeOpen(isOpen: boolean) {
        if (this._isOpen !== isOpen) {
            if (isOpen) {
                this.openPopOver();
            } else {
                this.popoverDestroy$.next();
                this.closePopOver();
                this.selectService.setSearch(null);
            }
        }
        if (this.disabled) {
            return;
        }
        this._isOpen = isOpen;
        this.isOpenChange.emit(isOpen);
        this.changeDetectorRef.markForCheck();
    }

    private openPopOver() {
        if (!this.isAfterViewInit) {
            return;
        }
        if (this.disabled) {
            return;
        }
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create(this.overlayConfig);
            if (!this.isMobile) {
                const elementWidth = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().width;
                this.overlayRef.updateSize({ width: elementWidth });
            }
            this.overlayRef.attach(this.popupTemplatePortal);
            this.subscribeToPopover();
        }
    }

    private closePopOver() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
        this.focusInput.nativeElement.focus();
    }

    private emitData(data: string[]) {
        if (!this.isAfterViewInit) {
            return;
        }
        if (this.multiSelect) {
            if (this._select === data) {
                return;
            }
            this._select = data;
        } else {
            if (this._select === data[0]) {
                return;
            }
            this._select = data[0];
        }
        if (this.onChangeFunction) {
            this.onTouchedFunction();
            this.onChangeFunction(this._select);
        }
        this.selectChange.emit(this._select);
    }

    private setOptionsByData(data: string | string[]) {
        if (!this.isAfterContentInit) {
            this.preDefineSelect = data;
            return;
        }
        this._select = data;
        this.selectService.setOptionsByData(data);
    }

    private createOverlayConfig(): OverlayConfig {
        if (this.multiSelect) {
            return this.createOverlayConfigMultiSelect();
        } else {
            return this.createOverlayConfigSingleSelect();
        }
    }

    private createOverlayConfigSingleSelect(): OverlayConfig {
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        const elementWidth = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().width;
        const position = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions(this.placement.map((key) => this.getConnectedPosition(key)))
            .withLockedPosition(false);
        const config: OverlayConfig = {
            positionStrategy: position,
            scrollStrategy,
            width: elementWidth,
            minWidth: 200,
            maxHeight: selectMaxHeight,
            disposeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: 'ruum-select-popover-background',
            panelClass: 'ruum-select-popover',
        };
        return config;
    }

    private createOverlayConfigMultiSelect(): OverlayConfig {
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        const elementWidth = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().width;
        const position = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions(this.placement.map((key) => this.getConnectedPosition(key)))
            .withLockedPosition(false);
        const config: OverlayConfig = {
            positionStrategy: position,
            scrollStrategy,
            width: elementWidth,
            minWidth: 200,
            maxHeight: selectMaxHeight,
            disposeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: 'ruum-select-popover-background',
            panelClass: 'ruum-select-popover',
        };
        return config;
    }

    private createOverlayConfigMobile(): OverlayConfig {
        const scrollStrategy = this.overlay.scrollStrategies.block();
        const position = this.overlay.position().global();
        const config: OverlayConfig = {
            positionStrategy: position,
            scrollStrategy,
            minWidth: 200,
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            disposeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: 'ruum-select-popover-background',
            panelClass: 'ruum-select-popover',
        };
        return config;
    }

    private subscribeToPopover() {
        this.overlayRef
            .detachments()
            .pipe(takeUntil(this.ngOnDestroy$), takeUntil(this.popoverDestroy$))
            .subscribe(() => {
                this.changeOpen(false);
            });
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.ngOnDestroy$), takeUntil(this.popoverDestroy$))
            .subscribe(() => {
                this.overlayRef.dispose();
            });
        this.overlayRef
            .keydownEvents()
            .pipe(
                takeUntil(this.ngOnDestroy$),
                takeUntil(this.popoverDestroy$),
                filter((event) => event.key === 'Escape'),
            )
            .subscribe(() => {
                this.overlayRef.dispose();
            });
    }

    private getConnectedPosition(key: SelectPlacement): ConnectedPosition {
        return positionMap[key] || positionMap.bottom;
    }

    private getSizeClassName(size: string): string {
        return ['btn', size].filter((el) => !!el).join('-');
    }

    private getBackgroundClassName(lightBackground) {
        return lightBackground ? 'bg-extra-light' : '';
    }

    private getPaddingClassName(size, multiSelect) {
        const py = size === 'sm' ? 'py-0' : 'py-1';
        const pl = size === 'md' && multiSelect ? 'pl-2' : 'pl-1';
        const pr = 'pr-0';
        return `${py} ${pl} ${pr}`;
    }
}
