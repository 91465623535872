import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TaskStatus } from '@ruum/ruum-reducers';
import { TaskListItem } from '../../common/connectors/tasks/task-list.model';
import { ExtendedTask } from '../../common/task-list/task-list-container.component';

@Component({
    selector: 'ruum-task-list-mobile-view',
    template: `
        <div class="d-flex flex-fill flex-column minw-0 overflow-x">
            <div class="d-flex flex-column rounded minw-0" data-test="task-list-items" *ngIf="tasks.length !== 0">
                <ruum-task-list-item-small
                    *ngFor="let task of tasks"
                    class="d-flex flex-fill flex-column"
                    [task]="task"
                    (changeStatus)="handleChangeStatus($event, task)"
                    (itemClicked)="itemClick.emit($event)"
                    [isReadOnly]="isReadOnly"
                >
                </ruum-task-list-item-small>
            </div>
        </div>

        <ruum-load-more *ngIf="showLoading" (loadMore)="loadMoreVisible()"></ruum-load-more>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListMobileViewComponent {
    @HostBinding('class') hostClassList = 'overflow-y';

    @Input() tasks: TaskListItem[];
    @Input() isReadOnly = false;
    @Input() showLoading = false;
    @Output() changeStatus = new EventEmitter<{
        task: ExtendedTask;
        status: TaskStatus;
    }>();
    @Output() itemClick = new EventEmitter<TaskListItem>();
    @Output() loadMore = new EventEmitter<void>();

    constructor() {}

    loadMoreVisible() {
        this.loadMore.emit();
    }

    handleChangeStatus(status, task) {
        this.changeStatus.emit({
            task,
            status,
        });
    }
}
