import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AppStoreWrapper } from '../../common/appStoreWrapper.service';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { ODataEnterpriseTechnicalUser, ODATA_SERVICE_NAME } from '../../common/odata/odata.model';
import { ODataConnector } from '../../common/odata/ODataConnector.service';
import { ODataPaginatedListLoader } from '../../common/odata/ODataPaginatedListLoader';
import { ODataQuery } from '../../common/odata/ODataQuery';
import { ServiceHelper } from '../../common/serviceHelper';
import {
    SingleTechnicalUserLoadedAction,
    TechnicalUserDeletedAction,
    TechnicalUsersLoadedAction,
    TechnicalUserUpdatedAction,
    TECHNICAL_USER_LIST_ACTION_TYPES,
} from './technical-user-list.reducer';

@Injectable({ providedIn: 'root' })
export class TechnicalUserListService extends ODataPaginatedListLoader<ODataEnterpriseTechnicalUser> {
    constructor(
        protected authService: AuthService,
        protected alertService: RuumAlertService,
        private odataConnector: ODataConnector,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private storeWrapper: AppStoreWrapper,
        private serviceHelper: ServiceHelper,
    ) {
        super(alertService, authService);
        this.setUpObservables();
    }

    private setUpObservables() {
        combineLatest([this.selectedEnterpriseService.selectedEnterpriseId(), this.getListObservable()])
            .pipe(distinctUntilChanged())
            .subscribe(([enterpriseId, page]) => {
                this.serviceHelper.dispatchWithoutPersisting<TechnicalUsersLoadedAction>(
                    TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USERS_LOADED,
                    { enterpriseId, page },
                );
            });
    }

    async create(data: ODataEnterpriseTechnicalUser): Promise<ODataEnterpriseTechnicalUser> {
        const technicalUser = await this.odataConnector
            .create<ODataEnterpriseTechnicalUser>(
                {
                    serviceRoot: ODATA_SERVICE_NAME.INTERNAL,
                    path: `Enterprises('${data.enterpriseId}')/technicalUsers`,
                },
                data,
            )
            .toPromise();

        this.serviceHelper.dispatchWithoutPersisting<SingleTechnicalUserLoadedAction>(
            TECHNICAL_USER_LIST_ACTION_TYPES.SINGLE_TECHNICAL_USER_LOADED,
            { enterpriseId: technicalUser.enterpriseId, technicalUser },
        );

        return technicalUser;
    }

    async update(data: ODataEnterpriseTechnicalUser): Promise<ODataEnterpriseTechnicalUser> {
        const technicalUser = await this.odataConnector
            .patch<ODataEnterpriseTechnicalUser>(
                {
                    serviceRoot: ODATA_SERVICE_NAME.INTERNAL,
                    path: `Enterprises('${data.enterpriseId}')/technicalUsers(${data.userId})`,
                },
                data,
            )
            .toPromise();

        this.serviceHelper.dispatchWithoutPersisting<TechnicalUserUpdatedAction>(
            TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_UPDATED,
            { enterpriseId: technicalUser.enterpriseId, technicalUser },
        );

        return technicalUser;
    }

    async delete(data: ODataEnterpriseTechnicalUser): Promise<void> {
        await this.odataConnector
            .delete<ODataEnterpriseTechnicalUser>({
                serviceRoot: ODATA_SERVICE_NAME.INTERNAL,
                path: `Enterprises('${data.enterpriseId}')/technicalUsers('${data.userId}')`,
            })
            .toPromise();

        this.serviceHelper.dispatchWithoutPersisting<TechnicalUserDeletedAction>(
            TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_DELETED,
            { enterpriseId: data.enterpriseId, technicalUserId: data.userId },
        );
    }

    getStoreData$() {
        return this.storeWrapper.enterpriseTechnicalUsers();
    }

    getTechnicalUsers$(): Observable<ODataEnterpriseTechnicalUser[]> {
        return this.getStoreData$().pipe(
            filter((page) => !!page),
            pluck('rows'),
        );
    }

    async getByKeys(
        enterpriseId: string,
        technicalUserId: string,
        forceLoad: boolean = false,
    ): Promise<ODataEnterpriseTechnicalUser> {
        try {
            // checkStore
            let technicalUser;
            if (!forceLoad) {
                technicalUser = await this.getStoreData$()
                    .pipe(
                        take(1),
                        map((paginatedList) => {
                            return paginatedList.rows.find((el) => el.userId === technicalUserId);
                        }),
                    )
                    .toPromise();
            }

            if (!technicalUser) {
                technicalUser = await this.loadTechnicalUserByKeys(enterpriseId, technicalUserId).toPromise();
                if (technicalUser) {
                    this.serviceHelper.dispatchWithoutPersisting<SingleTechnicalUserLoadedAction>(
                        TECHNICAL_USER_LIST_ACTION_TYPES.SINGLE_TECHNICAL_USER_LOADED,
                        { enterpriseId, technicalUser },
                    );
                }
            }

            return technicalUser;
        } catch (e) {
            // Not found
            return null;
        }
    }

    protected getRecords(query: ODataQuery): Observable<PaginatedList<ODataEnterpriseTechnicalUser>> {
        return this.loadEnterpriseTechnicalUsers(query);
    }

    // Backend Connection
    private loadTechnicalUserByKeys(
        enterpriseId: string,
        technicalUserId: string,
    ): Observable<ODataEnterpriseTechnicalUser> {
        return this.odataConnector.getOne<ODataEnterpriseTechnicalUser>({
            serviceRoot: ODATA_SERVICE_NAME.INTERNAL,
            path: `Enterprises('${enterpriseId}')/technicalUsers('${technicalUserId}')`,
        });
    }

    private loadEnterpriseTechnicalUsers(query: ODataQuery): Observable<PaginatedList<ODataEnterpriseTechnicalUser>> {
        return this.selectedEnterpriseService.selectedEnterpriseId().pipe(
            switchMap((enterpriseId) => {
                return this.odataConnector.getList<ODataEnterpriseTechnicalUser>({
                    serviceRoot: ODATA_SERVICE_NAME.INTERNAL,
                    path: `Enterprises('${enterpriseId}')/technicalUsers?`,
                    query,
                });
            }),
        );
    }
}
