import { Pipe, PipeTransform } from '@angular/core';
import { ComponentTheme } from '../ui-components.type';

/**
 * This pipe transforms color to theme
 * It should support different groups of colors:
 *  - old colors that are hardcoded in ruum-reducers
 *  - new colors that will be hardcoded in ruum-reducers
 *  - color of DELETED user
 */
@Pipe({ name: 'ruumProfileHexToTheme' })
export class ProfileHexToThemePipe implements PipeTransform {
    transform(hex: string): ComponentTheme {
        const key = (hex || '').toLowerCase();
        const hexThemeMap = {
            // theme for DELETED users
            '#d3d3d3': 'light',
            // OLD colors
            '#5589ed': 'indigo-light',
            '#0a6ed1': 'cyan-light',
            '#8567e5': 'lilac-light',
            '#e36adf': 'magenta-light',
            '#ff8a42': 'berry-light',
            '#fdba01': 'orange-light',
            '#39dbae': 'pear-light',
            // NEW colors
            '#6367de': 'indigo-light',
            '#5cd0e0': 'cyan-light',
            '#0f828f': 'cyan-light',
            '#925ace': 'lilac-light',
            '#c0399f': 'magenta-light',
            '#d04343': 'berry-light',
            '#d08014': 'orange-light',
            '#7ca10c': 'pear-light',
        };

        return hexThemeMap[key] || 'light';
    }
}
