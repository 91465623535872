import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import { UserListItem } from '../../../common/connectors/readModelConnector.service';
import { AllAssignees } from './loadAssigneesDropdownData';

@Component({
    selector: 'ruum-task-assignee-proposal-list',
    template: `
        <ng-container *ngIf="!hideUsers">
            <!-- List of Users -->
            <ruum-task-assignees-dropdown-item
                *ngFor="let assignee of allAssignees.projectAssignees; let last = last"
                [style.width]="width"
                [style.min-height.px]="'33'"
                [class.mb-1]="!last"
                [id]="assignee.id"
                [name]="assignee.fullName"
                [color]="assignee.color"
                [selected]="isSelected(assignee.id)"
                (click)="emitOnSelectedAssignee(assignee)"
                [size]="size"
            >
            </ruum-task-assignees-dropdown-item>

            <div
                *ngIf="allAssignees.otherAssignees.length > 0"
                class="text-secondary text-tiny intergace text-uppercase p-2 "
            >
                Workspace / Group members
            </div>
            <ruum-task-assignees-dropdown-item
                *ngFor="let assignee of allAssignees.otherAssignees; let last = last"
                [style.width]="width"
                [class.mb-1]="!last"
                [id]="assignee.id"
                [name]="assignee.fullName"
                [color]="assignee.color"
                [selected]="isSelected(assignee.id)"
                (click)="emitOnSelectedAssignee(assignee)"
                [size]="size"
                [isRole]="false"
            >
            </ruum-task-assignees-dropdown-item>
        </ng-container>

        <!-- List of Roles  -->
        <ng-container *ngIf="workspaceId && !hideRoles">
            <div *ngIf="functionalRoles.length > 0" class="text-secondary tiny intergace text-uppercase p-2 ">
                Roles
            </div>
            <ruum-task-assignees-dropdown-item
                *ngFor="let role of functionalRoles; let last = last"
                [style.width]="width"
                [class.mb-1]="!last"
                [id]="role.id"
                [name]="role.name"
                [avatarRounded]="false"
                [disabled]="role.disabled"
                [selected]="isRoleSelected(role.id)"
                (click)="emitOnSelectedRole(role)"
                [size]="size"
                [isRole]="true"
            >
            </ruum-task-assignees-dropdown-item>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssigneeProposalListComponent {
    @Input() hideUsers;
    @Input() hideRoles;
    @Input() allAssignees: AllAssignees = {
        projectAssignees: [],
        otherAssignees: [],
    };
    @Input() width = undefined;
    @Input() size = 'sm';
    @Input() workspaceId: string;
    @Input() functionalRoles: FunctionalRoleListItem[] = [];
    @Input() assigneeIds: string[] = [];
    @Input() assignedFunctionalRoleIds: string[] = [];

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelectAssignee = new EventEmitter<UserListItem>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSelectRole = new EventEmitter<FunctionalRoleListItem>();

    constructor(private cdr: ChangeDetectorRef) {}

    emitOnSelectedAssignee(assignee: UserListItem) {
        this.onSelectAssignee.emit(assignee);
        this.cdr.detectChanges();
    }

    emitOnSelectedRole(role: FunctionalRoleListItem) {
        this.onSelectRole.emit(role);
        this.cdr.detectChanges();
    }

    isRoleSelected(roleId: string) {
        return !!(this.assignedFunctionalRoleIds || []).find((id) => id === roleId);
    }

    isSelected(id: string) {
        return !!this.assigneeIds.find((assigneeId) => assigneeId === id);
    }
}
