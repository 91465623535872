import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { AutoResizeTextareaComponent } from '../../../shared/ui-components/input/autoresize-textarea.component';
import { ProjectParticipantWithAllRoles } from '../../connectors/readModelConnector.service';
import { isMobile } from '../../utils.service';
import { InputValidator } from '../../validators/inputValidator.service';
import { AddCommentService } from '../addComment.service';
import { getMentionedUserIds } from '../comments.helpers';

@Component({
    selector: 'add-comment-text-input',
    template: `
        <div class="d-flex flex-fill">
            <autoresize-textarea
                #input
                ngDefaultControl
                [class]="'border-danger'"
                placeholder="Write a comment or @-mention..."
                [(ngModel)]="text"
                (ngModelChange)="validateCommentText($event)"
                (keyup)="cursorMoved($event)"
                (click)="cursorMoved()"
                (keydown.enter)="onKeyEnter($event)"
                (keyup.control.enter)="onKeyControlEnter($event)"
                (keydown.arrowup)="onKeyArrowUp($event)"
                (keydown.arrowdown)="onKeyArrowDown($event)"
                (keydown.esc)="closetAtMentionList(); $event.preventDefault()"
                (inputChange)="cursorMoved()"
                (blurChange)="closetAtMentionList()"
            >
            </autoresize-textarea>
            <button
                class="btn btn-primary btn-icon btn-lg ml-1 align-self-end send"
                title="Ctrl + Enter to send"
                type="button"
                (click)="sendComment()"
                [disabled]="commentErrorMessage !== undefined"
            >
                <i class="icon icon-send"></i>
            </button>
        </div>
    `,
    styles: [
        `
            autoresize-textarea {
                flex: 1;
            }
            .atMention {
                font-size: 28px;
                color: #d9dcdc;
                padding: 20px 8px;
                line-height: 1px;
                height: 28px;
            }
            .send {
                height: 40px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCommentTextInputComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    @Output() add = new EventEmitter<AddCommentData>();

    @Output() errorMessage = new EventEmitter();
    text = '';
    commentLimit: number;
    commentErrorMessage = undefined;
    /** Users which were mentioned in the text. */
    mentionedUsers: ProjectParticipantWithAllRoles[] = [];

    subscriptions: Subscription[] = [];
    @ViewChild('input', { static: false }) textArea: AutoResizeTextareaComponent;
    private atMentionFocusIndex = NaN;
    private atMentionOpened = false;
    private termLen = NaN;

    constructor(
        private addCommentService: AddCommentService,
        private cdr: ChangeDetectorRef,
        private inputValidator: InputValidator,
    ) {
        this.commentLimit = inputValidator.longLimit;
    }

    ngOnInit() {
        this.listenToAtMention();

        this.subscriptions.push(
            this.addCommentService.atMentionFocusIndex().subscribe((index) => {
                this.atMentionFocusIndex = index;
            }),
            this.addCommentService.atMentionSearch().subscribe((list) => {
                this.atMentionOpened = list.length !== 0;
            }),
            this.subscribeEnter(),
        );
    }

    listenToAtMention() {
        this.subscriptions.push(
            this.addCommentService.participantToMention().subscribe((p) => {
                this.mentionedUsers.push(p);
                this.replaceSearchTermWithSelectedParticipant(p.mail);
            }),
        );
    }

    private subscribeEnter() {
        return this.addCommentService
            .atMentionEnter()
            .pipe(
                withLatestFrom(this.addCommentService.atMentionFocusIndex(), (_, index: number) => index),
                filter((index) => !isNaN(index)),
                withLatestFrom(
                    this.addCommentService.atMentionSearch(),
                    (index, mentionOptions) => mentionOptions[index],
                ),
            )
            .subscribe((participant) => {
                this.addCommentService.mentionParticipant(participant);
                this.closetAtMentionList();
            });
    }

    sendComment() {
        const text = this.text;
        if (text.trim().length !== 0 && this.commentErrorMessage === undefined) {
            const atMentions = getMentionedUserIds(text, this.mentionedUsers);
            this.add.emit({ text, atMentions });
            this.setText('');
            this.mentionedUsers = [];
        }
    }

    clearInputFieldOnPanelChange() {
        if (!isMobile()) {
            this.setText('');
        }
    }

    onAtClick(event: MouseEvent) {
        const cursorPos = (this.textArea.textareaEl.nativeElement as HTMLTextAreaElement).selectionStart || 0;
        let inserttext = ' @';
        if (cursorPos === 0 || this.text[cursorPos - 1] === ' ') {
            inserttext = '@';
        }
        this.setText(
            this.text.substr(0, cursorPos) + inserttext + this.text.substr(cursorPos),
            cursorPos + inserttext.length,
        );

        event.preventDefault();
    }

    closetAtMentionList() {
        this.addCommentService.searchAtMention(undefined);
    }

    setText(text: string, pos: number = NaN) {
        this.text = text;
        this.cdr.detectChanges(); // Update the Textarea before set the cursor position
        this.textArea.focusOnTextarea();
        setTimeout(() => this.setCursorPosition(pos), 0);
    }

    replaceSearchTermWithSelectedParticipant(participantEmail: string) {
        const mentionText = participantEmail.replace(/@.*/, '') + ' ';
        const cursorPos = (this.textArea.textareaEl.nativeElement as HTMLTextAreaElement).selectionStart || 0;
        this.setText(
            this.text.substr(0, cursorPos - this.termLen) + mentionText + this.text.substr(cursorPos),
            cursorPos - this.termLen + mentionText.length,
        );
    }

    setCursorPosition(pos: number) {
        (this.textArea.textareaEl.nativeElement as HTMLTextAreaElement).setSelectionRange(pos, pos);
        this.cursorMoved();
    }

    onKeyEnter(event: KeyboardEvent) {
        // eslint-disable-next-line import/no-deprecated
        if (event && event.keyCode === 229) {
            // 229 is for the IME key, should be ignored
            return;
        }
        if (isNaN(this.atMentionFocusIndex)) {
            this.text = `${this.text}\n`;
        } else {
            this.addCommentService.enterOnMention();
        }
        event.preventDefault();
    }

    onKeyControlEnter(event: KeyboardEvent) {
        if (isNaN(this.atMentionFocusIndex)) {
            this.sendComment();
        }
        event.preventDefault();
    }

    onKeyArrowUp(event: KeyboardEvent) {
        if (this.atMentionOpened) {
            this.addCommentService.previousAtMentionFocus();
            event.preventDefault();
        }
    }

    onKeyArrowDown(event: KeyboardEvent) {
        if (this.atMentionOpened) {
            this.addCommentService.nextAtMentionFocus();
            event.preventDefault();
        }
    }

    cursorMoved(event?: KeyboardEvent) {
        const ignoreKeys = ['Enter', 'Escape'];
        if (event && event.key && ignoreKeys.includes(event.key)) {
            return;
        }
        const element = this.textArea.textareaEl.nativeElement as HTMLTextAreaElement;
        if (element.selectionStart !== element.selectionEnd || element.selectionStart > this.text.length) {
            this.closetAtMentionList();
            return;
        }
        let term = '';
        for (let i = element.selectionStart - 1; i >= 0; --i) {
            if (this.text[i] === '@' && (i === 0 || /^\s|\n$/.test(this.text[i - 1]))) {
                this.addCommentService.searchAtMention(term);
                this.termLen = term.length;
                return;
            }
            if (this.text[i] === ' ') {
                break;
            }
            term = this.text[i] + term;
        }
        this.closetAtMentionList();
        this.termLen = NaN;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    validateCommentText(text: string) {
        this.commentErrorMessage = this.inputValidator.getTextInputErrorMessage(text, this.commentLimit);
        this.errorMessage.emit(this.commentErrorMessage);
    }
}

export interface AddCommentData {
    text: string;
    /** The id of the users being mentioned. */
    atMentions: string[];
}
