import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataLossDialogComponent } from './DataLossDialogComponent';
import { ErrorDialogComponent } from './ErrorDialog';
import { GenericConfirmationDialogComponent } from './GenericConfirmationDialog';

export interface GenericConfirmationDialogParams {
    title: string;
    message: string;
    acceptText?: string;
    rejectText?: string;
}

@Injectable()
export class ModalService extends NgbModal {
    openDataLossDialog(): Promise<boolean> {
        return this.open(DataLossDialogComponent, { backdrop: 'static' }).result;
    }

    openGenericConfirmationDialog(config: GenericConfirmationDialogParams): Promise<boolean> {
        const dialog = this.open(GenericConfirmationDialogComponent, { backdrop: 'static' });
        dialog.componentInstance.config = config;
        return dialog.result;
    }

    openErrorDialog(messages: string | string[]): Promise<void> {
        const dialog = this.open(ErrorDialogComponent, { backdrop: 'static' });
        if (!Array.isArray(messages)) {
            messages = [messages];
        }
        dialog.componentInstance.messages = messages;
        return dialog.result;
    }
}
