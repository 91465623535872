import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    BasicAuthenticationConfiguration,
    ExternalSystem,
    OAuthAuthenticationConfiguration,
    OAuthGrantType,
    OutboundAuthentication,
    OutboundAuthenticationType,
    SelectionListOption,
} from '@ruum/ruum-reducers';
import { Observable, Subject } from 'rxjs';
import { map, skip, startWith, takeUntil } from 'rxjs/operators';

const passwordPlaceholder = '6H1scTjjqftoV0#Ms046qzz;MFpnYlJB';
const scopePlaceholder = '';

interface FieldsOptions {
    clientSecretChanged: boolean;
    passwordChanged: boolean;
}
export interface ExternalSystemFormData {
    title: string;
    port: number;
    hostname: string;
    systemOAuthClientId?: string;
    authType: OutboundAuthenticationType;
    tokenEndpoint?: string;
    clientId?: string;
    clientSecret?: string;
    grantType?: OAuthGrantType;
    username?: string;
    scope?: string;
    password?: string;
}

@Component({
    selector: 'ruum-external-system-admin-dialog-create',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">{{ modalTitle }}</h2>
            <form [formGroup]="form">
                <div class="form-group mb-3">
                    <label class="form-default-label" for="labelField">Title</label>
                    <input
                        ngbAutofocus
                        class="form-control form-control-sm"
                        placeholder="Enter a title of the system you want to connect with..."
                        id="labelField"
                        type="text"
                        formControlName="title"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="labelField">Protocol</label>
                    <div class="py-1">HTTPS</div>
                </div>
                <div class="form-group">
                    <label class="form-default-label" for="portField">Port</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter a port"
                        id="portField"
                        type="number"
                        formControlName="port"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="portField">Hostname</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter hostname..."
                        id="hostnameField"
                        type="text"
                        formControlName="hostname"
                    />
                </div>

                <div class="form-group mb-3">
                    <label class="form-default-label" for="systemOAuthClientIdField"
                        >System Ruum User ID (OPTIONAL)</label
                    >
                    <input
                        class="form-control form-control-sm"
                        placeholder="The ID of the Ruum User or of the OAuth Client ID that the system will use to connect to Ruum"
                        id="systemOAuthClientIdField"
                        type="text"
                        formControlName="systemOAuthClientId"
                    />
                </div>

                <div class="form-group mb-3">
                    <label class="form-default-label" for="authField">Outbound Authentication Type</label>
                    <!-- Single select -->
                    <ruum-select
                        [size]="'sm'"
                        [theme]="'light'"
                        [type]="'outline'"
                        [isMobile]="false"
                        [multiSelect]="false"
                        formControlName="authType"
                    >
                        <ruum-select-placeholder>
                            Select an authentication type...
                        </ruum-select-placeholder>
                        <!-- <ruum-select-none></ruum-select-none> -->
                        <ruum-select-option
                            *ngFor="let option of authOptions"
                            [value]="option.id"
                            [content]="option.value"
                        ></ruum-select-option>
                    </ruum-select>
                </div>

                <!-- oauth -->
                <ng-container *ngIf="form.value.authType === 'oauth'">
                    <div class="form-group mb-3" *ngIf="form.value.authType === 'oauth'">
                        <label class="form-default-label" for="tokenEndpointField">Token Endpoint</label>
                        <input
                            class="form-control form-control-sm"
                            placeholder="Enter Token Endpoint..."
                            id="tokenEndpointField"
                            type="text"
                            formControlName="tokenEndpoint"
                        />
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-default-label" for="clientIdField">Client Id</label>
                        <input
                            class="form-control form-control-sm"
                            placeholder="Enter Client ID..."
                            id="clientIdField"
                            type="text"
                            formControlName="clientId"
                        />
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-default-label" for="scopeField">Scope</label>
                        <input
                            class="form-control form-control-sm"
                            placeholder="Scope of the Access Request. Multiple values are space-delimited."
                            id="scopeField"
                            type="text"
                            formControlName="scope"
                        />
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-default-label" for="clientSecretField">Client Secret</label>
                        <input
                            class="form-control form-control-sm"
                            placeholder="Enter Client Secret..."
                            id="clientSecretField"
                            type="password"
                            formControlName="clientSecret"
                        />
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-default-label" for="labelField">Grant Type</label>
                        <p>client_credentials</p>
                    </div>
                </ng-container>

                <!-- basic auth -->
                <ng-container *ngIf="form.value.authType === 'basic'">
                    <div class="form-group mb-3">
                        <label class="form-default-label" for="usernameField">Username</label>
                        <input
                            class="form-control form-control-sm"
                            placeholder="Enter Username..."
                            id="usernameField"
                            type="text"
                            formControlName="username"
                        />
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-default-label" for="passwordField">Password</label>
                        <input
                            class="form-control form-control-sm"
                            placeholder="Enter Password..."
                            id="passwordField"
                            type="password"
                            formControlName="password"
                        />
                    </div>
                </ng-container>
                <div class="d-flex align-items-center justify-content-between mt-6">
                    <a
                        class="btn btn-xs btn-outline-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.sap.com/viewer/f8e58f003adf4cc78f5152f51a66e771/Latest/en-US"
                    >
                        Need Help? See Documentation!
                    </a>

                    <button
                        *ngIf="{
                            passwordChanged: passwordChanged$ | async,
                            clientSecretChanged: clientSecretChanged$ | async
                        } as data"
                        class="btn btn-lg btn-primary"
                        type="button"
                        [disabled]="!form.valid"
                        (click)="submit(data)"
                    >
                        {{ buttonText }}
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalSystemDialogComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-block';

    form: FormGroup;

    @Input() mode = 'create';
    /** provided when editing */
    @Input() system: ExternalSystem;

    authOptions: SelectionListOption[] = [
        { id: 'none', value: 'None' },
        { id: 'forward_token', value: 'Forward Token' },
        { id: 'oauth', value: 'oAuth' },
        { id: 'basic', value: 'Basic' },
    ];

    options: SelectionListOption[] = [];

    passwordChanged$: Observable<boolean>;
    clientSecretChanged$: Observable<boolean>;

    get modalTitle(): string {
        return this.mode === 'create' ? 'New External System' : 'Update External System';
    }

    get buttonText(): string {
        return this.mode === 'create' ? 'Create' : 'Update';
    }

    private ngOnDestroy$ = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            title: ['', Validators.required],
            port: [443, Validators.required],
            hostname: ['', Validators.required],
            /** Different from 'outboundAuthentication.clientId' */
            systemOAuthClientId: [''],
            authType: [undefined, Validators.required],
            // oauth
            tokenEndpoint: [undefined],
            clientId: [undefined],
            clientSecret: [undefined],
            scope: [undefined],
            // basic auth
            username: [undefined],
            password: [undefined],
        });

        if (this.system) {
            this.updateForm(this.system);
        }

        this.passwordChanged$ = this.form.get('password').valueChanges.pipe(
            skip(1),
            map(() => true),
            startWith(false),
        );

        this.clientSecretChanged$ = this.form.get('clientSecret').valueChanges.pipe(
            skip(1),
            map(() => true),
            startWith(false),
        );
    }

    updateForm(system: ExternalSystem) {
        this.form = this.formBuilder.group({
            title: [system.title, Validators.required],
            port: [system.port, Validators.required],
            hostname: [system.hostname, Validators.required],
            /** Different from 'outboundAuthentication.clientId' */
            systemOAuthClientId: [system.ruumUserId],
            authType: [system.outboundAuthentication && system.outboundAuthentication.type, Validators.required],
            // oauth
            tokenEndpoint: [
                system.outboundAuthentication &&
                    (<OAuthAuthenticationConfiguration>system.outboundAuthentication).tokenEndpoint,
            ],
            clientId: [
                system.outboundAuthentication &&
                    (<OAuthAuthenticationConfiguration>system.outboundAuthentication).clientId,
            ],
            clientSecret: [
                (system.outboundAuthentication &&
                    (<OAuthAuthenticationConfiguration>system.outboundAuthentication).clientSecret) ||
                    passwordPlaceholder,
            ],
            scope: [
                (system.outboundAuthentication &&
                    (<OAuthAuthenticationConfiguration>system.outboundAuthentication).scope) ||
                    scopePlaceholder,
            ],
            // basic auth
            username: [
                system.outboundAuthentication &&
                    (<BasicAuthenticationConfiguration>system.outboundAuthentication).userName,
            ],
            password: [
                (system.outboundAuthentication &&
                    (<BasicAuthenticationConfiguration>system.outboundAuthentication).password) ||
                    passwordPlaceholder,
            ],
        });
        this.setRequiredValidators();
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    setRequiredValidators() {
        this.form
            .get('authType')
            .valueChanges.pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((selectedAuth) => {
                switch (selectedAuth) {
                    case 'oauth':
                        this.form.get('username').clearValidators();
                        this.form.get('password').clearValidators();

                        this.form.get('tokenEndpoint').setValidators(Validators.required);
                        this.form.get('clientId').setValidators(Validators.required);
                        this.form.get('clientSecret').setValidators(Validators.required);
                        this.form.get('scope').setValidators(Validators.required);
                        break;
                    case 'basic':
                        this.form.get('tokenEndpoint').clearValidators();
                        this.form.get('clientId').clearValidators();
                        this.form.get('clientSecret').clearValidators();
                        this.form.get('scope').clearValidators();

                        this.form.get('username').setValidators(Validators.required);
                        this.form.get('password').setValidators(Validators.required);
                        break;
                    default:
                        this.form.get('username').clearValidators();
                        this.form.get('password').clearValidators();
                        this.form.get('tokenEndpoint').clearValidators();
                        this.form.get('clientId').clearValidators();
                        this.form.get('clientSecret').clearValidators();
                        this.form.get('scope').clearValidators();
                        break;
                }
                this.form.get('tokenEndpoint').updateValueAndValidity();
                this.form.get('clientId').updateValueAndValidity();
                this.form.get('scope').updateValueAndValidity();
                this.form.get('clientSecret').updateValueAndValidity();
                this.form.get('username').updateValueAndValidity();
                this.form.get('password').updateValueAndValidity();
            });
    }

    submit(options: FieldsOptions) {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.value;

        const system: ExternalSystem = {
            id: undefined,
            title: data.title,
            port: data.port,
            hostname: data.hostname,
            ruumUserId: data.systemOAuthClientId,
            outboundAuthentication: this.getOutboundAuthentication(data),
        };

        this.activeModal.close(system);
    }

    private getOutboundAuthentication(data: ExternalSystemFormData): OutboundAuthentication {
        const clientSecret =
            this.mode === 'create'
                ? data.clientSecret
                : data.clientSecret !== passwordPlaceholder
                ? data.clientSecret
                : '';
        const password =
            this.mode === 'create' ? data.password : data.password !== passwordPlaceholder ? data.password : '';

        switch (data.authType) {
            case 'oauth':
                return {
                    type: 'oauth',
                    tokenEndpoint: data.tokenEndpoint,
                    clientId: data.clientId,
                    clientSecret,
                    grant_type: 'client_credentials',
                    scope: data.scope,
                };
            case 'basic':
                return {
                    type: 'basic',
                    userName: data.username,
                    password,
                };
            case 'forward_token':
                return {
                    type: 'forward_token',
                };
            case 'none':
                return {
                    type: 'none',
                };
        }
    }
}
