import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { BaseFormComponent } from '../common/components/form.component';
import { ModalService } from '../common/modal/modal.service';
import { WebhookListService } from './webhook-list.service';

@Component({
    selector: 'webhook-edit',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <div class="header">
                    <nav class="navbar navbar-light navbar-expand-lg">
                        <h3 class="ml-3 navbar-text">Edit Webhook</h3>
                        <button
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-outline-danger position-absolute right-0"
                            (click)="delete()"
                        >
                            Delete Webhook
                        </button>
                    </nav>
                </div>

                <div class="body">
                    <webhook-form [form]="form"></webhook-form>
                </div>

                <div class="footer">
                    <button class="btn btn-outline-secondary float-left" (click)="navToIntegratedEntities()">
                        <i class="icon icon-subtask"></i>
                        View Integrated Entities
                    </button>
                    <button
                        [disabled]="form.invalid"
                        class="btn btn-primary float-right"
                        type="button"
                        (click)="save()"
                    >
                        Save
                    </button>
                    <button class="btn btn-outline-secondary float-right" type="button" (click)="back()">Cancel</button>
                </div>
            </div>
        </ruum-max-content>
    `,
    styles: [],
})
export class EditWebhookComponent extends BaseFormComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList =
        'd-flex flex-column w-100 mw-100 h-100 mh-100 px-2 px-sm-4 px-md-5 px-lg-8 overflow-y';

    isCurrentUserSuperAdmin$: Observable<boolean>;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private webhookService: WebhookListService,
        private router: Router,
        private modalService: ModalService,
        private fb: FormBuilder,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {
        super();

        this.form = this.fb.group({
            id: [{ value: '', disabled: true }],
            name: ['', Validators.required],
            userId: [{ value: '', disabled: true }],
            workspaceId: [{ value: '', disabled: true }],
            url: ['', Validators.required],
            version: 1,
            type: [{ value: '', disabled: true }],
            authType: [{ value: 'BASIC', disabled: true }],
            username: ['', Validators.required],
            password: null,
        });
    }

    ngOnInit() {
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();
        this.webhookService.loadList();
        this.subscriptions.push(
            this.route.data.subscribe((data) => {
                const webhook = data.webhook;
                this.form.get('id').setValue(webhook.id);
                this.form.get('name').setValue(webhook.name);
                this.form.get('userId').setValue(webhook.userId);
                this.form.get('workspaceId').setValue(webhook.workspaceId);
                this.form.get('url').setValue(webhook.url);
                this.form.get('type').setValue(webhook.type);
                this.form.get('version').setValue(webhook.version);
                this.form.get('authType').setValue(webhook.authType);
                this.form.get('username').setValue(webhook.username);
                this.form.markAsPristine();
            }),
        );
    }

    async save() {
        try {
            const data = this.form.getRawValue();
            await this.webhookService.update(data);
            this.form.markAsPristine();
            setTimeout(this.back.bind(this), 200);
        } catch (error) {
            this.modalService.openErrorDialog('Can you please try again?');
        }
    }

    navToIntegratedEntities() {
        const webhook = this.form.getRawValue();
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        const toEntitiesPage = `/admin/enterprise/${enterpriseId}/webhooks/${webhook.id}/entities`;
        this.router.navigateByUrl(toEntitiesPage);
    }

    async delete() {
        try {
            const doYouWantToDelete: boolean = await this.modalService.openGenericConfirmationDialog({
                title: 'Are you sure you want to delete this Webhook?',
                message: 'There is no way to revert this action.',
            });
            const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();

            if (doYouWantToDelete) {
                const data = this.form.getRawValue();
                await this.webhookService.delete(data);
                this.form.markAsPristine();
                const toWebhooksPage = `/admin/enterprise/${enterpriseId}/webhooks`;
                this.router.navigateByUrl(toWebhooksPage);
            }
        } catch (e) {
            this.modalService.openErrorDialog('Can you please try again?');
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subs) => subs.unsubscribe());
    }
}
