import {
    AssignProjectCustomFieldAction,
    EntityAction,
    RuumAction,
    RuumActionTypes,
    SetProjectCustomFieldValueAction,
} from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../../paginatedList.model';
import { ProjectCustomFieldValue } from './customFieldsValues.model';

export const PROJECT_CUSTOM_FIELDS_VALUES_ACTION_TYPES = {
    PROJECT_CUSTOM_FIELDS_VALUES_LOADED: 'PROJECT_CUSTOM_FIELDS_VALUES_LOADED',
};

export type CustomFieldsValuesLoadedAction = EntityAction<
    'PROJECT_CUSTOM_FIELDS_VALUES_LOADED',
    {
        page: PaginatedList<ProjectCustomFieldValue>;
    }
>;

export function ProjectCustomFieldsValuesReducer(
    currentState: PaginatedList<ProjectCustomFieldValue> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<ProjectCustomFieldValue> {
    switch (action.type) {
        case PROJECT_CUSTOM_FIELDS_VALUES_ACTION_TYPES.PROJECT_CUSTOM_FIELDS_VALUES_LOADED:
            return listLoaded(currentState, action.payload.page);
        case RuumActionTypes.SET_PROJECT_CUSTOM_FIELD_VALUE:
            return setCustomFieldValue(currentState, action);
        case RuumActionTypes.ASSIGN_PROJECT_CUSTOM_FIELD:
            return assignFields(currentState, action);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<ProjectCustomFieldValue>,
    page: PaginatedList<ProjectCustomFieldValue>,
): PaginatedList<ProjectCustomFieldValue> {
    if (parseInt(page.currentPage as any, 10) > 1) {
        return {
            ...page,
            rows: currentState.rows.concat(page.rows),
        };
    } else {
        return page;
    }
}

function setCustomFieldValue(
    currentState: PaginatedList<ProjectCustomFieldValue>,
    action: SetProjectCustomFieldValueAction,
): PaginatedList<ProjectCustomFieldValue> {
    const rows = currentState.rows.map((row) => {
        if (row.id === action.payload.fieldId && row.projectId === action.entityId) {
            return {
                ...row,
                value: action.payload.value,
            };
        } else {
            return row;
        }
    });
    return {
        ...currentState,
        rows,
    };
}

function assignFields(
    currentState: PaginatedList<ProjectCustomFieldValue>,
    action: AssignProjectCustomFieldAction,
): PaginatedList<ProjectCustomFieldValue> {
    return {
        ...currentState,
        rows: currentState.rows.concat(
            action.payload.fieldIds.map((fieldId) => ({
                id: fieldId,
                projectId: action.entityId,
                value: null,
            })),
        ),
    };
}
