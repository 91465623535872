import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { RuumAlertService } from '../../../../common/components/alert/alert.service';
import { OrderedListParams, PaginatedList } from '../../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../../common/connectors/paginatedListLoader';
import {
    InvolvedUsersParams,
    ReadModelBackendConnector,
    UserListItem,
} from '../../../../common/connectors/readModelConnector.service';
import { InvolvedUsersService } from '../../../../common/connectors/users/users.service';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';

type MemberListSortBy = any;

const emptyPage = {
    pageSize: 25,
    currentPage: 1,
    totalItems: 0,
    rows: [],
};

@Component({
    selector: 'ruum-filter-members-select',
    template: `
        <ruum-select
            [select]="select"
            [search]="true"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [loading]="loading$ | async"
            [lightBackground]="false"
            [multiSelect]="multiSelect"
            [disabled]="disabled"
            (loadMoreOptions)="loadMore()"
            (isOpenChange)="isOpenChange($event)"
            (searchChange)="searchChange($event)"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-content>
                <ruum-select-placeholder *ngIf="!(selectedOptions$ | async)?.length">
                    {{ placeholder }}
                </ruum-select-placeholder>
                <ruum-profile-member
                    *ngFor="let selectedOption of selectedOptions$ | async; let count = count"
                    [name]="selectedOption.fullName"
                    [theme]="selectedOption.color | ruumProfileHexToTheme"
                    [size]="size"
                    [showName]="count === 1"
                    [componentClass]="'mr-1 text-body'"
                ></ruum-profile-member>
            </ruum-select-content>
            <ruum-select-member-option
                *ngFor="let option of options$ | async; trackBy: trackByOption"
                [value]="option.id"
                [content]="option.fullName"
                [theme]="option.color | ruumProfileHexToTheme"
            ></ruum-select-member-option>
        </ruum-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMembersSelectComponent
    extends PaginatedListLoader<UserListItem, InvolvedUsersParams, MemberListSortBy>
    implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    @Input() set selectedOptionIds(ids: string[]) {
        this.selectedOptionIdsSubject.next(ids);
    }

    @Input() set workspaceId(value: string) {
        this.workspaceIdSubject.next(value);
    }

    @Input() set groupId(value: string) {
        this.groupIdSubject.next(value);
    }

    @Input() set projectId(value: string) {
        this.projectIdSubject.next(value);
    }

    @Input() placeholder = 'Select Value';
    @Input() placement = ['bottom-left', 'top-left'];
    @Input() multiSelect = false;
    @Input() isMobile = false;
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIdsSubject.getValue();
        } else {
            return this.selectedOptionIdsSubject.getValue()[0];
        }
    }

    selectedOptions$: Observable<UserListItem[]>;
    options$: Observable<UserListItem[]>;
    loading$: Observable<boolean>;

    private selectedOptionIdsSubject = new BehaviorSubject([]);
    private workspaceIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private groupIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private projectIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private orderBySubject = new BehaviorSubject<OrderedListParams<MemberListSortBy>>(undefined);
    private dataStoreSubject = new BehaviorSubject<PaginatedList<UserListItem[]>>(emptyPage);

    private ngOnDestroySubject = new Subject<void>();

    constructor(
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        private readModelBackendConnector: ReadModelBackendConnector,
        private involvedUsersService: InvolvedUsersService,
    ) {
        super(alertService, authService);
    }

    ngOnInit() {
        this.setUpObservables();
        this.selectedOptions$ = this.getSelectedOptions();
        this.options$ = this.getOptions();
        this.loading$ = this.getLoading();
    }

    ngOnDestroy() {
        this.stopLoadingList();
    }

    trackByOption(index: number, item: UserListItem) {
        return item.id;
    }

    isOpenChange(open: boolean) {
        if (open) {
            this.reload();
            this.dataStoreSubject.next(emptyPage);
            this.searchSubject.next('');
            this.loadList();
        } else {
            this.stopLoadingList();
        }
    }

    searchChange(search): void {
        this.reload();
        this.dataStoreSubject.next(emptyPage);
        this.searchSubject.next(search);
    }

    loadMore() {
        this.maybeGoToNextPage();
    }

    selectChange(event) {
        this.changed.emit(event);
    }

    protected getData(
        page: number,
        filters: InvolvedUsersParams,
        orderBy: OrderedListParams<MemberListSortBy>,
    ): Observable<PaginatedList<UserListItem>> {
        return this.readModelBackendConnector.getInvolvedUsers({ page, pageSize: 25 }, filters).pipe(
            map((res) => {
                const rows = res.rows.filter((row: any) => !row.isTechnicalUser);
                return {
                    ...res,
                    rows,
                };
            }),
        );
    }

    protected getFilters$(): Observable<InvolvedUsersParams> {
        return combineLatest([
            this.searchSubject,
            this.projectIdSubject,
            this.groupIdSubject,
            this.workspaceIdSubject,
        ]).pipe(
            map<any, InvolvedUsersParams>(([search, projectId, groupId, workspaceId]) => ({
                search,
                entityId: projectId || groupId || workspaceId,
            })),
        );
    }

    protected getOrderBy$(): Observable<OrderedListParams<MemberListSortBy>> {
        return this.orderBySubject;
    }

    protected getStoreData$(): Observable<PaginatedList<any>> {
        return this.dataStoreSubject.asObservable();
    }

    private setUpObservables() {
        this.getListObservable()
            .pipe(takeUntil(this.ngOnDestroySubject))
            .subscribe((page: any) => {
                const currentState = this.dataStoreSubject.getValue();
                this.dataStoreSubject.next({
                    ...page,
                    rows: [...currentState.rows, ...page.rows],
                });
            });
    }

    private getOptions(): Observable<UserListItem[]> {
        return this.dataStoreSubject.pipe(
            map((currentState: any) => {
                return currentState.rows;
            }),
        );
    }

    private getSelectedOptions(): Observable<UserListItem[]> {
        return combineLatest([this.selectedOptionIdsSubject.asObservable()]).pipe(
            filter(([ids]) => ids.length !== 0),
            switchMap(([ids]) => {
                return this.involvedUsersService.dataList(ids);
            }),
        );
    }

    private getLoading(): Observable<boolean> {
        return this.hasMore$.asObservable().pipe(startWith(true));
    }
}
