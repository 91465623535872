import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    FileUploadOptions,
    getProjectGroupParticipants,
    getProjectRole,
    ProjectGroupParticipantRole,
    ProjectParticipantRole,
    Ruum,
    RuumAction,
    RuumActionTypes,
    TemplateParticipant,
    TemplateParticipantRole,
    User,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, throttleTime } from 'rxjs/operators';
import { AuthService } from '../../../../app/auth/auth.service';
import { CanvasCollabService } from '../../../ruum/canvas/canvasCollab.service';
import { AppState } from '../../app.store';
import { LobbyListItemProject } from '../lobbyList/lobby-list.model';
import { ProjectParticipantWithAllRoles, ReadModelBackendConnector, UserListItem } from '../readModelConnector.service';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { GroupParticipantProjectAccessListService } from './groupParticipantList.service';
import { ProjectParticipantListService } from './projectParticipantList.service';
import { WorkspaceParticipantProjectAccessListService } from './workspaceParticipantList.service';

@Injectable({ providedIn: 'root' })
export class SelectedProjectServiceHelper {
    private initialized = false;
    private persistedActions$: Observable<RuumAction>;
    private getSelectedProjectId: () => string;
    private reloadListItem: () => void;
    private selectedProject$: Observable<Ruum>;
    private listItem$: Observable<LobbyListItemProject>;
    /** The logged user master data. */
    private userProfile$: BehaviorSubject<UserListItem> = new BehaviorSubject<UserListItem>({} as any);
    /** The logged user master data and role in the selected Project. */
    private participant$: BehaviorSubject<User> = new BehaviorSubject<User>({ roles: [] } as any);
    /** The role the logged user has in the Group which the selected Project is part of. */
    private groupRole$: Observable<ProjectGroupParticipantRole>;
    /** The role the logged user has in the Workspace which the selected Project is part of. */
    private workspaceRole$: Observable<WorkspaceParticipantRole>;
    /** The role the logged user has in the Template which the selected Project is part of. */
    private templateRole$: Observable<TemplateParticipantRole>;

    private enterpriseFileUploadOptions$: BehaviorSubject<FileUploadOptions> = new BehaviorSubject<FileUploadOptions>({
        ruum: true,
        external: true,
        onedrive: true,
        google_drive: true,
        box: true,
        youtube: true,
    });

    constructor(
        private canvasCollab: CanvasCollabService,
        private authService: AuthService,
        protected store: Store<AppState>,
        private selectedWorkspace: SelectedWorkspaceService,
        protected readModelBackendConnector: ReadModelBackendConnector,
        private projectParticipantListService: ProjectParticipantListService,
        private workspaceParticipantListService: WorkspaceParticipantProjectAccessListService,
        private groupParticipantListService: GroupParticipantProjectAccessListService,
    ) {}

    initialize(
        persistedActions: Observable<RuumAction>,
        getId: () => string,
        selectedProject$: Observable<Ruum>,
        reloadListItem: () => void,
        listItem$: Observable<LobbyListItemProject>,
        templateParticipant$: Observable<TemplateParticipant>,
    ) {
        if (this.initialized) {
            throw Error('SelectedProjectServiceHelper was already initialized');
        }
        this.initialized = true;
        this.persistedActions$ = persistedActions;
        this.getSelectedProjectId = getId;
        this.selectedProject$ = selectedProject$;
        this.reloadListItem = reloadListItem;
        this.listItem$ = listItem$;

        this.listenToGroupRole();
        this.listenToWorkspaceRole();
        this.listenToParticipantList();
        this.listenToGroupId();
        this.listenToWorkspaceId();
        this.templateRole$ = templateParticipant$.pipe(
            map((participant) => <TemplateParticipantRole>(participant && participant.role)),
        );
        this.listenToLoggedUserParticipant();
    }

    private listenToLoggedUserParticipant(): void {
        combineLatest([
            this.userProfile$.pipe(filter<any>(Boolean)),
            this.selectedProject$.pipe(
                map((project) => project),
                distinctUntilChanged(),
            ),
            this.selectedProject$.pipe(
                map((project) => project.participants),
                distinctUntilChanged(),
            ),
            this.groupRole$,
            this.workspaceRole$,
            this.templateRole$,
            this.listItem$,
        ])
            .pipe(
                map(([profile, project, participants, groupRole, workspaceRole, templateRole, listItem]) => {
                    const userId = this.authService.getLoggedUserId();
                    const role = getProjectRole(
                        project.configuration,
                        participants,
                        userId,
                        groupRole,
                        workspaceRole,
                        templateRole,
                        project.belongsToTemplateId,
                        project.templateProjectPublished,
                    ) as ProjectParticipantRole;
                    const p: User = {
                        id: userId,
                        fullName: profile.fullName,
                        mail: profile.mail,
                        color: profile.color,
                        initials: profile.initials,
                        roles: [role],
                        hasArchived: listItem && listItem.archived,
                    };
                    return p;
                }),
            )
            .subscribe((participant: any) => this.participant$.next(participant));
    }

    private listenToGroupRole(): void {
        this.groupRole$ = this.store.select('selectedProjectGroup').pipe(
            distinctUntilChanged(),
            map((group) => {
                if (group && group.id) {
                    const groupParticipant = getProjectGroupParticipants(group).find(
                        (p) => p.id === this.authService.getLoggedUser().id,
                    );
                    if (groupParticipant) {
                        return groupParticipant.roles[0];
                    }
                } else {
                    return undefined;
                }
            }),
            distinctUntilChanged(),
        );
    }

    /** Selecting the Group will also select the Workspace. */
    private listenToWorkspaceRole(): void {
        this.workspaceRole$ = this.selectedWorkspace
            .loggedUserWorkspaceParticipant()
            .pipe(map((participant) => (participant && participant.role) as WorkspaceParticipantRole));
    }

    private listenToParticipantList() {
        const otherUsersActions$ = this.persistedActions$.pipe(
            filter((action) => action.createdBy && action.createdBy !== this.authService.getLoggedUser().id),
            throttleTime(5000),
        );
        const participantListActions$ = this.persistedActions$.pipe(
            filter(
                (action) =>
                    action.type === RuumActionTypes.ADD_PARTICIPANTS ||
                    action.type === RuumActionTypes.DELETE_PARTICIPANT ||
                    action.type === RuumActionTypes.SET_PROJECT_PARTICIPANT_ROLE,
            ),
        );

        merge(
            this.selectedProject$.pipe(
                map((project) => project.id),
                distinctUntilChanged(),
            ),
            /** Any action that changed the list. */
            participantListActions$,
            /** Actions or Canvas Steps from other users (to update last online at) */
            otherUsersActions$,
            this.canvasCollab.otherUsersChangedCanvas$.pipe(throttleTime(5000)),
        )
            .pipe(
                debounceTime(50),
                map(() => this.getSelectedProjectId()),
            )
            .subscribe((projectId) => {
                this.projectParticipantListService.projectId$.next(projectId);
                this.projectParticipantListService.reload();
                this.reloadListItem();
            });
    }

    private listenToGroupId() {
        this.selectedProject$
            .pipe(
                filter(() => !!this.getSelectedProjectId()),
                map((project) => project.groupId),
                distinctUntilChanged(),
            )
            .subscribe((groupId) => {
                this.reloadListItem();
            });
    }

    private listenToWorkspaceId() {
        this.selectedProject$
            .pipe(
                filter(() => !!this.getSelectedProjectId()),
                map((project) => project.workspaceId),
                distinctUntilChanged(),
            )
            .subscribe((groupId) => {
                this.reloadListItem();
            });
    }

    selectProject(projectId: string) {
        this.loadLoggerUserMasterData();
        this.loadGroupMembers(projectId);
        this.loadWSMembers(projectId);
        this.loadParticipantsList(projectId);
    }

    setEnterpriseFileUploadOptions(enterpriseFileUploadOptions: FileUploadOptions) {
        this.enterpriseFileUploadOptions$.next(enterpriseFileUploadOptions);
    }

    getEnterpriseFileUploadOptions(): Observable<FileUploadOptions> {
        return this.enterpriseFileUploadOptions$.asObservable();
    }

    unselectProject() {
        this.participant$.next({ roles: [] } as any);
    }

    private async loadLoggerUserMasterData() {
        const users = await this.readModelBackendConnector
            .getInvolvedUsers({ page: 1, pageSize: 25 }, { userIds: [this.authService.getLoggedUser().id] })
            .toPromise();
        this.userProfile$.next(users[0]);
    }

    loadParticipantsList(projectId: string) {
        this.projectParticipantListService.projectId$.next(projectId);
        this.projectParticipantListService.loadList();
    }

    async loadGroupMembers(projectId: string) {
        this.groupParticipantListService.projectId$.next(projectId);
        this.groupParticipantListService.reload();
    }

    async loadWSMembers(projectId: string) {
        this.workspaceParticipantListService.projectId$.next(projectId);
        this.workspaceParticipantListService.reload();
    }

    getProjectGroupRole(): ProjectGroupParticipantRole {
        let role: ProjectGroupParticipantRole;
        this.groupRole$.pipe(take(1)).subscribe((r) => (role = r));
        return role;
    }

    getWorkspaceRole(): WorkspaceParticipantRole {
        let role: WorkspaceParticipantRole;
        this.workspaceRole$.pipe(take(1)).subscribe((r) => (role = r));
        return role;
    }

    getTemplateRole(): TemplateParticipantRole {
        let role: TemplateParticipantRole;
        this.templateRole$.pipe(take(1)).subscribe((r) => (role = r));
        return role;
    }

    participants(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.projectParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !p.technicalUser)),
        );
    }

    technicalUsers(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.projectParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !!p.technicalUser)),
        );
    }

    loggedUserParticipant(): Observable<User> {
        return this.participant$.asObservable();
    }

    getLoggedUserParticipant(): User {
        return this.participant$.value;
    }

    groupMembers(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.groupParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !p.technicalUser)),
        );
    }

    workspaceMembers(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.workspaceParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !p.technicalUser)),
        );
    }
}
