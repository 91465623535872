import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, combineLatest, from, Observable, of, timer } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchAll,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { RuumAlertService } from '../components/alert/alert.service';
import { deepEqual } from '../utils.service';
import { OrderedListParams, PaginatedList } from './paginatedList.model';

export class GenericDataSource<T> extends DataSource<T> {
    constructor(private data: Observable<T[]> = of([])) {
        super();
    }

    connect(): Observable<T[]> {
        return this.data;
    }

    disconnect() {}
}

export const MAXIMUM_WAIT = 2 * 60 * 1000;
export const DEFAULT_BACK_OFF = 2000;

export abstract class PaginatedListLoader<T, F, O> {
    private readonly page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    protected readonly shouldLoadList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly reload$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
    readonly isLoadingAnotherPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly isLoadingFirstPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly hasMore$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly totalItems$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    readonly showLoading$: Observable<boolean>;

    constructor(protected alertService: RuumAlertService, protected authService: AuthService) {
        this.showLoading$ = combineLatest([this.hasMore$, this.isLoadingFirstPage$, this.isLoadingAnotherPage$]).pipe(
            map(([hasMore, isLoadingFirstPage, isLoadingAnotherPage]) => {
                return isLoadingFirstPage || isLoadingAnotherPage || hasMore;
            }),
        );
    }

    protected getListObservable(): Observable<PaginatedList<T>> {
        let backOff: number = DEFAULT_BACK_OFF;
        return combineLatest([
            this.authService.loggedUserId(),
            this.shouldLoadList$.pipe(distinctUntilChanged()),
            this.getFilters$().pipe(
                distinctUntilChanged((a, b) => deepEqual(a, b)),
                tap(() => {
                    /** Every time the filters change the page should go back to being 1 */
                    this.page$.next(1);
                }),
            ),
            this.page$.pipe(distinctUntilChanged()),
            this.getOrderBy$().pipe(
                distinctUntilChanged((a, b) => deepEqual(a, b)),
                tap(() => {
                    /** Every time the filters change the page should go back to being 1 */
                    this.page$.next(1);
                }),
            ),
            this.reload$,
        ]).pipe(
            /** Only make request if list is being shown */
            filter(([loggedUserId, shouldLoad]) => shouldLoad && !!loggedUserId),
            /** It is important this is before the deboundeTime because this information is used by maybeLoadNextPage */
            tap(([loggedUserId, shouldLoad, filters, page]) => this.setLoadIndicators(page, true)),
            /** debounce so that when multiple filters are changed one right after the other it only emits the latest value. */
            debounceTime(50),
            map(([loggedUserId, shouldLoad, filters, page, orderBy]) =>
                this.getData(page, filters, orderBy).pipe(tap(() => (backOff = DEFAULT_BACK_OFF))),
            ),
            /** So that only the HTTP request of the last state of filters is considered and we don't run into race conditions. */
            switchAll(),
            tap((page) => {
                this.setLoadIndicators(page.currentPage, false);
                this.totalItems$.next(page.totalItems);
                this.hasMore$.next(hasMorePages(page));
            }),
            /** Catch the error so that the stream is not completed after an API call fails */
            catchError((error, caught) => {
                if (backOff < MAXIMUM_WAIT) {
                    backOff += backOff / 2;
                }
                this.isLoadingAnotherPage$.next(false);
                this.isLoadingFirstPage$.next(false);

                if (error.status === 0) {
                    /** If is was a network error just try again */
                    return timer(backOff).pipe(switchMap(() => caught));
                } else {
                    return this.showErrorMessage(error).pipe(
                        /** After the user clicked on OK, wait two seconds and try again. */
                        switchMap(() => timer(backOff)),
                        switchMap(() => caught),
                    );
                }
            }),
        );
    }

    protected abstract getData(page: number, filters: F, orderBy: OrderedListParams<O>): Observable<PaginatedList<T>>;

    protected abstract getFilters$(): Observable<F>;

    /** The page that is stored in the store, only the page values (currentPage, pageSize, totalItems) are relevant. */
    protected abstract getStoreData$(): Observable<PaginatedList<any>>;

    protected getOrderBy$(): Observable<OrderedListParams<O>> {
        return new BehaviorSubject<OrderedListParams<O>>(undefined).asObservable();
    }

    private setLoadIndicators(page: number, loading: boolean) {
        if (loading) {
            if (page > 1) {
                this.isLoadingAnotherPage$.next(loading);
            } else {
                this.isLoadingFirstPage$.next(loading);
            }
        } else {
            this.isLoadingFirstPage$.next(false);
            this.isLoadingAnotherPage$.next(false);
        }
    }

    private showErrorMessage(error): Observable<any> {
        /** in this case user will be redirected to login */
        if (error.status === 403 || error.status === 401) {
            return of(1);
        } else {
            return from(this.alertService.warning('Error getting List.', error));
        }
    }

    getStoreRows$(): Observable<T[]> {
        return this.getStoreData$().pipe(map((page: PaginatedList<T>) => page.rows));
    }

    // Used by cdk tables
    getDataSource(): GenericDataSource<T> {
        return new GenericDataSource(this.getStoreRows$());
    }

    loadList() {
        this.shouldLoadList$.next(true);
    }

    stopLoadingList() {
        this.shouldLoadList$.next(false);
    }

    reload() {
        this.page$.next(1);
        this.reload$.next();
    }

    maybeGoToNextPage() {
        this.getStoreData$()
            .pipe(take(1))
            .subscribe((page) => {
                if (hasMorePages(page)) {
                    if (!this.isLoadingFirstPage$.value) {
                        // using current page from the store to assure pages are loaded in order
                        this.page$.next(page.currentPage + 1);
                    }
                }
            });
    }
}

export function hasMorePages<T>({ pageSize, currentPage, totalItems }: PaginatedList<T>): boolean {
    return pageSize * currentPage < totalItems;
}
