import { Injectable } from '@angular/core';
import { canvasSchema } from '@ruum/ruum-reducers';
import { Fragment, Node, ResolvedPos } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, share, take } from 'rxjs/operators';
import { getFormattedTextContent } from '../../variables/variable.helper';
import { CanvasSectionWithPolls, CanvasSectionWithTasks } from './../../../common/actions';
import { AppStoreWrapper } from './../../../common/appStoreWrapper.service';
import { CanvasService } from './../canvas.service';
import { getSections } from './../canvasHelpers';
import { CanvasStateManagerService } from './../canvasStateManager.service';

export interface SectionListItem {
    name: string;
    collapsed: boolean;
}

export const SECTION_HEADER_PLACEHOLDER = 'Give this section a name';

@Injectable({ providedIn: 'root' })
export class CanvasSectionsService {
    static isEmptyParagraph(pos: ResolvedPos): boolean {
        if (pos.depth !== 2) {
            return false;
        }

        const node = pos.node(2);
        if (!node || node.type.name !== 'paragraph') {
            return false;
        }
        for (let i = 0; i < node.content.childCount; ++i) {
            if (node.content.child(i).type.name !== 'hard_break') {
                return false;
            }
        }
        return true;
    }

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private canvasStateManager: CanvasStateManagerService,
        private canvasService: CanvasService,
    ) {}

    /**
     * Adds an empty section on top of the section passed as parameter.
     */
    addEmptySectionOnTopOf(sectionId: string, onTopOfSectionId: string) {
        this.addSectionOnTopOf(this.createEmptySection(sectionId), onTopOfSectionId);
    }

    createSystemConnectorConfigurationNode(id: string) {
        return canvasSchema.nodes.systemConnectorConfiguration.create({ id });
    }

    addSectionOnTopOf(newSection: Node, sectionId: string) {
        const fullState = this.canvasStateManager.getCurrentFullState();
        fullState.doc.content.forEach((sectionNode, pos) => {
            if (sectionNode.attrs.id === sectionId) {
                const tr = this.canvasStateManager.getCurrentFullState().tr.insert(pos, newSection);
                this.canvasService.dispatchTransactionToCanvas(tr);
                setTimeout(() => this.setFocusOnSectionEmptyField(newSection.attrs.id), 50);
                return false;
            }
        });
    }

    setFocusOnSectionEmptyField(sectionId: string) {
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        const sectionHeader = sectionState.doc.content.child(0);
        if (sectionHeader.content.childCount === 0) {
            this.setFocusOnSection(sectionId, 1);
        } else {
            // Find the first emplty paragraph
            const sectionContent = sectionState.doc.content.maybeChild(1);
            const sectionContentChildCount = sectionContent ? sectionContent.childCount : 0;
            for (let i = 0, pos = 2 + sectionHeader.nodeSize; i < sectionContentChildCount; ++i) {
                const node = sectionContent.child(i);
                if (CanvasSectionsService.isEmptyParagraph(sectionState.doc.resolve(pos))) {
                    this.setFocusOnSection(sectionId, pos);
                    break;
                }
                pos += node.nodeSize;
            }
        }
    }

    setFocusOnLastParagraph(sectionId: string) {
        const state = this.canvasStateManager.getSectionState(sectionId);
        this.setFocusOnSection(sectionId, state.doc.content.size - 2);
    }

    setFocusOnSectionHeader(sectionId: string) {
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        const endOfHeaderPos = sectionState.doc.child(0).nodeSize - 1;
        this.setFocusOnSection(sectionId, endOfHeaderPos);
    }

    setFocusOnSection(sectionId: string, pos: number) {
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        const resolvedPos = sectionState.doc.resolve(pos);
        const tr = sectionState.tr.setSelection(new TextSelection(resolvedPos));
        this.canvasStateManager.dispatchSectionTransaction(sectionId, tr);
        this.canvasStateManager.setFocusOnSection(sectionId);
    }

    /**
     *  Add an empty section to the end of the canvas.
     */
    addEmptySectionToTheBottom(sectionId: string) {
        this.addSectionToTheBottom(this.createEmptySection(sectionId));
    }

    addEmptySectionToTheTop(sectionId: string) {
        this.addSectionToTheTop(this.createEmptySection(sectionId));
    }

    addSectionToTheBottom(newSection: Node) {
        const tr = this.canvasStateManager
            .getCurrentFullState()
            .tr.insert(this.canvasStateManager.getCurrentFullState().doc.content.size, newSection);
        this.canvasService.dispatchTransactionToCanvas(tr);
        setTimeout(() => this.setFocusOnSectionEmptyField(newSection.attrs.id), 50);
    }

    addSectionToTheTop(newSection: Node) {
        const tr = this.canvasStateManager.getCurrentFullState().tr.insert(0, newSection);
        this.canvasService.dispatchTransactionToCanvas(tr);
        setTimeout(() => this.setFocusOnSectionEmptyField(newSection.attrs.id), 50);
    }

    addLineBelowSectionHeader(sectionId: string) {
        const newLineNode = canvasSchema.nodes.paragraph.create({});
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        const tr = sectionState.tr.insert(2, newLineNode);
        this.canvasStateManager.dispatchSectionTransaction(sectionId, tr);
        this.canvasStateManager.setFocusOnSection(sectionId);
    }

    createSection(sectionId: string, content?: Fragment | Node | Node[], headerDesc?: string): Node {
        const sectionHeader = canvasSchema.nodes.sectionHeader.create({}, headerDesc && canvasSchema.text(headerDesc));
        const sectionContent = canvasSchema.nodes.sectionContent.create({}, content);
        return canvasSchema.nodes.section.create(
            {
                id: sectionId,
            },
            [sectionHeader, sectionContent],
        );
    }

    private createEmptySection(sectionId: string) {
        const emptyParagraph = canvasSchema.nodes.paragraph.create();
        return this.createSection(sectionId, [emptyParagraph, emptyParagraph, emptyParagraph, emptyParagraph]);
    }

    getCanvasSectionWithTasks(): Observable<CanvasSectionWithTasks[]> {
        return this.canvasStateManager.validFullStateChange().pipe(
            map((state) => {
                const jsonDoc = state.doc.toJSON();
                if (jsonDoc.content) {
                    return jsonDoc.content.reduce((sections: CanvasSectionWithTasks[], section) => {
                        if (section.attrs.id && section.content) {
                            const sectionContent = section.content[1];
                            if (sectionContent && sectionContent.content) {
                                const tasks = sectionContent.content
                                    .filter((node) => node.type === 'task')
                                    .map((taskNode) => taskNode.attrs.id);
                                const sectionWithTasks: CanvasSectionWithTasks = {
                                    sectionId: section.attrs.id,
                                    sectionName: this.getSectionHeaderText(section.content[0]),
                                    tasks,
                                };
                                sections.push(sectionWithTasks);
                            }
                        }
                        return sections;
                    }, []);
                } else {
                    return [];
                }
            }),
            distinctUntilChanged((a, b) => this.canvasSectionsTasksEqual(a, b)),
        );
    }

    getCanvasSectionWithPolls(): Observable<CanvasSectionWithPolls[]> {
        return this.canvasStateManager.validFullStateChange().pipe(
            map((state) => {
                const jsonDoc = state.doc.toJSON();
                if (jsonDoc.content) {
                    return jsonDoc.content.reduce((sections: CanvasSectionWithPolls[], section) => {
                        if (section.attrs.id && section.content) {
                            const sectionContent = section.content[1];
                            if (sectionContent && sectionContent.content) {
                                const polls = sectionContent.content
                                    .filter((node) => node.type === 'poll')
                                    .map((pollNode) => pollNode.attrs.id);
                                const sectionWithPolls: CanvasSectionWithPolls = {
                                    sectionId: section.attrs.id,
                                    sectionName: this.getSectionHeaderText(section.content[0]),
                                    polls,
                                };
                                sections.push(sectionWithPolls);
                            }
                        }
                        return sections;
                    }, []);
                } else {
                    return [];
                }
            }),
            distinctUntilChanged((a, b) => this.canvasSectionsPollsEqual(a, b)),
        );
    }

    /**
     * Return the list of section ids in the order they are in the canvas.
     */
    getCanvasSectionIds(): string[] {
        let sectionIds: string[] = [];
        this.getCanvasSectionIds$()
            .pipe(take(1))
            .subscribe((ids) => (sectionIds = ids));
        return sectionIds;
    }

    getCanvasSectionIds$(): Observable<string[]> {
        return this.canvasStateManager.validFullStateChange().pipe(
            map((state) => {
                const sectionIds: string[] = [];
                const doc = state.doc as any;
                if (doc.content) {
                    doc.content.forEach((section) => sectionIds.push(section.attrs.id));
                }
                return sectionIds;
            }),
        );
    }

    getCanvasSections(): Observable<{ id: string; text: string }[]> {
        return this.canvasStateManager.validFullStateChange().pipe(
            map((state) => {
                const sectionIds: { id: string; text: string }[] = [];
                const doc = state.doc as any;
                if (doc.content) {
                    doc.content.forEach((section) =>
                        sectionIds.push({
                            id: section.attrs.id,
                            text: this.getSectionHeaderText(section.content.content[0].toJSON()),
                        }),
                    );
                }
                return sectionIds;
            }),
            distinctUntilChanged((sectionIdsA, sectionIdsB) => {
                if (sectionIdsA.length !== sectionIdsB.length) {
                    return false;
                }
                for (let i = 0; i < sectionIdsA.length; i++) {
                    const sectionBData = sectionIdsB[i];
                    if (!sectionBData) {
                        return false;
                    }
                    if (sectionBData.id !== sectionIdsA[i].id) {
                        return false;
                    }
                    if (sectionBData.text !== sectionIdsA[i].text) {
                        return false;
                    }
                }
                return true;
            }),
        );
    }

    getCanvasSectionsWithReplacedEmptyName(): Observable<{ id: string; text: string }[]> {
        return this.getCanvasSections().pipe(
            map((sections) =>
                sections.map((section) => {
                    const text = section.text ? section.text : SECTION_HEADER_PLACEHOLDER;
                    return { id: section.id, text };
                }),
            ),
        );
    }

    getSectionOrder() {
        const fullState = this.canvasStateManager.getCurrentFullState();
        const sections = getSections(fullState.doc);
        return sections.map((section) => section.node.attrs.id);
    }

    private canvasSectionsTasksEqual(a: CanvasSectionWithTasks[], b: CanvasSectionWithTasks[]): boolean {
        if (a.length === b.length) {
            for (let i = 0; i < a.length; i++) {
                if (a[i].sectionId === b[i].sectionId) {
                    const tasksEquals = this.sectionsObjectsEqual(a[i].tasks, b[i].tasks);
                    if (!tasksEquals) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    private sectionsObjectsEqual(a: string[], b: string[]): boolean {
        if (a.length === b.length) {
            for (let j = 0; j < a.length; j++) {
                if (a[j] !== b[j]) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    private canvasSectionsPollsEqual(a: CanvasSectionWithPolls[], b: CanvasSectionWithPolls[]): boolean {
        if (a.length === b.length) {
            for (let i = 0; i < a.length; i++) {
                if (a[i].sectionId === b[i].sectionId) {
                    const pollsEquals = this.sectionsObjectsEqual(a[i].polls, b[i].polls);
                    if (!pollsEquals) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    getSectionIdOfIndex(index) {
        const fullState = this.canvasStateManager.getCurrentFullState();
        const sections = getSections(fullState.doc);
        if (sections && sections.length && sections.length > index) {
            return sections[index].node.attrs.id;
        }
    }

    moveSectionFromIndexToIndex(sourceIndex: number, targetIndex: number) {
        const fullState = this.canvasStateManager.getCurrentFullState();
        const sections = getSections(fullState.doc);
        if (
            sourceIndex === targetIndex ||
            sourceIndex < 0 ||
            targetIndex < 0 ||
            sourceIndex > sections.length - 1 ||
            targetIndex > sections.length - 1
        ) {
            return;
        }

        const sectionToMove = sections[sourceIndex];
        const targetSection = sections[targetIndex];
        if (sourceIndex > targetIndex) {
            // move up
            const tr = fullState.tr
                .delete(sectionToMove.pos, sectionToMove.pos + sectionToMove.node.nodeSize)
                .insert(targetSection.pos, sectionToMove.node);
            this.canvasService.dispatchTransactionToCanvas(tr);
        } else {
            // move down
            const tr = fullState.tr
                .insert(targetSection.pos + targetSection.node.nodeSize, sectionToMove.node)
                .delete(sectionToMove.pos, sectionToMove.pos + sectionToMove.node.nodeSize);
            this.canvasService.dispatchTransactionToCanvas(tr);
        }
    }

    moveSectionBySteps(sectionId: string, steps: number) {
        const fullState = this.canvasStateManager.getCurrentFullState();
        const sections = getSections(fullState.doc);
        const sourceIndex = sections.findIndex((section) => section.node.attrs.id === sectionId);
        let targetIndex;
        if (sourceIndex + steps > sections.length - 1) {
            targetIndex = sections.length - 1; // out of bounds 0 -> move to start of sections
        } else if (sourceIndex + steps < 0) {
            targetIndex = 0; // out of bounds n > len -> move to end of sections
        } else {
            targetIndex = sourceIndex + steps; // or move by steps
        }
        this.moveSectionFromIndexToIndex(sourceIndex, targetIndex);
    }

    moveSectionUp(sectionId: string) {
        this.moveSectionBySteps(sectionId, -1);
    }

    moveSectionDown(sectionId: string) {
        this.moveSectionBySteps(sectionId, 1);
    }

    isFirstSection(sectionId: string) {
        const sections = getSections(this.canvasStateManager.getCurrentFullState().doc);
        const index = sections.findIndex((section) => section.node.attrs.id === sectionId);

        if (index === 0) {
            return true;
        } else {
            return false;
        }
    }

    isLastSection(sectionId: string) {
        const sections = getSections(this.canvasStateManager.getCurrentFullState().doc);
        const index = sections.findIndex((section) => section.node.attrs.id === sectionId);

        if (index === sections.length - 1) {
            return true;
        } else {
            return false;
        }
    }

    /*
     * returns an observable <Map> with sectionId and the current name of the section
     */
    getCanvasSectionList$(): Observable<Map<string, string>> {
        return this.canvasStateManager.validFullStateChange().pipe(
            debounceTime(50),
            distinctUntilChanged((a, b) => {
                return JSON.stringify(a) === JSON.stringify(b);
            }),
            map((state) => {
                const sectionNameMap: Map<string, string> = new Map();
                const doc = state.doc as any;

                if (doc.content) {
                    doc.content.forEach((sectionContent) => {
                        let headerText = this.getSectionHeaderText(sectionContent.firstChild.toJSON());
                        headerText = headerText === '' ? 'Unnamed Section' : headerText;
                        sectionNameMap.set(sectionContent.attrs.id, headerText);
                    });
                }
                return sectionNameMap;
            }),
        );
    }

    /*
     * returns an observable <string[]> with ids of all sections and system connectors
     */
    getCanvasSectionsWithObjectIdsList$(): Observable<string[]> {
        return this.canvasStateManager.validFullStateChange().pipe(
            debounceTime(50),
            distinctUntilChanged((a, b) => {
                return JSON.stringify(a) === JSON.stringify(b);
            }),
            map((state) => {
                const doc = state.doc as any;
                const sectionNames = [];

                if (doc.content) {
                    doc.content.forEach((sectionContent) => {
                        const sectionContentId = Boolean(sectionContent.attrs.objectId)
                            ? sectionContent.attrs.objectId
                            : sectionContent.attrs.id;

                        sectionNames.push(sectionContentId);
                    });
                }
                return sectionNames;
            }),
        );
    }

    getSectionText(sectionId: string): Observable<string> {
        /** it will not update the text if the variable changes */
        return this.canvasStateManager.validSectionStateChange(sectionId).pipe(
            debounceTime(100),
            map((sectionState) => {
                const sectionHeader = sectionState.doc.firstChild;
                return this.getSectionHeaderText(sectionHeader.toJSON());
            }),
            share(),
        );
    }

    getSectionTextforCollapsedSection(sectionId: string): Observable<{ [key: string]: any }> {
        return combineLatest([
            this.appStoreWrapper.variables(),
            this.canvasStateManager.validSectionStateChange(sectionId),
        ]).pipe(
            map(([v, sectionState]) => {
                return sectionState.doc.firstChild.toJSON();
            }),
            share(),
        );
    }

    getSectionTextWithReplacedEmptyName(sectionId: string): Observable<string> {
        return this.getSectionText(sectionId).pipe(map((title) => (title ? title : SECTION_HEADER_PLACEHOLDER)));
    }

    getSectionHeaderText(sectionHeaderJSON): string {
        if (!sectionHeaderJSON || (sectionHeaderJSON && !sectionHeaderJSON.content)) {
            return '';
        }
        let text = '';
        for (const child of sectionHeaderJSON.content) {
            if (child.type === 'customVariable') {
                text += this.getVariableValue(child.attrs.id);
            } else if (child.type === 'text') {
                text += child.text;
            }
        }
        return text;
    }

    private getVariableValue(variableId: string): string {
        let val;
        this.appStoreWrapper
            .variables()
            .pipe(
                map((variables) => variables.find((v) => v.id === variableId)),
                filter((variable) => !!variable),
                map((variable) => getFormattedTextContent(variable)),
                take(1),
            )
            .subscribe((text) => (val = text));
        return val;
    }

    private state() {
        return this.canvasStateManager.getCurrentFullState();
    }

    private doc() {
        return this.state().doc;
    }

    getCursorPos(sectionId: string): Observable<ResolvedPos> {
        return this.canvasStateManager
            .validSectionStateChange(sectionId)
            .pipe(map((viewState) => viewState.selection.$anchor));
    }
}
