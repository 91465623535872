import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { RuumDropdownItem, RuumDropdownList } from '../../shared/ui-components/dropdown/dropdown.model';
import { fadeDownAnimation } from './../animation/animation-triggers';

@Component({
    selector: 'ruum-team-members',
    template: `
        <ruum-team-members-header
            [title]="title"
            [membersCount]="membersCount"
            [expanded]="expanded"
            [selectedPermissionOption]="selectedPermissionOption"
            [permissionOptions]="permissionOptions"
            [showMembersPermissions]="showMembersPermissions"
            (click)="toggle()"
            [readOnly]="readOnly"
            (enableDefaultPermission)="enableDefaultPermission.emit($event)"
            (selectPermission)="selectPermission.emit($event)"
        >
            <ng-content
                data-content="ruum-task-assignees-dropdown"
                select="[data-content='ruum-task-assignees-dropdown']"
            >
            </ng-content>

            <ng-content data-content="item-contextual-menu" select="[data-content='item-contextual-menu']">
            </ng-content>
        </ruum-team-members-header>
        <div *ngIf="expanded && membersCount > 0" [@fadeDownAnimation] class="d-flex flex-column px-4 pb-4">
            <ng-content select="[data-content='team-members-list']"></ng-content>
            <ng-content select="ruum-load-more"></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeDownAnimation],
})
export class TeamMembersComponent implements OnInit {
    @HostBinding('class')
    hostClassList = 'd-flex flex-fill flex-column minw-0';

    @Input()
    title = '';

    @Input()
    expanded = true;

    @Input()
    readOnly = false;

    @Input()
    membersCount = 0;

    @Input()
    showMembersPermissions = true;

    @Input()
    selectedPermissionOption: RuumDropdownItem;

    @Input()
    permissionOptions: RuumDropdownList[] = [];

    @Output()
    selectPermission = new EventEmitter<string>();

    @Output()
    enableDefaultPermission = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    toggle() {
        this.expanded = !this.expanded;
    }
}
