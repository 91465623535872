import { ProcessEnum, ProcessStep } from '@ruum/ruum-reducers';
import { TransitionObject } from '../../../common/connectors/project/process/process.model';

export function getStepTransitionObject(step: ProcessStep): TransitionObject {
    return {
        id: getObjectId(step),
        type: step.type,
    };
}

function getObjectId(step: ProcessStep): string {
    switch (step.type) {
        case 'approval':
            return step.approvalId;
        case 'canvas':
            return step.sectionId;
        case 'system_connector':
            return step.systemConnectorConfigurationId;
        case 'final':
            return ProcessEnum.FINAL_STEP_ID;
        default:
            throw new Error(`No able to get Transition Object ID for step type '${step.type}'`);
    }
}
