import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../../../common/common.module';
import { CustomFieldModule } from '../../custom-field';
import { SystemConnectorConfigurationSelectModule } from '../system-connector-configuration-select/system-connector-configuration-select.module';
import { SystemConnectorSelectModule } from '../system-connector-select/system-connector-select.module';
import { SystemSelectModule } from '../system-select/system-select.module';
import { SystemConnectorConfigDialogCreateComponent } from './system-connector-config-dialog-create.component';
import { SystemConnectorConfigDialogEditComponent } from './system-connector-config-dialog-edit.component';
import { SystemConnectorDialogCreateComponent } from './system-connector-dialog-create.component';
import { SystemConnectorDialogEditComponent } from './system-connector-dialog-edit.component';
import { SystemConnectorIOItemComponent } from './system-connector-io-item.component';

@NgModule({
    declarations: [
        SystemConnectorIOItemComponent,
        SystemConnectorDialogCreateComponent,
        SystemConnectorDialogEditComponent,
        SystemConnectorConfigDialogCreateComponent,
        SystemConnectorConfigDialogEditComponent,
    ],
    imports: [
        CommonModule,
        RuumCommonModule,
        CustomFieldModule,
        SystemSelectModule,
        SystemConnectorSelectModule,
        SystemConnectorConfigurationSelectModule,
    ],
    exports: [
        SystemConnectorDialogCreateComponent,
        SystemConnectorConfigDialogEditComponent,
        SystemConnectorConfigDialogCreateComponent,
        SystemConnectorConfigDialogEditComponent,
    ],
})
export class SystemConnectorDialogModule {}
