import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-breadcrumb-project-group-item',
    template: `
        <a [routerLink]="['/projectgroups', id]">
            <h3
                class="ruum-breadcrumb-group-name ruum-breadcrumb-item text-truncate text-small text-white-64"
                [title]="name"
            >
                <button class="btn btn-sm btn-icon btn-link-light minw-0 pl-0" aria-label="Navigate to project group">
                    <i class="project-group-grey-inactive"></i>
                </button>
                <span class="align-middle">{{ name }}</span>
            </h3>
        </a>
    `,
})
export class BreadcrumbProjectGroupItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() id: string;

    @Input() name: string;

    constructor() {}

    ngOnInit() {}
}
