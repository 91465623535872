import { Component, HostBinding, Input } from '@angular/core';
import { UnseenInfo, UnseenPanelsInfo } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RuumUIStateService } from '../../../ruum/ui-state/ruumUIState.service';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { Release } from '../../connectors/profile/profile.model';
import { ProfileService } from '../../connectors/profile/profile.service';
// TODO: move UnseenItemsCounter to common
interface UnseenItemsCounter {
    emails: string[];
    attachments: string[];
    team: string[];
    comments: string[];
}

@Component({
    selector: 'navbar-tab-bottom',
    template: `
        <li class="nav-item d-flex flex-column align-items-center justify-content-center flex-fill pt-0">
            <button
                *ngIf="icon"
                class="btn btn-icon py-0"
                type="button"
                [class.btn-link-primary]="active"
                [class.btn-link-light]="!active"
            >
                <i class="icon {{ icon }}"></i>
                <unseen-item-counter [counter]="filterUnseenItems(unseenItemsCountersObs | async, name)">
                </unseen-item-counter>
            </button>

            <button
                type="button"
                class="btn font-weight-bold pb-0"
                [class.btn-link-primary]="active"
                [class.btn-link-light]="!active"
            >
                <span class="tiny">{{ name }}</span>
            </button>
        </li>
    `,
    styles: [
        `
            .nav-item {
                min-height: 56px;
            }
            .btn {
                min-height: 20px;
            }
        `,
    ],
})
export class NavbarTabBottomComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';
    @Input() name = '';
    @Input() icon: string | undefined = '';
    @Input() active = false;

    unseenItemsCountersObs: Observable<UnseenItemsCounter>;
    unassignedMailsCounterObs: Observable<number>;
    releasesObs: Observable<any>;

    mailNumberObs: Observable<number>;
    moreNumberObs: Observable<number>;

    constructor(
        private ruumUIStateService: RuumUIStateService,
        private appStoreWrapper: AppStoreWrapper,
        private profileService: ProfileService,
    ) {
        this.unseenItemsCountersObs = this.ruumUIStateService.allSideMenus().pipe(
            map((panelsInfo: UnseenPanelsInfo) => {
                const newPanelsInfo = {};
                // Theres no 'tasks' in UnseenPanelsInfo interface
                // Removed in ruum-reducer #169
                Object.keys(panelsInfo)
                    .filter((panelId) => panelId !== 'tasks')
                    .forEach((panelId) => {
                        newPanelsInfo[panelId] =
                            panelsInfo[panelId].unseenItemsInfo.filter(
                                (info: UnseenInfo) => info.stillNewCounter > 1,
                            ) || [];
                    });
                return newPanelsInfo as UnseenItemsCounter;
            }),
        );
        this.unassignedMailsCounterObs = this.appStoreWrapper.unassignedMailsCounterForUser();
        this.releasesObs = this.profileService.userUnseenReleases().pipe(
            map((allReleases: Release[]) => {
                return allReleases.reduce((clusteredReleases, release: Release) => {
                    if (!clusteredReleases[release.referencing]) {
                        clusteredReleases[release.referencing] = [];
                    }
                    clusteredReleases[release.referencing].push(release.id);
                    return clusteredReleases;
                }, {});
            }),
        );
        // eslint-disable-next-line import/no-deprecated
        this.mailNumberObs = combineLatest([
            this.unassignedMailsCounterObs,
            this.unseenItemsCountersObs.pipe(
                map((counter) => (counter && counter.emails && counter.emails.length) || 0),
            ),
        ]).pipe(map(([unassignedMail, unseenMail]) => unassignedMail + unseenMail));
        this.moreNumberObs = this.mailNumberObs;
    }

    filterUnseenItems(unseenItemsCounter: UnseenItemsCounter, id: string): number | string {
        let unassignedItemId: string;
        let hasNotification: boolean;
        switch (id) {
            case 'Ruums':
                unassignedItemId = 'projects';
                hasNotification = false;
                break;
            case 'Canvas':
                unassignedItemId = 'canvas';
                hasNotification = false;
                break;
            case 'Team':
                unassignedItemId = 'team';
                hasNotification = true;
                break;
            case 'Files':
                unassignedItemId = 'attachments';
                hasNotification = true;
                break;
            case 'Activity':
                unassignedItemId = 'activity';
                hasNotification = true;
                break;
            case 'Email':
                unassignedItemId = 'email';
                hasNotification = true;
                break;

            case 'Data':
                unassignedItemId = 'data';
                hasNotification = false;
                break;

            case 'More':
                hasNotification = true;
                break;
            default:
                break;
        }
        if (hasNotification && unseenItemsCounter) {
            return typeof unseenItemsCounter[unassignedItemId] !== 'undefined'
                ? unseenItemsCounter[unassignedItemId].length
                : 0;
        }
    }
}
