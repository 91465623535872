import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Day } from '@ruum/ruum-reducers';
import { DatepickerComponent } from '../../ui-components/datepicker/datepicker.component';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-select-project-template-config',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">Create a Ruum from '{{ templateName }}'</h2>
            <div class="d-flex align-items-center">
                <button
                    class="btn btn-lg btn-icon ruum-checkbox"
                    [class.btn-link-secondary]="ignoreTaskDates"
                    [class.btn-link-primary]="!ignoreTaskDates"
                    (click)="toggleIgnoreTaskDates()"
                    ngbAutofocus
                >
                    <i
                        class="icon"
                        [class.icon-checkbox-unchecked]="ignoreTaskDates"
                        [class.icon-checkbox-checked]="!ignoreTaskDates"
                    ></i>
                </button>
                <span class="pl-2">Align task start and due dates</span>
            </div>

            <div class="container mt-5" *ngIf="!ignoreTaskDates">
                <div class="row no-gutters d-flex align-items-center">
                    <div class="col-7 d-flex align-items-center">
                        <span class="pr-2" [style.width.px]="80">Based on</span>
                        <ruum-select [(select)]="selectedOption" [style.height.px]="40">
                            <ruum-select-option
                                *ngFor="let option of options"
                                [value]="option.value"
                                [content]="option.content"
                            ></ruum-select-option>
                        </ruum-select>
                    </div>
                    <div class="col-5 d-flex align-items-center">
                        <span class="px-2">on</span>
                        <div class="input-group">
                            <div class="d-flex" [style.width.px]="0">
                                <ruum-datepicker
                                    #datepicker
                                    [showButton]="false"
                                    [isStartDay]="true"
                                    [date]="datepickerDate"
                                    [placeholder]="''"
                                    fullDateFormat="false"
                                    customDateFormat="WWW. dd mmm"
                                    (dateChanged)="onChange($event)"
                                    (resetDates)="onChange(null)"
                                >
                                </ruum-datepicker>
                            </div>
                            <div
                                class="input-group cursor-pointer hov"
                                (click)="onFocus()"
                                (keydown.space)="onFocus()"
                                (keydown.enter)="onFocus()"
                            >
                                <input
                                    readonly
                                    class="form-control"
                                    type="text"
                                    [placeholder]="'Select a date'"
                                    [value]="date | date: 'mediumDate'"
                                    ruumInputHighlight
                                />
                                <div class="input-group-append" ruumInputHighlight>
                                    <span class="input-group-text text-dark">
                                        <i class="icon icon-date hov-child"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column mt-5">
                <span class="text-small text-secondary">
                    Tasks with start or due dates will be realigned to meet your schedule.
                </span>
            </div>

            <div class="d-flex justify-content-end mt-6">
                <button class="btn btn-lg btn-primary" type="button" [disabled]="false" (click)="createProject()">
                    Create Ruum
                </button>
            </div>
        </ruum-modal-dialog>
    `,
    styles: [
        `
            .ruum-checkbox {
                padding: 0;
                min-width: 0;
                min-height: 0;
                width: 24px;
                height: 24px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProjectTemplateConfigComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([this.componentClass]);
    }

    @Input() templateId: string;
    @Input() templateName: string;
    @Input() componentClass: string;

    @ViewChild('datepicker', { static: false }) datepicker: DatepickerComponent;

    date: Date;
    datepickerDate: Day;

    ignoreAttachments = false;
    ignoreTaskDates = true;
    options = [
        {
            value: 'relativeStartDate',
            content: 'Start date for first task',
        },
        {
            value: 'relativeDueDate',
            content: 'Due date for last task',
        },
    ];
    selectedOption = 'relativeStartDate';

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.date = new Date();
        this.datepickerDate = {
            year: this.date.getFullYear(),
            month: this.date.getMonth() + 1,
            day: this.date.getDate(),
        };
    }

    toggleIgnoreTaskDates(): void {
        this.ignoreTaskDates = !this.ignoreTaskDates;
    }

    createProject(): void {
        this.activeModal.close({
            ignoreAttachments: this.ignoreAttachments,
            ignoreTaskDates: this.ignoreTaskDates,
            relativeStartDate: this.selectedOption === 'relativeStartDate' ? this.datepickerDate : null,
            relativeDueDate: this.selectedOption === 'relativeDueDate' ? this.datepickerDate : null,
        });
    }

    onFocus(): void {
        this.datepicker.openDatepicker();
    }

    onChange(data: Day): void {
        this.date = new Date(data.year, data.month - 1, data.day);
        this.datepickerDate = data;
    }
}
