import { Injectable } from '@angular/core';
import { Observable, Subject, using } from 'rxjs';
import { filter } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WebsocketConnectionService {
    private WEBSOCKET_URL = environment.WEBSOCKET_URL;
    private socket: Socket;

    constructor() {
        this.createSocketConnection();
    }

    listenToDocument(documentId: string): Observable<DocumentChangeWSData> {
        return this.listen<DocumentChangeWSData>(
            'subscribe-to-document',
            'unsubscribe-from-document',
            'new-server-steps',
            documentId,
        ).pipe(filter((data) => data.documentId === documentId));
    }

    listenToEntity(entityId: string): Observable<EntityChangeWSData> {
        return this.listen<EntityChangeWSData>(
            'subscribe-to-entity',
            'unsubscribe-from-entity',
            'entity-changed',
            entityId,
        ).pipe(filter((data) => data.entityId === entityId));
    }

    private listen<T>(
        subscribeEvtName: string,
        unsubscribeEvtName: string,
        changeEvent: string,
        id: string,
    ): Observable<T> {
        this.socket.emit(subscribeEvtName, id);

        const subject = new Subject<T>();

        function listener(data: T) {
            subject.next(data);
        }

        this.socket.on(changeEvent, listener);

        this.socket.on('reconnect', () => {
            this.socket.emit(subscribeEvtName, id);
        });

        return using(
            () => {
                return {
                    /** Removes websocket event listener on 'unsubscribe' */
                    unsubscribe: () => {
                        this.socket.removeListener(changeEvent, listener);
                        this.socket.emit(unsubscribeEvtName, id);
                    },
                };
            },
            () => subject.asObservable(),
        );
    }

    emit(event: string, ...args: any[]) {
        this.socket.emit.apply(this.socket, [event, ...args]);
    }

    /** TODO: send JWT. */
    private createSocketConnection() {
        this.socket = io(this.WEBSOCKET_URL, { upgrade: false, transports: ['websocket', 'polling'] });

        this.socket.on('disconnect', (data) => {
            console.log(`socket ${this.socket.id} disconnected.`, data);
        });

        this.socket.on('connect', () => {
            console.log(`socket ${this.socket.id} connected.`);
        });
    }
}

interface EntityChangeWSData {
    entityId: string;
    version: number;
    clientId: string;
}

interface DocumentChangeWSData {
    documentId: string;
    clientId: string;
}
