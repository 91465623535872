import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getTemplateRole, TemplateParticipant, WorkspaceParticipantRole } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../app.store';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { TemplateListItem } from './template-list.model';

@Injectable({ providedIn: 'root' })
export class SelectedTemplateServiceHelper {
    private participant$: Observable<TemplateParticipant>;

    constructor(
        private authService: AuthService,
        protected store: Store<AppState>,
        private selectedWorkspace: SelectedWorkspaceService,
    ) {
        this.participant$ = combineLatest([
            this.selectedTemplate(),
            this.authService.loggedUserId(),
            this.selectedWorkspace.loggedUserWorkspaceParticipant(),
        ]).pipe(
            map(([template, userId, workspaceParticipant]) => {
                if (!template) {
                    return {
                        id: userId,
                        role: undefined,
                    };
                }
                const workspaceRole = <WorkspaceParticipantRole>(workspaceParticipant && workspaceParticipant.role);
                const role = getTemplateRole(
                    template.participants || [],
                    userId,
                    workspaceRole,
                    template.isGlobalOrEnterpriseTemplate,
                    !!template.publishedProjectId,
                );
                return {
                    id: userId,
                    role,
                } as TemplateParticipant;
            }),
        );
    }

    selectedTemplate(): Observable<TemplateListItem> {
        return this.store.select('selectedTemplate');
    }

    loggedUserTemplateParticipant() {
        return this.participant$;
    }

    participants(): Observable<TemplateParticipant[]> {
        return this.selectedTemplate().pipe(
            filter((template) => !!template),
            map((template) => (template.participants ? template.participants.filter((p) => !p.isTechnicalUser) : [])),
        );
    }

    technicalUsers(): Observable<TemplateParticipant[]> {
        return this.selectedTemplate().pipe(
            filter((template) => !!template && !!template.participants),
            map((template) => template.participants.filter((p) => !!p.isTechnicalUser)),
        );
    }
}
