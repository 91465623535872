import { Component, HostBinding, Input } from '@angular/core';
import { PaywallPrivilege } from '../../../paywall/paywall-privilege';
import { PaywallRejectStrategyData, PaywallRejectStrategyId } from '../../../paywall/paywall-reject-strategy';

@Component({
    selector: '[navbar-tab-top]',
    template: `
        <button
            type="button"
            area-label="Tab"
            [title]="tooltip"
            class="btn px-2 "
            [attr.aria-label]="'Open ' + name"
            [class.btn-link-primary]="active"
            [class.btn-link-light]="!active"
            [requiredPrivilege]="paywallPrivilege"
            [rejectStrategy]="paywallRejectStrategy"
            [rejectStrategyData]="paywallRejectStrategyData"
        >
            <i *ngIf="icon" class="mr-1 icon {{ icon }}"></i>
            <span>{{ name }}</span>
        </button>
        <div class="border-bottom-lg w-100 mt-1" [class.border-primary]="active" [class.border-white]="!active"></div>
    `,
    styles: [
        `
            .border-bottom-lg {
                border-bottom: 2px solid;
            }
        `,
    ],
})
export class NavbarTabTopComponent {
    @HostBinding('class') hostClassList = 'd-block nav-item mr-5 mt-2 d-flex flex-column align-items-center';
    @Input() name = '';
    @Input() icon: string | undefined = '';
    @Input() tooltip?: string;
    @Input() active = false;

    @Input() paywallPrivilege?: PaywallPrivilege;
    @Input() paywallRejectStrategy?: PaywallRejectStrategyId;
    @Input() paywallRejectStrategyData?: PaywallRejectStrategyData;
}
