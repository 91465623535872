import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-load-spinner',
    template: `
        <img [style.width.px]="imageWidth" alt="Loading..." [src]="src" />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSpinnerComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block text-center';

    @Input() size = 'xl';

    @Input() theme: 'default' | 'white' = 'default';

    @Input() classList = '';

    get imageWidth(): number {
        switch (this.size) {
            case 'xs':
                return 16;
            case 'sm':
                return 24;
            case 'md':
                return 32;
            case 'lg':
                return 40;
            case 'xl':
                return 60;
            default:
                return 60;
        }
    }

    get src(): string {
        return this.theme === 'white' ? '/assets/img/spinner-white.svg' : '/assets/img/spinner.svg';
    }

    constructor() {}

    ngOnInit() {
        this.hostClassList = `${this.hostClassList} ${this.classList}`;
    }
}
