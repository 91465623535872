import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APPLICATION_PART } from '../help/help.service';
import { RuumSidepanelService } from '../layout/sidepanel.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { NavbarService } from './navbar.service';

@Component({
    selector: 'ruum-navbar',
    template: `
        <div
            id="ruum-navbar"
            class="bg-white ruum-navbar-top border-bottom ruum-main-nav-panel"
            [class.ruum-main-nav-panel-open]="mainNavPanelOpen$ | async"
            [class.fixed-top]="stickyTop$ | async"
            [style.top.px]="getCommonAlertHeight()"
        >
            <div class="h-100 position-absolute absolute-left" *ngIf="applicationPart === 'group'"></div>
            <div class="ruum-navbar-container">
                <ruum-main-navbar
                    *ngIf="!hidePrimaryNavbar"
                    [applicationPart]="applicationPart"
                    [isHome]="isHome"
                    [isNavbarWithBackButton]="isNavbarWithBackButton"
                    [class.ruum-main-navbar-sidepanel-open]="sidePanelOpen$ | async"
                    [class.ruum-main-navbar-shrinked]="isShrink$ | async"
                >
                    <ng-content select=".navbar-breadcrumb"></ng-content>
                    <ng-content select=".navbar-options"></ng-content>
                    <ng-content select=".navbar-template-controles"></ng-content>
                </ruum-main-navbar>
                <ng-content select=".navbar-secondary-top"></ng-content>
            </div>
        </div>

        <div
            class="ruum-navbar-bottom fixed-bottom border-top d-print-none"
            *ngIf="!isNavbarWithBackButton"
            [class.ruum-navbar-bottom-shrinked]="isShrink$ | async"
        >
            <div class="w-100">
                <ng-content select=".navbar-secondary-bottom"></ng-content>
            </div>
        </div>
    `,
    styles: [
        `
            .border-bottom {
                border-bottom-color: #eeeef0 !important;
            }
            .border-top {
                border-top-color: #eeeef0 !important;
            }
            ruum-main-navbar {
                max-height: 57px;
                transition: max-height 0.15s ease-in-out;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block  position-fixed fixed-top';

    @HostBinding('style.top') backgroundColor = 'auto';

    @Input() applicationPart: APPLICATION_PART;

    @Input() isHome = false;

    @Input() hidePrimaryNavbar = false;

    isNavbarWithBackButton = false;

    stickyTop$: Observable<boolean>;
    isShrink$: Observable<boolean>;
    sidePanelOpen$: Observable<boolean>;
    mainNavPanelOpen$: Observable<boolean>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private navbarService: NavbarService,
        private sidePanelService: RuumSidepanelService,
        private sidenavService: SidenavService,
    ) {}

    ngOnInit(): void {
        this.stickyTop$ = this.navbarService.stickyTop$;
        this.isShrink$ = this.navbarService.isShrink$;
        this.sidePanelOpen$ = this.sidePanelService.isSidepanelOpen$;
        this.mainNavPanelOpen$ = this.sidenavService.isMainNavPanelOpen$;

        this.getActiveTab();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.getActiveTab();
            }
        });
    }

    getActiveTab(): void {
        const sidepanelOutlet = this.route.children.find((item) => item.outlet === 'sidepanel');
        if (sidepanelOutlet) {
            const buttonId = sidepanelOutlet.snapshot.data.buttonId;
            this.isNavbarWithBackButton =
                buttonId === 'task-details' || buttonId === 'comments' || buttonId === 'project-chat';
        } else {
            this.isNavbarWithBackButton = false;
        }
    }

    getCommonAlertHeight(): number {
        return this.navbarService.getCommonAlertHeight();
    }
}
