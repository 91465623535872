import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CanvasCollabService } from '../../../ruum/canvas/canvasCollab.service';
import { LoggerService } from '../../logger.service';
import { RuumAlertService } from '../alert/alert.service';

export interface AppVersion {
    GitCommit: string;
    BuildTag: string;
    BuildTime: string;
}

@Component({
    selector: 'ruum-version-verifier',
    template: ``,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionVerifierComponent implements OnInit, OnDestroy {
    private CURRENT_COMMIT = environment.GIT_COMMIT;
    private VERIFY_INTERVAL = 15 * 60 * 1000;
    private timeout;

    constructor(
        private http: HttpClient,
        private logger: LoggerService,
        private canvasCollabService: CanvasCollabService,
        private alertService: RuumAlertService,
    ) {}

    ngOnInit() {
        this.logger.log(`app version: ${this.CURRENT_COMMIT}`);
        this.guaranteeVersionIsOK();
    }

    guaranteeVersionIsOK() {
        this.verifyVersion().then(() => {
            this.timeout = setTimeout(() => this.guaranteeVersionIsOK(), this.VERIFY_INTERVAL);
        });
    }

    private verifyVersion() {
        return this.getVersion().then((appVersion) => {
            if (appVersion && appVersion.GitCommit && appVersion.GitCommit !== this.CURRENT_COMMIT) {
                console.log(`New version: ${appVersion.GitCommit}`);
                this.forcePageRefresh();
            } else {
                this.logger.log(`App version is the latest `, JSON.stringify(appVersion));
            }
        });
    }

    private getVersion(): Promise<AppVersion> {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
        });
        return this.http
            .get<AppVersion>('/version.json', { headers })
            .toPromise()
            .catch((err) => {
                this.logger.error(`error getting app version`, err);
                return undefined;
            });
    }

    private forcePageRefresh() {
        this.canvasCollabService
            .isSendingData()
            .pipe(
                filter((isSending) => !isSending),
                take(1),
            )
            .subscribe(() => {
                this.alertService
                    .info({
                        actionText: 'Refresh',
                        noCancel: true,
                        options: {
                            size: 'lg',
                        },
                        title: 'There is a new version of the application. Please refresh to continue.',
                        autoClose: 120000,
                    })
                    .then(
                        () => window.location.reload(),
                        () => window.location.reload(),
                    );
            });
    }

    ngOnDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}
