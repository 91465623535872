import { style, keyframes } from '@angular/animations';

const translateValue = '100%';
const translateMediumValue = '60px';
const translateSmallValue = '24px';

/****** Width/Height Animation Keyframes ******/
export const heightIn = keyframes([style({ height: 0 }), style({ height: '*' })]);
// TODO: angulat-cli: fix keyframes below
// export const heightOut = keyframes([style({ height: '*' }), style({ height: 0 })]);
export const heightOut = keyframes([style({ height: 100 }), style({ height: 0 })]);

export const widthIn = keyframes([style({ width: 0 }), style({ width: '*' })]);
// TODO: angulat-cli: fix keyframes below
// export const widthOut = keyframes([style({ width: '*' }), style({ width: 0 })]);
export const widthOut = keyframes([style({ width: 100 }), style({ width: 0 })]);

/****** Fade Animation Keyframes ******/
export const fadeIn = keyframes([style({ opacity: 0 }), style({ opacity: 1 })]);
export const fadeInDown = keyframes([
    style({ opacity: 0, transform: `translate3d(0, -${translateValue}, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInDownMedium = keyframes([
    style({ opacity: 0, transform: `translate3d(0, -${translateMediumValue}, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInDownSmall = keyframes([
    style({ opacity: 0, transform: `translate3d(0, -${translateSmallValue}, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInUp = keyframes([
    style({ opacity: 0, transform: `translate3d(0, ${translateValue}, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInUpMedium = keyframes([
    style({ opacity: 0, transform: `translate3d(0, ${translateMediumValue}, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInUpSmall = keyframes([
    style({ opacity: 0, transform: `translate3d(0, ${translateSmallValue}, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInLeft = keyframes([
    style({ opacity: 0, transform: `translate3d(-${translateValue}, 0, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInLeftMedium = keyframes([
    style({ opacity: 0, transform: `translate3d(-${translateMediumValue}, 0, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInLeftSmall = keyframes([
    style({ opacity: 0, transform: `translate3d(-${translateSmallValue}, 0, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInRight = keyframes([
    style({ opacity: 0, transform: `translate3d(${translateValue}, 0, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInRightMedium = keyframes([
    style({ opacity: 0, transform: `translate3d(${translateMediumValue}, 0, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeInRightSmall = keyframes([
    style({ opacity: 0, transform: `translate3d(${translateSmallValue}, 0, 0)` }),
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
]);
export const fadeOut = keyframes([style({ opacity: 1 }), style({ opacity: 0 })]);
export const fadeOutDown = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(0, -${translateValue}, 0)` }),
]);
export const fadeOutDownMedium = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(0, -${translateMediumValue}, 0)` }),
]);
export const fadeOutDownSmall = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(0, -${translateSmallValue}, 0)` }),
]);
export const fadeOutUp = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(0, ${translateValue}, 0)` }),
]);
export const fadeOutUpMedium = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(0, ${translateMediumValue}, 0)` }),
]);
export const fadeOutUpSmall = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(0, ${translateSmallValue}, 0)` }),
]);
export const fadeOutLeft = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(-${translateValue}, 0, 0)` }),
]);
export const fadeOutLeftMedium = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(-${translateMediumValue}, 0, 0)` }),
]);
export const fadeOutLeftSmall = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(-${translateSmallValue}, 0, 0)` }),
]);
export const fadeOutRight = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(${translateValue}, 0, 0)` }),
]);
export const fadeOutRightMedium = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(${translateMediumValue}, 0, 0)` }),
]);
export const fadeOutRightSmall = keyframes([
    style({ opacity: 1, transform: `translate3d(0, 0, 0)` }),
    style({ opacity: 0, transform: `translate3d(${translateSmallValue}, 0, 0)` }),
]);

/****** Slide Animation Keyframes ******/
export const slideInDown = keyframes([
    style({ transform: `translate3d(0, -${translateValue}, 0)`, visibility: 'visible' }),
    style({ transform: `translate3d(0, 0, 0)` }),
]);
export const slideInUp = keyframes([
    style({ transform: `translate3d(0, ${translateValue}, 0)`, visibility: 'visible' }),
    style({ transform: `translate3d(0, 0, 0)` }),
]);
export const slideInLeft = keyframes([
    style({ transform: `translate3d(-${translateValue}, 0, 0)`, visibility: 'visible' }),
    style({ transform: `translate3d(0, 0, 0)` }),
]);
export const slideInRight = keyframes([
    style({ transform: `translate3d(${translateValue}, 0, 0)`, visibility: 'visible' }),
    style({ transform: `translate3d(0, 0, 0)` }),
]);
export const slideOutDown = keyframes([
    style({ transform: `translate3d(0, 0, 0)` }),
    style({ transform: `translate3d(0, ${translateValue}, 0)`, visibility: 'hidden' }),
]);
export const slideOutUp = keyframes([
    style({ transform: `translate3d(0, 0, 0)` }),
    style({ transform: `translate3d(0, -${translateValue}, 0)`, visibility: 'hidden' }),
]);
export const slideOutLeft = keyframes([
    style({ transform: `translate3d(0, 0, 0)` }),
    style({ transform: `translate3d(-${translateValue}, 0, 0)`, visibility: 'hidden' }),
]);
export const slideOutRight = keyframes([
    style({ transform: `translate3d(0, 0, 0)` }),
    style({ transform: `translate3d(${translateValue}, 0, 0)`, visibility: 'hidden' }),
]);
