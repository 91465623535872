import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    NgModule,
    OnInit,
    Output,
} from '@angular/core';
import { RuumDropdownItem, RuumDropdownPlacement } from '../../../shared/ui-components/dropdown/dropdown.model';
import { DropdownModule } from '../../../shared/ui-components/dropdown/dropdown.module';
import { MenuModule } from '../../../shared/ui-components/menu/menu.module';

@Component({
    selector: 'ruum-task-list-item-options',
    template: `
        <ruum-menu>
            <ruum-menu-trigger>
                <button class="btn btn-icon" aria-label="Show task related options">
                    <i class="icon icon-contextual-menu"></i>
                </button>
            </ruum-menu-trigger>
            <ruum-menu-option icon="icon-delete" (onClick)="delete($event)" theme="danger">
                Delete {{ this.isMilestone ? 'Milestone' : 'Task' }}
            </ruum-menu-option>
        </ruum-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskListItemOptionsComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill align-items-center';

    @Input()
    placement: RuumDropdownPlacement = ['bottom-right', 'top-right', 'auto'];

    @Input()
    isMilestone = false;

    @Output()
    deleteTask = new EventEmitter<RuumDropdownItem>();

    ngOnInit() {}

    delete(event: Event) {
        this.deleteTask.emit({
            id: 'DELETE_TASK',
            name: 'Delete ' + (this.isMilestone ? 'Milestone' : 'Task'),
            theme: 'danger',
        });
    }
}

@NgModule({
    declarations: [RuumTaskListItemOptionsComponent],
    imports: [CommonModule, DropdownModule, MenuModule],
    exports: [RuumTaskListItemOptionsComponent],
})
export class RuumTaskListItemOptionsModule {}
