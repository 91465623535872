import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentTheme } from '../../../shared/ui-components/ui-components.type';

@Component({
    selector: 'ruum-breadcrumb-workspace-item',
    template: `
        <a [routerLink]="['/workspaces', id]">
            <h3 class="ruum-breadcrumb-item text-truncate  text-{{ theme }} text-small py-2" [title]="name">
                <span class="align-middle">{{ name }}</span>
            </h3>
        </a>
    `,
})
export class BreadcrumbWorkspaceItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() id: string;

    @Input() name: string;

    @Input() theme: ComponentTheme = 'white-64';

    constructor() {}

    ngOnInit() {}
}
