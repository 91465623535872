import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuDeviderComponent } from './menu-devider/menu-devider.component';
import { MenuOptionDescriptionComponent } from './menu-option-description/menu-option-description.component';
import { MenuOptionComponent } from './menu-option/menu-option.component';
import { MenuTriggerComponent } from './menu-trigger/menu-trigger.component';
import { MenuComponent } from './menu.component';

@NgModule({
    declarations: [
        MenuComponent,
        MenuOptionComponent,
        MenuTriggerComponent,
        MenuDeviderComponent,
        MenuOptionDescriptionComponent,
    ],
    imports: [CommonModule, OverlayModule, LayoutModule],
    exports: [
        MenuComponent,
        MenuOptionComponent,
        MenuTriggerComponent,
        MenuDeviderComponent,
        MenuOptionDescriptionComponent,
    ],
})
export class MenuModule {}
