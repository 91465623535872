import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { PaginationParams } from '../../common/connectors/lobbyList/lobby-list.model';
import { OrderedListParams, PaginatedList } from '../../common/connectors/paginatedList.model';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { getQueryString } from '../../common/connectors/readModelConnector.service';
import { WebhookEntityEventsOrderBy } from './entities/events/webhook-entity-events-list.service';
import { WebhookEntitiesOrderBy } from './entities/webhook-entities-list.service';
import { WebhookConfigurationOrderBy } from './webhook-list.service';
import {
    WebhookConfiguration,
    WebhookConfigurationListItem,
    WebhookEntityEventListItem,
    WebhookEntitySyncStateListItem,
} from './webhooks.model';

export interface WebhookConfigurationFilters {
    enterpriseId: string;
}

export interface WebhookEntityStateFilters {
    webhookId: string;
}

export interface WebhookEntityEventFilters {
    webhookId: string;
    entityId: string;
}

@Injectable()
export class WebhooksConnector {
    private API: string = environment.PROJECT_SERVICE_URL;

    constructor(
        private http: HttpClient,
        private profileService: ProfileService,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    getWebhookConfigurations(
        pagination: PaginationParams,
        filters: WebhookConfigurationFilters,
        orderBy: OrderedListParams<WebhookConfigurationOrderBy>,
    ): Observable<PaginatedList<WebhookConfiguration>> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        const query = getQueryString(pagination, filters, orderBy);
        return this.http.get<PaginatedList<WebhookConfiguration>>(
            `${this.API}/v1/enterprises/${enterpriseId}/webhooks${query}`,
            {
                withCredentials: true,
            },
        );
    }
    getWebhookConfigurationByKeys(id: string, webhookId: string): Observable<WebhookConfigurationListItem> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http.get<WebhookConfigurationListItem>(
            `${this.API}/v1/enterprises/${enterpriseId}/webhooks/${webhookId}`,
            {
                withCredentials: true,
            },
        );
    }

    createWebhook(data: WebhookConfiguration): Observable<WebhookConfiguration> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http.post<WebhookConfiguration>(`${this.API}/v1/enterprises/${enterpriseId}/webhooks`, data, {
            withCredentials: true,
        });
    }

    updateWebhook(data: WebhookConfiguration): Observable<WebhookConfiguration> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http.put<WebhookConfiguration>(
            `${this.API}/v1/enterprises/${enterpriseId}/webhooks/${data.id}`,
            data,
            { withCredentials: true },
        );
    }

    getSyncedEntities(
        pagination: PaginationParams,
        filters: WebhookEntityStateFilters,
        orderBy: OrderedListParams<WebhookEntitiesOrderBy>,
    ): Observable<PaginatedList<WebhookEntitySyncStateListItem>> {
        filters = { ...filters };
        const webhookId = filters.webhookId;
        delete filters.webhookId;

        orderBy = {
            by: 'currentState',
            direction: 'desc',
        };

        const query = getQueryString(pagination, filters, orderBy);
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http.get<PaginatedList<any>>(
            `${this.API}/v1/enterprises/${enterpriseId}/webhooks/${webhookId}/entities${query}`,
            {
                withCredentials: true,
            },
        );
    }

    getSyncedEntityEvents(
        pagination: PaginationParams,
        filters: WebhookEntityEventFilters,
        orderBy: OrderedListParams<WebhookEntityEventsOrderBy>,
    ): Observable<PaginatedList<WebhookEntityEventListItem>> {
        filters = { ...filters };
        const webhookId = filters.webhookId;
        const entityId = filters.entityId;

        delete filters.webhookId;
        delete filters.entityId;

        orderBy = {
            by: 'entityVersion',
            direction: 'desc',
        };

        const query = getQueryString(pagination, filters, orderBy);

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http.get<PaginatedList<any>>(
            `${this.API}/v1/enterprises/${enterpriseId}/webhooks/${webhookId}/entities/${entityId}/events${query}`,
            { withCredentials: true },
        );
    }

    getEntityState(webhookId: string, objectId: string): Observable<WebhookEntitySyncStateListItem> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http.get<WebhookEntitySyncStateListItem>(
            `${this.API}/v1/enterprises/${enterpriseId}/webhooks/${webhookId}/entities/${objectId}`,
            { withCredentials: true },
        );
    }

    delete(data: WebhookConfiguration): Promise<any> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http
            .delete<any>(`${this.API}/v1/enterprises/${enterpriseId}/webhooks/${data.id}`, {
                withCredentials: true,
            })
            .toPromise();
    }

    retry(webhookId: string, objectId: string, fromVersion: number, skip?: boolean): Promise<any> {
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.http
            .post<any>(
                `${this.API}/v1/enterprises/${enterpriseId}/webhooks/${webhookId}/entities/${objectId}/events/${fromVersion}/retry`,
                {
                    fromVersion,
                    skip,
                },
                { withCredentials: true },
            )
            .toPromise();
    }
}
