import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SelectMemberOptionComponent } from './select-member-option/select-member-option.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectRoleOptionComponent } from './select-role-option/select-role-option.component';
import { RuumSelectData } from './select.model';

type SelectOption = SelectOptionComponent | SelectMemberOptionComponent | SelectRoleOptionComponent;
@Injectable()
export class SelectService {
    readonly onSelectOptionEvent$: Observable<MouseEvent | KeyboardEvent>;
    readonly hasSelectedOptions$: Observable<boolean>;
    readonly selectedOptionIds$: Observable<string[]>;
    readonly selectedOptionsContent$: Observable<RuumSelectData[]>;
    readonly selectedOptionsCount$: Observable<number>;
    readonly isMultiSelect$: Observable<boolean>;
    readonly search$: Observable<string>;

    private _onSelectOptionEvent$ = new Subject<MouseEvent | KeyboardEvent>();
    private _hasSelectedOptions$ = new BehaviorSubject<boolean>(false);
    private _selectedOptionIds$ = new BehaviorSubject<string[]>([]);
    private _selectedOptionsContent$ = new BehaviorSubject<RuumSelectData[]>([]);
    private _selectedOptionsCount$ = new BehaviorSubject<number>(0);
    private _isMultiSelect$ = new BehaviorSubject<boolean>(false);
    private _search$ = new Subject<string>();

    private selectedOptionsMap = new Map<string, string>();
    private selectedOptionsElementsMap = new Map<string, SelectOption>();
    private selectedOptionsElementsList: SelectOption[] = [];
    private focusData: { index: number; item: SelectOption } = null;

    constructor() {
        this.onSelectOptionEvent$ = this._onSelectOptionEvent$.asObservable();
        this.hasSelectedOptions$ = this._hasSelectedOptions$.asObservable();
        this.selectedOptionIds$ = this._selectedOptionIds$.asObservable();
        this.selectedOptionsCount$ = this._selectedOptionsCount$.asObservable();
        this.selectedOptionsContent$ = this._selectedOptionsContent$.asObservable();
        this.isMultiSelect$ = this._isMultiSelect$.asObservable();
        this.search$ = this._search$.asObservable();
    }

    onEvent(event: MouseEvent | KeyboardEvent) {
        this._onSelectOptionEvent$.next(event);
    }

    setSelect(selectedId: string, content: string) {
        if (this.selectedOptionsMap.has(selectedId)) {
            return;
        }
        const isMultiSelect = this._isMultiSelect$.value;
        if (isMultiSelect) {
            const selectedOptionsCount = this._selectedOptionsCount$.value;
            this._selectedOptionsCount$.next(selectedOptionsCount + 1);
            this._hasSelectedOptions$.next(true);
        } else {
            // on Single Select
            this._selectedOptionsCount$.next(1);
            this._hasSelectedOptions$.next(true);
            this.unSelectAllOptions();
        }
        this.selectedOptionsMap.set(selectedId, content);
        if (this.selectedOptionsElementsMap.has(selectedId)) {
            const element = this.selectedOptionsElementsMap.get(selectedId);
            element.selected = true;
        }
        this.puplishSelectedData();
    }

    setUnSelect(selectedId: string) {
        if (!this.selectedOptionsMap.has(selectedId)) {
            return;
        }
        this.selectedOptionsMap.delete(selectedId);
        if (this.selectedOptionsElementsMap.has(selectedId)) {
            const element = this.selectedOptionsElementsMap.get(selectedId);
            element.selected = false;
        }
        const isMultiSelect = this._isMultiSelect$.value;
        if (isMultiSelect) {
            const selectedOptionsCount = this._selectedOptionsCount$.value;
            this._selectedOptionsCount$.next(selectedOptionsCount - 1);
            const hasSelectedOption = selectedOptionsCount - 1 > 0;
            this._hasSelectedOptions$.next(hasSelectedOption);
            this.puplishSelectedData();
        } else {
            // on Single Select
            this._hasSelectedOptions$.next(false);
            this._selectedOptionsCount$.next(0);
            this.unSelectAllOptions();
        }
    }

    isSelected(selectedId: string): boolean {
        return this.selectedOptionsMap.has(selectedId);
    }

    updateContent(selectedId: string, content: string) {
        if (!this.selectedOptionsMap.has(selectedId)) {
            return;
        }
        this.selectedOptionsMap.set(selectedId, content);
        this.puplishSelectedData();
    }

    setIsMultiSelect(isMultiSelect: boolean) {
        this._isMultiSelect$.next(isMultiSelect);
    }

    registerOption(value: string, element: SelectOption) {
        if (this.selectedOptionsElementsMap.has(value)) {
            // option with same value
            console.log('two options with same value in select');
        }
        this.selectedOptionsElementsMap.set(value, element);
        this.selectedOptionsElementsList = Array.from(this.selectedOptionsElementsMap.values());
    }

    unRegisterOption(value: string) {
        if (this.selectedOptionsElementsMap.has(value)) {
            this.selectedOptionsElementsMap.delete(value);
        }
    }

    removeLastItem() {
        const ids = this._selectedOptionIds$.value;
        if (ids.length > 0) {
            const lastId = ids[ids.length - 1];
            this.setUnSelect(lastId);
        }
    }

    selectFirstItem() {
        for (const element of this.selectedOptionsElementsList) {
            if (element.isVisible) {
                if (element.disabled) {
                    return;
                }
                this.setSelect(element.value, element.content);
                return;
            }
        }
    }

    focusNextItem() {
        const lastIndex = this.focusData ? this.focusData.index : 0;
        for (let i = lastIndex; i < this.selectedOptionsElementsList.length; i++) {
            const element = this.selectedOptionsElementsList[i];
            if (element.isVisible && !element.disabled) {
                if (this.focusData && this.focusData.index === i) {
                    continue;
                }
                this.focusData = { index: i, item: element };
                element.focusElement();
                return;
            }
        }
    }

    focusPrevItem() {
        if (!this.focusData) {
            this.focusNextItem();
            return;
        }
        const lastIndex = this.focusData.index;
        for (let i = lastIndex; i >= 0; i--) {
            const element = this.selectedOptionsElementsList[i];
            if (element.isVisible && !element.disabled) {
                if (this.focusData && this.focusData.index === i) {
                    continue;
                }
                this.focusData = { index: i, item: element };
                element.focusElement();
                return;
            }
        }
    }

    reset() {
        this.unSelectAllOptions();
        this._hasSelectedOptions$.next(false);
        this._selectedOptionsCount$.next(0);
        this._selectedOptionIds$.next([]);
        this._selectedOptionsContent$.next([]);
        this.focusData = null;
    }

    setSearch(search: string) {
        this.focusData = null;
        this._search$.next(search);
    }

    setOptionsByData(data: string | string[]) {
        this.unSelectAllOptions();
        if (!data) {
            this._selectedOptionsCount$.next(0);
            this._hasSelectedOptions$.next(false);
            return;
        }
        if (Array.isArray(data)) {
            this._selectedOptionsCount$.next(data.length);
            this._hasSelectedOptions$.next(data.length > 0);
            this._selectedOptionIds$.next(data);
            const optionContent: RuumSelectData[] = [];
            for (const value of data) {
                const contentItem: RuumSelectData = { value, content: '' };
                const element = this.selectedOptionsElementsMap.get(value);
                if (element) {
                    element.selected = true;
                    contentItem.content = element.content;
                    contentItem.description = element.description;
                    contentItem.icon = element.icon;
                    contentItem.type = element.optionType;
                    contentItem.theme = element.theme;
                }
                optionContent.push(contentItem);
                this.selectedOptionsMap.set(contentItem.value, contentItem.content);
            }
            this._selectedOptionsContent$.next(optionContent);
        } else {
            this._selectedOptionsCount$.next(1);
            this._hasSelectedOptions$.next(true);
            this._selectedOptionIds$.next([data]);
            const element = this.selectedOptionsElementsMap.get(data);
            const contentItem: RuumSelectData = { value: data, content: '' };
            if (element) {
                element.selected = true;
                contentItem.content = element.content;
                contentItem.description = element.description;
                contentItem.icon = element.icon;
                contentItem.type = element.optionType;
                contentItem.theme = element.theme;
            }
            this.selectedOptionsMap.set(contentItem.value, contentItem.content);
            this._selectedOptionsContent$.next([contentItem]);
        }
    }

    private unSelectAllOptions() {
        this.selectedOptionsMap.clear();
        for (const element of this.selectedOptionsElementsMap.values()) {
            element.selected = false;
        }
    }

    private puplishSelectedData() {
        const ids = this.selectedOptionsMap.keys();
        this._selectedOptionIds$.next(Array.from(this.selectedOptionsMap.keys()));
        const optionContent: RuumSelectData[] = [];
        for (const id of ids) {
            optionContent.push({ value: id, content: this.selectedOptionsMap.get(id) });
        }
        this._selectedOptionsContent$.next(optionContent);
    }
}
