import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../../../common/common.module';
import { ExternalSystemDialogComponent } from './external-system-dialog.component';

@NgModule({
    declarations: [ExternalSystemDialogComponent],
    imports: [CommonModule, RuumCommonModule],
    exports: [ExternalSystemDialogComponent],
})
export class ExternalSystemDialogModule {}
