import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Commentable, CommentableType } from '@ruum/ruum-reducers';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { componentHelper } from '../ui-components/ui-components.helper';
import { ComponentTheme, ComponentType } from '../ui-components/ui-components.type';
import { ShowCommentService } from './showComment.service';

@Component({
    selector: 'show-comments-button',
    template: `
        <div
            (click)="!disabled && showComments()"
            data-help-id="section_comments"
            (keydown.space)="!disabled && showComments()"
            (keydown.enter)="!disabled && showComments()"
        >
            <button
                type="button"
                class="btn btn-sm btn-icon"
                [class.hov-child]="hideWhenEmpty"
                [class.hov-active]="hasComments"
                [ngClass]="buttonClass"
                aria-label="Show comments"
                [disabled]="disabled"
            >
                <i class="icon icon-comment"></i>
            </button>
            <span
                *ngIf="hasUnseen"
                class="badge-container text-tiny font-weight-bold badge badge-info badge-pill text-white"
                >{{ unseenCommentsCounter }}
            </span>
        </div>
    `,
    styles: [
        `
            :host {
                position: relative;
                display: block;
                width: 32px;
                height: 32px;
            }

            .badge-container {
                position: relative;
                top: -36px;
                left: 16px;
                min-width: 16px;
                height: 16px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ShowCommentsComponent implements OnInit, OnDestroy {
    // @HostBinding('class') get hostClassList() {
    //     return componentHelper.transformClassNameArrayToString(['d-flex', this.componentClass]);
    // }

    @Input() commentableId: string;
    @Input() commentableType: CommentableType;
    @Input() hideWhenEmpty = true;
    @Input() theme: ComponentTheme = 'secondary';
    @Input() type: ComponentType = 'link';
    @Input() componentClass = '';
    @Input() disabled = false;

    hasUnseen = false;
    unseenCommentsCounter = 0;
    hasComments = false;
    commentsCounter = 0;

    subscriptions: Subscription[] = [];

    constructor(
        private ruumCommentsService: ShowCommentService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        /** For some reason using Observable was not triggering change detection every time */
        this.handleCommentableChange();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    get buttonClass() {
        const theme = this.hasComments
            ? componentHelper.getThemeClassName('secondary', this.type)
            : componentHelper.getThemeClassName(this.theme, this.type);
        return [theme];
    }

    showComments() {
        this.ruumCommentsService.showCommentsOfObject(this.commentableId, this.commentableType);
    }

    private handleCommentableChange() {
        this.subscriptions.push(
            this.ruumCommentsService
                .commentableWithId(this.commentableId, this.commentableType)
                .pipe(filter((commentable) => !!commentable))
                .subscribe((commentable) => {
                    this.unseenCommentsCounter = this.getUnseenCounter(commentable);
                    this.hasUnseen = this.unseenCommentsCounter > 0;
                    this.commentsCounter = commentable.comments.length;
                    this.hasComments = this.commentsCounter > 0;

                    this.cdr.detectChanges();
                }),
        );
    }

    private getUnseenCounter(commentable: Commentable) {
        const loggedUserLastSeenAt = commentable.commentsSeenInfo[this.authService.getLoggedUserId()] || 0;
        return commentable.comments.filter((comment) => comment.createdAt > loggedUserLastSeenAt).length;
    }
}
