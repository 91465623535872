import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { ProjectMoveToGroupComponent } from './project-move-to-group.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalDialogModule],
    declarations: [ProjectMoveToGroupComponent],
    exports: [ProjectMoveToGroupComponent],
})
export class ProjectMoveToGroupModule {}
