import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SavedViewColumnDefinition } from './saved-views.model';

@Injectable({ providedIn: 'root' })
export class SavedViewColumnsService {
    getColumnsByType(typeIds): Observable<SavedViewColumnDefinition[]> {
        return combineLatest([this.getColumns(typeIds)]).pipe(map(([columns]) => columns.filter((el) => !!el)));
    }

    getColumnsMapByType(typeIds): Observable<{ [key: string]: SavedViewColumnDefinition }> {
        return combineLatest([this.getColumns(typeIds)]).pipe(
            map(([columns]) => {
                return columns
                    .filter((el) => !!el)
                    .reduce((state, column) => {
                        return {
                            ...state,
                            [column.typeId]: column,
                        };
                    }, {});
            }),
        );
    }

    getColumns(typeIds): Observable<SavedViewColumnDefinition[]> {
        const entities = this.entities();
        const list = typeIds.map((typeId) => entities[typeId]);
        return of(list);
    }

    getColumnsMap(): Observable<{ [key: string]: SavedViewColumnDefinition }> {
        return of(this.entities());
    }

    private entities(): { [key: string]: SavedViewColumnDefinition } {
        const headerPadding = 12 + 2;
        const list = [
            {
                typeId: 'lobbyStatus',
                mandatory: true,
                minWidth: 90 + headerPadding,
                initialWidth: 102,
                unresizable: true,
                icon: 'icon-tasks',
            },
            {
                typeId: 'favorite',
                mandatory: true,
                minWidth: 80,
                initialWidth: 80,
                unresizable: true,
                icon: 'icon-fav',
            },
            {
                typeId: 'comments',
                mandatory: true,
                minWidth: 68,
                initialWidth: 68,
                unresizable: true,
                icon: 'icon-comment',
            },
            {
                typeId: 'lobbyName',
                mandatory: true,
                minWidth: 100,
                initialWidth: 800,
                icon: 'icon-text',
            },
            {
                typeId: 'changedAt',
                mandatory: false,
                minWidth: 120 + headerPadding,
                initialWidth: 150,
                icon: 'icon-activity',
            },
            {
                typeId: 'createdBy',
                mandatory: false,
                minWidth: 120 + headerPadding,
                initialWidth: 200,
                icon: 'icon-user',
            },
            {
                typeId: 'requestedBy',
                mandatory: false,
                minWidth: 120 + headerPadding,
                initialWidth: 200,
                icon: 'icon-user',
            },
            {
                typeId: 'tags',
                mandatory: false,
                minWidth: 110 + headerPadding,
                initialWidth: 128,
                icon: 'icon-label',
            },
            {
                typeId: 'workspace',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-workspace',
            },
            {
                typeId: 'taskStatus',
                mandatory: true,
                minWidth: 100,
                initialWidth: 100,
                unresizable: true,
                icon: 'icon-tasks',
            },
            {
                typeId: 'taskName',
                mandatory: true,
                minWidth: 150,
                initialWidth: 400,
                icon: 'icon-text',
            },
            {
                typeId: 'section',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 300,
                icon: 'icon-canvas',
            },
            {
                typeId: 'project',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 300,
                icon: 'icon-canvas',
            },
            {
                typeId: 'group',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-folder',
            },
            {
                typeId: 'subtasks',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-subtask',
            },
            {
                typeId: 'priority',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 102,
                icon: 'icon-priority-outlined',
            },
            {
                typeId: 'startDate',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-date',
            },
            {
                typeId: 'dueDate',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-date',
            },
            {
                typeId: 'assignees',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-assign-task',
            },
            {
                typeId: 'roles',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-role',
            },
            {
                typeId: 'string',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-text',
            },
            {
                typeId: 'long_string',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 150,
                icon: 'icon-text',
            },
            {
                typeId: 'number',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 150,
                icon: 'icon-hashtag',
            },
            {
                typeId: 'email',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-email',
            },
            {
                typeId: 'link',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-link',
            },
            {
                typeId: 'date',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-date',
            },
            {
                typeId: 'phone',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-phone',
            },
            {
                typeId: 'selection_list',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-poll',
            },
            {
                typeId: 'record',
                mandatory: false,
                minWidth: 90 + headerPadding,
                initialWidth: 160,
                icon: 'icon-record-lookup',
            },
            {
                typeId: 'approvalStatus',
                mandatory: true,
                minWidth: 200 + headerPadding,
                initialWidth: 200,
                icon: 'icon-tasks',
            },
            {
                typeId: 'approver',
                mandatory: true,
                minWidth: 150 + headerPadding,
                initialWidth: 250,
                icon: 'icon-assign-task',
            },
            {
                typeId: 'approvalActions',
                mandatory: true,
                minWidth: 150 + headerPadding,
                initialWidth: 150,
                icon: 'icon-thumbs-up-down',
            },
            {
                typeId: 'createdOn',
                mandatory: false,
                minWidth: 150 + headerPadding,
                initialWidth: 150,
                icon: 'icon-date',
            },
        ];

        return list.reduce((state, item) => {
            return {
                ...state,
                [item.typeId]: item,
            };
        }, {});
    }
}
