import { Component, HostBinding, Input } from '@angular/core';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import { UserListItem } from '../../../common/connectors/readModelConnector.service';
import { AssignedItem } from './assigned-item-list.component';

type DefaultButtonTheme = 'link' | 'outline' | 'none';
@Component({
    selector: 'ruum-task-assignees-button',
    template: `
        <div class="d-flex flex-fill w-100">
            <ng-container *ngIf="assignedUsers.length === 0 && assignedRoles.length === 0">
                <button
                    class="btn btn-sm"
                    type="button"
                    [class.btn-outline-light]="defaultButtonTheme === 'outline'"
                    [class.btn-link-light]="defaultButtonTheme === 'link'"
                    [class.d-none]="defaultButtonTheme === 'none'"
                >
                    <span>Assign</span>
                </button>
            </ng-container>

            <ruum-assigned-item-list
                *ngIf="assignedUsers.length !== 0"
                [assignees]="assignedUserItems"
                [maxShownAssignees]="maxShownAssignees"
                [showFullNameOfFirstAssignee]="showFullNameOfFirstAssignee"
                [avatarSize]="avatarSize"
                [isRole]="false"
            ></ruum-assigned-item-list>

            <ruum-assigned-item-list
                *ngIf="assignedRoles.length !== 0"
                [assignees]="assignedRoleItems"
                [maxShownAssignees]="maxShownAssignees"
                [showFullNameOfFirstAssignee]="showFullNameOfFirstAssignee"
                [avatarSize]="avatarSize"
                [isRole]="true"
            ></ruum-assigned-item-list>
        </div>
        <button *ngIf="showAddUserIcons" class="btn btn-sm btn-icon btn-link-secondary p-1" type="button">
            <i class="icon icon-add-user"></i>
        </button>
    `,
})
export class TaskAssigneesButtonComponent {
    @HostBinding('class') class = 'd-flex flex-fill justify-content-between w-100';

    @Input() maxShownAssignees = 5;
    @Input() showFullNameOfFirstAssignee = true;
    @Input() defaultButtonTheme: DefaultButtonTheme = 'link';
    @Input() avatarSize: string;
    @Input() showAddUserIcons = false;

    @Input()
    set assignedUsers(assignedUsers: UserListItem[]) {
        this._assignedUsers = assignedUsers;
        if (assignedUsers) {
            this.assignedUserItems = assignedUsers.map((user) => ({
                id: user.id,
                initials: user.initials,
                name: user.fullName,
                color: user.color,
            }));
        }
    }
    get assignedUsers(): UserListItem[] {
        return this._assignedUsers || [];
    }

    @Input()
    set assignedRoles(assignedRoles: FunctionalRoleListItem[]) {
        this._assignedRoles = assignedRoles;
        if (assignedRoles) {
            this.assignedRoleItems = assignedRoles.map((role) => ({
                id: role.id,
                initials: role.name,
                name: role.name,
                color: '#000000',
            }));
        }
    }

    get assignedRoles(): FunctionalRoleListItem[] {
        return this._assignedRoles || [];
    }

    assignedUserItems: AssignedItem[];
    assignedRoleItems: AssignedItem[];

    private _assignedUsers: UserListItem[];
    private _assignedRoles: FunctionalRoleListItem[];
}
