import { BreakpointObserver } from '@angular/cdk/layout';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BOOTSTRAP_BREAKPOINTS } from '../../../common/ruum-bootstrap/breakpoints';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { MenuService } from './menu.service';

export type MenuPlacement =
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-top'
    | 'left-bottom'
    | 'right'
    | 'right-top'
    | 'right-bottom';

/* eslint-disable */
@Component({
    selector: 'ruum-menu',
    template: `
        <div class="d-inline-block" (click)="toggleOpen($event)" #triggerElement>
            <ng-content select="ruum-menu-trigger"></ng-content>
        </div>
        <ng-template #popupTemplate>
            <div
                class="shadow py-2 w-100 popover-container rounded"
                [class.mt-1]="placementBottom"
                [class.mb-1]="placementTop"
                [class.mr-1]="placementLeft"
                [class.ml-1]="placementRight"
                [class.isMobile]="isMobile"
            >
                <div
                    class="popover-content-wrapper"
                    [class.px-4]="isMobile && !isFullscreen"
                    [class.w-100]="isMobile && !isFullscreen"
                    [style.width]="width"
                >
                    <div
                        class="bg-white rounded popover-options-container"
                        [class.shadow]="isMobile"
                        [class.py-4]="isMobile"
                        [class.w-100]="isFullscreen"
                        [class.h-100]="isFullscreen"
                        [class.overflow-y]="enableYScroll"
                    >
                        <div #contentWrapper>
                            <ng-content></ng-content>
                        </div>
                    </div>
                    <div class="py-4" *ngIf="isMobile && !isFullscreen">
                        <button class="btn btn-dark w-100" (click)="toggleOpen()">Cancel</button>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    providers: [MenuService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-inline-block ', this.componentClass]);
    }

    @ViewChild('triggerElement', { static: false }) triggerElement: ElementRef<HTMLElement>;
    @ViewChild('popupTemplate', { static: false }) popupTemplate: TemplateRef<any>;
    @ViewChild('contentWrapper', { static: false }) contentWrapper: ElementRef<HTMLElement>;

    @HostBinding('class.disabled')
    @Input()
    disabled = false;

    @Input() autoClose = true;

    @Input() enableYScroll = true; // settign vertical scroll to auto

    @Input() isFullscreen = false; // to expand menu overlay to full screen in mobile mode

    @Input() width = 'auto';

    @Input() componentClass = '';

    @Input() offsetX = null;

    @Input()
    set isOpen(isOpen: boolean) {
        this.changeOpen(isOpen);
    }
    get isOpen(): boolean {
        return this._isOpen;
    }

    @Input()
    set isMobile(isMobile: boolean) {
        this._isMobile = isMobile;
        this.overlayConfig = isMobile ? this.createOverlayConfigMobile() : this.createOverlayConfig();
    }
    get isMobile(): boolean {
        return this._isMobile;
    }

    @Input() placement: MenuPlacement[] = ['bottom', 'top'];

    @Output() isOpenChange = new EventEmitter<boolean>();

    get placementBottom(): boolean {
        return (
            this.placement.includes('bottom') ||
            this.placement.includes('bottom-left') ||
            this.placement.includes('bottom-right')
        );
    }

    get placementTop(): boolean {
        return (
            this.placement.includes('top') ||
            this.placement.includes('top-left') ||
            this.placement.includes('top-right')
        );
    }

    get placementLeft(): boolean {
        return (
            this.placement.includes('left') ||
            this.placement.includes('left-top') ||
            this.placement.includes('left-bottom')
        );
    }

    get placementRight(): boolean {
        return (
            this.placement.includes('right') ||
            this.placement.includes('right-top') ||
            this.placement.includes('right-bottom')
        );
    }

    private _isOpen = false;
    private _isMobile = null;
    private isAfterViewInit = false;
    private templatePortal: TemplatePortal;
    private overlayRef: OverlayRef;
    private overlayConfig: OverlayConfig;
    private ngOnDestroy$ = new Subject<void>();
    private popoverDestroy$ = new Subject<void>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private breakpointObserver: BreakpointObserver,
        private menuService: MenuService,
    ) {}

    ngOnInit() {
        if (this.autoClose) {
            this.menuService.onMenuOptionClick$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
                this.changeOpen(false);
            });
        }
        if (this.isMobile === null) {
            this.breakpointObserver
                .observe([BOOTSTRAP_BREAKPOINTS.XSmall, BOOTSTRAP_BREAKPOINTS.Small])
                .pipe(
                    takeUntil(this.ngOnDestroy$),
                    map((result) => result.matches),
                )
                .subscribe((isMobile) => {
                    this.isMobile = isMobile;
                    this.overlayConfig = isMobile ? this.createOverlayConfigMobile() : this.createOverlayConfig();
                    this.changeDetectorRef.markForCheck();
                    if (this._isOpen) {
                        this.changeOpen(false);
                    }
                });
        }
    }

    ngAfterViewInit() {
        this.templatePortal = new TemplatePortal<any>(this.popupTemplate, this.viewContainerRef);
        this.overlayConfig = this.isMobile ? this.createOverlayConfigMobile() : this.createOverlayConfig(this.offsetX);

        this.isAfterViewInit = true;
        if (this._isOpen) {
            this.openPopOver();
        }
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    toggleOpen(event: Event) {
        if (event !== undefined) {
            event.stopPropagation();
        }
        this.changeOpen(!this._isOpen);
    }

    private changeOpen(isOpen: boolean) {
        if (this._isOpen !== isOpen) {
            if (isOpen) {
                this.openPopOver();
            } else {
                this.popoverDestroy$.next();
                this.closePopOver();
            }
        }
        if (this.disabled) {
            return;
        }
        this._isOpen = isOpen;
        this.isOpenChange.emit(isOpen);
        this.changeDetectorRef.markForCheck();
    }

    private openPopOver() {
        if (!this.isAfterViewInit) {
            return;
        }
        if (this.disabled) {
            return;
        }
        if (!this.overlayRef) {
            // if desktop, re-calculate overlay config
            // with offsetX(if any).
            // if mobile, then no need to recalculate the
            // overlay config, just refer the default config
            // from initialization phase
            if (!this.isMobile) {
                this.overlayConfig = this.createOverlayConfig(this.offsetX);
            }

            this.overlayRef = this.overlay.create(this.overlayConfig);
            this.overlayRef.attach(this.templatePortal);
            this.subscribeToPopover();
            setTimeout(() => {
                this.focusTriggerElement(this.contentWrapper);
            }, 200);
        }
    }

    private closePopOver() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }

        this.focusTriggerElement(this.triggerElement);
    }

    private focusTriggerElement(elementRef: ElementRef<HTMLElement>) {
        if (elementRef.nativeElement) {
            const focusEl = componentHelper.getKeyboardFocusableElements(elementRef.nativeElement)[0];
            if (focusEl) {
                focusEl.focus();
            }
        }
    }

    private createOverlayConfig(offsetX: number = null): OverlayConfig {
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        const position = this.overlay
            .position()
            .flexibleConnectedTo(this.triggerElement)
            .withPositions(this.placement.map((key) => this.getConnectedPosition(key)))
            .withLockedPosition(false);

        if (!!offsetX) {
            position.withDefaultOffsetX(offsetX);
        }

        const config: OverlayConfig = {
            positionStrategy: position,
            scrollStrategy,
            minWidth: 116,
            disposeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: 'ruum-menu-popover-background',
            panelClass: 'ruum-menu-popover',
        };
        return config;
    }

    private createOverlayConfigMobile(): OverlayConfig {
        const scrollStrategy = this.overlay.scrollStrategies.block();
        const position = this.overlay.position().global();
        const config: OverlayConfig = {
            positionStrategy: position,
            scrollStrategy,
            minWidth: 200,
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            disposeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: 'ruum-menu-popover-background',
            panelClass: 'ruum-menu-popover',
        };
        return config;
    }

    private subscribeToPopover() {
        this.overlayRef
            .detachments()
            .pipe(takeUntil(this.ngOnDestroy$), takeUntil(this.popoverDestroy$))
            .subscribe(() => {
                this.changeOpen(false);
            });
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.ngOnDestroy$), takeUntil(this.popoverDestroy$))
            .subscribe(() => {
                this.overlayRef.dispose();
            });
    }

    private getConnectedPosition(key: MenuPlacement): ConnectedPosition {
        const topPosition: ConnectedPosition = {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
        };

        const topLeftPosition: ConnectedPosition = {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
        };

        const topRightPosition: ConnectedPosition = {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
        };

        const rightPosition: ConnectedPosition = {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
        };

        const rightTopPosition: ConnectedPosition = {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom',
        };

        const rightBottomPosition: ConnectedPosition = {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
        };

        const bottomPosition: ConnectedPosition = {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
        };

        const bottomLeftPosition: ConnectedPosition = {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        };

        const bottomRightPosition: ConnectedPosition = {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        };

        const leftPosition: ConnectedPosition = {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
        };

        const leftTopPosition: ConnectedPosition = {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
        };

        const leftBottomPosition: ConnectedPosition = {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top',
        };

        const positionMap = {
            top: topPosition,
            'top-left': topLeftPosition,
            'top-right': topRightPosition,
            bottom: bottomPosition,
            'bottom-left': bottomLeftPosition,
            'bottom-right': bottomRightPosition,
            left: leftPosition,
            'left-top': leftTopPosition,
            'left-bottom': leftBottomPosition,
            right: rightPosition,
            'right-top': rightTopPosition,
            'right-bottom': rightBottomPosition,
        };
        return positionMap[key] || positionMap.bottom;
    }
}
