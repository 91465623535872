import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RuumAction, RuumEntityType } from '@ruum/ruum-reducers';
import { combineLatest, Observable, timer } from 'rxjs';
import { catchError, concatAll, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthBackendConnector } from './authServiceConnector.service';
import { ProjectServiceBackendConnector } from './projectServiceConnector.service';
import { WebsocketConnectionService } from './websocketConnection.service';

@Injectable({ providedIn: 'root' })
export class EntityChangesListener {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;

    constructor(
        private http: HttpClient,
        private backendConnector: AuthBackendConnector,
        private wsConnection: WebsocketConnectionService,
        private projectServiceConnector: ProjectServiceBackendConnector,
    ) {}

    listenToEntityChanges<T>(
        entityId: string,
        entityType: RuumEntityType,
        version: number,
    ): Observable<ActionAndVersion[]> {
        return combineLatest([
            this.wsConnection.listenToEntity(entityId).pipe(startWith({})),
            /**
             *  This is a way for the web app to proactively get new actions. It is a workaround the fact that sometimes the frontend does not
             *  receive the websocket notification that a new action was persisted.
             *  This does not fix the problem that a user will not see changes from a different user when the websocket connection is broken.
             */
            this.projectServiceConnector.shouldGetNewActions$.pipe(
                filter((id) => id === entityId),
                startWith({}),
            ),
        ]).pipe(
            debounceTime(100),
            map(() =>
                this.getChangesSince(entityId, entityType, version).pipe(
                    catchError((err, caught) => this.catchGetChangeError(err, caught)),
                ),
            ),
            concatAll(),
            tap((changes) => {
                if (changes.length > 0) {
                    version = changes[changes.length - 1].entityVersion;
                }
            }),
        );
    }

    /** If it fails, try again at every 4 seconds. */
    private catchGetChangeError(err: any, caught: Observable<ActionAndVersion[]>) {
        if (err.status === 403) {
            throw err;
        } else {
            return timer(4000).pipe(switchMap(() => caught));
        }
    }

    private getChangesSince(
        entityId: string,
        entityType: RuumEntityType,
        version: number,
    ): Observable<ActionAndVersion[]> {
        let url;
        if (entityType === 'project') {
            url = `${this.PROJECT_SERVICE_URL}/v1/ruums/${entityId}/actions?since=${version}`;
        } else if (entityType === 'project_group') {
            url = `${this.PROJECT_SERVICE_URL}/v1/projectgroups/${entityId}/actions?since=${version}`;
        } else if (entityType === 'workspace') {
            url = `${this.PROJECT_SERVICE_URL}/v1/workspaces/${entityId}/actions?since=${version}`;
        } else if (entityType === 'template') {
            url = `${this.PROJECT_SERVICE_URL}/v1/templates/${entityId}/actions?since=${version}`;
        } else if (entityType === 'enterprise') {
            url = `${this.PROJECT_SERVICE_URL}/v1/enterprises/${entityId}/actions?since=${version}`;
        } else {
            throw Error(`Invalid entity type ${entityType}`);
        }
        return this.http
            .get<ActionAndVersion[]>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }
}

export interface ActionAndVersion {
    action: RuumAction;
    entityVersion: number;
}
