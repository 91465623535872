import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, switchAll } from 'rxjs/operators';
import { LobbyListItemProject } from '../../../project/project.model';
import { AutoResizeTextareaComponent } from '../../../shared/ui-components/input/autoresize-textarea.component';
import { ReadModelBackendConnector } from '../../connectors/readModelConnector.service';
import { TrackingConnector } from '../../trackingConnector.service';
import { InputValidator } from '../../validators/inputValidator.service';

interface SearchableItem {
    id: string;
    name: string;
    [any: string]: any;
}

@Component({
    selector: 'add-task-dialog',
    template: `
        <ruum-modal-dialog>
            <!-- Set a description -->
            <ng-container>
                <h2 class="mt-4 mb-5 text-truncate">New Task</h2>
                <span class="text-dark text-uppercase font-weight-bold text-tiny mb-1">Task Name</span>
                <autoresize-textarea
                    ngDefaultControl
                    [formControlSize]="'sm'"
                    [(ngModel)]="description"
                    placeholder="Type the task name..."
                    data-test="ruum-modal-task-name"
                    (ngModelChange)="validateInput($event)"
                    [errorMessage]="taskDescriptionErrorMessage"
                >
                </autoresize-textarea>
            </ng-container>
            <div
                class="d-flex flex-fill align-items-center pr-4 bg-white"
                *ngIf="taskDescriptionErrorMessage !== undefined"
            >
                <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                    <i class="icon icon-cancel"></i>
                </button>
                <div class="text-tiny text-danger">
                    {{ taskDescriptionErrorMessage }}
                </div>
            </div>

            <!-- Select a Ruum -->
            <div class="mt-4 position-relative" *ngIf="!projectId && !!description">
                <span class="text-dark text-uppercase font-weight-bold text-tiny">Add to Ruum</span>
                <input
                    class="form-control form-control-sm"
                    placeholder="Select or search for a Ruum"
                    ruumInputHighlight
                    autocomplete="off"
                    [ngbTypeahead]="projectItems"
                    [(ngModel)]="selectedProject"
                    (selectItem)="selectProject($event)"
                    [inputFormatter]="formatter"
                    [resultTemplate]="itemTemplate"
                    (click)="projectSearchClick()"
                    (focus)="projectSearchClick()"
                    data-test="ruum-modal-task-ruum"
                />

                <ng-template #itemTemplate let-item="result" let-t="term">
                    <span>{{ item.name }}</span>
                </ng-template>
            </div>

            <div class="mt-1" *ngIf="!!selectedProject && selectedProject.isViewer">
                <div class="text-small text-danger">
                    You have view only permissions for this ruum and can not add tasks to it.
                </div>
            </div>

            <!-- Select a Section -->
            <div class="mt-4" *ngIf="description && hasProjectWriteAccess()">
                <span class="text-dark text-uppercase font-weight-bold text-tiny mt-4">add to section</span>
                <select-section
                    class="mt-4"
                    [projectId]="getSelectedProjectId()"
                    (selectSection)="selectSection($event)"
                >
                </select-section>
            </div>

            <div class="d-flex flex-fill justify-content-end mt-6">
                <button
                    class="btn btn-lg btn-primary"
                    [disabled]="canNotCreate()"
                    title="Select a Ruum and a Section"
                    (click)="createTask()"
                >
                    Create Task
                </button>
            </div>
        </ruum-modal-dialog>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTaskDialogComponent implements OnInit {
    @Input() groupId: string;
    @Input() projectId: string;

    description = '';
    taskDescriptionErrorMessage = undefined;
    taskDescriptionMaxLength: number;

    projectItems$: Observable<SearchableItem[]>;

    showProjects$ = new Subject<void>();

    @ViewChild(AutoResizeTextareaComponent, { static: false })
    private descriptionInput: AutoResizeTextareaComponent;

    selectedProject: LobbyListItemProject;
    selectedSectionId: string;

    constructor(
        private activeModal: NgbActiveModal,
        private trackingConnector: TrackingConnector,
        private readModelConnector: ReadModelBackendConnector,
        private inputValidator: InputValidator,
    ) {
        this.taskDescriptionMaxLength = this.inputValidator.mediumLimit;
    }

    ngOnInit() {
        this.trackingConnector.trackEventInferCategoryFromUrl('new-task-dialog', 'opened');
    }

    projectItems = (text$: Observable<string>): Observable<SearchableItem[]> => {
        return combineLatest([this.showProjects$, text$.pipe(startWith(''))]).pipe(
            map(([_, search]) => this.getProjects(search)),
            switchAll(),
        );
    };

    projectSearchClick() {
        this.showProjects$.next();
    }

    formatter(item: SearchableItem): string {
        return item.name;
    }

    private getProjects(search: string): Observable<SearchableItem[]> {
        return this.readModelConnector.getLobbyListItemProjects({ groupId: this.groupId, name: search }).pipe(
            map((page) => {
                return page.rows;
            }),
        );
    }

    selectProject(data) {
        this.trackingConnector.trackEventInferCategoryFromUrl('project', 'selected', data.item.id);
        this.selectedSectionId = undefined;
    }

    selectSection(sectionId: string) {
        this.selectedSectionId = sectionId;
    }

    createTask() {
        this.activeModal.close({
            projectId: this.getSelectedProjectId(),
            sectionId: this.selectedSectionId,
            description: this.description,
        });
    }

    canNotCreate(): boolean {
        return (
            !this.description ||
            !this.getSelectedProjectId() ||
            !this.selectedSectionId ||
            !this.hasProjectWriteAccess() ||
            this.taskDescriptionErrorMessage !== undefined
        );
    }

    hasProjectWriteAccess(): boolean {
        return !!this.projectId || (this.selectedProject && !this.selectedProject.isViewer);
    }

    getSelectedProjectId() {
        return this.projectId || (this.selectedProject && this.selectedProject.id);
    }

    validateInput(taskDescription: string) {
        this.taskDescriptionErrorMessage = this.inputValidator.getTextInputErrorMessage(
            taskDescription,
            this.taskDescriptionMaxLength,
        );
    }
}
