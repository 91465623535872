import {
    AddCustomFieldDefinitionAction,
    CustomFieldDefinition,
    EntityAction,
    RuumAction,
    WorkspaceActionsTypes,
} from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../../app/lobby/lobby.reducer';
import { CustomFieldListItem } from '../../customFields/customFields.model';
import { PaginatedList } from '../../paginatedList.model';

export const CUSTOM_FIELD_LIST_ACTION_TYPES = {
    CUSTOM_FIELDS_LOADED: 'CUSTOM_FIELDS_LOADED',
};

export type CustomFieldsLoadedAction = EntityAction<
    'CUSTOM_FIELDS_LOADED',
    {
        page: PaginatedList<CustomFieldDefinition>;
    }
>;

export function CustomFieldListReducer(
    currentState: PaginatedList<string> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<string> {
    switch (action.type) {
        case CUSTOM_FIELD_LIST_ACTION_TYPES.CUSTOM_FIELDS_LOADED:
            return listLoaded(currentState, action.payload.page);
        case WorkspaceActionsTypes.ADD_CUSTOM_FIELD_DEFINITION:
            return addField(currentState, action);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<string>,
    data: PaginatedList<CustomFieldListItem>,
): PaginatedList<string> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows.map((row) => row.id)),
        };
    } else {
        return {
            ...data,
            rows: data.rows.map((row) => row.id),
        };
    }
}

function addField(currentState: PaginatedList<string>, action: AddCustomFieldDefinitionAction): PaginatedList<string> {
    return {
        ...currentState,
        rows: currentState.rows.concat(action.payload.definition.id),
    };
}
