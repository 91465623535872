import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { SavedViewFieldsService } from '../../../../common/connectors/savedView/fields/saved-view-fields.service';
import { SavedViewFiltersService } from '../../../../common/connectors/savedView/saved-view-filters.service';
import {
    FilterLogicalOperator,
    FilterLogicalOperatorId,
    SavedViewFieldDefinition,
    SavedViewFilterDefinition,
    SavedViewFilterValue,
} from '../../../../common/connectors/savedView/saved-views.model';

@Component({
    selector: 'ruum-filter-rule',
    template: `
        <div class="d-flex flex-fill">
            <div class="d-flex flex-fill border border-light rounded" [style.padding.px]="3" [style.height.px]="40">
                <ruum-filter-field-select
                    [excludedIds]="selectedFieldIds"
                    [selectedOptionIds]="[selectedFieldId$ | async]"
                    [componentClass]="'mr-1 ruum-field-select'"
                    (changed)="fieldChange($event)"
                ></ruum-filter-field-select>
                <ruum-filter-operator-select
                    *ngIf="showLogicalOperatorSelect$ | async"
                    [selectedLogicalOperatorId]="selectedLogicalOperatorId$ | async"
                    [logicalOperators]="logicalOperators$ | async"
                    [componentClass]="'mr-1 ruum-logical-operator-select'"
                    (changed)="logicalOperatorChange($event)"
                ></ruum-filter-operator-select>

                <ruum-filter-field-value-select
                    *ngIf="showFieldValueSelect$ | async"
                    [selectedFieldId]="selectedFieldId$ | async"
                    [selectedLogicalOperatorId]="selectedLogicalOperatorId$ | async"
                    [selectedOptions]="selectedOptionsIds$ | async"
                    [componentClass]="'ruum-value-select'"
                    [multiSelect]="isMultiSelect"
                    (changed)="fieldValueChange($event)"
                ></ruum-filter-field-value-select>
            </div>
            <div class="d-flex align-items-center">
                <button class="btn btn-link-secondary btn-icon" (click)="onRemoveRule()">
                    <i class="icon icon-cancel-filled"></i>
                </button>
            </div>
        </div>
    `,
    styles: [
        `
            .ruum-field-select {
                width: 175px;
            }

            .ruum-logical-operator-select {
                width: 104px;
            }

            .ruum-value-select {
                width: 175px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterRuleComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    @Input() selectedFieldIds: string[] = [];

    @Input() set selectedFieldId(value: string) {
        this.selectedFieldIdSource.next(value);
    }

    get selectedFieldId(): string {
        return this.selectedFieldIdSource.getValue();
    }

    @Input() set selectedLogicalOperatorId(value: FilterLogicalOperatorId) {
        this.selectedLogicalOperatorIdSource.next(value);
    }

    get selectedLogicalOperatorId(): FilterLogicalOperatorId {
        return this.selectedLogicalOperatorIdSource.getValue();
    }

    @Input() set selectedOptions(value: string[]) {
        this.selectedOptionsIdsSource.next(value);
    }

    get selectedOptions(): string[] {
        return this.selectedOptionsIdsSource.getValue();
    }

    @Output() removeRule = new EventEmitter<string>();
    @Output() ruleChanged = new EventEmitter<SavedViewFilterValue>();

    logicalOperators$: Observable<FilterLogicalOperator[]>;

    selectedFieldId$: Observable<string>;
    selectedLogicalOperatorId$: Observable<FilterLogicalOperatorId>;
    selectedOptionsIds$: Observable<string[]>;

    selectedFieldIdSource = new BehaviorSubject<string>(undefined);
    selectedLogicalOperatorIdSource = new BehaviorSubject<FilterLogicalOperatorId>(undefined);
    selectedOptionsIdsSource = new BehaviorSubject<string[]>([]);

    get showLogicalOperatorSelect$(): Observable<boolean> {
        return this.selectedFieldId$.pipe(map((fieldId) => !!fieldId));
    }

    get showFieldValueSelect$(): Observable<boolean> {
        return combineLatest([this.selectedFieldId$, this.selectedLogicalOperatorId$]).pipe(
            map(([selectedFieldId, selectedLogicalOperatorId]) => {
                return !!selectedFieldId && !!selectedLogicalOperatorId;
            }),
        );
    }

    get isMultiSelect(): boolean {
        return ['IS_ANY_OF', 'IS_NONE_OF'].indexOf(this.selectedLogicalOperatorIdSource.getValue()) !== -1;
    }

    private ngOnDestroy$ = new Subject<void>();

    constructor(
        private savedViewFieldsService: SavedViewFieldsService,
        private savedViewFiltersService: SavedViewFiltersService,
    ) {}

    ngOnInit() {
        this.selectedFieldId$ = this.selectedFieldIdSource.asObservable();
        this.logicalOperators$ = this.getLogicalOperators();
        this.selectedLogicalOperatorId$ = this.getSelectedLogicalOperatorId();
        this.selectedOptionsIds$ = this.selectedOptionsIdsSource.asObservable();

        /** set first logical operator as selected */
        this.subscribeSelectedLogicalOperatorId();
    }

    fieldChange(fieldId: string) {
        if (fieldId && fieldId !== this.selectedFieldIdSource.getValue()) {
            this.selectedFieldIdSource.next(fieldId);
            this.selectedLogicalOperatorIdSource.next(undefined);
            this.notifyRuleChanged();
        }
    }

    logicalOperatorChange(logicalOperator) {
        if (logicalOperator && logicalOperator !== this.selectedLogicalOperatorIdSource.getValue()) {
            this.selectedLogicalOperatorIdSource.next(logicalOperator);
            this.notifyRuleChanged();
        }
    }

    fieldValueChange(value: any) {
        if (!!value && JSON.stringify(value) !== JSON.stringify(this.selectedOptionsIdsSource.getValue())) {
            const list = Array.isArray(value) ? value : [value];

            this.selectedOptionsIdsSource.next(list);
            this.notifyRuleChanged();
        }
    }

    notifyRuleChanged() {
        const fieldId = this.selectedFieldIdSource.getValue();
        const selectedLogic = this.selectedLogicalOperatorIdSource.getValue();
        const selectedOptions = this.selectedOptionsIdsSource.getValue();
        this.ruleChanged.emit({ fieldId, selectedLogic, selectedOptions });
    }

    onRemoveRule() {
        this.removeRule.emit(this.selectedFieldId);
    }

    private getSelectedLogicalOperatorId(): Observable<FilterLogicalOperatorId> {
        return this.selectedLogicalOperatorIdSource.asObservable();
    }

    private getLogicalOperators(): Observable<FilterLogicalOperator[]> {
        return combineLatest([this.selectedFieldIdSource.asObservable()]).pipe(
            filter(([selectedFieldId]) => !!selectedFieldId),
            switchMap(([selectedFieldId]) => {
                return this.savedViewFieldsService.getFieldById(selectedFieldId);
            }),
            filter((fieldDefinition) => !!fieldDefinition),
            switchMap((fieldDefinition: SavedViewFieldDefinition) => {
                return this.savedViewFiltersService.getFilterByTypeId(fieldDefinition.typeId);
            }),
            map((filterDefinition: SavedViewFilterDefinition) => {
                return filterDefinition.logics;
            }),
        );
    }

    private subscribeSelectedLogicalOperatorId() {
        return combineLatest([this.selectedLogicalOperatorIdSource.asObservable(), this.logicalOperators$])
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map(([selectedLogicalOperatorId, logicalOperators]) => {
                    if (!selectedLogicalOperatorId) {
                        this.selectedLogicalOperatorIdSource.next(logicalOperators[0].id);
                        return logicalOperators[0].id;
                    }
                }),
            )
            .subscribe();
    }
}
