import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { componentHelper } from '../ui-components.helper';
import { ComponentSize } from '../ui-components.type';

@Component({
    selector: 'ruum-search',
    template: `
        <div
            role="search"
            class="input-group"
            [class.form-white]="theme === 'white'"
            [class.input-group-sm]="size === 'sm'"
            [class.input-group-lg]="size === 'lg'"
        >
            <div class="input-group-prepend" ruumInputHighlight>
                <span class="input-group-text">
                    <i class="icon icon-search"></i>
                </span>
            </div>
            <input
                class="form-control"
                [class.form-control-sm]="size === 'sm'"
                [class.form-control-lg]="size === 'lg'"
                #search
                id="ruum-search"
                name="ruum-search"
                type="text"
                ruumInputHighlight
                [placeholder]="placeholder"
                [ngModel]="value"
                (ngModelChange)="changeValue($event)"
                [attr.aria-label]="ariaLabel"
                ngbAutofocus
            />
            <div
                *ngIf="value"
                class="input-group-append"
                ruumInputHighlight
                (click)="clear()"
                (keydown.space)="clear()"
                (keydown.enter)="clear()"
            >
                <span class="input-group-text">
                    <i class="icon icon-cancel"></i>
                </span>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex', 'flex-fill', this.componentClass]);
    }

    @Input() value = '';
    @Input() theme = '';
    @Input() placeholder = 'Search';
    @Input() debounceTime = 500;
    @Input() ariaLabel = 'Search';

    @Input() size: ComponentSize = 'sm'; // sm | md | 'lg'

    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;

    @Input() componentClass = '';

    @Output() changed = new EventEmitter<string>();

    private subscriptions: Subscription[] = [];

    private search$ = new Subject<string>();

    constructor() {}

    ngOnInit() {
        this.subscriptions.push(this.subscribeSearch());
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    changeValue(newValue: string): void {
        this.value = newValue;
        this.search$.next(this.value);
    }

    clear() {
        this.value = '';
        this.changed.emit(this.value);
    }

    private subscribeSearch(): Subscription {
        return this.search$.pipe(debounceTime(this.debounceTime)).subscribe(() => {
            this.changed.emit(this.value);
        });
    }
}
