import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { WebhookEntitySyncStateListItem } from '../../webhooks.model';
import { WebhookEntityEventsListService } from './webhook-entity-events-list.service';

@Injectable({
    providedIn: 'root',
})
export class GetWebhookEntityEventsResolver implements Resolve<any> {
    constructor(private webhookEntityEventsListService: WebhookEntityEventsListService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<Observable<WebhookEntitySyncStateListItem[]>> {
        const webhookId = route.paramMap.get('webhookId');
        const entityId = route.paramMap.get('entityId');

        this.webhookEntityEventsListService.filterByWebhookId(webhookId);
        this.webhookEntityEventsListService.filterByEntityId(entityId);

        return this.webhookEntityEventsListService.getStoreData$().pipe(
            filter((page) => !!page),
            pluck('rows'),
        );
    }
}
