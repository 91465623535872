import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { map, switchAll, take } from 'rxjs/operators';
import { SectionsService } from '../../../ruum/section/sections.service';
import { RuumDropDownOption } from '../../components/ruum-dropdown-popup.component';
import { RuumPopupService } from '../../popup/ruum-popup-container.service';
import { isMobile } from '../../utils.service';
import { ActionToken, SidenavService, SidePanelDisplayNode } from '../sidenav.service';

@Component({
    selector: 'ruum-side-panel-node',
    template: `
        <ruum-side-panel-section
            [navigationContext]="navigationContext"
            [node]="node"
            [type]="node?.type"
            [caption]="node?.name"
            [actions]="node?.actions"
            [lenght]="node?.nodes?.length"
            [sectionExpanded]="sectionExpanded"
            [increaseFontSize]="increaseFontSize()"
            (sectionHover)="sectionHover($event)"
            (expandAction)="onExpandAction(node)"
            (onAddElement)="addElement.emit()"
            [class.section-hover]="sectionHoverState"
        >
            <div cdkDropList (cdkDropListDropped)="dropNode($event)" class="overflow-y" [style.max-height.vh]="50">
                <ruum-draggable-item
                    *ngFor="let node of node?.nodes"
                    class="drag-container gu-render-no-border"
                    cdkDrag
                    (cdkDragStarted)="dragStart($event)"
                    (cdkDragEnded)="dragEnd($event)"
                    [hideButton]="true"
                    [hideBorder]="true"
                    [ngClass]="node?.type"
                    [isReadOnly]="hasAction(node, actionTokens.DRAGGABLE)"
                >
                    <div
                        class="node flex-fill"
                        [class.generic-hover]="!hoverSubAction"
                        (mouseover)="nodeHoverState = true"
                        (focus)="nodeHoverState = true"
                        (mouseout)="nodeHoverState = false"
                        (blur)="nodeHoverState = false"
                    >
                        <ruum-side-panel-node-item
                            *ngIf="node?.type !== 'workspace'"
                            [increaseFontSize]="increaseFontSize()"
                            [label]="node?.name"
                            [sectionId]="node?.id"
                            [tooltip]="node?.tooltip"
                            [iconName]="node?.icon"
                            [isDraggable]="hasAction(node, actionTokens.DRAGGABLE)"
                            [isExpandable]="hasAction(node, actionTokens.DRILL_DOWN)"
                            [isExpanded]="false"
                            [isUpdated]="true"
                            [actionContextActive]="contextActive"
                            [hasContext]="hasAction(node, actionTokens.OPEN_FOLDER_CONTEXT)"
                            [addEnableNowSelector]="addEnableNowSelector"
                            (drag)="dispatchAction(node, actionTokens.DRAGGABLE, $event)"
                            (toggleContext)="dispatchAction(node, actionTokens.OPEN_FOLDER_CONTEXT, $event)"
                            (activateNode)="activateNode(node)"
                            (nodeHoverSubAction)="onHoverSubAction($event)"
                            (onChangeOption)="openContextPopup(node?.id, $event)"
                        ></ruum-side-panel-node-item>
                    </div>
                    <ruum-side-panel-node
                        [navigationContext]="navigationContext"
                        (nodeEvent)="nodeEvent.emit($event)"
                        *ngIf="node?.nodes?.length > 0 && node?.renderSubNode"
                        [node]="node"
                        [nodes]="node?.nodes"
                        [activeNode]="activeNode"
                    ></ruum-side-panel-node>
                </ruum-draggable-item>
            </div>
        </ruum-side-panel-section>
    `,
    styles: [
        `
            .node {
                flex-direction: column;
                overflow: hidden;
            }
        `,
    ],
})
export class MainNavPanelNodeComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';
    @Input() navigationContext: string;
    @Input() title: string;
    @Input() node: SidePanelDisplayNode;
    @Input() nodes: SidePanelDisplayNode[];
    @Input() activeNode: SidePanelDisplayNode = null;
    @Input() sectionExpanded = false;
    @Input() addEnableNowSelector: false;
    @Output() nodeEvent = new EventEmitter<SidePanelDisplayNode>();
    @Output() dropNodeEvent = new EventEmitter<CdkDragDrop<any>>();
    @Output() expandAction = new EventEmitter<SidePanelDisplayNode>();
    @Output() addElement = new EventEmitter();
    @Output() changeOption = new EventEmitter<string>();

    // expose to template...
    actionTokens = ActionToken;
    sectionHoverState: boolean;
    nodeHoverState = false;
    hoverSubAction = false;
    options: RuumDropDownOption[];
    contextActive = false;

    constructor(
        private ruumPopupService: RuumPopupService,
        private sidenavService: SidenavService,
        private sectionService: SectionsService,
    ) {}

    toggleSubnodes(node: SidePanelDisplayNode) {
        if (node.nodes && node.nodes.length > 0) {
            node.renderSubNode = !node.renderSubNode;
        }
    }

    increaseFontSize(): boolean {
        return this.sidenavService.isIncreasedFontSize();
    }

    getTypeIconClass(node: SidePanelDisplayNode) {
        return node.icon ? 'icon-' + node.icon : '';
    }

    getStateIconClass(node: SidePanelDisplayNode) {
        return node.renderSubNode && node.renderSubNode === true ? 'icon-cevron-down icon' : 'icon-cevron-right icon';
    }

    dropNode(e: CdkDragDrop<any>) {
        this.dropNodeEvent.emit(e);
    }

    hasAction(node: SidePanelDisplayNode, thisAction: ActionToken) {
        return node && node.actions ? node.actions.has(thisAction) : false;
    }

    nodeAction(node: SidePanelDisplayNode) {}

    onExpandAction(node) {
        this.expandAction.emit(node);
    }

    onHoverSubAction(e) {
        this.hoverSubAction = e;
    }

    sectionHover(e) {
        this.sectionHoverState = e;
    }

    openContextPopup(nodeId, event) {
        const pos: ClientRect = event.target.getBoundingClientRect();
        const options: RuumDropDownOption[] = [
            { id: 'duplicateSection', text: 'Duplicate Section', icon: 'icon-duplicate' },
            { id: 'createTemplate', text: 'Create Template', icon: 'icon-template' },
            { id: 'deleteSection', text: 'Delete Section', icon: 'icon-delete' },
        ];

        this.sectionService
            .isCollapsed(nodeId)
            .pipe(
                take(1),
                map((collapsed) => {
                    if (collapsed) {
                        options.unshift({ id: 'expandSection', text: 'Expand Section', icon: 'icon-expand' });
                    } else {
                        options.unshift({ id: 'collapseSection', text: 'Collapse Section', icon: 'icon-collapse' });
                    }
                    return this.ruumPopupService.showSelectDropdown({ options, pos });
                }),
                switchAll(),
            )
            .subscribe((selectedOption) => {
                this.contextActive = false;
                if (selectedOption) {
                    this.dispatchContextAction(selectedOption, nodeId);
                }
            });
    }

    private dispatchContextAction(selectedOption, nodeId) {
        switch (selectedOption.id) {
            case 'expandSection':
                this.sidenavService.expandSection(nodeId);
                break;
            case 'collapseSection':
                this.sidenavService.collapseSection(nodeId);
                break;
            case 'duplicateSection':
                this.sidenavService.duplicateSection(nodeId);
                break;
            case 'createTemplate':
                this.sidenavService.createSectionTemplateFrom(nodeId);
                break;
            // not Implemented
            case 'deleteSection':
                this.sidenavService.deleteSection(nodeId);
                break;
            default:
        }
    }

    dragStart(event: CdkDragStart<any>) {
        // patch document because prosemirror is otherwise throw an error
        (document as any).classList = event.source.element.nativeElement.classList;
    }

    dragEnd() {
        // undo the document patch
        delete (document as any).classList;
    }

    activateNode(node) {
        if (isMobile()) {
            this.sidenavService.onMainNavPanelDeactivate();
        }
        this.nodeEvent.emit(node);
    }

    dispatchAction(node: SidePanelDisplayNode, action: ActionToken, target) {
        switch (action) {
            case ActionToken.DRILL_DOWN:
                this.toggleSubnodes(node);
                break;
            case ActionToken.OPEN_FOLDER_CONTEXT:
                this.openContextPopup(node.id, target);
                break;
            case ActionToken.OPEN_CANVAS:
            // not Implemented
        }
    }
}
