import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountType } from '@ruum/ruum-reducers';
import { SettingsNavigationTarget } from '../settings-navigation-target';
import { SettingsView } from '../settings.view';

@Component({
    selector: 'ruum-settings-modal',
    template: `
        <div>
            <div class="d-flex justify-content-end py-1 px-2">
                <button class="btn btn-lg btn-link-secondary btn-icon close" type="button" (click)="dismissModal()">
                    <i class="icon icon-cancel"></i>
                </button>
            </div>
            <div class="px-5 pt-5 pb-4">
                <div class="d-flex flex-fill">
                    <div class="d-flex flex-column d-flex flex-fill justify-content-center">
                        <h2>{{ view.name }}</h2>
                    </div>
                    <button
                        class="btn btn-round btn-avatar btn-lg ruum-profile-avatar"
                        type="button"
                        title="{{ view.initials }}"
                    >
                        <span class="h2">{{ view.initials }}</span>
                    </button>
                </div>

                <div class="d-flex mt-0">
                    <span class="text-secondary">{{ view.email }}</span>
                </div>
                <div class="d-flex mt-0 flex-column">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <p
                            class="ruum-plan d-block mb-0"
                            [class.ruum-free-plan]="view.isBasic"
                            [class.ruum-pro-plan]="view.isProfessional"
                            [class.ruum-ent-plan]="view.isEnterprise"
                        >
                            {{ view.accountTypeLabel }}
                        </p>
                        <button *ngIf="view.showUpgradeButton" class="btn btn-primary" (click)="view.upgrade()">
                            Upgrade
                        </button>
                    </div>
                    <span class="d-block mt-0 text-small text-secondary" *ngIf="view.daysLeft">
                        {{ view.daysLeft }} days left
                    </span>
                </div>
            </div>

            <div class="ruum-divider mx-5"></div>

            <div class="px-5 py-2">
                <div
                    class="d-flex py-2"
                    (click)="goTo(SettingsNavigationTarget.PROFILE)"
                    (keydown.space)="goTo(SettingsNavigationTarget.PROFILE)"
                    (keydown.enter)="goTo(SettingsNavigationTarget.PROFILE)"
                >
                    <button class="btn btn-block btn-link-secondary px-0" type="button">
                        <i class="icon icon-user btn-icon-prepend"></i>
                        <span class="d-flex flex-fill font-weight-bold">Profile Settings</span>
                        <i class="icon icon-forward btn-icon-append"></i>
                    </button>
                </div>

                <div
                    class="d-flex py-2 mt-1"
                    (click)="goTo(SettingsNavigationTarget.NOTIFICATIONS)"
                    (keydown.space)="goTo(SettingsNavigationTarget.NOTIFICATIONS)"
                    (keydown.enter)="goTo(SettingsNavigationTarget.NOTIFICATIONS)"
                >
                    <button class="btn btn-block btn-link-secondary px-0" type="button">
                        <i class="icon icon-notification btn-icon-prepend"></i>
                        <span class="d-flex flex-fill font-weight-bold">Notifications Settings</span>
                        <i class="icon icon-forward btn-icon-append"></i>
                    </button>
                </div>

                <div
                    class="d-flex py-2 mt-1"
                    (click)="goTo(SettingsNavigationTarget.ADMIN)"
                    (keydown.space)="goTo(SettingsNavigationTarget.ADMIN)"
                    (keydown.enter)="goTo(SettingsNavigationTarget.ADMIN)"
                >
                    <button class="btn btn-block btn-link-secondary px-0" type="button">
                        <i class="icon icon-admin-dashboard btn-icon-prepend"></i>
                        <span class="d-flex flex-fill font-weight-bold">Admin Panel</span>
                        <i class="icon icon-forward btn-icon-append"></i>
                    </button>
                </div>

                <div
                    class="d-flex py-2 mt-1"
                    (click)="openHelpCenter()"
                    (keydown.space)="openHelpCenter()"
                    (keydown.enter)="openHelpCenter()"
                >
                    <button class="btn btn-block btn-link-secondary px-0" type="button">
                        <i class="icon icon-help-outline btn-icon-prepend"></i>
                        <span class="d-flex flex-fill font-weight-bold">Help Portal</span>
                        <i class="icon icon-forward btn-icon-append"></i>
                    </button>
                </div>

                <div
                    class="d-flex py-2 mt-1"
                    (click)="contactUs()"
                    (keydown.space)="contactUs()"
                    (keydown.enter)="contactUs()"
                >
                    <button class="btn btn-block btn-link-secondary px-0" type="button">
                        <i class="icon icon-email btn-icon-prepend"></i>
                        <span class="d-flex flex-fill font-weight-bold">Support</span>
                        <i class="icon icon-forward btn-icon-append"></i>
                    </button>
                </div>
            </div>

            <div class="ruum-divider mx-5"></div>

            <div class="px-5 py-2">
                <div
                    class="d-flex py-2"
                    (click)="goTo(SettingsNavigationTarget.LOGOUT)"
                    (keydown.space)="goTo(SettingsNavigationTarget.LOGOUT)"
                    (keydown.enter)="goTo(SettingsNavigationTarget.LOGOUT)"
                >
                    <button class="btn btn-block btn-link-secondary px-0" type="button">
                        <i class="icon icon-leave-ruum btn-icon-prepend icon-rotate-180"></i>
                        <span class="d-flex flex-fill font-weight-bold">Logout</span>
                    </button>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                height: 100%;
            }

            .btn-avatar {
                width: 64px;
                height: 64px;
                border-radius: 32px;
            }
        `,
    ],
    providers: [SettingsView],
})
export class SettingsModalComponent implements OnChanges, OnInit {
    @Input()
    name: string;

    @Input()
    initials: string;

    @Input()
    email: string;

    @Input()
    accountType: AccountType;

    @Input()
    isTrial: boolean;

    @Input()
    daysLeft?: number;

    @Output()
    navigate = new EventEmitter();

    settingsModal: NgbModalRef;

    SettingsNavigationTarget = SettingsNavigationTarget;

    constructor(private readonly modalService: NgbModal, readonly view: SettingsView) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        this.view.onEnclosingComponentChange(
            this.name,
            this.initials,
            this.email,
            this.accountType,
            this.isTrial,
            this.daysLeft,
        );
    }

    goTo(settingsNavigationTarget: SettingsNavigationTarget): void {
        this.navigate.emit(settingsNavigationTarget);
        this.dismissAllModals();
    }

    contactUs() {
        window.open(
            `https://help.sap.com/viewer/d9b57ff016e941489cff8d1035b9aea5/Latest/en-US/0b07325520a14864a757a6ffa3118291.html`,
            undefined,
            'noopener',
        );
    }

    openHelpCenter() {
        window.open(`https://help.sap.com/viewer/product/RUUM/Latest/en-US`, undefined, 'noopener');
    }

    dismissModal() {
        this.dismissAllModals();
    }

    dismissAllModals() {
        // TODO: Andrii: activeModal.dismiss() does not work for some reasone.
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }
    }
}
