import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { RuumUIStateService } from '../../ruum/ui-state/ruumUIState.service';
import { AppStoreWrapper } from '../appStoreWrapper.service';
import { NavbarService } from '../navbar/navbar.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { isMobile } from '../utils.service';
import { RuumSidepanelService } from './sidepanel.service';

@Component({
    selector: 'ruum-main-layout',
    template: `
        <!-- Offline alert, Read-only Alert, Edit Alert and etc -->
        <header>
            <div
                id="ruum-common-alert"
                class="fixed-top d-block w-100"
                [class.ruum-main-nav-panel-open]="mainNavPanelOpen$ | async"
            >
                <offline-alert></offline-alert>
                <ng-content select="[data-content='alert']"> </ng-content>
            </div>
            <div class="d-flex" [style.height.px]="alertHeight$ | async"></div>

            <ng-content select="[data-content='navbar']"> </ng-content>
        </header>

        <main
            role="main"
            aria-label="Content"
            class="d-flex flex-fill main-margin-top ruum-main-nav-panel"
            [class.ruum-main-nav-panel-open]="mainNavPanelOpen$ | async"
            [class.margin-top-lg]="hasLargeNavBar"
        >
            <!-- Main content: Canvas, Task List, Timeline and etc. -->
            <div
                class="ruum-screen d-flex flex-fill justify-content-center mw-100 minw-0 d-print-block"
                [class.screen-pushed]="(isScreePushed$ | async) !== true"
                [class.show-sidepanel]="isSidepanelOpen$ | async"
            >
                <div class="d-flex flex-fill justify-content-center mw-100 minw-0">
                    <router-outlet></router-outlet>
                </div>
            </div>

            <!-- Sidepanel -->
            <aside
                role="complementary"
                aria-label="Side Panel"
                class="position-fixed right-0 top-0 h-100 d-print-none bg-white ruum-sidepanel-outlet"
                [class.shrinked-header]="isShrink$ | async"
                [class.open]="isSidepanelOpen$ | async"
                [style.padding-top.px]="
                    ((isShrink$ | async) || (isMSTeams() | async) || (isSimplifiedView() | async) || isMobile()
                        ? 48
                        : 92) + (alertHeight$ | async)
                "
            >
                <router-outlet name="sidepanel"> </router-outlet>
            </aside>
        </main>

        <ng-content select="[data-content='contact-us']"> </ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumScreenLayoutComponent implements OnInit {
    @HostBinding('class') hostClassList = 'ruum-app-screen d-flex flex-column flex-fill';

    @Input() bgColorClass = 'ruum-app-bg';

    isShrink$: Observable<boolean>;
    isSidepanelOpen$: Observable<boolean>;
    isScreePushed$: Observable<boolean>;
    isMainNavPanelOpen = true;
    alertHeight$: Observable<number>;
    hasLargeNavBar: boolean;

    mainNavPanelOpen$: Observable<boolean>;

    constructor(
        private sidepanelService: RuumSidepanelService,
        private sidenavService: SidenavService,
        private navbarService: NavbarService,
        private changeDetectorRef: ChangeDetectorRef,
        private appStoreWrapper: AppStoreWrapper,
        private uiStateService: RuumUIStateService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.isSidepanelOpen$ = this.sidepanelService.isSidepanelOpen$;
        this.isScreePushed$ = this.sidepanelService.isPanelOverlay$.pipe(
            tap(() => {
                this.changeDetectorRef.markForCheck();
                this.changeDetectorRef.detectChanges();
            }),
        );
        this.isShrink$ = this.navbarService.isShrink$;
        this.mainNavPanelOpen$ = this.sidenavService.isMainNavPanelOpen$;
        this.hostClassList += ` ${this.bgColorClass}`;
        this.hasLargeNavBar = this.route.root.firstChild.firstChild.snapshot.data.hasLargeNavBar;

        this.alertHeight$ = this.uiStateService.offline().pipe(
            delay(300),
            map(() => this.getAlertHeight()),
        );
    }

    getAlertHeight(): number {
        return this.navbarService.getCommonAlertHeight();
    }

    isMobile() {
        return isMobile();
    }

    isMSTeams(): Observable<boolean> {
        return this.appStoreWrapper
            .msTeamsStage()
            .pipe(distinctUntilChanged())
            .pipe(
                map((msTeamsStage) => {
                    return !!msTeamsStage;
                }),
            );
    }

    isSimplifiedView(): Observable<boolean> {
        return this.appStoreWrapper
            .simplifiedView()
            .pipe(distinctUntilChanged())
            .pipe(
                map((simplifiedView) => {
                    return !!simplifiedView;
                }),
            );
    }
}
