import { Day, TypeVariableDateFormat, Variable } from '@ruum/ruum-reducers';
import { assertUnreachable } from '../../common/utils.service';

export function isVariableEmpty(variable: Variable): boolean {
    return !getVariableTextContent(variable) || !getVariableTextContent(variable).trim();
}

export function getRegExpStringForVariable(minLen: number = 2): string {
    return String.raw`([a-zA-Z0-9-_ ]{${minLen},})`;
}

export function getVariableTextContent(variable: Variable): string {
    switch (variable.dataType) {
        case 'TEXT':
        case 'TEL':
        case 'EMAIL':
        case 'NUMBER':
        case 'URL':
            return variable.value;
        case 'DATE':
            return variable.value.year === 0 ? '' : getTextFromDate(variable.value, variable.format);
        case 'LIST':
            return (variable.value.find((v) => v.key === variable.key) || { value: '' }).value;
        default:
            assertUnreachable(variable);
    }
}

function formatNumber(num: string): string {
    const parts = num.split('.');
    if (parts[0]) {
        const chars = parts[0].split('');
        const segs = [];
        while (chars.length !== 0) {
            segs.unshift(chars.splice(-3));
        }
        parts[0] = segs.map((seg) => seg.join('')).join(',');
    }
    return parts.join('.');
}

export function getFormattedTextContent(variable: Variable): string {
    switch (variable.dataType) {
        case 'NUMBER':
            return formatNumber(variable.value);
        case 'TEL':
        case 'EMAIL':
        case 'TEXT':
        case 'DATE':
        case 'LIST':
            return getVariableTextContent(variable);
        case 'URL':
            return variable.value;
        default:
            assertUnreachable(variable);
    }
}

export function getTextFromDate(date: Day, format: TypeVariableDateFormat) {
    const FORMAT_MAP: { [key in TypeVariableDateFormat]: string } = {
        short: 'MMM D',
        medium: 'LL',
        long: 'dddd, MMMM D, YYYY',
    };
    return new Date(date.year, date.month - 1, date.day).format(FORMAT_MAP[format]);
}

export function getVariableFromSelection(selectionJson: { [key: string]: any } | void): string | null {
    if (!selectionJson) {
        return null;
    }
    let content = selectionJson.content;
    const reId = new RegExp(String.raw`^${getRegExpStringForVariable()}$`);
    while (1) {
        if (!content || content.length !== 1) {
            return null;
        }
        if (content[0].type === 'paragraph') {
            const nodes: any[] = content[0].content || [];
            let text = '';
            for (const node of nodes) {
                if (node.type === 'text') {
                    text += node.text;
                } else {
                    return null;
                }
            }
            return reId.test(text) ? text.trim() || null : null;
        } else {
            content = content[0].content;
        }
    }
}
