import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { CustomFieldValue, CustomFieldValueSelectionList } from '@ruum/ruum-reducers';
import {
    RuumDropdownItem,
    RuumDropdownList,
    UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE,
} from '../../../ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-custom-field-selection-list',
    template: `
        <!-- dropdown -->
        <ng-container *ngIf="!isReadOnly; else readOnlyTemplate">
            <ruum-select-dropdown-controller
                [selectTitle]="customField.title"
                [isMultiSelect]="customField.isMultiSelect"
                [options]="options"
                [selectedItems]="selectedValues"
                (selectItem)="onChange($event)"
                (selectItems)="onMultiSelectChange($event)"
                (unselectItem)="onUnselect($event)"
            ></ruum-select-dropdown-controller>
        </ng-container>

        <!-- disabled dropdown -->
        <ng-template #readOnlyTemplate>
            <div class="d-flex flex-fill form-control form-control-sm p-0 disabled">
                <ng-container *ngIf="selectedValues.length !== 0; else placeholderTemplate">
                    <div class="d-flex flex-fill flex-wrap minw-0 pl-1" [style.padding-top.px]="3">
                        <ng-container *ngIf="selectedValues.length !== 0">
                            <span
                                *ngFor="let selectedValue of selectedValues"
                                class="bg-light text-tiny text-truncate font-weight-bold p-1 mr-1 rounded"
                                [style.margin-bottom.px]="3"
                            >
                                {{ selectedValue.name }}</span
                            >
                        </ng-container>
                    </div>
                </ng-container>

                <!-- disabled dropdown when nothing is selected-->
                <ng-template #placeholderTemplate>
                    <span class="d-block w-100 pl-1 text-light disabled" [style.padding-top.px]="3">{{
                        placeholder
                    }}</span>
                </ng-template>

                <button class="btn disabled align-self-start btn-sm btn-icon btn-link-secondary p-1" type="button">
                    <i class="icon icon-cevron-down"></i>
                </button>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldSelectionListComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Input()
    set customField(customField: CustomFieldValueSelectionList) {
        this._customField = customField;
        if (customField) {
            this.createDropdownFromCustomField(customField);
            this.isMultiSelect = customField.isMultiSelect;
        } else {
            this.options = [];
            this.selectedValues = null;
            this.hasValue = false;
            this.isMultiSelect = false;
        }
    }
    get customField(): CustomFieldValueSelectionList {
        return this._customField;
    }

    @Input() isReadOnly = false;

    @Output() valueChange = new EventEmitter<CustomFieldValue>();

    options: RuumDropdownList = [];
    selectedValues: RuumDropdownList = [];
    hasValue = false;
    isMultiSelect = false;

    private _customField: CustomFieldValueSelectionList;

    get sections(): RuumDropdownList[] {
        return [this.options];
    }

    get placeholder(): string {
        return this.customField.placeholder ? this.customField.placeholder : UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE.name;
    }

    get customFieldValue(): string {
        return this.selectedValues.length > 0
            ? this.selectedValues.map((value) => value.name).join(', ')
            : this.placeholder;
    }

    constructor() {}

    ngOnInit() {}

    onChange(item: RuumDropdownItem) {
        const value = item.id;
        if (this.customField.value !== value) {
            const changedField = { ...this.customField, value: [value] };
            this.valueChange.emit(changedField);
        }
    }

    onMultiSelectChange(items: RuumDropdownItem[]) {
        const idLists = items.map((item) => item.id);
        if (this.valueHasChanged(this.customField, idLists)) {
            const changedField = { ...this.customField, value: idLists };
            this.valueChange.emit(changedField);
        }
    }

    onUnselect(event) {
        const changedField = { ...this.customField, value: [] };
        this.valueChange.emit(changedField);
    }

    private createDropdownFromCustomField(customField: CustomFieldValueSelectionList) {
        // for single select there will be just one value
        const selectedOptionIds: string[] = customField.value ? (customField.value as string[]) : [];
        const selectedValues = [];

        this.options = customField.options.map((option) => {
            if (selectedOptionIds.includes(option.id)) {
                selectedValues.push({ id: option.id, name: option.value });
            }
            return { id: option.id, name: option.value };
        });
        this.hasValue = selectedValues.length > 0;
        this.selectedValues = this.hasValue ? selectedValues : [];
    }

    private valueHasChanged(customField: CustomFieldValueSelectionList, items: RuumDropdownItem[]): boolean {
        if (!customField.value && items.length === 0) {
            return false;
        }
        if (!customField.value) {
            return true;
        }
        if ((customField.value as string[]).length === 0 && items.length === 0) {
            return false;
        }
        return true;
    }
}
