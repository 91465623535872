import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentSize } from '../ui-components.type';

@Component({
    selector: 'ruum-profile-role-group',
    template: `
        <ruum-profile-role
            *ngFor="let role of roles | slice: 0:maxShownProfiles"
            [name]="role.name"
            [size]="size"
            [disabled]="disabled"
            [disableTooltip]="disableTooltip"
        ></ruum-profile-role>
        <ruum-profile-others-counter
            *ngIf="roles.length > maxShownProfiles"
            [quantity]="roles.length - maxShownProfiles"
            [nameList]="getOtherRoles(roles, maxShownProfiles)"
            [size]="size"
            [disabled]="disabled"
        ></ruum-profile-others-counter>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileRoleGroupComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return ['d-flex', 'align-items-start', this.componentClass].filter((el) => !!el).join(' ');
    }
    @Input() roles = []; // FunctionalRoleListItem[]
    @Input() maxShownProfiles = 1;
    @Input() size: ComponentSize;
    @Input() disabled: boolean;
    @Input() disableTooltip: boolean;
    @Input() componentClass: string;

    constructor() {}

    ngOnInit() {}

    getOtherRoles(roles: any, maxShownProfiles: number): string[] {
        return roles.slice(maxShownProfiles, roles.length).map((el) => el.name);
    }
}
