import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { TrackingConnector } from '../common/trackingConnector.service';
import { WindowRef } from '../common/utils/window.ref';

@Component({
    selector: 'upgrade-dialog',
    template: `
        <div class="d-flex flex-column align-items-center p-4">
            <button class="btn btn-link-secondary btn-icon ruum-close-button" type="button" (click)="close()">
                <i class="icon icon-cancel"></i>
            </button>

            <div class="mt-4"></div>
            <ruum-illustration-paywall-pro [width]="240"></ruum-illustration-paywall-pro>
            <h2 class="mt-5 mx-8 px-8 text-center">
                This feature is available to <br />
                Enterprise users only
            </h2>
            <p class="mt-5 mb-0 px-5 text-small text-center text-dark">
                For more information about Enterprise features, see our SAP Help Portal:
            </p>
            <button class="mt-5 btn btn-primary btn-lg px-6 py-3" (click)="goToUpgradePage()">
                Help Portal
            </button>

            <p class="mt-5 text-tiny text-center text-secondary">
                Have a question? Then access the
                <a href="https://support.sap.com/en/index.html" class="text-secondary">SAP Support Portal</a>
            </p>
        </div>
    `,
})
export class UpgradeDialogComponent implements OnInit {
    @Input() featureName = 'This feature';

    constructor(
        private activeModal: NgbActiveModal,
        private trackingConnector: TrackingConnector,
        private readonly windowRef: WindowRef,
    ) {}

    ngOnInit() {
        this.trackingConnector.trackEventInferCategoryFromUrl('upgrade-dialog', 'opened');
    }

    close() {
        this.trackingConnector.trackEventInferCategoryFromUrl('upgrade-dialog', 'closed');
        this.activeModal.close();
    }

    goToUpgradePage() {
        this.trackingConnector.trackEventInferCategoryFromUrl('upgrade-dialog', 'link_clicked');
        this.windowRef.open(environment.PAYWALL_UPGRADE_URL, '_blank');
    }
}

