import { RuumAction, RuumTask } from '@ruum/ruum-reducers';
import { LOBBY_LIST_ACTIONS } from '../lobby/shared/lobby-list/lobby-list.actions';
import { TASK_LIST_ACTIONS } from './connectors/tasks/task-list.actions';
import { KANBAN_ACTIONS } from './kanban/kanban.actions';

/**
 * The action types should be globally unique.
 */
export const ACTION_TYPES = {
    MAILS_COUNTER_FOR_USER_LOADED: 'MAILS_COUNTER_FOR_USER_LOADED',
    MAILS_FOR_USER_LOADED: 'MAILS_FOR_USER_LOADED',
    MAIL_ASSIGNED_TO_RUUM: 'MAIL_ASSIGNED_TO_RUUM',

    /** sets app state, lobby and ruum, to busy or not. */
    SET_BUSY: 'SET_BUSY',
    SET_OFFLINE: 'SET_OFFLINE',
    SET_IE_OR_EDGE: 'SET_IE_OR_EDGE',
    SET_MS_TEAMS_STAGE: 'SET_MS_TEAMS_STAGE',
    SET_SIMPLIFIED_VIEW: 'SET_SIMPLIFIED_VIEW',

    USER_PROFILE_LOADED: 'USER_PROFILE_LOADED',
    EDIT_USER_SETTINGS: 'EDIT_USER_SETTINGS',
    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',

    USER_SEEN_RELEASE: 'USER_SEEN_RELEASE',
    USER_SEEN_WELCOME_MESSAGE: 'USER_SEEN_WELCOME_MESSAGE',

    ...LOBBY_LIST_ACTIONS,
    ...TASK_LIST_ACTIONS,
    ...KANBAN_ACTIONS,
};

export interface AddTasksToTimelineActionPayload {
    tasks: RuumTask[];
    canvasSectionsWithTasks: CanvasSectionWithTasks[];
}

export interface CanvasSectionWithTasks {
    sectionId: string;
    sectionName: string;
    tasks: string[];
}

export interface CanvasSectionWithPolls {
    sectionId: string;
    sectionName: string;
    polls: string[];
}

export interface ShowInfoOfEventActionPayload {
    objectId: string;
    objectType: string;
}

export interface ShowObjectDetailsActionPayload {
    objectId: string;
    objectType: string;
}

export interface SetEventInfoVisibilityActionPayload {
    visible: boolean;
}

export interface ActionTypeAndPayload<T extends RuumAction = any> {
    type: T['type'];
    payload: T['payload'];
    idempotencyKey?: string;
}
