import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AddExternalTableDefinitionAction,
    DeleteExternalTableDefinitionAction,
    TableDefinition,
    UpdateExternalTableDefinitionAction,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../app.store';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { getRandomId } from '../../utils.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { SelectedEnterpriseService } from './../enterprise/selected-enterprise.service';
import { TableDefinitionListFilters, TableDefinitionListSortBy } from './table-definition-list.model';
import { TableDefinitionLoadedAction, TABLE_DEFINITION_LIST_ACTION_TYPES } from './table-definition-list.reducer';

@Injectable({ providedIn: 'root' })
export class TableDefinitionListService extends PaginatedListLoader<
    TableDefinition,
    TableDefinitionListFilters,
    TableDefinitionListSortBy
> {
    readonly workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly enterpriseId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly search$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly tableDefinition$: Observable<TableDefinition[]>;

    private orderBy$ = new BehaviorSubject<OrderedListParams<TableDefinitionListSortBy>>(undefined);

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        private appStore: AppStoreWrapper,
        protected authService: AuthService,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
    ) {
        super(alertService, authService);
        this.setUpObservables();
    }

    protected getFilters$(): Observable<TableDefinitionListFilters> {
        return combineLatest([this.enterpriseId$, this.workspaceId$, this.search$]).pipe(
            map<any, TableDefinitionListFilters>(([enterpriseId, workspaceId, search]) => ({
                enterpriseId,
                workspaceId,
                search,
            })),
        );
    }

    resetFilters() {
        this.enterpriseId$.next(undefined);
        this.workspaceId$.next(undefined);
        this.search$.next(undefined);
    }

    orderBy(by: TableDefinitionListSortBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
    }

    protected getData(
        page: number,
        filters: TableDefinitionListFilters,
        orderBy: OrderedListParams<TableDefinitionListSortBy>,
    ): Observable<PaginatedList<TableDefinition>> {
        return this.readModelConnector.getTableDefinitions({ page }, filters, orderBy);
    }

    protected getOrderBy$(): Observable<OrderedListParams<TableDefinitionListSortBy>> {
        return this.orderBy$;
    }

    getStoreRows$(): Observable<TableDefinition[]> {
        return this.store.select('tableDefinitionList').pipe(map((data) => data.rows));
    }

    private setUpObservables(): void {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<TableDefinitionLoadedAction>('TABLE_DEFINITION_LOADED', {
                page,
            });
        });
    }

    getStoreData$(): Observable<PaginatedList<TableDefinition>> {
        return this.store.select('tableDefinitionList');
    }

    // TODO: add types
    createTableDefinition(data): Promise<any> {
        const payload: AddExternalTableDefinitionAction['payload'] = {
            tableId: getRandomId('external_table_definition_'),
            systemId: data.systemId,
            path: data.path,
            columns: data.columns,
            name: data.name,
            apiType: data.apiType,
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<AddExternalTableDefinitionAction>(
                enterpriseId,
                'ADD_EXTERNAL_TABLE_DEFINITION',
                payload,
            )
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    TABLE_DEFINITION_LIST_ACTION_TYPES.ADD_EXTERNAL_TABLE_DEFINITION_TO_LIST,
                    payload,
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }

    // TODO: add types
    updateTableDefinition(tableId, data): Promise<any> {
        const payload: UpdateExternalTableDefinitionAction['payload'] = {
            tableId,
            editFields: {
                name: data.name,
                systemId: data.systemId,
                path: data.path,
                apiType: data.apiType,
                columns: data.columns,
            },
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<UpdateExternalTableDefinitionAction>(
                enterpriseId,
                'UPDATE_EXTERNAL_TABLE_DEFINITION',
                payload,
            )
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    TABLE_DEFINITION_LIST_ACTION_TYPES.UPDATE_EXTERNAL_TABLE_DEFINITION_TO_LIST,
                    payload,
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }

    deleteTableDefinition(tableId: string): Promise<any> {
        const payload: DeleteExternalTableDefinitionAction['payload'] = {
            tableId,
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<DeleteExternalTableDefinitionAction>(
                enterpriseId,
                'DELETE_EXTERNAL_TABLE_DEFINITION',
                payload,
            )
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    TABLE_DEFINITION_LIST_ACTION_TYPES.DELETE_EXTERNAL_TABLE_DEFINITION_FROM_LIST,
                    payload,
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }
}
