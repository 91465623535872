import { Injectable } from '@angular/core';

type GenericObject = { [key: string]: any };
type ErrorArgs = [string, Error, GenericObject?];

@Injectable({ providedIn: 'root' })
export class LoggerService {
    constructor() {}

    debug(...args: any[]) {
        try {
            console.log.apply(null, args);
        } catch (e) {}
    }

    log(...args: any[]) {
        try {
            console.log.apply(null, args);
        } catch (e) {}
    }

    error(...args: ErrorArgs) {
        try {
            console.error.apply(null, args);
        } catch (e) {}
    }

    warn(...args: any[]) {
        try {
            console.warn.apply(null, args);
        } catch (e) {}
    }
}
