import { style, animate, animateChild, query, sequence, group, AnimationMetadata } from '@angular/animations';
import { isChrome, isFirefox } from '../utils.service';
import { timingInMedium, timingOutMedium } from './animation-timings';

export const pageAnimationTransition = (
    prevPageLeaveAnimation: AnimationMetadata | AnimationMetadata[] = [animateChild()],
    nextPageEnterAnimation: AnimationMetadata | AnimationMetadata[] = [animateChild()],
) => {
    const steps = [];
    steps.push(style({ position: 'relative' }));
    steps.push(
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, width: '100%' }), { optional: true }),
    );
    steps.push(query(':enter', style({ opacity: '0' }), { optional: true }));
    steps.push(query(':leave', prevPageLeaveAnimation, { optional: true }));

    if (isChrome() || isFirefox()) {
        steps.push(
            group([
                query(':leave', animate('0ms', style({ opacity: '0' })), { optional: true }),
                query(':enter', animate('0ms', style({ opacity: '1' })), { optional: true }),
            ]),
        );
    } else {
        steps.push(
            group([
                query(':leave', animate(timingOutMedium, style({ opacity: '0' })), { optional: true }),
                query(':enter', animate(timingInMedium, style({ opacity: '1' })), { optional: true }),
            ]),
        );
    }
    steps.push(query(':enter', nextPageEnterAnimation, { optional: true }));
    return steps;
};
