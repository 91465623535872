import { AuthenticationAction, AuthenticationFlow } from './authentication.model';

function getDefault(): AuthenticationFlow {
    return {
        email: '',
        phaseId: '1-email',
        redirectUrl: undefined,
        token: {},
        additionalInfo: {},
    };
}

export function AuthenticationReducer(currentState: AuthenticationFlow = getDefault(), action: AuthenticationAction) {
    switch (action.type) {
        case 'GO_TO_PHASE':
            return { ...currentState, ...{ phaseId: action.payload.phaseId } };
        case 'SET_EMAIL':
            return { ...currentState, ...{ email: action.payload.email } };
        case 'SET_REDIRECT_URL':
            return { ...currentState, ...{ redirectUrl: action.payload.redirectUrl } };
        case 'SET_TOKEN':
            const newTokenEntry = {};
            newTokenEntry[action.payload.tokenType] = action.payload.token;
            return { ...currentState, ...{ token: { ...currentState.token, ...newTokenEntry } } };
        case 'SET_ADDITIONAL_INFO':
            return { ...currentState, ...{ additionalInfo: { ...currentState.additionalInfo, ...action.payload } } };
        default:
            return currentState;
    }
}
