import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MenuService {
    readonly onMenuOptionClick$: Observable<MouseEvent>;
    readonly optionsWithIcon$: Observable<boolean>;

    private _onMenuOptionClick$ = new Subject<MouseEvent>();
    private optionsWithIconCount$ = new BehaviorSubject<number>(0);

    constructor() {
        this.optionsWithIcon$ = this.optionsWithIconCount$.pipe(map((optionCount) => optionCount > 0));
        this.onMenuOptionClick$ = this._onMenuOptionClick$.asObservable();
    }

    onClick(event: MouseEvent) {
        this._onMenuOptionClick$.next(event);
    }

    addOptionsWithIcon() {
        this.optionsWithIconCount$.next(this.optionsWithIconCount$.value + 1);
    }

    removeOptionsWithIcon() {
        this.optionsWithIconCount$.next(this.optionsWithIconCount$.value - 1);
    }
}
