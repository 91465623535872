import { Injectable } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class RuumDropdownService {
    private popovers: NgbPopover[] = [];

    registerPopover(popover: NgbPopover) {
        this.popovers = [...this.popovers, popover];
    }

    closeAllPopovers() {
        this.popovers.forEach((popover: NgbPopover) => popover.close());
        this.popovers = [];
    }
}
