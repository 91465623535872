import { assertUnreachable } from '../utils.service';

export const DEAD_ZONE = 10;
export type PopupAlign = 'left' | 'right' | 'center' | 'top-left';

export function getPopupPosition(
    height: number,
    width: number,
    align: PopupAlign,
    refPos: { left: number; top: number; right: number; bottom: number },
): { left: number; top: number } {
    const windowHeight = window.innerHeight - DEAD_ZONE * 2;
    const windowWidth = window.innerWidth - DEAD_ZONE * 2;

    const maxY = windowHeight + DEAD_ZONE - height;
    const maxX = windowWidth + DEAD_ZONE - width;

    let left: number;
    let top: number;

    if (align === 'left') {
        if (refPos.bottom <= maxY) {
            // if bottom space is enough
            left = refPos.left;
            top = refPos.bottom;
        } else if (refPos.top > height + DEAD_ZONE) {
            // if up space is enough
            left = refPos.left;
            top = refPos.top - height;
        } else if (refPos.left <= maxX) {
            // if right space is enough
            left = refPos.right;
            top = refPos.top;
        } else {
            left = refPos.left - width;
            top = refPos.top;
        }
    } else if (align === 'right') {
        if (refPos.bottom <= maxY) {
            // if bottom space is enough
            left = refPos.right - width;
            top = refPos.bottom;
        } else if (refPos.top > height + DEAD_ZONE) {
            // if up space is enough
            left = refPos.right - width;
            top = refPos.top - height;
        } else if (refPos.left >= width + DEAD_ZONE) {
            // if left space is enough
            left = refPos.left - width;
            top = refPos.top;
        } else {
            left = refPos.right;
            top = refPos.top;
        }
    } else if (align === 'center') {
        left = (refPos.left + refPos.right - width) / 2;
        if (refPos.bottom <= maxY) {
            // if bottom space is enough
            top = refPos.bottom;
        } else {
            top = refPos.top - height;
        }
    } else if (align === 'top-left') {
        left = refPos.left;
        if (refPos.top - DEAD_ZONE >= height) {
            top = refPos.top - height;
        } else {
            top = refPos.bottom;
        }
    } else {
        assertUnreachable(align);
    }
    return {
        left: Math.min(Math.max(DEAD_ZONE, left), maxX),
        top: Math.min(Math.max(DEAD_ZONE, top), maxY),
    };
}
