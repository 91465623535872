import { Injectable } from '@angular/core';
import { TableRow } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, scan } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { RuumAlertService } from '../../components/alert/alert.service';
import { PaginatedList } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { TableDataFilters } from './tableData.model';

@Injectable({ providedIn: 'root' })
export class TableRecordListStore extends PaginatedListLoader<TableRow, TableDataFilters, null> {
    readonly tableId$ = new BehaviorSubject<string>(undefined);
    readonly search$ = new BehaviorSubject<string>(undefined);
    readonly list$ = new BehaviorSubject<PaginatedList<TableRow>>(getDefaultPaginatedList());

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.getListObservable()
            .pipe(
                scan((currentState, page) => {
                    if (!!currentState && page.currentPage > 1) {
                        return {
                            ...page,
                            rows: [...currentState.rows, ...page.rows],
                        };
                    } else {
                        return page;
                    }
                }),
            )
            .subscribe((page) => {
                this.list$.next(page);
            });
    }

    loadTable(tableId: string) {
        this.resetFilters();
        this.list$.next(getDefaultPaginatedList());
        this.tableId$.next(tableId);
    }

    resetFilters() {
        this.tableId$.next(undefined);
        this.search$.next(undefined);
    }

    protected getData(page: number, filters: TableDataFilters): Observable<PaginatedList<TableRow>> {
        return this.readModelConnector.getTableRows({ page }, filters.id, filters);
    }

    protected getFilters$(): Observable<TableDataFilters> {
        return combineLatest([this.tableId$, this.search$.pipe(debounceTime(400))]).pipe(
            map(([id, search]) => ({ id, search })),
            filter((filters) => !!filters.id),
        );
    }

    getStoreData$(): Observable<PaginatedList<TableRow>> {
        return this.list$;
    }
}
