import { Pipe, PipeTransform } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';

@Pipe({ name: 'dateIsOverdue' })
export class IsOverduePipe implements PipeTransform {
    transform(day?: Day | Date): boolean {
        if (!day) {
            return false;
        }
        if (!(day instanceof Date)) {
            day = new Date(day.year, day.month - 1, day.day + 1);
        }
        return day.getTime() < Date.now();
    }
}
