import { RuumAction, editItemWithId, ProjectReducerDecoratorsActionTypes } from '@ruum/ruum-reducers';
import { Email } from './../ruum.model';

export function MailsReducerDecorator(ruumMails: Email[] = [], action: RuumAction): Email[] {
    let newState: Email[];

    switch (action.type) {
        case ProjectReducerDecoratorsActionTypes.TOGGLE_COLLAPSE_MAIL_ATTACHMENTS:
            newState = editItemWithId(ruumMails, action.payload.mailId, {
                showingAttachments: action.payload.showingAttachments,
            });
            break;
        default:
            newState = ruumMails;
    }

    return newState;
}
