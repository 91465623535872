import {
    CustomFieldDefinitionReducer,
    isCustomFieldDefinitionReducerAction,
    ProjectAction,
    RuumAction,
} from '@ruum/ruum-reducers';
import { CUSTOM_FIELD_LIST_ACTION_TYPES } from '../workspace/customFieldsList/customFieldsList.reducer';
import { CustomFieldListItem } from './customFields.model';

export const CUSTOM_FIELD_WEBAPP_ACTION_TYPES = {
    ADD_OR_REPLACE_CUSTOM_FIELD: 'ADD_OR_REPLACE_CUSTOM_FIELD',
};

export type AddOrReplaceCustomField = ProjectAction<
    'ADD_OR_REPLACE_CUSTOM_FIELD',
    {
        fields: CustomFieldListItem[];
    }
>;

export function CustomFieldsReducer(currentState: CustomFieldsMap = {}, action: RuumAction): CustomFieldsMap {
    if (isCustomFieldDefinitionReducerAction(action.type)) {
        return runCustomFieldDefinitionReducer(currentState, action);
    }

    switch (action.type) {
        case CUSTOM_FIELD_LIST_ACTION_TYPES.CUSTOM_FIELDS_LOADED:
        case CUSTOM_FIELD_WEBAPP_ACTION_TYPES.ADD_OR_REPLACE_CUSTOM_FIELD:
            const rows = action.payload.page ? action.payload.page.rows : action.payload.fields;
            return addOrReplace(currentState, rows);
        default:
            return currentState;
    }
}

function addOrReplace(currentState: CustomFieldsMap, entries: CustomFieldListItem[]): CustomFieldsMap {
    const newEntries = getMap(entries);
    return {
        ...currentState,
        ...newEntries,
    };
}

function getMap(entries: CustomFieldListItem[]): CustomFieldsMap {
    const map: CustomFieldsMap = {};

    entries.forEach((entry) => {
        map[entry.id] = entry;
    });

    return map;
}

function runCustomFieldDefinitionReducer(currentState: CustomFieldsMap, action: RuumAction): CustomFieldsMap {
    const workspaceId = action.entityId;
    const list = Object.values(currentState).filter((field) => field.sourceId === workspaceId);

    const changed = <CustomFieldListItem[]>CustomFieldDefinitionReducer(list, action);
    return addOrReplace(currentState, changed);
}

export interface CustomFieldsMap {
    [fieldId: string]: CustomFieldListItem;
}
