import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

type EmptyState = 'noResults' | 'noSearchResults' | 'noFilterResults';

@Component({
    selector: 'ruum-empty-state',
    template: `
        <ng-container [ngSwitch]="emptyState">
            <ng-container *ngSwitchCase="'noSearchResults'">
                <ruum-illustration-search-results
                    [width]="275"
                    [componentClass]="'mb-6'"
                ></ruum-illustration-search-results>
                <h3 class="text-center mb-3">No Results for Your Search</h3>
                <div class="text-center text-secondary mb-3" [style.max-width.px]="400">
                    Try being less specific or using different keywords.
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'noFilterResults'">
                <ng-content select="[data-content=no-filter-results]"> </ng-content>
                <h3 class="text-center mb-3">{{ filterMessage }}</h3>
                <div class="text-center text-secondary mb-3" [style.max-width.px]="400">
                    Try to change filter parameters.
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <div class="d-flex flex-column flex-fill justify-content-center align-items-center px-7 pb-7">
                    <ng-content select="[data-content=no-results]"> </ng-content>
                    <h3 class="text-center mb-3">{{ noResultsMessage }}</h3>
                    <ng-content select="[data-content=no-results-content]"></ng-content>
                </div>
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
    @HostBinding('class') hostClassList =
        'd-flex flex-column flex-fill justify-content-center align-items-center px-7 pb-7';

    @Input() searchApplied = false;
    @Input() filterApplied = false;
    @Input() message: string;
    @Input() items: any[] = [];
    @Input() entityName = 'Results';

    constructor() {}

    get emptyState(): EmptyState {
        if (this.searchApplied && this.items.length === 0) {
            return 'noSearchResults';
        } else if (this.filterApplied && this.items.length === 0) {
            return 'noFilterResults';
        } else {
            return 'noResults';
        }
    }

    get filterMessage() {
        return this.message ? this.message : `No ${this.entityName} Found`;
    }

    get noResultsMessage() {
        return this.message ? this.message : `No ${this.entityName} Available`;
    }
}
