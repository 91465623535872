import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../app.store';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { PaginatedList } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ReadModelBackendConnector, WorkspaceFilters, WorkspaceListItem } from '../readModelConnector.service';
import { WorkspaceListLoadedAction } from './workspace-list.reducer';

@Injectable({ providedIn: 'root' })
export class WorkspaceListService extends PaginatedListLoader<WorkspaceListItem, WorkspaceFilters, void> {
    readonly workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    enterpriseId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly list$: Observable<WorkspaceListItem[]>;

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.setUpObservables();
        this.list$ = this.workspaceList();
        this.loadList();
    }

    protected getFilters$(): Observable<WorkspaceFilters> {
        return combineLatest([this.workspaceId$, this.enterpriseId$]).pipe(
            map(([workspaceId, enterpriseId]) => ({
                id: workspaceId,
                enterpriseId,
            })),
        );
    }

    protected getData(page: number, filters: WorkspaceFilters): Observable<PaginatedList<WorkspaceListItem>> {
        return this.readModelConnector.getWorkspaces({ page, pageSize: 50 }, filters);
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<WorkspaceListLoadedAction>('WORKSPACE_LIST_LOADED', { page });
        });
    }

    workspaceList(): Observable<WorkspaceListItem[]> {
        return this.store.select('workspaceList').pipe(map((page) => page.rows));
    }

    getWorkspaceListItemById(id): Observable<WorkspaceListItem> {
        return this.workspaceList().pipe(
            map((list) => {
                return list.find((item) => item.id === id);
            }),
        );
    }

    getStoreData$(): Observable<PaginatedList<WorkspaceListItem>> {
        return this.store.select('workspaceList');
    }
}
