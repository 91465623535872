import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { canvasSchema } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CanvasService } from '../../ruum/canvas/canvas.service';
import { CanvasSectionsService } from '../../ruum/canvas/sections/canvasSections.service';
import { CanvasTasksService } from '../../ruum/canvas/sections/tasks/canvasTasks.service';
import { SectionsService } from '../../ruum/section/sections.service';
import { RuumTasksService } from '../../ruum/tasks/tasks.service';
import { RuumUIStateService } from '../../ruum/ui-state/ruumUIState.service';
import { TemplateActionsService } from '../../shared/templates/template-actions.service';
import { AppStoreWrapper } from '../appStoreWrapper.service';
import { CommonService } from '../common.service';
import { RuumAlertService } from '../components/alert/alert.service';
import { LobbyListItem } from '../connectors/lobbyList/lobby-list.model';
import { SelectedProjectService } from '../connectors/project/selectedProject.service';
import { TemplateListItemService } from '../connectors/templates/template-list-item.service';
import { ServiceHelper } from '../serviceHelper';
import { isMobile } from '../utils.service';

export interface SidePanelDisplayNode {
    id?: string;
    icon?: string;
    path?: string;
    name: string;
    type: string;
    tooltip?: string;

    action?: string;
    active?: boolean;
    nodes?: SidePanelDisplayNode[];
    actions?: Map<ActionToken, NavActions>;
    renderSubNode?: boolean;
    workspaceAdmin?: boolean;
}

export interface NavActions {
    action?: string;
    active?: boolean;
    label?: string;
    url?: string;
    id?: string;
}

export enum ActionToken {
    ADD,
    EXPAND,
    DRILL_DOWN,
    DRAGGABLE,
    OPEN_FOLDER_CONTEXT,
    OPEN_CANVAS,
    BUTTON,
}

@Injectable({ providedIn: 'root' })
export class SidenavService {
    constructor(
        private router: Router,
        private selectedProjectService: SelectedProjectService,
        private appStoreWrapper: AppStoreWrapper,
        private sectionsService: SectionsService,
        private canvasService: CanvasService,
        private templateListItemService: TemplateListItemService,
        private commonService: CommonService,
        private alertService: RuumAlertService,
        private ruumUIStateService: RuumUIStateService,
        private tasksService: RuumTasksService,
        private canvasTasksService: CanvasTasksService,
        private canvasSectionsService: CanvasSectionsService,
        private serviceHelper: ServiceHelper,
        private templateActionsService: TemplateActionsService,
    ) {
        this.bootstrapObservables();
        this.routeEvent(this.router);
        if (isMobile()) {
            this.onMainNavPanelDeactivate();
        } else {
            this.onMainNavPanelActivate();
        }
    }

    private isSectionVisible: Map<string, boolean> = new Map();
    private panelVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isDesktop: boolean;
    private isAuth$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private isMainNavPanelOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private renewSubpanelSubject: Subject<boolean> = new Subject<boolean>();
    private renewMainNavPanel$: Observable<boolean>;

    isMainNavPanelOpen$: Observable<boolean> = this.isMainNavPanelOpenSubject.asObservable();
    isMainNavPanelOpen = null;

    private IS_READONLY = false;

    routeEvent(router: Router) {
        this.renewMainNavPanel$ = this.renewSubpanelSubject.asObservable();
        router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.renewNavPanel();
                this.isAuth$.next(!!e.url && e.url.startsWith('/auth'));
            }
        });
    }

    updateMainNavPanel(): Observable<boolean> {
        return this.renewMainNavPanel$;
    }

    private renewNavPanel() {
        this.IS_READONLY = this.selectedProjectService.getIsReadOnly();
        this.renewSubpanelSubject.next(true);
    }

    setMainNavPanelOpen(shallMainNavPanelBeOpen: boolean) {
        this.isMainNavPanelOpenSubject.next(shallMainNavPanelBeOpen);
    }

    private bootstrapObservables() {
        // Visibility of global Panel
        this.isGlobalPanelVisible$().subscribe((visible) => {
            this.panelVisible$.next(visible);
            if (!visible) {
                this.onMainNavPanelDeactivate();
            }
        });
    }

    isIncreasedFontSize(): boolean {
        // use increased font when we are on mobile device
        return !this.isDesktop;
    }

    setIncreasedFontSize(isIncreased: boolean) {
        this.isDesktop = isIncreased;
    }

    isReadOnly(): boolean {
        return this.IS_READONLY;
    }

    mapSidePanelDisplayNode(lobbyListItem: LobbyListItem): SidePanelDisplayNode {
        const icon: string = lobbyListItem.type === 'project' ? 'file' : 'folder-filled';
        const actions: Map<ActionToken, NavActions> = new Map<ActionToken, NavActions>();
        return {
            id: lobbyListItem.id,
            icon,
            action: 'router:/ruum/' + lobbyListItem.id,
            name: lobbyListItem.name,
            renderSubNode: false,
            type: lobbyListItem.type,
            actions,
        };
    }

    private dispatchWindowResizeEvent() {
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
            const resizeEvent: any = window.document.createEvent('UIEvents');
            if (resizeEvent && resizeEvent.initUIEvent) {
                resizeEvent.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(resizeEvent);
            }
        } else {
            window.dispatchEvent(new Event('resize'));
        }
    }

    onMainNavPanelActivate() {
        this.isMainNavPanelOpenSubject.next(true);
        this.isMainNavPanelOpen = true;
        this.setMainNavPanelOpen(this.isMainNavPanelOpen);
        this.dispatchWindowResizeEvent();
    }

    onMainNavPanelDeactivate() {
        this.isMainNavPanelOpenSubject.next(false);
        this.isMainNavPanelOpen = false;
        this.setMainNavPanelOpen(this.isMainNavPanelOpen);
        // dispatch resize to adjust gant timeline e.g.
        this.dispatchWindowResizeEvent();
    }

    routerNavigate(path) {
        this.router.navigateByUrl(path);
    }

    nodeAction(path: string) {
        const navigationPath = path.split('router:')[1];
        if (navigationPath) {
            this.routerNavigate(navigationPath);
        }
    }

    getSectionVisible(nodeId): boolean {
        let state = this.isSectionVisible.get(nodeId);

        // set always to true on first call...
        if (typeof state === 'undefined') {
            this.isSectionVisible.set(nodeId, true);
        }
        try {
            state = localStorage.getItem('section-' + nodeId) === 'false' ? false : true;
        } catch (err) {}
        return state;
    }

    sectionVisible(nodeId: string, isVisible: boolean) {
        if (nodeId === 'lobby' || nodeId === 'sections' || nodeId === 'workspace') {
            this.isSectionVisible.set(nodeId, isVisible);

            try {
                localStorage.setItem('section-' + nodeId, isVisible.toString());
            } catch (err) {}
        }
    }

    collapseSection(id) {
        this.sectionsService.collapseSection(id);
    }

    expandSection(id) {
        this.sectionsService.expandSection(id);
    }

    duplicateSection(id) {
        this.sectionsService.duplicateSection(id);
    }

    deleteSection(id) {
        this.sectionsService.delete(id);
    }

    sectionClicked($event) {
        this.canvasService.scrollToNodeId($event.id);
    }

    createSectionTemplateFrom(id) {
        this.createSectionTemplateFromId(id);
    }

    createSectionTemplateFromScratch() {
        this.addSectionFromSectionTemplate();
    }

    createTaskSectionFromScratch() {
        this.addTasksSection();
    }

    addSection() {
        if (!this.ruumUIStateService.isAppOffline()) {
            const sectionId = this.serviceHelper.getRandomId('section');
            this.canvasSectionsService.addEmptySectionToTheTop(sectionId);
            setTimeout(() => this.canvasService.scrollToNodeId(sectionId), 50);
        }
    }

    /* TODO MARKUS: Remove this and reuse createSectionTemplateFromId() from canvas might be placed in a service */

    private createSectionTemplateFromId(sectionId) {
        if (this.ruumUIStateService.isAppOffline()) {
            return;
        }
        this.canvasSectionsService.getSectionIdOfIndex(0);
        this.commonService.setBusyWithoutAuthentication(true);
        this.templateListItemService
            .createSectionTemplateFromDeprecated(sectionId)
            .then((ruumId) => {
                this.commonService.setBusyWithoutAuthentication(true);
                this.router.navigateByUrl(`/templates/sections/${ruumId}`);
            })
            .catch((err) => {
                this.commonService.setBusyWithoutAuthentication(false);
                this.alertService.info(`Error creating section template`);
            });
    }

    /* TODO MARKUS: Remove this and reuse addTasksSection() from canvas might be placed in a service */

    addTasksSection() {
        if (this.ruumUIStateService.isAppOffline()) {
            return;
        }
        const sectionId = this.serviceHelper.getRandomId('section');
        const emptyParagraph = canvasSchema.nodes.paragraph.create();
        const tasks = Array(3)
            .fill(null)
            .map(() => this.canvasTasksService.createTaskNode());
        const section = this.canvasSectionsService.createSection(
            sectionId,
            [
                this.createEmParagraph(
                    `Be sure to set the task's start and end dates and then assign it to the appropriate` +
                        ` person/people. If you need to add a bit more context, just add a comment!`,
                ),
                ...tasks,
                emptyParagraph,
            ],
            'Tasks',
        );
        this.sectionsService.createIfNeeded(section.attrs.id);
        this.canvasSectionsService.addSectionToTheTop(section);

        tasks.forEach((task, i) =>
            this.tasksService.addTaskWithIdFromCanvas(task.attrs.id, `Task ${i + 1}`, false, null),
        );

        setTimeout(() => this.canvasService.scrollToNodeId(sectionId), 50);
    }

    private createEmParagraph(text: string) {
        return canvasSchema.nodes.paragraph.create({}, canvasSchema.text(text, [canvasSchema.marks.em.create()]));
    }

    /* TODO MARKUS: Remove this and reuse addSectionTemplate() from canvas might be placed in a service */

    addSectionFromSectionTemplate() {
        if (this.ruumUIStateService.isAppOffline()) {
            return;
        }

        const sectionId = this.canvasSectionsService.getSectionIdOfIndex(0);
        this.templateActionsService.selectSectionTemplate(sectionId).then((section) => {
            if (section) {
                this.scrollToNodeId(section.id);
            }
        });
    }

    scrollToNodeId(nodeId: string): void {
        setTimeout(() => {
            this.canvasService.scrollToNodeId(nodeId);
        }, 500);
    }

    isGlobalPanelVisible$(): Observable<boolean> {
        return combineLatest([this.isMSTeams(), this.isSimplifiedView(), this.isAuth$]).pipe(
            map(([isMSTeams, isSimplifiedView, isAuth]) => {
                if (isMSTeams || isSimplifiedView || isAuth) {
                    return false;
                } else {
                    return true;
                }
            }),
        );
    }

    isMSTeams(): Observable<boolean> {
        return this.appStoreWrapper
            .msTeamsStage()
            .pipe(distinctUntilChanged())
            .pipe(
                map((msTeamsStage) => {
                    return !!msTeamsStage;
                }),
            );
    }

    isSimplifiedView(): Observable<boolean> {
        return this.appStoreWrapper
            .simplifiedView()
            .pipe(distinctUntilChanged())
            .pipe(
                map((simplifiedView) => {
                    return !!simplifiedView;
                }),
            );
    }
}
