import { defaultNotificationSettings, NotificationSettings, RuumAction } from '@ruum/ruum-reducers';
import { ACTION_TYPES } from '../common/actions';
import { ProfileListItem } from '../common/connectors/profile/profile.model';

const defaultUserProfile = (): ProfileListItem => ({
    id: undefined,
    email: undefined,
    notificationSettings: defaultNotificationSettings,
    hasSeenWelcomeMessage: Date.now(),
    authorizations: {
        accountType: 'BASIC',
    },
    accountType: 'BASIC',
    color: '#ffffff',
    emailVerified: true,
    enterpriseId: undefined,
    marketingEmailsEnabled: true,
    technicalUser: false,
    timezone: 'DE',
});

// TODO: move it to connectors/profile
export function UserProfileReducer(
    currentState: ProfileListItem = defaultUserProfile(),
    action: RuumAction,
): ProfileListItem {
    let newState: ProfileListItem;

    switch (action.type) {
        case ACTION_TYPES.USER_PROFILE_LOADED:
            newState = Object.assign(defaultUserProfile(), action.payload);
            break;
        case ACTION_TYPES.EDIT_USER_SETTINGS:
            newState = {
                ...currentState,
                ...{
                    notificationSettings: UserSettingsReducer(currentState.notificationSettings, action),
                },
            };
            break;
        case ACTION_TYPES.USER_SEEN_WELCOME_MESSAGE:
            newState = Object.assign({}, currentState, {
                hasSeenWelcomeMessage: Date.now(),
            });
            break;
        case ACTION_TYPES.UPDATE_USER_PROFILE:
            newState = { ...currentState };
            newState[action.payload.field] = action.payload.value;
            break;
        default:
            newState = currentState;
    }

    return newState;
}

// eslint-disable-next-line
export function UserSettingsReducer(
    currentState: NotificationSettings = defaultNotificationSettings,
    action: RuumAction,
): NotificationSettings {
    let newState: NotificationSettings;

    switch (action.type) {
        case ACTION_TYPES.EDIT_USER_SETTINGS:
            newState = Object.assign({}, currentState, action.payload);
            break;
        default:
            newState = currentState;
    }

    return newState;
}
