import { Pipe, PipeTransform } from '@angular/core';
import { CustomFieldDefinitionSelectionList } from '../../../common/connectors/customFields/customFields.model';

@Pipe({
    name: 'customFieldTypeName',
})
export class CustomFieldTypeNamePipe implements PipeTransform {
    transform(value: any, ...args: CustomFieldDefinitionSelectionList[]): any {
        switch (value) {
            case 'selection_list': {
                return args[0] ? this.dropdownName(args[0]) : 'Dropdown List';
            }
            case 'number': {
                return 'Number';
            }
            case 'email': {
                return 'E-Mail';
            }
            case 'link': {
                return 'Link';
            }
            case 'date': {
                return 'Date';
            }
            case 'phone': {
                return 'Phone';
            }
            case 'long_string': {
                return 'Long Text';
            }
            case 'record': {
                return 'Record';
            }
            default: {
                return 'Text';
            }
        }
    }

    private dropdownName(customField: CustomFieldDefinitionSelectionList): string {
        return customField.isMultiSelect ? 'Multi select' : 'Single select';
    }
}
