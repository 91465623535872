import { FocusKeyManager } from '@angular/cdk/a11y';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { of } from 'rxjs';
import { SidenavPrimaryListItemComponent } from './sidenav-primary-list-item.component';

@Component({
    selector: 'ruum-sidenav-primary-list',
    template: `
        <div
            class="d-flex flex-column ruum-sidenav-nav-item-group py-2"
            tabindex="0"
            role="listbox"
            [attr.aria-label]="'PRIMARY LIST'"
            [attr.aria-multiselectable]="false"
            (keydown)="manage($event)"
        >
            <ruum-sidenav-primary-list-item
                *ngFor="let item of items$ | async"
                [name]="item.name"
                [title]="item.tooltip"
                [icon]="item.icon"
                [path]="item.path"
                [style.min-height.px]="32"
            ></ruum-sidenav-primary-list-item>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavPrimaryListComponent implements OnInit, AfterViewInit {
    @HostBinding('class') hostClassList = 'd-flex flex-column border-white-16 border-bottom minw-0';

    @ViewChildren(SidenavPrimaryListItemComponent) viewChildren: QueryList<SidenavPrimaryListItemComponent>;

    items$ = of([
        {
            id: 'HOME',
            name: 'Home',
            icon: 'home',
            tooltip: 'View and manage your available Ruums.',
            path: 'home',
        },
        {
            id: 'TEMPLATES',
            name: 'Templates',
            icon: 'template',
            tooltip: 'View and manage your available Templates.',
            path: 'templates',
        },
    ]);

    private keyManager: FocusKeyManager<SidenavPrimaryListItemComponent>;

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        this.keyManager = new FocusKeyManager(this.viewChildren).withWrap();
    }

    manage(event) {
        this.keyManager.onKeydown(event);
    }
}
