import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FunctionalRole, RuumTask, TemplateParticipant } from '@ruum/ruum-reducers';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ReadModelBackendConnector } from '../../../common/connectors/readModelConnector.service';
import { SelectedTemplateService } from '../../../common/connectors/templates/selectedTemplate.service';
import { getEmailsList, RuumTeamInviteValidator } from '../../../common/team/team-invite-dialog-validator';
import { TrackingConnector } from '../../../common/trackingConnector.service';
import { isMobile } from '../../../common/utils.service';
import { InputValidator } from '../../../common/validators/inputValidator.service';
import { RuumDropdownItem, RuumDropdownList } from '../../ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-template-invite-collaborators-dialog',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">Template Collaborators</h2>
            <h3 class="mt-4 mb-5" *ngIf="functionalRole">and assign to role "{{ functionalRole.name }}"</h3>
            <h3 class="mt-4 mb-5" *ngIf="task">and assign to task "{{ task.description }}"</h3>

            <ruum-hint *ngIf="!!workspaceId">
                <div class="d-flex">
                    <i class="icon icon-info-filled h3 pr-3"></i>
                    <span class="text-black">
                        Workspace admins can access and edit all templates within their workspace. All other workspace
                        members can only access published templates.
                    </span>
                </div>
            </ruum-hint>

            <form [formGroup]="form" class="mt-5">
                <div class="d-flex flex-fill align-items-end">
                    <div class="d-flex flex-fill flex-column">
                        <div class="form-group w-100 mb-0">
                            <label class="form-default-label uppercase" for="email">Invite via email</label>
                            <div class="input-group">
                                <input
                                    id="email"
                                    class="form-control form-control-email"
                                    type="email"
                                    placeholder="Enter email"
                                    formControlName="email"
                                    autocomplete="off"
                                    ruumInputHighlight
                                    (keyup.enter)="form.controls.email.valid && addParticipant()"
                                    [(ngModel)]="emailModel"
                                    [ngbTypeahead]="search"
                                    ngbAutofocus
                                />
                                <div class="input-group-append" ruumInputHighlight>
                                    <span class="input-group-text pr-3">
                                        <span *ngIf="form.controls.email.value" class="text-tiny d-md-flex d-none"
                                            >Hit enter to add</span
                                        >
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ruum-participant-options-dropdown
                        style="width: 104px;"
                        [componentClass]="'btn-outline-secondary btn-lg ml-2'"
                        [selectedItem]="selectedItem"
                        [sectionList]="[roles]"
                        [size]="'lg'"
                        (itemChange)="changeRole($event)"
                    >
                    </ruum-participant-options-dropdown>
                </div>
                <div class="text-small text-danger pl-3 pt-1" *ngIf="form.controls.email.hasError('SAPDL')">
                    Sorry, we don't support SAP DLs
                </div>
                <div class="text-small text-danger pl-3 pt-1" *ngIf="form.controls.email.hasError('blockedDomain')">
                    Sorry, we only allow business email addresses
                </div>
                <div class="text-small text-danger pl-0 pt-0" *ngIf="form.controls.email.hasError('invalid')">
                    <button class="btn btn-link-danger btn-icon border-0" type="button">
                        <i class="icon icon-cancel"></i>
                    </button>
                    <span>Sorry, that's not a valid email entry </span>
                </div>

                <!-- participants to invite list -->
                <div *ngIf="form.controls.participants.value.length" class="d-flex flex-fill flex-column mt-4">
                    <div
                        *ngFor="let participant of form.controls.participants.value; let index = index"
                        class="d-flex align-items-center border-light pb-2"
                    >
                        <button class="btn btn-light btn-round btn-icon btn-without-hover border-0" type="button">
                            <i class="icon icon-user"></i>
                        </button>
                        <div class="d-flex flex-fill px-3 minw-0">
                            <span class="text text-truncate" [title]="participant.email">
                                {{ participant.email }}
                            </span>
                        </div>

                        <ruum-participant-options-dropdown
                            [selectedItem]="participant.role"
                            [sectionList]="[roles]"
                            [size]="'sm'"
                            [componentClass]="'btn-sm btn-link-secondary px-0'"
                            (itemChange)="changeParticipantRole($event, participant, index)"
                        >
                        </ruum-participant-options-dropdown>

                        <button
                            class="btn btn-link-danger btn-icon border-0"
                            type="button"
                            (click)="removeParticipant(index)"
                        >
                            <i class="icon icon-cancel"></i>
                        </button>
                    </div>
                </div>

                <div class="d-flex flex-fill align-items-end">
                    <div class="d-flex flex-fill flex-column">
                        <div class="form-group w-100 mb-0 mt-3">
                            <textarea
                                class="form-control"
                                rows="1"
                                placeholder="Personal message (optional)"
                                formControlName="personalMessage"
                            >
                            </textarea>
                        </div>
                    </div>
                    <div class="d-flex pl-2" [style.width.px]="104">
                        <button
                            class="btn btn-lg btn-block btn-primary"
                            type="button"
                            [disabled]="
                                (form.controls.email.hasError('required') &&
                                    form.controls.participants.value.length === 0) ||
                                form.controls.email.hasError('maxlength') ||
                                form.controls.personalMessage.invalid
                            "
                            (click)="sendInvite()"
                        >
                            Invite
                        </button>
                    </div>
                </div>
                <div class="d-flex align-items-center" *ngIf="form.controls.personalMessage.hasError('maxlength')">
                    <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                        <i class="icon icon-cancel"></i>
                    </button>
                    <div class="text-tiny text-danger">
                        Personal message must not exceed {{ personalMessageMaxLength }} characters
                    </div>
                </div>
            </form>

            <template-collaborators-list
                [collaborators$]="collaborators$"
                [roles]="roles"
            ></template-collaborators-list>
        </ruum-modal-dialog>
    `,
})
export class TemplateCollaboratorsComponent implements OnInit {
    @Input() id: string;

    @Input() workspaceId: string;

    @Input() name: string;

    @Input() roles: RuumDropdownList;

    @Input() functionalRole: FunctionalRole;

    @Input() emails: string[];

    @Input() task: RuumTask;
    selectedItem: RuumDropdownItem;

    @Input() preallocatedEmail: string;

    @Input() collaborators$: Observable<TemplateParticipant[]>;
    form: FormGroup;

    emailModel: any;

    RUUM_MAIL: string = environment.MAIL;

    APP_URL: string = environment.APP_URL;

    personalMessageMaxLength: number;

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private readModelConnector: ReadModelBackendConnector,
        private trackingConnector: TrackingConnector,
        private selectedTemplateService: SelectedTemplateService,
        private inputValidator: InputValidator,
    ) {}

    ngOnInit() {
        this.personalMessageMaxLength = this.inputValidator.mediumLimit;
        this.form = this.formBuilder.group({
            email: [
                '',
                [
                    Validators.required,
                    RuumTeamInviteValidator.email,
                    RuumTeamInviteValidator.SAPDL,
                    Validators.maxLength(this.inputValidator.shortLimit),
                ], // sync validators
            ],
            participants: this.formBuilder.array([]),
            personalMessage: ['', [Validators.maxLength(this.personalMessageMaxLength)]],
        });

        this.collaborators$ = this.selectedTemplateService.participants();

        this.selectedItem = this.roles[1];

        if (this.emails && this.emails.length) {
            this.emails.forEach((email) => this.addPredefinedParticipant(email));
        }

        if (this.preallocatedEmail) {
            this.emailModel = this.preallocatedEmail;
        }
    }
    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((term: string) =>
                this.getSuggestedEmails(term).pipe(
                    catchError(() => {
                        return observableOf([]);
                    }),
                ),
            ),
        );
    };
    getSuggestedEmails(filterValue: string): Observable<string[]> {
        if (isMobile() || !filterValue || !filterValue.replace(/\s,;/g, '').length) {
            return observableOf([]);
        }
        const alreadyAddedMails = this.form.controls.participants.value.map((el) => el.email);
        return this.readModelConnector.getInviteSuggestions(this.id, filterValue, alreadyAddedMails);
    }
    changeRole(option: RuumDropdownItem) {
        this.trackingConnector.trackEventInferCategoryFromUrl('invite_dialog_input_member_role_change', option.name);
        this.selectedItem = option;
    }
    changeParticipantRole(option: RuumDropdownItem, participant, index: number) {
        this.trackingConnector.trackEventInferCategoryFromUrl('invite_dialog_added_member_role_change', option.name);
        const participantsControl = this.form.get('participants') as FormArray;
        participantsControl.at(index).setValue({
            email: participant.email,
            role: option,
        });
    }
    addParticipant() {
        this.trackingConnector.trackEventInferCategoryFromUrl('invite_dialog_email', 'added');
        const emailControl = this.form.get('email') as FormControl;
        const participantsControl = this.form.get('participants') as FormArray;
        const emails = getEmailsList(emailControl.value);
        emails.forEach((email) => {
            participantsControl.push(this.createParticipant(email));
        });
        emailControl.reset();
    }
    addPredefinedParticipant(email: string) {
        const participantsControl = this.form.get('participants') as FormArray;
        participantsControl.push(this.createParticipant(email));
    }
    removeParticipant(index: number) {
        this.trackingConnector.trackEventInferCategoryFromUrl('invite_dialog_added_member', 'removed');
        const participantsControl = this.form.get('participants') as FormArray;
        participantsControl.removeAt(index);
    }
    sendInvite() {
        this.trackingConnector.trackEventInferCategoryFromUrl('invite_dialog_send_invite', 'sent');
        if (this.form.controls.email.valid) {
            this.addParticipant();
        }
        const participants = this.form.controls.participants.value.map((el) => {
            return {
                email: el.email,
                role: el.role.id,
            };
        });
        const personalMessage = this.form.controls.personalMessage.value;
        const invite = { participants, personalMessage };
        this.activeModal.close(invite);
    }
    getMailTo(): string {
        const emails = this.form.controls.participants.value.map((participant) => participant.email);
        return this.getEncodedText(emails.join(';'));
    }

    getMailCC(): string {
        return this.RUUM_MAIL;
    }

    getMailSubject(): string {
        return this.getEncodedText(this.name);
    }

    getEncodedText(text: string): string {
        return encodeURIComponent(text);
    }

    createParticipant(email: string) {
        return this.formBuilder.group({
            email,
            role: this.selectedItem,
        });
    }
}
