import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { CustomFieldValue } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-custom-field-value',
    templateUrl: './custom-field-value.component.html',
    styleUrls: ['./custom-field-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldValueComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-inline-block align-self-center mw-100';

    @Input() customField: CustomFieldValue;
    @Input() isReadOnly = false;

    @Output() valueChange = new EventEmitter<CustomFieldValue>();

    constructor() {}

    ngOnInit() {}

    onValueChange(event: CustomFieldValue) {
        this.valueChange.emit(event);
    }
}
