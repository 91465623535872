import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionTypeAndPayload } from '../../actions';
import { AppState } from '../../app.store';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';
import { FunctionalRoleListItem } from './functionalRoles.model';
import { AddOrReplaceFunctionalRoles } from './functionalRoles.reducer';

@Injectable({ providedIn: 'root' })
export class FunctionalRolesService extends StoreLoader<FunctionalRoleListItem> {
    constructor(protected store: Store<AppState>, private readModelBackendConnector: ReadModelBackendConnector) {
        super();
    }

    getAllInvolvedFunctionalRoles(): Observable<FunctionalRoleListItem[]> {
        return this.getStoreData().pipe(map((userMap) => Object.values(userMap)));
    }

    protected getData(ids: string[]): Observable<FunctionalRoleListItem[]> {
        return this.readModelBackendConnector
            .getFunctionalRoles({ page: 1, pageSize: ids.length }, { id: ids })
            .pipe(map((page) => page.rows));
    }

    protected storeData(roles: FunctionalRoleListItem[]): void {
        const add: ActionTypeAndPayload<AddOrReplaceFunctionalRoles> = {
            type: 'ADD_OR_REPLACE_FUNCTIONAL_ROLES',
            payload: {
                roles,
            },
        };
        this.store.dispatch(add);
    }

    protected failedToLoad(id: string): void {
        const add: ActionTypeAndPayload<AddOrReplaceFunctionalRoles> = {
            type: 'ADD_OR_REPLACE_FUNCTIONAL_ROLES',
            payload: {
                roles: [
                    {
                        id,
                        name: 'Failed to Load',
                        sourceId: '',
                        sourceType: 'workspace',
                        assigned: false,
                        workspaceName: undefined,
                        isSectionLocked: false,
                    },
                ],
            },
        };
        this.store.dispatch(add);
    }

    protected getStoreData(): Observable<{ [id: string]: FunctionalRoleListItem }> {
        return this.store.select('functionalRoles');
    }
}
