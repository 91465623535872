import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStoreWrapper } from '../appStoreWrapper.service';

@Injectable({ providedIn: 'root' })
export class HelpService {
    constructor(private appStoreWrapper: AppStoreWrapper, private router: Router) {}

    showGettingStarted(applicationPart: APPLICATION_PART, force?: boolean) {}
}

export type APPLICATION_PART =
    | 'ruum'
    | 'lobby'
    | 'section-template'
    | 'group'
    | 'report'
    | 'workspace'
    | 'read-only-ruum'
    | 'authentication';
