import { EntityAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../paginatedList.model';
import { TaskListItem } from './task-list.model';

export const TASK_LIST_ACTIONS = {
    REPLACE_TASKS: 'REPLACE_TASKS',
    TASK_LIST_LOADED: 'TASK_LIST_LOADED',
};

/**
 * All action payloads should contain this data.
 * It is needed to find the task inside TaskListWrapper.
 */
export interface TaskListItemActionPayload {
    /** In this projectId */
    projectId: string;
    /** This task */
    taskId: string;
    /** Which is a child of this task. */
    parentId?: string;
}

/** Adds a task to one of the task lists in TaskListWrapper */
export interface AddTaskListItemActionPayload extends TaskListItemActionPayload {
    description: string;
    parentTask: TaskListItem;
}

/** Adds an extra ID to the task.children array */
export interface AddChildActionPayload extends TaskListItemActionPayload {
    subtaskId: string;
}

export type ReplaceTasksAction = EntityAction<
    'REPLACE_TASKS',
    {
        tasks: TaskListItem[];
    }
>;

export type TaskListLoadedAction = EntityAction<
    'TASK_LIST_LOADED',
    {
        list: PaginatedList<TaskListItem>;
        parentId?: string;
        projectId?: string;
    }
>;

export interface UpdateTaskIsMilestone extends TaskListItemActionPayload {
    isMilestone: boolean;
}
