import { FocusableOption } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ruum-sidenav-lobby-list-item',
    template: `
        <div
            *ngIf="{ active: active$ | async } as data"
            class="d-flex flex-fill align-items-center cursor-pointer minw-0 px-3 py-1 ruum-sidenav-nav-item"
            role="option"
            tabindex="-1"
            [attr.aria-selected]="data.active"
            [class.active]="data.active"
            [title]="title"
            (click)="navigateTo($event, path)"
            (keydown.space)="navigateTo($event, path)"
            (keydown.enter)="navigateTo($event, path)"
        >
            <div
                class="btn btn-xs btn-icon no-hover ml-5 mr-1"
                [class.btn-link-white]="data.active"
                [class.btn-link-white-64]="!data.active"
            >
                <i class="icon icon-{{ icon }}"></i>
            </div>
            <div class="text-small text-truncate small" [class.font-weight-bold]="data.active">{{ name }}</div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavLobbyListItemComponent implements OnInit, OnDestroy, FocusableOption {
    @HostBinding('class') hostClassList = 'd-flex minw-0';

    @Input() name: string;
    @Input() title: string;
    @Input() icon: string;
    @Input() path: string;
    active$: Observable<boolean>;

    private ngOnDestroy$ = new Subject<void>();

    constructor(private router: Router, private element: ElementRef) {}

    ngOnInit() {
        this.active$ = this.router.events.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((event) => event instanceof NavigationEnd),
            map(() => !!this.router.url.match(this.path)),
            startWith(!!this.router.url.match(this.path)),
        );
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    navigateTo(event, path: string) {
        event.stopPropagation();
        this.router.navigate([path]);
    }

    focus(): void {
        this.element.nativeElement.querySelector('.ruum-sidenav-nav-item').focus();
    }
}
