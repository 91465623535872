import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomFieldRecordKeyString,
    NewActivity,
    PGParticipant,
    ProjectGroupParticipantRole,
    ProjectParticipantRole,
    SystemConnector,
    TableDefinition,
    TableRow,
    Template,
    TemplateParticipantRole,
    User,
    WorkspaceParticipant,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserListFilters } from '../../admin/user-list/enterprise-participant-list.service';
import { ActivityHistoryFilter, ActivityHistoryListSortBy } from './activity/activity.model';
import { ApprovalListFilters, ApprovalListItem, ApprovalListOrderBy } from './approvals/approval.model';
import { AuthBackendConnector } from './authServiceConnector.service';
import { ChatsListFilters, ChatsListItem, ChatsListOrderBy, CommentsListFilters } from './chats/chat-list.model';
import { CommentListItem } from './commentable/commentable.reducer';
import { CustomFieldListItem, CustomFieldsFilters } from './customFields/customFields.model';
import {
    ExternalSystemInfo,
    ExternalSystemListFilters,
    ExternalSystemListItem,
    ExternalSystemListSortBy,
    ExternalSystemReachableResult,
} from './externalSystem/external-system-list.model';
import { FunctionalRoleListItem } from './functionalRoles/functionalRoles.model';
import {
    LobbyListFilters,
    LobbyListItem,
    LobbyListItemProject,
    LobbyListItemProjectGroup,
    LobbyListOrderBy,
    LobbyListType,
    PaginationParams,
} from './lobbyList/lobby-list.model';
import { OrderedListParams, PaginatedList, SortDirection } from './paginatedList.model';
import { ProfileListItem } from './profile/profile.model';
import {
    ProjectCustomFieldsValuesFilters,
    ProjectCustomFieldValue,
} from './project/customFieldsValues/customFieldsValues.model';
import {
    SystemConnectorListFilters,
    SystemConnectorListSortBy,
} from './system-connectors/system-connectors-list.model';
import { TableDataFilters } from './tableData/tableData.model';
import { TableDefinitionListFilters, TableDefinitionListSortBy } from './tableDefinition/table-definition-list.model';
import { TaksListFilters, TaskListItem, TaskListOrderBy } from './tasks/task-list.model';
import { TemplateListFilters, TemplateListItem } from './templates/template-list.model';
import { CustomFieldListSortBy } from './workspace/customFieldsList/customFieldsList.service';
import { FunctionalRolesListSortBy } from './workspace/functionalRolesList/functionalRolesList.service';

@Injectable({ providedIn: 'root' })
export class ReadModelBackendConnector {
    private READ_MODEL_URL = environment.READ_MODEL_URL;

    constructor(private http: HttpClient, private backendConnector: AuthBackendConnector) {}

    getTemplates(
        pagination: PaginationParams,
        filters: TemplateListFilters,
    ): Observable<PaginatedList<TemplateListItem>> {
        const query = getQueryString(pagination, filters);
        return this.http.get<PaginatedList<TemplateListItem>>(`${this.READ_MODEL_URL}/v1/templates${query}`, {
            withCredentials: true,
        });
    }

    getTemplate(templateId: string): Observable<TemplateListItem> {
        return this.http.get<TemplateListItem>(`${this.READ_MODEL_URL}/v1/templates/${templateId}`, {
            withCredentials: true,
        });
    }

    getTemplateSharingSettings(templateId: string): Observable<Partial<TemplateListItem>> {
        return this.http.get<Partial<Template>>(`${this.READ_MODEL_URL}/v1/templates/${templateId}/sharingSettings`, {
            withCredentials: true,
        });
    }

    getLobbyList(
        filters: LobbyListFilters,
        orderBy: OrderedListParams<LobbyListOrderBy>,
        page: number,
        pageSize: number = 25,
    ): Observable<PaginatedList<LobbyListItem>> {
        const query = getQueryString({ page, pageSize }, filters, orderBy);
        return this.http
            .get<PaginatedList<LobbyListItem>>(`${this.READ_MODEL_URL}/v1/lobbylist${query}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getLobbyListById(id: string) {
        return this.http
            .get<PaginatedList<LobbyListItem>>(`${this.READ_MODEL_URL}/v1/lobbylist?listType=all&id=${id}`, {
                withCredentials: true,
            })
            .pipe(
                map((res) => res.rows[0]),
                catchError(this.backendConnector.handleError.bind(this.backendConnector)),
            );
    }

    getTags(type: LobbyListType, groupId: string, workspaceId?: string): Observable<string[]> {
        return this.http
            .get<string[]>(
                `${this.READ_MODEL_URL}/v1/tags?listType=${type}&groupId=${groupId || ''}&workspaceId=${workspaceId ||
                    ''}`,
                {
                    withCredentials: true,
                },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getTask(projectId: string, taskId: string): Observable<TaskListItem> {
        return this.getTasks(new PaginationParams(1), { projectId, taskId }, { by: 'status', direction: 'asc' }).pipe(
            map((page) => page.rows[0]),
        );
    }

    getTasks(
        pagination: PaginationParams,
        filters: TaksListFilters,
        orderBy: OrderedListParams<TaskListOrderBy>,
    ): Observable<PaginatedList<TaskListItem>> {
        const query = getQueryString(pagination, filters, orderBy);

        const url = `${this.READ_MODEL_URL}/v1/tasks${query}`;

        return this.http
            .get<PaginatedList<TaskListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getChats(
        pagination: PaginationParams,
        filters: ChatsListFilters,
        orderBy: OrderedListParams<ChatsListOrderBy>,
    ): Observable<PaginatedList<ChatsListItem>> {
        const query = getQueryString(pagination, filters, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/chats${query}`;

        return this.http
            .get<PaginatedList<ChatsListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getComments(
        pagination: PaginationParams,
        filters: CommentsListFilters,
        orderBy: OrderedListParams<'createdAt'>,
    ): Observable<PaginatedList<CommentListItem>> {
        const query = getQueryString(pagination, filters, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/comments${query}`;
        return this.http
            .get<PaginatedList<ChatsListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getInviteSuggestions(ruumId: string, filter?: string, alreadyAddedMails?: string[]): Observable<string[]> {
        let url = `${this.READ_MODEL_URL}/v1/email-suggestions?ruumId=${ruumId}`;
        if (filter) {
            url += `&filter=${filter}`;
        }
        if (alreadyAddedMails && alreadyAddedMails.length) {
            url += `&exclude=${alreadyAddedMails.join(',')}`;
        }
        return this.http
            .get<string[]>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getLobbyListItemProjectGroup(groupId: string): Observable<LobbyListItemProjectGroup> {
        return this.http
            .get<PaginatedList<LobbyListItemProjectGroup>>(`${this.READ_MODEL_URL}/v1/lobbylist?id=${groupId}`, {
                withCredentials: true,
            })
            .pipe(
                map((list) => list.rows[0]),
                catchError(this.backendConnector.handleError.bind(this.backendConnector)),
            );
    }

    getLobbyListItemProject(projectId: string): Observable<LobbyListItemProject> {
        if (projectId === undefined) {
            return of();
        }
        return this.http
            .get<LobbyListItemProject>(`${this.READ_MODEL_URL}/v1/projects/${projectId}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getLobbyListItemProjects(
        filters: LobbyListFilters,
        orderBy: OrderedListParams<LobbyListOrderBy> = { by: 'name', direction: 'asc' },
    ): Observable<PaginatedList<LobbyListItemProject>> {
        return this.getLobbyList(
            {
                ...filters,
                listType: 'projects',
            },
            orderBy,
            1,
        ) as Observable<PaginatedList<LobbyListItemProject>>;
    }

    getLobbyListItemGroups(
        filters: LobbyListFilters,
        orderBy: OrderedListParams<LobbyListOrderBy> = { by: 'name', direction: 'asc' },
    ): Observable<PaginatedList<LobbyListItemProjectGroup>> {
        return this.getLobbyList(
            {
                ...filters,
                listType: 'projectgroups',
            },
            orderBy,
            1,
        ) as Observable<PaginatedList<LobbyListItemProjectGroup>>;
    }

    getSections(projectId: string, filters: SectionListFilters) {
        const filtersStr = getFilters(filters);
        return this.http
            .get<PaginatedList<SectionListItem>>(
                `${this.READ_MODEL_URL}/v1/projects/${projectId}/sections?${filtersStr}`,
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getInvolvedUsers(
        pagination: PaginationParams,
        filters: InvolvedUsersParams,
    ): Observable<PaginatedList<UserListItem>> {
        const query = getQueryString(pagination, filters);

        return this.http
            .get<UserListItem[]>(`${this.READ_MODEL_URL}/v1/involvedusers${query}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getWorkspaces(
        pagination: PaginationParams,
        filters: WorkspaceFilters = {},
    ): Observable<PaginatedList<WorkspaceListItem>> {
        const query = getQueryString(pagination, filters);

        return this.http
            .get<UserListItem[]>(`${this.READ_MODEL_URL}/v1/workspaces${query}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getProjectAccess(
        pagination: PaginationParams,
        projectId: string,
        filters: UserProjectAccessFilters,
        orderBy?: OrderedListParams<ProjectAccessOrderBy>,
    ): Observable<PaginatedList<ProjectParticipantWithAllRoles>> {
        const query = getQueryString(pagination, filters, orderBy);
        return this.http.get<PaginatedList<ProjectParticipantWithAllRoles>>(
            `${this.READ_MODEL_URL}/v1/projects/${projectId}/access${query}`,
            { withCredentials: true },
        );
    }

    getProjectGroupAccess(
        pagination: PaginationParams,
        projectGroupId: string,
        filters: PGAccessFilters,
    ): Observable<PaginatedList<PGParticipantWithAllRoles>> {
        const query = getQueryString(pagination, filters);
        return this.http
            .get<PaginatedList<ProjectParticipantWithAllRoles>>(
                `${this.READ_MODEL_URL}/v1/projectgroups/${projectGroupId}/access${query}`,
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getWorkspaceAccess(
        pagination: PaginationParams,
        workspaceId: string,
        filters: WorkspaceAccessFilters = {},
    ): Observable<PaginatedList<WorkspaceParticipantWithAllRoles>> {
        const query = getQueryString(pagination, filters);
        return this.http
            .get<PaginatedList<ProjectParticipantWithAllRoles>>(
                `${this.READ_MODEL_URL}/v1/workspaces/${workspaceId}/access${query}`,
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getCustomFields(
        pagination: PaginationParams,
        filters?: CustomFieldsFilters,
        orderBy?: OrderedListParams<CustomFieldListSortBy>,
    ): Observable<PaginatedList<CustomFieldListItem>> {
        const query = getQueryString(pagination, filters, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/customfields${query}`;

        return this.http
            .get<PaginatedList<UserListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getFunctionalRoles(
        pagination: PaginationParams,
        filters?: FunctionalRolesFilters,
        orderBy?: OrderedListParams<FunctionalRolesListSortBy>,
    ): Observable<PaginatedList<FunctionalRoleListItem>> {
        const query = getQueryString(pagination, filters, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/functionalroles${query}`;

        return this.http
            .get<PaginatedList<FunctionalRoleListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getProjectFunctionalRoleAssignees(
        pagination: PaginationParams,
        roleId: string,
        filters: { search?: string } = {},
    ): Observable<PaginatedList<UserListItem>> {
        const query = getQueryString(pagination, filters);
        const url = `${this.READ_MODEL_URL}/v1/functionalroles/${roleId}/assignees${query}`;

        return this.http
            .get<PaginatedList<FunctionalRoleListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getProjectCustomFieldsValues(
        pagination: PaginationParams,
        filters?: ProjectCustomFieldsValuesFilters,
        orderBy?: OrderedListParams<CustomFieldListSortBy>,
    ): Observable<PaginatedList<ProjectCustomFieldValue>> {
        const query = getQueryString(pagination, { ...filters, projectId: undefined }, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/projects/${filters.projectId}/customfieldsvalues${query}`;

        return this.http
            .get<PaginatedList<UserListItem>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getSystemConnectors(
        pagination: PaginationParams,
        filters?: SystemConnectorListFilters,
        orderBy?: OrderedListParams<SystemConnectorListSortBy>,
    ): Observable<PaginatedList<SystemConnector>> {
        const query = getQueryString(pagination, filters, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/systemconnectors${query}`;

        return this.http
            .get<PaginatedList<SystemConnector>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    isExternalSystemHostnameReachable(systemId: string): Observable<ExternalSystemReachableResult> {
        return this.http
            .get<ExternalSystemReachableResult>(`${this.READ_MODEL_URL}/v1/externalsystems/${systemId}/reachable`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getExternalSystems(
        pagination: PaginationParams,
        filters?: ExternalSystemListFilters,
        orderBy?: OrderedListParams<ExternalSystemListSortBy>,
    ): Observable<PaginatedList<ExternalSystemInfo>> {
        const query = getQueryString(pagination, filters, orderBy);
        const url = `${this.READ_MODEL_URL}/v1/externalsystems${query}`;

        return this.http
            .get<PaginatedList<ExternalSystemInfo>>(url, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    async getExternalSystem(systemId: string): Promise<ExternalSystemListItem> {
        return this.http
            .get<ExternalSystemListItem>(`${this.READ_MODEL_URL}/v1/externalsystems/${systemId}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    getTableRows(
        pagination: PaginationParams,
        tableId: string,
        filters: TableDataFilters,
    ): Observable<PaginatedList<TableRow>> {
        filters = {
            ...filters,
            id: undefined,
        };
        const query = getQueryString(pagination, filters);

        return this.http
            .get(`${this.READ_MODEL_URL}/v1/tabledata/${tableId}/records${query}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getRecordsByKeys(keys: CustomFieldRecordKeyString[]): Observable<PaginatedList<TableRow>> {
        return this.http
            .post(
                `${this.READ_MODEL_URL}/v1/tabledata/getByKeys`,
                {
                    keys,
                },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getTableDefinitions(
        pagination: PaginationParams,
        filters?: TableDefinitionListFilters,
        orderBy?: OrderedListParams<TableDefinitionListSortBy>,
    ): Observable<PaginatedList<TableDefinition>> {
        const query = getQueryString(pagination, filters, orderBy);

        return this.http
            .get(`${this.READ_MODEL_URL}/v1/tabledefinitions${query}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getApprovals(
        pagination: PaginationParams,
        filters: ApprovalListFilters,
        orderBy: OrderedListParams<ApprovalListOrderBy>,
    ): Observable<PaginatedList<ApprovalListItem>> {
        const query = getQueryString(pagination, filters, orderBy);

        return this.http
            .get(`${this.READ_MODEL_URL}/v1/approvals${query}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getActivityHistory(
        pagination: PaginationParams,
        filters: ActivityHistoryFilter,
        orderBy?: OrderedListParams<ActivityHistoryListSortBy>,
    ): Observable<PaginatedList<NewActivity>> {
        const query = getQueryString(pagination, {}, orderBy);
        const { entityId, objectId } = filters;

        return this.http
            .get<PaginatedList<NewActivity>>(
                `${this.READ_MODEL_URL}/v1/projects/${entityId}/tasks/${objectId}/activities${query}`,
                {
                    withCredentials: true,
                },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    getUserProfile(): Observable<ProfileListItem> {
        return this.http
            .get<ProfileListItem>(`${this.READ_MODEL_URL}/v1/profiles/me`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }
}

export interface SectionListItem {
    id: string;
    name: string;
    documentId: string;
    order: number;
}

export interface SectionListFilters {
    sectionId?: string;
    name?: string;
}

export interface UserListItem {
    id: string;
    fullName: string;
    initials: string;
    color: string;
    mail: string;
    email?: string;

    isYou?: boolean;
    lastOnlineAt?: any;

    /** Deprecated. Use 'id' instead. */
    userId?: string;
    /** Deprecated. Use 'fullName' instead. */
    displayName?: string;
}

export interface UserFiltersParams {
    /** only users in this Ruum */
    projectId?: string;
    /** exclude users in this Ruum. */
    excludeProjectId?: string;
    /** only users in this Group */
    groupId?: string;
    /** exclude users in this Group. */
    excludeGroupId?: string;
    excludeUserIds?: string[];
    excludeUserEmails?: string[];
    /** used to match the name of the email of the User. */
    filter?: string;
}

export interface InvolvedUsersParams {
    entityId?: string;
    userIds?: string[];
    excludeUserIds?: string[];
    search?: string;
}

export interface WorkspaceListItem {
    id: string;
    name: string;
    createdAt: number;
    createdBy: string;
    changedAt: number;
    changedBy: string;
    /** The role the logged user has in the workspace. */
    role?: WorkspaceParticipantRole;
    /** If the looged user has access to the workspace. False if user can see the item only because she has access to one of the workspaces's groups or projects.  */
    hasWorkspaceAccess: boolean;
    numberOfParticipants: number;
}

export interface WorkspaceFilters {
    id?: string;
    includeTemplates?: boolean;
    enterpriseId?: string;
}
export interface ProjectParticipantWithAllRoles extends User {
    /** The role the user has because she is part of the Project. */
    projectParticipantRole?: ProjectParticipantRole;
    /** The role the user has because she is part of the Group. */
    groupParticipantRole?: ProjectParticipantRole;
    /** The role the user has because she is part of the Workspace. */
    workspaceParticipantRole?: ProjectParticipantRole;
    /** The last time the user was online */
    lastOnlineAt: number;
    isYou?: boolean;
}

export interface TemplateParticipantWithAllRoles extends User {
    /** The role the user has because she is part of the Template. */
    templateParticipantRole?: TemplateParticipantRole;
    /** The role the user has because she is part of the Workspace. */
    workspaceParticipantRole?: TemplateParticipantRole;
    templateId: string;
    workspaceId: string;
}

export interface PGParticipantWithAllRoles extends PGParticipant, UserListItem {
    /** The role the user has because she is part of the Group. */
    groupParticipantRole?: ProjectGroupParticipantRole;
    /** The role the user has because she is part of the Workspace. */
    workspaceParticipantRole?: ProjectGroupParticipantRole;
}

export type ProjectAccessOrderBy = 'access' | 'id' | 'lastOnlineAt';

export interface UserProjectAccessFilters {
    userIds?: string[];
    isProjectParticipant?: boolean;
    isGroupParticipant?: boolean;
    isWorkspaceParticipant?: boolean;
    isTechnicalUser?: boolean;
    /** Include Group Members and Workspace Members that have no access to the Project. */
    includeNoAccess?: boolean;
    /** Matches name or email. */
    search?: string;
}

export interface UserTemplateAccessFilters {
    userIds?: string[];
    isWorkspaceParticipant?: boolean;
    isTemplateParticipant?: boolean;
    /** Include Group Members and Workspace Members that have no access to the Project. */
    includeNoAccess?: boolean;
    /** Matches name or email. */
    search?: string;
}

export interface WorkspaceParticipantWithAllRoles extends WorkspaceParticipant, UserListItem {}

export interface PaginatedListParams {
    page: number;
    pageSize: number;
}

export interface PGAccessFilters {
    isProjectGroupParticipant?: boolean;
    isTechnicalUser?: boolean;
    isWorkspaceParticipant?: boolean;
    /** Include Workspace Members that have no access to the Group. */
    includeNoAccess?: boolean;
}

export interface PGAccessParams extends PaginatedListParams, OrderedListParams<'id'>, PGAccessFilters {}

export interface UserProjectAccessParams
    extends PaginatedListParams,
        OrderedListParams<ProjectAccessOrderBy>,
        UserProjectAccessFilters {}

export interface FunctionalRolesFilters {
    workspaceId?: string;
    id?: string[];
    search?: string;
    relativeToProjectId?: string;
}

export function getQueryString(pagination: PaginationParams, filters: any, orderBy?: OrderedListParams<any>) {
    const filtersStr = getFilters(filters);
    const orderByStr = getOrderBy(orderBy);

    let query = `?page=${pagination.page}&pageSize=${pagination.pageSize || 25}`;

    if (filtersStr) {
        query += `&${filtersStr}`;
    }

    if (orderByStr) {
        query += `&${orderByStr}`;
    }

    return query;
}

export function getODataQueryString(
    pagination: PaginationParams,
    filters: any,
    orderBy?: OrderedListParams<any>,
    expand?: string[],
): string {
    const orderByStr = getOrderByOData(orderBy);
    const parts: string[] = [];

    if (expand) {
        parts.push(`$expand=${expand.join(',')}`);
    }

    if (pagination.page || pagination.pageSize) {
        parts.push(`$skip=${(pagination.page - 1) * (pagination.pageSize || 0)}`);
        parts.push(`$top=${pagination.pageSize || 25}`);
    }

    if (filters && getFiltersOData(filters)) {
        parts.push(getFiltersOData(filters));
    }

    if (orderByStr) {
        parts.push(`${orderByStr}`);
    }

    return `?${parts.join('&')}`;
}

export function getFilters(filters: any): string {
    const filterParams = Object.keys(filters).reduce((filterList: string[], key) => {
        let value = filters[key];

        if (value && value.constructor === Array) {
            value = filters[key].map((f: any) => (f === null ? 'null' : f));
            value = value.join(',');
        }

        if (key === 'status') {
            value = value.replace('No Status', 'null');
        }

        if (value || value === false || value === null) {
            const filter = `${key}=${encodeURIComponent(value)}`;
            return filterList.concat(filter);
        }

        return filterList;
    }, []);

    return filterParams.join('&');
}

function getOrderBy(orderBy: { by?: string; direction?: SortDirection }): string {
    if (orderBy && orderBy.by) {
        return `orderBy=${orderBy.by}&orderByDirection=${orderBy.direction || 'asc'}`;
    } else {
        return '';
    }
}

function getOrderByOData(orderBy: { by?: string; direction?: SortDirection }): string {
    if (orderBy && orderBy.by) {
        return `$orderby=${orderBy.by} ${orderBy.direction || 'asc'}`;
    } else {
        return '';
    }
}

function getFiltersOData(filters: UserListFilters): string {
    // comment below will replace the the filter to ignore upper case
    // filterString += `&$filter=contains(lowercase(email),'${encodeURIComponent(lowercase(filters.search))}')`;
    const filterString = `&$filter=contains(`;
    const search = Object.keys(filters).map((key) => {
        return filters[key] ? key : undefined;
    });

    return search[0] ? filterString + search.join(',') + `,'${encodeURIComponent(filters[search[0]])}')` : undefined;
}

export interface WorkspaceAccessFilters {
    userIds?: string[];
    role?: WorkspaceParticipantRole;
    search?: string;
}
