import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { RuumDropdownItem, RuumDropdownList } from '../../../shared/ui-components/dropdown/dropdown.model';

@Component({
    selector: 'block-invite-toggle',
    template: `
        <div [ngbTooltip]="tooltipContent" [disableTooltip]="isAdmin">
            <button
                class="btn btn-sm btn-icon"
                type="button"
                #popover="ngbPopover"
                placement="bottom-right"
                autoClose="outside"
                [ngbPopover]="popoverContent"
                container="bof"
                title="Change who can invite participants to this Ruum"
                [class.btn-link-secondary]="!invitesBlocked"
                [class.btn-link-info]="invitesBlocked"
                [disabled]="!isAdmin"
            >
                <i class="icon" [class.icon-lock-locked]="invitesBlocked" [class.icon-lock-open]="!invitesBlocked"></i>
            </button>
        </div>
        <ng-template #popoverContent>
            <ruum-dropdown-list [list]="options" (selectItem)="onChangeOption($event)"> </ruum-dropdown-list>
        </ng-template>

        <ng-template #tooltipContent>
            <div class="text-small font-weight-bold">Only Admins can set invite rights</div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockInviteToggleComponent implements OnChanges {
    constructor() {}

    activeOption: RuumDropdownItem;

    options: RuumDropdownList = [
        {
            id: 'not_blocked',
            name: 'Admins & Editors can invite',
            icon: 'icon-lock-open',
            iconSide: 'right',
        },
        {
            id: 'blocked',
            name: 'Only Admins can invite',
            theme: 'danger',
            icon: 'icon-lock-locked',
            iconSide: 'right',
        },
    ];

    @Input()
    invitesBlocked = false;

    @Input()
    isAdmin = false;

    @Output()
    private changeOption = new EventEmitter();

    @ViewChild('popover', { static: false })
    private popover: NgbPopover;

    ngOnChanges() {
        if (this.invitesBlocked) {
            this.activeOption = this.options[0];
        } else {
            this.activeOption = this.options[1];
        }
    }

    onChangeOption(option: RuumDropdownItem) {
        this.popover.close();
        this.activeOption = option;
        this.changeOption.emit(this.activeOption.id);
    }
}
