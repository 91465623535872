import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
    transform(date: Date | string | number, format: string): string {
        if (!format || !date) {
            return '';
        }
        return new Date(date).format(format);
    }
}
@NgModule({
    declarations: [DateFormatPipe],
    exports: [DateFormatPipe],
})
export class DateFormatPipeModule {}
