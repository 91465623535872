import {
    DEFAULT_OIDC_PROVIDER_ID,
    Enterprise,
    EnterpriseAction,
    EnterpriseAuthenticationType,
    EnterpriseOAuthClient,
    RuumAction,
    UpdateOAuthClientAction,
} from '@ruum/ruum-reducers';

export const ENTERPRISE_ACTION_TYPES = {
    SELECT_ENTERPRISE: 'SELECT_ENTERPRISE',
    ENTERPRISE_CONFIGURATION_LOADED: 'ENTERPRISE_CONFIGURATION_LOADED',
    ENTERPRISE_PARTICIPANTS_LOADED: 'ENTERPRISE_PARTICIPANTS_LOADED',
    UPDATE_ENTERPRISE_UPLOAD_OPTIONS: 'UPDATE_ENTERPRISE_UPLOAD_OPTIONS',
    CREATE_ENTERPRISE_OAUTH_CLIENT: 'CREATE_ENTERPRISE_OAUTH_CLIENT',
    DELETE_ENTERPRISE_OAUTH_CLIENT: 'DELETE_ENTERPRISE_OAUTH_CLIENT',
};

const defaultEnterprise: Enterprise = {
    id: '',
    name: '',
    createdAt: undefined,
    participants: [],
    fileUploadOptions: null,
    oauthClients: [],
    technicalUsers: [],
    patterns: [],
    signupRuums: [],
    externalSystems: {},
    systemConnectors: {},
    tableDefinitions: {},
    authenticationType: EnterpriseAuthenticationType.basic,
    oidcProviderId: DEFAULT_OIDC_PROVIDER_ID,
};

export function SelectedEnterpriseReducer(
    currentState: Enterprise = defaultEnterprise,
    action: RuumAction,
): Enterprise {
    switch (action.type) {
        case ENTERPRISE_ACTION_TYPES.SELECT_ENTERPRISE:
            return selectEnterprise(action);
        case ENTERPRISE_ACTION_TYPES.ENTERPRISE_CONFIGURATION_LOADED:
            return enterpriseConfigurationLoaded(action);
        case ENTERPRISE_ACTION_TYPES.UPDATE_ENTERPRISE_UPLOAD_OPTIONS:
            return updateEnterpriseUploadOptions(currentState, action);
        // case ENTERPRISE_ACTION_TYPES.CREATE_ENTERPRISE_OAUTH_CLIENT:
        //     return createOAuthClient(currentState, action);
        case ENTERPRISE_ACTION_TYPES.DELETE_ENTERPRISE_OAUTH_CLIENT:
            return updateEnterpriseOauth(currentState, action);
        // case ENTERPRISE_ACTION_TYPES.DELETE_ENTERPRISE_OAUTH_CLIENT:
        //     return deleteOAuthClient(currentState, action);
        // case ENTERPRISE_ACTION_TYPES.CHANGE_ROLE:
        //     return changeRole(currentState, action);
        default:
            return currentState;
    }
}

function selectEnterprise(action: EnterpriseAction): Enterprise {
    return action.payload;
}

function enterpriseConfigurationLoaded(action: EnterpriseAction): Enterprise {
    return {
        ...action.payload.enterpriseConfiguration,
    };
}

function updateEnterpriseUploadOptions(currentState: Enterprise, action: EnterpriseAction): Enterprise {
    return {
        ...currentState,
        fileUploadOptions: action.payload,
    };
}

function updateEnterpriseOauth(currentState: Enterprise, action: UpdateOAuthClientAction): Enterprise {
    return {
        ...currentState,
        oauthClients: currentState.oauthClients,
        ...(action.payload as EnterpriseOAuthClient),
    };
}

// function changeRole(currentState: Enterprise, action: ChangeRoleAction): Enterprise {
//     return {
//         ...currentState,
//         participants: changeParticipantRole(currentState.participants, action),
//     };
// }

// function deleteOAuthClient(currentState: Enterprise, action: DeleteOAuthClientAction): Enterprise {
//     return {
//         ...currentState,
//         oauthClients: deleteOAuthClientInformation(currentState.oauthClients, action),
//     };
// }

// function createOAuthClient(currentState: Enterprise, action: CreateOAuthClientAction): Enterprise {
//     return {
//         ...currentState,
//         oauthClients: createOAuthClientInformation(currentState.oauthClients, action),
//     };
// }
