import { Injectable } from '@angular/core';
import { AssignedFunctionalRoles, AssignFunctionalRoleToProjectAction, canvasSchema } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedProjectService } from '../../../../common/connectors/project/selectedProject.service';
import { CanvasService } from '../../canvas.service';

@Injectable({ providedIn: 'root' })
export class RuumFunctionalRolesService {
    constructor(private selectedProjectService: SelectedProjectService, private canvasService: CanvasService) {}

    getFunctionalRole(id: string): Observable<AssignedFunctionalRoles> {
        return this.selectedProjectService
            .selectedProject()
            .pipe(map(({ functionalRoles }) => functionalRoles.find((role) => role.id === id)));
    }

    isRoleOnCanvas(id: string) {
        return this.canvasService.getComponentIsInCanvas(id);
    }

    addFunctionalRole(functionalRoleId: string): void {
        this.canvasService.insertAtSelection(canvasSchema.nodes.functionalRole.create({ id: functionalRoleId }));
        this.addRoleToProjectIfNotExists(functionalRoleId);
    }

    addRoleToProjectIfNotExists(id: string): void {
        const project = this.selectedProjectService.getSelectedProject();
        const alreadyAssigned = project.functionalRoles.some((role) => role.id === id);

        if (!alreadyAssigned) {
            this.selectedProjectService.persistAction<AssignFunctionalRoleToProjectAction>(
                'ASSIGN_FUNCTIONAL_ROLE_TO_PROJECT',
                { functionalRoleIds: [id] },
            );
        }
    }

    removeFunctionalRole(id: string) {
        this.canvasService.deleteNodeWithId(id);
    }
}
