import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutsModule } from '../../layouts/layouts.module';
import { LoadMoreModule } from '../../ui-components/load-more/load-mode.module';
import { SearchModule } from '../../ui-components/search/search.module';
import { SavedViewSettingsDialogButtonComponent } from './saved-view-settings-dialog-button.component';
import { SavedViewSettingsDialogComponent } from './saved-view-settings-dialog.component';
import { ScrollableListComponent } from './scrollable-list.component';
import { SortableListComponent } from './sortable-list.component';

@NgModule({
    declarations: [
        SavedViewSettingsDialogButtonComponent,
        SavedViewSettingsDialogComponent,
        SortableListComponent,
        ScrollableListComponent,
    ],
    imports: [CommonModule, LayoutsModule, SearchModule, DragDropModule, LoadMoreModule],
    exports: [SavedViewSettingsDialogButtonComponent, SavedViewSettingsDialogComponent],
})
export class SavedViewSettingsDialogModule {}
