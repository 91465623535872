import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'move-to-section-dialog',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">Move to Section</h2>

            <span class="text-dark text-uppercase font-weight-bold text-tiny mt-4">select a section</span>
            <select-section [projectId]="projectId" (selectSection)="selectSection($event)"></select-section>
        </ruum-modal-dialog>
    `,
})
export class MoveToSectionDialogComponent {
    @Input() projectId: string;

    constructor(private activeModal: NgbActiveModal) {}

    selectSection(sectionId: string) {
        this.activeModal.close(sectionId);
    }
}
