import { Processes } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentIndex } from '../indexDocument';
import { ObjectsProcessStep } from '../objectProcessStep';
import { CustomFieldsStepConfiguration, getCustomFieldsConfiguration } from './customFieldStepConfiguration';
import { getRolesConfiguration, RolesStepConfiguration } from './roleStepConfiguration';
import { getTasksStepConfiguration, TasksStepConfiguration } from './tasksStepConfiguration';

export function objectsStepConfiguration(
    canvasObjects$: Observable<DocumentIndex>,
    sections$: Observable<ObjectsProcessStep>,
    processes$: Observable<Processes>,
): Observable<ObjectStepConfiguration> {
    return combineLatest([canvasObjects$, sections$, processes$]).pipe(
        map(([objects, sections, processes]) => buildConfiguration(objects, sections, processes)),
    );
}

function buildConfiguration(
    { tasks, fields, roles }: DocumentIndex,
    processSections: ObjectsProcessStep,
    processes: Processes,
): ObjectStepConfiguration {
    return {
        fields: getCustomFieldsConfiguration(fields, processSections, processes),
        tasks: getTasksStepConfiguration(tasks, processSections, processes),
        roles: getRolesConfiguration(roles, processSections, processes),
    };
}

export interface ObjectStepConfiguration {
    tasks: TasksStepConfiguration;
    fields: CustomFieldsStepConfiguration;
    roles: RolesStepConfiguration;
}
