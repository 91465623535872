import {
    CustomFieldsValues,
    ProcessCanvasStep,
    ProcessStepTransition,
    StepProjectCustomFieldsConfiguration,
    StepTasksConfiguration,
} from '@ruum/ruum-reducers';
import { TasksMap } from '../../../task/task.reducer';
import { SectionsObjects } from './indexDocument';
import {
    ProcessApprovalStepWebApp,
    ProcessCanvasStepWebApp,
    ProcessSystemConnectorStepWebApp,
} from './objectProcessStep';
import { ProcessStepTransitionState } from './objectStepTransitions';

export function getCanvasStepTransitionState(
    fromStep: ProcessCanvasStepWebApp | ProcessApprovalStepWebApp,
    sectionObjects: SectionsObjects,
    tasks: TasksMap,
    projectCustomFields: CustomFieldsValues,
    transition: ProcessStepTransition,
): ProcessStepTransitionState {
    if (transition.condition && transition.condition.type === 'all_mandatory_are_done') {
        return {
            ...transition,
            processId: fromStep.processId,
            stepId: fromStep.id,
            enabled:
                fromStep.active &&
                allMandatoryAreDone(fromStep, fromStep.sectionId, sectionObjects, tasks, projectCustomFields),
        };
    } else {
        return {
            ...transition,
            processId: fromStep.processId,
            stepId: fromStep.id,
            enabled: fromStep.active,
        };
    }
}

function allMandatoryAreDone(
    step: ProcessCanvasStep | ProcessSystemConnectorStepWebApp | ProcessApprovalStepWebApp,
    sectionId: string,
    sectionsObjects: SectionsObjects,
    tasksMap: TasksMap,
    projectCustomFields: CustomFieldsValues,
): boolean {
    const sectionObjects = sectionsObjects[sectionId];
    if (step.type !== 'canvas') {
        return;
    }
    return (
        !!sectionObjects &&
        allFieldsAreFilled(sectionObjects.fields, projectCustomFields, step.customFieldsConfiguration) &&
            allMandatoryTasksAreDone(sectionObjects.tasks, tasksMap, step.tasksConfiguration)
    );
}

function allMandatoryTasksAreDone(
    taskIds: string[],
    tasksMap: TasksMap,
    configuration: StepTasksConfiguration,
): boolean {
    return taskIds
        .filter((taskId) => !!configuration[taskId] && configuration[taskId].mandatory)
        .every(
            (taskId) =>
                !!tasksMap[taskId] && (tasksMap[taskId].status === 'DONE' || tasksMap[taskId].status === 'REACHED'),
        );
}

function allFieldsAreFilled(
    fieldIds: string[],
    projectCustomFields: CustomFieldsValues,
    configuration: StepProjectCustomFieldsConfiguration,
): boolean {
    return fieldIds
        .filter((fieldId) => !!configuration[fieldId] && configuration[fieldId].mandatory)
        .every((fieldId) => {
            const fieldValue = projectCustomFields[fieldId];
            if (Array.isArray(fieldValue)) {
                return (fieldValue as unknown[]).length > 0;
            }
            return !!fieldValue || fieldValue === 0;
        });
}
