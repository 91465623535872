import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
    selector: 'ruum-max-content',
    template: `
        <div class="h-100 w-100 position-absolute"><ng-content></ng-content></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaxContentComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill position-relative';
}
