import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { RuumAlertService } from '../components/alert/alert.service';
import { PaginatedList } from './../connectors/paginatedList.model';
import { PaginatedListLoader } from './../connectors/paginatedListLoader';
import { DEFAULT_ODATA_PAGE_SIZE, ODataListResponse } from './odata.model';
import { ODataPaginationParams } from './ODataConnector.service';
import { ODataQuery, ODataQueryParams } from './ODataQuery';

export abstract class ODataPaginatedListLoader<T> extends PaginatedListLoader<T, ODataQuery, ODataQuery> {
    protected readonly query$ = new BehaviorSubject<ODataQuery>(undefined);
    protected currentQuery: ODataQuery;

    constructor(protected alertService: RuumAlertService, protected authService: AuthService) {
        super(alertService, authService);
        if (!this.currentQuery) {
            this.reset();
        }
    }

    query(params: ODataQueryParams) {
        this.currentQuery = new ODataQuery(params);
        this.query$.next(this.currentQuery); // New instance to trigger change on list loader
    }

    getQuery(): ODataQuery {
        return this.currentQuery;
    }

    reset(): ODataPaginatedListLoader<T> {
        this.currentQuery = new ODataQuery({});
        this.query$.next(this.currentQuery);
        return this;
    }

    protected getData(page: number, query: ODataQuery): Observable<PaginatedList<T>> {
        const queryWithPagination = this.resolvePagination(page, query);
        return this.getRecords(queryWithPagination);
    }

    private resolvePagination(page: number, query: ODataQuery): ODataQuery {
        const top = query.getTop() || DEFAULT_ODATA_PAGE_SIZE;
        query.top(top).skip((page - 1) * top);
        return query;
    }

    protected abstract getRecords(query: ODataQuery): Observable<PaginatedList<T>>;

    getFilters$(): Observable<ODataQuery> {
        return this.query$;
    }
}

export function convertODataResponseToPaginatedList<T>(
    paginationParams: ODataPaginationParams,
    body: ODataListResponse,
): PaginatedList<T> {
    const $top = paginationParams.top;
    const $skip = paginationParams.skip;
    const totalItems = body['@odata.count'];
    return {
        pageSize: $top,
        currentPage: $skip / $top + 1,
        totalItems,
        rows: <T[]>body.value,
    };
}
