import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { UiComponentsModule } from '../../ui-components/ui-components.module';
import { FunctionalRoleSelectComponent } from './functional-role-select.component';

@NgModule({
    imports: [CommonModule, SelectModule, UiComponentsModule],
    declarations: [FunctionalRoleSelectComponent],
    exports: [FunctionalRoleSelectComponent],
})
export class FunctionalRoleSelectModule {}
