import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { EnterpriseRole } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';

@Injectable({ providedIn: 'root' })
export abstract class BaseRoleGuard implements CanActivate {
    constructor(protected selectedEnterpriseService: SelectedEnterpriseService, private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.selectedEnterpriseService.selectedEnterpriseRole().pipe(
            map((role: EnterpriseRole) => {
                const canActivate = this.assertUserRoles(role);
                if (canActivate) {
                    return canActivate;
                }
                return this.router.parseUrl('admin');
            }),
        );
    }

    protected getAllowedRoles(): string[] {
        return [];
    }

    private assertUserRoles(role: EnterpriseRole): boolean {
        const allowedRoles = this.getAllowedRoles();

        return allowedRoles.indexOf(role) >= 0;
    }
}
