import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-view-controls',
    template: `
        <div role="region" aria-label="View Controls" class="d-flex px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5">
            <ng-content></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewControlsComponent {
    @HostBinding('class') get hostClassList() {
        return ['d-block', this.componentClass].filter((el) => !!el).join(' ');
    }

    @Input() componentClass: string;

    constructor() {}
}
