import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../../ui-components/icon/icon.module';
import { CustomFieldDatePipe } from './custom-field-date.pipe';
import { CustomFieldTypeNamePipe } from './custom-field-type-name.pipe';

@NgModule({
    declarations: [CustomFieldTypeNamePipe, CustomFieldDatePipe],
    imports: [CommonModule, IconModule],
    exports: [CustomFieldTypeNamePipe, CustomFieldDatePipe],
})
export class CustomFieldSharedModule {}
