<table *ngIf="dataSource" class="w-100" cdk-table [dataSource]="dataSource" [trackBy]="trackByCustomField">
    <!-- id Column -->
    <ng-container *ngIf="idColumnTemplate" cdkColumnDef="id">
        <th cdk-header-cell *cdkHeaderCellDef class="custom-field-list-head px-2 pb-3"></th>
        <td cdk-cell *cdkCellDef="let element" class="custom-field-list-column px-2 py-1 text-small text-truncate">
            <ng-container *ngTemplateOutlet="idColumnTemplate; context: { $implicit: element }"></ng-container>
        </td>
    </ng-container>

    <!-- title Column -->
    <ng-container cdkColumnDef="title">
        <th cdk-header-cell *cdkHeaderCellDef class="custom-field-list-head px-2 pb-3">
            <sortable-column
                name="title"
                [sorting]="getSort('title')"
                [direction]="getSortDirection('title')"
                (sort)="onSortChange($event)"
            ></sortable-column>
        </th>
        <td
            cdk-cell
            *cdkCellDef="let element"
            class="custom-field-list-column px-2 py-1 text-small text-truncate text-capitalize"
        >
            <span class="font-weight-bold text-capitalize">{{ element.title }}</span>
        </td>
    </ng-container>

    <!-- type Column -->
    <ng-container cdkColumnDef="type">
        <th cdk-header-cell *cdkHeaderCellDef class="custom-field-list-head px-2 pb-3">
            <sortable-column
                name="type"
                [sorting]="getSort('type')"
                [direction]="getSortDirection('type')"
                (sort)="onSortChange($event)"
            >
            </sortable-column>
        </th>
        <td cdk-cell *cdkCellDef="let element" class="custom-field-list-column px-2 py-1 text-small text-truncate">
            <span
                ><button class="btn btn-xs btn-icon btn-link-secondary btn-without-hover mr-1">
                    <i class="custom-field-type-icon icon" [ngClass]="element.type | ruumObjectTypeToIcon"></i>
                </button>
                <ng-container *ngIf="!showAssignedType">{{ element.type | customFieldTypeName }}</ng-container>
                <ng-container *ngIf="showAssignedType">
                    {{ element.type | customFieldTypeName: element }}
                </ng-container>
            </span>
        </td>
    </ng-container>

    <!-- description Column -->
    <ng-container cdkColumnDef="description">
        <th cdk-header-cell *cdkHeaderCellDef class="custom-field-list-head px-2 pb-3">
            <h5 class="text-secondary">description</h5>
        </th>
        <td cdk-cell *cdkCellDef="let element" class="custom-field-list-column px-2 py-1 text-small text-truncate">
            <span>{{ element.description }}</span>
        </td>
    </ng-container>

    <!-- value Column -->
    <ng-container cdkColumnDef="value">
        <th cdk-header-cell *cdkHeaderCellDef class="custom-field-list-head px-2 pb-3">
            <h5 class="text-secondary">value</h5>
        </th>
        <td cdk-cell *cdkCellDef="let element" class="custom-field-list-column px-2 py-1 text-truncate">
            <ruum-custom-field-value
                [customField]="element"
                [isReadOnly]="isReadOnlyField(element)"
                [ngbTooltip]="'This field is not editable, because it is part of an inactive section'"
                [disableTooltip]="!isReadOnlyField(element)"
                (valueChange)="onValueChange($event)"
            >
            </ruum-custom-field-value>
        </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns"
        class="border-bottom border-light hov hov-bg-primary-light"
    ></tr>
</table>
