import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebhookEntitySyncStateListItem } from '../../webhooks.model';
import { WebhookEntitiesListService } from '../webhook-entities-list.service';

@Injectable({
    providedIn: 'root',
})
export class GetWebhookEntityByIdResolver implements Resolve<any> {
    constructor(private webhookEntitiesListService: WebhookEntitiesListService, private router: Router) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<Observable<WebhookEntitySyncStateListItem>> {
        const enterpriseId = route.paramMap.get('enterpriseId');
        const webhookId = route.paramMap.get('webhookId');
        const entityId = route.paramMap.get('entityId');

        this.webhookEntitiesListService.filterByWebhookId(webhookId);
        const entity = await this.webhookEntitiesListService.getByKeys(webhookId, entityId);

        if (entity) {
            return this.webhookEntitiesListService.getStoreData$().pipe(
                map((entities: any) => {
                    return entities.rows.find((el) => el.entityId === entityId);
                }),
            );
        } else {
            this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/webhooks/${webhookId}/entities`);
        }
    }
}
