import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuumDropdownPopupMobileComponent } from '../components/ruum-dropdown-popup-mobile.component';
import { RuumDropDownOption, RuumDropdownPopupComponent } from '../components/ruum-dropdown-popup.component';
import { isMobile } from '../utils.service';
import { RuumPopupBase } from './ruum-popup-base';

@Injectable({ providedIn: 'root' })
export class RuumPopupService {
    popupContainer: { showPopup(...args): Observable<any>; close(): void };

    /**
     * Internal use by popup Container
     */
    init(popupContainer): void {
        this.popupContainer = popupContainer;
    }

    /**
     * Show the popup with arguments
     * @param popupComponent The Popup Component to be shown. The Component must inherited from
     *          RuumPopupBase and added to `entryComponents` of `app.module.ts`
     * @param argus The argument send to the Popup Component. All the members of this object will be
     *          set to Popup Component instance as instance member, prefer @Input() used in the
     *          popup component.
     * @returns The Observable Object to trace the close event. An object will be
     *          sent to the subscriber when the popup is closed. And the Observable Object will be
     *          completed at the same time.
     */
    showPopup<RET = any>(popupComponent: new (...args) => RuumPopupBase<RET>, argus?: {}): Observable<RET | undefined> {
        return this.popupContainer.showPopup(popupComponent, argus);
    }

    showSelectDropdown<RET = any>(params: ShowSelectDropdownParameters): Observable<RET | undefined> {
        const dropdownComponent = isMobile() ? RuumDropdownPopupMobileComponent : RuumDropdownPopupComponent;
        return this.popupContainer.showPopup(dropdownComponent, params);
    }

    /**
     * Close any popup(if any) on the screen.
     */
    closePopup() {
        this.popupContainer.close();
    }
}

export interface ShowSelectDropdownParameters {
    options: RuumDropDownOption[];
    pos: ClientRect;
    title?: string;
    showTitle?: boolean;
}
