import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownModule } from '../../ui-components/dropdown/dropdown.module';
import { MilestoneStatusMenuComponent } from './milestone-status-menu.component';
import { MilestoneStatusComponent } from './milestone-status.component';
import { TaskStatusLightThemePipe } from './task-status-light-theme.pipe';
import { TaskStatusMenuComponent } from './task-status-menu.component';
import { TaskStatusComponent } from './task-status.component';

@NgModule({
    declarations: [
        TaskStatusMenuComponent,
        TaskStatusComponent,
        MilestoneStatusMenuComponent,
        MilestoneStatusComponent,
        TaskStatusLightThemePipe,
    ],
    imports: [CommonModule, DropdownModule],
    exports: [
        TaskStatusMenuComponent,
        TaskStatusComponent,
        MilestoneStatusMenuComponent,
        MilestoneStatusComponent,
        TaskStatusLightThemePipe,
    ],
})
export class TaskStatusModule {}
