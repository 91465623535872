import { SystemConnectorConfiguration } from '@ruum/ruum-reducers';

export function createDefaultSystemConnectorConfiguration(): SystemConnectorConfiguration {
    return {
        id: null,
        systemConnectorId: null,
        inputMapping: {},
        outputMapping: {},
    };
}

export function createIOObjectFromArray(list: { name: string; type: string; label: string }[]) {
    const result = {};
    for (const item of list) {
        result[item.name] = {
            type: item.type,
            label: item.label,
        };
    }
    return result;
}

export function createIOArrayFromObject(obj: { [key: string]: { type: string; label: string } }) {
    const result = [];
    for (const key of Object.keys(obj)) {
        const { type, label } = obj[key];
        result.push({ name: key, type, label });
    }
    return result;
}

export function cloneSystemConnectorConfiguration(
    systemConnectorConfiguration: SystemConnectorConfiguration,
): SystemConnectorConfiguration {
    const inputMapping = { ...systemConnectorConfiguration.inputMapping };
    const outputMapping = { ...systemConnectorConfiguration.outputMapping };
    return {
        ...systemConnectorConfiguration,
        inputMapping,
        outputMapping,
    };
}
