import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'ruum-task-assignees-dropdown-item',
    template: `
        <div
            class="list-group-item list-group-item-action d-flex align-items-center rounded cursor-pointer px-2 py-1"
            [ngClass]="getBackgroundClass(color | ruumProfileHexToTheme)"
            [class.disabled]="disabled"
        >
            <ruum-profile-member *ngIf="!isRole" [name]="name" [theme]="color | ruumProfileHexToTheme" [size]="size">
            </ruum-profile-member>

            <ruum-profile-role *ngIf="isRole" [name]="name" [size]="size"> </ruum-profile-role>
            <div class="pl-2 text-truncate" [class.small]="size !== 'lg'">{{ name }}</div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskAssigneesDropdownItemComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill mb-1';

    @Input() id: string;
    @Input() name: string;
    @Input() avatarRounded = true;
    @Input() color = '#000000';
    @Input() selected: boolean;
    @Input() focused = false;
    @Input() disabled: boolean;
    @Input() size = 'sm';
    @Input() isRole = false;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() select = new EventEmitter();

    hover = false;

    @HostListener('mouseenter') onMouseEnter() {
        this.hover = true;
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hover = false;
    }

    getBackgroundClass(theme: string) {
        const bgClass = this.selected ? `bg-${theme}` : ``;
        const hovBgClass = `hov-bg-${theme}`;
        return [bgClass, 'hov', hovBgClass].filter((el) => !!el);
    }

    getSize(size: string): string {
        return size === 'lg' ? 'sm' : 'xs';
    }
}
