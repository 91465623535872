import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatsListItem } from '../../connectors/chats/chat-list.model';

@Component({
    selector: 'ruum-project-chats-overview-list',
    template: `
        <div
            *ngFor="let chatsListItem of chatsListItems"
            class="d-flex flex-fill flex-column cursor-pointer hov hov-bg-extra-light"
            [class.border-bottom]="isProjectChat"
            [class.border-light]="isProjectChat"
        >
            <ruum-project-chats-overview-list-item [chatsListItem]="chatsListItem" (click)="goToComment(chatsListItem)">
            </ruum-project-chats-overview-list-item>
        </div>

        <div
            *ngIf="!searchQuery && isProjectChat && chatsListItems?.length < 1"
            (click)="openProjectChat()"
            (keydown.space)="openProjectChat()"
            (keydown.enter)="openProjectChat()"
            class="cursor-pointer hov hov-bg-extra-light border-bottom border-light"
        >
            <div class="d-flex flex-row minw-0 p-4">
                <div class="btn btn-xs btn-icon btn-link-light btn-without-hover px-1 py-2 border-0">
                    <i class="icon icon-comments"></i>
                </div>

                <div class="d-flex align-items-center justify-content-between flex-fill minw-0">
                    <div class="text-truncate interface pl-2 py-1">Ruum Chat</div>
                    <button class="btn btn-icon btn-icon btn-link-secondary" type="button">
                        <i class="icon icon-forward"></i>
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class ProjectChatsOverviewListComponent implements OnInit {
    @Input() chatsListItems: ChatsListItem[];
    @Input() isProjectChat = false;
    @Input() searchQuery = '';

    @Output() openChat = new EventEmitter();
    @Output() openComment = new EventEmitter<ChatsListItem>();

    constructor() {}

    ngOnInit() {}

    goToComment(chatListItem: ChatsListItem) {
        this.openComment.emit(chatListItem);
    }
    openProjectChat() {
        this.openChat.emit();
    }
}
