import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TaskStatus } from '@ruum/ruum-reducers';
import { TaskListItem } from '../../common/connectors/tasks/task-list.model';

@Component({
    selector: 'ruum-task-list-item-small',
    template: `
        <div
            class="d-flex flex-fill minw-0 p-2 bg-white border-bottom border-light"
            (click)="itemClicked.emit(task)"
            (keydown.space)="itemClicked.emit(task)"
            (keydown.enter)="itemClicked.emit(task)"
        >
            <div class="d-flex flex-column">
                <ruum-task-status-menu
                    *ngIf="!task.isMilestone"
                    [statusId]="task.status"
                    [disabled]="isReadOnly"
                    (statusChanged)="onChangeStatus($event)"
                >
                </ruum-task-status-menu>

                <ruum-milestone-status-menu
                    *ngIf="task.isMilestone"
                    [statusId]="task.status"
                    [dueDate]="task.dueDate"
                    [disabled]="isReadOnly"
                    (statusChanged)="onChangeStatus(task.id, task.parent, $event)"
                >
                </ruum-milestone-status-menu>
            </div>
            <div class="d-flex flex-column flex-fill minw-0 px-2 pb-2">
                <div class="py-1 ruum-task-name-small text-truncate">{{ task.description }}</div>

                <div class="d-flex flex-fill minw-0">
                    <div class="d-flex flex-column flex-fill minw-0">
                        <div class="d-flex flex-fill">
                            <div class="d-flex ruum-dot" *ngIf="task.assignees.length">
                                <button class="btn btn-icon btn-link-light btn-without-hover px-0 minw-0">
                                    <i class="icon icon-at"></i>
                                </button>
                                <div *ngFor="let assignee of task.assignees | slice: 0:2" class="d-flex">
                                    <span
                                        class="text-small font-weight-bold py-2 pl-1"
                                        [style.color]="assignee.color"
                                        >{{ assignee.initials }}</span
                                    >
                                </div>
                                <div *ngIf="task.assignees.length > 2" class="d-flex">
                                    <span class="text-small text-secondary py-2 pl-1"
                                        >+ {{ task.assignees.length - 2 }}</span
                                    >
                                </div>
                            </div>
                            <div class="d-flex" *ngIf="task.startDate || task.dueDate">
                                <span class="text-small text-secondary py-2">{{ date | formateDate }}</span>
                            </div>
                        </div>
                        <div
                            class="d-flex flex-fill minw-0 text-small mall text-light font-italic ruum-task-project-small"
                        >
                            <div class="d-flex d-flex minw-0">
                                <span class="text-truncate">
                                    <ng-container *ngIf="task.groupName">
                                        {{ task.groupName }}
                                        <span class="px-1">/</span>
                                    </ng-container>
                                    <ng-container *ngIf="task.projectName">
                                        {{ task.projectName }}
                                        <span class="px-1">/</span>
                                    </ng-container>
                                    <ng-container *ngIf="task.sectionName">
                                        {{ task.sectionName || '[Unnamed Section]' }}
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-start">
                        <ruum-task-priority-tag
                            *ngIf="task.priority"
                            [priority]="task.priority"
                            [onlyIcon]="true"
                            [componentClass]="'minw-0 pl-1 pr-0'"
                        >
                        </ruum-task-priority-tag>

                        <button
                            *ngIf="task.children.length > 0"
                            class="btn btn-icon btn-link-secondary btn-without-hover minw-0 pl-1 pr-0"
                        >
                            <i class="icon icon-subtask"></i>
                        </button>

                        <button
                            *ngIf="task.firstCommentId"
                            aria-label="Show comments for task"
                            class="btn btn-icon btn-link-secondary btn-without-hover minw-0 pl-1 pr-0"
                        >
                            <i class="icon icon-comment"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .ruum-dot:after {
                content: '•';
                color: rgba(37, 44, 66, 0.16);
                margin: 4px 8px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListItemSmallComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';
    @Input() task: TaskListItem;
    @Input() isReadOnly = false;
    @Output() changeStatus = new EventEmitter();
    @Output() itemClicked = new EventEmitter<TaskListItem>();

    get date() {
        return [this.task.startDate, this.task.dueDate];
    }

    constructor() {}

    onChangeStatus(status: TaskStatus) {
        this.changeStatus.emit(status);
    }

    getRuumLink() {
        return `/ruums/${this.task.projectId}/task/${this.task.id}`;
    }

    getCommentLink() {
        const ruumLink: string = this.getRuumLink();

        if (this.task.firstUnseenCommentId) {
            return `${ruumLink}/${this.task.firstUnseenCommentId}`;
        } else if (this.task.firstCommentId) {
            return `${ruumLink}/${this.task.firstCommentId}`;
        } else {
            return ruumLink;
        }
    }
}
