import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SavedViewFilter, SavedViewFilterValue } from '../../../common/connectors/savedView/saved-views.model';
import { SidenavService } from '../../../common/sidenav/sidenav.service';
import { TrackingConnector } from './../../../common/trackingConnector.service';

@Component({
    selector: 'ruum-generic-filter',
    template: `
        <div class="d-flex mr-3">
            <ruum-menu [componentClass]="'mx-0'" [offsetX]="offsetX" (isOpenChange)="toggleMenu($event)">
                <ruum-menu-trigger>
                    <button
                        class="btn btn-sm"
                        [class.btn-extra-light]="(hasAppliedFilters$ | async) === false"
                        [class.btn-primary-light]="(hasAppliedFilters$ | async) === true"
                        (click)="openFilter()"
                    >
                        <span class="pr-1"
                            >{{ (hasAppliedFilters$ | async) === true ? filterRules.length : '' }} Filter</span
                        >
                        <i class="icon icon-arrow-drop-down"></i>
                    </button>
                </ruum-menu-trigger>

                <div *ngIf="isMenuOpen" cdkTrapFocus class="d-flex flex-column filter-cont px-4 py-2">
                    <div class="d-flex flex-fill mb-3">
                        <span class="text-small text-dark">Meet all of the following rules</span>
                    </div>
                    <div
                        class="d-flex flex-fill mb-3"
                        *ngFor="let rule of filterRules$ | async; let index = index; trackBy: trackByFieldId"
                    >
                        <ruum-filter-rule
                            [selectedFieldIds]="selectedFieldIds$ | async"
                            [selectedFieldId]="rule.fieldId"
                            [selectedLogicalOperatorId]="rule.selectedLogic"
                            [selectedOptions]="rule.selectedOptions"
                            (ruleChanged)="onChangeFilter($event, index)"
                            (removeRule)="removeRule($event, index)"
                        >
                        </ruum-filter-rule>
                    </div>
                    <div class="d-flex flex-fill">
                        <button
                            class="btn btn-sm btn-outline-secondary btn-icon"
                            [disabled]="isAddRuleDisabled$ | async"
                            (click)="addRule()"
                        >
                            <i class="icon icon-add"></i>
                        </button>
                    </div>
                </div>
            </ruum-menu>

            <button
                *ngIf="totalItems"
                class="btn btn-xs btn-link-primary no-hover my-1"
                [ngbTooltip]="totalItemsTooltip"
            >
                <span>{{ totalItems | ruumShortNumber }} {{ totalItems === 1 ? 'Result' : 'Results' }}</span>
            </button>

            <ng-template #totalItemsTooltip>
                <div class="text-small font-weight-bold">
                    {{ totalItems }} {{ totalItems === 1 ? 'Result' : 'Results' }}
                </div>
            </ng-template>

            <button
                *ngIf="(hasAppliedFilters$ | async) === true"
                class="btn btn-xs btn-link-primary"
                (click)="clearRules()"
            >
                <i class="icon icon-cancel-filled"></i>
                <span class="pl-1">Clear</span>
            </button>
        </div>
    `,
    styles: [
        `
            .filter-cont {
                width: 534px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFilterComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() set rules(values: SavedViewFilter[]) {
        this.filterRulesSubject.next(values);
    }

    @Input() totalItems: number;
    @Input() isFilterOpen: boolean;
    @Output() filterChange = new EventEmitter<{ rules: SavedViewFilterValue[]; conjunction: string }>();
    @Output() closeMobileFilterOverlay = new EventEmitter();

    offsetX = 0;

    conjunction: string;

    isMenuOpen = false;

    isAddRuleDisabled$: Observable<boolean>;

    filterRulesSubject = new BehaviorSubject<SavedViewFilterValue[]>([]);

    get filterRules$(): Observable<SavedViewFilterValue[]> {
        return this.filterRulesSubject.asObservable();
    }

    get filterRules(): SavedViewFilterValue[] {
        return this.filterRulesSubject.getValue();
    }

    selectedFieldIdsSubject = new BehaviorSubject<string[]>([]);

    get selectedFieldIds$(): Observable<string[]> {
        return this.selectedFieldIdsSubject.asObservable();
    }

    get hasAppliedFilters$(): Observable<boolean> {
        return this.filterRulesSubject.asObservable().pipe(
            map((filterRules) => {
                return filterRules.map((filterRule) => filterRule.fieldId).some((isRuleValid) => !!isRuleValid);
            }),
        );
    }

    private ngOnDestroy$ = new Subject<void>();

    constructor(private ruumMainNavPanelService: SidenavService, private trackingConnector: TrackingConnector) {}

    ngOnInit() {
        this.conjunction = 'all';
        this.isAddRuleDisabled$ = this.isAddNewRuleDisabled();

        this.filterRulesSubject.pipe(takeUntil(this.ngOnDestroy$)).subscribe((filterRules) => {
            const ids = [...filterRules.map((filterRule) => filterRule.fieldId)];
            this.selectedFieldIdsSubject.next(ids);

            if (filterRules.length === 0) {
                this.addRule();
            }
        });
    }

    onChangeFilter(filterRule: SavedViewFilterValue, index: number) {
        if (this.filterRules[index].fieldId !== filterRule.fieldId) {
            filterRule = {
                ...filterRule,
                selectedLogic: undefined,
                selectedOptions: [],
            };
        }

        const list = [...this.filterRules];
        list.splice(index, 1, filterRule);
        this.filterRulesSubject.next(list);

        if (
            filterRule.fieldId &&
            filterRule.selectedLogic &&
            filterRule.selectedOptions &&
            filterRule.selectedOptions.length !== 0
        ) {
            this.trackingConnector.trackEvent('saved_view', 'filter', 'added', filterRule.fieldId, index);
            this.filterChange.emit({ rules: this.filterRules, conjunction: this.conjunction });
        }
    }

    addRule() {
        const filterRule: SavedViewFilterValue = this.getNewRule();
        this.filterRulesSubject.next([...this.filterRules, filterRule]);
    }

    removeRule(fieldId: string, index: number) {
        const list = [...this.filterRules];
        list.splice(index, 1);
        this.filterRulesSubject.next(list);
        this.trackingConnector.trackEvent('saved_view', 'filter', 'removed', fieldId, index);
        this.filterChange.emit({ rules: this.filterRules, conjunction: this.conjunction });
    }

    clearRules() {
        this.filterRulesSubject.next([]);
        this.filterChange.emit({ rules: [], conjunction: this.conjunction });
    }

    openFilter() {
        this.offsetX = this.ruumMainNavPanelService.isMainNavPanelOpen ? 152 : 0;
    }

    toggleMenu(isOpen: boolean) {
        const action = isOpen ? 'opened' : 'closed';
        this.trackingConnector.trackEvent('saved_view', 'filters_menu', action);
        this.isMenuOpen = isOpen;
    }

    trackByFieldId(index: number, item: SavedViewFilter) {
        return item.fieldId;
    }

    private getNewRule(): SavedViewFilterValue {
        return {
            fieldId: '',
            selectedLogic: undefined,
            selectedOptions: [],
        };
    }

    private isAddNewRuleDisabled(): Observable<boolean> {
        return this.filterRules$.pipe(
            map((filterRules) => {
                return !filterRules
                    .map((filterRule) => {
                        return (
                            filterRule.fieldId &&
                            filterRule.selectedLogic &&
                            filterRule.selectedOptions &&
                            filterRule.selectedOptions.length !== 0
                        );
                    })
                    .every((isRuleValid) => isRuleValid);
            }),
        );
    }
}
