import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';

const scrollPercent = 95;

@Component({
    selector: 'ruum-load-more',
    template: `
        <ruum-load-spinner [size]="size" [theme]="theme"></ruum-load-spinner>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class') hostClassList = 'd-block text-center p-2';

    @Input() scrollElement: HTMLElement;
    @Input() scrollTop = false;
    @Input() size = 'xl';
    @Input() theme: 'default' | 'white' = 'default';
    @Output() loadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

    private subsciption: Subscription;

    constructor(private renderer: Renderer2, private element: ElementRef) {}

    ngOnInit() {}

    ngAfterViewInit() {
        const parent: HTMLElement = this.scrollElement
            ? this.scrollElement
            : this.renderer.parentNode(this.element.nativeElement);
        this.createObserver(parent);
        this.checkIsVisible(parent);
    }
    ngOnDestroy() {
        this.removeObserver();
    }

    private createObserver(parent: HTMLElement): void {
        if (this.subsciption) {
            return;
        }

        this.subsciption = fromEvent(parent, 'scroll', { passive: true })
            .pipe(auditTime(100))
            .subscribe(this.onScroll.bind(this));
    }

    private removeObserver(): void {
        if (this.subsciption) {
            this.subsciption.unsubscribe();
        }
    }

    /**
     * check if the scroll container is on bottom
     * @param e scroll event
     */
    private onScroll(e: Event): void {
        const element = e.target as HTMLDivElement;
        this.checkIsVisible(element);
    }

    private checkIsVisible(element: HTMLElement) {
        const actualScrollHeight = element.scrollHeight - element.clientHeight;
        if (actualScrollHeight === 0) {
            this.loadMore.emit(true);
            return;
        }
        const positionPercentage = element.scrollTop / actualScrollHeight;
        if (!this.scrollTop) {
            if (positionPercentage > scrollPercent / 100) {
                this.loadMore.emit(true);
            }
        } else if (this.scrollTop) {
            if (positionPercentage < 20 / 100) {
                this.loadMore.emit(true);
            }
        }
    }
}
