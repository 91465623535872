import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TrackingConnector } from '../../trackingConnector.service';
import { NavbarItem } from './../../../common/navbar/navbar.model';
import { SecondaryNavbarService } from './secondary-navbar.service';

@Component({
    selector: 'ruum-secondary-navbar',
    template: `
        <ruum-secondary-navbar-top
            *ngIf="placement === 'top'"
            [tabs]="tabs"
            [activeTab]="activeTab"
            [buttons]="buttons"
            [activeButton]="activeButton"
            (clickTab)="clickTopTab($event)"
            (clickButton)="clickTopButton($event)"
        >
            <ng-container data-content="ruum-team-invite-button">
                <ng-content select="[data-content='ruum-team-invite-button']"></ng-content>
            </ng-container>
        </ruum-secondary-navbar-top>

        <ruum-secondary-navbar-bottom
            *ngIf="placement === 'bottom'"
            [activeTab]="activeTab"
            [tabs]="tabs"
            [buttons]="buttons"
            [activeButton]="activeButton"
            (clickTab)="clickBottomTab($event)"
            (clickButton)="clickBottomButton($event)"
        >
        </ruum-secondary-navbar-bottom>
    `,
})
export class SecondaryNavbarComponent implements OnInit {
    @Input()
    module = '';

    @Input()
    placement = 'top';

    @Input()
    tabs: NavbarItem[] = [];

    @Input()
    buttons: NavbarItem[] = [];

    subcriptions: Subscription[] = [];

    constructor(private trackingConnector: TrackingConnector, private secondaryNavbarService: SecondaryNavbarService) {}

    ngOnInit() {
        this.secondaryNavbarService.setTabs(this.tabs);
        this.secondaryNavbarService.setButtons(this.buttons);
    }

    get activeTab(): NavbarItem {
        return this.secondaryNavbarService.getActiveTab();
    }

    get activeButton(): NavbarItem {
        return this.secondaryNavbarService.getActiveButton();
    }

    clickTopTab(tab: NavbarItem) {
        this.secondaryNavbarService.triggerTopTab(tab);
        this.trackingConnector.trackEventInferCategoryFromUrl(`${this.module}_tab`, 'selected', tab.id);
    }

    clickBottomTab(tab: NavbarItem) {
        this.secondaryNavbarService.triggerBottomTab(tab);
        this.trackingConnector.trackEventInferCategoryFromUrl(`${this.module}_tab`, 'selected', tab.id);
    }

    clickTopButton(button: NavbarItem) {
        this.secondaryNavbarService.triggerTopButton(button);
    }

    clickBottomButton(button: NavbarItem) {
        this.secondaryNavbarService.triggerBottomButton(button);
        this.trackingConnector.trackEventInferCategoryFromUrl(`${this.module}_button`, 'selected', button.id);
    }
}
