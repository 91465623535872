import { EntityAction } from '@ruum/ruum-reducers';
import { SavedViewColumnValue, SavedViewFilterValue, SavedViewType } from './saved-views.model';

export const SAVED_VIEW_SELECT = 'SAVED_VIEW_SELECT';
export const SAVED_VIEW_UNSELECT = 'SAVED_VIEW_UNSELECT';
export const SAVED_VIEW_UPDATE_COLUMNS = 'SAVED_VIEW_UPDATE_COLUMNS';
export const SAVED_VIEW_UPDATE_FILTERS = 'SAVED_VIEW_UPDATE_FILTERS';
export const SAVED_VIEW_ADD_NEW_VIEW = 'SAVED_VIEW_ADD_NEW_VIEW';
export const SAVED_VIEW_CLONE_VIEW = 'SAVED_VIEW_CLONE_VIEW';

export type SavedViewSelectAction = EntityAction<'SAVED_VIEW_SELECT', { selectedSavedViewId: string }>;
export type SavedViewUnselectAction = EntityAction<
    'SAVED_VIEW_UNSELECT',
    {
        selectedSavedViewId: null;
    }
>;

export type SavedViewUpdateColumnsAction = EntityAction<
    'SAVED_VIEW_UPDATE_COLUMNS',
    {
        id: string;
        columns: SavedViewColumnValue[];
    }
>;

export type SavedViewUpdateFiltersAction = EntityAction<
    'SAVED_VIEW_UPDATE_FILTERS',
    {
        id: string;
        filters: SavedViewFilterValue[];
    }
>;

export type SavedViewAddNewViewAction = EntityAction<
    'SAVED_VIEW_ADD_NEW_VIEW',
    {
        id: string;
        name: string;
        filterValues: SavedViewFilterValue[];
        columnValues: SavedViewColumnValue[];
        viewType: SavedViewType;
    }
>;

export type SavedVieCloneViewAction = EntityAction<
    'SAVED_VIEW_CLONE_VIEW',
    {
        id: string;
        templateId: string;
    }
>;
