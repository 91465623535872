import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentSize, ComponentTheme, ComponentType } from '../ui-components.type';

function hexColorToRgba(hex: string, opacity: number) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
}

export type ProfileInitials = 1 | 2 | 3;

@Component({
    selector: 'ruum-profile',
    template: `
        <!-- Tooltip -->
        <ng-template #tooltip>
            <span>
                {{ name }}
            </span>
        </ng-template>

        <!-- Profile -->
        <button
            class="btn"
            type="button"
            [tabindex]="tabindex"
            [ngbTooltip]="tooltip"
            [disableTooltip]="disableTooltip"
            [tooltipClass]="tooltipTheme"
            [container]="'body'"
            [ngClass]="componentClass"
            [disabled]="disabled"
            [style.color]="colorValue"
            [style.background-color]="backgroundColorValue"
            [class.btn-avatar]="!noBackground"
            [class.px-0]="noBackground"
            [attr.aria-label]="ariaLabel ? ariaLabel : null"
        >
            <span>{{ name | ruumProfileInitials: initials }}</span>
        </button>
        <!-- Profile Label-->
        <span *ngIf="showName" class="ml-1 text-truncate" [ngClass]="size | ruumTextSize" [title]="name">{{
            name
        }}</span>
        <!-- Close button-->
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex align-items-center minw-0';

    @Input() name = '';
    @Input() icon: string;
    @Input() ariaLabel: string;
    @Input() initials: ProfileInitials = 2;
    @Input() rounded = true;
    @Input() disableTooltip = false;
    @Input() showName = false;

    /** Common UI properties */
    @Input() size: ComponentSize = 'sm';
    @Input() type: ComponentType = 'default';
    @Input() theme: ComponentTheme = 'primary';

    @Input() tabindex = -1;

    /** States */
    @Input() hover = false;
    @Input() active = false;
    @Input() disabled = false;

    /* @deprecated */
    @Input() color = '#FFFFFF';
    /* @deprecated */
    @Input() noBackground = false;

    @Input() tooltipTheme: string;

    ngOnInit() {}

    /* @deprecated */
    get colorValue() {
        return this.theme ? '' : this.color;
    }

    /* @deprecated */
    get backgroundColorValue() {
        const backgroundColor = this.noBackground ? 'transparent' : hexColorToRgba(this.color, 0.08);
        return this.theme ? '' : backgroundColor;
    }

    get componentClass(): string[] {
        return [
            this.getSizeClass(),
            this.getThemeClass(),
            this.getRoundClass(),
            this.getNoHoverClass(),
            this.getActiveClass(),
        ].filter((el) => !!el);
    }

    private getSizeClass(): string {
        return ['btn', this.size].filter((el) => !!el).join('-');
    }

    private getThemeClass(): string {
        return ['btn', this.getType(), this.theme].filter((el) => !!el).join('-');
    }

    private getType(): string {
        const typeMap = {
            default: '',
            outline: 'outline',
            link: 'link',
        };
        return typeMap[this.type] || typeMap.default;
    }

    private getRoundClass(): string {
        return this.rounded ? 'btn-round' : '';
    }

    private getNoHoverClass(): string {
        return this.hover ? '' : 'btn-without-hover';
    }

    private getActiveClass(): string {
        return this.active ? 'active' : '';
    }
}
