import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import {
    ProjectParticipantWithAllRoles,
    UserListItem,
    WorkspaceParticipantWithAllRoles,
} from '../../../common/connectors/readModelConnector.service';
import { TaskAssigneesMenuComponent } from './task-assignees-menu.component';

export interface AllAssignees {
    projectAssignees: UserListItem[];
    otherAssignees: UserListItem[];
}

export function getDrodownData(
    component: TaskAssigneesMenuComponent,
): Observable<[AllAssignees, FunctionalRoleListItem[]]> {
    return combineLatest([component.assigneesSearchQuery$, component.fetching$]).pipe(
        debounceTime(300),
        filter(([search, fetching]) => fetching),
        switchMap(([search, fetching]) =>
            combineLatest([getAllAssignees(component, search), getFunctionalRoles(component, search)]),
        ),
    );
}

function getAllAssignees(component: TaskAssigneesMenuComponent, search: string): Observable<AllAssignees> {
    if (component.hideUsers) {
        return of({
            projectAssignees: [],
            otherAssignees: [],
        });
    }

    return component
        .getProjectAccess(
            { page: 1, pageSize: 25 },
            component.projectId,
            {
                search,
                isTechnicalUser: false,
            },
            { by: 'access' },
        )
        .pipe(
            map((data) => {
                const projectAssignees = data.rows
                    .filter((participant) => participant.projectParticipantRole)
                    .map((participant: ProjectParticipantWithAllRoles) => toUserListItem(participant))
                    .sort((a: any, b: any) => sortListItems(a, b));

                const otherAssignees = data.rows
                    .filter((participant) => !participant.projectParticipantRole)
                    .map((participant: ProjectParticipantWithAllRoles) => toUserListItem(participant))
                    .sort((a: any, b: any) => sortListItems(a, b));

                return {
                    projectAssignees,
                    otherAssignees,
                };
            }),
            catchError((err, caught) => {
                console.error(err);
                component.warning(`Error getting Users`, err);
                return of({
                    projectAssignees: [],
                    otherAssignees: [],
                });
            }),
        );
}

function getFunctionalRoles(
    component: TaskAssigneesMenuComponent,
    search: string,
): Observable<FunctionalRoleListItem[]> {
    if (component.hideRoles) {
        return of([]);
    }

    return component
        .getFunctionalRoles(
            { page: 1, pageSize: 50 },
            {
                workspaceId: component.workspaceId,
                // the parameter "relativeToProjectId" sets the disabled flag on the resultset, which leads to disabled roles in the dialog.
                relativeToProjectId: component.projectId,
                search,
            },
        )
        .pipe(
            map((page) =>
                page.rows.map((row) => ({
                    ...row,
                    disabled: !component.isAdmin && !row.assigned,
                })),
            ),
            map((rows) => rows.filter((row) => !row.disabled)),
            catchError((err) => {
                console.error(err);
                component.warning(`Error getting Roles`, err);
                return of([]);
            }),
        );
}

function toUserListItem(participant: ProjectParticipantWithAllRoles | WorkspaceParticipantWithAllRoles): UserListItem {
    return {
        id: participant.id,
        fullName: participant.fullName,
        initials: participant.initials,
        color: participant.color,
        mail: participant.mail,
    };
}

function sortListItems(a, b) {
    if (a.fullName > b.fullName) {
        return 1;
    }
    if (a.fullName < b.fullName) {
        return -1;
    }
    return 0;
}
