import {
    CommentableType,
    CommentActionPayload,
    CommentActionTypes,
    CommentsReducer,
    ProjectAction,
    RuumAction,
    RuumComment,
} from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { PaginatedList } from '../paginatedList.model';

export interface CommentListItem extends RuumComment {
    unseen: boolean;
    projectId: string;
    changedAt: number;
    changedBy: string;
}
export interface CommentableData {
    projectId?: string;
    objectType?: CommentableType | '';
    objectId?: string;
    objectText?: string;
    list?: PaginatedList<CommentListItem>;
}

export type CommentableLoadedAction = ProjectAction<
    'COMMENTABLE_LOADED',
    {
        data: CommentableData;
    }
>;

export type CommentableResetAction = ProjectAction<
    'COMMENTABLE_RESET',
    {
        resetList: boolean;
    }
>;

export type CommentListLoadedAction = ProjectAction<
    'COMMENT_LIST_LOADED',
    {
        list: PaginatedList<CommentListItem>;
    }
>;

export const COMMENTABLE_ACTION_TYPES = {
    COMMENTABLE_LOADED: 'COMMENTABLE_LOADED',
    COMMENTABLE_RESET: 'COMMENTABLE_RESET',
    COMMENT_LIST_LOADED: 'COMMENT_LIST_LOADED',
};

export function CommentableWebAppReducer(
    currentState: CommentableData = defaultCommentable(),
    action: RuumAction,
): CommentableData {
    if (!!CommentActionTypes[action.type]) {
        const payload: CommentActionPayload = action.payload;
        if (
            action.entityId === currentState.projectId &&
            payload.onObjectId === currentState.objectId &&
            payload.onObjectType === currentState.objectType
        ) {
            return {
                ...currentState,
                list: runCommentsReducer(currentState.list, action),
            };
        }
    }

    switch (action.type) {
        case COMMENTABLE_ACTION_TYPES.COMMENTABLE_LOADED:
            return loadedCommentable(currentState, action);
        case COMMENTABLE_ACTION_TYPES.COMMENT_LIST_LOADED:
            return loadedComments(currentState, action);
        case COMMENTABLE_ACTION_TYPES.COMMENTABLE_RESET:
            return reset(currentState, action);
        default:
            return currentState;
    }
}

function loadedCommentable(currentState: CommentableData, action: CommentableLoadedAction): CommentableData {
    return {
        ...currentState,
        ...action.payload.data,
    };
}

function loadedComments(currentState: CommentableData, action: CommentListLoadedAction): CommentableData {
    if (
        parseInt(action.payload.list.currentPage as any, 10) > 1 &&
        currentState.list.rows.length < currentState.list.totalItems
    ) {
        return {
            ...currentState,
            list: {
                ...action.payload.list,
                rows: currentState.list.rows.concat(action.payload.list.rows),
            },
        };
    } else {
        return {
            ...currentState,
            list: action.payload.list,
        };
    }
}

function reset(currentState: CommentableData, action: CommentableResetAction): CommentableData {
    if (action.payload.resetList) {
        return defaultCommentable();
    } else {
        return {
            ...currentState,
            projectId: '',
            objectType: '',
            objectId: '',
        };
    }
}

function runCommentsReducer(
    currentState: PaginatedList<CommentListItem>,
    action: RuumAction,
): PaginatedList<CommentListItem> {
    const newRows = <CommentListItem[]>CommentsReducer(currentState.rows, action);
    return {
        ...currentState,
        rows: newRows,
    };
}

export function defaultCommentable(): CommentableData {
    return {
        projectId: '',
        objectType: '',
        objectId: '',
        list: getDefaultPaginatedList(),
    };
}
