import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-task-list-cell-project',
    template: `
        <div
            (click)="$event.stopPropagation()"
            (keydown.space)="$event.stopPropagation()"
            (keydown.enter)="$event.stopPropagation()"
            class="minw-0 w-100"
        >
            <a class="cursor-pointer d-flex minw-0" [routerLink]="getTaskLink()">
                <span
                    class="small text-dark text-truncate pr-4"
                    [ngbTooltip]="projectTooltipContent"
                    [placement]="['top', 'bottom', 'auto']"
                >
                    {{ task.projectName }}
                </span>
            </a>
        </div>

        <ng-template #projectTooltipContent>
            <div class="small interface">{{ task.projectName }}</div>
            <i class="small" style="color: #FFFFFF; opacity: 0.56;">Click to go to Ruum</i>
        </ng-template>
    `,
})
export class TaskListCellProjectComponent {
    @HostBinding() class = 'd-flex align-items-center w-100';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }

    task: any = {};

    constructor() {}

    getTaskLink() {
        return `/projects/${this.task.projectId}/tasks`;
    }
}
