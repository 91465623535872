import { Injectable } from '@angular/core';

/**
 * Use this instead of the native window object directly.
 *
 * This allows one to mock this in test cases.
 */
@Injectable({
    providedIn: 'root',
})
export class WindowRef {
    open(url: string, target?: string): void {
        window.open(url, target);
    }

    historyGoBack(): void {
        window.history.back();
    }

    pageOffset(): { pageXOffset: number; pageYOffset: number } {
        return {
            pageXOffset: window.pageXOffset,
            pageYOffset: window.pageYOffset,
        };
    }
}
