import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-tag-v1',
    template: `
        <button
            class="btn btn-round btn-light"
            [class.btn-xs]="size === 'xs'"
            [class.btn-sm]="size === 'sm'"
            [class.btn-lg]="size === 'lg'"
            [class.active]="selected"
            [class.btn-without-hover]="!clickable && !selected"
            [class.pr-1]="showRemoveIcon"
            type="button"
            [title]="name"
        >
            <span
                class="text-truncate"
                [class.mr-1]="showRemoveIcon"
                [class.tiny]="size === 'xs'"
                [class.small]="size === 'sm' || 'lg'"
                [class.text-dark]="!selected"
            >
                {{ name }}
            </span>
            <i *ngIf="showRemoveIcon" class="icon" [class.icon-cancel]="showRemoveIcon"> </i>
        </button>
    `,
})
export class RuumTagComponent {
    @HostBinding('class') hostClassList = 'd-flex minw-0';

    @Input()
    name = '';

    @Input()
    size: string;

    @Input()
    selected = false;

    @Input()
    showRemoveIcon = false;

    @Input()
    clickable = true;
}
