import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UnseenInfo } from '@ruum/ruum-reducers';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RuumUIStateService } from '../../../ruum/ui-state/ruumUIState.service';
import { ChatListService } from '../../connectors/chats/chats-list.service';
import { NavbarItem } from './../navbar.model';

export interface UnseenChangesCounters {
    emails: number;
    attachments: number;
    team: number;
    comments: number;
    chats: number;
}
@Injectable({
    providedIn: 'root',
})
export class SecondaryNavbarService {
    private tabs: NavbarItem[] = [];

    private activeTab: NavbarItem;

    private buttons: NavbarItem[] = [];

    private activeButton: NavbarItem = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ruumUIStateService: RuumUIStateService,
        private chatListService: ChatListService,
    ) {
        this.subscribeRouterEvents();
    }

    setTabs(tabs) {
        this.tabs = tabs;
        this.activeTab = this.getActiveTabByRouteData();
    }

    setButtons(buttons) {
        this.buttons = buttons;
        this.activeButton = this.getActiveButtonByRouteData();
    }

    getActiveTab(): NavbarItem {
        return this.activeTab;
    }

    getActiveButton() {
        return this.activeButton;
    }

    getActiveTabByRouteData(): NavbarItem {
        if (
            this.route.root.firstChild && // App
            this.route.root.firstChild.firstChild && // Home, Group, Project, Templates, ...
            this.route.root.firstChild.firstChild.firstChild // Lobby List, Task List, Canvas, Sidepanels, ...
        ) {
            const tabId: string = this.route.root.firstChild.firstChild.firstChild.snapshot.data.id;
            return this.tabs.find((tab) => tab.id === tabId) || this.tabs[0];
        } else {
            return this.tabs[0];
        }
    }

    getActiveButtonByRouteData(): NavbarItem {
        let sidepanelOutlet;
        if (this.route.root.firstChild.firstChild) {
            sidepanelOutlet = this.route.root.firstChild.firstChild.children.find(
                (item) => item.outlet === 'sidepanel',
            );
        }

        if (sidepanelOutlet) {
            return this.buttons.find((button) => button.id === sidepanelOutlet.snapshot.data.id);
        } else {
            return null;
        }
    }

    triggerTopTab(tab: NavbarItem) {
        this.activeTab = tab;
        this.router.navigate([{ outlets: { primary: [tab.path] } }], {
            relativeTo: this.route.root.firstChild.firstChild,
        });
    }

    triggerBottomTab(tab: NavbarItem) {
        this.activeTab = tab;
        this.activeButton = null;
        this.router
            .navigate([{ outlets: { primary: [tab.path] } }], { relativeTo: this.route.root.firstChild.firstChild })
            .then(() => this.closeSidepanel());
    }

    triggerTopButton(navButton) {
        if (this.activeButton && this.activeButton.id === navButton.id) {
            this.activeButton = null;
            this.closeSidepanel();
        } else {
            this.activeButton = navButton;
            this.router.navigate([{ outlets: { sidepanel: [navButton.path] } }], {
                relativeTo: this.route.root.firstChild.firstChild,
            });
        }
    }

    getUnseenChangesCounters(): Observable<UnseenChangesCounters> {
        return combineLatest([this.ruumUIStateService.allSideMenus(), this.chatListService.unseenComments()]).pipe(
            map(([panelsInfo, unseenComments]) => {
                const counterMap: any = {};
                // Theres no 'tasks' in UnseenPanelsInfo interface
                // Removed in ruum-reducer #169
                Object.keys(panelsInfo)
                    .filter((panelId) => panelId !== 'tasks')
                    .forEach((panelId) => {
                        counterMap[panelId] = (
                            panelsInfo[panelId].unseenItemsInfo.filter(
                                (info: UnseenInfo) => info.stillNewCounter > 1,
                            ) || []
                        ).length;
                    });

                counterMap.chats = unseenComments;
                return counterMap as UnseenChangesCounters;
            }),
        );
    }

    closeSidepanel() {
        return this.router.navigate([{ outlets: { sidepanel: null } }], {
            relativeTo: this.route.root.firstChild.firstChild,
        });
    }

    triggerBottomButton(navButton) {
        this.activeButton = navButton;
        this.router.navigate([{ outlets: { sidepanel: [navButton.path] } }], {
            relativeTo: this.route.root.firstChild.firstChild,
        });
    }

    private subscribeRouterEvents(): Subscription {
        return this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeTab = this.getActiveTabByRouteData();
                this.activeButton = this.getActiveButtonByRouteData();
            }
        });
    }
}
