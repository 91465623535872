import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InvolvedUsersService } from '../../../common/connectors/users/users.service';

@Pipe({ name: 'ruumTemplateCreatorName' })
export class TemplateCreatorNamePipe implements PipeTransform {
    constructor(private involvedUsersService: InvolvedUsersService) {}

    transform(creatorId: string, categoryId: string): Observable<string> {
        if (creatorId === 'admin') {
            const name = this.getNameByCategor(categoryId);
            return of(name);
        } else {
            return this.involvedUsersService.dataList([creatorId]).pipe(
                map((list) => list[0]),
                filter((item) => !!item),
                map((item) => {
                    if (item.fullName === 'Failed to load') {
                        return this.getNameByCategor(categoryId);
                    } else {
                        return item.fullName;
                    }
                }),
            );
        }
    }

    private getNameByCategor(categoryId: string): string {
        return categoryId === 'owned' || categoryId === 'shared' ? 'Ruum Admin' : 'Ruum Template';
    }
}
