import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruumTextSize' })
export class TextSizePipe implements PipeTransform {
    transform(size: string): string {
        const sizeMap = {
            xs: 'text-small',
            sm: 'text-small',
            md: '',
        };

        return sizeMap[size] || sizeMap.md;
    }
}
