import { RuumAction } from '@ruum/ruum-reducers';
import { LobbyListItemProject, LobbyListItemProjectGroup } from '../common/connectors/lobbyList/lobby-list.model';
import { LobbyListItemReducer } from '../common/connectors/lobbyList/lobby-list.reducer';

export const SELECTED_LOBBY_LIST_ITEM_PROJECT_REDUCER_ACTIONS = {
    LOAD_LOBBY_LIST_ITEM_PROJECT: 'LOAD_LOBBY_LIST_ITEM_PROJECT',
};

/**
 * The LobbyListItemProject of the selected Project.
 */
export function SelectedLobbyListItemProjectReducer(
    currentState: LobbyListItemProject,
    action: RuumAction,
): LobbyListItemProject {
    switch (action.type) {
        case SELECTED_LOBBY_LIST_ITEM_PROJECT_REDUCER_ACTIONS.LOAD_LOBBY_LIST_ITEM_PROJECT:
            return action.payload;
        default:
            return LobbyListItemReducer(currentState ? [currentState] : [], action)[0] as LobbyListItemProject;
    }
}

export const SELECTED_LOBBY_LIST_ITEM_PG_REDUCER_ACTIONS = {
    LOAD_LOBBY_LIST_ITEM_PROJECT_GROUP: 'LOAD_LOBBY_LIST_ITEM_PROJECT_GROUP',
};

/**
 * The LobbyListItemProjectGroup of the selected Project Group.
 */
export function SelectedLobbyListItemPGReducer(
    currentState: LobbyListItemProjectGroup,
    action: RuumAction,
): LobbyListItemProjectGroup {
    switch (action.type) {
        case SELECTED_LOBBY_LIST_ITEM_PG_REDUCER_ACTIONS.LOAD_LOBBY_LIST_ITEM_PROJECT_GROUP:
            return action.payload;
        default:
            return LobbyListItemReducer(currentState ? [currentState] : [], action)[0] as LobbyListItemProjectGroup;
    }
}
