import { Pipe, PipeTransform } from '@angular/core';
import { IconClass } from './icon-classes';

@Pipe({
    name: 'ruumObjectTypeToIcon',
})
export class ObjectTypeToIconPipe implements PipeTransform {
    // TODO: Stop using toLowerCase everywhere so we can use the ActivityTypeCanvasObject, CustomFieldDefinitionType, etc.
    transform(value: string): IconClass {
        switch (value) {
            /** Custom Fields */
            case 'string': {
                return 'icon-text';
            }
            case 'long_string': {
                return 'icon-long-text';
            }
            case 'selection_list': {
                return 'icon-poll';
            }
            case 'number': {
                return 'icon-hashtag';
            }
            case 'email': {
                return 'icon-email';
            }
            case 'link': {
                return 'icon-link';
            }
            case 'date': {
                return 'icon-date';
            }
            case 'createdOn': {
                return 'icon-date';
            }
            case 'approvalActions': {
                return 'icon-thumbs-up-down';
            }
            case 'phone': {
                return 'icon-phone';
            }
            case 'record': {
                return 'icon-record-lookup';
            }
            /** Standard Fields */
            case 'lobbyStatus': {
                return 'icon-tasks';
            }
            case 'favorite': {
                return 'icon-fav';
            }
            case 'comments': {
                return 'icon-comments';
            }
            case 'comment': {
                return 'icon-comment';
            }
            case 'lobbyName': {
                return 'icon-text';
            }
            case 'changedAt': {
                return 'icon-activity';
            }
            case 'createdBy': {
                return 'icon-user';
            }
            case 'requestedBy': {
                return 'icon-user';
            }
            case 'approver': {
                return 'icon-assign-task';
            }
            case 'responsibles': {
                return 'icon-assign-task';
            }
            case 'approvalStatus': {
                return 'icon-tasks';
            }
            case 'tags': {
                return 'icon-label';
            }
            case 'workspace': {
                return 'icon-workspace';
            }
            case 'taskStatus': {
                return 'icon-tasks';
            }
            case 'milestone': {
                return 'icon-milestone';
            }
            case 'taskName': {
                return 'icon-text';
            }
            case 'section': {
                return 'icon-canvas';
            }
            case 'project': {
                return 'icon-canvas';
            }
            case 'group': {
                return 'icon-folder';
            }
            case 'subtasks': {
                return 'icon-subtask';
            }
            case 'priority': {
                return 'icon-priority-outlined';
            }
            case 'startDate': {
                return 'icon-date-start';
            }
            case 'dueDate': {
                return 'icon-date-due';
            }
            case 'assignees': {
                return 'icon-assign-task';
            }
            case 'roles': {
                return 'icon-role';
            }
            case 'approverRole': {
                return 'icon-role';
            }
            case 'functional_role': {
                return 'icon-role';
            }
            case 'task': {
                return 'icon-tasks';
            }
            case 'attachment': {
                return 'icon-file';
            }
            case 'file': {
                return 'icon-file';
            }
            case 'poll': {
                return 'icon-poll';
            }
            case 'customStatus': {
                return 'icon-info';
            }
            case 'custom_status': {
                return 'icon-info';
            }
            case 'processBar': {
                return 'icon-process-bar';
            }
            case 'process_bar': {
                return 'icon-process-bar';
            }
            case 'approval': {
                return 'icon-thumbs-up';
            }
            case 'participant': {
                return 'icon-team';
            }
            case 'team': {
                return 'icon-team';
            }
            /** Actions */
            case 'see_ruum': {
                return 'icon-visibility-on';
            }
            case 'change_ruum_name': {
                return 'icon-canvas';
            }
            default: {
                return 'icon-text';
            }
        }
    }
}
