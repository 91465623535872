import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, pairwise, map, distinctUntilChanged, auditTime, tap } from 'rxjs/operators';

const scrollUpVelocity = 30; // 10px in 50ms;

@Injectable({ providedIn: 'root' })
export class DocumentScrollService {
    scrollObservable$: Observable<ScrollPosition>;

    private scrollPercent = 95;

    constructor() {
        this.scrollObservable$ = fromEvent(window, 'scroll', { passive: true }).pipe(
            map<any, ScrollPosition>(
                (e: any): ScrollPosition => {
                    return {
                        sH: document.documentElement.scrollHeight,
                        sT: window.pageYOffset || document.documentElement.scrollTop,
                        cH: document.documentElement.clientHeight,
                    };
                },
            ),
        );
    }

    userScrolledToBottom$() {
        return this.scrollObservable$.pipe(
            pairwise(),
            filter((positions) => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1])),
        );
    }

    hasVerticalScrollbar(): boolean {
        const element = window.document.documentElement;
        return element.scrollHeight > element.clientHeight;
    }

    isScrollUp$(): Observable<boolean> {
        return this.scrollObservable$.pipe(
            pairwise(),
            map((positions) => !this.isUserScrollingDown(positions)),
            distinctUntilChanged(),
        );
    }

    isScrollUpWithVelocity$(): Observable<boolean> {
        let pushedNextValue = false;
        return this.scrollObservable$.pipe(
            auditTime(100),
            pairwise(),
            map((positions) => {
                const isScrollDown = this.isUserScrollingDown(positions);
                if (isScrollDown) {
                    pushedNextValue = false;
                    return false;
                }
                if (pushedNextValue) {
                    return true;
                }
                const isScrollUp = this.isScrollUpCaluclation(positions, scrollUpVelocity);
                if (isScrollUp) {
                    pushedNextValue = true;
                    return true;
                }
                return false;
            }),
            distinctUntilChanged(),
        );
    }

    private isUserScrollingDown = (positions) => {
        return positions[0].sT < positions[1].sT;
    };

    private isScrollExpectedPercent = (position) => {
        const positionPercentage = (position.sT + position.cH) / position.sH;
        return positionPercentage > this.scrollPercent / 100;
    };

    private isScrollUpCaluclation = (positions, velocity) => {
        return positions[0].sT > positions[1].sT + velocity;
    };
}

interface ScrollPosition {
    sH: number;
    sT: number;
    cH: number;
}
