import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { RuumDropdownItem } from '../../../shared/ui-components/dropdown/dropdown.model';

export type RuumStatusSize = 'xs' | 'sm' | 'md' | 'lg';
// [class.text-dark]="!active && isLightTheme(status.theme)"
// [class.text-white]="active && isLightTheme(status.theme)"
@Component({
    selector: 'ruum-status-button',
    template: `
        <button
            class="btn btn-round ruum-status-label"
            type="button"
            [disabled]="isReadOnly"
            [class.btn-xs]="size === 'xs'"
            [class.btn-sm]="size === 'sm'"
            [class.btn-lg]="size === 'lg'"
            [class.btn-outline-light]="status.theme === 'light' && !active"
            [class.btn-dark]="status.theme === 'light' && active"
            [class.btn-danger-light]="status.theme === 'danger-light' && !active"
            [class.btn-danger]="status.theme === 'danger-light' && active"
            [class.btn-warning-light]="status.theme === 'warning-light' && !active"
            [class.btn-warning]="status.theme === 'warning-light' && active"
            [class.btn-info-light]="status.theme === 'info-light' && !active"
            [class.btn-info]="status.theme === 'info-light' && active"
            [class.btn-success-light]="status.theme === 'success-light' && !active"
            [class.btn-success]="status.theme === 'success-light' && active"
            (click)="selectChange.emit(status)"
        >
            <span>
                {{ status.name }}
            </span>
        </button>
    `,
    styles: [
        `
            .ruum-status-label {
                width: 86px;
            }
        `,
    ],
})
export class RuumStatusButtonComponent {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input()
    status: RuumDropdownItem;

    @Input()
    active = false;

    @Input()
    isReadOnly = false;

    @Input()
    size: RuumStatusSize = 'xs';

    @Output()
    selectChange = new EventEmitter<RuumDropdownItem>();

    isLightTheme(theme: RuumDropdownItem['theme']): boolean {
        return (
            theme === 'danger-light' || theme === 'warning-light' || theme === 'info-light' || theme === 'success-light'
        );
    }
}
