import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Day, not, RuumTask, TaskPriority } from '@ruum/ruum-reducers';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RuumUIStateService } from '../../../app/ruum/ui-state/ruumUIState.service';
import { RuumTasksService } from '../../ruum/tasks/tasks.service';
import { TaskAssignmentsIds } from '../../shared/task/task-assignees/task-assignees-menu.component';
import { RuumDropdownItem } from '../../shared/ui-components/dropdown/dropdown.model';
import { AddCommentData } from '../comments/components/add-comment-text-input.component';
import { RuumAlertOptions, RuumAlertService } from '../components/alert/alert.service';
import { InvolvedUsersService } from '../connectors/users/users.service';
import { SecondaryNavbarService } from '../navbar/secondary-navbar/secondary-navbar.service';
import { isEqual } from '../utils.service';
import { InputValidator } from '../validators/inputValidator.service';
import { NavbarService } from './../../common/navbar/navbar.service';
import { BOOTSTRAP_BREAKPOINTS } from './../ruum-bootstrap/breakpoints';
import { MoveToSectionDialogComponent } from './move-to-section/move-to-section.component';

@Component({
    selector: 'ruum-task-common-details',
    template: `
        <div class="d-flex flex-fill flex-column minw-0">
            <ruum-sidepanel-back-button
                [name]="'Back to Parent Task'"
                (click)="showParentTaskDetails()"
                *ngIf="task.parent"
            >
            </ruum-sidepanel-back-button>
            <div class="d-flex flex-fill align-items-center py-5 pr-5 pl-4">
                <div class="d-flex flex-fill">
                    <ruum-task-status-menu
                        *ngIf="task.isMilestone !== true"
                        [statusId]="task.status"
                        [showName]="true"
                        [disabled]="isReadOnly || (isOffline$ | async)"
                        (statusChanged)="changeStatus.emit($event)"
                    >
                    </ruum-task-status-menu>
                    <ruum-milestone-status-menu
                        *ngIf="task.isMilestone === true"
                        [statusId]="task.status"
                        [dueDate]="task.dueDate"
                        [showName]="true"
                        [disabled]="isReadOnly || (isOffline$ | async)"
                        (statusChanged)="changeStatus.emit($event)"
                    >
                    </ruum-milestone-status-menu>
                </div>
                <div class="d-flex">
                    <button
                        *ngIf="isDesktop$ | async"
                        class="ml-3 btn btn-sm btn-link-light"
                        (click)="onShowOnCanvas(false)"
                    >
                        Show on Canvas
                    </button>
                    <button
                        *ngIf="(isDesktop$ | async) !== true"
                        class="ml-3 btn btn-sm btn-link-light"
                        (click)="onShowOnCanvas(true)"
                    >
                        Show on Canvas
                    </button>
                </div>
                <div class="d-flex">
                    <ruum-task-details-options
                        *ngIf="!isReadOnly && (isOffline$ | async) !== true"
                        [task]="task"
                        [workspaceId]="workspaceId"
                        [isAdmin]="isAdmin"
                        [isLocked]="isLocked"
                        (deleteTask)="onDeleteTask($event)"
                        (moveToSection)="onMoveToSection($event)"
                        (setIsMilestone)="onSetIsMilestone($event)"
                        (assignCustomField)="onAssignCustomField($event)"
                    >
                    </ruum-task-details-options>
                </div>
            </div>

            <div class="d-flex flex-column px-4 mb-2">
                <autoresize-textarea
                    ngDefaultControl
                    [formControlSize]="'sm'"
                    (blurChange)="onChangeDescription($event)"
                    [ngModel]="task.description"
                    [isReadOnly]="isReadOnly || (isOffline$ | async) || isLocked"
                    placeholder="Type the task description here"
                    [errorMessage]="taskDescriptionErrorMessage"
                    (ngModelChange)="validateInput($event)"
                >
                </autoresize-textarea>
            </div>
            <div
                class="d-flex flex-fill align-items-center ml-4 pr-4 bg-white"
                *ngIf="taskDescriptionErrorMessage !== undefined"
            >
                <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                    <i class="icon icon-cancel"></i>
                </button>
                <div class="text-tiny text-danger">
                    {{ taskDescriptionErrorMessage }}
                </div>
            </div>

            <div class="d-flex flex-column px-5 border-bottom border-light">
                <div class="mb-4 text-small text-secondary">
                    Created by <span class="text-dark">{{ getCreatorName() | async }}</span
                    >, {{ task.createdAt | date: 'd MMM' }}
                </div>

                <div class="mb-4 text-small text-secondary">
                    <button
                        area-label="Label"
                        class="btn btn-xs btn-link-secondary px-0"
                        (click)="goToTaskActivity($event, task.id)"
                        data-test="task-activity-button"
                        *ngIf="!isTemplate"
                    >
                        <i class="icon icon-activity"></i>
                        <span class="text-uppercase pl-2">Task Activity</span>
                        <i class="icon icon-forward"></i>
                    </button>
                </div>
            </div>

            <ng-content select="ruum-project-task-transition-configuration"></ng-content>

            <div class="d-flex flex-column px-5  py-4 border-bottom border-light">
                <ng-container *ngIf="!isTemplate">
                    <div class="d-flex flex-fill mb-2 hov">
                        <form-label text="Assignees" icon="icon-team"></form-label>
                        <div class="d-flex flex-fill">
                            <ruum-task-assignees-menu
                                [assigneeIds]="task.assignees"
                                [assignedFunctionalRoleIds]="task.assignedFunctionalRoles"
                                [projectId]="projectId"
                                [placement]="['bottom', 'top', 'left']"
                                [maxShownAssignees]="5"
                                [showFullNameOfFirstAssignee]="true"
                                [defaultButtonTheme]="'outline'"
                                [isReadOnly]="isReadOnly || (isOffline$ | async)"
                                (assigneesChanged)="onAssigneesChange($event)"
                                [workspaceId]="workspaceId"
                                [isAdmin]="isAdmin"
                                [task]="task"
                                [hideRoles]="true"
                            >
                            </ruum-task-assignees-menu>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="workspaceId">
                    <div class="d-flex flex-fill mb-2 hov">
                        <form-label text="Roles" icon="icon-role"></form-label>
                        <div class="d-flex flex-fill">
                            <ruum-task-assignees-menu
                                [assigneeIds]="task.assignees"
                                [assignedFunctionalRoleIds]="task.assignedFunctionalRoles"
                                [projectId]="projectId"
                                [placement]="['bottom', 'top', 'left']"
                                [maxShownAssignees]="5"
                                [showFullNameOfFirstAssignee]="true"
                                [defaultButtonTheme]="'outline'"
                                [isReadOnly]="isReadOnly || (isOffline$ | async)"
                                (assigneesChanged)="onAssigneesChange($event)"
                                [workspaceId]="workspaceId"
                                [isAdmin]="isAdmin"
                                [task]="task"
                                [hideUsers]="true"
                                [canInviteParticipants]="canInviteParticipants"
                            >
                            </ruum-task-assignees-menu>
                        </div>
                    </div>
                </ng-container>

                <div class="d-flex flex-fill mb-2">
                    <form-label text="{{ task.isMilestone ? 'Date' : 'Dates' }}" icon="icon-date-due"></form-label>
                    <div class="d-flex flex-fill">
                        <task-period
                            *ngIf="!task.isMilestone"
                            [startDate]="task.startDate"
                            [dueDate]="task.dueDate"
                            [isReadOnly]="isReadOnly || (isOffline$ | async)"
                            (changeStartDate)="changeStartDate.emit($event)"
                            (changeDueDate)="changeDueDate.emit($event)"
                            [isOverdue]="task.status !== 'DONE' && (task.dueDate | dateIsOverdue)"
                        >
                        </task-period>

                        <ruum-datepicker
                            *ngIf="task.isMilestone === true"
                            [disableSince]=""
                            [isReadOnly]="isReadOnly || (isOffline$ | async)"
                            [defaultDate]=""
                            placeholder="Due Date"
                            fullDateFormat="false"
                            [customDateFormat]="'WWW. dd mmm'"
                            (dateChanged)="changeDueDate.emit($event)"
                            (resetDates)="changeDueDate.emit($event)"
                            [date]="task.dueDate"
                            [isStartDay]="true"
                        >
                        </ruum-datepicker>
                    </div>
                </div>

                <div class="d-flex flex-fill" *ngIf="!task.isMilestone">
                    <form-label text="Priority" icon="icon-priority-outlined"></form-label>
                    <div class="d-flex flex-fill">
                        <ruum-task-details-priority
                            [priority]="task.priority"
                            [isReadOnly]="isReadOnly || (isOffline$ | async)"
                            (priorityChanged)="onChangePriority($event)"
                        >
                        </ruum-task-details-priority>
                    </div>
                </div>
            </div>

            <ruum-task-details-subtask-list
                [taskParentId]="task.parent"
                [taskId]="task.id"
                [tasks]="subtasks"
                [isReadOnly]="isReadOnly || (isOffline$ | async) || isLocked"
                (addSubtask)="addSubtask.emit($event)"
                (changeSubtaskStatus)="onChangeSubtaskStatus($event, task.id)"
                (changeSubtaskOrder)="changeSubtaskOrder.emit($event)"
                (showSubtaskDetails)="showSubtaskDetails.emit($event)"
            >
            </ruum-task-details-subtask-list>

            <ng-content select="ruum-task-custom-field-value-list"></ng-content>

            <div class="d-flex flex-column pt-5">
                <comment-list-wrapper
                    [isReadOnly]="isReadOnly || (isOffline$ | async)"
                    [comments]="task.comments || []"
                    [projectId]="projectId"
                    [commentableId]="task.id"
                    [commentableType]="'task'"
                    (edit)="onEditComment($event)"
                    (delete)="onDeleteComment($event)"
                    (add)="onAddComment($event)"
                >
                </comment-list-wrapper>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column flex-fill minw-0';
    @Input() task: RuumTask;
    @Input() projectId: string;
    @Input() workspaceId: string;
    @Input() isReadOnly = false;
    @Input() isLocked = false;
    @Input() designTime = false;
    @Input() isTemplate = false;

    @Input() subtasks: RuumTask[];
    @Input() isAdmin = false;
    @Input() canInviteParticipants = false;

    @Output() changeStatus = new EventEmitter();
    @Output() changePriority = new EventEmitter();
    @Output() changeDescription = new EventEmitter<string>();
    @Output() changeAssignees = new EventEmitter<TaskAssignmentsIds>();
    @Output() changeStartDate = new EventEmitter<Day>();
    @Output() changeDueDate = new EventEmitter<Day>();
    @Output() deleteTask = new EventEmitter<RuumDropdownItem>();
    @Output() moveToSection = new EventEmitter<string>();
    @Output() editComment = new EventEmitter<{ id: string; text: string }>();
    @Output() deleteComment = new EventEmitter<string>();
    @Output() addComment = new EventEmitter<AddCommentData>();
    @Output() addSubtask = new EventEmitter<string>();
    @Output() changeSubtaskOrder = new EventEmitter<string[]>();
    @Output() changeSubtaskStatus = new EventEmitter();
    @Output() showSubtaskDetails = new EventEmitter();
    @Output() showOnCanvas = new EventEmitter();
    @Output() assignCustomField = new EventEmitter<RuumDropdownItem>();
    @Output() setIsMilestone = new EventEmitter<{ isMilestone: boolean }>();

    isOffline$: Observable<boolean>;
    isDesktop$: Observable<boolean>;
    taskDescriptionErrorMessage = undefined;
    taskDescriptionMaxLength: number;

    constructor(
        private tasksService: RuumTasksService,
        private modalService: NgbModal,
        private ruumAlertService: RuumAlertService,
        private navbarService: NavbarService,
        private uiStateService: RuumUIStateService,
        private usersService: InvolvedUsersService,
        private secondaryNavbarService: SecondaryNavbarService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private route: ActivatedRoute,
        private inputValidator: InputValidator,
    ) {
        this.taskDescriptionMaxLength = this.inputValidator.mediumLimit;
    }

    ngOnInit() {
        this.isOffline$ = this.uiStateService.offline();
        this.isDesktop$ = this.breakpointObserver
            .observe([BOOTSTRAP_BREAKPOINTS.Large, BOOTSTRAP_BREAKPOINTS.XLarge])
            .pipe(map((result) => result.matches));
        this.navbarService.setBackButtonTitile(`${this.task.isMilestone ? 'Milestone' : 'Task'} Details`);
    }

    ngOnChanges(chnages) {
        const taskDetail = { projectId: this.projectId, taskId: this.task.id };
        this.navbarService.setTaskDetailId(taskDetail);
        this.navbarService.setBackButtonTitile(`${this.task.isMilestone ? 'Milestone' : 'Task'} Details`);
    }

    ngOnDestroy(): void {
        this.navbarService.setTaskDetailId(null);
    }

    onEditComment($event) {
        this.editComment.emit($event);
    }

    onDeleteComment($event) {
        this.deleteComment.emit($event);
    }

    onChangeDescription(event) {
        if (this.taskDescriptionErrorMessage === undefined) {
            const newDescription = event.target.value.trim();
            this.changeDescription.emit(newDescription);
        }
    }

    validateInput(taskDescription: string) {
        this.taskDescriptionErrorMessage = this.inputValidator.getTextInputErrorMessage(
            taskDescription,
            this.taskDescriptionMaxLength,
        );
    }

    getCreatorName(): Observable<string> {
        if (this.task.createdBy) {
            return this.usersService.data(this.task.createdBy).pipe(map((user) => user.fullName));
        } else {
            return of('');
        }
    }

    onAssigneesChange({ assignees, assignedFunctionalRoles: roles }: TaskAssignmentsIds) {
        if (not(isEqual(assignees, this.task.assignees)) || not(isEqual(roles, this.task.assignedFunctionalRoles))) {
            this.tasksService.setAssignees({ taskId: this.task.id, assignees, assignedFunctionalRoles: roles });
        }
    }

    onChangePriority(priority: TaskPriority) {
        this.changePriority.emit(priority);
    }

    onMoveToSection() {
        const instance = this.modalService.open(MoveToSectionDialogComponent, { centered: true });
        instance.componentInstance.taskId = this.task.id;
        instance.componentInstance.projectId = this.projectId;
        instance.result.then(
            (sectionId) => {
                this.moveToSection.emit(sectionId);
            },
            () => {},
        );
    }

    showParentTaskDetails($event) {
        this.showSubtaskDetails.emit(this.task.parent);
    }

    onDeleteTask(event) {
        this.deleteTask.emit(event);
    }

    onAddComment(event: AddCommentData) {
        this.addComment.emit(event);
    }

    onSetIsMilestone(event) {
        const hasCustomFields = Object.keys(this.task.customFieldsValues).length > 0;
        if (hasCustomFields) {
            const confirmDialogData: RuumAlertOptions = {
                title: `All values, added to this task's custom fields will be deleted`,
                actionText: 'Ok',
                noCancel: false,
            };
            this.ruumAlertService
                .warning(confirmDialogData)
                .then(() => {
                    this.setIsMilestone.emit(event);
                })
                .catch(() => {});
        } else {
            this.setIsMilestone.emit(event);
        }
    }

    onChangeSubtaskStatus(event, parentId) {
        const { taskId, taskStatus } = event;
        this.changeSubtaskStatus.emit({ taskId, parentId, status: taskStatus });
    }

    onShowOnCanvas(closeSidepanel: boolean) {
        if (closeSidepanel) {
            this.secondaryNavbarService.closeSidepanel().then(() => {
                this.showOnCanvas.emit();
            });
        } else {
            this.showOnCanvas.emit();
        }
    }

    onAssignCustomField(event) {
        this.assignCustomField.emit(event);
    }

    goToTaskActivity(event: Event, taskId: string) {
        event.preventDefault();
        this.router.navigate([{ outlets: { sidepanel: ['activities', taskId] } }], { relativeTo: this.route.parent });
    }
}
