<ng-container *ngIf="customField" [ngSwitch]="customField?.type">
    <!-- for Selection List -->
    <ruum-custom-field-selection-list
        *ngSwitchCase="'selection_list'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-selection-list>

    <!-- for email -->
    <ruum-custom-field-email
        *ngSwitchCase="'email'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-email>

    <!-- for date -->
    <ruum-custom-field-date
        *ngSwitchCase="'date'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-date>

    <!-- for number -->
    <ruum-custom-field-number
        *ngSwitchCase="'number'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-number>

    <!-- for phone -->
    <ruum-custom-field-phone
        *ngSwitchCase="'phone'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-phone>

    <!-- for link -->
    <ruum-custom-field-link
        *ngSwitchCase="'link'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-link>

    <!-- for text long -->
    <ruum-custom-field-text-long
        *ngSwitchCase="'long_string'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-text-long>

    <ruum-custom-field-record
        *ngSwitchCase="'record'"
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-record>

    <!-- default text -->
    <ruum-custom-field-text
        *ngSwitchDefault
        [customField]="customField"
        [isReadOnly]="isReadOnly"
        (valueChange)="onValueChange($event)"
    >
    </ruum-custom-field-text>
</ng-container>
