import { AbstractControl } from '@angular/forms';
const emailAddressParser = require('email-addresses');

/**
 * Convert different formats of emails to pure list
 * Example:
 *      Input: LastName MidName, FirstName <fn.mn.ln@ruumapp.com>; LastName, FirstName <fn.ln@ruumapp.com>
 *      Output: ["fn.mn.ln@ruumapp.com", "fn.ln@ruumapp.com"]
 */
export const getEmailsList = (email) => {
    const parsedAddressList = emailAddressParser.parseAddressList(
        email
            .replace(/;/g, ',')
            .split(',')
            .filter((a) => a.indexOf('@') !== -1)
            .join(','),
    );
    const addresses: string[] = (parsedAddressList || []).filter((a) => a.address.length > 0).map((a) => a.address);
    return addresses;
};

export class RuumTeamInviteValidator {
    static email(control: AbstractControl) {
        const emailControlValue = control.value;
        if (!emailControlValue) {
            return null;
        }
        const emails = getEmailsList(emailControlValue);
        return emails.length === 0 ? { invalid: true } : null;
    }

    static SAPDL(control: AbstractControl) {
        const emailControlValue = control.value;
        if (!emailControlValue) {
            return null;
        }
        const emails = getEmailsList(emailControlValue);
        const validatedEmails = emails.map((e) => {
            return /^dl\_\w*@exchange.sap.corp$/i.test(e) || /^dl\_\w*@global.corp.sap$/i.test(e);
        });
        const invalid = validatedEmails.some((invalidEmail) => invalidEmail);
        return invalid ? { SAPDL: true } : null;
    }
}
