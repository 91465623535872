import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { CanvasBackendConnector } from '../../../ruum/canvas/canvas.backendConnector';
import { AppState } from '../../app.store';
import { CommonService } from '../../common.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { DocumentScrollService } from '../../documentScroll.service';
import { ServiceHelper } from '../../serviceHelper';
import { TrackingConnector } from '../../trackingConnector.service';
import { deepEqual } from '../../utils.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { SHOWIND_SUBTASKS_ACTIONS } from './task-list-wrapper.reducer';
import { ReplaceTasksAction, TaskListLoadedAction } from './task-list.actions';
import { TaskListConnector } from './task-list.connector';
import { TaksListFilters, TaskListItem, TaskListOrderBy, TaskListWrapper } from './task-list.model';

@Injectable({ providedIn: 'root' })
export class TaskListService extends TaskListConnector {
    constructor(
        protected readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected canvasConnector: CanvasBackendConnector,
        protected trackingConnector: TrackingConnector,
        protected authService: AuthService,
        private serviceHelper: ServiceHelper,
        private documentScrollService: DocumentScrollService,
        private store: Store<AppState>,
        private commonService: CommonService,
    ) {
        super(
            readModelConnector,
            alertService,
            projectServiceConnector,
            canvasConnector,
            trackingConnector,
            authService,
        );
        this.setUpObservables();
    }

    private setUpObservables() {
        this.filters$()
            .pipe(
                tap(() => {
                    this.store.dispatch({ type: SHOWIND_SUBTASKS_ACTIONS.CLOSE_ALL_SUBTAKS, payload: {} });
                }),
            )
            .subscribe();

        this.orderBy$
            .pipe(
                distinctUntilChanged((a, b) => deepEqual(a, b)),
                tap(() => {
                    this.store.dispatch({ type: SHOWIND_SUBTASKS_ACTIONS.CLOSE_ALL_SUBTAKS, payload: {} });
                }),
            )
            .subscribe();

        this.getListObservable().subscribe((page) => {
            this.serviceHelper.dispatchWithoutPersisting<TaskListLoadedAction>('TASK_LIST_LOADED', { list: page });

            if (page.currentPage === 1) {
                this.maybeGoToNextPage();
            }
        });

        this.loadIndicatorObs().subscribe((loading) => {
            this.commonService.setBusy(loading);
        });
    }
    filters$(): Observable<TaksListFilters> {
        return this.getFilters$();
    }

    isLoadingFirstPage(): Observable<boolean> {
        return this.loadIndicatorObs();
    }

    filterByWorkspaceId(workspaceId: string) {
        this.workspaceId$.next(workspaceId);
    }

    filterByGroupId(groupId: string) {
        this.groupId$.next(groupId);
    }

    filterByProjectId(projectId: string) {
        this.projectId$.next(projectId);
    }

    orderListBy(by: TaskListOrderBy, direction: SortDirection) {
        localStorage.setItem('taskListSortBy', by);
        localStorage.setItem('taskListSortDirection', direction);
        return this.orderBy$.next({ by, direction });
    }

    orderBy(): Observable<OrderedListParams<TaskListOrderBy>> {
        return this.orderBy$;
    }

    getOrderBy(): OrderedListParams<TaskListOrderBy> {
        let orderBy: OrderedListParams<TaskListOrderBy>;
        this.orderBy()
            .pipe(take(1))
            .subscribe((val) => (orderBy = val));
        return orderBy;
    }

    taskList(): Observable<TaskListItem[]> {
        return this.taskListRoot().pipe(
            switchMap<PaginatedList<string>, Observable<TaskListItem[]>>((plist) => this.taskMapper(plist.rows)),
        );
    }

    taskListChildOf(projectId: string, parentId: string): Observable<TaskListItem[]> {
        return this.taskListChildOfHelper(projectId, parentId).pipe(
            switchMap<PaginatedList<string>, Observable<TaskListItem[]>>((plist) => {
                if (!plist) {
                    return of([]);
                }
                return this.taskMapper(plist.rows);
            }),
        );
    }

    taskListRoot(): Observable<PaginatedList<string>> {
        return this.wrapper().pipe(
            map((wrapper) => wrapper.root),
            distinctUntilChanged(),
        );
    }

    loadMore() {
        this.maybeGoToNextPage();
    }

    showLoading(): Observable<boolean> {
        return combineLatest([this.hasMore$, this.isLoadingAnotherPage$]).pipe(
            map(([hasMore, isLoadingAnotherPage]) => hasMore || isLoadingAnotherPage),
        );
    }

    getTaskById(id: string): Observable<TaskListItem> {
        return this.store.select('task').pipe(
            map((taskMap) => {
                return taskMap[id];
            }),
        );
    }

    private taskListChildOfHelper(projectId: string, parentId: string): Observable<PaginatedList<string>> {
        return this.wrapper().pipe(
            map((wrapper) => {
                return wrapper.children[`${projectId}-${parentId}`];
            }),
            distinctUntilChanged(),
        );
    }

    private taskMapper(list: string[]): Observable<TaskListItem[]> {
        return combineLatest([of(list), this.store.select('task')]).pipe(
            map(([idList, taskMap]) => {
                return idList.map((id) => taskMap[id]).filter(Boolean);
            }),
        );
    }

    loadSubstasks(projectId: string, parentId: string) {
        return this.getSubstasks(projectId, parentId)
            .toPromise()
            .then((page) => {
                this.serviceHelper.dispatchWithoutPersisting<TaskListLoadedAction>('TASK_LIST_LOADED', {
                    projectId,
                    parentId,
                    list: page,
                });
            });
    }

    showSubtasks(projectId: string, taskId: string, parentId: string, show: boolean) {
        this.serviceHelper.dispatchWithoutPersisting(SHOWIND_SUBTASKS_ACTIONS.SHOW_SUBTASKS, {
            taskId,
            projectId,
            parentId,
            show,
        });
    }

    isShowingSubtasks(projectId: string, taskId: string): Observable<boolean> {
        return this.wrapper().pipe(
            map((wrapper) => !!wrapper.showingSubtasks[`${projectId}-${taskId}`]),
            distinctUntilChanged(),
        );
    }

    private wrapper(): Observable<TaskListWrapper> {
        return this.store.select('taskListWrapper').pipe(distinctUntilChanged());
    }

    reloadTask(projectId: string, taskId: string) {
        this.getTask(projectId, taskId)
            .toPromise()
            .then((task) => {
                if (task) {
                    this.serviceHelper.dispatchWithoutPersisting<ReplaceTasksAction>('REPLACE_TASKS', {
                        tasks: [task],
                    });
                }
            });
    }

    getStoreData$(): Observable<PaginatedList<string>> {
        return this.wrapper().pipe(map((wrapper) => wrapper.root));
    }
}
