import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import * as validationHelpers from '../../auth/phases/phasesHelpers';

@Component({
    selector: 'ruum-password',
    template: `
        <div class="form-group mb-0" [ngClass]="{ 'mb-4': slim }">
            <label class="form-default-label" for="user-passowrd" data-test="ruum-auth-password-label">Password</label>
            <div class="input-group">
                <div *ngIf="!slim" class="input-group-prepend" ruumInputHighlight>
                    <span class="input-group-text">
                        <i class="icon icon-lock-locked"></i>
                    </span>
                </div>
                <input
                    class="form-control"
                    id="user-passowrd"
                    name="user-passowrd"
                    placeholder="{{ showValidation ? 'Create password' : 'Type your password' }}"
                    type="{{ showPassword ? 'text' : 'password' }}"
                    ruumInputHighlight
                    autocomplete="password"
                    name="password"
                    data-test="ruum-auth-password-input"
                    [(ngModel)]="password"
                    (keyup)="changed($event)"
                    (paste)="changed($event)"
                    (keyup)="onValidatePassword()"
                    #focus
                />
                <div class="input-group-append" ruumInputHighlight>
                    <span
                        class="input-group-text cursor-pointer"
                        *ngIf="!showPassword"
                        (click)="showPassword = true"
                        (keydown.space)="showPassword = true"
                        (keydown.enter)="showPassword = true"
                    >
                        <i class="icon icon-visibility-on"></i>
                        <span *ngIf="!slim" class="text-tiny pl-1">Show</span>
                    </span>
                    <span
                        class="input-group-text cursor-pointer"
                        *ngIf="showPassword"
                        (click)="showPassword = false"
                        (keydown.space)="showPassword = false"
                        (keydown.enter)="showPassword = false"
                    >
                        <i class="icon icon-visibility-off"></i>
                        <span *ngIf="!slim" class="text-tiny pl-1">Hide</span>
                    </span>
                </div>
            </div>
            <div
                class="mt-1"
                *ngIf="showValidation && focus.value !== '' && !validPassword()"
                data-test="ruum-auth-password-errors"
                @shrinkOut
            >
                <div class="d-flex align-items-center">
                    <button
                        class="btn btn-xs btn-icon border-0 cursor-default"
                        type="button"
                        [class.btn-outline-success]="validateMinChars()"
                        [class.btn-outline-danger]="!validateMinChars()"
                        tabindex="-1"
                    >
                        <i
                            class="icon"
                            [class.icon-task-completed]="validateMinChars()"
                            [class.icon-cancel]="!validateMinChars()"
                        >
                        </i>
                    </button>
                    <div
                        class="tiny"
                        [class.text-success]="validateMinChars()"
                        [class.text-danger]="!validateMinChars()"
                    >
                        At least 8 characters
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <button
                        class="btn btn-xs btn-icon border-0 cursor-default"
                        type="button"
                        [class.btn-outline-success]="validateMinTwoNumbers()"
                        [class.btn-outline-danger]="!validateMinTwoNumbers()"
                        tabindex="-1"
                    >
                        <i
                            class="icon"
                            [class.icon-task-completed]="validateMinTwoNumbers()"
                            [class.icon-cancel]="!validateMinTwoNumbers()"
                        >
                        </i>
                    </button>
                    <div
                        class="tiny"
                        [class.text-success]="validateMinTwoNumbers()"
                        [class.text-danger]="!validateMinTwoNumbers()"
                    >
                        At least 2 numbers
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            :host.is-invalid input,
            :host.is-invalid .input-group-append .input-group-text {
                border-color: #bb0000;
            }
        `,
    ],
    animations: [
        trigger('shrinkOut', [
            state('in', style({})),
            transition('* => void', [
                style({ height: '*', opacity: 1 }),
                animate(200, style({ height: 0, opacity: 0 })),
            ]),
            transition('void => *', [
                style({ height: 0, opacity: 0 }),
                animate(200, style({ height: '*', opacity: 1 })),
            ]),
        ]),
    ],
})
export class PasswordComponent implements AfterViewInit {
    @HostBinding('class') classList = 'd-flex flex-fill flex-column w-100';

    @ViewChild('focus', { static: false }) private elementRef: ElementRef;

    @Input() showValidation: boolean;
    @Input() autofocus: boolean;
    @Input() slim: boolean;

    @Output() passwordChange = new EventEmitter<string>();
    @Output() passwordValidate = new EventEmitter<boolean>();

    showPassword: boolean;
    password: string;

    constructor() {
        this.password = '';
    }

    changed(event) {
        this.passwordChange.emit(event.target.value);
    }
    onValidatePassword() {
        this.passwordValidate.emit(this.validPassword());
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            this.setFocus();
        }
    }

    setFocus() {
        this.elementRef.nativeElement.focus();
    }

    validateMinChars(): boolean {
        return validationHelpers.containsMin8Chars(this.password);
    }
    validateNumber(): boolean {
        return validationHelpers.containsNumber(this.password);
    }
    validateMinTwoNumbers(): boolean {
        return validationHelpers.containsTwoNumbers(this.password);
    }

    validPassword(): boolean {
        return this.validateMinChars() && this.validateNumber();
    }
}
