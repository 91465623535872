import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ruum-modal-dialog',
    template: `
        <div class="modal-body" [class.h-100]="fullHeight">
            <button
                *ngIf="!hideCloseButton"
                class="btn btn-link-secondary btn-icon close"
                type="button"
                aria-label="Close"
                (click)="onCloseButtonClick()"
            >
                <i class="icon icon-cancel"></i>
            </button>
            <div class="my-4 mx-2" [class.h-100]="fullHeight">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent {
    @HostBinding('class') class = 'd-block shadow';

    @Input()
    hideCloseButton = false;
    @Input()
    dismissOnClose = true;
    @Input() fullHeight = false;

    constructor(private activeModal: NgbActiveModal) {}

    onCloseButtonClick() {
        if (this.dismissOnClose) {
            this.activeModal.dismiss();
        } else {
            this.activeModal.close();
        }
    }
}
