import { Injectable } from '@angular/core';
import {
    getProjectGroupRole,
    PGParticipant,
    ProjectGroup,
    ProjectGroupActionTypes,
    ProjectGroupConfiguration,
    ProjectGroupParticipantRole,
    RuumAction,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AuthService } from '../../../../app/auth/auth.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { PGParticipantWithAllRoles, ReadModelBackendConnector, UserListItem } from '../readModelConnector.service';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { ProjectGroupParticipantListService } from './projectGroupParticipantList.service';
import { WorkspaceParticipantListService } from './workspaceParticipantList.service';

@Injectable({ providedIn: 'root' })
export class SelectedProjectGroupHelper {
    private initialized = false;
    private persistedActions$: Observable<RuumAction>;
    private selectedProjectGroup$: Observable<ProjectGroup>;
    private getSelectedGroupId: () => string;
    private reloadListItem: () => void;
    /** The logged user master data. */
    private userProfile$: BehaviorSubject<UserListItem> = new BehaviorSubject<UserListItem>({} as any);
    /** The role the logged user has in the Workspace which the selected Group is part of. */
    private workspaceRole$: Observable<WorkspaceParticipantRole>;
    /** The logged user master data and role in the selected Project. */
    private participant$: BehaviorSubject<PGParticipant> = new BehaviorSubject<PGParticipant>({ roles: [] } as any);

    constructor(
        protected authService: AuthService,
        private selectedWorkspace: SelectedWorkspaceService,
        protected readModelBackendConnector: ReadModelBackendConnector,
        private projectServiceConnector: ProjectServiceBackendConnector,
        private workspaceParticipantListService: WorkspaceParticipantListService,
        private projectGroupParticipantListService: ProjectGroupParticipantListService,
    ) {}

    initialize(
        persistedActions: Observable<RuumAction>,
        getId: () => string,
        selectedProjectGroup$: Observable<ProjectGroup>,
        reloadListItem: () => void,
    ) {
        if (this.initialized) {
            throw Error('SelectedProjectServiceHelper was already initialized');
        }
        this.initialized = true;
        this.selectedProjectGroup$ = selectedProjectGroup$;
        this.persistedActions$ = persistedActions;
        this.getSelectedGroupId = getId;
        this.reloadListItem = reloadListItem;
        this.listenToWorkspaceRole();
        this.listenToLoggedUserParticipant();
        this.listenToParticipantList();
        this.listenToWorkspaceId();
    }

    private listenToLoggedUserParticipant(): void {
        combineLatest([
            this.userProfile$.pipe(filter<UserListItem>(Boolean)),
            this.selectedProjectGroup$.pipe(
                filter<ProjectGroup>(Boolean),
                map((group) => group.configuration),
                filter<ProjectGroupConfiguration>(Boolean),
                distinctUntilChanged(),
            ),
            this.selectedProjectGroup$.pipe(
                filter<ProjectGroup>(Boolean),
                map((project) => project.participants),
                distinctUntilChanged(),
            ),
            this.workspaceRole$,
        ])
            .pipe(
                map(([profile, configuration, participants, workspaceRole]) => {
                    const userId = this.authService.getLoggedUserId();
                    const role = getProjectGroupRole(
                        configuration,
                        participants,
                        userId,
                        workspaceRole,
                    ) as ProjectGroupParticipantRole;
                    return {
                        id: userId,
                        fullName: profile.fullName,
                        email: profile.email,
                        color: profile.color,
                        initials: profile.initials,
                        roles: [role],
                    } as PGParticipant;
                }),
            )
            .subscribe((participant) => this.participant$.next(participant));
    }

    private listenToWorkspaceRole(): void {
        this.workspaceRole$ = this.selectedWorkspace
            .loggedUserWorkspaceParticipant()
            .pipe(map((participant) => (participant && participant.role) as WorkspaceParticipantRole));
    }

    getWorkspaceRole(): WorkspaceParticipantRole {
        let role: WorkspaceParticipantRole;
        this.workspaceRole$.pipe(take(1)).subscribe((r) => (role = r));
        return role;
    }

    private listenToParticipantList() {
        const participantListActions$ = this.persistedActions$.pipe(
            filter(
                (action) =>
                    action.type === ProjectGroupActionTypes.ADD_PARTICIPANTS_TO_PROJECT_GROUP ||
                    action.type === ProjectGroupActionTypes.REMOVE_PARTICIPANT_FROM_PROJECT_GROUP ||
                    action.type === ProjectGroupActionTypes.SET_PROJECT_GROUP_PARTICIPANT_ROLE,
            ),
        );

        merge(
            this.selectedProjectGroup$.pipe(
                map((group) => group && group.id),
                distinctUntilChanged(),
            ),
            /** Any action that changed the list. */
            participantListActions$,
        )
            .pipe(map(() => this.getSelectedGroupId()))
            .subscribe((projectGroupId) => {
                this.projectGroupParticipantListService.projectGroupId$.next(projectGroupId);
                this.projectGroupParticipantListService.reload();
                this.reloadListItem();
            });
    }

    private listenToWorkspaceId() {
        this.selectedProjectGroup$
            .pipe(
                filter(() => !!this.getSelectedGroupId()),
                map((group) => group.workspaceId),
                distinctUntilChanged(),
            )
            .subscribe((workspaceId) => {
                this.reloadListItem();
            });
    }

    selectProjectGroup(groupId) {
        this.loadLoggerUserMasterData();
        this.loadWSMembers(groupId);
    }

    private async loadLoggerUserMasterData() {
        const users = await this.readModelBackendConnector
            .getInvolvedUsers({ page: 1 }, { userIds: [this.authService.getLoggedUser().id] })
            .toPromise();
        this.userProfile$.next(users[0]);
    }

    async loadGroupParticipants() {
        this.projectGroupParticipantListService.loadList();
    }

    async loadWSMembers(projectGroupId: string) {
        this.workspaceParticipantListService.projectGroupId$.next(projectGroupId);
        this.workspaceParticipantListService.reload();
    }

    unselectProjectGroup() {
        this.participant$.next({ roles: [] } as any);
    }

    loggedUserParticipant(): Observable<PGParticipant> {
        return this.participant$.asObservable();
    }

    getLoggedUserParticipant(): PGParticipant {
        return this.participant$.value;
    }

    participants(): Observable<PGParticipantWithAllRoles[]> {
        return this.projectGroupParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !p.technicalUser)),
        );
    }

    technicalUsers(): Observable<PGParticipantWithAllRoles[]> {
        return this.projectGroupParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => p.technicalUser)),
        );
    }

    workspaceMembers(): Observable<PGParticipantWithAllRoles[]> {
        return this.workspaceParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !p.technicalUser)),
        );
    }
}
