import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CustomizableObjects,
    DEFAULT_OIDC_PROVIDER_ID,
    Enterprise,
    EnterpriseAction,
    EnterpriseAuthenticationType,
    FileUploadOptions,
    ProcessEnum,
    ProjectAction,
    ProjectGroup,
    ProjectGroupAction,
    ProjectGroupParticipantRole,
    ProjectParticipantRole,
    Ruum,
    RuumAction,
    RuumActionTypes,
    Template,
    TemplateAction,
    TemplateType,
    Workspace,
    WorkspaceAction,
    WorkspaceParticipant,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import { Observable, Subject, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompanySize, LoggedUser } from './auth/authentication.model';
import { AuthBackendConnector } from './authServiceConnector.service';
import { Transaction } from './command.model';
import { ProcessStepTransitionState } from './project/process/objectStepTransitions';
import { TransitionCreateData, TransitionObject } from './project/process/process.model';
import { TemplateInviteParticipant } from './templates/selectedTemplate.service';

export const getDefaultEnterprise = (enterpriseId: string = undefined): Enterprise => ({
    id: enterpriseId,
    name: '',
    createdAt: undefined,
    participants: [],
    fileUploadOptions: null,
    oauthClients: [],
    technicalUsers: [],
    patterns: [],
    signupRuums: [],
    externalSystems: {},
    systemConnectors: {},
    tableDefinitions: {},
    authenticationType: EnterpriseAuthenticationType.basic,
    oidcProviderId: DEFAULT_OIDC_PROVIDER_ID,
});

@Injectable({ providedIn: 'root' })
export class ProjectServiceBackendConnector {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;
    shouldGetNewActions$ = new Subject<string>();

    constructor(private http: HttpClient, private backendConnector: AuthBackendConnector) {}

    createProject(
        name: string,
        templateId?: string,
        initConfig?: any,
        workspaceId?: string,
        groupId?: string,
    ): Promise<Ruum> {
        return this.http
            .post<Ruum>(
                `${this.PROJECT_SERVICE_URL}/v1/ruums`,
                {
                    name,
                    templateId,
                    createdFrom: this.getCreatedFrom(),
                    initDataConfig: initConfig,
                    workspaceId,
                    groupId,
                },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    getCreatedFrom(): string {
        if (/\/ruums\/ruum_/.test(window.location.pathname)) {
            return 'ruum_header';
        }
        if (/template_.*\/create/.test(window.location.pathname)) {
            return 'shared_link';
        }

        return 'lobby';
    }

    // TODO: rename to duplicateProject
    duplicate(projectId: string, projectName: string, workspaceId: string, groupId: string): Promise<any> {
        return this.createProject(projectName, projectId, undefined, workspaceId, groupId);
    }

    addActionToRuum<T extends ProjectAction>(
        ruumId: string,
        type: T['type'],
        payload: T['payload'] = {},
        clientId?: string,
    ): Promise<any> {
        return this.addActionToRuum$(ruumId, type, payload, clientId).toPromise();
    }

    private addActionToRuum$<T extends ProjectAction>(
        ruumId: string,
        type: T['type'],
        payload: T['payload'] = {},
        clientId?: string,
    ): Observable<any> {
        const options = clientId
            ? { 'RUUM-CLIENT-ID': clientId, 'RUUM-PATH': window.location.pathname }
            : { 'RUUM-PATH': window.location.pathname };

        const headers = new HttpHeaders(options);
        return this.http
            .put(`${this.PROJECT_SERVICE_URL}/v1/ruums/${ruumId}/actions`, [{ type, payload }], {
                withCredentials: true,
                headers,
            })
            .pipe(timeout(12 * 1000));
    }

    sendTransactionToServer(tr: Transaction): Observable<any> {
        const headers = new HttpHeaders({ 'RUUM-CLIENT-ID': tr.clientId, 'RUUM-PATH': window.location.pathname });
        const data = [{ type: tr.actionType, payload: tr.actionPayload, idempotencyKey: tr.idempotencyKey }];

        if (tr.entityType === 'project') {
            return this.http.put(`${this.PROJECT_SERVICE_URL}/v1/ruums/${tr.entityId}/actions`, data, {
                withCredentials: true,
                headers,
            });
        }
        if (tr.entityType === 'project_group') {
            return this.http.put(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${tr.entityId}/actions`, data, {
                withCredentials: true,
                headers,
            });
        } else if (tr.entityType === 'workspace') {
            return this.http.put(`${this.PROJECT_SERVICE_URL}/v1/workspaces/${tr.entityId}/actions`, data, {
                withCredentials: true,
                headers,
            });
        } else if (tr.entityType === 'template') {
            return this.http.put(`${this.PROJECT_SERVICE_URL}/v1/templates/${tr.entityId}/actions`, data, {
                withCredentials: true,
                headers,
            });
        } else if (tr.entityType === 'enterprise') {
            return this.http.put(`${this.PROJECT_SERVICE_URL}/v1/enterprises/${tr.entityId}/actions`, data, {
                withCredentials: true,
                headers,
            });
        } else {
            throw new Error(`unexpected entity type ${tr.entityType}`);
        }
    }

    deleteLoggedUserFromRuum(ruumId: string, userId: string) {
        const headers = new HttpHeaders({ 'RUUM-PATH': window.location.pathname });
        return this.http
            .put(
                `${this.PROJECT_SERVICE_URL}/v1/ruums/${ruumId}/actions`,
                [
                    {
                        type: RuumActionTypes.DELETE_PARTICIPANT,
                        payload: {
                            id: userId,
                        },
                    },
                ],
                { withCredentials: true, headers },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    deleteProject(projectId): Promise<void> {
        return this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    deleteProjectGroup(projectGroupId): Promise<void> {
        return this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${projectGroupId}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    createTemplate(
        name: string,
        projectId: string,
        workspaceId: string,
        templateType: TemplateType = 'ruum',
        isProcessEnabled?: boolean,
    ): Promise<Template> {
        const createdFrom = /\/ruums\/ruum_/.test(window.location.pathname) ? 'ruum_header' : 'lobby';
        let ruumType;
        if (!projectId && templateType === 'ruum') {
            ruumType = isProcessEnabled ? 'process' : 'project';
        }
        if (!projectId && templateType === 'section') {
            ruumType = undefined;
        }

        return this.http
            .post<Template>(
                `${this.PROJECT_SERVICE_URL}/v1/templates`,
                {
                    name: name || 'Section Template',
                    projectId,
                    createdFrom,
                    type: templateType,
                    workspaceId,
                    ruumType,
                },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    deleteTemplate(templateId: string): Promise<void> {
        return this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/templates/${templateId}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    duplicateTemplate(templateId: string): Promise<Template> {
        return this.http
            .post<Template>(`${this.PROJECT_SERVICE_URL}/v1/templates/${templateId}/duplicate`, undefined, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    async editTemplate(templateId: string): Promise<Template> {
        const result = await this.http
            .put<Template>(`${this.PROJECT_SERVICE_URL}/v1/templates/${templateId}/edit`, {}, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(templateId);
        return result;
    }

    createSectionTemplate(workspaceId?: string, fromTemplateId?: string): Promise<Ruum> {
        const createdFrom = /\/ruums\/ruum_/.test(window.location.pathname) ? 'ruum_header' : 'lobby';
        return this.http
            .post<Ruum>(
                `${this.PROJECT_SERVICE_URL}/v2/sectiontemplates`,
                {
                    createdFrom,
                    workspaceId,
                    templateId: fromTemplateId,
                },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    async publishTemplate(templateId: string, projectIdToPublish: string): Promise<Template> {
        const result = await this.http
            .put<Template>(
                `${this.PROJECT_SERVICE_URL}/v1/templates/${templateId}/publish/${projectIdToPublish}`,
                {},
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(templateId);
        this.shouldGetNewActions$.next(projectIdToPublish);
        return result;
    }

    async inviteToTemplate(templateId: string, invitees: TemplateInviteParticipant[], personalMessage: string) {
        const result = await this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/templates/${templateId}/invite`,
                { invitees, personalMessage },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(templateId);
        return result;
    }

    moveTemplateToWorkspace(templateId: string, workspaceId: string): Promise<any> {
        return this.http
            .patch(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/templates`,
                { templateId },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    addActionToTemplate<T extends TemplateAction>(
        templateId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Promise<any> {
        return this.addActionToTemplate$(templateId, type, payload, clientId).toPromise();
    }

    private addActionToTemplate$<T extends TemplateAction>(
        templateId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Observable<any> {
        const options = clientId
            ? { 'RUUM-CLIENT-ID': clientId, 'RUUM-PATH': window.location.pathname }
            : { 'RUUM-PATH': window.location.pathname };

        const headers = new HttpHeaders(options);
        return this.http
            .put(`${this.PROJECT_SERVICE_URL}/v1/templates/${templateId}/actions`, [{ type, payload }], {
                withCredentials: true,
                headers,
            })
            .pipe(timeout(12 * 1000));
    }

    getRuum(projectId: string): Promise<Ruum> {
        return this.http
            .get<Ruum>(`${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    getProjectGroup(projectGroupId: string): Promise<ProjectGroup> {
        return this.http
            .get<ProjectGroup>(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${projectGroupId}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    createProjectGroup(name: string, description: string, workspaceId?: string) {
        return this.http
            .post<Ruum>(
                `${this.PROJECT_SERVICE_URL}/v1/projectgroups`,
                { name, description, workspaceId },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    addActionToProjectGroup<T extends ProjectGroupAction>(
        groupId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Promise<any> {
        return this.addActionToProjectGroup$(groupId, type, payload, clientId).toPromise();
    }

    private addActionToProjectGroup$<T extends ProjectGroupAction>(
        groupId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Observable<any> {
        const options = clientId
            ? { 'RUUM-CLIENT-ID': clientId, 'RUUM-PATH': window.location.pathname }
            : { 'RUUM-PATH': window.location.pathname };

        const headers = new HttpHeaders(options);
        return this.http
            .put(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/actions`, [{ type, payload }], {
                withCredentials: true,
                headers,
            })
            .pipe(timeout(12 * 1000));
    }

    addActionToWorkspace<T extends WorkspaceAction>(
        workspaceId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Promise<any> {
        return this.addActionToWorkspace$(workspaceId, type, payload, clientId).toPromise();
    }

    addActionToWorkspace$<T extends WorkspaceAction>(
        workspaceId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Observable<any> {
        const options = clientId
            ? { 'RUUM-CLIENT-ID': clientId, 'RUUM-PATH': window.location.pathname }
            : { 'RUUM-PATH': window.location.pathname };

        const headers = new HttpHeaders(options);
        return this.http
            .put(`${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/actions`, [{ type, payload }], {
                withCredentials: true,
                headers,
            })
            .pipe(timeout(12 * 1000));
    }

    addProjectToProjectGroup(groupId: string, projectId: string): Promise<any> {
        return this.http
            .patch(
                `${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/projects`,
                { projectId },
                { withCredentials: true },
            )
            .toPromise();
    }

    removeProjectFromProjectGroup(groupId: string, projectId: string): Promise<any> {
        return this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/projects/${projectId}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    async addParticipantsToProjectGroup(
        groupId: string,
        participants: PGInviteParticipant[],
        personalMessage: string,
    ): Promise<any> {
        const result = await this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/invite`,
                { participants, personalMessage },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(groupId);
        return result;
    }

    async removeParticipantFromProjectGroup(groupId: string, userId: string): Promise<any> {
        const result = await this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/participants/${userId}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(groupId);
        return result;
    }

    async setGroupParticipantRole(groupId: string, userId: string, role: ProjectGroupParticipantRole): Promise<any> {
        const result = await this.http
            .put(
                `${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/participants/${userId}/role`,
                { role },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(groupId);
        return result;
    }

    archiveProjectGroup(groupId: string): Promise<any> {
        return this.http
            .post(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/archive`, {}, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    unarchiveProjectGroup(groupId: string): Promise<any> {
        return this.http
            .post(`${this.PROJECT_SERVICE_URL}/v1/projectgroups/${groupId}/unarchive`, {}, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    async inviteToProject(
        projectId: string,
        invitees: ProjectInviteParticipant[],
        personalMessage: string,
        taskId?: string,
    ) {
        const result = await this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/invite`,
                { invitees, personalMessage, taskId },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async inviteToWorkspace(workspaceId: string, invitees: WorkspaceInviteParticipant[], personalMessage: string) {
        const result = await this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/invite`,
                { invitees, personalMessage },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(workspaceId);
        return result;
    }

    getWorkspaceParticipant(workspaceId: string, userId: string): Promise<WorkspaceParticipant> {
        return this.http
            .get<WorkspaceParticipant>(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/participants/${userId}`,
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    getWorkspace(workspaceId: string): Promise<Workspace> {
        return this.http
            .get<Workspace>(`${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}`, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    async removeParticipantFromWorkspace(workspaceId: string, userId: string): Promise<any> {
        const result = await this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/participants/${userId}`, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(workspaceId);
        return result;
    }

    async setWorkspaceParticipantRole(
        workspaceId: string,
        userId: string,
        role: WorkspaceParticipantRole,
    ): Promise<any> {
        const result = await this.http
            .put(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/participants/${userId}/role`,
                { role },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(workspaceId);
        return result;
    }

    addProjectToWorkspace(workspaceId: string, projectId: string): Promise<any> {
        return this.http
            .patch(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/projects`,
                { projectId },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    addGroupToWorkspace(workspaceId: string, projectGroupId: string): Promise<any> {
        return this.http
            .patch(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/projectgroups`,
                { projectGroupId },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    assignFieldsToProject(projectId: string, fieldIds: string[], toObjectType: CustomizableObjects): Promise<any> {
        return this.http
            .put(
                `${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/customfields`,
                { fieldIds, toObjectType },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
    }

    async insertSectionTemplate(projectId: string, templateId: string, sectionId: string): Promise<any> {
        const result = await this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/insertsectiontemplate`,
                {
                    sectionTemplateId: templateId,
                    toRuumId: projectId,
                    onTopOfSectionId: sectionId,
                },
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async removeAssigneeFromFunctionalRole(workspaceId: string, roleId: string, assigneeId: string) {
        const result = await this.http
            .delete(
                `${this.PROJECT_SERVICE_URL}/v1/workspaces/${workspaceId}/functionalroles/${roleId}/assignees/${assigneeId}`,
                { withCredentials: true },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(workspaceId);
        return result;
    }

    addActionToEnterprise<T extends EnterpriseAction>(
        enterpriseId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Promise<any> {
        return this.addActionToEnterprise$(enterpriseId, type, payload, clientId).toPromise();
    }

    addActionToEnterprise$<T extends EnterpriseAction>(
        enterpriseId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Observable<any> {
        const options = clientId
            ? { 'RUUM-CLIENT-ID': clientId, 'RUUM-PATH': window.location.pathname }
            : { 'RUUM-PATH': window.location.pathname };

        const headers = new HttpHeaders(options);
        return this.http
            .put(`${this.PROJECT_SERVICE_URL}/v1/enterprises/${enterpriseId}/actions`, [{ type, payload }], {
                withCredentials: true,
                headers,
            })
            .pipe(timeout(12 * 1000));
    }

    getEnterpriseFileuploadoptions(projectId: string): Promise<FileUploadOptions> {
        return this.http
            .get<FileUploadOptions>(`${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/fileuploadoptions`, {
                withCredentials: true,
            })
            .toPromise()
            .catch(
                () =>
                    ({
                        ruum: true,
                        external: true,
                        onedrive: true,
                        google_drive: true,
                        box: true,
                        youtube: true,
                    } as FileUploadOptions),
            );
    }

    getEnterprise(enterpriseId: string): Observable<Enterprise> {
        return this.http
            .get<Enterprise>(`${this.PROJECT_SERVICE_URL}/v1/enterprises/${enterpriseId}`, {
                withCredentials: true,
            })
            .pipe(
                catchError((err) => {
                    console.log('The enterprise data cant be loaded with your enterprise role');
                    return of(getDefaultEnterprise(enterpriseId));
                }),
            );
        // Disable to throw the error for now so that also a Integration Admin can continue to the admin webapp
        // .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)));
    }

    async deleteSection(projectId: string, sectionId: string) {
        const result = await this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/document/${projectId}/sections/${sectionId}`, {
                withCredentials: true,
            })
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async duplicateSection(fromRuumId: string, sectionId: string, toRuumId: string, onTopOfSectionId: string) {
        const result = await this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/duplicatesection`,
                {
                    fromRuumId,
                    sectionId,
                    toRuumId,
                    onTopOfSectionId,
                },
                { withCredentials: true },
            )
            .toPromise();
        this.shouldGetNewActions$.next(toRuumId);
        return result;
    }

    /** Process */

    async executeTransition(
        projectId: string,
        { processId, stepId, id: transitionId }: ProcessStepTransitionState,
    ): Promise<any> {
        const result = await this.http
            .put(
                `${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/process/${processId}/steps/${stepId}/transitions/${transitionId}`,
                {},
                {
                    withCredentials: true,
                },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async startProcessDesign(projectId: string, data: TransitionCreateData): Promise<any> {
        const result = await this.http
            .post(`${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/process`, data, { withCredentials: true })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async createOrUpdateStepTransition(
        projectId: string,
        processId: string,
        data: TransitionCreateData,
    ): Promise<ProcessStepTransitionState> {
        const result = await this.http
            .post(`${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/process/${processId}/transitions`, data, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async createApprovalSection(
        projectId: string,
        data: { sectionId: string; approvalId: string; onTopOfSectionId: string },
    ) {
        const result = await this.http
            .post(`${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/approvals`, data, {
                withCredentials: true,
            })
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    async setProcessInitialStep(projectId: string, object: TransitionObject): Promise<any> {
        const result = await this.http
            .put(
                `${this.PROJECT_SERVICE_URL}/v1/ruums/${projectId}/process/${ProcessEnum.ROOT_PROCESS_ID}/setInitialStep`,
                { object },
                {
                    withCredentials: true,
                },
            )
            .pipe(catchError(this.backendConnector.handleError.bind(this.backendConnector)))
            .toPromise();
        this.shouldGetNewActions$.next(projectId);
        return result;
    }

    /** Auth */
    login(email: string, password: string, redirectURL: string) {
        return this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/auth/login`,
                {
                    userMail: email,
                    password,
                    redirect_uri: redirectURL,
                },
                { withCredentials: true },
            )
            .toPromise();
    }

    loginSSO(email: string, redirectURL: string): Promise<SSOLoginInfo> {
        return this.http
            .post<SSOLoginInfo>(
                `${this.PROJECT_SERVICE_URL}/v1/auth/loginsso`,
                {
                    userMail: email,
                    redirect_uri: redirectURL,
                },
                { withCredentials: true },
            )
            .toPromise();
    }

    requestPasswordReset(userMail: string, greCAPTCHAToken: string): Promise<any> {
        return this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/auth/requestReset`,
                {
                    userMail,
                    greCAPTCHAToken,
                },
                { withCredentials: true },
            )
            .toPromise();
    }

    resetPassword(userMail: string, password: string, token: string): Promise<any> {
        return this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/auth/reset`,
                {
                    userMail,
                    password,
                    token,
                },
                { withCredentials: true },
            )
            .toPromise();
    }

    profileExists(email: string): Promise<ProfileExistsInfo | false> {
        return this.http
            .get<ProfileExistsInfo>(`${this.PROJECT_SERVICE_URL}/v1/auth/profileexists/${email}`)
            .toPromise()
            .then((info) => ({ ...info, exists: true }))
            .catch((err) => {
                if (err.status === 404) {
                    return this.getNonExistingProfileSigninInfo(email);
                }
                throw err;
            });
    }

    getNonExistingProfileSigninInfo(email: string): Promise<ProfileExistsInfo> {
        return this.http
            .get<ProfileExistsInfo>(`${this.PROJECT_SERVICE_URL}/v1/auth/userSigninInfo/${email}`)
            .toPromise()
            .then((info) => ({ ...info, exists: false }))
            .catch(() => ({ exists: false }));
    }

    verifySessions(): Promise<string> {
        return this.http
            .get<LoggedUser>(this.PROJECT_SERVICE_URL + '/v1/auth/loggeduser', { withCredentials: true })
            .toPromise()
            .then((data: LoggedUser) => data.id);
    }

    loggedUser(): Observable<LoggedUser> {
        return this.http.get<LoggedUser>(`${this.PROJECT_SERVICE_URL}/v1/auth/loggeduser`, { withCredentials: true });
    }

    logout(): Promise<Response> {
        return this.http
            .get<Response>(`${this.PROJECT_SERVICE_URL}/v1/auth/logout`, { withCredentials: true })
            .toPromise();
    }

    changeEmail(newEmail: string, password: string): Promise<Response> {
        return this.http
            .post<Response>(
                `${this.PROJECT_SERVICE_URL}/v1/auth/changeEmail`,
                { newEmail, password },
                { withCredentials: true },
            )
            .toPromise();
    }

    signup(
        mail: string,
        password: string,
        isEnterpriseUser: boolean,
        isSSOUser: boolean,
        firstName: string,
        lastName: string,
        trackingId: string,
        touVersion: string,
        marketingEmailsAllowed: boolean,
        greCAPTCHAToken: string,
        companySize?: CompanySize,
        companyRole?: string,
        companyFunction?: string,
    ) {
        return this.http
            .post(
                `${this.PROJECT_SERVICE_URL}/v1/auth/signup`,
                {
                    mail,
                    password,
                    isEnterpriseUser,
                    isSSOUser,
                    firstName,
                    lastName,
                    companySize,
                    companyRole,
                    companyFunction,
                    marketingEmailsAllowed,
                    touVersion,
                    trackingId,
                    greCAPTCHAToken,
                },
                { withCredentials: true },
            )
            .toPromise();
    }

    requestMailVerificationDelete(userMail: string) {
        return this.http
            .delete(`${this.PROJECT_SERVICE_URL}/v1/auth/requestEmailVerification/${userMail}`, {
                withCredentials: true,
            })
            .toPromise();
    }

    addActionToProfile<T extends RuumAction>(
        profileId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Promise<any> {
        return this.addActionToProfile$(profileId, type, payload, clientId).toPromise();
    }

    addActionToProfile$<T extends RuumAction>(
        profileId: string,
        type: T['type'],
        payload: T['payload'],
        clientId?: string,
    ): Observable<any> {
        const options = clientId
            ? { 'RUUM-CLIENT-ID': clientId, 'RUUM-PATH': window.location.pathname }
            : { 'RUUM-PATH': window.location.pathname };

        const headers = new HttpHeaders(options);
        return this.http
            .put(`${this.PROJECT_SERVICE_URL}/v1/profiles/${profileId}/actions`, [{ type, payload }], {
                withCredentials: true,
                headers,
            })
            .pipe(timeout(12 * 1000));
    }
}

export interface PGInviteParticipant {
    email: string;
    role?: ProjectGroupParticipantRole;
}

export interface ProjectInviteParticipant {
    email: string;
    role?: ProjectParticipantRole;
}

export interface WorkspaceInviteParticipant {
    email: string;
    role?: WorkspaceParticipantRole;
}

export interface ProfileExistsInfo {
    exists: boolean;
    ssoEnabled?: boolean;
    emailNeedsVerification?: boolean;
    enterpriseUser?: string;
    emailChangePending?: boolean;
    canSignUp?: boolean;
}

export interface SSOLoginInfo {
    /** The URL the webapp should redirect to for SSO. */
    loginUrl: string;
}
