import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalDialogMobileComponent } from './modal-dialog-mobile.component';
import { ModalDialogComponent } from './modal-dialog.component';
import { ShowAgainCheckboxComponent } from './show-again-checkbox.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ModalDialogComponent, ModalDialogMobileComponent, ShowAgainCheckboxComponent],
    exports: [ModalDialogComponent, ModalDialogMobileComponent, ShowAgainCheckboxComponent],
})
export class ModalDialogModule {}
