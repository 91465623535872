import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'technical-user-form',
    template: `
        <form class="mt-5 text-sm text-right" [formGroup]="form">
            <div class="form-group row required">
                <label for="userId" class="col-sm-2 col-form-label col-form-label-sm">User ID</label>

                <!-- When Creating -->
                <div *ngIf="isCreating === true" class="col-sm-8 input-group input-group-sm">
                    <div class="input-group-prepend input-sm">
                        <span class="input-group-text">{{ form.get('enterpriseId')?.value }}_</span>
                    </div>
                    <input
                        class="form-control form-control-sm"
                        name="userId"
                        formControlName="userId"
                        (input)="form.patchValue({ userId: this.patchUserIdAndUpdateEmail($event.target.value) })"
                    />
                </div>
                <!-- When Editing -->
                <div *ngIf="isCreating === false" class="col-sm-8 disabled">
                    <input
                        class="form-control form-control-sm disabled"
                        name="userId"
                        formControlName="userId"
                        readonly
                    />
                </div>
            </div>

            <div class="form-group row required">
                <label for="name" class="col-sm-2 col-form-label col-form-label-sm">Name</label>
                <div class="col-sm-8">
                    <input
                        [ngClass]="{ disabled: !this.isCreating }"
                        class="form-control form-control-sm"
                        placeholder="technical_user_name"
                        name="name"
                        autofocus
                        formControlName="name"
                        [readonly]="!this.isCreating"
                    />
                </div>
            </div>

            <div class="form-group row disabled">
                <label for="email" class="col-sm-2 col-form-label col-form-label-sm">Email</label>
                <div class="col-sm-8">
                    <input
                        readonly
                        class="form-control form-control-sm disabled"
                        name="email"
                        formControlName="email"
                    />
                </div>
            </div>
            <div class="form-group row required">
                <label for="password" class="col-sm-2 col-form-label col-form-label-sm">Password</label>
                <div class="col-sm-8">
                    <input
                        [ngClass]="{ disabled: !this.isCreating }"
                        type="password"
                        class="form-control form-control-sm"
                        name="password"
                        placeholder="*****"
                        formControlName="password"
                        autocomplete="new-password"
                        [readonly]="!this.isCreating"
                    />
                </div>
            </div>
        </form>
    `,
    styles: [``],
})
export class TechnicalUserFormComponent {
    @Input()
    form: FormGroup;

    @Input()
    isCreating = false;

    constructor() {}

    patchUserIdAndUpdateEmail(userId: string): string {
        const newUserId = this.formatForId(userId);
        if (newUserId !== '') {
            this.form.get('email').setValue(`${this.form.get('enterpriseId').value}_${newUserId}@ruumapp.com`);
        } else {
            this.form.get('email').setValue(null);
        }
        return newUserId;
    }

    private formatForId(text: string): string {
        return text.toLowerCase().replace(/[^a-z0-9_-]/g, '');
    }
}
