export const LOBBY_LIST_ACTIONS = {
    LOBBY_LIST_TAGS_LOADED: 'TAGS_LOADED',
    LOBBY_LIST_LOADED: 'LOBBY_LIST_LOADED',
    SET_ITEM_STATUS: 'SET_ITEM_STATUS',
    UPDATE_LOBBY_LIST_STATUS_FILTER: 'UPDATE_LOBBY_LIST_STATUS_FILTER',
    UNSET_STATUS_FILTER: 'UNSET_STATUS_FILTER',
    ARCHIVE_ITEM: 'ARCHIVE_ITEM',
    UNARCHIVE_ITEM: 'UNARCHIVE_ITEM',
    SET_AS_FAVORITE: 'SET_AS_FAVORITE',
    RENAME_LIST_ITEM: 'RENAME_LIST_ITEM',
    DELETE_USER_FROM_ITEM: 'DELETE_USER_FROM_ITEM',
    MOVE_PROJECT_TO_GROUP: 'MOVE_PROJECT_TO_GROUP',
    ADD_TAG_TO_ITEM: 'ADD_TAG_TO_ITEM',
    REMOVE_TAG_FROM_ITEM: 'REMOVE_TAG_FROM_ITEM',
    UNSET_LOBBY_LIST_TAG_FILTER: 'UNSET_LOBBY_LIST_TAG_FILTER',
    UPDATE_LOBBY_LIST_TAG_FILTER: 'UPDATE_RUUMS_TAG_FILTER',
};
