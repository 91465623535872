import { AddTaskActionPayload, RuumActionTypes, TaskPriority, TaskStatus } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { CanvasBackendConnector } from '../../../../app/ruum/canvas/canvas.backendConnector';
import { AuthService } from '../../../auth/auth.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { TrackingConnector } from '../../trackingConnector.service';
import { deepEqual, getRandomId, wait } from '../../utils.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { TaksListFilters, TaskListItem, TaskListOrderBy } from './task-list.model';

const initializeOrder = () => {
    return {
        by: (localStorage.getItem('taskListSortBy') as TaskListOrderBy) || 'changedAt',
        direction: (localStorage.getItem('taskListSortDirection') as SortDirection) || 'desc',
    };
};

export abstract class TaskListConnector extends PaginatedListLoader<TaskListItem, TaksListFilters, TaskListOrderBy> {
    readonly groupId$ = new BehaviorSubject<string>(undefined);
    readonly workspaceId$ = new BehaviorSubject<string>(undefined);
    readonly isMilestone$ = new BehaviorSubject<boolean>(undefined);
    readonly projectId$ = new BehaviorSubject<string>(undefined);
    readonly parentId$ = new BehaviorSubject<string>(null);
    readonly orderBy$ = new BehaviorSubject<OrderedListParams<TaskListOrderBy>>(initializeOrder());
    readonly status$ = new BehaviorSubject<TaskStatus[]>([]);
    readonly priorities$ = new BehaviorSubject<TaskPriority[]>([]);
    readonly createdBy$ = new BehaviorSubject<string>(undefined);
    readonly assignedMemberIds$ = new BehaviorSubject<string[]>([]);
    readonly assignedRoleIds$ = new BehaviorSubject<string[]>([]);
    readonly description$ = new BehaviorSubject<string>(undefined);
    readonly showSubtasksOnFilter$ = new BehaviorSubject<boolean>(undefined);
    readonly customFields$ = new BehaviorSubject<string>('');

    constructor(
        protected readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected canvasConnector: CanvasBackendConnector,
        protected trackingConnector: TrackingConnector,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
    }

    getListObservable(): Observable<PaginatedList<TaskListItem>> {
        return super.getListObservable();
    }

    protected getData(
        page: number,
        filters: TaksListFilters,
        orderBy: OrderedListParams<TaskListOrderBy>,
    ): Observable<PaginatedList<TaskListItem>> {
        return this.readModelConnector.getTasks({ page }, filters, orderBy);
    }

    getFilters$(): Observable<TaksListFilters> {
        return combineLatest([
            this.createdBy$,
            this.assignedMemberIds$,
            this.assignedRoleIds$,
            this.groupId$,
            this.status$,
            this.priorities$,
            this.description$.pipe(
                debounceTime(300),
                tap((description) => {
                    if (description) {
                        this.trackingConnector.trackEventInferCategoryFromUrl('task_list_filter', 'description');
                    }
                }),
            ),
            this.projectId$,
            this.parentId$.pipe(distinctUntilChanged()),
            this.workspaceId$,
            this.isMilestone$,
            this.showSubtasksOnFilter$,
            this.customFields$,
        ]).pipe(
            map<any, TaksListFilters>(
                ([
                    createdBy,
                    assignedTo,
                    assignedToFunctionalRole,
                    groupId,
                    status,
                    priorities,
                    description,
                    projectId,
                    parentId,
                    workspaceId,
                    isMilestone,
                    showSubtasksOnFilter,
                    taskCustomFields,
                ]) => {
                    const archived = !!projectId ? undefined : false;

                    // On Task List we explicitely show all subtasks when a filter is set
                    const askForAllSubtasks =
                        showSubtasksOnFilter &&
                        (createdBy !== undefined ||
                            assignedTo.length !== 0 ||
                            assignedToFunctionalRole.length !== 0 ||
                            status.length !== 0 ||
                            priorities.length !== 0 ||
                            taskCustomFields !== '');

                    return {
                        createdBy,
                        assignedTo,
                        assignedToFunctionalRole,
                        groupId,
                        status,
                        priorities,
                        description,
                        projectId,
                        templateType: projectId ? undefined : null,
                        parentId: askForAllSubtasks ? undefined : parentId,
                        workspaceId,
                        archived,
                        isMilestone,
                        taskCustomFields,
                    };
                },
            ),
            distinctUntilChanged((a, b) => deepEqual(a, b)),
        );
    }

    protected getOrderBy$() {
        return this.orderBy$;
    }

    resetFilters() {
        this.createdBy$.next(undefined);
        this.assignedMemberIds$.next([]);
        this.assignedRoleIds$.next([]);
        this.groupId$.next(undefined);
        this.workspaceId$.next(undefined);
        this.status$.next([]);
        this.priorities$.next([]);
        this.description$.next(undefined);
        this.projectId$.next(undefined);
        this.parentId$.next(null);
        this.showSubtasksOnFilter$.next(true);
        this.customFields$.next('');
    }

    resetStatusFilter() {
        this.status$.next([]);
    }

    resetMilestoneFilter() {
        this.status$.next([]);
    }

    resetCreatorFilter() {
        this.createdBy$.next(undefined);
    }

    resetPriorityFilter() {
        this.priorities$.next([]);
    }

    filtersCount$(): Observable<number> {
        return combineLatest([this.status$, this.priorities$, this.assignedMemberIds$, this.assignedRoleIds$]).pipe(
            map(([status, priorities, assignedTo, assignedToFunctionalRole]) => {
                const statusFilter = status.length;
                const priorityFilter = priorities.length;

                return statusFilter + priorityFilter + assignedTo.length + assignedToFunctionalRole.length;
            }),
            distinctUntilChanged(),
        );
    }

    showSubtask(showSubtask: boolean) {
        showSubtask ? this.parentId$.next(undefined) : this.parentId$.next(null);
    }

    resetStatusAndPriorityFilters() {
        this.status$.next([]);
        this.priorities$.next([]);
        this.assignedMemberIds$.next([]);
        this.assignedRoleIds$.next([]);
    }

    loadIndicatorObs(): Observable<boolean> {
        return this.isLoadingFirstPage$.pipe(debounceTime(200));
    }

    isBusy$(): Observable<boolean> {
        return combineLatest([this.isLoadingAnotherPage$, this.isLoadingFirstPage$]).pipe(
            map(([first, other]) => first || other),
        );
    }

    isLoadingAnotherPage(): Observable<boolean> {
        return this.isLoadingAnotherPage$;
    }

    createTask(
        projectId: string,
        sectionId: string,
        description: string,
        payloadOverwrite?: Partial<AddTaskActionPayload>,
    ) {
        const taskId = getRandomId('task_');
        const payload: AddTaskActionPayload = {
            id: taskId,
            description,
            assignees: [],
            ...payloadOverwrite,
        };

        return (
            Promise.all([
                this.projectServiceConnector.addActionToRuum(projectId, RuumActionTypes.ADD_TASK, payload),
                this.canvasConnector.addTaskToTheBottomOf(projectId, sectionId, taskId).toPromise(),
            ])
                /** canvas task is added to database async. */
                .then(() => wait(500))
                .then(() => {
                    this.reload();
                    this.trackingConnector.trackEventInferCategoryFromUrl('task', 'created');
                })
                .catch((err) => {
                    this.trackingConnector.trackEventInferCategoryFromUrl('task', 'creation-errored');
                    return this.alertService.warning('Error creating Task', err).then(() => {
                        throw err;
                    });
                })
        );
    }

    filterByDescription(description: string) {
        this.description$.next(description);
    }

    filterByGroupId(groupId: string) {
        this.groupId$.next(groupId);
    }

    filterByProjectId(projectId: string) {
        this.projectId$.next(projectId);
    }

    filterByCreatedBy(createdBy: string) {
        this.trackingConnector.trackEventInferCategoryFromUrl('task_list_filter', 'createdBy', createdBy);
        this.createdBy$.next(createdBy);
    }

    filterByMilestone(isMilestone: boolean) {
        this.isMilestone$.next(isMilestone);
    }

    showSubtasksOnFilter(showSubtasks: boolean) {
        this.showSubtasksOnFilter$.next(showSubtasks);
    }

    filterByAssignedMembers(ids: string[]) {
        this.assignedMemberIds$.next(ids);
    }

    filterByAssignedRoles(ids: string[]) {
        this.assignedRoleIds$.next(ids);
    }

    resetFilterByAssignedMembers() {
        this.assignedMemberIds$.next([]);
    }

    resetFilterByAssignedRoles() {
        this.assignedRoleIds$.next([]);
    }

    filterByStatus(statusList: TaskStatus[]) {
        this.status$.next(statusList);
    }

    filterByPriority(priorityList: TaskPriority[]) {
        this.priorities$.next(priorityList);
    }

    filterByCustomFields(customFields: { [key: string]: string }) {
        this.customFields$.next(JSON.stringify(customFields));
    }

    orderListBy(by: TaskListOrderBy, direction: SortDirection) {
        return this.orderBy$.next({ by, direction });
    }

    getOrderBy(): OrderedListParams<TaskListOrderBy> {
        let orderBy: OrderedListParams<TaskListOrderBy>;
        this.orderBy$.pipe(take(1)).subscribe((val) => (orderBy = val));
        return orderBy;
    }

    getSubstasks(projectId: string, parentId: string): Observable<PaginatedList<TaskListItem>> {
        return this.readModelConnector.getTasks(
            { page: 1, pageSize: 200 },
            { projectId, parentId },
            { by: 'childOrder', direction: 'asc' },
        );
    }

    getTask(projectId: string, taskId: string): Observable<TaskListItem> {
        return this.readModelConnector.getTask(projectId, taskId);
    }
}
