import { Injectable } from '@angular/core';
import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../auth/auth.service';
import { LoggerService as Logger } from './../../common/logger.service';
import { applyDocTransaction, applySectionTransaction } from './state/stateChangePropagation';

export interface CanvasState {
    /** The full documents with all sections */
    fullCanvasState: EditorState;
    /** The state of each section in the document */
    sectionsStates: EditorState[];
}

export interface LastDragEvent {
    timestamp: number;
    sectionId: string;
}

@Injectable({ providedIn: 'root' })
export class CanvasStateManagerService {
    private canvasState$ = new BehaviorSubject<CanvasState>({
        fullCanvasState: undefined,
        sectionsStates: [],
    });

    private sectionsViews: {
        [sectionId: string]: EditorView;
    } = {};

    private subscriptions: Subscription[] = [];

    private lastFocusedSectionId$ = new BehaviorSubject<string>(undefined);
    /**
     * workaround to avoid circular dependency injection problem
     */
    private createSectionState: (sectionNode) => EditorState;

    constructor(private logger: Logger, private authService: AuthService) {}

    startManagingState(fullState: EditorState, sectionsStates: EditorState[]) {
        this.changeState(fullState, sectionsStates);
    }

    stopManagingState() {
        this.logger.debug('CanvasStateManagerService', 'stopManagingState');
        this.cancelSubscriptions();
        /*
        Notify the other services no canvas state is being managed at the moment. They should wait for the next
        valid stateChange$ before using 'getCurrentFullState'.
        If anything else is trying to use this state there should be a null pointer exception
        */
        this.changeState(undefined, []);
    }

    dispatchSectionTransaction(sectionId: string, sectionTransaction: Transaction) {
        const oldCanvasState = this.getCanvasState();
        try {
            const states = applySectionTransaction(
                sectionId,
                sectionTransaction,
                this.getCurrentFullState(),
                this.getSectionsStates(),
                (step) => this.addTrackingKeysToStep(step),
            );
            this.changeState(states.docState, states.sectionStates);
        } catch (e) {
            this.logger.error('applySectionTransaction failed', e, {
                sectionId,
                sectionTransaction,
            });
            this.canvasState$.next(oldCanvasState);
        }
    }

    private addTrackingKeysToStep(step) {
        step.userId = this.authService.getLoggedUser().id;
        step.createdAt = new Date().getTime();
    }

    dispatchDocTransaction(docTransaction: Transaction) {
        const states = applyDocTransaction(
            docTransaction,
            this.getCurrentFullState(),
            this.getSectionsStates(),
            this.createSectionState,
        );
        const result = this.changeState(states.docState, states.sectionStates);
        if (!result) {
            this.logger.error('failed to dispatch doc transaction', new Error(), docTransaction);
        }
    }

    fullStateChange() {
        return this.canvasState$.pipe(map((canvasState) => canvasState.fullCanvasState));
    }

    validFullStateChange() {
        return this.fullStateChange().pipe(filter((state) => !!state));
    }

    sectionsStatesChange(): Observable<EditorState[]> {
        return this.canvasState$.pipe(map((canvasState) => canvasState.sectionsStates));
    }

    validSectionStateChange(sectionId: string): Observable<EditorState> {
        return this.sectionStateChange(sectionId).pipe(filter((state) => !!state));
    }

    sectionStateChange(sectionId: string): Observable<EditorState> {
        return this.sectionsStatesChange().pipe(
            map((states) => states.find((state) => state.doc.attrs.id === sectionId)),
        );
    }

    getCurrentFullState(): EditorState {
        return this.getCanvasState().fullCanvasState;
    }

    getSectionsStates() {
        return this.getCanvasState().sectionsStates;
    }

    getSectionState(sectionId: string): EditorState {
        return this.getSectionsStates().find((state) => state.doc.attrs.id === sectionId);
    }

    private getCanvasState(): CanvasState {
        let canvasState;
        this.canvasState$.pipe(take(1)).subscribe((state) => (canvasState = state));
        return canvasState;
    }

    private changeState(fullCanvasState, sectionsStates): boolean {
        const oldCanvasState = this.getCanvasState();
        try {
            const newCanvasState: CanvasState = { fullCanvasState, sectionsStates };
            this.canvasState$.next(newCanvasState);

            if (!environment.production) {
                (window as any).currentCanvasState = fullCanvasState;
                (window as any).sectionsStates = sectionsStates;
            }
            return true;
        } catch (e) {
            this.logger.error('error updating to new state', e, { fullCanvasState, sectionsStates });
            this.canvasState$.next(oldCanvasState);
            return false;
        }
    }

    focusedSectionIdChange(): Observable<string> {
        return this.lastFocusedSectionId$;
    }

    getLastFocusedSectionId(): string {
        let id;
        this.lastFocusedSectionId$.pipe(take(1)).subscribe((sectionId) => (id = sectionId));
        return id;
    }

    getFocusedSectionState(): EditorState {
        if (this.lastFocusedSectionId$) {
            return this.getSectionState(this.getLastFocusedSectionId());
        } else {
            return this.getSectionsStates()[0];
        }
    }

    getSectionView(sectionId: string): EditorView {
        return this.sectionsViews[sectionId];
    }

    setSectionView(sectionId: string, view: EditorView) {
        this.sectionsViews[sectionId] = view;
    }

    setFocusOnSection(sectionId: string) {
        this.sectionsViews[sectionId].focus();
    }

    setSectionEditorFocus(sectionId: string) {
        this.lastFocusedSectionId$.next(sectionId);
    }

    setCreateSectionState(func: (sectionNode) => EditorState) {
        this.createSectionState = func;
    }

    private cancelSubscriptions() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
