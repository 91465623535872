import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { EnterpriseRole } from '@ruum/ruum-reducers';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { WorkspaceListService } from '../../common/connectors/workspace/workspace-list.service';
import { TechnicalUserListService } from '../technical-users/technical-user-list.service';
import { WebhookType } from './webhooks.model';
@Component({
    selector: 'webhook-form',
    template: `
        <form class="mt-5 text-sm text-right" [formGroup]="form">
            <div class="form-group row" *ngIf="isIdFieldVisible">
                <label for="id" class="col-sm-2 col-form-label col-form-label-sm">Webhook ID</label>
                <div class="col-sm-8">
                    <input class="form-control form-control-sm disabled" readonly name="id" formControlName="id" />
                </div>
            </div>

            <div class="form-group row required">
                <label for="name" class="col-sm-2 col-form-label col-form-label-sm">Name</label>
                <div class="col-sm-8">
                    <input
                        class="form-control form-control-sm"
                        placeholder="my_webhook_name"
                        name="name"
                        autofocus
                        formControlName="name"
                    />
                </div>
            </div>

            <div class="form-group row required">
                <label for="url" class="col-sm-2 col-form-label col-form-label-sm">Url</label>
                <div class="col-sm-8">
                    <input
                        class="form-control form-control-sm"
                        name="url"
                        placeholder="https://mywebhook.com/v1/webhook"
                        formControlName="url"
                    />
                </div>
            </div>
            <div class="form-group row required">
                <label for="type" class="col-sm-2 col-form-label col-form-label-sm">Trigger Type</label>
                <div class="col-sm-8">
                    <ruum-select-controller
                        (selectChange)="triggerTypeChanged($event)"
                        [withUnselect]="false"
                        [options]="availableTriggerTypes"
                        [search]="false"
                        size="sm"
                        name="type"
                        formControlName="type"
                    >
                    </ruum-select-controller>
                </div>
            </div>
            <div class="form-group row required" *ngIf="form.get('type').value === 'TECHNICAL_USER'">
                <label for="userId" class="col-sm-2 col-form-label col-form-label-sm">User</label>
                <div class="col-sm-8">
                    <!-- <input class="form-control form-control-sm" name="userId" formControlName="userId"> -->
                    <ruum-select-controller
                        [withUnselect]="false"
                        [options]="this.technicalUsers$ | async"
                        [search]="true"
                        size="sm"
                        name="userId"
                        formControlName="userId"
                    >
                    </ruum-select-controller>
                </div>
            </div>
            <div class="form-group row required" *ngIf="form.get('type').value === 'WORKSPACE'">
                <label for="workspaceId" class="col-sm-2 col-form-label col-form-label-sm">Workspace</label>
                <div class="col-sm-8">
                    <!-- <input class="form-control form-control-sm" name="workspaceId" formControlName="workspaceId"> -->
                    <ruum-select-controller
                        [withUnselect]="false"
                        [options]="this.workspaces$ | async"
                        [search]="true"
                        size="sm"
                        name="workspaceId"
                        formControlName="workspaceId"
                    >
                    </ruum-select-controller>
                </div>
            </div>
            <div class="form-group row">
                <label for="authType" class="col-sm-2 col-form-label col-form-label-sm">Auth Type</label>
                <div class="col-sm-8">
                    <input
                        readonly
                        class="form-control form-control-sm disabled"
                        name="authType"
                        formControlName="authType"
                    />
                </div>
            </div>
            <div class="form-group row required">
                <label for="username" class="col-sm-2 col-form-label col-form-label-sm">Username</label>
                <div class="col-sm-8">
                    <input
                        class="form-control form-control-sm"
                        name="username"
                        placeholder="webhookUser"
                        formControlName="username"
                        autocomplete="off"
                    />
                </div>
            </div>
            <div class="form-group row required">
                <label for="password" class="col-sm-2 col-form-label col-form-label-sm">Password</label>
                <div class="col-sm-8">
                    <input
                        type="password"
                        class="form-control form-control-sm"
                        name="password"
                        placeholder="*****"
                        formControlName="password"
                        autocomplete="new-password"
                    />
                </div>
            </div>
        </form>
    `,
})
export class WebhookFormComponent implements OnInit, OnDestroy {
    @Input()
    form: FormGroup;

    @Input()
    isIdFieldVisible = true;

    availableTriggerTypes: any[] = [
        {
            id: WebhookType.TECHNICAL_USER,
            title: 'Integration Account',
        },
        {
            id: WebhookType.WORKSPACE,
            title: 'Workspace',
        },
    ];
    technicalUsers$: Observable<any[]> = of([]);
    workspaces$: Observable<any[]> = of([]);

    subscriptions: Subscription[] = [];

    constructor(
        private workspaceListService: WorkspaceListService,
        private technicalUserListService: TechnicalUserListService,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    ngOnInit() {
        if (this.form) {
            const type = this.form.get('type').value;
            if (type) {
                this.setType(type);
            } else {
                this.setType(WebhookType.TECHNICAL_USER);
            }
        }
        this.workspaceListService.enterpriseId$.next(this.selectedEnterpriseService.getSelectedEnterpriseId());
        this.workspaceListService.loadList();
        this.technicalUserListService.loadList();

        this.workspaces$ = this.workspaceListService.list$.pipe(
            map((workspaces) => {
                return workspaces.map((ws) => {
                    return {
                        id: ws.id,
                        title: `${ws.name} (${ws.id})`,
                    };
                });
            }),
        );

        this.technicalUsers$ = this.technicalUserListService.getTechnicalUsers$().pipe(
            map((data) => {
                return data.map((acc) => {
                    return {
                        id: acc.userId,
                        title: `${acc.name} (${acc.userId})`,
                    };
                });
            }),
        );

        this.subscriptions.push(
            this.selectedEnterpriseService.selectedEnterpriseRole().subscribe((role) => {
                if (role !== EnterpriseRole.EnterpriseAdmin) {
                    // Other roles cannot change url
                    this.form.get('url').disable();
                }
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    triggerTypeChanged(type: WebhookType) {
        if (type) {
            this.setType(type);
        }
    }

    setType(type: WebhookType) {
        switch (type) {
            case WebhookType.TECHNICAL_USER:
                this.form.get('workspaceId').setValidators(null);
                this.form.get('workspaceId').setValue(null);
                this.form.get('userId').setValidators([Validators.required]);
                break;

            case WebhookType.WORKSPACE:
                this.form.get('userId').setValidators(null);
                this.form.get('userId').setValue(null);
                this.form.get('workspaceId').setValidators([Validators.required]);
                break;
        }

        if (type !== this.form.get('type').value) {
            this.form.get('type').setValue(type);
        }
    }
}
