import { UserListItem } from '../readModelConnector.service';

export class PaginationParams {
    page: number;
    /** should default to 25 */
    pageSize?: number;

    constructor(page: number, pageSize: number = 25) {
        this.page = page;
        this.pageSize = pageSize;
    }
}

export interface LobbyListFilters {
    tags?: string[];
    archived?: boolean;
    status?: string[];
    createdBy?: string;
    name?: string;
    groupId?: string;
    admin?: boolean;
    workspaceId?: string;
    listType?: LobbyListType;
    customFields?: { [key: string]: any };
}

export type LobbyListType = 'projects' | 'projectgroups' | 'projects_and_groups' | 'all';

export type LobbyListItemType = 'project' | 'projectgroup';

interface LobbyListItemBase {
    type: LobbyListItemType;
    id: string;
    name: string;
    favorite: boolean;
    archived: boolean;
    status: string;
    createdAt: number;
    changedAt: number;
    createdBy: UserListItem;
    changedBy: UserListItem;
    tags: string[];
    changesWhileGone: UnseenChanges[];
    isAdmin: boolean;
    isViewer: boolean;
    numberOfParticipants?: number;
    groupId?: string;
    workspaceId?: string;
    workspaceName: string;
    hasSeenItemBefore: boolean;
}

export interface LobbyListItemProject extends LobbyListItemBase {
    type: 'project';
    changesWhileGone: UnseenChanges[];
    groupId: string;
    groupName: string;
    /** Logged user is a member of the group where this project is. */
    groupMember: boolean;
    /** Logged user is the admin of the group where this project is. */
    groupAdmin: boolean;
    /** Logged user is a member of the workspace where this project is. */
    workspaceMember: boolean;
    /** Logged user is the admin of the workspace where this project is. */
    workspaceAdmin: boolean;
    /** Logged user is a member of the Project, i.e. she doesn't have access only because of the Group or Workspace. */
    projectMember: boolean;
    /** Number of comments in the project. */
    commentsCount: number;
    /** Number of comments in the project the logged user hasn't seen yet. */
    unseenCommentsCount: number;
    nameChangeBlocked: boolean;
}

export interface LobbyListItemProjectGroup extends LobbyListItemBase {
    type: 'projectgroup';
    numberOfProjects: number;
    description?: string;
    /** If the looged user has access to the group. False if user can see the item only because she has access to one of the group's projects.  */
    hasGroupAccess: boolean;
    /** Logged user is a member of the workspace where this group is. */
    workspaceMember: boolean;
    /** Logged user is the admin of the workspace where this group is. */
    workspaceAdmin: boolean;
    /** Logged user is a member of the Group, i.e. she doesn't have access only because of the Workspace. */
    groupMember: boolean;
}

export type LobbyListItem = LobbyListItemProject | LobbyListItemProjectGroup;

export interface UnseenChanges {
    loggedUserId: string;
    userId: string;
    fullName: string;
    initials: string;
    color: string;
    tasks: number;
    polls: number;
    attachments: number;
    sections: number;
    emails: number;
    comments: number;
    participants: number;
    hasSeenRuum: boolean;
    canvas: boolean;
}

export type LobbyListOrderBy = 'status' | 'name' | 'changesWhileGone' | 'changedAt' | 'createdBy';
