import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    RemoveTemplateParticipantAction,
    SetTemplateParticipantRoleAction,
    TemplateAction,
    TemplateParticipant,
    TemplateParticipantRole,
    TemplateSharingSettings,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { filter, map, pluck, take } from 'rxjs/operators';
import { AuthService } from '../../../../app/auth/auth.service';
import { AppState } from '../../app.store';
import { CommonService } from '../../common.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { StoreExceptionCatcher } from '../../storeExceptionCatcher.service';
import { EntityChangesListener } from '../changesListener.service';
import { SelectedProjectService } from '../project/selectedProject.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { SelectedEntityService } from '../selectedEntity';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { SelectedTemplateServiceHelper as SelectedTemplateHelper } from './selectedTemplate.helper';
import { TEMPLATE_WEB_APP_ACTION_TYPES } from './selectedTemplate.reducer';
import { TemplateListItem } from './template-list.model';

export interface TemplateInviteParticipant {
    email: string;
    role: TemplateParticipantRole;
    technicalUser?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SelectedTemplateService extends SelectedEntityService {
    constructor(
        protected authService: AuthService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected store: Store<AppState>,
        protected router: Router,
        protected storeExceptionCatcher: StoreExceptionCatcher,
        protected alertService: RuumAlertService,
        protected changesListener: EntityChangesListener,
        protected readModelBackendConnector: ReadModelBackendConnector,
        private titleService: Title,
        private selectedWorkspace: SelectedWorkspaceService,
        private commonService: CommonService,
        private helper: SelectedTemplateHelper,
        private selectedProject: SelectedProjectService,
    ) {
        super(
            authService,
            projectServiceConnector,
            store,
            router,
            storeExceptionCatcher,
            alertService,
            changesListener,
            'template',
        );
    }

    async selectTemplate(templateId: string): Promise<TemplateListItem> {
        this.unselectTemplate();
        const template = await this.readModelBackendConnector.getTemplate(templateId).toPromise();

        this.selectEntity(template);

        await this.selectTemplateProject(template);

        this.titleService.setTitle(`${template.name}`);
        return template;
    }

    private selectTemplateProject(template: TemplateListItem): Promise<any> {
        let projectId: string;
        if (template.role === 'TemplateViewer') {
            projectId = template.publishedProjectId;
        } else if (template.draftProjectId) {
            projectId = template.draftProjectId;
        } else {
            projectId = template.publishedProjectId;
        }
        return this.selectedProject.selectProject(projectId);
    }

    async createRuumFromCurrentTemplate() {
        this.commonService.setBusy(true);
        try {
            const template = this.getSelectedTemplate();
            const project = await this.projectServiceConnector.createProject(
                template.name,
                template.id,
                undefined,
                template.workspaceId,
            );
            this.router.navigate(['ruums', project.id]);
        } catch (err) {
            this.alertService.warning(`Error creating Ruum from Template`, err);
        } finally {
            this.commonService.setBusy(false);
        }
    }

    protected dispatchSelectEntity(template: TemplateListItem) {
        this.store.dispatch(this.getAction(TEMPLATE_WEB_APP_ACTION_TYPES.SELECT_TEMPLATE, template, {}));
    }

    protected reload(templateId: string) {
        this.selectTemplate(templateId).catch((err) => {
            this.router.navigateByUrl('/home');
        });
    }

    persistAction<T extends TemplateAction>(type: T['type'], payload: T['payload']): Promise<any> {
        const role = this.getLoggedUserParticipant().role;
        const wsParticipant = this.selectedWorkspace.getLoggedUserParticipant();
        const workspaceRole = <WorkspaceParticipantRole>(wsParticipant && wsParticipant.role);
        return super.persistAction(type, payload, { role, workspaceRole });
    }

    persistActions<T extends TemplateAction>(actions: { type: T['type']; payload: T['payload'] }[]): Promise<any> {
        const promises: Promise<any>[] = [];
        for (const action of actions) {
            promises.push(super.persistAction(action.type, action.payload, {}));
        }
        return Promise.all(promises);
    }

    unselectTemplate() {
        this.selectedProject.unselectProject();
        this.selectDummyTemplate();
        this.stopListeningToChanges();
    }

    private selectDummyTemplate() {
        this.store.dispatch({
            type: TEMPLATE_WEB_APP_ACTION_TYPES.SELECT_TEMPLATE,
            payload: {},
        });
    }

    /*****************************************************/

    /**
     * This part of the code are helpers to the selected state in the store.
     */
    getSelectedTemplateId(): string {
        return this.getSeletedEntityId();
    }

    getSelectedTemplate(): TemplateListItem {
        let template: TemplateListItem;
        this.selectedTemplate()
            .pipe(take(1))
            .subscribe((t) => (template = t));
        return template;
    }

    selectedTemplate(): Observable<TemplateListItem> {
        return this.store.select('selectedTemplate');
    }

    loggedUserTemplateParticipant(): Observable<TemplateParticipant> {
        return this.helper.loggedUserTemplateParticipant();
    }

    getLoggedUserParticipant(): TemplateParticipant {
        let participant: TemplateParticipant;
        this.loggedUserTemplateParticipant()
            .pipe(take(1))
            .subscribe((p) => (participant = p));
        return participant;
    }

    getTemplateSharingSettings(): Observable<TemplateSharingSettings> {
        return this.selectedTemplate().pipe(pluck('sharingSettings'));
    }

    participants(): Observable<TemplateParticipant[]> {
        return this.helper.participants();
    }

    technicalUsers(): Observable<TemplateParticipant[]> {
        return this.helper.technicalUsers();
    }

    isAdmin(): Observable<boolean> {
        return this.loggedUserTemplateParticipant().pipe(
            filter((user) => !!user),
            map((user: TemplateParticipant) => {
                return user && user.role === 'TemplateAdmin';
            }),
        );
    }

    isReadOnly(): Observable<boolean> {
        return this.loggedUserTemplateParticipant().pipe(
            map((loggedUser) => {
                if (!loggedUser) {
                    return true;
                }
                return loggedUser.role === 'TemplateViewer' || loggedUser.role === undefined;
            }),
        );
    }

    async inviteUserToTemplate(
        templateId: string,
        invitees: TemplateInviteParticipant[],
        personalMessage?: string,
    ): Promise<void> {
        return this.projectServiceConnector.inviteToTemplate(templateId, invitees, personalMessage);
    }

    setParticipantRole(userId: string, role: TemplateParticipantRole) {
        return this.persistAction<SetTemplateParticipantRoleAction>('SET_TEMPLATE_PARTICIPANT_ROLE', {
            id: userId,
            role,
        });
    }

    async removeUserFromTemplate(userId: string): Promise<'cancel' | 'ok'> {
        const leaving = this.authService.getLoggedUser().id === userId;
        const title = `Are you sure you want to ${
            leaving ? ' leave this template?' : ' permanently remove the member from this template?'
        }`;

        try {
            const result = await this.alertService
                .warning({ title, actionText: `${leaving ? 'Leave' : 'Remove'}` })
                .catch((err) => 'cancel');
            if (result === 'cancel') {
                return result;
            }
            await this.removeTemplateParticipant(userId);

            if (leaving) {
                this.unselectTemplate();
            } else {
                await this.participants().pipe(take(2)).toPromise();
            }
            return 'ok';
        } catch (err) {
            this.alertService.warning(`Error ${leaving ? 'leaving' : 'removing user from'} Template.`, err);
            throw err;
        }
    }

    async removeTemplateParticipant(userId: string) {
        return this.persistAction<RemoveTemplateParticipantAction>('REMOVE_PARTICIPANT_FROM_TEMPLATE', { id: userId });
    }
}
