import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentSize, ComponentTheme, ComponentType } from '../ui-components.type';

@Component({
    selector: 'ruum-profile-role',
    template: `
        <ruum-profile
            [name]="name"
            [initials]="initials"
            [size]="size | ruumProfileSize"
            [type]="type"
            [theme]="theme"
            [rounded]="rounded"
            [hover]="hover"
            [active]="active"
            [disabled]="disabled"
            [disableTooltip]="disableTooltip"
            [showName]="showName"
            [tabindex]="tabindex"
            [tooltipTheme]="tooltipTheme"
        ></ruum-profile>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileRoleComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return ['d-flex minw-0', this.componentClass].filter((el) => !!el).join(' ');
    }

    @Input() name = '';

    @Input() size: ComponentSize;
    @Input() type: ComponentType;
    @Input() tabindex = -1;

    @Input() hover: boolean;
    @Input() active: boolean;
    @Input() disabled: boolean;
    @Input() rounded = false;

    @Input() componentClass: string;
    @Input() disableTooltip = false;
    @Input() showName = false;
    @Input() tooltipTheme: string;

    theme: ComponentTheme = 'light';
    initials = 2;

    constructor() {}

    ngOnInit() {}
}
