import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AdminHomeModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RuumCommonModule } from './common/common.module';
import { RequestIdInterceptor } from './common/utils/request-id.interceptor';
import { GlobalErrorHandler } from './common/globalErrorHandler';
import { RuumNavbarModule } from './common/navbar/navbar.module';
import { getMetaReducers, reducers } from './common/reducers';
import { RuumBootstrapModule } from './common/ruum-bootstrap/ruum-bootstrap.module';
import { SidenavModule } from './common/sidenav/sidenav.module';
import { StoreExceptionCatcher } from './common/storeExceptionCatcher.service';
import { ExternalSystemCallInterceptor } from './common/utils/external-system-call.interceptor';
import { HttpCSRFInterceptor } from './common/utils/http-csrf.interceptor';
import { HttpNoCacheInterceptor } from './common/utils/http-no-cache.interceptor';
import { HttpOfflineInterceptor } from './common/utils/http-offline.interceptor';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        /** Angular modules */
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictActionImmutability: false,
            },
        }),
        !environment.production
            ? StoreDevtoolsModule.instrument({
                  maxAge: 25, // Retains last 25 states
                  logOnly: environment.production, // Restrict extension to log-only mode
              })
            : [],
        /** Vendor modules */
        /** Ruum modules */
        RuumCommonModule,
        RuumBootstrapModule,
        AdminHomeModule,
        RuumNavbarModule,
        AppRoutingModule,
        SidenavModule,
    ],
    providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpNoCacheInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpOfflineInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ExternalSystemCallInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCSRFInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestIdInterceptor,
            multi: true,
        },
        {
            provide: USER_PROVIDED_META_REDUCERS,
            deps: [StoreExceptionCatcher],
            useFactory: getMetaReducers,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
})
export class AppModule {}
