import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ruum-hint',
    template: `
        <div class="d-flex flex-fill">
            <div
                class="d-flex flex-column flex-fill bg-accent-light text-accent rounded text-small p-4"
                [style.line-height.px]="18"
            >
                <ng-content></ng-content>
            </div>
        </div>
    `,
})
export class RuumSidepanelHintComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';
}
