import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { RuumDropDownOption } from '../../components/ruum-dropdown-popup.component';
import { RuumPopupService } from '../../popup/ruum-popup-container.service';
import { ActionToken, NavActions, SidenavService, SidePanelDisplayNode } from '../sidenav.service';

@Component({
    selector: 'ruum-side-panel-section',
    template: `
        <ng-container *ngIf="isSegmentNode(); else elseBlock">
            <div
                [ngClass]="type"
                class="section pb-2"
                [class.splitter]="sectionSplitter"
                [style.padding-top.px]="6"
                attr.data-help-id="sidepanel_{{ caption }}"
            >
                <div
                    *ngIf="caption != ''"
                    class="node-item section-item py-1 px-3"
                    [class.generic-highlight]="genericHiglight"
                >
                    <button
                        *ngIf="hasAction(actions, actionTokens.DRILL_DOWN)"
                        tabindex="-1"
                        class="btn btn-xs btn-icon btn-link-white-64"
                        (mouseover)="sectionHover.emit(true)"
                        (focus)="sectionHover.emit(true)"
                        (mouseout)="sectionHover.emit(false)"
                        (blur)="sectionHover.emit(false)"
                        (click)="toggleSection($event)"
                    >
                        <i
                            class="icon"
                            [class.icon-cevron-down]="sectionVisible"
                            [class.icon-cevron-right]="!sectionVisible"
                        ></i>
                    </button>

                    <div
                        class="text-white-64 flex-fill text-tiny text-uppercase font-weight-bold py-1"
                        (click)="toggleSection($event)"
                        (keydown.space)="toggleSection($event)"
                        (keydown.enter)="toggleSection($event)"
                    >
                        {{ caption }}
                    </div>

                    <button
                        *ngIf="hasAction(actions, actionTokens.EXPAND)"
                        tabindex="-1"
                        class="btn btn-xs btn-icon btn-link-white-64 ml-1"
                        (click)="toggleExpandSection($event)"
                        (mouseover)="genericHiglight = false"
                        (focus)="genericHiglight = false"
                        (mouseout)="genericHiglight = true"
                        (blur)="genericHiglight = true"
                        [ngbTooltip]="expandTooltipContent"
                        [placement]="['top', 'bottom', 'auto']"
                    >
                        <i
                            class="icon"
                            [class.icon-collapse]="!sectionExpanded"
                            [class.icon-expand]="sectionExpanded"
                        ></i>
                    </button>

                    <button
                        *ngIf="hasAction(actions, actionTokens.ADD)"
                        tabindex="-1"
                        class="btn btn-xs btn-icon btn-link-white-64 ml-1"
                        (click)="addElement($event)"
                        (mouseover)="genericHiglight = false"
                        (focus)="genericHiglight = false"
                        (mouseout)="genericHiglight = true"
                        (blur)="genericHiglight = true"
                        [ngbTooltip]="newSectionTooltipContent"
                        [placement]="['top', 'bottom', 'auto']"
                    >
                        <i class="icon icon-add"></i>
                    </button>
                </div>

                <div [ngClass]="type" *ngIf="sectionVisible">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </div>
                <div
                    *ngIf="hasAction(actions, actionTokens.BUTTON) && sectionVisible && lenght >= 24"
                    class="mx-4 py-1 text-center offset-1"
                >
                    <button
                        (click)="performAction(actions, actionTokens.BUTTON)"
                        class="pl-1 btn btn-xs btn-outline-white-64"
                        tabindex="-1"
                        style="vertical-align: middle"
                    >
                        <span>More Ruums</span>
                    </button>
                </div>
                <div>
                    <busy-state
                        [white]="true"
                        [spinnerSize]="20"
                        [spanPage]="false"
                        [componentId]="navigationContext"
                        class="position-relative"
                    ></busy-state>
                </div>
            </div>
        </ng-container>
        <ng-template #elseBlock><ng-container *ngTemplateOutlet="content"></ng-container> </ng-template>

        <ng-template #content><ng-content></ng-content></ng-template>

        <ng-template #expandTooltipContent>
            <div *ngIf="!sectionExpanded" class="text-small font-weight-bold">Collapse all Sections</div>
            <div *ngIf="sectionExpanded" class="text-small font-weight-bold">Expand all Sections</div>
        </ng-template>

        <ng-template #newSectionTooltipContent>
            <div class="text-small font-weight-bold">Add Section</div>
        </ng-template>
    `,
    styles: [
        `
            :host {
                width: 100%;
            }
            :host-context(.section-hover) .node-item {
                background: rgba(256, 256, 256, 0.08);
                cursor: pointer;
            }

            .section-item {
                &.generic-highlight:hover {
                    cursor: pointer;

                    .icon-cevron-down,
                    .icon-cevron-right {
                        color: white;
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavPanelSectionComponent implements OnInit {
    @Input() node: SidePanelDisplayNode;
    @Input() navigationContext: string;
    @Input() caption: '';
    @Input() actions: Map<ActionToken, NavActions> = null;
    @Input() type: string;
    @Input() tabNavigation = false;
    @Input() sectionExpanded = true;
    @Input() lenght: 0;
    @Input() sectionSplitter: false;
    @Input() increaseFontSize: false;

    @Output() tabNavigationClicked: EventEmitter<SidePanelDisplayNode>;
    @Output() expandAction = new EventEmitter();
    @Output() sectionHover = new EventEmitter<boolean>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onAddElement = new EventEmitter<boolean>();

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('node') set _displayNode(node: SidePanelDisplayNode) {
        this.displayNode = node;
        if (this.displayNode && this.displayNode.id) {
            this.sectionVisible = this.displayNode.renderSubNode;
        }
    }

    displayNode: SidePanelDisplayNode;
    actionTokens = ActionToken;
    sectionVisible = true;
    genericHiglight = true;
    helpIdentifier = '';
    private options: RuumDropDownOption[];
    private optionsReadOnly: RuumDropDownOption[] = [];

    constructor(private sidenavService: SidenavService, private ruumPopupService: RuumPopupService) {}

    ngOnInit() {
        const options: RuumDropDownOption[] = [
            { id: 'addBlank', text: 'Blank Section', icon: 'icon-add' },
            { id: 'addTasks', text: 'Task List', icon: 'icon-tasks' },
            { id: 'addTemplate', text: 'Section Template', icon: 'icon-template' },
        ];
        this.options = options;
        this.helpIdentifier = `navigation_${this.type}`;
    }

    toggleSection(event: MouseEvent) {
        event.stopPropagation();
        this.sectionVisible = !this.sectionVisible;
        this.sidenavService.sectionVisible(this.displayNode.id, this.sectionVisible);
    }
    addElement(event: MouseEvent) {
        event.stopPropagation();
        this.openAddPopup(event);
    }

    toggleExpandSection(event: MouseEvent) {
        event.stopPropagation();
        this.sectionExpanded = !this.sectionExpanded;
        this.expandAction.emit(this.sectionExpanded);
    }

    hasAction(actions: Map<ActionToken, NavActions>, thisAction: ActionToken) {
        return actions && actions.has(thisAction);
    }

    performAction(actions: Map<ActionToken, NavActions>, thisAction: ActionToken) {
        if (actions.has(thisAction) && actions.get(thisAction) && actions.get(thisAction).url) {
            this.sidenavService.nodeAction(actions.get(thisAction).url);
        }
    }

    isSegmentNode() {
        if (this.type === 'MainNavigation' || this.type === 'segment' || this.type === 'tabbar-segment') {
            return true;
        } else {
            return false;
        }
    }

    private openAddPopup(event) {
        const pos: ClientRect = event.target.getBoundingClientRect();
        const options = this.sidenavService.isReadOnly() ? this.optionsReadOnly : this.options;
        if (options.length > 0) {
            return this.ruumPopupService
                .showSelectDropdown({ options, pos, title: '', showTitle: false })
                .pipe(take(1))
                .subscribe((selectedOption) => {
                    if (selectedOption) {
                        this.dispatchAddAction(selectedOption);
                    }
                });
        }
    }

    private dispatchAddAction(selectedOption) {
        switch (selectedOption.id) {
            case 'addBlank':
                this.sidenavService.addSection();
                break;
            case 'addTasks':
                this.sidenavService.addTasksSection();
                break;
            case 'addTemplate':
                this.sidenavService.createSectionTemplateFromScratch();
                break;
            default:
        }
    }
}
