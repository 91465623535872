import { DataSource } from '@angular/cdk/table';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { CustomFieldValue } from '@ruum/ruum-reducers';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomFieldListItem } from '../../../../common/connectors/customFields/customFields.model';
import { OrderedListParams, SortDirection } from '../../../../common/connectors/paginatedList.model';
import { CustomFieldListColumns } from '../custom-field-list-columns.types';

export class CustomfieldListDataSource extends DataSource<CustomFieldListItem | CustomFieldValue> {
    constructor(private data: Observable<CustomFieldListItem[] | CustomFieldValue[]> = of([])) {
        super();
    }

    connect(): Observable<CustomFieldListItem[] | CustomFieldValue[]> {
        return this.data;
    }

    disconnect() {}
}

@Component({
    selector: 'ruum-custom-field-list',
    templateUrl: './custom-field-list.component.html',
    styleUrls: ['./custom-field-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldListComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block w-100 mw-100';

    @Input() idColumnTemplate: TemplateRef<any>;
    @Input() displayedColumns: CustomFieldListColumns[] = [];
    @Input() isReadOnly = false;
    @Input() showAssignedType = false;
    @Input() readOnlyFields = [];
    @Input() sort: OrderedListParams<CustomFieldListColumns>;
    @Input()
    set data(data: Observable<CustomFieldListItem[] | CustomFieldValue[]>) {
        this._data = data;
        if (data) {
            this.dataSource = new CustomfieldListDataSource(
                data.pipe(
                    tap(() => {
                        this.changeDetectorRef.markForCheck();
                    }),
                ),
            );
        }
    }
    get data(): Observable<CustomFieldListItem[] | CustomFieldValue[]> {
        return this._data;
    }

    @Output() valueChange = new EventEmitter<CustomFieldValue>();
    @Output() sortChange = new EventEmitter<OrderedListParams<CustomFieldListColumns>>();

    dataSource: CustomfieldListDataSource;

    private _data: Observable<CustomFieldListItem[] | CustomFieldValue[]>;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {}

    /**
     * get the sort direction of the sortable header item
     */
    getSortDirection(sortBy: CustomFieldListColumns): SortDirection {
        if (this.sort && this.sort.by === sortBy) {
            return this.sort.direction;
        }
        return 'asc';
    }

    /**
     * get the information if the column is sorted
     */
    getSort(sortBy: CustomFieldListColumns): boolean {
        if (this.sort && this.sort.by === sortBy) {
            return true;
        }
        return false;
    }

    trackByCustomField(index: number, customField: CustomFieldListItem): string {
        if (!customField) {
            return;
        }
        return customField.id;
    }

    isReadOnlyField(customField: CustomFieldListItem) {
        return this.isReadOnly || this.readOnlyFields.includes(customField.id);
    }

    onValueChange(event: CustomFieldValue) {
        this.valueChange.emit(event);
    }

    onSortChange(sortChange: OrderedListParams<CustomFieldListColumns>) {
        this.sortChange.emit(sortChange);
    }
}
