import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { ProjectDuplicateComponent } from './project-duplicate.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalDialogModule],
    declarations: [ProjectDuplicateComponent],
    exports: [ProjectDuplicateComponent],
})
export class ProjectDuplicateModule {}
