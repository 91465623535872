import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    AddWorkspaceFunctionalRoleAssigneeAction,
    Workspace,
    WorkspaceAction,
    WorkspaceActionsTypes,
    WorkspaceParticipant,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AuthService } from '../../../../app/auth/auth.service';
import { WORKSPACE_WEB_APP_ACTION_TYPES } from '../../../../app/common/connectors/workspace/selected-workspace.reducer';
import { WORKSPACE_LIST_ITEM_ACTION_TYPES } from '../../../../app/common/connectors/workspace/workspace-list-item.reducer';
import { AppState } from '../../app.store';
import { CommonService } from '../../common.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { StoreExceptionCatcher } from '../../storeExceptionCatcher.service';
import { EntityChangesListener } from '../changesListener.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import {
    ReadModelBackendConnector,
    UserListItem,
    WorkspaceListItem,
    WorkspaceParticipantWithAllRoles,
} from '../readModelConnector.service';
import { SelectedEntityService } from '../selectedEntity';
import { WorkspaceParticipantListService } from './workspaceParticipantList.service';

@Injectable({ providedIn: 'root' })
export class SelectedWorkspaceService extends SelectedEntityService {
    /** The logged user master data and role in the selected Workspace. */
    private participant$: BehaviorSubject<WorkspaceParticipantWithAllRoles> =
        new BehaviorSubject<WorkspaceParticipantWithAllRoles>({
            roles: [],
        } as any);
    /** The logged user master data. */
    private userProfile$: BehaviorSubject<UserListItem> = new BehaviorSubject<UserListItem>({} as any);

    constructor(
        protected authService: AuthService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected store: Store<AppState>,
        protected router: Router,
        protected storeExceptionCatcher: StoreExceptionCatcher,
        protected alertService: RuumAlertService,
        protected changesListener: EntityChangesListener,
        protected readModelBackendConnector: ReadModelBackendConnector,
        private titleService: Title,
        private workspaceParticipantListService: WorkspaceParticipantListService,
        private commonService: CommonService,
    ) {
        super(
            authService,
            projectServiceConnector,
            store,
            router,
            storeExceptionCatcher,
            alertService,
            changesListener,
            'workspace',
        );
        this.listenToLoggedUserParticipant();
        this.listenToParticipantActions();
    }

    private async loadLoggerUserMasterData() {
        const users = await this.readModelBackendConnector
            .getInvolvedUsers({ page: 1, pageSize: 25 }, { userIds: [this.authService.getLoggedUser().id] })
            .pipe(map((page) => page.rows))
            .toPromise();
        this.userProfile$.next(users[0]);
    }

    private listenToLoggedUserParticipant(): void {
        combineLatest([
            this.userProfile$.pipe(filter<UserListItem>(Boolean)),
            this.selectedWorkspace().pipe(
                filter<Workspace>(Boolean),
                map((ws) => (ws ? ws.participants : [])),
                distinctUntilChanged(),
            ),
        ])
            .pipe(
                map(([profile, workspaceParticipants]) => {
                    const userId = this.authService.getLoggedUserId();
                    if (workspaceParticipants) {
                        const role = workspaceParticipants.find((participant) => participant.id === userId).role;
                        return {
                            id: profile.id,
                            fullName: profile.fullName,
                            initials: profile.initials,
                            color: profile.color,
                            mail: profile.mail,
                            role,
                        } as WorkspaceParticipantWithAllRoles;
                    }
                }),
            )
            .subscribe((participant) => {
                this.participant$.next(participant);
            });
    }

    private listenToParticipantActions() {
        const participantListActions$ = this.persistedActions().pipe(
            filter(
                (action) =>
                    action.type === WorkspaceActionsTypes.ADD_WORKSPACE_PARTICIPANTS ||
                    action.type === WorkspaceActionsTypes.SET_WORKSPACE_PARTICIPANT_ROLE ||
                    action.type === WorkspaceActionsTypes.REMOVE_PARTICIPANT_FROM_WORKSPACE,
            ),
        );
        merge(
            this.selectedWorkspace().pipe(
                map((ws) => ws && ws.id),
                distinctUntilChanged(),
            ),
            participantListActions$,
        )
            .pipe(
                debounceTime(50),
                map(() => this.getSelectedWorkspaceId()),
            )
            .subscribe((workspaceId) => {
                this.workspaceParticipantListService.workspaceId$.next(workspaceId);
                this.workspaceParticipantListService.reload();
                this.reloadListItem();
            });
    }

    async selectWorkspace(workspaceId: string): Promise<WorkspaceSelectData> {
        if (this.getSelectedWorkspaceId() === workspaceId) {
            return {
                workspace: this.getSelectedWorkspace(),
                workspaceListItem: this.getSelectedListItem(),
            };
        }

        const { workspace, workspaceListItem } = await this.getData(workspaceId);
        /** The user might not have access to the workspace itself, only to some of the projects or groups inside of it. */
        if (workspace) {
            this.selectEntity(workspace);
        } else {
            this.unselectWorkspace();
        }

        this.titleService.setTitle(`${workspaceListItem.name}`);
        this.store.dispatch({
            type: WORKSPACE_LIST_ITEM_ACTION_TYPES.LOAD_WORKSPACE_LIST_ITEM,
            payload: workspaceListItem,
        });
        this.loadLoggerUserMasterData();
        return { workspace, workspaceListItem };
    }

    async reloadListItem() {
        const workspaceId = this.getSelectedWorkspaceId();
        const workspaceListItem = await this.readModelBackendConnector
            .getWorkspaces({ page: 1 }, { id: workspaceId })
            .toPromise()
            .then((list) => list.rows[0]);
        this.store.dispatch({
            type: WORKSPACE_LIST_ITEM_ACTION_TYPES.LOAD_WORKSPACE_LIST_ITEM,
            payload: workspaceListItem,
        });
    }

    async addAssigneeToFunctionalRole(assigneeId: string, roleId: string) {
        return this.persistAction<AddWorkspaceFunctionalRoleAssigneeAction>(
            'ADD_ASSIGNEE_TO_WORKSPACE_FUNCTIONAL_ROLE',
            {
                role: roleId,
                newAssignee: assigneeId,
            },
        );
    }

    async removeAssigneeFromFunctionalRole(assigneeId: string, roleId: string) {
        const workspaceId = this.getSelectedWorkspaceId();
        return this.projectServiceConnector.removeAssigneeFromFunctionalRole(workspaceId, roleId, assigneeId);
    }

    protected dispatchSelectEntity(workspace: Workspace) {
        this.store.dispatch(this.getAction(WORKSPACE_WEB_APP_ACTION_TYPES.SELECT_WORKSPACE, workspace, {}));
    }

    private async getData(workspaceId: string): Promise<WorkspaceSelectData> {
        const [workspace, workspaceListItem] = (await Promise.all([
            this.projectServiceConnector.getWorkspace(workspaceId).catch((err) => {}),
            this.readModelBackendConnector
                .getWorkspaces({ page: 1 }, { id: workspaceId, includeTemplates: true })
                .toPromise()
                .then((list) => list.rows[0]),
        ])) as [Workspace, WorkspaceListItem];

        if (workspaceListItem) {
            return {
                workspace,
                workspaceListItem,
            };
        } else {
            throw new Error(`You don't have access to workspace ${workspaceId}`);
        }
    }

    protected reload(workspaceId: string) {
        this.selectWorkspace(workspaceId).catch((err) => {
            this.router.navigateByUrl('/home');
        });
    }

    persistAction<T extends WorkspaceAction>(type: T['type'], payload: T['payload']): Promise<any> {
        const role = this.getLoggedUserRole();
        return super.persistAction(type, payload, { role });
    }

    persistActions<T extends WorkspaceAction>(actions: { type: T['type']; payload: T['payload'] }[]): Promise<any> {
        const promises: Promise<any>[] = [];
        for (const action of actions) {
            promises.push(super.persistAction(action.type, action.payload, {}));
        }
        return Promise.all(promises);
    }

    unselectWorkspace() {
        this.selectDummyWorkspace();
        this.store.dispatch({
            type: WORKSPACE_LIST_ITEM_ACTION_TYPES.LOAD_WORKSPACE_LIST_ITEM,
            payload: {},
        });
        this.stopListeningToChanges();
    }

    private selectDummyWorkspace() {
        this.store.dispatch({
            type: WORKSPACE_WEB_APP_ACTION_TYPES.SELECT_WORKSPACE,
            payload: {},
        });
    }

    /*****************************************************/

    /**
     * This part of the code are helpers to the selected state in the store.
     */
    getSelectedWorkspaceId(): string {
        return this.getSeletedEntityId();
    }

    getSelectedWorkspace(): Workspace {
        let workspace: Workspace;
        this.selectedWorkspace()
            .pipe(take(1))
            .subscribe((g) => (workspace = g));
        return workspace;
    }

    selectedWorkspace(): Observable<Workspace> {
        return this.store.select('selectedWorkspace');
    }

    loggedUserWorkspaceParticipant(): Observable<WorkspaceParticipantWithAllRoles> {
        return this.participant$.asObservable();
    }

    getLoggedUserParticipant(): WorkspaceParticipant {
        let participant: WorkspaceParticipant;
        this.loggedUserWorkspaceParticipant()
            .pipe(take(1))
            .subscribe((p) => (participant = p));
        return participant;
    }

    getLoggedUserRole(): WorkspaceParticipantRole {
        const participant = this.getLoggedUserParticipant();
        return <WorkspaceParticipantRole>(participant && participant.role);
    }

    /** group participants which were not removed. */
    participants(): Observable<WorkspaceParticipantWithAllRoles[]> {
        return this.workspaceParticipantListService.list$.pipe(
            map((page) => page.rows),
            map((list) => list.filter((p) => !p.technicalUser)),
        );
    }

    listItem(): Observable<WorkspaceListItem> {
        return this.store.select('selectedWorkspaceListItem');
    }

    getSelectedListItem(): WorkspaceListItem {
        let item: WorkspaceListItem;
        this.listItem()
            .pipe(take(1))
            .subscribe((i) => (item = i));
        return item;
    }

    isAdmin(): Observable<boolean> {
        return this.loggedUserWorkspaceParticipant().pipe(
            filter((user) => !!user),
            map((user: WorkspaceParticipant) => {
                return user && user.role === 'WorkspaceAdmin';
            }),
        );
    }

    isReadOnly(): Observable<boolean> {
        return this.loggedUserWorkspaceParticipant().pipe(
            map((loggedUser) => {
                if (!loggedUser) {
                    return true;
                }
                return loggedUser.role === 'WorkspaceViewer' || loggedUser.role === undefined;
            }),
        );
    }

    async leaveWorkspace() {
        const result = await this.alertService
            .warning({
                title: `Are you sure you want to leave this workspace?`,
                actionText: `Leave`,
            })
            .then(() => true)
            .catch((err) => false);
        if (!result) {
            return;
        }
        try {
            this.commonService.setBusy(true, 'workspace-team');
            await this.projectServiceConnector.removeParticipantFromWorkspace(
                this.getSelectedWorkspaceId(),
                this.authService.getLoggedUserId(),
            );
            this.unselectWorkspace();
            this.router.navigate(['home']);
        } catch (err) {
            console.error(err);
            this.alertService.warning(`Error leaving Workspace.`, err);
        } finally {
            this.commonService.setBusy(false, 'workspace-team');
        }
    }

    async removeUserFromWorkspace(userId: string): Promise<any> {
        const result = await this.alertService
            .warning({
                title: `Are you sure you want to permanently remove the member from this workspace?`,
                actionText: `Remove`,
            })
            .then(() => true)
            .catch((err) => false);
        if (!result) {
            return;
        }
        try {
            this.commonService.setBusy(true, 'workspace-team');
            const promise = this.participants().pipe(take(2)).toPromise();
            await this.projectServiceConnector.removeParticipantFromWorkspace(this.getSelectedWorkspaceId(), userId);
            await promise;
        } catch (err) {
            console.error(err);
            this.alertService.warning(`Error removing user from Workspace.`, err);
        } finally {
            this.commonService.setBusy(false, 'workspace-team');
        }
    }
}

export interface WorkspaceSelectData {
    workspace?: Workspace;
    workspaceListItem: WorkspaceListItem;
}
