import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { TemplateCategoryListItem, TemplateListItem } from '../../../common/connectors/templates/template-list.model';
import { TemplateListService } from '../../../common/connectors/templates/template-list.service';
import { ServiceHelper } from '../../../common/serviceHelper';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-select-section-template',
    template: `
        <div class="modal-body p-0">
            <button
                class="position-absolute top-2 right-2 btn btn-link-secondary btn-icon close"
                type="button"
                (click)="dismiss()"
            >
                <i class="icon icon-cancel"></i>
            </button>
            <div class="row no-gutters">
                <div class="col-3 p-5 bg-extra-light">
                    <div class="d-flex flex-column overflow-y" [style.max-height.px]="720">
                        <div class="d-flex flex-column mb-5">
                            <h5 class="text-secondary mb-1">Your Templates</h5>
                            <div class="d-flex flex-fill flex-column align-items-start">
                                <button class="btn btn-sm btn-link-secondary px-0" (click)="scrollTo('owned')">
                                    <span class="text-truncate">Created by you</span>
                                </button>
                            </div>
                            <div class="d-flex flex-fill flex-column align-items-start">
                                <button class="btn btn-sm btn-link-secondary px-0" (click)="scrollTo('shared')">
                                    <span class="text-truncate">Shared with you</span>
                                </button>
                            </div>
                        </div>

                        <ng-container *ngIf="otherCategories$ | async as categories">
                            <div *ngIf="categories.length" class="d-flex flex-column mb-5">
                                <h5 class="text-secondary mb-1">Ruum Templates</h5>
                                <div class="d-flex flex-fill flex-column align-items-start">
                                    <button
                                        *ngFor="let category of categories"
                                        class="btn btn-sm btn-link-secondary px-0"
                                        (click)="scrollTo(category.id)"
                                    >
                                        <span class="text-truncate">{{
                                            category.name | ruumTemplateCategoryName
                                        }}</span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-9 p-5">
                    <h2 class="mb-5">Select a Section Template</h2>
                    <div class="d-flex flex-fill mb-5">
                        <ruum-search ariaLabel="Search for template" (changed)="search($event)"></ruum-search>
                    </div>

                    <div class="d-flex flex-fill flex-column overflow-y" [style.max-height.px]="600">
                        <ng-container *ngIf="ownedCategories$ | async as categories">
                            <ruum-select-template-group
                                *ngFor="let category of categories"
                                [type]="'section'"
                                [category]="category"
                                [componentClass]="'pb-6'"
                                (clicked)="templateSelected($event)"
                            ></ruum-select-template-group>
                        </ng-container>

                        <ng-container *ngIf="isOwnedTemplateEmpty$ | async">
                            <ruum-select-template-owned-placeholder
                                (manageTemplates)="manageTemplates()"
                            ></ruum-select-template-owned-placeholder>
                        </ng-container>

                        <ng-container *ngIf="sharedCategories$ | async as categories">
                            <ruum-select-template-group
                                *ngFor="let category of categories"
                                [type]="'section'"
                                [category]="category"
                                [componentClass]="'pb-6'"
                                (clicked)="templateSelected($event)"
                            ></ruum-select-template-group>
                        </ng-container>

                        <ng-container *ngIf="isSharedTemplateEmpty$ | async">
                            <ruum-select-template-shared-placeholder></ruum-select-template-shared-placeholder>
                        </ng-container>

                        <ng-container *ngIf="otherCategories$ | async as categories">
                            <ruum-select-template-group
                                *ngFor="let category of categories"
                                [type]="'section'"
                                [category]="category"
                                [componentClass]="'pb-6'"
                                (clicked)="templateSelected($event)"
                            ></ruum-select-template-group>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .close {
                z-index: 1051;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSectionTemplateComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([this.componentClass]);
    }

    @Input() componentClass: string;

    allCategories$: Observable<TemplateCategoryListItem[]>;
    ownedCategories$: Observable<TemplateCategoryListItem[]>;
    sharedCategories$: Observable<TemplateCategoryListItem[]>;
    otherCategories$: Observable<TemplateCategoryListItem[]>;

    isOwnedTemplateEmpty$: Observable<boolean>;
    isSharedTemplateEmpty$: Observable<boolean>;

    search$ = new BehaviorSubject<string>('');

    constructor(
        private activeModal: NgbActiveModal,
        private serviceHelper: ServiceHelper,
        private templateListService: TemplateListService,
    ) {}

    ngOnInit() {
        this.allCategories$ = this.templateListService.getPublishedSectionTemplates();

        this.ownedCategories$ = this.templateListService.getCategoriesCreatedByYou(this.allCategories$, this.search$);
        this.sharedCategories$ = this.templateListService.getCategoriesSharedWithYou(this.allCategories$, this.search$);
        this.otherCategories$ = this.templateListService.getOtherCategories(this.allCategories$, this.search$);
        this.isOwnedTemplateEmpty$ = this.templateListService.isCategoryCreatedByYouEmpty(this.allCategories$);
        this.isSharedTemplateEmpty$ = this.templateListService.isCategorySharedWithYouEmpty(this.allCategories$);

        this.templateListService.loadList();
    }

    ngOnDestroy() {
        this.templateListService.stopLoadingList();
    }

    templateSelected(template: TemplateListItem) {
        this.activeModal.close({
            action: 'ADD_SECTION_TO_PROJECT',
            template,
        });
    }

    manageTemplates() {
        this.activeModal.close({
            action: 'MANAGE_SECTION_TEMPLATES',
            template: null,
        });
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    scrollTo(id) {
        this.serviceHelper.scrollToNodeId('template-category', id);
    }

    search(searchValue) {
        this.search$.next(searchValue);
    }
}
