import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivityTypeToIconPipe } from './activity-type-to-icon.pipe';
import { ObjectTypeToIconPipe } from './object-type-to-icon.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [ObjectTypeToIconPipe, ActivityTypeToIconPipe],
    exports: [ObjectTypeToIconPipe, ActivityTypeToIconPipe],
})
export class IconModule {}
