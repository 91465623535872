import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FunctionalRoleSelectModule } from '../../functional-role/functional-role-select/functional-role-select.module';
import { GroupSelectModule } from '../../lobby/group-select/group-select.module';
import { ProjectSelectModule } from '../../lobby/project-select/project-select.module';
import { TaskModule } from '../../task/task.module';
import { DatepickerModule } from '../../ui-components/datepicker/datepicker.module';
import { LoadSpinnerModule } from '../../ui-components/load-spinner/load-spinner.module';
import { ProfileModule } from '../../ui-components/profile/profile.module';
import { ShortModule } from '../../ui-components/short-number/short-number.module';
import { WorkspaceSelectModule } from '../../workspace/workspace-select/workspace-select.module';
import { MenuModule } from './../../../shared/ui-components/menu/menu.module';
import { SearchModule } from './../../../shared/ui-components/search/search.module';
import { SelectModule } from './../../../shared/ui-components/select/select.module';
import { FilterFieldValueDatepickerComponent } from './filter-field-value-select/filter-field-value-detepicker.component';
import { FilterFieldValueListComponent } from './filter-field-value-select/filter-field-value-list-select.component';
import { FilterFieldValueSearchComponent } from './filter-field-value-select/filter-field-value-search.component';
import { FilterFieldValueSelectComponent } from './filter-field-value-select/filter-field-value-select.component';
import { FilterOperatorSelectComponent } from './filter-operator-select/filter-operator-select.component';
import { FilterRuleComponent } from './filter-rule/filter-rule.component';
import { FilterSelectModule } from './filter-select/filter-select.module';
import { GenericFilterComponent } from './generic-filter.component';

@NgModule({
    imports: [
        CommonModule,
        DatepickerModule,
        FormsModule,
        SelectModule,
        MenuModule,
        NgbModule,
        TaskModule,
        SearchModule,
        ProfileModule,
        LoadSpinnerModule,
        ShortModule,
        FilterSelectModule,
        ProjectSelectModule,
        GroupSelectModule,
        WorkspaceSelectModule,
        FunctionalRoleSelectModule,
        A11yModule,
    ],
    declarations: [
        GenericFilterComponent,
        FilterRuleComponent,
        FilterOperatorSelectComponent,
        FilterFieldValueSelectComponent,
        FilterFieldValueSearchComponent,
        FilterFieldValueListComponent,
        FilterFieldValueDatepickerComponent,
    ],
    exports: [GenericFilterComponent],
})
export class GenericFilterModule {}
