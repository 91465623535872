import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponseWithContext } from '../../utils/request-id.interceptor';
import { RuumAlertComponent, RuumAlertTheme } from './alert.component';

export interface RuumAlertOptions {
    title: string;
    description?: string | string[];
    actionText: string;
    cancelText?: string;
    noCancel?: boolean;
    options?: NgbModalOptions;
    /** It will automatically close after this number of milliseconds. */
    autoClose?: number;
    requestId?: string;
}

@Injectable({ providedIn: 'root' })
export class RuumAlertService {
    constructor(private modalService: NgbModal) {}

    warning(
        alertOptionsOrTitle: string | RuumAlertOptions,
        error?: Error | HttpErrorResponseWithContext,
    ): Promise<any> {
        if (typeof alertOptionsOrTitle === 'string') {
            alertOptionsOrTitle = {
                title: alertOptionsOrTitle,
                noCancel: true,
                actionText: 'Ok',
            };
        }
        if (error instanceof HttpErrorResponseWithContext) {
            alertOptionsOrTitle.requestId = error.requestId;
        }
        return this.open(alertOptionsOrTitle, 'danger');
    }

    info(alertOptions: string | RuumAlertOptions): Promise<any> {
        if (typeof alertOptions === 'string') {
            alertOptions = {
                title: alertOptions,
                noCancel: true,
                actionText: 'Ok',
            };
        }
        return this.open(alertOptions, 'info');
    }

    delete(alertOptions: string | RuumAlertOptions): Promise<void> {
        if (typeof alertOptions === 'string') {
            alertOptions = {
                title: alertOptions,
                actionText: 'Delete',
            };
        }
        return this.open(alertOptions, 'danger');
    }

    /** Returns a Promise that resolves when user clicks on 'alertOptions.actionText' and fails when user clicks on 'Cancel' */
    private open(alertOptions: RuumAlertOptions, theme: RuumAlertTheme): Promise<any> {
        const {
            title,
            description,
            actionText,
            options,
            noCancel,
            cancelText,
            requestId: correlationId,
        } = alertOptions;
        const modalOptions: NgbModalOptions = {
            centered: true,
            size: 'sm',
            /** If it will close when the user clicks outside of the popup, static means it will not. */
            backdrop: noCancel ? 'static' : true,
            ...options,
        };
        const modalRef: NgbModalRef = this.modalService.open(RuumAlertComponent, modalOptions);

        modalRef.componentInstance.title = title;
        modalRef.componentInstance.description = this.buildDescription(description, correlationId);
        modalRef.componentInstance.buttonText = actionText;
        modalRef.componentInstance.showCancel = !noCancel;
        modalRef.componentInstance.cancelText = cancelText || 'Cancel';

        modalRef.componentInstance.theme = theme;

        this.maybeAutoClose(alertOptions.autoClose, modalRef);

        return modalRef.result;
    }

    private maybeAutoClose(autoClose: number, modalRef: NgbModalRef) {
        if (autoClose) {
            setTimeout(() => {
                modalRef.close();
            }, autoClose);
        }
    }

    private buildDescription(content: string | string[], correlationId: string | undefined): string[] {
        const descriptionItems = Array.isArray(content) ? content : [content];
        if (!!correlationId) {
            descriptionItems.push(
                `Please provide this information when you create a ticket: Request-ID: ${correlationId}`,
            );
        }
        return descriptionItems;
    }
}
