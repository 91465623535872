import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Commentable, CommentableType, COMMENTABLE_OBJECTS } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedProjectService } from '../../common/connectors/project/selectedProject.service';

@Injectable({ providedIn: 'root' })
export class ShowCommentService {
    /**
     * How COMMENTABLE_OBJECTS maps to one of the Ruum attributes.
     * e.g. Commentable of type section is in ruum.sections.
     */
    private commentableMapping: { [key in CommentableType]: string } = {
        project: 'project',
        section: 'sections',
        task: 'tasks',
        attachment: 'attachments',
        email: 'mails',
        poll: 'polls',
        customStatus: 'customStatus',
        processBar: 'processBars',
        approval: 'approval',
    };

    private DEFAULT_COMMENTABLE: Commentable = { id: undefined, comments: [], commentsSeenInfo: {} };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private selectedProjectService: SelectedProjectService,
    ) {}

    showCommentsOfObject(objectId: string, objectType: CommentableType, extras?: any): Promise<boolean> {
        if (objectType === 'approval') {
            return this.showApprovalComments(objectId);
        } else {
            return this.router.navigate([{ outlets: { sidepanel: [`${objectType}s`, objectId, 'comments'] } }], {
                relativeTo: this.route.children[0].children[0],
                ...extras,
            });
        }
    }
    private showApprovalComments(objectId: string) {
        const [approvalId, sectionId] = objectId.split(':');
        return this.router.navigate([{ outlets: { sidepanel: ['approval-detail', sectionId, approvalId] } }], {
            relativeTo: this.route.children[0].children[0],
        });
    }

    commentableWithId(objectId: string, objectType: string) {
        return this.commentableList(objectType).pipe(map((list) => list.find((item) => item.id === objectId)));
    }

    getCommentableByObjectTypeAndObjectId(
        projectId: string,
        objectType: string,
        objectId: string,
    ): Observable<Commentable> {
        // TODO: select specific project in backend
        if (objectType === 'project') {
            return this.selectedProjectService.selectedProject();
        } else {
            return this.commentableList(objectType).pipe(
                map((list) => list.find((item) => item.id === objectId)),
                map((commentable) => commentable || this.DEFAULT_COMMENTABLE),
            );
        }
    }

    commentableList(objectType: string): Observable<Commentable[]> {
        if (COMMENTABLE_OBJECTS.indexOf(objectType) === -1) {
            throw new Error(`${objectType} is not a commentable object.`);
        }

        if (!this.commentableMapping[objectType]) {
            throw new Error(`commentable mapping for object type ${objectType} not provided.`);
        }

        return this.selectedProjectService
            .selectedProject()
            .pipe(map((ruum) => ruum[this.commentableMapping[objectType]]));
    }
}
