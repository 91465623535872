import { A11yModule } from '@angular/cdk/a11y';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadMoreModule } from '../load-more/load-mode.module';
import { ProfileModule } from '../profile/profile.module';
import { TagModule } from '../tag/tag.module';
import { SelectActionOptionComponent } from './select-action-option/select-action-option.component';
import { SelectContentComponent } from './select-content/select-content.component';
import { SelectControllerComponent } from './select-controller/select-controller.component';
import { SelectDropdownControllerComponent } from './select-dropdown-controller/select-dropdown-controller.component';
import { SelectMemberOptionComponent } from './select-member-option/select-member-option.component';
import { SelectNoneComponent } from './select-none/select-none.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectPlaceholderComponent } from './select-placeholder/select-placeholder.component';
import { SelectPopoverDesktopComponent } from './select-popover-desktop/select-popover-desktop.component';
import { SelectPopoverMobileComponent } from './select-popover-mobile/select-popover-mobile.component';
import { SelectRoleOptionComponent } from './select-role-option/select-role-option.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectTagListComponent } from './select-tag-list/select-tag-list.component';
import { SelectComponent } from './select.component';

@NgModule({
    declarations: [
        SelectComponent,
        SelectOptionComponent,
        SelectMemberOptionComponent,
        SelectRoleOptionComponent,
        SelectPlaceholderComponent,
        SelectNoneComponent,
        SelectActionOptionComponent,
        SelectSearchComponent,
        SelectContentComponent,
        SelectTagListComponent,
        SelectPopoverDesktopComponent,
        SelectPopoverMobileComponent,
        SelectDropdownControllerComponent,
        SelectControllerComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        PortalModule,
        LayoutModule,
        LoadMoreModule,
        TagModule,
        ProfileModule,
        A11yModule,
    ],
    exports: [
        SelectComponent,
        SelectOptionComponent,
        SelectMemberOptionComponent,
        SelectRoleOptionComponent,
        SelectActionOptionComponent,
        SelectPlaceholderComponent,
        SelectNoneComponent,
        SelectContentComponent,
        SelectDropdownControllerComponent,
        SelectControllerComponent,
        A11yModule,
    ],
})
export class SelectModule {}
