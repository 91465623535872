import { Processes, StepProjectCustomFieldConfiguration } from '@ruum/ruum-reducers';
import { CustomFieldSections } from '../indexDocument';
import { ObjectsProcessStep, ProcessStepWebApp } from '../objectProcessStep';

export function getCustomFieldsConfiguration(
    fields: CustomFieldSections,
    processSections: ObjectsProcessStep,
    processes: Processes,
): CustomFieldsStepConfiguration {
    const fieldsMap: CustomFieldsStepConfiguration = {};

    Object.keys(fields).forEach((fieldId) => {
        const sectionIds = fields[fieldId];

        /** If the field is not in any section, there is nothing to do. */
        if (!sectionIds || sectionIds.length === 0) {
            return;
        }

        const fieldProcessSections = sectionIds.filter((id) => !!processSections[id]);

        /** If none of the sections where this field is is connected to a process step, there is nothing to do. */
        if (fieldProcessSections.length === 0) {
            return;
        }

        /** This assumes that all sections in which a field is belong to the same process. */
        const processId = processSections[fieldProcessSections[0]].processId;
        const process = processes[processId];
        const processActiveStepId = process ? process.activeStepId : null;

        const activeSections = fieldProcessSections.filter((sectionId) => processSections[sectionId].active);

        /** If the field is not in any active section, it is not active */
        if (activeSections.length === 0) {
            fieldsMap[fieldId] = {
                active: false,
                readOnly: false,
                mandatory: null,
                fieldId,
                processId,
                processActiveStepId,
            };
        }

        activeSections.forEach((id) => {
            /** Get the process and the step where that section is and get the custom fields configuration */
            const step: ProcessStepWebApp = processSections[id];
            if (step.type !== 'canvas' && step.type !== 'approval') {
                return;
            }
            const configuration = step.customFieldsConfiguration[fieldId];
            if (configuration) {
                fieldsMap[fieldId] = {
                    ...configuration,
                    active: true,
                    processId,
                    processActiveStepId,
                };
            } else {
                fieldsMap[fieldId] = {
                    active: true,
                    readOnly: false,
                    mandatory: false,
                    fieldId,
                    processId,
                    processActiveStepId,
                };
            }
        });
    });
    return fieldsMap;
}

export interface CustomFieldsStepConfiguration {
    [fieldId: string]: CustomFieldStepConfiguration;
}

export interface CustomFieldStepConfiguration extends StepProjectCustomFieldConfiguration {
    processId: string;
    processActiveStepId: string;
    active: boolean;
}
