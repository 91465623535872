import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    FileUploadOptions,
    getDefaultRuum,
    ProjectAction,
    ProjectParticipantRole,
    Ruum,
    RuumActionTypes,
    User,
} from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchAll, take } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { LobbyListItemProject } from '../../../project/project.model';
import { PROJECT_WEB_APP_ACTION_TYPES } from '../../../project/reducerWrapper/ruumReducerWrapper.actions';
import { BlameMap, SelectedProjectWrapper } from '../../../project/reducerWrapper/ruumReducerWrapper.model';
import { AppState } from '../../app.store';
import { CommonService } from '../../common.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { StoreExceptionCatcher } from '../../storeExceptionCatcher.service';
import { flattenUniq, wait } from '../../utils.service';
import { EntityChangesListener } from '../changesListener.service';
import { RoleInParentEntity } from '../command.model';
import { SelectedProjectGroupService } from '../projectGroup/selectedProjectGroup.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ProjectParticipantWithAllRoles, ReadModelBackendConnector, UserListItem } from '../readModelConnector.service';
import { SelectedEntityService } from '../selectedEntity';
import { SelectedTemplateServiceHelper } from '../templates/selectedTemplate.helper';
import { InvolvedUsersService } from '../users/users.service';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { SELECTED_LOBBY_LIST_ITEM_PROJECT_REDUCER_ACTIONS } from './../../../project/selectedLobbyListItem.reducer';
import { ProjectCustomFieldsValuesService } from './customFieldsValues/customFieldsValues.service';
import { SelectedProjectServiceHelper } from './selectedProject.helpers';

@Injectable({ providedIn: 'root' })
export class SelectedProjectService extends SelectedEntityService {
    constructor(
        protected authService: AuthService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected store: Store<AppState>,
        protected router: Router,
        protected storeExceptionCatcher: StoreExceptionCatcher,
        protected alertService: RuumAlertService,
        protected changesListener: EntityChangesListener,
        protected commonService: CommonService,
        protected readModelBackendConnector: ReadModelBackendConnector,
        protected involvedUsersService: InvolvedUsersService,
        private titleService: Title,
        private selectedProjectGroup: SelectedProjectGroupService,
        private selectedWorkspace: SelectedWorkspaceService,
        private selectedTemplateHelper: SelectedTemplateServiceHelper,
        private helper: SelectedProjectServiceHelper,
        private customFieldValuesService: ProjectCustomFieldsValuesService,
    ) {
        super(
            authService,
            projectServiceConnector,
            store,
            router,
            storeExceptionCatcher,
            alertService,
            changesListener,
            'project',
        );
        this.helper.initialize(
            this.persistedActions(),
            () => this.getSelectedProject().id,
            this.selectedProject(),
            () => this.reloadListItem(),
            this.lobbyListItem(),
            this.selectedTemplateHelper.loggedUserTemplateParticipant(),
        );
    }

    async selectProject(projectId: string): Promise<ProjectSelectData> {
        if (!projectId) {
            throw new Error(`Not able to select project id ${projectId}`);
        }

        const { project: baseProject, lobbyListItem, fileUploadOptions } = await this.getData(projectId);

        // TODO: remove this logic once perf issues in WebApp are fixed
        // Having a large number of sections in a Ruum cause severe perf issue in the FE
        // For bigger Ruums we always return all section collapsed
        // This is part of a fix furhter described here: https://github.tools.sap/Ruum/product/issues/4065
        const project = collapseSectionFromProjectOnCondition(baseProject);

        this.titleService.setTitle(`${project.name}`);
        this.selectEntity(project);
        this.involvedUsersService.loadInvolvedUsers(project.id);

        /** Favorite information and group name (even if user has no access to group) is in the LobbyListItem */
        this.propagateSelectedProjectActions(lobbyListItem, projectId, fileUploadOptions);

        await this.updateSelectedProjectParents(project);
        return { project, lobbyListItem };
    }

    private propagateSelectedProjectActions(
        lobbyListItem: any,
        projectId: string,
        fileUploadOptions: FileUploadOptions,
    ) {
        this.store.dispatch({
            type: SELECTED_LOBBY_LIST_ITEM_PROJECT_REDUCER_ACTIONS.LOAD_LOBBY_LIST_ITEM_PROJECT,
            payload: lobbyListItem || {},
        });

        this.helper.selectProject(projectId);

        if (fileUploadOptions) {
            this.helper.setEnterpriseFileUploadOptions(fileUploadOptions);
        }
    }

    private async updateSelectedProjectParents(project: Ruum) {
        if (project.groupId) {
            await this.selectedProjectGroup.selectProjectGroup(project.groupId);
        }
        if (project.workspaceId) {
            await this.selectedWorkspace.selectWorkspace(project.workspaceId);
        } else {
            this.selectedWorkspace.unselectWorkspace();
        }
    }

    async reloadListItem() {
        const projectId = this.getSelectedProjectId();
        const lobbyListItem = await this.getLobbyListItem(projectId);
        this.store.dispatch({
            type: SELECTED_LOBBY_LIST_ITEM_PROJECT_REDUCER_ACTIONS.LOAD_LOBBY_LIST_ITEM_PROJECT,
            payload: lobbyListItem || {},
        });
    }

    isTemplate(): Observable<boolean> {
        return this.selectedProject().pipe(map((project) => !!project.belongsToTemplateId));
    }

    protected dispatchSelectEntity(project: Ruum) {
        this.store.dispatch(this.getAction(PROJECT_WEB_APP_ACTION_TYPES.SELECT_PROJECT, project, {}));
    }

    private async getData(projectId: string) {
        const [project, lobbyListItem, fileUploadOptions] = await Promise.all([
            this.projectServiceConnector.getRuum(projectId),
            this.getLobbyListItem(projectId),
            this.projectServiceConnector.getEnterpriseFileuploadoptions(projectId),
        ]);
        return { project, lobbyListItem, fileUploadOptions };
    }

    private getLobbyListItem(projectId: string) {
        return (
            this.readModelBackendConnector
                .getLobbyListItemProject(projectId)
                .toPromise()
                /** If it is a template there will be no LobbyListItem */
                .catch((err) => undefined)
        );
    }

    protected reload(projectId: string) {
        this.selectProject(projectId).catch((err) => {
            this.router.navigateByUrl('/home');
        });
        this.customFieldValuesService.reload();
    }

    unselectProject() {
        this.selectedProjectGroup.unselectProjectGroup();
        this.stopListeningToChanges();
        /**
         * The canvas code rely on the selected project id going to undefined.
         *  LobbyListItem is not removed from the store so that the top navigation bar doesn't jump.
         */
        this.store.dispatch({ type: PROJECT_WEB_APP_ACTION_TYPES.SELECT_PROJECT, payload: getDefaultRuum() });
        this.helper.unselectProject();
    }

    persistAction<T extends ProjectAction>(type: T['type'], payload: T['payload']): Promise<any> {
        const projectGroupRole = this.helper.getProjectGroupRole();
        const workspaceRole = this.helper.getWorkspaceRole();
        const templateRole = this.helper.getTemplateRole();
        const role = this.getLoggedUserParticipant().roles[0];
        const roles: RoleInParentEntity = { projectGroupRole, workspaceRole, templateRole, role };
        return super.persistAction(type, payload, roles);
    }

    dispatchAction<T extends ProjectAction>(type: T['type'], payload: T['payload']): Promise<any> {
        const projectGroupRole = this.helper.getProjectGroupRole();
        const workspaceRole = this.helper.getWorkspaceRole();
        const templateRole = this.helper.getTemplateRole();
        const roles: RoleInParentEntity = { projectGroupRole, workspaceRole, templateRole };
        return super.dispatchAction(type, payload, roles);
    }

    removedLoggeduserFromProject() {
        this.commonService.setBusy(true);
        const id = this.authService.getLoggedUser().id;
        /** this call might fail, e.g. if the user is the last admin */
        return this.persistAction(RuumActionTypes.DELETE_PARTICIPANT, { id })
            .then(() => {
                this.unselectProject();
            })
            .then(() => wait(500))
            .then(() => {
                this.router.navigateByUrl('/home/ruums');
                this.commonService.setBusy(false);
            })
            .catch((err) => {
                this.commonService.setBusy(false);
                throw err;
            });
    }

    lobbyListItem(): Observable<LobbyListItemProject> {
        return this.store.select('selectedLobbyListItemProject');
    }

    addSectionRole(sectionId: string, roleId: string) {
        this.persistAction(RuumActionTypes.ADD_SECTION_ROLE, {
            sectionId,
            roleId,
        });
    }

    deleteSectionRole(sectionId: string, roleId: string) {
        this.persistAction(RuumActionTypes.REMOVE_SECTION_ROLE, {
            sectionId,
            roleId,
        });
    }

    updateSectionRole(sectionId: string, roleId: string, newRoleId: string) {
        this.persistAction(RuumActionTypes.UPDATE_SECTION_ROLE, {
            sectionId,
            roleId,
            newRoleId,
        });
    }

    /** It means going to the lobby, not removing the user from the project. */
    leaveProject() {
        const selectedProjectId = this.getSelectedProject().id;
        if (!selectedProjectId) {
            return;
        }
        return this.projectServiceConnector
            .addActionToRuum(selectedProjectId, RuumActionTypes.LEAVE_RUUM, {})
            .catch((err) => {});
    }

    /*****************************************************/

    /**
     * This part of the code are helpers to the selected state in the store.
     */

    getSelectedProjectId(): string {
        return this.getSeletedEntityId();
    }

    getSelectedProject(): Ruum {
        let project: Ruum;
        this.selectedProject()
            .pipe(take(1))
            .subscribe((r) => (project = r));
        return project;
    }

    getEnterpriseFileUploadOptions(): Observable<FileUploadOptions> {
        return this.helper.getEnterpriseFileUploadOptions();
    }

    selectedProject(): Observable<Ruum> {
        return this.selectedProjectWrapper().pipe(map((wrapper) => wrapper.project));
    }

    participants(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.helper.participants();
    }

    technicalUsers(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.helper.technicalUsers();
    }

    loggedUserParticipant(): Observable<User> {
        return this.helper.loggedUserParticipant();
    }

    getLoggedUserParticipant(): User {
        return this.helper.getLoggedUserParticipant();
    }

    getLoggedUserRole(): ProjectParticipantRole {
        const participant = this.getLoggedUserParticipant();
        return <ProjectParticipantRole>(participant && participant.roles[0]);
    }

    groupMembers(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.helper.groupMembers();
    }

    workspaceMembers(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.helper.workspaceMembers();
    }

    usersProjectAccess(userIds: string[] = []): Observable<ProjectParticipantWithAllRoles[]> {
        return combineLatest([
            this.helper.participants(),
            this.helper.groupMembers(),
            this.helper.workspaceMembers(),
        ]).pipe(map((lists) => flattenUniq(lists).filter((item) => userIds.includes(item.id))));
    }

    isProjectAdmin(): Observable<boolean> {
        return this.loggedUserParticipant().pipe(map((p) => p.roles.indexOf('ProjectAdmin') !== -1));
    }

    getIsProjectAdmin(): boolean {
        let isProjectAdmin: boolean;
        this.loggedUserParticipant()
            .pipe(
                map((p) => p.roles.indexOf('ProjectAdmin') !== -1),
                take(1),
            )
            .subscribe((r) => (isProjectAdmin = r));

        return isProjectAdmin;
    }

    isProjectEditor(): Observable<boolean> {
        return this.loggedUserParticipant().pipe(map((p) => p.roles.indexOf('ProjectEditor') !== -1));
    }

    isReadOnly(): Observable<boolean> {
        return this.loggedUserParticipant().pipe(map((p) => p.roles.indexOf('ProjectViewer') !== -1));
    }

    getIsReadOnly(): boolean {
        let isReadOnly: boolean;
        this.isReadOnly().subscribe((r) => (isReadOnly = r));
        return isReadOnly;
    }

    projectCreator(): Observable<UserListItem> {
        return this.selectedProject().pipe(
            map((project) => project.createdBy),
            distinctUntilChanged(),
            map((userId) => this.involvedUser(userId)),
            switchAll(),
        );
    }

    areInvitesBlocked(): Observable<boolean> {
        return this.selectedProject().pipe(
            map((project) => {
                if (project.configuration) {
                    return project.configuration.blockInvites;
                } else {
                    return false;
                }
            }),
        );
    }

    currentUserCanInviteNewParticipats$(): Observable<boolean> {
        return combineLatest([this.loggedUserParticipant(), this.areInvitesBlocked()]).pipe(
            map(([participant, invitesAreBlocked]) => {
                if (invitesAreBlocked) {
                    return participant.roles[0] === 'ProjectAdmin';
                } else {
                    return participant.roles[0] !== 'ProjectViewer';
                }
            }),
        );
    }

    blameMap(): Observable<BlameMap> {
        return this.selectedProjectWrapper().pipe(map((wrapper) => wrapper.blameMap));
    }

    isBeingBlamed(userId: string): Observable<boolean> {
        return this.blameMap().pipe(map((user) => !!user[userId]));
    }

    selectedProjectWrapper(): Observable<SelectedProjectWrapper> {
        return this.store.select('selectedProject');
    }

    involvedUser(userId: string): Observable<UserListItem> {
        return this.involvedUsersService.data(userId);
    }

    involvedUsers(userIds: string[]): Observable<UserListItem[]> {
        return this.involvedUsersService.dataList(userIds);
    }

    allInvolvedUsers(): Observable<UserListItem[]> {
        return this.involvedUsersService.allInvolvedUsers();
    }

    loadGroupMembers(projectId: string) {
        this.helper.loadGroupMembers(projectId);
    }

    loadWSMembers(projectId: string) {
        this.helper.loadWSMembers(projectId);
    }

    isProcessEnabled(): Observable<boolean> {
        return this.selectedProject().pipe(map((project) => project.type === 'process'));
    }
}

export interface ProjectSelectData {
    project: Ruum;
    /** Will not be available if the Project is a template. */
    lobbyListItem?: LobbyListItemProject;
}

export function collapseSectionFromProjectOnCondition(baseProject: Ruum, maxSectionsNumber = 7): Ruum {
    const participantsUIState = baseProject.uiState.participantsUIState;
    const participants = Object.keys(participantsUIState);

    const newParticipantsUIState = participants.reduce(
        (newUIState, nextParticipant) => {
            const participantSectionState = newUIState[nextParticipant].sectionsState;
            const newSectionState =
                participantSectionState.length > maxSectionsNumber
                    ? participantSectionState.map((state) => ({ ...state, collapsed: true }))
                    : participantSectionState;
            newUIState[nextParticipant] = {
                ...newUIState[nextParticipant],
                sectionsState: [...newSectionState],
            };
            return newUIState;
        },
        { ...participantsUIState },
    );

    return {
        ...baseProject,
        uiState: {
            ...baseProject.uiState,
            participantsUIState: newParticipantsUIState,
        },
    };
}
