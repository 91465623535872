import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TaskPriority, TaskStatus } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { ACTION_TYPES } from '../../common/actions';
import { AppState } from '../../common/app.store';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { ProjectServiceBackendConnector } from '../../common/connectors/projectServiceConnector.service';
import { ReadModelBackendConnector } from '../../common/connectors/readModelConnector.service';
import { TaskListItem } from '../../common/connectors/tasks/task-list.model';
import { KanbanListItem } from '../../common/kanban/kanban.model';
import { ServiceHelper } from '../../common/serviceHelper';
import { TrackingConnector } from '../../common/trackingConnector.service';
import { CanvasBackendConnector } from '../../ruum/canvas/canvas.backendConnector';
import { RuumTasksService } from '../../ruum/tasks/tasks.service';
import { ProjectTaskDetailsService } from '../shared/projectTaskDetails.service';
import { KanbanGroupTaskConnector } from './kanban-group-task-connector';

export type GroupPriorityType = TaskPriority | 'NO_PRIORITY';
type KanbanGroupType = TaskStatus | GroupPriorityType;

export type StackBy = 'status' | 'priority';

@Injectable({ providedIn: 'root' })
export class KanbanTaskService {
    private kanbanGroupMap: Map<KanbanGroupType, KanbanGroupTaskConnector> = new Map();
    private projectId$ = new BehaviorSubject<string>('');
    readonly createdBy$ = new BehaviorSubject<string>(undefined);
    readonly assignedMemberIds$ = new BehaviorSubject<string[]>([]);
    readonly assignedRoleIds$ = new BehaviorSubject<string[]>([]);
    readonly customFields$ = new BehaviorSubject<{ [key: string]: string }>({});

    private subscriptions = new Subscription();

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private alertService: RuumAlertService,
        private trackingConnector: TrackingConnector,
        private projectServiceConnector: ProjectServiceBackendConnector,
        private projectTaskDetailsService: ProjectTaskDetailsService,
        private canvasConnector: CanvasBackendConnector,
        private serviceHelper: ServiceHelper,
        private taskService: RuumTasksService,
        private store: Store<AppState>,
        private authService: AuthService,
    ) {}

    createList(groupId: KanbanGroupType): KanbanGroupTaskConnector {
        if (this.kanbanGroupMap.has(groupId)) {
            return this.kanbanGroupMap.get(groupId);
        }
        const tasklistConnector = new KanbanGroupTaskConnector(
            this.readModelConnector,
            this.alertService,
            this.projectServiceConnector,
            this.canvasConnector,
            this.trackingConnector,
            this.authService,
            this.store,
            groupId,
        );
        this.kanbanGroupMap.set(groupId, tasklistConnector);
        this.setUpObservables(tasklistConnector, groupId);
        return tasklistConnector;
    }

    filterByProjectId(projectId: string) {
        this.projectId$.next(projectId);
    }

    filterByCreatedBy(createdBy: string) {
        this.createdBy$.next(createdBy);
    }

    filterByAssignedMembers(ids: string[]) {
        this.assignedMemberIds$.next(ids);
    }

    filterByAssignedRoles(ids: string[]) {
        this.assignedRoleIds$.next(ids);
    }

    filterByCustomFields(customFields: { [key: string]: string }) {
        this.customFields$.next(customFields);
    }

    resetFilters() {
        this.projectId$.next(undefined);
        this.createdBy$.next(undefined);
        this.assignedMemberIds$.next([]);
        this.assignedRoleIds$.next([]);
        this.customFields$.next({});
    }

    loadAllTasks() {
        this.kanbanGroupMap.forEach((connector) => connector.loadList());
    }

    stopAllLoading() {
        this.kanbanGroupMap.forEach((connector) => connector.stopLoadingList());
    }

    reloadAllTask() {
        this.kanbanGroupMap.forEach((connector) => connector.reload());
    }

    loadMore(groupId: KanbanGroupType) {
        const tasklistConnector = this.kanbanGroupMap.get(groupId);
        tasklistConnector.maybeGoToNextPage();
    }

    updateTaskStatus(task: TaskListItem, status: TaskStatus, projectId: string, kanbanBeforeElement?: string) {
        const taskId = task.id;
        this.taskService.setStatus(taskId, status, kanbanBeforeElement);
    }

    updateTaskPriority(task: TaskListItem, priority: TaskPriority, projectId: string, kanbanBeforeElement?: string) {
        const taskId = task.id;
        this.taskService.setPriority(taskId, priority, kanbanBeforeElement);
    }

    kanbanGroupList(groupdId: KanbanGroupType): Observable<TaskListItem[]> {
        const store$ = this.kanbanList();
        const list$ = of(groupdId);
        return combineLatest([store$, list$]).pipe(
            map(([item, group]) => {
                return item[group];
            }),
            filter<PaginatedList<string>>(Boolean),
            switchMap((plist) => this.taskMapper(plist.rows)),
        );
    }

    createTask(projectId: string, sectionId: string, description: string, groupId: KanbanGroupType, payload) {
        return this.kanbanGroupMap.get(groupId).createTask(projectId, sectionId, description, payload);
    }

    deleteTask(taskId: string, projectId: string, parentId: string) {
        this.projectTaskDetailsService.deleteTask(projectId, taskId, parentId);
    }

    moveKanbanCard(taskId: string, projectId: string, kanbanBeforeElement: string, stackBy: StackBy) {
        return this.taskService.moveKanbanCard(taskId, projectId, stackBy, kanbanBeforeElement);
    }

    kanbanGroupListSize(groupdId: KanbanGroupType): Observable<number> {
        return this.kanbanList().pipe(
            map((item) => item[groupdId]),
            distinctUntilChanged(),
            filter(Boolean),
            map<PaginatedList<string>, number>((plist) => plist.totalItems),
        );
    }

    kanbanTotalItems() {
        return this.kanbanList().pipe(
            map((kanbanList) => {
                return Object.keys(kanbanList).reduce((totalItems, key) => {
                    return totalItems + kanbanList[key].totalItems;
                }, 0);
            }),
            distinctUntilChanged(),
        );
    }

    clearKanbanList() {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.kanbanGroupMap.clear();
        return this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.KANBAN_CLEAR, {});
    }

    /**
     * empty will clear the list but not remove the subscriptions
     */
    emptyAllLists() {
        return this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.KANBAN_EMPTY, {});
    }

    private taskMapper(list: string[]): Observable<TaskListItem[]> {
        const store$ = this.store.select('task');
        const list$ = of(list);
        return combineLatest([store$, list$]).pipe(
            map(([taskMap, tasklist]) => {
                return tasklist.map((id) => taskMap[id]).filter(Boolean);
            }),
        );
    }

    private setUpObservables(tasklistConnector: KanbanGroupTaskConnector, groupId: KanbanGroupType) {
        this.subscriptions.add(
            this.projectId$.pipe(filter<string>(Boolean)).subscribe((projectId) => {
                tasklistConnector.filterByProjectId(projectId);
            }),
        );
        this.subscriptions.add(
            this.createdBy$.pipe(filter<string>(Boolean)).subscribe((createdBy) => {
                tasklistConnector.filterByCreatedBy(createdBy);
            }),
        );
        this.subscriptions.add(
            this.assignedMemberIds$.pipe(filter<string[]>(Boolean)).subscribe((ids) => {
                tasklistConnector.filterByAssignedMembers(ids);
            }),
        );
        this.subscriptions.add(
            this.assignedRoleIds$.pipe(filter<string[]>(Boolean)).subscribe((ids) => {
                tasklistConnector.filterByAssignedRoles(ids);
            }),
        );
        this.subscriptions.add(
            this.customFields$.pipe(filter<{ [key: string]: string }>(Boolean)).subscribe((customFields) => {
                tasklistConnector.filterByCustomFields(customFields);
            }),
        );

        this.subscriptions.add(
            tasklistConnector.getListObservable().subscribe((page) => {
                this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.KANBAN_LOADED, {
                    kanbanGroupId: groupId,
                    list: page,
                });
            }),
        );
    }

    private kanbanList(): Observable<KanbanListItem> {
        return this.store.select('kanban').pipe(distinctUntilChanged());
    }
}
