import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { ODataListResponse, ODataRequestParams, ODataResponse } from '../../common/odata/odata.model';
import { convertODataResponseToPaginatedList } from '../../common/odata/ODataPaginatedListLoader';

export class ODataPaginationParams {
    skip: number;
    top: number;
    constructor(skip: number, top: number) {
        this.skip = skip;
        this.top = top;
    }
}
@Injectable()
export class ODataConnector {
    private PROJECT_SERVICE_URL: string = environment.PROJECT_SERVICE_URL;

    constructor(private http: HttpClient) {}

    getOne<T>(params: ODataRequestParams): Observable<T> {
        const url = this.buildODataUrl(params);

        return this.http
            .get<ODataResponse>(url, { withCredentials: true })
            .pipe(map((odataResponse) => odataResponse as T))
            .pipe(catchError(this.handleError.bind(this)));
    }

    getList<T>(params: ODataRequestParams): Observable<PaginatedList<T>> {
        const url = this.buildODataUrl(params);

        return this.http
            .get<ODataListResponse>(url, { withCredentials: true })
            .pipe(
                map((odataResponse) => {
                    let skip = 0;
                    let top = 0;
                    if (params.query) {
                        skip = params.query.getSkip();
                        top = params.query.getTop();
                    }
                    return convertODataResponseToPaginatedList<T>(new ODataPaginationParams(skip, top), odataResponse);
                }),
            )
            .pipe(catchError(this.handleError.bind(this)));
    }

    create<T>(params: ODataRequestParams, data: T): Observable<T> {
        const url = this.buildODataUrl(params);
        return this.http.post<T>(url, data, { withCredentials: true });
    }

    patch<T>(params: ODataRequestParams, data: T): Observable<T> {
        const url = this.buildODataUrl(params);
        return this.http.patch<T>(url, data, { withCredentials: true });
    }

    delete<T>(params: ODataRequestParams): Observable<T> {
        const url = this.buildODataUrl(params);
        return this.http.delete<T>(url, { withCredentials: true });
    }

    private buildODataUrl(params: ODataRequestParams): string {
        const query = params.query ? params.query.toQuery() : '';
        return `${this.PROJECT_SERVICE_URL}/v1/odata/${params.serviceRoot}/${params.path}${query}`;
    }

    /** GENERAL */
    handleError(error: any) {
        // TODO: Dialog?
        console.error(error);

        // this.redirectToLogin();
    }
}
