import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { TableDirectiveService } from './table.directive.service';

@Directive({
    selector: '[ruumHoveredRowTracked]',
})
export class HoveredRowTrackedDirective implements OnDestroy {
    @Input()
    get rowIndex(): number {
        return this._rowIndex$.getValue();
    }
    set rowIndex(value: number) {
        if (value !== this.rowIndex) {
            this._rowIndex$.next(value);
        }
    }

    private _rowIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private subscriptions: Subscription[] = [];

    constructor(private element: ElementRef, private tableDirectiveService: TableDirectiveService) {
        this.initHoverEvent();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    initHoverEvent(): void {
        const rowMouseEnters = fromEvent(this.element.nativeElement, 'mouseenter');
        const rowMouseLeaves = fromEvent(this.element.nativeElement, 'mouseleave');

        const mouseEntersSubscription = rowMouseEnters.subscribe(() => {
            this.tableDirectiveService.setHoveredRowIndex(this.rowIndex);

            const mouseLeavesSubscription = rowMouseLeaves.subscribe(() => {
                this.tableDirectiveService.setHoveredRowIndex(null);
            });

            this.subscriptions.push(mouseLeavesSubscription);
        });

        this.subscriptions.push(mouseEntersSubscription);
    }
}
