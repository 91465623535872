import { ActionReducer, MetaReducer } from '@ngrx/store';
import { RuumAction } from '@ruum/ruum-reducers';
import { TechnicalUserListReducer } from '../admin/technical-users/technical-user-list.reducer';
import { EnterpriseParticipantListReducer } from '../admin/user-list/enterprise-participant-list.reducer';
import { WebhookListReducer } from '../admin/webhooks/webhook-list.reducer';
import { ProjectGroupReducerWrapper } from '../lobby/project-group/projectGroupWrapper.reducer';
import { SelectedTaskReducer } from '../lobby/shared/task-details/selectedTask.reducer';
import { RuumReducerWrapper } from '../project/reducerWrapper/ruumReducerWrapper';
import {
    SelectedLobbyListItemPGReducer,
    SelectedLobbyListItemProjectReducer,
} from '../project/selectedLobbyListItem.reducer';
import { LobbyReducer } from './../lobby/lobby.reducer';
import { RuumMailCounterReducer, RuumMailReducer } from './../ruum/mail/unassigned-mail/mails.reducer';
import { UserProfileReducer } from './../settings/profile.reducer';
import { AppState } from './app.store';
import { CommonStateReducer } from './common.reducer';
import { ActivityHistoryListReducer } from './connectors/activity/activity.reducer';
import { ApprovalListWrapperReducer } from './connectors/approvals/approval-list-wrapper.reducer';
import { AuthenticationReducer } from './connectors/auth/authentication.reducer';
import { ChatsReducer } from './connectors/chats/chats-list.reducer';
import { CommentableWebAppReducer } from './connectors/commentable/commentable.reducer';
import { CustomFieldsReducer } from './connectors/customFields/customFields.reducer';
import { SelectedEnterpriseReducer } from './connectors/enterprise/selected-enterprise.reducer';
import { ExternalSystemListReducer } from './connectors/externalSystem/external-system-list.reducer';
import { FunctionRolesWebAppReducer } from './connectors/functionalRoles/functionalRoles.reducer';
import { ProjectCustomFieldsValuesReducer } from './connectors/project/customFieldsValues/customFieldsValues.reducer';
import { SavedViewsReducer } from './connectors/savedView/saved-views.reducer';
import { SystemConnectorListReducer } from './connectors/system-connectors/system-connectors-list.reducer';
import { TableDataRecordWebAppReducer } from './connectors/tableData/tableDataRecords.reducer';
import { TableDefinitionListReducer } from './connectors/tableDefinition/table-definition-list.reducer';
import { TaskListWrapperReducer } from './connectors/tasks/task-list-wrapper.reducer';
import { SelectedTemplateReducer } from './connectors/templates/selectedTemplate.reducer';
import { TemplateListReducer } from './connectors/templates/template-list.reducer';
import { UsersReducer } from './connectors/users/users.reducer';
import { CustomFieldListReducer } from './connectors/workspace/customFieldsList/customFieldsList.reducer';
import { FunctionRolesListReducer } from './connectors/workspace/functionalRolesList/functionalRolesList.reducer';
import { SelectedWorkspaceReducer } from './connectors/workspace/selected-workspace.reducer';
import { WorkspaceListItemReducer } from './connectors/workspace/workspace-list-item.reducer';
import { WorkspaceListReducer } from './connectors/workspace/workspace-list.reducer';
import { FocusListReducer } from './focus/focus.reducer';
import { KanbanListReducer } from './kanban/kanban.reducer';
import { StoreExceptionCatcher } from './storeExceptionCatcher.service';
import { TaskReducer } from './task/task.reducer';

export const reducers = {
    lobby: LobbyReducer,
    task: TaskReducer,
    taskListWrapper: TaskListWrapperReducer,
    kanban: KanbanListReducer,
    selectedProject: RuumReducerWrapper,
    selectedLobbyListItemProject: SelectedLobbyListItemProjectReducer,
    selectedProjectGroup: ProjectGroupReducerWrapper,
    selectedLobbyListItemProjectGroup: SelectedLobbyListItemPGReducer,
    selectedWorkspace: SelectedWorkspaceReducer,
    selectedWorkspaceListItem: WorkspaceListItemReducer,
    selectedTask: SelectedTaskReducer,
    selectedEnterprise: SelectedEnterpriseReducer,
    common: CommonStateReducer,
    userProfile: UserProfileReducer,
    unassignedMails: RuumMailReducer,
    unassignedMailsCounter: RuumMailCounterReducer,
    authentication: AuthenticationReducer,
    chats: ChatsReducer,
    users: UsersReducer,
    customFields: CustomFieldsReducer,
    customFieldsList: CustomFieldListReducer,
    systemConnectorList: SystemConnectorListReducer,
    activityHistory: ActivityHistoryListReducer,
    focusState: FocusListReducer,
    externalSystemList: ExternalSystemListReducer,
    projectCustomFieldsValues: ProjectCustomFieldsValuesReducer,
    commentable: CommentableWebAppReducer,
    workspaceList: WorkspaceListReducer,
    templateList: TemplateListReducer,
    // TODO: Move to inside enterprise once ruum-reducers are ready
    webhookList: WebhookListReducer,
    selectedTemplate: SelectedTemplateReducer,
    savedViews: SavedViewsReducer,
    functionalRoles: FunctionRolesWebAppReducer,
    functionalRolesList: FunctionRolesListReducer,
    tableDefinitionList: TableDefinitionListReducer,
    enterpriseTechnicalUsersList: TechnicalUserListReducer,
    enterpriseParticipantsList: EnterpriseParticipantListReducer,
    tableDataRecords: TableDataRecordWebAppReducer,
    approvalListWrapper: ApprovalListWrapperReducer,
};

export function getMetaReducers(storeExceptionCatcher: StoreExceptionCatcher): MetaReducer<any>[] {
    /**
     * Guarantees store will still be in a consistent state if reducers throw an exception.
     * Notifies StoreExceptionCatcher of the result of dispatching a given action.
     */
    function exceptionCatcher(reducer: ActionReducer<any>): ActionReducer<any> {
        return (state: AppState, action: RuumAction): AppState => {
            try {
                state = reducer(state, action);
                storeExceptionCatcher.sendResult(action);
            } catch (err) {
                storeExceptionCatcher.sendResult(action, err);
            }
            return state;
        };
    }

    return [exceptionCatcher];
}
