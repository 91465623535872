import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-select-template-card',
    template: `
        <div class="d-flex flex-fill minw-0 p-2">
            <div class="d-flex pr-3">
                <ruum-profile-template
                    [theme]="theme | ruumTemplateTheme"
                    [icon]="icon | ruumTemplateIcon"
                ></ruum-profile-template>
            </div>
            <div class="d-flex flex-fill flex-column minw-0">
                <div class="d-flex flex-fill minw-0">
                    <div class="d-flex flex-fill flex-column justify-content-center minw-0">
                        <div class="d-flex flex-fill1 align-items-center minw-0">
                            <div class="d-flex flex-fill minw-0">
                                <span class="text-small text-truncate font-weight-bold" [title]="name">{{ name }}</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center minw-0">
                            <span class="text-small text-dark text-truncate"
                                >{{ categoryId | ruumTemplateCreatedBy }} {{ creator }}</span
                            >
                            <span class="text-small text-dark px-1" *ngIf="workspaceId">•</span>
                            <span class="text-small text-dark text-truncate">{{ workspaceName }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <button class="btn btn-sm btn-outline-primary m-1" [class.hov-child]="hover">
                    <span>{{ type === 'ruum' ? 'Create Ruum' : 'Use Section' }}</span>
                </button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTemplateCardComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            this.getDefaultClassName(),
            this.getHoverClassName(this.hover),
            this.getActiveClassName(this.active),
            componentHelper.getCursorClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @Input() type = 'ruum';
    @Input() icon: string;
    @Input() name: string;
    @Input() theme: string;
    @Input() creator: string;
    @Input() categoryId: string;
    @Input() workspaceId: string;
    @Input() workspaceName: string;
    @Input() isAdmin: boolean;
    @Input() isEditor: boolean;
    @Input() isViewer: boolean;

    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass: string;

    constructor() {}

    ngOnInit() {}

    private getDefaultClassName(): string {
        return 'd-flex hov border border-light bg-white rounded';
    }

    private getHoverClassName(hover: boolean): string {
        return hover ? 'hov-bg-primary-light hov-border-transparent' : '';
    }

    private getActiveClassName(active: boolean): string {
        return active ? 'hov-active' : '';
    }
}
