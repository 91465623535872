import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserListItem } from '../../../app/common/connectors/readModelConnector.service';

@Component({
    selector: 'participant-initials',
    template: `
        <ruum-profile-member
            *ngIf="participant?.color"
            [name]="participant?.fullName"
            [theme]="getColor() | ruumProfileHexToTheme"
        >
        </ruum-profile-member>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantInitialsComponent {
    @Input() participant: UserListItem;
    /**
     * Indicates to not use the user's color.
     */
    @Input() noColor = false;
    /**
     * Hide background colour.
     */
    @Input() noBackground = false;

    NO_COLOR_VALUE = '#d3d3d3';

    getColor() {
        return this.noColor ? this.NO_COLOR_VALUE : this.participant && this.participant.color;
    }
}
