import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';

@Injectable({ providedIn: 'root' })
export class SelectDefaultEnterpriseGuard implements CanDeactivate<any> {
    constructor(private selectedEnterpriseService: SelectedEnterpriseService) {}

    canDeactivate(): boolean {
        this.selectedEnterpriseService.unselectEnterprise();
        return true;
    }
}
