import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { RuumDropdownDesktopComponent } from './dropdown-desktop.component';
import { RuumDropdownMobileComponent } from './dropdown-mobile.component';
import {
    isUnselectDropdownItem,
    RuumDropdownItem,
    RuumDropdownList,
    RuumDropdownPlacement,
    RuumDropdownSections,
} from './dropdown.model';

@Component({
    selector: 'ruum-dropdown',
    template: `
        <ruum-dropdown-wrapper [class.cursor-not-allowed]="isReadOnly">
            <ruum-dropdown-desktop
                #desktopDropdown
                [placement]="placement"
                [autoClose]="autoClose"
                [container]="container"
                [stopPropagation]="stopPropagation"
                [isReadOnly]="isReadOnly"
                (openMenu)="openMenu.emit($event)"
                (closeMenu)="closeMenu.emit($event)"
            >
                <!-- button -->
                <div
                    class="d-flex flex-fill minw-0"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                >
                    <ng-content select="[data-content='ruum-dropdown-button-desktop']"></ng-content>
                </div>
                <!-- menu -->
                <ruum-dropdown-section-list
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                    [style.width]="width"
                    [selectedItems]="selectedItems"
                    [sectionList]="sections"
                    [isMultiSelect]="isMultiSelect"
                    (selectItem)="onSelect($event, desktopDropdown)"
                    (selectItems)="onMultiSelect($event)"
                >
                </ruum-dropdown-section-list>
            </ruum-dropdown-desktop>

            <ruum-dropdown-mobile
                #mobileDropdown
                [showCancelButton]="showCancelButton"
                [showApplyButton]="showApplyButton"
                [stopPropagation]="stopPropagation"
                [isReadOnly]="isReadOnly"
                (openMenu)="openMenu.emit($event)"
                (closeMenu)="closeMenu.emit($event)"
            >
                <!-- button -->
                <div class="d-flex minw-0 w-100" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                    <ng-content select="[data-content='ruum-dropdown-button-mobile']"></ng-content>
                </div>
                <!-- menu -->
                <ruum-dropdown-section-list
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                    [selectedItems]="selectedItems"
                    [sectionList]="sections"
                    [isMultiSelect]="isMultiSelect"
                    [size]="'lg'"
                    (selectItem)="onSelect($event, mobileDropdown)"
                    (selectItems)="onMultiSelect($event)"
                >
                </ruum-dropdown-section-list>
            </ruum-dropdown-mobile>
        </ruum-dropdown-wrapper>
    `,
    styles: [
        `
            .read-only {
                pointer-events: none;
                cursor: default;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumDropdownComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex minw-0';

    @Input()
    class = 'd-flex';

    @HostBinding('class') get hostClasses(): string {
        return this.class;
    }

    @Input()
    placement: RuumDropdownPlacement = ['bottom-right', 'top-right', 'auto'];

    @Input()
    autoClose: boolean | 'inside' | 'outside' = 'outside';

    @Input()
    container = 'body';

    @Input()
    stopPropagation = true;

    @Input()
    showCancelButton = true;

    @Input()
    showApplyButton = false;

    @Input()
    isReadOnly = false;

    @Input()
    sections: RuumDropdownSections = [];

    @Input()
    selectedItems: RuumDropdownList = [];

    @Input()
    width = '';

    @Input() isMultiSelect = false;

    @Output()
    selectItem = new EventEmitter<RuumDropdownItem>();

    @Output()
    selectItems = new EventEmitter<RuumDropdownItem[]>();

    @Output()
    unselectItem = new EventEmitter<void>();

    @Output()
    openMenu = new EventEmitter();

    @Output()
    closeMenu = new EventEmitter();

    ngOnInit() {}

    onSelect(item: RuumDropdownItem, dropdown: RuumDropdownDesktopComponent | RuumDropdownMobileComponent) {
        dropdown.close();
        if (isUnselectDropdownItem(item)) {
            this.selectedItems = [];
            this.unselectItem.emit();
        } else {
            this.selectItem.emit(item);
        }
    }

    onMultiSelect(items: RuumDropdownItem[]) {
        if (items.length === 0) {
            this.selectedItems = [];
            this.unselectItem.emit();
        } else {
            this.selectedItems = items;
            this.selectItems.emit(items);
        }
    }
}
