import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SelectSearchComponent } from '../select-search/select-search.component';
import { RuumSelectData } from '../select.model';

@Component({
    selector: 'ruum-select-tag-list',
    template: `
        <div class="d-flex flex-fill flex-wrap minw-0">
            <ng-container *ngFor="let selectedOption of selectedOptions; let last = last">
                <ruum-profile-tag
                    *ngIf="selectedOption.type === 'member'"
                    [componentClass]="'my-1 mx-1'"
                    (click)="removeOptionClick(selectedOption.value)"
                >
                    <ruum-profile-member
                        [name]="selectedOption.content"
                        [theme]="selectedOption.theme"
                        [size]="'sm'"
                    ></ruum-profile-member>
                </ruum-profile-tag>

                <ruum-profile-tag
                    *ngIf="selectedOption.type === 'role'"
                    [componentClass]="'my-1 mx-1'"
                    (click)="removeOptionClick(selectedOption.value)"
                >
                    <ruum-profile-role [name]="selectedOption.content" [size]="'sm'"></ruum-profile-role>
                </ruum-profile-tag>

                <ruum-tag
                    *ngIf="selectedOption.type === 'default'"
                    [name]="selectedOption.content"
                    [hover]="true"
                    [showCancel]="true"
                    [componentClass]="'my-1 mx-1'"
                    (click)="removeOptionClick(selectedOption.value)"
                ></ruum-tag>
            </ng-container>
            <ng-content select="ruum-select-search"></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTagListComponent implements OnInit {
    @ContentChild(SelectSearchComponent, { static: false }) selectSearchComponent: SelectSearchComponent;
    @HostBinding('class.d-flex')
    @HostBinding('class.flex-fill')
    hostClassList = true;

    @HostBinding('class.m-1')
    get margin() {
        return this.selectedOptions.length !== 0 || !!this.selectSearchComponent;
    }

    @Input() selectedOptions: RuumSelectData[] = [];

    @Output() removeOption = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    removeOptionClick(value: string) {
        this.removeOption.next(value);
    }
}
