import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    interval as observableInterval,
    Observable,
    timer as observableTimer,
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    scan,
    switchAll,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { LOBBY_LIST_ACTIONS } from '../../../lobby/shared/lobby-list/lobby-list.actions';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { CommonService } from '../../common.service';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { DEFAULT_BACK_OFF, MAXIMUM_WAIT, PaginatedListLoader } from '../paginatedListLoader';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { LobbyListFilters, LobbyListItem, LobbyListOrderBy, LobbyListType } from './lobby-list.model';

const initializeOrder = () => {
    return {
        by: (sessionStorage.getItem('ruumSortBy') as LobbyListOrderBy) || 'changedAt',
        direction: (sessionStorage.getItem('ruumSortDirection') as SortDirection) || 'desc',
    };
};

@Injectable({ providedIn: 'root' })
export class LobbyListService extends PaginatedListLoader<LobbyListItem, LobbyListFilters, LobbyListOrderBy> {
    private nameFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private status$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private tags$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private listType$: BehaviorSubject<LobbyListType> = new BehaviorSubject<LobbyListType>('projects_and_groups');
    private archived$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private groupId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private customFields$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private createdBy$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private admin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
    private orderBy$: BehaviorSubject<OrderedListParams<LobbyListOrderBy>> = new BehaviorSubject<
        OrderedListParams<LobbyListOrderBy>
    >(initializeOrder());
    private lobbyChangeNotifier$: BehaviorSubject<PaginatedList<LobbyListItem>> = new BehaviorSubject(undefined);

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private serviceHelper: ServiceHelper,
        private readModelConnector: ReadModelBackendConnector,
        private commonService: CommonService,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.setUpObservables();
    }

    protected getOrderBy$() {
        return this.orderBy$;
    }

    protected getData(
        page: number,
        filters: LobbyListFilters,
        orderBy: OrderedListParams<LobbyListOrderBy>,
    ): Observable<PaginatedList<LobbyListItem>> {
        return this.readModelConnector.getLobbyList(filters, orderBy, page);
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page) => {
            this.serviceHelper.dispatchWithoutPersisting(LOBBY_LIST_ACTIONS.LOBBY_LIST_LOADED, { list: page });
            // lobby list component needs >25 elements to make trigger the scroll event
            if (page.currentPage === 1) {
                this.maybeGoToNextPage();
            }
            this.lobbyChangeNotifier$.next(page);
        });

        this.getTagsObservable().subscribe((tags) => {
            this.serviceHelper.dispatchWithoutPersisting(LOBBY_LIST_ACTIONS.LOBBY_LIST_TAGS_LOADED, { tags });
        });

        this.loadIndicatorObs().subscribe((loading) => {
            this.commonService.setBusy(loading);
        });
    }

    private getTagsObservable(): Observable<string[]> {
        let backOff: number = DEFAULT_BACK_OFF;
        return combineLatest([
            this.listType$.pipe(distinctUntilChanged()),
            this.groupId$.pipe(distinctUntilChanged()),
            this.shouldLoadList$.pipe(distinctUntilChanged()),
        ]).pipe(
            filter(([type, groupId, shouldLoad]) => shouldLoad),
            debounceTime(10),
            map(([type, groupId]) =>
                this.readModelConnector.getTags(type, groupId).pipe(tap(() => (backOff = DEFAULT_BACK_OFF))),
            ),
            switchAll(),
            catchError((error, caught) => {
                if (backOff < MAXIMUM_WAIT) {
                    backOff += backOff / 2;
                }
                return observableTimer(backOff).pipe(switchMap(() => caught));
            }),
        );
    }

    private loadIndicatorObs(): Observable<boolean> {
        return combineLatest([
            observableInterval(100),
            this.isLoadingFirstPage$.pipe(
                map((loading) => ({ loading, at: Date.now() })),
                /** if it is still loading use the time it first started to load */
                scan((before, now) => (now.loading && before.loading ? before : now)),
            ),
        ]).pipe(
            map(([_, loading]) => {
                /** Only show load indicator if API call is taking more than 200ms */
                return loading.loading && Date.now() - loading.at > 200;
            }),
            distinctUntilChanged(),
        );
    }

    protected getFilters$() {
        return combineLatest([
            this.tags$,
            this.status$,
            this.nameFilter$.pipe(debounceTime(250)),
            this.archived$,
            this.groupId$,
            this.admin$,
            this.workspaceId$,
            this.getSearchListType(),
            this.customFields$,
            this.createdBy$,
        ]).pipe(
            map(([tags, status, name, archived, groupId, admin, workspaceId, listType, customFields, createdBy]) => {
                const filters = {
                    archived,
                    name,
                    status,
                    tags,
                    groupId,
                    admin,
                    workspaceId,
                    listType,
                    customFields,
                    createdBy,
                };

                return filters as LobbyListFilters;
            }),
        );
    }

    filterByListType(type: LobbyListType) {
        this.listType$.next(type);
    }

    filterByStatus(statusList: string[]) {
        this.status$.next(statusList);
    }

    filterByTags(tags: string[]) {
        this.tags$.next(tags);
    }

    filterByGroupId(groupId: string) {
        this.groupId$.next(groupId);
    }

    getGroupId$(): Observable<string> {
        return this.groupId$.asObservable();
    }

    filterByWorkspaceId(workspaceId: string) {
        this.workspaceId$.next(workspaceId);
    }

    getWorkspaceId(): string {
        return this.workspaceId$.value;
    }

    getGroupId(): string {
        return this.groupId$.value;
    }

    getWorkspaceId$(): Observable<string> {
        return this.workspaceId$.asObservable();
    }

    getArchived$(): Observable<boolean> {
        return this.archived$.asObservable();
    }

    filterByCustomFields(customFields: { [key: string]: string }) {
        this.customFields$.next(JSON.stringify(customFields));
    }

    filterByCreatedBy(creator: string) {
        this.createdBy$.next(creator);
    }

    loadMore() {
        this.maybeGoToNextPage();
    }

    sortListBy(by: LobbyListOrderBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
        sessionStorage.setItem('ruumSortBy', by);
        sessionStorage.setItem('ruumSortDirection', direction);
    }

    orderBy(): Observable<OrderedListParams<LobbyListOrderBy>> {
        return this.orderBy$;
    }

    getOrderBy(): OrderedListParams<LobbyListOrderBy> {
        let orderBy: OrderedListParams<LobbyListOrderBy>;
        this.orderBy()
            .pipe(take(1))
            .subscribe((val) => (orderBy = val));
        return orderBy;
    }

    isLoadingAnotherPage(): Observable<boolean> {
        return this.isLoadingAnotherPage$;
    }

    loadList() {
        this.shouldLoadList$.next(true);
    }

    stopLoadingList() {
        this.shouldLoadList$.next(false);
    }

    showLoading(): Observable<boolean> {
        return combineLatest([this.hasMore$, this.isLoadingAnotherPage$]).pipe(
            map(([hasMore, isLoadingAnotherPage]) => hasMore || isLoadingAnotherPage),
        );
    }

    getlobbyChangeNotifier$(): BehaviorSubject<PaginatedList<LobbyListItem>> {
        return this.lobbyChangeNotifier$;
    }

    filterForName(name: string) {
        this.nameFilter$.next(name);
    }

    getNameFilter(): string {
        let str: string;
        this.nameFilter$.pipe(take(1)).subscribe((name) => (str = name));
        return str;
    }

    // TODO: Andrii: remove it
    filterForType(type: string, values: string[], isSelected: boolean) {}

    resetFilters() {
        this.status$.next([]);
        this.tags$.next([]);
        this.nameFilter$.next('');
        this.archived$.next(false);
        this.customFields$.next('');
        this.createdBy$.next('');
    }

    resetStatusFilter() {
        this.status$.next([]);
    }

    resetTagFilter() {
        this.tags$.next([]);
    }

    resetCreatorFilter() {
        this.createdBy$.next('');
    }

    getStoreRows$(): Observable<LobbyListItem[]> {
        return this.appStoreWrapper.lobbyList().pipe(map((ruumList) => ruumList.rows));
    }

    getListType(): Observable<LobbyListType> {
        return this.listType$;
    }

    /**
     * If the user is typing something in the search we should also show projects which are inside groups.
     */
    private getSearchListType(): Observable<LobbyListType> {
        return combineLatest([this.listType$, this.nameFilter$]).pipe(
            map(([listType, nameSearch]) => {
                if (listType === 'projects_and_groups' && !!nameSearch && !!nameSearch.trim()) {
                    return 'all';
                } else {
                    return listType;
                }
            }),
        );
    }

    showArchived(archived: boolean) {
        this.archived$.next(archived);
    }

    isBusy$(): Observable<boolean> {
        return combineLatest([this.isLoadingAnotherPage$, this.isLoadingFirstPage$]).pipe(
            map(([first, other]) => first || other),
        );
    }

    getStoreData$() {
        return this.appStoreWrapper.lobbyList();
    }
}
