import { FocusableOption } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ruum-sidenav-workspace-list-item',
    template: `
        <div
            *ngIf="{ active: active$ | async } as data"
            class="d-flex flex-fill align-items-center cursor-pointer minw-0 px-3 py-1 ruum-sidenav-nav-item"
            role="option"
            tabindex="-1"
            [attr.aria-selected]="data.active"
            [class.active]="data.active"
            [title]="title"
            (click)="navigateTo($event, path)"
            (keydown.space)="navigateTo($event, path)"
            (keydown.enter)="navigateTo($event, path)"
        >
            <ruum-profile-workspace [name]="name" [size]="'sm'" [componentClass]="'ml-5 mr-1'"></ruum-profile-workspace>
            <div class="flex-fill text-small text-truncate small" [class.font-weight-bold]="data.active">
                {{ name }}
            </div>
            <ruum-workspace-menu
                [tabindex]="-1"
                [workspaceId]="id"
                [workspaceName]="name"
                [isAdmin]="isAdmin"
                [theme]="'white-64'"
                [size]="'xs'"
            ></ruum-workspace-menu>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavWorkspaceListItemComponent implements OnInit, OnDestroy, FocusableOption {
    @HostBinding('class') hostClassList = 'd-flex minw-0';

    @Input() id: string;
    @Input() name: string;
    @Input() title: string;
    @Input() path: string;
    @Input() isAdmin: boolean;

    active$: Observable<boolean>;

    private ngOnDestroy$ = new Subject<void>();

    constructor(private router: Router, private element: ElementRef) {}

    ngOnInit() {
        this.active$ = this.router.events.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((event) => event instanceof NavigationEnd),
            map(() => !!this.router.url.replace('-settings', 's').match(this.path)),
            startWith(!!this.router.url.replace('-settings', 's').match(this.path)),
        );
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    navigateTo(event, path: string) {
        event.stopPropagation();
        this.router.navigate([path]);
    }

    focus(): void {
        this.element.nativeElement.querySelector('.ruum-sidenav-nav-item').focus();
    }
}
