import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ChangeRoleAction,
    DeleteOAuthClientAction,
    EnterpriseAction,
    EnterpriseFileUploadOptions,
    EnterpriseParticipant,
    EnterpriseRole,
    FileUploadOptions,
    UpdateFileUploadOptionsAction,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AppState } from '../../common/app.store';
import { AppStoreWrapper } from '../../common/appStoreWrapper.service';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { ODataEnterpriseOAuthClient } from '../../common/odata/odata.model';
import { ODataConnector } from './../../common/odata/ODataConnector.service';
import { BackendConnector, BasicEnterprise } from './backendConnector.service';

@Injectable()
export class EnterpriseSettingsService {
    fileUploadOptions$: BehaviorSubject<EnterpriseFileUploadOptions> = new BehaviorSubject<EnterpriseFileUploadOptions>(
        undefined,
    );

    oauthClients: ODataEnterpriseOAuthClient[] = undefined;
    oauthClients$: Observable<ODataEnterpriseOAuthClient[]> = undefined;

    constructor(
        private backendConnector: BackendConnector,
        private store: Store<AppState>,
        private appStoreWrapper: AppStoreWrapper,
        private authService: AuthService,
        private odataConnector: ODataConnector,
        private profileService: ProfileService,
    ) {
        this.oauthClients$ = this.getOauthClients();
        this.oauthClients$.subscribe((enterpriseOAuthClients) => {
            if (enterpriseOAuthClients) {
                this.oauthClients = enterpriseOAuthClients;
            }
        });
    }

    async updateFileUploadOptions(newFileUploadOptions: FileUploadOptions): Promise<void> {
        const enterpriseUploadOptionsAction: UpdateFileUploadOptionsAction = {
            at: Date.now(),
            createdBy: this.authService.getLoggedUserId(),
            type: 'UPDATE_ENTERPRISE_UPLOAD_OPTIONS',
            payload: newFileUploadOptions,
        };

        return this.backendConnector.updateEnterpriseFileUploadOptions(enterpriseUploadOptionsAction).then((e) => {
            this.store.dispatch(enterpriseUploadOptionsAction);
        });
    }

    async updateParticipantRole(
        currentEnterpriseParticipant: EnterpriseParticipant,
        role: EnterpriseRole,
    ): Promise<void> {
        const defaultBaseAction: Partial<EnterpriseAction> = {
            at: Date.now(),
            createdBy: this.authService.getLoggedUserId(),
        };

        return this.backendConnector.updateEnterpriseConfiguration({
            ...defaultBaseAction,
            type: 'CHANGE_ROLE',
            payload: { id: currentEnterpriseParticipant.id, role },
        } as ChangeRoleAction);
    }

    reloadBasicEnterpriseConfiguration(enterpriseId: string): Observable<BasicEnterprise> {
        return this.backendConnector.getEnterpriseConfigurationById().pipe(
            map((enterpriseConfiguration: BasicEnterprise) => {
                // necessary due to backend sending the enterpriseId within every property
                if (enterpriseConfiguration.fileUploadOptions) {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    delete enterpriseConfiguration.fileUploadOptions['enterpriseId'];
                }

                const action: EnterpriseAction = {
                    createdBy: this.authService.getLoggedUserId(),
                    at: Date.now(),
                    type: 'ENTERPRISE_CONFIGURATION_LOADED',
                    payload: { enterpriseConfiguration },
                };
                this.store.dispatch(action);
                return enterpriseConfiguration;
            }),
        );
    }

    reloadFileUploadOption(): Observable<FileUploadOptions> {
        return this.backendConnector.getEnterpriseFileUploadOptions().pipe(
            take(1),
            map((fileUploadOptions: FileUploadOptions) => {
                // necessary due to backend sending the enterpriseId within every property
                // eslint-disable-next-line @typescript-eslint/dot-notation
                if (fileUploadOptions['enterpriseId']) {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    delete fileUploadOptions['enterpriseId'];
                }

                const action: EnterpriseAction = {
                    createdBy: this.authService.getLoggedUserId(),
                    at: Date.now(),
                    type: 'UPDATE_ENTERPRISE_UPLOAD_OPTIONS',
                    payload: { ...fileUploadOptions },
                };
                this.store.dispatch(action);
                return fileUploadOptions;
            }),
        );
    }

    getFileUploadOptions() {
        return this.appStoreWrapper.fileUploadOptions();
    }

    // TODO: @ngrx9: fix type
    getOauthClients(): any {
        return this.appStoreWrapper.oauthClients();
    }

    async updateOrCreateOauthClient(oauthClient: ODataEnterpriseOAuthClient): Promise<void> {
        const oauthClientUpdateOrCreateAction: EnterpriseAction = {
            at: Date.now(),
            createdBy: this.authService.getLoggedUserId(),
            type: oauthClient.clientId ? 'UPDATE_ENTERPRISE_OAUTH_CLIENT' : 'CREATE_ENTERPRISE_OAUTH_CLIENT',
            payload: oauthClient,
        };

        return this.excecuteEnterpriseConfigurationChange(oauthClientUpdateOrCreateAction);
    }

    async deleteOauthClient(clientId: string): Promise<void> {
        const oauthClientDeleteAction: DeleteOAuthClientAction = {
            at: Date.now(),
            createdBy: this.authService.getLoggedUserId(),
            type: 'DELETE_ENTERPRISE_OAUTH_CLIENT',
            payload: {
                clientId,
            },
        };

        return this.excecuteEnterpriseConfigurationChange(oauthClientDeleteAction);
    }

    async excecuteEnterpriseConfigurationChange(action: EnterpriseAction): Promise<void> {
        let enterpriseId: string;
        this.appStoreWrapper.enterpriseId().subscribe((id) => (enterpriseId = id));

        return this.backendConnector.updateEnterpriseConfiguration(action).then((e) => {
            this.store.dispatch(action);
        });
    }
}
