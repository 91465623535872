import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SavedViewFieldsService } from '../../../../common/connectors/savedView/fields/saved-view-fields.service';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-filter-custom-field-list-select',
    template: `
        <ruum-select
            [select]="select"
            [search]="showSearch"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [multiSelect]="multiSelect"
            [placement]="placement"
            [lightBackground]="false"
            [disabled]="disabled"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-placeholder>
                {{ placeholder }}
            </ruum-select-placeholder>

            <ruum-select-option
                *ngFor="let option of options$ | async; trackBy: trackByOption"
                [value]="option[idField]"
                [content]="option[contentField]"
            ></ruum-select-option>
        </ruum-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCustomFieldListSelectComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    // Component properties
    @Input() idField = 'id';
    @Input() contentField = 'value';

    @Input() selectedFieldId: string;
    @Input() selectedOptionIds: string[] = [];
    @Input() multiSelect = false;
    @Input() placeholder = 'Select Field';
    @Input() showSearch = true;
    @Input() placement = ['bottom-left', 'top-left'];

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';
    @Output() changed = new EventEmitter();

    options$: Observable<any>;

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIds;
        } else {
            return this.selectedOptionIds[0];
        }
    }

    constructor(private savedViewFieldsService: SavedViewFieldsService) {}

    ngOnInit() {
        this.options$ = this.savedViewFieldsService.getFieldById(this.selectedFieldId).pipe(
            filter((field) => !!field),
            map((field) => {
                return field.options;
            }),
        );
    }

    ngOnDestroy() {}

    selectChange(event) {
        this.changed.emit(event);
    }

    trackByOption(index: number, item) {
        return item.fieldId;
    }
}
