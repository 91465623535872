import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-task-list-cell-section',
    template: `
        <a class="cursor-pointer d-flex minw-0 w-100" [routerLink]="getTaskLink()">
            <span
                class="small text-dark text-truncate pr-4"
                [ngbTooltip]="sectionTooltipContent"
                [placement]="['top', 'bottom', 'auto']"
            >
                {{ task.sectionName || '[Unnamed Section]' }}
            </span>
        </a>

        <ng-template #sectionTooltipContent>
            <div class="small interface">{{ task.sectionName || 'This section has no name yet' }}</div>
        </ng-template>
    `,
})
export class TaskListCellSectionComponent {
    @HostBinding() class = 'd-flex align-items-center w-100';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }

    task: any = {};

    constructor() {}

    getTaskLink() {
        return `/projects/${this.task.projectId}/tasks`;
    }
}
