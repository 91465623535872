import { ProjectAction, RuumAction, TableRow } from '@ruum/ruum-reducers';

export interface TableDataRecordMap {
    [id: string]: TableRow;
}

export const TABLE_DATA_RECORD_WEBAPP_ACTION_TYPES = {
    ADD_OR_REPLACE_TABLE_DATA_RECORD: 'ADD_OR_REPLACE_TABLE_DATA_RECORD',
};

export type AddOrReplaceTableDataRecords = ProjectAction<
    'ADD_OR_REPLACE_TABLE_DATA_RECORD',
    {
        records: TableRow[];
    }
>;

export function TableDataRecordWebAppReducer(
    currentState: TableDataRecordMap = {},
    action: RuumAction,
): TableDataRecordMap {
    switch (action.type) {
        case TABLE_DATA_RECORD_WEBAPP_ACTION_TYPES.ADD_OR_REPLACE_TABLE_DATA_RECORD:
            return addOrReplace(currentState, action.payload.records);
        default:
            return currentState;
    }
}

function addOrReplace(currentState: TableDataRecordMap, entries: TableRow[]): TableDataRecordMap {
    const newEntries = getMap(entries);
    return {
        ...currentState,
        ...newEntries,
    };
}

function getMap(entries: TableRow[]): TableDataRecordMap {
    const map: TableDataRecordMap = {};

    entries.forEach((entry) => {
        map[entry.__id] = entry;
    });

    return map;
}
