import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TaskPriority } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-task-priority-tag',
    template: `
        <button
            class="btn {{ componentClass }}"
            [class.btn-round]="fill"
            [class.btn-xs]="fill"
            [class.btn-sm]="!fill"
            [class.btn-icon]="onlyIcon"
            [class.pl-2]="!onlyIcon && !onlyText"
            [class.pr-4]="!onlyIcon && !onlyText"
            [class.btn-secondary]="option.theme === 'secondary' && fill && active"
            [class.btn-danger]="option.theme === 'danger' && fill && active"
            [class.btn-warning]="option.theme === 'warning' && fill && active"
            [class.btn-link-light]="
                (option.theme === 'secondary' && fill && !active) ||
                (option.theme === 'warning' && fill && !active) ||
                (option.theme === 'danger' && fill && !active) ||
                (option.theme === 'secondary' && !fill)
            "
            [class.btn-link-danger]="option.theme === 'danger' && !fill"
            [class.btn-link-warning]="option.theme === 'warning' && !fill"
            aria-label="Select task priority"
        >
            <i
                *ngIf="!onlyText"
                class="icon {{ option.icon }}"
                [class.text-secondary]="option.theme === 'secondary' && fill && !active"
                [class.text-warning]="option.theme === 'warning' && fill && !active"
                [class.text-danger]="option.theme === 'danger' && fill && !active"
            >
            </i>
            <span *ngIf="!onlyIcon" class="ml-1">{{ option.text }}</span>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPriorityTagComponent implements OnChanges {
    @Input() priority: TaskPriority;
    @Input() active = false;
    @Input() onlyIcon = false;
    @Input() onlyText = false;
    @Input() fill = false;
    @Input() componentClass = '';

    option: { text: string; icon: string; theme: string };

    ngOnChanges() {
        this.option = this.getPriorityTheme(this.priority);
    }

    private getPriorityTheme(status: TaskPriority): { id: string; text: string; icon: string; theme: string } {
        switch (status) {
            case 'HIGH':
                return { id: 'HIGH', text: 'High', icon: 'icon-priority-filled', theme: 'danger' };
            case 'MEDIUM':
                return { id: 'MEDIUM', text: 'Medium', icon: 'icon-priority-filled', theme: 'warning' };
            case 'LOW':
                return { id: 'LOW', text: 'Low', icon: 'icon-priority-filled', theme: 'secondary' };
            default:
                return { id: 'NO_PRIORITY', text: 'No Priority', icon: 'icon-priority-outlined', theme: 'secondary' };
        }
    }
}
