import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { RuumSidepanelService } from './../../layout/sidepanel.service';

@Component({
    selector: 'ruum-task-list-new-item',
    template: `
        <!-- mobile -->
        <div class="fixed-bottom d-flex d-lg-none justify-content-center ruum-create-task d-print-none">
            <button
                class="shadow btn btn-lg btn-primary btn-icon btn-round btn-new-task"
                type="button"
                [disabled]="isReadOnly"
                (click)="addTask.emit($event)"
            >
                <i class="icon icon-add"></i>
            </button>
        </div>
        <!-- desktop -->
        <div class="d-lg-flex d-none">
            <div class="d-inline-block rounded position-relative d-print-none">
                <button
                    class="btn btn-primary pl-2 ml-2"
                    [disabled]="isReadOnly"
                    type="button"
                    (click)="addTask.emit($event)"
                >
                    <i class="mr-1 icon icon-add"></i>
                    <span>New Task</span>
                </button>
            </div>
        </div>
    `,
    styles: [
        `
            .btn-new-task {
                width: 56px;
                height: 56px;
                border-radius: 28px;
            }
            .btn-new-task i {
                width: 32px;
                height: 32px;
                font-size: 32px;
            }

            .ruum-create-task {
                bottom: 80px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskLIstNewItemComponent implements OnInit {
    @Input()
    isReadOnly = false;

    @Output()
    addTask = new EventEmitter();

    isSidepanelOpen$: Observable<boolean>;

    constructor(private sidepanelService: RuumSidepanelService) {}

    ngOnInit() {
        this.isSidepanelOpen$ = this.sidepanelService.isSidepanelOpen$;
    }
}
