import { ProcessEnum, Ruum } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { distinctUntilChanged, pairwise } from 'rxjs/operators';
import { ApprovalStore } from '../../approvals/approval.store';

/**
 * Reload the approval every time it enters or leaves an approval step.
 */
export function reloadApprovalItem(project$: Observable<Ruum>, approvalStore: ApprovalStore): void {
    project$
        .pipe(
            distinctUntilChanged((a, b) => a.processes === b.processes),
            pairwise(),
        )
        .subscribe(([before, after]) => {
            const processBefore = before.processes[ProcessEnum.ROOT_PROCESS_ID];
            const processAfter = after.processes[ProcessEnum.ROOT_PROCESS_ID];

            if (!processBefore || !processBefore.activeStepId || !processAfter) {
                return;
            }

            const previouStep = processBefore.steps[processBefore.activeStepId];
            const activeStep = processAfter.steps[processAfter.activeStepId];

            if (activeStep.type === 'approval') {
                approvalStore.reloadApproval(before.id, activeStep.approvalId);
            } else if (previouStep.type === 'approval') {
                approvalStore.reloadApproval(before.id, previouStep.approvalId);
            }
        });
}
