import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-menu-devider',
    template: `
        <hr class="my-2" />
        <h5 *ngIf="headline" class="text-dark px-2 py-1 mx-2 mt-2">{{ headline }}</h5>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDeviderComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Input() headline: string;

    constructor() {}

    ngOnInit() {}
}
