import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-sap-ruum-logo',
    template: `
        <img
            alt="Ruum Logo"
            src="assets/img/SAP-Ruum-Logo-{{ color }}.svg"
            [width]="ruumLogoWidth"
            [height]="ruumLogoHeight"
        />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SAPRUUMLogoComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex align-items-center';

    @Input() ruumLogoWidth = 90;

    @Input() ruumLogoHeight = 16;

    @Input() dark = false;

    get color() {
        return this.dark ? 'regular' : 'white';
    }
    constructor() {}

    ngOnInit() {}
}
