import { ChangeDetectionStrategy, Component, ElementRef, HostBinding } from '@angular/core';
import { EnterpriseRole } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { ODataEnterpriseParticipant } from '../../common/odata/odata.model';
import { TrackingConnector } from '../../common/trackingConnector.service';
import { ODataBaseListViewComponentDirective } from '../common/components/odata-base-list-view.component';
import { EnterpriseSettingsService } from '../common/enterpriseSettings.service';
import { ModalService } from '../common/modal/modal.service';
import { EnterpriseParticipantsListService } from './enterprise-participant-list.service';

const roleMapping = {
    EnterpriseAdmin: 'Administrator',
    EnterpriseIntegrationAdmin: 'Integration Administrator',
    EnterpriseParticipant: 'Participant',
};

interface RoleOption {
    text: string;
    icon: string;
    onClick: () => void;
    theme?: string;
}

@Component({
    selector: 'ruum-enterprise-participants-list',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <!-- Sub Header Content -->
                <ruum-view-controls>
                    <div class="w-25">
                        <ruum-search
                            [size]="'sm'"
                            [value]="search"
                            ariaLabel="Search for enterprise participant"
                            (changed)="onChangeSearchValue($event)"
                        ></ruum-search>
                    </div>
                </ruum-view-controls>

                <!-- empty state: no items yet, no search result -->
                <ruum-empty-state
                    *ngIf="(rows$ | async)?.length === 0 && (isLoadingFirstPage$ | async) !== true"
                    [entityName]="'Enterprise Participants'"
                >
                    <ruum-illustration-system-connectors
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-system-connectors>
                </ruum-empty-state>

                <!-- Table Container -->
                <div
                    class="d-flex flex-column px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5"
                    scrollDetectionDirective
                    (scrolledToBottom)="scrolledToBottom()"
                >
                    <!--list component-->
                    <table *ngIf="(rows$ | async)?.length !== 0" class="w-100" cdk-table [dataSource]="dataSource">
                        <!-- Column content projection -->
                        <!-- Full Name Column -->
                        <ng-container cdkColumnDef="name">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="name"
                                    [sorting]="isSorted('name')"
                                    [direction]="getSortDirection('name')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ element.name }}</span>
                            </td>
                        </ng-container>
                        <!-- Email Column -->
                        <ng-container cdkColumnDef="email">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="email"
                                    [sorting]="isSorted('email')"
                                    [direction]="getSortDirection('email')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ element.email }}</span>
                            </td>
                        </ng-container>
                        <!-- Last Activity Column -->
                        <ng-container cdkColumnDef="lastActivity">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="lastActivity"
                                    label="Last Activity"
                                    [sorting]="isSorted('lastActivity')"
                                    [direction]="getSortDirection('lastActivity')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ element.lastActivity | date }}</span>
                            </td>
                        </ng-container>
                        <!-- Role Column -->
                        <ng-container cdkColumnDef="role">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="role"
                                    [sorting]="isSorted('role')"
                                    [direction]="getSortDirection('role')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ getRoleName(element.role) }}</span>
                            </td>
                        </ng-container>
                        <!-- id Column -->
                        <ng-container cdkColumnDef="id">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3"></th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <ng-container>
                                    <ruum-menu>
                                        <ruum-menu-trigger>
                                            <button class="btn btn-sm btn-link-secondary btn-icon">
                                                <i class="icon icon-contextual-menu"></i>
                                            </button>
                                        </ruum-menu-trigger>
                                        <ruum-menu-option
                                            *ngFor="let option of getOptions(element); let index"
                                            [icon]="option.icon"
                                            [theme]="option.theme"
                                            (onClick)="option.onClick()"
                                            >{{ option.text }}</ruum-menu-option
                                        >
                                    </ruum-menu>
                                </ng-container>
                            </td>
                        </ng-container>

                        <!-- column filter row -->
                        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns"
                            class="border-bottom border-light hov hov-bg-primary-light"
                        ></tr>

                        <!-- busy indicator row -->
                        <tr *ngIf="(isBusy$ | async) === true">
                            <td colspan="4">
                                <img
                                    class="ml-4 p-2"
                                    width="60"
                                    src="./../../assets/img/spinner.svg"
                                    alt="Loading Spinner"
                                />
                            </td>
                        </tr>
                    </table>

                    <!-- spinner: initial fetching -->
                    <ruum-load-spinner
                        classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                        *ngIf="isLoadingFirstPage$ | async"
                    ></ruum-load-spinner>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="scrolledToBottom()"
                    ></ruum-load-more>
                </div>
            </div>
        </ruum-max-content>
    `,
    styles: [
        `
            . {
                max-width: 240px;
            }

            . {
                min-width: 180px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminEnterpriseParticipantsListComponent extends ODataBaseListViewComponentDirective {
    @HostBinding('class') hostClassList = 'd-flex flex-column w-100 mw-100 h-100 mh-100 overflow-y';

    isLoadingFirstPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;

    constructor(
        enterpriseParticipantsListService: EnterpriseParticipantsListService,
        profileService: ProfileService,
        selectedEnterpriseService: SelectedEnterpriseService,
        trackingConnector: TrackingConnector,
        private enterpriseSettingsService: EnterpriseSettingsService,
        private modalService: ModalService,
        private authService: AuthService,
        private elementRef: ElementRef,
    ) {
        super(
            enterpriseParticipantsListService,
            profileService,
            selectedEnterpriseService,
            trackingConnector,
            ['name', 'email', 'role', 'lastActivity', 'id'],
            ['name', 'email', 'role'],
        );

        this.isLoadingFirstPage$ = this.listService.isLoadingFirstPage$;
        this.showLoading$ = this.listService.showLoading$;
    }

    back() {
        history.back();
    }

    getRoleName(role: EnterpriseRole): string {
        return roleMapping[role] || role;
    }

    getOptions(currentParticipant: ODataEnterpriseParticipant): RoleOption[] {
        const roleMappingKeys = Reflect.ownKeys(roleMapping);

        const options: RoleOption[] = roleMappingKeys.reduce((currentOptions, possibleRole: EnterpriseRole, index) => {
            if (roleMappingKeys.indexOf(currentParticipant.role) < roleMappingKeys.indexOf(possibleRole)) {
                currentOptions.push({
                    text: `Demote to ${roleMapping[possibleRole]}`,
                    icon: 'icon-cevron-down',
                    onClick: () => {
                        this.maybeChangeParticipantRolechangeParticipantRole(currentParticipant, possibleRole);
                    },
                });
            }
            if (roleMappingKeys.indexOf(currentParticipant.role) > roleMappingKeys.indexOf(possibleRole)) {
                currentOptions.push({
                    text: `Promote to ${roleMapping[possibleRole]}`,
                    icon: 'icon-cevron-up',
                    onClick: () => {
                        this.maybeChangeParticipantRolechangeParticipantRole(currentParticipant, possibleRole);
                    },
                });
            }
            return currentOptions;
        }, []);
        options.push({
            text: 'Delete',
            icon: 'icon-delete',
            theme: 'danger',
            onClick: () => this.deleteParticipantFromEnterprise(currentParticipant.userId),
        });

        return options;
    }

    private async maybeChangeParticipantRolechangeParticipantRole(
        participant: ODataEnterpriseParticipant,
        role: EnterpriseRole,
    ): Promise<void> {
        if (this.authService.getLoggedUserId() === participant.userId) {
            const doYouWantToDelete: boolean = await this.modalService.openGenericConfirmationDialog({
                title: `Demote Yourself?`,
                message: `Are you sure you want to demote your own Admin rights to become just a ${this.getRoleName(
                    role,
                )}? Only another Admin would be able to undo this. Only succeed if you are absolutely sure this is what you want.`,
            });

            if (doYouWantToDelete) {
                this.changeParticipantRole(participant, role);
            }
        } else {
            this.changeParticipantRole(participant, role);
        }
    }

    private async changeParticipantRole(participant: ODataEnterpriseParticipant, role: EnterpriseRole): Promise<void> {
        await this.enterpriseSettingsService.updateParticipantRole(
            { id: participant.userId, role: participant.role as EnterpriseRole },
            role,
        );
    }

    private deleteParticipantFromEnterprise(userId: string) {
        // TODO: should be replaced at some point with actually removing the user (either blocklisting for current enterprise, or actually removing the account)
        window.location.href = `mailto:ruum@sap.com?subject=Request User deletion&body=${userId}`;
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }
}
