import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-menu-trigger',
    template: `
        <ng-content></ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTriggerComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    constructor() {}

    ngOnInit() {}
}
