import { ChangeDetectorRef, Directive, HostBinding, HostListener } from '@angular/core';

@Directive({ selector: 'button[ruumIconButton]' })
export class RuumIconButtonDirective {
    @HostBinding('class.ruum-icon-button') private iconButton = true;

    @HostBinding('class.active') private active = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    @HostListener('popup-focus') focus() {
        this.active = true;
        this.changeDetectorRef.detectChanges();
    }

    @HostListener('popup-blur') blur() {
        this.active = false;
        this.changeDetectorRef.detectChanges();
    }
}
