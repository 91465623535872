import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SavedViewFilter } from '../../../../common/connectors/savedView/saved-views.model';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-filter-field-value-search',
    template: `
        <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="placeholder"
            [ngModel]="value"
            (ngModelChange)="changeValue($event)"
        />
    `,
    styles: [
        `
            .h-min-content {
                height: min-content;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldValueSearchComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    // Component properties
    @Input() selectedFieldId: string;
    @Input() selectedLogicOperator: string;
    @Input() selectType: string;
    @Input() set selectedOptions(options: string[]) {
        this.value = (options || []).join(',');
    }

    @Input() placeholder = 'Enter value';
    @Input() debounceTime = 1000;
    @Input() options: SavedViewFilter[] = [];
    @Input() showSearch = true;
    @Input() placement = ['bottom-left', 'top-left'];

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    value = '';

    @Output() changed = new EventEmitter();

    private search$ = new Subject<string>();
    private ngOnDestroy$ = new Subject<void>();

    constructor() {}

    ngOnInit() {
        this.search$
            .pipe(takeUntil(this.ngOnDestroy$), debounceTime(this.debounceTime))
            .subscribe(() => this.changed.emit(this.value));
    }

    changeValue(newValue: string): void {
        this.value = newValue;
        this.search$.next(this.value);
    }
}
