export const componentHelper = {
    getDefaultClassName(): string {
        return 'btn';
    },
    getSizeClassName(size: string): string {
        return ['btn', this.getSize(size)].filter((el) => !!el).join('-');
    },

    getSize(size: string): string {
        const sizeMap = {
            xs: 'xs',
            sm: 'xs',
            md: 'md',
            lg: 'lg',
            xl: 'xl',
        };
        return sizeMap[size] || sizeMap.md;
    },

    getThemeClassName(theme: string, type?: string): string {
        return ['btn', this.getType(type), theme].filter((el) => !!el).join('-');
    },

    getType(type?: string): string {
        const typeMap = {
            default: '',
            outline: 'outline',
            link: 'link',
        };
        return typeMap[type] || typeMap.default;
    },

    getRoundClassName(rounded: boolean): string {
        return rounded ? 'btn-round' : '';
    },

    getHoverClassName(hover: boolean) {
        return hover ? 'hover' : 'no-hover';
    },

    getActiveClassName(active: boolean): string {
        return active ? 'active' : '';
    },

    getDisabledClassName(disabled): string {
        return disabled ? 'disabled' : '';
    },

    getCursorClassName(disabled: boolean) {
        return disabled ? 'cursor-not-allowed' : 'cursor-pointer';
    },

    transformClassNameArrayToString(list: string[]): string {
        return list.filter((el) => !!el).join(' ');
    },

    getKeyboardFocusableElements(element: HTMLElement) {
        const focusableElements =
            'a:not([disabled]), button:not([disabled]), textarea:not([disabled]), select:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"]), iframe, object, embed';
        return element.querySelectorAll<HTMLElement>(focusableElements);
    },
};
