import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { APPLICATION_PART } from '../../common/help/help.service';
import { RuumUIStateService } from './../../ruum/ui-state/ruumUIState.service';

@Component({
    selector: 'offline-alert',
    template: `
        <div *ngIf="offline$ | async" class="w-100 bg-warning">
            <button class="btn btn-sm btn-icon btn-without-hover btn-link-secondary ml-2 ml-md-5 d-inline-block">
                <i class="icon icon-warning text-white"></i>
            </button>
            <span class="text-white ml-2 ml-md-5">
                Trying to reconnect&nbsp;&nbsp;&nbsp;
            </span>
            <span class="text-white text-small" *ngIf="applicationPart == 'ruum'">
                So far we don’t support offline editing. Make sure you have a working internet connection.
            </span>
            <span class="text-white text-small" *ngIf="applicationPart != 'ruum'">
                It looks like you're offline or having a very bad internet connection.
            </span>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineAlertComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    @Input() applicationPart: APPLICATION_PART = 'ruum';

    offline$: Observable<boolean>;

    private subscription: Subscription = new Subscription();

    constructor(private uiStateService: RuumUIStateService) {}

    ngOnInit() {
        this.subscription.add(
            combineLatest([
                fromEvent(window, 'online').pipe(startWith(false)),
                fromEvent(window, 'offline').pipe(startWith(false)),
            ])
                .pipe(
                    map(() => navigator.onLine),
                    shareReplay(),
                )
                .subscribe((isOnline) => this.uiStateService.setOffline(!isOnline)),
        );

        this.offline$ = this.uiStateService.offline();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
