import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ExternalSystem, TableDefinition } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { ExternalSystemStore } from '../../common/connectors/externalSystem/externalSystem.store';
import { OrderedListParams, SortDirection } from '../../common/connectors/paginatedList.model';
import { TableDefinitionListSortBy } from '../../common/connectors/tableDefinition/table-definition-list.model';
import { TableDefinitionListService } from '../../common/connectors/tableDefinition/table-definition-list.service';
import { TableDefinitionActionsService } from '../../shared/table-definition/table-definition-actions.service';
import { GenericDataSource } from './../../common/connectors/paginatedListLoader';

export type TableDefinitionTableData = TableDefinition & { system?: ExternalSystem };

@Component({
    selector: 'ruum-admin-table-definitions',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <!-- view controles -->
                <ruum-view-controls>
                    <div class="w-25">
                        <ruum-search
                            [size]="'sm'"
                            [value]="searchValue$ | async"
                            (changed)="search($event)"
                        ></ruum-search>
                    </div>
                    <div class="d-flex flex-fill justify-content-end">
                        <button
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-primary float-right"
                            type="button"
                            (click)="createItem()"
                        >
                            <i class="mr-1 icon icon-add"></i>
                            <span>New</span>
                        </button>
                    </div>
                </ruum-view-controls>

                <!-- empty state: no items yet, no search result -->
                <ruum-empty-state
                    *ngIf="(data$ | async)?.length === 0 && (isLoadingFirstPage$ | async) !== true"
                    [searchApplied]="(searchValue$ | async)?.length > 0"
                    [entityName]="'API Mapping'"
                >
                    <ruum-illustration-system-connectors
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-system-connectors>
                </ruum-empty-state>

                <!-- view list -->
                <div
                    *ngIf="(data$ | async)?.length > 0"
                    class="d-flex flex-column px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5"
                >
                    <table class="w-100" cdk-table [dataSource]="dataSource" [trackBy]="trackByFunction">
                        <!-- Name Column -->
                        <ng-container cdkColumnDef="name">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'name'"
                                    [label]="'Name'"
                                    [sorting]="isSortBy('name')"
                                    [direction]="getSortDirection('name')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.name }}</span>
                            </td>
                        </ng-container>

                        <!-- System Name Column -->
                        <ng-container cdkColumnDef="system">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'systemTitle'"
                                    [label]="'System'"
                                    [sorting]="isSortBy('systemTitle')"
                                    [direction]="getSortDirection('systemTitle')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.system?.title }}</span>
                            </td>
                        </ng-container>

                        <!-- Path Column -->
                        <ng-container cdkColumnDef="path">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'path'"
                                    [label]="'Path'"
                                    [sorting]="isSortBy('path')"
                                    [direction]="getSortDirection('path')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.path }}</span>
                            </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container cdkColumnDef="actions">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3"></th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 text-right">
                                <button class="btn btn-icon btn-link-secondary ml-1" (click)="editItem(element)">
                                    <i class="icon icon-settings"></i>
                                </button>
                                <button class="btn btn-icon btn-link-danger ml-1" (click)="deleteItem(element)">
                                    <i class="icon icon-delete"></i>
                                </button>
                            </td>
                        </ng-container>

                        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns"
                            class="border-bottom border-light hov hov-bg-primary-light"
                        ></tr>
                    </table>

                    <!-- spinner: initial fetching -->
                    <ruum-load-spinner
                        classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                        *ngIf="isLoadingFirstPage$ | async"
                    ></ruum-load-spinner>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="loadMore()"
                    ></ruum-load-more>
                </div>
            </div>
        </ruum-max-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminTableDefinitionsComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column w-100 mw-100 h-100 mh-100 overflow-y';

    isCurrentUserSuperAdmin$: Observable<boolean>;

    displayedColumns = ['name', 'system', 'path', 'actions'];

    isLoadingFirstPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;
    data$: Observable<TableDefinitionTableData[]>;
    dataSource: GenericDataSource<TableDefinitionTableData>;
    searchValue$: Observable<string>;

    sortValue: OrderedListParams<TableDefinitionListSortBy> = { by: 'name', direction: 'asc' };

    constructor(
        private elementRef: ElementRef,
        private tableDefinitionListService: TableDefinitionListService,
        private tableDefinitionActionsService: TableDefinitionActionsService,
        private externalSystemStore: ExternalSystemStore,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    ngOnInit() {
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();

        this.tableDefinitionListService.resetFilters();
        this.tableDefinitionListService.loadList();
        this.tableDefinitionListService.enterpriseId$.next(this.selectedEnterpriseService.getSelectedEnterpriseId());

        this.data$ = this.getData();
        this.dataSource = new GenericDataSource<TableDefinitionTableData>(this.data$);

        this.isLoadingFirstPage$ = this.tableDefinitionListService.isLoadingFirstPage$;
        this.showLoading$ = this.tableDefinitionListService.showLoading$;
        this.searchValue$ = this.tableDefinitionListService.search$.asObservable();
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }

    ngOnDestroy() {
        this.tableDefinitionListService.stopLoadingList();
    }

    trackByFunction(index: number, item: TableDefinition): string {
        if (!item) {
            return;
        }
        return item.id;
    }

    createItem() {
        this.tableDefinitionActionsService.createTableDefinition();
    }

    editItem(tableDefinition: TableDefinition) {
        this.tableDefinitionActionsService.updateTableDefinition(tableDefinition);
    }

    deleteItem(tableDefinition: TableDefinition) {
        this.tableDefinitionActionsService.deleteTableDefinition(tableDefinition);
    }

    search(value: string): void {
        this.tableDefinitionListService.search$.next(value);
    }

    isSortBy(sortBy: TableDefinitionListSortBy): boolean {
        if (this.sortValue && this.sortValue.by === sortBy) {
            return true;
        }
        return false;
    }

    getSortDirection(sortBy: TableDefinitionListSortBy): SortDirection {
        if (this.sortValue && this.sortValue.by === sortBy) {
            return this.sortValue.direction;
        }
        return 'asc';
    }

    sort(sortChange: OrderedListParams<TableDefinitionListSortBy>) {
        this.sortValue = sortChange;
        this.tableDefinitionListService.orderBy(sortChange.by as TableDefinitionListSortBy, sortChange.direction);
    }

    loadMore(): void {
        this.tableDefinitionListService.maybeGoToNextPage();
    }

    private getData(): Observable<TableDefinitionTableData[]> {
        return this.tableDefinitionListService.getStoreRows$().pipe(
            switchMap<TableDefinition[], Observable<TableDefinitionTableData[]>>((tableDefinitionList) => {
                const systemIdList = tableDefinitionList.map((tableDefinition: any) => tableDefinition.systemId);
                return this.externalSystemStore.dataList(systemIdList).pipe(
                    map((externalSystemList) => {
                        const systemMap = new Map<string, ExternalSystem>();
                        for (const item of externalSystemList) {
                            systemMap.set(item.id, item);
                        }
                        return tableDefinitionList.map((tableDefinition: any) => {
                            const system = systemMap.get(tableDefinition.systemId);
                            return { ...tableDefinition, system };
                        }) as TableDefinitionTableData[];
                    }),
                );
            }),
        );
    }
}
