import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../common.module';
import { RuumBootstrapModule } from '../ruum-bootstrap/ruum-bootstrap.module';
import { GettingStartedComponent } from './../components/getting-started/getting-started.component';
import { GettingStartedService } from './../components/getting-started/getting-started.service';
import { HelpPopoverComponent } from './help-popover.component';
import { HelpService } from './help.service';
import { KeyboardShortcutsComponent } from './keyboard-shortcuts/keyboard-shortcuts.component';

@NgModule({
    imports: [CommonModule, RuumCommonModule, RuumBootstrapModule],
    declarations: [HelpPopoverComponent, KeyboardShortcutsComponent, GettingStartedComponent],
    providers: [HelpService, GettingStartedService],
    exports: [HelpPopoverComponent],
})
export class RuumHelpModule {}
