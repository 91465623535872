import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TaskListItem } from '../../../common/connectors/tasks/task-list.model';

@Component({
    selector: 'ruum-task-list-cell-comment',
    template: `
        <div class="ruum-task-comments d-flex flex-fill justify-content-end">
            <button
                class="btn btn-icon minw-0"
                [class.btn-link-gray-24]="!task.firstCommentId"
                [class.btn-link-secondary]="task.firstCommentId"
                (click)="commentsClick($event)"
                aria-label="Show comments for task"
            >
                <i class="icon icon-comment"></i>
            </button>
        </div>
    `,
})
export class TaskListCellCommentComponent {
    @HostBinding() class = 'd-flex align-items-center';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }
    @Output() commentsClicked = new EventEmitter<TaskListItem>();

    task: any = {};

    constructor() {}

    commentsClick(event) {
        event.stopPropagation();
        this.commentsClicked.emit(this.task);
    }
}
