import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AccountType } from '@ruum/ruum-reducers';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrialService, TrialStatus } from '../../../paywall/trial/trial.service';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { ProfileListItem } from '../../connectors/profile/profile.model';
import { APPLICATION_PART } from '../../help/help.service';
import { SidenavService } from '../../sidenav/sidenav.service';
import { TrackingConnector } from '../../trackingConnector.service';
import { NavbarService } from '../navbar.service';
import { ProfileService } from './../../connectors/profile/profile.service';

@Component({
    selector: 'ruum-main-navbar',
    template: `
        <nav
            role="navigation"
            aria-label="Main"
            id="ruum-primary-navbar"
            [class.navbar-main-template]="isTemplateRoute"
            [class.navbar-main]="!isTemplateRoute"
            class="navbar navbar-light navbar-expand-lg d-flex justify-content-center"
        >
            <div class="w-100 d-flex flex-fill pr-2 pr-sm-2 pr-md-2 pr-lg-8 pl-2 pl-sm-2">
                <div
                    class="flex-fill align-items-center minw-0"
                    [class.d-flex]="!isNavbarWithBackButton"
                    [class.d-lg-flex]="isNavbarWithBackButton"
                    [class.d-none]="isNavbarWithBackButton"
                >
                    <button
                        class="btn btn-sm btn-icon d-lg-flex d-none mr-2"
                        [class.btn-link-dark]="isTemplateRoute"
                        [class.btn-link-white-64]="!isTemplateRoute"
                        (click)="toggleNavigationDrawer()"
                    >
                        <i class="icon icon-menu"></i>
                    </button>

                    <a
                        class="pr-6"
                        [class.d-lg-flex]="!isHome"
                        [class.d-none]="!isHome"
                        [class.d-flex]="isHome"
                        routerLink="/home"
                    >
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center bg-sap-ruum-logo">
                                <ruum-sap-ruum-logo
                                    [dark]="isTemplateRoute"
                                    [ruumLogoHeight]="isTemplateRoute == true ? 20 : 16"
                                ></ruum-sap-ruum-logo>
                            </div>
                        </div>
                    </a>

                    <div class="d-flex flex-fill breadcrumb-wrapper minw-0" [style.min-height.px]="44">
                        <ng-content></ng-content>
                    </div>
                </div>

                <div *ngIf="isNavbarWithBackButton" class="d-flex d-lg-none flex-fill align-items-center minw-0">
                    <ruum-navbar-back-button [theme]="isTemplateRoute ? 'dark' : 'white-64'"></ruum-navbar-back-button>
                    <h3 clas="text-truncate minw-0">{{ backBattonTitle$ | async }}</h3>
                </div>
                <!-- 0px - 768px -->
                <div class="navbar-toggler border-0 p-0 pl-1">
                    <div
                        *ngIf="isHome"
                        class="d-flex flex-fill align-items-center minw-0"
                        [style.padding-top.px]="6"
                        [style.padding-bottom.px]="6"
                    >
                        <ruum-profile-member
                            [name]="(userProfile$ | async)?.fullName"
                            [theme]="isTemplateRoute ? 'light' : 'white-16'"
                            [hover]="true"
                            [active]="false"
                            [disabled]="false"
                            [tabindex]="0"
                            [componentClass]="'ml-2'"
                            (click)="toggleSettingsModal(settingsModalContent)"
                        ></ruum-profile-member>
                    </div>
                </div>
                <!-- 768px - 2500px -->
                <div class="collapse navbar-collapse" style="flex: none;" data-help-id="global_help_button">
                    <div class="navbar-nav ml-auto py-1">
                        <!-- Navbar Help -->
                        <button
                            aria-label="Open Help Menu"
                            class="btn btn-sm btn-icon px-1 ml-1"
                            [class.btn-link-dark]="isTemplateRoute"
                            [class.btn-link-white-64]="!isTemplateRoute"
                            type="button"
                            #helpPopover="ngbPopover"
                            placement="bottom-right"
                            autoClose="outside"
                            [ngbPopover]="helpPopoverContent"
                            (click)="trackEventInferCategoryFromUrl('main_navbar_help', 'clicked')"
                        >
                            <i class="icon icon-help-outline"></i>
                        </button>
                        <!-- /Navbar Help -->
                        <!-- Navbar User -->
                        <ruum-profile-member
                            #settingsPopover="ngbPopover"
                            [placement]="'bottom-right'"
                            [autoClose]="'outside'"
                            [ngbPopover]="settingsPopoverContent"
                            [name]="(userProfile$ | async)?.fullName"
                            [theme]="isTemplateRoute ? 'light' : 'white-16'"
                            [hover]="true"
                            [active]="false"
                            [disabled]="false"
                            [tabindex]="0"
                            [componentClass]="'ml-1'"
                            [disableTooltip]="true"
                            [ariaLabel]="'Open Settings Menu'"
                        ></ruum-profile-member>
                        <!-- /Navbar User -->
                    </div>
                </div>
            </div>
        </nav>

        <!-- Help popover -->
        <ng-template #helpPopoverContent>
            <ruum-help-popover (navigate)="navigateHelp($event)" [applicationPart]="applicationPart">
            </ruum-help-popover>
        </ng-template>

        <!-- Settings popover (Desktop)-->
        <ng-template #settingsPopoverContent>
            <ng-container
                *ngIf="{
                    userProfile: userProfile$ | async,
                    accountType: accountType$ | async,
                    trialStatus: trialStatus$ | async
                } as data"
            >
                <ruum-settings-popover
                    [name]="data.userProfile.fullName"
                    [initials]="data.userProfile.fullName | ruumProfileInitials"
                    [email]="data.userProfile.email"
                    [accountType]="data.accountType"
                    [isTrial]="data.trialStatus.status === 'running'"
                    [daysLeft]="data.trialStatus.daysLeft"
                    (navigate)="navigateSettings($event)"
                >
                </ruum-settings-popover>
            </ng-container>
        </ng-template>

        <!-- Settings modal (Mobile)-->
        <ng-template #settingsModalContent>
            <ng-container
                *ngIf="{
                    userProfile: userProfile$ | async,
                    accountType: accountType$ | async,
                    trialStatus: trialStatus$ | async
                } as data"
            >
                <ruum-settings-modal
                    [name]="data.userProfile.fullName"
                    [initials]="data.userProfile.fullName | ruumProfileInitials"
                    [email]="data.userProfile.mail"
                    [accountType]="data.accountType"
                    [isTrial]="data.trialStatus.status === 'running'"
                    [daysLeft]="data.trialStatus.daysLeft"
                    (navigate)="navigateSettings($event)"
                >
                </ruum-settings-modal>
            </ng-container>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavbarComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-block';

    @Input() applicationPart: APPLICATION_PART;

    @Input() isNavbarWithBackButton: boolean;

    @Input() isHome: boolean;

    @ViewChild('helpPopover', { static: false }) helpPopover: NgbPopover;

    @ViewChild('settingsPopover', { static: false }) settingsPopover: NgbPopover;

    userProfile$: Observable<ProfileListItem>;

    accountType$: Observable<AccountType>;

    trialStatus$: Observable<TrialStatus>;

    backBattonTitle$: Observable<string>;

    isTemplateRoute: boolean;

    private isNavigationDrawerOpen: boolean;

    private subscriptions: Subscription[] = [];

    constructor(
        private modalService: NgbModal,
        private navbarService: NavbarService,
        private trackingConnector: TrackingConnector,
        private sidenavService: SidenavService,
        private appStoreWrapper: AppStoreWrapper,
        private trailService: TrialService,
        private router: Router,
        private route: ActivatedRoute,
        private profileService: ProfileService,
    ) {}

    ngOnInit() {
        this.isTemplateRoute = this.route.root.firstChild.firstChild.snapshot.data.isTemplate;
        this.userProfile$ = this.profileService.userProfile();
        this.accountType$ = this.profileService
            .userProfile()
            .pipe(map((userProfile) => userProfile.authorizations.accountType));

        this.trialStatus$ = this.trailService.trackTrialStatus();

        this.backBattonTitle$ = this.navbarService.backBattonTitle$;
        this.subscriptions.push(
            this.sidenavService.isMainNavPanelOpen$.subscribe((isNavigationDrawerOpen) => {
                this.isNavigationDrawerOpen = isNavigationDrawerOpen;
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    toggleSettingsModal(settingsModalComponent) {
        this.modalService.open(settingsModalComponent, { windowClass: 'modal-fullscreen' });
    }

    navigateHelp(): void {
        this.helpPopover.close();
    }

    trackEventInferCategoryFromUrl(object: string, action: string) {
        this.trackingConnector.trackEventInferCategoryFromUrl(object, action);
    }

    toggleNavigationDrawer() {
        if (this.isNavigationDrawerOpen) {
            this.sidenavService.onMainNavPanelDeactivate();
        } else {
            this.sidenavService.onMainNavPanelActivate();
        }
    }

    navigateSettings(option: string) {
        this.settingsPopover.close();
        switch (option) {
            case 'profile':
                return this.router.navigate(['home', 'settings', '!', 'profile']);
            case 'notifications':
                return this.router.navigate(['home', 'settings', '!', 'notifications']);
            case 'admin':
                return this.router.navigate(['admin', 'enterprise']);
            case 'logout':
                return this.router.navigate(['auth', 'logout']);
            default:
                return;
        }
    }
}
