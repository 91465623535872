import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EnterpriseReport } from './../dashboard.model';
import { DashboardService } from './../dashboard.service';

@Component({
    selector: 'dashboard-user-info-licenses',
    template: `
        <div class="">
            <div class="dashboard-widget-title">
                Licenses Used
            </div>
            <div class="counter">
                <span class="used">{{ (licenseData$ | async)?.usedLicenses || 0 }}</span>
                <span class="total">/{{ (licenseData$ | async)?.licenses || 0 }}</span>
            </div>
            <div class="chart">
                <span
                    class="used"
                    [style.width.%]="
                        getPercentages((licenseData$ | async)?.usedLicenses, (licenseData$ | async)?.licenses)
                    "
                ></span>
            </div>
        </div>
    `,
    styles: [
        `
            .counter .used,
            .counter .total {
                font-size: 50px;
                font-weight: bold;
            }
            .counter .total {
                color: #e1e1e1;
            }
            .chart {
                width: 100%;
                position: relative;
                height: 16px;
                background-color: #f3f3f3;
                border-radius: 10px;
            }
            .chart .used {
                position: absolute;
                top: 0;
                width: 10px;
                height: 100%;
                background-color: #0a6ed1;
                border-radius: 10px;
            }
        `,
    ],
})
export class LicensesIndicatorComponent implements OnInit {
    licenseData$: Observable<EnterpriseReport>;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {
        this.licenseData$ = this.dashboardService.getReport();
    }

    getPercentages(usedLicenses: number, licenses: number) {
        if (!usedLicenses || !licenses) {
            return 0;
        }
        return Math.min(Math.ceil((usedLicenses * 100) / licenses), 100);
    }
}
