import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../../../../shared/ui-components/select/select.module';
import { DatepickerModule } from '../../../ui-components/datepicker/datepicker.module';
import { IconModule } from '../../../ui-components/icon/icon.module';
import { ProfileModule } from '../../../ui-components/profile/profile.module';
import { TagModule } from '../../../ui-components/tag/tag.module';
import { FilterCustomFieldListSelectComponent } from './filter-custom-field-list-select.component';
import { FilterFieldSelectComponent } from './filter-field-select.component';
import { FilterMembersSelectComponent } from './filter-members-select.component';
import { FilterTagSelectComponent } from './filter-tag-select.component';

@NgModule({
    imports: [CommonModule, DatepickerModule, FormsModule, SelectModule, ProfileModule, TagModule, IconModule],
    declarations: [
        FilterFieldSelectComponent,
        FilterMembersSelectComponent,
        FilterTagSelectComponent,
        FilterCustomFieldListSelectComponent,
    ],
    exports: [
        FilterFieldSelectComponent,
        FilterMembersSelectComponent,
        FilterTagSelectComponent,
        FilterCustomFieldListSelectComponent,
    ],
})
export class FilterSelectModule {}
