import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    UrlSegment,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { ProfileService } from '../common/connectors/profile/profile.service';
import { environment } from './../../environments/environment';
import { ModalService } from './../admin/common/modal/modal.service';
import { GettingStartedService } from './../common/components/getting-started/getting-started.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
    private APP_URL = environment.APP_URL;
    private MS_REDIRECT_URL = this.APP_URL + '/msteams/tab-login-success.html';

    private privacyStatementNeedsUpdateAlredyShown = false;
    private gettingStartedMessageAlredyShown = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private profileService: ProfileService,
        private modalService: ModalService,
        private commonService: CommonService,
        private gettingStartedService: GettingStartedService,
    ) {}

    canLoad(route: Route, segments: UrlSegment[]) {
        return true;
    }

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state): Promise<boolean> {
        // TODO: for some reasone this.activatedRoute.snapshot and activatedRouteSnapshot have different data
        return this.authService
            .verifySessions()
            .then(() => this.loadProfile())
            .then(() => this.checkEmailIsBounced())
            .then((isEmailBounced) => {
                if (isEmailBounced) {
                    return this.navigateToVerificationFailure();
                }
            })
            .then(() => this.checkEmailIsVerified())
            .then((isEmailVerified: boolean) => {
                // TODO: userMailBounced
                /** continue email verification when it's not verified yet */
                if (!isEmailVerified && activatedRouteSnapshot?.data?.phaseId !== '2c-verified-login') {
                    return this.navigateToEmailVerification(this.activatedRoute.snapshot.queryParams);
                }

                /** Do not allow to visit auth pages when User is logged in. It redirects to Home view */
                if (activatedRouteSnapshot?.data?.phaseId) {
                    this.router.navigate(['home'], {
                        queryParams: { ...this.activatedRoute.snapshot.queryParams },
                    });
                    return false;
                }

                // TODO: when token is used?
                /** Set trackingId */
                this.setTrackingId(activatedRouteSnapshot.queryParams.t);

                /** Set MS Teams State */
                this.setMsTeamsStage(activatedRouteSnapshot.queryParams.msteams);

                if (activatedRouteSnapshot.queryParams.msteams === 'login') {
                    window.location.href = this.MS_REDIRECT_URL;
                    return false;
                }

                /** Set simplified value */
                this.setSimplifiedView(activatedRouteSnapshot.queryParams.simplified);

                /** Do not allow to visit current page when queryParams have URL Back. Redirect to URL Back */
                if (this.activatedRoute.snapshot.queryParams.urlback) {
                    this.authService.setRedirectUrl(this.activatedRoute.snapshot.queryParams.urlback);
                    window.location.href = this.activatedRoute.snapshot.queryParams.urlback;
                    return false;
                }

                /** Allow to visit current page */
                this.authService.setRedirectUrl('');
                this.checkForPrivacyAgreement();

                return true;
            })
            .catch((err) => {
                // TODO: when token is used?
                /** Set trackingId */
                this.setTrackingId(activatedRouteSnapshot.queryParams.t);

                /** Set MS Teams State */
                this.setMsTeamsStage(activatedRouteSnapshot.queryParams.msteams);

                /** Set simplified value */
                this.setSimplifiedView(activatedRouteSnapshot.queryParams.simplified);

                /** Allow to visit auth pages when User is NOT logged in */
                if (activatedRouteSnapshot?.data?.phaseId) {
                    if (!activatedRouteSnapshot.queryParams.urlback) {
                        const urlback = '/' + window.location.search;
                        this.authService.setRedirectUrl(encodeURIComponent(urlback)); // TODO: remove it?
                        this.router.navigate(['auth', activatedRouteSnapshot.routeConfig.path], {
                            queryParams: { urlback },
                        });

                        return false;
                    } else {
                        return true;
                    }
                }

                /** Set url back */
                if (!activatedRouteSnapshot.queryParams.urlback) {
                    let urlback;
                    if (window.location.pathname === '/auth/logout') {
                        urlback = '/' + window.location.search;
                    } else {
                        urlback = window.location.pathname + window.location.search;
                    }

                    this.authService.setRedirectUrl(encodeURIComponent(urlback));
                    this.router.navigate(['auth'], {
                        queryParams: { urlback },
                    });
                    return false;
                } else {
                    this.router.navigate(['auth'], {
                        queryParams: activatedRouteSnapshot.queryParams,
                    });
                    return false;
                }
            });
    }

    private loadProfile(): Promise<any> {
        if (!this.profileService.getCurrentUserProfileValue()) {
            return this.profileService.loadMyProfile();
        } else {
            return Promise.resolve();
        }
    }

    private checkEmailIsVerified(): Promise<boolean> {
        return this.authService
            .isEmailVerified()
            .pipe(take(1))
            .toPromise();
    }

    private checkEmailIsBounced(): Promise<boolean> {
        return this.authService
            .isEmailBounced()
            .pipe(take(1))
            .toPromise();
    }

    private navigateToVerificationFailure() {
        this.profileService.logout();
        this.authService.logout();
        window.location.href = 'https://www.ruumapp.com/verification-failure';
    }

    private navigateToEmailVerification(queryParams): Promise<boolean> {
        this.router.navigate(['auth', 'email-verification'], {
            queryParams,
        });
        return Promise.resolve(false);
    }

    private setSimplifiedView(simplified: string): void {
        if (simplified) {
            const simplifiedView = simplified === 'true';
            this.commonService.setSimplifiedView(simplifiedView);
        }
    }

    private setMsTeamsStage(msTeamsStage: string): void {
        if (msTeamsStage) {
            this.commonService.setMsTeamsStage(msTeamsStage);
        }
    }

    private setTrackingId(trackingId: string): void {
        if (trackingId) {
            this.authService.setToken(trackingId, 'trackingId');
        }
    }

    private checkForPrivacyAgreement() {
        return Promise.all([
            this.profileService
                .userShouldSeeGettingStartedMessage()
                .pipe(take(1))
                .toPromise(),
        ]).then(([userShouldSeeGettingStartedMessage]) => {
            if (userShouldSeeGettingStartedMessage && !this.gettingStartedMessageAlredyShown) {
                this.gettingStartedMessageAlredyShown = true;
                this.gettingStartedService.openGettingStarted(false);
            }
        });
    }
}
