import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { BackendConnector } from './../common/backendConnector.service';
import { ChangeAuthenticationProviderConfiguration } from './configuration.model';

@Injectable()
export class ConfigurationService {
    configuration$: BehaviorSubject<ChangeAuthenticationProviderConfiguration> = new BehaviorSubject<
        ChangeAuthenticationProviderConfiguration
    >(undefined);

    constructor(private backendConnector: BackendConnector, private profileService: ProfileService) {
        this.profileService.getCurrentUserEnterpriseId$().subscribe((enterpriseId) => {
            if (enterpriseId) {
                this.loadConfiguration(enterpriseId);
            }
        });
    }

    getConfiguration(): BehaviorSubject<ChangeAuthenticationProviderConfiguration> {
        return this.configuration$;
    }

    private loadConfiguration(enterpriseId: string) {
        this.backendConnector
            .getConfigurationOfCurrentEnterprise()
            .pipe(take(1))
            .subscribe((configuration) => this.configuration$.next(configuration));
    }

    saveConfiguration(configuration: ChangeAuthenticationProviderConfiguration): Promise<void> {
        return this.profileService
            .getCurrentUserEnterpriseId$()
            .toPromise()
            .then((enterpriseId) => {
                return this.backendConnector
                    .checkConfigurationOfEnterpriseId(enterpriseId, configuration)
                    .toPromise()
                    .then((validityCheckResults) => {
                        const errors: string[] = Object.keys(validityCheckResults).reduce(
                            (acc: string[], prop: string) => acc.concat(validityCheckResults[prop]),
                            [],
                        );
                        if (errors.length > 0) {
                            throw { errors };
                        } else {
                            return this.backendConnector
                                .setConfigurationOfEnterpriseId(enterpriseId, configuration)
                                .toPromise();
                        }
                    })
                    .then(() => this.loadConfiguration(enterpriseId));
            });
    }
}
