import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { RuumCommonModule } from '../common/common.module';
import { HttpCSRFInterceptor } from '../common/utils/http-csrf.interceptor';
import { MenuModule } from '../shared/ui-components/menu/menu.module';
import { ProfileModule } from '../shared/ui-components/profile/profile.module';
import { UiComponentsModule } from '../shared/ui-components/ui-components.module';
import { RuumNavbarModule } from './../common/navbar/navbar.module';
import { ODataConnector } from './../common/odata/ODataConnector.service';
import { ExternalSystemDialogModule } from './../shared/external-system/external-system-dialog/external-system-dialog.module';
import { AdminNavbarBreadcrumbComponent } from './admin-navbar-breadcrumb.component';
import { AdminNavbarComponent } from './admin-navbar.component';
// Other
import { AdminRoutingModule } from './admin-routing.module';
// Components
import { AdminComponent } from './admin.component';
import { SelectDefaultEnterpriseGuard } from './auth/guards/SelectDefaultEnterpriseGuard';
import { BackendConnector } from './common/backendConnector.service';
import { DrodownPopupComponent } from './common/components/dropdown-popup.component';
import { TabsComponent } from './common/components/tabs.component';
import { WidgetComponent } from './common/components/widget.component';
import { EnterpriseSettingsService } from './common/enterpriseSettings.service';
import { CanDeactivateGuard } from './common/modal/CanDeactivateGuard';
import { DataLossDialogComponent } from './common/modal/DataLossDialogComponent';
import { ErrorDialogComponent } from './common/modal/ErrorDialog';
import { GenericConfirmationDialogComponent } from './common/modal/GenericConfirmationDialog';
import { ModalService } from './common/modal/modal.service';
import { DashboardAPIUsageGraphComponent } from './dashboard/api-info/api-graph.component';
import { DashboardAPIInfoWidgetComponent } from './dashboard/api-info/api-widget.component';
import { DashboardWidgetComponent } from './dashboard/dashboard-widget.component';
import { AdminDashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './dashboard/dashboard.service';
import { HomeComponent } from './dashboard/home.component';
import { LicensesIndicatorComponent } from './dashboard/usage-info/licenses-indicator.component';
import { DashboardUserUsageGraphComponent } from './dashboard/usage-info/usage-graph.component';
import { DashboardUsageInfoWidgetComponent } from './dashboard/usage-info/usage-widget.component';
import { AdminExternalSystemsModule } from './external-systems/external-systems.module';
import { AdminMainLayoutModule } from './layout/main-layout.module';
import { AdminProfileMenuModule } from './profile/profile-menu.module';
import { CreateOauthClientComponent } from './security/create-oauth-client.component';
import { SecurityOauthTabComponent } from './security/security-oauth-tab.component';
import { SecurityWidgetComponent } from './security/security-widget.component';
import { AdminStorageSettingsComponent } from './storage/storage-widget.component';
import { SupportWidgetComponent } from './support/support-widget.component';
import { AdminSystemConnectorModule } from './system-connector/system-connector.module';
import { AdminTableDefinitionsModule } from './table-definitions/table-definitions.module';
import { AdminCreateTechnicalUserComponent } from './technical-users/create-technical-user.component';
import { AdminEditTechnicalUserComponent } from './technical-users/edit-technical-user.component';
import { TechnicalUserFormComponent } from './technical-users/technical-user-form.component';
import { AdminTechnicalUserListComponent } from './technical-users/technical-user-list.component';
import { TechnicalUserListService } from './technical-users/technical-user-list.service';
import { UserConfigurationComponent } from './user-configuration/configuration.component';
import { ConfigurationService } from './user-configuration/configuration.service';
import { AdminEnterpriseParticipantsListComponent } from './user-list/enterprise-participant-list.component';
import { EnterpriseParticipantsListService } from './user-list/enterprise-participant-list.service';
import { CreateWebhookComponent } from './webhooks/create-webhook.component';
import { EditWebhookComponent } from './webhooks/edit-webhook.component';
import { PayloadViewerDialogComponent } from './webhooks/entities/events/payload-viewer-dialog.component';
import { WebhookSyncedEntityEventsComponent } from './webhooks/entities/events/webhook-entity-events-list.component';
import { WebhookEntitiesListComponent } from './webhooks/entities/webhook-entities-list.component';
import { WebhookEntitiesListService } from './webhooks/entities/webhook-entities-list.service';
import { WebhookFormComponent } from './webhooks/webhook-form.component';
import { WebhookListComponent } from './webhooks/webhook-list.component';
import { WebhookListService } from './webhooks/webhook-list.service';
import { WebhooksConnector } from './webhooks/webhooks.connector';

const COMPONENTS = [
    AdminComponent,
    AdminNavbarComponent,
    AdminNavbarBreadcrumbComponent,
    HomeComponent,
    AdminDashboardComponent,
    DashboardWidgetComponent,
    AdminEnterpriseParticipantsListComponent,
    DrodownPopupComponent,
    WidgetComponent,
    DashboardUsageInfoWidgetComponent,
    DashboardUserUsageGraphComponent,
    LicensesIndicatorComponent,
    TabsComponent,
    AdminTechnicalUserListComponent,
    SecurityWidgetComponent,
    SecurityOauthTabComponent,
    CreateOauthClientComponent,
    AdminStorageSettingsComponent,
    SupportWidgetComponent,
    DashboardAPIInfoWidgetComponent,
    DashboardAPIUsageGraphComponent,
    WebhookListComponent,
    UserConfigurationComponent,
    AdminCreateTechnicalUserComponent,
    AdminEditTechnicalUserComponent,
    CreateWebhookComponent,
    EditWebhookComponent,
    WebhookEntitiesListComponent,
    WebhookSyncedEntityEventsComponent,
    DataLossDialogComponent,
    WebhookFormComponent,
    TechnicalUserFormComponent,
    GenericConfirmationDialogComponent,
    PayloadViewerDialogComponent,
    ErrorDialogComponent,
];

const PROVIDERS = [
    BackendConnector,
    ODataConnector,
    EnterpriseSettingsService,
    EnterpriseParticipantsListService,
    DashboardService,
    TechnicalUserListService,
    ConfigurationService,
    WebhookListService,
    WebhooksConnector,
    WebhookEntitiesListService,
    ModalService,
    CanDeactivateGuard,
    SelectDefaultEnterpriseGuard,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpCSRFInterceptor,
        multi: true,
    },
];

const MODULES = [
    CommonModule,
    RuumCommonModule,
    ProfileModule,
    RuumNavbarModule,
    MenuModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    UiComponentsModule,
    RuumCommonModule,
    ToastrModule.forRoot({
        timeOut: 1000,
        positionClass: 'toast-bottom-center',
    }),
    AdminRoutingModule,
    AdminProfileMenuModule,
    AdminMainLayoutModule,
    AdminExternalSystemsModule,
    AdminSystemConnectorModule,
    AdminTableDefinitionsModule,
    ExternalSystemDialogModule,
];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS],
    providers: [...PROVIDERS],
})
export class AdminHomeModule {}
