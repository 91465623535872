import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ruum-workspace-rename',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5">{{ title }}</h2>
            <form [formGroup]="form" (ngSubmit)="save()">
                <div class="text-small text-dark mb-4" [style.line-height.px]="18">
                    Rename your workspace cautiously, since this change will impact every workspace member.
                </div>
                <div class="d-flex flex-fill flex-column">
                    <div
                        class="form-group w-100"
                        [class.mb-0]="invalid('name')"
                        [class.input-group-invalid]="invalid('name')"
                    >
                        <label class="form-default-label" for="name">Name</label>
                        <input
                            id="name"
                            class="form-control"
                            type="text"
                            placeholder="e.g. My brilliant Ruum"
                            autofocus
                            formControlName="name"
                            ngbAutofocus
                        />
                        <div class="d-flex align-items-center" *ngIf="invalid('name')">
                            <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                                <i class="icon icon-cancel"></i>
                            </button>
                            <div class="text-tiny text-danger">The name is required.</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-6">
                    <button class="btn btn-lg btn-primary" type="submit" [disabled]="form.invalid">
                        Update
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
})
export class WorkspaceRenameComponent implements OnInit {
    @Input() title = 'Rename Workspace';
    @Input() name = '';

    form: FormGroup = this.formBuilder.group({
        name: ['', Validators.required],
    });

    constructor(private activeModal: NgbActiveModal, private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form.setValue({ name: this.name });
    }

    invalid(name: string): boolean {
        const control = this.form.get(name);
        return control.invalid && control.touched;
    }

    save() {
        const newName = this.form.get('name').value;
        this.activeModal.close(newName);
    }
}
