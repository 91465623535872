import { Processes, StepTaskConfiguration } from '@ruum/ruum-reducers';
import { TaskSections } from '../indexDocument';
import { ObjectsProcessStep, ProcessStepWebApp } from '../objectProcessStep';

export function getTasksStepConfiguration(
    tasks: TaskSections,
    processSections: ObjectsProcessStep,
    processes: Processes,
): TasksStepConfiguration {
    const tasksMap: TasksStepConfiguration = {};

    Object.keys(tasks).forEach((taskId) => {
        const sectionIds = tasks[taskId];

        /** If the task is not in any section, there is nothing to do. */
        if (!sectionIds || sectionIds.length === 0) {
            return;
        }

        const taskProcessSections = sectionIds.filter((id) => !!processSections[id]);

        /** If none of the sections where this task is is connected to a process step, there is nothing to do. */
        if (taskProcessSections.length === 0) {
            return;
        }

        const processId = processSections[taskProcessSections[0]].processId;
        const process = processes[processId];

        const activeSections = taskProcessSections.filter((id) => processSections[id].active);

        /** If the task is not in any active section, it is not active */
        if (activeSections.length === 0) {
            tasksMap[taskId] = {
                active: false,
                mandatory: false,
                taskId,
                processId,
                processActiveStepId: process.activeStepId,
            };
        }

        activeSections.forEach((id) => {
            /** Get the process and the step where that section is and get the tasks configuration */
            const step: ProcessStepWebApp = processSections[id];
            if (step.type !== 'canvas') {
                return;
            }
            const configuration = step.tasksConfiguration[taskId];
            if (configuration) {
                tasksMap[taskId] = {
                    ...configuration,
                    active: true,
                    processId,
                    processActiveStepId: process.activeStepId,
                };
            } else {
                tasksMap[taskId] = {
                    active: true,
                    mandatory: false,
                    taskId,
                    processId,
                    processActiveStepId: process.activeStepId,
                };
            }
        });
    });
    return tasksMap;
}

export interface TasksStepConfiguration {
    [taskId: string]: TaskStepConfiguration;
}

export interface TaskStepConfiguration extends StepTaskConfiguration {
    processId: string;
    processActiveStepId: string;
    active: boolean;
}
