import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Template, TemplateType } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, of, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { AppState } from '../../app.store';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { PaginatedList } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { SelectedProjectService } from '../project/selectedProject.service';
import { SelectedProjectGroupService } from '../projectGroup/selectedProjectGroup.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { TemplateCategoryListItem, TemplateListFilters } from './template-list.model';
import { TemplatesLoadedAction } from './template-list.reducer';

@Injectable({ providedIn: 'root' })
export class TemplateListService extends PaginatedListLoader<Template, TemplateListFilters, void> {
    readonly workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly templateType$: BehaviorSubject<TemplateType> = new BehaviorSubject<TemplateType>('ruum');
    readonly list$ = new Observable<Template[]>(undefined);

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        protected authService: AuthService,
        private selectedProjectGroupService: SelectedProjectGroupService,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private selectedProjectService: SelectedProjectService,
    ) {
        super(alertService, authService);
        this.setUpObservables();
        this.loadList();
    }

    protected getFilters$(): Observable<TemplateListFilters> {
        return combineLatest([this.workspaceId$, this.templateType$]).pipe(
            map(([workspaceId, type]) => ({
                workspaceId,
                type,
            })),
        );
    }

    protected getData(page: number, filters: TemplateListFilters): Observable<PaginatedList<Template>> {
        return this.readModelConnector.getTemplates({ page }, filters);
    }

    categorizedList(type: TemplateType = 'ruum'): Observable<TemplateCategoryListItem[]> {
        this.templateType$.next(type);
        const selectedGroupId = this.selectedProjectGroupService.getSelectedGroupId();
        const selectedWorkspaceId = this.selectedWorkspaceService.getSelectedWorkspaceId();

        return this.store.select('templateList').pipe(
            map((state) => {
                return type === 'ruum' ? state.categoryTemplates : state.categorySectionTemplates;
            }),
        );
    }

    getAllRuumTemplates(): Observable<TemplateCategoryListItem[]> {
        return this.categorizedList('ruum');
    }

    getPublishedRuumTemplates(): Observable<TemplateCategoryListItem[]> {
        return this.getAllRuumTemplates().pipe(
            map((categories) => {
                const selectedGroupId = this.selectedProjectGroupService.getSelectedGroupId();
                const selectedWorkspaceId = this.selectedWorkspaceService.getSelectedWorkspaceId();

                return categories
                    .map((category) => {
                        /**
                         * IF (!selectedWorkspaceId && !selectedGroupId) then show All non-WS templates and All WS templates
                         * IF (!selectedWorkspaceId && selectedGroupId) then show All non-WS templates
                         * IF (selectedWorkspaceId) then show All non-WS templates and templates of Selected WS
                         */
                        let filteredTemplates = [];
                        if (!selectedWorkspaceId && !selectedGroupId) {
                            filteredTemplates = category.templates;
                        }

                        if (!selectedWorkspaceId && selectedGroupId) {
                            filteredTemplates = category.templates.filter((template) => !template.workspaceId);
                        }
                        if (selectedWorkspaceId) {
                            filteredTemplates = category.templates.filter(
                                (template) => template.workspaceId === selectedWorkspaceId || !template.workspaceId,
                            );
                        }

                        return {
                            ...category,
                            templates: filteredTemplates
                                .slice()
                                .sort((templateA, templateB) => templateA.changedAt - templateB.changedAt),
                        };
                    })
                    .map((category) => {
                        return {
                            ...category,
                            templates: category.templates.filter((template) => !!template.publishedProjectId),
                        };
                    })
                    .filter((category) => category.templates.length !== 0);
            }),
        );
    }

    getAllSectionTemplates(): Observable<TemplateCategoryListItem[]> {
        return this.categorizedList('section');
    }

    getPublishedSectionTemplates(): Observable<TemplateCategoryListItem[]> {
        return this.getAllSectionTemplates().pipe(
            map((categories) => {
                const selectedWorkspaceId = this.selectedWorkspaceService.getSelectedWorkspaceId();

                return categories
                    .map((category) => {
                        /**
                         * IF (!selectedWorkspaceId && selectedGroupId) then show All non-WS templates
                         * IF (selectedWorkspaceId) then show All non-WS templates and templates of Selected WS
                         */

                        let filteredTemplates = [];
                        // if the template is shared, but the user doesn't have access to the workspace, the user should still see this template.
                        if (category.id === 'shared') {
                            const project = this.selectedProjectService.getSelectedProject();
                            if (!selectedWorkspaceId) {
                                filteredTemplates = category.templates.filter(
                                    (template) => !template.workspaceId || template.workspaceId === project.workspaceId,
                                );
                            }
                            if (selectedWorkspaceId) {
                                filteredTemplates = category.templates.filter(
                                    (template) => template.workspaceId === selectedWorkspaceId || !template.workspaceId,
                                );
                            }
                        } else {
                            if (!selectedWorkspaceId) {
                                filteredTemplates = category.templates.filter((template) => !template.workspaceId);
                            }
                            if (selectedWorkspaceId) {
                                filteredTemplates = category.templates.filter(
                                    (template) => template.workspaceId === selectedWorkspaceId || !template.workspaceId,
                                );
                            }
                        }
                        return {
                            ...category,
                            templates: filteredTemplates.sort(
                                (templateA, templateB) => templateA.changedAt - templateB.changedAt,
                            ),
                        };
                    })
                    .map((category) => {
                        return {
                            ...category,
                            templates: category.templates.filter((template) => !!template.publishedProjectId),
                        };
                    })
                    .filter((category) => category.templates.length !== 0);
            }),
        );
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<TemplatesLoadedAction>('TEMPLATES_LOADED', { page });
        });
    }

    getStoreData$() {
        return of(getDefaultPaginatedList<TemplateCategoryListItem>());
    }

    getStoreRows$() {
        return of([]);
    }

    getCategoriesCreatedByYou(
        allCategories$: Observable<TemplateCategoryListItem[]>,
        search$: Observable<string>,
    ): Observable<TemplateCategoryListItem[]> {
        return combineLatest([allCategories$, search$]).pipe(this.ownedCategories(), this.searchCategories());
    }

    getCategoriesSharedWithYou(
        allCategories$: Observable<TemplateCategoryListItem[]>,
        search$: Observable<string>,
    ): Observable<TemplateCategoryListItem[]> {
        return combineLatest([allCategories$, search$]).pipe(this.sharedCategories(), this.searchCategories());
    }

    getOtherCategories(
        allCategories$: Observable<TemplateCategoryListItem[]>,
        search$: Observable<string>,
    ): Observable<TemplateCategoryListItem[]> {
        return combineLatest([allCategories$, search$]).pipe(this.otherCategories(), this.searchCategories());
    }
    getWorkspaceCategories(
        allCategories$: Observable<TemplateCategoryListItem[]>,
        search$: Observable<string>,
    ): Observable<TemplateCategoryListItem[]> {
        return combineLatest([allCategories$, search$]).pipe(this.searchCategories());
    }

    isCategoryCreatedByYouEmpty(allCategories$: Observable<TemplateCategoryListItem[]>): Observable<boolean> {
        return allCategories$.pipe(this.emptyCategory('owned'));
    }

    isCategorySharedWithYouEmpty(allCategories$: Observable<TemplateCategoryListItem[]>): Observable<boolean> {
        return allCategories$.pipe(this.emptyCategory('shared'));
    }

    private searchCategories() {
        return pipe(
            map(([categories, search]) => {
                return categories
                    .map((category) => ({
                        ...category,
                        templates: category.templates.filter(
                            (template) => !!template.name.toLocaleLowerCase().match(search.toLocaleLowerCase()),
                        ),
                    }))
                    .filter((category) => category.templates.length !== 0);
            }),
        );
    }

    private ownedCategories() {
        return pipe(map(([categories, search]) => [categories.filter((category) => category.id === 'owned'), search]));
    }

    private sharedCategories() {
        return pipe(map(([categories, search]) => [categories.filter((category) => category.id === 'shared'), search]));
    }

    private otherCategories() {
        return pipe(
            map(([categories, search]) => [
                categories.filter((category) => category.id !== 'owned' && category.id !== 'shared'),
                search,
            ]),
        );
    }

    private emptyCategory(categoryId) {
        return pipe(
            map((categories: any) => {
                const ownedCategories = categories.filter(
                    (category) => category.id === categoryId && category.templates.length !== 0,
                );
                return ownedCategories.length === 0;
            }),
        );
    }
}
