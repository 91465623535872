import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { FileUploadOptions } from '@ruum/ruum-reducers';
import { Observable, Subscription } from 'rxjs';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { TabOption } from '../common/components/tabs.component';

@Component({
    selector: 'security-widget',
    template: `
        <ruum-admin-widget>
            <div details>
                <ruum-tabs
                    [options]="options"
                    (tabChanged)="tabChanged($event)"
                    [activeIndex]="activeTabIndex"
                ></ruum-tabs>
                <security-oauth-tab *ngIf="activeTabId === 'oauth'"></security-oauth-tab>
            </div>
        </ruum-admin-widget>
    `,
})
export class SecurityWidgetComponent implements OnDestroy {
    options: TabOptionWithUrl[] = [
        {
            id: 'oauth',
            url: 'oauth',
            text: 'OAuth',
        },
    ];
    activeTabId: string = this.options[0].id;
    activeTabIndex = 0;

    form: FormGroup;
    fileUploadOptions$: Observable<FileUploadOptions>;

    formFields = [
        { name: 'ruum', label: 'My Device' },
        { name: 'external', label: 'Link' },
        { name: 'onedrive', label: 'OneDrive / Sharepoint' },
        { name: 'google_drive', label: 'Google Drive' },
        { name: 'box', label: 'Box' },
        { name: 'youtube', label: 'Youtube' },
    ];
    private enterpriseId: string; // workaround
    private subscriptions: Subscription[] = [];

    constructor(private router: Router, private profileService: ProfileService) {
        this.subscriptions.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const url = window.location.pathname;
                    const index: number = this.options.findIndex((option) => url.indexOf(option.url) >= 0);
                    if (index !== -1) {
                        this.tabChangedToIndex(index);
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.profileService
                .getCurrentUserEnterpriseId$()
                .subscribe((enterpriseId) => (this.enterpriseId = enterpriseId)),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    tabChanged(activeTabId: string) {
        const index: number = this.options.findIndex((el) => el.id === activeTabId);
        const option: TabOptionWithUrl = this.options[index];
        if (option) {
            this.router.navigateByUrl(`/admin/enterprise/${this.enterpriseId}/${option.url}`);
        }
    }

    tabChangedToIndex(index: number) {
        this.activeTabIndex = index;
        this.activeTabId = this.options[index].id;
    }
}

export interface TabOptionWithUrl extends TabOption {
    url: string;
}
