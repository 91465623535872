import { RuumAction, Workspace, WorkspaceAction, WorkspaceReducer } from '@ruum/ruum-reducers';

export const WORKSPACE_WEB_APP_ACTION_TYPES = {
    SELECT_WORKSPACE: 'SELECT_WORKSPACE',
};

export function SelectedWorkspaceReducer(currentState: Workspace, action: WorkspaceAction): Workspace {
    switch (action.type) {
        case WORKSPACE_WEB_APP_ACTION_TYPES.SELECT_WORKSPACE:
            return action.payload;
        default:
            return callWorkspaceReducer(currentState, { ...action });
    }
}

function callWorkspaceReducer(currentState: Workspace, action: RuumAction): Workspace {
    /**
     * Will only accept actions that were sent by SelectedWorkspaceService.
     * This is needed because both RuumReducer and WorkspaceReducer accept CustomFieldDefinitionActionTypes.
     */
    if (action.entityType === 'workspace') {
        return WorkspaceReducer(currentState, action);
    } else {
        return currentState;
    }
}
