import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-task-list-cell-group',
    template: `
        <div
            (click)="$event.stopPropagation()"
            (keydown.space)="$event.stopPropagation()"
            (keydown.enter)="$event.stopPropagation()"
        >
            <a class="cursor-pointer d-flex minw-0" [routerLink]="getGroupLink()" [tabindex]="task.groupName?0:-1">
                <span
                    class="small text-dark text-truncate pr-4"
                    [ngbTooltip]="groupTooltipContent"
                    [placement]="['top', 'bottom', 'auto']"
                >
                    {{ task.groupName }}
                </span>
            </a>
        </div>

        <ng-template #groupTooltipContent>
            <div class="small interface">{{ task.groupName }}</div>
            <i class="small" style="color: #FFFFFF; opacity: 0.56;">Click to go to Group</i>
        </ng-template>
    `,
})
export class DataCellGroupComponent {
    @HostBinding() class = 'd-flex align-items-center w-100';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }

    task: any = {};

    constructor() {}

    getGroupLink() {
        return this.task.groupId ? `/projectgroups/${this.task.groupId}/tasks` : '';
    }
}
