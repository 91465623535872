import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { SavedViewFieldsService } from '../../../../common/connectors/savedView/fields/saved-view-fields.service';
import { SavedViewFieldDefinition } from '../../../../common/connectors/savedView/saved-views.model';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-filter-field-select',
    template: `
        <ruum-select
            [select]="select"
            [search]="showSearch"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [placement]="placement"
            [lightBackground]="false"
            [disabled]="disabled"
            [loading]="showLoading$ | async"
            (loadMoreOptions)="loadMore()"
            (isOpenChange)="isOpenChange($event)"
            (searchChange)="searchChange($event)"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-placeholder>
                {{ placeholder }}
            </ruum-select-placeholder>

            <ruum-select-option
                *ngFor="let option of options$ | async; trackBy: trackByOption"
                [value]="option.fieldId"
                [content]="option.title"
                [icon]="option.typeId | ruumObjectTypeToIcon"
                [description]="option.description"
            ></ruum-select-option>
        </ruum-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldSelectComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex', this.componentClass]);
    }

    // Component properties
    @Input() excludedIds: string[] = [];
    @Input() includedIds: string[] = [];
    @Input() selectedOptionIds: string[] = [];
    @Input() multiSelect = false;
    @Input() placeholder = 'Select Field';
    @Input() showSearch = true;
    @Input() placement = ['bottom-left', 'top-left'];

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    options$: Observable<SavedViewFieldDefinition[]>;
    showLoading$: Observable<boolean>;

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIds;
        } else {
            return this.selectedOptionIds[0];
        }
    }

    constructor(private savedViewFieldsService: SavedViewFieldsService) {}

    ngOnInit() {
        const excludedIds: string[] = this.excludedIds.filter((id) => this.selectedOptionIds.indexOf(id) === -1);
        this.options$ = this.savedViewFieldsService.getFields$({ excludedIds, relativeTo: 'filters' });
        this.showLoading$ = this.savedViewFieldsService.hasMore();
    }

    ngOnDestroy() {
        this.savedViewFieldsService.stopLoad();
    }

    isOpenChange(open) {
        if (open) {
            this.savedViewFieldsService.startLoad();
        } else {
            this.savedViewFieldsService.stopLoad();
        }
    }

    searchChange(search: string) {
        this.savedViewFieldsService.search(search);
    }

    loadMore() {
        this.savedViewFieldsService.loadMore();
    }

    selectChange(event) {
        this.changed.emit(event);
    }

    trackByOption(index: number, item) {
        return item.fieldId;
    }
}
