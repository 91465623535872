import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PGParticipant, ProjectGroup, ProjectGroupAction, ProjectGroupParticipantRole } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { PROJECT_GROUP_WEB_APP_ACTION_TYPE } from '../../../lobby/project-group/projectGroupWrapper.reducer';
import { SELECTED_LOBBY_LIST_ITEM_PG_REDUCER_ACTIONS } from '../../../project/selectedLobbyListItem.reducer';
import { AppState } from '../../app.store';
import { RuumAlertService } from '../../components/alert/alert.service';
import { StoreExceptionCatcher } from '../../storeExceptionCatcher.service';
import { EntityChangesListener } from '../changesListener.service';
import { LobbyListItemProjectGroup } from '../lobbyList/lobby-list.model';
import { LobbyListService } from '../lobbyList/lobby-list.service';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { PGParticipantWithAllRoles, ReadModelBackendConnector } from '../readModelConnector.service';
import { SelectedEntityService } from '../selectedEntity';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { SelectedProjectGroupHelper } from './selectedProjectGroup.helper';

@Injectable({ providedIn: 'root' })
export class SelectedProjectGroupService extends SelectedEntityService {
    constructor(
        protected authService: AuthService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected store: Store<AppState>,
        protected router: Router,
        protected storeExceptionCatcher: StoreExceptionCatcher,
        protected alertService: RuumAlertService,
        protected changesListener: EntityChangesListener,
        protected readModelBackendConnector: ReadModelBackendConnector,
        private selectedWorkspace: SelectedWorkspaceService,
        private lobbyListService: LobbyListService,
        private titleService: Title,
        private helper: SelectedProjectGroupHelper,
    ) {
        super(
            authService,
            projectServiceConnector,
            store,
            router,
            storeExceptionCatcher,
            alertService,
            changesListener,
            'project_group',
        );
        this.helper.initialize(
            this.persistedActions(),
            () => this.getSelectedGroupId(),
            this.selectedProjectGroup(),
            () => this.reloadListItem(),
        );
    }

    async selectProjectGroup(groupId: string): Promise<ProjectGroupSelectData> {
        this.unselectProjectGroup();

        const { group, lobbyListItem } = await this.getData(groupId);
        /** The user might not have access to the group itself, only to some of the projects inside of it. */
        if (group) {
            this.selectEntity(group);
            if (group.workspaceId) {
                await this.selectedWorkspace.selectWorkspace(group.workspaceId);
            } else {
                this.selectedWorkspace.unselectWorkspace();
                this.lobbyListService.filterByWorkspaceId(undefined);
            }
        } else {
            this.selectDummyGroup();
        }

        this.titleService.setTitle(`${lobbyListItem.name}`);
        this.store.dispatch({
            type: SELECTED_LOBBY_LIST_ITEM_PG_REDUCER_ACTIONS.LOAD_LOBBY_LIST_ITEM_PROJECT_GROUP,
            payload: lobbyListItem,
        });

        this.helper.selectProjectGroup(groupId);

        return { group, lobbyListItem };
    }

    async reloadListItem() {
        const groupId = this.getSelectedGroupId();
        const lobbyListItem: LobbyListItemProjectGroup = await this.readModelBackendConnector
            .getLobbyListItemProjectGroup(groupId)
            .toPromise();
        this.store.dispatch({
            type: SELECTED_LOBBY_LIST_ITEM_PG_REDUCER_ACTIONS.LOAD_LOBBY_LIST_ITEM_PROJECT_GROUP,
            payload: lobbyListItem,
        });
    }

    protected dispatchSelectEntity(group: ProjectGroup) {
        this.store.dispatch(this.getAction(PROJECT_GROUP_WEB_APP_ACTION_TYPE.SELECT_PROJECT_GROUP, group, {}));
    }

    private async getData(groupId: string): Promise<ProjectGroupSelectData> {
        const [group, lobbyListItem] = ((await Promise.all([
            this.projectServiceConnector.getProjectGroup(groupId).catch((err) => {}),
            this.readModelBackendConnector.getLobbyListItemProjectGroup(groupId).toPromise(),
        ])) as any) as [ProjectGroup, LobbyListItemProjectGroup];

        if (lobbyListItem) {
            return {
                group,
                lobbyListItem,
            };
        } else {
            throw new Error(`You don't have access to group ${groupId}`);
        }
    }

    protected reload(groupId: string) {
        this.selectProjectGroup(groupId).catch((err) => {
            this.router.navigateByUrl('/home');
        });
    }

    persistAction<T extends ProjectGroupAction>(type: T['type'], payload: T['payload']): Promise<void> {
        const workspaceRole = this.helper.getWorkspaceRole();
        const role = this.getLoggedUserRole();
        return super.persistAction(type, payload, { workspaceRole, role });
    }

    removedLoggeduserFromGroup() {
        const groupId = this.getSelectedGroupId();
        const userId = this.authService.getLoggedUser().id;
        /** this call might fail, e.g. if the user is the last admin */
        return this.projectServiceConnector.removeParticipantFromProjectGroup(groupId, userId).then(() => {
            this.stopListeningToChanges();
        });
    }

    unselectProjectGroup() {
        /** LobbyListItem is not removed from the store so that the top navigation bar doesn't jump. */
        this.selectDummyGroup();
        this.helper.unselectProjectGroup();
        this.stopListeningToChanges();
    }

    /** To avoid null pointers. */
    private selectDummyGroup() {
        this.store.dispatch({
            type: PROJECT_GROUP_WEB_APP_ACTION_TYPE.SELECT_PROJECT_GROUP,
            payload: {
                participants: [],
            },
        });
    }

    /*****************************************************/

    /**
     * This part of the code are helpers to the selected state in the store.
     */
    getSelectedGroupId(): string {
        return this.getSeletedEntityId();
    }

    getSelectedProjectGroup(): ProjectGroup {
        let group: ProjectGroup;
        this.selectedProjectGroup()
            .pipe(take(1))
            .subscribe((g) => (group = g));
        return group;
    }

    participants(): Observable<PGParticipantWithAllRoles[]> {
        return this.helper.participants();
    }

    technicalUsers(): Observable<PGParticipantWithAllRoles[]> {
        return this.helper.technicalUsers();
    }

    /** If the logged user is an admin in the selected Group */
    isAdmin(): Observable<boolean> {
        return this.loggedUserParticipant().pipe(map((p) => p.roles.indexOf('ProjectGroupAdmin') !== -1));
    }

    isViewer(): Observable<boolean> {
        return this.loggedUserParticipant().pipe(map((p) => p.roles.indexOf('ProjectGroupViewer') !== -1));
    }

    isReadOnly(): Observable<boolean> {
        return this.loggedUserParticipant().pipe(
            map((p) => p.roles.indexOf('ProjectGroupViewer') !== -1 || p.roles.length === 0),
        );
    }

    /** Logged user group participant */
    loggedUserParticipant(): Observable<PGParticipant> {
        return this.helper.loggedUserParticipant();
    }

    getLoggedUserRole(): ProjectGroupParticipantRole {
        const participant = this.helper.getLoggedUserParticipant();
        return <ProjectGroupParticipantRole>(participant && participant.roles[0]);
    }

    workspaceMembers(): Observable<PGParticipantWithAllRoles[]> {
        return this.helper.workspaceMembers();
    }

    selectedProjectGroup(): Observable<ProjectGroup> {
        return this.store.select('selectedProjectGroup');
    }

    groupCreator(): Observable<PGParticipant> {
        return this.selectedProjectGroup().pipe(
            map((group) => group.participants.find((p) => p.id === group.createdBy)),
        );
    }

    currentParticipant(): Observable<PGParticipant> {
        return this.selectedProjectGroup().pipe(
            map((group) => group.participants.find((p) => p.id === this.authService.getLoggedUser().id)),
        );
    }

    lobbyListItem(): Observable<LobbyListItemProjectGroup> {
        return this.store.select('selectedLobbyListItemProjectGroup');
    }

    loadGroupParticipants() {
        this.helper.loadGroupParticipants();
    }

    loadWSMembers(groupId: string) {
        this.helper.loadWSMembers(groupId);
    }
}

export interface ProjectGroupSelectData {
    group?: ProjectGroup;
    lobbyListItem: LobbyListItemProjectGroup;
}
