import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ProjectParticipantWithAllRoles } from '../../connectors/readModelConnector.service';
import { AddCommentService } from '../addComment.service';

@Component({
    selector: 'at-mention-list',
    template: `
        <div class="position-relative">
            <ul class="mention-list shadow bg-white rounded p-1" *ngIf="(mentionOptions$ | async)?.length">
                <div class="d-flex flex-fill flex-column px-1" style="max-height: 412px; overflow-y: auto;">
                    <ruum-task-assignees-dropdown-item
                        *ngFor="let assignee of mentionOptions$ | async; let index = index; let last = last"
                        [focused]="(mentionOptionsFocusIndex$ | async) === index"
                        [class.mb-1]="!last"
                        [id]="assignee.id"
                        [name]="assignee.fullName"
                        [color]="assignee.color"
                        [selected]="false"
                        [attr.data-index]="index"
                        (mousedown)="mentionParticipant(assignee); $event.preventDefault()"
                        (touchend)="mentionParticipant(assignee); $event.preventDefault()"
                        (mouseleave)="mouseLeave(index)"
                    >
                    </ruum-task-assignees-dropdown-item>
                </div>
            </ul>
        </div>
    `,
    styles: [
        `
            .mention-list {
                width: 300px;
                position: absolute;
                bottom: 0;
                margin: 0;
                overflow-y: auto;
            }
        `,
    ],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantAtMentionListComponent implements OnInit {
    mentionOptions$: Observable<any>;
    mentionOptionsFocusIndex$: Observable<number>;
    destroy$: Subject<null> = new Subject();
    @ViewChild('list', { static: false }) private mentionList: ElementRef;
    mouseHoverDisabled = false;

    allAssignees$: Observable<ProjectParticipantWithAllRoles[]>;
    projectAssignees$: Observable<ProjectParticipantWithAllRoles[]>;
    otherAssignees$: Observable<ProjectParticipantWithAllRoles[]>;

    constructor(private addCommentService: AddCommentService) {}

    ngOnInit() {
        this.allAssignees$ = this.addCommentService.atMentionSearch();
        this.projectAssignees$ = this.getProjectAssignees();
        this.otherAssignees$ = this.getOtherAssignees();

        this.mentionOptions$ = this.addCommentService.atMentionSearch();
        this.mentionOptionsFocusIndex$ = this.addCommentService.atMentionFocusIndex();
        this.scrollOnKeyboard();
    }

    private scrollOnKeyboard() {
        this.mentionOptionsFocusIndex$
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.destroy$),
                filter((index) => !isNaN(index)),
            )
            .subscribe((index) => {
                this.mouseHoverDisabled = true;
                if (this.mentionList) {
                    const container = this.mentionList.nativeElement as HTMLElement;
                    const item = container && (container.querySelector(`[data-index="${index}"]`) as HTMLElement);
                    if (!item) {
                        return;
                    }
                    const top = item.offsetTop;
                    const height = item.offsetHeight;
                    const containerHeight = container.offsetHeight;
                    const containerScroll = container.scrollTop;
                    if (top < containerScroll) {
                        container.scrollTo(0, top);
                    } else if (top > containerHeight + containerScroll - height) {
                        container.scrollTo(0, top - containerHeight + height);
                    }
                }
            });
    }

    mentionParticipant(participant: ProjectParticipantWithAllRoles) {
        this.addCommentService.mentionParticipant(participant);
        this.addCommentService.searchAtMention(undefined);
    }

    mouseEnter(index: number) {
        if (!this.mouseHoverDisabled) {
            this.addCommentService.setAtMentionFocusIndex(index);
        }
    }

    mouseLeave(index: number) {
        if (!this.mouseHoverDisabled) {
            this.addCommentService.setAtMentionFocusIndex(NaN);
        }
    }

    private getProjectAssignees(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.allAssignees$.pipe(
            map((allAssignees) => {
                return allAssignees.filter((participant) => participant.projectParticipantRole);
            }),
        );
    }

    private getOtherAssignees(): Observable<ProjectParticipantWithAllRoles[]> {
        return this.allAssignees$.pipe(
            map((allAssignees) => {
                return allAssignees.filter((participant) => !participant.projectParticipantRole);
            }),
        );
    }
}
