import { RuumAction } from '@ruum/ruum-reducers';
import { WorkspaceListItem } from '../readModelConnector.service';

export const WORKSPACE_LIST_ITEM_ACTION_TYPES = {
    LOAD_WORKSPACE_LIST_ITEM: 'LOAD_WORKSPACE_LIST_ITEM',
    CHANGE_WORKSPACE_NAME: 'CHANGE_WORKSPACE_NAME',
};

export function WorkspaceListItemReducer(currentState: WorkspaceListItem, action: RuumAction): WorkspaceListItem {
    switch (action.type) {
        case WORKSPACE_LIST_ITEM_ACTION_TYPES.LOAD_WORKSPACE_LIST_ITEM:
            return action.payload;
        case WORKSPACE_LIST_ITEM_ACTION_TYPES.CHANGE_WORKSPACE_NAME:
            return renameWorkspace(currentState, action);
        default:
            return currentState;
    }
}

function renameWorkspace(currentState: WorkspaceListItem, action: RuumAction): WorkspaceListItem {
    const payload = action.payload;
    if (currentState.id === payload.id) {
        return {
            ...currentState,
            name: payload.name,
        };
    } else {
        return currentState;
    }
}
