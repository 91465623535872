import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumA11yModule } from '../../../common/a11y/a11y.module';
import { RuumBootstrapModule } from '../../../common/ruum-bootstrap/ruum-bootstrap.module';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { ProjectGroupCreateModule } from '../../project-group/project-group-create/project-group-create.module';
import { DropdownModule } from '../../ui-components/dropdown/dropdown.module';
import { ProjectDuplicateModule } from '../project-duplicate/project-duplicate.module';
import { ProjectMoveToGroupModule } from '../project-move-to-group/project-move-to-group.module';
import { ProjectRenameModule } from '../project-rename/project-rename.module';
import { ProjectMenuComponent } from './project-menu.component';

@NgModule({
    imports: [
        CommonModule,
        ModalDialogModule,
        DropdownModule,
        RuumBootstrapModule,
        ProjectRenameModule,
        ProjectDuplicateModule,
        RuumA11yModule,
        ProjectGroupCreateModule,
        ProjectMoveToGroupModule,
    ],
    declarations: [ProjectMenuComponent],
    exports: [ProjectMenuComponent],
})
export class ProjectMenuModule {}
