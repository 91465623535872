import { Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { GenericDataSource } from '../../common/connectors/paginatedListLoader';
import { WebhookListService } from './webhook-list.service';
import { WebhookConfigurationListItem } from './webhooks.model';

@Component({
    selector: 'ruum-admin-webhook-list',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <ruum-view-controls>
                    <div class="w-25">
                        <ruum-search
                            [size]="'sm'"
                            [value]="search"
                            ariaLabel="Search for webhook"
                            (changed)="onChangeSearchValue($event)"
                        ></ruum-search>
                    </div>
                    <div class="d-flex flex-fill justify-content-end">
                        <button
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-primary float-right"
                            type="button"
                            routerLink="./new"
                        >
                            <i class="mr-1 icon icon-add"></i>
                            <span>New</span>
                        </button>
                    </div>
                </ruum-view-controls>

                <!-- empty state: no items yet, no search result -->
                <ruum-empty-state
                    *ngIf="(rows$ | async)?.length === 0 && (isLoadingFirstPage$ | async) !== true"
                    [searchApplied]="search?.length > 0"
                    [entityName]="'Webhooks'"
                >
                    <ruum-illustration-system-connectors
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-system-connectors>
                </ruum-empty-state>

                <div
                    *ngIf="(rows$ | async)?.length > 0"
                    class="d-flex flex-column px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5"
                >
                    <table class="w-100" cdk-table [dataSource]="dataSource">
                        <!-- Name Column -->
                        <ng-container cdkColumnDef="name">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'name'"
                                    [label]="'Name'"
                                    [sorting]="isSortBy('name')"
                                    [direction]="getSortDirection('name')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">
                                    <a
                                        routerLink="/admin/enterprise/{{ currentUserEnterpriseId$ | async }}/webhooks/{{
                                            element.id
                                        }}"
                                    >
                                        {{ element.name }}
                                    </a>
                                </span>
                            </td>
                        </ng-container>

                        <!-- Url Column -->
                        <ng-container cdkColumnDef="url">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'url'"
                                    [label]="'url'"
                                    [sorting]="isSortBy('urlTitle')"
                                    [direction]="getSortDirection('urlTitle')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.url }}</span>
                            </td>
                        </ng-container>

                        <!-- Type Column -->
                        <ng-container cdkColumnDef="type">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'type'"
                                    [label]="'Trigger type'"
                                    [sorting]="isSortBy('type')"
                                    [direction]="getSortDirection('type')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">
                                    {{ element.type === 'WORKSPACE' ? 'Workspace' : 'Technical User' }}
                                </span>
                            </td>
                        </ng-container>

                        <!-- entityId Column -->
                        <ng-container cdkColumnDef="entityId">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'entityId'"
                                    [label]="'Trigger entityId'"
                                    [sorting]="isSortBy('entityId')"
                                    [direction]="getSortDirection('entityId')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">
                                    {{ element.workspaceId || element.userId }}
                                </span>
                            </td>
                        </ng-container>

                        <!-- entities Column -->
                        <ng-container cdkColumnDef="entities">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'entityId'"
                                    [label]="'View Entities'"
                                    [sorting]="isSortBy('entityId')"
                                    [direction]="getSortDirection('entityId')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">
                                    <button
                                        routerLink="/admin/enterprise/{{ currentUserEnterpriseId$ | async }}/webhooks/{{
                                            element.id
                                        }}/entities"
                                        class="btn btn-sm btn-outline-secondary"
                                    >
                                        <i class="icon icon-subtask"></i>
                                    </button>
                                </span>
                            </td>
                        </ng-container>

                        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns"
                            class="border-bottom border-light hov hov-bg-primary-light"
                        ></tr>
                    </table>

                    <!-- spinner: initial fetching -->
                    <ruum-load-spinner
                        classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                        *ngIf="isLoadingFirstPage$ | async"
                    ></ruum-load-spinner>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="loadMore()"
                    ></ruum-load-more>
                </div>
            </div>
        </ruum-max-content>
    `,
    styles: [],
})

// <ruum-load-more *ngIf="showLoading$ | async" (loadMore)="loadMore()"></ruum-load-more>
export class WebhookListComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column w-100 mw-100 h-100 mh-100 overflow-y';

    rows$: Observable<WebhookConfigurationListItem[]> = of([]);
    enterpriseId$: Observable<string>;
    private subscriptions: Subscription[] = [];
    isLoadingFirstPage$: Observable<boolean>;
    isLoadingAnotherPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;
    hasMore$: Observable<boolean>;
    isCurrentUserSuperAdmin$: Observable<boolean>;
    currentUserEnterpriseId$: Observable<string>;
    dataSource: GenericDataSource<WebhookConfigurationListItem>;
    displayedColumns: string[] = ['name', 'url', 'type', 'entityId', 'entities'];

    search = '';

    constructor(
        private webhookListService: WebhookListService,
        private elementRef: ElementRef,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    ngOnInit() {
        this.webhookListService.loadList();
        this.rows$ = this.webhookListService.getStoreData$().pipe(
            filter((page) => !!page),
            pluck('rows'),
        );
        this.isLoadingFirstPage$ = this.webhookListService.isLoadingFirstPage$;
        this.isLoadingAnotherPage$ = this.webhookListService.isLoadingAnotherPage$;
        this.showLoading$ = this.webhookListService.showLoading$;
        this.hasMore$ = this.webhookListService.hasMore$;
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();
        this.currentUserEnterpriseId$ = this.selectedEnterpriseService.selectedEnterpriseId();
        this.dataSource = this.webhookListService.getDataSource();
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }

    loadMore() {
        this.webhookListService.maybeGoToNextPage();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subs) => subs.unsubscribe());
    }

    back() {
        history.back();
    }

    scrolledToBottom() {
        this.webhookListService.maybeGoToNextPage();
    }

    onChangeSearchValue(event) {
        // TODO: implement search
    }

    isSortBy() {
        return false;
    }
    getSortDirection() {
        return false;
    }
    sort() {}
}
