import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MoveKanbanCardAction, RuumAction, RuumActionTypes } from '@ruum/ruum-reducers';
import { ACTION_TYPES } from '../actions';
import { PaginatedList } from '../connectors/paginatedList.model';
import { TaskListReducer } from '../connectors/tasks/task-list.reducer';
import { KanbanClearActionPayload, KanbanEmptyActionPayload, KanbanLoadedActionPayload } from './kanban.actions';
import { KanbanListItem } from './kanban.model';

const initialState = {};

export function KanbanListReducer(currentState: KanbanListItem = initialState, action: RuumAction): KanbanListItem {
    switch (action.type) {
        case ACTION_TYPES.KANBAN_LOADED: {
            return kanbanLoaded(currentState, action as KanbanLoadedActionPayload);
        }
        case ACTION_TYPES.KANBAN_EMPTY: {
            return kanbanEmpty(currentState, action);
        }
        case ACTION_TYPES.KANBAN_CLEAR: {
            return kanbanClear(currentState, action);
        }
        case RuumActionTypes.SET_TASK_STATUS: {
            return setTaskStatus(currentState, action);
        }
        case RuumActionTypes.MOVE_KANBAN_CARD: {
            return kanbanMoveCard(currentState, action);
        }
        case RuumActionTypes.SET_TASK_PRIORITY: {
            return setTaskPriority(currentState, action);
        }
        case RuumActionTypes.SET_TASK_IS_MILESTONE: {
            return setTaskMileStone(currentState, action);
        }
        case RuumActionTypes.DELETE_TASK: {
            return deleteTask(currentState, action);
        }

        default:
            return currentState;
    }
}

function kanbanLoaded(currentState: KanbanListItem = initialState, action: KanbanLoadedActionPayload): KanbanListItem {
    const groupId = action.payload.kanbanGroupId;

    if (!groupId) {
        return currentState;
    }

    const change = {};
    const tasklistAction = { ...action, type: ACTION_TYPES.TASK_LIST_LOADED };
    change[groupId] = TaskListReducer(currentState[groupId], tasklistAction);
    return {
        ...currentState,
        ...change,
    };
}

function kanbanEmpty(currentState: KanbanListItem, action: KanbanEmptyActionPayload): KanbanListItem {
    const groupId = action.payload.kanbanGroupId;

    if (groupId) {
        const { [groupId]: parentValue, ...rest } = currentState;
        return { ...currentState, ...rest };
    }

    const emptyList: KanbanListItem = {};
    for (const key of Object.keys(currentState)) {
        const pageSize = currentState[key].currentPage;
        const totalItems = currentState[key].totalItems;
        emptyList[key] = { currentPage: 1, rows: [], totalItems, pageSize };
    }

    return emptyList;
}

function kanbanClear(currentState: KanbanListItem, action: KanbanClearActionPayload): KanbanListItem {
    const groupId = action.payload.kanbanGroupId;

    if (groupId) {
        const { [groupId]: parentValue, ...rest } = currentState;
        return { ...currentState, ...rest };
    }

    return initialState;
}

function kanbanMoveCard(currentState: KanbanListItem, action: MoveKanbanCardAction): KanbanListItem {
    const { taskId, groupId, kanbanBeforeElement } = action.payload;
    const projectId = action.entityId;

    if (!taskId || !groupId) {
        return currentState;
    }

    const [optionId, foundedItem, index] = findItemInKanban(currentState, projectId, taskId);
    const currentPage: PaginatedList<string> = currentState[optionId];
    const currentRows = currentPage.rows;
    const change = {};
    let position: number;

    if (!kanbanBeforeElement) {
        position = currentRows.length;
    } else {
        const kanbanBeforeElementId = generatedId(projectId, kanbanBeforeElement);
        position = currentRows.findIndex((item) => item === kanbanBeforeElementId);
    }

    if (position < 0) {
        return currentState;
    }

    if (index < position) {
        position--;
    }

    const newRows = currentRows.map((i) => i);
    moveItemInArray(newRows, index, position);
    change[optionId] = { ...currentState[optionId], rows: newRows };

    return {
        ...currentState,
        ...change,
    };
}

function setTaskStatus(currentState: KanbanListItem = initialState, action: RuumAction): KanbanListItem {
    const { status, taskId, kanbanBeforeElement } = action.payload;
    const projectId = action.entityId;

    if (!taskId || !currentState[status]) {
        return currentState;
    }
    const [groupId, foundedItem] = findItemInKanban(currentState, projectId, taskId);
    const change = {};

    if (!groupId || !foundedItem) {
        return currentState;
    }
    const id = generatedId(projectId, taskId);
    const kanbanBeforeElementId = generatedId(projectId, kanbanBeforeElement);

    change[groupId] = removeItemOfKanban(currentState[groupId], foundedItem);
    change[status] = addItemToKanban(currentState[status], id, kanbanBeforeElementId);

    return {
        ...currentState,
        ...change,
    };
}

function setTaskPriority(currentState: KanbanListItem = initialState, action: RuumAction): KanbanListItem {
    const { priority: payloadPriority, taskId, kanbanBeforeElement } = action.payload;
    const projectId = action.entityId;
    const priority = payloadPriority ? payloadPriority : 'NO_PRIORITY';

    if (!taskId || !currentState[priority]) {
        return currentState;
    }

    const [groupId, foundedItem] = findItemInKanban(currentState, projectId, taskId);
    const change = {};

    if (!groupId || !foundedItem) {
        return currentState;
    }
    const id = generatedId(projectId, taskId);
    const kanbanBeforeElementId = generatedId(projectId, kanbanBeforeElement);

    change[groupId] = removeItemOfKanban(currentState[groupId], foundedItem);
    change[priority] = addItemToKanban(currentState[priority], id, kanbanBeforeElementId);
    return {
        ...currentState,
        ...change,
    };
}

function setTaskMileStone(currentState: KanbanListItem = initialState, action: RuumAction): KanbanListItem {
    const { taskId, isMilestone } = action.payload;
    const projectId = action.entityId;

    if (isMilestone) {
        const [groupId, foundedItem] = findItemInKanban(currentState, projectId, taskId);
        const change = {};

        if (!groupId || !foundedItem) {
            return currentState;
        }

        change[groupId] = removeItemOfKanban(currentState[groupId], foundedItem);
        return {
            ...currentState,
            ...change,
        };
    }
    return currentState;
}

function deleteTask(currentState: KanbanListItem = initialState, action: RuumAction): KanbanListItem {
    const { id: taskId } = action.payload;
    const projectId = action.entityId;

    if (projectId && taskId) {
        const [groupId, foundedItem] = findItemInKanban(currentState, projectId, taskId);
        const change = {};

        if (!groupId || !foundedItem) {
            return currentState;
        }

        change[groupId] = removeItemOfKanban(currentState[groupId], foundedItem);
        return {
            ...currentState,
            ...change,
        };
    }
    return currentState;
}

function addItemToKanban(
    plist: PaginatedList<string>,
    id: string,
    kanbanBeforeElementId?: string,
): PaginatedList<string> {
    const currentTotalItems = plist.totalItems + 1;
    const currentRows = plist.rows;
    if (kanbanBeforeElementId) {
        const position = currentRows.findIndex((item) => item === kanbanBeforeElementId);
        if (position < 0) {
            return plist;
        }
        const newRows = [...currentRows.slice(0, position), id, ...currentRows.slice(position)];
        return { ...plist, totalItems: currentTotalItems, rows: newRows };
    } else {
        return { ...plist, totalItems: currentTotalItems, rows: [...currentRows, id] };
    }
}

function removeItemOfKanban(plist: PaginatedList<string>, id: string): PaginatedList<string> {
    let foundedItem = false;
    const rows = plist.rows.filter((item) => {
        if (item === id) {
            foundedItem = true;
            return false;
        }
        return true;
    });
    const totalItems = foundedItem ? plist.totalItems - 1 : plist.totalItems;
    return { ...plist, totalItems, rows };
}

function findItemInKanban(currentState: KanbanListItem, projectId: string, id: string): [string, string, number] {
    const taskId = generatedId(projectId, id);
    for (const groupId of Object.keys(currentState)) {
        const [foundedItem, index] = findTaskInList(currentState[groupId], taskId);
        if (foundedItem) {
            return [groupId, foundedItem, index];
        }
    }
    return [null, null, null];
}

function findTaskInList(list: PaginatedList<string>, id: string): [string, number] {
    let foundedItem = null;
    let index = null;
    if (list) {
        foundedItem = list.rows.find((item, i) => {
            if (item === id) {
                index = i;
                return true;
            }
        });
    }
    return [foundedItem, index];
}

function generatedId(projectId: string, id: string): string {
    if (!projectId || !id) {
        return null;
    }
    return `${projectId}-${id}`;
}
