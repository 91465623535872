import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Day, TaskStatus } from '@ruum/ruum-reducers';
import {
    RuumDropdownItem,
    RuumDropdownList,
    RuumDropdownPlacement,
    RuumDropdownSections,
} from '../../ui-components/dropdown/dropdown.model';
import { TaskStatusService } from './task-status.service';

@Component({
    selector: 'ruum-milestone-status-menu',
    template: `
        <ruum-dropdown
            [placement]="placement"
            [width]="width"
            [sections]="getSections()"
            [selectedItems]="getSelectedItems(statusId)"
            [isReadOnly]="disabled"
            (selectItem)="changeStatus($event)"
            [container]="container"
        >
            <!-- desktop dropdown button -->
            <ng-container data-content="ruum-dropdown-button-desktop">
                <ruum-milestone-status
                    [statusId]="statusId"
                    aria-label="Select Milestone"
                    [showName]="showName"
                ></ruum-milestone-status>
            </ng-container>

            <!-- mobile dropdown button -->
            <ng-container data-content="ruum-dropdown-button-mobile">
                <ruum-milestone-status [statusId]="statusId" [showName]="showName"></ruum-milestone-status>
            </ng-container>
        </ruum-dropdown>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneStatusMenuComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() statusId: TaskStatus;
    @Input() dueDate: Day;
    @Input() showName = false;
    @Input() width = '144px';
    @Input() size = 'sm';
    @Input() placement: RuumDropdownPlacement = ['bottom-left', 'top-left', 'auto'];
    @Input() container: 'ruum-dropdown';
    @Input() disabled = false;
    @Output() statusChanged = new EventEmitter<TaskStatus>();

    constructor(private taskStatusService: TaskStatusService) {}

    ngOnInit() {}

    changeStatus(item: RuumDropdownItem) {
        this.statusChanged.emit(item.id);
    }

    getSections(): RuumDropdownSections {
        const list = this.taskStatusService.getMilestoneStatusList(this.dueDate);
        const section = [list];
        return section;
    }

    getSelectedItems(id: TaskStatus): RuumDropdownList {
        const item = this.taskStatusService.getStatusById(id);
        const list = [item];
        return list;
    }
}
