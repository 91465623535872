import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { canvasSchema, Ruum } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isMobile } from '../../common/utils.service';
import { CanvasService } from '../../ruum/canvas/canvas.service';
import { CanvasSectionsService } from '../../ruum/canvas/sections/canvasSections.service';
import { CanvasTasksService } from '../../ruum/canvas/sections/tasks/canvasTasks.service';
import { SectionsService } from '../../ruum/section/sections.service';
import { RuumTasksService } from '../../ruum/tasks/tasks.service';
import { RuumUIStateService } from '../../ruum/ui-state/ruumUIState.service';
import { TemplateActionsService } from '../../shared/templates/template-actions.service';
import { AppStoreWrapper } from '../appStoreWrapper.service';
import { CommonService } from '../common.service';
import { RuumAlertService } from '../components/alert/alert.service';
import { SelectedProjectService } from '../connectors/project/selectedProject.service';
import { TemplateListItemService } from '../connectors/templates/template-list-item.service';
import { ServiceHelper } from '../serviceHelper';
import { TrackingConnector } from '../trackingConnector.service';
import { ActionToken, SidenavService, SidePanelDisplayNode } from './sidenav.service';

@Injectable({ providedIn: 'root' })
export class RuumMainNavPanelSegmentService {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private selectedProjectService: SelectedProjectService,
        private appStoreWrapper: AppStoreWrapper,
        private sectionsService: SectionsService,
        private canvasService: CanvasService,
        private templateListItemService: TemplateListItemService,
        private commonService: CommonService,
        private alertService: RuumAlertService,
        private ruumUIStateService: RuumUIStateService,
        private tasksService: RuumTasksService,
        private canvasTasksService: CanvasTasksService,
        private canvasSectionsService: CanvasSectionsService,
        private serviceHelper: ServiceHelper,
        private trackingConnector: TrackingConnector,
        private sidenavService: SidenavService,
        private templateActionsService: TemplateActionsService,
    ) {
        // this.documentScrollService.userScrolledToBottom$().subscribe(() => this.maybeGoToNextPage());
        const subscription = this.routeProject$.subscribe((project) => {
            this.IS_READONLY = this.selectedProjectService.getIsReadOnly();
        });

        this.project$ = this.appStoreWrapper.selectedRuum();

        this.subjectObservable$ = combineLatest([
            this.project$.pipe(map((project) => project.id)),
            this.initSectionsResolver(),
        ])
            .pipe(
                map(([projectId, nodes]) => {
                    const subject$ = this.subjectList.get(projectId);
                    if (subject$) {
                        subject$.next(nodes);
                    } else {
                        this.subjectList.set(projectId, new BehaviorSubject<SidePanelDisplayNode>(nodes));
                    }
                }),
            )
            .subscribe();

        // reflect Admin state of current project
        this.project$ = this.appStoreWrapper.selectedRuum();
        this.project$.subscribe((x) => {
            this.IS_READONLY = this.selectedProjectService.getIsReadOnly();
        });

        // change whenever path changes...
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.currentPath$.next(val.urlAfterRedirects);
            }
        });
    }

    private IS_READONLY = true;

    private subjectList = new Map<string, BehaviorSubject<SidePanelDisplayNode>>();
    private subjectObservable$;

    private routeProject$: Observable<any> = this.route.url;
    currentPath$: BehaviorSubject<string> = new BehaviorSubject('');
    private project$: Observable<Ruum>;

    private currentLastCanvasId = '';

    getSegmentForProjectId(id) {
        return this.subjectList.get(id);
    }

    initSectionsResolver(): Observable<SidePanelDisplayNode> {
        const resolve = combineLatest([
            this.canvasSectionsService.getCanvasSectionList$().pipe(
                map((mymap) => {
                    let actions = new Map([
                        [ActionToken.DRAGGABLE, {}],
                        [ActionToken.OPEN_FOLDER_CONTEXT, {}],
                    ]);

                    if (isMobile()) {
                        actions.delete(ActionToken.DRAGGABLE);
                    }

                    if (this.IS_READONLY) {
                        actions = new Map();
                    }

                    return Array.from(mymap.entries()).map((entry) => {
                        this.currentLastCanvasId = entry[0];
                        return {
                            id: entry[0],
                            icon: 'canvas',
                            action: 'scroll:' + entry[0],
                            name: entry[1],
                            type: 'section',
                            actions,
                        };
                    });
                }),
            ),
            this.currentPath$,
        ]).pipe(
            map(([nodes, path]) => {
                let name = 'This Ruum';
                let actions = new Map([
                    [ActionToken.EXPAND, {}],
                    [ActionToken.DRILL_DOWN, {}],
                    [ActionToken.ADD, {}],
                ]);

                if (path.indexOf('/templates/') !== -1) {
                    name = 'This Template';
                    actions = new Map([
                        [ActionToken.EXPAND, {}],
                        [ActionToken.DRILL_DOWN, {}],
                    ]);
                }

                if (!isMobile() && !this.selectedProjectService.getIsReadOnly()) {
                    actions.set(ActionToken.DRAGGABLE, {});
                }

                let sectionNode = {
                    id: 'sections',
                    icon: '',
                    action: '',
                    name,
                    type: 'segment',
                    renderSubNode: this.sidenavService.getSectionVisible('sections'),
                    actions,
                    nodes,
                };

                // TODO Markus: find better place for following operation
                // remove whole section navigator if we are in '/templates/sections'
                if (path.indexOf('/templates/sections') !== -1) {
                    sectionNode = null;
                }

                return sectionNode;
            }),
        );

        return resolve as Observable<SidePanelDisplayNode>;
    }

    private setLoadIndicators(page, flag) {}

    // dispatch sectionService actions

    collapseSection(id) {
        this.sectionsService.collapseSection(id);
    }

    duplicateSection(id) {
        this.trackingConnector.trackEvent('global_nav_panel', 'section_context_popup', 'create_blank_section', id);
        this.sectionsService.duplicateSection(id);
    }

    // dispatch canvasService actions

    deleteSection(id) {
        this.trackingConnector.trackEvent('global_nav_panel', 'section_context_popup', 'create_blank_section', id);
        this.canvasService.deleteNodeWithId(id);
    }

    sectionClicked($event) {
        this.canvasService.scrollToNodeId($event.id);
    }

    // dispatch templateService actions
    createSectionTemplateFrom(id) {
        this.createSectionTemplateFromId(id);
    }

    createSectionTemplateFromScratch() {
        this.addSectionFromSectionTemplate();
    }

    createTaskSectionFromScratch() {
        this.addTasksSection();
    }

    addSection() {
        if (!this.ruumUIStateService.isAppOffline()) {
            const sectionId = this.serviceHelper.getRandomId('section');
            this.trackingConnector.trackEvent(
                'global_nav_panel',
                'current_project_context_popup',
                'create_blank_section',
                sectionId,
            );
            this.canvasSectionsService.addEmptySectionToTheTop(sectionId);
            setTimeout(() => this.canvasService.scrollToNodeId(sectionId), 50);
        }
    }

    /* TODO MARKUS: Remove this and reuse createSectionTemplateFromId() from canvas might be placed in a service */

    private createSectionTemplateFromId(sectionId) {
        if (this.ruumUIStateService.isAppOffline()) {
            return;
        }
        this.trackingConnector.trackEvent(
            'global_nav_panel',
            'section_context_popup',
            'create_template_from_section',
            sectionId,
        );

        this.canvasSectionsService.getSectionIdOfIndex(0);
        this.commonService.setBusyWithoutAuthentication(true);
        this.templateListItemService
            .createSectionTemplateFromDeprecated(sectionId)
            .then((ruumId) => {
                this.commonService.setBusy(true);
                this.router.navigateByUrl(`/templates/sections/${ruumId}`);
            })
            .catch((err) => {
                this.commonService.setBusy(false);
                this.alertService.info(`Error creating section template`);
            });
    }

    /* TODO MARKUS: Remove this and reuse addTasksSection() from canvas might be placed in a service */

    addTasksSection() {
        if (this.ruumUIStateService.isAppOffline()) {
            return;
        }
        // drag_and_drop_across_sections

        const sectionId = this.serviceHelper.getRandomId('section');
        this.trackingConnector.trackEvent(
            'global_nav_panel',
            'current_project_context_popup',
            'add_task_section',
            sectionId,
        );
        const emptyParagraph = canvasSchema.nodes.paragraph.create();
        const tasks = Array(3)
            .fill(null)
            .map(() => this.canvasTasksService.createTaskNode());
        const section = this.canvasSectionsService.createSection(
            sectionId,
            [
                this.createEmParagraph(
                    `Be sure to set the task's start and end dates and then assign it to the appropriate` +
                        ` person/people. If you need to add a bit more context, just add a comment!`,
                ),
                ...tasks,
                emptyParagraph,
            ],
            'Tasks',
        );
        this.sectionsService.createIfNeeded(section.attrs.id);
        this.canvasSectionsService.addSectionToTheTop(section);

        tasks.forEach((task, i) =>
            this.tasksService.addTaskWithIdFromCanvas(task.attrs.id, `Task ${i + 1}`, false, null),
        );

        setTimeout(() => this.canvasService.scrollToNodeId(sectionId), 50);
    }

    private createEmParagraph(text: string) {
        return canvasSchema.nodes.paragraph.create({}, canvasSchema.text(text, [canvasSchema.marks.em.create()]));
    }

    /* TODO MARKUS: Remove this and reuse addSectionTemplate() from canvas might be placed in a service */

    addSectionFromSectionTemplate() {
        if (this.ruumUIStateService.isAppOffline()) {
            return;
        }

        const sectionId = this.canvasSectionsService.getSectionIdOfIndex(0);
        this.templateActionsService.selectSectionTemplate(sectionId).then((section) => {
            if (section) {
                this.scrollToNodeId(section.id);
            }
        });
    }

    scrollToNodeId(nodeId: string): void {
        setTimeout(() => {
            this.canvasService.scrollToNodeId(nodeId);
        }, 500);
    }
}
