import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnterpriseRole } from '@ruum/ruum-reducers';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { EnterprisesAccess, ProfileService } from '../../common/connectors/profile/profile.service';
import { environment } from './../../../environments/environment';

@Component({
    selector: 'ruum-admin-home',
    template: `
        <ruum-admin-widget>
            <div *ngIf="(notAdmin$ | async) === true">
                Oops, it looks like you are not an enterprise admin. You're being redirect to
                <a [href]="getRuumWebappLink()">Ruum</a> in a moment.
            </div>
        </ruum-admin-widget>
    `,
    styles: [
        `
            div {
                white-space: pre-line;
            }
            ul {
                padding: 0;
            }
            li {
                cursor: pointer;
                list-style: none;
            }
            li div {
                font-weight: bold;
            }
        `,
    ],
})
export class HomeComponent implements OnInit, OnDestroy {
    enterpriseId$: Observable<string>;
    enterpriseRole$: Observable<string>;
    notAdmin$: Observable<boolean>;
    subscriptions: Subscription[] = [];

    constructor(private profileService: ProfileService, private router: Router) {}

    ngOnInit() {
        this.enterpriseId$ = this.profileService
            .getUserAdminEnterprises()
            .pipe(map((enterpriseAccess: EnterprisesAccess[]) => enterpriseAccess[0].enterpriseId));
        this.enterpriseRole$ = this.enterpriseId$.pipe(
            switchMap((enterpriseId) => this.profileService.getCurrentUserEnterpriseRole$(enterpriseId)),
        );

        this.notAdmin$ = this.enterpriseId$.pipe(map((enterpriseId) => !enterpriseId));

        this.subscriptions.push(
            combineLatest([this.enterpriseId$, this.enterpriseRole$])
                .pipe(distinctUntilChanged())
                .subscribe(([defaultEnterpriseId, role]) => {
                    switch (role) {
                        case EnterpriseRole.EnterpriseAdmin:
                            this.goToDashboard(defaultEnterpriseId);
                            break;
                        case EnterpriseRole.EnterpriseIntegrationAdmin:
                            this.goToSystems(defaultEnterpriseId);
                            break;
                        default:
                            this.delayedGoToWebapp();
                    }
                }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    goToDashboard(enterpriseId: string) {
        this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/overview`);
    }

    goToSystems(enterpriseId: string) {
        this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/systems`);
    }

    delayedGoToWebapp() {
        setTimeout(() => {
            this.router.navigateByUrl(this.getRuumWebappLink());
        }, 2000);
    }

    getRuumWebappLink() {
        return `${environment.APP_URL}/home/projects`;
    }
}
