import { ChangeDetectionStrategy, Component, ElementRef, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { ProfileService } from '../../common/connectors/profile/profile.service';
import { TrackingConnector } from '../../common/trackingConnector.service';
import { ODataBaseListViewComponentDirective } from '../common/components/odata-base-list-view.component';
import { TechnicalUserListService } from './technical-user-list.service';

@Component({
    selector: 'ruum-admin-technical-user-list',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <!-- Sub Header Content -->

                <ruum-view-controls>
                    <div class="w-25">
                        <ruum-search
                            [size]="'sm'"
                            [value]="search"
                            ariaLabel="Search for user"
                            (changed)="onChangeSearchValue($event)"
                        ></ruum-search>
                    </div>
                    <div class="d-flex flex-fill justify-content-end">
                        <button
                            data-content="sub-header-content"
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-primary float-right"
                            type="button"
                            routerLink="./new"
                        >
                            <i class="mr-1 icon icon-add"></i>
                            <span>New</span>
                        </button>
                    </div>
                </ruum-view-controls>

                <!-- empty state: no items yet, no search result -->
                <ruum-empty-state
                    *ngIf="(rows$ | async)?.length === 0 && (isLoadingFirstPage$ | async) !== true"
                    [searchApplied]="this.search?.length > 0"
                    [entityName]="'Technical Users'"
                >
                    <ruum-illustration-system-connectors
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-system-connectors>
                </ruum-empty-state>
                <!-- Columns -->
                <div
                    *ngIf="(rows$ | async)?.length !== 0"
                    class="d-flex flex-column px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5"
                    scrollDetectionDirective
                    (scrolledToBottom)="scrolledToBottom()"
                >
                    <!--list component-->
                    <table class="w-100 cursor-default" cdk-table [dataSource]="this.dataSource">
                        <!-- Column content projection -->
                        <ng-container data-content="list-item" cdkColumnDef="userId">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="userId"
                                    label="User ID"
                                    [sorting]="isSorted('userId')"
                                    [direction]="getSortDirection('userId')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <a
                                    routerLink="/admin/enterprise/{{
                                        selectedEnterpriseService.selectedEnterpriseId() | async
                                    }}/technical-users/{{ element.userId }}"
                                    class="active-column pt-3 pb-3"
                                >
                                    <span>{{ element.userId }}</span>
                                </a>
                            </td>
                        </ng-container>

                        <!-- Full Name Column -->
                        <ng-container cdkColumnDef="name">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="name"
                                    [sorting]="isSorted('name')"
                                    [direction]="getSortDirection('name')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ element.name }}</span>
                            </td>
                        </ng-container>
                        <!-- User ID Column -->
                        <ng-container cdkColumnDef="initials">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="initials"
                                    [sorting]="isSorted('initials')"
                                    [direction]="getSortDirection('initials')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ element.initials }}</span>
                            </td>
                        </ng-container>
                        <!-- Email Column -->
                        <ng-container cdkColumnDef="email">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    name="email"
                                    [sorting]="isSorted('email')"
                                    [direction]="getSortDirection('email')"
                                    (sort)="onSortChange($event)"
                                ></sortable-column>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 small text-truncate">
                                <span>{{ element.email }}</span>
                            </td>
                        </ng-container>

                        <!-- column filter row -->
                        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns"
                            class="border-bottom border-light hov hov-bg-primary-light"
                        ></tr>

                        <!-- busy indicator row -->
                        <tr *ngIf="(isBusy$ | async) === true">
                            <td colspan="4">
                                <img
                                    class="ml-4 p-2"
                                    width="60"
                                    src="./../../assets/img/spinner.svg"
                                    alt="Loading Spinner"
                                />
                            </td>
                        </tr>
                    </table>

                    <!-- spinner: initial fetching -->
                    <ruum-load-spinner
                        classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                        *ngIf="isLoadingFirstPage$ | async"
                    ></ruum-load-spinner>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="scrolledToBottom()"
                    ></ruum-load-more>
                </div>

                <div class="footer"></div>
            </div>
        </ruum-max-content>
    `,
    styles: [
        `
            a.active-column {
                line-height: 32px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminTechnicalUserListComponent extends ODataBaseListViewComponentDirective {
    @HostBinding('class') hostClassList = 'd-flex flex-column w-100 mw-100 h-100 mh-100 overflow-y';

    isLoadingFirstPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;
    isCurrentUserSuperAdmin$: Observable<boolean>;

    constructor(
        technicalUserListService: TechnicalUserListService,
        profileService: ProfileService,
        selectedEnterpriseService: SelectedEnterpriseService,
        trackingConnector: TrackingConnector,
        private elementRef: ElementRef,
    ) {
        super(
            technicalUserListService,
            profileService,
            selectedEnterpriseService,
            trackingConnector,
            ['userId', 'name', 'initials', 'email'],
            ['userId', 'name', 'email'],
        );

        this.isLoadingFirstPage$ = this.listService.isLoadingFirstPage$;
        this.showLoading$ = this.listService.showLoading$;
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();
    }

    back() {
        history.back();
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }
}
