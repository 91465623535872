import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { SelectService } from '../select.service';

@Component({
    selector: 'ruum-select-search',
    template: `
        <input
            type="text"
            #inputField
            class="w-100 form-control-sm border border-white"
            placeholder="Search"
            (keydown.enter)="onEnter()"
            (keydown.backspace)="onBackspace()"
            (input)="onSearch($event)"
        />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class.d-flex')
    @HostBinding('class.flex-fill')
    hostClassList = true;

    @ViewChild('inputField', { static: false }) inputField: ElementRef<HTMLInputElement>;

    @Output() searchChange = new EventEmitter<string>();
    @Output() removeLastItem = new EventEmitter<void>();
    @Output() selectFirstItem = new EventEmitter<void>();

    private value = '';
    private ngOnDestroy$ = new Subject<void>();

    constructor(private renderer: Renderer2, private elementRef: ElementRef, private selectService: SelectService) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        this.focusSearch();
        this.selectService.onSelectOptionEvent$
            .pipe(
                takeUntil(this.ngOnDestroy$),
                filter((event) => event.type === 'KeyboardEvent'),
                debounceTime(10),
            )
            .subscribe(this.focusSearch.bind(this));
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    onSearch(event: Event) {
        this.value = (event.target as HTMLInputElement).value;
        this.searchChange.emit(this.value.trim());
    }

    onBackspace() {
        if (this.value === '') {
            this.removeLastItem.emit();
        }
    }

    onEnter() {
        if (this.value !== '') {
            this.selectFirstItem.emit();
            this.inputField.nativeElement.value = '';
            this.searchChange.emit('');
        }
    }

    private focusSearch() {
        setTimeout(() => {
            const parentNode: HTMLElement = this.renderer.parentNode(this.elementRef.nativeElement);
            if (parentNode.scrollTo) {
                // on IE not every element has scrollTo
                parentNode.scrollTo({ top: parentNode.scrollHeight });
            }
            this.inputField.nativeElement.focus();
        });
    }
}
