import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SavedViewColumn } from '../../../common/connectors/savedView/saved-views.model';

@Component({
    selector: 'ruum-task-list-item-custom-field-text',
    template: `
        {{ value }}
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListItemCustomFieldTextComponent implements OnInit {
    @HostBinding('class') hostClassList = 'text-truncate py-2';

    @Input() column: SavedViewColumn;

    @Input() taskListItem;

    get value() {
        const customFieldsValues = this.taskListItem.customFieldsValues || {};
        return customFieldsValues[this.column.fieldId] || '';
    }

    constructor() {}

    ngOnInit() {}
}
