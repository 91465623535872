import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'i.icon',
})
export class GenerateAriaLabelOnButtonsDirective implements AfterViewInit {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        const parent = this.renderer.parentNode(this.elementRef.nativeElement) as HTMLElement;
        if (parent && parent.tagName === 'BUTTON' && !parent.getAttribute('aria-label')) {
            const classList = (this.elementRef.nativeElement as HTMLElement).classList || [];
            let iconName = '';
            classList.forEach((className) => {
                if (className.includes('icon-')) {
                    iconName = className.replace('icon-', '').replace('-', ' ');
                }
            });
            this.renderer.setAttribute(parent, 'aria-label', iconName || 'Icon');
        }
    }
}
