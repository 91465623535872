import { Injectable } from '@angular/core';
import { TemplateParticipantRole } from '@ruum/ruum-reducers';
import { RuumDropdownItem, RuumDropdownList } from '../shared/ui-components/dropdown/dropdown.model';

export const TEMPLATE_ADMIN: TemplateParticipantRole = 'TemplateAdmin';
export const TEMPLATE_EDITOR: TemplateParticipantRole = 'TemplateEditor';
export const TEMPLATE_VIEWER: TemplateParticipantRole = 'TemplateViewer';

@Injectable({ providedIn: 'root' })
export class TemplateRolesService {
    getRoles(): RuumDropdownList {
        return [
            { id: TEMPLATE_ADMIN, name: 'Admin', description: 'Full access' },
            { id: TEMPLATE_EDITOR, name: 'Editor', description: 'Can Edit, Comment & Invite others' },
            { id: TEMPLATE_VIEWER, name: 'Viewer', description: 'Can View & Add Comments' },
        ];
    }

    getRolesByParticipantRole(participantRole: TemplateParticipantRole): RuumDropdownList {
        return this.getRoles().map((role) => {
            return {
                ...role,
                disabled: this.isRoleDisabled(role, participantRole),
            };
        });
    }

    private isRoleDisabled(role: RuumDropdownItem, participantRole: TemplateParticipantRole): boolean {
        if (role.id === TEMPLATE_ADMIN) {
            return !(participantRole === TEMPLATE_ADMIN);
        }

        if (role.id === TEMPLATE_EDITOR) {
            return !(participantRole === TEMPLATE_ADMIN || participantRole === TEMPLATE_EDITOR);
        }

        if (role.id === TEMPLATE_VIEWER) {
            return !(participantRole === TEMPLATE_ADMIN || participantRole === TEMPLATE_EDITOR);
        }

        return false;
    }
}
