import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ACTION_VERSIONS, Entity, RuumAction, RuumEntityType } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable, Observer, of, Subject, Subscription, timer } from 'rxjs';
import { catchError, concatAll, map, scan, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { ACTION_TYPES } from '../actions';
import { AppState } from '../app.store';
import { RuumAlertService } from '../components/alert/alert.service';
import { StoreExceptionCatcher } from '../storeExceptionCatcher.service';
import { EntityChangesListener } from './changesListener.service';
import { RoleInParentEntity, Transaction, Transactional, TransactionExecution } from './command.model';
import { ProjectServiceBackendConnector } from './projectServiceConnector.service';

export abstract class SelectedEntityService {
    private clientId: string;
    private queuedTransactions$ = new BehaviorSubject<number>(0);
    private otherClientActions$ = new Subject<RuumAction>();
    private persistedActions$ = new Subject<RuumAction>();
    private transactional: Transactional;
    /** Having the ID here instead of getting it from the store fixes some race conditions. */
    private selectedEntityId: string;

    constructor(
        protected authService: AuthService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected store: Store<AppState>,
        protected router: Router,
        protected storeExceptionCatcher: StoreExceptionCatcher,
        protected alertService: RuumAlertService,
        protected changesListener: EntityChangesListener,
        private readonly entityType: RuumEntityType,
    ) {
        this.clientId = Date.now() + '';
    }

    protected selectEntity(entity: Entity): void {
        this.stopListeningToChanges();
        this.selectedEntityId = entity.id;
        this.transactional = this.createTransactional(entity.id, entity.version);
        this.dispatchSelectEntity(entity);
    }

    protected getSeletedEntityId(): string {
        return this.selectedEntityId;
    }

    stopListeningToChanges() {
        if (this.transactional) {
            this.transactional.changesSubscription.unsubscribe();
            this.transactional.transactionSubscription.unsubscribe();
            this.transactional = undefined;
            this.selectedEntityId = undefined;
        }
        this.queuedTransactions$.next(0);
    }

    protected abstract dispatchSelectEntity(entity: Entity);

    private createTransactional(entityId: string, version: number): Transactional {
        const transactions$ = new Subject<TransactionExecution>();
        const transactional$ = transactions$.pipe(
            tap(() => this.queuedTransactions$.next(1)),
            map((transaction) => this.executeTransaction$(transaction)),
            /** make transactions execute in sequence */
            concatAll(),
        );

        const transactionSubscription = transactional$.subscribe();

        const changesSubscription = this.listenToNewActions(this.entityType, entityId, version);

        return {
            entityId,
            transactions$,
            transactionSubscription,
            changesSubscription,
        };
    }

    private listenToNewActions(entityType: RuumEntityType, entityId: string, version: number): Subscription {
        const entityChanges$ = this.changesListener.listenToEntityChanges<RuumAction>(entityId, entityType, version);
        return entityChanges$.subscribe(
            (changes) => {
                this.goOnline();
                changes.forEach((change) => this.maybeDispatchAction(change.action));
            },
            (err) => {
                console.error(`error listening to new actions ${entityType} - ${entityId}`, err);
                this.reload(entityId);
            },
        );
    }

    private maybeDispatchAction(action: RuumAction) {
        if (action.clientId !== this.clientId) {
            this.store.dispatch(action);
            this.otherClientActions$.next(action);
        }
        this.persistedActions$.next(action);
    }

    otherClientActions(): Observable<RuumAction> {
        return this.otherClientActions$.asObservable();
    }

    persistedActions(): Observable<RuumAction> {
        return this.persistedActions$.asObservable();
    }

    private goOffline() {
        if (this.isOffline()) {
            return;
        }

        this.store.dispatch({
            type: ACTION_TYPES.SET_OFFLINE,
            payload: { offline: true },
            createdBy: this.authService.getLoggedUser().id,
        });
    }

    private goOnline() {
        if (!this.isOffline()) {
            return;
        }

        this.store.dispatch({
            type: ACTION_TYPES.SET_OFFLINE,
            payload: { offline: false },
            createdBy: this.authService.getLoggedUser().id,
        });
    }

    private isOffline() {
        let offline: boolean;
        this.store
            .select('common')
            .pipe(
                map((common) => common.offline),
                take(1),
            )
            .subscribe((bol) => (offline = bol));
        return offline;
    }

    /** Writting action to the store and sending it to the server. */

    persistAction<T extends RuumAction>(
        type: T['type'],
        payload: T['payload'],
        roles: RoleInParentEntity,
    ): Promise<any> {
        if (!this.transactional) {
            return Promise.reject(
                new Error(
                    `It is not possible to persist an action before the ${
                        this.entityType
                    } was selected ${type} - ${JSON.stringify(payload)} - ${JSON.stringify(roles)}`,
                ),
            );
        }
        return this.dispatchAction(type, payload, roles).then(() => {
            return this.scheduleTransaction(type, payload);
        });
    }

    getAction(type: string, payload: any, roles: RoleInParentEntity): RuumAction {
        const entityId = this.transactional ? this.transactional.entityId : undefined;
        return {
            type,
            at: Date.now(),
            createdBy: this.authService.getLoggedUser().id,
            payload,
            version: ACTION_VERSIONS[type] || 1,
            projectGroupRole: roles.projectGroupRole,
            workspaceRole: roles.workspaceRole,
            templateRole: roles.templateRole,
            role: roles.role,
            entityType: this.entityType,
            entityId,
            events: [],
        };
    }

    dispatchAction(type: string, payload: any, roles: RoleInParentEntity): Promise<void> {
        const action = this.getAction(type, payload, roles);
        const promise = this.storeExceptionCatcher.waitForResult(action);
        this.store.dispatch(action);
        return promise;
    }

    private scheduleTransaction(actionType: string, actionPayload: any): Promise<void> {
        return new Promise((resolve, reject) => {
            const transaction: Transaction = {
                entityId: this.transactional.entityId,
                entityType: this.entityType,
                actionType,
                actionPayload,
                clientId: this.clientId,
                idempotencyKey: undefined,
            };
            this.transactional.transactions$.next({ transaction, resolve, reject });
        });
    }

    private executeTransaction$(transaction: TransactionExecution): Observable<Entity> {
        return new Observable((observer: Observer<Entity>) => this.executeTransaction(transaction, observer));
    }

    private executeTransaction(execution: TransactionExecution, observer: Observer<Entity>): void {
        const send = {
            tr: execution.transaction,
            retryCount: 0,
        };
        this.sendTransactionToServer(send)
            .then(() => {
                execution.resolve();
                observer.complete();
                this.queuedTransactions$.next(-1);
            })
            .catch((err) => {
                return this.handleUnrecoverableError(err, send);
            });
    }

    private sendTransactionToServer(send: SendTransaction): Promise<any> {
        const observable: Observable<any> = this.projectServiceConnector
            .sendTransactionToServer(send.tr)
            .pipe(catchError((err, caught) => this.catch(err, caught, send)));

        return observable.toPromise().then(() => {
            this.goOnline();
        });
    }

    private catch(err: any, caught: Observable<any>, send: SendTransaction) {
        if (err.status === 0) {
            this.goOffline();
            /** If it is offline try again in 4 seconds. */
            return timer(4000).pipe(switchMap(() => caught));
        }
        if (err.status === 403) {
            this.router.navigateByUrl('/auth'); // TODO
            return of(1);
        } else {
            if (send.retryCount === 0) {
                send.retryCount++;
                /** Try again once more in case it was a network issue */
                return timer(500).pipe(switchMap(() => caught));
            } else {
                return this.handleUnrecoverableError(err, send);
            }
        }
    }

    /**
     * We already dispatched an action that failed to be sent to the server. We can only give up and start from scratch.
     */
    protected handleUnrecoverableError(err, send: SendTransaction): Promise<void> {
        this.stopListeningToChanges();

        console.error('error persisting action', send.tr, err);

        return this.alertService
            .warning({
                actionText: 'Reload',
                title: 'Sorry, but our app seems to be confused. We’ll reload to fix this.',
                noCancel: true,
                autoClose: 5000,
            })
            .then(
                () => {
                    this.reload(send.tr.entityId);
                },
                () => {
                    this.reload(send.tr.entityId);
                },
            );
    }

    protected reload(entityId: string) {
        throw new Error('reload not implemented');
    }

    isSendingData(): Observable<boolean> {
        return this.queuedTransactions$.pipe(
            scan((queueLength, count) => {
                if (count === 0) {
                    return 0;
                } else {
                    return queueLength + count;
                }
            }),
            map((queueLength) => queueLength > 0),
        );
    }
}

export interface SendTransaction {
    tr: Transaction;
    /** How many times it failed to be sent to the server. */
    retryCount: number;
}
