import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unseenChangesCounterOf',
})
export class UnseenChangesCountersPipe implements PipeTransform {
    transform(unseenChangesCounters, id) {
        if (!unseenChangesCounters) {
            return 0;
        }

        if (unseenChangesCounters[id]) {
            return unseenChangesCounters[id] || 0;
        }

        return 0;
    }
}
