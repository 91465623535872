import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import {
    LobbyListFilters,
    LobbyListItem,
    LobbyListOrderBy,
    LobbyListType,
} from '../../../common/connectors/lobbyList/lobby-list.model';
import { OrderedListParams, PaginatedList } from '../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../common/connectors/paginatedListLoader';
import { ReadModelBackendConnector } from '../../../common/connectors/readModelConnector.service';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../ui-components/ui-components.type';

const emptyPage = {
    pageSize: 25,
    currentPage: 1,
    totalItems: 0,
    rows: [],
};

@Component({
    selector: 'ruum-group-select',
    template: `
        <ruum-select
            [select]="select"
            [search]="true"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [loading]="loading$ | async"
            [lightBackground]="false"
            [multiSelect]="multiSelect"
            [disabled]="disabled"
            (loadMoreOptions)="loadMore()"
            (isOpenChange)="isOpenChange($event)"
            (searchChange)="searchChange($event)"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-content>
                <ruum-select-placeholder *ngIf="!(selectedOptions$ | async)?.length">
                    {{ placeholder }}
                </ruum-select-placeholder>

                <div
                    class="ruum-select-single-value align-items-center pl-2"
                    *ngFor="let selectedOption of selectedOptions$ | async"
                >
                    <div
                        class="text-truncate font-weight-normal"
                        [class.text-small]="size === 'sm'"
                        [class.text-body]="theme === 'light'"
                    >
                        {{ selectedOption.name }}
                    </div>
                </div>
            </ruum-select-content>
            <ruum-select-option
                *ngFor="let option of options$ | async"
                [value]="option.id"
                [content]="option.name"
            ></ruum-select-option>
        </ruum-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSelectComponent extends PaginatedListLoader<LobbyListItem, LobbyListFilters, LobbyListOrderBy>
    implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    @Input() set selectedOptionIds(ids: string[]) {
        this.selectedOptionIdsSubject.next(ids);
    }

    @Input() set workspaceId(value: string) {
        this.workspaceIdSubject.next(value);
    }

    @Input() placeholder = 'Select Value';
    @Input() placement = ['bottom-left', 'top-left'];
    @Input() multiSelect = false;
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIdsSubject.getValue();
        } else {
            return this.selectedOptionIdsSubject.getValue()[0];
        }
    }

    selectedOptions$: Observable<LobbyListItem[]>;
    options$: Observable<LobbyListItem[]>;
    loading$: Observable<boolean>;

    private selectedOptionIdsSubject = new BehaviorSubject([]);
    private workspaceIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private groupIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private listType$: BehaviorSubject<string> = new BehaviorSubject<LobbyListType>('projectgroups');
    private searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private orderBySubject = new BehaviorSubject<OrderedListParams<LobbyListOrderBy>>({
        by: 'changedAt',
        direction: 'desc',
    });
    private dataStoreSubject = new BehaviorSubject<PaginatedList<LobbyListItem[]>>(emptyPage);
    private ngOnDestroy$ = new Subject<void>();

    constructor(
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        private readModelBackendConnector: ReadModelBackendConnector,
    ) {
        super(alertService, authService);
    }

    ngOnInit(): void {
        this.setUpObservables();
        this.selectedOptions$ = this.getSelectedOptions();
        this.options$ = this.getOptions();
        this.loading$ = this.getLoading();
    }

    ngOnDestroy(): void {
        this.stopLoadingList();
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    trackByOption(index: number, item: LobbyListItem): string {
        return item.id;
    }

    isOpenChange(open: boolean): void {
        if (open) {
            this.reload();
            this.dataStoreSubject.next(emptyPage);
            this.searchSubject.next('');
            this.loadList();
        } else {
            this.stopLoadingList();
        }
    }

    searchChange(search): void {
        this.reload();
        this.dataStoreSubject.next(emptyPage);
        this.searchSubject.next(search);
    }

    loadMore(): void {
        this.maybeGoToNextPage();
    }

    selectChange(event): void {
        this.changed.emit(event);
    }

    protected getData(
        page: number,
        filters: LobbyListFilters,
        orderBy: OrderedListParams<LobbyListOrderBy>,
    ): Observable<PaginatedList<LobbyListItem>> {
        return this.readModelBackendConnector.getLobbyList(filters, orderBy, page, 25);
    }

    protected getFilters$(): Observable<LobbyListFilters> {
        return combineLatest([this.searchSubject, this.listType$, this.workspaceIdSubject]).pipe(
            map<any, LobbyListFilters>(([search, listType, workspaceId]) => ({
                name: search,
                listType,
                workspaceId,
            })),
        );
    }

    protected getOrderBy$(): Observable<OrderedListParams<LobbyListOrderBy>> {
        return this.orderBySubject;
    }

    protected getStoreData$(): Observable<PaginatedList<any>> {
        return this.dataStoreSubject.asObservable();
    }

    private setUpObservables(): void {
        this.getListObservable()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((page: any) => {
                const currentState = this.dataStoreSubject.getValue();
                this.dataStoreSubject.next({
                    ...page,
                    rows: [...currentState.rows, ...page.rows],
                });
            });
    }

    private getOptions(): Observable<LobbyListItem[]> {
        return this.dataStoreSubject.pipe(
            map((currentState: any) => {
                return currentState.rows;
            }),
        );
    }

    private getSelectedOptions(): Observable<LobbyListItem[]> {
        return combineLatest([this.selectedOptionIdsSubject.asObservable()]).pipe(
            filter(([ids]) => ids.length !== 0),
            switchMap(([ids]) => {
                return this.getDataByIds(ids);
            }),
        );
    }

    private getDataByIds(ids: string[]): Observable<LobbyListItem[]> {
        return combineLatest(ids.map((id) => this.getDataById(id))).pipe(startWith([]));
    }

    private getDataById(id: string): Observable<LobbyListItem> {
        return this.readModelBackendConnector.getLobbyListById(id);
    }

    private getLoading(): Observable<boolean> {
        return this.hasMore$.asObservable().pipe(startWith(true));
    }
}
