import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TemplateParticipant } from '@ruum/ruum-reducers';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProfileListItem } from '../../../common/connectors/profile/profile.model';
import { UserListItem } from '../../../common/connectors/readModelConnector.service';
import { SelectedTemplateService } from '../../../common/connectors/templates/selectedTemplate.service';
import { InvolvedUsersService } from '../../../common/connectors/users/users.service';
import { RuumDropdownItem, RuumDropdownList } from '../../ui-components/dropdown/dropdown.model';
import { ProfileService } from './../../../common/connectors/profile/profile.service';

@Component({
    selector: 'template-collaborators-list',
    template: `
        <label class="h5 text-secondary uppercase pb-2" for="collaborators">Template Collaborators</label>

        <div
            id="collaborators"
            *ngFor="let collaborator of collaborators$ | async; let index = index"
            class="d-flex align-items-center border-light pb-2"
        >
            <ruum-profile-member
                [name]="(resolveMember(collaborator.id) | async)?.fullName"
                [theme]="(resolveMember(collaborator.id) | async)?.color | ruumProfileHexToTheme"
                [size]="'md'"
                [hover]="false"
                [active]="false"
                [disabled]="false"
            ></ruum-profile-member>

            <div class="d-flex flex-column flex-fill px-3 minw-0">
                <span
                    class="text text-truncate"
                    [title]="
                        mapYouInList(
                            (resolveMember(collaborator.id) | async)?.isYou,
                            (resolveMember(collaborator.id) | async)?.fullName
                        )
                    "
                >
                    {{
                        mapYouInList(
                            (resolveMember(collaborator.id) | async)?.isYou,
                            (resolveMember(collaborator.id) | async)?.fullName
                        )
                    }}
                </span>
                <span
                    class="text small text-secondary text-truncate"
                    [title]="(resolveMember(collaborator.id) | async)?.mail"
                >
                    {{ (resolveMember(collaborator.id) | async)?.mail }}
                </span>
            </div>

            <ruum-template-team-participant-options [loggedUser]="loggedUser$ | async" [participant]="collaborator">
            </ruum-template-team-participant-options>
        </div>
    `,
})
export class TemplateCollaboratorsListComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-column flex-fill mt-5 pt-5 border-top';

    @Input() collaborators$: Observable<TemplateParticipant[]> = of([]);
    @Input()
    roles: RuumDropdownList;
    userProfile$: Observable<ProfileListItem>;
    loggedUser$: Observable<TemplateParticipant>;

    constructor(
        private usersService: InvolvedUsersService,
        private selectedTemplateService: SelectedTemplateService,
        private profileService: ProfileService,
    ) {}

    ngOnInit() {
        this.userProfile$ = this.profileService.userProfile();
        this.loggedUser$ = this.selectedTemplateService.loggedUserTemplateParticipant();
    }
    resolveMember(userId: string): Observable<UserListItem> {
        return combineLatest([this.usersService.data(userId), this.userProfile$])
            .pipe(filter(Boolean))
            .pipe(
                map(([participant, userProfile]) => {
                    return participant.id === userProfile.id
                        ? { ...participant, isYou: true }
                        : { ...participant, isYou: false };
                }),
            );
    }

    selectedCollaboratorRole(collaboratorRole): RuumDropdownItem {
        return this.roles.filter((role) => role.id === collaboratorRole)[0];
    }

    mapYouInList(isYou, fullName) {
        return isYou ? 'You' : fullName;
    }
}
