import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'wicg-inert';
import { SelectedProjectService } from './../connectors/project/selectedProject.service';

@Component({
    selector: 'ruum-sidenav-content',
    template: `
        <ruum-sidenav-primary-list></ruum-sidenav-primary-list>
        <!-- <ruum-sidenav-project-sections *ngIf="isSelectedProject$ | async"></ruum-sidenav-project-sections> -->
        <ruum-project-main-nav-panel></ruum-project-main-nav-panel>
        <ruum-sidenav-lobby-list></ruum-sidenav-lobby-list>
        <ruum-sidenav-workspace-list></ruum-sidenav-workspace-list>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill flex-column';

    isSelectedProject$: Observable<boolean>;

    constructor(private selectedProjectService: SelectedProjectService) {}

    ngOnInit() {
        this.isSelectedProject$ = this.selectedProjectService.selectedProject().pipe(map((project) => !!project.id));
    }
}
