import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-select-placeholder',
    template: `
        <div class="pl-2 text-truncate font-weight-normal">
            <ng-content></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlaceholderComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';

    constructor() {}

    ngOnInit() {}
}
