import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserListItem } from '../../../common/connectors/readModelConnector.service';
import { TaskListItem } from '../../../common/connectors/tasks/task-list.model';
import { InvolvedUsersService } from '../../../common/connectors/users/users.service';

@Component({
    selector: 'ruum-task-list-cell-assignees',
    template: `
        <div class="d-flex">
            <ruum-task-assignees-button
                [assignedUsers]="assignedUsers$ | async"
                [maxShownAssignees]="maxShownAssignees"
                [showFullNameOfFirstAssignee]="showFullNameOfFirstAssignee"
                [defaultButtonTheme]="'none'"
            >
            </ruum-task-assignees-button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListCellAssigneeComponent implements OnInit {
    @HostBinding() class = 'd-flex w-100';

    @Input()
    set data(value: TaskListItem) {
        this.task$.next(value);
    }
    get data(): TaskListItem {
        return this.task$.getValue();
    }
    @Input() maxShownAssignees = 2;
    @Input() showFullNameOfFirstAssignee = true;

    task$: BehaviorSubject<TaskListItem> = new BehaviorSubject<TaskListItem>(null);
    assignedUsers$: Observable<UserListItem[]>;

    constructor(private userService: InvolvedUsersService) {}

    ngOnInit() {
        this.assignedUsers$ = this.task$.pipe(switchMap((value) => this.userService.dataList(value.assignees)));
    }
}
