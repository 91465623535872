export enum PaywallPrivilege {
    RUUM_ARCHIVE = 'ruumArchive',
    PROJECT_GROUP_CREATION = 'projectGroupCreation',
    VARIABLE_CREATION = 'variableCreation',
    CUSTOM_FIELD_CREATION = 'customTaskFieldCreation',
    TEMPLATE_CREATION = 'templateCreation',
    MILESTONE_CREATION = 'milestoneCreation',
    TAG_CREATION = 'tagCreation',
    REPORT_CREATION = 'reportCreation',
    EXTERNAL_FILES = 'externalFiles',
    KANBAN = 'kanban',
    TEST = 'test',
}
