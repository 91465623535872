import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldValue } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-custom-field-link',
    template: `
        <ng-container *ngIf="!isReadOnly; else readOnlyTemplate">
            <div class="input-group input-group-sm hov">
                <input
                    aria-label="Custom Field Link"
                    class="form-control"
                    [formControl]="formControl"
                    [placeholder]="placeholder"
                    ruumInputHighlight
                    (blur)="onValueChange()"
                />
                <div class="input-group-append" ruumInputHighlight>
                    <span class="input-group-text">
                        <a
                            *ngIf="customField.value; else noLink"
                            [href]="customField.value"
                            target="_blank"
                            rel="noopener noreferrer"
                            ><i class="icon icon-link hov-child"></i
                        ></a>
                    </span>
                </div>
            </div>

            <ng-template #noLink>
                <i class="icon icon-link hov-child"></i>
            </ng-template>
        </ng-container>

        <ng-template #readOnlyTemplate>
            <div class="input-group input-group-sm hov disabled">
                <ng-container *ngIf="customField.value; else placeholderTemplate">
                    <div class="form-control disabled">
                        <a [href]="customField.value">{{ customField.value }}</a>
                    </div>
                    <div class="input-group-append" ruumInputHighlight>
                        <span class="input-group-text">
                            <a [href]="customField.value" target="_blank" rel="noopener noreferrer"
                                ><i class="icon icon-link hov-child"></i
                            ></a>
                        </span>
                    </div>
                </ng-container>

                <ng-template #placeholderTemplate>
                    <span class="form-control text-light disabled">{{ placeholder }}</span>
                    <div class="input-group-append" ruumInputHighlight>
                        <span class="input-group-text">
                            <i class="icon icon-link hov-child"></i>
                        </span>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldLinkComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Input()
    set customField(customField: CustomFieldValue) {
        this._customField = customField;
        if (customField) {
            const value = customField.value ? (customField.value as string) : '';
            // this is needed because sometimes the customField ref changed but not the value
            if (value !== this.value) {
                this.value = value;
                this.formControl.setValue(value);
            }
        }
    }
    get customField(): CustomFieldValue {
        return this._customField;
    }

    @Input() isReadOnly = false;

    @Output() valueChange = new EventEmitter<CustomFieldValue>();

    get placeholder(): string {
        return this.customField.placeholder ? this.customField.placeholder : 'Enter a valid URL';
    }

    formControl = new FormControl('');

    private _customField: CustomFieldValue;
    private value = '';

    constructor() {}

    ngOnInit() {}

    onValueChange() {
        let value = this.formControl.value;
        const hasNoHttp = value.indexOf('http://') === -1 && value.indexOf('https://') === -1;
        if (hasNoHttp) {
            value = 'https://' + value;
        }
        if (value === 'https://' || value === 'http://') {
            value = null;
        }
        if (this.customField.value !== value) {
            const changedField = { ...this.customField, value };
            this.valueChange.emit(changedField);
        }
    }
}
