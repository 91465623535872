import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from '../../ui-components/dropdown/dropdown.module';
import { ProfileModule } from '../../ui-components/profile/profile.module';
import { AssignedItemListComponent } from './assigned-item-list.component';
import { TaskAssigneeProposalListComponent } from './task-assignee-proposal-list.component';
import { TaskAssigneesButtonComponent } from './task-assignees-button.component';
import { RuumTaskAssigneesDropdownItemComponent } from './task-assignees-dropdown-item.component';
import { TaskAssigneesMenuComponent } from './task-assignees-menu.component';
import { TaskAssigneesComponent } from './task-assignees.component';

@NgModule({
    imports: [CommonModule, FormsModule, NgbTooltipModule, DropdownModule, ProfileModule],

    declarations: [
        TaskAssigneesComponent,
        TaskAssigneesMenuComponent,
        RuumTaskAssigneesDropdownItemComponent,
        TaskAssigneesButtonComponent,
        TaskAssigneeProposalListComponent,
        AssignedItemListComponent,
    ],
    exports: [
        TaskAssigneesComponent,
        TaskAssigneesMenuComponent,
        RuumTaskAssigneesDropdownItemComponent,
        TaskAssigneesButtonComponent,
        TaskAssigneeProposalListComponent,
        AssignedItemListComponent,
    ],
})
export class TaskAssigneesModule {}
