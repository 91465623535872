import { formatDate } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    getRecordKeys,
    getRecordKeyString,
    RecordKeys,
    TableDefinition,
    TableDefinitionColumn,
    TableRow,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GenericDataSource } from '../../../common/connectors/paginatedListLoader';
import { TableDataRecordStore } from '../../../common/connectors/tableData/tableDataRecords.store';
import { TableRecordListStore } from '../../../common/connectors/tableData/tableRecordList.store';
import { TableDefinitionStore } from '../../../common/connectors/tableDefinition/tableDefinition.store';
import { deepEqual } from '../../../common/utils.service';

type RecordViewMode = 'edit' | 'test';
type SelectedTableRow = TableRow & { __key: string };
@Component({
    selector: 'ruum-record-lookup-dialog',
    template: `
        <ruum-modal-dialog>
            <ng-container *ngIf="definition$ | async as definition">
                <h2 *ngIf="mode === 'edit'" class="ml-3 mb-5">Select {{ definition.name }}</h2>
                <h2 *ngIf="mode === 'test'" class="ml-3 mb-5">Mapping Test</h2>
                <!-- Search -->
                <div class="d-flex flex-fill mb-7">
                    <div class="d-flex mr-3" [style.width.px]="280">
                        <ruum-search
                            [size]="'sm'"
                            [placeholder]="'Search for Values'"
                            [value]="searchValue$ | async"
                            (changed)="search($event)"
                        ></ruum-search>
                    </div>
                </div>

                <!-- empty state: no items yet, no search result -->
                <ruum-empty-state
                    *ngIf="
                        (data$ | async)?.length === 0 &&
                        (isLoadingFirstPage$ | async) === false &&
                        (isLoadingAnotherPage$ | async) === false
                    "
                    [searchApplied]="(searchValue$ | async)?.length > 0"
                    [entityName]="'Records'"
                >
                    <ruum-illustration-custom-fields
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-custom-fields>
                </ruum-empty-state>

                <!-- view list -->
                <div
                    *ngIf="(data$ | async)?.length > 0 && (isLoadingFirstPage$ | async) === false"
                    class="ruum-table-container d-flex flex-column position-relative overflow-x overflow-y"
                    [style.max-height.px]="400"
                >
                    <table class="w-100" cdk-table [dataSource]="dataSource">
                        <ng-container cdkColumnDef="checkbox">
                            <th cdk-header-cell *cdkHeaderCellDef></th>
                            <td cdk-cell *cdkCellDef="let element">
                                <div
                                    class="btn btn-icon btn-xs btn-without-hover my-1"
                                    [class.btn-link-gray-24]="!isSelected(element, definition)"
                                    [class.btn-link-primary]="isSelected(element, definition)"
                                >
                                    <i
                                        *ngIf="multiSelect"
                                        class="icon"
                                        [class.icon-checkbox-checked]="isSelected(element, definition)"
                                        [class.icon-checkbox-unchecked]="!isSelected(element, definition)"
                                    ></i>
                                    <i
                                        *ngIf="!multiSelect"
                                        class="icon"
                                        [class.icon-task-completed-filled]="isSelected(element, definition)"
                                        [class.icon-task-open]="!isSelected(element, definition)"
                                    ></i>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container *ngFor="let row of tableProperties$ | async">
                            <ng-container [cdkColumnDef]="row.name">
                                <th
                                    cdk-header-cell
                                    *cdkHeaderCellDef
                                    class="minw-0"
                                    [attr.id]="row.name"
                                    [id]="row.name"
                                >
                                    <button class="btn btn-xs btn-link-light btn-without-hover px-2">
                                        <h5 class="text-truncate">{{ row.label }}</h5>
                                    </button>
                                </th>

                                <td
                                    cdk-cell
                                    *cdkCellDef="let element"
                                    class="text-truncate px-2 py-1"
                                    [style.max-width.px]="240"
                                    [style.min-width.px]="130"
                                >
                                    <span class="text-small">{{ getValue(element, row) | async }}</span>
                                </td>
                            </ng-container>
                        </ng-container>

                        <tr
                            cdk-header-row
                            *cdkHeaderRowDef="displayedColumns$ | async"
                            class="border-bottom border-light"
                        ></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns$ | async"
                            class="border-bottom border-light hov hov-bg-primary-light cursor-pointer"
                            (click)="toggleRow(row, definition)"
                        ></tr>
                    </table>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="loadMore()"
                    ></ruum-load-more>
                </div>

                <!-- spinner -->
                <ruum-load-spinner
                    classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                    *ngIf="(isLoadingFirstPage$ | async) === true || (isLoadingAnotherPage$ | async) === true"
                ></ruum-load-spinner>

                <!-- list of tags -->
                <div
                    *ngIf="(data$ | async)?.length > 0 && mode === 'edit'"
                    class="d-flex flex-column align-items-start mt-3"
                >
                    <div
                        class="btn btn-sm no-hover px-0 mb-2"
                        [class.btn-link-secondary]="selectedItemsCountTheme === 'secondary'"
                        [class.btn-link-primary]="selectedItemsCountTheme === 'primary'"
                    >
                        <span class="mr-1">{{ selectedItemsCount }}</span>
                        <i class="icon icon-arrow-drop-down"></i>
                    </div>
                    <div class="d-flex flex-frap">
                        <ruum-tag
                            *ngFor="let selectedRow of selectedRows$ | async"
                            [style.max-width.px]="192"
                            [name]="selectedRow[selectedRow.__key]"
                            [theme]="'primary-light'"
                            [showCancel]="true"
                            [rounded]="true"
                            [componentClass]="'mr-2 mb-2'"
                            (click)="toggleRow(selectedRow, definition)"
                        ></ruum-tag>
                    </div>
                </div>

                <!-- footer buttons -->
                <div class="d-flex justify-content-end mt-6">
                    <button
                        *ngIf="mode === 'edit'"
                        class="btn btn-lg btn-outline-secondary mr-3"
                        type="button"
                        (click)="cancel()"
                    >
                        Cancel
                    </button>
                    <button
                        *ngIf="mode === 'edit'"
                        class="btn btn-lg btn-primary"
                        type="button"
                        (click)="apply(definition)"
                    >
                        Apply
                    </button>
                    <button *ngIf="mode === 'test'" class="btn btn-lg btn-primary" type="button" (click)="close()">
                        Close
                    </button>
                </div>
            </ng-container>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordLookupDialogComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-block';

    @Input() set tableId(value: string) {
        this.tableIdSource.next(value);
    }

    get tableId(): string {
        return this.tableIdSource.getValue();
    }

    @Input() set recordsKeys(recordKeys: RecordKeys[]) {
        this.recordsKeysSource.next(recordKeys);
    }

    get recordsKeys(): RecordKeys[] {
        return this.recordsKeysSource.getValue();
    }
    @Input() multiSelect = false;
    @Input() mode: RecordViewMode = 'edit';

    searchValue$: Observable<string>;
    definition$: Observable<TableDefinition>;
    displayedColumns$: Observable<string[]>;
    tableProperties$: Observable<TableDefinitionColumn[]>;
    data$: Observable<TableRow[]>;
    dataSource: GenericDataSource<TableRow>;
    isLoadingFirstPage$: Observable<boolean>;
    isLoadingAnotherPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;

    selectedRows$: Observable<SelectedTableRow[]>;

    get scrollElement() {
        return this.elementRef.nativeElement.querySelector('.ruum-table-container');
    }

    get selectedItemsCount(): string {
        const count = this.selectedRowsSource.getValue().length;
        const s = count === 1 ? '' : 's';
        return `${count} Selected Item${s}`;
    }

    get selectedItemsCountTheme(): string {
        return this.selectedRowsSource.getValue().length > 0 ? 'primary' : 'secondary';
    }

    private tableIdSource = new BehaviorSubject<string>(undefined);
    private recordsKeysSource = new BehaviorSubject<RecordKeys[]>([]);
    private selectedRowsSource = new BehaviorSubject<SelectedTableRow[]>([]);
    private ngOnDestroy$ = new Subject<void>();

    constructor(
        private activeModal: NgbActiveModal,
        private tableDataService: TableRecordListStore,
        private tableDefinitionService: TableDefinitionStore,
        private tableDataRecordStore: TableDataRecordStore,
        private elementRef: ElementRef,
        @Inject(LOCALE_ID) private locale: string,
    ) {}

    ngOnInit() {
        this.tableDataService.resetFilters();
        this.tableDataService.loadList();
        this.tableDataService.loadTable(this.tableId);
        this.data$ = this.tableDataService.getStoreRows$();
        this.dataSource = new GenericDataSource<TableRow>(this.data$);
        this.definition$ = this.tableDefinitionService.data(this.tableId);
        this.displayedColumns$ = this.getDisplayedColumns(this.definition$);
        this.tableProperties$ = this.getTableProperties(this.definition$);
        this.subscribeTableDataRecors();
        this.selectedRows$ = this.selectedRowsSource.asObservable();
        this.isLoadingFirstPage$ = this.tableDataService.isLoadingFirstPage$;
        this.isLoadingAnotherPage$ = this.tableDataService.isLoadingAnotherPage$;
        this.showLoading$ = this.tableDataService.showLoading$;
        this.searchValue$ = this.tableDataService.search$.asObservable();
    }

    ngOnDestroy() {
        this.tableDataService.stopLoadingList();
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    loadMore() {
        this.tableDataService.maybeGoToNextPage();
    }

    search(searchValue: string) {
        this.tableDataService.search$.next(searchValue);
    }

    getValue(element: any, row: TableRow): Observable<string> {
        return this.definition$.pipe(
            map((definition: TableDefinition) => {
                const column = definition.columns.find((col) => col.name === row.name);
                switch (column.type) {
                    case 'date':
                        const date = new Date(element[row.name]);
                        return formatDate(date, 'd MMM, y h:mm:ss a', this.locale);
                    case 'number':
                    case 'string':
                    default:
                        return element[row.name];
                }
            }),
        );
    }

    toggleRow(row: TableRow, definition: TableDefinition) {
        const titleKey = definition.columns.find((column) => column.isTitle).name;
        const newRow = { ...row, __key: titleKey };
        const isSelected = this.isSelected(newRow, definition);
        let rows = [];
        if (isSelected) {
            rows = [...this.selectedRowsSource.getValue().filter((selectedRow) => !deepEqual(selectedRow, newRow))];
        } else {
            rows = this.multiSelect ? [...this.selectedRowsSource.getValue(), newRow] : [newRow];
        }
        this.selectedRowsSource.next(rows);
    }

    isSelected(row: TableRow, definition: TableDefinition): boolean {
        const keys = getRecordKeys(definition, row);
        return !!this.selectedRowsSource
            .getValue()
            .map((selectedRow) => getRecordKeys(definition, selectedRow))
            .find((selectedRecord) => deepEqual(selectedRecord, keys));
    }

    cancel() {
        this.activeModal.dismiss();
    }

    apply(definition) {
        const recordsKeys = this.selectedRowsSource
            .getValue()
            .map((selectedRow) => getRecordKeys(definition, selectedRow));
        this.activeModal.close(recordsKeys);
    }

    close() {
        this.activeModal.close([]);
    }

    private getDisplayedColumns(definition$): Observable<string[]> {
        return definition$.pipe(
            map((definition: TableDefinition) => {
                if (this.mode === 'edit') {
                    return ['checkbox', ...definition.columns.map((column) => column.name)];
                } else {
                    return [...definition.columns.map((column) => column.name)];
                }
            }),
        );
    }

    private getTableProperties(definition$): Observable<TableDefinitionColumn[]> {
        return definition$.pipe(
            map((definition: TableDefinition) => {
                return definition.columns;
            }),
        );
    }

    private subscribeTableDataRecors() {
        combineLatest([this.definition$, this.recordsKeysSource, this.definition$])
            .pipe(
                takeUntil(this.ngOnDestroy$),

                switchMap(([table, recordsKeys, definition]) => {
                    const ids = recordsKeys.map((keys) => getRecordKeyString(table, keys));
                    return this.tableDataRecordStore
                        .dataList(ids)
                        .pipe(tap((list) => list.forEach((row) => this.toggleRow(row, definition))));
                }),
            )
            .subscribe();
    }
}
