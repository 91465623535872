import {
    DeleteTaskAction,
    isTasksReducerAction,
    ProjectParticipantRole,
    RuumAction,
    RuumActionTypes,
    TasksReducer as RuumTaskReducer,
    User,
} from '@ruum/ruum-reducers';
import { PaginatedList } from '../connectors/paginatedList.model';
import { ReplaceTasksAction, TASK_LIST_ACTIONS } from '../connectors/tasks/task-list.actions';
import { TaskListItem } from '../connectors/tasks/task-list.model';
import { KANBAN_ACTIONS } from '../kanban/kanban.actions';

export interface TasksMap {
    [id: string]: TaskListItem; // id is 'projectId-taskId'
}

export function TaskReducer(currentState: TasksMap = {}, action: RuumAction): TasksMap {
    if (isTasksReducerAction(action.type)) {
        if (action.type === RuumActionTypes.DELETE_TASK) {
            return deleteTask(currentState, action);
        } else {
            return runTaskReducer(currentState, action);
        }
    }

    switch (action.type) {
        case KANBAN_ACTIONS.KANBAN_LOADED:
        case TASK_LIST_ACTIONS.TASK_LIST_LOADED:
            return storeTask(currentState, action);
        case TASK_LIST_ACTIONS.REPLACE_TASKS:
            return replaceTasks(currentState, action);
        default:
            return currentState;
    }
}

function runTaskReducer(currentState: TasksMap, action: RuumAction): TasksMap {
    if (!action.payload) {
        return currentState;
    }

    const taskId = action.payload.taskId;
    const projectId = action.entityId;

    const key = `${projectId}-${taskId}`;
    const task = currentState[key];
    if (task) {
        const projectTasks = Object.keys(currentState).reduce((tasks, stateKey) => {
            if (stateKey.startsWith(projectId)) {
                tasks.push(currentState[stateKey]);
            }
            return tasks;
        }, []);

        const actionCreator: User = {
            id: action.createdBy,
            roles: [<ProjectParticipantRole>action.role],
        };

        // some reducers will add events so we need to add events array to the action
        const actionWithEmptyEvents = { ...action, events: [] };

        /** The action might change more than one Task. */
        const newTasks = RuumTaskReducer(projectTasks, actionWithEmptyEvents, { actionCreator, isTemplate: false });

        return _replaceTasks(currentState, <TaskListItem[]>newTasks);
    }

    return currentState;
}

function storeTask(currentState: TasksMap, action: RuumAction): TasksMap {
    const plist: PaginatedList<TaskListItem> = action.payload.list;
    if (plist) {
        const changeList = {};
        for (const item of plist.rows) {
            const { projectId, id } = item;
            const key = `${projectId}-${id}`;
            changeList[key] = item;
        }
        return { ...currentState, ...changeList };
    }
    return currentState;
}

function replaceTasks(currentState: TasksMap, action: ReplaceTasksAction): TasksMap {
    return _replaceTasks(currentState, action.payload.tasks);
}

function _replaceTasks(currentState: TasksMap, tasks: TaskListItem[]): TasksMap {
    const change: TasksMap = {};
    tasks.forEach((task) => {
        const { projectId, id } = task;
        const key = `${projectId}-${id}`;
        change[key] = task;
    });
    return {
        ...currentState,
        ...change,
    };
}

function deleteTask(currentState: TasksMap, action: DeleteTaskAction): TasksMap {
    const projectId = action.entityId;
    const taskId = action.payload.id;
    const key = `${projectId}-${taskId}`;
    const { [key]: value, ...rest } = currentState;
    return rest;
}
