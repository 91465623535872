import { CustomFieldsValues, ProcessStepTransition, Ruum } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TasksMap } from '../../../task/task.reducer';
import { getCanvasStepTransitionState } from './getCanvasStepTransitionState';
import { DocumentIndex, SectionsObjects } from './indexDocument';
import { ObjectsProcessStep } from './objectProcessStep';

export interface ProcessStepTransitionsState {
    [objectId: string]: ProcessStepTransitionState[];
}

export interface ProcessStepTransitionState extends ProcessStepTransition {
    processId: string;
    stepId: string;
    enabled: boolean;
}

export function processStepTransitions(
    project$: Observable<Ruum>,
    sectionProcessSteps$: Observable<ObjectsProcessStep>,
    documentIndex$: Observable<DocumentIndex>,
    tasks$: Observable<TasksMap>,
): Observable<ProcessStepTransitionsState> {
    return combineLatest([project$, sectionProcessSteps$, documentIndex$, tasks$]).pipe(
        map(([project, processSections, documentIndex, tasks]) =>
            buildProcessStepTransitions(processSections, documentIndex.sections, tasks, project.customFieldsValues),
        ),
    );
}

function buildProcessStepTransitions(
    objectProcessStep: ObjectsProcessStep,
    sectionObjects: SectionsObjects,
    tasks: TasksMap,
    projectCustomFields: CustomFieldsValues,
): ProcessStepTransitionsState {
    const transitions: ProcessStepTransitionsState = {};

    Object.keys(objectProcessStep).forEach((objectId) => {
        const step = objectProcessStep[objectId];

        transitions[objectId] = [];
        Object.keys(step.transitions).forEach((transitionId) => {
            const transition = step.transitions[transitionId];
            if (!transition) {
                return;
            }
            let state: ProcessStepTransitionState;

            if (step.type === 'canvas' || step.type === 'approval') {
                state = getCanvasStepTransitionState(step, sectionObjects, tasks, projectCustomFields, transition);
            } else if (step.type === 'system_connector') {
                state = {
                    ...transition,
                    processId: step.processId,
                    stepId: step.id,
                    /**  */
                    enabled: false,
                };
            }

            transitions[objectId].push(state);
        });
    });

    return transitions;
}
