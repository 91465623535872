import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { AppState } from '../../../app.store';
import { RuumAlertService } from '../../../components/alert/alert.service';
import { ServiceHelper } from '../../../serviceHelper';
import { CustomFieldListItem, CustomFieldsFilters } from '../../customFields/customFields.model';
import { CustomFieldsMap } from '../../customFields/customFields.reducer';
import { OrderedListParams, PaginatedList, SortDirection } from '../../paginatedList.model';
import { PaginatedListLoader } from '../../paginatedListLoader';
import { ReadModelBackendConnector } from '../../readModelConnector.service';
import { SelectedWorkspaceService } from '../selected-workspace.service';
import { CustomFieldsLoadedAction } from './customFieldsList.reducer';

export type CustomFieldListSortBy = 'type' | 'title';

@Injectable({ providedIn: 'root' })
export class CustomFieldListService extends PaginatedListLoader<
    CustomFieldListItem,
    CustomFieldsFilters,
    CustomFieldListSortBy
> {
    readonly workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly relativeToProjectId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly relativeToTasksOfProjectId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly relativeToGroupId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly search$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private orderBy$ = new BehaviorSubject<OrderedListParams<CustomFieldListSortBy>>(undefined);

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        private selectedWorkspaceService: SelectedWorkspaceService,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.workspaceId$.next(this.selectedWorkspaceService.getSelectedWorkspaceId());
        this.setUpObservables();
    }

    protected getFilters$(): Observable<CustomFieldsFilters> {
        return combineLatest([
            this.workspaceId$,
            this.search$,
            this.relativeToProjectId$,
            this.relativeToTasksOfProjectId$,
            this.relativeToGroupId$,
        ]).pipe(
            map<any, CustomFieldsFilters>(
                ([workspaceId, search, relativeToProjectId, relativeToTasksOfProjectId, relativeToGroupId]) => ({
                    workspaceId,
                    search,
                    relativeToProjectId,
                    relativeToTasksOfProjectId,
                    relativeToGroupId,
                }),
            ),
        );
    }

    resetFilters() {
        this.workspaceId$.next(undefined);
        this.search$.next(undefined);
        this.relativeToProjectId$.next(undefined);
        this.relativeToTasksOfProjectId$.next(undefined);
        this.relativeToGroupId$.next(undefined);
    }

    orderBy(by: CustomFieldListSortBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
    }

    protected getData(
        page: number,
        filters: CustomFieldsFilters,
        orderBy: OrderedListParams<CustomFieldListSortBy>,
    ): Observable<PaginatedList<CustomFieldListItem>> {
        return this.readModelConnector.getCustomFields({ page }, filters, orderBy);
    }

    protected getOrderBy$(): Observable<OrderedListParams<CustomFieldListSortBy>> {
        return this.orderBy$;
    }

    getStoreRows$(): Observable<CustomFieldListItem[]> {
        return combineLatest([
            this.store.select('customFieldsList') as Observable<PaginatedList<string>>,
            this.store.select('customFields') as Observable<CustomFieldsMap>,
        ]).pipe(
            map(([ids, customFieldListMap]) => {
                return ids.rows.map((id) => customFieldListMap[id]);
            }),
        );
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<CustomFieldsLoadedAction>('CUSTOM_FIELDS_LOADED', { page });
        });
    }

    getStoreData$(): Observable<PaginatedList<string>> {
        return this.store.select('customFieldsList');
    }
}
