import { Processes, ProcessStepType } from '@ruum/ruum-reducers';
import { RoleSections } from '../indexDocument';
import { ObjectsProcessStep } from '../objectProcessStep';

export interface RolesStepConfiguration {
    [fieldId: string]: RoleStepConfiguration;
}

export interface RoleStepConfiguration {
    roleId: string;
    stepId: string;
    stepType: ProcessStepType;
    processId: string;
    processActiveStepId: string;
    active: boolean;
}

export function getRolesConfiguration(
    roles: RoleSections,
    processSections: ObjectsProcessStep,
    processes: Processes,
): RolesStepConfiguration {
    const map: RolesStepConfiguration = {};

    Object.keys(roles).forEach((roleId) => {
        const sectionIds = roles[roleId];

        /** If the role is not in any section, there is nothing to do. */
        if (!sectionIds || sectionIds.length === 0) {
            return;
        }

        const roleProcessSections = sectionIds.filter((id) => !!processSections[id]);

        /** If none of the sections where this role is is connected to a process step, there is nothing to do. */
        if (roleProcessSections.length === 0) {
            return;
        }

        const activeSections = roleProcessSections.filter((id) => processSections[id].active);

        /** If the role is not in any active section, it is not active */
        if (activeSections.length === 0) {
            const step = processSections[roleProcessSections[0]];
            const processId = step.processId;
            const process = processes[processId];
            map[roleId] = {
                active: false,
                stepId: step.id,
                stepType: step.type,
                roleId,
                processId,
                processActiveStepId: process.activeStepId,
            };
        } else {
            const step = processSections[activeSections[0]];
            const processId = step.processId;
            const process = processes[processId];
            map[roleId] = {
                active: true,
                stepId: step.id,
                stepType: step.type,
                roleId,
                processId,
                processActiveStepId: process.activeStepId,
            };
        }
    });

    return map;
}
