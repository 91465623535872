import { CustomFieldValueRecord, TableDefinition } from '@ruum/ruum-reducers';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchAll } from 'rxjs/operators';
import { ExternalSystemStore } from '../../../../../common/connectors/externalSystem/externalSystem.store';
import { RuumOnPremiseConnectorAuthHandler } from '../../../../../common/connectors/externalSystem/ruumOnPremiseConnectorAuthHandler.service';

export function getCustomFieldRecordData(
    currentValue$: Observable<CustomFieldValueRecord>,
    systemsStore: ExternalSystemStore,
    extConnectorPremAuthHandler: RuumOnPremiseConnectorAuthHandler,
    tableDefinition$: Observable<TableDefinition>,
): Observable<CustomFieldRecordData> {
    const getIsAuthenticated = (systemId: string): Observable<boolean> => {
        return systemsStore.data(systemId).pipe(
            map((system: any) => {
                if (system.outboundAuthenticationType === 'forward_token') {
                    return extConnectorPremAuthHandler.isAuthenticated(system.id);
                } else {
                    return of(true);
                }
            }),
            switchAll(),
        );
    };
    return combineLatest([tableDefinition$, currentValue$]).pipe(
        map(([tableDefinition, currentValue]) => {
            if (tableDefinition.type === 'local_table') {
                return of({
                    tableDefinition,
                    currentValue,
                    isAuthenticated: true,
                });
            } else {
                return getIsAuthenticated(tableDefinition.systemId).pipe(
                    map((isAuthenticated) => ({
                        tableDefinition,
                        currentValue,
                        isAuthenticated,
                    })),
                );
            }
        }),
        switchAll(),
    );
}

export interface CustomFieldRecordData {
    currentValue: CustomFieldValueRecord;
    isAuthenticated: boolean;
    tableDefinition: TableDefinition;
}
