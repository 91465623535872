import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Approval,
    getDefaultSectionState,
    RuumActionTypes,
    RuumSectionSettings,
    SectionState,
    SectionStateActionTypes,
    SetSectionLockContentAction,
} from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { FunctionalRolesService } from '../../common/connectors/functionalRoles/functionalRoles.service';
import { SelectedProjectService } from '../../common/connectors/project/selectedProject.service';
import { ProjectServiceBackendConnector } from '../../common/connectors/projectServiceConnector.service';
import { AppStoreWrapper } from './../../common/appStoreWrapper.service';
import { FunctionalRoleListItem } from './../../common/connectors/functionalRoles/functionalRoles.model';
import { CanvasSectionsService } from './../canvas/sections/canvasSections.service';
import { RuumUIStateService } from './../ui-state/ruumUIState.service';

@Injectable({ providedIn: 'root' })
export class SectionsService {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;

    constructor(
        private selectedProjectService: SelectedProjectService,
        private appStoreWrapper: AppStoreWrapper,
        private canvasSectionsService: CanvasSectionsService,
        private http: HttpClient,
        private alertService: RuumAlertService,
        private uiState: RuumUIStateService,
        private projectServiceConnector: ProjectServiceBackendConnector,
        private functionalRolesService: FunctionalRolesService,
    ) {}

    /**
     * MOVE_SECTION_UP and MOVE_SECTION_DOWN are only needed for tracking. The actual order of the sections in only kept in the canvas.
     *
     */
    moveSectionUp(sectionId: string) {
        const moveSectionAction = { sectionId };
        this.selectedProjectService.persistAction(RuumActionTypes.MOVE_SECTION_UP, moveSectionAction);
        this.canvasSectionsService.moveSectionUp(sectionId);
    }

    moveSectionDown(sectionId: string) {
        const moveSectionAction = { sectionId };
        this.selectedProjectService.persistAction(RuumActionTypes.MOVE_SECTION_DOWN, moveSectionAction);
        this.canvasSectionsService.moveSectionDown(sectionId);
    }

    isFirstSection(sectionId: string) {
        return this.canvasSectionsService.isFirstSection(sectionId);
    }

    isLastSection(sectionId: string) {
        return this.canvasSectionsService.isLastSection(sectionId);
    }

    createIfNeeded(sectionId: string) {
        this.appStoreWrapper
            .sections()
            .pipe(
                map((sections) => sections.find((s) => s.id === sectionId)),
                take(1),
            )
            .subscribe((section) => {
                if (!section && this.selectedProjectService.getSelectedProjectId()) {
                    this.selectedProjectService.persistAction(RuumActionTypes.CREATE_OR_EDIT_SECTION, { sectionId });
                    this.expandSection(sectionId);
                }
            });
    }

    expandSection(sectionId: string) {
        this.selectedProjectService.persistAction(RuumActionTypes.TOGGLE_COLLAPSE_SECTION, {
            sectionId,
            collapse: false,
        });
    }

    collapseSection(sectionId: string) {
        this.selectedProjectService.persistAction(RuumActionTypes.TOGGLE_COLLAPSE_SECTION, {
            sectionId,
            collapse: true,
        });
    }

    collapseAllSections() {
        let allSectionIds: string[];
        this.appStoreWrapper
            .sections()
            .pipe(take(1))
            .subscribe((ids) => (allSectionIds = ids.map((i) => i.id)));
        this.selectedProjectService.persistAction(SectionStateActionTypes.COLLAPSE_ALL_SECTIONS, {
            sectionIds: allSectionIds,
        });
    }

    expandAllSections() {
        let allSectionIds: string[];
        this.appStoreWrapper
            .sections()
            .pipe(take(1))
            .subscribe((ids) => (allSectionIds = ids.map((i) => i.id)));
        this.selectedProjectService.persistAction(SectionStateActionTypes.EXPAND_ALL_SECTIONS, {
            sectionIds: allSectionIds,
        });
    }

    isCollapsed(sectionId: string): Observable<boolean> {
        return this.uiState.loggedUserUIState().pipe(
            map((uiState) => this.getSectionState(sectionId, uiState.sectionsState)),
            map((state) => state.collapsed),
            distinctUntilChanged(),
        );
    }

    getIsCollapsed(sectionId: string): boolean {
        let collapsed: boolean;

        this.isCollapsed(sectionId)
            .pipe(take(1))
            .subscribe((c) => (collapsed = c));

        return collapsed;
    }

    allSectionsCollapsed(): Observable<boolean> {
        return this.canvasSectionsService.getCanvasSectionIds$().pipe(
            debounceTime(150),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap((sectionIds) => {
                return combineLatest(sectionIds.map((sectionId) => this.isCollapsed(sectionId))).pipe(
                    map((sectionsCollapseState) => sectionsCollapseState.every((isCollapsed) => isCollapsed)),
                );
            }),
        );
    }

    private getSectionState(sectionId: string, sectionStates: SectionState[]): SectionState {
        const state = sectionStates.find((sectionState) => sectionState.id === sectionId);
        if (state) {
            return state;
        } else {
            const sectionIds = this.canvasSectionsService.getCanvasSectionIds();
            const index = sectionIds.indexOf(sectionId);
            /** If the user never expanded or collapsed a given section and that section is in a position greater than 5, the section will be collapsed by default. */
            if (index <= 4) {
                return getDefaultSectionState(sectionId, false);
            } else {
                return getDefaultSectionState(sectionId, true);
            }
        }
    }

    delete(sectionId: string) {
        if (this.canvasSectionsService.getCanvasSectionIds().length === 1) {
            this.alertService.info('Having at least one section is mandatory.').catch(() => {});
            return;
        }

        const projectId = this.selectedProjectService.getSelectedProjectId();

        return this.projectServiceConnector.deleteSection(projectId, sectionId).catch((err) => {
            this.alertService.info('Error deleting section');
            throw err;
        });
    }

    duplicateSection(sectionId: string) {
        const ruumId = this.selectedProjectService.getSelectedProjectId();
        return this.projectServiceConnector.duplicateSection(ruumId, sectionId, ruumId, sectionId).catch((err) => {
            this.alertService.info('Error duplicating section');
            throw err;
        });
    }

    getIsSectionContentLocked(sectionId: string): boolean {
        const project = this.selectedProjectService.getSelectedProject();
        const section = project.sections.find((s) => s.id === sectionId);
        return !!(section && section.contentLocked);
    }

    isSectionContentLocked(sectionId: string): Observable<boolean> {
        return this.selectedProjectService.selectedProject().pipe(
            map((project) => {
                const section = project.sections.find((s) => s.id === sectionId);
                return !!(section && section.contentLocked);
            }),
        );
    }

    setSectionLockContent(sectionId: string, lock: boolean) {
        return this.selectedProjectService.persistAction<SetSectionLockContentAction>('SET_SECTION_LOCK_CONTENT', {
            sectionId,
            lock,
        });
    }

    getSectionLockContent(sectionId: string): boolean {
        const project = this.selectedProjectService.getSelectedProject();
        const section = project.sections.find((s) => s.id === sectionId);
        return !!(section && section.lockContent);
    }

    getSectionSettings(sectionId: string): Observable<RuumSectionSettings> {
        return this.selectedProjectService.selectedProject().pipe(
            map((project) => project.sections.find((s) => s.id === sectionId)),
            filter((section) => !!section),
        );
    }

    getSectionRoles(sectionId: string): Observable<FunctionalRoleListItem[]> {
        return combineLatest([
            this.getDataCollectionSectionRoles(sectionId),
            this.getApprovalsSectionRoles(sectionId),
        ]).pipe(
            map(([dataCollectionSectionRoles, approvalsSectionRoles]) => {
                return [...dataCollectionSectionRoles, ...approvalsSectionRoles];
            }),
        );
    }

    private getDataCollectionSectionRoles(sectionId: string): Observable<FunctionalRoleListItem[]> {
        return this.getSectionSettings(sectionId).pipe(
            filter(({ roles }) => !!roles),
            switchMap(({ roles }) => this.functionalRolesService.dataList(roles)),
            distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
            startWith([]),
        );
    }

    private getApprovalsSectionRoles(sectionId: string): Observable<FunctionalRoleListItem[]> {
        return this.selectedProjectService.selectedProject().pipe(
            map((project) => {
                const approvalItems = Object.keys(project.approvals)
                    .map((approvalKey: string) => {
                        return project.approvals[approvalKey];
                    })
                    .filter((approval: Approval) => {
                        return approval.sectionId === sectionId;
                    })
                    .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue.items }), {});

                return Object.keys(approvalItems)
                    .map((approvalItemKey: string) => {
                        return approvalItems[approvalItemKey].functionalRoleId;
                    })
                    .filter((roleId) => !!roleId);
            }),
            switchMap((roleIds) => {
                return this.functionalRolesService.dataList(roleIds);
            }),
            distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
            startWith([]),
        );
    }
}
