import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ruum-sidepanel-back-button',
    template: `
        <div class="d-flex flex-fill px-4 pt-5">
            <button class="btn btn-sm btn-link-primary px-0">
                <i class="icon icon-back"></i>
                <span class="pl-1">{{ name || 'Back' }}</span>
            </button>
        </div>
    `,
})
export class RuumSidepanelBackButtonComponent {
    @HostBinding('class') hostClassList = 'd-lg-flex d-none flex-fill';

    @Input()
    name: string;
}
