import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableAPIType, TableDefinitionColumn } from '@ruum/ruum-reducers';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';
import { ExternalSystemListService } from '../../../common/connectors/externalSystem/external-system-list.service';
import { ExternalSystemActionsService } from '../../external-system/external-system-actions.service';

export class PropertiesValidator {
    static atLeastOneKeyIsRequired(control: AbstractControl) {
        const columns = control.get('columns');

        if (!columns) {
            return null;
        }

        return columns.value.some((el) => el.isKey) ? null : { atLeastOneKeyIsRequired: true };
    }
}

@Component({
    selector: 'ruum-table-definition-dialog',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">{{ modalTitle }}</h2>
            <form [formGroup]="form">
                <div class="form-group mb-3">
                    <label class="form-default-label" for="labelField">Name</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the name of your new mapping object..."
                        id="labelField"
                        type="text"
                        formControlName="name"
                        ngbAutofocus
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="selectSystem">System</label>
                    <ruum-select-controller
                        id="selectSystem"
                        placeholder="Select the system you want to connect with..."
                        formControlName="systemId"
                        [listLoader]="listLoader"
                        [actionOptions]="actionOptions"
                        [withUnselect]="false"
                        (actionItemClick)="addNewSystem($event)"
                    ></ruum-select-controller>
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="pathField">API Type</label>
                    <ruum-select formControlName="apiType" [style.height.px]="32">
                        <ruum-select-placeholder>Select API Type</ruum-select-placeholder>
                        <ruum-select-option value="odata_v4" content="OData v4"></ruum-select-option>
                        <ruum-select-option value="odata_v2" content="OData v2"></ruum-select-option>
                    </ruum-select>
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="pathField">path</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the path to your API endpoint..."
                        id="pathField"
                        type="path"
                        formControlName="path"
                    />
                </div>
                <hr />

                <div class="d-flex align-items-center mt-5">
                    <div class="flex-fill text-truncate text-small font-weight-bold">Response Property Mapping</div>
                    <button class="btn btn-outline-primary btn-xs" (click)="addProperty()">
                        <i class="icon icon-add"></i>
                        <span>Add Property</span>
                    </button>
                </div>
                <div class="d-table mt-5 w-100" formArrayName="columns" autocomplete="off">
                    <div class="d-table-row text-secondary text-tiny font-weight-bold">
                        <div class="d-table-cell px-2" [style.width.px]="205">
                            <label class="form-default-label" for="apiName">API PROPERTY NAME</label>
                        </div>
                        <div class="d-table-cell px-2" [style.width.px]="55">
                            <label class="form-default-label" for="isKey">IS KEY</label>
                        </div>
                        <div class="d-table-cell px-2" [style.width.px]="65">
                            <label class="form-default-label" for="isTitle">IS TITLE</label>
                        </div>
                        <!-- <div class="d-table-cell px-2" [style.width.px]="100">
                            <label class="form-default-label" for="isFilterable">IS FILTERABLE</label>
                        </div> -->
                        <div class="d-table-cell px-2" [style.width.px]="100">
                            <label class="form-default-label" for="dataType">DATA TYPE</label>
                        </div>
                        <div class="d-table-cell px-2"><label class="form-default-label" for="label">LABEL</label></div>
                        <div class="d-table-cell px-2"></div>
                    </div>
                    <div
                        class="d-table-row align-top"
                        *ngFor="let formGroup of properties.controls; let index = index"
                        [formGroup]="formGroup"
                    >
                        <div class="d-table-cell align-top form-group form-white border-bottom border-light">
                            <input
                                id="apiName"
                                class="form-control form-control-sm"
                                placeholder="Enter property name..."
                                type="text"
                                formControlName="name"
                            />
                        </div>
                        <div class="d-table-cell align-middle form-group border-bottom border-light">
                            <input
                                id="isKey"
                                class="form-control form-control-sm"
                                placeholder="Is Key?"
                                type="checkbox"
                                formControlName="isKey"
                            />
                        </div>
                        <div class="d-table-cell align-middle form-group border-bottom border-light">
                            <input
                                id="isTitle"
                                class="form-control form-control-sm"
                                placeholder="Is Title?"
                                type="radio"
                                name="isTitle"
                                formControlName="isTitle"
                                [value]="index"
                                (change)="titleIndexChanged(index)"
                            />
                        </div>
                        <!-- <div class="d-table-cell align-middle form-group border-bottom border-light">
                            <input
                                id="isFilterable"
                                class="form-control form-control-sm"
                                placeholder="Is Filterable?"
                                type="checkbox"
                                formControlName="isFilterable"
                            />
                        </div> -->
                        <div class="d-table-cell align-top form-group border-bottom border-light">
                            <ruum-select
                                id="dataType"
                                formControlName="type"
                                [lightBackground]="false"
                                [type]="'link'"
                                [style.height.px]="32"
                            >
                                <ruum-select-placeholder>Select Data type</ruum-select-placeholder>
                                <ruum-select-option value="string" content="String"></ruum-select-option>
                                <ruum-select-option value="number" content="Number"></ruum-select-option>
                                <ruum-select-option value="boolean" content="Boolean"></ruum-select-option>
                                <ruum-select-option value="date" content="Date"></ruum-select-option>
                            </ruum-select>
                        </div>
                        <div class="d-table-cell align-top form-group form-white border-bottom border-light">
                            <input
                                id="label"
                                class="form-control form-control-sm"
                                placeholder="Enter label..."
                                type="text"
                                formControlName="label"
                            />
                        </div>
                        <div class="d-table-cell align-middle form-group border-bottom border-light">
                            <button class="btn btn-sm btn-link-danger btn-icon" (click)="removeProperty(index)">
                                <i class="icon icon-cancel"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-between mt-6">
                    <div class="d-flex align-items-end">
                        <a
                            class="btn btn-xs btn-outline-accent"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://help.sap.com/viewer/f8e58f003adf4cc78f5152f51a66e771/Latest/en-US"
                        >
                            Need Help? See Documentation!
                        </a>
                    </div>

                    <div>
                        <!-- <button
                            class="btn btn-lg btn-outline-secondary mr-3"
                            type="button"
                            [disabled]="!form.valid"
                            (click)="testApiMapping()"
                        >
                            Test
                        </button> -->
                        <button
                            class="btn btn-lg btn-primary"
                            type="button"
                            [disabled]="!form.valid"
                            (click)="submit()"
                        >
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDefinitionDialogComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-block';

    @Input() mode = 'create';
    @Input() name: string;
    @Input() systemId: string;
    @Input() path: string;
    @Input() apiType: TableAPIType = TableAPIType.ODATA_V4;
    @Input() columns: TableDefinitionColumn[] = [];

    listLoader: ExternalSystemListService;

    form: FormGroup;
    properties: FormArray;

    actionOptions = [
        {
            id: 'ADD_SYSTEM',
            title: 'Add a System',
        },
    ];

    titleIndex = 0;

    get modalTitle(): string {
        return this.mode === 'create' ? 'New API Mapping' : 'Update API Mapping';
    }

    get buttonText(): string {
        return this.mode === 'create' ? 'Create' : 'Update';
    }

    constructor(
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private externalSystemListService: ExternalSystemListService,
        private externalSystemActionsService: ExternalSystemActionsService,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    ngOnInit() {
        this.listLoader = this.externalSystemListService;
        this.externalSystemListService.loadList();
        this.titleIndex = this.getTitleIndex(this.columns);
        this.properties = this.getPropertiesFormArray(this.columns);
        this.form = this.getTableDefinitionFormGroup(
            this.name,
            this.systemId,
            this.path,
            this.apiType,
            this.properties,
            this.titleIndex,
        );
    }

    ngOnDestroy() {
        this.externalSystemListService.stopLoadingList();
    }

    addNewSystem(event) {
        if (event === 'ADD_SYSTEM') {
            this.externalSystemActionsService.createExternalSystem();
        }
    }

    titleIndexChanged(titleIndex: number): void {
        this.titleIndex = titleIndex;
    }

    addProperty() {
        const itemFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            isKey: [this.properties.length === 0 ? true : false],
            isTitle: [0],
            isFilterable: [false],
            type: ['string', Validators.required],
            label: ['', Validators.required],
        });
        this.properties.push(itemFormGroup);
    }

    removeProperty(index: number) {
        this.properties.removeAt(index);
    }

    submit() {
        if (!this.form.valid) {
            return;
        }
        const formData = this.form.value;
        formData.columns = formData.columns.map((column, index) => {
            return {
                ...column,
                isTitle: this.titleIndex === index,
            };
        });
        this.activeModal.close(formData);
    }

    testApiMapping() {
        if (!this.form.valid) {
            return;
        }
    }

    private getTitleIndex(columns: TableDefinitionColumn[]): number {
        const index = (this.columns || []).map((el) => el.isTitle).indexOf(true);
        return index === -1 ? 0 : index;
    }

    private getPropertiesFormArray(columns: TableDefinitionColumn[]): FormArray {
        const controls = columns.map((column, i) => {
            return this.formBuilder.group({
                name: [column.name, Validators.required],
                isKey: [column.isKey],
                isTitle: [column.isTitle ? i : undefined],
                isFilterable: [column.isFilterable],
                type: [column.type, Validators.required],
                label: [column.label, Validators.required],
            });
        });

        return this.formBuilder.array(controls, Validators.required);
    }

    private getTableDefinitionFormGroup(
        name: string,
        systemId: string,
        path: string,
        apiType: TableAPIType,
        columns: FormArray,
        titleIndex: number,
    ): FormGroup {
        return this.formBuilder.group(
            {
                name: [name, Validators.required],
                systemId: [systemId, Validators.required],
                path: [path, Validators.required],
                apiType: [apiType, Validators.required],
                columns,
                titleIndex: [titleIndex],
            },
            {
                validator: PropertiesValidator.atLeastOneKeyIsRequired,
            },
        );
    }
}
