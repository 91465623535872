import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminNavbarService {
    private titleSource = new BehaviorSubject<string>('Home');

    constructor() {}

    setTitle(value: string): void {
        this.titleSource.next(value);
    }

    getTitle(): Observable<string> {
        return this.titleSource.asObservable();
    }
}
