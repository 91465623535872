import { Injectable } from '@angular/core';
import { ExternalSystem } from '@ruum/ruum-reducers';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { ExternalSystemInfo } from '../../common/connectors/externalSystem/external-system-list.model';
import { ExternalSystemListService } from '../../common/connectors/externalSystem/external-system-list.service';
import { ReadModelBackendConnector } from '../../common/connectors/readModelConnector.service';
import { ModalService } from './../../admin/common/modal/modal.service';
import { ExternalSystemDialogComponent } from './external-system-dialog/external-system-dialog.component';

@Injectable({ providedIn: 'root' })
export class ExternalSystemActionsService {
    constructor(
        private externalSystemListService: ExternalSystemListService,
        private modalService: ModalService,
        private alertService: RuumAlertService,
        private readModelBackendConnector: ReadModelBackendConnector,
    ) {}

    createExternalSystem(): Promise<any> {
        const modalRef = this.modalService.open(ExternalSystemDialogComponent, {
            size: 'lg',
            backdrop: 'static',
        });

        return modalRef.result.then(
            (data: ExternalSystem) => this.externalSystemListService.createExternalSystem(data),
            () => {},
        );
    }

    async updateExternalSystem(externalSystem: ExternalSystemInfo): Promise<any> {
        const completeSystem = await this.readModelBackendConnector.getExternalSystem(externalSystem.id);

        const modalRef = this.modalService.open(ExternalSystemDialogComponent, {
            size: 'lg',
            backdrop: 'static',
        });

        const component: ExternalSystemDialogComponent = modalRef.componentInstance;
        component.mode = 'edit';
        component.system = completeSystem;

        return modalRef.result.then(
            (data: ExternalSystem) => {
                this.externalSystemListService.updateExternalSystem(externalSystem.id, data);
            },
            () => {},
        );
    }

    deleteExternalSystem(externalSystem: ExternalSystemInfo): Promise<any> {
        return this.alertService
            .warning({
                title: `Are you sure you want to delete this external system?`,
                actionText: 'Delete',
            })
            .then(
                () => this.externalSystemListService.deleteExternalSystem(externalSystem.id),
                () => {},
            );
    }
}
