import { Injectable } from '@angular/core';
import {
    Process,
    ProcessEnum,
    Processes,
    ProcessStep,
    SetProcessStepCustomFieldConfigurationAction,
    SetProcessStepTaskConfigurationAction,
    StepProjectCustomFieldsConfiguration,
} from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { CanvasStateManagerService } from '../../../../ruum/canvas/canvasStateManager.service';
import { CanvasSectionsService } from '../../../../ruum/canvas/sections/canvasSections.service';
import { RuumTasksService } from '../../../../ruum/tasks/tasks.service';
import { TasksMap } from '../../../task/task.reducer';
import { ApprovalStore } from '../../approvals/approval.store';
import { ProjectServiceBackendConnector } from '../../projectServiceConnector.service';
import { SystemConnectorsStore } from '../../system-connectors/system-connectors.store';
import { SelectedProjectService } from '../selectedProject.service';
import { transitionedToText } from './getTransitionedToText';
import { getTransitionObjects } from './getTransitionObjects';
import { DocumentIndex, indexDocument } from './indexDocument';
import { objectsProcessStep, ObjectsProcessStep, ProcessApprovalStepWebApp } from './objectProcessStep';
import { objectsStepConfiguration, ObjectStepConfiguration } from './objectsStepConfig/objectsStepConfiguration';
import {
    processStepTransitions,
    ProcessStepTransitionsState,
    ProcessStepTransitionState,
} from './objectStepTransitions';
import { TransitionCreateData, TransitionObject } from './process.model';
import { reloadApprovalItem } from './reloadApprovalListItem';

@Injectable({ providedIn: 'root' })
export class ProcessService {
    readonly lockContent$: Observable<boolean>;
    /** If the process design has already started, i.e. if there is already a process. */
    readonly processDesignStarted$: Observable<boolean>;
    /** If we are in the process design time, i.e. in a Template which is in a whitelisted Workspace. */
    readonly processDesignTime$: Observable<boolean>;
    private processes$: Observable<Processes>;

    /**
     * Object = Section, SystemConnectorConfiguration or Approval
     */
    private objectProcessStep$: Observable<ObjectsProcessStep>;
    private objectStepTransitions$: Observable<ProcessStepTransitionsState>;
    /**
     * Object = Task, Custom Field or Role.
     */
    private objectsStepConfiguration$: Observable<ObjectStepConfiguration>;

    private documentIndex$: Observable<DocumentIndex>;

    constructor(
        private selectedProjectService: SelectedProjectService,
        private canvasStateManager: CanvasStateManagerService,
        private tasksService: RuumTasksService,
        private approvalStore: ApprovalStore,
        private systemConnectorStore: SystemConnectorsStore,
        private canvasSectionService: CanvasSectionsService,
        private projectBackendConnector: ProjectServiceBackendConnector,
    ) {
        this.createObservables();
        // eslint-disable-next-line @typescript-eslint/dot-notation
        window['processService'] = this;
        this.lockContent$ = this.selectedProjectService.selectedProject().pipe(pluck('configuration', 'lockContent'));
        this.processDesignTime$ = this.selectedProjectService.selectedProject().pipe(
            map((project) => !!project.belongsToTemplateId && !!project.workspaceId),
            shareReplay(1),
        );

        this.processDesignStarted$ = this.processes$.pipe(map((processes) => Object.keys(processes).length > 0));

        reloadApprovalItem(this.selectedProjectService.selectedProject(), this.approvalStore);
    }

    rootProcess(): Observable<Process> {
        return this.selectedProjectService.selectedProject().pipe(
            map((project) => project.processes[ProcessEnum.ROOT_PROCESS_ID]),
            distinctUntilChanged(),
        );
    }

    getRootProcess(): Process {
        const project = this.selectedProjectService.getSelectedProject();
        return project.processes[ProcessEnum.ROOT_PROCESS_ID];
    }

    getProcesses(): Observable<Processes> {
        return this.processes$;
    }

    getIsSectionInactive(sectionId: string): boolean {
        let inactive;
        this.isObjectInactive(sectionId)
            .pipe(take(1))
            .subscribe((bool) => (inactive = bool));
        return inactive;
    }

    getStartSection(): Observable<string> {
        return this.rootProcess().pipe(
            filter<Process>(Boolean),
            map((process) => {
                const initialStepId = process.initialStepId;
                const step = process.steps[initialStepId];
                if (step.type === 'canvas' || step.type === 'approval') {
                    return step.sectionId;
                }
                return null;
            }),
        );
    }

    isProcessInitialStep(stepId: string): Observable<boolean> {
        return this.rootProcess().pipe(
            filter<Process>(Boolean),
            map((process) => {
                return process.initialStepId === stepId;
            }),
        );
    }

    setProcessInitialStep(object: TransitionObject) {
        const projectId = this.selectedProjectService.getSelectedProjectId();
        return this.projectBackendConnector.setProcessInitialStep(projectId, object);
    }

    /**
     * Object = Section, SystemConnectorConfiguration or Approval
     */
    isObjectInactive(objectId: string): Observable<boolean> {
        return this.objectProcessStep$.pipe(
            map((objects) => {
                return !!objects[objectId] && !!objects[objectId].processActiveStepId && !objects[objectId].active;
            }),
        );
    }

    approvalsRequiredFieldsAreEmpty(objectId: string) {
        const takeOnlyApprovalStep = () =>
            filter((object: ObjectsProcessStep) => {
                return object[objectId]?.type === 'approval';
            });
        const getCustomFieldConfig = () =>
            map((object: ObjectsProcessStep) => {
                return (object[objectId] as ProcessApprovalStepWebApp)?.customFieldsConfiguration;
            });
        const getMandatoryCustomFieldsIds = (customFieldsConfiguration: StepProjectCustomFieldsConfiguration) => {
            return Object.keys(customFieldsConfiguration).filter((customFieldId) => {
                return customFieldsConfiguration[customFieldId].mandatory;
            });
        };

        const isEmptyValue = (value: unknown) => {
            if (!value) {
                return true;
            }
            if (value.constructor === Object && Object.keys(value).length === 0) {
                return true;
            }
            if (Array.isArray(value) && value.length === 0) {
                return true;
            }
            return false;
        };

        return this.selectedProjectService.selectedProject().pipe(
            switchMap((ruum) => {
                return this.objectProcessStep$.pipe(
                    takeOnlyApprovalStep(),
                    getCustomFieldConfig(),
                    filter((customFieldConfig) => !!customFieldConfig),
                    map((customFieldConfig) => {
                        return getMandatoryCustomFieldsIds(customFieldConfig);
                    }),
                    map((customFieldIds: string[]) => {
                        return customFieldIds
                            .map((customeFieldId) => ruum.customFieldsValues[customeFieldId])
                            .some(isEmptyValue);
                    }),
                );
            }),
        );
    }

    stepTransitions(objectId: string): Observable<ProcessStepTransitionState[]> {
        return this.objectStepTransitions$.pipe(map((sections) => sections[objectId] || []));
    }

    executeTransition(data: ProcessStepTransitionState) {
        const projectId = this.selectedProjectService.getSelectedProjectId();
        return this.projectBackendConnector.executeTransition(projectId, data);
    }

    createApprovalSection(data: { sectionId: string; approvalId: string; onTopOfSectionId: string }) {
        const projectId = this.selectedProjectService.getSelectedProjectId();
        return this.projectBackendConnector.createApprovalSection(projectId, data);
    }

    taskIsReadOnly(taskId: string): Observable<boolean> {
        return this.objectsStepConfiguration$.pipe(
            map(
                (objects) =>
                    !!objects.tasks[taskId] &&
                    !!objects.tasks[taskId].processActiveStepId &&
                    !objects.tasks[taskId].active,
            ),
        );
    }

    taskIsMandatory(taskId: string): Observable<boolean> {
        return this.objectsStepConfiguration$.pipe(
            map((objects) => !!objects.tasks[taskId] && objects.tasks[taskId].mandatory),
        );
    }

    fieldIsReadOnly(fieldId: string): Observable<boolean> {
        return this.objectsStepConfiguration$.pipe(
            map(
                (objects) =>
                    !!objects.fields[fieldId] &&
                    !!objects.fields[fieldId].processActiveStepId &&
                    (!objects.fields[fieldId].active || objects.fields[fieldId].readOnly),
            ),
        );
    }

    fieldIsMandatory(fieldId: string): Observable<boolean> {
        return this.objectsStepConfiguration$.pipe(
            map((objects) => !!objects.fields[fieldId] && objects.fields[fieldId].mandatory),
        );
    }

    readyOnlyFields(): Observable<string[]> {
        return this.objectsStepConfiguration$.pipe(
            distinctUntilChanged(),
            map((objects) =>
                Object.values(objects.fields)
                    .filter((field) => !field.active || field.readOnly)
                    .map((field) => field.fieldId),
            ),
        );
    }

    roleIsReadOnly(roleId: string): Observable<boolean> {
        return this.objectsStepConfiguration$.pipe(
            map(
                (objects) =>
                    !!objects.roles[roleId] &&
                    !!objects.roles[roleId].processActiveStepId &&
                    !objects.roles[roleId].active,
            ),
        );
    }

    createOrUpdateStepTransition(data: TransitionCreateData): Promise<ProcessStepTransitionState> {
        const project = this.selectedProjectService.getSelectedProject();
        const rootProcess = project.processes[ProcessEnum.ROOT_PROCESS_ID];
        if (rootProcess) {
            return this.projectBackendConnector.createOrUpdateStepTransition(
                project.id,
                ProcessEnum.ROOT_PROCESS_ID,
                data,
            );
        } else {
            return this.startProcessDesign(project.id, data);
        }
    }

    objectProcessStep<T extends ProcessStep>(objectId: string): Observable<T> {
        return this.objectProcessStep$.pipe(map<ObjectsProcessStep, T>((objects) => objects[objectId] as any));
    }

    getObjectProcessStep<T extends ProcessStep>(objectId: string): T {
        let step: T;
        this.objectProcessStep<T>(objectId)
            .pipe(take(1))
            .subscribe((s) => (step = s));
        return step;
    }

    private startProcessDesign(projectId: string, data: TransitionCreateData) {
        return this.projectBackendConnector.startProcessDesign(projectId, data);
    }

    async setStepCustomFieldConfiguration(
        processId: string,
        stepId: string,
        fieldId: string,
        mandatory: boolean,
        readOnly: boolean,
    ) {
        return await this.selectedProjectService.persistAction<SetProcessStepCustomFieldConfigurationAction>(
            'SET_PROCESS_STEP_CUSTOM_FIELD_CONFIGURATION',
            {
                processId,
                stepId,
                fieldId,
                configuration: {
                    mandatory,
                    readOnly,
                },
            },
        );
    }

    async setStepTaskConfiguration(processId: string, stepId: string, taskId: string, mandatory: boolean) {
        return await this.selectedProjectService.persistAction<SetProcessStepTaskConfigurationAction>(
            'SET_PROCESS_STEP_TASK_CONFIGURATION',
            {
                processId,
                stepId,
                taskId,
                configuration: {
                    mandatory,
                },
            },
        );
    }

    isProcessCompleted(): Observable<boolean> {
        return this.rootProcess().pipe(map((process) => process && process.activeStepId === ProcessEnum.FINAL_STEP_ID));
    }

    getTransitionObjects(fromObjectId?: string) {
        return getTransitionObjects(
            fromObjectId,
            this.selectedProjectService,
            this.systemConnectorStore,
            this.canvasStateManager,
            this.canvasSectionService,
        );
    }

    /**
     * The title of the step which was the target, i.e the toStepId, of the last transition that was executed from
     * the step associated with 'object'.
     */
    lastTransitionedToText(object: TransitionObject): Observable<string> {
        return transitionedToText(this, object);
    }

    private createObservables() {
        this.processes$ = this.selectedProjectService.selectedProject().pipe(
            map((project) => project.processes),
            distinctUntilChanged(),
        );

        /** For each section, approval and system connector configuration, in which process and step it is. */
        this.objectProcessStep$ = objectsProcessStep(this.processes$).pipe(startWith({}), shareReplay(1));

        /**
         * For each task and custom fields, in which sections they are.
         * For each section, what are the tasks and custom fields inside of it.
         */
        this.documentIndex$ = indexDocument(this.canvasStateManager.fullStateChange()).pipe(
            startWith({ tasks: {}, fields: {}, sections: {}, roles: {} }),
            shareReplay(1),
        );

        /** For each task, custom fields and role, what is the configuration for it based on the step they are. */
        this.objectsStepConfiguration$ = objectsStepConfiguration(
            this.documentIndex$,
            this.objectProcessStep$,
            this.processes$,
        ).pipe(startWith({ tasks: {}, fields: {}, roles: {} }), shareReplay(1));

        /** For each section, approval and system connector configuration, what are the step transitions associated with it and whether they are enabled or not. */
        this.objectStepTransitions$ = processStepTransitions(
            this.selectedProjectService.selectedProject(),
            this.objectProcessStep$,
            this.documentIndex$,
            this.tasksService.taskMap$.pipe(map((taskMap) => <TasksMap>taskMap)),
        ).pipe(startWith({}), shareReplay(1));
    }
}
