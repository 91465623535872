import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { TaskPriority } from '@ruum/ruum-reducers';
import { TaskPriorityService } from '../../task/task-priority/task-priority.service';
import { RuumDropdownItem } from '../../ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-task-list-priority-button',
    template: `
        <ng-container *ngIf="getPriority(id) as priority">
            <div class="btn btn-sm btn-icon" [ngClass]="getButtonNgClass(priority)" aria-label="Set Priority">
                <i class="icon" [ngClass]="getIconNgClass(priority)"></i>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskListPriorityButtonComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    @Input()
    id: TaskPriority;

    constructor(private taskPriorityService: TaskPriorityService) {}

    ngOnInit() {}

    getPriority(id: TaskPriority): RuumDropdownItem {
        return this.taskPriorityService.getPriorityById(id);
    }

    getButtonNgClass(priority: RuumDropdownItem): string {
        return priority.id ? `btn-link-${priority.theme}` : 'btn-link-gray-24';
    }

    getIconNgClass(priority: RuumDropdownItem): string[] {
        const icon = priority.icon ? priority.icon : 'icon-priority-outlined';
        return [icon];
    }
}
