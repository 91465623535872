import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SavedViewTypeDefinition } from './saved-views.model';

@Injectable({ providedIn: 'root' })
export class SavedViewVisualizationsService {
    getVisualization(typeId: string): Observable<SavedViewTypeDefinition> {
        const entities = this.entities();
        return of(entities[typeId]);
    }

    getVisualizations(typeIds): Observable<SavedViewTypeDefinition[]> {
        const entities = this.entities();
        const list = typeIds.map((typeId) => entities[typeId]);
        return of(list);
    }

    getVisualizationsMap(): Observable<{ [key: string]: SavedViewTypeDefinition }> {
        return of(this.entities());
    }

    getColumnFieldIdsByViewType(typeId: string): string[] {
        const entities = this.entities();

        return entities[typeId].columnFieldIds;
    }

    private entities(): { [key: string]: SavedViewTypeDefinition } {
        const lobbyList: SavedViewTypeDefinition = {
            viewType: 'homeLobbyList',
            name: 'Ruums',
            filterFieldIds: [
                'standard_field_lobby_list_status',
                'standard_field_created_by',
                'standard_field_tags',
                'standard_field_groupsAndRuums',
                'standard_field_workspace',
                'standard_field_group',
                // 'standard_field_roles', // TODO: fix it
            ],
            columnFieldIds: [
                'standard_field_lobby_list_status',
                'standard_field_favorite',
                'standard_field_comments',
                'standard_field_lobby_list_name',
                'standard_field_changed_at',
                'standard_field_created_by',
                'standard_field_tags',
                'standard_field_workspace',
                'standard_field_group',
            ],
        };
        const taskList: SavedViewTypeDefinition = {
            viewType: 'homeTaskList',
            name: 'Tasks',
            filterFieldIds: [
                'standard_field_task_list_status',
                'standard_field_milestone',
                'standard_field_priority',
                'standard_field_created_by',
                'standard_field_assignees',
                'standard_field_roles',
                'standard_field_workspace',
                'standard_field_group',
                'standard_field_project',
            ],
            columnFieldIds: [
                'standard_field_task_list_status',
                'standard_field_comments',
                'standard_field_task_list_name',
                'standard_field_task_list_subtasks',
                'standard_field_priority',
                'standard_field_workspace',
                'standard_field_group',
                'standard_field_project',
                'standard_field_section',
                'standard_field_startdate',
                'standard_field_duedate',
                'standard_field_assignees',
                'standard_field_roles',
            ],
        };
        const kanban: SavedViewTypeDefinition = {
            viewType: 'projectKanban',
            name: 'Kanban',
            filterFieldIds: ['standard_field_created_by', 'standard_field_assignees', 'standard_field_roles'],
            columnFieldIds: [],
        };
        const approvalList: SavedViewTypeDefinition = {
            viewType: 'homeApprovalList',
            name: 'Approvals',
            filterFieldIds: [
                'standard_field_approval_list_status',
                'standard_field_approval_list_approver_role',
                'standard_field_approval_list_approver',
                'standard_field_approval_list_responsibles',
                'standard_field_requested_by',
                'standard_field_workspace',
                'standard_field_group',
                'standard_field_project',
            ],
            columnFieldIds: [
                'standard_field_approval_list_status',
                'standard_field_approval_list_approver',
                'standard_field_approval_list_actions',
                'standard_field_project',
                'standard_field_section',
                'standard_field_changed_at',
                'standard_field_requested_by',
                'standard_field_requested_on',
                'standard_field_workspace',
                'standard_field_group',
            ],
        };

        return {
            homeLobbyList: {
                viewType: 'homeLobbyList',
                name: lobbyList.name,
                filterFieldIds: this.filterIds(lobbyList.filterFieldIds, []),
                columnFieldIds: this.filterIds(lobbyList.columnFieldIds, []),
            },
            workspaceLobbyList: {
                viewType: 'workspaceLobbyList',
                name: lobbyList.name,
                filterFieldIds: this.filterIds(lobbyList.filterFieldIds, ['standard_field_workspace']),
                columnFieldIds: this.filterIds(lobbyList.columnFieldIds, ['standard_field_workspace']),
            },
            groupLobbyList: {
                viewType: 'groupLobbyList',
                name: lobbyList.name,
                filterFieldIds: this.filterIds(lobbyList.filterFieldIds, [
                    'standard_field_workspace',
                    'standard_field_groupsAndRuums',
                    'standard_field_group',
                ]),
                columnFieldIds: this.filterIds(lobbyList.columnFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                ]),
            },
            homeTaskList: {
                viewType: 'homeTaskList',
                name: taskList.name,
                filterFieldIds: this.filterIds(taskList.filterFieldIds, []),
                columnFieldIds: this.filterIds(taskList.columnFieldIds, []),
            },
            workspaceTaskList: {
                viewType: 'workspaceTaskList',
                name: taskList.name,
                filterFieldIds: this.filterIds(taskList.filterFieldIds, ['standard_field_workspace']),
                columnFieldIds: this.filterIds(taskList.columnFieldIds, ['standard_field_workspace']),
            },
            groupTaskList: {
                viewType: 'groupTaskList',
                name: taskList.name,
                filterFieldIds: this.filterIds(taskList.filterFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                ]),
                columnFieldIds: this.filterIds(taskList.columnFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                ]),
            },
            projectTaskList: {
                viewType: 'projectTaskList',
                name: taskList.name,
                filterFieldIds: this.filterIds(taskList.filterFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                    'standard_field_project',
                ]),
                columnFieldIds: this.filterIds(taskList.columnFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                    'standard_field_project',
                ]),
            },
            projectKanban: {
                viewType: 'projectKanban',
                name: kanban.name,
                filterFieldIds: this.filterIds(kanban.filterFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                    'standard_field_project',
                ]),
                columnFieldIds: this.filterIds(kanban.columnFieldIds, []),
            },
            homeApprovalList: {
                viewType: approvalList.viewType,
                name: approvalList.name,
                filterFieldIds: this.filterIds(approvalList.filterFieldIds, []),
                columnFieldIds: this.filterIds(approvalList.columnFieldIds, []),
            },
            workspaceApprovalList: {
                viewType: 'workspaceApprovalList',
                name: approvalList.name,
                filterFieldIds: this.filterIds(approvalList.filterFieldIds, ['standard_field_workspace']),
                columnFieldIds: this.filterIds(approvalList.columnFieldIds, ['standard_field_workspace']),
            },
            groupApprovalList: {
                viewType: 'groupApprovalList',
                name: approvalList.name,
                filterFieldIds: this.filterIds(approvalList.filterFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                ]),
                columnFieldIds: this.filterIds(approvalList.columnFieldIds, [
                    'standard_field_workspace',
                    'standard_field_group',
                ]),
            },
        };
    }

    filterIds(allIds: string[], exlcudedIds: string[]) {
        return allIds.filter((id) => exlcudedIds.indexOf(id) === -1);
    }
}
