import { EditorState } from 'prosemirror-state';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export function indexDocument(state$: Observable<EditorState>): Observable<DocumentIndex> {
    return combineLatest([state$.pipe(filter<EditorState>(Boolean), distinctUntilChanged())]).pipe(
        map(([state]) => buildIndex(state)),
    );
}

function buildIndex(state: EditorState): DocumentIndex {
    const tasks: TaskSections = {};
    const fields: CustomFieldSections = {};
    const sections: SectionsObjects = {};
    const roles: RoleSections = {};

    state.doc.content.forEach((section) => {
        const sectionContent = section.content.maybeChild(1) || [];
        sections[section.attrs.id] = {
            tasks: [],
            fields: [],
            roles: [],
        };
        sectionContent.forEach((node) => {
            if (node.type.name === 'task') {
                if (!tasks[node.attrs.id]) {
                    tasks[node.attrs.id] = [];
                }
                tasks[node.attrs.id].push(section.attrs.id);
                sections[section.attrs.id].tasks.push(node.attrs.id);
            } else if (node.type.name === 'customField') {
                const fieldId = node.attrs.id.split('-')[0];
                if (!fields[fieldId]) {
                    fields[fieldId] = [];
                }
                fields[fieldId].push(section.attrs.id);
                sections[section.attrs.id].fields.push(fieldId);
            } else if (node.type.name === 'functionalRole') {
                if (!roles[node.attrs.id]) {
                    roles[node.attrs.id] = [];
                }
                roles[node.attrs.id].push(section.attrs.id);
                sections[section.attrs.id].roles.push(node.attrs.id);
            }
        });
    });

    return {
        tasks,
        fields,
        sections,
        roles,
    };
}

/**
 * Lookup data structure for document data.
 */
export interface DocumentIndex {
    tasks: TaskSections;
    fields: CustomFieldSections;
    roles: RoleSections;
    sections: SectionsObjects;
}

/** In which sections a given task is. The task will be in only one section. */
export interface TaskSections {
    [taskId: string]: string[];
}

/** In which sections a given custom field is. The custom field can be in many sections. */
export interface CustomFieldSections {
    [fieldId: string]: string[];
}

/** In which sections a given functional role is. The role can be in many sections. */
export interface RoleSections {
    [roleId: string]: string[];
}

export interface SectionsObjects {
    [sectionId: string]: {
        tasks: string[];
        fields: string[];
        roles: string[];
    };
}
