import { Injectable } from '@angular/core';
import { RuumAction } from '@ruum/ruum-reducers';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreExceptionCatcher {
    private resultObs: Subject<DispatchResult> = new Subject<DispatchResult>();

    /**
     * Returns a promise that will resolve or fail after 'action' is dispatched to the store.
     * The object passed to this function should be the same as the one passed to store.dispatch.
     * This function should be called before 'store.dispatch(action)'.
     */
    waitForResult(action: RuumAction): Promise<void> {
        return this.resultObs
            .pipe(
                filter((result) => result.action === action),
                take(1),
            )
            .toPromise()
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
            });
    }

    /** Should only be used by meta-reducer */
    sendResult(action: RuumAction, error?: any): void {
        this.resultObs.next({ action, error });
    }
}

export interface DispatchResult {
    action: any;
    error?: any;
}
