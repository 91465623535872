import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruumTemplateCategoryName' })
export class TemplateCategoryNamePipe implements PipeTransform {
    transform(categoryName: string): string {
        const ownedByMe = 'Owned by you';

        if (categoryName.toLowerCase() === ownedByMe.toLowerCase()) {
            return 'Created by you';
        } else {
            return categoryName;
        }
    }
}
