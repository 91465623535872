import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RuumCommonModule } from '../../../common/common.module';
import { RecordLookupDialogComponent } from './record-lookup-dialog.component';

@NgModule({
    declarations: [RecordLookupDialogComponent],
    imports: [CommonModule, FormsModule, CdkTableModule, RuumCommonModule],
    exports: [RecordLookupDialogComponent],
})
export class RecordLookupDialogModule {}
