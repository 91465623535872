import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Ruum } from '@ruum/ruum-reducers';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { ProfileService } from '../../connectors/profile/profile.service';
import { TrackingConnector } from '../../trackingConnector.service';
import { GettingStartedComponent } from './getting-started.component';

const DEFAULT_CONFIGURATION: GettingStartedDialogConfiguration = {
    title: 'Welcome to your Ruum lobby',
    videoUrl: 'https://www.youtube.com/embed/WhJzetQzxB4',
    description:
        'Your lobby contains content that you’ve created yourself or have been invited to participate in by your team members. Watch this short introduction to learn about creating and managing your content, viewing your task list, and accessing your templates library.',
    addressing: true,
};
const IN_PROJECT_CONFIGURATION: GettingStartedDialogConfiguration = {
    title: 'Welcome to your Ruum',
    videoUrl: 'https://www.youtube.com/embed/L64v6tbIJKE',
    description:
        'A Ruum is a collaborative space for you and your team to manage your work or project. Watch this short introduction to learn how to add content to your Ruum, invite your team, create tasks, and more.',
    addressing: true,
};

const IN_PROCESS_CONFIGURATION: GettingStartedDialogConfiguration = {
    title: 'Running your SAP Ruum Process',
    videoUrl: 'https://www.youtube.com/embed/YB9z1agOCfE',
    description: `It looks like you're currently viewing a Ruum created from a process template. Watch this short introduction to learn how to interact with custom fields, active sections, approvals, and more.`,
    addressing: false,
};

const TEMPLATE_LIBRARY_CONFIGURATION: GettingStartedDialogConfiguration = {
    title: 'Welcome to your Template Library',
    videoUrl: 'https://www.youtube.com/embed/1iusUVRAIaE',
    description:
        'Templates provide a preset layout for your ruum or section, saving you from replicating work and losing time with repetitive project configurations.',
    addressing: true,
};

@Injectable({ providedIn: 'root' })
export class GettingStartedService implements OnDestroy {
    private subcriptions = new Subscription();
    userName: string;
    selectedProject: Ruum;

    constructor(
        private trackingConnector: TrackingConnector,
        private modalService: NgbModal,
        private profileService: ProfileService,
        private activatedRoute: ActivatedRoute,
        private appStoreWrapper: AppStoreWrapper,
    ) {
        this.subcriptions.add(
            this.profileService
                .userProfile()
                .pipe(
                    filter((profile) => !!profile && !!profile.fullName),
                    map((profile) => profile.fullName),
                    map((userName) => userName.split(' ')[0]),
                )
                .subscribe((firstName) => (this.userName = firstName)),
        );
        this.subcriptions.add(
            this.appStoreWrapper
                .selectedRuum()
                .subscribe((selectedProject) => (this.selectedProject = selectedProject)),
        );
    }

    ngOnDestroy() {
        this.subcriptions.unsubscribe();
    }

    openGettingStarted(fromHelp: boolean) {
        const trackedEventObject: string = fromHelp ? 'main_navbar_getting_started' : 'welcome_getting_started';
        const modalRef: NgbModalRef = this.modalService.open(GettingStartedComponent, {
            centered: true,
            size: 'lg',
            backdrop: fromHelp ? true : 'static',
            beforeDismiss: () => {
                return fromHelp;
            },
        });

        const configuration: GettingStartedDialogConfiguration = this.getConfiguration();
        modalRef.componentInstance.title = this.getTitle(configuration);
        modalRef.componentInstance.description = configuration.description;
        modalRef.componentInstance.videoUrl = configuration.videoUrl;
        this.trackingConnector.trackEventInferCategoryFromUrl(trackedEventObject, 'opened');

        modalRef.result.finally(() => {
            this.trackingConnector.trackEventInferCategoryFromUrl(trackedEventObject, 'closed');
            if (!fromHelp) {
                this.profileService.dismissWelcomeMessage();
            }
            return fromHelp;
        });
    }

    getConfiguration(): GettingStartedDialogConfiguration {
        let configuration: GettingStartedDialogConfiguration = DEFAULT_CONFIGURATION;
        if (!!this.selectedProject.id) {
            if (Object.keys(this.selectedProject.processes || {}).length > 0) {
                configuration = IN_PROCESS_CONFIGURATION;
            } else {
                configuration = IN_PROJECT_CONFIGURATION;
            }
        } else {
            const isTemplateLibrary: boolean = this.isTemplateLibrary();
            if (isTemplateLibrary) {
                configuration = TEMPLATE_LIBRARY_CONFIGURATION;
            }
        }
        return configuration;
    }

    isTemplateLibrary(): boolean {
        try {
            return this.activatedRoute.root.firstChild.firstChild.snapshot.data.isTemplateLibrary;
        } catch (err) {
            // fallback
            return window.location.pathname.startsWith('/templates/');
        }
    }

    private getTitle(configuration: GettingStartedDialogConfiguration): string {
        const titleSuffix: string = !!this.userName && configuration.addressing ? `, ${this.userName}!` : '';
        return configuration.title + titleSuffix;
    }
}

interface GettingStartedDialogConfiguration {
    title: string;
    description: string;
    videoUrl: string;
    addressing: boolean;
}
