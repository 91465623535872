import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DropdownModule } from '../../shared/ui-components/dropdown/dropdown.module';
import { UiComponentsModule } from '../../shared/ui-components/ui-components.module';
import { RuumCommonModule } from '../common.module';
import { RuumHelpModule } from '../help/help.module';
import { BreadcrumbActiveItemComponent } from './breadcrumbs/breadcrumb-active-item.component';
import { BreadcrumbProjectGroupItemComponent } from './breadcrumbs/breadcrumb-project-group-item.component';
import { BreadcrumbSectionsTemplatesItemComponent } from './breadcrumbs/breadcrumb-section-templates-item.component';
import { BreadcrumbSeparatorComponent } from './breadcrumbs/breadcrumb-separator.component';
import { BreadcrumbTemplatesItemComponent } from './breadcrumbs/breadcrumb-templates-item.component';
import { BreadcrumbWorkspaceItemComponent } from './breadcrumbs/breadcrumb-workspace-item.component';
import { MainNavbarComponent } from './main-navbar/main-navbar.component';
import { NavbarBackButtonComponent } from './navbar-back-button/navbar-back-button.component';
import { NavbarTabBottomComponent } from './navbar-tab/navbar-tab-bottom.component';
import { NavbarTabTopComponent } from './navbar-tab/navbar-tab-top.component';
import { NavbarComponent } from './navbar.component';
import { NavbarService } from './navbar.service';
import { SecondaryNavbarBottomComponent } from './secondary-navbar-bottom/secondary-navbar-bottom.component';
import { SecondaryNavbarTopComponent } from './secondary-navbar-top/secondary-navbar-top.component';
import { SecondaryNavbarComponent } from './secondary-navbar/secondary-navbar.component';
import { SettingsModalComponent } from './settings/settings-modal/settings-modal.component';
import { SettingsPopoverComponent } from './settings/settings-popover/settings-popover.component';

const MODULES = [CommonModule, RouterModule, RuumCommonModule, RuumHelpModule, UiComponentsModule, DropdownModule];

const SERVICES = [NavbarService];

const ENTRY_COMPONENTS = [SettingsModalComponent];

const COMPONENTS = [
    NavbarComponent,
    MainNavbarComponent,
    NavbarTabTopComponent,
    NavbarTabBottomComponent,
    SettingsPopoverComponent,
    SecondaryNavbarBottomComponent,
    SecondaryNavbarTopComponent,
    SecondaryNavbarComponent,
    BreadcrumbSeparatorComponent,
    BreadcrumbActiveItemComponent,
    BreadcrumbProjectGroupItemComponent,
    BreadcrumbWorkspaceItemComponent,
    BreadcrumbTemplatesItemComponent,
    BreadcrumbSectionsTemplatesItemComponent,
    NavbarBackButtonComponent,
    ...ENTRY_COMPONENTS,
];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS],
    providers: [...SERVICES],
    exports: [...MODULES, ...COMPONENTS],
})
export class RuumNavbarModule {}
