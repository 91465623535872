import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { BaseFormComponent } from '../common/components/form.component';
import { ModalService } from '../common/modal/modal.service';
import { TechnicalUserListService } from './technical-user-list.service';

@Component({
    selector: 'ruum-admin-create-technical-user',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <div class="header">
                    <nav class="navbar navbar-light navbar-expand-lg">
                        <h3 class="ml-3 navbar-text">New Technical User</h3>
                    </nav>
                </div>

                <div class="body">
                    <technical-user-form [form]="form" [isCreating]="true"></technical-user-form>
                </div>

                <div class="footer">
                    <button
                        [disabled]="form.invalid"
                        class="btn btn-primary float-right"
                        type="button"
                        (click)="save()"
                    >
                        Create
                    </button>
                    <button class="btn btn-outline-secondary float-right" type="button" (click)="back()">
                        Cancel
                    </button>
                </div>
            </div>
            <ruum-max-content> </ruum-max-content
        ></ruum-max-content>
    `,
    styles: [``],
})
export class AdminCreateTechnicalUserComponent extends BaseFormComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList =
        'd-flex flex-column w-100 mw-100 h-100 mh-100 px-2 px-sm-4 px-md-5 px-lg-8 overflow-y';

    private subscriptions: Subscription[] = [];

    constructor(
        private technicalUserListService: TechnicalUserListService,
        private modalService: ModalService,
        private fb: FormBuilder,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {
        super();

        this.form = this.fb.group({
            enterpriseId: [{ value: '', disabled: true }],
            userId: ['', Validators.required],
            name: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.selectedEnterpriseService.selectedEnterpriseId().subscribe((enterpriseId: string) => {
                this.form.get('enterpriseId').setValue(enterpriseId);
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subs) => subs.unsubscribe());
    }

    async save(): Promise<void> {
        try {
            await this.technicalUserListService.create(this.form.getRawValue());
            this.form.markAsPristine();
            this.back();
        } catch (e) {
            console.error(e);
            if (e.error && e.error.error) {
                this.modalService.openErrorDialog(e.error.error.message);
            } else {
                this.modalService.openErrorDialog('Can you please try again?');
            }
        }
    }

    back() {
        history.back();
    }
}
