import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { componentHelper } from '../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../ui-components/ui-components.type';
import { FilterTagSelectService } from './filter-tag-select.service';

@Component({
    selector: 'ruum-filter-tag-select',
    template: `
        <ruum-select
            [select]="select"
            [search]="true"
            [size]="size"
            [theme]="theme"
            [type]="type"
            [loading]="showLoading$ | async"
            [lightBackground]="false"
            [multiSelect]="multiSelect"
            [disabled]="disabled"
            (loadMoreOptions)="loadMore()"
            (isOpenChange)="isOpenChange($event)"
            (searchChange)="searchChange($event)"
            (selectChange)="selectChange($event)"
        >
            <ruum-select-content>
                <ruum-select-placeholder *ngIf="!(selectedOptions$ | async)?.length">
                    {{ placeholder }}
                </ruum-select-placeholder>

                <ng-container *ngIf="selectedOptions$ | async as selectedOptions">
                    <ng-container
                        *ngFor="
                            let selectedOption of selectedOptions | slice: 0:maxDisplayedSelectedOptions;
                            let last = last
                        "
                    >
                        <ruum-tag
                            [name]="selectedOption"
                            [hover]="false"
                            [theme]="theme"
                            [componentClass]="getTagMarging(size, last)"
                        ></ruum-tag>
                    </ng-container>

                    <ruum-tag
                        *ngIf="selectedOptions.length > maxDisplayedSelectedOptions"
                        [name]="getOtherTagsNumber(selectedOptions.length, maxDisplayedSelectedOptions)"
                        [type]="'link'"
                        [hover]="false"
                        [componentClass]="'px-0'"
                    ></ruum-tag>
                </ng-container>
            </ruum-select-content>
            <ruum-select-option
                *ngFor="let option of options$ | async"
                [value]="option"
                [content]="option"
            ></ruum-select-option>
        </ruum-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTagSelectComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    @Input() set selectedOptionIds(ids: string[]) {
        this.selectedOptionIdsSource.next(ids);
    }

    get selectedOptionIds(): string[] {
        return this.selectedOptionIdsSource.getValue();
    }

    @Input() workspaceId: string;
    @Input() groupId: string;
    @Input() placeholder = 'Select Value';
    @Input() placement = ['bottom-left', 'top-left'];
    @Input() multiSelect = false;
    @Input() isMobile = false;
    @Input() idField = 'id';
    @Input() contentField = 'name';

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    selectedOptions$: Observable<string[]>;

    options$: Observable<string[]>;
    showLoading$: Observable<boolean>;

    maxDisplayedSelectedOptions = 1;

    get select(): string | string[] {
        if (this.multiSelect) {
            return this.selectedOptionIds;
        } else {
            return this.selectedOptionIds[0];
        }
    }

    private selectedOptionIdsSource = new BehaviorSubject([]);

    constructor(private filterTagSelectService: FilterTagSelectService) {}

    ngOnInit() {
        this.selectedOptions$ = this.selectedOptionIdsSource.asObservable();
        this.options$ = this.filterTagSelectService.getStoreRows$();
        this.showLoading$ = this.filterTagSelectService.hasMore$;
    }

    ngOnDestroy() {
        this.filterTagSelectService.stopLoadingList();
    }

    isOpenChange(open: boolean) {
        if (open) {
            this.filterTagSelectService.resetFilters();
            this.filterTagSelectService.workspaceId$.next(this.workspaceId);
            this.filterTagSelectService.groupId$.next(this.groupId);
            this.filterTagSelectService.listType$.next(this.groupId ? 'projects' : 'all');
            this.filterTagSelectService.loadList();
        } else {
            this.filterTagSelectService.stopLoadingList();
        }
    }

    searchChange(search) {}

    loadMore() {
        this.filterTagSelectService.maybeGoToNextPage();
    }

    selectChange(event) {
        this.changed.emit(event);
    }

    getTagMarging(size, isLast) {
        const mr = size === 'sm' ? 'mr-1' : 'mr-2';
        return isLast ? 'mr-0' : mr;
    }

    getOtherTagsNumber(all: number, shown: number): string {
        const value = all - shown;
        return `+${value > 99 ? 99 : value}`;
    }
}
