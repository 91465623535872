import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-task-dates-divider',
    template: `
        <i class="icon" [class.icon-minus]="(!startDate && !dueDate) || (!!startDate && !!dueDate)"></i>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDatesDividerComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            componentHelper.getDefaultClassName(),
            componentHelper.getThemeClassName(this.getTheme(this.theme, this.dueDate), this.type),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getCursorClassName(this.disabled),
            this.getSizeClassName(this.size),
            this.componentClass,
        ]);
    }

    // Component properties
    @Input() startDate: Day;
    @Input() dueDate: Day;

    // Common properties
    @Input() size: ComponentSize = 'md';
    @Input() type: ComponentType = 'link';
    @Input() theme: ComponentTheme;
    @Input() rounded = false;
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    constructor() {}

    ngOnInit() {}

    getTheme(theme, startDate) {
        const defaultTheme = startDate ? 'dark' : 'gray-24';
        return theme ? theme : defaultTheme;
    }

    private getSizeClassName(size: string): string {
        return ['btn', size].filter((el) => !!el).join('-');
    }
}
