import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { SystemSelectComponent } from './system-select.component';

@NgModule({
    declarations: [SystemSelectComponent],
    imports: [CommonModule, SelectModule],
    exports: [SystemSelectComponent],
})
export class SystemSelectModule {}
