import { EntityAction, RuumAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { getDefaultPaginatedList } from '../../lobby/lobby.reducer';
import { WebhooksEntitiesListReducer } from './entities/webhook-entities-list.reducer';
import { WebhookConfigurationListItem } from './webhooks.model';

export enum WEBHOOK_LIST_ACTION_TYPES {
    WEBHOOKS_LOADED = 'WEBHOOKS_LOADED',
    SINGLE_WEBHOOK_LOADED = 'SINGLE_WEBHOOK_LOADED',
    WEBHOOK_CREATED = 'WEBHOOK_CREATED',
    WEBHOOK_UPDATED = 'WEBHOOK_UPDATED',
    WEBHOOK_DELETED = 'WEBHOOK_DELETED',
}

export type WebhooksLoadedAction = EntityAction<
    'WEBHOOKS_LOADED',
    {
        page: PaginatedList<WebhookConfigurationListItem>;
    }
>;

export type WebhookCreatedAction = EntityAction<
    'WEBHOOK_CREATED',
    {
        webhook: WebhookConfigurationListItem;
    }
>;

export type WebhookUpdatedAction = EntityAction<
    'WEBHOOK_UPDATED',
    {
        webhook: WebhookConfigurationListItem;
    }
>;

export type SingleWebhookLoadedAction = EntityAction<
    'SINGLE_WEBHOOK_LOADED',
    {
        webhook: WebhookConfigurationListItem;
    }
>;

export type WebhookDeletedAction = EntityAction<
    'WEBHOOK_DELETED',
    {
        webhookId: string;
    }
>;

export function WebhookListReducer(
    currentState: PaginatedList<WebhookConfigurationListItem> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<WebhookConfigurationListItem> {
    switch (action.type) {
        case WEBHOOK_LIST_ACTION_TYPES.WEBHOOKS_LOADED:
            return webhooksLoaded(currentState, action);
        case WEBHOOK_LIST_ACTION_TYPES.SINGLE_WEBHOOK_LOADED:
            return singleWebhookLoaded(currentState, action);
        case WEBHOOK_LIST_ACTION_TYPES.WEBHOOK_CREATED:
            return webhookCreated(currentState, action);
        case WEBHOOK_LIST_ACTION_TYPES.WEBHOOK_UPDATED:
            return webhookUpdated(currentState, action);
        case WEBHOOK_LIST_ACTION_TYPES.WEBHOOK_DELETED:
            return webhookDeleted(currentState, action);
        default:
            return forwardActionToChildrenReducers(currentState, action);
    }
}

function webhooksLoaded(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: WebhooksLoadedAction,
): PaginatedList<WebhookConfigurationListItem> {
    const data = action.payload.page;
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}

function singleWebhookLoaded(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: SingleWebhookLoadedAction,
): PaginatedList<WebhookConfigurationListItem> {
    const loadedWebhook = action.payload.webhook;
    let added = false;

    const rows = currentState.rows.map((webhook) => {
        if (webhook.id === action.payload.webhook.id) {
            added = true;
            return action.payload.webhook;
        } else {
            return webhook;
        }
    });

    if (!added) {
        rows.push(loadedWebhook);
    }

    return {
        ...currentState,
        rows,
    };
}

function webhookCreated(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: WebhookCreatedAction,
): PaginatedList<WebhookConfigurationListItem> {
    return {
        ...currentState,
        rows: currentState.rows.concat(action.payload.webhook),
    };
}

function webhookUpdated(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: WebhookUpdatedAction,
): PaginatedList<WebhookConfigurationListItem> {
    return {
        ...currentState,
        rows: currentState.rows.map((webhook) => {
            if (webhook.id === action.payload.webhook.id) {
                return action.payload.webhook;
            } else {
                return webhook;
            }
        }),
    };
}

function webhookDeleted(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: WebhookDeletedAction,
): PaginatedList<WebhookConfigurationListItem> {
    return {
        ...currentState,
        rows: currentState.rows.filter((webhook) => webhook.id !== action.payload.webhookId),
    };
}

function forwardActionToChildrenReducers(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: RuumAction,
): PaginatedList<WebhookConfigurationListItem> {
    if (action.payload && action.payload.webhookId !== null) {
        currentState = augmentCurrentStateIfRequired(currentState, action);

        return {
            ...currentState,
            rows: currentState.rows.map((webhook) => {
                if (webhook.id === action.payload.webhookId) {
                    return {
                        ...webhook,
                        entities: WebhooksEntitiesListReducer(webhook.entities, action),
                    };
                } else {
                    return webhook;
                }
            }),
        };
    } else {
        return currentState;
    }
}
/**
 * If one of the children reducers is firing an action and the parent entity was not yet loaded,
 * we will create a dummy structure so that the data structure in the store is maintained properly
 */

function augmentCurrentStateIfRequired(
    currentState: PaginatedList<WebhookConfigurationListItem>,
    action: RuumAction,
): PaginatedList<WebhookConfigurationListItem> {
    if (
        actionPayloadHasWebhookId(action) &&
        (currentState.rows.length === 0 || !currentState.rows.find((wh) => wh.id === action.payload.webhookId))
    ) {
        const rows = [
            ...currentState.rows,
            <WebhookConfigurationListItem>{
                id: action.payload.webhookId,
            },
        ];

        return {
            ...currentState,
            rows,
        };
    } else {
        return currentState;
    }
}

function actionPayloadHasWebhookId(action: RuumAction): boolean {
    return action.payload && !!action.payload.webhookId;
}
