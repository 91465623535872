import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ruum-sidepanel-title',
    template: `
        <div class="d-flex flex-fill align-items-center p-5 minw-0">
            <ng-content></ng-content>
        </div>
    `,
})
export class RuumSidepanelTitleComponent {
    @HostBinding('class') hostClassList = 'd-flex';
}
