import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { RuumAlertService } from '../../components/alert/alert.service';
import { PaginatedList } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ProjectParticipantWithAllRoles, ReadModelBackendConnector } from '../readModelConnector.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceParticipantProjectAccessListService extends PaginatedListLoader<
    ProjectParticipantWithAllRoles,
    { projectId: string },
    null
> {
    readonly projectId$ = new BehaviorSubject<string>(undefined);
    readonly list$ = new BehaviorSubject<PaginatedList<ProjectParticipantWithAllRoles>>(getDefaultPaginatedList());

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.getListObservable()
            .pipe(
                scan((currentState, page) => {
                    if (!!currentState && page.currentPage > 1) {
                        return {
                            ...page,
                            rows: [...currentState.rows, ...page.rows],
                        };
                    } else {
                        return page;
                    }
                }),
            )
            .subscribe((list) => {
                this.list$.next(list);
            });
    }

    getFilters$(): Observable<WorkspaceParticipantListFilters> {
        return this.projectId$.pipe(
            map((projectId) => ({ projectId })),
            filter((filters) => !!filters.projectId),
        );
    }

    getData(
        page: number,
        filters: WorkspaceParticipantListFilters,
    ): Observable<PaginatedList<ProjectParticipantWithAllRoles>> {
        return this.readModelConnector.getProjectAccess(
            { page, pageSize: 50 },
            filters.projectId,
            {
                isWorkspaceParticipant: true,
                includeNoAccess: true,
                isTechnicalUser: false,
            },
            { by: 'lastOnlineAt', direction: 'desc' },
        );
    }

    getStoreData$() {
        return this.list$;
    }
}

export interface WorkspaceParticipantListFilters {
    projectId: string;
}
