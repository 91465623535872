import { FocusKeyManager } from '@angular/cdk/a11y';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkspaceListService } from '../connectors/workspace/workspace-list.service';
import { SidenavWorkspaceListItemComponent } from './sidenav-workspace-list-item.component';

export interface SideNavWorkspaceListitem {
    id: string;
    icon?: string;
    path: string;
    name: string;
    type: string;
    tooltip?: string;
    workspaceAdmin?: boolean;
}

@Component({
    selector: 'ruum-sidenav-workspace-list',
    template: `
        <div
            class="d-flex flex-column ruum-sidenav-nav-item-group"
            tabindex="0"
            role="listbox"
            [attr.aria-label]="'WORKSPACES'"
            [attr.aria-multiselectable]="false"
            [attr.aria-expanded]="!collapsed"
            (keydown)="manage($event)"
        >
            <div
                class="d-flex align-items-center cursor-pointer minw-0 px-3 py-2 ruum-sidenav-nav-item"
                (click)="toggle()"
                (keydown.space)="toggle()"
                (keydown.enter)="toggle()"
            >
                <div class="btn btn-xs btn-icon btn-link-white-64 no-hover">
                    <i class="icon" [class.icon-cevron-right]="collapsed" [class.icon-cevron-down]="!collapsed"></i>
                </div>
                <div class="text-tiny font-weight-bold text-truncate text-white-64">WORKSPACES</div>
            </div>
            <div *ngIf="!collapsed" class="d-flex flex-fill flex-column overflow-y pb-2" [style.max-height.vh]="40">
                <ruum-sidenav-workspace-list-item
                    *ngFor="let option of options$ | async; trackBy: trackById"
                    [id]="option.id"
                    [name]="option.name"
                    [title]="option.name"
                    [path]="option.path"
                    [isAdmin]="option.workspaceAdmin"
                    [style.min-height.px]="32"
                ></ruum-sidenav-workspace-list-item>
                <ruum-load-more
                    *ngIf="loading$ | async"
                    [size]="'sm'"
                    [theme]="'white'"
                    [scrollElement]="scrollElement"
                    (loadMore)="loadMore()"
                ></ruum-load-more>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavWorkspaceListComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column border-white-16 border-bottom minw-0';

    @ViewChildren(SidenavWorkspaceListItemComponent) viewChildren: QueryList<SidenavWorkspaceListItemComponent>;

    options$: Observable<SideNavWorkspaceListitem[]>;
    loading$: Observable<boolean>;

    collapsed = false;

    private keyManager: FocusKeyManager<SidenavWorkspaceListItemComponent>;
    constructor(private elementRef: ElementRef, private workspaceListService: WorkspaceListService) {}

    ngOnInit() {
        this.workspaceListService.reload();
        this.workspaceListService.loadList();
        this.options$ = this.getOptions();
        this.loading$ = this.getLoading();
    }

    ngAfterViewInit(): void {
        this.keyManager = new FocusKeyManager(this.viewChildren).withWrap();
    }

    ngOnDestroy(): void {
        this.workspaceListService.stopLoadingList();
    }

    get scrollElement() {
        return this.elementRef.nativeElement.querySelector('.overflow-y');
    }

    manage(event) {
        if (event.keyCode === ENTER || event.keyCode === SPACE) {
            this.toggle();
        } else {
            this.keyManager.onKeydown(event);
        }
    }

    toggle() {
        this.collapsed = !this.collapsed;
    }

    loadMore() {
        this.workspaceListService.maybeGoToNextPage();
    }

    trackById(index: number, item: SideNavWorkspaceListitem) {
        return item.id;
    }

    private getOptions(): Observable<SideNavWorkspaceListitem[]> {
        return this.workspaceListService.workspaceList().pipe(
            map((rows) => {
                return rows.map((item) => ({
                    id: item.id,
                    path: `/workspaces/${item.id}`,
                    name: item.name,
                    type: 'workspace',
                    workspaceAdmin: item.role === 'WorkspaceAdmin',
                }));
            }),
        );
    }

    private getLoading(): Observable<boolean> {
        return this.workspaceListService.showLoading$;
    }
}
