import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';

export interface NodeAndPos {
    node: Node;
    pos: number;
}

/**
 * Find all nodes children of 'node' which satisfy 'predicate'.
 * ex: findNodes(this.proseMirrorView.state.doc, 0, (node) => node.attrs.id === id )
 */
export function findNodes(node: Node, predicate: (node: Node) => boolean): NodeAndPos[] {
    const nodes = [];
    // eslint-disable-next-line no-shadow
    node.descendants((descendant, pos) => {
        if (predicate(descendant)) {
            nodes.push({ node: descendant, pos });
        }
    });
    return nodes;
}

export function findNode(node: Node, predicate: (node: Node) => boolean): NodeAndPos {
    let firstNode: NodeAndPos;
    // eslint-disable-next-line no-shadow
    node.descendants((descendant, pos) => {
        if (predicate(descendant)) {
            firstNode = { node: descendant, pos };
            return false;
        }
    });
    return firstNode;
}

export function findNodeWithId(doc: Node, id: string): NodeAndPos {
    return findNode(doc, (node) => node.attrs.id === id);
}

function forEachChildNodesInSection(sectionDoc: Node, cb: (node: Node, pos: number) => boolean | void): void {
    const sectionHeader = sectionDoc.content.child(0);
    const sectionContent = sectionDoc.content.maybeChild(1);
    const sectionContentChildCount = sectionContent ? sectionContent.childCount : 0;
    for (let i = 0, pos = 1 + sectionHeader.nodeSize; i < sectionContentChildCount; ++i) {
        const node = sectionContent.child(i);
        if (cb(node, pos) === false) {
            break;
        }
        pos += node.nodeSize;
    }
}

export function findChildNodesInSection(sectionDoc: Node, predicate: (node: Node) => boolean): NodeAndPos[] {
    const nodes: NodeAndPos[] = [];
    forEachChildNodesInSection(sectionDoc, (node, pos) => {
        if (predicate(node)) {
            nodes.push({ node, pos });
        }
    });
    return nodes;
}

export function findChildNodeInSection(sectionDoc: Node, predicate: (node: Node) => boolean): NodeAndPos | null {
    let foundNode: NodeAndPos | null = null;
    forEachChildNodesInSection(sectionDoc, (node, pos) => {
        if (predicate(node)) {
            foundNode = { node, pos };
            return false;
        }
    });
    return foundNode;
}

export function findChildNodeInSectionById(sectionDoc: Node, nodeId: string): NodeAndPos | null {
    return findChildNodeInSection(sectionDoc, (node) => node.attrs.id === nodeId);
}

export function findNodePosition(doc: Node, aNode: Node): number {
    const nodeAndPos = findNodes(doc, (node) => node === aNode)[0];
    return nodeAndPos ? nodeAndPos.pos : -1;
}

/** Returns the sections nodes and their position in the full document. */
export function getSections(fullDoc: Node): NodeAndPos[] {
    const nodes: NodeAndPos[] = [];
    fullDoc.forEach((node, pos) => nodes.push({ node, pos }));
    return nodes;
}

/**
 * In the Canvas the Components only change the HTML if we manually run changeDetectorRef.detectChanges().
 * Helper method that will only ruun if value changed.
 */
export function updateWithChangeDetection(obj, attribute, newValue, changeDetectorRef) {
    if (obj && obj[attribute] !== newValue) {
        obj[attribute] = newValue;
        changeDetectorRef.detectChanges();
    }
}

export function getSelectionSectionId(state) {
    try {
        return getPositionSectionId(state.doc, state.selection.from);
    } catch (e) {}
}

export function getPositionSectionId(doc: Node, pos) {
    return doc.resolve(pos).node(1).attrs.id;
}

export function getObjectSectionId(doc: Node, objectId: string): string {
    const node = findNodeWithId(doc, objectId);
    if (node) {
        return getPositionSectionId(doc, node.pos);
    } else {
        return undefined;
    }
}

export function getSelectionNodesWithGroup(state: EditorState, groupName: string): NodeAndPos[] {
    const from = state.selection.ranges[0].$from.pos;
    const to = state.selection.ranges[0].$to.pos;
    const nodes: NodeAndPos[] = [];
    state.doc.nodesBetween(from, to, (node, pos) => {
        if (node.type.spec.group === groupName) {
            nodes.push({ node, pos });
        }
        return true;
    });
    return nodes;
}

/**
 * Get the nodes in the selection.
 * Filter by type if provided.
 * Filter by attrs if provided.
 */
export function getSelectionNodes(state: EditorState, type?: string, attrs?): NodeAndPos[] {
    const from = state.selection.ranges[0].$from.pos;
    const to = state.selection.ranges[0].$to.pos;
    const nodes: NodeAndPos[] = [];
    state.doc.nodesBetween(from, to, (node, pos) => maybeAddNodeAndPos(nodes, node, pos, type, attrs));
    return nodes;
}

/**
 * adds NodeAndPos to 'nodes' if required type and attrs match.
 * returns boolean according to nodesBetween specification.
 */
function maybeAddNodeAndPos(nodes: NodeAndPos[], node: Node, pos: number, requiredType?: string, requiredAttrs?) {
    if (!isNewNode(nodes, pos)) {
        return true;
    }

    if (requiredType) {
        if (requiredType === node.type.name) {
            if (requiredAttrs) {
                for (const i in requiredAttrs) {
                    if (node.attrs[i] !== requiredAttrs[i]) {
                        return true;
                    }
                }
                nodes.push({ node, pos });
            } else {
                nodes.push({ node, pos });
            }
        }
    } else {
        nodes.push({ node, pos });
    }
}

function isNewNode(nodes: NodeAndPos[], pos: number) {
    return nodes.find((n) => n.pos === pos) === undefined;
}

/**
 * Check if the selection is children of a node with type 'nodeType'.
 * If 'attrs' is provided it will also check the nodes attributes.
 */
export function isChildrenOf(state: EditorState, nodeType: string, attrs?) {
    const path: any[] = state.selection.$from[`path`];
    const parent = path.filter((maybeNode) => maybeNode && maybeNode.type && maybeNode.type.name === nodeType)[0];
    if (parent) {
        if (attrs) {
            for (const i in attrs) {
                if (parent.attrs[i] !== attrs[i]) {
                    return false;
                }
            }
            return true;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export function selectionIsInSectionHeader(state: EditorState): boolean {
    return getSelectionNodes(state, 'sectionHeader').length > 0;
}

export function isSystemConnectorSection(section: Node): boolean {
    const sectionContent = section.maybeChild(1);
    const firstContent = sectionContent ? sectionContent.firstChild : undefined;
    return (
        section.attrs.objectType === 'systemConnectorConfiguration' ||
        /** Old system connector sections (before process v1 beta was released) had the system connector inside of the section content instead of having an 'objectType' */
        (firstContent && firstContent.type.name === 'systemConnectorConfiguration')
    );
}
