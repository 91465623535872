import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Enterprise, EnterpriseAction, EnterpriseParticipant, EnterpriseRole } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../../../app/auth/auth.service';
import { AppState } from '../../app.store';
import { RuumAlertService } from '../../components/alert/alert.service';
import { StoreExceptionCatcher } from '../../storeExceptionCatcher.service';
import { EntityChangesListener } from '../changesListener.service';
import { EnterprisesAccess, ProfileService } from '../profile/profile.service';
import { getDefaultEnterprise, ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { SelectedEntityService, SendTransaction } from '../selectedEntity';
import { ProfileListItem } from './../profile/profile.model';
import { ENTERPRISE_ACTION_TYPES } from './selected-enterprise.reducer';

@Injectable({ providedIn: 'root' })
export class SelectedEnterpriseService extends SelectedEntityService {
    private participant$: BehaviorSubject<EnterpriseParticipant> = new BehaviorSubject<EnterpriseParticipant>(
        {} as EnterpriseParticipant,
    );
    /** The logged user master data. */
    private userProfile$: Observable<ProfileListItem> = new Observable<ProfileListItem>({} as any);

    constructor(
        protected authService: AuthService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected store: Store<AppState>,
        protected router: Router,
        protected storeExceptionCatcher: StoreExceptionCatcher,
        protected alertService: RuumAlertService,
        protected changesListener: EntityChangesListener,
        protected profileService: ProfileService,
    ) {
        super(
            authService,
            projectServiceConnector,
            store,
            router,
            storeExceptionCatcher,
            alertService,
            changesListener,
            'enterprise',
        );
        this.userProfile$ = this.profileService.getCurrentUserProfile();
        this.listenToLoggedUserParticipant();
    }

    protected handleUnrecoverableError(err, send: SendTransaction): Promise<void> {
        return new Promise((resolve, reject) => {
            if (err && err.error && err.status === 400) {
                this.alertService
                    .warning({
                        actionText: 'Ok',
                        title: err.error.message,
                        noCancel: true,
                    })
                    .then(
                        () => reject(err),
                        () => reject(err),
                    );
            } else {
                return super.handleUnrecoverableError(err, send);
            }
        });
    }

    private getMainEnterpriseAccess = (profile: ProfileListItem): EnterprisesAccess =>
        profile.enterprises.find((enterprise) => enterprise.enterpriseId === profile.enterpriseId);

    private listenToLoggedUserParticipant(): void {
        combineLatest([this.userProfile$.pipe(filter<ProfileListItem>(Boolean))])
            .pipe(
                map(([profile]) => {
                    return {
                        id: profile.id,
                        role: this.getMainEnterpriseAccess(profile).role,
                    } as EnterpriseParticipant;
                }),
            )
            .subscribe((participant) => {
                this.participant$.next(participant);
            });
    }

    async selectEnterprise(enterpriseId: string): Promise<Enterprise> {
        const selectedEnterpriseId = this.getSelectedEnterpriseId();
        if (selectedEnterpriseId === enterpriseId) {
            return this.getSelectedEnterprise();
        }

        if (selectedEnterpriseId) {
            this.unselectEnterprise();
        }
        const enterprise = await this.getData(enterpriseId);
        this.selectEntity(enterprise);
        return enterprise;
    }

    protected dispatchSelectEntity(enterprise: Enterprise) {
        this.store.dispatch(this.getAction(ENTERPRISE_ACTION_TYPES.SELECT_ENTERPRISE, enterprise, {}));
    }

    private async getData(enterpriseId: string): Promise<Enterprise> {
        const enterprise = (await this.projectServiceConnector.getEnterprise(enterpriseId).toPromise()) as Enterprise;
        return enterprise;
    }

    protected reload(enterpriseId: string) {
        this.selectEnterprise(enterpriseId).catch((err) => {
            this.router.navigateByUrl('/home');
        });
    }

    persistAction<T extends EnterpriseAction>(type: T['type'], payload: T['payload']): Promise<any> {
        const role = this.getLoggedUserRole();
        return super.persistAction(type, payload, { role });
    }

    persistActions<T extends EnterpriseAction>(actions: { type: T['type']; payload: T['payload'] }[]): Promise<any> {
        const promises: Promise<any>[] = [];
        for (const action of actions) {
            promises.push(super.persistAction(action.type, action.payload, {}));
        }
        return Promise.all(promises);
    }

    unselectEnterprise() {
        this.dispatchSelectEntity(getDefaultEnterprise());
        this.stopListeningToChanges();
    }

    /**
     * This part of the code are helpers to the selected state in the store.
     */
    selectedEnterprise(): Observable<Enterprise> {
        return this.store.select('selectedEnterprise') as Observable<Enterprise>;
    }

    getSelectedEnterpriseId(): string {
        return this.getSeletedEntityId();
    }

    getSelectedEnterprise(): Enterprise {
        let enterprise: Enterprise;
        this.selectedEnterprise()
            .pipe(take(1))
            .subscribe((selectedEnterprise) => (enterprise = selectedEnterprise));
        return enterprise;
    }

    selectedEnterpriseId(): Observable<string> {
        return this.selectedEnterprise().pipe(
            switchMap((enterprise: Enterprise) => {
                if (enterprise.id) {
                    return of(enterprise.id);
                }
                return of(undefined);
            }),
        );
    }

    selectedEnterpriseRole(): Observable<EnterpriseRole> {
        return combineLatest([this.userProfile$, this.selectedEnterprise()]).pipe(
            map(([userProfile, enterprise]: [ProfileListItem, Enterprise]) => {
                const selectedParticipant = [...enterprise.participants]?.find(
                    (selectedEnterpriseParticipant) => selectedEnterpriseParticipant.id === userProfile.id,
                );
                if (selectedParticipant?.role) {
                    return selectedParticipant.role;
                }
                return userProfile.enterprises.find(
                    (profileEnterprise) => profileEnterprise.enterpriseId === enterprise.id,
                )?.role;
            }),
        );
    }

    isSuperAdminOfSelectedEnterprise(): Observable<boolean> {
        return this.selectedEnterpriseRole().pipe(
            map(
                (role: EnterpriseRole) =>
                    role === EnterpriseRole.EnterpriseAdmin || role === EnterpriseRole.EnterpriseIntegrationAdmin,
            ),
        );
    }

    isIntegrationAdminOfSelectedEnterprise(): Observable<boolean> {
        return this.selectedEnterpriseRole().pipe(
            map((role: EnterpriseRole) => role === EnterpriseRole.EnterpriseIntegrationAdmin),
        );
    }

    loggedUserEnterpriseParticipant(): Observable<EnterpriseParticipant> {
        return this.participant$.asObservable();
    }

    getLoggedUserParticipant(): EnterpriseParticipant {
        let participant: EnterpriseParticipant;
        this.loggedUserEnterpriseParticipant()
            .pipe(take(1))
            .subscribe((p) => (participant = p));
        return participant;
    }

    getLoggedUserRole(): EnterpriseRole {
        const participant = this.getLoggedUserParticipant();
        return <EnterpriseRole>(participant && participant.role);
    }
}
