import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentService {
    private scrollToComment$ = new Subject<string>();

    scrollToComment(commentId: string) {
        this.scrollToComment$.next(commentId);
    }

    shouldScrollToComment() {
        return this.scrollToComment$;
    }
}
