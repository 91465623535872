import { EntityAction, RuumAction } from '@ruum/ruum-reducers';
import { ODataEnterpriseTechnicalUser } from '../../common/odata/odata.model';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { getDefaultPaginatedList } from '../../lobby/lobby.reducer';
import {
    handleEntitiesLoaded,
    handleEntityCreated,
    handleEntityDeleted,
    handleEntityUpdated,
    handleSingleEntityLoaded,
} from '../common/admin-reducer.utils';

export enum TECHNICAL_USER_LIST_ACTION_TYPES {
    TECHNICAL_USERS_LOADED = 'TECHNICAL_USERS_LOADED',
    SINGLE_TECHNICAL_USER_LOADED = 'SINGLE_TECHNICAL_USER_LOADED',
    TECHNICAL_USER_CREATED = 'TECHNICAL_USER_CREATED',
    TECHNICAL_USER_UPDATED = 'TECHNICAL_USER_UPDATED',
    TECHNICAL_USER_DELETED = 'TECHNICAL_USER_DELETED',
}

export type TechnicalUsersLoadedAction = EntityAction<
    TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USERS_LOADED,
    {
        enterpriseId: string;
        page: PaginatedList<ODataEnterpriseTechnicalUser>;
    }
>;

export type TechnicalUserCreatedAction = EntityAction<
    TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_CREATED,
    {
        enterpriseId: string;
        technicalUser: ODataEnterpriseTechnicalUser;
    }
>;

export type TechnicalUserUpdatedAction = EntityAction<
    TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_UPDATED,
    {
        enterpriseId: string;
        technicalUser: ODataEnterpriseTechnicalUser;
    }
>;

export type SingleTechnicalUserLoadedAction = EntityAction<
    TECHNICAL_USER_LIST_ACTION_TYPES.SINGLE_TECHNICAL_USER_LOADED,
    {
        enterpriseId: string;
        technicalUser: ODataEnterpriseTechnicalUser;
    }
>;

export type TechnicalUserDeletedAction = EntityAction<
    TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_DELETED,
    {
        enterpriseId: string;
        technicalUserId: string;
    }
>;

function getEntity(action: RuumAction): ODataEnterpriseTechnicalUser {
    return action.payload.technicalUser;
}

function isSameEntity(a: ODataEnterpriseTechnicalUser, b: ODataEnterpriseTechnicalUser): boolean {
    return a.enterpriseId === b.enterpriseId && a.userId === b.userId;
}

export function TechnicalUserListReducer(
    currentState: PaginatedList<ODataEnterpriseTechnicalUser> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<ODataEnterpriseTechnicalUser> {
    switch (action.type) {
        case TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USERS_LOADED:
            return handleEntitiesLoaded<ODataEnterpriseTechnicalUser, TechnicalUsersLoadedAction>(currentState, action);
        case TECHNICAL_USER_LIST_ACTION_TYPES.SINGLE_TECHNICAL_USER_LOADED:
            return handleSingleEntityLoaded<ODataEnterpriseTechnicalUser, SingleTechnicalUserLoadedAction>(
                currentState,
                action,
                isSameEntity,
                getEntity,
            );
        case TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_CREATED:
            return handleEntityCreated<ODataEnterpriseTechnicalUser, TechnicalUserCreatedAction>(
                currentState,
                action,
                getEntity,
            );
        case TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_UPDATED:
            return handleEntityUpdated<ODataEnterpriseTechnicalUser, TechnicalUserUpdatedAction>(
                currentState,
                action,
                isSameEntity,
                getEntity,
            );
        case TECHNICAL_USER_LIST_ACTION_TYPES.TECHNICAL_USER_DELETED:
            return handleEntityDeleted<ODataEnterpriseTechnicalUser, TechnicalUserDeletedAction>(
                currentState,
                action,
                isSameEntity,
            );

        default:
            return currentState;
    }
}
