import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { componentHelper } from '../ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../ui-components.type';

@Component({
    selector: 'ruum-tag',
    template: `
        <i *ngIf="icon" class="icon" [ngClass]="icon"></i>
        <span
            class="d-flex flex-shrink-0 align-items-center"
            [class.text-truncate]="truncate"
            [class.pl-1]="icon"
            [class.pr-1]="showCancel"
            >{{ name }}</span
        >
        <i *ngIf="showCancel" class="icon icon-cancel"></i>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            componentHelper.getDefaultClassName(),
            componentHelper.getSizeClassName(this.size),
            componentHelper.getThemeClassName(this.theme, this.type),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getDisabledClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @HostBinding('attr.tabindex') get tabIndex() {
        return this.keyTabIndex;
    }

    // Component properties
    @Input() name = '';
    @Input() icon: string;
    @Input() showCancel: boolean;
    @Input() truncate = true;
    @Input() keyTabIndex = -1;

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'default';
    @Input() rounded = false;
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    constructor() {}

    ngOnInit() {}
}
