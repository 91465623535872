import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Navbar } from './../common/navbar/navbar.model';

@Component({
    selector: 'ruum-admin',
    template: `
        <ruum-main-layout bgColorClass="bg-white">
            <ruum-admin-navbar data-content="navbar" [navbar]="navbar$ | async"> </ruum-admin-navbar>
        </ruum-main-layout>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent implements OnInit {
    @HostBinding('class') hostClassList = 'ruum-app-bg d-flex flex-column flex-fill mimh-100';

    navbar$: Observable<Navbar>;

    constructor() {}

    ngOnInit() {
        this.navbar$ = of({
            tabs: [],
            buttons: [],
        });
    }
}
