import { NgModule } from '@angular/core';
import { MenuModule } from '../../ui-components/menu/menu.module';
import { CanvasSectionContextMenuComponent } from './canvas-section-context-menu.component';
import { CommonModule } from '@angular/common';
import { RuumBootstrapModule } from '../../../common/ruum-bootstrap/ruum-bootstrap.module';

@NgModule({
    imports: [CommonModule, MenuModule, RuumBootstrapModule],
    declarations: [CanvasSectionContextMenuComponent],
    exports: [CanvasSectionContextMenuComponent],
    providers: [],
})
export class CanvasSectionContextMenuModule {}
