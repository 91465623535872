import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentSize, ComponentTheme } from '../ui-components.type';

@Component({
    selector: 'ruum-profile-member',
    template: `
        <ruum-profile
            [name]="name"
            [initials]="initials"
            [size]="size | ruumProfileSize"
            [theme]="theme"
            [rounded]="rounded"
            [hover]="hover"
            [active]="active"
            [disabled]="disabled"
            [disableTooltip]="disableTooltip"
            [showName]="showName"
            [tabindex]="tabindex"
            [ariaLabel]="ariaLabel"
        >
        </ruum-profile>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMemberComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return ['d-flex minw-0', this.componentClass].filter((el) => !!el).join(' ');
    }
    @Input() name = '';

    @Input() size: ComponentSize;
    @Input() theme: ComponentTheme = 'secondary';
    @Input() tabindex = -1;

    @Input() showName: boolean;
    @Input() hover: boolean;
    @Input() active: boolean;
    @Input() disabled: boolean;

    @Input() componentClass: string;
    @Input() ariaLabel: string;
    @Input() disableTooltip = false;

    initials = 2;
    rounded = true;

    constructor() {}

    ngOnInit() {}
}
