import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { OrderedListParams, SortDirection } from '../../common/connectors/paginatedList.model';
import { ExternalSystemActionsService } from '../../shared/external-system/external-system-actions.service';
import {
    ExternalSystemInfo,
    ExternalSystemListSortBy,
} from './../../common/connectors/externalSystem/external-system-list.model';
import { ExternalSystemListService } from './../../common/connectors/externalSystem/external-system-list.service';
import { GenericDataSource } from './../../common/connectors/paginatedListLoader';

@Component({
    selector: 'ruum-admin-external-systems',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <!-- view controles -->
                <ruum-view-controls>
                    <div class="w-25">
                        <ruum-search
                            [size]="'sm'"
                            [value]="searchValue$ | async"
                            ariaLabel="Search for external systems"
                            (changed)="search($event)"
                        ></ruum-search>
                    </div>
                    <div class="d-flex flex-fill justify-content-end">
                        <button
                            *ngIf="isCurrentUserSuperAdmin$ | async"
                            class="btn btn-primary float-right"
                            type="button"
                            (click)="createItem()"
                        >
                            <i class="mr-1 icon icon-add"></i>
                            <span>New</span>
                        </button>
                    </div>
                </ruum-view-controls>

                <!-- empty state: no items yet, no search result -->
                <ruum-empty-state
                    *ngIf="(data$ | async)?.length === 0 && (isLoadingFirstPage$ | async) !== true"
                    [searchApplied]="(searchValue$ | async)?.length > 0"
                    [entityName]="'External Systems'"
                >
                    <ruum-illustration-system-connectors
                        data-content="no-results"
                        [width]="275"
                        [componentClass]="'mb-6'"
                    ></ruum-illustration-system-connectors>
                </ruum-empty-state>

                <!-- view list -->
                <div
                    *ngIf="(data$ | async)?.length > 0"
                    class="d-flex flex-column px-2 px-sm-2 px-md-2 px-lg-8 py-2 py-lg-5"
                >
                    <table class="w-100" cdk-table [dataSource]="dataSource" [trackBy]="trackByFunction">
                        <!-- Title Column -->
                        <ng-container cdkColumnDef="title">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'title'"
                                    [label]="'Title'"
                                    [sorting]="isSortBy('title')"
                                    [direction]="getSortDirection('title')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.title }}</span>
                            </td>
                        </ng-container>

                        <!-- Hostname Column -->
                        <ng-container cdkColumnDef="hostname">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <sortable-column
                                    [name]="'hostname'"
                                    [label]="'Hostname'"
                                    [sorting]="isSortBy('hostname')"
                                    [direction]="getSortDirection('hostname')"
                                    (sort)="sort($event)"
                                ></sortable-column>
                            </th>
                            <td
                                cdk-cell
                                *cdkCellDef="let element"
                                class="text-truncate px-2 py-1"
                                [style.max-width.px]="240"
                            >
                                <span class="text-small">{{ element.hostname }}</span>
                            </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container cdkColumnDef="status">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3">
                                <h5 class="text-secondary">Reachable</h5>
                            </th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1" [style.max-width.px]="240">
                                <div class="btn btn-icon btn-link-success ml-4">
                                    <i [class]="getSystemReachableStatusClass(element) | async"></i>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container cdkColumnDef="actions">
                            <th cdk-header-cell *cdkHeaderCellDef class="px-2 pb-3"></th>
                            <td cdk-cell *cdkCellDef="let element" class="px-2 py-1 text-right">
                                <button class="btn btn-icon btn-link-secondary ml-1" (click)="editItem(element)">
                                    <i class="icon icon-settings"></i>
                                </button>
                                <button class="btn btn-icon btn-link-danger ml-1" (click)="deleteItem(element)">
                                    <i class="icon icon-delete"></i>
                                </button>
                            </td>
                        </ng-container>

                        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                        <tr
                            cdk-row
                            *cdkRowDef="let row; columns: displayedColumns"
                            class="border-bottom border-light hov hov-bg-primary-light"
                        ></tr>
                    </table>

                    <!-- spinner: initial fetching -->
                    <ruum-load-spinner
                        classList="d-flex flex-column flex-fill justify-content-center align-items-center"
                        *ngIf="isLoadingFirstPage$ | async"
                    ></ruum-load-spinner>

                    <!-- spinner: next page fetching -->
                    <ruum-load-more
                        *ngIf="showLoading$ | async"
                        [scrollElement]="scrollElement"
                        (loadMore)="loadMore()"
                    ></ruum-load-more>
                </div>
            </div>
        </ruum-max-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminExternalSystemsComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column w-100 mw-100 h-100 mh-100 overflow-y';

    isCurrentUserSuperAdmin$: Observable<boolean>;

    displayedColumns = ['title', 'hostname', 'status', 'actions'];

    isLoadingFirstPage$: Observable<boolean>;
    showLoading$: Observable<boolean>;

    data$: Observable<ExternalSystemInfo[]>;
    dataSource: GenericDataSource<ExternalSystemInfo>;
    searchValue$: Observable<string>;
    sortValue: OrderedListParams<ExternalSystemListSortBy> = { by: 'title', direction: 'asc' };

    reachableStatusIconMap: { [systemId: string]: BehaviorSubject<string> } = {};

    constructor(
        private externalSystemListService: ExternalSystemListService,
        private elementRef: ElementRef,
        private externalSystemActionsService: ExternalSystemActionsService,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {}

    ngOnInit() {
        this.isCurrentUserSuperAdmin$ = this.selectedEnterpriseService.isSuperAdminOfSelectedEnterprise();
        this.externalSystemListService.resetFilters();
        this.externalSystemListService.loadList();

        this.data$ = this.externalSystemListService.getStoreRows$();

        this.dataSource = new GenericDataSource<ExternalSystemInfo>(this.data$);
        this.isLoadingFirstPage$ = this.externalSystemListService.isLoadingFirstPage$;
        this.showLoading$ = this.externalSystemListService.showLoading$;
        this.searchValue$ = this.externalSystemListService.search$.asObservable();
    }

    ngOnDestroy() {
        this.externalSystemListService.stopLoadingList();
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }

    trackByFunction(index: number, item: ExternalSystemInfo): string {
        if (!item) {
            return;
        }
        return item.id;
    }

    createItem(): void {
        this.externalSystemActionsService.createExternalSystem();
    }

    editItem(externalSystem: ExternalSystemInfo): void {
        this.externalSystemActionsService.updateExternalSystem(externalSystem);
    }

    deleteItem(externalSystem: ExternalSystemInfo) {
        this.externalSystemActionsService.deleteExternalSystem(externalSystem);
    }

    search(value: string): void {
        this.externalSystemListService.search$.next(value);
    }

    isSortBy(sortBy: ExternalSystemListSortBy): boolean {
        if (this.sortValue && this.sortValue.by === sortBy) {
            return true;
        }
        return false;
    }

    getSortDirection(sortBy: ExternalSystemListSortBy): SortDirection {
        if (this.sortValue && this.sortValue.by === sortBy) {
            return this.sortValue.direction;
        }
        return 'asc';
    }

    sort(sortChange: OrderedListParams<ExternalSystemListSortBy>) {
        this.sortValue = sortChange;
        this.externalSystemListService.orderBy(sortChange.by as ExternalSystemListSortBy, sortChange.direction);
    }

    loadMore(): void {
        this.externalSystemListService.maybeGoToNextPage();
    }

    getSystemReachableStatusClass(system: ExternalSystemInfo): Observable<string> {
        let classObservable = this.reachableStatusIconMap[system.id];
        if (!classObservable) {
            classObservable = this.reachableStatusIconMap[system.id] = new BehaviorSubject('icon icon-time text-info');

            // Trigger async update
            this.externalSystemListService
                .isDomainReachable(system.id)
                .toPromise()
                .then(
                    (status) => {
                        const iconClassList = status
                            ? 'icon icon-task-completed text-success'
                            : 'icon icon-task-blocked-filled text-warning';
                        classObservable.next(iconClassList);
                    },
                    () => {
                        classObservable.next('icon icon-task-blocked-filled text-warning');
                    },
                );
        }

        return this.reachableStatusIconMap[system.id];
    }
}
