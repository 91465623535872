import { ProcessEnum } from '@ruum/ruum-reducers';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, switchAll } from 'rxjs/operators';
import { isSystemConnectorSection } from '../../../../ruum/canvas/canvasHelpers';
import { CanvasStateManagerService } from '../../../../ruum/canvas/canvasStateManager.service';
import {
    CanvasSectionsService,
    SECTION_HEADER_PLACEHOLDER,
} from '../../../../ruum/canvas/sections/canvasSections.service';
import { SystemConnectorsStore } from '../../system-connectors/system-connectors.store';
import { SelectedProjectService } from '../selectedProject.service';
import { TransitionObject } from './process.model';

/**
 * It returns all the objects that can be Steps, i.e. sections and system connector configurations and approvals.
 */
export function getTransitionObjects(
    fromObjectId: string,
    selectedProjectService: SelectedProjectService,
    systemConnectorsService: SystemConnectorsStore,
    canvasStateManagerService: CanvasStateManagerService,
    canvasSectionsService: CanvasSectionsService,
): Observable<TransitionObject[]> {
    return combineLatest([
        connectorConfigurationObjects(selectedProjectService, systemConnectorsService),
        sectionObjects(canvasStateManagerService),
        canvasSectionsService.getCanvasSectionsWithObjectIdsList$(),
    ]).pipe(
        map(([systemConnectorConfigs, sections, canvasObjectsIds]) => {
            const filteredIds = canvasObjectsIds.filter((objectId) => objectId !== fromObjectId);
            const allSections = [...systemConnectorConfigs, ...sections];
            const objects = filteredIds.map((id) => {
                return allSections.find((sectionItem) => sectionItem.id === id);
            });

            return [
                ...objects,
                {
                    id: ProcessEnum.FINAL_STEP_ID,
                    type: 'final',
                    title: 'End / Close Process',
                },
            ];
        }),
    );
}

function connectorConfigurationObjects(
    selectedProjectService: SelectedProjectService,
    systemConnectorsService: SystemConnectorsStore,
): Observable<TransitionObject[]> {
    return selectedProjectService.selectedProject().pipe(
        map((project) => {
            const configs = Object.keys(project.systemConnectorConfigurations)
                .map((configId) => project.systemConnectorConfigurations[configId])
                .filter(Boolean);
            if (configs.length === 0) {
                return of(<TransitionObject[]>[]);
            } else {
                return combineLatest(
                    configs.map((config) =>
                        systemConnectorsService.data(config.systemConnectorId).pipe(
                            map(
                                (connector) =>
                                    <TransitionObject>{
                                        id: config.id,
                                        title: connector.title,
                                        type: 'system_connector',
                                    },
                            ),
                        ),
                    ),
                );
            }
        }),
        switchAll(),
    );
}

function sectionObjects(canvasStateManagerService: CanvasStateManagerService): Observable<TransitionObject[]> {
    return canvasStateManagerService.validFullStateChange().pipe(
        debounceTime(250),
        map((state) => {
            const objects: TransitionObject[] = [];
            state.doc.forEach((section) => {
                /**
                 * Sections which hold a system connector configuration should not be considered a transition object of type 'canvas'.
                 */
                if (isSystemConnectorSection(section)) {
                    return;
                }

                const textContent = section.firstChild ? section.firstChild.textContent : undefined;

                if (section.attrs.objectType === 'approval') {
                    objects.push({
                        id: section.attrs.objectId,
                        title: textContent || SECTION_HEADER_PLACEHOLDER,
                        type: 'approval',
                    });
                } else {
                    objects.push({
                        id: section.attrs.id,
                        title: textContent || SECTION_HEADER_PLACEHOLDER,
                        type: 'canvas',
                    });
                }
            });
            return objects;
        }),
    );
}
