import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PaywallPrivilege } from '../../../../paywall/paywall-privilege';
import { isUnselectDropdownItem, RuumDropdownItem, RuumDropdownItemSize } from '../dropdown.model';

@Component({
    selector: 'ruum-dropdown-item',
    template: `
        <div
            [title]="name"
            class="list-group-item list-group-item-action d-flex flex-column rounded px-2 mb-1"
            [class.disabled]="disabled"
            [class.cursor-not-allowed]="disabled"
            [class.cursor-pointer]="!disabled"
            [class.py-1]="size === 'lg'"
            [class.bg-light]="selected && (theme === 'secondary' || theme === 'light')"
            [class.bg-danger-light]="selected && (theme === 'danger' || theme === 'danger-light')"
            [class.bg-warning-light]="selected && (theme === 'warning' || theme === 'warning-light')"
            [class.bg-info-light]="selected && (theme === 'info' || theme === 'info-light')"
            [class.bg-success-light]="selected && (theme === 'success' || theme === 'success-light')"
            [class.bg-accent-light]="selected && (theme === 'accent' || theme === 'accent-light')"
            [ngbTooltip]="tipContent"
            [disableTooltip]="!tooltip"
            [placement]="tooltip && tooltip.placement ? tooltip.placement : 'auto'"
            [container]="'body'"
            data-test="ruum-dropdown-option"
            [requiredPrivilege]="paywallPrivilege"
            [rejectStrategy]="rejectStrategy"
            [rejectStrategyData]="{ featureName: name }"
            (requiredPrivilege.click)="!disabled && selectChange.emit(id)"
            (requiredPrivilege.keydown)="!disabled && selectChange.emit(id)"
        >
            <button
                *ngIf="theme"
                class="btn btn-block px-0"
                [class.btn-without-hover]="disabled"
                [class.disabled]="disabled"
                [class.btn-xs]="size === 'xs'"
                [class.btn-sm]="size === 'sm'"
                [class.btn-lg]="size === 'lg'"
                [class.btn-link-secondary]="theme === 'secondary'"
                [class.btn-link-light]="theme === 'light' || isUnselectItem()"
                [class.btn-link-danger]="theme === 'danger'"
                [class.btn-link-warning]="theme === 'warning'"
                [class.btn-link-info]="theme === 'info'"
                [class.btn-link-success]="theme === 'success'"
                [class.btn-link-accent]="theme === 'accent'"
                [class.btn-link-danger-light]="theme === 'danger-light'"
                [class.btn-link-warning-light]="theme === 'warning-light'"
                [class.btn-link-info-light]="theme === 'info-light'"
                [class.btn-link-success-light]="theme === 'success-light'"
                [class.btn-link-accent-light]="theme === 'accent-light'"
                type="button"
            >
                <i *ngIf="icon && iconSide === 'left'" class="mr-2 icon {{ icon }}"> </i>
                <span class="flex-fill text-left text-truncate">{{ name }}</span>
                <i *ngIf="icon && (iconSide === 'right' || !iconSide)" class="ml-2 icon {{ icon }}"> </i>
                <i *ngIf="!icon && selected" class="icon icon-done"> </i>
            </button>
            <div
                *ngIf="description"
                class="ruum-dropdown-item-description d-flex text-small text-secondary pb-2"
                [class.description-disabled]="disabled"
            >
                {{ description }}
            </div>
        </div>

        <ng-template #tipContent>
            <div class="text-small font-weight-bold">{{ tooltip.title }}</div>
            <i *ngIf="tooltip.description" class="small" style="color: #FFFFFF; opacity: 0.56;">{{
                tooltip.description
            }}</i>
        </ng-template>
    `,
    styles: [
        `
            .ruum-dropdown-item-description {
                margin-top: -4px;
            }
            .description-disabled {
                opacity: 0.65;
                color: rgba(37, 44, 66, 0.24) !important;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumDropdownItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex align-items-center';

    @Input()
    id: RuumDropdownItem['id'];

    @Input()
    name: RuumDropdownItem['name'];

    @Input()
    description: RuumDropdownItem['description'];

    @Input()
    icon: RuumDropdownItem['icon'];

    @Input()
    iconSide: RuumDropdownItem['iconSide'];

    @Input()
    theme: RuumDropdownItem['theme'];

    @Input()
    disabled: RuumDropdownItem['disabled'];

    @Input()
    hidden: RuumDropdownItem['hidden'];

    @Input()
    tooltip: RuumDropdownItem['tooltip'];

    @Input()
    size: RuumDropdownItemSize;

    @Input()
    paywallPrivilege?: PaywallPrivilege;

    @Input()
    rejectStrategy?: string;

    @Input() selected = false;

    @Input() isMultiSelect = false;

    @Output()
    selectChange = new EventEmitter();

    ngOnInit() {
        this.theme = this.theme || 'secondary';
    }

    private isUnselectItem(): boolean {
        return isUnselectDropdownItem(this as RuumDropdownItem);
    }
}
