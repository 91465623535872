import { ElementRef, RendererFactory2 } from '@angular/core';
import { PaywallRejectStrategy, PaywallRejectStrategyContext } from '../paywall-reject-strategy';

export class HideRejectStrategy implements PaywallRejectStrategy {
    private static readonly HIDE_CSS_CLASS = 'd-none';
    private static readonly HIDE_ATTRIBUTE_KEY = 'hidden';
    private static readonly HIDE_ATTRIBUTE_VALUE = 'hidden';

    readonly id = 'hide';

    constructor(private rendererFactory: RendererFactory2) {}

    onRejectInitial({ elementRef }: PaywallRejectStrategyContext) {
        // TODO: handle case where host element already has the disabled attribute or class
        this.setHideAttribute(elementRef, true);
        this.setHideClass(elementRef, true);
    }

    onRejectHostDestroy({ elementRef }: PaywallRejectStrategyContext) {
        // TODO: handle case where host element already has the disabled attribute or class
        this.setHideAttribute(elementRef, false);
        this.setHideClass(elementRef, false);
    }

    onRejectTrigger(_: PaywallRejectStrategyContext, event?: Event) {
        if (event) {
            // this doesn't work for host elements with a (click)="fn()", but it's not required in this case.
            event.stopPropagation();
        }
    }

    private setHideClass(elementRef: ElementRef<any>, value: boolean): void {
        const renderer = this.rendererFactory.createRenderer(elementRef.nativeElement, null);
        if (value) {
            renderer.addClass(elementRef.nativeElement, HideRejectStrategy.HIDE_CSS_CLASS);
        } else {
            renderer.removeClass(elementRef.nativeElement, HideRejectStrategy.HIDE_CSS_CLASS);
        }
    }

    private setHideAttribute(elementRef: ElementRef<any>, value: boolean): void {
        const renderer = this.rendererFactory.createRenderer(elementRef.nativeElement, null);
        if (value) {
            renderer.setAttribute(
                elementRef.nativeElement,
                HideRejectStrategy.HIDE_ATTRIBUTE_KEY,
                HideRejectStrategy.HIDE_ATTRIBUTE_VALUE,
            );
        } else {
            renderer.removeAttribute(elementRef.nativeElement, HideRejectStrategy.HIDE_ATTRIBUTE_KEY);
        }
    }
}
