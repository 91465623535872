import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { RuumDropdownItem, RuumDropdownItemSize, RuumDropdownList, RuumDropdownSectionList } from '../dropdown.model';

@Component({
    selector: 'ruum-dropdown-section-list',
    template: `
        <ng-template ngFor let-last="last" let-list [ngForOf]="sectionList">
            <ruum-dropdown-list
                [selectedItems]="selectedItems"
                [list]="list"
                [size]="size"
                [isMultiSelect]="isMultiSelect"
                (selectItem)="onSelect($event)"
                (selectItems)="onMultiSelect($event)"
            >
            </ruum-dropdown-list>
            <div *ngIf="!last" class="ruum-divider"></div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumDropdownSectionListComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-column';

    @Input()
    selectedItems: RuumDropdownList = [];

    @Input()
    sectionList: RuumDropdownSectionList = [];

    @Input()
    size: RuumDropdownItemSize = 'sm';

    @Input() isMultiSelect = false;

    @Output()
    selectItem = new EventEmitter<RuumDropdownItem>();

    @Output()
    selectItems = new EventEmitter<RuumDropdownItem[]>();

    onSelect(item: RuumDropdownItem): void {
        this.selectItem.emit(item);
    }

    onMultiSelect(items: RuumDropdownItem[]): void {
        this.selectItems.emit(items);
    }
}
