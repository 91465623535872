import {
    AddCustomFieldDefinitionSelectionListOptionAction,
    CustomFieldDefinition,
    RemoveCustomFieldDefinitionSelectionListOptionAction,
    ReorderCustomFieldDefinitionSelectionListOptionAction,
    SelectionListOption,
    UpdateCustomFieldDefinitionAction,
    UpdateCustomFieldDefinitionSelectionListOptionAction,
    WorkspaceAction,
} from '@ruum/ruum-reducers';

export function calculateDiff(
    definitionId: string,
    fromDefinition: Partial<CustomFieldDefinition>,
    toDefinition: Partial<CustomFieldDefinition>,
): WorkspaceAction[] {
    const actionList = [
        calculateBaseDiff(definitionId, fromDefinition, toDefinition),
        ...calculateOptionsDiff(definitionId, fromDefinition, toDefinition),
    ];
    return actionList.filter(Boolean);
}

function calculateBaseDiff(
    definitionId: string,
    fromDefinition: Partial<CustomFieldDefinition>,
    toDefinition: Partial<CustomFieldDefinition>,
): WorkspaceAction {
    const { description: fromDescription, placeholder: fromPlaceholder, title: fromTitle } = fromDefinition;
    const { description, placeholder, title } = toDefinition;
    const hasChanges = title !== fromTitle || description !== fromDescription || placeholder !== fromPlaceholder;
    if (hasChanges) {
        return createAction<UpdateCustomFieldDefinitionAction>('UPDATE_CUSTOM_FIELD_DEFINITION', {
            definitionId,
            editFields: {
                title,
                description,
                placeholder,
            },
        });
    }
    return null;
}

function calculateOptionsDiff(
    definitionId: string,
    fromDefinition: Partial<CustomFieldDefinition>,
    toDefinition: Partial<CustomFieldDefinition>,
): WorkspaceAction[] {
    if (fromDefinition.type !== 'selection_list') {
        return [];
    }
    if (toDefinition.type !== 'selection_list') {
        return [];
    }
    if (!toDefinition.options) {
        throw new Error('Dropdown List without options is not allowed');
    }

    const fromOptionsMap = createMapOfOptions(fromDefinition.options);
    const toOptionsMap = createMapOfOptions(toDefinition.options);

    const actionList: WorkspaceAction[] = calculateOptionsActions(fromOptionsMap, toOptionsMap, definitionId);
    actionList.push(calculateOptionsReorderAction(fromDefinition.options, toDefinition.options, definitionId));

    return actionList;
}

function calculateOptionsReorderAction(
    fromOptions: SelectionListOption[],
    toOptions: SelectionListOption[],
    definitionId: string,
): WorkspaceAction {
    if (fromOptions.length !== toOptions.length) {
        const optionIds = toOptions.map((option) => option.id);
        return createAction<ReorderCustomFieldDefinitionSelectionListOptionAction>(
            'REORDER_CUST_FIELD_DEF_SELECTION_LIST_OPTION',
            {
                definitionId,
                optionIds,
            },
        );
    }

    if (fromOptions.length === toOptions.length) {
        let needsReorder = false;
        for (let i = 0; i < fromOptions.length; i++) {
            if (fromOptions[i].id !== toOptions[i].id) {
                needsReorder = true;
                break;
            }
        }
        if (needsReorder) {
            const optionIds = toOptions.map((option) => option.id);
            return createAction<ReorderCustomFieldDefinitionSelectionListOptionAction>(
                'REORDER_CUST_FIELD_DEF_SELECTION_LIST_OPTION',
                {
                    definitionId,
                    optionIds,
                },
            );
        }
    }
    return null;
}

function calculateOptionsActions(
    fromOptions: Map<string, string>,
    toOptions: Map<string, string>,
    definitionId: string,
): WorkspaceAction[] {
    const actionList: WorkspaceAction[] = [];
    // calculation of remove and update actions
    for (const optionId of fromOptions.keys()) {
        const toOption = toOptions.has(optionId);
        const toOptionValue = toOptions.get(optionId);
        // on removed
        if (!toOption) {
            const action = createAction<RemoveCustomFieldDefinitionSelectionListOptionAction>(
                'REMOVE_CUST_FIELD_DEF_SELECTION_LIST_OPTION',
                {
                    definitionId,
                    optionId,
                },
            );
            actionList.push(action);
        }
        // on changed value
        if (toOption && toOptionValue && toOptionValue !== fromOptions.get(optionId)) {
            const action = createAction<UpdateCustomFieldDefinitionSelectionListOptionAction>(
                'UPDATE_CUST_FIELD_DEF_SELECTION_LIST_OPTION',
                {
                    definitionId,
                    option: { id: optionId, value: toOptionValue },
                },
            );
            actionList.push(action);
        }
    }
    // calulation of add actions
    for (const optionId of toOptions.keys()) {
        if (!fromOptions.has(optionId) && toOptions.get(optionId)) {
            const action = createAction<AddCustomFieldDefinitionSelectionListOptionAction>(
                'ADD_CUST_FIELD_DEF_SELECTION_LIST_OPTION',
                {
                    definitionId,
                    option: { id: optionId, value: toOptions.get(optionId) },
                },
            );
            actionList.push(action);
        }
    }
    return actionList;
}

function createMapOfOptions(options: SelectionListOption[]): Map<string, string> {
    const map = new Map<string, string>();
    for (const option of options) {
        map.set(option.id, option.value);
    }
    return map;
}

function createAction<T extends WorkspaceAction>(type: T['type'], payload: T['payload']): WorkspaceAction {
    return { type, payload } as WorkspaceAction;
}
