/** modules */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LobbyListItemLabelsComponent } from '../lobby/shared/lobby-list/lobby-list-item-labels/lobby-list-item-labels.component';
import { RuumPaywallModule } from '../paywall/paywall.module';
// TODO: path issue
import { ParticipantInitialsComponent } from '../ruum/participant/participant-initials.component';
import { CommentsModule } from '../shared/comments/comments.module';
import { LayoutsModule } from '../shared/layouts/layouts.module';
import { ProjectGroupMenuModule } from '../shared/project-group/project-group-menu/project-group-menu.module';
import { ProjectMenuModule } from '../shared/project/project-menu/project-menu.module';
import { GenericFilterModule } from '../shared/saved-view/generic-filter/generic-filter.module';
import { SavedViewSettingsDialogModule } from '../shared/saved-view/saved-view-settings-dialog/saved-view-settings-dialog.module';
import { SavedViewTaskListModule } from '../shared/saved-view/task-list/saved-view-task-list.module';
import { TaskListModule } from '../shared/task-list/task-list.module';
import { TaskModule } from '../shared/task/task.module';
import { ButtonToggleModule } from '../shared/ui-components/btn-toggle/btn-toggle.module';
import { DropdownModule } from '../shared/ui-components/dropdown/dropdown.module';
import { UiComponentsModule } from '../shared/ui-components/ui-components.module';
import { RuumA11yModule } from './a11y/a11y.module';
import { ProjectChatsOverviewListItemComponent } from './chats/components/chats-overview-list-item.component';
import { ProjectChatsOverviewListComponent } from './chats/components/chats-overview-list.component';
import { CommentsOverviewComponent } from './chats/components/comments-overview.component';
import { AddCommentTextInputComponent } from './comments/components/add-comment-text-input.component';
import { AddCommentComponent } from './comments/components/add-comment.component';
import { ParticipantAtMentionListComponent } from './comments/components/at-mention-list.component';
import { CommentListWrapperComponent } from './comments/components/comment-list-wrapper.component';
import { CommentListComponent } from './comments/components/comment-list.component';
import { RuumCommentComponent } from './comments/components/comment.component';
import { CommonCommentsComponent } from './comments/components/comments.component';
import { RuumAlertComponent } from './components/alert/alert.component';
import { BlockInviteToggleComponent } from './components/block-invite-toggle/block-invite-toggle.component';
import { BusyStateComponent } from './components/busy-state.component';
import { RuumDraggableItemComponent } from './components/draggable-item/draggable-item.component';
import { FormattedDateComponent } from './components/formatted-date.component';
import { GDPRCheckBoxesComponent } from './components/gdpr-checkboxes/gdpr-checkboxes.component';
import { OfflineAlertComponent } from './components/offline-alert.component';
import { RuumParticipantOptionsDropdownComponent } from './components/participant-options-dropdown/participant-options-dropdown.component';
import { PasswordComponent } from './components/password.component';
import { ReadOnlyComponent } from './components/readonly-hint.component';
import { RuumActionButtonsComponent } from './components/ruum-action-buttons.component';
import { RuumCardButtonsComponent } from './components/ruum-card-buttons.component';
import { RuumContributorsComponent } from './components/ruum-contributors.component';
import { RuumDropdownPopupMobileComponent } from './components/ruum-dropdown-popup-mobile.component';
import { RuumDropdownPopupComponent } from './components/ruum-dropdown-popup.component';
import { RuumStatusDesktopComponent } from './components/ruum-status/ruum-status-desktop.component';
import { RuumStatusMobileComponent } from './components/ruum-status/ruum-status-mobile.component';
import { RuumTagComponent } from './components/ruum-tag/ruum-tag.component';
import { SelectWorkspaceComponent } from './components/select-workspace/select-workspace.component';
import { RuumSidepanelBackButtonComponent } from './components/sidepanel/sidepanel-back-button.component';
import { RuumSidepanelContainerComponent } from './components/sidepanel/sidepanel-container.component';
import { RuumSidepanelContentComponent } from './components/sidepanel/sidepanel-content.component';
import { RuumSidepanelHintComponent } from './components/sidepanel/sidepanel-hint.component';
import { RuumSidepanelTitleComponent } from './components/sidepanel/sidepanel-title.component';
import { SingleSelectComponent } from './components/single-select.component';
import { TabNavigationComponent } from './components/tab-navigation.component';
import { TextDividerComponent } from './components/text-divider.component';
import { UnseenChangesCountersPipe } from './components/unseen-changes-counters.pipe';
import { UnseenItemsCounterComponent } from './components/unseen-item-counter.component';
import { VersionVerifierComponent } from './components/version-verifier/version-verifier.component';
import { RuumIconButtonDirective } from './directive/ruum-icon-button.directive';
import { ScrollDetectionDirective } from './directive/scroll-detection.directive';
/** components */
import { RuumScreenLayoutComponent } from './layout/screen-layout.component';
import { DateFormatPipeModule } from './pipe/date-format.pipe';
import { FormatDatePipeModule } from './pipe/formate-date.pipe';
import { IsOverduePipe } from './pipe/isOverdue.pipe';
import { RuumOwnerNamePipeModule } from './pipe/owner-name.pipe';
import { TimeAgoPipeModule } from './pipe/time-ago.pipe';
import { RuumPopupContainerComponent } from './popup/ruum-popup-container.component';
import { RuumBootstrapModule } from './ruum-bootstrap/ruum-bootstrap.module';
import { SelectSectionComponent } from './section/select-section.component';
import { FormLabelComponent } from './task-details/form-label.component';
import { MoveToSectionDialogComponent } from './task-details/move-to-section/move-to-section.component';
import { RuumTaskDetailsOptionsComponent } from './task-details/options/task-details-options.component';
import { RuumTaskDetailsPriorityComponent } from './task-details/priority/task-details-priority.component';
import { TaskDetailsSubtaskItemComponent } from './task-details/subtasks/task-details-subtask-item.component';
import { RuumTaskDetailsSubtaskListComponent } from './task-details/subtasks/task-details-subtask-list.component';
import { RuumTaskDetailsComponent } from './task-details/task-details.component';
import { AddTaskDialogComponent } from './task-list/add-task/add-task-dialog.component';
import { TaskListFiltersComponent } from './task-list/filters/task-list-filters.component';
import { RuumTaskListSortButtonComponent } from './task-list/filters/task-list-sort-button.component';
import { TaskListContainerComponent } from './task-list/task-list-container.component';
import { TaskListHeaderComponent } from './task-list/task-list-header.component';
import { RuumTaskListItemOptionsModule } from './task-list/task-list-item-options/task-list-item-options.component';
import { TaskLIstNewItemComponent } from './task-list/task-list-new-item/task-list-new-item.component';
import { TaskPeriodComponent } from './task/period/task-period.component';
import { TeamInviteButtonComponent } from './team/team-invite-button.component';
import { RuumTeamInviteDialogComponent } from './team/team-invite-dialog.component';
import { TeamMemberAddToComponent } from './team/team-member-add-to.component';
import { TeamMemberRoleDropdownComponent } from './team/team-member-role-dropdown.component';
import { TeamMembersHeaderComponent } from './team/team-members-header.component';
import { TeamMembersListItemComponent } from './team/team-members-list-item.component';
import { TeamMembersComponent } from './team/team-members.component';

const MODULES = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    RuumBootstrapModule,
    DragDropModule,
    RuumPaywallModule,
    CdkTableModule,
    UiComponentsModule,
    LayoutsModule, // TODO: remove it later
    GenericFilterModule,
    SavedViewSettingsDialogModule,
    SavedViewTaskListModule,
    TaskListModule,
    DropdownModule,
    TaskModule,
    CommentsModule,
    RuumTaskListItemOptionsModule,
    ProjectMenuModule,
    ProjectGroupMenuModule,
    RuumOwnerNamePipeModule,
    FormatDatePipeModule,
    ButtonToggleModule,
    RuumA11yModule,
    DateFormatPipeModule,
    TimeAgoPipeModule,
];

const ENTRY_COMPONENTS = [
    RuumDropdownPopupComponent,
    RuumDropdownPopupMobileComponent,
    RuumAlertComponent,
    SelectWorkspaceComponent,
    RuumTeamInviteDialogComponent,
    AddTaskDialogComponent,
    MoveToSectionDialogComponent,
    SelectSectionComponent,
];

const COMPONENTS = [
    RuumScreenLayoutComponent,
    FormattedDateComponent,
    BusyStateComponent,
    GDPRCheckBoxesComponent,
    IsOverduePipe,
    ReadOnlyComponent,
    OfflineAlertComponent,
    RuumActionButtonsComponent,
    RuumCardButtonsComponent,
    RuumDraggableItemComponent,
    RuumIconButtonDirective,
    RuumPopupContainerComponent,
    RuumContributorsComponent,
    RuumTagComponent,
    ScrollDetectionDirective,
    TabNavigationComponent,
    TextDividerComponent,
    UnseenItemsCounterComponent,
    PasswordComponent,
    RuumStatusMobileComponent,
    RuumStatusDesktopComponent,
    RuumSidepanelContainerComponent,
    RuumSidepanelTitleComponent,
    RuumSidepanelHintComponent,
    RuumSidepanelContentComponent,
    RuumSidepanelBackButtonComponent,
    RuumParticipantOptionsDropdownComponent,
    TeamInviteButtonComponent,
    TeamMembersListItemComponent,
    TaskListContainerComponent,
    TaskLIstNewItemComponent,
    TaskListHeaderComponent,
    RuumTaskDetailsOptionsComponent,
    RuumTaskDetailsPriorityComponent,
    RuumTaskDetailsSubtaskListComponent,
    TaskPeriodComponent,
    TaskListFiltersComponent,
    RuumTaskListSortButtonComponent,
    LobbyListItemLabelsComponent,
    CommentListComponent,
    RuumCommentComponent,
    AddCommentTextInputComponent,
    ParticipantAtMentionListComponent,
    AddCommentComponent,
    CommentListWrapperComponent,
    FormLabelComponent,
    TaskDetailsSubtaskItemComponent,
    RuumTaskDetailsComponent,
    BlockInviteToggleComponent,
    MoveToSectionDialogComponent,
    SelectSectionComponent,
    VersionVerifierComponent,
    SingleSelectComponent,
    CommentsOverviewComponent,
    ProjectChatsOverviewListComponent,
    ProjectChatsOverviewListItemComponent,
    CommonCommentsComponent,
    // path issue
    ParticipantInitialsComponent,
    TeamMembersComponent,
    TeamMembersHeaderComponent,
    TeamMemberRoleDropdownComponent,
    TeamMemberAddToComponent,
    UnseenChangesCountersPipe,

    ...ENTRY_COMPONENTS,
];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS, ...MODULES],
    providers: [IsOverduePipe],
})
export class RuumCommonModule {}
