import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Template, TemplateSharingViewSettings } from '@ruum/ruum-reducers';
import { CommonService } from '../../common/common.service';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { SelectWorkspaceComponent } from '../../common/components/select-workspace/select-workspace.component';
import { FeatureFlagService } from '../../common/connectors/featureFlags/featureFlags.service';
import { SelectedProjectService } from '../../common/connectors/project/selectedProject.service';
import { SelectedProjectGroupService } from '../../common/connectors/projectGroup/selectedProjectGroup.service';
import { ProjectServiceBackendConnector } from '../../common/connectors/projectServiceConnector.service';
import { SelectedTemplateService } from '../../common/connectors/templates/selectedTemplate.service';
import { TemplateListItemService } from '../../common/connectors/templates/template-list-item.service';
import { SelectedWorkspaceService } from '../../common/connectors/workspace/selected-workspace.service';
import { CreateProjectTemplateComponent } from './template-dialogs/create-project-template.component';
import { SelectProjectTemplateConfigComponent } from './template-dialogs/select-project-template-config.component';
import { SelectProjectTemplateComponent } from './template-dialogs/select-project-template.component';
import { SelectSectionTemplateComponent } from './template-dialogs/select-section-template.component';
import { SuggestPublishTemplateComponent } from './template-dialogs/suggest-publish-template.component';
import { TemplateEditCoverComponent } from './template-dialogs/template-edit-cover.component';

@Injectable({ providedIn: 'root' })
export class TemplateActionsService {
    constructor(
        private router: Router,
        private modalService: NgbModal,
        private ruumAlertService: RuumAlertService,
        private commonService: CommonService,
        private templateListItemService: TemplateListItemService,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
        private selectedProjectService: SelectedProjectService,
        private selectedProjectGroupService: SelectedProjectGroupService,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private selectedTemplateService: SelectedTemplateService,
        private featureFlagService: FeatureFlagService,
    ) {}

    openProjectTemplate(templateId: string): Promise<boolean> {
        return this.router.navigate(['templates', 'projects', templateId]);
    }

    openProcessTemplate(templateId: string): Promise<boolean> {
        // TODO: fix it in release/v7.12.0
        // return this.router.navigate(['templates', 'processes', templateId]);
        return this.router.navigate(['templates', 'projects', templateId]);
    }

    openNewProjectTemplate(templateId: string): Promise<boolean> {
        return this.router.navigate(['templates', 'projects', templateId], {
            queryParams: { silentTemplateCreation: true },
            queryParamsHandling: 'merge',
        });
    }

    openNewProcessTemplate(templateId: string): Promise<boolean> {
        return this.router.navigate(['templates', 'processes', templateId], {
            queryParams: { silentTemplateCreation: true },
            queryParamsHandling: 'merge',
        });
    }

    openNewSectionTemplate(templateId: string): Promise<boolean> {
        return this.router.navigate(['templates', 'sections', templateId], {
            queryParams: { silentTemplateCreation: true },
            queryParamsHandling: 'merge',
        });
    }

    openSectionTemplate(templateId: string): Promise<boolean> {
        return this.router.navigate(['templates', 'sections', templateId]);
    }

    openProjectTemplates(): Promise<boolean> {
        return this.router.navigate(['templates', '!', 'projects']);
    }

    openSectionTemplates(): Promise<boolean> {
        return this.router.navigate(['templates', '!', 'sections']);
    }

    openProject(projectId: string, viewSetting?: TemplateSharingViewSettings): Promise<boolean> {
        let queryParams = {};
        if (viewSetting && viewSetting === 'simplified') {
            queryParams = { simplified: true };
        }
        return this.router.navigate(['projects', projectId], { queryParams });
    }

    createRuumTemplate(): Promise<any> {
        const modalRef: NgbModalRef = this.modalService.open(CreateProjectTemplateComponent);
        return modalRef.result.then(
            ({ templateName, templateDescription, templateWorkspace }) => {
                this.commonService.setBusy(true);

                return this.featureFlagService
                    .isProcessEnabled(templateWorkspace)
                    .toPromise()
                    .then((isProcessEnabled: boolean) =>
                        this.projectServiceBackendConnector.createTemplate(
                            templateName,
                            undefined,
                            templateWorkspace,
                            'ruum',
                            isProcessEnabled,
                        ),
                    )
                    .then((newTemplate) =>
                        Promise.all([
                            this.templateListItemService.changeDescription(newTemplate.id, templateDescription),
                            this.openProjectTemplate(newTemplate.id),
                        ]).then(() => this.commonService.setBusy(false)),
                    )
                    .catch((err) => {
                        this.commonService.setBusy(false);
                        this.ruumAlertService.warning('Error creating template', err);
                    });
            },
            () => {},
        );
    }

    createSectionTemplate(): Promise<any> {
        const modalRef: NgbModalRef = this.modalService.open(CreateProjectTemplateComponent);
        return modalRef.result.then(
            ({ templateName, templateDescription, templateWorkspace }) => {
                this.commonService.setBusy(true);
                return this.projectServiceBackendConnector
                    .createTemplate(templateName, undefined, templateWorkspace, 'section', false)
                    .then((newTemplate) =>
                        Promise.all([
                            this.templateListItemService.changeDescription(newTemplate.id, templateDescription),
                            this.openSectionTemplate(newTemplate.id),
                        ]).then(() => this.commonService.setBusy(false)),
                    )
                    .catch(() => this.commonService.setBusy(false));
            },
            () => {},
        );
    }

    selectProjectTemplate(): Promise<any> {
        return this.modalService.open(SelectProjectTemplateComponent, { size: 'lg' }).result.then(
            ({ action, template }) => {
                if (action === 'CREATE_PROJECT_FROM_TEMPLATE') {
                    return this.adjustProjectTemplateCreation(template.id, template.name, template.workspaceId);
                }
                if (action === 'MANAGE_PROJECT_TEMPLATES') {
                    return this.openProjectTemplates();
                }
            },
            () => {},
        );
    }

    selectSectionTemplate(onTopOfSectionId: string): Promise<any> {
        return this.modalService.open(SelectSectionTemplateComponent, { size: 'lg' }).result.then(
            ({ action, template }) => {
                if (action === 'ADD_SECTION_TO_PROJECT') {
                    const projectId = this.selectedProjectService.getSelectedProjectId();

                    return this.projectServiceBackendConnector.insertSectionTemplate(
                        projectId,
                        template.id,
                        onTopOfSectionId,
                    );
                }
                if (action === 'MANAGE_SECTION_TEMPLATES') {
                    return this.openSectionTemplates();
                }
            },
            () => {},
        );
    }

    adjustProjectTemplateCreation(templateId: string, templateName: string, templateWorkspaceId: string): Promise<any> {
        const modalRef = this.modalService.open(SelectProjectTemplateConfigComponent, {});
        modalRef.componentInstance.templateId = templateId;
        modalRef.componentInstance.templateName = templateName;

        return modalRef.result.then(
            (config) => {
                this.commonService.setBusy(true);

                const selectedGroupId = this.selectedProjectGroupService.getSelectedGroupId();
                const selectedWorkspaceId = this.selectedWorkspaceService.getSelectedWorkspaceId();
                const workspaceId = templateWorkspaceId ? templateWorkspaceId : selectedWorkspaceId;

                this.projectServiceBackendConnector
                    .createProject(templateName, templateId, config, workspaceId, selectedGroupId)
                    .then((project) => {
                        this.openProject(project.id);
                        this.commonService.setBusy(false);
                    });
            },
            () => {},
        );
    }

    editCover(
        id: string,
        templateName: string,
        templateDescription: string,
        coverColor: string,
        coverIcon: string,
    ): Promise<any> {
        const modalRef = this.modalService.open(TemplateEditCoverComponent, { centered: true });

        modalRef.componentInstance.name = templateName;
        modalRef.componentInstance.description = templateDescription;
        modalRef.componentInstance.coverColor = coverColor;
        modalRef.componentInstance.coverIcon = coverIcon;

        return modalRef.result.then(
            ({ name, description, coverColor: colorTheme, coverIcon: icon }) => {
                return this.templateListItemService.changeTemplateFields(id, {
                    name,
                    description,
                    colorTheme,
                    icon,
                });
            },
            () => {},
        );
    }

    duplicateTemplate(templateId: string): Promise<any> {
        this.commonService.setBusy(true);
        return this.projectServiceBackendConnector
            .duplicateTemplate(templateId)
            .then((template) => {
                this.commonService.setBusy(false);
                return template;
            })
            .catch(() => this.commonService.setBusy(false));
    }

    moveTemplateToWorkspace(templateId): Promise<any> {
        const modalRef = this.modalService.open(SelectWorkspaceComponent);
        modalRef.componentInstance.headerText = 'Move Template to Workspace';
        return modalRef.result.then(
            (workspaceId) => {
                this.commonService.setBusy(true);
                return this.templateListItemService
                    .moveTemplateToWorkspace(templateId, workspaceId)
                    .then(() => this.commonService.setBusy(false))
                    .catch(() => this.commonService.setBusy(false));
            },
            () => {},
        );
    }

    leaveTemplate(templateId: string): Promise<boolean> {
        return this.ruumAlertService
            .warning({
                title: 'Are you sure you want to permanently leave this Template?',
                actionText: 'Leave',
            })
            .then(
                () => {
                    this.commonService.setBusy(true);
                    this.selectedTemplateService.unselectTemplate();
                    return this.templateListItemService
                        .leaveTemplate(templateId)
                        .then(() => {
                            this.commonService.setBusy(false);
                            return true;
                        })
                        .catch((error) => {
                            this.commonService.setBusy(false);
                            if (error.error.message === 'There should be at least one TemplateAdmin') {
                                return this.leaveAndDeleteTemplate(templateId);
                            }
                            return false;
                        });
                },
                () => {
                    return false;
                },
            );
    }

    leaveAndDeleteTemplate(templateId): Promise<boolean> {
        return this.ruumAlertService
            .warning({
                title: 'You are the only template admin. Would you like to delete this template instead?',
                actionText: 'Delete',
            })
            .then(
                () => {
                    this.commonService.setBusy(true);
                    this.selectedTemplateService.unselectTemplate();
                    return this.templateListItemService
                        .deleteTemplate(templateId)
                        .then(() => {
                            this.commonService.setBusy(false);
                            return true;
                        })
                        .catch(() => {
                            this.commonService.setBusy(false);
                            return false;
                        });
                },
                () => {
                    return false;
                },
            );
    }

    deleteTemplate(templateId): Promise<boolean> {
        return this.ruumAlertService
            .warning({
                title: 'Are you sure you want to permanently delete this Template?',
                actionText: 'Delete',
            })
            .then(
                () => {
                    this.commonService.setBusy(true);
                    return this.templateListItemService
                        .deleteTemplate(templateId)
                        .then(() => {
                            this.commonService.setBusy(false);
                            return true;
                        })
                        .catch(() => {
                            this.commonService.setBusy(false);
                            return false;
                        });
                },
                () => {
                    return false;
                },
            );
    }

    suggestPublishTemplate(templateId: string, draftProjectId: string): Promise<boolean> {
        const modalRef = this.modalService.open(SuggestPublishTemplateComponent, { centered: true, size: 'sm' });
        return modalRef.result.then(
            (publish) => {
                if (publish) {
                    return this.publishTemplate(templateId, draftProjectId).then(
                        () => true,
                        () => true,
                    );
                }
                return true;
            },
            () => true,
        );
    }

    confirmPublishTemplateDraft(belongsToWorkspace?: boolean): Promise<boolean> {
        let description: string[];
        if (belongsToWorkspace) {
            description = [
                'By publishing this template, you will share it with all team members of the selected workspace. Workspace admins will be able to edit the template, other members will only be able to create new ruums.',
            ];
        } else {
            description = ['Publishing allows you to create new ruums from this template.'];
        }
        return this.ruumAlertService
            .info({
                title: 'Publish template?',
                description,
                noCancel: false,
                actionText: 'Publish',
            })
            .then(
                () => true,
                () => false,
            );
    }

    confirmPublishTemplateChanges(belongsToWorkspace?: boolean) {
        let description: string[];
        if (belongsToWorkspace) {
            description = [
                'By publishing template changes you overwrite the previous template version. The new version will be shared with all team members of the selected workspace.',
            ];
        } else {
            description = ['By publishing template changes you overwrite the previous template version.'];
        }
        return this.ruumAlertService
            .info({
                title: 'Publish changes?',
                description,
                noCancel: false,
                actionText: 'Publish',
            })
            .then(
                () => true,
                () => false,
            );
    }

    publishTemplate(templateId: string, draftProjectId: string): Promise<Template> {
        return this.projectServiceBackendConnector.publishTemplate(templateId, draftProjectId);
    }

    editTemplate(templateId: string): Promise<any> {
        return this.projectServiceBackendConnector
            .editTemplate(templateId)
            .then(async () => await this.selectedTemplateService.selectTemplate(templateId));
    }
}
