import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isMac } from '../../utils.service';

@Component({
    selector: 'ruum-keyboard-shortcuts',
    template: `
        <div class="modal-body">
            <button class="btn btn-link-secondary btn-icon close" type="button" (click)="dismiss()">
                <i class="icon icon-cancel"></i>
            </button>
            <div class="m-5">
                <h2 class="mt-4 mb-5">Canvas Shortcuts</h2>

                <h4 class="mb-5">General</h4>
                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">New Task</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in empty Line +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SHIFT</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">(</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">)</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Subtask</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in Task Text +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">TAB</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Un-Subtask</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in Task Text +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SHIFT</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">TAB</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Unordered List</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in Paragraph +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="isMac()">
                            ⌘
                        </button>
                        <button
                            class="btn btn-xs btn-keyboard active text-gray-700 mr-2"
                            type="button"
                            *ngIf="!isMac()"
                        >
                            CTRL
                        </button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SHIFT</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">U</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Ordered List</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in Paragraph +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="isMac()">
                            ⌘
                        </button>
                        <button
                            class="btn btn-xs btn-keyboard active text-gray-700 mr-2"
                            type="button"
                            *ngIf="!isMac()"
                        >
                            CTRL
                        </button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SHIFT</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">O</button>
                    </div>
                </div>

                <h4 class="mt-7 mb-5">Text Styling</h4>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Medium Header</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in empty Line +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">#</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">#</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SPACE</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Small Header</div>
                        <span class="text-tiny text-gray-500 mr-2">Cursor in empty Line +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">#</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">#</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">#</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SPACE</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Bold</div>
                        <span class="text-tiny text-gray-500 mr-2">Text highlighted +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="isMac()">
                            ⌘
                        </button>
                        <button
                            class="btn btn-xs btn-keyboard active text-gray-700 mr-2"
                            type="button"
                            *ngIf="!isMac()"
                        >
                            CTRL
                        </button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">B</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Italic</div>
                        <span class="text-tiny text-gray-500 mr-2">Text highlighted +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="isMac()">
                            ⌘
                        </button>
                        <button
                            class="btn btn-xs btn-keyboard active text-gray-700 mr-2"
                            type="button"
                            *ngIf="!isMac()"
                        >
                            CTRL
                        </button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">I</button>
                    </div>
                </div>

                <div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Underline</div>
                        <span class="text-tiny text-gray-500 mr-2">Text highlighted +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="isMac()">
                            ⌘
                        </button>
                        <button
                            class="btn btn-xs btn-keyboard active text-gray-700 mr-2"
                            type="button"
                            *ngIf="!isMac()"
                        >
                            CTRL
                        </button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">U</button>
                    </div>
                </div>

                <!--<div class="d-flex align-items-cente mb-4">
                    <div class="d-flex flex-fill justify-content-center align-items-center">
                        <div class="d-flex flex-fill">Strikethrough </div>
                        <span class="text-tiny text-gray-500 mr-2">Text highlighted  +</span>
                    </div>
                    <div class="keyboards d-flex justify-content-start">
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="isMac()">⌘</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button" *ngIf="!isMac()">CTRL</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">SHIFT</button>
                        <button class="btn btn-xs btn-keyboard active text-gray-700 mr-2" type="button">X</button>
                    </div>
                </div>-->
            </div>
        </div>
    `,
    styles: [
        `
            .keyboards {
                width: 38%;
            }
            .btn-keyboard {
                background: rgba(37, 44, 66, 0.08);
                border: 1px solid rgba(37, 44, 66, 0.24);
                cursor: default !important;
            }
        `,
    ],
})
export class KeyboardShortcutsComponent {
    constructor(private activeModal: NgbActiveModal) {}

    isMac() {
        return isMac();
    }

    dismiss() {
        this.activeModal.dismiss();
    }
}
