import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ProfileSizePipe } from './profile-size.pipe';

@Component({
    selector: 'ruum-profile-others-counter',
    template: `
        <button
            class="btn btn-link-light btn-avatar"
            type="button"
            [disabled]="disabled"
            [ngClass]="componentClass"
            [ngbTooltip]="tooltip"
            [disableTooltip]="disableTooltip"
            [container]="'body'"
            [tooltipClass]="tooltipTheme"
        >
            <span>{{ quantityFotmatted }}</span>
        </button>
        <ng-template #tooltip>
            <div
                class="d-flex text-small text-break-all text-left"
                *ngFor="let name of nameList; let last = last"
                [class.mb-1]="!last"
            >
                {{ name }}
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileOthersCounterComponent implements OnInit {
    @HostBinding('class.d-flex') hostClassList = true;

    @Input() quantity: number;
    @Input() nameList: string[] = [];
    @Input() size: string;

    /** States */
    @Input() hover = false;
    @Input() active = false;
    @Input() disabled = false;
    @Input() tooltipTheme: string;

    private profileSizePipe = new ProfileSizePipe();

    constructor() {}

    ngOnInit() {}

    get quantityFotmatted(): string {
        const value = this.quantity > 99 ? '99' : this.quantity;
        return `+${value}`;
    }

    get componentClass(): string[] {
        return [this.getSizeClass(), this.getNoHoverClass(), this.getActiveClass()].filter((el) => !!el);
    }

    get disableTooltip(): boolean {
        return this.nameList.length === 0;
    }

    private getSizeClass(): string {
        const size = this.profileSizePipe.transform(this.size);
        return ['btn', size].filter((el) => !!el).join('-');
    }

    private getNoHoverClass(): string {
        return this.hover ? '' : 'btn-without-hover';
    }
    private getActiveClass(): string {
        return this.active ? 'active' : '';
    }
}
