import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { RuumDropdownItem, RuumDropdownList } from '../../dropdown/dropdown.model';
import { SelectOptionComponent } from '../select-option/select-option.component';

@Component({
    selector: 'ruum-select-dropdown-controller',
    template: `
        <ruum-select
            [theme]="'light'"
            [type]="'outline'"
            size="sm"
            [lightBackground]="true"
            [selectTitle]="selectTitle"
            [multiSelect]="isMultiSelect"
            [select]="selectedOptions"
            (selectChange)="onSelectChange($event)"
        >
            <ruum-select-none></ruum-select-none>
            <ruum-select-option
                *ngFor="let item of options; trackBy: trackByOption"
                [value]="item.id"
                [content]="item.name"
            >
            </ruum-select-option>
        </ruum-select>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDropdownControllerComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block w-100 mw-100';

    @Input()
    selectTitle: string;

    @Input()
    options: RuumDropdownList = [];

    @Input()
    selectedItems: RuumDropdownList = [];

    @Input() isMultiSelect = false;

    @Output()
    selectItem = new EventEmitter<RuumDropdownItem>();

    @Output()
    selectItems = new EventEmitter<RuumDropdownItem[]>();

    @Output()
    unselectItem = new EventEmitter<void>();

    get selectedOptions(): string | string[] {
        const ids = this.selectedItems.map((data) => data.id);
        if (this.isMultiSelect) {
            return ids;
        } else {
            return ids[0];
        }
    }

    constructor() {}

    ngOnInit() {}

    onSelectChange(ids: string | string[]) {
        if (this.isMultiSelect) {
            const idsSet = new Set<string>();
            if (Array.isArray(ids)) {
                ids.forEach((v) => idsSet.add(v));
            } else {
                idsSet.add(ids);
            }
            const selectedItems = this.options.filter((item) => idsSet.has(item.id));
            this.selectItems.next(selectedItems);
        }
        if (!this.isMultiSelect) {
            if (ids) {
                const selectedItem = this.options.find((item) => item.id === ids);
                this.selectItem.next(selectedItem);
            } else {
                this.unselectItem.next();
            }
        }
    }

    trackByOption(index: number, item: SelectOptionComponent) {
        return item.value;
    }
}
