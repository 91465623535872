import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TaskAssigneesModule } from './task-assignees/task-assignees.module';
import { TaskDatesModule } from './task-dates/task-dates.module';
import { TaskDescriptionModule } from './task-description/task-description.module';
import { TaskPriorityModule } from './task-priority/task-priority.module';
import { TaskStatusModule } from './task-status/task-status.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TaskStatusModule,
        TaskDescriptionModule,
        TaskAssigneesModule,
        TaskPriorityModule,
        TaskDatesModule,
    ],
    exports: [TaskStatusModule, TaskDescriptionModule, TaskAssigneesModule, TaskPriorityModule, TaskDatesModule],
})
export class TaskModule {}
