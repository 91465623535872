import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SecondaryNavbarService, UnseenChangesCounters } from '../navbar/secondary-navbar/secondary-navbar.service';

@Component({
    selector: 'ruum-team-invite-button',
    template: `
        <div
            class="d-flex"
            [ngbTooltip]="inviteTooltipContent"
            [placement]="tooltipPlacement"
            [disableTooltip]="disableTooltip"
            [container]="'body'"
        >
            <button
                class="btn btn-sm btn-outline-primary"
                type="button"
                [disabled]="disableInviteButton || disabled"
                (click)="clickInviteButton.emit($event)"
                [title]="inviteButtonTooltip"
                [attr.aria-label]="inviteButtonText"
            >
                <span>{{ inviteButtonText }}</span>
            </button>
        </div>

        <div class="d-flex" [ngbTooltip]="tooltipContent" [disableTooltip]="!tooltipText">
            <button
                class="btn ml-1 px-1"
                [class.btn-link-secondary]="!activeTeamButton"
                [class.btn-primary-light]="activeTeamButton"
                type="button"
                [title]="name"
                [disabled]="disabled || disableTeamButton"
                (click)="clickTeamButton.emit($event)"
            >
                <i class="icon {{ icon }}"></i>
                <span class="text-small pl-1">{{ numberOfParticipants }}</span>
                <unseen-item-counter
                    [counter]="unseenChangesCounters$ | async | unseenChangesCounterOf: 'team'"
                ></unseen-item-counter>
            </button>
        </div>

        <ng-template #inviteTooltipContent>
            <div class="text-small font-weight-bold">{{ inviteTooltipText }}</div>
        </ng-template>

        <ng-template #tooltipContent>
            <div class="text-small font-weight-bold">{{ tooltipText }}</div>
        </ng-template>
    `,
})
export class TeamInviteButtonComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input() name: string;
    @Input() icon: string;
    @Input() numberOfParticipants = 0;
    @Input() disabled: boolean;
    @Input() activeTeamButton = false;
    @Input() disableInviteButton = false;
    @Input() disableTeamButton = false;
    @Input() disableTooltip = false;
    @Input() tooltipText = '';
    @Input() inviteTooltipText = 'Only Admins can invite new team members.';
    @Input() inviteButtonText = 'Invite';
    @Input() tooltipPlacement: string[] = ['left', 'right'];

    @Output() clickInviteButton = new EventEmitter();
    @Output() clickTeamButton = new EventEmitter();

    inviteButtonTooltip = 'Invite team members';

    unseenChangesCounters$: Observable<UnseenChangesCounters>;

    constructor(private secondaryNavbarService: SecondaryNavbarService) {}

    ngOnInit() {
        this.unseenChangesCounters$ = this.secondaryNavbarService.getUnseenChangesCounters();
    }
}
