import { Component, HostBinding } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { BaseFormComponent } from '../common/components/form.component';
import { ModalService } from '../common/modal/modal.service';
import { WebhookListService } from './webhook-list.service';

@Component({
    selector: 'create-webhook',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <div class="body">
                    <webhook-form [form]="form" [isIdFieldVisible]="false"></webhook-form>
                </div>

                <div class="footer">
                    <button
                        [disabled]="form.invalid"
                        class="btn btn-primary float-right"
                        type="button"
                        (click)="save()"
                    >
                        Save
                    </button>
                    <button class="btn btn-outline-secondary float-right" type="button" (click)="back()">
                        Cancel
                    </button>
                </div>
            </div>
        </ruum-max-content>
    `,
})
export class CreateWebhookComponent extends BaseFormComponent {
    @HostBinding('class') hostClassList =
        'd-flex flex-column w-100 mw-100 h-100 mh-100 px-2 px-sm-4 px-md-5 px-lg-8 overflow-y';

    private saving = false;

    constructor(
        private fb: FormBuilder,
        private webhookListService: WebhookListService,
        private modalService: ModalService,
        private router: Router,
        private selectedEnterpriseService: SelectedEnterpriseService,
    ) {
        super();

        this.form = this.fb.group({
            // Maybe move to form..
            name: ['', Validators.required],
            userId: null,
            url: ['', Validators.required],
            version: 1,
            workspaceId: null,
            authType: 'BASIC',
            type: ['TECHNICAL_USER', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    async save() {
        try {
            if (!this.saving) {
                this.saving = true;

                const data = this.form.getRawValue();
                await this.webhookListService.create(data);
                this.form.markAsPristine();
                const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
                const toWebhooksPage = `/admin/enterprise/${enterpriseId}/webhooks`;
                this.router.navigateByUrl(toWebhooksPage);
            }
        } catch (error) {
            this.modalService.openErrorDialog('Can you please try again?');
            this.saving = false;
        }
    }
}
