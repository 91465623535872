import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { CommentableType, CommentActionPayload, RuumActionTypes } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectServiceBackendConnector } from '../../../../app/common/connectors/projectServiceConnector.service';
import { RuumUIStateService } from '../../../../app/ruum/ui-state/ruumUIState.service';
import { CommentListItem } from '../../connectors/commentable/commentable.reducer';
import { SelectedTemplateService } from '../../connectors/templates/selectedTemplate.service';
import { TemplateListItem } from '../../connectors/templates/template-list.model';
import { AddCommentData } from './add-comment-text-input.component';

@Component({
    selector: 'comment-list-wrapper',
    template: `
        <!-- Comments -->
        <div class="d-flex flex-column px-4">
            <comment-list
                class="ruum-comment-list"
                [comments]="comments"
                [commentableId]="commentableId"
                [commentableType]="commentableType"
                [projectId]="projectId"
                [isReadOnly]="isReadOnly"
                (edit)="edit.emit($event)"
                (delete)="delete.emit($event)"
            >
            </comment-list>
        </div>

        <!-- Input to add new comment -->
        <div class="position-fixed bottom-0 right-0 w-100 d-flex flex-column ruum-add-comment-container">
            <div class="d-flex flex-fill ruum-gradient"></div>
            <add-comment
                *ngIf="(isEnterpriseTemplate$ | async) !== true && showInput && (isOffline$ | async) !== true"
                [projectId]="projectId"
                (add)="add.emit($event)"
                (errorMessage)="setErrorMessage($event)"
            >
            </add-comment>
            <div
                class="d-flex  flex-fill align-items-center ml-4 pr-4 bg-white"
                *ngIf="commentErrorMessage !== undefined"
            >
                <button class="btn btn-xs btn-outline-danger btn-icon border-0" type="button">
                    <i class="icon icon-cancel"></i>
                </button>
                <div class="text-tiny text-danger">
                    {{ commentErrorMessage }}
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .ruum-comment-list {
                margin-bottom: 90px;
            }

            .ruum-add-comment-container {
                margin-right: -1px;
            }

            .ruum-gradient {
                height: 24px;
                background: rgba(255, 255, 255, 0);
                background: -moz-linear-gradient(
                    top,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 1) 100%
                );
                background: -webkit-gradient(
                    left top,
                    left bottom,
                    color-stop(0%, rgba(255, 255, 255, 0)),
                    color-stop(50%, rgba(255, 255, 255, 0.5)),
                    color-stop(100%, rgba(255, 255, 255, 1))
                );
                background: -webkit-linear-gradient(
                    top,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 1) 100%
                );
                background: -o-linear-gradient(
                    top,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 1) 100%
                );
                background: -ms-linear-gradient(
                    top,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 1) 100%
                );
                background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.5) 50%,
                    rgba(255, 255, 255, 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentListWrapperComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill flex-column';
    @Input() comments: CommentListItem[] = [];
    @Input() commentableId: string;
    @Input() commentableType: CommentableType;
    @Input() projectId: string;
    @Input() isReadOnly = false;

    @Output() edit = new EventEmitter<{ id: string; text: string }>();
    @Output() delete = new EventEmitter<string>();
    @Output() add = new EventEmitter<AddCommentData>();
    isOffline$: Observable<boolean>;
    isEnterpriseTemplate$: Observable<boolean>;
    showInput = false;
    commentErrorMessage = undefined;

    constructor(
        private cdr: ChangeDetectorRef,
        private uiStateService: RuumUIStateService,
        private projectServiceConnector: ProjectServiceBackendConnector,
        private selectedTemplateService: SelectedTemplateService,
    ) {
        this.isOffline$ = this.uiStateService.offline();
    }

    ngOnInit() {
        this.recordCommentsWereSeen();
        setTimeout(() => {
            this.showInput = true;
            try {
                this.cdr.detectChanges();
            } catch (e) {}
        }, 300);
        this.isEnterpriseTemplate$ = this.selectedTemplateService
            .selectedTemplate()
            .pipe(filter(Boolean))
            .pipe(map((project: TemplateListItem) => project.isGlobalOrEnterpriseTemplate));
    }

    private recordCommentsWereSeen() {
        const payload: CommentActionPayload = {
            id: this.commentableId,
            onObjectId: this.commentableId,
            onObjectType: this.commentableType,
        };
        this.projectServiceConnector.addActionToRuum(this.projectId, RuumActionTypes.SHOW_COMMENTS_OF_OBJECT, payload);
    }
    ngOnDestroy() {
        this.showInput = false;
    }

    setErrorMessage(errorMessage: string) {
        this.commentErrorMessage = errorMessage;
    }
}
