import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumA11yModule } from '../../../common/a11y/a11y.module';
import { RuumBootstrapModule } from '../../../common/ruum-bootstrap/ruum-bootstrap.module';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { DropdownModule } from '../../ui-components/dropdown/dropdown.module';
import { ProjectGroupRenameModule } from '../project-group-rename/project-group-rename.module';
import { ProjectGroupMenuComponent } from './project-group-menu.component';

@NgModule({
    imports: [
        CommonModule,
        ModalDialogModule,
        DropdownModule,
        RuumBootstrapModule,
        ProjectGroupRenameModule,
        RuumA11yModule,
    ],
    declarations: [ProjectGroupMenuComponent],
    exports: [ProjectGroupMenuComponent],
})
export class ProjectGroupMenuModule {}
