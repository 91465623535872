import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadSpinnerComponent } from './load-spinner.component';

@NgModule({
    imports: [CommonModule],
    declarations: [LoadSpinnerComponent],
    exports: [LoadSpinnerComponent],
})
export class LoadSpinnerModule {}
