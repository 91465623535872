import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../../../common/common.module';
import { CustomFieldSharedModule } from '../../shared/custom-field/custom-field-shared.module';
import { CustomFieldDialogCreateComponent } from './custom-field-dialog-create.component';
import { CustomFieldDialogEditComponent } from './custom-field-dialog-edit.component';
import { CustomFieldSelectTableDefinitionComponent } from './custom-field-select-table-definition.component';
import { CustomFieldAddInstanceComponent } from './custom-fields-add-instance.component';
import { CustomFieldAddToCanvasComponent } from './custom-fields-add-to-canvas.component';
import { CustomFieldAssignToProjectComponent } from './custom-fields-assign-to-project.component';
import { CustomFieldAssignToTaskComponent } from './custom-fields-assign-to-task.component';
import { CustomFieldAssignComponent } from './custom-fields-assign.component';
import { CustomFieldSelectionListDropdownComponent } from './selection-list/custom-field-selection-list-dropdown.component';
import { CustomFieldSelectionListOptionsComponent } from './selection-list/custom-field-selection-list-options.component';
import { CustomFieldSelectionListComponent } from './selection-list/custom-field-selection-list.component';

@NgModule({
    declarations: [
        CustomFieldDialogCreateComponent,
        CustomFieldAddToCanvasComponent,
        CustomFieldAssignToProjectComponent,
        CustomFieldAssignToTaskComponent,
        CustomFieldAssignComponent,
        CustomFieldDialogEditComponent,
        CustomFieldSelectionListComponent,
        CustomFieldSelectionListDropdownComponent,
        CustomFieldSelectionListOptionsComponent,
        CustomFieldAddInstanceComponent,
        CustomFieldSelectTableDefinitionComponent,
    ],
    imports: [CommonModule, CustomFieldSharedModule, RuumCommonModule],
    exports: [
        CustomFieldDialogCreateComponent,
        CustomFieldAddToCanvasComponent,
        CustomFieldAssignToProjectComponent,
        CustomFieldAssignToTaskComponent,
        CustomFieldDialogEditComponent,
        CustomFieldAddInstanceComponent,
    ],
})
export class CustomFieldDialogModule {}
