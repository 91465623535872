import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { RuumAlertService } from '../../components/alert/alert.service';
import { PaginatedList } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { PGParticipantWithAllRoles, ReadModelBackendConnector } from '../readModelConnector.service';

@Injectable({ providedIn: 'root' })
export class ProjectGroupParticipantListService extends PaginatedListLoader<
    PGParticipantWithAllRoles,
    { projectGroupId: string },
    null
> {
    readonly projectGroupId$ = new BehaviorSubject<string>(undefined);
    readonly list$ = new BehaviorSubject<PaginatedList<PGParticipantWithAllRoles>>(getDefaultPaginatedList());

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.getListObservable()
            .pipe(
                scan((currentState, page) => {
                    if (!!currentState && page.currentPage > 1) {
                        return {
                            ...page,
                            rows: [...currentState.rows, ...page.rows],
                        };
                    } else {
                        return page;
                    }
                }),
            )
            .subscribe((list) => {
                this.list$.next(list);
            });
    }

    getFilters$(): Observable<PGParticipantListFilters> {
        return this.projectGroupId$.pipe(
            map((projectGroupId) => ({ projectGroupId })),
            filter((filters) => !!filters.projectGroupId),
        );
    }

    getData(page: number, filters: PGParticipantListFilters): Observable<PaginatedList<PGParticipantWithAllRoles>> {
        return this.readModelConnector.getProjectGroupAccess({ page, pageSize: 50 }, filters.projectGroupId, {
            isProjectGroupParticipant: true,
        });
    }

    getStoreData$() {
        return this.list$;
    }
}

export interface PGParticipantListFilters {
    projectGroupId: string;
}
