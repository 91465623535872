import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruumTemplateIcon' })
export class TemplateIconPipe implements PipeTransform {
    transform(iconName: string): string {
        const defaultIcon = 'icon-template';
        const icon = iconName ? `icon-${iconName}` : defaultIcon;
        return icon.toLocaleLowerCase() === 'icon-none' ? defaultIcon : icon;
    }
}
