import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { SelectService } from '../select.service';

@Component({
    selector: 'ruum-select-action-option',
    template: `
        <ng-content></ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectActionOptionComponent implements OnInit {
    @HostBinding('class')
    hostClassList = 'ruum-select-action-option d-flex flex-fill align-items-center minw-0 font-weight-bold';

    @HostBinding('class.disabled')
    @Input()
    disabled = false;

    @HostBinding('style.margin-bottom.px') marginBottom = 2;

    constructor(private selectService: SelectService) {}

    ngOnInit() {}

    @HostListener('click', ['$event'])
    onMenuClick(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.selectService.onEvent(event);
    }
}
