import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruumTemplateCreatedBy' })
export class TemplateCreatedByPipe implements PipeTransform {
    transform(categoryId: string): string {
        if (categoryId === 'owned') {
            return 'created by';
        }

        if (categoryId === 'shared') {
            return 'by';
        }

        return '';
    }
}
