import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../../common/app.store';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import { OrderedListParams, PaginatedList } from '../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../common/connectors/paginatedListLoader';
import { ServiceHelper } from '../../../common/serviceHelper';
import { WebhookEntityStateFilters, WebhooksConnector } from '../webhooks.connector';
import { WebhookEntitySyncStateListItem } from '../webhooks.model';
import {
    SingleWebhookEntityLoadedAction,
    WebhookEntitiesLoadedAction,
    WEBHOOK_ENTITIES_LIST_ACTION_TYPES,
} from './webhook-entities-list.reducer';

export type WebhookEntitiesOrderBy = 'currentState';

@Injectable({ providedIn: 'root' })
export class WebhookEntitiesListService extends PaginatedListLoader<
    WebhookEntitySyncStateListItem,
    WebhookEntityStateFilters,
    WebhookEntitiesOrderBy
> {
    private webhookId$: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        protected authService: AuthService,
        protected alertService: RuumAlertService,
        private webhooksConnector: WebhooksConnector,
        private serviceHelper: ServiceHelper,
        private store: Store<AppState>,
    ) {
        super(alertService, authService);
        this.loadList();
        this.setUpObservables();
    }

    filterByWebhookId(webhookId: string) {
        this.webhookId$.next(webhookId);
    }

    // TODO: @ngrx9: fix type
    getStoreData$(): any {
        return combineLatest([this.store.select('webhookList'), this.webhookId$]).pipe(
            filter(([webhooks, selectedWebhookId]) => webhooks && selectedWebhookId !== null),
            map(([webhooks, selectedWebhookId]) => {
                const webhook = webhooks.rows.find((wh) => wh.id === selectedWebhookId);
                return webhook ? webhook.entities : [];
            }),
        );
    }

    protected getFilters$(): Observable<WebhookEntityStateFilters> {
        return combineLatest([this.webhookId$]).pipe(
            filter(([webhookId]) => {
                return !!webhookId;
            }),
            map<any, WebhookEntityStateFilters>(([webhookId]) => ({
                webhookId,
            })),
        );
    }

    private setUpObservables() {
        this.getListObservable()
            .pipe(
                withLatestFrom(this.webhookId$),
                filter(([page, webhookId]) => {
                    return !!webhookId;
                }),
            )
            .subscribe(([page, webhookId]) => {
                this.serviceHelper.dispatchWithoutPersisting<WebhookEntitiesLoadedAction>(
                    WEBHOOK_ENTITIES_LIST_ACTION_TYPES.WEBHOOK_ENTITIES_LOADED,
                    { page, webhookId },
                );
            });
    }

    async getByKeys(
        webhookId: string,
        entityId: string,
        forceLoad: boolean = false,
    ): Promise<WebhookEntitySyncStateListItem> {
        try {
            // checkStore
            let entity;
            if (!forceLoad) {
                entity = await this.getStoreData$()
                    .pipe(
                        take(1),
                        map((paginatedList: any) => {
                            return paginatedList.rows.find((el) => el.entityId === entityId);
                        }),
                    )
                    .toPromise();
            }

            if (!entity) {
                entity = await this.webhooksConnector.getEntityState(webhookId, entityId).toPromise();
                if (entity) {
                    this.serviceHelper.dispatchWithoutPersisting<SingleWebhookEntityLoadedAction>(
                        WEBHOOK_ENTITIES_LIST_ACTION_TYPES.SINGLE_WEBHOOK_ENTITY_LOADED,
                        { webhookId, entity },
                    );
                }
            }

            return entity;
        } catch (e) {
            // Not found
            return null;
        }
    }

    protected getData(
        page: number,
        filters: WebhookEntityStateFilters,
        orderBy: OrderedListParams<WebhookEntitiesOrderBy>,
    ): Observable<PaginatedList<WebhookEntitySyncStateListItem>> {
        return this.webhooksConnector.getSyncedEntities({ page }, filters, orderBy);
    }
}
