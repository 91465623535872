import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TrackingConnector } from './../../../common/trackingConnector.service';
import { differenceBy, intersectionBy } from './../../../common/utils.service';

export interface ListViewItem {
    id: string;
    fieldId: string;
    name: string;
    icon: string;
    isFixed?: boolean;
    isChecked?: boolean;
    isHidden?: boolean;
}

export enum modalHeight {
    lg = 678,
}

@Component({
    selector: 'ruum-saved-view-settings-dialog',
    template: `
        <ruum-modal-dialog [fullHeight]="true" [style.minHeight.px]="modalHeight" [style.height.px]="modalHeight">
            <div class="w-100 h-100 pb-8 d-flex flex-column justify-content-between">
                <div class="d-flex flex-column">
                    <h2 class="mb-6">{{ title }}</h2>

                    <div class="d-flex pl-2 saved-view-dialog-content">
                        <div class="d-flex flex-column w-50 border-right border-light">
                            <h5 class="ml-2 mt-5 mb-4 text-gray-500 pl-4">Table fields</h5>
                            <div class="pl-4">
                                <ruum-sortable-list
                                    *ngIf="unchangeableListItems"
                                    [listItems]="unchangeableListItems"
                                    [hasVisibilityIcon]="false"
                                    [hasHover]="false"
                                    [dragDisabled]="true"
                                >
                                </ruum-sortable-list>
                            </div>

                            <div
                                *ngIf="checkedListItems && unchangeableListItems"
                                class="border-bottom border-light mt-1 mr-4 mb-3 ml-4"
                            ></div>

                            <ruum-scrollable-list
                                addContentClass="pt-1"
                                addScrollIndicatorClass="ml-6"
                                style="margin-left: -1rem"
                            >
                                <ruum-sortable-list
                                    *ngIf="checkedListItems"
                                    [listItems]="checkedListItems"
                                    (itemClicked)="handleListItemClick($event)"
                                    (sortingOrderChanged)="handleSortingOrderChange($event)"
                                >
                                </ruum-sortable-list>
                            </ruum-scrollable-list>
                        </div>

                        <div class="d-flex flex-column w-50 pl-4">
                            <h5 class="mt-5 mb-4 mr-6 text-gray-500">Add fields to the table</h5>

                            <div class="d-flex mr-6 mb-3 search-wrapper">
                                <ruum-search
                                    ariaLabel="Search for field"
                                    (changed)="search($event)"
                                    size="md"
                                ></ruum-search>
                            </div>

                            <div
                                *ngIf="searchResultIsEmpty()"
                                class="d-flex justify-content-center mt-5 text-gray-600 text-small"
                            >
                                No search results found
                            </div>

                            <div
                                *ngIf="uncheckedListIsEmpty()"
                                class="d-flex flex-column align-items-center mt-5 text-gray-600 text-no-results"
                            >
                                <span class="text-small">All fields are already shown.</span>
                                <span class="d-inline-flex align-items-center">
                                    <span class="text-small">Hide them by clicking on the</span>
                                    <i class="d-inline-flex ml-2 icon icon-visibility-on"></i>
                                </span>
                            </div>

                            <ruum-scrollable-list>
                                <ruum-sortable-list
                                    [listItems]="uncheckedListItems"
                                    (itemClicked)="handleListItemClick($event)"
                                    [dragDisabled]="true"
                                >
                                </ruum-sortable-list>

                                <ruum-load-more
                                    *ngIf="hasMoreUncheckedItems"
                                    (loadMore)="loadMoreVisible()"
                                ></ruum-load-more>
                            </ruum-scrollable-list>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <button class="btn btn-lg btn-primary" (click)="applyChanges()">
                        Apply Changes
                    </button>
                </div>
            </div>
        </ruum-modal-dialog>
    `,
    styles: [
        `
            .saved-view-dialog-content {
                max-height: 472px;
            }

            .text-no-results {
                line-height: 20px;
            }

            .modal-height-lg {
                min-height: 678px;
            }

            .search-wrapper {
                min-height: 40px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedViewSettingsDialogComponent implements OnInit, OnDestroy {
    @Input() title = '';
    @Input() hiddenColumns: string[] = [];
    @Input()
    set listItems(value: ListViewItem[]) {
        this._listItems$.next(value);
    }
    get listItems(): ListViewItem[] {
        return this._listItems$.getValue();
    }
    @Input() modalHeight = modalHeight.lg;
    @Input() hasMoreUncheckedItems = false;

    @Output() searchQueryChange = new EventEmitter<string>();
    @Output() loadMore = new EventEmitter<void>();

    @HostBinding('style.minHeight.px') hostStyle = this.modalHeight;

    unchangeableListItems: ListViewItem[];
    hiddenByDefaultListItems: ListViewItem[];
    checkedListItems: ListViewItem[];
    uncheckedListItems: ListViewItem[];

    private subscriptions: Subscription[] = [];
    private _listItems$ = new BehaviorSubject<ListViewItem[]>([]);
    private hasUnsavedChanges = false;
    private unsavedChecked: ListViewItem[] = [];
    private unsavedUnchecked: ListViewItem[] = [];
    private searchQuery = '';

    constructor(private activeModal: NgbActiveModal, private trackingConnector: TrackingConnector) {}

    ngOnInit() {
        this.initLists();
        this.updateUnsavedUserSettings();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    initLists() {
        const listItemsSubscription = this._listItems$.subscribe((listItems) => {
            if (this.hasUnsavedChanges) {
                this.initItemsByUnsavedSettings(listItems);
            } else {
                this.initItemsByNewSettings(listItems);
            }
        });

        this.subscriptions.push(listItemsSubscription);
    }

    initItemsByUnsavedSettings(listItems: ListViewItem[]) {
        const { unsavedChecked, unsavedUnchecked } = this;

        const newCheckedListItems = this.getCheckedItems(listItems);
        const newUncheckedListItems = this.getNotCheckedItems(listItems);

        const unsavedUncheckedToChecked = intersectionBy(newUncheckedListItems, unsavedChecked, 'id');
        const unsavedCheckedToUnchecked = intersectionBy(newCheckedListItems, unsavedUnchecked, 'id').map((item) => ({
            ...item,
            isChecked: false,
        }));

        const filteredUnsavedCheckedToUnchecked =
            this.searchQuery === ''
                ? unsavedCheckedToUnchecked
                : unsavedCheckedToUnchecked.filter((item) => this.fieldNameContainsQuery(item.name));

        this.uncheckedListItems = [
            ...filteredUnsavedCheckedToUnchecked, // Show items marked as not checked but not saved by user yet
            ...differenceBy(newUncheckedListItems, unsavedUncheckedToChecked, 'id'), // Exclude items marked as checked but not saved by user yet
        ];
        /* checkedListItems is not changed since it is not affected by search */
    }

    private fieldNameContainsQuery(fieldName: string): boolean {
        const name = fieldName.toLowerCase();
        const formattedQuery = this.searchQuery.toLowerCase();

        return name.indexOf(formattedQuery) > -1;
    }

    initItemsByNewSettings(listItems: ListViewItem[]): void {
        this.unchangeableListItems = this.getFixedItems(listItems);
        this.hiddenByDefaultListItems = this.getHiddenByDefaultItems(listItems);
        this.checkedListItems = this.getCheckedItems(listItems);
        this.uncheckedListItems = this.getNotCheckedItems(listItems);
    }

    applyChanges(): void {
        this.hasUnsavedChanges = false;

        this.activeModal.close(
            [
                ...this.unchangeableListItems,
                ...this.checkedListItems,
                ...this.uncheckedListItems,
                ...this.getHiddenColumns(), // TODO: Check this method
                ...this.hiddenByDefaultListItems,
            ].filter((el) => !!el),
        );
    }

    getHiddenColumns() {
        return this.hiddenColumns.map((hiddenColumnId) => {
            return this.listItems.find((listItem) => listItem.id === hiddenColumnId);
        });
    }

    getCheckedItems(items: ListViewItem[]): ListViewItem[] {
        return this.getNotFixedItems(items).filter((item) => item.isChecked);
    }

    getNotCheckedItems(items: ListViewItem[]): ListViewItem[] {
        return this.getNotFixedItems(items).filter((item) => !item.isChecked);
    }

    getFixedItems(items: ListViewItem[]): ListViewItem[] {
        return items.filter((item) => item.isFixed);
    }

    getNotFixedItems(items: ListViewItem[]): ListViewItem[] {
        return items.filter((item) => !item.isFixed && this.itemIsVisible(item.id) && !item.isHidden);
    }

    getHiddenByDefaultItems(items: ListViewItem[]): ListViewItem[] {
        return items.filter((item) => item.isHidden);
    }

    itemIsVisible(itemId: string): boolean {
        return this.hiddenColumns.indexOf(itemId) === -1;
    }

    handleListItemClick(listItem: ListViewItem) {
        const { isChecked } = listItem;

        if (isChecked) {
            this.trackingConnector.trackEvent('saved_view', 'column', 'removed', listItem.fieldId);
            this.hideListItem(listItem);
        } else {
            this.trackingConnector.trackEvent('saved_view', 'column', 'added', listItem.fieldId);
            this.showListItem(listItem);
        }

        this.hasUnsavedChanges = true;
        this.updateUnsavedUserSettings();
    }

    hideListItem(listItem: ListViewItem) {
        const indexInList = this.checkedListItems.findIndex((item) => item.id === listItem.id);
        const updatedListItem = {
            ...listItem,
            isChecked: false,
        };

        this.uncheckedListItems = [updatedListItem, ...this.uncheckedListItems];
        this.checkedListItems = [
            ...this.checkedListItems.slice(0, indexInList),
            ...this.checkedListItems.slice(indexInList + 1),
        ];
    }

    showListItem(listItem: ListViewItem) {
        const indexInList = this.uncheckedListItems.findIndex((item) => item.id === listItem.id);
        const updatedListItem = {
            ...listItem,
            isChecked: true,
        };

        this.checkedListItems = [...this.checkedListItems, updatedListItem];
        this.uncheckedListItems = [
            ...this.uncheckedListItems.slice(0, indexInList),
            ...this.uncheckedListItems.slice(indexInList + 1),
        ];
    }

    handleSortingOrderChange(newItems: ListViewItem[]) {
        this.checkedListItems = [...newItems];
        this.hasUnsavedChanges = true;
    }

    search(query: string): void {
        this.searchQuery = query;
        this.searchQueryChange.emit(query);
    }

    updateUnsavedUserSettings() {
        this.unsavedChecked = [...this.checkedListItems];
        this.unsavedUnchecked = [...this.uncheckedListItems];
    }

    searchResultIsEmpty(): boolean {
        return this.searchQuery !== '' && this.uncheckedListItems.length === 0;
    }

    uncheckedListIsEmpty() {
        return this.uncheckedListItems.length === 0 && !this.searchResultIsEmpty();
    }

    loadMoreVisible() {
        this.loadMore.emit();
    }
}
