import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FunctionalRole, RuumTask } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import { FunctionalRolesService } from '../../../common/connectors/functionalRoles/functionalRoles.service';
import { PaginationParams } from '../../../common/connectors/lobbyList/lobby-list.model';
import { OrderedListParams } from '../../../common/connectors/paginatedList.model';
import { SelectedProjectService } from '../../../common/connectors/project/selectedProject.service';
import {
    FunctionalRolesFilters,
    ReadModelBackendConnector,
    UserListItem,
} from '../../../common/connectors/readModelConnector.service';
import { InvolvedUsersService } from '../../../common/connectors/users/users.service';
import { FunctionalRolesListSortBy } from '../../../common/connectors/workspace/functionalRolesList/functionalRolesList.service';
import { TeamInviteDialogService } from '../../../common/team/team-invite-dialog.service';
import { HttpErrorResponseWithContext } from '../../../common/utils/request-id.interceptor';
import { ProjectRolesService } from '../../../project/project-roles.service';
import { RuumFunctionalRolesService } from '../../../ruum/canvas/sections/functionalRole/functionalRole.service';
import { RuumDropdownPlacement } from '../../ui-components/dropdown/dropdown.model';
import { RuumDropdownService } from '../../ui-components/dropdown/dropdown.service';
import {
    ProjectAccessOrderBy,
    UserProjectAccessFilters,
} from './../../../common/connectors/readModelConnector.service';
import { AllAssignees, getDrodownData } from './loadAssigneesDropdownData';

export type RoleOrUser = UserListItem | FunctionalRoleListItem;

export interface TaskAssignmentsIds {
    assignees: string[];
    assignedFunctionalRoles: string[];
}
@Component({
    selector: 'ruum-task-assignees-menu',
    template: `
        <ruum-dropdown-wrapper>
            <!-- desktop -->
            <ruum-dropdown-desktop
                #desktopDropdown
                [placement]="placement"
                [popoverClass]="'ruum-task-assignees-dropdown-desktop'"
                [isReadOnly]="isReadOnly"
                (openMenu)="openMenu()"
                [openAssigneeDropdown$]="openAssigneeDropdown$"
                (closeMenu)="closeMenu('close')"
                [stopPropagation]="false"
                container="body"
            >
                <!-- button -->
                <ng-container
                    *ngIf="staticButton; else dynamicDesktopButton"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                >
                    <ng-content select="[data-content='desktop-button']"></ng-content>
                </ng-container>

                <ng-template #dynamicDesktopButton>
                    <div class="d-flex flex-fill" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                        <ruum-task-assignees-button
                            data-content="desktop-dropdown-button"
                            [assignedUsers]="hideUsers ? [] : selectedAssignees"
                            [assignedRoles]="hideRoles ? [] : selectedFunctionalRoles"
                            [maxShownAssignees]="maxShownAssignees"
                            [showFullNameOfFirstAssignee]="maxShownAssignees !== 1"
                            [defaultButtonTheme]="defaultButtonTheme"
                            [avatarSize]="avatarSize"
                            [showAddUserIcons]="showAddUserIcons"
                        >
                        </ruum-task-assignees-button>
                    </div>
                </ng-template>

                <!-- menu -->
                <div
                    class="d-flex flex-fill flex-column"
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                >
                    <div
                        *ngIf="selectedAssignees.length > 0 || selectedFunctionalRoles.length > 0"
                        class="d-flex px-1"
                        style="max-height: 100px; overflow-y: auto;"
                        [style.max-width]="'216px'"
                    >
                        <div class="d-flex flex-wrap mt-1">
                            <ng-container *ngIf="!hideRoles">
                                <ruum-profile-tag
                                    class="mr-1 mb-1"
                                    *ngFor="let item of selectedFunctionalRoles"
                                    (click)="onSelectRole(item)"
                                >
                                    <ruum-profile-role [name]="item.name" [size]="'sm'"></ruum-profile-role>
                                </ruum-profile-tag>
                            </ng-container>

                            <ng-container *ngIf="!hideUsers">
                                <ruum-profile-tag
                                    class="mr-1 mb-1"
                                    *ngFor="let item of selectedAssignees"
                                    (click)="onSelectAssignee(item)"
                                >
                                    <ruum-profile-member
                                        [name]="item.fullName"
                                        [theme]="item.color | ruumProfileHexToTheme"
                                        [size]="'sm'"
                                    >
                                    </ruum-profile-member>
                                </ruum-profile-tag>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="selectedAssignees.length > 0" class="border-bottom border-light mb-2"></div>

                    <div class="form-group mb-1 px-1">
                        <input
                            class="form-control form-control-sm w-100"
                            #search
                            id="search-assignees"
                            name="search-assignees"
                            type="text"
                            autocomplete="off"
                            ruumInputHighlight
                            [(ngModel)]="assigneesSearch"
                            [placeholder]="'Search team members or roles'"
                            [style.min-width]="width"
                            [style.font-size.px]="14"
                            (input)="searchAssignees()"
                        />
                    </div>

                    <div *ngIf="fetching$ | async" class="d-flex px-1 justify-content-center">
                        <img alt="Loading or Busy state" src="/assets/img/spinner.svg" width="20" />
                    </div>

                    <div class="d-flex flex-fill flex-column px-1" style="max-height: 312px; overflow-y: auto;">
                        <!-- Invite User -->
                        <div
                            class="d-flex flex-column flex-fill justify-content-center py-2"
                            *ngIf="isEmptySearchResult(allAssignees) && !hideUsers"
                            [ngbTooltip]="inviteTooltip"
                            [placement]="['left', 'auto']"
                            [disableTooltip]="canInviteParticipants"
                        >
                            <div class="text-light text-tiny text-center mb-2">No users found in the Workspace.</div>
                            <div class="d-flex justify-content-center">
                                <button
                                    class="btn btn-outline-primary"
                                    type="button"
                                    data-content="desktop-dropdown-button"
                                    (click)="inviteUser()"
                                    [disabled]="!canInviteParticipants"
                                >
                                    Invite User
                                </button>
                            </div>
                        </div>
                        <ng-template #inviteTooltip>
                            <div class="text-small font-weight-bold">You are not allowed to invite users</div>
                        </ng-template>

                        <ruum-task-assignee-proposal-list
                            [hideUsers]="hideUsers"
                            [hideRoles]="hideRoles"
                            [allAssignees]="allAssignees"
                            [width]="width"
                            [workspaceId]="workspaceId"
                            [functionalRoles]="functionalRoles"
                            (onSelectAssignee)="onSelectAssignee($event)"
                            (onSelectRole)="onSelectRole($event)"
                            [assignedFunctionalRoleIds]="assignedFunctionalRoleIds"
                            [assigneeIds]="assigneeIds"
                        ></ruum-task-assignee-proposal-list>
                    </div>
                </div>
            </ruum-dropdown-desktop>

            <!-- mobile -->
            <ruum-dropdown-mobile
                #mobileDropdown
                [showCancelButton]="showCancelButton"
                [showApplyButton]="showApplyButton"
                [isReadOnly]="isReadOnly"
                (openMenu)="openMenu()"
                (closeMenu)="closeMenu($event)"
            >
                <!-- button -->

                <ng-container
                    *ngIf="staticButton; else dynamicDesktopButton"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                >
                    <ng-content select="[data-content='mobile-button']"></ng-content>
                </ng-container>

                <ng-template #dynamicMobileButton>
                    <div class="d-flex flex-fill" data-content="ruum-dropdown-button" data-test="ruum-dropdown-button">
                        <ruum-task-assignees-button
                            data-content="mobile-dropdown-button"
                            [assignedUsers]="hideUsers ? [] : selectedAssignees"
                            [assignedRoles]="hideRoles ? [] : selectedFunctionalRoles"
                            [maxShownAssignees]="maxShownAssignees"
                            [showFullNameOfFirstAssignee]="showFullNameOfFirstAssignee"
                            [defaultButtonTheme]="defaultButtonTheme"
                            [avatarSize]="'xs'"
                            [showAddUserIcons]="showAddUserIcons"
                        >
                        </ruum-task-assignees-button>
                    </div>
                </ng-template>
                <!-- menu -->
                <div
                    class="d-flex flex-fill flex-column p-1"
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                >
                    <ruum-task-assignee-proposal-list
                        [hideUsers]="hideUsers"
                        [hideRoles]="hideRoles"
                        [allAssignees]="allAssignees"
                        width="100%"
                        [workspaceId]="workspaceId"
                        [functionalRoles]="functionalRoles"
                        (onSelectAssignee)="onSelectAssignee($event)"
                        (onSelectRole)="onSelectRole($event)"
                        [assignedFunctionalRoleIds]="assignedFunctionalRoleIds"
                        [assigneeIds]="assigneeIds"
                        [size]="'lg'"
                    ></ruum-task-assignee-proposal-list>
                </div>
            </ruum-dropdown-mobile>
        </ruum-dropdown-wrapper>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssigneesMenuComponent implements OnInit, OnDestroy, OnChanges {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    @ViewChild('search', { static: false })
    search: ElementRef;

    @Input() openAssigneeDropdown$: Observable<Event> = new Observable();
    @Input() assigneeIds: string[] = [];
    @Input() staticButton = false;
    @Input() assignedFunctionalRoleIds: string[] = [];
    @Input() projectId: string;
    @Input() hideRoles = false;
    @Input() hideUsers = false;
    @Input() avatarSize: 'xs' | 'sm';
    @Input() placement: RuumDropdownPlacement = ['bottom-left', 'top-left', 'left'];
    @Input() width = '208px';
    @Input() isReadOnly = false;
    @Input() maxShownAssignees = 1;
    @Input() showFullNameOfFirstAssignee = false;
    @Input() defaultButtonTheme = 'link';
    @Input() workspaceId: string;
    @Input() isAdmin: boolean;
    @Input() canInviteParticipants = false;
    /** If assigning functional roles and users to a task. */
    @Input() task: RuumTask = null;
    /** If assigning users to a functional role. */
    @Input() functionalRole: FunctionalRole;
    @Input() showAddUserIcons = false;
    @Output() assigneesChanged = new EventEmitter<TaskAssignmentsIds>();

    initAssigneeIds: string[] = [];

    showCancelButton = true;
    showApplyButton = true;

    assigneesSearch = '';

    assigneesSearchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    taskAssignments$: BehaviorSubject<TaskAssignmentsIds> = new BehaviorSubject<TaskAssignmentsIds>({
        assignedFunctionalRoles: [],
        assignees: [],
    });
    fetching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    selectedAssignees: UserListItem[] = [];
    selectedFunctionalRoles: FunctionalRoleListItem[] = [];

    allAssignees: AllAssignees = {
        projectAssignees: [],
        otherAssignees: [],
    };
    functionalRoles: FunctionalRoleListItem[] = [];

    private subscriptions: Subscription[] = [];

    constructor(
        private usersService: InvolvedUsersService,
        private selectedProjectService: SelectedProjectService,
        private projectRolesService: ProjectRolesService,
        private functionalRoleService: FunctionalRolesService,
        private cdr: ChangeDetectorRef,
        private teamInviteDialogService: TeamInviteDialogService,
        private ruumFunctionalRoleService: RuumFunctionalRolesService,
        private ruumDropdownService: RuumDropdownService,
        private readModelBackendConnector: ReadModelBackendConnector,
        private alertService: RuumAlertService,
    ) {}

    ngOnInit() {
        this.taskAssignments$.next({
            assignedFunctionalRoles: this.assignedFunctionalRoleIds,
            assignees: this.assigneeIds,
        });
        this.initAssigneeIds = [...this.assigneeIds];

        this.subscriptions = [
            this.getSelectedAssignees().subscribe((data) => {
                this.selectedAssignees = data;
                this.cdr.detectChanges();
            }),
            this.getSelectedFunctionalRoles().subscribe((data) => {
                this.selectedFunctionalRoles = data;
                this.cdr.detectChanges();
            }),
        ];
        this.loadData();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnChanges(changes) {
        if (changes.assigneeIds) {
            this.assigneeIds = [...changes.assigneeIds.currentValue];
            this.taskAssignments$.next({
                assignees: this.assigneeIds,
                assignedFunctionalRoles: this.assignedFunctionalRoleIds,
            });
        }
        if (changes.assignedFunctionalRoleIds && changes.assignedFunctionalRoleIds.currentValue) {
            this.assignedFunctionalRoleIds = [...changes.assignedFunctionalRoleIds.currentValue];
            this.taskAssignments$.next({
                assignees: this.assigneeIds,
                assignedFunctionalRoles: this.assignedFunctionalRoleIds,
            });
        }
    }

    getProjectAccess(
        pagination: PaginationParams,
        projectId: string,
        filters: UserProjectAccessFilters,
        orderBy?: OrderedListParams<ProjectAccessOrderBy>,
    ) {
        return this.readModelBackendConnector.getProjectAccess(pagination, projectId, filters, orderBy);
    }

    getFunctionalRoles(
        pagination: PaginationParams,
        filters?: FunctionalRolesFilters,
        orderBy?: OrderedListParams<FunctionalRolesListSortBy>,
    ) {
        return this.readModelBackendConnector.getFunctionalRoles(pagination, filters, orderBy);
    }

    warning(message: string, error: Error | HttpErrorResponseWithContext) {
        this.alertService.warning(message, error);
    }

    isEmptySearchResult(assignees: AllAssignees) {
        return assignees.otherAssignees.length === 0 && assignees.projectAssignees.length === 0;
    }

    private toggleSelection(currentSelection: string[] = [], newId: string): string[] {
        const isSelected = currentSelection.some((item) => item === newId);
        if (isSelected) {
            return currentSelection.filter((item) => item !== newId);
        } else {
            return currentSelection.concat(newId);
        }
    }

    onSelectAssignee(assignee: UserListItem) {
        this.assigneeIds = this.toggleSelection(this.assigneeIds, assignee.id);
        this.emitChangeAssignees({
            assignees: this.assigneeIds,
            assignedFunctionalRoles: this.assignedFunctionalRoleIds,
        });
    }

    onSelectRole(role: RoleOrUser) {
        this.assignedFunctionalRoleIds = this.toggleSelection(this.assignedFunctionalRoleIds, role.id);
        this.assignedFunctionalRoleIds.forEach((id) => this.ruumFunctionalRoleService.addRoleToProjectIfNotExists(id));
        this.emitChangeAssignees({
            assignees: this.assigneeIds,
            assignedFunctionalRoles: this.assignedFunctionalRoleIds,
        });
    }

    emitChangeAssignees(data) {
        this.assigneesChanged.emit(data);
        this.taskAssignments$.next(data);
    }

    openMenu() {
        this.taskAssignments$.next({
            assignees: this.assigneeIds,
            assignedFunctionalRoles: this.assignedFunctionalRoleIds,
        });
        this.assigneesSearchQuery$.next('');
        this.assigneesSearch = '';
        this.fetching$.next(true);
        setTimeout(() => this.search.nativeElement.focus(), 200);
    }

    closeMenu(event: 'close' | 'dismiss') {
        if (event === 'close' || event === 'dismiss') {
            this.emitChangeAssignees({
                assignees: this.assigneeIds,
                assignedFunctionalRoles: this.assignedFunctionalRoleIds,
            });
        }
    }

    searchAssignees() {
        this.assigneesSearchQuery$.next(this.assigneesSearch);
        this.fetching$.next(true);
    }

    clearAssigneesSearch() {
        this.assigneesSearch = '';
        this.assigneesSearchQuery$.next(this.assigneesSearch);
    }

    private getSelectedAssignees(): Observable<UserListItem[]> {
        return combineLatest([this.taskAssignments$]).pipe(
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap(([{ assignees }]) => {
                if (assignees.length) {
                    return this.usersService.dataList(assignees);
                } else {
                    return of([]);
                }
            }),
        );
    }

    private getSelectedFunctionalRoles(): Observable<FunctionalRoleListItem[]> {
        return combineLatest([this.taskAssignments$]).pipe(
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap(([{ assignedFunctionalRoles }]) => {
                if (assignedFunctionalRoles && assignedFunctionalRoles.length) {
                    return this.functionalRoleService.dataList(assignedFunctionalRoles);
                } else {
                    return of([]);
                }
            }),
            startWith([]),
        );
    }

    private loadData() {
        const subs = getDrodownData(this).subscribe(([allAssignees, roles]) => {
            this.allAssignees = allAssignees;
            this.functionalRoles = roles;
            this.fetching$.next(false);
            this.cdr.detectChanges();
        });
        this.subscriptions.push(subs);
    }

    inviteUser() {
        this.ruumDropdownService.closeAllPopovers();
        const project = this.selectedProjectService.getSelectedProject();
        const permissionRole = this.selectedProjectService.getLoggedUserParticipant().roles[0];
        const allowedRoles = this.projectRolesService.getRolesByParticipantRole(permissionRole);
        this.teamInviteDialogService
            .inviteProjectMembers(project, allowedRoles, [this.assigneesSearch], this.functionalRole, this.task)
            .catch(() => {});
    }
}
