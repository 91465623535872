import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'data-loss-dialog',
    template: `
        <div class="modal-header">
            <h1 class="modal-title">Unsaved changes</h1>
        </div>
        <div class="modal-body">
            Are you sure you want to leave this screen? There are unsaved changes that will be lost.
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="reject()">Stay</button>
            <button type="button" class="btn btn-danger" (click)="accept()">Leave</button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLossDialogComponent {
    constructor(private activeModal: NgbActiveModal) {}

    accept() {
        this.activeModal.close(true);
    }
    reject() {
        this.activeModal.close(false);
    }
}
