import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatepickerDatePipe } from './datepicker-day.pipe';
import { DatePickerPopupComponent } from './datepicker-popup.component';
import { DatepickerComponent } from './datepicker.component';
import { MyDatePickerModule } from './my-date-picker';

@NgModule({
    declarations: [DatepickerDatePipe, DatepickerComponent, DatePickerPopupComponent],
    imports: [CommonModule, FormsModule, MyDatePickerModule],
    exports: [DatepickerDatePipe, DatepickerComponent, DatePickerPopupComponent],
})
export class DatepickerModule {}
