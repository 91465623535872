import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ruum-admin-dashboard',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100 py-2 py-lg-5">
                <dashboard-usage-info-widget></dashboard-usage-info-widget>
                <dashboard-api-info-widget></dashboard-api-info-widget>
            </div>
        </ruum-max-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminDashboardComponent {
    @HostBinding('class') hostClassList =
        'd-flex flex-column w-100 mw-100 h-100 mh-100 px-2 px-sm-4 px-md-5 px-lg-8 overflow-y';
}
