import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { componentHelper } from '../ui-components.helper';

@Component({
    selector: 'ruum-illustration-comments',
    template: `
        <img [alt]="alt" src="assets/illustrations/comments.svg" [width]="svgWidth" [height]="svgHeight" />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationCommentsComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex align-items-center', this.componentClass]);
    }

    @Input() alt = 'Comments';

    @Input() width = 285;

    @Input() componentClass = '';

    get svgWidth() {
        return (this.width / this.initialWidth) * this.initialWidth;
    }

    get svgHeight() {
        return (this.width / this.initialWidth) * this.initialHeight;
    }

    private initialWidth = 285;

    private initialHeight = 269;

    constructor() {}

    ngOnInit() {}
}
