import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TaskPriority, TaskStatus } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderedListParams } from '../../common/connectors/paginatedList.model';
import { SavedViewColumn, SavedViewColumnValue } from '../../common/connectors/savedView/saved-views.model';
import { TaskListItem, TaskListOrderBy } from '../../common/connectors/tasks/task-list.model';
import { ExtendedTask } from '../../common/task-list/task-list-container.component';
import { TableDirectiveService } from '../ui-components/table/table.directive.service';

interface IndentedTask extends TaskListItem {
    indentation?: number;
}
@Component({
    selector: 'ruum-task-list',
    template: `
        <cdk-table [dataSource]="rows">
            <!-- Status Column -->
            <ng-container cdkColumnDef="standard_field_task_list_status">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_task_list_status">
                    <sortable-column
                        class="pl-1"
                        name="Status"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'status'"
                        (sort)="handleSorting('status')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_task_list_status">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="d-flex pr-4 mr-4">
                            <ruum-task-status-menu
                                *ngIf="!element.isMilestone"
                                [statusId]="element.status"
                                [disabled]="isReadOnly"
                                (statusChanged)="onChangeStatus($event, element)"
                            >
                            </ruum-task-status-menu>

                            <ruum-milestone-status-menu
                                *ngIf="element.isMilestone"
                                [statusId]="element.status"
                                [dueDate]="element.dueDate"
                                [disabled]="isReadOnly"
                                (statusChanged)="onChangeStatus($event, element)"
                            >
                            </ruum-milestone-status-menu>
                        </div>
                    </div>
                </cdk-cell>
            </ng-container>

            <!-- Comments Column -->
            <ng-container cdkColumnDef="standard_field_comments">
                <cdk-header-cell *cdkHeaderCellDef id="standard_field_comments"></cdk-header-cell>
                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="ruum-lobby-list-item-chat">
                    <ruum-task-list-cell-comment
                        [data]="element"
                        (commentsClicked)="handleCommentsClick($event)"
                    ></ruum-task-list-cell-comment>
                </cdk-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container cdkColumnDef="standard_field_task_list_name">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_task_list_name">
                    <sortable-column
                        class="pl-1"
                        name="Name"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'description'"
                        (sort)="handleSorting('description')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell
                    *cdkCellDef="let element; let idx = index"
                    class="d-flex bg-white"
                    data-test="standard_field_task_list_name"
                >
                    <ruum-task-list-cell-description
                        [data]="element"
                        [indentation]="getIndentation(element)"
                        (showOnCanvas)="handleShowOnCanvas($event)"
                        [hoveredRowIndex]="hoveredRowIndex$ | async"
                        [index]="idx"
                    ></ruum-task-list-cell-description>
                </cdk-cell>
            </ng-container>

            <!-- Subtasks Column -->
            <ng-container cdkColumnDef="standard_field_task_list_subtasks">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_task_list_subtasks">
                    <ruum-header-cell-unsortable name="Subtasks"></ruum-header-cell-unsortable>
                </cdk-header-cell>

                <cdk-cell
                    *cdkCellDef="let element"
                    class="d-flex bg-white"
                    data-test="standard_field_task_list_subtasks"
                >
                    <ruum-task-list-cell-subtasks
                        [data]="element"
                        [showingSubtasks]="subtasksAreVisible"
                        (subtasksVisibilityToggled)="handleToggleSubtasksVisibility($event)"
                    ></ruum-task-list-cell-subtasks>
                </cdk-cell>
            </ng-container>

            <!-- Priority Column -->
            <ng-container cdkColumnDef="standard_field_priority">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_priority">
                    <sortable-column
                        class="pl-1"
                        name="Priority"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'priority'"
                        (sort)="handleSorting('priority')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_priority">
                    <div class="ruum-task-priority d-flex justify-content-center align-items-center">
                        <div class="d-flex pr-4 mr-4" *ngIf="!element.isMilestone">
                            <ruum-task-list-cell-priority
                                [isReadOnly]="isReadOnly"
                                [id]="element.priority"
                                (priorityChanged)="onChangePriority($event, element)"
                            >
                            </ruum-task-list-cell-priority>
                        </div>
                    </div>
                </cdk-cell>
            </ng-container>

            <!-- Group Column -->
            <ng-container cdkColumnDef="standard_field_group">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_group">
                    <ruum-header-cell-unsortable name="Group"></ruum-header-cell-unsortable>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_group">
                    <ruum-task-list-cell-group [data]="element"></ruum-task-list-cell-group>
                </cdk-cell>
            </ng-container>

            <!-- Project Column -->
            <ng-container cdkColumnDef="standard_field_project">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_project">
                    <sortable-column
                        class="pl-1"
                        name="Ruum"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'project'"
                        (sort)="handleSorting('project')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_project">
                    <ruum-task-list-cell-project [data]="element"></ruum-task-list-cell-project>
                </cdk-cell>
            </ng-container>

            <!-- Section Column -->
            <ng-container cdkColumnDef="standard_field_section">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_section">
                    <sortable-column
                        class="pl-1"
                        name="Section"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'section'"
                        (sort)="handleSorting('section')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_section">
                    <ruum-task-list-cell-section [data]="element"></ruum-task-list-cell-section>
                </cdk-cell>
            </ng-container>

            <!-- Start date Column -->
            <ng-container cdkColumnDef="standard_field_startdate">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_startdate">
                    <sortable-column
                        class="pl-1"
                        name="Start date"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'startDate'"
                        (sort)="handleSorting('startDate')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_startdate">
                    <ruum-task-list-cell-start-date [data]="element"></ruum-task-list-cell-start-date>
                </cdk-cell>
            </ng-container>

            <!-- Due date Column -->
            <ng-container cdkColumnDef="standard_field_duedate">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_duedate">
                    <sortable-column
                        class="pl-1"
                        name="Due date"
                        [direction]="orderedBy.direction"
                        [sorting]="orderedBy.by === 'dueDate'"
                        (sort)="handleSorting('dueDate')"
                    >
                    </sortable-column>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_duedate">
                    <ruum-task-list-cell-due-date [data]="element"></ruum-task-list-cell-due-date>
                </cdk-cell>
            </ng-container>

            <!-- Assignees Column -->
            <ng-container cdkColumnDef="standard_field_assignees">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_assignees">
                    <ruum-header-cell-unsortable name="Assignees"></ruum-header-cell-unsortable>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_assignees">
                    <ruum-task-list-cell-assignees [data]="element"></ruum-task-list-cell-assignees>
                </cdk-cell>
            </ng-container>

            <!-- Roles Column -->
            <ng-container cdkColumnDef="standard_field_roles">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_roles">
                    <ruum-header-cell-unsortable name="Roles"></ruum-header-cell-unsortable>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex bg-white" data-test="standard_field_roles">
                    <ruum-task-list-cell-assigned-functional-roles
                        [data]="element"
                    ></ruum-task-list-cell-assigned-functional-roles>
                </cdk-cell>
            </ng-container>

            <!-- Workspaces Column -->
            <ng-container cdkColumnDef="standard_field_workspace">
                <cdk-header-cell *cdkHeaderCellDef ruumResizableColumn id="standard_field_workspace">
                    <button class="btn btn-xs btn-link-light btn-without-hover px-0">
                        <h5>Workspace</h5>
                    </button>
                </cdk-header-cell>

                <cdk-cell *cdkCellDef="let element" class="d-flex align-items-center bg-white">
                    <div class="small text-dark text-truncate pr-4">{{ element.workspaceName }}</div>
                </cdk-cell>
            </ng-container>

            <!-- Contextual Menu Column -->
            <ng-container cdkColumnDef="contextual-menu">
                <cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>
                <cdk-cell
                    *cdkCellDef="let element"
                    class="d-flex bg-white"
                    [style.minWidth.px]="32"
                    data-test="contextual-menu"
                >
                    <ruum-task-list-item-options
                        *ngIf="!isReadOnly"
                        [isMilestone]="element.isMilestone"
                        (deleteTask)="onDeleteTask(element)"
                    >
                    </ruum-task-list-item-options>
                </cdk-cell>
            </ng-container>

            <!-- Custom Field Columns -->
            <ng-container *ngFor="let customFieldColumn of customFieldColumns">
                <ng-container [cdkColumnDef]="customFieldColumn.fieldId">
                    <cdk-header-cell
                        *cdkHeaderCellDef
                        ruumResizableColumn
                        class="d-flex flex-fill minw-0"
                        [attr.id]="customFieldColumn.fieldId"
                        [id]="customFieldColumn.fieldId"
                    >
                        <button class="btn btn-xs btn-link-light btn-without-hover px-0">
                            <h5 class="text-truncate">{{ customFieldColumn.title || '' }}</h5>
                        </button>
                    </cdk-header-cell>

                    <cdk-cell *cdkCellDef="let element" class="py-1 px-2 text-small text-dark text-break-all bg-white">
                        <ruum-task-list-item-custom-field
                            [column]="customFieldColumn"
                            [taskListItem]="element"
                        ></ruum-task-list-item-custom-field>
                    </cdk-cell>
                </ng-container>
            </ng-container>

            <cdk-header-row
                *cdkHeaderRowDef="displayedColumns"
                class="d-inline-flex mx-lg-8"
                ruumResizableRow
                ruumRowCellWidth
                [columns]="columns"
                [displayedColumnsIds]="displayedColumns"
                (columnsResized)="handleColumnsResize($event)"
            ></cdk-header-row>
            <cdk-row
                *cdkRowDef="let row; columns: displayedColumns; let idx = index"
                class="d-inline-flex mx-lg-8 hov"
                [class.active]="isActive(row)"
                (click)="itemClick($event, row)"
                ruumRowCellWidth
                [columns]="columns"
                [displayedColumnsIds]="displayedColumns"
                ruumHoveredRowTracked
                [rowIndex]="idx"
            ></cdk-row>
        </cdk-table>

        <ruum-load-more
            *ngIf="loaderIsVisible()"
            [scrollElement]="scrollElement"
            (loadMore)="loadMoreVisible()"
        ></ruum-load-more>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            cdk-header-cell,
            cdk-header-cell:first-child,
            cdk-cell {
                padding-left: 12px;
            }
            cdk-cell:first-child {
                padding-left: 12px;
            }
            cdk-header-cell:last-child {
                padding-right: 12px;
            }
            cdk-cell:last-child {
                border-right: 1px solid rgba(50, 54, 58, 0.08);
                padding-right: 12px;
            }

            cdk-cell {
                border-bottom: 1px solid rgba(50, 54, 58, 0.08);
                vertical-align: top;
            }

            cdk-row {
                height: 40px;
                cursor: pointer;
            }
        `,
    ],
})
export class TaskListComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-column flex-fill overflow-x';

    @Input()
    set columns(value: SavedViewColumn[]) {
        if (value) {
            this._columns$.next(value);
        }
    }
    get columns(): SavedViewColumn[] {
        return this._columns$.getValue();
    }
    @Input()
    set rows(value: TaskListItem[]) {
        this._dataSource$.next(value);
    }
    get rows(): TaskListItem[] {
        return this._dataSource$.getValue();
    }
    @Input() activeId = '';
    @Input() orderedBy: OrderedListParams<TaskListOrderBy> = {};
    @Input() subtasksVisibilityByTask = {};
    @Input() isReadOnly = false;
    @Input() showLoading = false;

    @Output() orderChanged = new EventEmitter<string>();
    @Output() changeStatus = new EventEmitter<{
        task: ExtendedTask;
        status: TaskStatus;
    }>();
    @Output() changePriority = new EventEmitter<{
        task: ExtendedTask;
        priority: TaskPriority;
    }>();
    @Output() showOnCanvas = new EventEmitter<{ projectId: string; taskId: string }>();
    @Output() toggleSubtasksVisibility = new EventEmitter<{ subtasksAreVisible: boolean; task: TaskListItem }>();
    @Output() commentClicked = new EventEmitter<ExtendedTask>();
    @Output() taskDeleted = new EventEmitter<ExtendedTask>();
    @Output() columnWidthChanged = new EventEmitter<SavedViewColumnValue[]>();
    @Output() itemClicked = new EventEmitter<TaskListItem>();
    @Output() loadMore = new EventEmitter<void>();

    private _columns$ = new BehaviorSubject<SavedViewColumn[]>([]);
    private _dataSource$ = new BehaviorSubject<TaskListItem[]>([]);
    hoveredRowIndex$: Observable<number>;

    constructor(private elementRef: ElementRef, private tableDirectiveService: TableDirectiveService) {}

    ngOnInit() {
        this.hoveredRowIndex$ = this.tableDirectiveService.getHoveredRowIndex$();
    }

    get displayedColumns() {
        const ids = this.columns.map((column) => column.fieldId);

        if (ids.length > 0) {
            return [...ids, 'contextual-menu'];
        }
        return [];
    }

    get customFieldColumns(): SavedViewColumn[] {
        return this.columns.filter((column) => !!column.fieldId.match('custom_field_'));
    }

    get scrollElement() {
        return this.elementRef.nativeElement;
    }

    isActive(row: TaskListItem): boolean {
        return this.activeId === row.id;
    }

    handleSorting(sortingKey: string) {
        this.orderChanged.emit(sortingKey);
    }

    onChangeStatus(status: TaskStatus, task: ExtendedTask) {
        this.changeStatus.emit({
            task,
            status,
        });
    }

    onChangePriority(priority: TaskPriority, task: ExtendedTask): void {
        this.changePriority.emit({
            task,
            priority,
        });
    }

    getIndentation(task: IndentedTask): number {
        return task.indentation || 0;
    }

    handleShowOnCanvas(task: ExtendedTask): void {
        const resultTask = this.parseTask(task);

        this.showOnCanvas.emit({
            projectId: resultTask.projectId,
            taskId: resultTask.parent || resultTask.id,
        });
    }

    parseTask(task: ExtendedTask): IndentedTask {
        const resultTask = {
            ...task,
            id: task.taskId,
        };

        delete resultTask.taskId;

        return resultTask;
    }

    subtasksAreVisible = (task: ExtendedTask): boolean => {
        return this.subtasksVisibilityByTask[task.id] || false;
    };

    handleToggleSubtasksVisibility($event) {
        this.toggleSubtasksVisibility.emit($event);
    }

    handleCommentsClick(task: ExtendedTask) {
        this.commentClicked.emit(task);
    }

    onDeleteTask(task: ExtendedTask) {
        this.taskDeleted.emit(task);
    }

    handleColumnsResize(resizedColumns: SavedViewColumnValue[]): void {
        const newColumns = this.mergeColumnsWithResized(resizedColumns);

        this.columnWidthChanged.emit(newColumns);
    }

    mergeColumnsWithResized(resizedColumns: SavedViewColumnValue[]): SavedViewColumnValue[] {
        return this.columns.map((column) => {
            const resized = resizedColumns.find((resizedColumn) => resizedColumn.fieldId === column.fieldId);

            if (resized) {
                return resized;
            }
            return {
                fieldId: column.fieldId,
                width: column.width,
            };
        });
    }

    itemClick(event: Event, task: TaskListItem): void {
        event.stopPropagation();

        this.itemClicked.emit(task);
    }

    loaderIsVisible() {
        return this.showLoading && this.columns.length > 0;
    }

    loadMoreVisible() {
        this.loadMore.emit();
    }
}
