import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipeModule } from '../../../common/pipe/date-format.pipe';
import { TimeAgoPipeModule } from '../../../common/pipe/time-ago.pipe';
import { DatepickerModule } from '../../ui-components/datepicker/datepicker.module';
import { TaskDatesDividerComponent } from './task-dates-divider.component';
import { TaskDueDateComponent } from './task-due-date.component';
import { TaskStartDateComponent } from './task-start-date.component';

@NgModule({
    declarations: [TaskStartDateComponent, TaskDueDateComponent, TaskDatesDividerComponent],
    imports: [CommonModule, DatepickerModule, DateFormatPipeModule, TimeAgoPipeModule],
    exports: [TaskStartDateComponent, TaskDueDateComponent, TaskDatesDividerComponent],
})
export class TaskDatesModule {}
