import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BreakpointService } from '../../../common/breakpoint.service';

export interface ColumnValue {
    fieldId: string;
    width: number;
}

export interface Column extends ColumnValue {
    minWidth: number;
    unresizable?: boolean;
}

const MIN_TABLE_WIDTH = {
    md: 1280,
    lg: 2000,
};

@Injectable({
    providedIn: 'root',
})
export class TableDirectiveService implements OnDestroy {
    private hoveredColumnId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private currentResizeId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private hoveredRowIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private currentClickId: string; // currentResizeId can be the left sibling of clickId
    private resizeStartPageX: number;
    private subscriptions: Subscription[] = [];

    isXXLargeBreakpoint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private breakpointService: BreakpointService) {
        const breakpointSubscription = this.breakpointService.isXXLargeBreakpoint().subscribe((isXXLargeBreakpoint) => {
            this.isXXLargeBreakpoint$.next(isXXLargeBreakpoint);
        });

        this.subscriptions.push(breakpointSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    getHoveredColumnId$(): Observable<string> {
        return this.hoveredColumnId$;
    }

    getHoveredColumnId(): string {
        return this.hoveredColumnId$.getValue();
    }

    setHoveredColumnId(id: string) {
        this.hoveredColumnId$.next(id);
    }

    getHoveredRowIndex$(): Observable<number> {
        return this.hoveredRowIndex$;
    }

    setHoveredRowIndex(rowIndex: number) {
        this.hoveredRowIndex$.next(rowIndex);
    }

    getCurrentResizeId$(): Observable<string> {
        return this.currentResizeId$;
    }

    getCurrentResizeId(): string {
        return this.currentResizeId$.getValue();
    }

    setCurrentResizeId(id: string): void {
        this.currentResizeId$.next(id);
    }

    getResizePageX(): number {
        return this.resizeStartPageX;
    }

    setResizePageX(pageX: number): void {
        this.resizeStartPageX = pageX;
    }

    getCurrentClickId(): string {
        return this.currentClickId;
    }

    setCurrentClickId(columnId: string): void {
        this.currentClickId = columnId;
    }

    getMinTableWidth(): number {
        const isXXLargeBreakpoint = this.isXXLargeBreakpoint$.getValue();

        return isXXLargeBreakpoint ? MIN_TABLE_WIDTH.lg : MIN_TABLE_WIDTH.md;
    }

    columnsWidthIsLessThanMinTableWidth(columns: Column[]): boolean {
        const columnsWidths = columns.reduce(
            (sumWidth, column) => (column.width ? sumWidth + column.width : sumWidth),
            0,
        );

        return columnsWidths < this.getMinTableWidth();
    }
}
