import { RuumAction } from '@ruum/ruum-reducers';
import { ACTION_TYPES } from './actions';
import { BusyState, CommonState } from './common.model';

const defaultState: CommonState = {
    busy: {},
};

export function CommonStateReducer(currentState: CommonState = defaultState, action: RuumAction): CommonState {
    let newState: CommonState;

    switch (action.type) {
        case ACTION_TYPES.SET_BUSY:
            const componentId: string = action.payload.componentId || 'application';
            return Object.assign({}, currentState, {
                busy: setBusy(currentState.busy, componentId, action.payload.busy),
            });
        case ACTION_TYPES.SET_OFFLINE:
            return {
                ...currentState,
                ...{ offline: action.payload.offline },
                ...{ busy: action.payload.offline ? {} : currentState.busy },
            };
        case ACTION_TYPES.SET_IE_OR_EDGE: {
            return {
                ...currentState,
                ...{ isIE: action.payload.isIE, isEdge: action.payload.isEdge },
            };
        }
        case ACTION_TYPES.SET_MS_TEAMS_STAGE: {
            return {
                ...currentState,
                ...{ msTeamsStage: action.payload.stage },
            };
        }
        case ACTION_TYPES.SET_SIMPLIFIED_VIEW: {
            return {
                ...currentState,
                ...{ simplifiedView: action.payload.simplifiedView },
            };
        }
        default:
            newState = currentState;
    }

    return newState;
}

function setBusy(busyState: BusyState, componentId: string, busy: boolean) {
    if (busyState[componentId] === busy) {
        return busyState;
    } else {
        const busyStateChange = {};
        busyStateChange[componentId] = busy;
        return Object.assign({}, busyState, busyStateChange);
    }
}
