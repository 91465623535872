import { EntityAction, NewActivity, RuumAction } from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../paginatedList.model';

export const ACTIVITY_LIST_ACTION_TYPES = {
    ACTIVITY_HISTORY_LOADED: 'ACTIVITY_HISTORY_LOADED',
};

export type ActivityHistoryLoadedAction = EntityAction<
    'ACTIVITY_HISTORY_LOADED',
    {
        page: PaginatedList<NewActivity>;
    }
>;

export function ActivityHistoryListReducer(
    currentState: PaginatedList<NewActivity> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<NewActivity> {
    switch (action.type) {
        case ACTIVITY_LIST_ACTION_TYPES.ACTIVITY_HISTORY_LOADED:
            return listLoaded(currentState, action.payload.page);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<NewActivity>,
    data: PaginatedList<NewActivity>,
): PaginatedList<NewActivity> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: [...currentState.rows, ...data.rows],
        };
    } else {
        return {
            ...data,
            rows: [...data.rows],
        };
    }
}
