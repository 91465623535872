import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type RuumAlertTheme = 'primary' | 'secondary' | 'info' | 'danger';

@Component({
    selector: 'ruum-alert',
    template: `
        <div class="modal-body">
            <div class="m-2">
                <h2 class="mb-5">{{ title }}</h2>
                <div id="description" *ngFor="let p of description" class="text-dark pb-2">
                    {{ p }}
                </div>
                <div class="d-flex justify-content-end mt-6">
                    <button
                        *ngIf="showCancel"
                        class="btn btn-outline-secondary mr-2"
                        (click)="dismiss($event)"
                        (keydown.space)="dismiss($event)"
                        (keydown.enter)="dismiss($event)"
                        [attr.aria-label]="ariaLabel(cancelText)"
                    >
                        {{ cancelText }}
                    </button>
                    <button
                        class="btn"
                        type="button"
                        [class.btn-primary]="theme === 'primary'"
                        [class.btn-secondary]="theme === 'secondary'"
                        [class.btn-info]="theme === 'info'"
                        [class.btn-danger]="theme === 'danger'"
                        (click)="close($event)"
                        [attr.aria-label]="ariaLabel(buttonText)"
                        ngbAutofocus
                    >
                        {{ buttonText }}
                    </button>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            #description {
                word-break: break-all;
            }
        `,
    ],
})
export class RuumAlertComponent implements OnInit {
    @Input() title = '';

    @Input() description: string[] = [];

    @Input() buttonText = '';

    @Input() theme: RuumAlertTheme = 'primary';

    @Input() showCancel = true;

    @Input() cancelText = 'Cancel';

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {}

    ariaLabel(buttonText: string) {
        return [this.title, ...this.description, '.', buttonText].join(' ');
    }

    dismiss(event: Event) {
        event.preventDefault();
        this.activeModal.dismiss();
    }

    close(event: Event) {
        event.preventDefault();
        this.activeModal.close(true);
    }
}
