import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Directive({
    selector: '[scrollDetectionDirective]',
})
export class ScrollDetectionDirective implements OnDestroy {
    @Output() scrolledToBottom: EventEmitter<void> = new EventEmitter();

    private scrollEvent$;

    constructor(private el: ElementRef) {
        this.scrollEvent$ = fromEvent(this.el.nativeElement, 'scroll', { passive: true })
            .pipe(
                filter((event: any) => {
                    return event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight - 10;
                }),
                distinctUntilChanged(),
                filter((result) => result),
            )
            .subscribe((e: any) => {
                this.scrolledToBottom.emit();
            });
    }

    ngOnDestroy() {
        this.scrollEvent$.unsubscribe();
    }
}
