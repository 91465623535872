import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { MenuService } from '../menu.service';

@Component({
    selector: 'ruum-menu-option',
    template: `
        <button
            (click)="onMenuClick($event)"
            class="d-block btn btn-link-secondary btn-sm w-100 pl-2 pr-0 mx-0 py-0 text-left border-0 no-hover"
            [ngClass]="theme ? 'text-' + theme : null"
            [attr.disabled]="disabled ? true : null"
        >
            <div class="d-flex align-items-center w-100">
                <div class="flex-fill text-truncate">
                    <ng-content></ng-content>
                </div>
                <div *ngIf="optionsWithIcon$ | async" class="icon-container btn btn-icon">
                    <i *ngIf="icon" class="icon" [ngClass]="icon"></i>
                </div>
            </div>
            <ng-content select="ruum-menu-option-description"></ng-content>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuOptionComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-block pl-0 mx-2';

    @Input()
    disabled = false;

    @Input() theme: string;
    @Input() icon: string;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick: EventEmitter<any> = new EventEmitter();

    optionsWithIcon$: Observable<boolean>;

    constructor(private menuService: MenuService) {
        this.optionsWithIcon$ = menuService.optionsWithIcon$;
    }

    ngOnInit(): void {
        if (this.icon) {
            this.menuService.addOptionsWithIcon();
        }
    }

    ngOnDestroy(): void {
        if (this.icon) {
            this.menuService.removeOptionsWithIcon();
        }
    }

    onMenuClick(event: MouseEvent) {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        this.menuService.onClick(event);
        this.onClick.emit(event);
    }
}
