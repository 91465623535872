import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MOBILE_WIDTH_THRESHOLD } from './../../constants/other';
import { DailyReportEntry, EnterpriseReport, IntegrationAccountInfo } from './../dashboard.model';
import { DashboardService } from './../dashboard.service';

@Component({
    selector: 'api-usage-graph',
    template: `
        <div class="api-tooltip"></div>
        <svg class="api-svg" [style.width.px]="width" [style.height.px]="height">
            <text class="api-empty" fill="#abaeaf" x="40%" y="45%" style="font-size: 18px; opacity: 1;">
                No data available
            </text>
        </svg>
    `,
    styles: [
        `
            .api-tooltip {
                opacity: 0;
                background-color: #ffffff;
                text-align: center;
                position: absolute;
                padding: 24px;
                min-width: 80px;
                box-shadow: 3px 3px 14px 0 rgba(181, 181, 181, 0.5);
                color: #173145;
                font-size: 20px;
                border-radius: 8px;
                pointer-events: none;
            }
        `,
    ],
})
export class DashboardAPIUsageGraphComponent implements OnInit, OnChanges, OnDestroy {
    @Input() days: number;
    svg: any;
    usageData$: Observable<EnterpriseReport>;
    highlighting = false;
    resizeHandler: () => void;
    height = 500;
    width = 800;

    constructor(private dashboardService: DashboardService) {
        this.usageData$ = this.dashboardService.getReport();
    }

    ngOnInit() {
        this.usageData$.subscribe((reportData: EnterpriseReport) => {
            if (!reportData) {
                return this.cleanSVG();
            }
            this.initSVG(this.transformData(reportData));
        });
        this.initSizes();
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    initSizes() {
        const sidebarWidth: number = window.innerWidth < MOBILE_WIDTH_THRESHOLD ? 130 : 332;
        this.resizeHandler = () => {
            const sidebarWidth2: number = window.innerWidth < MOBILE_WIDTH_THRESHOLD ? 130 : 332;
            const newWidth: number = Math.max(332, Math.min(window.innerWidth - sidebarWidth2, 800));
            if (this.width !== newWidth) {
                this.width = newWidth;
                this.ngOnChanges();
            }
        };
        this.width = Math.max(300, Math.min(window.innerWidth - sidebarWidth, 800));
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    }

    transformData(reportData: EnterpriseReport): RuumCreationDataEntry[] {
        if (!reportData || !Object.keys(reportData).length) {
            return [];
        }
        return reportData.entries
            .slice(0, Number(this.days))
            .reduce((acc: RuumCreationDataEntry[], curr: DailyReportEntry) => {
                Object.keys(curr.ruumCreations).forEach((integrationId) => {
                    let integrationIdx = acc.findIndex((entry) => entry.integrationId === integrationId);
                    if (integrationIdx === -1) {
                        integrationIdx = acc.length;
                        const integrationAccountInfo: IntegrationAccountInfo = reportData.integrationAccounts.find(
                            (accountInfo) => accountInfo.name === integrationId,
                        );
                        acc[integrationIdx] = {
                            integrationId,
                            integrationShortName: integrationAccountInfo ? integrationAccountInfo.shortName : '',
                            integrationFullName: integrationAccountInfo ? integrationAccountInfo.fullName : '',
                            ruumCreationCount: 0,
                        };
                    }
                    acc[integrationIdx].ruumCreationCount += curr.ruumCreations[integrationId];
                });
                return acc;
            }, []);
    }

    ngOnChanges() {
        if (this.usageData$) {
            this.usageData$.pipe(take(1)).subscribe((usageData) => this.initSVG(this.transformData(usageData)));
        }
    }

    cleanSVG() {
        if (this.svg) {
            this.svg.selectAll('*:not(.api-empty)').remove();
        }
    }

    showNoData(show: boolean) {
        d3.select('.api-empty').style('opacity', show ? 1 : 0);
    }

    initSVG(usageData: RuumCreationDataEntry[]) {
        this.svg = d3.select('.api-svg');
        this.cleanSVG();
        if (!usageData || !usageData.length) {
            return;
        }
        if (usageData.reduce((acc, prev) => acc + prev.ruumCreationCount, 0) === 0) {
            this.showNoData(true);
            return;
        } else {
            this.showNoData(false);
        }
        const margin = { top: 15, right: 45, bottom: 30, left: 25 };
        const innerWidth = this.width - margin.left - margin.right;
        const innerHeight = this.height - margin.top - margin.bottom;
        const basis = this.svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
        const x = d3
            .scaleBand()
            .domain(usageData.map((d) => d.integrationId))
            .rangeRound([0, innerWidth])
            .paddingInner(0.1);
        const y = d3
            .scaleLinear()
            .domain([0, d3.max(usageData, (d) => getClosestValue(d.ruumCreationCount))])
            .rangeRound([innerHeight, 0]);
        const color = d3
            .scaleLinear<string>()
            .domain([0, usageData.length - 1])
            .range(['#173145', '#9CD5DD']);

        const yAxis = basis
            .append('g')
            .call(
                d3
                    .axisLeft(y)
                    .ticks(5)
                    .tickSizeInner(0),
            )
            .attr('transform', `translate(${innerWidth + margin.left + 20}, 0)`);
        yAxis.select('.domain').remove();
        yAxis.selectAll('.tick text').attr('fill', '#abaeaf');

        const gridlines = this.svg
            .append('g')
            .call(
                d3
                    .axisLeft(y)
                    .ticks(5)
                    .tickSize(-innerWidth)
                    .tickFormat(undefined),
            )
            .attr('transform', `translate(${margin.left}, ${margin.top})`);
        gridlines.select('.domain').remove();
        gridlines.selectAll('.tick line').attr('stroke', '#ececec');

        const xAxis = basis
            .append('g')
            .attr('transform', 'translate(0,' + innerHeight + ')')
            .call(
                d3
                    .axisBottom(x)
                    .tickFormat((d) => usageData.find((entry) => entry.integrationId === d).integrationShortName),
            );
        const toolTip = d3.select('.api-tooltip');
        xAxis.select('.domain').remove();
        xAxis.selectAll('.tick line').attr('fill', 'none');
        xAxis
            .selectAll('.tick text')
            .attr('fill', '#abaeaf')
            .on('mouseover', () => toolTip.style('opacity', 0.7))
            .on('mouseout', () => {
                toolTip.style('opacity', 0);
            })
            .on('mousemove', showAccountInfoDetailsFromLabel);

        this.svg
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .selectAll('.bar')
            .data(usageData)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => x(d.integrationId))
            .attr('y', (d) => y(d.ruumCreationCount))
            .attr('width', x.bandwidth())
            .attr('height', (d) => innerHeight - y(d.ruumCreationCount))
            .attr('fill', (d, i) => color(i))
            .on('mouseover', () => toolTip.style('opacity', 0.7))
            .on('mouseout', () => {
                toolTip.style('opacity', 0);
            })
            .on('mousemove', showAccountInfoDetails);

        function showAccountInfoDetailsFromLabel(integrationAccountId: string) {
            const integrationAccountInfo = usageData.find((data) => data.integrationId === integrationAccountId);
            showAccountInfoDetails(integrationAccountInfo);
        }

        function showAccountInfoDetails(integrationAccountInfo: RuumCreationDataEntry) {
            toolTip.style('opacity', 0.7);
            toolTip.html(getTooltipText(integrationAccountInfo));
        }
    }
}

function getTooltipText(integrationAccountInfo: RuumCreationDataEntry): string {
    return (
        `${integrationAccountInfo.integrationFullName}:<br />` +
        `<b>${integrationAccountInfo.ruumCreationCount === 0 ? 'no' : integrationAccountInfo.ruumCreationCount}</b>` +
        `&nbsp;ruum${integrationAccountInfo.ruumCreationCount === 1 ? '' : 's'} created`
    );
}

function getClosestValue(value: number): number {
    return Math.ceil(value / 10) * 10;
}

interface RuumCreationDataEntry {
    integrationShortName: string;
    integrationFullName: string;
    integrationId: string;
    ruumCreationCount: number;
}
