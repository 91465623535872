import {
    AddExternalTableDefinitionAction,
    DeleteExternalTableDefinitionAction,
    EntityAction,
    RuumAction,
    TableDefinition,
    UpdateExternalTableDefinitionAction,
} from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../paginatedList.model';

export const TABLE_DEFINITION_LIST_ACTION_TYPES = {
    TABLE_DEFINITION_LOADED: 'TABLE_DEFINITION_LOADED',
    ADD_EXTERNAL_TABLE_DEFINITION_TO_LIST: 'ADD_EXTERNAL_TABLE_DEFINITION_TO_LIST',
    UPDATE_EXTERNAL_TABLE_DEFINITION_TO_LIST: 'UPDATE_EXTERNAL_TABLE_DEFINITION_TO_LIST',
    DELETE_EXTERNAL_TABLE_DEFINITION_FROM_LIST: 'DELETE_EXTERNAL_TABLE_DEFINITION_FROM_LIST',
};

export type TableDefinitionLoadedAction = EntityAction<
    'TABLE_DEFINITION_LOADED',
    {
        page: PaginatedList<TableDefinition>;
    }
>;

export function TableDefinitionListReducer(
    currentState: PaginatedList<TableDefinition> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<TableDefinition> {
    switch (action.type) {
        case TABLE_DEFINITION_LIST_ACTION_TYPES.TABLE_DEFINITION_LOADED:
            return listLoaded(currentState, action.payload.page);
        case TABLE_DEFINITION_LIST_ACTION_TYPES.ADD_EXTERNAL_TABLE_DEFINITION_TO_LIST:
            return addTableDefinition(currentState, action.payload);
        case TABLE_DEFINITION_LIST_ACTION_TYPES.UPDATE_EXTERNAL_TABLE_DEFINITION_TO_LIST:
            return updateTableDefinition(currentState, action.payload);
        case TABLE_DEFINITION_LIST_ACTION_TYPES.DELETE_EXTERNAL_TABLE_DEFINITION_FROM_LIST:
            return deleteTableDefinition(currentState, action.payload);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<TableDefinition>,
    data: PaginatedList<TableDefinition>,
): PaginatedList<TableDefinition> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}

function addTableDefinition(
    currentState: PaginatedList<TableDefinition>,
    payload: AddExternalTableDefinitionAction['payload'],
): PaginatedList<TableDefinition> {
    const row: TableDefinition = {
        ...payload,
        id: payload.tableId,
        type: 'external_table',
    };
    return {
        ...currentState,
        rows: [...currentState.rows, row],
    };
}

function updateTableDefinition(
    currentState: PaginatedList<TableDefinition>,
    payload: UpdateExternalTableDefinitionAction['payload'],
): PaginatedList<TableDefinition> {
    return {
        ...currentState,
        rows: currentState.rows.map((row) => {
            if (row.id === payload.tableId) {
                return {
                    ...row,
                    ...payload.editFields,
                };
            } else {
                return row;
            }
        }),
    };
}

function deleteTableDefinition(
    currentState: PaginatedList<TableDefinition>,
    payload: DeleteExternalTableDefinitionAction['payload'],
): PaginatedList<TableDefinition> {
    return {
        ...currentState,
        rows: currentState.rows.filter((el) => el.id !== payload.tableId),
    };
}
