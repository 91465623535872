import { Component, Input } from '@angular/core';

@Component({
    selector: 'ruum-admin-widget',
    template: `
        <div class="widget-container d-flex flex-column bg-white border border-light rounded w-100 p-4 mb-6">
            <ng-content></ng-content>
        </div>
    `,
})
export class WidgetComponent {
    @Input() title: string;
    @Input() url: string;
}

// temporarily bringing back widget config
export interface WidgetConfiguration {
    id?: string;
    enterpriseId: string;
    description?: string;
    issuer?: string;
    clientId?: string;
    recipient?: string;
    audience?: string;
    techUserPwd?: string;
    techUserId?: string;
    tokenUrl?: string;
    marketingSystemDomain?: string;
    scope?: string;
    hardcodedEmail?: string;
}
