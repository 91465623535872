import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SavedViewColumn } from '../../../common/connectors/savedView/saved-views.model';

@Component({
    selector: 'ruum-task-list-item-custom-field-selection-list',
    template: `
        <ruum-tag
            *ngFor="let option of options | slice: 0:1"
            [name]="option"
            [hover]="false"
            [componentClass]="'mr-1 mt-1'"
        ></ruum-tag>
        <ruum-tag
            *ngIf="options.length > 1"
            [name]="getOtherOptionsNumber(options.length, 1)"
            [type]="'link'"
            [hover]="false"
            [componentClass]="'px-0 mt-1'"
        ></ruum-tag>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListItemCustomFieldSelectionListComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-wrap minw-0';

    @Input() column: SavedViewColumn;

    @Input() taskListItem;

    get options(): string[] {
        const customFieldsValues = this.taskListItem.customFieldsValues || {};
        const selectedOptionIds = customFieldsValues[this.column.fieldId] || [];
        const options = this.column.options || [];
        const optionsMap = options.reduce((accumulator, currentValue) => {
            return { ...accumulator, [currentValue.id]: currentValue };
        }, {});

        return selectedOptionIds.map((id) => optionsMap[id]).map((option) => option.value);
    }

    constructor() {}

    ngOnInit() {}

    getOtherOptionsNumber(all: number, shown: number): string {
        const value = all - shown;
        return `+${value > 99 ? 99 : value}`;
    }
}
