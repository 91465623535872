import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TableDirectiveService } from './table.directive.service';

@Directive({
    selector: '[ruumResizableColumn]',
})
export class ResizableColumnDirective implements OnDestroy {
    @Input() id: string;

    private subscriptions: Subscription[] = [];

    constructor(private element: ElementRef, private tableDirectiveService: TableDirectiveService) {
        this.initHoverSubscription();
        this.initResizeSubscription();
        this.initClickEvent();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    initHoverSubscription() {
        const hoverSubscription = this.tableDirectiveService
            .getHoveredColumnId$()
            .pipe(distinctUntilChanged())
            .subscribe((hoveredId) => {
                this.updateHoveredColumnHighlight(hoveredId);
            });

        this.subscriptions.push(hoverSubscription);
    }

    initResizeSubscription() {
        const resizeSubscription = this.tableDirectiveService
            .getCurrentResizeId$()
            .pipe(distinctUntilChanged())
            .subscribe((resizedId) => {
                this.updateResizedColumnHighlight(resizedId);
            });

        this.subscriptions.push(resizeSubscription);
    }

    updateHoveredColumnHighlight(hoveredId: string): void {
        const currentElement = this.element.nativeElement;

        if (this.id === hoveredId) {
            this.addClass('has-devider-visible', currentElement);

            this.highlightPreviousColumn(currentElement);
        } else {
            this.removeClass('has-devider-visible', currentElement);
        }
    }

    highlightPreviousColumn(currentElement: Element): void {
        const previousElement = currentElement.previousElementSibling;

        if (previousElement) {
            this.addClass('has-devider-visible', previousElement);
        }
    }

    addClass(newClassName: string, element: Element): void {
        const classes = element.className.split(' ');

        if (classes.indexOf(newClassName) === -1) {
            element.className += ` ${newClassName}`;
        }
    }

    removeClass(className: string, element: Element): void {
        const classes = element.className.split(' ');
        const classIndex = classes.indexOf(className);

        if (classIndex !== -1) {
            const newClasses = [...classes.slice(0, classIndex), ...classes.slice(classIndex + 1)];

            element.className = newClasses.join(' ');
        }
    }

    initClickEvent(): void {
        const headerRowMouseDowns = fromEvent(this.element.nativeElement, 'mousedown');

        const mouseDownSubscription = headerRowMouseDowns.subscribe(() => {
            this.tableDirectiveService.setCurrentClickId(this.id);
        });

        this.subscriptions.push(mouseDownSubscription);
    }

    updateResizedColumnHighlight(resizedId: string) {
        const currentElement = this.element.nativeElement;

        if (this.id === resizedId) {
            this.addClass('has-devider-focused', currentElement);
        } else {
            this.removeClass('has-devider-focused', currentElement);
        }
    }
}
