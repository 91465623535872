import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RuumOnPremiseConnectorAuthHandler } from '../connectors/externalSystem/ruumOnPremiseConnectorAuthHandler.service';

@Injectable({ providedIn: 'root' })
export class ExternalSystemCallInterceptor implements HttpInterceptor {
    constructor(private extConnectorPremAuthHandler: RuumOnPremiseConnectorAuthHandler) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const match = request.url.match(/\/v1\/tabledata.*/); // Check if call is going to /v1/tabledata

        if (match) {
            const headers = {};
            const validTokens = this.extConnectorPremAuthHandler.getTokens();
            Object.keys(validTokens).forEach((systemId) => {
                const token = validTokens[systemId];
                headers[`x-forward-token-${systemId}`] = token;
            });
            request = request.clone({
                setHeaders: headers,
            });
        }

        return next.handle(request);
    }
}
