import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemConnector } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-system-connector-dialog-create',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">New System Connector</h2>
            <form [formGroup]="createForm">
                <div class="form-group mb-3">
                    <label class="form-default-label" for="labelField">Name</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the name of your new system connector..."
                        id="labelField"
                        type="text"
                        formControlName="title"
                        ngbAutofocus
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="description">Description</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter a description"
                        type="text"
                        formControlName="description"
                        id="description"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="system">System</label>
                    <ruum-system-select formControlName="systemId"></ruum-system-select>
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="pathField">Path</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the path to your API endpoint..."
                        id="pathField"
                        type="path"
                        formControlName="path"
                        id="system"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="type">Type</label>
                    <ruum-select formControlName="type" id="type">
                        <ruum-select-option content="Standard" value="standard"></ruum-select-option>
                        <ruum-select-option content="SCP Workflow" value="scp_workflow"></ruum-select-option>
                        <ruum-select-option content="RPA" value="rpa_bot"></ruum-select-option>
                    </ruum-select>
                </div>
                <div class="form-group mb-3" *ngIf="createForm.value.type === 'scp_workflow'">
                    <label class="form-default-label" for="definitionIdField">SCP Workflow Definition ID</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the SCP Workflow Definition ID"
                        id="definitionIdField"
                        type="text"
                        formControlName="definitionId"
                    />
                </div>

                <div class="form-group mb-3" *ngIf="createForm.value.type === 'rpa_bot'">
                    <label class="form-default-label" for="xtokenField">SAP iRPA Token</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the SAP iRPA Token"
                        id="xtokenField"
                        type="text"
                        formControlName="xtoken"
                    />
                </div>

                <hr />
                <!-- INPUTS -->
                <div class="d-flex align-items-center mt-5">
                    <div class="flex-fill text-truncate text-small font-weight-bold">Request Property Mapping</div>
                    <button class="btn btn-outline-primary btn-xs" (click)="addInputProperty()">
                        <i class="icon icon-add"></i> Add Property
                    </button>
                </div>
                <div class="d-table mt-5 w-100" formArrayName="input" autocomplete="off">
                    <div class="d-table-row text-secondary text-tiny font-weight-bold">
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-name">PROPERTY NAME</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-type">DATA TYPE</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-label">LABEL</label>
                        </div>
                        <div class="d-table-cell px-2"></div>
                    </div>
                    <ruum-system-connector-io-item
                        *ngFor="let ctrItem of systemInputs.controls; let i = index"
                        [formGroup]="ctrItem"
                        (remove)="removeInputProperty(i)"
                    ></ruum-system-connector-io-item>
                </div>
                <!-- OUTPUTS -->
                <div class="d-flex align-items-center mt-5">
                    <div class="flex-fill text-truncate text-small font-weight-bold">Response Property Mapping</div>
                    <button class="btn btn-outline-primary btn-xs" (click)="addOutputProperty()">
                        <i class="icon icon-add"></i> Add Property
                    </button>
                </div>
                <div class="d-table mt-5 w-100" formArrayName="output" autocomplete="off">
                    <div class="d-table-row text-secondary text-tiny font-weight-bold">
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-name">PROPERTY NAME</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-type">DATA TYPE</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-label">LABEL</label>
                        </div>
                        <div class="d-table-cell px-2"></div>
                    </div>
                    <ruum-system-connector-io-item
                        *ngFor="let ctrItem of systemOutputs.controls; let i = index"
                        [formGroup]="ctrItem"
                        (remove)="removeOutputProperty(i)"
                    ></ruum-system-connector-io-item>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-6">
                    <a
                        class="btn btn-xs btn-outline-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.sap.com/viewer/f8e58f003adf4cc78f5152f51a66e771/Latest/en-US"
                    >
                        Need Help? See Documentation!
                    </a>
                    <button
                        class="btn btn-lg btn-primary"
                        type="button"
                        [disabled]="!createForm.valid"
                        (click)="createConnector()"
                    >
                        Create
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemConnectorDialogCreateComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Output() create = new EventEmitter<SystemConnector>();

    createForm: FormGroup;
    systemInputs: FormArray;
    systemOutputs: FormArray;

    constructor(private ngbActiveModal: NgbActiveModal, private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.systemInputs = this.formBuilder.array([]);
        this.systemOutputs = this.formBuilder.array([]);
        this.createForm = this.formBuilder.group({
            title: ['', Validators.required],
            systemId: ['', Validators.required],
            description: [''],
            path: ['', Validators.required],
            type: ['standard', Validators.required],
            xtoken: [undefined],
            definitionId: [undefined],
            input: this.systemInputs,
            output: this.systemOutputs,
        });
    }

    addInputProperty() {
        const itemFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            type: ['string', Validators.required],
            label: ['', Validators.required],
        });
        this.systemInputs.push(itemFormGroup);
    }

    removeInputProperty(index: number) {
        this.systemInputs.removeAt(index);
    }

    addOutputProperty() {
        const itemFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            type: ['string', Validators.required],
            label: ['', Validators.required],
        });
        this.systemOutputs.push(itemFormGroup);
    }

    removeOutputProperty(index: number) {
        this.systemOutputs.removeAt(index);
    }

    createConnector() {
        if (!this.createForm.valid) {
            return;
        }
        const data = { ...this.createForm.value };
        if (data.type !== 'scp_workflow') {
            delete data.definitionId;
        }
        if (data.type !== 'rpa_bot') {
            delete data.xtoken;
        }
        this.ngbActiveModal.close(data);
    }
}
