import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface PayloadViewerDialogData {
    url: string;
    statusCode: number;
    requestPayload: string;
    responseHeaders: string;
    responsePayload: string;
    responseTime: string;
}

@Component({
    selector: 'payload-viewer-dialog',
    template: `
        <div class="modal-header">
            <h1 class="modal-title">Payload</h1>
        </div>
        <div class="modal-body">
            <section>
                <h2>Request</h2>
                <h5>Url</h5>
                <pre class="bg-gray-100">POST {{ data.url }}</pre>
                <h5>Headers</h5>
                <pre class="bg-gray-100">{{ tryToFormatCode({ 'content-type': 'application/json' }) }}</pre>
                <h5>Body</h5>
                <pre class="bg-gray-100"><code>{{tryToFormatCode(data.requestPayload)}}</code></pre>
            </section>
            <section>
                <h2>Response</h2>
                <pre>Took {{ data.responseTime }}ms</pre>
                <h5>Status Code</h5>
                <pre class="bg-gray-100"><code>{{data.statusCode}}</code></pre>
                <h5>Headers</h5>
                <pre class="bg-gray-100"><code>{{tryToFormatCode(data.responseHeaders)}}</code></pre>
                <h5>Body</h5>
                <pre class="bg-gray-100"><code>{{tryToFormatCode(data.responsePayload)}}</code></pre>
            </section>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="close()">Close</button>
        </div>
    `,
})
export class PayloadViewerDialogComponent {
    @Input() data: PayloadViewerDialogData;

    constructor(private activeModal: NgbActiveModal) {}

    close() {
        this.activeModal.close();
    }

    tryToFormatCode(code: string | any): string {
        try {
            if (code) {
                if (typeof code === 'string') {
                    return JSON.stringify(JSON.parse(code), null, 4);
                } else {
                    return JSON.stringify(code, null, 4);
                }
            } else {
                return 'Nothing was received.';
            }
        } catch (e) {
            return code;
        }
    }
}
