import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { RuumPopupBase } from '../../common/popup/ruum-popup-base';
import { PaywallPrivilege } from '../../paywall/paywall-privilege';
import { DEAD_ZONE, getPopupPosition, PopupAlign } from '../popup/ruum-popup-helper';

export interface RuumDropDownOption<T extends string = string> {
    /** Unique id of the option  */
    id: T;
    /**
     * The text which will appear in the dropdown.
     */
    text: string;
    /**
     * The text and color of the text on the right hand side.
     * When set this is what is going to be used when this value is selected
     * instead of 'text'.
     * e.g. Initials for participants.
     */
    rightSide?: {
        text: string;
        color: string;
    };
    /**
     * The icon
     */
    icon?: string;
    /**
     * Background Color
     */
    color?: string;

    /**
     * Text Color
     */
    hoverColor?: string;
    activeHoverColor?: string;

    theme?: string;

    badgeText?: string;

    /**
     * An optional description that is shown underneath the actual text.
     */
    description?: string;

    paywallPrivilege?: PaywallPrivilege;
    paywallRejectStrategy?: string;
    isDisabled?: boolean;
    disabledReason?: string;
}

export type RuumDropDownPopupAlign = PopupAlign;

@Component({
    selector: 'ruum-dropdown-popup',
    template: `
        <ul
            class="ruum-dropdown-options"
            [style.height]="popupHeight"
            (mouseenter)="onMouseEntrance('enter')"
            (mouseleave)="onMouseEntrance('leave')"
        >
            <li class="title" *ngIf="showTitle">{{ title }}</li>
            <div
                *ngFor="let option of options"
                [ngbTooltip]="option.isDisabled === true ? notAllowed : null"
                [placement]="['left', 'right']"
                [class.cursor-not-allowed]="option.isDisabled"
            >
                <li
                    class="ruum-dropdown-option"
                    [requiredPrivilege]="option.paywallPrivilege"
                    [rejectStrategy]="option.paywallRejectStrategy"
                    [rejectStrategyData]="{ featureName: option.text }"
                    (requiredPrivilege.click)="clickOnOption(option)"
                    [class.colorful-bg]="colorfulBG"
                    data-test="ruum-dropdown-option"
                    [class.disabled]="option.isDisabled"
                >
                    <div
                        class="d-flex justify-content-between"
                        [style.border]="colorfulBG && getBorderColor(option.color)"
                        [style.color]="colorfulBG && getBackgroundColor(option.color)"
                        [style.padding]="colorfulBG && getPadding()"
                        [class.with-description]="option.description"
                        [class.text-danger]="option.icon === 'icon-delete'"
                    >
                        <div
                            [ngClass]="{ centered: colorfulBG }"
                            [style.color]="colorfulBG && getBackgroundColor(option.color)"
                        >
                            {{ option.text }}
                        </div>
                        <span
                            *ngIf="option.rightSide"
                            class="option-right-side"
                            [style.color]="option.rightSide?.color"
                            >{{ option.rightSide.text }}</span
                        >
                        <i
                            *ngIf="option.icon"
                            class="option-right-side icon {{ option?.icon }} text-{{ option?.theme }}"
                            [attr.data-badge]="option.badgeText || undefined"
                        ></i>
                        <div *ngIf="option.description" class="description">{{ option.description }}</div>
                    </div>
                </li>
                <ng-template #notAllowed
                    ><span>{{ option.disabledReason }}</span></ng-template
                >
            </div>
        </ul>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            .centered {
                width: 100%;
                text-align: center;
            }
            .option-right-side {
                margin-left: 8px;
                margin-top: 2px;
                margin-bottom: -2px;
                position: relative;
            }
            .title {
                margin: 1px 0;
                padding: 7px 8px;
                color: rgba(37, 44, 66, 0.48);
            }
            .ruum-dropdown-option.colorful-bg > div {
                border-radius: 17px;
            }
            .ruum-dropdown-options {
                overflow-y: auto;
                box-sizing: border-box;
            }
            .ruum-dropdown-option {
                min-width: 110px;
            }
            .ruum-dropdown-option > div.with-description {
                display: block;
            }
            .option-right-side[data-badge]:after {
                content: attr(data-badge);
                position: absolute;
                color: white;
                display: block;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                background: #0a6ed1;
                border-radius: 8px;
                font-size: 12px;
                height: 15px;
                line-height: 15px;
                min-width: 15px;
                text-align: center;
                font-style: normal;
                padding: 0 3px;
                box-sizing: border-box;
            }
            .description {
                font-size: 12px;
                line-height: 12px;
                color: rgba(23, 49, 69, 0.4);
            }
            .disabled {
                pointer-events: none;
                color: #d3d3d3;
            }
        `,
    ],
})
export class RuumDropdownPopupComponent extends RuumPopupBase implements AfterViewInit, OnDestroy {
    @Input() options: RuumDropDownOption[] = [];
    @Input() pos: { left: number; top: number; bottom: number; right: number };
    @Input() align: RuumDropDownPopupAlign = 'left';
    @Input() colorfulBG = false;
    @Input() title?: string;
    @Input() showTitle = false;

    mouseState$: Subject<'enter' | 'leave'> = new Subject();
    pos$: BehaviorSubject<{}> = new BehaviorSubject({});

    popupHeight = 'auto'; // used internally
    beforeFocus: HTMLElement;

    constructor(private elem: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super();
        this.mouseState$
            .pipe(
                debounceTime(300),
                filter((state) => state === 'leave'),
                take(1),
            )
            .subscribe(() => this.close());
    }

    onMouseEntrance(action: 'enter' | 'leave') {
        // this.mouseState$.next(action);
    }

    ngOnDestroy() {
        this.pos$.complete();
        this.mouseState$.complete();
    }

    clickOnOption(option) {
        this.close(option);
    }

    getMaskColor() {
        return 'rgba(0,0,0,0)';
    }

    ngAfterViewInit() {
        // eslint-disable-next-line prefer-const
        let { height, width } = this.elem.nativeElement.getClientRects()[0];
        const windowHeight = window.innerHeight - DEAD_ZONE * 2;

        if (height > windowHeight) {
            height = windowHeight;
            this.popupHeight = `${height}px`;
        }

        this.pos$.next(getPopupPosition(height, width, this.align, this.pos));
    }

    getPos() {
        return this.pos$;
    }

    getBorderColor(color?: string): string | undefined {
        if (color) {
            return 'solid 1px ' + color;
        }
        return undefined;
    }
    getBackgroundColor(color?: string): string | undefined {
        if (color) {
            return color;
        }
        return undefined;
    }
    getPadding() {
        return '0px 8px';
    }
}
