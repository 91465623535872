import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
    IMyCalendarDay,
    IMyCalendarViewChanged,
    IMyDate,
    IMyDateModel,
    IMyDateRange,
    IMyDayLabels,
    IMyInputAutoFill,
    IMyInputFieldChanged,
    IMyInputFocusBlur,
    IMyMarkedDate,
    IMyMarkedDates,
    IMyMonth,
    IMyMonthLabels,
    IMyOptions,
    IMyWeek,
} from './interfaces/index';
import { LocaleService } from './services/my-date-picker.locale.service';
import { UtilService } from './services/my-date-picker.util.service';

export const MYDP_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => MyDatePickerComponent),
    multi: true,
};

enum CalToggle {
    Open = 1,
    CloseByDateSel = 2,
    CloseByCalBtn = 3,
    CloseByOutClick = 4,
}
enum Year {
    min = 1000,
    max = 9999,
}
enum InputFocusBlur {
    focus = 1,
    blur = 2,
}
enum KeyCode {
    enter = 13,
    space = 32,
}
enum MonthId {
    prev = 1,
    curr = 2,
    next = 3,
}

@Component({
    selector: 'my-date-picker',
    exportAs: 'mydatepicker',
    styles: [
        `
            .ruum-datepicker {
                width: 248px;
            }

            .ruum-datepicker-day,
            .ruum-datepicker-weekday {
                min-width: 32px;
                min-height: 32px;
                outline: none;
            }

            .ruum-datepicker-day div,
            .ruum-datepicker-weekday div {
                min-width: 32px;
                min-height: 26px;
            }

            .ruum-datepicker-day div {
                cursor: pointer;
            }

            .ruum-datepicker-day div span,
            .ruum-datepicker-weekday div span {
                min-width: 26px;
                min-height: 26px;
            }

            .ruum-datepicker-day span:hover {
                background-color: rgba(92, 208, 224, 0.24);
                border-radius: 13px;
            }

            .ruum-datepicker-day .ruum-datepicker-today {
                border: 1px solid #0a6ed1;
                border-radius: 13px;
            }

            .ruum-datepicker-day .ruum-datepicker-weekday {
                color: rgba(37, 44, 66, 0.48);
            }

            .ruum-datepicker-day .ruum-datepicker-selected-day,
            .ruum-datepicker-day .ruum-datepicker-selected-day:hover,
            .ruum-datepicker-day .ruum-datepicker-selected-startday,
            .ruum-datepicker-day .ruum-datepicker-selected-startday:hover,
            .ruum-datepicker-day .ruum-datepicker-selected-endday,
            .ruum-datepicker-day .ruum-datepicker-selected-endday:hover {
                background-color: #0a6ed1;
                color: white;
                border-radius: 13px;
            }

            .ruum-datepicker-day .ruum-datepicker-disabled-day,
            .ruum-datepicker-day .ruum-datepicker-disabled-day:hover {
                background-color: #ffffff;
                opacity: 0.24;
            }

            .ruum-datepicker-day .ruum-datepicker-selected-same-day,
            .ruum-datepicker-day .ruum-datepicker-selected-same-day:hover {
                background-color: #0a6ed1;
                border-radius: 13px;
            }

            .ruum-datepicker-day .ruum-datepicker-date-range,
            .ruum-datepicker-day .ruum-datepicker-date-range:hover {
                background-color: rgba(92, 208, 224, 0.16);
            }

            .ruum-datepicker-day div.ruum-datepicker-date-range-startday,
            .ruum-datepicker-day .ruum-datepicker-date-range-starthover {
                background-color: rgba(92, 208, 224, 0.16);
                border-radius: 14px 0 0 14px;
            }

            .ruum-datepicker-day div.ruum-datepicker-date-range-endday,
            .ruum-datepicker-day .ruum-datepicker-date-range-endhover {
                background-color: rgba(92, 208, 224, 0.16);
                border-radius: 0 14px 14px 0;
            }

            .hide-button {
                display: block;
                overflow: hidden;
                visibility: hidden;
            }

            .selected-date-button {
                width: 100%;
                color: inherit;
                padding-left: 4px;
                padding-right: 4px;
            }

            .overdue .selected-date-button {
                color: #bb0000 !important;
            }
        `,
    ],
    template: `
        <div
            class="mydp"
            [ngStyle]="{ width: opts.showInputField ? opts.width : null, border: opts.inline ? 'none' : null }"
        >
            <button
                [class.hide-button]="!showButton"
                class="btn ruum-button selected-date-button px-0"
                #popover="ngbPopover"
                [ngbPopover]="popoverContent"
                [placement]="placement"
                [autoClose]="'outside'"
                [container]="'body'"
                [triggers]="'manual'"
                (click)="toggleDatepicker($event)"
                [attr.data-test]="isStartDay ? 'selected-date-button-start' : 'selected-date-button-due'"
            >
                <span *ngIf="selectionDayText">{{ selectionPrefix }}{{ selectionDayText }}</span>
                <span *ngIf="!selectionDayText" class="interface">{{ placeholder }}</span>
            </button>

            <ng-template #popoverContent>
                <div class="ruum-datepicker p-4 bg-white shadow rounded">
                    <div class="ruum-datepicker-navigation d-flex">
                        <button class="btn btn-link-secondary btn-icon" type="button" (click)="prevMonth()">
                            <i class="icon icon-back"></i>
                        </button>
                        <div
                            class="d-flex flex-fill justify-content-center align-items-center font-weight-bold"
                            data-test="ruum-datepicker-navigation-current-month"
                        >
                            {{ visibleMonth.monthText }} {{ visibleMonth.year }}
                        </div>
                        <button class="btn btn-link-secondary btn-icon" type="button" (click)="nextMonth()">
                            <i class="icon icon-forward"></i>
                        </button>
                    </div>
                    <div class="ruum-datepicker-week d-flex justify-content-center mt-2">
                        <div
                            class="ruum-datepicker-weekday d-flex justify-content-center align-items-center text-light text-small font-weight-bold"
                            *ngFor="let d of weekDays"
                        >
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="d-flex justify-content-center align-items-center">{{ d }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="ruum-datepicker-week d-flex justify-content-start" *ngFor="let w of dates">
                        <div
                            class="ruum-datepicker-day text-small d-flex justify-content-center align-items-center"
                            *ngFor="let d of w.week"
                            [class.d-flex]="d.cmo !== nextMonthId"
                            [class.d-none]="d.cmo === nextMonthId"
                            tabindex="0"
                        >
                            <div
                                class="d-flex justify-content-center align-items-center"
                                *ngIf="d.cmo === currMonthId"
                                (click)="!d.disabled && cellClicked(d); $event.stopPropagation()"
                                (keydown)="cellKeyDown($event, d)"
                                (mouseover)="!d.disabled && handleHover(d)"
                                (focus)="!d.disabled && handleHover(d)"
                                [ngClass]="{
                                    'ruum-datepicker-date-range': isDateRange(d),
                                    'ruum-datepicker-date-range-startday':
                                        datesBetween.length > 0 && (isStartDate(d) || isSelectedStartDay(d)),
                                    'ruum-datepicker-date-range-endday':
                                        datesBetween.length > 0 && (isEndDate(d) || isSelectedEndDay(d)),
                                    'ruum-datepicker-date-range-starthover': startHover(d),
                                    'ruum-datepicker-date-range-endhover': dueHover(d)
                                }"
                            >
                                <span
                                    class="d-flex justify-content-center align-items-center"
                                    [ngClass]="{
                                        'ruum-datepicker-today': d.currDay && opts.markCurrentDay,
                                        'ruum-datepicker-weekday':
                                            (d.dayNbr === 6 && opts.satHighlight) ||
                                            (d.dayNbr === 0 && opts.sunHighlight),
                                        'ruum-datepicker-selected-day':
                                            (isSelectedDate(d) && isStartDay) || (isSelectedDate(d) && !isStartDay),
                                        'ruum-datepicker-selected-startday': isStartDate(d),
                                        'ruum-datepicker-selected-endday': isEndDate(d),
                                        'ruum-datepicker-selected-same-day': isSameSelectedDay(d),
                                        'ruum-datepicker-disabled-day': d.disabled
                                    }"
                                    >{{ d.dateObj.day }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-4">
                        <button
                            class="btn btn-outline-secondary"
                            type="button"
                            *ngIf="isStartDay && !selectionDayText && opts.showTodayBtn"
                            [class.disabled]="disableTodayBtn"
                            (click)="todayClicked()"
                        >
                            Today
                        </button>
                        <button
                            class="btn btn-outline-danger border-0"
                            type="button"
                            *ngIf="selectionDayText"
                            (click)="reset()"
                        >
                            Reset
                        </button>
                        <div class="flex-fill"></div>
                        <!-- <button
                            class="btn btn-primary"
                            type="button"
                            [class.disabled]="!selectionDayText"
                            (click)="selectionDayText && selectAndClose()"
                        >
                            Select
                        </button> -->
                    </div>
                </div>
            </ng-template>
        </div>
    `,
    providers: [LocaleService, UtilService, MYDP_VALUE_ACCESSOR],
    encapsulation: ViewEncapsulation.None,
})
export class MyDatePickerComponent implements OnChanges, ControlValueAccessor {
    constructor(
        private elem: ElementRef,
        private cdr: ChangeDetectorRef,
        private localeService: LocaleService,
        private utilService: UtilService,
    ) {
        this.setLocaleOptions();
    }
    @Input() options: IMyOptions;
    @Input() locale: string;
    @Input() defaultMonth: string;
    @Input() selDate: string;
    @Input() placeholder: string;
    @Input() selector: number;
    @Input() disabled: boolean;
    @Input() disableSince: IMyDate;
    @Input() disableUntil: IMyDate;
    @Input() selectionPrefix: string;
    @Input() showButton = true;
    @Input() isStartDay: boolean;
    @Output() dateChanged: EventEmitter<IMyDateModel> = new EventEmitter<IMyDateModel>();
    @Output() resetDates: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() inputFieldChanged: EventEmitter<IMyInputFieldChanged> = new EventEmitter<IMyInputFieldChanged>();
    @Output() calendarViewChanged: EventEmitter<IMyCalendarViewChanged> = new EventEmitter<IMyCalendarViewChanged>();
    @Output() calendarToggle: EventEmitter<number> = new EventEmitter<number>();
    @Output() inputFocusBlur: EventEmitter<IMyInputFocusBlur> = new EventEmitter<IMyInputFocusBlur>();

    showSelector = false;
    visibleMonth: IMyMonth = { monthText: '', monthNbr: 0, year: 0 };
    selectedMonth: IMyMonth = { monthText: '', monthNbr: 0, year: 0 };
    selectedDate: IMyDate = { year: 0, month: 0, day: 0 };
    weekDays: Array<string> = [];
    dates: Array<IMyWeek> = [];
    selectionDayText = '';
    selectionInputText = '';
    invalidDate = false;
    disableTodayBtn = false;
    dayIdx = 0;
    weekDayOpts: Array<string> = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
    autoFillOpts: IMyInputAutoFill = { separator: '', formatParts: [], enabled: true };
    currHoverDate: IMyDate = { year: 0, month: 0, day: 0 };

    editMonth = false;
    invalidMonth = false;
    editYear = false;
    invalidYear = false;

    prevMonthDisabled = false;
    nextMonthDisabled = false;
    prevYearDisabled = false;
    nextYearDisabled = false;

    prevMonthId: number = MonthId.prev;
    currMonthId: number = MonthId.curr;
    nextMonthId: number = MonthId.next;
    datesBetween: Array<IMyDate> = [];

    // Default options
    opts: IMyOptions = {
        dayLabels: {} as IMyDayLabels,
        monthLabels: {} as IMyMonthLabels,
        dateFormat: '' as string,
        showTodayBtn: true as boolean,
        todayBtnText: '' as string,
        firstDayOfWeek: '' as string,
        satHighlight: true as boolean,
        sunHighlight: true as boolean,
        markCurrentDay: true as boolean,
        disableUntil: { year: 0, month: 0, day: 0 } as IMyDate,
        disableSince: { year: 0, month: 0, day: 0 } as IMyDate,
        disableDays: [] as Array<IMyDate>,
        enableDays: [] as Array<IMyDate>,
        markDates: [] as Array<IMyMarkedDates>,
        markWeekends: {} as IMyMarkedDate,
        disableDateRanges: [] as Array<IMyDateRange>,
        disableWeekends: false as boolean,
        showWeekNumbers: false as boolean,
        height: '34px' as string,
        width: '100%' as string,
        // width: '61px', /** exact size to fit label 'Start Date' in one line */
        selectionTextFontSize: '14px' as string,
        inline: false as boolean,
        showClearDateBtn: true as boolean,
        alignSelectorRight: false as boolean,
        openSelectorTopOfInput: false as boolean,
        indicateInvalidDate: true as boolean,
        editableDateField: true as boolean,
        editableMonthAndYear: true as boolean,
        disableHeaderButtons: true as boolean,
        minYear: Year.min as number,
        maxYear: Year.max as number,
        componentDisabled: false as boolean,
        showSelectorArrow: true as boolean,
        showInputField: true as boolean,
        openSelectorOnInputClick: false as boolean,
        inputAutoFill: true as boolean,
        ariaLabelInputField: 'Date input field' as string,
        ariaLabelClearDate: 'Clear Date' as string,
        ariaLabelOpenCalendar: 'Open Calendar' as string,
        ariaLabelPrevMonth: 'Previous Month' as string,
        ariaLabelNextMonth: 'Next Month' as string,
        ariaLabelPrevYear: 'Previous Year' as string,
        ariaLabelNextYear: 'Next Year' as string,
    };

    @Input()
    placement: any = ['bottom-left', 'bottom', 'bottom-right', 'top-left', 'top', 'top-right', 'auto'];

    @ViewChild('popover', { static: false })
    popover: NgbPopover;

    onChangeCb: (_: any) => void = () => {};
    onTouchedCb: () => void = () => {};

    toggleDatepicker(event) {
        // event.stopPropagation();
        if (this.popover.isOpen()) {
            this.popover.close();
            this.calendarToggle.emit(CalToggle.CloseByCalBtn);
        } else {
            this.popover.open();

            this.setVisibleMonth();
            this.calendarToggle.emit(CalToggle.Open);
        }
        this.detectChanges();
    }

    close() {
        this.popover.close();
    }

    inputPlaceholder() {
        if (this.isStartDay) {
            return 'Start Date';
        } else {
            return 'Due Date';
        }
    }
    isHovered(d) {
        return (
            !this.isEndDate(d) &&
            !this.isStartDate(d) &&
            !this.isSelectedDate(d) &&
            !this.isDateRange(d) &&
            this.currHoverDate.year === d.dateObj.year &&
            this.currHoverDate.month === d.dateObj.month &&
            this.currHoverDate.day === d.dateObj.day
        );
    }
    isDateRange(d) {
        if (
            (!this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 }) &&
                (this.disableSince || this.disableUntil)) ||
            (this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 }) &&
                (this.disableSince || this.disableUntil))
        ) {
            return (
                this.datesBetween.filter(
                    (date) =>
                        date.year === d.dateObj.year && date.month === d.dateObj.month && date.day === d.dateObj.day,
                ).length > 0
            );
        }
    }
    startHover(d) {
        return (
            this.datesBetween.length > 0 &&
            this.isStartDay &&
            this.isHovered(d) &&
            this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 })
        );
    }
    dueHover(d) {
        return (
            this.datesBetween.length > 0 &&
            !this.isStartDay &&
            this.isHovered(d) &&
            this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 })
        );
    }
    getDatesBetween(start: IMyDate, end: IMyDate): Array<IMyDate> {
        const startDate = this.getDate(start.year, start.month, start.day);
        const endDate = this.getDate(end.year, end.month, end.day);
        let currentDate = new Date(startDate);
        const stopDate = new Date(endDate);
        const datesBetween = [];
        while (currentDate <= stopDate) {
            const currDate = {
                year: new Date(currentDate).year(),
                month: new Date(currentDate).month() + 1,
                day: new Date(currentDate).date(),
            };
            datesBetween.push(currDate);
            currentDate = new Date(currentDate).add(1, 'day');
        }
        return datesBetween;
    }
    compareObjects(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    }
    handleHover(d) {
        this.currHoverDate = d.dateObj;
        if (
            !this.isStartDay &&
            this.disableUntil !== null &&
            this.disableUntil !== undefined &&
            this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 })
        ) {
            this.datesBetween = this.getDatesBetween(this.opts.disableUntil, this.currHoverDate);
        } else if (
            this.isStartDay &&
            this.disableSince !== null &&
            this.disableSince !== undefined &&
            this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 })
        ) {
            this.datesBetween = this.getDatesBetween(this.currHoverDate, this.opts.disableSince);
        }
    }
    isSelectedDate(d) {
        return (
            d.cmo === this.currMonthId &&
            this.selectedDate.day === d.dateObj.day &&
            this.selectedDate.month === d.dateObj.month &&
            this.selectedDate.year === d.dateObj.year
        );
    }
    isEndDate(d) {
        if (this.opts.disableSince == null) {
            return;
        } else {
            return (
                d.cmo === this.currMonthId &&
                this.opts.disableSince.day === d.dateObj.day &&
                this.opts.disableSince.month === d.dateObj.month &&
                this.opts.disableSince.year === d.dateObj.year
            );
        }
    }
    isStartDate(d) {
        if (this.opts.disableUntil == null) {
            return false;
        } else {
            return (
                d.cmo === this.currMonthId &&
                this.opts.disableUntil.day === d.dateObj.day &&
                this.opts.disableUntil.month === d.dateObj.month &&
                this.opts.disableUntil.year === d.dateObj.year
            );
        }
    }
    isSelectedStartDay(d) {
        return this.isSelectedDate(d) && this.isStartDay;
    }
    isSelectedEndDay(d) {
        return this.isSelectedDate(d) && !this.isStartDay;
    }
    isSameSelectedDay(d) {
        if (this.opts.disableUntil != null && this.opts.disableSince != null) {
            return (
                (this.isEndDate(d) && this.isSelectedStartDay(d)) || (this.isStartDate(d) && this.isSelectedEndDay(d))
            );
        }
    }

    closeDropdown(event) {
        event.stopPropagation();
        this.showSelector = false;
        this.detectChanges();
        this.calendarToggle.emit(CalToggle.CloseByOutClick);
        if (this.opts.editableMonthAndYear) {
            this.resetMonthYearEdit();
        }
        this.close();
    }

    setLocaleOptions(): void {
        const opts: IMyOptions = this.localeService.getLocaleOptions(this.locale);
        Object.keys(opts).forEach((k) => {
            (this.opts as IMyOptions)[k] = opts[k];
        });
    }

    setOptions(): void {
        if (this.options !== undefined) {
            Object.keys(this.options).forEach((k) => {
                (this.opts as IMyOptions)[k] = this.options[k];
            });
        }
        if (this.opts.minYear < Year.min) {
            this.opts.minYear = Year.min;
        }
        if (this.opts.maxYear > Year.max) {
            this.opts.maxYear = Year.max;
        }
        if (this.disabled !== undefined) {
            this.opts.componentDisabled = this.disabled;
        }

        if (this.disableSince !== undefined) {
            this.opts.disableSince = this.disableSince;
        } else {
            this.opts.disableSince = undefined;
        }

        if (this.disableUntil !== undefined) {
            this.opts.disableUntil = this.disableUntil;
        } else {
            this.opts.disableUntil = undefined;
        }

        const separator: string = this.utilService.getDateFormatSeparator(this.opts.dateFormat);
        this.autoFillOpts = {
            separator,
            formatParts: this.opts.dateFormat.split(separator),
            enabled: this.opts.inputAutoFill,
        };
    }

    getSelectorTopPosition(): string {
        if (this.opts.openSelectorTopOfInput) {
            return this.elem.nativeElement.children[0].offsetHeight + 'px';
        }
    }

    resetMonthYearEdit(): void {
        this.editMonth = false;
        this.editYear = false;
        this.invalidMonth = false;
        this.invalidYear = false;
    }

    editMonthClicked(event: any): void {
        event.stopPropagation();
        if (this.opts.editableMonthAndYear) {
            this.editMonth = true;
        }
    }

    editYearClicked(event: any): void {
        event.stopPropagation();
        if (this.opts.editableMonthAndYear) {
            this.editYear = true;
        }
    }

    onFocusInput(event: any): void {
        this.inputFocusBlur.emit({ reason: InputFocusBlur.focus, value: event.target.value });
    }

    onBlurInput(event: any): void {
        this.selectionDayText = event.target.value;
        this.onTouchedCb();
        this.inputFocusBlur.emit({ reason: InputFocusBlur.blur, value: event.target.value });
    }

    onUserMonthInput(value: string): void {
        this.invalidMonth = false;
        const m: number = this.utilService.isMonthLabelValid(value, this.opts.monthLabels);
        if (m !== -1) {
            this.editMonth = false;
            if (m !== this.visibleMonth.monthNbr) {
                this.visibleMonth = { monthText: this.monthText(m), monthNbr: m, year: this.visibleMonth.year };
                this.generateCalendar(m, this.visibleMonth.year, true);
            }
        } else {
            this.invalidMonth = true;
        }
    }

    onUserYearInput(value: string): void {
        this.invalidYear = false;
        const y: number = this.utilService.isYearLabelValid(Number(value), this.opts.minYear, this.opts.maxYear);
        if (y !== -1) {
            this.editYear = false;
            if (y !== this.visibleMonth.year) {
                this.visibleMonth = {
                    monthText: this.visibleMonth.monthText,
                    monthNbr: this.visibleMonth.monthNbr,
                    year: y,
                };
                this.generateCalendar(this.visibleMonth.monthNbr, y, true);
            }
        } else {
            this.invalidYear = true;
        }
    }

    isTodayDisabled(): void {
        this.disableTodayBtn = this.utilService.isDisabledDay(
            this.getToday(),
            this.opts.disableUntil,
            this.opts.disableSince,
            this.opts.disableWeekends,
            this.opts.disableDays,
            this.opts.disableDateRanges,
            this.opts.enableDays,
        );
    }

    parseOptions(): void {
        if (this.locale) {
            this.setLocaleOptions();
        }
        this.setOptions();
        this.isTodayDisabled();
        this.dayIdx = this.weekDayOpts.indexOf(this.opts.firstDayOfWeek);
        if (this.dayIdx !== -1) {
            let idx: number = this.dayIdx;
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < this.weekDayOpts.length; i++) {
                this.weekDays.push(this.opts.dayLabels[this.weekDayOpts[idx]]);
                idx = this.weekDayOpts[idx] === 'sa' ? 0 : idx + 1;
            }
        }
    }

    writeValue(value: any): void {
        if (value && value.date) {
            this.updateDateValue(this.parseSelectedDate(value.date), false);
        } else if (value === '') {
            this.updateDateValue({ year: 0, month: 0, day: 0 }, true);
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeCb = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCb = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('selector') && changes.selector.currentValue > 0) {
            this.openBtnClicked();
        }

        if (changes.hasOwnProperty('placeholder')) {
            this.placeholder = changes.placeholder.currentValue;
        }

        if (changes.hasOwnProperty('locale')) {
            this.locale = changes.locale.currentValue;
        }

        if (changes.hasOwnProperty('disabled')) {
            this.disabled = changes.disabled.currentValue;
        }

        if (changes.hasOwnProperty('options')) {
            this.options = changes.options.currentValue;
        }

        this.weekDays.length = 0;
        this.parseOptions();

        if (changes.hasOwnProperty('defaultMonth')) {
            const dm: string = changes.defaultMonth.currentValue;
            if (dm !== null && dm !== undefined && dm !== '') {
                this.selectedMonth = this.parseSelectedMonth(dm);
            } else {
                this.selectedMonth = { monthText: '', monthNbr: 0, year: 0 };
            }
        }

        if (changes.hasOwnProperty('selDate')) {
            const sd: any = changes.selDate;
            if (
                sd.currentValue !== null &&
                sd.currentValue !== undefined &&
                sd.currentValue !== '' &&
                Object.keys(sd.currentValue).length !== 0
            ) {
                this.selectedDate = this.parseSelectedDate(sd.currentValue);
                setTimeout(() => {
                    this.onChangeCb(this.getDateModel(this.selectedDate));
                });
            } else {
                // Do not clear on init
                if (!sd.isFirstChange()) {
                    this.clearDate();
                }
            }
        }
        if (this.opts.inline) {
            this.setVisibleMonth();
        } else if (this.showSelector) {
            this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, false);
        }
    }

    reset(): void {
        // Remove date button clicked
        this.resetDate();
        if (this.showSelector) {
            this.calendarToggle.emit(CalToggle.CloseByCalBtn);
        }
        this.showSelector = false;
        this.detectChanges();
        this.close();
    }

    openBtnClicked(): void {
        // Open selector button clicked
        this.showSelector = !this.showSelector;
        if (this.showSelector) {
            this.setVisibleMonth();
            this.calendarToggle.emit(CalToggle.Open);
        } else {
            this.calendarToggle.emit(CalToggle.CloseByCalBtn);
        }
        this.detectChanges();
    }

    setVisibleMonth(): void {
        // Sets visible month of calendar
        let y = 0;
        let m = 0;
        if (!this.utilService.isInitializedDate(this.selectedDate)) {
            if (this.selectedMonth.year === 0 && this.selectedMonth.monthNbr === 0) {
                const today: IMyDate = this.getToday();
                y = today.year;
                m = today.month;
            } else {
                y = this.selectedMonth.year;
                m = this.selectedMonth.monthNbr;
            }
        } else {
            y = this.selectedDate.year;
            m = this.selectedDate.month;
        }
        this.visibleMonth = { monthText: this.monthText(m), monthNbr: m, year: y };

        // Create current month
        this.generateCalendar(m, y, true);
    }

    prevMonth(): void {
        // Previous month from calendar
        const d: Date = this.getDate(this.visibleMonth.year, this.visibleMonth.monthNbr, 1);
        d.setMonth(d.getMonth() - 1);

        const y: number = d.getFullYear();
        const m: number = d.getMonth() + 1;

        this.visibleMonth = { monthText: this.monthText(m), monthNbr: m, year: y };
        this.generateCalendar(m, y, true);
    }

    nextMonth(): void {
        // Next month from calendar
        const d: Date = this.getDate(this.visibleMonth.year, this.visibleMonth.monthNbr, 1);
        d.setMonth(d.getMonth() + 1);

        const y: number = d.getFullYear();
        const m: number = d.getMonth() + 1;

        this.visibleMonth = { monthText: this.monthText(m), monthNbr: m, year: y };
        this.generateCalendar(m, y, true);
    }

    prevYear(): void {
        // Previous year from calendar
        this.visibleMonth.year--;
        this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, true);
    }

    nextYear(): void {
        // Next year from calendar
        this.visibleMonth.year++;
        this.generateCalendar(this.visibleMonth.monthNbr, this.visibleMonth.year, true);
    }

    todayClicked(): void {
        // Today button clicked
        const today: IMyDate = this.getToday();
        this.selectDate(today);
        const dateModel: IMyDateModel = this.getDateModel(this.selectedDate);
        this.dateChanged.emit(dateModel);
        this.selectAndClose();

        if ((this.opts.inline && today.year !== this.visibleMonth.year) || today.month !== this.visibleMonth.monthNbr) {
            this.visibleMonth = { monthText: this.monthText(today.month), monthNbr: today.month, year: today.year };
            this.generateCalendar(today.month, today.year, true);
        }
    }

    cellClicked(cell: any): void {
        // Cell clicked on the calendar
        if (cell.cmo === this.prevMonthId) {
            // Previous month day
            this.prevMonth();
        } else if (cell.cmo === this.currMonthId) {
            // Current month day - if date is already selected clear it
            if (
                cell.dateObj.year === this.selectedDate.year &&
                cell.dateObj.month === this.selectedDate.month &&
                cell.dateObj.day === this.selectedDate.day
            ) {
                this.clearDate();
            } else {
                this.selectDate(cell.dateObj);
                this.selectAndClose();
            }
        } else if (cell.cmo === this.nextMonthId) {
            // Next month day
            this.nextMonth();
        }
        this.resetMonthYearEdit();
    }

    cellKeyDown(event: any, cell: any) {
        // Cell keyboard handling
        if ((event.keyCode === KeyCode.enter || event.keyCode === KeyCode.space) && !cell.disabled) {
            event.preventDefault();
            this.cellClicked(cell);
        }
    }

    clearDate(event?): void {
        if (event) {
            event.stopPropagation();
        }
        // Clears the date and notifies parent using callbacks and value accessor
        const date: IMyDate = { year: 0, month: 0, day: 0 };
        this.datesBetween = [];
        this.updateDateValue(date, true);
    }

    resetDate(event?): void {
        if (event) {
            event.stopPropagation();
        }
        const date: IMyDate = { year: 0, month: 0, day: 0 };
        this.datesBetween = [];
        this.resetDates.emit(true);
        this.onChangeCb('');
        this.onTouchedCb();
        this.updateDateValue(date, true);
        this.showSelector = false;
    }

    selectDate(date: IMyDate): void {
        // Date selected, notifies parent using callbacks and value accessor
        this.updateDateValue(date, false);
        this.detectChanges();
    }
    selectAndClose() {
        const dateModel: IMyDateModel = this.getDateModel(this.selectedDate);
        this.dateChanged.emit(dateModel);
        this.onChangeCb(dateModel);
        this.onTouchedCb();
        if (this.showSelector) {
            this.calendarToggle.emit(CalToggle.CloseByDateSel);
        }
        this.showSelector = false;
        this.close();
    }
    updateDateValue(date: IMyDate, clear: boolean): void {
        // Updates date values
        this.selectedDate = date;
        this.selectionDayText = clear ? '' : this.formatDate(date);
        this.inputFieldChanged.emit({ value: this.selectionDayText, dateFormat: this.opts.dateFormat, valid: !clear });
        this.invalidDate = false;

        if (
            this.isStartDay &&
            !!this.disableSince &&
            !this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 })
        ) {
            this.datesBetween = this.getDatesBetween(this.selectedDate, this.disableSince);
        } else if (
            !this.isStartDay &&
            !!this.disableUntil &&
            !this.compareObjects(this.selectedDate, { year: 0, month: 0, day: 0 })
        ) {
            this.datesBetween = this.getDatesBetween(this.disableUntil, this.selectedDate);
        }
        if (clear) {
            this.datesBetween = [];
        }
        this.detectChanges();
    }

    getDateModel(date: IMyDate): IMyDateModel {
        // Creates a date model object from the given parameter
        return {
            date,
            jsdate: this.getDate(date.year, date.month, date.day),
            formatted: this.formatDate(date),
            epoc: Math.round(this.getTimeInMilliseconds(date) / 1000.0),
        };
    }

    preZero(val: string): string {
        // Prepend zero if smaller than 10
        return parseInt(val, 10) < 10 ? '0' + val : val;
    }

    formatDate(val: any): string {
        const date = this.getDate(val.year, val.month, val.day);
        const weekDay = new Date(date).format('dd');

        // Returns formatted date string, if mmm is part of dateFormat returns month as a string
        const formatted: string = this.opts.dateFormat
            .replace('yyyy', val.year)
            .replace('dd', this.preZero(val.day))
            .replace('WWW', weekDay);
        return this.opts.dateFormat.indexOf('mmm') !== -1
            ? formatted.replace('mmm', this.montShorthText(val.month))
            : formatted.replace('mm', this.preZero(val.month));
    }
    formatInputDate(val: any): string {
        const date = this.getDate(val.year, val.month, val.day);
        const weekDay = new Date(date).format('dd');

        // Returns formatted date string, if mmm is part of dateFormat returns month as a string
        const formatted: string = this.opts.dateFormat
            .replace('yyyy', val.year)
            .replace('dd', this.preZero(val.day))
            .replace('WWW', weekDay);
        return this.opts.dateFormat.indexOf('mmm') !== -1
            ? `${weekDay}. ${formatted.replace('mmm', this.monthText(val.month))}`
            : `${weekDay}, ${formatted.replace('mm', this.preZero(val.month))}`;
    }

    monthText(m: number): string {
        // Returns month as a text
        const monthLabels = {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December',
        };
        // return this.opts.monthLabels[m];
        return monthLabels[m];
    }

    montShorthText(m: number): string {
        // Returns month as a text
        const monthLabels = {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sept',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec',
        };
        return monthLabels[m];
    }

    monthStartIdx(y: number, m: number): number {
        // Month start index
        const d = new Date();
        d.setDate(1);
        d.setMonth(m - 1);
        d.setFullYear(y);
        const idx = d.getDay() + this.sundayIdx();
        return idx >= 7 ? idx - 7 : idx;
    }

    daysInMonth(m: number, y: number): number {
        // Return number of days of current month
        return new Date(y, m, 0).getDate();
    }

    daysInPrevMonth(m: number, y: number): number {
        // Return number of days of the previous month
        const d: Date = this.getDate(y, m, 1);
        d.setMonth(d.getMonth() - 1);
        return this.daysInMonth(d.getMonth() + 1, d.getFullYear());
    }

    isCurrDay(d: number, m: number, y: number, cmo: number, today: IMyDate): boolean {
        // Check is a given date the today
        return d === today.day && m === today.month && y === today.year && cmo === this.currMonthId;
    }

    getToday(): IMyDate {
        const date: Date = new Date();
        return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }

    getTimeInMilliseconds(date: IMyDate): number {
        return this.getDate(date.year, date.month, date.day).getTime();
    }

    getWeekday(date: IMyDate): string {
        // Get weekday: su, mo, tu, we ...
        return this.weekDayOpts[this.utilService.getDayNumber(date)];
    }

    getDate(year: number, month: number, day: number): Date {
        // Creates a date object from given year, month and day
        return new Date(year, month - 1, day, 0, 0, 0, 0);
    }

    sundayIdx(): number {
        // Index of Sunday day
        return this.dayIdx > 0 ? 7 - this.dayIdx : 0;
    }

    generateCalendar(m: number, y: number, notifyChange: boolean): void {
        this.dates.length = 0;
        const today: IMyDate = this.getToday();
        const monthStart: number = this.monthStartIdx(y, m);
        const dInThisM: number = this.daysInMonth(m, y);
        const dInPrevM: number = this.daysInPrevMonth(m, y);

        let dayNbr = 1;
        let cmo: number = this.prevMonthId;
        for (let i = 1; i < 7; i++) {
            const week: Array<IMyCalendarDay> = [];
            if (i === 1) {
                // First week
                const pm = dInPrevM - monthStart + 1;
                // Previous month
                for (let j = pm; j <= dInPrevM; j++) {
                    const date: IMyDate = { year: y, month: m - 1, day: j };
                    week.push({
                        dateObj: date,
                        cmo,
                        currDay: this.isCurrDay(j, m, y, cmo, today),
                        dayNbr: this.utilService.getDayNumber(date),
                        disabled: this.utilService.isDisabledDay(
                            date,
                            this.opts.disableUntil,
                            this.opts.disableSince,
                            this.opts.disableWeekends,
                            this.opts.disableDays,
                            this.opts.disableDateRanges,
                            this.opts.enableDays,
                        ),
                        markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
                    });
                }

                cmo = this.currMonthId;
                // Current month
                const daysLeft: number = 7 - week.length;
                for (let j = 0; j < daysLeft; j++) {
                    const date: IMyDate = { year: y, month: m, day: dayNbr };
                    week.push({
                        dateObj: date,
                        cmo,
                        currDay: this.isCurrDay(dayNbr, m, y, cmo, today),
                        dayNbr: this.utilService.getDayNumber(date),
                        disabled: this.utilService.isDisabledDay(
                            date,
                            this.opts.disableUntil,
                            this.opts.disableSince,
                            this.opts.disableWeekends,
                            this.opts.disableDays,
                            this.opts.disableDateRanges,
                            this.opts.enableDays,
                        ),
                        markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
                    });
                    dayNbr++;
                }
            } else {
                // Rest of the weeks
                for (let j = 1; j < 8; j++) {
                    if (dayNbr > dInThisM) {
                        // Next month
                        dayNbr = 1;
                        cmo = this.nextMonthId;
                    }
                    const date: IMyDate = { year: y, month: cmo === this.currMonthId ? m : m + 1, day: dayNbr };
                    week.push({
                        dateObj: date,
                        cmo,
                        currDay: this.isCurrDay(dayNbr, m, y, cmo, today),
                        dayNbr: this.utilService.getDayNumber(date),
                        disabled: this.utilService.isDisabledDay(
                            date,
                            this.opts.disableUntil,
                            this.opts.disableSince,
                            this.opts.disableWeekends,
                            this.opts.disableDays,
                            this.opts.disableDateRanges,
                            this.opts.enableDays,
                        ),
                        markedDate: this.utilService.isMarkedDate(date, this.opts.markDates, this.opts.markWeekends),
                    });
                    dayNbr++;
                }
            }
            const weekNbr: number =
                this.opts.showWeekNumbers && this.opts.firstDayOfWeek === 'mo'
                    ? this.utilService.getWeekNumber(week[0].dateObj)
                    : 0;
            this.dates.push({ week, weekNbr });
            this.detectChanges();
        }

        this.setHeaderBtnDisabledState(m, y);

        if (notifyChange) {
            // Notify parent
            this.calendarViewChanged.emit({
                year: y,
                month: m,
                first: { number: 1, weekday: this.getWeekday({ year: y, month: m, day: 1 }) },
                last: { number: dInThisM, weekday: this.getWeekday({ year: y, month: m, day: dInThisM }) },
            });
        }
    }
    parseSelectedDate(selDate: any): IMyDate {
        // Parse selDate value - it can be string or IMyDate object
        let date: IMyDate = { day: 0, month: 0, year: 0 };
        if (typeof selDate === 'string') {
            const sd: string = selDate as string;
            date.day = this.utilService.parseDatePartNumber(this.opts.dateFormat, sd, 'dd');

            date.month =
                this.opts.dateFormat.indexOf('mmm') !== -1
                    ? this.utilService.parseDatePartMonthName(this.opts.dateFormat, sd, 'mmm', this.opts.monthLabels)
                    : this.utilService.parseDatePartNumber(this.opts.dateFormat, sd, 'mm');

            date.year = this.utilService.parseDatePartNumber(this.opts.dateFormat, sd, 'yyyy');
        } else if (typeof selDate === 'object') {
            date = selDate;
        }
        this.selectionDayText = this.formatDate(date);
        this.selectionInputText = this.formatInputDate(date);
        return date;
    }

    parseSelectedMonth(ms: string): IMyMonth {
        return this.utilService.parseDefaultMonth(ms);
    }

    setHeaderBtnDisabledState(m: number, y: number): void {
        let dpm = false;
        let dpy = false;
        let dnm = false;
        let dny = false;
        if (this.opts.disableHeaderButtons) {
            dpm = this.utilService.isMonthDisabledByDisableUntil(
                {
                    year: m === 1 ? y - 1 : y,
                    month: m === 1 ? 12 : m - 1,
                    day: this.daysInMonth(m === 1 ? 12 : m - 1, m === 1 ? y - 1 : y),
                },
                this.opts.disableUntil,
            );
            dpy = this.utilService.isMonthDisabledByDisableUntil(
                { year: y - 1, month: m, day: this.daysInMonth(m, y - 1) },
                this.opts.disableUntil,
            );
            dnm = this.utilService.isMonthDisabledByDisableSince(
                { year: m === 12 ? y + 1 : y, month: m === 12 ? 1 : m + 1, day: 1 },
                this.opts.disableSince,
            );
            dny = this.utilService.isMonthDisabledByDisableSince(
                { year: y + 1, month: m, day: 1 },
                this.opts.disableSince,
            );
        }
        this.prevMonthDisabled = (m === 1 && y === this.opts.minYear) || dpm;
        this.prevYearDisabled = y - 1 < this.opts.minYear || dpy;
        this.nextMonthDisabled = (m === 12 && y === this.opts.maxYear) || dnm;
        this.nextYearDisabled = y + 1 > this.opts.maxYear || dny;
        this.cdr.detectChanges();
    }

    /**
     * Stupid workaround to make it work in the canvas.
     * There was a problem when this component was inside a component that gets destroyed by a
     * NodeViewBuilder.
     * We should get rid of this date picker component in the future.
     */
    detectChanges() {
        try {
            this.cdr.detectChanges();
        } catch (e) {}
    }
}
