import { Component, HostBinding, Input } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';
import { IsOverduePipe } from '../../../common/pipe/isOverdue.pipe';

@Component({
    selector: 'ruum-task-list-cell-due-date',
    template: `
        <div
            *ngIf="task.dueDate"
            class="d-flex small py-2"
            [class.text-dark]="!isOverDueDate(task)"
            [class.text-danger]="isOverDueDate(task)"
        >
            {{ formatDate(task.dueDate) }}
        </div>
    `,
})
export class TaskListCellDueDateComponent {
    @HostBinding() class = 'd-flex align-items-center';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }

    task: any = {};

    constructor(private dateIsOverdue: IsOverduePipe) {}

    formatDate(day: Day): string {
        return day && new Date(day.year, day.month - 1, day.day, 0, 0, 0, 0).format('DD MMM YYYY');
    }

    isOverDueDate(task) {
        return task.status !== 'DONE' && this.dateIsOverdue.transform(task.dueDate);
    }
}
