import { EntityAction, RuumAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../../../../common/connectors/paginatedList.model';
import { getDefaultPaginatedList } from '../../../../lobby/lobby.reducer';
import { WebhookEntityEventListItem } from '../../webhooks.model';

export enum WEBHOOK_ENTITY_EVENTS_LIST_ACTION_TYPES {
    WEBHOOK_ENTITY_EVENTS_LOADED = 'WEBHOOK_ENTITY_EVENTS_LOADED',
}

export type WebhookEntityEventsLoadedAction = EntityAction<
    'WEBHOOK_ENTITY_EVENTS_LOADED',
    {
        webhookId: string;
        entityId: string;
        page: PaginatedList<WebhookEntityEventListItem>;
    }
>;

export function WebhooksEntityEventsListReducer(
    currentState: PaginatedList<WebhookEntityEventListItem> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<WebhookEntityEventListItem> {
    switch (action.type) {
        case WEBHOOK_ENTITY_EVENTS_LIST_ACTION_TYPES.WEBHOOK_ENTITY_EVENTS_LOADED:
            return eventsLoaded(currentState, action);
        default:
            return currentState;
    }
}

function eventsLoaded(
    currentState: PaginatedList<WebhookEntityEventListItem>,
    action: WebhookEntityEventsLoadedAction,
): PaginatedList<WebhookEntityEventListItem> {
    const data = action.payload.page;
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}
