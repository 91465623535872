import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpNoCacheInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method === 'GET') {
            const customRequest = request.clone({ setParams: { _no_ie_cache: `${Date.now()}` } });
            return next.handle(customRequest);
        }
        return next.handle(request);
    }
}
