import { animate, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, take, withLatestFrom } from 'rxjs/operators';
import { MaskAnimType, PopupAnimType, RuumPopupBase } from './ruum-popup-base';
import { RuumPopupService } from './ruum-popup-container.service';

@Component({
    selector: 'ruum-popup-container',
    template: `
        <div
            class="mask"
            *ngIf="openedPopup"
            [style.backgroundColor]="maskBackground"
            (click)="clickOnMask()"
            (keydown.space)="clickOnMask()"
            (keydown.enter)="clickOnMask()"
            [@mask]="maskAnim"
        ></div>
        <div
            class="popup-container"
            style="display: none"
            [ngStyle]="popupPos$ | async"
            #popup
            [@popup]="popupAnim"
            (@popup.done)="popupAnimDone($event)"
        >
            <ng-container #content></ng-container>
        </div>
    `,
    animations: [
        trigger('mask', [
            transition('void => fade', [style({ opacity: 0 }), animate('200ms ease-out', style({ opacity: 1 }))]),
            transition('fade => void', [style({ opacity: 1 }), animate('200ms ease-out', style({ opacity: 0 }))]),
        ]),
        trigger('popup', [
            transition('out => slideBottom', [
                style({ transform: 'translateY(100%)' }),
                animate('200ms ease-out', style({ transform: 'translateY(0)' })),
            ]),
            transition('slideBottom => out', [
                style({ transform: 'translateY(0)' }),
                animate('200ms ease-out', style({ transform: 'translateY(100%)' })),
            ]),
            transition('out => slideLeft', [
                style({ transform: 'translateX(-100%)' }),
                animate('200ms ease-out', style({ transform: 'translateX(0)' })),
            ]),
            transition('slideLeft => out', [
                style({ transform: 'translateX(0)' }),
                animate('200ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
            transition('out => slideTop', [
                style({ transform: 'translateY(-50px)' }),
                animate('200ms ease-out', style({ transform: 'translateY(0)' })),
            ]),
            transition('slideTop => out', [
                style({ transform: 'translateX(0)' }),
                animate('200ms ease-out', style({ transform: 'translateX(-50px)' })),
            ]),
            transition('out => fade', [style({ opacity: 0 }), animate('200ms ease-out', style({ opacity: 1 }))]),
            transition('fade => out', [style({ opacity: 1 }), animate('200ms ease-out', style({ opacity: 0 }))]),
        ]),
    ],
    // TODO: fix z-index. Use bootstrap class names
    styles: [
        `
            :host {
                position: absolute;
            }
            .mask,
            .popup-container {
                position: fixed;
            }
            .mask {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1060;
            }
            .popup-container {
                z-index: 1061;
            }
            .mask {
                color: rgba(0, 0, 0, 0.1);
            }
        `,
    ],
})
export class RuumPopupContainerComponent implements OnDestroy {
    @ViewChild('content', { read: ViewContainerRef, static: true }) contentAnchor: ViewContainerRef;
    @ViewChild('popup', { static: true }) popupElem: ElementRef;
    openedPopup: ComponentRef<RuumPopupBase> | null = null;
    maskBackground: string;
    popupPos$: Observable<{
        left: string;
        top: string;
        right: string;
        bottom: string;
        height: string;
        width: string;
    }>;

    maskAnim: MaskAnimType;

    popupAnim: PopupAnimType | 'out' = 'out';

    closeData$: Subject<any> = new Subject<any>();
    popupLeftDone$: Subject<any> = new Subject<any>();
    popupAnimDone$ = new Subject<boolean>();

    private popupAnimDoneSubscription: Subscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private ruumPopupService: RuumPopupService,
        private cdr: ChangeDetectorRef,
    ) {
        this.ruumPopupService.init(this);
        this.popupAnimDoneSubscription = this.popupAnimDone$.pipe(debounceTime(100)).subscribe(() => {
            this.popupElem.nativeElement.style.display = 'none';
            this.contentAnchor.clear();
            this.cdr.detectChanges();
            this.popupLeftDone$.next();
        });
    }

    showPopup(popupComponent: Type<RuumPopupBase>, argus: {} = {}): Observable<any> {
        this.contentAnchor.clear();

        // Make sure the dom is cleared
        this.cdr.detectChanges();

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(popupComponent);
        const componentRef = this.contentAnchor.createComponent(componentFactory);
        componentRef.instance.popupContainer = this;
        // Set the Input values for the component
        // eslint-disable-next-line guard-for-in
        for (const key in argus) {
            componentRef.instance[key] = argus[key];
        }

        /**
         * Display CSS must be set by code because the timing issue for Popup Component:
         * The container must to be displayed before the popup component initialization,
         * otherwise it's impossible for the popup to get it's own size by getClientRects()
         */
        this.popupElem.nativeElement.style.display = 'block';

        // Set the background of mask by the setting of popup
        this.maskBackground = componentRef.instance.getMaskColor();
        this.maskAnim = componentRef.instance.getMaskAnim();
        this.popupAnim = componentRef.instance.getPopupAnim();
        // Set the position of the popup
        this.popupPos$ = componentRef.instance.getPos().pipe(
            map((pos) => {
                const popupPos = {
                    left: 'auto',
                    top: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                    height: 'auto',
                    width: 'auto',
                };
                for (const key in pos) {
                    if (typeof pos[key] === 'number') {
                        popupPos[key] = `${pos[key]}px`;
                    } else {
                        popupPos[key] = pos[key];
                    }
                }
                return popupPos;
            }),
        );

        if (componentRef.instance.noMask()) {
            this.openedPopup = null;
        } else {
            this.openedPopup = componentRef;
        }
        // Force dirty check, this function is called outside the component scope so force check is required
        this.cdr.detectChanges();

        return this.popupLeftDone$.pipe(
            withLatestFrom(this.closeData$.pipe(take(1)), (_, data) => data),
            take(1),
        );
    }

    close(data?) {
        this.closeData$.next(data);
        this.openedPopup = null;
        this.popupAnim = 'out';
        this.cdr.detectChanges();
    }

    popupAnimDone(event) {
        if (event.toState === 'out') {
            this.popupAnimDone$.next();
        }
    }

    ngOnDestroy() {
        this.closeData$.complete();
        this.popupAnimDoneSubscription.unsubscribe();
    }

    clickOnMask() {
        if (
            this.openedPopup &&
            !this.openedPopup.instance.customMaskClick() &&
            this.openedPopup.instance.closeOnMaskClick()
        ) {
            this.close();
        }
    }
}
