import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadSpinnerModule } from '../load-spinner/load-spinner.module';
import { LoadMoreComponent } from './load-more.component';

@NgModule({
    imports: [CommonModule, LoadSpinnerModule],
    declarations: [LoadMoreComponent],
    exports: [LoadMoreComponent],
})
export class LoadMoreModule {}
