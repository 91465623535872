import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'form-label',
    template: `
        <div class="d-flex" [style.width]="'110px'">
            <button area-label="Label" class="btn btn-xs btn-link-secondary btn-without-hover px-0 my-1">
                <i *ngIf="!!icon" [class]="getIconClass()"></i>
                <span class="text-uppercase pl-2">{{ text }}</span>
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {
    @Input() text: string;
    @Input() icon: string;

    getIconClass() {
        return `icon ${this.icon}`;
    }
}
