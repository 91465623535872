import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { componentHelper } from '../ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../ui-components.type';

@Component({
    selector: 'ruum-profile-template',
    template: `
        <i class="icon" [ngClass]="icon"></i>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileTemplateComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            'btn-icon',
            componentHelper.getDefaultClassName(),
            componentHelper.getSizeClassName(this.size),
            componentHelper.getThemeClassName(this.theme, this.type),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getDisabledClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @Input() icon = 'icon-template';
    @Input() size: ComponentSize = 'lg';
    @Input() theme: ComponentTheme = 'primary-light';
    @Input() hover = false;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    type: ComponentType = 'default';
    rounded = false;

    constructor() {}

    ngOnInit() {}
}
