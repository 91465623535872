import { Day } from '@ruum/ruum-reducers';
import { from as observableFrom, throwError as observableThrowError, timer as observableTimer } from 'rxjs';
import { ProjectParticipantWithAllRoles } from './connectors/readModelConnector.service';

export const generalTitle = 'Ruum';

/**
 * Transform hex color to rgba so that we can add opacity.
 */
export function hexColorToRgba(hex: string, opacity: number) {
    if (!hex) {
        return `rgba(0,0,0,${opacity})`;
    }
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive)
 */
export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns a random string of length 'characters'.
 */
export function randomStringOfSize(characters: number) {
    return Math.random()
        .toString(36)
        .substring(2, characters + 2);
}

export function isMobile() {
    return /Android|iPhone|iPod/i.test(navigator.userAgent);
}

export function isMac() {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
}

export function isChrome() {
    const reg = new RegExp(/(Chrome)\/((\d+)?[\w\.]+)/i);
    return reg.test(navigator.userAgent);
}

export function isFirefox() {
    const reg = new RegExp(/^(?!.*Seamonkey)(?=.*Firefox).*/i);
    return reg.test(navigator.userAgent);
}

/**
 * Mix the two color
 * @param colorA color in #RRGGBB format
 * @param colorB color in #RRGGBB format
 * @param ratio ratio from 0 to 1
 */
export function mixColor(colorA: string, colorB: string = '#FFFFFF', ratio: number = 0.1): string {
    const colorRE = /^#([0-9a-fA-F][0-9a-fA-F])([0-9a-fA-F][0-9a-fA-F])([0-9a-fA-F][0-9a-fA-F])$/;
    const pA = colorRE.exec(colorA);
    const pB = colorRE.exec(colorB);
    if (!pA || !pB) {
        return 'white';
    }
    return (
        '#' +
        [1, 2, 3]
            .map((index) => [pA[index], pB[index]])
            .map((parts) => parts.map((s) => parseInt(s, 16)))
            .map(([source, target]) => Math.floor(source * ratio + target * (1 - ratio)))
            .map((n) => ('00' + n.toString(16)).substr(-2))
            .join('')
    );
}

/**
 * Gets the given url parameter from the current window's path (if available).
 */
export function getUrlParameter(parameter: string, hashBased?: boolean) {
    const urlParameters: string = hashBased ? window.location.href : window.location.search;
    if (urlParameters.indexOf(parameter + '=') === -1) {
        return;
    }
    const startIndex: number = urlParameters.indexOf(`${parameter}=`) + parameter.length + 1;
    const endIndex: number = urlParameters.indexOf('&', startIndex);
    return urlParameters.substring(startIndex, endIndex === -1 ? undefined : endIndex);
}

const prefix = String.raw`(http:\/\/|https:\/\/)`;
const validCharacters = String.raw`[\.\d\w\/\-\*_@!?+&:;%#$=(),']`;
export const VALID_URL = String.raw`${prefix}${validCharacters}+\.\w{2,}${validCharacters}*`;
export const HTTP_URL_REGEX = new RegExp(String.raw`(${VALID_URL})\s*$`);

export function retryWhenHandler(numberOfAttempts: number, delay: number, ignoreFailing?: boolean) {
    return (attempts) => {
        let count = 0;
        if (ignoreFailing) {
            return observableFrom('');
        }
        return attempts.flatMap((error) => {
            return ++count >= numberOfAttempts ? observableThrowError(error) : observableTimer(count * delay);
        });
    };
}

export function assertUnreachable(x: never): never {
    throw new Error(`Didn't expect to get here`);
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function timestampToDay(time: number): Day {
    const date = new Date(time);
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
    };
}

export function dayToTimestamp(day: Day): number {
    return new Date(day.year, day.month - 1, day.day).getTime();
}

/** checks whether two given days are the same day */
export function equalDays(aDay: Day, anotherDay: Day): boolean {
    if (!aDay || !anotherDay) {
        return false;
    }
    return aDay.day === anotherDay.day && aDay.month === anotherDay.month && aDay.year === anotherDay.year;
}

export function deepEqual(objA: any, objB: any): boolean {
    if (typeof objA !== typeof objB) {
        return false;
    }

    /** non-object */
    if (typeof objA !== 'object' || objA === null || objB === null) {
        return objA === objB;
    }

    /** object (include array) */
    const keysA = Object.keys(objA).sort();
    const keysB = Object.keys(objB).sort();

    return (
        keysA.length === keysB.length &&
        keysA.every((keyA, index) => keyA === keysB[index]) &&
        keysA.every((key) => deepEqual(objA[key], objB[key]))
    );
}

export function wait(ms: number) {
    return new Promise((res, rej) => setTimeout(() => res(true), ms));
}

export function getRandomId(prefixName?: string) {
    return `${prefixName ? prefixName : ''}${Date.now()}_${randomStringOfSize(8)}`;
}

export const flattenUniq = (lists: ProjectParticipantWithAllRoles[][]) =>
    lists.reduce((acc, list) => {
        const copy = [...acc];
        list.forEach((item) => (!copy.some((cloneItem) => cloneItem.id === item.id) ? copy.push(item) : null));
        return copy;
    }, []);

export const isEmpty = (obj: any) => {
    return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};

export const xorWith = (array: any[], values: any[], comparator: Function) => {
    if (!comparator) {
        return [];
    }
    if ((!array || !array.length) && (!values || !values.length)) {
        return [];
    } else if ((!array || !array.length) && values && values.length) {
        return values;
    } else if ((!values || !values.length) && array && array.length) {
        return array;
    } else {
        return array
            .filter((item) => !values.some((value) => comparator(item, value)))
            .concat(values.filter((value) => !array.some((item) => comparator(value, item))));
    }
};

const isObjectLike = (value) => {
    return value != null && typeof value == 'object';
};

export const isEqual = (value: any, other: any) => {
    // If premittives are equal return true;
    if (value === other) {
        return true;
    }
    // Get the value type
    const type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) {
        return false;
    }

    if (value === null || other === null || (!isObjectLike(value) && !isObjectLike(other))) {
        return value !== value && other !== other;
    }

    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) {
        return false;
    }

    // Compare two items
    const compare = (item1: any, item2: any) => {
        // Get the object type
        const itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!isEqual(item1, item2)) {
                return false;
            }
        }

        // Otherwise, do a simple comparison
        else {
            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) {
                return false;
            }

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) {
                    return false;
                }
            } else {
                if (item1 !== item2) {
                    return false;
                }
            }
        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (let i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) {
                return false;
            }
        }
    } else {
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) {
                    return false;
                }
            }
        }
    }

    // If nothing failed, return true
    return true;
};

export const differenceBy = (primaryList: any[], secondaryList: any[], key: string) => {
    if (!primaryList || !primaryList.length) {
        return [];
    }
    if (!secondaryList || !secondaryList.length) {
        return primaryList;
    }

    const uniqueFromPrimaryList = primaryList.filter((primaryListItem) => {
        return !secondaryList.some((secondaryListItem) => {
            return primaryListItem[key] === secondaryListItem[key];
        });
    });

    const uniqueFromSecondaryList = secondaryList.filter((secondaryListItem) => {
        return !primaryList.some((primaryListItem) => {
            return primaryListItem[key] === secondaryListItem[key];
        });
    });

    return uniqueFromPrimaryList.concat(uniqueFromSecondaryList);
};

export const intersectionBy = (primaryList: any[], secondaryList: any[], key: string) => {
    if (!primaryList || !primaryList.length) {
        return [];
    }
    if (!secondaryList || !secondaryList.length) {
        return primaryList;
    }

    const uniqueFromPrimaryList = primaryList.filter((primaryListItem) => {
        return secondaryList.some((secondaryListItem) => {
            return primaryListItem[key] === secondaryListItem[key];
        });
    });

    return [].concat(uniqueFromPrimaryList);
};

export const intersectionWith = (array: any[], values: any[], comparator: Function) => {
    if (!array || !array.length || !values || !values.length || !comparator) {
        return [];
    }

    return array.filter((item) => values.some((value) => comparator(item, value)));
};
