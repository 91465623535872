import { Injectable } from '@angular/core';
import { TableDefinition } from '@ruum/ruum-reducers';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { TableDefinitionListService } from '../../common/connectors/tableDefinition/table-definition-list.service';
import { ModalService } from './../../admin/common/modal/modal.service';
import { TableDefinitionDialogComponent } from './table-definition-dialog/table-definition-dialog.component';

@Injectable({ providedIn: 'root' })
export class TableDefinitionActionsService {
    constructor(
        private modalService: ModalService,
        private alertService: RuumAlertService,
        private tableDefinitionListService: TableDefinitionListService,
    ) {}

    createTableDefinition(): Promise<any> {
        const modalRef = this.modalService.open(TableDefinitionDialogComponent, {
            size: 'lg',
            backdrop: 'static',
        });

        return modalRef.result.then(
            (data) => this.tableDefinitionListService.createTableDefinition(data),
            () => {},
        );
    }

    updateTableDefinition(tableDefinition): Promise<any> {
        const modalRef = this.modalService.open(TableDefinitionDialogComponent, {
            size: 'lg',
            backdrop: 'static',
        });

        modalRef.componentInstance.mode = 'edit';
        modalRef.componentInstance.name = tableDefinition.name;
        modalRef.componentInstance.apiType = tableDefinition.apiType;
        modalRef.componentInstance.systemId = tableDefinition.systemId;
        modalRef.componentInstance.path = tableDefinition.path;
        modalRef.componentInstance.columns = tableDefinition.columns;

        return modalRef.result.then(
            (data) => this.tableDefinitionListService.updateTableDefinition(tableDefinition.id, data),
            () => {},
        );
    }

    deleteTableDefinition(tableDefinition: TableDefinition): Promise<any> {
        return this.alertService
            .warning({
                title: `Are you sure you want to delete this api mapping?`,
                actionText: 'Delete',
            })
            .then(
                () => {
                    this.tableDefinitionListService
                        .deleteTableDefinition(tableDefinition.id)
                        .catch((error) => this.alertService.info(error.message));
                },
                () => {},
            );
    }
}
