import { Pipe, PipeTransform } from '@angular/core';
const sizeMap = {
    sm: 'xs',
    md: 'sm',
    lg: 'lg',
};
@Pipe({ name: 'ruumProfileSize' })
export class ProfileSizePipe implements PipeTransform {
    transform(size: string): string {
        return sizeMap[size] || sizeMap.md;
    }
}
