import { Component, Input } from '@angular/core';

@Component({
    selector: 'ruum-header-cell-unsortable',
    template: `
        <div class="d-flex">
            <button class="btn btn-xs btn-link-light btn-without-hover px-0">
                <h5>{{ name }}</h5>
            </button>
        </div>
    `,
})
export class HeaderCellUnsortableComponent {
    @Input() name: string;

    constructor() {}
}
