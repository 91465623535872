import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RuumBootstrapModule } from '../../../../common/ruum-bootstrap/ruum-bootstrap.module';
import { MyDatePickerComponent } from './my-date-picker.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RuumBootstrapModule],
    declarations: [MyDatePickerComponent],
    exports: [MyDatePickerComponent],
})
export class MyDatePickerModule {}
