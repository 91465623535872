import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TaskPriority } from '@ruum/ruum-reducers';
import {
    RuumDropdownItem,
    RuumDropdownPlacement,
    RuumDropdownSections,
} from '../../../shared/ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-task-details-priority',
    template: `
        <ruum-dropdown
            *ngIf="!showNewMenu"
            [placement]="placement"
            [sections]="sections"
            [width]="'144px'"
            [isReadOnly]="isReadOnly"
            (selectItem)="onSelect($event)"
        >
            <dropdown-select-button
                data-content="ruum-dropdown-button-desktop"
                [item]="getPriorityDropdownItem(priority)"
                [isReadOnly]="isReadOnly"
                placeholder="Set Priority"
                [ariaLabel]="'Select task priority'"
                data-test="ruum-task-details-priority-dropdown"
            >
            </dropdown-select-button>

            <dropdown-select-button
                data-content="ruum-dropdown-button-mobile"
                [item]="getPriorityDropdownItem(priority)"
                [isReadOnly]="isReadOnly"
                placeholder="Set Priority"
            >
            </dropdown-select-button>
        </ruum-dropdown>

        <ruum-menu *ngIf="showNewMenu" [placement]="placement" [width]="'144px'">
            <ruum-menu-trigger>
                <dropdown-select-button
                    data-content="ruum-dropdown-button-desktop"
                    [item]="getPriorityDropdownItem(priority)"
                    [isReadOnly]="isReadOnly"
                    placeholder="Set Priority"
                    [ariaLabel]="'Select task priority'"
                    data-test="ruum-task-details-priority-dropdown"
                >
                </dropdown-select-button>
            </ruum-menu-trigger>
            <ng-container *ngFor="let section of sections; let last = last">
                <ng-container *ngFor="let option of section">
                    <ruum-menu-option
                        (click)="onSelect(option)"
                        [theme]="option.theme"
                        [icon]="option.icon"
                        [disabled]="option.disabled"
                        [disableTooltip]="!option.disabled"
                        [ngbTooltip]="option.tooltip?.title"
                    >
                        {{ option.name }}
                    </ruum-menu-option>
                </ng-container>
                <ruum-menu-devider *ngIf="!last"></ruum-menu-devider>
            </ng-container>
        </ruum-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskDetailsPriorityComponent implements OnInit {
    showNewMenu = true;

    @HostBinding('class') hostClassList = 'd-flex flex-fill';

    @Input()
    priority: TaskPriority;

    @Input()
    placement: RuumDropdownPlacement = ['bottom-left', 'top-left', 'auto'];

    @Input() isReadOnly = false;

    @Output()
    priorityChanged = new EventEmitter();

    sections: RuumDropdownSections;

    ngOnInit() {
        this.sections = this.getSections();
    }

    onSelect(option: RuumDropdownItem) {
        this.priorityChanged.emit(option.id);
    }

    getSections(): RuumDropdownSections {
        const priorityIds: TaskPriority[] = ['HIGH', 'MEDIUM', 'LOW', null];
        const sections = [priorityIds.map((id) => this.getPriorityDropdownItem(id))];
        return sections;
    }

    getPriorityDropdownItem(priority: TaskPriority): RuumDropdownItem {
        switch (priority) {
            case 'HIGH':
                return { id: 'HIGH', name: 'High', icon: 'icon-priority-filled', theme: 'danger' };
            case 'MEDIUM':
                return { id: 'MEDIUM', name: 'Medium', icon: 'icon-priority-filled', theme: 'warning' };
            case 'LOW':
                return { id: 'LOW', name: 'Low', icon: 'icon-priority-filled', theme: 'secondary' };
            default:
                return { id: null, name: 'No Priority', theme: 'secondary' };
        }
    }
}
