import { Injectable } from '@angular/core';
import { AccountType } from '@ruum/ruum-reducers';
import { PaywallService } from './../../../paywall/paywall.service';

@Injectable() // Note: this injectable should be component scoped
export class SettingsView {
    // OnChanges: The lifecycle should be managed by the enclosing components: settings-modal and settings-popover

    name: string;
    initials: string;
    email: string;
    accountType: AccountType;
    isTrial: boolean;
    daysLeft?: number;

    /* eslint-disable */
    private _isBasic: boolean;
    private _isProfessional: boolean;
    private _isEnterprise: boolean;

    private _accountTypeLabel: string;

    /* eslint-enable */

    constructor(private readonly paywallService: PaywallService) {}

    onEnclosingComponentChange(
        name: string,
        initials: string,
        email: string,
        accountType: AccountType,
        isTrial: boolean,
        daysLeft?: number,
    ): void {
        this.name = name;
        this.initials = initials;
        this.email = email;
        this.accountType = accountType;
        this.isTrial = isTrial;
        this.daysLeft = daysLeft;

        this._isBasic = this.accountType === 'BASIC';
        this._isProfessional = this.accountType === 'PROFESSIONAL';
        this._isEnterprise = this.accountType === 'ENTERPRISE';

        if (this._isBasic) {
            this._accountTypeLabel = 'Starter';
        } else if (this._isProfessional && this.isTrial) {
            this._accountTypeLabel = 'PRO Plan - Trial';
        } else if (this._isProfessional && !this.isTrial) {
            this._accountTypeLabel = 'PRO Plan';
        } else if (this._isEnterprise) {
            this._accountTypeLabel = 'Enterprise Plan';
        } else {
            this._accountTypeLabel = '';
        }
    }

    upgrade(): void {
        this.paywallService.navigateToUpgradeView();
    }

    get isBasic(): boolean {
        return this._isBasic;
    }

    get isProfessional(): boolean {
        return this._isProfessional;
    }

    get isEnterprise(): boolean {
        return this._isEnterprise;
    }

    get accountTypeLabel(): string {
        return this._accountTypeLabel;
    }

    get showUpgradeButton(): boolean {
        return false;
    }
}
