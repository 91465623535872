import { Component, Output, Input, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { RuumDropDownOption } from './ruum-dropdown-popup.component';

@Component({
    selector: 'card-buttons',
    template: `
        <action-buttons
            *ngIf="actions && actions.length > 0"
            [actions]="actions"
            (selectChange)="executeAction.emit($event)"
        ></action-buttons>
    `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumCardButtonsComponent {
    @Input() actions: RuumDropDownOption[] = [];
    @Output() executeAction = new EventEmitter<RuumDropDownOption>();
}
