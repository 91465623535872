import { Injectable } from '@angular/core';
import {
    AddTaskActionPayload,
    EditTaskActionPayload,
    ReorderSubtasksActionPayload,
    RuumActionTypes,
    SetSubtaskActionPayload,
    SetTaskAssigneesActionPayload,
    SetTaskPriorityActionPayload,
    SetTaskStatusAction,
    TaskEditFields,
    TaskPriority,
    TaskStatus,
} from '@ruum/ruum-reducers';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { RuumAlertService } from '../../common/components/alert/alert.service';
import { SelectedProjectService } from '../../common/connectors/project/selectedProject.service';
import { AppStoreWrapper } from './../../common/appStoreWrapper.service';
import { ServiceHelper } from './../../common/serviceHelper';
import { RuumTask } from './../ruum.model';

@Injectable({ providedIn: 'root' })
export class RuumTasksService {
    private focus$: Subject<string> = new Subject<string>();

    readonly taskMap$: Observable<{
        [taskId: string]: RuumTask;
    }>;

    constructor(
        private selectedProjectService: SelectedProjectService,
        private serviceHelper: ServiceHelper,
        private appStoreWrapper: AppStoreWrapper,
        private alertService: RuumAlertService,
    ) {
        this.taskMap$ = this.appStoreWrapper.tasks().pipe(
            distinctUntilChanged(),
            map((tasks) => {
                const taskMap = {};
                tasks.forEach((task) => {
                    taskMap[task.id] = { ...task };
                });
                return { ...taskMap };
            }),
        );
    }

    focusOnTask$(taskId: string): Observable<string> {
        return this.focus$.pipe(filter((id) => id && id === taskId));
    }

    setFocusOnTask(taskId: string) {
        this.focus$.next(taskId);
    }

    addTask(taskDescription: string): string {
        const taskId = this.serviceHelper.getRandomId();
        const payload: AddTaskActionPayload = {
            id: taskId,
            description: taskDescription,
            assignees: [],
        };
        this.selectedProjectService.persistAction(RuumActionTypes.ADD_TASK, payload);
        return taskId;
    }

    addTaskWithIdFromCanvas(taskId: string, taskDescription: string, isMilestone: boolean, parent: string) {
        const payload: AddTaskActionPayload = {
            id: taskId,
            description: taskDescription,
            isMilestone,
            assignees: [],
            parent,
        };
        return this.selectedProjectService.persistAction(RuumActionTypes.ADD_TASK, payload);
    }

    setPriority(taskId: string, priority: TaskPriority, kanbanBeforeElement?: string) {
        const payload: SetTaskPriorityActionPayload = { taskId, priority, kanbanBeforeElement };
        this.selectedProjectService.persistAction(RuumActionTypes.SET_TASK_PRIORITY, payload);
    }

    setStatus(taskId: string, status: TaskStatus, kanbanBeforeElement?: string) {
        return this.selectedProjectService.persistAction<SetTaskStatusAction>('SET_TASK_STATUS', {
            status,
            taskId,
            kanbanBeforeElement,
        });
    }

    setAssignees(assigneeData: SetTaskAssigneesActionPayload) {
        return this.selectedProjectService.persistAction(RuumActionTypes.SET_TASK_ASSIGNEES, assigneeData);
    }

    editTask(taskId: string, editFields: TaskEditFields) {
        const payload: EditTaskActionPayload = {
            taskId,
            editFields,
        };
        return this.selectedProjectService.persistAction(RuumActionTypes.EDIT_TASK, payload);
    }

    deleteTask(id: string) {
        this.selectedProjectService.persistAction(RuumActionTypes.DELETE_TASK, { id });
    }

    subtasks(taskId: string): Observable<RuumTask[]> {
        return this.taskMap$.pipe(
            map((taskMap) => {
                const task = taskMap[taskId];
                if (task) {
                    return (task.children || []).reduce((subtasks: RuumTask[], nextTaskId) => {
                        if (taskMap[nextTaskId]) {
                            subtasks = [...subtasks, taskMap[nextTaskId]];
                        }
                        return subtasks;
                    }, []);
                } else {
                    return [];
                }
            }),
        );
    }

    setSubtask(subtaskId: string, parentId: string) {
        const payload: SetSubtaskActionPayload = { parentId, subtaskId };
        return this.selectedProjectService.persistAction(RuumActionTypes.SET_SUBTASK, payload);
    }

    removeSubtask(subtaskId: string, parentId: string) {
        const payload: SetSubtaskActionPayload = { parentId, subtaskId };
        this.selectedProjectService.persistAction(RuumActionTypes.REMOVE_SUBTASK_FROM_PARENT, payload);
    }

    getTask(taskId: string): Observable<RuumTask> {
        return this.taskMap$.pipe(map((taskMap) => taskMap[taskId]));
    }

    changeSubtaskOrder(taskId: string, children: string[]) {
        const payload: ReorderSubtasksActionPayload = {
            taskId,
            subtaskIds: children,
        };
        return this.selectedProjectService.persistAction(RuumActionTypes.REORDER_SUBTASKS, payload);
    }

    getTaskObject(taskId: string): RuumTask {
        let task;
        this.getTask(taskId)
            .pipe(take(1))
            .subscribe((t) => (task = t));
        return task;
    }

    askForDeletionConfirmation(taskId: string): Promise<void> {
        const task: RuumTask = this.getTaskObject(taskId);

        if (!task) {
            return Promise.reject(undefined);
        }

        const objectTypeName: string = task.isMilestone ? 'milestone' : 'task';
        let msg = `Do you want to permanently delete this ${objectTypeName}?`;
        if (task.children && task.children.length > 0) {
            msg = `Do you want to permanently delete this ${objectTypeName} and all of its subtasks?`;
        }
        return this.alertService.delete(msg);
    }

    moveKanbanCard(taskId: string, projectId: string, groupId: string, kanbanBeforeElement: string) {
        const payload = { taskId, projectId, groupId, kanbanBeforeElement };
        this.selectedProjectService.persistAction(RuumActionTypes.MOVE_KANBAN_CARD, payload);
    }

    /**
     * It returns the top most parent of the Task. If the task has no parent it returns 'taskId'.
     * This is used to later find out in which section a task is.
     */
    getTaskRootParentId(taskId: string): string {
        const task = this.getTaskObject(taskId);
        const parentId = this._getTaskRootParentId(task);
        return parentId || taskId;
    }

    private _getTaskRootParentId(task: RuumTask): string {
        if (!task) {
            return undefined;
        }

        if (task.parent) {
            const parent = this.getTaskObject(task.parent);
            return this._getTaskRootParentId(parent);
        } else {
            return task.id;
        }
    }
}
