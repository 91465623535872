import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatsListItem } from '../../connectors/chats/chat-list.model';
import { UserListItem } from '../../connectors/readModelConnector.service';
import { InvolvedUsersService } from '../../connectors/users/users.service';

@Component({
    selector: 'ruum-project-chats-overview-list-item',
    template: `
        <div class="d-flex align-items-start minw-0 p-4 hov">
            <button class="btn btn-xs btn-icon btn-link-light btn-without-hover px-1 pt-2 border-0">
                <i class="icon {{ chatsListItem.objectType | ruumObjectTypeToIcon }}"></i>
            </button>

            <div class="flex-fill d-flex flex-column minw-0 pl-2">
                <div class="d-flex flex-row align-items-center flex-fill justify-content-between minw-0 mb-2">
                    <div title="{{ setHeaderText(chatsListItem) }}" class="text-truncate font-weight-bold py-1">
                        {{ setHeaderText(chatsListItem) }}
                    </div>
                    <span
                        *ngIf="hasUnseen(chatsListItem)"
                        class="badge-container text-tiny font-weight-bold badge badge-info badge-pill text-white mr-1"
                        >{{ chatsListItem.unseenComments <= 99 ? chatsListItem.unseenComments : '99+' }}
                    </span>
                </div>

                <div class="d-flex flex-column mb-1">
                    <div class="text-small text-truncate">{{ chatsListItem.commentText }}</div>
                </div>

                <div class="d-flex flex-fill align-items-center" *ngIf="getChatCreator() | async as creator">
                    <div class="d-flex flex-fill py-1 minw-0 align-items-center justify-content-start">
                        <ruum-profile-member
                            [name]="creator.fullName"
                            [theme]="creator.color | ruumProfileHexToTheme"
                            [size]="'sm'"
                            [componentClass]="'mr-2'"
                        ></ruum-profile-member>
                        <div class="text-tiny text-truncate font-weight-bold">{{ creator.fullName }}</div>
                        <div class="d-flex" [style.min-width.px]="96">
                            <div class="text-tiny text-secondary mx-1">&#8226;</div>
                            <div class="text-tiny text-secondary">
                                {{ chatsListItem.createdAt | dateFormat: "MMM DD, HH:mm 'YY" }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex hov-child">
                        <button class="btn btn-xs btn-link-secondary px-0" type="button">
                            <span>View</span>
                            <i class="icon icon-forward"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class ProjectChatsOverviewListItemComponent implements OnInit {
    @Input()
    chatsListItem: ChatsListItem;

    constructor(private usersService: InvolvedUsersService) {}

    ngOnInit() {}

    setHeaderText(chatsListItem: ChatsListItem) {
        if (chatsListItem.objectType === 'project') {
            return 'Ruum Chat';
        } else if (chatsListItem.objectType === 'processBar') {
            return 'Process Bar';
        } else {
            return chatsListItem.objectText;
        }
    }

    hasUnseen(chatListItem: ChatsListItem): boolean {
        if (chatListItem && chatListItem.unseenComments > 0) {
            return true;
        }
        return false;
    }

    getChatCreator(): Observable<UserListItem> {
        return this.usersService.data(this.chatsListItem.createdBy);
    }
}
