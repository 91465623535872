import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ruum-tab-navigation',
    template: `
        <div class="d-flex align-items-center">
            <button
                *ngFor="let option of options; index as i; last as isLast"
                class="btn px-0 pb-1 mr-4"
                [class.border-bottom]="activeIndex === i"
                [class.btn-link-primary]="activeIndex === i"
                [class.btn-link-secondary]="activeIndex !== i"
                (click)="onClickTab(i)"
            >
                {{ option.text }}
            </button>
        </div>
    `,
    styles: [
        `
            .border-bottom {
                border-bottom-width: 2px !important;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavigationComponent {
    @Output() tabNavigationChanged: EventEmitter<number> = new EventEmitter();
    @Input() options: TabNavigationOption[] = [];
    @Input() activeIndex = 0;
    @Input() width = 100;

    onClickTab(index: number) {
        if (index !== this.activeIndex) {
            this.tabNavigationChanged.emit(index);
        }
    }
}

export interface TabNavigationOption {
    id: any;
    text: string;
}
