import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShortNumberPipe } from './short-number.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [ShortNumberPipe],
    exports: [ShortNumberPipe],
})
export class ShortModule {}
