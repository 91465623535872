import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Day } from '@ruum/ruum-reducers';
import { DatepickerComponent } from '../../../ui-components/datepicker/datepicker.component';
import { componentHelper } from '../../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-filter-field-value-datepicker',
    template: `
        <div class="input-group input-group-sm">
            <div class="d-flex" [style.width.px]="0">
                <ruum-datepicker
                    #datepicker
                    [showButton]="false"
                    [isStartDay]="true"
                    [date]="date"
                    [placeholder]="'Select a date'"
                    fullDateFormat="false"
                    customDateFormat="WWW. dd mmm"
                    (dateChanged)="onChange($event)"
                    (resetDates)="onChange(null)"
                >
                </ruum-datepicker>
            </div>

            <div
                class="btn btn-sm btn-light btn-block pl-2 pr-1"
                (click)="openDatepicker()"
                tabindex="0"
                (keydown.enter)="openDatepicker()"
                (keydown.space)="openDatepicker()"
                aria-label="Open Datepicker"
                aria-role="button"
            >
                <span
                    *ngIf="!value"
                    class="flex-fill text-truncate font-weight-normal text-small text-secondary text-left"
                    >Select Date</span
                >
                <span *ngIf="value" class="flex-fill text-truncate font-weight-normal text-small text-body text-left">{{
                    value | date: 'mediumDate'
                }}</span>
                <i class="icon icon-date"></i>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldValueDatepickerComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    @ViewChild('datepicker', { static: false }) datepicker: DatepickerComponent;

    @Input() set selectedOptions(options: string[]) {
        const value = (options || []).join(',');
        this.value = value ? new Date(value) : null;
        this.setDate(this.value);
    }

    @Input() componentClass = '';

    @Output() changed = new EventEmitter();

    date: Day = null;

    value: Date;

    constructor() {}

    ngOnInit() {}

    openDatepicker() {
        this.datepicker.openDatepicker();
    }

    onChange(value) {
        if (value) {
            this.value = new Date(value.year, value.month - 1, value.day);
            const dateAsString =
                this.value.getFullYear() +
                '-' +
                ('0' + (this.value.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + this.value.getDate()).slice(-2);

            this.changed.emit(new Date(dateAsString).toISOString());
        }
    }

    private setDate(value) {
        if (value) {
            const date = new Date(value);
            this.date = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            };
        } else {
            this.date = null;
        }
    }
}
