import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RequestIdInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(this.addRequestIdToError));
    }

    private addRequestIdToError<T>(error: Error | HttpErrorResponse, catched: Observable<T>): ObservableInput<T> {
        if (error instanceof HttpErrorResponse) {
            const requestId = error.headers.get('x-request-id');
            throw new HttpErrorResponseWithContext(requestId, { ...error });
        }
        throw error;
    }
}

export class HttpErrorResponseWithContext extends HttpErrorResponse {
    constructor(
        readonly requestId: string,
        init: { error?: any; headers?: HttpHeaders; status?: number; statusText?: string; url?: string },
    ) {
        super(init);
    }
}
