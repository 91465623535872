import { ProjectAction, RuumAction } from '@ruum/ruum-reducers';
import { UserListItem } from '../readModelConnector.service';

export const USERS_ACTION_TYPES = {
    ADD_OR_REPLACE_INVOLVED_USERS: 'ADD_OR_REPLACE_INVOLVED_USERS',
};

export type AddOrReplaceInvolvedUsersAction = ProjectAction<
    'ADD_OR_REPLACE_INVOLVED_USERS',
    {
        users: UserListItem[];
    }
>;

export function UsersReducer(currentState: UsersMap = {}, action: RuumAction): UsersMap {
    switch (action.type) {
        case USERS_ACTION_TYPES.ADD_OR_REPLACE_INVOLVED_USERS:
            return addOrReplace(currentState, action);
        default:
            return currentState;
    }
}

function addOrReplace(currentState: UsersMap, action: AddOrReplaceInvolvedUsersAction): UsersMap {
    const newUsers = getMap(action.payload.users);
    return {
        ...currentState,
        ...newUsers,
    };
}

function getMap(users: UserListItem[]): UsersMap {
    const map: UsersMap = {};

    users.forEach((user) => {
        map[user.id] = user;
    });

    return map;
}

export interface UsersMap {
    [userId: string]: UserListItem;
}
