import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExternalSystemStore } from './externalSystem.store';

// Configuration
const SUCCESS_PAGE_PATH = `/success.html`;
const IS_LOGGED_IN_POLL_INTERVAL = 100;

class JWTTokenData {
    constructor(token, expiryDate, refreshToken) {
        this.token = token;
        this.expiryDate = expiryDate;
        this.refreshToken = refreshToken;
    }

    token: string;
    expiryDate: number;
    refreshToken: string;
}

interface AuthStatuses {
    [systemId: string]: BehaviorSubject<boolean>;
}

interface TokenCache {
    [systemId: string]: TokenCacheEntry;
}

interface TokenCacheEntry {
    data: JWTTokenData;
    interval: NodeJS.Timer | number;
}

interface AuthRequestControl {
    [systemId: string]: Promise<JWTTokenData>;
}

@Injectable({ providedIn: 'root' })
export class RuumOnPremiseConnectorAuthHandler {
    private authStatuses: AuthStatuses = {};
    private tokenCache: TokenCache = {};
    private authRequestControl: AuthRequestControl = {};

    constructor(private http: HttpClient, private systemStore: ExternalSystemStore) {}

    async authenticate(systemId: string): Promise<void> {
        let token = await this.getValidTokenFromCacheOrSession(systemId);

        if (!token) {
            token = await this.getAuthenticationFromLoginPopup(systemId);
        }

        this.persistTokenAndNotify(systemId, token);
    }

    /**
     * Returns all valid system tokens in the cache.
     */
    getTokens(): { [systemId: string]: string } {
        const validTokens = {};
        Object.keys(this.tokenCache).forEach((systemId) => {
            const cache = this.tokenCache[systemId];
            if (cache && cache.data && cache.data.expiryDate > Date.now()) {
                validTokens[systemId] = cache.data.token;
            }
        });
        return validTokens;
    }

    private async getValidTokenFromCacheOrSession(systemId: string): Promise<JWTTokenData> {
        const cached = this.tokenCache[systemId];
        if (cached) {
            const token = cached.data;
            if (token && token.expiryDate < Date.now()) {
                return token;
            }
        }
        return this.fetchToken(systemId);
    }

    private persistTokenAndNotify(systemId: string, token: JWTTokenData): JWTTokenData {
        if (token) {
            this.tokenCache[systemId] = {
                data: token,
                interval: this.scheduleRefresh(systemId, token.expiryDate),
            };
        } else {
            delete this.tokenCache[systemId];
        }

        this.getAuthStatusObservable(systemId).next(!!token);

        return token;
    }

    private scheduleRefresh(systemId: string, expiryDate: number): NodeJS.Timer | number {
        return setTimeout(async () => {
            this.refreshToken(systemId);
        }, expiryDate - Date.now());
    }

    isAuthenticated(systemId: string): Observable<boolean> {
        let isAuthenticated = false;
        const cached = this.tokenCache[systemId];
        if (cached) {
            isAuthenticated = cached.data.expiryDate > Date.now();
        } else {
            this.refreshToken(systemId);
        }

        const observable = this.getAuthStatusObservable(systemId);

        observable.next(isAuthenticated);
        return observable.asObservable();
    }

    private async refreshToken(systemId) {
        try {
            const token = await this.getValidTokenFromCacheOrSession(systemId);
            this.persistTokenAndNotify(systemId, token);
        } catch (e) {
            console.log('Error refreshing token. Aborting');
            this.persistTokenAndNotify(systemId, null);
        }
    }

    private getAuthStatusObservable(systemId: string): BehaviorSubject<boolean> {
        let observable = this.authStatuses[systemId];
        if (!observable) {
            observable = new BehaviorSubject<boolean>(false);
            this.authStatuses[systemId] = observable;
        }
        return observable;
    }

    private async fetchToken(systemId: string): Promise<JWTTokenData> {
        if (!this.authRequestControl[systemId]) {
            this.authRequestControl[systemId] = this.fetchTokenDelayed(systemId);
        }
        return this.authRequestControl[systemId];
    }

    private async fetchTokenDelayed(systemId): Promise<JWTTokenData> {
        try {
            const systemUrl = await this.getSystemUrl(systemId);
            let token;
            const response: any = await this.http
                .get(`${systemUrl}/api/getToken`, {
                    withCredentials: true,
                    responseType: 'text',
                })
                .toPromise();

            if (response) {
                if (response.startsWith('<')) {
                    token = null;
                } else {
                    token = JSON.parse(response);
                }
            }

            delete this.authRequestControl[systemId];
            return token;
        } catch (e) {
            // Something wrong happened while trying to authenticate, remove the entry on the cache.
            delete this.authRequestControl[systemId];
        }
    }

    private async getAuthenticationFromLoginPopup(systemId: string): Promise<JWTTokenData> {
        const systemUrl = await this.getSystemUrl(systemId);
        const popupAuthentication = new Promise<void>((resolve, reject) => {
            const loginWindow = window.open(
                `${systemUrl}${SUCCESS_PAGE_PATH}?no_cache=${Date.now()}`,
                'Authentication',
                'width=600,height=600,noopener',
            );

            const intervalId = setInterval(() => {
                loginWindow.postMessage('isLoggedIn?', systemUrl);
            }, IS_LOGGED_IN_POLL_INTERVAL);

            window.addEventListener(
                'message',
                async (event) => {
                    // check origin
                    if (event.origin === systemUrl) {
                        clearInterval(intervalId);
                        resolve();
                        loginWindow.close();
                    }
                },
                false,
            );
        });

        await popupAuthentication;

        return this.fetchToken(systemId);
    }

    private async getSystemUrl(systemId: string): Promise<string> {
        const system = await this.systemStore
            .data(systemId)
            .pipe(take(1))
            .toPromise();
        return `https://${system.hostname}${system.port === 443 ? '' : ':' + system.port}`;
    }
}
