import { RuumAction } from '@ruum/ruum-reducers';
import { Mail as RuumMail } from './mail.model';
import { ACTION_TYPES } from './../../../common/actions';

export function RuumMailReducer(currentState: RuumMail[] = [], action: RuumAction): RuumMail[] {
    switch (action.type) {
        case ACTION_TYPES.MAILS_FOR_USER_LOADED:
            return action.payload;
        case ACTION_TYPES.MAIL_ASSIGNED_TO_RUUM:
            const mailId: string = action.payload;
            const mailIndex: number = currentState.findIndex((mail) => mail.id === mailId);
            if (mailIndex === -1) {
                return currentState;
            }
            return [...currentState.slice(0, mailIndex), ...currentState.slice(mailIndex + 1)];
        default:
            return currentState;
    }
}

export function RuumMailCounterReducer(currentState: number = 0, action: RuumAction): number {
    switch (action.type) {
        case ACTION_TYPES.MAILS_COUNTER_FOR_USER_LOADED:
            return action.payload;
        case ACTION_TYPES.MAIL_ASSIGNED_TO_RUUM:
            return currentState === undefined ? 0 : currentState - 1;
        default:
            return currentState;
    }
}
