import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private defaultHandler = new ErrorHandler();

    handleError(error: Error | HttpErrorResponse) {
        this.defaultHandler.handleError(error);
    }
}
