import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppStoreWrapper } from './appStoreWrapper.service';
import { ProfileService } from './connectors/profile/profile.service';

@Injectable({ providedIn: 'root' })
export class TrackingConnector {
    private userLoggedIn = false;
    private NO_TRACKING = false;
    private TRACKING_URL: string = environment.TRACKING_URL;

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private profileService: ProfileService,
    ) {
        this.profileService.userProfile().subscribe((profile) => {
            if (profile && profile.id) {
                this.userLoggedIn = true;
                // TODO: no tracking needs to be implemented properly. At the moment it always will be undefined
                // eslint-disable-next-line @typescript-eslint/dot-notation
                if (profile['noTracking']) {
                    this.NO_TRACKING = true;
                }
            } else {
                this.userLoggedIn = false;
            }
        });
        this.trackPageViews();
    }

    trackEventInferCategoryFromUrl(object: string, action: string, eventLabel?: string, eventValue?: number) {
        const category = this.getTrackingCategory();
        if (category === 'other' && !eventLabel) {
            eventLabel = this.router.url;
        }
        this.trackEvent(category, object, action, eventLabel, eventValue);
    }

    private getTrackingCategory(): EVENT_CATEGORY {
        const url = this.getUrl();
        if (url[0] === 'home') {
            return this.getHomeCategory(url);
        } else if (url[0] === 'projectgroups') {
            return this.getProjectGroupCategory(url);
        } else if (url[0] === 'projects') {
            return this.getProjectCategory(url);
        } else if (url[0] === 'templates') {
            return this.getTemplateCategory(url);
        } else {
            return 'other';
        }
    }

    private getHomeCategory(url: string[]): EVENT_CATEGORY {
        if (url[1] === 'tasks') {
            return 'lobby_task_list';
        } else if (url[1] === 'projects') {
            return 'lobby_project';
        } else if (url[1] === 'settings') {
            return this.getSettingsCategory(url);
        } else {
            return 'other';
        }
    }

    private getSettingsCategory(url: string[]): EVENT_CATEGORY {
        if (url[2] === 'profile') {
            return 'settings_profile';
        } else if (url[2] === 'notifications') {
            return 'settings_notifications';
        } else {
            return 'other';
        }
    }

    private getProjectGroupCategory(url: string[]): EVENT_CATEGORY {
        if (url[2] === 'projects') {
            return 'project_group_projects';
        }
        if (url[2] === 'tasks') {
            return 'project_group_task_list';
        } else {
            return 'other';
        }
    }

    private getProjectCategory(url: string[]): EVENT_CATEGORY {
        if (url[2] === 'canvas') {
            return 'project_canvas';
        }
        if (url[2] === 'tasks') {
            return 'project_task_list';
        }
        if (url[2] === 'timeline') {
            return 'project_timeline';
        } else {
            return 'other';
        }
    }

    private getTemplateCategory(url: string[]): EVENT_CATEGORY {
        if (url[1] === 'sections') {
            return 'lobby_templates_sections';
        } else if (url[1] === 'projects') {
            return 'template_project';
        } else {
            return 'other';
        }
    }

    private getUrl(): string[] {
        const url: string[] = this.router.url
            .split('/')
            .filter((val) => !!val)
            .map((val) => val.replace('(', '').replace(')', ''));
        return url;
    }

    trackEvent(appLocation: EVENT_CATEGORY, object: string, action: string, eventLabel?: string, eventValue?: number) {
        if (!this.NO_TRACKING) {
            this.ruumTracking(appLocation, `${object}_${action}`, eventLabel, eventValue);
        }
    }

    private ruumTracking(appLocation: string, eventName: string, eventLabel?: string, eventValue?: number): void {
        if (this.NO_TRACKING || !this.userLoggedIn) {
            return;
        }

        const event = {
            appLocation,
            eventName,
            eventLabel,
            eventValue,
            url: this.router.url,
        };
        this.http
            .post(`${this.TRACKING_URL}/v1/events?debug=${eventName}`, event, { withCredentials: true })
            .toPromise()
            .catch((err) => {
                // Do nothing.
            });
    }

    private trackPageViews() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (!this.NO_TRACKING) {
                    if (this.userLoggedIn) {
                        this.http
                            .post(
                                `${this.TRACKING_URL}/v1/pageview`,
                                { path: this.router.url },
                                { withCredentials: true },
                            )
                            .toPromise()
                            .catch((err) => {
                                // Do nothing.
                            });
                    }
                }
            }
        });
    }
}

export type EVENT_CATEGORY =
    | 'admin_users'
    | 'lobby'
    | 'lobby_header'
    | 'lobby_task_list'
    | 'lobby_project'
    | 'lobby_project_group'
    | 'settings'
    | 'settings_profile'
    | 'settings_notifications'
    | 'getting_started'
    | 'workspace'
    | 'workspace_header'
    | 'workspace_task_list'
    | 'workspace_projects'
    | 'workspace_invite_dialog'
    | 'saved_view'
    | 'project_group'
    | 'project_group_header'
    | 'project_group_task_list'
    | 'project_group_projects'
    | 'project_invite_dialog'
    | 'project'
    | 'project_header'
    | 'project_canvas'
    | 'project_task_list'
    | 'project_timeline'
    | 'project_side_bar'
    | 'lobby_templates_projects'
    | 'lobby_templates_sections'
    | 'template_project'
    | 'template_section'
    | 'authentication'
    | 'privacy-agreement'
    | 'global_nav_panel'
    | 'other';
