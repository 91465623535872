import { RuumAction } from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { TASK_LIST_ACTIONS } from './task-list.actions';
import { TaskListItemSubtasks, TaskListShowingSubtasks, TaskListWrapper } from './task-list.model';
import { TaskListReducer } from './task-list.reducer';

export const SHOWIND_SUBTASKS_ACTIONS = {
    SHOW_SUBTASKS: 'SHOW_SUBTASKS',
    CLOSE_ALL_SUBTAKS: 'CLOSE_ALL_SUBTAKS',
};

function getDefault(): TaskListWrapper {
    return {
        root: getDefaultPaginatedList<string>(),
        children: {},
        showingSubtasks: {},
    };
}

export function TaskListWrapperReducer(
    currentState: TaskListWrapper = getDefault(),
    action: RuumAction,
): TaskListWrapper {
    if (!TASK_LIST_ACTIONS[action.type] && !SHOWIND_SUBTASKS_ACTIONS[action.type]) {
        return currentState;
    }

    let newState: TaskListWrapper;

    const parentId = action.payload.parentId;

    if (parentId) {
        newState = {
            ...currentState,
            children: TaskListItemSubtasksReducer(currentState.children, action),
            showingSubtasks: ShowingSubtasksReducer(currentState.showingSubtasks, action),
        };
    } else {
        newState = {
            ...currentState,
            root: TaskListReducer(currentState.root, action),
            showingSubtasks: ShowingSubtasksReducer(currentState.showingSubtasks, action),
        };
    }
    return newState;
}

function TaskListItemSubtasksReducer(
    currentState: TaskListItemSubtasks = {},
    action: RuumAction,
): TaskListItemSubtasks {
    const change: TaskListItemSubtasks = {};

    const projectId = action.payload.projectId;
    const parentId = action.payload.parentId;

    const key = `${projectId}-${parentId}`;

    const taskList = currentState[key];

    /**
     * If there is an action to change one of the children of X, but the children of X are not loaded yet, ignore the action.
     * Other actions can be dispatched when the user edits a subtask in the task details without having the task list open.
     */
    if (!taskList && action.type !== TASK_LIST_ACTIONS.TASK_LIST_LOADED) {
        return currentState;
    }

    change[key] = TaskListReducer(taskList, action);

    return {
        ...currentState,
        ...change,
    };
}

function ShowingSubtasksReducer(
    currentState: TaskListShowingSubtasks = {},
    action: ShowSubtasksAction,
): TaskListShowingSubtasks {
    switch (action.type) {
        case SHOWIND_SUBTASKS_ACTIONS.CLOSE_ALL_SUBTAKS:
            return {};
        case SHOWIND_SUBTASKS_ACTIONS.SHOW_SUBTASKS:
            const { projectId, taskId } = action.payload;
            const key = `${projectId}-${taskId}`;
            const change = {};
            change[key] = action.payload.show;
            return {
                ...currentState,
                ...change,
            };
        default:
            return currentState;
    }
}

export interface ShowSubtasksAction extends RuumAction {
    type: 'SHOW_SUBTASKS';
    payload: {
        taskId: string;
        projectId: string;
        show: boolean;
        /** the parent of 'taskId' */
        parentId: string;
    };
}
