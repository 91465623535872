import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruumTaskStatusLightTheme' })
export class TaskStatusLightThemePipe implements PipeTransform {
    transform(themeName: string): string {
        const themeMap = {
            secondary: 'light',
            info: 'info-light',
            warning: 'warning-light',
            danger: 'danger-light',
            success: 'success-light',
        };

        return themeMap[themeName] || themeMap.secondary;
    }
}
