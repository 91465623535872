import { Transaction } from 'prosemirror-state';
import { AddMarkStep, RemoveMarkStep, StepMap } from 'prosemirror-transform';

function id(node) {
    return node.attrs.id;
}

function sectionOffset(doc, sectionId) {
    for (let i = 0, offset = 0; i < doc.childCount; i++) {
        const section = doc.child(i);
        if (id(section) === sectionId) {
            return offset;
        }
        offset += section.nodeSize;
    }
    throw new Error(`Could not find section ${sectionId} in document`);
}

function applySectionTransactionsToDoc(docState, sectionTransactions: Transaction[], sectionId, addTrackingKeysToStep) {
    let docTransaction: Transaction = null;
    sectionTransactions.forEach((sectionTransaction) => {
        if (sectionTransaction.steps.length === 0) {
            return;
        }
        if (docTransaction === null) {
            docTransaction = docState.tr;
        }
        const offsetMap = StepMap.offset(sectionOffset(docTransaction.doc, sectionId) + 1);
        sectionTransaction.steps.forEach((step) => {
            const mapped = step.map(offsetMap);
            addTrackingKeysToStep(mapped);
            docTransaction.step(mapped);
        });
    });
    if (docTransaction) {
        /** FIXME: is that ok? */
        const doNotAddToHistory = sectionTransactions.some((tr) => tr.getMeta('addToHistory') === false);
        return docState.apply(docTransaction.setMeta('addToHistory', !doNotAddToHistory));
    } else {
        return docState;
    }
}

function applyDocTransactionToSections(sectionStates, docTransaction, makeSectionState) {
    if (docTransaction.steps.length === 0) {
        return sectionStates;
    }

    // Determine which sections the steps touch, and for each section,
    // collect the steps inside of it and whether any steps crossed its
    // boundaries.
    const touchedSections = Object.create(null);
    docTransaction.steps.forEach((step, i) => {
        const { from, to } = stepExtent(step, docTransaction.mapping.maps[i]);
        const touched = getTouchedSections(docTransaction.docs[i], from, to);

        for (const info of touched) {
            let known = touchedSections[info.id];
            if (!known) {
                known = touchedSections[info.id] = { steps: [], outside: false };
            }
            known.steps.push({ step, offset: -(info.pos + 1) });
            if (info.outside) {
                known.outside = true;
            }
        }
    });

    const newSectionStates = [];
    // For each section in the final doc, create a state—by using or
    // updating the old state if possible, or by creating a fresh state
    // for sections that didn't exist before or that had steps happen
    // across their boundaries.
    docTransaction.doc.content.forEach((node) => {
        const sectionId = id(node);
        const touched = touchedSections[sectionId];
        let state = sectionStates.find((sectionState) => id(sectionState.doc) === sectionId);
        if (!state || (touched && touched.outside)) {
            state = makeSectionState(node);
        } else if (touched) {
            // FIXME keep that setMeta here?
            state = state.apply(offsetSteps(state, touched.steps).setMeta('disableComponentProtector', true));
        }
        newSectionStates.push(state);
    });
    return newSectionStates;
}

function offsetSteps(state, steps) {
    const newTransaction = state.tr;
    steps.forEach(({ step, offset }, index) => {
        newTransaction.step(step.map(StepMap.offset(offset)));
        for (const member in step) {
            if (step.hasOwnProperty(member) && !newTransaction.steps[index].hasOwnProperty(member)) {
                // Restore the userId info to the transaction
                newTransaction.steps[index][member] = step[member];
            }
        }
    });
    return newTransaction;
}

function stepExtent(step, map) {
    let from;
    let to;
    if (step instanceof AddMarkStep || step instanceof RemoveMarkStep) {
        ({ from, to } = step);
    } else {
        map.forEach((start, end) => {
            from = from == null ? start : Math.min(from, start);
            to = to == null ? end : Math.max(to, end);
        });
    }
    // FIXME this may fail when new step types are added. If we keep using
    // it we should probably propose it as a method in the Step interface
    // itself.
    if (from == null) {
        throw new Error('Could not determine extent for step ' + JSON.stringify(step.toJSON()));
    }
    return { from, to };
}

function getTouchedSections(doc, from, to) {
    const touched = [];
    doc.content.forEach((node, pos) => {
        const end = pos + node.nodeSize;
        if (from < end && to > pos) {
            touched.push({ pos, id: node.attrs.id, outside: from <= pos || to >= end });
        }
    });
    return touched;
}

export function applyDocTransaction(transaction, docState, sectionStates, makeSectionState) {
    return {
        docState: docState.apply(transaction),
        sectionStates: applyDocTransactionToSections(sectionStates, transaction, makeSectionState),
    };
}

export function applySectionTransaction(sectionId, transaction, docState, sectionStates, addTrackingKeysToStep) {
    const newSectionStates = sectionStates.map((state) => {
        if (id(state.doc) !== sectionId) {
            return state;
        }
        const { state: newState, transactions } = state.applyTransaction(transaction);
        docState = applySectionTransactionsToDoc(docState, transactions, sectionId, addTrackingKeysToStep);
        return newState;
    });
    return { docState, sectionStates: newSectionStates };
}
