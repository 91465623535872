import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { RuumTask } from '@ruum/ruum-reducers';
import { PaywallPrivilege } from '../../../paywall/paywall-privilege';
import {
    filterDropdownList,
    RuumDropdownItem,
    RuumDropdownItemFilters,
    RuumDropdownList,
    RuumDropdownPlacement,
    RuumDropdownSections,
} from '../../../shared/ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-task-details-options',
    template: `
        <ruum-dropdown
            *ngIf="!showNewMenu"
            [placement]="placement"
            [sections]="sections"
            [width]="'200px'"
            (selectItem)="onSelect($event)"
        >
            <!-- desktop button -->
            <button
                class="btn btn-icon btn-link-secondary"
                data-content="ruum-dropdown-button-desktop"
                aria-label="'Show Task detail related options'"
                type="button"
            >
                <i class="icon icon-contextual-menu"></i>
            </button>
            <!-- mobile button -->
            <button class="btn btn-icon btn-link-secondary" data-content="ruum-dropdown-button-mobile" type="button">
                <i class="icon icon-contextual-menu"></i>
            </button>
        </ruum-dropdown>

        <ruum-menu *ngIf="showNewMenu" [placement]="placement" [width]="'200px'">
            <ruum-menu-trigger>
                <button
                    class="btn btn-icon btn-link-secondary"
                    data-content="ruum-dropdown-button-desktop"
                    aria-label="'Show Task detail related options'"
                    type="button"
                >
                    <i class="icon icon-contextual-menu"></i>
                </button>
            </ruum-menu-trigger>
            <ng-container *ngFor="let section of sections; let last = last">
                <ng-container *ngFor="let option of section">
                    <ruum-menu-option
                        (click)="onSelect(option)"
                        [theme]="option.theme"
                        [icon]="option.icon"
                        [disabled]="option.disabled"
                        [disableTooltip]="!option.disabled"
                        [ngbTooltip]="option.tooltip?.title"
                    >
                        {{ option.name }}
                    </ruum-menu-option>
                </ng-container>
                <ruum-menu-devider *ngIf="!last"></ruum-menu-devider>
            </ng-container>
        </ruum-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskDetailsOptionsComponent implements OnChanges {
    showNewMenu = true;

    @HostBinding('class') hostClassList = 'd-flex flex-fill';
    @Input()
    placement: RuumDropdownPlacement = ['bottom-right', 'top-right', 'auto'];

    @Input() task: RuumTask;
    @Input() workspaceId: string;
    @Input() isAdmin = false;
    @Input() isLocked = false;

    @Output() deleteTask = new EventEmitter<RuumDropdownItem>();
    @Output() assignCustomField = new EventEmitter<RuumDropdownItem>();
    @Output() moveToSection = new EventEmitter<RuumDropdownItem>();
    @Output() setIsMilestone = new EventEmitter<{ isMilestone: boolean }>();

    sections: RuumDropdownSections;

    private defaultOptions: RuumDropdownList = [];
    private deleteTaskOption: RuumDropdownList = [];
    optionFilters: RuumDropdownItemFilters = [
        {
            id: 'ASSIGN_CUSTOM_FIELD',
            isDisabled: () => !this.workspaceId,
            disabledTooltip: {
                title: 'You can only add Custom Fields to Ruums inside of a Workspace',
                placement: 'auto',
            },
        },
        {
            id: 'ASSIGN_CUSTOM_FIELD',
            isDisabled: () => !this.isAdmin,
            disabledTooltip: {
                title: 'Only Ruum Admins can do it.',
                placement: 'auto',
            },
        },
        {
            id: 'ASSIGN_CUSTOM_FIELD',
            isDisabled: () => this.task.isMilestone,
            disabledTooltip: {
                title: 'You can only add Custom Fields to Tasks',
                placement: 'auto',
            },
        },
        {
            id: 'DELETE_TASK',
            isDisabled: () => this.isLocked,
            disabledTooltip: {
                title: `This action was blocked in this ruum's template setting`,
                placement: 'auto',
            },
        },
        {
            id: 'TURN_INTO_TASK',
            isDisabled: () => this.isLocked,
            disabledTooltip: {
                title: `This action was blocked in this ruum's template setting`,
                placement: 'auto',
            },
        },
        {
            id: 'TURN_INTO_MILESTONE',
            isDisabled: () => this.isLocked,
            disabledTooltip: {
                title: `This action was blocked in this ruum's template setting`,
                placement: 'auto',
            },
        },
        {
            id: 'MOVE_TO_SECTION',
            isDisabled: () => this.isLocked,
            disabledTooltip: {
                title: `This action was blocked in this ruum's template setting`,
                placement: 'auto',
            },
        },
    ];

    ngOnChanges() {
        this.defaultOptions = [
            { id: 'MOVE_TO_SECTION', name: 'Move to Section' },
            { id: 'ASSIGN_CUSTOM_FIELD', name: 'Add Custom Field' },
        ];

        if (this.task.isMilestone) {
            this.defaultOptions.push({ id: 'TURN_INTO_TASK', name: 'Turn into Task' });
        } else {
            this.defaultOptions.push({
                id: 'TURN_INTO_MILESTONE',
                name: 'Turn into Milestone',
                paywallRejectStrategy: 'basic',
                paywallPrivilege: PaywallPrivilege.MILESTONE_CREATION,
            });
        }
        this.deleteTaskOption = [
            { id: 'DELETE_TASK', name: 'Delete ' + (this.task.isMilestone ? 'Milestone' : 'Task'), theme: 'danger' },
        ];
        this.sections = [
            filterDropdownList(this.defaultOptions, this.optionFilters),
            filterDropdownList(this.deleteTaskOption, this.optionFilters),
        ];
    }

    onSelect(item: RuumDropdownItem) {
        switch (item.id) {
            case 'DELETE_TASK':
                return this.deleteTask.emit(item);
            case 'ASSIGN_CUSTOM_FIELD':
                return this.assignCustomField.emit(item);
            case 'MOVE_TO_SECTION':
                return this.moveToSection.emit(item);
            case 'TURN_INTO_TASK':
                return this.setIsMilestone.emit({ isMilestone: false });
            case 'TURN_INTO_MILESTONE':
                return this.setIsMilestone.emit({ isMilestone: true });
            default:
                return;
        }
    }
}
