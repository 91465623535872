import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { ProjectSelectComponent } from './project-select.component';

@NgModule({
    imports: [CommonModule, SelectModule],
    declarations: [ProjectSelectComponent],
    exports: [ProjectSelectComponent],
})
export class ProjectSelectModule {}
