import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { Day } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MOBILE_WIDTH_THRESHOLD } from './../../constants/other';
import { EnterpriseReport } from './../dashboard.model';
import { DashboardService } from './../dashboard.service';

interface ChartData {
    day: Day;
    activeUsersCount: number;
}

@Component({
    selector: 'user-usage-graph',
    template: `
        <svg class="usage-svg" [style.width.px]="width" [style.height.px]="height"></svg>
        <div class="usage-tooltip"></div>
    `,
    styles: [
        `
            .usage-tooltip {
                opacity: 0;
                background-color: #ffffff;
                position: absolute;
                text-align: center;
                padding: 8px;
                height: 40px;
                line-height: 30px;
                min-width: 80px;
                box-shadow: 3px 3px 14px 0 rgba(181, 181, 181, 0.5);
                color: #173145;
                font-size: 30px;
                font-weight: bold;
                border-radius: 8px;
                pointer-events: none;
            }
        `,
    ],
})
export class DashboardUserUsageGraphComponent implements OnInit, OnDestroy, OnChanges {
    @Input() days: number;
    svg: any;
    usageData$: Observable<EnterpriseReport>;
    highlighting = false;
    resizeHandler: () => void;
    height = 500;
    width = 800;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit() {
        this.usageData$ = this.dashboardService.getReport();
        this.usageData$.subscribe((reportData: EnterpriseReport) => {
            if (!reportData) {
                return this.cleanSVG();
            }
            this.initSVG(this.transformData(reportData));
        });
        this.initSizes();
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    initSizes() {
        const sidebarWidth: number = window.innerWidth < MOBILE_WIDTH_THRESHOLD ? 130 : 332;
        this.resizeHandler = () => {
            const sidebarWidth2: number = window.innerWidth < MOBILE_WIDTH_THRESHOLD ? 130 : 332;
            const newWidth: number = Math.max(332, Math.min(window.innerWidth - sidebarWidth2, 800));
            if (this.width !== newWidth) {
                this.width = newWidth;
                this.ngOnChanges();
            }
        };
        this.resizeHandler();
        this.width = Math.max(300, Math.min(window.innerWidth - sidebarWidth, 800));
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    }

    transformData(reportData: EnterpriseReport) {
        if (!reportData || !Object.keys(reportData).length) {
            return [];
        }
        return reportData.entries.slice(0, Number(this.days)).map((entry) => {
            return {
                day: entry.date,
                activeUsersCount: entry.activeUserCount,
            };
        });
    }

    ngOnChanges() {
        if (this.usageData$) {
            this.usageData$.pipe(take(1)).subscribe((usageData) => this.initSVG(this.transformData(usageData)));
        }
    }

    cleanSVG() {
        if (this.svg) {
            this.svg.selectAll('*').remove();
        }
    }

    initSVG(usageData: UsageDataEntry[]) {
        this.svg = d3.select('.usage-svg');
        this.cleanSVG();
        if (!usageData || !usageData.length) {
            return;
        }
        const margin = { top: 25, right: 45, bottom: 30, left: 25 };
        const innerWidth = this.width - margin.left - margin.right;
        const innerHeight = this.height - margin.top - margin.bottom;
        const basis = this.svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
        const x = d3
            .scaleLinear()
            .domain(d3.extent(usageData, (d) => getTimestampFromDay(d.day)))
            .rangeRound([0, innerWidth]);
        const y = d3
            .scaleLinear()
            .domain([0, d3.max(usageData, (d) => getClosestValue(d.activeUsersCount))])
            .rangeRound([innerHeight, 0]);
        const line = d3
            .line<ChartData>()
            .x((d: ChartData) => x(new Date(d.day.year, d.day.month - 1, d.day.day).getTime()))
            .y((d: ChartData) => y(d.activeUsersCount));

        const yAxis = basis
            .append('g')
            .call(
                d3
                    .axisLeft(y)
                    .ticks(5)
                    .tickSizeInner(0),
            )
            .attr('transform', `translate(${innerWidth + margin.left + 20}, 0)`);
        yAxis.select('.domain').remove();
        yAxis.selectAll('.tick text').attr('fill', '#abaeaf');

        const gridlines = this.svg
            .append('g')
            .call(
                d3
                    .axisLeft(y)
                    .ticks(5)
                    .tickSize(-innerWidth)
                    .tickFormat(undefined),
            )
            .attr('transform', `translate(${margin.left}, ${margin.top})`);
        gridlines.select('.domain').remove();
        gridlines.selectAll('.tick line').attr('stroke', '#ececec');

        const firstDate = this.svg
            .append('g')
            .attr('transform', `translate(0, ${innerHeight + margin.top + 5})`)
            .append('text')
            .text(getDateLabelText(usageData[usageData.length - 1].day))
            .attr('fill', '#abaeaf')
            .attr('x', 0)
            .attr('y', 20)
            .style('font-size', 14);

        const lastDate = this.svg
            .append('g')
            .attr('transform', `translate(${innerWidth}, ${innerHeight + margin.top + 5})`)
            .append('text')
            .text(getDateLabelText(usageData[0].day))
            .attr('fill', '#abaeaf')
            .attr('x', 2)
            .attr('y', 20)
            .style('font-size', 14);

        const hoverDate = this.svg
            .append('g')
            .append('text')
            .attr('fill', '#abaeaf')
            .attr('x', 0)
            .attr('y', 20)
            .style('font-size', 14);

        // the data line
        this.svg
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .append('path')
            .datum(usageData)
            .attr('fill', 'none')
            .attr('stroke', '#A2D16B')
            .attr('stroke-width', 3)
            .attr('d', line);

        // the indicator when hovering
        const hoverElem = this.svg
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .style('opacity', 0);
        hoverElem
            .append('line')
            .style('stroke', '#ececec')
            .style('stroke-width', 2)
            .attr('y1', 0)
            .attr('y2', innerHeight);
        hoverElem
            .append('circle')
            .attr('r', 11)
            .style('fill', '#ffffff')
            .style('stroke', '#A2D16B')
            .style('stroke-width', 10);

        const hoverInfo = d3.select('.usage-tooltip');

        this.svg
            .append('rect')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .attr('width', innerWidth)
            .attr('height', innerHeight)
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .on('mouseover', () => hoverElem.style('opacity', 1))
            .on('mouseout', () => {
                hoverElem.style('opacity', 0);
                hoverInfo.style('opacity', 0);
                hoverDate.style('opacity', 0);
                firstDate.style('opacity', 1);
                lastDate.style('opacity', 1);
            })
            .on('mousemove', mousemove);

        const svgCoordinates = this.svg._groups[0][0] ? this.svg._groups[0][0].getBoundingClientRect() : undefined;

        function mousemove() {
            if (!svgCoordinates) {
                return;
            }
            const contentNode = d3.select('.content').node() as HTMLDivElement;
            const scrollTop = contentNode ? contentNode.scrollTop : 0;
            const xTimestamp = x.invert(d3.mouse(this)[0]);
            const indexToShow = usageData.map((a) => getTimestampFromDay(a.day)).findIndex((a) => a < xTimestamp);
            const dataPointLeft = usageData[indexToShow];
            const dataPointRight = indexToShow > 0 ? usageData[indexToShow - 1] : undefined;
            const data = dataPointRight
                ? xTimestamp - getTimestampFromDay(dataPointLeft.day) <
                  getTimestampFromDay(dataPointRight.day) - xTimestamp
                    ? dataPointLeft
                    : dataPointRight
                : dataPointLeft;
            if (!data) {
                return;
            }
            hoverElem
                .attr(
                    'transform',
                    'translate(' +
                        (x(getTimestampFromDay(data.day)) + margin.left) +
                        ',' +
                        (y(data.activeUsersCount) + margin.top) +
                        ')',
                )
                .select('line')
                .attr('y2', innerHeight)
                .attr('transform', 'translate(0' + -y(data.activeUsersCount) + ')');
            hoverInfo.html(data.activeUsersCount.toString());
            hoverInfo
                .style('opacity', 1)
                .style('left', x(getTimestampFromDay(data.day)) + 18 + 'px')
                .style('top', y(data.activeUsersCount) + margin.top / 2 + innerHeight - 82 + 'px');
            hoverDate.text(getDateLabelText(data.day));
            hoverDate
                .style('opacity', 1)
                .attr(
                    'transform',
                    'translate(' +
                        (x(getTimestampFromDay(data.day)) +
                            margin.left -
                            hoverDate._groups[0][0].getBoundingClientRect().width / 2) +
                        ',' +
                        (innerHeight + margin.top + 5) +
                        ')',
                );
            firstDate.style('opacity', 0);
            lastDate.style('opacity', 0);
        }
    }
}

function getClosestValue(value: number): number {
    return Math.ceil(value / 10) * 10;
}

function getTimestampFromDay(day: Day): number {
    return new Date(day.year, day.month - 1, day.day).getTime();
}

function getDateLabelText(day: Day): string {
    return new Date(day.year, day.month - 1, day.day).format('DD MMM');
}

interface UsageDataEntry {
    day: Day;
    activeUsersCount: number;
}
