import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemConnector } from '@ruum/ruum-reducers';
import { ExternalSystemListService } from '../../../common/connectors/externalSystem/external-system-list.service';
import { createIOArrayFromObject } from './system-connector-dialog.utils';

@Component({
    selector: 'ruum-system-connector-dialog-edit',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">Edit System Connector</h2>
            <form [formGroup]="form">
                <div class="form-group mb-3">
                    <label class="form-default-label" for="labelField">Name</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the name of your new system connector..."
                        id="labelField"
                        type="text"
                        formControlName="title"
                        ngbAutofocus
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="description">Description</label>
                    <input
                        id="sc-description"
                        class="form-control form-control-sm"
                        placeholder="Enter a description"
                        type="text"
                        formControlName="description"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="select-system">System</label>
                    <ruum-system-select id="select-system" formControlName="systemId"></ruum-system-select>
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="pathField">path</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the path to your API endpoint..."
                        id="pathField"
                        type="path"
                        formControlName="path"
                    />
                </div>
                <div class="form-group mb-3">
                    <label class="form-default-label" for="type">Type</label>
                    <ruum-select formControlName="type" id="type" (selectChange)="handleTypeChanged($event)">
                        <ruum-select-option content="Standard" value="standard"></ruum-select-option>
                        <ruum-select-option content="SCP Workflow" value="scp_workflow"></ruum-select-option>
                        <ruum-select-option content="RPA" value="rpa_bot"></ruum-select-option>
                    </ruum-select>
                </div>

                <div class="form-group mb-3" *ngIf="form.value.type === 'scp_workflow'">
                    <label class="form-default-label" for="definitionIdField">SCP Workflow Definition ID</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="Enter the SCP Workflow Definition ID"
                        id="definitionIdField"
                        type="text"
                        formControlName="definitionId"
                    />
                </div>

                <div class="form-group mb-3" *ngIf="form.value.type === 'rpa_bot'">
                    <label class="form-default-label" for="xtokenField">SAP iRPA Token</label>
                    <input
                        class="form-control form-control-sm"
                        placeholder="***********"
                        id="xtokenField"
                        type="text"
                        formControlName="xtoken"
                    />
                </div>

                <hr />
                <!-- INPUTS -->
                <div class="d-flex align-items-center mt-5">
                    <div class="flex-fill text-truncate text-small font-weight-bold">Request Property Mapping</div>
                    <button class="btn btn-outline-primary btn-xs" (click)="addInputProperty()">
                        <i class="icon icon-add"></i> Add Property
                    </button>
                </div>
                <div class="d-table mt-5 w-100" formArrayName="input" autocomplete="off">
                    <div class="d-table-row text-secondary text-tiny font-weight-bold">
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-name">PROPERTY NAME</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-type">DATA TYPE</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-label">LABEL</label>
                        </div>
                        <div class="d-table-cell px-2"></div>
                    </div>
                    <ruum-system-connector-io-item
                        *ngFor="let ctrItem of systemInputs.controls; let i = index"
                        [formGroup]="ctrItem"
                        (remove)="removeInputProperty(i)"
                    ></ruum-system-connector-io-item>
                </div>

                <!-- OUTPUTS -->
                <div class="d-flex align-items-center mt-5">
                    <div class="flex-fill text-truncate text-small font-weight-bold">Response Property Mapping</div>
                    <button class="btn btn-outline-primary btn-xs" (click)="addOutputProperty()">
                        <i class="icon icon-add"></i> Add Property
                    </button>
                </div>
                <div class="d-table mt-5 w-100" formArrayName="output" autocomplete="off">
                    <div class="d-table-row text-secondary text-tiny font-weight-bold">
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-name">PROPERTY NAME</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-type">DATA TYPE</label>
                        </div>
                        <div class="d-table-cell px-2">
                            <label class="form-default-label" for="io-label">LABEL</label>
                        </div>
                        <div class="d-table-cell px-2"></div>
                    </div>
                    <ruum-system-connector-io-item
                        *ngFor="let ctrItem of systemOutputs.controls; let i = index"
                        [formGroup]="ctrItem"
                        (remove)="removeOutputProperty(i)"
                    ></ruum-system-connector-io-item>
                </div>

                <div class="d-flex align-items-center justify-content-between mt-6">
                    <a
                        class="btn btn-xs btn-outline-accent"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.sap.com/viewer/f8e58f003adf4cc78f5152f51a66e771/Latest/en-US"
                    >
                        Need Help? See Documentation!
                    </a>
                    <button
                        class="btn btn-lg btn-primary"
                        type="button"
                        [disabled]="!form.valid"
                        (click)="updateConnector()"
                    >
                        Update
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemConnectorDialogEditComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Input() systemConnector: SystemConnector;

    @Output() create = new EventEmitter<SystemConnector>();

    form: FormGroup;
    systemInputs: FormArray;
    systemOutputs: FormArray;

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        public externalSystemListService: ExternalSystemListService,
    ) {}

    ngOnInit() {
        const { title, systemId, description, path, input, output, type } = this.systemConnector;
        let definitionId;

        if (this.systemConnector.type === 'scp_workflow') {
            definitionId = this.systemConnector.definitionId;
        }
        const inputList = createIOArrayFromObject(input);
        const outputList = createIOArrayFromObject(output);
        const inputControls = this.createIoFormControlList(inputList);
        const ouptuptControls = this.createIoFormControlList(outputList);
        this.systemInputs = this.formBuilder.array(inputControls);
        this.systemOutputs = this.formBuilder.array(ouptuptControls);
        this.form = this.formBuilder.group({
            title: [title, Validators.required],
            systemId: [systemId, Validators.required],
            description: [description],
            path: [path, Validators.required],
            type: [type, Validators.required],
            xtoken: [null],
            definitionId: [definitionId],
            input: this.systemInputs,
            output: this.systemOutputs,
        });
        this.setType(type);
    }

    addInputProperty() {
        const itemFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            type: ['string', Validators.required],
            label: ['', Validators.required],
        });
        this.systemInputs.push(itemFormGroup);
    }

    removeInputProperty(index: number) {
        this.systemInputs.removeAt(index);
    }

    addOutputProperty() {
        const itemFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            type: ['string', Validators.required],
            label: ['', Validators.required],
        });
        this.systemOutputs.push(itemFormGroup);
    }

    removeOutputProperty(index: number) {
        this.systemOutputs.removeAt(index);
    }

    updateConnector() {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.value;
        this.ngbActiveModal.close(data);
    }

    private createIoFormControlList(list: { name: string; type: string; label: string }[]) {
        return list.map((item) =>
            this.formBuilder.group({
                name: [item.name, Validators.required],
                type: [item.type, Validators.required],
                label: [item.label, Validators.required],
            }),
        );
    }
    handleTypeChanged(type: string) {
        if (type) {
            this.setType(type);
        }
    }

    setType(type: string) {
        switch (type) {
            case 'rpa_bot':
                if (this.systemConnector.type !== 'rpa_bot') {
                    this.form.get('xtoken').setValidators([Validators.required]);
                }
                this.form.get('definitionId').setValidators(null);
                this.form.get('definitionId').setValue(null);
                return;
            case 'scp_workflow':
                this.form.get('definitionId').setValidators([Validators.required]);
                this.form.get('xtoken').setValidators(null);
                this.form.get('xtoken').setValue(null);
                return;
            case 'standard':
                this.form.get('definitionId').setValidators(null);
                this.form.get('definitionId').setValue(null);
                this.form.get('xtoken').setValidators(null);
                this.form.get('xtoken').setValue(null);
                return;
        }
    }
}
