import { Location } from '@angular/common';
import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnterpriseRole } from '@ruum/ruum-reducers';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectedEnterpriseService } from '../../common/connectors/enterprise/selected-enterprise.service';
import { EnterprisesAccess, ProfileService } from '../../common/connectors/profile/profile.service';
import { EnterpriseSettingsService } from '../common/enterpriseSettings.service';
enum NavigationMenuItemType {
    item = 'item',
    group = 'group',
}

interface NavigationMenuItem {
    id: string;
    text: string;
    roles: string[];
    type: NavigationMenuItemType;
    icon?: string;
    path?: string;
    children?: NavigationMenuItem[];
    expanded?: boolean;
}
@Component({
    selector: 'ruum-admin-sidenav-content',
    template: `
        <div class="d-flex align-items-center px-3 py-md-1 mb-1" *ngIf="(availableEnterpriseIds$ | async)?.length > 1">
            <ruum-select [(select)]="_selectedEnterpriseId" [multiSelect]="false" [search]="false" theme="white">
                <ruum-select-placeholder>
                    {{ (availableEnterpriseIds$ | async)[0] }}
                </ruum-select-placeholder>

                <ruum-select-option
                    *ngFor="let enterpriseId of availableEnterpriseIds$ | async"
                    (click)="switchEnterprise(enterpriseId)"
                    [value]="enterpriseId"
                    [content]="enterpriseId.split('_')[1]"
                ></ruum-select-option>
            </ruum-select>
        </div>
        <ng-container *ngFor="let item of items$ | async">
            <ng-container *ngIf="item.type === 'item'">
                <a
                    class="node-item d-flex align-items-center px-3 py-md-1 mb-1"
                    [class.active]="item.id === (activeMenuItemId$ | async)"
                    [routerLink]="getRouterLink(item)"
                    (click)="navigationChanged.emit(true)"
                >
                    <div class="btn btn-xs btn-icon btn-link-white-64">
                        <i class="icon" [ngClass]="item.icon"></i>
                    </div>

                    <div class="flex-fill text-white text-small text-truncate py-3 py-md-1 ie-truncate-fix">
                        {{ item.text }}
                    </div>
                </a>
            </ng-container>

            <div class="d-flex flex-column mb-1" [class.group]="item.expanded" *ngIf="item.type === 'group'">
                <div
                    class="node-item d-flex align-items-center px-3 py-md-1 flex-fill mb-1"
                    (click)="collapseItem(item)"
                    (keydown.space)="collapseItem(item)"
                    (keydown.enter)="collapseItem(item)"
                >
                    <div class="btn btn-xs btn-icon btn-link-white-64">
                        <i class="icon" [ngClass]="item.icon"></i>
                    </div>
                    <div class="flex-fill text-white text-small text-truncate py-3 py-md-1 ie-truncate-fix">
                        {{ item.text }}
                    </div>
                    <div class="btn btn-xs btn-icon btn-link-white-64">
                        <i *ngIf="item.expanded === true" class="icon icon-cevron-down"></i>
                        <i *ngIf="item.expanded === false" class="icon icon-cevron-up"></i>
                    </div>
                </div>
                <div class="d-flex flex-column" *ngIf="item.expanded">
                    <a
                        *ngFor="let child of item.children"
                        class="node-item d-flex flex-fill align-items-center px-3 py-md-1 mb-1 pl-8"
                        [class.active]="child.id === (activeMenuItemId$ | async)"
                        [routerLink]="getRouterLink(child)"
                        (click)="navigationChanged.emit(true)"
                    >
                        <div class="flex-fill text-white text-small text-truncate py-3 py-md-1 ie-truncate-fix">
                            {{ child.text }}
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
        <div class="d-flex flex-fill"></div>
        <ruum-admin-company-info *ngIf="enterpriseIdSet$ | async"></ruum-admin-company-info>
    `,
    styles: [
        `
            .group {
                background-color: rgba(256, 256, 256, 0.08);
            }

            .node-item:hover,
            .node-item.active {
                background: #507ca8;
                color: white;
                cursor: pointer;
            }
        `,
    ],
})
export class AdminNavigationMenuComponent implements OnInit {
    @HostBinding('class') hostClassList = 'flex-fill flex-column d-flex mt-2';

    @Output() navigationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    items$: Observable<NavigationMenuItem[]>;
    activeMenuItemId$: Subject<string> = new Subject<string>();
    enterpriseIdSet$: Observable<boolean>;
    availableEnterpriseIds$: Observable<string[]>;

    selectedEnterpriseId: Observable<string> = new Observable<string>();
    _selectedEnterpriseId: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private profileService: ProfileService,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private enterpriseSettingsService: EnterpriseSettingsService,
        private location: Location,
    ) {}

    ngOnInit() {
        this.enterpriseIdSet$ = this.profileService
            .getCurrentUserEnterpriseId$()
            .pipe(map((enterpriseId) => !!enterpriseId));

        this.availableEnterpriseIds$ = this.profileService
            .getUserAdminEnterprises()
            .pipe(map((enterprises: EnterprisesAccess[]) => enterprises.map((enterprise) => enterprise.enterpriseId)));

        this.selectedEnterpriseId = this.selectedEnterpriseService.selectedEnterprise().pipe(
            map((selectedEnterprise) => {
                this._selectedEnterpriseId = selectedEnterprise.id;
                return selectedEnterprise.id;
            }),
        );

        this.items$ = this.selectedEnterpriseId.pipe(
            switchMap(
                (enterpriseId): Observable<NavigationMenuItem[]> => {
                    return this.profileService.getCurrentUserEnterpriseRole$(enterpriseId).pipe(
                        map((role) => {
                            return this.getMenuItemsForUserRole(this.getAllMenuItems(), role);
                        }),
                    );
                },
            ),
        );
    }

    switchEnterprise(enterpriseId: string) {
        this.selectedEnterpriseService.selectEnterprise(enterpriseId).then(() => {
            if (this.selectedEnterpriseService.isIntegrationAdminOfSelectedEnterprise()) {
                this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/systems`);
            } else {
                this.router.navigateByUrl(`/admin/enterprise/${enterpriseId}/overview`);
            }
        });
    }

    getRouterLink(item): string {
        if (item.path === 'home') {
            return './admin/enterprise';
        }
        return `./admin/enterprise/${this._selectedEnterpriseId}/${item.path}`;
    }

    collapseItem(item) {
        item.expanded = !item.expanded;
    }

    private roleCanSeeMenuItem(item: NavigationMenuItem, role: EnterpriseRole): boolean {
        if (item.roles.length > 0) {
            return item.roles.indexOf(role) >= 0;
        } else {
            return true; // no role configuration
        }
    }

    private getMenuItemsForUserRole(items: NavigationMenuItem[] = [], role: EnterpriseRole): NavigationMenuItem[] {
        return items.reduce((filteredMenu, item) => {
            if (this.roleCanSeeMenuItem(item, role)) {
                const itemCopy = { ...item };
                if (item.type === NavigationMenuItemType.group) {
                    itemCopy.children = this.getMenuItemsForUserRole(itemCopy.children, role);
                }
                filteredMenu.push(itemCopy);
            }
            return filteredMenu;
        }, []);
    }

    getAllMenuItems(): NavigationMenuItem[] {
        return [
            {
                id: 'overview',
                path: 'overview',
                icon: 'icon-admin-dashboard',
                text: 'Dashboard',
                type: NavigationMenuItemType.item,
                roles: [EnterpriseRole.EnterpriseAdmin],
            },
            {
                id: 'users',
                path: 'users',
                icon: 'icon-team',
                text: 'Users',
                type: NavigationMenuItemType.item,
                roles: [EnterpriseRole.EnterpriseAdmin],
            },
            {
                id: 'storage',
                path: 'storage',
                icon: 'icon-data',
                text: 'Storage',
                type: NavigationMenuItemType.item,
                roles: [EnterpriseRole.EnterpriseAdmin],
            },
            {
                id: 'integrations',
                icon: 'icon-activity',
                text: 'Integrations',
                type: NavigationMenuItemType.group,
                expanded: true,
                roles: [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin],
                children: [
                    /*
                    {
                        id: 'systems',
                        path: 'systems',
                        text: 'External Systems',
                        type: NavigationMenuItemType.item,
                        roles: [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin],
                    },
                    {
                        id: 'connectors',
                        path: 'connectors',
                        text: 'System Connectors',
                        type: NavigationMenuItemType.item,
                        roles: [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin],
                    },
                    {
                        id: 'api-mapping',
                        path: 'api-mapping',
                        text: 'API Mapping',
                        type: NavigationMenuItemType.item,
                        roles: [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin],
                    },
                    */
                    {
                        id: 'technical-users',
                        path: 'technical-users',
                        text: 'Technical Users',
                        type: NavigationMenuItemType.item,
                        roles: [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin],
                    },
                    {
                        id: 'webhooks',
                        path: 'webhooks',
                        text: 'Webhooks',
                        type: NavigationMenuItemType.item,
                        roles: [EnterpriseRole.EnterpriseAdmin, EnterpriseRole.EnterpriseIntegrationAdmin],
                    },
                ],
            },
            {
                id: 'support',
                path: 'support',
                icon: 'icon-help-outline',
                text: 'Support',
                type: NavigationMenuItemType.item,
                roles: [],
            },
        ];
    }
}
