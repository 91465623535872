import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommentableType } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../app.store';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { CommentsListFilters } from '../chats/chat-list.model';
import { CommentableData, CommentListItem, CommentListLoadedAction } from '../commentable/commentable.reducer';
import { CommentableInfo } from '../commentable/commentable.service';
import { PaginatedList } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ReadModelBackendConnector } from '../readModelConnector.service';

@Injectable({ providedIn: 'root' })
export class CommentListService extends PaginatedListLoader<CommentListItem, CommentsListFilters, void> {
    private commentable$: BehaviorSubject<CommentableInfo> = new BehaviorSubject<CommentableInfo>(undefined);
    private pageSize = 25;
    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        private store: Store<AppState>,
        protected alertService: RuumAlertService,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
        this.setUpObservables();
    }

    loadComments(projectId: string, objectType: CommentableType, objectId: string): void {
        this.pageSize = 25;
        this.commentable$.next({ projectId, objectType, objectId });
        this.loadList();
    }
    loadCommentsToCommentsId(projectId: string, objectType: CommentableType, objectId: string, commentId: string) {
        this.pageSize = 200;
        this.commentable$.next({ projectId, objectType, objectId });
        this.loadList();
    }

    private setUpObservables() {
        this.getListObservable().subscribe((comments) => {
            this.serviceHelper.dispatchWithoutPersisting<CommentListLoadedAction>('COMMENT_LIST_LOADED', {
                list: comments,
            });
        });
    }

    getData(page: number, filters: CommentsListFilters): Observable<PaginatedList<CommentListItem>> {
        return this.readModelConnector.getComments(
            { page, pageSize: this.pageSize },
            {
                projectId: filters.projectId,
                objectType: filters.objectType,
                objectId: filters.objectId,
            },
            { by: 'createdAt', direction: 'desc' },
        );
    }

    getFilters$() {
        return this.commentable$.pipe(
            map((commentable) => {
                const filters: CommentsListFilters = {
                    ...commentable,
                };
                return filters;
            }),
        );
    }

    commentable(): Observable<CommentableData> {
        return this.store.select('commentable');
    }

    comments$(): Observable<CommentListItem[]> {
        return this.commentable().pipe(map((commentable) => commentable.list.rows));
    }

    commentableParams$(): Observable<CommentableInfo> {
        return this.commentable().pipe(
            map((commentable) => ({
                projectId: commentable.projectId,
                objectType: commentable.objectType,
                objectId: commentable.objectId,
            })),
        );
    }

    getStoreData$() {
        return this.commentable().pipe(map((commentable) => commentable.list));
    }
}
