import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SelectPlacement } from '../select.component';
import { SelectService } from '../select.service';

@Component({
    selector: 'ruum-select-popover-desktop',
    template: `
        <div class="popover-content-wrapper">
            <ng-container *ngIf="isMultiSelect">
                <ng-content select="ruum-select-tag-list"></ng-content>
            </ng-container>
            <div *ngIf="!isMultiSelect && isSearch" class="m-1">
                <ng-content select="ruum-select-search"></ng-content>
            </div>
            <div *ngIf="isSearch || isMultiSelect" class="border-bottom border-light"></div>
            <div class="mx-1 mt-1 rounded popover-options-container desktop" [style.padding-bottom.px]="2">
                <ng-template [cdkPortalOutlet]="optionsTempaltePortal"></ng-template>
                <ruum-load-more *ngIf="loading" [size]="'sm'" (loadMore)="loadMoreOptions.emit()"></ruum-load-more>
            </div>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPopoverDesktopComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block shadow w-100 rounded popover-container';

    @HostBinding('class.multi-select')
    @Input()
    isMultiSelect = false;

    @Input() loading = false;

    @Input() isSearch = false;

    @Input() optionsTempaltePortal: TemplatePortal;

    @Input() placement: SelectPlacement[] = [];

    @Output() loadMoreOptions = new EventEmitter();

    @HostBinding('class.mt-1')
    get placementBottom(): boolean {
        return (
            !this.isMultiSelect &&
            (this.placement.includes('bottom') ||
                this.placement.includes('bottom-left') ||
                this.placement.includes('bottom-right'))
        );
    }

    @HostBinding('class.mb-1')
    get placementTop(): boolean {
        return (
            !this.isMultiSelect &&
            (this.placement.includes('top') ||
                this.placement.includes('top-left') ||
                this.placement.includes('top-right'))
        );
    }

    @HostBinding('class.mr-1')
    get placementLeft(): boolean {
        return (
            !this.isMultiSelect &&
            (this.placement.includes('left') ||
                this.placement.includes('left-top') ||
                this.placement.includes('left-bottom'))
        );
    }

    @HostBinding('class.ml-1')
    get placementRight(): boolean {
        return (
            !this.isMultiSelect &&
            (this.placement.includes('right') ||
                this.placement.includes('right-top') ||
                this.placement.includes('right-bottom'))
        );
    }

    constructor(private selectService: SelectService) {}

    ngOnInit() {}

    @HostListener('document:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown') {
            this.selectService.focusNextItem();
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            this.selectService.focusPrevItem();
            event.preventDefault();
        }
    }
}
