import { AddCommentActionPayload, User } from '@ruum/ruum-reducers';
import { ProjectParticipantWithAllRoles } from '../connectors/readModelConnector.service';
import { getRandomId } from '../utils.service';

export function getAtMentionsFromCommentText(commentText: string, participants: User[]): string[] {
    // space or begining of line followed '@' followed by anything that is not a whitespace.
    const atMentionAttempts = !!commentText && commentText.match(/(^|\s)@([^\s]*)/g);

    if (atMentionAttempts) {
        return getValidAtMentions(removeAtAndSpaces(atMentionAttempts), participants);
    } else {
        return [];
    }
}

export function removeAtAndSpaces(atMentionAttempts: string[]) {
    return atMentionAttempts.map((s) => s.trim().replace('@', ''));
}

/**
 * An at mention attempt has the following format: "@UserEmailWithoutDomain"
 *
 * So to mention user with mail: felipe.taiarol@sap.com
 *
 * The user should type: @felipe.taiarol"
 *
 * if the text after @ matches an user email the attempt was successful.
 */
export function getValidAtMentions(atMentionAttempts: string[], participants: User[]) {
    return atMentionAttempts.filter(
        (attempt) => participants.map((p) => p.mail.replace(/@.*/, '')).indexOf(attempt) !== -1,
    );
}

export function getMentionedUserIds(text: string, participants: ProjectParticipantWithAllRoles[]): string[] {
    const mentions: string[] = getAtMentionsFromCommentText(text, participants);
    return mentions.map((mention) => participants.find((p) => p.mail.startsWith(mention + '@')).id);
}

export function getAddCommentActionPayload(
    atMentions: string[],
    text: string,
    commentableId: string,
    commentableType: string,
): AddCommentActionPayload {
    const commentId = getRandomId(`comment_${commentableType}_${commentableId}_`);
    return {
        id: commentId,
        onObjectId: commentableId,
        onObjectType: commentableType,
        text,
        atMentions,
    };
}
