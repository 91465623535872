import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../../../common/common.module';
import { CustomFieldSharedModule } from '../../shared/custom-field/custom-field-shared.module';
import { RecordLookupDialogModule } from '../../table-definition/record-lookup-dialog/record-lookup-dialog.module';
import { DatepickerModule } from '../../ui-components/datepicker/datepicker.module';
import { SelectModule } from '../../ui-components/select/select.module';
import { CustomFieldValueComponent } from './custom-field-controller/custom-field-value.component';
import { CustomFieldDateComponent } from './custom-field-elements/custom-field-date.component';
import { CustomFieldEmailComponent } from './custom-field-elements/custom-field-email.component';
import { CustomFieldLinkComponent } from './custom-field-elements/custom-field-link.component';
import { CustomFieldNumberComponent } from './custom-field-elements/custom-field-number.component';
import { CustomFieldPhoneComponent } from './custom-field-elements/custom-field-phone.component';
import { CustomFieldSelectionListComponent } from './custom-field-elements/custom-field-selection-list.component';
import { CustomFieldTextLongComponent } from './custom-field-elements/custom-field-text-long.component';
import { CustomFieldTextComponent } from './custom-field-elements/custom-field-text.component';
import { CustomFieldRecordComponent } from './custom-field-elements/record/custom-field-record.component';

@NgModule({
    declarations: [
        CustomFieldValueComponent,
        CustomFieldEmailComponent,
        CustomFieldSelectionListComponent,
        CustomFieldTextComponent,
        CustomFieldTextLongComponent,
        CustomFieldPhoneComponent,
        CustomFieldLinkComponent,
        CustomFieldDateComponent,
        CustomFieldNumberComponent,
        CustomFieldRecordComponent,
    ],
    imports: [
        CommonModule,
        RuumCommonModule,
        CustomFieldSharedModule,
        SelectModule,
        DatepickerModule,
        RecordLookupDialogModule,
    ],
    exports: [CustomFieldValueComponent],
})
export class CustomFieldValueModule {}
