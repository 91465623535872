import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

const minHiddenItemHeight = 16; // Item considered visible if hidden not more than on 16px;
@Component({
    selector: 'ruum-scrollable-list',
    template: `
        <div
            class="border-light"
            [ngClass]="addScrollIndicatorClass"
            [class.border-top]="showTopScrollIndicator$ | async"
        ></div>
        <div
            class="d-flex
                    gradient
                    gradient-vertical-sm
                    gradient-white
                    gradient-from-bottom-to-top
                    mr-4"
        ></div>
        <div #scrollableList class="overflow-y block-with-gradients" [ngClass]="addContentClass">
            <ng-content></ng-content>
        </div>
        <div
            class="d-flex
                    gradient
                    gradient-vertical-sm
                    gradient-white
                    gradient-from-top-to-bottom
                    position-bottom
                    mr-4"
        ></div>
        <div
            class="border-light"
            [ngClass]="addScrollIndicatorClass"
            [class.border-top]="showBottomScrollIndicator$ | async"
        ></div>
    `,
    styles: [
        `
            .block-with-gradients {
                margin-top: -16px;
                margin-bottom: -16px;
            }

            .position-bottom {
                margin-top: auto;
            }

            .gradient {
                width: initial !important;
            }
        `,
    ],
})
export class ScrollableListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() addContentClass = '';
    @Input() addScrollIndicatorClass = '';

    @HostBinding('class') classList = 'd-flex flex-column align-items-stretch overflow-y mr-5';

    @ViewChild('scrollableList', { static: false }) private scrollableList: ElementRef;

    private subscriptions: Subscription[] = [];

    isListScrolled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private scrollTimeout: ReturnType<typeof setTimeout>;
    private listScrolls$: Observable<MouseEvent>;
    private _hasHiddenItemsTop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _hasHiddenItemsBottom$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    get hasHiddenItemsTop() {
        return this._hasHiddenItemsTop$.getValue();
    }
    set hasHiddenItemsTop(hasHiddenItems: boolean) {
        if (hasHiddenItems !== this.hasHiddenItemsTop) {
            this._hasHiddenItemsTop$.next(hasHiddenItems);
        }
    }

    get hasHiddenItemsBottom() {
        return this._hasHiddenItemsBottom$.getValue();
    }
    set hasHiddenItemsBottom(hasHiddenItems: boolean) {
        if (hasHiddenItems !== this.hasHiddenItemsBottom) {
            this._hasHiddenItemsBottom$.next(hasHiddenItems);
        }
    }

    showTopScrollIndicator$: Observable<boolean>;
    showBottomScrollIndicator$: Observable<boolean>;
    listScrolledObservable$: Observable<boolean>;

    ngOnInit() {
        this.initIsListScrolledObservable();
        this.initTopIndicatorObservable();
        this.initBottomIndicatorObservable();
    }

    ngAfterViewInit() {
        this.initListScrollsObservables();
        this.initListScrollsSubscriptions();
        this.initHiddenScrollItemsSubscriptions();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    initTopIndicatorObservable() {
        this.showTopScrollIndicator$ = combineLatest([this.listScrolledObservable$, this._hasHiddenItemsTop$]).pipe(
            map(([isListScrolled, hasHiddenItemsTop]) => {
                return isListScrolled || hasHiddenItemsTop;
            }),
        );
    }

    initBottomIndicatorObservable() {
        this.showBottomScrollIndicator$ = combineLatest([
            this.listScrolledObservable$,
            this._hasHiddenItemsBottom$,
        ]).pipe(
            map(([isListScrolled, hasHiddenItemsBottom]) => {
                return isListScrolled || hasHiddenItemsBottom;
            }),
        );
    }

    initListScrollsObservables() {
        this.listScrolls$ = fromEvent(this.scrollableList.nativeElement, 'scroll');
    }

    initListScrollsSubscriptions() {
        const listScrollsSubscription = this.listScrolls$.subscribe(() => {
            clearTimeout(this.scrollTimeout);
            this.updateIsListScrolled(true);

            this.scrollTimeout = setTimeout(() => {
                this.updateIsListScrolled(false);
            }, 200);
        });

        this.subscriptions.push(listScrollsSubscription);
    }

    updateIsListScrolled(isScrolled: boolean) {
        this.isListScrolled$.next(isScrolled);
    }

    initHiddenScrollItemsSubscriptions() {
        const hiddenScrollItemsSubscription = this.listScrolls$.subscribe(() => {
            const { scrollHeight, scrollTop, offsetHeight } = this.scrollableList.nativeElement;
            const hiddenHeight = scrollHeight - scrollTop - offsetHeight;

            if (scrollTop < minHiddenItemHeight) {
                this.hasHiddenItemsTop = false;
            } else {
                this.hasHiddenItemsTop = true;
            }

            if (hiddenHeight < minHiddenItemHeight) {
                this.hasHiddenItemsBottom = false;
            } else {
                this.hasHiddenItemsBottom = true;
            }
        });

        this.subscriptions.push(hiddenScrollItemsSubscription);
    }

    initIsListScrolledObservable() {
        // Fix fo Chrome: not allow 'scroll' events if scrolled to the end of list
        this.listScrolledObservable$ = this.isListScrolled$.pipe(
            filter((isScrolled) => this.hasHiddenItemsBottom || !isScrolled),
            distinctUntilChanged(),
        );
    }
}
