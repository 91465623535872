import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddNewItemDesktopButtonComponent } from './add-new-item-desktop-button.component';
import { AddNewItemMobileButtonComponent } from './add-new-item-mobile-button.component';
import { AddNewItemComponent } from './add-new-item.component';

@NgModule({
    imports: [CommonModule, LayoutModule],
    declarations: [AddNewItemComponent, AddNewItemDesktopButtonComponent, AddNewItemMobileButtonComponent],
    exports: [AddNewItemComponent, AddNewItemDesktopButtonComponent, AddNewItemMobileButtonComponent],
})
export class AddNewItemModule {}
