import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
    RuumDropdownItem,
    RuumDropdownItemSize,
    RuumDropdownList,
    UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE,
} from '../dropdown.model';

@Component({
    selector: 'ruum-dropdown-list',
    template: `
        <div class="d-flex flex-fill flex-column p-1 overflow-x" style="max-height: 412px; overflow-y: auto;">
            <ng-template ngFor let-item [ngForOf]="list">
                <ruum-dropdown-item
                    [id]="item.id"
                    [name]="item.name"
                    [description]="item.description"
                    [icon]="item.icon"
                    [iconSide]="item.iconSide"
                    [theme]="item.theme"
                    [tooltip]="item.tooltip"
                    [disabled]="item.disabled"
                    [hidden]="item.hidden"
                    [size]="size"
                    [selected]="isSelected(item.id)"
                    [isMultiSelect]="isMultiSelect"
                    [paywallPrivilege]="item.paywallPrivilege"
                    [rejectStrategy]="rejectStrategy(item)"
                    (selectChange)="onSelect($event)"
                >
                </ruum-dropdown-item>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumDropdownListComponent {
    @HostBinding('class') hostClassList = 'd-flex';
    @Input()
    selectedItems: RuumDropdownList = [];

    @Input()
    list: RuumDropdownList = [];

    @Input()
    size: RuumDropdownItemSize = 'sm';

    @Input() isMultiSelect = false;

    @Output()
    selectItem = new EventEmitter<RuumDropdownItem>();

    @Output()
    selectItems = new EventEmitter<RuumDropdownItem[]>();

    isSelected(itemId: string): boolean {
        return !!this.selectedItems.filter((selectedItem) => {
            return selectedItem.id === itemId;
        })[0];
    }

    onSelect(itemId: string): void {
        if (this.isMultiSelect) {
            this.onMultiSelect(itemId);
        } else {
            const item: RuumDropdownItem = this.list.find((i) => i.id === itemId);
            this.selectItem.emit(item);
        }
    }

    rejectStrategy(item): string {
        return item.paywallRejectStrategy ? item.paywallRejectStrategy : 'noop';
    }

    private onMultiSelect(itemId: string) {
        if (itemId === UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE.id) {
            this.selectItem.emit(UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE);
            return;
        }
        const isSelected = this.isSelected(itemId);
        if (isSelected) {
            const filterdSelectList = this.selectedItems.filter(
                (selectedItem) =>
                    selectedItem.id !== itemId && selectedItem.id !== UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE.id,
            );
            this.selectItems.emit(filterdSelectList);
        } else {
            const item: RuumDropdownItem = this.list.find((i) => i.id === itemId);
            const itemsWithoutUnset = this.selectedItems.filter(
                (selectedItem) => selectedItem.id !== UNSELECT_RUUM_DROPDOWN_ITEM_TEMPLATE.id,
            );
            this.selectItems.emit([...itemsWithoutUnset, item]);
        }
    }
}
