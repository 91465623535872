import { RuumAction, Template, TemplateAction, TemplateReducer } from '@ruum/ruum-reducers';

export const TEMPLATE_WEB_APP_ACTION_TYPES = {
    SELECT_TEMPLATE: 'SELECT_TEMPLATE',
    UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
};

export function SelectedTemplateReducer(currentState: Template, action: TemplateAction): Template {
    switch (action.type) {
        case TEMPLATE_WEB_APP_ACTION_TYPES.SELECT_TEMPLATE:
            return action.payload;
        case TEMPLATE_WEB_APP_ACTION_TYPES.UPDATE_TEMPLATE:
            return {
                ...currentState,
                ...action.payload,
            };
        default:
            return callTemplateReducer(currentState, { ...action });
    }
}

function callTemplateReducer(currentState: Template, action: RuumAction): Template {
    if (action.entityType === 'template') {
        return TemplateReducer(currentState, action);
    } else {
        return currentState;
    }
}
