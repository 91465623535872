import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { SystemConnectorConfigurationSelectComponent } from './system-connector-configuration-select.component';

@NgModule({
    declarations: [SystemConnectorConfigurationSelectComponent],
    imports: [CommonModule, SelectModule],
    exports: [SystemConnectorConfigurationSelectComponent],
})
export class SystemConnectorConfigurationSelectModule {}
