import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { RuumAlertService } from '../../../../common/components/alert/alert.service';
import { LobbyListType } from '../../../../common/connectors/lobbyList/lobby-list.model';
import { OrderedListParams, PaginatedList } from '../../../../common/connectors/paginatedList.model';
import { PaginatedListLoader } from '../../../../common/connectors/paginatedListLoader';
import { ReadModelBackendConnector } from '../../../../common/connectors/readModelConnector.service';

type TagsSortBy = 'name';
interface TagFilters {
    listType: LobbyListType;
    groupId?: string;
    workspaceId?: string;
}

const emptyPage = {
    pageSize: 25,
    currentPage: 1,
    totalItems: 0,
    rows: [],
};

@Injectable({ providedIn: 'root' })
export class FilterTagSelectService extends PaginatedListLoader<string, TagFilters, TagsSortBy> {
    readonly groupId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly orderBy$ = new BehaviorSubject<OrderedListParams<TagsSortBy>>(undefined);
    readonly listType$: BehaviorSubject<string> = new BehaviorSubject<LobbyListType>('projects_and_groups');
    private pageStore$ = new BehaviorSubject<PaginatedList<string[]>>(emptyPage);
    private rowsStore$ = new BehaviorSubject<string[]>([]);

    constructor(
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        private readModelBackendConnector: ReadModelBackendConnector,
    ) {
        super(alertService, authService);
        this.setUpObservables();
    }

    resetFilters() {
        this.pageStore$.next(emptyPage);
        this.rowsStore$.next([]);
        this.groupId$.next(undefined);
        this.workspaceId$.next(undefined);
        this.listType$.next('projects_and_groups');
    }

    protected getData(
        page: number,
        filters: TagFilters,
        orderBy: OrderedListParams<TagsSortBy>,
    ): Observable<PaginatedList<any>> {
        return this.readModelBackendConnector.getTags(filters.listType, filters.groupId, filters.workspaceId).pipe(
            map((tags) => {
                return {
                    pageSize: tags.length,
                    currentPage: 1,
                    totalItems: tags.length,
                    rows: [...tags],
                };
            }),
        );
    }

    protected getFilters$(): Observable<TagFilters> {
        return combineLatest([this.listType$, this.groupId$, this.workspaceId$]).pipe(
            map<any, TagFilters>(([listType, groupId, workspaceId]) => ({
                listType,
                groupId,
                workspaceId,
            })),
        );
    }

    protected getOrderBy$(): Observable<OrderedListParams<TagsSortBy>> {
        return this.orderBy$;
    }

    protected getStoreData$(): Observable<PaginatedList<any>> {
        return this.pageStore$.asObservable();
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.pageStore$.next(page);
            this.rowsStore$.next([...this.rowsStore$.getValue(), ...page.rows]);
        });
    }

    private getRows() {
        return this.rowsStore$.asObservable();
    }
}
