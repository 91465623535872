import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './../../shared/layouts/layouts.module';
import { UiComponentsModule } from './../../shared/ui-components/ui-components.module';
import { AdminExternalSystemsComponent } from './external-systems.component';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, CdkTableModule, UiComponentsModule, LayoutsModule],
    declarations: [AdminExternalSystemsComponent],
    exports: [AdminExternalSystemsComponent],
})
export class AdminExternalSystemsModule {}
