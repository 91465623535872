import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IllustrationApprovalsComponent } from './illustration-approvals.component';
import { IllustrationAttachmentsComponent } from './illustration-attachments.component';
import { IllustrationCommentsComponent } from './illustration-comments.component';
import { IllustrationCustomFieldsComponent } from './illustration-custom-fields.component';
import { IllustrationEmailsComponent } from './illustration-emails.component';
import { IllustrationFilterApprovalsComponent } from './illustration-filter-approvals.component';
import { IllustrationFilterRuumsComponent } from './illustration-filter-ruums.component';
import { IllustrationFilterTasksComponent } from './illustration-filter-tasks.component';
import { IllustrationLoginComponent } from './illustration-login.component';
import { IllustrationPaywallProComponent } from './illustration-paywall-pro.component';
import { IllustrationPaywallTrialOverComponent } from './illustration-paywall-trial-over.component';
import { IllustrationPaywallTrialStartedComponent } from './illustration-paywall-trial-started.component';
import { IllustrationProcessWelcomeComponent } from './illustration-process-welcome.component';
import { IllustrationProcessComponent } from './illustration-processes.component';
import { IllustrationRolesComponent } from './illustration-roles.component';
import { IllustrationRuumsComponent } from './illustration-ruums.component';
import { IllustrationSearchResultsComponent } from './illustration-search-results.component';
import { IllustrationSystemConnectorsComponent } from './illustration-system-connectors.component';
import { IllustrationTasksInTimelineComponent } from './illustration-tasks-in-timeline.component';
import { IllustrationTasksComponent } from './illustration-tasks.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        IllustrationTasksComponent,
        IllustrationTasksInTimelineComponent,
        IllustrationRuumsComponent,
        IllustrationProcessComponent,
        IllustrationApprovalsComponent,
        IllustrationCustomFieldsComponent,
        IllustrationRolesComponent,
        IllustrationSystemConnectorsComponent,
        IllustrationCommentsComponent,
        IllustrationAttachmentsComponent,
        IllustrationEmailsComponent,
        IllustrationPaywallTrialStartedComponent,
        IllustrationPaywallTrialOverComponent,
        IllustrationPaywallProComponent,
        IllustrationLoginComponent,
        IllustrationSearchResultsComponent,
        IllustrationProcessWelcomeComponent,
        IllustrationFilterApprovalsComponent,
        IllustrationFilterRuumsComponent,
        IllustrationFilterTasksComponent,
    ],
    exports: [
        IllustrationTasksComponent,
        IllustrationTasksInTimelineComponent,
        IllustrationRuumsComponent,
        IllustrationProcessComponent,
        IllustrationApprovalsComponent,
        IllustrationCustomFieldsComponent,
        IllustrationRolesComponent,
        IllustrationSystemConnectorsComponent,
        IllustrationCommentsComponent,
        IllustrationAttachmentsComponent,
        IllustrationEmailsComponent,
        IllustrationPaywallTrialStartedComponent,
        IllustrationPaywallTrialOverComponent,
        IllustrationPaywallProComponent,
        IllustrationLoginComponent,
        IllustrationSearchResultsComponent,
        IllustrationProcessWelcomeComponent,
        IllustrationFilterApprovalsComponent,
        IllustrationFilterRuumsComponent,
        IllustrationFilterTasksComponent,
    ],
})
export class IllustrationModule {}
