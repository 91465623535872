import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { intersectionWith, isEqual } from '../../../common/utils.service';
import { Column, ColumnValue, TableDirectiveService } from './table.directive.service';

@Directive({
    selector: '[ruumRowCellWidth]',
})
export class RowCellWidthDirective implements OnDestroy {
    @Input()
    get columns(): Column[] {
        return this._columns$.getValue();
    }
    set columns(value: Column[]) {
        if (!isEqual(value, this.columns)) {
            this._columns$.next(value);
        }
    }
    @Input()
    get displayedColumnsIds(): string[] {
        return this._displayedColumnsIds$.getValue();
    }
    set displayedColumnsIds(value: string[]) {
        if (!isEqual(value, this.displayedColumnsIds)) {
            this._displayedColumnsIds$.next(value);
        }
    }
    @Output() columnsResized = new EventEmitter<ColumnValue[]>();

    private _columns$: BehaviorSubject<Column[]> = new BehaviorSubject<Column[]>([]);
    private _displayedColumnsIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private subscriptions: Subscription[] = [];

    constructor(private element: ElementRef, private tableDirectiveService: TableDirectiveService) {
        this.initWidth();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    initWidth(): void {
        const columnsSubscription = combineLatest([
            this._columns$.pipe(distinctUntilChanged()),
            this._displayedColumnsIds$.pipe(distinctUntilChanged()),
        ])
            .pipe(
                filter(([columns, displayedIds]) => columns.length > 0 && displayedIds.length > 0),
                map(this.filterOnlyDisplayedColumns),
            )
            .subscribe((columns) => {
                if (this.tableDirectiveService.columnsWidthIsLessThanMinTableWidth(columns)) {
                    const newColumns = this.recalculateColumnsWidths(columns);

                    this.initCellsWidths(newColumns);
                    this.saveResizedColumns(newColumns);
                } else {
                    this.initCellsWidths(columns);
                }
            });

        this.subscriptions.push(columnsSubscription);
    }

    filterOnlyDisplayedColumns = ([columns, displayedIds]): Column[] => {
        return intersectionWith(columns, displayedIds, (column, id) => column.fieldId === id);
    };

    recalculateColumnsWidths(columns: Column[]): Column[] {
        const defaultColumnWidth = this.calculateDefaultColumnWidth(columns);

        return columns.map((column) => {
            if (!column.unresizable) {
                return {
                    ...column,
                    width: defaultColumnWidth < column.minWidth ? column.minWidth : defaultColumnWidth,
                };
            }

            return column;
        });
    }

    calculateDefaultColumnWidth(columns: Column[]): number {
        const tableWidth = this.getTableWidth();
        const sumWidthUnresizable = this.calculateUnresizableColumnsWidthsTotal(columns);
        const countResizableColumns = this.calculateCountResizableColumns(columns);

        return (tableWidth - sumWidthUnresizable) / countResizableColumns;
    }

    getTableWidth() {
        const table = document.getElementsByTagName('cdk-table');

        return Math.ceil(table[0].getBoundingClientRect().width);
    }

    calculateUnresizableColumnsWidthsTotal(columns: Column[]): number {
        return columns.reduce((sumWidths, column) => (column.unresizable ? sumWidths + column.width : sumWidths), 0);
    }

    calculateCountResizableColumns(columns: Column[]): number {
        return columns.reduce((resultCount, column) => (column.unresizable ? resultCount : resultCount + 1), 0);
    }

    initCellsWidths(columns: Column[]): void {
        const cells = this.element.nativeElement.childNodes;

        if (cells.length > 0) {
            columns.forEach((column) => {
                this.setColumnDOMWidth(column);
            });
        }
    }

    setColumnDOMWidth(column: Column): void {
        const cells = this.element.nativeElement.childNodes;
        let columnCellElement: HTMLDivElement;

        cells.forEach((cell: HTMLDivElement) => {
            if (cell.className && cell.className.indexOf(`cdk-column-${column.fieldId}`) > -1) {
                columnCellElement = cell;
            }
        });

        if (columnCellElement) {
            columnCellElement.style.width = `${column.width}px`;
        }
    }

    saveResizedColumns(columns: Column[]) {
        const newColumns = columns.map((column) => ({
            fieldId: column.fieldId,
            width: column.width,
        }));

        this.columnsResized.emit(newColumns);
    }
}
