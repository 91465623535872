import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const BOOTSTRAP_BREAKPOINTS = {
    XSmall: '(max-width: 575.99px)',
    Small: '(min-width: 576px) and (max-width: 767.99px)',
    Medium: '(min-width: 768px) and (max-width: 991.99px)',
    Large: '(min-width: 992px) and (max-width: 1279.99px)',
    XLarge: '(min-width: 1280px) and (max-width: 2559.99px)',
    XXLarge: '(min-width: 2560px)',
};

@Injectable({ providedIn: 'root' })
export class BreakpointService {
    constructor(private breakpointObserver: BreakpointObserver) {}

    isLargeBreakpoint(): Observable<boolean> {
        return this.breakpointObserver
            .observe([BOOTSTRAP_BREAKPOINTS.Large, BOOTSTRAP_BREAKPOINTS.XLarge, BOOTSTRAP_BREAKPOINTS.XXLarge])
            .pipe(map((result) => result.matches));
    }

    isXXLargeBreakpoint(): Observable<boolean> {
        return this.breakpointObserver.observe([BOOTSTRAP_BREAKPOINTS.XXLarge]).pipe(map((result) => result.matches));
    }
}
