import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableDefinitionDialogModule } from '../../shared/table-definition/table-definition-dialog/table-definition-dialog.module';
import { RuumCommonModule } from './../../common/common.module';
import { SearchModule } from './../../shared/ui-components/search/search.module';
import { AdminTableDefinitionsComponent } from './table-definitions.component';

@NgModule({
    imports: [CommonModule, RuumCommonModule, SearchModule, TableDefinitionDialogModule],
    declarations: [AdminTableDefinitionsComponent],
    exports: [AdminTableDefinitionsComponent],
})
export class AdminTableDefinitionsModule {}
