import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import {
    RuumDropdownItem,
    RuumDropdownItemSize,
    RuumDropdownPlacement,
    RuumDropdownSectionList,
} from '../../shared/ui-components/dropdown/dropdown.model';
@Component({
    selector: 'ruum-team-member-add-to',
    template: `
        <ruum-dropdown-wrapper>
            <!-- desktop -->
            <ruum-dropdown-desktop #dropdownDesktop [placement]="placement">
                <!-- button -->
                <button
                    class="btn btn-sm btn-link-secondary px-0"
                    type="button"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                    [disabled]="isReadOnly"
                >
                    <span>{{ selectedItem?.name }}</span>
                    <i class="icon icon-cevron-down"></i>
                </button>

                <!-- menu -->
                <div class="d-flex flex-column" data-content="ruum-dropdown-menu" data-test="ruum-dropdown-menu">
                    <div class="text-small font-weight-bold text-uppercase px-3 pt-3 pb-2 text-secondary">
                        {{ title }}
                    </div>
                    <ruum-dropdown-section-list
                        style="width: 276px;"
                        [sectionList]="sectionList"
                        (selectItem)="onSelect($event, dropdownDesktop)"
                    >
                    </ruum-dropdown-section-list>
                </div>
            </ruum-dropdown-desktop>

            <!-- mobile -->
            <ruum-dropdown-mobile #dropdownMobile [isReadOnly]="isReadOnly">
                <!-- button -->
                <button
                    class="btn btn-sm btn-link-secondary px-0"
                    type="button"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                    [disabled]="isReadOnly"
                >
                    <span>{{ selectedItem?.name }}</span>
                    <i class="icon icon-cevron-down"></i>
                </button>
                <!-- menu -->

                <div class="d-flex flex-column" data-content="ruum-dropdown-menu" data-test="ruum-dropdown-menu">
                    <div class="text-small font-weight-bold text-uppercase px-3 pt-3 pb-2 text-secondary">
                        {{ title }}
                    </div>
                    <ruum-dropdown-section-list
                        data-content="ruum-dropdown-menu"
                        data-test="ruum-dropdown-menu"
                        [sectionList]="sectionList"
                        [size]="'lg'"
                        (selectItem)="onSelect($event, dropdownMobile)"
                    >
                    </ruum-dropdown-section-list>
                </div>
            </ruum-dropdown-mobile>
        </ruum-dropdown-wrapper>
    `,
})
export class TeamMemberAddToComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input()
    title: string;

    @Input()
    selectedItem: RuumDropdownItem;

    @Input()
    sectionList: RuumDropdownSectionList;

    @Input()
    size: RuumDropdownItemSize = 'xs';

    @Input()
    isReadOnly = false;

    @Input()
    placement: RuumDropdownPlacement = ['bottom-right', 'top-right', 'auto'];

    @Output()
    memberChanged = new EventEmitter<RuumDropdownItem>();

    ngOnInit() {}

    onSelect(item: RuumDropdownItem, dropdown) {
        dropdown.close();
        this.memberChanged.emit(item);
    }
}
