import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import { FunctionalRolesService } from '../../../common/connectors/functionalRoles/functionalRoles.service';
import { TaskListItem } from '../../../common/connectors/tasks/task-list.model';

@Component({
    selector: 'ruum-task-list-cell-assigned-functional-roles',
    template: `
        <div class="d-flex">
            <ruum-task-assignees-button
                [assignedRoles]="assignedFunctionalRoles$ | async"
                [maxShownAssignees]="1"
                [showFullNameOfFirstAssignee]="false"
            >
            </ruum-task-assignees-button>
        </div>
    `,
})
export class TaskListCellFunctionalRolesComponent implements OnDestroy {
    @HostBinding() class = 'd-flex w-100';

    @Input()
    set data(value: TaskListItem) {
        this.task = value;
        this.functionalRoleService.dataList(value.assignedFunctionalRoles).subscribe((functionalRoles) => {
            this.assignedFunctionalRoles$.next(functionalRoles);
        });
    }
    get data(): TaskListItem {
        return this.task;
    }
    @Input() maxShownAssignees = 2;
    @Input() showFullNameOfFirstAssignee = true;
    subscriptions: Subscription[] = [];

    task: any = {} as TaskListItem;
    assignedFunctionalRoles$: BehaviorSubject<FunctionalRoleListItem[]> = new BehaviorSubject<FunctionalRoleListItem[]>(
        [],
    );

    constructor(private functionalRoleService: FunctionalRolesService) {}

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getRoles(roleIds: string[]): Observable<FunctionalRoleListItem[]> {
        return this.functionalRoleService.dataList(roleIds);
    }
}
