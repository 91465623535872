import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentSize } from '../ui-components.type';

@Component({
    selector: 'ruum-profile-member-group',
    template: `
        <ruum-profile-member
            *ngFor="let member of members | slice: 0:maxShownProfiles"
            [name]="member.fullName"
            [theme]="member.color | ruumProfileHexToTheme"
            [size]="size"
            [disabled]="disabled"
            [disableTooltip]="disableTooltip"
        ></ruum-profile-member>
        <ruum-profile-others-counter
            *ngIf="members.length > maxShownProfiles"
            [quantity]="members.length - maxShownProfiles"
            [nameList]="getOtherMembers(members, maxShownProfiles)"
            [size]="size"
            [disabled]="disabled"
        ></ruum-profile-others-counter>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMemberGroupComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return ['d-flex', 'align-items-start', this.componentClass].filter((el) => !!el).join(' ');
    }
    @Input() members = []; // UserListItem[]
    @Input() maxShownProfiles = 1;
    @Input() size: ComponentSize;
    @Input() disabled: boolean;
    @Input() disableTooltip: boolean;
    @Input() componentClass: string;

    constructor() {}

    ngOnInit() {}

    getOtherMembers(members: any, maxShownProfiles: number): string[] {
        return members.slice(maxShownProfiles, members.length).map((el) => el.fullName);
    }
}
