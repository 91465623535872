import { RuumEnvironment } from '.';

export const environment: RuumEnvironment = {
    production: true,
    BOX_APP_ID: 'ysplq9ve4rw9hoj4gon8dfdhgobrcums',
    AUTH_SERVICE_URL: 'https://auth.dev-ruumapp.com/api',
    APP_URL: 'https://open.dev-ruumapp.com',
    MAIL: 'hello@dev-ruumapp.com',
    WEBSOCKET_URL: 'https://websockets.dev-ruumapp.com',
    EMAIL_SERVICE_URL: 'https://email.dev-ruumapp.com',
    READ_MODEL_URL: 'https://read-model.dev-ruumapp.com',
    PROJECT_SERVICE_URL: 'https://project.dev-ruumapp.com',
    TRACKING_URL: 'https://tracking.dev-ruumapp.com',
    MS_APP_ID: '3d87b9eb-e312-43e2-b6ff-062d589575a7', // TODO: this is still from staging
    MS_APP_REDIRECT_URI: 'https://open.dev-ruumapp.com/auth/office365',
    GDRIVE_APP_ID: '567029703749',
    GDRIVE_API_KEY: 'AIzaSyBKRcVAfcuUsFgu-gmpNs5cJbeh1E7eHAg',
    GDRIVE_CLIENT_ID: '567029703749-3cip64jdggiftl67fuvfndv3n60nij5g.apps.googleusercontent.com',
    GRECAPTCHA_SITE_KEY: '6LdCldgUAAAAAEy-rsZYZ8Nyw10MP8gGkBv8CeHi',
    GIT_COMMIT: process.env.GIT_COMMIT,
    // BEGIN Paywall related settings
    PAYWALL_UPGRADE_URL: 'https://help.sap.com/viewer/product/RUUM/Latest/en-US',
    PAYWALL_TRIAL_STATUSBAR_ENABLED: false,
};
