import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomFieldsService } from '../../customFields/customFields.service';
import { ReadModelBackendConnector } from '../../readModelConnector.service';
import { SelectedWorkspaceService } from '../../workspace/selected-workspace.service';
import { WorkspaceListService } from '../../workspace/workspace-list.service';
import { SavedViewFieldDefinition } from '../saved-views.model';
import { SavedViewCustomFieldsService } from './saved-view-custom-fields.service';
import { SavedViewStandardFieldsService } from './saved-view-standard-fields.service';

export type StandardFieldId =
    | 'standard_field_lobby_list_status'
    | 'standard_field_favorite'
    | 'standard_field_lobby_list_name'
    | 'standard_field_changed_at'
    | 'standard_field_created_by'
    | 'standard_field_tags'
    | 'standard_field_workspace'
    | 'standard_field_group'
    | 'standard_field_project'
    | 'standard_field_section'
    | 'standard_field_task_list_status'
    | 'standard_field_task_list_name'
    | 'standard_field_task_list_subtasks'
    | 'standard_field_priority'
    | 'standard_field_startdate'
    | 'standard_field_duedate'
    | 'standard_field_assignees'
    | 'standard_field_roles'
    | 'standarf_field_groupsAndRuums'
    | 'standard_field_milestone'
    | 'standard_field_approval_list_status'
    | 'standard_field_approval_list_approver'
    | 'standard_field_approval_list_responsibles'
    | 'standard_field_approval_list_approver_role'
    | 'standard_field_approval_list_actions'
    | 'standard_field_requested_on'
    | 'standard_field_requested_by';

interface FieldsParams {
    excludedIds: string[];
    relativeTo: 'filters' | 'columns';
}

@Injectable({ providedIn: 'root' })
export class SavedViewFieldsService {
    constructor(
        private readModelBackendConnector: ReadModelBackendConnector,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private customFieldsService: CustomFieldsService,
        private savedViewStandardFieldsService: SavedViewStandardFieldsService,
        private savedViewCustomFieldsService: SavedViewCustomFieldsService,
        private workspaceListService: WorkspaceListService,
    ) {}

    startLoad(): void {
        this.savedViewStandardFieldsService.startLoad();
        this.savedViewCustomFieldsService.startLoad();
    }

    stopLoad(): void {
        this.savedViewStandardFieldsService.stopLoad();
        this.savedViewCustomFieldsService.stopLoad();
    }

    search(search: string): void {
        this.savedViewStandardFieldsService.search(search);
        this.savedViewCustomFieldsService.search(search);
    }

    loadMore(): void {
        this.savedViewStandardFieldsService.loadMore();
        this.savedViewCustomFieldsService.loadMore();
    }

    getFields$({ excludedIds, relativeTo }: FieldsParams): Observable<SavedViewFieldDefinition[]> {
        const _excludedIds = excludedIds || [];
        const _relativeTo = relativeTo || 'filters';

        return combineLatest([
            this.savedViewStandardFieldsService.getData(_relativeTo),
            this.savedViewCustomFieldsService.getData(),
            this.workspaceListService.workspaceList(),
        ]).pipe(
            map(([standardFields, customFields, workspaceList]) => {
                const excludedWorkspaceFieldIds =
                    workspaceList.length === 0 ? ['standard_field_workspace', 'standard_field_roles'] : [];

                return [...standardFields, ...customFields].filter((field) => {
                    return !!field && [..._excludedIds, ...excludedWorkspaceFieldIds].indexOf(field.fieldId) === -1;
                });
            }),
        );
    }

    hasMore(): Observable<boolean> {
        return combineLatest([
            this.savedViewStandardFieldsService.showLoading(),
            this.savedViewCustomFieldsService.showLoading(),
            this.savedViewStandardFieldsService.hasMore(),
            this.savedViewCustomFieldsService.hasMore(),
        ]).pipe(
            map(([showLoadingStandardFields, showLoadingCustomFields, hasMoreStandardFields, hasMoreCustomFields]) => {
                const showLoading = showLoadingStandardFields || showLoadingCustomFields;
                const hasMore = hasMoreStandardFields || hasMoreCustomFields;
                return showLoading || hasMore;
            }),
        );
    }

    getFieldById(id: string): Observable<SavedViewFieldDefinition> {
        const standardFieldIds = this.filterFieldIdsByPrefix('standard_field_', [id]);
        const customFieldIds = this.filterFieldIdsByPrefix('custom_field_', [id]);

        return combineLatest([
            this.getStandardFieldsByIds(standardFieldIds),
            this.getCustomFieldsByIds(customFieldIds),
        ]).pipe(
            map(([standardFields, customFields]) => {
                return [...standardFields, ...customFields]
                    .filter((field) => !!field)
                    .find((field) => field.fieldId === id);
            }),
        );
    }

    // ============== //

    getFields(fieldIds: string[]): Observable<SavedViewFieldDefinition[]> {
        const standardFieldIds = this.filterFieldIdsByPrefix('standard_field_', fieldIds);
        const customFieldIds = this.filterFieldIdsByPrefix('custom_field_', fieldIds);

        return combineLatest([
            this.getStandardFieldsByIds(standardFieldIds),
            this.getCustomFieldsByIds(customFieldIds),
        ]).pipe(
            map(([standardFields, customFields]) => {
                return [...standardFields, ...customFields].filter((field) => !!field);
            }),
        );
    }

    getFieldsMap(fieldIds: string[]): Observable<{ [key: string]: SavedViewFieldDefinition }> {
        const standardFieldIds = this.filterFieldIdsByPrefix('standard_field_', fieldIds);
        const customFieldIds = this.filterFieldIdsByPrefix('custom_field_', fieldIds);

        return combineLatest([
            this.getStandardFieldsByIds(standardFieldIds),
            this.getCustomFieldsByIds(customFieldIds),
        ]).pipe(
            map(([standardFields, customFields]) => {
                return [...standardFields, ...customFields]
                    .filter((field) => !!field)
                    .reduce((state, field) => {
                        return {
                            ...state,
                            [field.fieldId]: field,
                        };
                    }, {});
            }),
        );
    }

    getCustomFields() {
        return this.readModelBackendConnector
            .getCustomFields(
                { page: 1, pageSize: 500 },
                { workspaceId: this.selectedWorkspaceService.getSelectedWorkspaceId() },
                { by: 'title' },
            )
            .pipe(
                map((page) => {
                    return page.rows.map((field) => {
                        return {
                            fieldId: field.id,
                            title: field.title,
                            typeId: field.type,
                        };
                    });
                }),
            );
    }

    getStandardFieldValueByFieldIds(fieldIds: string[]): SavedViewFieldDefinition[] {
        const entities = this.entities();

        return fieldIds.map((fieldId) => entities[fieldId]);
    }

    private getStandardFieldsByIds(fieldIds: string[]): Observable<SavedViewFieldDefinition[]> {
        const entities = this.entities();
        const list = fieldIds.map((fieldId) => entities[fieldId]);
        return of(list);
    }

    private getCustomFieldsByIds(fieldIds: string[]): Observable<SavedViewFieldDefinition[]> {
        if (fieldIds.length === 0) {
            return of([]);
        } else {
            return this.customFieldsService.dataList(fieldIds).pipe(
                filter((fields) => fields.length !== 0),
                map((fields) => {
                    return fields.map((field: any) => {
                        return {
                            fieldId: field.id,
                            typeId: field.type,
                            title: field.title,
                            description: field.description,
                            options: field.options,
                        };
                    });
                }),
            );
        }
    }

    private filterFieldIdsByPrefix(prefix: string, fieldIds: string[]): string[] {
        return fieldIds.filter((fieldId) => !!fieldId.match(prefix));
    }

    private entities(): { [key: string]: SavedViewFieldDefinition } {
        return {
            standard_field_lobby_list_status: {
                fieldId: 'standard_field_lobby_list_status',
                title: 'Status',
                typeId: 'lobbyStatus',
            },
            standard_field_favorite: {
                fieldId: 'standard_field_favorite',
                title: 'Favorite',
                typeId: 'favorite',
            },
            standard_field_comments: {
                fieldId: 'standard_field_comments',
                title: 'Comments',
                typeId: 'comments', // TODO: fix it
            },
            standard_field_lobby_list_name: {
                fieldId: 'standard_field_lobby_list_name',
                title: 'Name',
                typeId: 'lobbyName',
            },
            standard_field_changed_at: {
                fieldId: 'standard_field_changed_at',
                title: 'Last Changed',
                typeId: 'changedAt', // TODO: fix it
            },
            standard_field_created_by: {
                fieldId: 'standard_field_created_by',
                title: 'Creator',
                typeId: 'createdBy', // TODO: fix it
            },
            standard_field_tags: {
                fieldId: 'standard_field_tags',
                title: 'Labels',
                typeId: 'tags', // TODO: fix it
            },
            standard_field_section: {
                fieldId: 'standard_field_section',
                title: 'Section',
                typeId: 'section', // TODO: fix it
            },
            standard_field_project: {
                fieldId: 'standard_field_project',
                title: 'Ruum',
                typeId: 'project', // TODO: fix it
            },
            standard_field_group: {
                fieldId: 'standard_field_group',
                title: 'Group',
                typeId: 'group', // TODO: fix it
            },
            standard_field_workspace: {
                fieldId: 'standard_field_workspace',
                title: 'Workspace',
                typeId: 'workspace', // TODO: fix it
            },
            standard_field_task_list_status: {
                fieldId: 'standard_field_task_list_status',
                title: 'Task Status',
                typeId: 'taskStatus', // TODO: fix it
            },
            standard_field_task_list_name: {
                fieldId: 'standard_field_task_list_name',
                title: 'Name',
                typeId: 'taskName', // TODO: fix it
            },
            standard_field_task_list_subtasks: {
                fieldId: 'standard_field_task_list_subtasks',
                title: 'Subtasks',
                typeId: 'subtasks', // TODO: fix it
            },
            standard_field_priority: {
                fieldId: 'standard_field_priority',
                title: 'Priority',
                typeId: 'priority', // TODO: fix it
            },
            standard_field_startdate: {
                fieldId: 'standard_field_startdate',
                title: 'Start Date',
                typeId: 'startDate', // TODO: fix it
            },
            standard_field_duedate: {
                fieldId: 'standard_field_duedate',
                title: 'Due Date',
                typeId: 'dueDate', // TODO: fix it
            },
            standard_field_assignees: {
                fieldId: 'standard_field_assignees',
                title: 'Assignees',
                typeId: 'assignees', // TODO: fix it
            },
            standard_field_roles: {
                fieldId: 'standard_field_roles',
                title: 'Roles',
                typeId: 'roles', // TODO: fix it
            },
            standard_field_groupsAndRuums: {
                fieldId: 'standard_field_groupsAndRuums',
                title: 'Groups & Ruums',
                typeId: 'groupsAndRuums', // TODO: fix it
            },
            standard_field_milestone: {
                fieldId: 'standard_field_milestone',
                title: 'Milestone Status',
                typeId: 'milestone', // TODO: fix it
            },
            // TODO: check if we need menu
            standard_field_approval_list_status: {
                fieldId: 'standard_field_approval_list_status',
                title: 'Status',
                typeId: 'approvalStatus',
            },
            standard_field_approval_list_approver: {
                fieldId: 'standard_field_approval_list_approver',
                title: 'Approver',
                typeId: 'approver',
            },
            standard_field_approval_list_responsibles: {
                fieldId: 'standard_field_approval_list_responsibles',
                title: 'Responsible',
                typeId: 'responsibles',
            },
            standard_field_approval_list_approver_role: {
                fieldId: 'standard_field_approval_list_approver_role',
                title: 'Approver Role',
                typeId: 'approverRole',
            },
            standard_field_approval_list_actions: {
                fieldId: 'standard_field_approval_list_actions',
                title: 'Actions',
                typeId: 'approvalActions',
            },
            standard_field_requested_on: {
                fieldId: 'standard_field_requested_on',
                title: 'Requested On',
                typeId: 'createdOn',
            },
            standard_field_requested_by: {
                fieldId: 'standard_field_requested_by',
                title: 'Requestor',
                typeId: 'requestedBy',
            },
        };
    }
}
