import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskPriority, TaskStatus } from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TaskListComponent } from '../../shared/task-list/task-list.component';
import { CommonService } from '../common.service';
import { OrderedListParams, SortDirection } from '../connectors/paginatedList.model';
import { StandardFieldId } from '../connectors/savedView/fields/saved-view-fields.service';
import {
    SavedView,
    SavedViewColumn,
    SavedViewColumnValue,
    SavedViewFilterValue,
} from '../connectors/savedView/saved-views.model';
import { SelectedSavedViewService } from '../connectors/savedView/selected-saved-view.service';
import { TaskListItem, TaskListOrderBy } from '../connectors/tasks/task-list.model';
import { TaskListService } from '../connectors/tasks/task-list.service';
import { NavbarService, TaskDetail } from '../navbar/navbar.service';
import { BOOTSTRAP_BREAKPOINTS } from '../ruum-bootstrap/breakpoints';
import { TrackingConnector } from '../trackingConnector.service';
import { AddTaskDialogComponent } from './add-task/add-task-dialog.component';

interface IndentedTask extends TaskListItem {
    indentation?: number;
}

export interface ExtendedTask extends IndentedTask {
    taskId: string;
}

@Component({
    selector: 'ruum-task-list-container',
    template: `
        <div class="d-flex flex-column flex-fill w-100 minw-0">
            <ruum-max-content>
                <div class="d-flex flex-column w-100 minw-0 h-100 mh-100">
                    <ruum-view-controls>
                        <ruum-task-list-filters
                            [groupId]="groupId"
                            [projectId]="projectId"
                            [workspaceId]="workspaceId"
                            [isReadOnly]="isReadOnly"
                            [filterRules]="filters$ | async"
                            [totalItems]="totalItems$ | async"
                            [hiddenColumns]="hiddenColumns"
                            (searchChanged)="searchChanged($event)"
                            (addTask)="addTask()"
                        >
                        </ruum-task-list-filters>
                    </ruum-view-controls>

                    <ng-container *ngIf="tableTasks$ | async as tasks">
                        <div
                            *ngIf="tasks.length !== 0 && (isDesktop$ | async) === true"
                            class="d-flex flex-fill flex-row minw-0 overflow-x"
                        >
                            <div
                                class="d-flex
                                    flex-fill
                                    gradient-fixed-to-side-panel
                                    position-fixed
                                    gradient
                                    gradient-horizontal-md
                                    gradient-gray
                                    gradient-from-right-to-left
                                    left-0"
                                [class.with-side-panel-open]="isSidePanelOpen"
                            ></div>
                            <ruum-task-list
                                [columns]="columns$ | async"
                                [rows]="tasks"
                                (columnWidthChanged)="handleWidthChanged($event)"
                                [orderedBy]="orderBy$ | async"
                                (orderChanged)="handleOrderChange($event)"
                                [isReadOnly]="isReadOnly"
                                [subtasksVisibilityByTask]="subtasksVisibilityByTask"
                                (changeStatus)="handleChangeStatus($event)"
                                (showOnCanvas)="handleShowOnCanvas($event)"
                                (toggleSubtasksVisibility)="handleToggleSubtasksVisibility($event)"
                                (changePriority)="handleChangePriority($event)"
                                (commentClicked)="handleCommentsClick($event)"
                                (taskDeleted)="handleDeleteTask($event)"
                                (itemClicked)="itemClick($event)"
                                [activeId]="(activeTask$ | async)?.projectId + (activeTask$ | async)?.taskId"
                                [showLoading]="showLoading$ | async"
                                (loadMore)="loadMore()"
                                #taskListRef
                            ></ruum-task-list>
                            <div
                                class="
                                    flex-fill
                                    position-fixed
                                    gradient
                                    gradient-horizontal-md
                                    gradient-gray
                                    gradient-from-left-to-right
                                    gradient-fixed-to-side-panel"
                                [class.right-0]="!hasScrollbar()"
                                [class.right-3]="hasScrollbar()"
                                [class.d-flex]="!isTaskDetailsOpen"
                                [class.d-none]="isTaskDetailsOpen"
                                [class.with-sidebar-open]="isTaskDetailsOpen"
                            ></div>
                        </div>

                        <ruum-task-list-mobile-view
                            *ngIf="(isDesktop$ | async) !== true"
                            [isReadOnly]="isReadOnly"
                            [tasks]="tasks"
                            [showLoading]="showLoading$ | async"
                            (loadMore)="loadMore()"
                            (changeStatus)="handleChangeStatus($event)"
                            (itemClick)="itemClick($event)"
                        >
                        </ruum-task-list-mobile-view>

                        <ruum-empty-state
                            *ngIf="tasks.length === 0"
                            [searchApplied]="searchApplied"
                            [items]="tasks"
                            [filterApplied]="(filters$ | async)?.length > 0"
                            entityName="Tasks"
                        >
                            <ruum-illustration-filter-tasks
                                data-content="no-filter-results"
                                [width]="140"
                                [componentClass]="'mb-6'"
                            ></ruum-illustration-filter-tasks>

                            <ruum-illustration-tasks
                                data-content="no-results"
                                [width]="275"
                                [componentClass]="'mb-6'"
                            ></ruum-illustration-tasks>
                            <div data-content="no-results-content" class="text-center text-secondary mb-3">
                                Click "New Tasks" to create one.
                            </div>
                        </ruum-empty-state>
                    </ng-container>
                </div>
            </ruum-max-content>
        </div>
    `,
    styles: [
        `
            .first-page-loader {
                left: 0;
                top: 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column flex-fill align-items-center minw-0 w-100';

    /** If the list is being shown inside a Group. */
    @Input() groupId: string;
    /** If the list is being shown inside a Ruum. */
    @Input() projectId: string;
    @Input() isReadOnly = false;
    @Input() stickyColumnId: StandardFieldId = null;
    @Input() hiddenColumns: StandardFieldId[] = [];
    @Input() isSidePanelOpen = false;
    @Input() isTaskDetailsOpen = false;
    @Input() workspaceId: string;
    @Output() itemClicked = new EventEmitter<{ projectId: string; id: string }>();
    @Output() commentsClicked = new EventEmitter<{ projectId: string; id: string }>();

    @Output() taskAdded = new EventEmitter<void>();
    @Output() deleteTask = new EventEmitter<{
        projectId: string;
        taskId: string;
        parentId: string;
        isMilestone: boolean;
    }>();
    @Output() showOnCanvas = new EventEmitter<{ projectId: string; taskId: string }>();
    @Output() changeStatus = new EventEmitter<{
        projectId: string;
        taskId: string;
        parentId: string;
        status: TaskStatus;
    }>();
    @Output() changePriority = new EventEmitter<{
        projectId: string;
        taskId: string;
        parentId: string;
        priority: TaskPriority;
    }>();

    tasks$: Observable<TaskListItem[]>;
    /* tableTasks$ is needed for manual control over the subtasks in the tasks list */
    tableTasks$ = new BehaviorSubject<ExtendedTask[]>([]);
    activeTask$: Observable<TaskDetail>;
    showLoading$: Observable<boolean>;
    orderBy$: Observable<OrderedListParams<TaskListOrderBy>>;
    order: OrderedListParams<TaskListOrderBy> = {};
    subtasksVisibility$ = {};
    subtasksVisibilityByTask = {};
    subtasks$ = new BehaviorSubject<object>({});
    isDesktop$: Observable<boolean>;
    columns$: Observable<SavedViewColumn[]>;
    filters$: Observable<SavedViewFilterValue[]>;
    totalItems$: Observable<number>;
    loading$: Observable<boolean>;
    searchValueSubject = new BehaviorSubject('');
    subscriptions: Subscription[] = [];

    tasksTableElement: HTMLElement;

    @ViewChild('taskListRef', { static: false }) taskListElement: TaskListComponent;

    private selectedSavedView: SavedView;

    constructor(
        private taskListService: TaskListService,
        private modalService: NgbModal,
        private commonService: CommonService,
        private trackingConnector: TrackingConnector,
        private navbarService: NavbarService,
        private breakpointObserver: BreakpointObserver,
        private selectedSavedViewService: SelectedSavedViewService,
    ) {}

    ngOnInit() {
        this.filters$ = this.selectedSavedViewService
            .getSelectedSavedView()
            .pipe(map((savedView) => savedView.filters));

        this.columns$ = this.selectedSavedViewService
            .getSelectedSavedView()
            .pipe(map((savedView) => this.initSavedViewColumns(savedView)));
        this.tasks$ = this.taskListService.taskList();

        this.showLoading$ = this.taskListService.showLoading();
        this.taskListService.loadList();
        // the task detail outside the project ist setting the project, the reason why debounce is needed
        this.activeTask$ = this.navbarService.taskDetailId$.pipe(debounceTime(100));
        this.orderBy$ = this.taskListService.orderBy();

        this.loading$ = this.taskListService.isBusy$();
        this.totalItems$ = this.getTotalItems();

        this.initSubtasksSubscriptions();
        this.initWindowSizeObserver();
    }

    ngAfterViewInit() {
        this.tasksTableElement = this.taskListElement && this.taskListElement.scrollElement;
    }

    ngOnDestroy(): void {
        this.taskListService.stopLoadingList();

        for (const taskId of Object.keys(this.subtasksVisibility$)) {
            this.subtasksVisibility$[taskId].unsubscribe();
        }
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    get searchApplied(): boolean {
        return !!this.searchValueSubject.getValue();
    }

    searchChanged(value: string) {
        this.searchValueSubject.next(value);
        this.taskListService.filterByDescription(value);
        if (value) {
            this.taskListService.showSubtask(true);
        } else {
            this.taskListService.showSubtask(false);
        }
    }

    initSubtasksSubscriptions(): void {
        const subtasksSubscription = this.subtasks$.subscribe((subtasksSubscriptionsConfig) => {
            const subtasksObservables = Object.keys(subtasksSubscriptionsConfig).reduce((observablesArray, key) => {
                return [...observablesArray, subtasksSubscriptionsConfig[key]];
            }, []);

            // eslint-disable-next-line import/no-deprecated
            const newTasksObservable = combineLatest(...[this.tasks$, ...subtasksObservables]).pipe(
                map(([tasks, ...subtasksArrays]) => {
                    if (subtasksArrays) {
                        return this.addSubtasksToTasksList(tasks, subtasksArrays);
                    }
                    return tasks;
                }),
                map((tasks) =>
                    tasks.map((task: TaskListItem) => ({
                        ...task,
                        taskId: task.id,
                        id: `${task.projectId}${task.id}`,
                    })),
                ),
            );

            const newTasksSubscription = newTasksObservable.subscribe((newTasks) => {
                this.tableTasks$.next(newTasks);
            });
            this.subscriptions.push(newTasksSubscription);
        });

        this.subscriptions.push(subtasksSubscription);
    }

    selectNotHiddenColumns(columns: SavedViewColumn[]): SavedViewColumn[] {
        return columns.filter((column) => {
            return this.hiddenColumns.indexOf(column.fieldId as StandardFieldId) === -1;
        });
    }

    initSavedViewColumns(savedView: SavedView): SavedViewColumn[] {
        if (!this.selectedSavedView || this.selectedSavedView.viewType === savedView.viewType) {
            this.selectedSavedView = { ...savedView };
        }

        if (savedView.id === this.selectedSavedView.id) {
            return this.selectNotHiddenColumns(savedView.columns);
        }

        return [];
    }

    addSubtasksToTasksList(tasks: TaskListItem[], subtasksArrays: TaskListItem[][]): IndentedTask[] {
        const resultTasks = subtasksArrays.reduce(
            (resultTaskArray, subtasks) => {
                if (subtasks.length > 0) {
                    return this.insertSubtasksForTask(resultTaskArray, subtasks);
                }
                return resultTaskArray;
            },
            [...tasks],
        );

        return resultTasks;
    }

    insertSubtasksForTask(destTaskArray: IndentedTask[], subtasks: TaskListItem[]): IndentedTask[] {
        const { parent } = subtasks[0];
        const parentIndex = destTaskArray.findIndex((task) => task.id === parent);
        const extendedSubtasks = this.extendTasksWithIndentation(destTaskArray[parentIndex], subtasks);
        const newTasks = [
            ...destTaskArray.slice(0, parentIndex + 1),
            ...extendedSubtasks,
            ...destTaskArray.slice(parentIndex + 1),
        ];

        return newTasks;
    }

    initWindowSizeObserver(): void {
        this.isDesktop$ = this.breakpointObserver
            .observe([BOOTSTRAP_BREAKPOINTS.Large, BOOTSTRAP_BREAKPOINTS.XLarge])
            .pipe(map((result) => result.matches));
    }

    extendTasksWithIndentation(parentTask: IndentedTask, subtasks: TaskListItem[]): IndentedTask[] {
        const parentIndentation = parentTask.indentation || 0;

        return subtasks.map((subtask) => ({
            ...subtask,
            indentation: parentIndentation + 1,
            sectionName: ' ',
        }));
    }

    handleOrderChange(by: TaskListOrderBy): void {
        const current = this.taskListService.getOrderBy();
        let direction: SortDirection = current.direction;

        if (current.by === by) {
            if (current.direction === 'asc') {
                direction = 'desc';
            } else {
                direction = 'asc';
            }
        }

        this.taskListService.orderListBy(by, direction);
    }

    itemClick(item: any): void {
        const resultItem = this.parseTask(item);

        this.itemClicked.emit(resultItem);
    }

    parseTask(task: ExtendedTask): IndentedTask {
        const resultTask = {
            ...task,
            id: task.taskId,
        };

        delete resultTask.taskId;

        return resultTask;
    }

    addTask(): void {
        const modalRef = this.modalService.open(AddTaskDialogComponent);
        modalRef.componentInstance.groupId = this.groupId;
        modalRef.componentInstance.projectId = this.projectId;
        modalRef.result
            .then(({ projectId, sectionId, description }) => this.createTask(projectId, sectionId, description))
            .catch(() => {
                this.trackingConnector.trackEventInferCategoryFromUrl('new-task-dialog', 'closed');
            });
    }

    handleToggleSubtasksVisibility({ subtasksAreVisible, task }): void {
        if (subtasksAreVisible) {
            this.taskListService.loadSubstasks(task.projectId, task.taskId);
            this.addSubtasksSubscriptionForTask(task);
        } else {
            this.recursivelyHideSubtasks(task);
            this.recursivelyRemoveSubtasksSubscriptionForTask(task);
        }

        this.taskListService.showSubtasks(task.projectId, task.taskId, task.parent, subtasksAreVisible);

        const hasSubscription = Boolean(this.subtasksVisibility$[task.id]);

        if (!hasSubscription) {
            this.createSubtasksVisibilitySubscriptions(task);
        }
    }

    recursivelyHideSubtasks(task: ExtendedTask): void {
        const { children } = task;

        if (children.length > 0) {
            for (const childId of children) {
                const childTask = this.tableTasks$.value.find((tableTask) => tableTask.taskId === childId);

                if (childTask) {
                    this.recursivelyHideSubtasks(childTask);
                }
            }
        }

        this.taskListService.showSubtasks(task.projectId, task.taskId, task.parent, false);
    }

    addSubtasksSubscriptionForTask(task: ExtendedTask): void {
        const newSubtasks$ = this.taskListService
            .taskListChildOf(task.projectId, task.taskId)
            .pipe(filter((newSubtasks) => newSubtasks.length > 0));

        const currentSubtasksSubscriptions = this.subtasks$.value;
        const newSubtasksConfig = {
            ...currentSubtasksSubscriptions,
            [task.id]: newSubtasks$,
        };
        this.subtasks$.next(newSubtasksConfig);
    }

    recursivelyRemoveSubtasksSubscriptionForTask(task: ExtendedTask): void {
        const { children } = task;
        if (children.length > 0) {
            for (const childId of children) {
                const childTask = this.tableTasks$.value.find((tableTask) => tableTask.taskId === childId);

                if (childTask) {
                    this.recursivelyRemoveSubtasksSubscriptionForTask(childTask);
                }
            }
        }

        this.removeSubtasksSubscriptionForTask(task);
    }

    removeSubtasksSubscriptionForTask(task: ExtendedTask): void {
        const newSubtasksSubscriptions = { ...this.subtasks$.value };

        if (newSubtasksSubscriptions[task.id]) {
            delete newSubtasksSubscriptions[task.id];
        }
        this.subtasks$.next(newSubtasksSubscriptions);
    }

    createSubtasksVisibilitySubscriptions(task: ExtendedTask): void {
        const showingSubtasksSubscription$ = this.taskListService
            .isShowingSubtasks(task.projectId, task.taskId)
            .pipe(distinctUntilChanged())
            .subscribe((isVisible) => {
                this.subtasksVisibilityByTask[task.id] = isVisible;
            });

        this.subtasksVisibility$[task.id] = showingSubtasksSubscription$;
    }

    subtasksAreVisible(task: ExtendedTask): boolean {
        return this.subtasksVisibilityByTask[task.id] || false;
    }

    private async createTask(projectId: string, sectionId: string, description: string): Promise<void> {
        this.commonService.setBusy(true);
        try {
            await this.taskListService.createTask(projectId, sectionId, description);
            this.commonService.setBusy(false);
        } catch (e) {
            this.commonService.setBusy(false);
        }
    }

    handleDeleteTask(task: ExtendedTask): void {
        const resultTask = this.parseTask(task);

        this.deleteTask.emit({
            projectId: resultTask.projectId,
            taskId: resultTask.id,
            parentId: resultTask.parent,
            isMilestone: resultTask.isMilestone,
        });
    }

    handleCommentsClick(task: ExtendedTask): void {
        const resultTask = this.parseTask(task);
        const { projectId, id } = resultTask;

        this.commentsClicked.emit({ projectId, id });
    }

    handleShowOnCanvas(task: ExtendedTask): void {
        const resultTask = this.parseTask(task);

        this.showOnCanvas.emit({
            projectId: resultTask.projectId,
            taskId: resultTask.parent || resultTask.id,
        });
    }

    handleChangePriority($event: { task: ExtendedTask; priority: TaskPriority }) {
        const { task, priority } = $event;
        const resultTask = this.parseTask(task);

        this.changePriority.emit({
            projectId: resultTask.projectId,
            taskId: resultTask.id,
            parentId: resultTask.parent,
            priority,
        });
    }

    handleChangeStatus($event: { task: ExtendedTask; status: TaskStatus }) {
        const { task, status } = $event;
        const resultTask = this.parseTask(task);

        this.changeStatus.emit({
            projectId: resultTask.projectId,
            taskId: resultTask.id,
            parentId: resultTask.parent,
            status,
        });
    }

    handleWidthChanged(columns: SavedViewColumnValue[]) {
        this.selectedSavedViewService.updateColumns(columns);
    }

    loadMore() {
        this.taskListService.loadMore();
    }

    hasScrollbar(): boolean {
        if (this.tasksTableElement) {
            return this.tasksTableElement.scrollHeight > this.tasksTableElement.clientHeight;
        }
        return false;
    }

    private getTotalItems(): Observable<number> {
        return combineLatest([
            this.loading$,
            this.taskListService.totalItems$,
            this.filters$,
            this.searchValueSubject.asObservable(),
        ]).pipe(
            debounceTime(250),
            filter(([loading, totalItems, filterRules, searchValue]) => !loading),
            map(([loading, totalItems, filterRules, searchValue]) => {
                if (filterRules.length !== 0 || searchValue) {
                    return totalItems;
                } else {
                    return 0;
                }
            }),
        );
    }
}
