import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointService } from './../common/breakpoint.service';
import { AdminNavbarService } from './admin-navbar.service';

@Component({
    selector: 'ruum-admin-navbar-breadcrumb',
    template: `
        <div class="d-flex flex-fill align-items-center minw-0">
            <div class="d-flex flex-fill align-items-center minw-0">
                <ng-container *ngIf="(isLargeBreakpoint$ | async) !== true">
                    <ruum-navbar-back-button></ruum-navbar-back-button>
                </ng-container>
                <ruum-breadcrumb-active-item [name]="'Admin Panel'"></ruum-breadcrumb-active-item>
                <ruum-breadcrumb-separator></ruum-breadcrumb-separator>
                <ruum-breadcrumb-active-item [name]="title$ | async"></ruum-breadcrumb-active-item>
            </div>
        </div>
    `,
})
export class AdminNavbarBreadcrumbComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';

    isLargeBreakpoint$: Observable<boolean>;
    title$: Observable<string>;

    constructor(private breakpointService: BreakpointService, private adminNavbarService: AdminNavbarService) {}

    ngOnInit() {
        this.isLargeBreakpoint$ = this.breakpointService.isLargeBreakpoint();
        this.title$ = this.adminNavbarService.getTitle();
    }
}
