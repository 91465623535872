import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputModule } from '../../ui-components/input/input.module';
import { TaskDescriptionComponent } from './task-description.component';

@NgModule({
    declarations: [TaskDescriptionComponent],
    imports: [CommonModule, FormsModule, InputModule],
    exports: [TaskDescriptionComponent],
})
export class TaskDescriptionModule {}
