import { PaginatedList } from '../../common/connectors/paginatedList.model';

export enum WebhookEntityType {
    RUUM = 'ruum',
    PROJECT_GROUP = 'project_group',
}

export class WebhookEvent {
    id: number;
    entityId: string;
    entityType: WebhookEntityType;
    actionId: string;
    actionType: string;
    actionVersion: number;
    actionPayload: string;
    actionMetadata: string;
    createdBy: string;
    createdAt: number;
}

export enum WebhookAuthType {
    BASIC = 'BASIC',
}
export enum WebhookType {
    TECHNICAL_USER = 'TECHNICAL_USER',
    WORKSPACE = 'WORKSPACE',
}
export class WebhookConfiguration {
    id: string;
    name: string;
    userId: string;
    enterpriseId: string;
    type: WebhookType;
    url: string;
    version: number;
    authType: WebhookAuthType;
    username: string;
    password: string;
}

export interface WebhookEntityEventListItem {
    entityId: string;
    entityVersion: number;
    status: string;
    statusCode: number;
    createdBy: string;
    createdAt: Date;
    changedBy: string;
    changedAt: Date;
}

export interface WebhookEntitySyncStateListItem {
    webhookId: string;
    entityId: string;
    events?: PaginatedList<WebhookEntityEventListItem>;
    lastEntityVersion: number;
    currentEntityVersion: number;
    currentState: string;
}

export class WebhookConfigurationListItem {
    id: string;
    name: string;
    userId?: string;
    workspaceId?: string;
    enterpriseId: string;
    type: WebhookType;
    url: string;
    version: number;
    authType: WebhookAuthType;
    username: string;
    entities?: PaginatedList<WebhookEntitySyncStateListItem>;
    // password: string;
}

export enum WebhookSyncStatus {
    OK = 'OK',
    ERROR = 'ERROR',
    NONE = 'NONE',
}

export class WebhookEventSyncStatus {
    entityId: string;
    entityType: WebhookEntityType;
    eventId: number;
    webhookId: string;
    status: WebhookSyncStatus;
    responsePayload: string;
    statusCode: number;
    lastChangedBy: string;
    lastChangedAt: number;
}
