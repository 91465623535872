import { ChangeDetectorRef, Component, HostBinding, HostListener, Input } from '@angular/core';
import { isMobile } from '../../utils.service';

@Component({
    selector: 'ruum-draggable-item',
    template: `
        <div
            class="d-flex flex-fill minw-0 ruum-draggable-item-container align-items-center rounded"
            [class.border]="isDraggable() && !hideBorder"
        >
            <button class="btn btn-link-secondary px-0 float-left" type="button" *ngIf="!isMobile() && !hideButton">
                <i class="icon" [class.icon-drag]="isDraggable()"></i>
            </button>
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            .ruum-draggable-item-container {
                border: 1px solid transparent;
            }
            :host-context(.gu-unselectable) .ruum-draggable-item-container {
                border-color: transparent !important;
            }
            :host-context(.gu-render-no-border) .ruum-draggable-item-container {
                border: 0px solid transparent;
            }
            :host-context(.gu-unselectable):not(.gu-mirror) .icon {
                color: transparent !important;
            }
        `,
    ],
})
export class RuumDraggableItemComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';
    @Input() hideButton = false;
    @Input() hideBorder = false;
    @Input() isReadOnly = false;

    private hover = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    @HostListener('mouseenter') onMouseEnter() {
        this.hover = true;
        this.changeDetectorRef.detectChanges();
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hover = false;
        this.changeDetectorRef.detectChanges();
    }

    isDraggable() {
        return this.hover && !this.isReadOnly;
    }

    isMobile() {
        return isMobile();
    }
}
