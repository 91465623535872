import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { KanbanTaskService } from '../../../project/kanban/kanban-task.service';
import { ApprovalListService } from './../../../common/connectors/approvals/approval-list.service';
import { ApprovalStatus } from './../../../common/connectors/approvals/approval.model';
import { LobbyListService } from './../../../common/connectors/lobbyList/lobby-list.service';
import { TaskListService } from './../../../common/connectors/tasks/task-list.service';
import { SavedViewFilter, SavedViewFilterDefinition } from './saved-views.model';

@Injectable({ providedIn: 'root' })
export class SavedViewFiltersService {
    constructor(
        private ruumListService: LobbyListService,
        private taskListService: TaskListService,
        private kanbanTaskService: KanbanTaskService,
        private approvalListService: ApprovalListService,
    ) {}

    getFiltersByType(typeIds): Observable<SavedViewFilterDefinition[]> {
        return combineLatest([this.getFilters(typeIds)]).pipe(map(([columns]) => columns.filter((el) => !!el)));
    }

    getFiltersMapByType(typeIds): Observable<{ [key: string]: SavedViewFilterDefinition }> {
        return combineLatest([this.getFilters(typeIds)]).pipe(
            map(([columns]) => {
                return columns
                    .filter((el) => !!el)
                    .reduce((state, column) => {
                        return {
                            ...state,
                            [column.typeId]: column,
                        };
                    }, {});
            }),
        );
    }

    getFilters(typeIds: string[]): Observable<SavedViewFilterDefinition[]> {
        const entities = this.entities();
        const list = typeIds.map((typeId) => entities[typeId]);
        return of(list);
    }

    getFilterByTypeId(typeId: string): Observable<SavedViewFilterDefinition> {
        const entities = this.entities();
        const item = entities[typeId];
        return of(item);
    }

    getFiltersMap(): Observable<{ [key: string]: SavedViewFilterDefinition }> {
        return of(this.entities());
    }

    applyLobbyListFilters(savedViewFilters: SavedViewFilter[], workspaceId: string, groupId: string) {
        this.ruumListService.resetFilters();
        this.ruumListService.filterByWorkspaceId(workspaceId);
        this.ruumListService.filterByGroupId(groupId);
        this.ruumListService.filterByListType('projects_and_groups');
        if (groupId) {
            this.ruumListService.filterByListType('projects');
        }

        savedViewFilters.forEach((savedViewFilter) => {
            /** List Type */
            if (savedViewFilter.typeId === 'groupsAndRuums') {
                const listType: any = savedViewFilter.selectedOptions.join(',');
                this.ruumListService.filterByListType(listType);
            }

            /** Status */
            if (savedViewFilter.typeId === 'lobbyStatus') {
                this.ruumListService.filterByStatus(savedViewFilter.selectedOptions);
            }

            /** Labels */
            if (savedViewFilter.typeId === 'tags') {
                this.ruumListService.filterByTags(savedViewFilter.selectedOptions);
            }

            /** Creator */
            if (savedViewFilter.typeId === 'createdBy') {
                this.ruumListService.filterByCreatedBy(savedViewFilter.selectedOptions.join(','));
            }

            /** Workspace */
            if (savedViewFilter.typeId === 'workspace') {
                this.ruumListService.filterByWorkspaceId(savedViewFilter.selectedOptions.join(','));
            }

            /** Group */
            if (savedViewFilter.typeId === 'group') {
                this.ruumListService.filterByListType('projects');
                this.ruumListService.filterByGroupId(savedViewFilter.selectedOptions.join(','));
            }
        });

        /** Custom Fields */
        const customFields = this.getCustomFields(savedViewFilters);
        if (Object.keys(customFields).length) {
            this.ruumListService.filterByListType('projects');
            this.ruumListService.filterByCustomFields(customFields);
        }
    }

    applyTaskListFilters(savedViewFilters: SavedViewFilter[], workspaceId: string, groupId: string, projectId: string) {
        this.taskListService.resetFilters();
        this.taskListService.filterByWorkspaceId(workspaceId);
        this.taskListService.filterByGroupId(groupId);
        this.taskListService.filterByProjectId(projectId);

        savedViewFilters.forEach((savedViewFilter) => {
            /** Priority */
            if (savedViewFilter.typeId === 'priority') {
                const priorityList: any = savedViewFilter.selectedOptions;
                this.taskListService.filterByPriority(priorityList);
            }

            /** Creator */
            if (savedViewFilter.typeId === 'createdBy') {
                this.taskListService.filterByCreatedBy(savedViewFilter.selectedOptions.join(','));
            }

            /** Assigned Members */
            if (savedViewFilter.typeId === 'assignees') {
                this.taskListService.filterByAssignedMembers(savedViewFilter.selectedOptions);
            }

            /** Assigned Roles */
            if (savedViewFilter.typeId === 'roles') {
                this.taskListService.filterByAssignedRoles(savedViewFilter.selectedOptions);
            }

            /** Workspace */
            if (savedViewFilter.typeId === 'workspace') {
                this.taskListService.filterByWorkspaceId(savedViewFilter.selectedOptions.join(','));
            }

            /** Group */
            if (savedViewFilter.typeId === 'group') {
                this.taskListService.filterByGroupId(savedViewFilter.selectedOptions.join(','));
            }

            /** Project */
            if (savedViewFilter.typeId === 'project') {
                this.taskListService.filterByProjectId(savedViewFilter.selectedOptions.join(','));
            }
        });

        /** Task Status and Milestone Stataus */
        const statusList = savedViewFilters
            .filter(
                (savedViewFilter) => savedViewFilter.typeId === 'taskStatus' || savedViewFilter.typeId === 'milestone',
            )
            .reduce((accumulator, currentValue) => {
                return [...accumulator, currentValue.selectedOptions];
            }, []);

        if (statusList.length) {
            this.taskListService.filterByStatus(statusList);
        }

        /** Custom Fields */
        const customFields = this.getCustomFields(savedViewFilters);
        if (Object.keys(customFields).length) {
            this.taskListService.filterByCustomFields(customFields);
        }
    }

    applyKanbanFilters(savedViewFilters: SavedViewFilter[], workspaceId: string, groupId: string, projectId: string) {
        this.kanbanTaskService.resetFilters();
        this.kanbanTaskService.filterByProjectId(projectId);

        savedViewFilters.forEach((savedViewFilter) => {
            /** Creator */
            if (savedViewFilter.typeId === 'createdBy') {
                this.kanbanTaskService.filterByCreatedBy(savedViewFilter.selectedOptions.join(','));
            }

            /** Assigned Members */
            if (savedViewFilter.typeId === 'assignees') {
                this.kanbanTaskService.filterByAssignedMembers(savedViewFilter.selectedOptions);
            }

            /** Assigned Roles */
            if (savedViewFilter.typeId === 'roles') {
                this.kanbanTaskService.filterByAssignedRoles(savedViewFilter.selectedOptions);
            }
        });

        /** Custom Fields */
        const customFields = this.getCustomFields(savedViewFilters);
        if (Object.keys(customFields).length) {
            this.kanbanTaskService.filterByCustomFields(customFields);
        }
    }

    applyApprovalListFilters(
        savedViewFilters: SavedViewFilter[],
        workspaceId: string,
        groupId: string,
        projectId: string,
    ) {
        this.approvalListService.resetFilters();
        this.approvalListService.filterByWorkspaceId(workspaceId);
        this.approvalListService.filterByGroupId(groupId);
        this.approvalListService.filterByProjectId(projectId);

        savedViewFilters.forEach((savedViewFilter) => {
            /** requestor */
            if (savedViewFilter.typeId === 'requestedBy') {
                this.approvalListService.filterByCreatedBy(savedViewFilter.selectedOptions.join(','));
            }

            /** Approval Status */
            if (savedViewFilter.typeId === 'approvalStatus') {
                this.approvalListService.filterByStatus(<ApprovalStatus[]>savedViewFilter.selectedOptions);
            }

            /** Assigned Members */
            if (savedViewFilter.typeId === 'approver') {
                this.approvalListService.filterByApproverIds(savedViewFilter.selectedOptions);
            }

            /** Assigned Members */
            if (savedViewFilter.typeId === 'responsibles') {
                this.approvalListService.filterByResponsibleIds(savedViewFilter.selectedOptions);
            }

            /** Assigned Roles */
            if (savedViewFilter.typeId === 'approverRole') {
                this.approvalListService.filterByApproverRoleIds(savedViewFilter.selectedOptions);
            }

            /** Workspace */
            if (savedViewFilter.typeId === 'workspace') {
                this.approvalListService.filterByWorkspaceId(savedViewFilter.selectedOptions.join(','));
            }

            /** Group */
            if (savedViewFilter.typeId === 'group') {
                this.approvalListService.filterByGroupId(savedViewFilter.selectedOptions.join(','));
            }

            /** Project */
            if (savedViewFilter.typeId === 'project') {
                this.approvalListService.filterByProjectId(savedViewFilter.selectedOptions.join(','));
            }
        });

        /** Custom Fields */
        const customFields = this.getCustomFields(savedViewFilters);
        if (Object.keys(customFields).length) {
            this.approvalListService.filterByCustomFields(customFields);
        }
    }

    private getCustomFields(savedViewFilters: SavedViewFilter[]) {
        return savedViewFilters
            .filter((rule) => !!rule.fieldId.match('custom_field_'))
            .reduce((accumulator, currentValue) => {
                const fieldId = currentValue.fieldId;
                let value: any = currentValue.selectedOptions[0];

                if (currentValue.typeId === 'number') {
                    value = parseFloat(currentValue.selectedOptions[0]);
                }

                if (currentValue.typeId === 'selection_list') {
                    value = currentValue.selectedOptions;
                }

                return { ...accumulator, [fieldId]: value };
            }, {});
    }

    private entities(): { [key: string]: SavedViewFilterDefinition } {
        const list: SavedViewFilterDefinition[] = [
            {
                typeId: 'lobbyStatus',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'No Status', title: 'No Status' },
                        { fieldId: 'On Track', title: 'On Track' },
                        { fieldId: 'Minor Delay', title: 'Minor Delay' },
                        { fieldId: 'Major Delay', title: 'Major Delay' },
                        { fieldId: 'Completed', title: 'Completed' },
                    ],
                },
            },
            {
                typeId: 'taskStatus',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'OPEN', title: 'Open' },
                        { fieldId: 'WORKING', title: 'Working' },
                        { fieldId: 'AT_RISK', title: 'At Risk' },
                        { fieldId: 'BLOCKED', title: 'Blocked' },
                        { fieldId: 'DONE', title: 'Done' },
                    ],
                },
            },
            {
                typeId: 'changedAt',
                logics: [],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'today', title: 'Today' },
                        { fieldId: 'yesterday', title: 'Yesterday' },
                        { fieldId: 'this week', title: 'This Week' },
                        { fieldId: 'this month', title: 'This Month' },
                        { fieldId: 'last 2 weeks', title: 'Last 2 Weeks' },
                    ],
                },
            },
            {
                typeId: 'createdBy',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'members-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'tags',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'tag-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'project',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'project-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'section',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'section-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'group',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'group-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'workspace',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'workspace-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'priority',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'HIGH', title: 'High' },
                        { fieldId: 'MEDIUM', title: 'Medium' },
                        { fieldId: 'LOW', title: 'Low' },
                    ],
                },
            },
            {
                typeId: 'startDate',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'datepicker',
                },
            },
            {
                typeId: 'dueDate',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'datepicker',
                },
            },
            {
                typeId: 'createdOn',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'datepicker',
                },
            },
            {
                typeId: 'changedAt',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'datepicker',
                },
            },
            {
                typeId: 'assignees',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'members-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'roles',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'roles-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'groupsAndRuums',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'projects', title: 'Only Ruums' },
                        { fieldId: 'projectgroups', title: 'Only Groups' },
                    ],
                },
            },
            {
                typeId: 'milestone',
                logics: [{ id: 'IS_ANY_OF', name: 'is any of' }],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'MISSED', title: 'Missed' },
                        { fieldId: 'FUTURE', title: 'Future' },
                        { fieldId: 'REACHED', title: 'Reached' },
                    ],
                },
            },
            {
                typeId: 'approvalStatus',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'select',
                    optionsList: [
                        { fieldId: 'approved', title: 'Approved' },
                        { fieldId: 'rejected', title: 'Rejected' },
                        { fieldId: 'pending', title: 'Pending' },
                        { fieldId: 'null', title: 'Not Ready' },
                    ],
                },
            },
            {
                typeId: 'responsibles',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'members-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'approver',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'members-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'approverRole',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'roles-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'createdBy',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'members-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'requestedBy',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'members-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'string',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'number',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'boolean',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'date',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'datepicker',
                    optionsList: [],
                },
            },
            {
                typeId: 'link',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'email',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'phone',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'selection_list',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'custom-field-list-select',
                    optionsList: [],
                },
            },
            {
                typeId: 'long_string',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
            {
                typeId: 'record',
                logics: [{ id: 'IS', name: 'is' }],
                selectionAttrs: {
                    type: 'search',
                    optionsList: [],
                },
            },
        ];

        return list.reduce((state, item) => {
            return {
                ...state,
                [item.typeId]: item,
            };
        }, {});
    }
}
