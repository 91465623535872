import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SavedViewColumnsService, SavedViewListItem } from '../saved-view-settings-dialog/saved-view-columns.service';
import { ListViewItem } from '../saved-view-settings-dialog/saved-view-settings-dialog.component';

@Component({
    selector: 'ruum-task-list-customize-columns',
    template: `
        <ruum-saved-view-settings-dialog-button
            [columns]="columns$ | async"
            [hiddenColumns]="hiddenColumns"
            dialogTitle="Customize Columns"
            (updateColumns)="updateColumns($event)"
            (searchQueryChange)="searchColumns($event)"
            (cancelSettings)="resetSearch()"
            [hasMoreUncheckedItems]="hasMoreCustomFields() | async"
            (loadMore)="loadMoreListItems()"
        ></ruum-saved-view-settings-dialog-button>
    `,
    providers: [SavedViewColumnsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListCustomizeColumnsComponent implements OnInit {
    @HostBinding('class.d-flex')
    className = true;

    @Input() hiddenColumns: string[] = [];

    columns$: Observable<ListViewItem[]>;

    constructor(private savedViewColumnsService: SavedViewColumnsService) {}

    ngOnInit() {
        this.initListItems();
    }

    initListItems(): void {
        this.columns$ = this.savedViewColumnsService.getColumns$();
    }

    updateColumns(columns: SavedViewListItem[]) {
        this.savedViewColumnsService.updateColumns(columns);
        this.savedViewColumnsService.resetSearch();
    }

    searchColumns(query: string) {
        this.savedViewColumnsService.searchColumns(query);
    }

    resetSearch(): void {
        this.savedViewColumnsService.resetSearch();
    }

    loadMoreListItems(): void {
        this.savedViewColumnsService.loadMoreItems();
    }

    hasMoreCustomFields(): Observable<boolean> {
        return this.savedViewColumnsService.hasMoreCustomFields();
    }
}
