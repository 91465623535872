import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SavedViewFieldsService } from '../../../common/connectors/savedView/fields/saved-view-fields.service';
import { SavedViewColumn } from '../../../common/connectors/savedView/saved-views.model';
import { SelectedSavedViewService } from '../../../common/connectors/savedView/selected-saved-view.service';
import { differenceBy } from '../../../common/utils.service';
import { ListViewItem } from './saved-view-settings-dialog.component';

export interface SavedViewListItem extends SavedViewColumn, ListViewItem {} // SavedViewListItem is used to preserve saved view parameters for saving
@Injectable({
    providedIn: 'root',
})
export class SavedViewColumnsService implements OnDestroy {
    private columnsObservable$: Observable<SavedViewListItem[]>;

    constructor(
        private selectedSavedViewService: SelectedSavedViewService,
        private savedViewFieldsService: SavedViewFieldsService,
    ) {
        this.initColumnsObservable();
    }

    ngOnDestroy(): void {
        this.savedViewFieldsService.stopLoad();
    }

    initLoading() {
        this.savedViewFieldsService.startLoad();
    }

    stopLoading() {
        this.savedViewFieldsService.stopLoad();
    }

    initColumnsObservable() {
        const savedViewVisibleColumns = this.selectedSavedViewService
            .getSelectedSavedView()
            .pipe(map((savedView) => savedView.columns));

        this.columnsObservable$ = combineLatest([
            savedViewVisibleColumns,
            this.selectedSavedViewService.getAllAvailableColumns(),
        ]).pipe(
            map(([visibleColumns, allColumns]) => {
                const invisibleColumns = differenceBy(allColumns, visibleColumns, 'fieldId').map((column) => ({
                    ...column,
                    width: column.initialWidth,
                }));

                return [
                    ...this.transformColumnToView({ visible: true }, visibleColumns),
                    ...this.transformColumnToView({ visible: false }, invisibleColumns),
                ];
            }),
        );
    }

    transformColumnToView({ visible }: { visible: boolean }, savedViewColumns: any[]): SavedViewListItem[] {
        return savedViewColumns.map((savedViewColumn) => ({
            ...savedViewColumn,
            id: savedViewColumn.fieldId,
            name: savedViewColumn.title,
            isFixed: savedViewColumn.mandatory,
            isChecked: visible,
        }));
    }

    getColumns$(): Observable<SavedViewListItem[]> {
        return this.columnsObservable$;
    }

    updateColumns(columns: SavedViewListItem[]): void {
        const savedViewColumns = columns
            .filter((column) => column.isChecked)
            .map((column) => ({
                fieldId: column.id,
                width: column.width,
            }));

        this.selectedSavedViewService.updateColumns(savedViewColumns);
    }

    searchColumns(query: string): void {
        this.savedViewFieldsService.search(query);
    }

    resetSearch(): void {
        this.savedViewFieldsService.search('');
    }

    loadMoreItems() {
        this.savedViewFieldsService.loadMore();
    }

    hasMoreCustomFields(): Observable<boolean> {
        return this.savedViewFieldsService.hasMore();
    }
}
