import {
    FunctionalRoleReducer,
    isFunctionalRoleReducerAction,
    ProjectAction,
    RuumAction,
    WorkspaceParticipant,
    WorkspaceParticipantRole,
} from '@ruum/ruum-reducers';
import {
    FunctionalRolesMap,
    FUNCTIONAL_ROLE_LIST_ACTION_TYPES,
} from '../workspace/functionalRolesList/functionalRolesList.reducer';
import { FunctionalRoleListItem } from './functionalRoles.model';

export interface FunctionalRoleMap {
    [id: string]: FunctionalRoleListItem;
}

export const FUNCTIONAL_ROLE_WEBAPP_ACTION_TYPES = {
    ADD_OR_REPLACE_FUNCTIONAL_ROLES: 'ADD_OR_REPLACE_FUNCTIONAL_ROLES',
};

export type AddOrReplaceFunctionalRoles = ProjectAction<
    'ADD_OR_REPLACE_FUNCTIONAL_ROLES',
    {
        roles: FunctionalRoleListItem[];
    }
>;

export function FunctionRolesWebAppReducer(
    currentState: FunctionalRoleMap = {},
    action: RuumAction,
): FunctionalRoleMap {
    if (isFunctionalRoleReducerAction(action.type)) {
        return runFunctionalRolesReducers(currentState, action);
    }
    switch (action.type) {
        case FUNCTIONAL_ROLE_LIST_ACTION_TYPES.FUNCTIONAL_ROLES_LOADED:
        case FUNCTIONAL_ROLE_WEBAPP_ACTION_TYPES.ADD_OR_REPLACE_FUNCTIONAL_ROLES:
            const rows = action.payload.page ? action.payload.page.rows : action.payload.roles;
            return addOrReplace(currentState, rows);
        default:
            return currentState;
    }
}

function addOrReplace(currentState: FunctionalRoleMap, entries: FunctionalRoleListItem[]): FunctionalRoleMap {
    const newEntries = getMap(entries);
    return {
        ...currentState,
        ...newEntries,
    };
}

function getMap(entries: FunctionalRoleListItem[]): FunctionalRoleMap {
    const map: FunctionalRoleMap = {};

    entries.forEach((entry) => {
        map[entry.id] = entry;
    });

    return map;
}

function runFunctionalRolesReducers(currentState: FunctionalRolesMap, action: RuumAction): FunctionalRolesMap {
    const workspaceId = action.entityId;

    const actionCreator: WorkspaceParticipant = {
        id: action.createdBy,
        role: action.role as WorkspaceParticipantRole,
    };
    const list = Object.values(currentState).filter((field) => field.sourceId === workspaceId);

    const changed = <FunctionalRoleListItem[]>(
        FunctionalRoleReducer(Object.values(currentState), action, { actionCreator })
    );
    return addOrReplace(currentState, changed);
}
