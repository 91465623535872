import { ProjectAction, RuumAction } from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../../app/lobby/lobby.reducer';
import { PaginatedList } from '../../../common/connectors/paginatedList.model';
import { Chats, ChatsListItem } from './chat-list.model';

export const CHATS_LIST_ACTIONS = {
    CHATS_LIST_LOADED: 'CHATS_LIST_LOADED',
    PROJECT_CHAT_LOADED: 'PROJECT_CHAT_LOADED',
    UNSEEN_COMMENTS_COUNTER_LOADED: 'UNSEEN_COMMENTS_COUNTER_LOADED',
    REPLACE_CHAT: 'REPLACE_CHAT',
};

function getDefault(): Chats {
    return {
        unseenComments: 0,
        list: getDefaultPaginatedList(),
        projectChat: getDefaultPaginatedList(),
    };
}

export function ChatsReducer(currentState: Chats = getDefault(), action: RuumAction): Chats {
    switch (action.type) {
        case CHATS_LIST_ACTIONS.UNSEEN_COMMENTS_COUNTER_LOADED:
            return {
                ...currentState,
                unseenComments: action.payload.unseenComments,
            };
        case CHATS_LIST_ACTIONS.PROJECT_CHAT_LOADED:
            return {
                ...currentState,
                projectChat: action.payload.projectChat,
            };
        case CHATS_LIST_ACTIONS.REPLACE_CHAT:
            return {
                ...currentState,
                list: replaceChat(currentState.list, action),
            };
        default:
            return {
                ...currentState,
                list: ChatsListReducer(currentState.list, action),
            };
    }
}

function ChatsListReducer(
    currentState: PaginatedList<ChatsListItem>,
    action: RuumAction,
): PaginatedList<ChatsListItem> {
    if (!CHATS_LIST_ACTIONS[action.type]) {
        return currentState;
    }

    switch (action.type) {
        case CHATS_LIST_ACTIONS.CHATS_LIST_LOADED:
            return chatsLoaded(currentState, action.payload.list);
    }
}

function chatsLoaded(
    currentState: PaginatedList<ChatsListItem>,
    data: PaginatedList<ChatsListItem>,
): PaginatedList<ChatsListItem> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return data;
    }
}

function replaceChat(
    currentState: PaginatedList<ChatsListItem>,
    action: ReplaceChatAction,
): PaginatedList<ChatsListItem> {
    const { objectId, objectType, projectId } = action.payload.chat;
    const rows = currentState.rows.map((row) => {
        if (row.objectId === objectId && row.objectType === objectType && row.projectId === projectId) {
            return action.payload.chat;
        } else {
            return row;
        }
    });
    return {
        ...currentState,
        rows,
    };
}

export type ReplaceChatAction = ProjectAction<
    'REPLACE_CHAT',
    {
        chat: ChatsListItem;
    }
>;
