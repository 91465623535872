import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BaseFormComponent } from '../components/form.component';
import { ModalService } from './modal.service';

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard implements CanDeactivate<BaseFormComponent> {
    constructor(private modalService: ModalService) {}
    canDeactivate(component: BaseFormComponent): Promise<boolean> {
        if (typeof component.isDirty === 'function' && component.isDirty()) {
            return this.modalService.openDataLossDialog();
        }
        return Promise.resolve(true);
    }
}
