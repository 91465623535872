import { Injectable } from '@angular/core';
import { SystemConnector, SystemConnectors } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';

@Injectable({ providedIn: 'root' })
export class SystemConnectorsStore extends StoreLoader<SystemConnector> {
    private map = new BehaviorSubject<SystemConnectors>({});

    constructor(private readModelBackendConnector: ReadModelBackendConnector) {
        super();
    }

    protected getData(id: string[]): Observable<SystemConnector[]> {
        return this.readModelBackendConnector
            .getSystemConnectors({ page: 1, pageSize: id.length }, { id })
            .pipe(map((page) => page.rows));
    }

    protected storeData(list: SystemConnector[]): void {
        const change: SystemConnectors = {};
        list.forEach((table) => {
            change[table.id] = table;
        });
        this.map.next({
            ...this.map.value,
            ...change,
        });
    }

    protected failedToLoad(id: string): void {
        this.map.next({
            ...this.map.value,
            [id]: {
                id,
                title: 'Failed to load',
                type: 'standard',
                createdAt: Date.now(),
                createdBy: null,
                input: null,
                output: null,
                systemId: '',
                path: '',
            },
        });
    }

    protected getStoreData(): Observable<{ [id: string]: SystemConnector }> {
        return this.map.asObservable();
    }
}
