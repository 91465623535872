import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RadioButtonComponent } from '../radio/radio.component';
import { RadioButtonGroupComponent } from './radio-group.component';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [RadioButtonComponent, RadioButtonGroupComponent],
    exports: [RadioButtonComponent, RadioButtonGroupComponent],
})
export class RadioModule {}
