import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { ProjectGroupRenameComponent } from './project-group-rename.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalDialogModule],
    declarations: [ProjectGroupRenameComponent],
    exports: [ProjectGroupRenameComponent],
})
export class ProjectGroupRenameModule {}
