import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { CanvasBackendConnector } from '../../../ruum/canvas/canvas.backendConnector';
import { RuumAlertService } from '../../components/alert/alert.service';
import { TrackingConnector } from '../../trackingConnector.service';
import { deepEqual } from '../../utils.service';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { ApprovalListFilters, ApprovalListItem, ApprovalListOrderBy, ApprovalStatus } from './approval.model';

const initializeOrder = () => {
    return {
        by: (sessionStorage.getItem('approvalListSortBy') as ApprovalListOrderBy) || 'lastChangedAt',
        direction: (sessionStorage.getItem('approvalListSortDirection') as SortDirection) || 'desc',
    };
};

export abstract class ApprovalListConnector extends PaginatedListLoader<
    ApprovalListItem,
    ApprovalListFilters,
    ApprovalListOrderBy
> {
    readonly groupId$ = new BehaviorSubject<string>(undefined);
    readonly workspaceId$ = new BehaviorSubject<string>(undefined);
    readonly template$ = new BehaviorSubject<boolean>(false);
    readonly projectId$ = new BehaviorSubject<string>(undefined);
    readonly projectName$ = new BehaviorSubject<string>(undefined);
    readonly status$ = new BehaviorSubject<ApprovalStatus[]>([]);
    readonly createdBy$ = new BehaviorSubject<string>(undefined);
    readonly approverIds$ = new BehaviorSubject<string[]>([]);
    readonly responsibleIds$ = new BehaviorSubject<string[]>([]);
    readonly approverRoleIds$ = new BehaviorSubject<string[]>([]);
    readonly customFields$ = new BehaviorSubject<string>('');
    readonly orderBy$ = new BehaviorSubject<OrderedListParams<ApprovalListOrderBy>>(initializeOrder());

    constructor(
        protected readModelConnector: ReadModelBackendConnector,
        protected alertService: RuumAlertService,
        protected projectServiceConnector: ProjectServiceBackendConnector,
        protected canvasConnector: CanvasBackendConnector,
        protected trackingConnector: TrackingConnector,
        protected authService: AuthService,
    ) {
        super(alertService, authService);
    }

    protected getData(
        page: number,
        filters: ApprovalListFilters,
        orderBy: OrderedListParams<ApprovalListOrderBy>,
    ): Observable<PaginatedList<ApprovalListItem>> {
        return this.readModelConnector.getApprovals({ page }, filters, orderBy);
    }

    getGroupId$(): Observable<string> {
        return this.groupId$.asObservable();
    }

    getWorkspaceId$(): Observable<string> {
        return this.workspaceId$.asObservable();
    }

    resetFilters() {
        this.createdBy$.next(undefined);
        this.approverIds$.next([]);
        this.responsibleIds$.next([]);
        this.approverRoleIds$.next([]);
        this.groupId$.next(undefined);
        this.workspaceId$.next(undefined);
        this.status$.next([]);
        this.projectId$.next(undefined);
        this.projectName$.next(undefined);
        this.customFields$.next('');
    }

    getFilters$(): Observable<ApprovalListFilters> {
        return combineLatest([
            this.customFields$,
            this.createdBy$,
            this.projectName$,
            this.groupId$,
            this.workspaceId$,
            this.approverIds$,
            this.responsibleIds$,
            this.approverRoleIds$,
            this.status$,
            this.projectId$,
            this.template$,
        ]).pipe(
            map<any, ApprovalListFilters>(
                ([
                    customFields,
                    createdBy,
                    projectName,
                    groupId,
                    workspaceId,
                    approverId,
                    responsibles,
                    approverRoleId,
                    status,
                    projectId,
                    template,
                ]) => {
                    return {
                        customFields,
                        createdBy,
                        projectName,
                        groupId,
                        workspaceId,
                        approverId,
                        responsibles,
                        approverRoleId,
                        status,
                        projectId,
                        template,
                    };
                },
            ),
            distinctUntilChanged((a, b) => deepEqual(a, b)),
        );
    }

    isBusy$(): Observable<boolean> {
        return combineLatest([this.isLoadingAnotherPage$, this.isLoadingFirstPage$]).pipe(
            map(([first, other]) => first || other),
        );
    }
}
