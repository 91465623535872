import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { SavedViewFilterRules, SavedViewFilterValue } from '../../connectors/savedView/saved-views.model';
import { SelectedSavedViewService } from '../../connectors/savedView/selected-saved-view.service';
import { isMobile } from './../../../common/utils.service';
@Component({
    selector: 'ruum-task-list-filters',
    template: `
        <div class="d-flex align-items-center">
            <div class="d-flex mr-0 mr-md-3" [class.flex-fill]="isMobile()" [style.width.px]="280">
                <ruum-search
                    [theme]="'white'"
                    [size]="'sm'"
                    ariaLabel="Search for task list filters"
                    [value]="searchValue"
                    (changed)="search($event)"
                ></ruum-search>
            </div>

            <ruum-generic-filter
                *ngIf="!isMobile()"
                [isFilterOpen]="isFilterOpen"
                [rules]="filterRules"
                [totalItems]="totalItems"
                (filterChange)="onFilterChange($event)"
                (closeMobileFilterOverlay)="onFilterOverlayClose()"
            >
            </ruum-generic-filter>

            <ruum-task-list-customize-columns
                *ngIf="!isMobile()"
                [hiddenColumns]="hiddenColumns"
            ></ruum-task-list-customize-columns>

            <div class="d-flex flex-fill justify-content-end" *ngIf="!isMobile()">
                <ruum-task-list-new-item [isReadOnly]="isReadOnly" (addTask)="addTask.emit($event)">
                </ruum-task-list-new-item>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListFiltersComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-column flex-fill';
    @Input() projectId: string;
    @Input() groupId: string;
    @Input() isReadOnly = false;
    @Input() hiddenColumns: string[] = [];
    @Input() filterRules: SavedViewFilterValue[] = [];
    @Input() totalItems: number;
    @Input() workspaceId: string;
    @Output() searchChanged = new EventEmitter<string>();
    @Output() addTask = new EventEmitter();

    searchValue = '';
    isFilterOpen = false;
    isMobile = isMobile;

    constructor(private selectedSavedViewService: SelectedSavedViewService) {}

    ngOnInit() {}

    onFilterChange(filterRules: SavedViewFilterRules) {
        this.selectedSavedViewService.updateFilters(filterRules.rules);
    }

    search(value: string): void {
        this.searchChanged.emit(value);
    }

    onFilterOverlayClose() {
        if (this.isFilterOpen) {
            this.isFilterOpen = false;
        }
    }
}
