import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { User } from '@ruum/ruum-reducers';
import { RuumTask } from '../../../ruum/ruum.model';

@Component({
    selector: 'ruum-task-details-subtask-list',
    template: `
        <div class="d-flex flex-fill flex-column py-4 border-bottom border-light">
            <!-- Add subtask input -->
            <div class="input-group px-4" *ngIf="!isReadOnly">
                <div class="input-group-prepend" ruumInputHighlight>
                    <span class="input-group-text">
                        <i class="icon icon-subtask"></i>
                    </span>
                </div>
                <input
                    class="form-control form-control-sm"
                    id="subtask"
                    name="subtask"
                    type="text"
                    placeholder="Add Subtasks"
                    aria-label="Enter Sub Task description"
                    ruumInputHighlight
                    (keydown.enter)="onAddSubtask($event)"
                    [(ngModel)]="newTaskDescription"
                />
            </div>
            <!-- Subtask list -->
            <div class="d-flex flex-fill flex-column" [class.mt-3]="tasks.length">
                <div
                    [cdkDropListDisabled]="isReadOnly"
                    cdkDropList
                    (cdkDropListDropped)="dropSubtask($event)"
                    class="flex-fill px-4"
                >
                    <ruum-draggable-item
                        *ngFor="let subtask of tasks; let last = last"
                        cdkDrag
                        (cdkDragStarted)="dragStart($event)"
                        (cdkDragEnded)="dragEnd($event)"
                        [cdkDragData]="subtask"
                        class="flex-fill"
                        [hideButton]="true"
                        [hideBorder]="true"
                        [isReadOnly]="isReadOnly"
                    >
                        <ruum-task-details-subtask-item
                            [id]="subtask.id"
                            [isMilestone]="subtask.isMilestone"
                            [name]="subtask.description"
                            [status]="subtask.status"
                            [memberIds]="subtask.assignees"
                            [roleIds]="subtask.assignedFunctionalRoles"
                            [startDate]="subtask.startDate"
                            [dueDate]="subtask.dueDate"
                            [priority]="subtask.priority"
                            [children]="subtask.children"
                            [comments]="subtask.comments"
                            [projectParticipants]="projectParticipants"
                            [last]="last"
                            (changeStatus)="onChangeStatus(subtask.id, $event)"
                            (showSubtaskDetails)="onShowSubtaskDetails($event)"
                        >
                            <button
                                cdkDragHandle
                                class="btn btn-xs btn-icon btn-link-light minw-0 my-1 pl-1 pr-0 hov-child cursor-grabbing"
                            >
                                <i class="icon icon-drag"></i>
                            </button>
                        </ruum-task-details-subtask-item>
                    </ruum-draggable-item>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .input-group,
            .form-control-sm {
                max-height: 32px;
            }
            .input-group-text {
                padding: 5px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumTaskDetailsSubtaskListComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill flex-column';

    @Input() taskParentId: string;

    @Input() taskId: string;

    @Input() tasks: RuumTask[];

    @Input() projectParticipants: User[];

    @Input() isReadOnly = false;

    @Output() addSubtask = new EventEmitter<string>();

    @Output() changeSubtaskOrder = new EventEmitter<string[]>();

    @Output() changeSubtaskStatus = new EventEmitter();

    @Output() showSubtaskDetails = new EventEmitter();

    newTaskDescription = '';

    getDraggableListName(): string {
        return `task-details-${this.taskId}`;
    }

    onAddSubtask(event) {
        if (this.newTaskDescription.trim() !== '') {
            this.addSubtask.emit(this.newTaskDescription);
            this.newTaskDescription = '';
        }
    }

    dropSubtask(event: CdkDragDrop<RuumTask>) {
        const newTaskList: any = [...this.tasks];
        // extract ids of all tasks
        const taskIds = newTaskList.map(({ id }) => id);

        // swap the order of tasks
        moveItemInArray(taskIds, event.previousIndex, event.currentIndex);
        this.changeSubtaskOrder.emit(taskIds);
    }

    onChangeStatus(taskId, taskStatus) {
        this.changeSubtaskStatus.emit({ taskId, taskStatus });
    }

    onShowSubtaskDetails(event) {
        this.showSubtaskDetails.emit(event);
    }

    dragStart(event: CdkDragStart<any>) {
        // patch document because prosemirror is otherwise throw an error
        (document as any).classList = event.source.element.nativeElement.classList;
    }

    dragEnd() {
        // undo the document patch
        delete (document as any).classList;
    }
}
