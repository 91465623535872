import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmDialogData {
    title: string;
    message: string;
    acceptText?: string;
    rejectText?: string;
}
@Component({
    selector: 'generic-confirm-dialog',
    template: `
        <div class="modal-header">
            <h1 class="modal-title">{{ config.title }}</h1>
        </div>
        <div class="modal-body">
            {{ config.message }}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="reject()">
                {{ config.rejectText ? config.rejectText : 'No' }}
            </button>
            <button type="button" class="btn btn-danger" (click)="accept()">
                {{ config.acceptText ? config.acceptText : 'Yes' }}
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericConfirmationDialogComponent {
    @Input() config: ConfirmDialogData;

    constructor(private activeModal: NgbActiveModal) {}

    accept() {
        this.activeModal.close(true);
    }
    reject() {
        this.activeModal.close(false);
    }
}
