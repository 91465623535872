import { Injectable } from '@angular/core';
import {
    GroupMembersProjectPermission,
    ProjectParticipantRole,
    WorkspaceMembersProjectPermission,
} from '@ruum/ruum-reducers';
import { RuumDropdownItem, RuumDropdownList } from '../../app/shared/ui-components/dropdown/dropdown.model';

export const PROJECT_ADMIN: ProjectParticipantRole = 'ProjectAdmin';
export const PROJECT_EDITOR: ProjectParticipantRole = 'ProjectEditor';
export const PROJECT_VIEWER: ProjectParticipantRole = 'ProjectViewer';

export const GROUP_MEMBERS_PRPOJECT_ACCESS_PERMISSION: GroupMembersProjectPermission = 'access';
export const GROUP_MEMBERS_PRPOJECT_EDIT_PERMISSION: GroupMembersProjectPermission = 'edit';
export const GROUP_MEMBERS_PRPOJECT_VIEW_PERMISSION: GroupMembersProjectPermission = 'view';
export const GROUP_MEMBERS_PRPOJECT_NO_ACCESS_PERMISSION: GroupMembersProjectPermission = 'no_access';

export const WORKSPACE_MEMBERS_PRPOJECT_ACCESS_PERMISSION: WorkspaceMembersProjectPermission = 'access';
export const WORKSPACE_MEMBERS_PRPOJECT_EDIT_PERMISSION: WorkspaceMembersProjectPermission = 'edit';
export const WORKSPACE_MEMBERS_PRPOJECT_VIEW_PERMISSION: WorkspaceMembersProjectPermission = 'view';
export const WORKSPACE_MEMBERS_PRPOJECT_NO_ACCESS_PERMISSION: WorkspaceMembersProjectPermission = 'no_access';

@Injectable({ providedIn: 'root' })
export class ProjectRolesService {
    private readonly groupPermissionEntities = {
        [GROUP_MEMBERS_PRPOJECT_ACCESS_PERMISSION]: {
            id: GROUP_MEMBERS_PRPOJECT_ACCESS_PERMISSION,
            name: 'Can Access',
            description: 'Access rights defined by role in Group',
        },
        [GROUP_MEMBERS_PRPOJECT_EDIT_PERMISSION]: {
            id: GROUP_MEMBERS_PRPOJECT_EDIT_PERMISSION,
            name: 'Can Edit',
            description: 'All Group Members can Edit, Comment & Invite others',
        },
        [GROUP_MEMBERS_PRPOJECT_VIEW_PERMISSION]: {
            id: GROUP_MEMBERS_PRPOJECT_VIEW_PERMISSION,
            name: 'Can View',
            description: 'All Group Members can View Content & Add Comments',
        },
        [GROUP_MEMBERS_PRPOJECT_NO_ACCESS_PERMISSION]: {
            id: GROUP_MEMBERS_PRPOJECT_NO_ACCESS_PERMISSION,
            name: 'Remove Group Access',
            description: '',
        },
    };

    private readonly workspacePermissionEntities = {
        [WORKSPACE_MEMBERS_PRPOJECT_ACCESS_PERMISSION]: {
            id: WORKSPACE_MEMBERS_PRPOJECT_ACCESS_PERMISSION,
            name: 'Can Access',
            description: 'Access rights defined by role in Workspace',
        },
        [WORKSPACE_MEMBERS_PRPOJECT_EDIT_PERMISSION]: {
            id: WORKSPACE_MEMBERS_PRPOJECT_EDIT_PERMISSION,
            name: 'Can Edit',
            description: 'All Workspace Members can Edit, Comment & Invite others',
        },
        [WORKSPACE_MEMBERS_PRPOJECT_VIEW_PERMISSION]: {
            id: WORKSPACE_MEMBERS_PRPOJECT_VIEW_PERMISSION,
            name: 'Can View',
            description: 'All Workspace Members can View Content & Add Comments',
        },
        [WORKSPACE_MEMBERS_PRPOJECT_NO_ACCESS_PERMISSION]: {
            id: WORKSPACE_MEMBERS_PRPOJECT_NO_ACCESS_PERMISSION,
            name: 'Remove Workspace Access',
            description: '',
        },
    };

    getPermisionRoles(): RuumDropdownList {
        return [
            { id: PROJECT_ADMIN, name: 'Admin', description: 'Full access' },
            { id: PROJECT_EDITOR, name: 'Editor', description: 'Can Edit, Comment & Invite others' },
            { id: PROJECT_VIEWER, name: 'Viewer', description: 'Can View & Add Comments' },
        ];
    }

    /**
     * The permission roles the logged user is allowed to give
     */
    getRolesByParticipantRole(participantRole: ProjectParticipantRole): RuumDropdownList {
        return this.getPermisionRoles().map((role) => {
            return {
                ...role,
                disabled: this.isRoleDisabled(role, participantRole),
            };
        });
    }

    getGroupPermissionById(id: GroupMembersProjectPermission) {
        return this.groupPermissionEntities[id];
    }

    getWorkspacePermissionById(id: WorkspaceMembersProjectPermission) {
        return this.workspacePermissionEntities[id];
    }

    getRoleExceptionMessage(
        projectRole: ProjectParticipantRole,
        groupRole: ProjectParticipantRole,
        workspaceRole: ProjectParticipantRole,
    ) {
        if (this.getRoleWeight(workspaceRole) > this.getRoleWeight(projectRole)) {
            const permissionsName = this.getPermisionsName(workspaceRole);
            return `This person can still access as they have ${permissionsName} permissions on workspace level`;
        }

        if (this.getRoleWeight(groupRole) > this.getRoleWeight(projectRole)) {
            const permissionsName = this.getPermisionsName(groupRole);
            return `This person can still access as they have ${permissionsName} permissions on group level`;
        }
        return '';
    }

    private getRoleWeight(role: ProjectParticipantRole): number {
        const roleWeightMap = {
            [PROJECT_ADMIN]: 3,
            [PROJECT_EDITOR]: 2,
            [PROJECT_VIEWER]: 1,
        };

        return roleWeightMap[role] || 0;
    }

    private getPermisionsName(role: ProjectParticipantRole): string {
        const rolePermisionsMap = {
            [PROJECT_ADMIN]: 'admin',
            [PROJECT_EDITOR]: 'edit',
            [PROJECT_VIEWER]: 'view',
        };

        return rolePermisionsMap[role] || '';
    }

    private isRoleDisabled(role: RuumDropdownItem, participantRole: ProjectParticipantRole): boolean {
        if (role.id === PROJECT_ADMIN) {
            return !(participantRole === PROJECT_ADMIN);
        }

        if (role.id === PROJECT_EDITOR) {
            return !(participantRole === PROJECT_ADMIN || participantRole === PROJECT_EDITOR);
        }

        if (role.id === PROJECT_VIEWER) {
            return !(participantRole === PROJECT_ADMIN || participantRole === PROJECT_EDITOR);
        }

        return false;
    }
}
