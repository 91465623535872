import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ODataEnterpriseOAuthClient } from '../../common/odata/odata.model';
import { BackendConnector } from '../common/backendConnector.service';
import { BaseFormComponent } from '../common/components/form.component';
import { CustomValidator } from '../common/CustomValidator';
import { EnterpriseSettingsService } from '../common/enterpriseSettings.service';
import { ModalService } from '../common/modal/modal.service';

@Component({
    selector: 'create-oauth-client',
    template: `
        <ruum-admin-widget class="page">
            <div class="header">
                <div class="navbar navbar-light navbar-expand-lg">
                    <button class="btn text-primary btn-sm" type="button" (click)="back()">
                        <i class="icon icon-back"></i>
                        Back
                    </button>

                    <h3 class="ml-3 navbar-text">New OAuth Client</h3>
                </div>
            </div>

            <div class="body">
                <form class="mt-5 text-sm text-right" [formGroup]="form">
                    <div class="form-group row" [style.display]="form.controls.clientId.value ? '' : 'none'">
                        <label for="clientId" class="col-sm-3 col-form-label col-form-label-sm">Client Id</label>
                        <div class="col-sm-7">
                            <input
                                class="form-control form-control-sm"
                                placeholder="Client Id"
                                name="clientId"
                                autofocus
                                formControlName="clientId"
                            />
                        </div>
                    </div>
                    <div class="form-group row required">
                        <label for="appName" class="col-sm-3 col-form-label col-form-label-sm">App Name</label>
                        <div class="col-sm-7">
                            <input
                                class="form-control form-control-sm"
                                name="appName"
                                placeholder="Name of the Application"
                                formControlName="appName"
                            />
                        </div>
                    </div>
                    <div class="form-group row required">
                        <label for="appDescription" class="col-sm-3 col-form-label col-form-label-sm"
                            >App Description</label
                        >
                        <div class="col-sm-7">
                            <input
                                class="form-control form-control-sm"
                                name="appDescription"
                                placeholder="Description of the Application"
                                formControlName="appDescription"
                            />
                        </div>
                    </div>

                    <div class="form-group row required">
                        <label class="col-sm-3 col-form-label col-form-label-sm" for="redirectUrl">Redirect URL</label>
                        <div class="col-sm-7" name="redirectUrls" formArrayName="redirectUrls">
                            <div
                                class="input-group input-group-sm mb-2"
                                *ngFor="let redirectUrl of redirectUrls.controls; let i = index"
                            >
                                <input
                                    id="redirectUrl"
                                    class="form-control form-control-sm"
                                    (keyup.enter)="addRedirectUrl()"
                                    placeholder="Redirect URL"
                                    autofocus
                                    [formControlName]="i"
                                />
                                <div class="input-group-append">
                                    <button
                                        type="button"
                                        class="btn btn-outline-secondary"
                                        title="Remove domain"
                                        (click)="removeRedirectUrl(i)"
                                    >
                                        <i class="icon icon-delete"></i>
                                    </button>
                                </div>
                            </div>

                            <button class="btn btn-primary mt-2" (click)="addRedirectUrl()">Add Redirect Url</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="footer">
                <button [disabled]="form.invalid" class="btn btn-primary float-right" type="button" (click)="save()">
                    Save
                </button>
                <button class="btn btn-outline-secondary float-left" type="button" (click)="back()">
                    Cancel
                </button>
            </div>
        </ruum-admin-widget>
    `,
})
export class CreateOauthClientComponent extends BaseFormComponent {
    enterpriseOAuthClient$: Observable<ODataEnterpriseOAuthClient> = new Observable<ODataEnterpriseOAuthClient>(
        undefined,
    );

    constructor(
        private backendConnector: BackendConnector,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private enterpriseSettingsService: EnterpriseSettingsService,
        private toastrService: ToastrService,
        private fb: FormBuilder,
    ) {
        super();

        this.form = this.fb.group({
            clientId: [{ value: '', disabled: true }],
            appName: ['', [Validators.required, Validators.minLength(3)]],
            appDescription: ['', Validators.minLength(3)],
            redirectUrls: this.fb.array([this.fb.control('', [Validators.required, CustomValidator.uriValidator])]),
        });

        this.route.params.subscribe((routeParams) => {
            const oauthClientKey = routeParams.clientKey;
            if (!oauthClientKey || !oauthClientKey.length) {
                return;
            }
            this.enterpriseOAuthClient$ = this.enterpriseSettingsService.getOauthClients().pipe(
                map((info: any) => {
                    return info.find((data) => data.clientId === oauthClientKey);
                }),
            );
            this.enterpriseOAuthClient$.subscribe((loadedConfig) => {
                this.form.patchValue(loadedConfig);
            });
        });
    }

    get redirectUrls() {
        return this.form.get('redirectUrls') as FormArray;
    }

    addRedirectUrl() {
        this.redirectUrls.push(this.fb.control(''));
    }

    removeRedirectUrl(index) {
        this.redirectUrls.removeAt(index);
    }

    save() {
        const enterpriseOAuthClient = this.form.getRawValue() as ODataEnterpriseOAuthClient;
        enterpriseOAuthClient.redirectUrls = enterpriseOAuthClient.redirectUrls.filter((url) => url !== '');
        this.enterpriseSettingsService
            .updateOrCreateOauthClient(enterpriseOAuthClient)
            .then((res) => {
                this.toastrService.success('Configuration Saved!');
                this.router.navigate(['../'], { relativeTo: this.route });
            })
            .catch((err) => {
                this.modalService.openErrorDialog(err.errors);
                this.resetForm();
            });
    }

    resetForm() {
        if (this.enterpriseOAuthClient$ !== undefined) {
            this.form.reset(this.enterpriseOAuthClient$);
        } else {
            this.form.reset({});
        }
    }

    autoCompleteFormData(enterpriseId): ODataEnterpriseOAuthClient {
        const formData = this.form.getRawValue();
        const mktCloudUrl = formData.mktCloudUrl;

        return {
            enterpriseId,
            ...formData,
            description: formData.description,
            issuer: 'ruum',
            clientId: formData.techUserId,
            recipient: `${mktCloudUrl}/sap/bc/sec/oauth2/token`,
            audience: mktCloudUrl,
            tokenUrl: `${mktCloudUrl}/sap/bc/sec/oauth2/token`,
            marketingSystemDomain: mktCloudUrl,
            hardcodedEmail: 'john.s4@gmx.de',
            scope: 'API_MKT_CAMPAIGN_SRV_0002 API_MKT_CAMPAIGN_MESSAGE_SRV_0001',
        };
    }
}
