import { Injectable } from '@angular/core';
import { randomStringOfSize } from './../utils.service';

@Injectable({ providedIn: 'root' })
export class RandomStringGenerator {
    next(characters: number) {
        if (characters > 20) {
            characters = 20;
        }
        return randomStringOfSize(characters);
    }
}
