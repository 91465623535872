import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ruum-suggest-publish-template',
    template: `
        <ruum-modal-dialog>
            <h2 class="mt-4 mb-5 text-truncate">Publish template?</h2>

            <div class="d-flex mb-5">
                <span class="text-black">To create Ruums from this template, you need to publish it first.</span>
            </div>
            <div class="d-flex  mb-5">
                <span class="text-black"
                    >If the template is a part of a workspace, you will share it with all team members of the selected
                    workspace. Workspace admins will be able to edit the template, other members will only be able to
                    create new ruums.</span
                >
            </div>

            <div class="d-flex flex-fill">
                <div class="d-flex flex-fill align-items-center">
                    <ruum-show-again-checkbox [dialogId]="'SuggestPublishTemplateComponent'"></ruum-show-again-checkbox>
                </div>
            </div>

            <div class="d-flex flex-fill justify-content-end mt-6">
                <div class="d-flex">
                    <button
                        class="btn btn-outline-secondary mr-2"
                        #firstFocus
                        type="button"
                        (click)="closeDialog(false)"
                    >
                        Not now
                    </button>

                    <button class="btn btn-accent" type="button" (click)="closeDialog(true)">
                        Publish
                    </button>
                </div>
            </div>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestPublishTemplateComponent implements AfterViewInit {
    @HostBinding('class') hostClassList = 'd-block bg-accent-light rounded';
    @ViewChild('firstFocus', { static: false }) private firstFocusElementRef: ElementRef;

    constructor(private activeModal: NgbActiveModal) {}

    ngAfterViewInit(): void {
        this.firstFocusElementRef.nativeElement.focus();
    }

    closeDialog(publish: boolean) {
        this.activeModal.close(publish);
    }
}
