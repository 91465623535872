import { ExecutedTransition, iterateAllProcessSteps, Process, ProcessStep } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getStepTransitionObject } from '../../../../templates/project-template/template-settings/getStepTransitionObject';
import { TransitionObject } from './process.model';
import { ProcessService } from './process.service';

export function transitionedToText(processService: ProcessService, object: TransitionObject): Observable<string> {
    return combineLatest([processService.rootProcess(), processService.getTransitionObjects()]).pipe(
        map(([process, transitionObjects]) => {
            if (!process) {
                return;
            }
            const step = getTransitionObjectStep(process, object);
            if (!step) {
                return;
            }
            const executedTransition = getLastExecutedTransition(process.history, step.id);
            if (!executedTransition) {
                return;
            }
            const toStep = process.steps[executedTransition.toStepId];
            const toObject = getStepTransitionObject(toStep);
            const toObjectWithTitle = transitionObjects.find((obj) => obj.id === toObject.id);
            return toObjectWithTitle.title;
        }),
    );
}

function getTransitionObjectStep(process: Process, object: TransitionObject): ProcessStep {
    return iterateAllProcessSteps({ [process.id]: process }, (step) => {
        if (step.type === object.type) {
            return (
                (step.type === 'approval' && step.approvalId === object.id) ||
                (step.type === 'canvas' && step.sectionId === object.id) ||
                (step.type === 'system_connector' && step.systemConnectorConfigurationId === object.id)
            );
        }
    });
}

function getLastExecutedTransition(history: ExecutedTransition[] = [], stepId: string): ExecutedTransition {
    for (let i = history.length - 1; i >= 0; i--) {
        const entry = history[i];
        if (entry.fromStepId === stepId) {
            return entry;
        }
    }
}
