import { Pipe, PipeTransform } from '@angular/core';

/**
 * keep in mind that "😀".length is 2
 */
const EMOJI_LENGTH = 2;

/**
 * This pipe transforms 1-word, 2-words and 3-words name to initials.
 * Examples:
 *   transform('Designer', 0) => ''
 *   transform('Designer', 1) => 'D'
 *   transform('Designer', 2) => 'De'
 *   transform('Designer', 3) => 'Des'
 *   transform('Product Manager', 1) => 'P'
 *   transform('Product Manager', 2) => 'PM'
 *   transform('Product Manager', 3) => 'PM'
 *   transform('Ruumie The First', 1) => 'R'
 *   transform('Ruumie The First', 2) => 'RF'
 *   transform('Ruumie The First', 3) => 'RTF'
 */
@Pipe({ name: 'ruumProfileInitials' })
export class ProfileInitialsPipe implements PipeTransform {
    transform(name, maxInitials: number = 2): string {
        if (!maxInitials) {
            return '';
        }
        if (name) {
            const startsWithEmoji = /^\p{Emoji}/u.test(name);
            if (startsWithEmoji) {
                // this is a simple solution and will not fit for all combinations
                const calculatedMaxInitials = Math.max(EMOJI_LENGTH, maxInitials + 1);
                return name.substring(0, calculatedMaxInitials);
            }

            const fullNameArray = name.split(' ');

            if (fullNameArray.length === 1) {
                return fullNameArray[0].substring(0, maxInitials);
            }

            const initials = fullNameArray.map((el) => el[0]);

            if (maxInitials === 2) {
                const joinedInitials = initials.join('');
                return (joinedInitials.charAt(0) + joinedInitials.charAt(joinedInitials.length - 1)).toUpperCase();
            }

            return initials
                .slice(0, maxInitials)
                .join('')
                .toUpperCase();
        }
    }
}
