import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActionToken, SidePanelDisplayNode } from '../sidenav.service';

@Component({
    selector: 'ruum-side-panel-node-item',
    template: `
        <div
            class="node-item px-3 py-md-1 flex-fill align-items-center hov"
            [class.generic-highlight]="genericHighlight"
            (click)="genericHighlight && activateNode.emit()"
            (keydown.space)="genericHighlight && activateNode.emit()"
            (keydown.enter)="genericHighlight && activateNode.emit()"
            (mouseover)="actionContextActive = false"
            (focus)="actionContextActive = false"
            [attr.data-help-id]="addEnableNowSelector ? label : null"
        >
            <button
                *ngIf="isDraggable"
                class="btn btn-xs btn-icon btn-link-white-64 drag_handle_left draggable hov-child"
                (click)="drag.emit()"
                (mouseover)="genericHighlight = false"
                (focus)="genericHighlight = false"
                (mouseout)="genericHighlight = true"
                (blur)="genericHighlight = true"
            >
                <i class="icon icon-drag"></i>
            </button>

            <div *ngIf="!isDraggable" class="btn btn-xs btn-icon btn-link-white-64 placeholder">
                <i class="icon"></i>
            </div>

            <div
                *ngIf="isExpandable"
                class="btn btn-xs btn-icon btn-link-white-64"
                (click)="toggleSubnodes.emit()"
                (keydown.space)="toggleSubnodes.emit()"
                (keydown.enter)="toggleSubnodes.emit()"
                (mouseover)="genericHighlight = false"
                (focus)="genericHighlight = false"
                (mouseout)="genericHighlight = true"
                (blur)="genericHighlight = true"
            >
                <i class="icon" [class.icon-cevron-down]="isExpanded" [class.icon-cevron-right]="!isExpanded"></i>
            </div>

            <div *ngIf="iconName" class="btn btn-xs btn-icon btn-link-white-64">
                <i class="icon" [ngClass]="getTypeIconClass()"></i>
            </div>

            <div *ngIf="!iconName" class="btn btn-xs btn-icon btn-white-64 btn-without-hover mr-1 border-0">
                {{ label | slice: 0:1 }}
            </div>

            <div
                [attr.title]="tooltip || label"
                class="flex-fill text-small text-truncate py-3 py-md-1 ie-truncate-fix"
                [class.small]="!increaseFontSize"
                (click)="activateNode.emit()"
                (keydown.space)="activateNode.emit()"
                (keydown.enter)="activateNode.emit()"
            >
                {{ label }}
            </div>

            <ruum-canvas-section-context-menu
                [sectionId]="sectionId"
                (isOpenChange)="contextMenuIsOpen()"
                [parentInfo]="'navBar'"
            ></ruum-canvas-section-context-menu>
        </div>
    `,
    styles: [
        `
            .node-item {
                .draggable:hover {
                    cursor: grab;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                }

                &:hover {
                    background: #507ca8;
                    color: white;
                    cursor: pointer;
                }

                .node-item {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    .label {
                        overflow: hidden;
                        .ellipsis {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

                :host-context(.gu-mirror) {
                    .icon-drag:before {
                        content: 'T';
                    }
                    .icon-drag {
                        visibility: visible;
                    }
                    opacity: 0.8;
                    background-color: #3cc7da;
                    cursor: move;
                    cursor: grabbing;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                }

                :host-context(.gu-transit) {
                    .icon-drag:before {
                        content: 'T';
                    }
                    .icon-drag {
                        visibility: visible;
                    }
                    cursor: move;
                    cursor: grabbing;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                }
            }

            .node-item:hover {
                &.generic-highlight {
                    .label {
                        color: white;
                    }
                }

                :host-context(.dragging) .node-item {
                    cursor: grab;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavPanelNodeItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'node label';
    @Input() isDraggable;
    @Input() isExpandable;
    @Input() isExpanded;
    @Input() iconName;
    @Input() label;
    @Input() tooltip?;
    @Input() hasContext;
    @Input() isUpdated;
    @Input() actionContextActive;
    @Input() increaseFontSize: false;
    @Input() addEnableNowSelector: false;
    @Input() sectionId;

    @Output() dispatchAction = new EventEmitter();
    @Output() activateNode = new EventEmitter();
    @Output() toggleSubnodes = new EventEmitter();
    @Output() toggleContext = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() drag = new EventEmitter<DragEvent>();
    @Output() nodeHoverSubAction = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onChangeOption = new EventEmitter();

    actionTokens = ActionToken;
    genericHighlight = true;

    constructor(private router: Router, private cdr: ChangeDetectorRef) {}

    static hasAction(node: SidePanelDisplayNode, thisAction: ActionToken) {
        return node.actions && node.actions.has(thisAction);
    }

    getTypeIconClass() {
        return this.iconName ? 'icon-' + this.iconName : '';
    }

    routerNavigate(path) {
        this.router.navigateByUrl(path);
    }

    ngOnInit() {}

    contextMenuIsOpen() {
        this.cdr.detectChanges();
    }
}
