import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { routeAnimations } from './app.animation';
import { AuthService } from './auth/auth.service';
import { CommonService } from './common/common.service';
import { SidenavService } from './common/sidenav/sidenav.service';
import { isMobile } from './common/utils.service';
@Component({
    selector: 'app-root',
    template: `
        <div
            class="d-flex flex-fill notranslate flex-column"
            [class.global-nav-panel-open]="isMainNavPanelOpen"
            [@routeAnimations]="prepareRoute(outlet)"
        >
            <busy-state></busy-state>
            <div *ngIf="showSpashScreen" class="spash"></div>
            <!-- Main Nav Panel -->
            <nav
                role="navigation"
                aria-label="Side"
                class="d-flex ruum-main-nav-panel-outlet d-print-none"
                [class.open]="isMainNavPanelOpen"
                *ngIf="getIsMainNavPanelVisible() && !showSpashScreen"
            >
                <ruum-sidenav>
                    <ng-content
                        data-content="project-main-nav-segment"
                        select="[data-content='project-main-nav-panel']"
                    >
                    </ng-content>
                </ruum-sidenav>
            </nav>
            <!-- Navbar -->
            <div
                (click)="closeGlobalNavPanel($event)"
                class="d-flex flex-column flex-fill"
                (keydown.space)="closeGlobalNavPanel($event)"
                (keydown.enter)="closeGlobalNavPanel($event)"
            >
                <router-outlet #outlet="outlet" (activate)="hideSpashScreen()"></router-outlet>
            </div>
            <ng-container *ngIf="isTrialStatusbarEnabled">
                <ruum-trial-statusbar></ruum-trial-statusbar>
            </ng-container>
            <ruum-popup-container></ruum-popup-container>
            <ruum-version-verifier></ruum-version-verifier>
        </div>
    `,
    styles: [
        `
            .spash {
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                background: url(../assets/illustrations/splash-screen.svg) no-repeat center center fixed;
                background-size: 50%;
            }
        `,
    ],
    animations: [routeAnimations],
})
export class AppComponent implements OnInit, OnDestroy {
    // make sure the profile is only loaded once
    profileLoaded = false;
    @Input() withRouting = true;

    private subcriptions = new Subscription();

    isMainNavPanelOpen = false;
    isMainNavPanelVisible = false;
    eventsOnMobileBlocked = false;
    showSpashScreen = true;

    constructor(
        private authService: AuthService,
        private router: Router,
        private commonService: CommonService,
        private modalService: NgbModal,
        private sidenavService: SidenavService,
    ) {
        this.checkBrowser();
    }

    ngOnInit() {
        this.subcriptions.add(this.subscribeRouterEvents());
        this.subcriptions.add(
            this.authService
                .loggedUserId()
                .pipe(map((id) => !!id))
                .subscribe((loggedId) => (this.isMainNavPanelVisible = loggedId)),
        );
        this.subcriptions.add(
            this.sidenavService.isMainNavPanelOpen$.subscribe((state) => {
                this.isMainNavPanelOpen = state;
                this.eventsOnMobileBlocked = this.isMainNavPanelOpen && isMobile();
            }),
        );
    }

    getIsMainNavPanelVisible(): boolean {
        return this.isMainNavPanelVisible;
    }

    hideSpashScreen() {
        this.showSpashScreen = false;
    }

    ngOnDestroy() {
        this.subcriptions.unsubscribe();
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }

    closeGlobalNavPanel($event) {
        if (this.eventsOnMobileBlocked) {
            $event.stopPropagation();
            this.sidenavService.onMainNavPanelDeactivate();
        }
    }

    get isTrialStatusbarEnabled(): boolean {
        return environment.PAYWALL_TRIAL_STATUSBAR_ENABLED;
    }

    private subscribeRouterEvents(): Subscription {
        return this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.modalService.hasOpenModals()) {
                    this.modalService.dismissAll();
                }
            }
        });
    }

    private checkBrowser() {
        let isIE = false;
        const ua = window.navigator.userAgent;
        const msie: boolean = ua.indexOf('MSIE ') !== -1;
        const trident: boolean = ua.indexOf('Trident/') !== -1;
        const edge: boolean = ua.indexOf('Edge/') !== -1;
        if (msie || trident) {
            isIE = true;
        }
        const isEdge: boolean = edge;
        this.commonService.setIEOrEdge(isIE, isEdge);
    }
}
