import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isMobile } from '../../../common/utils.service';
import { NavbarItem } from '../navbar.model';

@Component({
    selector: 'ruum-secondary-navbar-bottom',
    template: `
        <nav
            id="ruum-secondary-bottom-navbar"
            role="navigation"
            aria-label="Secondary"
            class="navbar navbar-light navbar-expand bg-white d-flex p-0"
        >
            <div class="container-fluid px-0">
                <div class="collapse navbar-collapse d-flex flex-fill">
                    <ul class="navbar-nav d-flex justify-content-center flex-fill">
                        <navbar-tab-bottom
                            *ngFor="let tab of sanitizedTabs"
                            [ngStyle]="{ width: 100 / allTabs.length + '%' }"
                            [id]="tab.id"
                            [name]="tab.name"
                            [icon]="tab.icon"
                            [active]="tab.id === activeTab.id"
                            (click)="clickTab.emit(tab)"
                        >
                        </navbar-tab-bottom>

                        <navbar-tab-bottom
                            *ngFor="let button of buttons"
                            [ngStyle]="{ width: 100 / allTabs.length + '%' }"
                            [id]="button.id"
                            [name]="button.name"
                            [icon]="button.icon"
                            [active]="activeButton && activeButton.id === button.id"
                            (click)="clickButton.emit(button)"
                        >
                        </navbar-tab-bottom>
                    </ul>
                </div>
            </div>
        </nav>
    `,
})
export class SecondaryNavbarBottomComponent {
    @Input()
    set tabs(items: NavbarItem[]) {
        // TODO: hide 'timeline','kanban' & 'custom-fields'tabs on mobile
        // as it is not fully supported for mobile display
        this.sanitizedTabs = isMobile()
            ? items.filter((item) => this.exemptionTabListIdsOnMobile.indexOf(item.id) === -1)
            : [...items];
    }

    @Input()
    activeTab: NavbarItem;

    @Input()
    buttons: NavbarItem[] = [];

    @Input()
    activeButton: NavbarItem;

    @Output()
    clickTab = new EventEmitter<NavbarItem>();

    @Output()
    clickButton = new EventEmitter<NavbarItem>();

    // TODO: except following tabs from navbar on mobile display
    // as it is not fully supported for mobile display
    private exemptionTabListIdsOnMobile = ['timeline', 'kanban', 'custom-fields'];

    sanitizedTabs: NavbarItem[] = [];

    get allTabs() {
        return [...this.sanitizedTabs, this.buttons];
    }
}
