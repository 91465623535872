import { Directive, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectedEnterpriseService } from '../../../common/connectors/enterprise/selected-enterprise.service';
import { OrderedListParams, SortDirection } from '../../../common/connectors/paginatedList.model';
import { GenericDataSource } from '../../../common/connectors/paginatedListLoader';
import { ProfileService } from '../../../common/connectors/profile/profile.service';
import { ODataPaginatedListLoader } from '../../../common/odata/ODataPaginatedListLoader';
import { accumulateFilterQuery } from '../../../common/odata/ODataUtils';
import { EVENT_CATEGORY, TrackingConnector } from '../../../common/trackingConnector.service';

@Directive()
export class ODataBaseListViewComponentDirective implements OnInit {
    rows$: Observable<any[]> = of([]);
    isBusy$: Observable<boolean> = of(false);

    // list properties
    sort: OrderedListParams<string>;
    dataSource: GenericDataSource<any>;
    // search
    search: string;

    constructor(
        protected listService: ODataPaginatedListLoader<any>,
        protected profileService: ProfileService,
        protected selectedEnterpriseService: SelectedEnterpriseService,
        private trackingConnector: TrackingConnector,
        protected displayedColumns: string[] = [],
        protected filterableColumns: string[] = [],
    ) {}

    ngOnInit() {
        this.search = undefined;
        this.listService.query({});
        this.listService.loadList();
        this.rows$ = this.listService.getStoreRows$();
        this.isBusy$ = this.listService.showLoading$;

        this.dataSource = this.listService.getDataSource();
    }

    scrolledToBottom() {
        this.listService.maybeGoToNextPage();
    }

    getSortDirection(sortBy: string): SortDirection {
        if (this.sort && this.sort.by === sortBy) {
            return this.sort.direction;
        }
        return 'asc';
    }

    isSorted(sortBy: string): boolean {
        if (this.sort && this.sort.by === sortBy) {
            return true;
        }
        return false;
    }

    onSortChange(sortChange: OrderedListParams<string>) {
        this.sort = sortChange;

        this.listService.query({
            orderby: `${sortChange.by} ${sortChange.direction}`,
        });

        this.trackingConnector.trackEvent(
            `admin_user` as EVENT_CATEGORY,
            `sort_${sortChange.by || 'name'}_${sortChange.direction || 'asc'}`,
            'selected',
            sortChange.by || 'name',
        );
    }

    onChangeSearchValue(searchValue: string): void {
        this.search = searchValue;

        this.listService.query({
            filter: accumulateFilterQuery(this.filterableColumns || this.displayedColumns, searchValue, 'or'),
        });
    }
}
