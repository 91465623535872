import { FunctionalRoleListItem } from '../../functionalRoles/functionalRoles.model';

import {
    AddFunctionalRoleToWorkspaceAction,
    EntityAction,
    pluck,
    RuumAction,
    WorkspaceActionsTypes,
} from '@ruum/ruum-reducers';

import { PaginatedList } from '../../paginatedList.model';

import { getDefaultPaginatedList } from '../../../../lobby/lobby.reducer';

export interface FunctionalRoleMap {
    [id: string]: FunctionalRoleListItem;
}

export const FUNCTIONAL_ROLE_LIST_ACTION_TYPES = {
    ADD_OR_REPLACE_FUNCTIONAL_ROLES: 'ADD_OR_REPLACE_FUNCTIONAL_ROLES',
    FUNCTIONAL_ROLES_LOADED: 'FUNCTIONAL_ROLES_LOADED',
};

export type FunctionalRolesLoadedAction = EntityAction<
    'FUNCTIONAL_ROLES_LOADED',
    {
        page: PaginatedList<FunctionalRoleListItem>;
    }
>;

export interface FunctionalRolesMap {
    [fieldId: string]: FunctionalRoleListItem;
}

export function FunctionRolesListReducer(
    currentState: PaginatedList<string> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<string> {
    switch (action.type) {
        case FUNCTIONAL_ROLE_LIST_ACTION_TYPES.FUNCTIONAL_ROLES_LOADED:
            return listLoaded(currentState, action.payload.page);
        case WorkspaceActionsTypes.ADD_FUNCTIONAL_ROLE_TO_WORKSPACE:
            return addRole(currentState, action);
        default:
            return currentState;
    }
}

function listLoaded(
    currentState: PaginatedList<string>,
    data: PaginatedList<FunctionalRoleListItem>,
): PaginatedList<string> {
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows.map((row) => row.id)),
        };
    } else {
        return {
            ...data,
            rows: data.rows.map((row) => row.id),
        };
    }
}

function addRole(
    currentState: PaginatedList<string>,
    action: AddFunctionalRoleToWorkspaceAction,
): PaginatedList<string> {
    return {
        ...currentState,
        rows: currentState.rows.concat(action.payload.functionalRoles.map(pluck('id'))),
    };
}
