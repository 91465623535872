import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AdminNavbarService } from './admin-navbar.service';

@Injectable({ providedIn: 'root' })
export class AdminNavbarGuard implements CanActivateChild {
    constructor(private adminNavbarService: AdminNavbarService, private titleService: Title) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.adminNavbarService.setTitle(route.data.title);
        this.titleService.setTitle(`Admin: ${route.data.title}`);
        return true;
    }
}
