import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectModule } from '../../ui-components/select/select.module';
import { WorkspaceSelectComponent } from './workspace-select.component';

@NgModule({
    imports: [CommonModule, SelectModule],
    declarations: [WorkspaceSelectComponent],
    exports: [WorkspaceSelectComponent],
})
export class WorkspaceSelectModule {}
