import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PaginatedListLoader } from '../../../../common/connectors/paginatedListLoader';
import { StoreLoader } from '../../../../common/connectors/storeLoader.abstract';

@Component({
    selector: 'ruum-select-controller',
    template: `
        <ruum-select
            [size]="size"
            [loading]="loading$ | async"
            [selectTitle]="selectTitle"
            [multiSelect]="multiSelect"
            [disabled]="disabled"
            [select]="select"
            [search]="search"
            (loadMoreOptions)="loadMore()"
            (isOpenChange)="onOpenChange($event)"
            (selectChange)="onSelectChange($event)"
            (searchChange)="onSearchChange($event)"
        >
            <ruum-select-none *ngIf="withUnselect"></ruum-select-none>
            <ruum-select-placeholder *ngIf="placeholder">{{ placeholder }}</ruum-select-placeholder>
            <ruum-select-option
                *ngFor="let item of options; trackBy: trackByOption"
                [value]="item[idField]"
                [content]="item[contentField]"
                [icon]="item[contentIcon]"
                [description]="item[contentDescription]"
            >
            </ruum-select-option>
            <ruum-select-option
                *ngFor="let item of listData$ | async; trackBy: trackByOption"
                [value]="item[idField]"
                [content]="item[contentField]"
                [icon]="item[contentIcon]"
                [description]="item[contentDescription]"
            >
            </ruum-select-option>
            <ruum-select-action-option
                *ngFor="let actionItem of actionOptions"
                (click)="onActionItemClick(actionItem.id)"
                >{{ actionItem.title }}</ruum-select-action-option
            >
        </ruum-select>
    `,
    styles: [],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectControllerComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectControllerComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @HostBinding('class') hostClassList = 'd-block w-100 mw-100';

    @Input() select: string | string[];
    @Input() multiSelect = false;
    @Input() size = 'sm';
    @Input() disabled = false;
    @Input() withUnselect = true;
    @Input() search = true;
    @Input() selectTitle: string;
    @Input() placeholder: string;
    @Input() options: object[] = [];
    @Input() idField = 'id';
    @Input() contentField = 'title';
    @Input() contentIcon = 'icon';
    @Input() contentDescription = 'description';
    @Input() listLoader: PaginatedListLoader<any, any, any> = null;
    @Input() storeLoader: StoreLoader<any> = null;
    @Input() actionOptions: { id: string; title: string }[] = [];
    @Input() forceFocus = false;

    @Output() searchChange = new EventEmitter<string>();
    @Output() isOpenChange = new EventEmitter<boolean>();
    @Output() selectChange = new EventEmitter<string | string[]>();
    @Output() selectDataChange = new EventEmitter<any | any[]>();
    @Output() actionItemClick = new EventEmitter<string>();

    loading$: Observable<boolean> = of(false);
    listData$: Observable<object[]>;

    private onChangeFunction;
    private onTouchedFunction;
    private isAfterViewIinit = false;
    private shouldListLoaderLoaded = false;
    private isInitialSelectLoaded = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.listLoader) {
            this.loading$ = this.listLoader.showLoading$;
            this.listData$ = this.listLoader.getStoreRows$();
            this.shouldListLoaderLoaded = true;
        }
        if (this.select) {
            this.loadStoreData(this.select);
        }
    }

    ngAfterViewInit() {
        this.isAfterViewIinit = true;
    }

    trackByOption = (index: number, item) => {
        return item && item[this.idField];
    };

    writeValue(obj: string | string[]): void {
        this.changeDetectorRef.markForCheck();
        this.select = obj;
        this.loadStoreData(obj);
    }

    registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFunction = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onSelectChange(event: string | string[]) {
        if (!this.isAfterViewIinit) {
            return;
        }
        this.selectChange.emit(event);
        if (this.onChangeFunction) {
            this.onTouchedFunction();
            this.onChangeFunction(event);
        }
        this.emitData(event);
    }

    onOpenChange(event: boolean) {
        if (this.shouldListLoaderLoaded && event) {
            this.shouldListLoaderLoaded = false;
            this.listLoader.loadList();
        }
    }

    onSearchChange(event: string) {
        this.searchChange.emit(event);
    }

    onActionItemClick(id: string) {
        this.actionItemClick.emit(id);
    }

    loadMore() {
        this.listLoader.maybeGoToNextPage();
    }

    private async emitData(id: string | string[]) {
        if (!id) {
            if (this.multiSelect) {
                this.selectDataChange.emit([]);
            } else {
                this.selectDataChange.emit(undefined);
            }
            return;
        }

        const data = await this.getSelectedObjectsByKeys(id);

        this.selectDataChange.emit(data);
    }

    private async getSelectedObjectsByKeys(id: string | string[]): Promise<any | any[]> {
        return combineLatest([this.listData$ || of([]), of(this.options || [])])
            .pipe(
                map((lists) => {
                    const options = lists[0].concat(lists[1]);

                    if (Array.isArray(id)) {
                        return options.find((opt) => id.indexOf(opt[this.idField]) !== -1);
                    } else {
                        return options.find((opt) => opt[this.idField] === id);
                    }
                }),
                take(1),
            )
            .toPromise();
    }

    private loadStoreData(id: string | string[]) {
        if (!this.isInitialSelectLoaded && this.shouldListLoaderLoaded && this.storeLoader && id) {
            this.isInitialSelectLoaded = true;
            let selectedItem$: Observable<object | object[]>;
            if (typeof id === 'string') {
                selectedItem$ = this.storeLoader.data(id);
            } else {
                selectedItem$ = this.storeLoader.dataList(id);
            }
            this.listData$ = combineLatest([this.listLoader.getStoreRows$(), selectedItem$]).pipe(
                map(([list, item]) => {
                    if (list && list.length) {
                        return list;
                    } else if (item && Array.isArray(item)) {
                        return item;
                    } else if (item) {
                        return [item];
                    }
                    return null;
                }),
            );
        }
    }
}
