import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BOOTSTRAP_BREAKPOINTS } from '../../../common/ruum-bootstrap/breakpoints';
@Component({
    selector: 'ruum-dropdown-wrapper',
    template: `
        <!-- Visible on md, lg and xl screen sizes -->
        <!-- Hidden on xs and sm screen sizes -->
        <div *ngIf="isDesktop$ | async" class="d-flex flex-fill minw-0">
            <ng-content select="ruum-dropdown-desktop"></ng-content>
        </div>
        <!-- Hidden on md, lg and xl screen sizes -->
        <!-- Visible on xs and sm screen sizes -->
        <div *ngIf="(isDesktop$ | async) !== true" class="d-flex flex-fill minw-0">
            <ng-content select="ruum-dropdown-mobile"></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumDropdownWrapperComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex flex-fill minw-0';

    isDesktop$: Observable<boolean>;

    constructor(private breakpointObserver: BreakpointObserver, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.isDesktop$ = this.breakpointObserver
            .observe([BOOTSTRAP_BREAKPOINTS.Large, BOOTSTRAP_BREAKPOINTS.XLarge])
            .pipe(
                map((result) => result.matches),
                tap(() => this.changeDetectorRef.markForCheck()),
            );
    }
}
