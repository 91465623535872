import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppStoreWrapper } from '../common/appStoreWrapper.service';
import { WindowRef } from '../common/utils/window.ref';
import { ProfileService } from './../common/connectors/profile/profile.service';
import { PaywallPrivilege } from './paywall-privilege';
import { PaywallRejectStrategy } from './paywall-reject-strategy';

@Injectable({
    providedIn: 'root',
})
export class PaywallService {
    /* eslint-disable */
    private readonly _grantedPrivileges = new BehaviorSubject<PaywallPrivilege[]>([]);
    private _rejectStrategies: PaywallRejectStrategy[] | undefined;
    /* eslint-enable */

    constructor(
        private readonly appStoreWrapper: AppStoreWrapper,
        private readonly windowRef: WindowRef,
        private readonly injector: Injector,
        private readonly profileService: ProfileService,
    ) {
        this.initGrantedPrivileges();
    }

    private initGrantedPrivileges() {
        this.profileService
            .userProfile()
            .pipe(
                map((userProfile) => {
                    const authorizations = userProfile.authorizations;
                    const grantedPrivileges = Object.keys(PaywallPrivilege)
                        .filter((privilege) => {
                            const authKey = PaywallPrivilege[privilege];
                            // make sure that authorization is set to false, defensive programming
                            const auth =
                                typeof authorizations[authKey] === 'undefined' ? true : authorizations[authKey];
                            return auth;
                        })
                        .map((privilege) => PaywallPrivilege[privilege]);
                    return grantedPrivileges;
                }),
                // filter(grantedPrivileges => grantedPrivileges.length > 0), // TODO: check this. Somehow this is empty on first load
                // tap(grantedPrivilages => console.log('grantedPrivilages', grantedPrivilages))
            )
            .subscribe(this._grantedPrivileges);
    }

    grantedPrivileges(): Observable<PaywallPrivilege[]> {
        return this._grantedPrivileges;
    }

    navigateToUpgradeView(): void {
        this.windowRef.open(environment.PAYWALL_UPGRADE_URL, '_blank');
    }
}
