import { trigger, animate, transition, group, animateChild, query, style } from '@angular/animations';
import {
    fadeIn,
    fadeOut,
    fadeInLeftMedium,
    fadeInLeftSmall,
    fadeInRightMedium,
    fadeInRightSmall,
    fadeOutLeftMedium,
    fadeOutLeftSmall,
    fadeOutRightMedium,
    fadeOutRightSmall,
    timingInMedium,
    timingOutMedium,
    pageAnimationTransition,
} from './common/animation';

const fromHomeToProjectGroup = transition(
    'homePageAnimations => projectGroupPageAnimations',
    pageAnimationTransition(
        /* Home page on :leave event */
        group([query('@ruumLobbyListAnimations', animate(timingOutMedium, fadeOutLeftMedium), { optional: true })]),
        /* ProjectGroup page on :enter event */
        group([
            query(
                '@ruumProjectGroupNavbarBreadcrumbAnimations',
                [style({ opacity: 0 }), animate(timingInMedium, fadeInLeftSmall)],
                { optional: true },
            ),
            query(
                '@ruumProjectGroupNavbarButtonsAnimations',
                [style({ opacity: 0 }), animate(timingInMedium, fadeInRightSmall)],
                { optional: true },
            ),
            query('@ruumLobbyListAnimations', [style({ opacity: 0 }), animate(timingInMedium, fadeInRightMedium)], {
                optional: true,
            }),
        ]),
    ),
);

const fromHomeToProject = transition(
    'homePageAnimations => projectPageAnimations',
    pageAnimationTransition(
        /* Home page on :leave event */
        group([query('@ruumLobbyListAnimations', animate(timingOutMedium, fadeOutLeftMedium), { optional: true })]),
        /* Ruum page on :enter event */
        animateChild(),
    ),
);

const fromProjectGroupToHome = transition(
    'projectGroupPageAnimations => homePageAnimations',
    pageAnimationTransition(
        /* ProjectGroup page on :leave event */
        group([
            query('@ruumProjectGroupNavbarBreadcrumbAnimations', animate(timingOutMedium, fadeOutLeftSmall), {
                optional: true,
            }),
            query('@ruumProjectGroupNavbarButtonsAnimations', animate(timingOutMedium, fadeOutRightSmall), {
                optional: true,
            }),
            query('@ruumLobbyListAnimations', animate(timingOutMedium, fadeOutRightMedium), { optional: true }),
        ]),
        /* Home page on :enter event */
        group([
            query('@ruumLobbyListAnimations', [style({ opacity: 0 }), animate(timingInMedium, fadeInLeftMedium)], {
                optional: true,
            }),
        ]),
    ),
);

const fromProjectGroupToProject = transition(
    'projectGroupPageAnimations => projectPageAnimations',
    pageAnimationTransition(
        /* ProjectGroup page on :leave event */
        group([
            query('@ruumProjectGroupNavbarBreadcrumbAnimations', animate(timingOutMedium, fadeOutLeftSmall), {
                optional: true,
            }),
            query('@ruumProjectGroupNavbarButtonsAnimations', animate(timingOutMedium, fadeOutRightSmall), {
                optional: true,
            }),
            query('@ruumLobbyListAnimations', animate(timingOutMedium, fadeOutLeftMedium), { optional: true }),
        ]),
        /* Ruum page on :enter event */
        animateChild(),
    ),
);

const fromProjectToHome = transition(
    'projectPageAnimations => homePageAnimations',
    pageAnimationTransition(
        /* Ruum page on :leave event */
        animateChild(),
        /* Home page on :enter event */
        group([
            query('@ruumLobbyListAnimations', [style({ opacity: 0 }), animate(timingInMedium, fadeInLeftMedium)], {
                optional: true,
            }),
        ]),
    ),
);

const fromProjectToProjectGroup = transition(
    'projectPageAnimations => projectGroupPageAnimations',
    pageAnimationTransition(
        /* Ruum page on :leave event */
        animateChild(),
        /* ProjectGroup page on :enter event */
        group([
            query(
                '@ruumProjectGroupNavbarBreadcrumbAnimations',
                [style({ opacity: 0 }), animate(timingInMedium, fadeInLeftSmall)],
                { optional: true },
            ),
            query(
                '@ruumProjectGroupNavbarButtonsAnimations',
                [style({ opacity: 0 }), animate(timingInMedium, fadeInRightSmall)],
                { optional: true },
            ),
            query('@ruumLobbyListAnimations', [style({ opacity: 0 }), animate(timingInMedium, fadeInLeftMedium)], {
                optional: true,
            }),
        ]),
    ),
);

const fromAnyToTemplates = transition(
    '* <=> templatesPageAnimations',
    pageAnimationTransition(
        /* Any pages on :leave event */
        group([query('.ruum-screen', animate(timingOutMedium, fadeOut), { optional: true })]),
        /* Templates page on :enter event */
        group([query('.ruum-screen', [style({ opacity: 0 }), animate(timingInMedium, fadeIn)], { optional: true })]),
    ),
);

const fromAnyToSettings = transition(
    '* <=> settingsPageAnimations',
    pageAnimationTransition(
        /* Any pages on :leave event */
        group([query('.ruum-screen', animate(timingOutMedium, fadeOut), { optional: true })]),
        /* Settings page on :enter event */
        group([query('.ruum-screen', [style({ opacity: 0 }), animate(timingInMedium, fadeIn)], { optional: true })]),
    ),
);
// TODO: angular-cli: Fix
// ERROR in src/app/app.component.ts(32,3): Error during template compile of 'AppComponent'
//   Function expressions are not supported in decorators in 'routeAnimations'
//     'routeAnimations' references 'pageAnimationTransition' at src/app/app.animation.ts(26,95)
//       'pageAnimationTransition' references 'pageAnimationTransition'
//         'pageAnimationTransition' contains the error at src/app/common/animation/animation-transitions.ts(5,40)
//           Consider changing the function expression into an exported function.

// const GoogleRegExp = new RegExp(/Chrome/);
// const GoogleIncRegExp = new RegExp(/Google Inc/);
// const isChrome = (GoogleRegExp.test(navigator.userAgent) && GoogleIncRegExp.test(navigator.vendor));
const isChrome = false;
export const routeAnimations = trigger(
    'routeAnimations',
    isChrome
        ? [
              fromHomeToProjectGroup,
              fromHomeToProject,
              fromProjectGroupToHome,
              fromProjectGroupToProject,
              fromProjectToHome,
              fromProjectToProjectGroup,
              fromAnyToTemplates,
              fromAnyToSettings,
          ]
        : [],
);
