import { Injectable } from '@angular/core';
import { canvasSchema } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { randomStringOfSize } from './../../../../common/utils.service';
import { RuumTask } from './../../../ruum.model';
import { CanvasService } from './../../canvas.service';

@Injectable({ providedIn: 'root' })
export class CanvasTasksService {
    constructor(private canvasService: CanvasService) {}

    createEmptyTaskAtSelection(): string {
        const taskNode = this.getNewTask();
        this.canvasService.insertAtSelection(taskNode);
        return taskNode.attrs.id;
    }

    createEmptyMilestoneAtSelection(): string {
        const taskNode = this.getNewMilestone();
        this.canvasService.insertAtSelection(taskNode);
        return taskNode.attrs.id;
    }

    addTaskToCanvas(task: RuumTask) {
        const taskNode = canvasSchema.nodes.task.create(task);
        this.canvasService.insertAtSelection(taskNode);
    }

    removeTaskFromCanvas(id: string) {
        this.canvasService.deleteNodeWithId(id);
    }

    addTaskBelow(nodeId: string) {
        const taskNode = this.getNewTask();
        this.canvasService.addNodeBelow(nodeId, taskNode);
    }

    taskIsInTheCanvas(taskId: string): Observable<boolean> {
        return this.canvasService.componentIsInCanvas(taskId);
    }

    createSystemConnectorNode(id: string) {
        return canvasSchema.nodes.systemConnector.create({ id });
    }

    createTaskNode(id?: string) {
        id = id || `task_${Date.now()}_${randomStringOfSize(8)}`;
        return canvasSchema.nodes.task.create({ id });
    }

    private getNewTask() {
        const taskNode = this.createTaskNode();
        /**
         * This flag is needed so that task-description knows if this task is being created now or already exist in the store.
         * We can't simply query the store to known it because the ProseMirror's node could be created before the ADD_TASK action is synchronized.
         * We also can't store this info in the node's attrs because it should be local to the user that is creating it.
         * Therefore, this hack is needed.
         * The CanvasComponentNodeViewBuilder will set this flag to 'false' when the task appears in the store.
         */
        (taskNode as any).beingCreatedFromCanvas = true;
        (taskNode as any).isMilestone = false;
        return taskNode;
    }

    private getNewMilestone() {
        const milestsone = this.createTaskNode();
        /**
         * This flag is needed so that task-description knows if this task is being created now or already exist in the store.
         * We can't simply query the store to known it because the ProseMirror's node could be created before the ADD_TASK action is synchronized.
         * We also can't store this info in the node's attrs because it should be local to the user that is creating it.
         * Therefore, this hack is needed.
         * The CanvasComponentNodeViewBuilder will set this flag to 'false' when the task appears in the store.
         */
        (milestsone as any).beingCreatedFromCanvas = true;
        (milestsone as any).isMilestone = true;
        return milestsone;
    }
}
