import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentTheme } from '../../../shared/ui-components/ui-components.type';

@Component({
    selector: 'ruum-breadcrumb-active-item',
    template: `
        <div
            class="text-small text-{{ theme }} text-truncate font-weight-bold minw-0 py-2"
            [title]="name"
            [ngbTooltip]="tooltipText ? tooltip : undefined"
        >
            {{ name }}
        </div>

        <ng-template #tooltip>
            <div class="text-small font-weight-bold">{{ tooltipText }}</div>
        </ng-template>
    `,
})
export class BreadcrumbActiveItemComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex minw-0';

    @Input() name: string;
    @Input() theme: ComponentTheme = 'white';
    @Input() tooltipText: string;

    constructor() {}

    ngOnInit() {}
}
