import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { WebhookEntitySyncStateListItem } from '../webhooks.model';
import { WebhookEntitiesListService } from './webhook-entities-list.service';

@Injectable({
    providedIn: 'root',
})
export class GetWebhookEntitiesListResolver implements Resolve<any> {
    constructor(private webhookEntitiesListService: WebhookEntitiesListService) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<Observable<WebhookEntitySyncStateListItem[]>> {
        const webhookId = route.paramMap.get('webhookId');

        this.webhookEntitiesListService.filterByWebhookId(webhookId);

        return this.webhookEntitiesListService.getStoreData$().pipe(
            filter((page) => !!page),
            pluck('rows'),
        );
    }
}
