import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import 'wicg-inert';
import { BOOTSTRAP_BREAKPOINTS } from '../ruum-bootstrap/breakpoints';
import { SidenavService } from './sidenav.service';

@Component({
    selector: 'ruum-sidenav',
    template: `
        <ng-container *ngIf="{ isOpen: isOpen$ | async } as data">
            <div
                class="off-side-panel-canvas ruum-main-nav ruum-main-nav-top main-nav-dark"
                [class.open]="data.isOpen"
                [class.d-none]="sidePanelHidden"
                [attr.inert]="isDesktop && !data.isOpen ? '' : null"
            >
                <div class="main-nav-handle">
                    <button
                        data-help-id="global_sidepanel_toggle"
                        class="btn btn-xs btn-icon btn-link-white position-relative"
                        [style.top.px]="21"
                        [style.left.px]="-4"
                        (click)="toggle(data.isOpen)"
                    >
                        <i class="icon" [class.icon-back]="!data.isOpen" [class.icon-menu]="data.isOpen"></i>
                    </button>
                </div>

                <ruum-sidenav-content *ngIf="(isAdminPanel$ | async) !== true"> </ruum-sidenav-content>
                <ruum-admin-sidenav-content *ngIf="(isAdminPanel$ | async) === true"></ruum-admin-sidenav-content>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavPanelCanvasComponent implements OnInit, OnDestroy {
    sidePanelHidden = true;
    isDesktop = true;
    isOpen$: Observable<boolean>;
    isAdminPanel$: Observable<boolean>;

    private ngOnDestroy$ = new Subject<void>();

    constructor(
        private sidenavService: SidenavService,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.isAdminPanel$ = this.router.events.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((event) => event instanceof NavigationEnd),
            map(() => !!this.router.url.match('/admin')),
            startWith(!!this.router.url.match('/admin')),
        );

        this.isOpen$ = this.sidenavService.isMainNavPanelOpen$;
        this.sidenavService
            .isGlobalPanelVisible$()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((visible) => {
                this.sidePanelHidden = !visible;
            });

        // detect breakpoints / desktop programmatically
        this.breakpointObserver
            .observe([BOOTSTRAP_BREAKPOINTS.Medium, BOOTSTRAP_BREAKPOINTS.Large, BOOTSTRAP_BREAKPOINTS.XLarge])
            .subscribe((result) => {
                this.isDesktop = result.matches;
                this.sidenavService.setIncreasedFontSize(this.isDesktop);
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    toggle(isNavigationDrawerOpen: boolean) {
        if (isNavigationDrawerOpen) {
            this.sidenavService.onMainNavPanelDeactivate();
        } else {
            this.sidenavService.onMainNavPanelActivate();
        }
    }
}
