import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InputValidator } from '../../../common/validators/inputValidator.service';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-template-edit-cover',
    template: `
        <ruum-modal-dialog>
            <form [formGroup]="form">
                <h2 class="mt-4 mb-5 text-truncate">Edit Template Cover</h2>

                <ruum-template-common-setting
                    [name]="name"
                    [description]="description"
                    [coverColor]="coverColor"
                    [coverIcon]="coverIcon"
                    [rowClass]="'px-0'"
                    (nameChanged)="changeName($event)"
                    (descriptionChanged)="changeDescription($event)"
                    (coverColorChanged)="changeColor($event)"
                    (coverIconChanged)="changeIcon($event)"
                    editCoverOnly="true"
                    [nameErrorMessage]="nameErrorMessage"
                    [descriptionErrorMessage]="descriptionErrorMessage"
                ></ruum-template-common-setting>
                <div class="d-flex justify-content-end mt-6">
                    <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        [disabled]="form.invalid"
                        (click)="applyChanges()"
                    >
                        Apply Changes
                    </button>
                </div>
            </form>
        </ruum-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditCoverComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([this.componentClass]);
    }

    @Input() name: string;
    @Input() description: string;
    @Input() coverColor: string;
    @Input() coverIcon: string;

    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;

    @Input() componentClass: string;

    form: FormGroup;

    nameLimit: number;
    nameErrorMessage = undefined;
    descriptionLimit: number;
    descriptionErrorMessage = undefined;

    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private inputValidator: InputValidator,
    ) {
        this.nameLimit = this.inputValidator.shortLimit;
        this.descriptionLimit = this.inputValidator.mediumLimit;
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(this.nameLimit), InputValidator.whiteSpace]],
            description: ['', [Validators.maxLength(this.descriptionLimit), InputValidator.whiteSpace]],
            coverColor: ['', Validators.required],
            coverIcon: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.form.controls.name.setValue(this.name);
        this.form.controls.description.setValue(this.description);
        this.form.controls.coverColor.setValue(this.coverColor || 'primary-light');
        this.form.controls.coverIcon.setValue(this.coverIcon || 'template');
    }

    changeName(name: string): void {
        this.form.controls.name.setValue(name);
        this.nameErrorMessage = this.inputValidator.getTextInputErrorMessage(name, this.nameLimit, true);
    }

    changeDescription(description: string): void {
        this.form.controls.description.setValue(description);
        this.descriptionErrorMessage = this.inputValidator.getTextInputErrorMessage(description, this.descriptionLimit);
    }

    changeColor(coverColorName: string): void {
        this.form.controls.coverColor.setValue(coverColorName);
    }

    changeIcon(coverIcon: string): void {
        this.form.controls.coverIcon.setValue(coverIcon);
    }

    applyChanges(): void {
        this.activeModal.close({
            name: this.form.controls.name.value,
            description: this.form.controls.description.value || '',
            coverColor: this.form.controls.coverColor.value,
            coverIcon: this.form.controls.coverIcon.value,
        });
    }
}
