import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Day } from '@ruum/ruum-reducers';

@Component({
    selector: 'task-period',
    template: `
        <div
            class="btn btn-sm mr-2"
            [class.btn-outline-light]="!startDate"
            [class.btn-link-secondary]="startDate"
            [class.px-0]="startDate"
            [class.read-only]="isReadOnly"
        >
            <ruum-datepicker
                [disableSince]="dueDate"
                [isReadOnly]="isReadOnly"
                [disableUntil]=""
                [defaultDate]=""
                placeholder="Start Date"
                fullDateFormat="false"
                [customDateFormat]="'WWW. dd mmm'"
                (dateChanged)="onChangeStartDate($event)"
                (resetDates)="resetDates()"
                [date]="startDate"
                [isStartDay]="true"
            >
            </ruum-datepicker>
        </div>

        <div
            class="btn btn-sm"
            [class.btn-outline-light]="!dueDate"
            [class.btn-link-secondary]="dueDate"
            [class.px-0]="dueDate"
            [class.read-only]="isReadOnly"
        >
            <ruum-datepicker
                [disableSince]=""
                [isReadOnly]="isReadOnly"
                [disableUntil]="startDate"
                [defaultDate]=""
                placeholder="Due Date"
                fullDateFormat="false"
                [customDateFormat]="'WWW. dd mmm'"
                (dateChanged)="onChangeDueDate($event)"
                (resetDates)="resetDates()"
                [date]="dueDate"
                [isStartDay]="false"
                [isOverdue]="isOverdue"
            >
            </ruum-datepicker>
        </div>
    `,
    styles: [
        `
            .btn {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
            .read-only {
                pointer-events: none;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPeriodComponent {
    @HostBinding('class') hostClassList = 'd-flex';
    @Input() startDate: Day;
    @Input() dueDate: Day;
    @Input() isReadOnly: boolean;
    @Input() isOverdue: boolean;
    @Output() changeStartDate = new EventEmitter<Day>();
    @Output() changeDueDate = new EventEmitter<Day>();

    onChangeStartDate(newDate) {
        if (!newDate) {
            newDate = null;
        }
        const dueDate = this.dueDate;
        if (
            newDate != null &&
            dueDate != null &&
            ((newDate.day > dueDate.day && newDate.year >= dueDate.year && newDate.month >= dueDate.month) ||
                newDate.year > dueDate.year ||
                (newDate.year === dueDate.year && newDate.month > dueDate.month))
        ) {
            this.startDate = { ...this.startDate };
            alert('Start Date cannot be after Due Date');
            return;
        }
        this.changeStartDate.emit(newDate);
    }

    onChangeDueDate(newDate) {
        if (!newDate) {
            newDate = null;
        }
        const startDate = this.startDate;
        if (
            newDate != null &&
            startDate != null &&
            // Previous Year
            (newDate.year < startDate.year ||
                // Previous Month
                (newDate.year <= startDate.year && newDate.month < startDate.month) ||
                // Previous Day
                (newDate.day < startDate.day && newDate.year <= startDate.year && newDate.month <= startDate.month))
        ) {
            this.dueDate = { ...this.dueDate };
            alert('Due Date cannot be before Start Date');
            return;
        }
        this.changeDueDate.emit(newDate);
    }

    resetDates() {
        this.changeStartDate.emit(null);
        this.changeDueDate.emit(null);
    }
}
