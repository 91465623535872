import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FocusService } from '../../../shared/lobby/lobby-list-item/lobby-list-item-comments/focus.service';
import { FocusState } from '../../focus/focus.reducer';
import { NavbarService } from '../../navbar/navbar.service';

@Component({
    selector: 'ruum-sidepanel-container',
    template: `
        <div tabindex="-1" class="position-absolute ruum-close-button justify-content-end d-lg-flex d-none">
            <button
                focus-id="sidepanel-container-close-button"
                class="btn btn-outline-secondary btn-icon minw-0 mt-1 pr-0 pl-1"
                type="button"
                (click)="closePanel()"
                (keydown.escape)="closePanel()"
            >
                <i class="icon icon-forward"></i>
            </button>
        </div>

        <div class="bg-white w-100 h-100 border-left border-light ruum-scrollable position-relative">
            <busy-state componentId="sidepanel-container"></busy-state>
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            :host {
                position: relative;
                height: 100%;
            }
            .ruum-close-button {
                right: calc(100% - 1px) !important;
            }
            .ruum-close-button .btn-outline-secondary {
                background-image: url('./../../../../assets/img/side-panel-handle.svg');
                border-right-color: white !important;
                border: none;
                height: 72px;
                width: 24px;
            }
            .ruum-scrollable {
                overflow-y: auto;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumSidepanelContainerComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'w-100 d-flex flex-column flex-fill bg-white';

    stickyTop$: Observable<boolean>;

    private subscriptions: Subscription[] = [];
    focusState$: Observable<FocusState>;

    private previousFocusId: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private navbarService: NavbarService,
        private focusService: FocusService,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        this.stickyTop$ = this.navbarService.stickyTop$;
        this.focusState$ = this.focusService.focusId$;
        this.subscriptions.push(
            this.focusState$.subscribe(({ focusId, previousFocusId }) => {
                if (focusId === this.focusService.getFocusTargetTemplateName(this.elementRef)) {
                    this.focusService.focusNextElement(this.elementRef);
                    this.previousFocusId = previousFocusId;
                }
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    closePanel() {
        this.router.navigate([{ outlets: { sidepanel: null } }], { relativeTo: this.route.parent }).then(() => {
            this.focusService.setFocus(
                this.previousFocusId,
                this.focusService.getFocusTargetTemplateName(this.elementRef),
            );
        });
    }
}
