import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ruum-profile-tag',
    template: `
        <ng-content></ng-content>
        <button
            class="btn btn-icon btn-link-secondary btn-xs"
            [class.btn-without-hover]="!hover"
            [class.active]="active"
            [disabled]="disabled"
        >
            <i class="icon icon-cancel"></i>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileTagComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return [
            'd-flex',
            'align-items-center',
            this.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            this.componentClass,
        ]
            .filter((el) => !!el)
            .join(' ');
    }

    /** States */
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;

    @Input() componentClass: string;

    constructor() {}

    ngOnInit() {}
}
