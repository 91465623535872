import { Injectable } from '@angular/core';
import {
    ProjectParticipantRole,
    ProjectReducerDecoratorsActionTypes,
    RuumActionTypes,
    SetProjectParticipantRoleAction,
} from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SelectedProjectService } from '../../common/connectors/project/selectedProject.service';
import { BlameUserAction } from '../../project/reducerWrapper/ruumReducerWrapper.actions';

@Injectable({ providedIn: 'root' })
export class ParticipantsService {
    constructor(private selectedProjectService: SelectedProjectService) {}

    participants() {
        return this.selectedProjectService.participants();
    }

    deleteParticipant(participantId: string) {
        return this.selectedProjectService.persistAction(RuumActionTypes.DELETE_PARTICIPANT, { id: participantId });
    }

    setParticipantRole(userId: string, role: ProjectParticipantRole) {
        return this.selectedProjectService.persistAction<SetProjectParticipantRoleAction>(
            'SET_PROJECT_PARTICIPANT_ROLE',
            { userId, role },
        );
    }

    deleteProposedParticipant(participantId: string, participantMail: string) {
        this.selectedProjectService.persistAction(RuumActionTypes.DELETE_PARTICIPANT, {
            id: participantId,
            mail: participantMail,
        });
    }

    setParticipantToBeInvited(participantId: string, participantMail: string, invited: boolean) {
        this.selectedProjectService.dispatchAction(ProjectReducerDecoratorsActionTypes.SET_PARTICIPANT_TO_BE_INVITED, {
            participantId,
            participantMail,
            invited,
        });
    }

    /**
     * Highlights the parts of the canvas which were written by user 'userId'.
     */
    blameUser(userId: string, blame: boolean) {
        this.selectedProjectService.dispatchAction<BlameUserAction>('BLAME_USER', {
            userId,
            blame,
        });
    }

    isBeingBlamed(userId: string): Observable<boolean> {
        return this.selectedProjectService.isBeingBlamed(userId);
    }

    toggleUserBlame(userId: string) {
        let blame: boolean;
        this.isBeingBlamed(userId)
            .pipe(take(1))
            .subscribe((b) => (blame = b));
        this.blameUser(userId, !blame);
    }

    getParticipant(participantId: string) {
        return this.selectedProjectService.participants().pipe(
            map((ps) => ps.filter((p) => p.id === participantId)[0]),
            filter((user) => !!user),
        );
    }
}
