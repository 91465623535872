import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, MemoizedSelector, select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../../app.store';
import { SavedViewVisualizationsService } from '../saved-view-visualizations.service';
import { SavedViewFieldDefinition, SavedViewValue } from '../saved-views.model';
import { SavedViewsState } from '../saved-views.reducer';

@Injectable({ providedIn: 'root' })
export class SavedViewStandardFieldsService {
    private search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private savedViewsState = createFeatureSelector<SavedViewsState>('savedViews');

    private selectedSavedViewValues: MemoizedSelector<AppState, SavedViewValue> = createSelector(
        this.savedViewsState,
        (state: SavedViewsState) => {
            return {
                ...state.entities[state.selectedSavedViewId],
            };
        },
    );

    constructor(
        private store: Store<AppState>,
        private savedViewVisualizationsService: SavedViewVisualizationsService,
    ) {}

    startLoad() {
        this.search$.next('');
    }

    stopLoad() {}

    search(search: string) {
        this.search$.next(search);
    }

    loadMore() {}

    getData(relativeTo): Observable<SavedViewFieldDefinition[]> {
        return combineLatest([this.search$, this.getSelectedSavedViewValue()]).pipe(
            switchMap(([search, savedViewValue]) => {
                return combineLatest([
                    this.savedViewVisualizationsService.getVisualization(savedViewValue.viewType),
                ]).pipe(
                    map(([visualization]) => {
                        const ids =
                            relativeTo === 'filters' ? visualization.filterFieldIds : visualization.columnFieldIds;

                        return this.getFilteredStandardFieldsByIds(ids, search);
                    }),
                );
            }),
        );
    }

    hasMore() {
        return of(false);
    }

    showLoading() {
        return of(false);
    }

    private getSelectedSavedViewValue(): Observable<SavedViewValue> {
        return this.store.pipe(
            select(this.selectedSavedViewValues),
            filter((state) => !!state.id),
        );
    }

    private getFilteredStandardFieldsByIds(fieldIds: string[], search: string) {
        return this.getStandardFieldsByIds(fieldIds).filter((item) => this.fieldNameContainsQuery(item.title, search));
    }

    private getStandardFieldsByIds(fieldIds: string[]): SavedViewFieldDefinition[] {
        const entities = this.entities();
        const list = fieldIds.map((fieldId) => entities[fieldId]);

        return list;
    }

    private fieldNameContainsQuery(fieldName: string, search: string): boolean {
        const name = fieldName.toLowerCase();
        const formattedQuery = search.toLowerCase();

        return name.indexOf(formattedQuery) > -1;
    }

    private entities(): { [key: string]: SavedViewFieldDefinition } {
        return {
            standard_field_lobby_list_status: {
                fieldId: 'standard_field_lobby_list_status',
                title: 'Status',
                typeId: 'lobbyStatus',
            },
            standard_field_favorite: {
                fieldId: 'standard_field_favorite',
                title: 'Favorite',
                typeId: 'favorite',
            },
            standard_field_comments: {
                fieldId: 'standard_field_comments',
                title: 'Comments',
                typeId: 'comments',
            },
            standard_field_lobby_list_name: {
                fieldId: 'standard_field_lobby_list_name',
                title: 'Name',
                typeId: 'lobbyName',
            },
            standard_field_changed_at: {
                fieldId: 'standard_field_changed_at',
                title: 'Last Changed',
                typeId: 'changedAt',
            },
            standard_field_created_by: {
                fieldId: 'standard_field_created_by',
                title: 'Creator',
                typeId: 'createdBy',
            },
            standard_field_tags: {
                fieldId: 'standard_field_tags',
                title: 'Labels',
                typeId: 'tags',
            },
            standard_field_section: {
                fieldId: 'standard_field_section',
                title: 'Section',
                typeId: 'section',
            },
            standard_field_project: {
                fieldId: 'standard_field_project',
                title: 'Ruum',
                typeId: 'project',
            },
            standard_field_group: {
                fieldId: 'standard_field_group',
                title: 'Group',
                typeId: 'group',
            },
            standard_field_workspace: {
                fieldId: 'standard_field_workspace',
                title: 'Workspace',
                typeId: 'workspace',
            },
            standard_field_task_list_status: {
                fieldId: 'standard_field_task_list_status',
                title: 'Task Status',
                typeId: 'taskStatus',
            },
            standard_field_task_list_name: {
                fieldId: 'standard_field_task_list_name',
                title: 'Name',
                typeId: 'taskName',
            },
            standard_field_task_list_subtasks: {
                fieldId: 'standard_field_task_list_subtasks',
                title: 'Subtasks',
                typeId: 'subtasks',
            },
            standard_field_priority: {
                fieldId: 'standard_field_priority',
                title: 'Priority',
                typeId: 'priority',
            },
            standard_field_startdate: {
                fieldId: 'standard_field_startdate',
                title: 'Start Date',
                typeId: 'startDate',
            },
            standard_field_duedate: {
                fieldId: 'standard_field_duedate',
                title: 'Due Date',
                typeId: 'dueDate',
            },
            standard_field_assignees: {
                fieldId: 'standard_field_assignees',
                title: 'Assignee',
                typeId: 'assignees',
            },
            standard_field_roles: {
                fieldId: 'standard_field_roles',
                title: 'Role',
                typeId: 'roles',
            },
            standard_field_groupsAndRuums: {
                fieldId: 'standard_field_groupsAndRuums',
                title: 'Groups & Ruums',
                typeId: 'groupsAndRuums',
            },
            standard_field_milestone: {
                fieldId: 'standard_field_milestone',
                title: 'Milestone Status',
                typeId: 'milestone',
            },
            standard_field_approval_list_status: {
                fieldId: 'standard_field_approval_list_status',
                title: 'Status',
                typeId: 'approvalStatus',
            },
            standard_field_approval_list_approver: {
                fieldId: 'standard_field_approval_list_approver',
                title: 'Approver',
                typeId: 'approver',
            },
            standard_field_approval_list_responsibles: {
                fieldId: 'standard_field_approval_list_responsibles',
                title: 'Responsible',
                typeId: 'responsibles',
            },
            standard_field_approval_list_approver_role: {
                fieldId: 'standard_field_approval_list_approver_role',
                title: 'Approver Role',
                typeId: 'approverRole',
            },
            standard_field_approval_list_actions: {
                fieldId: 'standard_field_approval_list_actions',
                title: 'Actions',
                typeId: 'approvalActions',
            },
            standard_field_requested_on: {
                fieldId: 'standard_field_requested_on',
                title: 'Requested On',
                typeId: 'createdOn',
            },
            standard_field_requested_by: {
                fieldId: 'standard_field_requested_by',
                title: 'Requestor',
                typeId: 'requestedBy',
            },
        };
    }
}
