import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ExtendedTask } from '../../../common/task-list/task-list-container.component';

@Component({
    selector: 'ruum-task-list-cell-description',
    template: `
        <div
            class="d-flex flex-fill align-items-center pr-0 text-truncate"
            [style.padding-left.px]="getPaddingLeft()"
            [attr.data-test-subtask-level]="indentation"
        >
            <button *ngIf="!!task.parent" class="btn btn-sm btn-icon btn-link-light">
                <i class="icon icon-subtask"></i>
            </button>
            <span class="interface text-truncate">{{ task.description }}</span>
            <button
                class="ml-3 btn btn-xs btn-outline-light ruum-show-on-canvas"
                [class.d-none]="!canvasButtonVisible()"
                (click)="onShowOnCanvas($event)"
            >
                Show on Canvas
            </button>
        </div>
    `,
})
export class TaskListCellDescriptionComponent {
    @HostBinding() class = 'd-flex w-100';

    @Input()
    set data(value: any) {
        this.task = value;
    }
    get data(): any {
        return this.task;
    }
    @Input() indentation = 0;

    @Input() hoveredRowIndex: number;

    @Input() index: number;

    @Output() showOnCanvas = new EventEmitter<{ task: ExtendedTask }>();

    task: any = {};

    constructor() {}

    getPaddingLeft() {
        if (this.indentation > 0) {
            const padding = (this.indentation - 1) * 24;
            return padding;
        } else {
            return 0;
        }
    }

    onShowOnCanvas(event: Event) {
        event.stopPropagation();

        this.showOnCanvas.emit(this.task);
    }

    canvasButtonVisible() {
        return this.hoveredRowIndex === this.index;
    }
}
