import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumBootstrapModule } from '../../../common/ruum-bootstrap/ruum-bootstrap.module';
import { ModalDialogModule } from '../../layouts/modal-dialog/modal-dialog.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { RuumStatusButtonComponent } from './ruum-status-button.component';
import { RuumStatusDesktopComponent } from './ruum-status-desktop.component';
import { RuumStatusMobileComponent } from './ruum-status-mobile.component';
import { RuumStatusComponent } from './ruum-status.component';

@NgModule({
    imports: [CommonModule, ModalDialogModule, DropdownModule, RuumBootstrapModule],
    declarations: [
        RuumStatusComponent,
        RuumStatusMobileComponent,
        RuumStatusDesktopComponent,
        RuumStatusButtonComponent,
    ],
    exports: [RuumStatusComponent, RuumStatusMobileComponent, RuumStatusDesktopComponent, RuumStatusButtonComponent],
})
export class RuumStatusModule {}
