import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileListItem } from '../../common/connectors/profile/profile.model';
import { ProfileService } from './../../common/connectors/profile/profile.service';

export interface TrialStatus {
    status: 'not_available' | 'running' | 'enterprise';
    daysLeft?: number;
}

@Injectable({
    providedIn: 'root',
})
export class TrialService {
    constructor(private profileService: ProfileService) {}

    // TODO: this can be removed once all pro trials and pro subscriptions have ended
    trackTrialStatus(): Observable<TrialStatus> {
        return this.profileService.userProfile().pipe(
            map((userProfile: ProfileListItem) => {
                if (userProfile.isTrial && userProfile.subscriptionEndDate) {
                    const daysLeft = this.differenceInDays(userProfile.subscriptionEndDate, new Date().getTime());
                    if (daysLeft > 0) {
                        return {
                            status: 'running',
                            daysLeft,
                        } as TrialStatus;
                    }
                } else if (userProfile.authorizations.accountType === 'ENTERPRISE') {
                    return {
                        status: 'enterprise',
                    } as TrialStatus;
                }
                return {
                    status: 'not_available',
                } as TrialStatus;
            }), // end map
        ); // end pipe
    }

    private differenceInDays = (timestamp1: number, timestamp2: number) => {
        const difference = timestamp1 - timestamp2;
        return Math.floor(difference / 1000 / 60 / 60 / 24) + 1;
    };
}
