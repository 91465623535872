import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import {
    RuumDropdownItem,
    RuumDropdownPlacement,
    RuumDropdownSections,
} from '../../shared/ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-single-select',
    template: `
        <ruum-dropdown
            *ngIf="!showNewMenu"
            [placement]="placement"
            [sections]="options"
            [width]="width"
            (selectItem)="selectChange.emit($event)"
        >
            <button
                data-content="ruum-dropdown-button-desktop"
                data-test="ruum-dropdown-button"
                type="button"
                [disabled]="isReadOnly"
                [ngClass]="buttonClassList"
                aria-label="ariaLabel"
            >
                <span>{{ selectedOption?.name }}</span>
                <i [ngClass]="['icon', buttonIcon]"></i>
            </button>

            <button
                data-content="ruum-dropdown-button-mobile"
                data-test="ruum-dropdown-button"
                type="button"
                [disabled]="isReadOnly"
                [ngClass]="buttonClassList"
            >
                <span>{{ selectedOption?.name }}</span>
                <i [ngClass]="['icon', buttonIcon]"></i>
            </button>
        </ruum-dropdown>

        <ruum-menu *ngIf="showNewMenu" [placement]="placement" [width]="width">
            <ruum-menu-trigger>
                <button [ngClass]="buttonClassList" aria-label="Show group access settings">
                    <span>{{ selectedOption?.name }}</span>
                    <i [ngClass]="['icon', buttonIcon]"></i>
                </button>
            </ruum-menu-trigger>
            <ng-container *ngFor="let section of options; let last = last">
                <ng-container *ngFor="let item of section">
                    <ruum-menu-option
                        (click)="selectChange.emit(item)"
                        [theme]="item.theme"
                        [icon]="item.icon"
                        [disabled]="item.disabled"
                        [disableTooltip]="!item.disabled"
                        [ngbTooltip]="item.tooltip?.title"
                    >
                        {{ item.name }}
                        <ruum-menu-option-description *ngIf="item.description">
                            {{ item.description }}
                        </ruum-menu-option-description>
                    </ruum-menu-option>
                </ng-container>
                <ruum-menu-devider *ngIf="!last"></ruum-menu-devider>
            </ng-container>
        </ruum-menu>
    `,
})
export class SingleSelectComponent implements OnInit {
    showNewMenu = true;

    @HostBinding('class')
    hostClassList = 'd-flex minw-0';

    @Input()
    buttonClassList = 'btn btn-sm btn-link-secondary px-0';

    @Input()
    buttonIcon = 'icon-cevron-down';

    @Input()
    selectedOption: RuumDropdownItem;

    @Input()
    options: RuumDropdownSections;

    @Input()
    placement: RuumDropdownPlacement = ['bottom-left', 'top-left', 'auto'];

    @Input()
    width = '144px';

    @Input()
    isReadOnly = false;

    @Input() ariaLabel = 'single select dropdown';

    @Output() selectChange = new EventEmitter<RuumDropdownItem>();

    constructor() {}

    ngOnInit() {}
}
