import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpCSRFInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method !== 'GET') {
            const csrfRequest = request.clone({
                setHeaders: { 'X-XSRF-TOKEN': this.cookieService.get('XSRF-TOKEN') },
            });
            return next.handle(csrfRequest);
        }
        return next.handle(request);
    }
}
