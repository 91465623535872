import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from '../../ui-components/menu/menu.module';
import { CustomFieldValueModule } from '../custom-field-value/custom-field-value.module';
import { TaskDetailCostumFieldValueListComponent } from './task-custom-field-value-list.component';

@NgModule({
    declarations: [TaskDetailCostumFieldValueListComponent],
    imports: [CommonModule, CustomFieldValueModule, NgbTooltipModule, MenuModule],
    exports: [TaskDetailCostumFieldValueListComponent],
})
export class CustomFieldCommonModule {}
