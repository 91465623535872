import { Observable } from 'rxjs';

export interface ScrollTarget {
    position: number;
    /** in milliseconds */
    time: number;
}

export const SCROLL_TIME = 500;

export function scrollIntoView(element: Element): boolean {
    let scrollableElm = element.parentElement;
    while (scrollableElm && !scrollableElm.hasAttribute('smooth-scroll')) {
        scrollableElm = scrollableElm.parentElement;
    }
    if (!scrollableElm) {
        return false;
    }

    const { top: scrollableTop, bottom: scrollableBottom } = scrollableElm.getBoundingClientRect();
    let { top: elemTop, bottom: elemBottom } = element.getBoundingClientRect();
    const scrollTop = scrollableElm.scrollTop;
    if (elemBottom - elemTop > scrollableBottom - scrollableTop) {
        elemBottom = elemTop + scrollableBottom - scrollableTop;
    }

    elemTop += scrollTop;
    elemBottom += scrollTop;

    const position = elemTop - scrollableTop + (elemBottom - elemTop) / 2 - (scrollableBottom - scrollableTop) / 2;

    const target: ScrollTarget = {
        position,
        time: SCROLL_TIME,
    };
    const event = new CustomEvent<ScrollTarget>('scroll-to', { detail: target, bubbles: true });
    element.dispatchEvent(event);
    return true;
}
