import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';
import { PaywallPrivilege } from './paywall-privilege';
import { PaywallRejectStrategyId } from './paywall-reject-strategy';
import { RejectionOnMissingPrivilege, RequiredPrivilegeController } from './required-privilege.controller';

@Directive({
    selector: '[requiredPrivilege]',
    providers: [RequiredPrivilegeController],
})
export class RequiredPrivilegeDirective implements OnInit, OnDestroy {
    static readonly STYLE_CLASS = 'ruum-requiredPrivilege';
    static readonly STYLE_CLASS_GRANTED = 'ruum-requiredPrivilege__granted';
    static readonly STYLE_CLASS_REJECTED = 'ruum-requiredPrivilege__rejected';

    @Input() requiredPrivilege: PaywallPrivilege;
    @Input() rejectStrategy: PaywallRejectStrategyId = 'noop';
    @Input() rejectStrategyData: any;

    /* eslint-disable */
    @Output('requiredPrivilege.click') requiredPrivilegeClick = new EventEmitter<Event>();
    @Output('requiredPrivilege.input') requiredPrivilegeInput = new EventEmitter<Event>();
    @Output('requiredPrivilege.keydown') requiredPrivilegeKeydown = new EventEmitter<Event>();
    /* eslint-enable */

    constructor(
        private readonly elementRef: ElementRef,
        private readonly controller: RequiredPrivilegeController,
        private readonly renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.addStyleClass();

        if (!this.requiredPrivilege) {
            return;
        }

        this.controller
            .initRejectByStrategyOnMissingPrivilege(
                this.requiredPrivilege,
                this.rejectStrategy,
                this.rejectStrategyData,
                this.elementRef,
            )
            .subscribe((rejection) => {
                this.addStateStyleClass(rejection);
            });
    }

    ngOnDestroy(): void {
        this.controller.destroyRejectByStrategyOnMissingPrivilege().subscribe((rejection) => {
            this.removeStateStyleClass(rejection);
        });
        this.removeStyleClass();
    }

    @HostListener('click', ['$event'])
    @HostListener('input', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    @HostListener('keydown.space', ['$event'])
    onEvent(event: Event): void {
        this.checkPrivileges(event);
    }

    private checkPrivileges(event: Event) {
        if (!this.requiredPrivilege) {
            event.preventDefault();
            this.emitEvent(event);
            return;
        }

        this.controller.rejectByStrategyOnMissingPrivilege().subscribe((rejectionOnMissingPrivilege) => {
            if (rejectionOnMissingPrivilege === RejectionOnMissingPrivilege.OPERATION_GRANTED) {
                event.preventDefault();
                this.emitEvent(event);
            }
        });
    }

    private emitEvent(event: Event) {
        switch (event.type) {
            case 'click':
                return this.requiredPrivilegeClick.emit(event);
            case 'input':
                return this.requiredPrivilegeInput.emit(event);
            case 'keydown':
                return this.requiredPrivilegeKeydown.emit(event);
        }
    }

    private addStyleClass(): void {
        this.renderer.addClass(this.elementRef.nativeElement, RequiredPrivilegeDirective.STYLE_CLASS);
    }

    private removeStyleClass(): void {
        this.renderer.removeClass(this.elementRef.nativeElement, RequiredPrivilegeDirective.STYLE_CLASS);
    }

    private addStateStyleClass(rejection: RejectionOnMissingPrivilege): void {
        this.renderer.addClass(this.elementRef.nativeElement, this.stateStyleClass(rejection));
    }

    private removeStateStyleClass(rejection: RejectionOnMissingPrivilege): void {
        this.renderer.removeClass(this.elementRef.nativeElement, this.stateStyleClass(rejection));
    }

    private stateStyleClass(rejection: RejectionOnMissingPrivilege): string {
        return rejection === RejectionOnMissingPrivilege.OPERATION_GRANTED
            ? RequiredPrivilegeDirective.STYLE_CLASS_GRANTED
            : RequiredPrivilegeDirective.STYLE_CLASS_REJECTED;
    }
}
