import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ModalDialogService } from './modal-dialog.service';

@Component({
    selector: 'ruum-show-again-checkbox',
    template: `
        <div class="form-check">
            <input
                id="remember-preference"
                type="checkbox"
                class="form-check-input checked-accent"
                [disabled]="disabled"
                (click)="toggle($event.target.checked)"
                ngbAutofocus
            />
            <label class="form-check-label ml-1" for="remember-preference">
                Don't show this again
            </label>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowAgainCheckboxComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            'd-flex',
            componentHelper.getCursorClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @Input() dialogId = '';
    @Input() disabled = false;
    @Input() componentClass = '';

    constructor(private modalDialogService: ModalDialogService) {}

    ngOnInit() {}

    toggle(checked: boolean) {
        this.modalDialogService.setShowDialogAgain(this.dialogId, !checked);
    }
}
