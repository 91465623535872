import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AddExternalSystemAction,
    DeleteExternalSystemAction,
    ExternalSystem,
    UpdateExternalSystemAction,
} from '@ruum/ruum-reducers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { getRandomId } from '../../../../app/common/utils.service';
import { AuthService } from '../../../auth/auth.service';
import { AppState } from '../../app.store';
import { RuumAlertService } from '../../components/alert/alert.service';
import { ServiceHelper } from '../../serviceHelper';
import { OrderedListParams, PaginatedList, SortDirection } from '../paginatedList.model';
import { PaginatedListLoader } from '../paginatedListLoader';
import { ProjectServiceBackendConnector } from '../projectServiceConnector.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { SelectedEnterpriseService } from './../enterprise/selected-enterprise.service';
import { ExternalSystemInfo, ExternalSystemListFilters, ExternalSystemListSortBy } from './external-system-list.model';
import { ExternalSystemLoadedAction, EXTERNAL_SYSTEM_LIST_ACTION_TYPES } from './external-system-list.reducer';

@Injectable({ providedIn: 'root' })
export class ExternalSystemListService extends PaginatedListLoader<
    ExternalSystemInfo,
    ExternalSystemListFilters,
    ExternalSystemListSortBy
> {
    readonly workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    readonly search$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private orderBy$ = new BehaviorSubject<OrderedListParams<ExternalSystemListSortBy>>(undefined);
    private enterpriseId$: Observable<string>;

    constructor(
        private readModelConnector: ReadModelBackendConnector,
        private serviceHelper: ServiceHelper,
        protected alertService: RuumAlertService,
        private store: Store<AppState>,
        protected authService: AuthService,
        private selectedEnterpriseService: SelectedEnterpriseService,
        private projectServiceBackendConnector: ProjectServiceBackendConnector,
    ) {
        super(alertService, authService);
        this.enterpriseId$ = this.selectedEnterpriseService.selectedEnterpriseId();
        this.setUpObservables();
    }

    protected getFilters$(): Observable<ExternalSystemListFilters> {
        return combineLatest([this.enterpriseId$, this.workspaceId$, this.search$]).pipe(
            distinctUntilChanged(),
            map<any, ExternalSystemListFilters>(([enterpriseId, workspaceId, search]) => ({
                enterpriseId,
                workspaceId,
                search,
            })),
        );
    }

    resetFilters() {
        this.workspaceId$.next(undefined);
        this.search$.next(undefined);
    }

    orderBy(by: ExternalSystemListSortBy, direction: SortDirection) {
        this.orderBy$.next({ by, direction });
    }

    protected getData(
        page: number,
        filters: ExternalSystemListFilters,
        orderBy: OrderedListParams<ExternalSystemListSortBy>,
    ): Observable<PaginatedList<ExternalSystemInfo>> {
        return this.readModelConnector.getExternalSystems({ page }, filters, orderBy);
    }

    protected getOrderBy$(): Observable<OrderedListParams<ExternalSystemListSortBy>> {
        return this.orderBy$;
    }

    getStoreRows$(): Observable<ExternalSystemInfo[]> {
        return this.store.select('externalSystemList').pipe(map((data) => data.rows));
    }

    private setUpObservables() {
        this.getListObservable().subscribe((page: any) => {
            this.serviceHelper.dispatchWithoutPersisting<ExternalSystemLoadedAction>('EXTERNAL_SYSTEM_LOADED', {
                page,
            });
        });
    }

    isDomainReachable(systemId: string): Observable<boolean> {
        return this.readModelConnector
            .isExternalSystemHostnameReachable(systemId)
            .pipe(map((result) => result.reachable));
    }

    getStoreData$() {
        return this.store.select('externalSystemList');
    }

    createExternalSystem(system: ExternalSystem): Promise<any> {
        const payload: AddExternalSystemAction['payload'] = {
            systemId: getRandomId('system_'),
            hostname: system.hostname,
            port: system.port,
            title: system.title,
            ruumUserId: system.ruumUserId,
            outboundAuthentication: system.outboundAuthentication,
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<AddExternalSystemAction>(enterpriseId, 'ADD_EXTERNAL_SYSTEM', payload)
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    EXTERNAL_SYSTEM_LIST_ACTION_TYPES.ADD_EXTERNAL_SYSTEM_TO_LIST,
                    payload,
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }

    updateExternalSystem(systemId, system: ExternalSystem): Promise<any> {
        const payload: UpdateExternalSystemAction['payload'] = {
            systemId,
            editFields: {
                hostname: system.hostname,
                port: system.port,
                title: system.title,
                ruumUserId: system.ruumUserId,
                outboundAuthentication: system.outboundAuthentication,
            },
        };

        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<UpdateExternalSystemAction>(enterpriseId, 'UPDATE_EXTERNAL_SYSTEM', payload)
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    EXTERNAL_SYSTEM_LIST_ACTION_TYPES.UPDATE_EXTERNAL_SYSTEM_TO_LIST,
                    payload,
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }

    deleteExternalSystem(systemId: string): Promise<any> {
        const payload: DeleteExternalSystemAction['payload'] = {
            systemId,
        };
        const enterpriseId = this.selectedEnterpriseService.getSelectedEnterpriseId();
        return this.projectServiceBackendConnector
            .addActionToEnterprise<DeleteExternalSystemAction>(enterpriseId, 'DELETE_EXTERNAL_SYSTEM', payload)
            .then(() => {
                return this.serviceHelper.dispatchWithoutPersisting(
                    EXTERNAL_SYSTEM_LIST_ACTION_TYPES.DELETE_EXTERNAL_SYSTEM_FROM_LIST,
                    payload,
                );
            })
            .catch((res) => this.alertService.warning(res.err.message, res.err));
    }
}
