import { Component, HostBinding, Input } from '@angular/core';
import { RuumDropdownItem } from '../dropdown.model';

@Component({
    selector: 'dropdown-select-button',
    template: `
        <div class="d-flex">
            <button *ngIf="!item || !item.id" aria-label="ariaLabel" class="btn btn-sm btn-outline-light" type="button">
                <span>{{ placeholder }}</span>
            </button>
            <button
                *ngIf="!!item && !!item.id"
                aria-label="ariaLabel"
                class="btn btn-sm px-0 btn-link-{{ item.theme }}"
                type="button"
            >
                <span>{{ item.name }}</span>
            </button>
            <button
                *ngIf="!isReadOnly && !!item && !!item.id"
                class="btn btn-sm btn-icon btn-link-secondary minw-0 px-0"
                type="button"
                aria-label="ariaLabel"
            >
                <i class="icon icon-cevron-down"></i>
            </button>
        </div>
    `,
    styles: [
        `
            button {
                background-color: transparent;
            }
        `,
    ],
})
export class DropdownSelectButtonComponent {
    @HostBinding('class') hostClassList = 'd-flex flex-fill';
    @Input() placeholder: string;
    @Input() item: RuumDropdownItem;
    @Input() isReadOnly = false;
    @Input() ariaLabel = 'select option';
}
