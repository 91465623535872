import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        NgbModalModule,
        NgbPopoverModule,
        NgbCollapseModule,
        NgbDropdownModule,
        NgbTypeaheadModule,
        NgbTooltipModule,
    ],
    declarations: [],
    exports: [
        /** export modules */
        NgbModalModule,
        NgbPopoverModule,
        NgbCollapseModule,
        NgbDropdownModule,
        NgbTypeaheadModule,
        NgbTooltipModule,
    ],
})
export class RuumBootstrapModule {}
