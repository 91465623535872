import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SelectedProjectService } from '../../project/selectedProject.service';
import { SelectedProjectGroupService } from '../../projectGroup/selectedProjectGroup.service';
import { CustomFieldListService } from '../../workspace/customFieldsList/customFieldsList.service';
import { SelectedWorkspaceService } from '../../workspace/selected-workspace.service';
import { WorkspaceListService } from '../../workspace/workspace-list.service';
import { SavedViewFieldDefinition } from '../saved-views.model';

@Injectable({ providedIn: 'root' })
export class SavedViewCustomFieldsService {
    constructor(
        private workspaceListService: WorkspaceListService,
        private selectedWorkspaceService: SelectedWorkspaceService,
        private selectedProjectGroupService: SelectedProjectGroupService,
        private selectedProjectService: SelectedProjectService,
        private customFieldListService: CustomFieldListService,
    ) {}

    startLoad() {
        const workspaceId = this.selectedWorkspaceService.getSelectedWorkspaceId();
        const groupId = this.selectedProjectGroupService.getSelectedGroupId();
        const projectId = this.selectedProjectService.getSelectedProjectId();
        const isNonWorkspaceGroup = groupId && !workspaceId;

        if (!isNonWorkspaceGroup) {
            this.customFieldListService.loadList();
        }

        this.customFieldListService.resetFilters();
        this.customFieldListService.workspaceId$.next(workspaceId);
        // this.customFieldListService.relativeToGroupId$.next(groupId);
        // this.customFieldListService.relativeToProjectId$.next(projectId);
        // this.customFieldListService.relativeToTasksOfProjectId$.next(projectId);
    }

    stopLoad() {
        this.customFieldListService.stopLoadingList();
        this.customFieldListService.resetFilters();
    }

    search(search: string) {
        this.customFieldListService.search$.next(search);
    }

    loadMore() {
        this.customFieldListService.maybeGoToNextPage();
    }

    getData(): Observable<SavedViewFieldDefinition[]> {
        return this.customFieldListService.getStoreRows$().pipe(
            switchMap((list) => {
                const fields = list.filter((el) => !!el);

                return combineLatest([of(fields), this.workspaceListService.workspaceList()]);
            }),
            map(([fields, workspaces]) => {
                const workspacesMap = workspaces.reduce((accumulator, currentValue) => {
                    return {
                        ...accumulator,
                        [currentValue.id]: currentValue.name,
                    };
                }, {});

                return fields.map((field: any) => ({
                    fieldId: field.id,
                    typeId: field.type,
                    title: field.title,
                    description: workspacesMap[field.sourceId] || '',
                    options: field.options,
                }));
            }),
        );
    }

    showLoading() {
        return this.customFieldListService.showLoading$;
    }

    hasMore() {
        return this.customFieldListService.hasMore$;
    }
}
