import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileModule } from '../profile/profile.module';
import { ApproversComponent } from './approvers.component';

@NgModule({
    imports: [CommonModule, NgbTooltipModule, ProfileModule],
    declarations: [ApproversComponent],
    exports: [ApproversComponent],
})
export class ApproversModule {}
