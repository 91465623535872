import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';
import { ExternalSystemInfo } from './external-system-list.model';

@Injectable({ providedIn: 'root' })
export class ExternalSystemStore extends StoreLoader<ExternalSystemInfo> {
    private map = new BehaviorSubject<ExternalSystemsMap>({});

    constructor(private readModelBackendConnector: ReadModelBackendConnector) {
        super();
    }

    protected getData(id: string[]): Observable<ExternalSystemInfo[]> {
        return this.readModelBackendConnector
            .getExternalSystems({ page: 1, pageSize: id.length }, { id })
            .pipe(map((page) => page.rows));
    }

    protected storeData(list: ExternalSystemInfo[]): void {
        const change: ExternalSystemsMap = {};
        list.forEach((table) => {
            change[table.id] = table;
        });
        this.map.next({
            ...this.map.value,
            ...change,
        });
    }

    protected failedToLoad(id: string): void {
        this.map.next({
            ...this.map.value,
            [id]: {
                id,
                hostname: '',
                port: 0,
                title: 'Failed to load',
                outboundAuthenticationType: 'none',
            },
        });
    }

    protected getStoreData(): Observable<{ [id: string]: ExternalSystemInfo }> {
        return this.map.asObservable();
    }
}

export interface ExternalSystemsMap {
    [tableId: string]: ExternalSystemInfo;
}
