import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-select-template-owned-placeholder',
    template: `
        <h3 class="text-truncate mb-2">Created by you</h3>
        <div class="d-flex flex-fill flex-column justify-content-center align-items-center mb-7 p-3 border rounded">
            <h4 class="text-secondary">You haven’t published any template yet</h4>
            <button class="btn btn-sm btn-link-primary" (click)="manageTemplates.emit()">
                <span class="font-weight-normal">{{ linkName }}</span>
            </button>
        </div>
    `,
    styles: [
        `
            .border {
                border-style: dashed !important;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTemplateOwnedPlaceholderComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill flex-column', this.componentClass]);
    }

    @Input() linkName = 'Manage Templates';

    @Input() componentClass: string;

    @Output() manageTemplates = new EventEmitter();

    constructor() {}

    ngOnInit() {}
}
