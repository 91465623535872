import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomFieldRecordKeyString, TableRow } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionTypeAndPayload } from '../../actions';
import { AppState } from '../../app.store';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';
import { AddOrReplaceTableDataRecords } from './tableDataRecords.reducer';

export interface TableDataRecordsGetDataParams {
    systemId: string;
    tableId: string;
}

@Injectable({ providedIn: 'root' })
export class TableDataRecordStore extends StoreLoader<TableRow> {
    constructor(private store: Store<AppState>, private readModelBackendConnector: ReadModelBackendConnector) {
        super();
    }

    protected getData(keys: CustomFieldRecordKeyString[]): Observable<TableRow[]> {
        return this.readModelBackendConnector.getRecordsByKeys(keys).pipe(map((page) => page.rows));
    }

    protected storeData(records: TableRow[]): void {
        const add: ActionTypeAndPayload<AddOrReplaceTableDataRecords> = {
            type: 'ADD_OR_REPLACE_TABLE_DATA_RECORD',
            payload: {
                records,
            },
        };
        this.store.dispatch(add);
    }

    protected failedToLoad(id: string): void {
        const add: ActionTypeAndPayload<AddOrReplaceTableDataRecords> = {
            type: 'ADD_OR_REPLACE_TABLE_DATA_RECORD',
            payload: {
                records: [
                    {
                        __id: id,
                    },
                ],
            },
        };
        this.store.dispatch(add);
    }

    protected getStoreData(): Observable<{ [id: string]: TableRow }> {
        return this.store.select('tableDataRecords');
    }

    protected getId(row: TableRow): string {
        return row.__id;
    }
}
