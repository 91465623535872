import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PanelInfo, ProjectReducerDecoratorsActionTypes, UserSpecificUIState } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CommonService } from '../../common/common.service';
import { SelectedProjectService } from '../../common/connectors/project/selectedProject.service';
import { AuthService } from './../../auth/auth.service';
import { AppState } from './../../common/app.store';
import { AppStoreWrapper } from './../../common/appStoreWrapper.service';
import { ServiceHelper } from './../../common/serviceHelper';
import { CanvasCollabService } from './../canvas/canvasCollab.service';
import { RuumUIState } from './../ruum.model';

@Injectable({ providedIn: 'root' })
export class RuumUIStateService {
    private ruumUIStateObs: Observable<RuumUIState>;
    private offline$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private appStoreWrapper: AppStoreWrapper,
        private authService: AuthService,
        private serviceHelper: ServiceHelper,
        private selectedProjectService: SelectedProjectService,
        private canvasCollabService: CanvasCollabService,
        private commonService: CommonService,
    ) {
        this.ruumUIStateObs = this.appStoreWrapper.selectedRuumUIState();
        this.offline$ = combineLatest([this.canvasCollabService.offline(), this.appStoreWrapper.appOffline()]).pipe(
            map(([canvasOffline, appOffline]) => canvasOffline || appOffline),
        );
    }

    ruumUIState() {
        return this.ruumUIStateObs;
    }

    shouldShowSidePanel() {
        return this.ruumUIStateObs.pipe(map((uiState) => uiState.showRightPanel));
    }

    allSideMenus() {
        return this.loggedUserUIState().pipe(
            map((userSpecificUIState) => {
                return userSpecificUIState.panelsInfo;
            }),
        );
    }

    sideMenu(menu: string): Observable<PanelInfo> {
        return this.loggedUserUIState().pipe(map((userSpecificUIState) => userSpecificUIState.panelsInfo[menu]));
    }

    loggedUserUIState(): Observable<UserSpecificUIState> {
        const loggedUser = this.authService.getLoggedUser();
        return this.ruumUIStateObs.pipe(
            map((uiState) => uiState.participantsUIState[loggedUser && loggedUser.id]),
            filter((userSpecificUIState) => userSpecificUIState !== undefined),
        );
    }

    userUIState(userId: string) {
        return this.ruumUIStateObs.pipe(
            map((uiState) => uiState.participantsUIState[userId]),
            filter((userSpecificUIState) => userSpecificUIState !== undefined),
        );
    }

    /**
     * Showing/hiding the canvas shortcuts
     */
    setCanvasShortcutsVisible(visible: boolean) {
        this.serviceHelper.dispatchWithoutPersisting(
            ProjectReducerDecoratorsActionTypes.SHOW_SHORTCUTS_INFORMATION,
            visible,
        );
    }

    canvasShortcutsVisible() {
        return this.ruumUIState().pipe(map((uiState) => uiState.showCanvasShortcuts));
    }

    offline() {
        return this.offline$;
    }

    isAppOffline(): boolean {
        let offline;
        this.offline()
            .pipe(take(1))
            .subscribe((isOffline) => (offline = isOffline));
        return offline;
    }

    setOffline(offline: boolean) {
        this.commonService.setOffline(offline);
    }
}
