import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnableNowLibService } from '../../../third-party/sapenablenowlib.service';
import {
    filterDropdownList,
    RuumDropdownItem,
    RuumDropdownItemFilters,
    RuumDropdownList,
    RuumDropdownSectionList,
} from '../../shared/ui-components/dropdown/dropdown.model';
import { CommonService } from '../common.service';
import { RuumAlertService } from '../components/alert/alert.service';
import { GettingStartedService } from '../components/getting-started/getting-started.service';
import { TrackingConnector } from '../trackingConnector.service';
import { APPLICATION_PART, HelpService } from './help.service';
import { KeyboardShortcutsComponent } from './keyboard-shortcuts/keyboard-shortcuts.component';

@Component({
    selector: 'ruum-help-popover',
    template: `
        <ruum-dropdown-section-list [sectionList]="dropdownSectionList" (selectItem)="select($event)">
        </ruum-dropdown-section-list>
    `,
    styles: [
        `
            :host {
                display: block;
                min-width: 200px;
            }
        `,
    ],
})
export class HelpPopoverComponent implements OnInit, OnChanges {
    @Input() applicationPart: APPLICATION_PART;
    @Output() navigate = new EventEmitter();

    helpOptions: RuumDropdownList = [
        { id: 'HELP_CENTER', name: 'Help Portal' },
        { id: 'GETTING_STARTED', name: 'Getting Started' },
        { id: 'KEYBOARD_SHORTCUTS', name: 'Keyboard Shortcuts' },
    ];

    contactUsOptions: RuumDropdownList = [{ id: 'CONTACT_US', name: 'Support' }];

    optionFilters: RuumDropdownItemFilters = [
        {
            id: 'KEYBOARD_SHORTCUTS',
            isHidden: () => ['lobby', 'group', 'report', 'read-only-ruum'].indexOf(this.applicationPart) !== -1,
        },
        { id: 'GETTING_STARTED', isHidden: () => !this.isGettingStartedEnabled() },
    ];

    dropdownSectionList: RuumDropdownSectionList;

    constructor(
        private modalService: NgbModal,
        private helpService: HelpService, // TODO
        private trackingConnector: TrackingConnector,
        private route: ActivatedRoute,
        private enableNowLibService: EnableNowLibService,
        private ruumAlertService: RuumAlertService,
        private commonService: CommonService,
        private gettingStartedService: GettingStartedService,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params && params['help-editor'] === 'true') {
                this.helpOptions.push({ id: 'ON_SCREEN_HELP', name: 'On Screen Help' });
            }
        });
        this.dropdownSectionList = this.getDropdownSectionList();
    }

    ngOnChanges() {
        this.dropdownSectionList = this.getDropdownSectionList();
    }

    getDropdownSectionList(): RuumDropdownSectionList {
        return [
            filterDropdownList(this.helpOptions, this.optionFilters),
            filterDropdownList(this.contactUsOptions, this.optionFilters),
        ];
    }

    select(item: RuumDropdownItem) {
        switch (item.id) {
            case 'HELP_CENTER':
                this.trackingConnector.trackEventInferCategoryFromUrl('main_navbar_help_center', 'opened');
                return this.openHelpPortal();
            case 'KEYBOARD_SHORTCUTS':
                this.trackingConnector.trackEventInferCategoryFromUrl('main_navbar_show_shortcuts', 'opened');
                return this.openKeyboardShortcuts();
            case 'GETTING_STARTED':
                return this.openGettingStarted();
            case 'CONTACT_US':
                this.trackingConnector.trackEventInferCategoryFromUrl('main_navbar_contact_form', 'opened');
                return this.contactUs();
            case 'ON_SCREEN_HELP':
                return this.startEnableNow();
            default:
                return;
        }
    }

    startEnableNow(): void {
        this.commonService.setBusy(true);
        this.enableNowLibService.lazyLoadEnableNow().subscribe((_) => {
            if (!(window as any).Help4) {
                this.ruumAlertService.warning('We had difficulties loading this feature. Please try again later.');
            }
            setTimeout(() => {
                this.commonService.setBusy(false);
                const globalToggleFunction = window['sap-enable-now-toggle'];
                if (globalToggleFunction) {
                    globalToggleFunction();
                }
            }, 1200);
        });
    }

    openKeyboardShortcuts(): void {
        this.navigate.emit();
        this.modalService.open(KeyboardShortcutsComponent, { centered: true });
    }

    openGettingStarted(): void {
        this.navigate.emit();
        this.gettingStartedService.openGettingStarted(true);
    }

    private contactUs(): void {
        window.open(
            `https://help.sap.com/viewer/d9b57ff016e941489cff8d1035b9aea5/Latest/en-US/0b07325520a14864a757a6ffa3118291.html`,
            undefined,
            'noopener',
        );
    }

    private isGettingStartedEnabled() {
        return window.innerWidth >= 1024;
    }

    private openHelpPortal() {
        this.trackingConnector.trackEventInferCategoryFromUrl('help_center', 'opened');
        window.open(`https://help.sap.com/viewer/product/RUUM/Latest/en-US`, undefined, 'noopener');
    }
}
