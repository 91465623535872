import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldValue } from '@ruum/ruum-reducers';

@Component({
    selector: 'ruum-custom-field-number',
    template: `
        <ng-container *ngIf="!isReadOnly; else readOnlyTemplate">
            <div class="input-group input-group-sm hov">
                <input
                    class="form-control"
                    type="number"
                    aria-label="Custom Field Number"
                    [formControl]="formControl"
                    [placeholder]="placeholder"
                    ruumInputHighlight
                    (blur)="onValueChange()"
                    aria-label="Enter a value"
                />
                <div class="input-group-append" ruumInputHighlight>
                    <span class="input-group-text">
                        <i class="icon icon-hashtag hov-child"></i>
                    </span>
                </div>
            </div>
        </ng-container>

        <ng-template #readOnlyTemplate>
            <div class="input-group input-group-sm hov disabled">
                <div class="form-control disabled">
                    <span [class.text-light]="!customField.value">
                        {{ customField.value || placeholder }}
                    </span>
                </div>
                <div class="input-group-append" ruumInputHighlight>
                    <span class="input-group-text disabled">
                        <i class="icon icon-hashtag hov-child"></i>
                    </span>
                </div>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldNumberComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-block';

    @Input()
    set customField(customField: CustomFieldValue) {
        this._customField = customField;
        if (customField) {
            const value = customField.value ? (customField.value as string) : '';
            // this is needed because sometimes the customField ref changed but not the value
            if (value !== this.value) {
                this.value = value;
                this.formControl.setValue(value);
            }
        }
    }
    get customField(): CustomFieldValue {
        return this._customField;
    }

    @Input() isReadOnly = false;

    @Output() valueChange = new EventEmitter<CustomFieldValue>();

    get placeholder(): string {
        return this.customField.placeholder ? this.customField.placeholder : 'Enter a value';
    }

    formControl = new FormControl('');

    private _customField: CustomFieldValue;
    private value = '';

    constructor() {}

    ngOnInit() {}

    onValueChange() {
        let value = this.formControl.value;
        if (this.customField.value !== value) {
            if (value) {
                value = parseInt(value, 10);
            }
            const changedField = { ...this.customField, value };
            this.valueChange.emit(changedField);
        }
    }
}
