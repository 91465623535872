import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdminSystemConnectorListModule } from './system-connector-list/system-connector-list.module';

@NgModule({
    imports: [CommonModule, AdminSystemConnectorListModule],
    declarations: [],
    exports: [],
})
export class AdminSystemConnectorModule {}
