import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RuumA11yModule } from '../../common/a11y/a11y.module';
import { FormatDatePipeModule } from '../../common/pipe/formate-date.pipe';
import { RuumBootstrapModule } from '../../common/ruum-bootstrap/ruum-bootstrap.module';
import { RuumTaskListItemOptionsModule } from '../../common/task-list/task-list-item-options/task-list-item-options.component';
import { TaskAssigneesModule } from '../task/task-assignees/task-assignees.module';
import { TaskPriorityModule } from '../task/task-priority/task-priority.module';
import { TaskStatusModule } from '../task/task-status/task-status.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { TaskListItemCustomFieldModule } from './task-list-item-custom-field/task-list-item-custom-field.module';
import { TaskListItemSmallComponent } from './task-list-item-small.component';
import { TaskListMobileViewComponent } from './task-list-mobile-view.component';
import { HeaderCellUnsortableComponent } from './task-list-table-cell/header-cell-unsortable.component';
import { TaskListCellAssigneeComponent } from './task-list-table-cell/task-list-cell-assignee.component';
import { TaskListCellCommentComponent } from './task-list-table-cell/task-list-cell-comment.component';
import { TaskListCellDescriptionComponent } from './task-list-table-cell/task-list-cell-description.component';
import { TaskListCellDueDateComponent } from './task-list-table-cell/task-list-cell-due-date.component';
import { DataCellGroupComponent } from './task-list-table-cell/task-list-cell-group.component';
import { TaskListCellPriorityComponent } from './task-list-table-cell/task-list-cell-priority.component';
import { TaskListCellProjectComponent } from './task-list-table-cell/task-list-cell-project.component';
import { TaskListCellFunctionalRolesComponent } from './task-list-table-cell/task-list-cell-role.component';
import { TaskListCellSectionComponent } from './task-list-table-cell/task-list-cell-section.component';
import { TaskListCellStartDateComponent } from './task-list-table-cell/task-list-cell-start-date.component';
import { TaskListCellSubtasksComponent } from './task-list-table-cell/task-list-cell-subtasks.component';
import { RuumTaskListPriorityButtonComponent } from './task-list-table-cell/task-list-priority-button.component';
import { TaskListComponent } from './task-list.component';

@NgModule({
    imports: [
        CommonModule,
        CdkTableModule,
        UiComponentsModule,
        RuumBootstrapModule,
        RouterModule,
        TaskStatusModule,
        TaskAssigneesModule,
        RuumTaskListItemOptionsModule,
        TaskPriorityModule,
        TaskListItemCustomFieldModule,
        FormatDatePipeModule,
        RuumA11yModule,
    ],
    declarations: [
        TaskListComponent,
        TaskListCellAssigneeComponent,
        TaskListCellDescriptionComponent,
        TaskListCellFunctionalRolesComponent,
        TaskListCellCommentComponent,
        DataCellGroupComponent,
        TaskListCellProjectComponent,
        TaskListCellSectionComponent,
        TaskListCellStartDateComponent,
        TaskListCellDueDateComponent,
        TaskListCellSubtasksComponent,
        HeaderCellUnsortableComponent,
        RuumTaskListPriorityButtonComponent,
        TaskListCellPriorityComponent,
        TaskListMobileViewComponent,
        TaskListItemSmallComponent,
    ],
    exports: [
        TaskListComponent,
        TaskListItemCustomFieldModule,
        TaskListItemSmallComponent,
        TaskListMobileViewComponent,
    ],
})
export class TaskListModule {}
