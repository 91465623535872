import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Transition animation type for mask display/hidden
 *
 * Available values: `'none'`, `'fade'`
 */
export type MaskAnimType = 'none' | 'fade';

/**
 * Transition animation type for popup display/hidden
 *
 * Available values: `'none'`, `'fade'`, `'slideBottom'`
 */
export type PopupAnimType = 'none' | 'fade' | 'slideBottom' | 'slideLeft' | 'slideTop';

/**
 * Base class for Popup in Ruum, should be inherited by all Popup Component
 * Generic type RET is the data type for close function
 */
export class RuumPopupBase<RET = any> {
    popupContainer: any;

    /**
     * Close the popup and send some data to the caller
     */
    close(data?: RET): void {
        this.popupContainer.close(data);
    }

    /**
     * Return the color of Mask to the Popup Container
     */
    getMaskColor(): string {
        return 'rgba(0,0,0,0.1)';
    }

    /**
     * Return the transition animation for mask display/hidden
     */
    getMaskAnim(): MaskAnimType {
        return 'none';
    }

    /**
     * Return the transition animation for Popup display/hidden
     */
    getPopupAnim(): PopupAnimType {
        return 'none';
    }

    /**
     * Return true to stop the default behavior (close popup)
     */
    customMaskClick(): boolean {
        return false;
    }

    /**
     * Tell the popup container if the popup should be closed automatically by the click on mask.
     */
    closeOnMaskClick(): boolean {
        return true;
    }

    /**
     * Return an observable object to tell the popup container the position and size of the popup.
     * The content of the object will be applied to the <div> node for the popup
     * Avaliable values:
     * ```
     * {
     *    left: number | 'auto' | string,
     *    top: number | 'auto' | string,
     *    right: number | 'auto' | string,
     *    bottom: number | 'auto' | string,
     *    width: number | 'auto' | string,
     *    height: number | 'auto' | string
     * }
     * ```
     * `'auto'` will be used if any value is not set.
     */
    getPos(): Observable<{}> {
        return new BehaviorSubject({ top: 0, left: 0 });
    }

    /**
     * Return the value that mask should or not shown
     */
    noMask(): boolean {
        return false;
    }
}
