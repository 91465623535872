import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EnterpriseReport } from './../dashboard/dashboard.model';
import { DashboardService } from './../dashboard/dashboard.service';

@Component({
    selector: 'ruum-admin-company-info',
    template: `
        <div class="d-flex flex-column minw-0 p-4">
            <div class="text-white text-truncate font-weight-bold">{{ (companyData$ | async)?.name }}</div>
            <div class="text-white text-truncate font-weight-bold">{{ (companyData$ | async)?.licenses }} Licenses</div>
        </div>
    `,
})
export class AdminCompanyInfoComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    companyData$: Observable<EnterpriseReport>;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit() {
        this.companyData$ = this.dashboardService.getReport();
    }
}
