import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RuumCommonModule } from '../../../common/common.module';
import { SystemConnectorDialogModule } from './../../../shared/system-connector/system-connector-dialog/system-connector-dialog.module';
import { SearchModule } from './../../../shared/ui-components/search/search.module';
import { AdminSystemConnectorListComponent } from './system-connector-list.component';

@NgModule({
    imports: [CommonModule, SearchModule, RuumCommonModule, SystemConnectorDialogModule],
    declarations: [AdminSystemConnectorListComponent],
    exports: [AdminSystemConnectorListComponent],
})
export class AdminSystemConnectorListModule {}
