import { EntityAction, RuumAction } from '@ruum/ruum-reducers';
import { ODataEnterpriseParticipant } from '../../common/odata/odata.model';
import { PaginatedList } from '../../common/connectors/paginatedList.model';
import { getDefaultPaginatedList } from '../../lobby/lobby.reducer';
import {
    handleEntitiesLoaded,
    handleEntityCreated,
    handleEntityDeleted,
    handleEntityUpdated,
    handleSingleEntityLoaded,
} from '../common/admin-reducer.utils';

export enum ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES {
    ENTERPRISE_PARTICIPANTS_LOADED = 'ENTERPRISE_PARTICIPANTS_LOADED',
    SINGLE_ENTERPRISE_PARTICIPANT_LOADED = 'SINGLE_ENTERPRISE_PARTICIPANT_LOADED',
    ENTERPRISE_PARTICIPANT_CREATED = 'ENTERPRISE_PARTICIPANT_CREATED',
    ENTERPRISE_PARTICIPANT_UPDATED = 'ENTERPRISE_PARTICIPANT_UPDATED',
    ENTERPRISE_PARTICIPANT_DELETED = 'ENTERPRISE_PARTICIPANT_DELETED',
}

export type EnterpriseParticipantsLoadedAction = EntityAction<
    'ENTERPRISE_PARTICIPANTS_LOADED',
    {
        enterpriseId: string;
        page: PaginatedList<ODataEnterpriseParticipant>;
    }
>;

export type EnterpriseParticipantCreatedAction = EntityAction<
    'ENTERPRISE_PARTICIPANT_CREATED',
    {
        enterpriseId: string;
        enterpriseParticipant: ODataEnterpriseParticipant;
    }
>;

export type EnterpriseParticipantUpdatedAction = EntityAction<
    'ENTERPRISE_PARTICIPANT_UPDATED',
    {
        enterpriseId: string;
        enterpriseParticipant: ODataEnterpriseParticipant;
    }
>;

export type SingleEnterpriseParticipantLoadedAction = EntityAction<
    'SINGLE_ENTERPRISE_PARTICIPANT_LOADED',
    {
        enterpriseId: string;
        enterpriseParticipant: ODataEnterpriseParticipant;
    }
>;

export type EnterpriseParticipantDeletedAction = EntityAction<
    'ENTERPRISE_PARTICIPANT_DELETED',
    {
        enterpriseId: string;
        enterpriseParticipantId: string;
    }
>;

function getEntity(action: RuumAction): ODataEnterpriseParticipant {
    return action.payload.enterpriseParticipant;
}

function isSameEntity(a: ODataEnterpriseParticipant, b: ODataEnterpriseParticipant): boolean {
    return a.enterpriseId === b.enterpriseId && a.userId === b.userId;
}

export function EnterpriseParticipantListReducer(
    currentState: PaginatedList<ODataEnterpriseParticipant> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<ODataEnterpriseParticipant> {
    switch (action.type) {
        case ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES.ENTERPRISE_PARTICIPANTS_LOADED:
            return handleEntitiesLoaded<ODataEnterpriseParticipant, EnterpriseParticipantsLoadedAction>(
                currentState,
                action,
            );
        case ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES.SINGLE_ENTERPRISE_PARTICIPANT_LOADED:
            return handleSingleEntityLoaded<ODataEnterpriseParticipant, SingleEnterpriseParticipantLoadedAction>(
                currentState,
                action,
                isSameEntity,
                getEntity,
            );
        case ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES.ENTERPRISE_PARTICIPANT_CREATED:
            return handleEntityCreated<ODataEnterpriseParticipant, EnterpriseParticipantCreatedAction>(
                currentState,
                action,
                getEntity,
            );
        case ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES.ENTERPRISE_PARTICIPANT_UPDATED:
            return handleEntityUpdated<ODataEnterpriseParticipant, EnterpriseParticipantUpdatedAction>(
                currentState,
                action,
                isSameEntity,
                getEntity,
            );
        case ENTERPRISE_PARTICIPANT_LIST_ACTION_TYPES.ENTERPRISE_PARTICIPANT_DELETED:
            return handleEntityDeleted<ODataEnterpriseParticipant, EnterpriseParticipantDeletedAction>(
                currentState,
                action,
                isSameEntity,
            );
        default:
            return currentState;
    }
}
