import { Injectable } from '@angular/core';
import { canvasSchema } from '@ruum/ruum-reducers';
import { Node } from 'prosemirror-model';
import { EditorState, NodeSelection, Selection, TextSelection, Transaction } from 'prosemirror-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, startWith } from 'rxjs/operators';
import {
    findChildNodeInSectionById,
    findNodes,
    findNodeWithId,
    getObjectSectionId,
    getPositionSectionId,
} from './canvasHelpers';
import { CanvasStateManagerService } from './canvasStateManager.service';

@Injectable({ providedIn: 'root' })
export class CanvasService {
    private stateDoc$: Observable<Node>;
    nodeTypesToSanitize: string[] = [];
    /**
     * subject to hold the value of canvas element id for "show on canvas" feature
     */
    private _scrollToNodeInCanvas$ = new BehaviorSubject<string>('');
    /**
     * publicly exposed observable to get value of scrolling canvas element id
     */
    scrollToNodeInCanvas$ = this._scrollToNodeInCanvas$.asObservable();
    constructor(private canvasStateManager: CanvasStateManagerService) {
        this.stateDoc$ = this.canvasStateManager.validFullStateChange().pipe(
            distinctUntilChanged(),
            debounceTime(150),
            map((state) => state.doc),
            share(),
        );
        this.nodeTypesToSanitize = this.getNodeTypesToSanitize();
    }
    getNodeTypesToSanitize(): string[] {
        return Object.keys(canvasSchema.nodes)
            .filter((nodeType) => {
                return (
                    (canvasSchema.nodes[nodeType] as any).groups &&
                    (canvasSchema.nodes[nodeType] as any).groups.indexOf('component') !== -1
                );
            })
            .map((nodeType) => {
                return canvasSchema.nodes[nodeType].spec.parseDOM[0].tag;
            });
    }

    /** Scroll to HTML element with this id. */
    scrollToNodeId(id: string) {
        this._scrollToNodeInCanvas$.next(id);
        // this.serviceHelper.scrollToNodeId('canvas', id);
    }

    deleteNodeWithId(id) {
        const nodes = findNodes(this.canvasStateManager.getCurrentFullState().doc, (node) => node.attrs.id === id);
        nodes.reverse().forEach((nodeAndPos) => {
            this.deleteRange(nodeAndPos.pos, nodeAndPos.pos + nodeAndPos.node.nodeSize);
        });
    }

    deleteRange(start: number, end: number) {
        const deleteTransaction = this.canvasStateManager.getCurrentFullState().tr.delete(start, end);
        deleteTransaction.setMeta('disableComponentProtector', true).setMeta('addToHistory', false);
        this.dispatchTransactionToCanvas(deleteTransaction);
    }

    deleteRangeInSection(sectionId: string, start: number, end: number) {
        const deleteTransaction = this.canvasStateManager.getSectionState(sectionId).tr.delete(start, end);
        deleteTransaction.setMeta('disableComponentProtector', true).setMeta('addToHistory', false);
        this.canvasStateManager.dispatchSectionTransaction(sectionId, deleteTransaction);
    }

    replaceNodeWithEmptyParagraph(id: string) {
        const fullState = this.canvasStateManager.getCurrentFullState();
        const nodeAndPos = findNodeWithId(fullState.doc, id);

        if (!nodeAndPos) {
            return;
        }

        const { node, pos } = nodeAndPos;

        const sectionId = getPositionSectionId(fullState.doc, pos);

        const relativePos = pos - fullState.doc.resolve(pos).start(1);
        const state = this.canvasStateManager.getSectionState(sectionId);

        const emptyParagraph = canvasSchema.nodes.paragraph.create();
        const tr = state.tr.replaceWith(relativePos, relativePos + node.nodeSize, emptyParagraph);
        tr.setMeta('disableComponentProtector', true).setMeta('addToHistory', false);
        tr.setSelection(TextSelection.create(tr.doc, relativePos + 1));

        this.canvasStateManager.dispatchSectionTransaction(sectionId, tr);
        this.canvasStateManager.setFocusOnSection(sectionId);
    }

    insertAtSelection(node: Node) {
        const sectionState = this.canvasStateManager.getFocusedSectionState();
        const sectionId = sectionState.doc.attrs.id;

        if (sectionState.selection instanceof TextSelection) {
            const pos = sectionState.selection.$anchor.pos - 1;
            const transaction = sectionState.tr.insert(pos, node).setMeta('addToHistory', false);
            const newPos = transaction.doc.resolve(pos);
            const transactionContinued = transaction
                .setSelection(new NodeSelection(newPos))
                .setMeta('addToHistory', false);
            this.canvasStateManager.dispatchSectionTransaction(sectionId, transactionContinued);
        } else {
            const tr = sectionState.tr
                .setSelection(
                    new TextSelection(
                        sectionState.doc.resolve(Math.max(sectionState.selection.anchor, sectionState.selection.head)),
                    ),
                )
                .setMeta('addToHistory', false);
            this.canvasStateManager.dispatchSectionTransaction(sectionId, tr);
            this.insertAtSelection(node);
        }
    }

    findChildNode(nodeId: string): { sectionId: string; node: Node; pos: number } | null {
        for (const sectionState of this.canvasStateManager.getSectionsStates()) {
            const nodePos = findChildNodeInSectionById(sectionState.doc, nodeId);
            if (nodePos) {
                return {
                    sectionId: sectionState.doc.attrs.id,
                    node: nodePos.node,
                    pos: nodePos.pos,
                };
            }
        }
        return null;
    }

    insertAtPos(sectionId: string, pos: number, node: Node) {
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        const transaction = sectionState.tr.insert(pos, node).setMeta('addToHistory', false);
        this.canvasStateManager.dispatchSectionTransaction(sectionId, transaction);
    }

    dispatchTransactionToCanvas(transaction: Transaction) {
        this.canvasStateManager.dispatchDocTransaction(transaction);
    }

    deleteSelection(sectionId: string) {
        const state = this.canvasStateManager.getSectionState(sectionId);
        const tr = state.tr
            .deleteSelection()
            .setMeta('disableComponentProtector', true)
            .setMeta('addToHistory', false);
        this.canvasStateManager.dispatchSectionTransaction(sectionId, tr);
    }

    selectNodeNear(nodeId: string, direction: 'UP' | 'DOWN') {
        const { pos, node } = findNodeWithId(this.fullDoc(), nodeId);
        const sectionId = getPositionSectionId(this.fullDoc(), pos);
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        let relativePos =
            pos -
            this.fullDoc()
                .resolve(pos)
                .start(1);

        if (direction === 'DOWN') {
            relativePos += node.nodeSize;
        }

        const newSelection = Selection.findFrom(sectionState.doc.resolve(relativePos), direction === 'UP' ? -1 : 1);
        if (newSelection) {
            this.canvasStateManager.setFocusOnSection(sectionId);
            const tr = this.canvasStateManager.getSectionState(sectionId).tr.setSelection(newSelection);
            this.canvasStateManager.dispatchSectionTransaction(sectionId, tr);
        }
    }

    getNodeAbove(nodeId: string): Node {
        const doc = this.fullDoc();
        const { pos, node } = findNodeWithId(doc, nodeId);
        const $pos = doc.resolve(pos);
        return $pos.nodeBefore;
    }

    componentIsInCanvas(componentId: string): Observable<boolean> {
        return this.stateDoc$.pipe(
            map((doc) => !!findNodeWithId(doc, componentId)),
            startWith(this.getComponentIsInCanvas(componentId)),
            distinctUntilChanged(),
        );
    }

    getComponentIsInCanvas(componentId: string): boolean {
        return this.fullState() ? !!findNodeWithId(this.fullDoc(), componentId) : false;
    }

    getSanitizedDocument(doc: Document): string {
        /**
         * doc has always the same structure:
         * an html document whose body contains the actually copied content
         */
        if (doc.body.childElementCount === 0) {
            return doc.body.innerHTML;
        }
        const rootElementCollection = doc.body.getElementsByTagName('canvas-section-content');

        // Fixes https://github.wdf.sap.corp/io/ruum-webapp/issues/587
        if (doc.body.innerHTML.indexOf('<!--StartFragment-->') !== -1) {
            doc.body.innerHTML = doc.body.innerHTML.replace(/\s\s/g, '');
        }
        /*
         * if the copied content is not coming from a Ruum canvas (doesn't contain a canvas-section-content element),
         * we can just return what was copied
         */
        if (rootElementCollection.length === 0) {
            return doc.body.innerHTML;
        }
        const canvasSectionContentElement = rootElementCollection[0];
        const collection: HTMLCollection = canvasSectionContentElement.children;
        /**
         * otherwise, we clean the child elements from the component types that should not be copied over
         */
        const nodesToDelete = [];
        for (let i = 0; i < collection.length; i++) {
            if (this.nodeTypesToSanitize.indexOf(collection.item(i).nodeName.toLowerCase()) !== -1) {
                nodesToDelete.push(collection.item(i));
            }
        }
        nodesToDelete.forEach((node) => node.remove());
        return canvasSectionContentElement.outerHTML;
    }

    executeCommandOnSection(
        sectionId: string,
        command: (state: EditorState, dispatch: (tr: Transaction) => void) => boolean,
    ): boolean {
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        return command(sectionState, (tr) => this.canvasStateManager.dispatchSectionTransaction(sectionId, tr));
    }

    addParagraphBelow(nodeId: string) {
        const emptyParagraph = canvasSchema.nodes.paragraph.createAndFill();
        this.addNodeBelow(nodeId, emptyParagraph, true);
    }

    addNodeAtEnd() {}

    addNodeBelow(nodeId: string, nodeToAdd: Node, focusAfter?: boolean) {
        const doc = this.canvasStateManager.getCurrentFullState().doc;
        const { node, pos } = findNodeWithId(doc, nodeId);
        const sectionId = getPositionSectionId(doc, pos);
        const sectionState = this.canvasStateManager.getSectionState(sectionId);
        const relativePos = pos - doc.resolve(pos).start(1);
        const transaction = sectionState.tr.insert(relativePos + 1, nodeToAdd);

        const selection = Selection.findFrom(transaction.doc.resolve(relativePos + 1), 1);
        transaction.setSelection(selection);

        this.canvasStateManager.dispatchSectionTransaction(sectionId, transaction.setMeta('addToHistory', false));

        if (focusAfter) {
            setTimeout(() => {
                this.canvasStateManager.setFocusOnSection(sectionId);
            }, 100);
        }
    }

    getObjectSectionId(nodeId: string): Observable<string> {
        return this.canvasStateManager.validFullStateChange().pipe(
            debounceTime(350),
            map((state) => getObjectSectionId(state.doc, nodeId)),
        );
    }

    private fullState() {
        return this.canvasStateManager.getCurrentFullState();
    }

    private fullDoc() {
        return this.fullState().doc;
    }
}
