import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateParticipant, TemplateParticipantRole } from '@ruum/ruum-reducers';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { CommonService } from '../../../../app/common/common.service';
import { AppStoreWrapper } from '../../../common/appStoreWrapper.service';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import { SelectedTemplateService } from '../../../common/connectors/templates/selectedTemplate.service';
import {
    TemplateRolesService,
    TEMPLATE_ADMIN,
    TEMPLATE_EDITOR,
    TEMPLATE_VIEWER,
} from '../../../project/template-roles.service';
import {
    filterDropdownList,
    RuumDropdownItem,
    RuumDropdownItemFilters,
    RuumDropdownList,
    RuumDropdownSectionList,
} from '../../ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-template-team-participant-options',
    template: `
        <ruum-participant-options-dropdown
            [selectedItem]="selectedDropdownOption$ | async"
            [sectionList]="dropdownOptions$ | async"
            [size]="'sm'"
            [componentClass]="'btn-sm btn-link-secondary px-0'"
            (itemChange)="onChangeOption($event)"
        >
        </ruum-participant-options-dropdown>
    `,
})
export class TemplateTeamParticipantOptionsComponent implements OnInit {
    @Input()
    loggedUser: TemplateParticipant;

    @Input()
    participant: TemplateParticipant;

    @Input()
    hideLeaveOptions = false;

    selectedDropdownOption$: Observable<RuumDropdownItem>;

    dropdownOptions$: Observable<RuumDropdownSectionList>;

    constructor(
        private appStoreWrapper: AppStoreWrapper,
        private alertService: RuumAlertService,
        private selectedTemplateService: SelectedTemplateService,
        private templateRolesService: TemplateRolesService,
        private commonService: CommonService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.selectedDropdownOption$ = this.selectedDropdownOption();
        this.dropdownOptions$ = this.getDropdownOptions();
    }

    onChangeOption(option) {
        switch (option.id) {
            case TEMPLATE_ADMIN:
                return this.setRole(this.participant.id, 'TemplateAdmin');
            case TEMPLATE_EDITOR:
                return this.setRole(this.participant.id, 'TemplateEditor');
            case TEMPLATE_VIEWER:
                return this.setRole(this.participant.id, 'TemplateViewer');
            case 'LEAVE_TEMPLATE':
                return this.leaveTemplate();
            case 'REMOVE_FROM_TEMPLATE':
                return this.removeFromTemplate();
            default:
                return;
        }
    }

    private selectedDropdownOption(): Observable<RuumDropdownItem> {
        return combineLatest([this.getRoles(), this.getParticipant()]).pipe(
            map(([roleOptions, participant]) => {
                return roleOptions.find((option) => option.id === participant.role);
            }),
        );
    }

    private getDropdownOptions(): Observable<RuumDropdownSectionList> {
        return combineLatest([this.getRoles(), this.getLeaveOptions(), this.getOptionRules()]).pipe(
            map(([roleOptions, leaveOptions, optionRules]) => {
                return [
                    filterDropdownList(roleOptions, optionRules),
                    filterDropdownList(leaveOptions, optionRules),
                ].filter((list) => list.length);
            }),
        );
    }

    private getOptionRules(): Observable<RuumDropdownItemFilters> {
        return combineLatest([
            this.getLoggedUser(),
            this.getParticipant(),
            this.isMSTeams(),
            this.isSimplifiedView(),
        ]).pipe(
            map(([loggedUser, participant, isMSTeams, isSimplifiedView]) => {
                return [
                    {
                        id: TEMPLATE_ADMIN,
                        isDisabled: () => !(loggedUser.role === TEMPLATE_ADMIN),
                        disabledTooltip: { title: 'Only Admins can do it.', placement: 'left' },
                    },
                    {
                        id: TEMPLATE_EDITOR,
                        isDisabled: () => !(loggedUser.role === TEMPLATE_ADMIN),
                        disabledTooltip: { title: 'Only Admins can do it.', placement: 'left' },
                    },
                    {
                        id: TEMPLATE_VIEWER,
                        isDisabled: () => !(loggedUser.role === TEMPLATE_ADMIN),
                        disabledTooltip: { title: 'Only Admins can do it.', placement: 'left' },
                    },
                    {
                        id: 'REMOVE_FROM_TEMPLATE',
                        isDisabled: () => !(loggedUser.role === TEMPLATE_ADMIN),
                        disabledTooltip: { title: 'Only Admins can do it.', placement: 'left' },
                        isHidden: () =>
                            participant.id === loggedUser.id || this.hideLeaveOptions || isMSTeams || isSimplifiedView,
                    },
                    {
                        id: 'LEAVE_TEMPLATE',
                        isHidden: () =>
                            participant.id !== loggedUser.id || this.hideLeaveOptions || isMSTeams || isSimplifiedView,
                    },
                ];
            }),
        );
    }

    private getLeaveOptions(): Observable<RuumDropdownList> {
        return of([
            { id: 'LEAVE_TEMPLATE', name: 'Leave Template', theme: 'danger' },
            { id: 'REMOVE_FROM_TEMPLATE', name: 'Remove from Template', theme: 'danger' },
        ]);
    }

    private isMSTeams(): Observable<boolean> {
        return this.appStoreWrapper
            .msTeamsStage()
            .pipe(distinctUntilChanged())
            .pipe(
                map((msTeamsStage) => {
                    return !!msTeamsStage;
                }),
            );
    }

    private isSimplifiedView(): Observable<boolean> {
        return this.appStoreWrapper
            .simplifiedView()
            .pipe(distinctUntilChanged())
            .pipe(
                map((simplifiedView) => {
                    return !!simplifiedView;
                }),
            );
    }

    private async setRole(userId: string, role: TemplateParticipantRole) {
        this.commonService.setBusy(true, 'template-team');
        try {
            const promise = this.selectedTemplateService.participants().pipe(take(2)).toPromise();
            await this.selectedTemplateService.setParticipantRole(userId, role);
            await promise;
        } catch (err) {
            console.error(err);
            this.alertService.info(err.message);
        } finally {
            this.commonService.setBusy(false, 'template-team');
        }
    }

    private removeFromTemplate(): void {
        this.selectedTemplateService
            .removeUserFromTemplate(this.participant.id)
            .catch((error) => this.alertService.info(error.message));
    }
    private leaveTemplate(): void {
        this.selectedTemplateService
            .removeUserFromTemplate(this.participant.id)
            .then((value) => {
                switch (value) {
                    case 'ok':
                        this.router.navigateByUrl('/templates');
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => this.alertService.info(error.message));
    }

    private getRoles(): Observable<RuumDropdownList> {
        return of(this.templateRolesService.getRoles());
    }

    private getLoggedUser(): Observable<TemplateParticipant> {
        return of(this.loggedUser).pipe(filter((loggedUser) => !!loggedUser));
    }

    private getParticipant(): Observable<TemplateParticipant> {
        return of(this.participant).pipe(filter((participant) => !!participant));
    }
}
