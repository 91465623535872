import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessApprovalStep, ProcessCanvasStep, Processes } from '@ruum/ruum-reducers';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CommonService } from '../../../common/common.service';
import { RuumAlertService } from '../../../common/components/alert/alert.service';
import { ProcessService } from '../../../common/connectors/project/process/process.service';
import { SelectedProjectService } from '../../../common/connectors/project/selectedProject.service';
import { TemplateListItemService } from '../../../common/connectors/templates/template-list-item.service';
import { CanvasService } from '../../../ruum/canvas/canvas.service';
import { SectionsService } from '../../../ruum/section/sections.service';

@Component({
    selector: 'ruum-canvas-section-context-menu',
    template: `
        <ruum-menu (isOpenChange)="calculatePosition($event)">
            <ruum-menu-trigger>
                <button
                    class="btn btn-icon"
                    aria-label="Show canvas section related options"
                    [class.btn-link-white-64]="parentInfo === 'navBar'"
                    [class.btn-link-secondary]="parentInfo !== 'navBar'"
                >
                    <i class="icon icon-contextual-menu"></i>
                </button>
            </ruum-menu-trigger>
            <ng-container *ngIf="(isReadOnly$ | async) === false; else readOnlyOptions">
                <ruum-menu-option *ngIf="!isFirst" icon="icon-cevron-up" (onClick)="executeAction('up')"
                    >Move Up</ruum-menu-option
                >
                <ruum-menu-option *ngIf="!isLast" icon="icon-cevron-down" (onClick)="executeAction('down')"
                    >Move Down</ruum-menu-option
                >
                <ruum-menu-option
                    *ngIf="{
                        disabled: sectionAddDeleteBlocked$ | async,
                        disableTooltip: (isSectionContentLocked$ | async) === false
                    } as data"
                    icon="icon-duplicate"
                    [disabled]="data.disabled"
                    [disableTooltip]="data.disableTooltip"
                    [placement]="['left', 'right']"
                    ngbTooltip="This Section is locked"
                    (onClick)="!data.disabled && executeAction('duplicate')"
                    >Duplicate Section</ruum-menu-option
                >
                <ruum-menu-option icon="icon-template" (onClick)="executeAction('template')"
                    >Create Template</ruum-menu-option
                >
                <ruum-menu-devider></ruum-menu-devider>
                <ruum-menu-option
                    *ngIf="{
                        disabled: isSectionDeletionDisabled$ | async,
                        disableTooltip: (isSectionContentLocked$ | async) === false,
                        tooltip: deleteReason$ | async
                    } as data"
                    theme="danger"
                    icon="icon-delete"
                    [disabled]="data.disabled"
                    [disableTooltip]="data.disableTooltip"
                    [placement]="['left', 'right']"
                    [ngbTooltip]="data.tooltip"
                    (onClick)="!data.disabled && executeAction('delete')"
                    >Delete Section</ruum-menu-option
                >
            </ng-container>
            <ng-template #readOnlyOptions>
                <ruum-menu-option icon="icon-collapse" (onClick)="executeAction('collapse')"
                    >Collapse Section</ruum-menu-option
                >
                <ruum-menu-option icon="icon-expand" (onClick)="executeAction('expand')"
                    >Expand Section</ruum-menu-option
                >
            </ng-template>
        </ruum-menu>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasSectionContextMenuComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-inline-block';

    @Input() sectionId: string;
    @Input() parentInfo: string;

    @Output() isOpenChange = new EventEmitter<boolean>();

    isReadOnly$: Observable<boolean>;
    isSectionContentLocked$: Observable<boolean>;
    isSectionDeletionDisabled$: Observable<boolean>;
    sectionAddDeleteBlocked$: Observable<boolean>;
    isSectionPartOfProcess$: Observable<boolean>;
    deleteReason$: Observable<string>;
    isFirst = false;
    isLast = false;

    constructor(
        private sectionService: SectionsService,
        private processService: ProcessService,
        private alertService: RuumAlertService,
        private commonService: CommonService,
        private canvasService: CanvasService,
        private templateListItemService: TemplateListItemService,
        private selectedProjectService: SelectedProjectService,

        private router: Router,
    ) {}

    ngOnInit() {
        this.isReadOnly$ = this.selectedProjectService.isReadOnly().pipe(shareReplay(1));
        this.isSectionContentLocked$ = this.sectionService.isSectionContentLocked(this.sectionId).pipe(shareReplay(1));
        this.isSectionPartOfProcess$ = this.processService
            .getProcesses()
            .pipe(map(this.isSectionPartOfProcess(this.sectionId)));
        this.sectionAddDeleteBlocked$ = this.selectedProjectService.selectedProject().pipe(
            map((project) => project.configuration && project.configuration.sectionAddDeleteBlocked),
            shareReplay(1),
        );
        this.deleteReason$ = this.sectionAddDeleteBlocked$.pipe(
            map((sectionAddDeleteBlocked) =>
                sectionAddDeleteBlocked
                    ? `This action was blocked in this ruum's template settings.`
                    : 'This Section is locked.',
            ),
        );

        this.isSectionDeletionDisabled$ = combineLatest([
            this.isSectionContentLocked$,
            this.sectionAddDeleteBlocked$,
            this.isSectionPartOfProcess$,
            this.selectedProjectService.isTemplate(),
        ]).pipe(
            map(
                ([isLocked, sectionAddDeleteBlocked, isPartOfProcess, isTemplate]) =>
                    (isPartOfProcess && !isTemplate) || isLocked || sectionAddDeleteBlocked,
            ),
        );
    }

    private isSectionPartOfProcess(sectionId: string) {
        return (processes: Processes): boolean => {
            for (const process of Object.values(processes)) {
                for (const step of Object.values(process.steps)) {
                    if (
                        (step as ProcessCanvasStep).sectionId === this.sectionId ||
                        (step as ProcessApprovalStep).sectionId === this.sectionId
                    ) {
                        return true;
                    }
                }
            }
            return false;
        };
    }

    calculatePosition(isOpen: boolean) {
        if (isOpen) {
            this.isFirst = this.sectionService.isFirstSection(this.sectionId);
            this.isLast = this.sectionService.isLastSection(this.sectionId);
        }
        this.isOpenChange.emit(isOpen);
    }

    executeAction(action: string) {
        switch (action) {
            case 'delete':
                this.deleteSection();
                break;
            case 'up': {
                this.sectionService.moveSectionUp(this.sectionId);
                break;
            }
            case 'down': {
                this.sectionService.moveSectionDown(this.sectionId);
                break;
            }
            case 'collapse': {
                this.sectionService.collapseSection(this.sectionId);
                break;
            }
            case 'expand': {
                this.sectionService.expandSection(this.sectionId);
                break;
            }
            case 'duplicate': {
                this.sectionService.duplicateSection(this.sectionId).then((res: { id: string }) => {
                    this.scrollToNodeId(res.id);
                });
                break;
            }
            case 'template': {
                this.createSectionTemplate();
                break;
            }
        }
    }

    private scrollToNodeId(nodeId: string): void {
        setTimeout(() => {
            this.canvasService.scrollToNodeId(nodeId);
        }, 500);
    }

    private createSectionTemplate() {
        this.commonService.setBusy(true);
        this.templateListItemService
            .createSectionTemplateFromDeprecated(this.sectionId)
            .then((ruumId) => {
                this.commonService.setBusy(false);
                this.router.navigateByUrl(`/templates/sections/${ruumId}`);
            })
            .catch((err) => {
                this.commonService.setBusy(false);
                this.alertService.info(`Error creating section template`);
            });
    }

    private deleteSection() {
        this.alertService
            .warning({
                title: 'Do you want to permanently delete this section? This will also delete all tasks, milestones, files, and emails which are in this section.',
                actionText: 'Delete',
            })
            .then(
                () => {
                    this.sectionService.delete(this.sectionId);
                },
                () => {},
            );
    }
}
