import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruumTemplateColorName' })
export class TemplateColorNamePipe implements PipeTransform {
    transform(colorName: string): string {
        if (colorName.toLowerCase() === 'primary') {
            return 'blue';
        }

        return colorName;
    }
}
