import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ACTION_VERSIONS, RuumAction } from '@ruum/ruum-reducers';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { AppState } from './../common/app.store';
import { getRandomId, isMobile } from './../common/utils.service';
import { RandomStringGenerator } from './../common/utils/randomStringGenerator.service';
import { scrollIntoView, SCROLL_TIME } from './directive/smooth-scroll.service';
import { StoreExceptionCatcher } from './storeExceptionCatcher.service';

@Injectable({ providedIn: 'root' })
export class ServiceHelper implements OnDestroy {
    private PROJECT_SERVICE_URL = environment.PROJECT_SERVICE_URL;
    private highlightElementObs = new Subject<Element>();
    private destroyObs = new Subject();

    constructor(
        private authService: AuthService,
        private store: Store<AppState>,
        private randomStringGenerator: RandomStringGenerator,
        private router: Router,
        private storeExceptionCatcher: StoreExceptionCatcher,
    ) {
        this.highlightElementObs.pipe(debounceTime(SCROLL_TIME)).subscribe((nodeDOM) => {
            nodeDOM.classList.add('highlight-flashing');
            setTimeout(() => nodeDOM.classList.remove('highlight-flashing'), 1600);
        });
    }

    getAction(actionType: string, payload: any): RuumAction {
        const action = this.getActionWithoutCreatedBy(actionType, payload);
        const loggedUser = this.authService.getLoggedUser();
        if (!loggedUser && !window.location.pathname.startsWith('/auth')) {
            // this.router.navigateByUrl('/auth'); // TODO: lobby list triggers it too early
        } else {
            action.createdBy = loggedUser && loggedUser.id;
        }
        return action;
    }

    private getActionWithoutCreatedBy(actionType: string, payload: any): RuumAction {
        const action: RuumAction = {
            type: actionType,
            at: Date.now(),
            createdBy: undefined,
            payload,
            events: [],
            version: ACTION_VERSIONS[actionType],
        };
        action[`_id`] = `${actionType}_${action.at}_${this.randomStringGenerator.next(4)}`;
        return action;
    }

    dispatchWithoutPersisting<T extends RuumAction>(
        actionType: T['type'],
        payload: T['payload'],
        entityId?: string,
    ): Promise<void> {
        const action = this.getAction(actionType, payload);
        action.entityId = entityId;
        const promise = this.storeExceptionCatcher.waitForResult(action);
        this.store.dispatch(action);
        return promise;
    }

    dispatchWithoutUserWithoutPersisting(actionType: string, payload: any) {
        const action = this.getActionWithoutCreatedBy(actionType, payload);
        this.store.dispatch(action);
    }

    getRandomId(prefix?: string) {
        return getRandomId(prefix);
    }

    scrollToNodeId(prefix: string, id: string, highlight: boolean = true) {
        try {
            const nodeDOM = document.querySelector(`[id="${prefix}-${id}"]`);
            if (nodeDOM) {
                if (isMobile() || !scrollIntoView(nodeDOM)) {
                    if ((Element.prototype as any).scrollIntoViewIfNeeded) {
                        // scrollIntoViewIfNeeded is available on limited browser but better than scrollIntoView()
                        (nodeDOM as any).scrollIntoViewIfNeeded();
                    } else {
                        nodeDOM.scrollIntoView();
                    }
                }
                if (highlight) {
                    this.highlightElementObs.next(nodeDOM);
                }
            }
        } catch (e) {}
    }

    ngOnDestroy(): void {
        this.destroyObs.next();
        this.destroyObs.complete();
    }
}
