import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { concatAll, filter, mapTo } from 'rxjs/operators';
import { PaywallPrivilege } from '../../../../paywall/paywall-privilege';
import { PaywallService } from '../../../../paywall/paywall.service';
import { SelectableTag } from '../../../lobby.model';
import { AppStoreWrapper } from './../../../../common/appStoreWrapper.service';

@Component({
    selector: 'lobby-list-item-labels',
    template: `
        <div
            class="d-flex flex-fill flex-wrap rounded tag-list"
            [class.cursor-pointer]="!isReadOnly"
            [class.pt-1]="labels?.length"
            [class.pl-1]="labels?.length"
            [placement]="['left-top', 'left-bottom']"
            autoClose="outside"
            [container]="'body'"
            [ngbPopover]="popoverContent"
            [disablePopover]="disablePopover"
            requiredPrivilege="tagCreation"
            rejectStrategy="basic"
            [rejectStrategyData]="{ featureName: 'Add label' }"
            #popover="ngbPopover"
        >
            <ng-template ngFor let-index="index" let-label [ngForOf]="labels">
                <div class="d-flex item-label mb-1 mr-1" *ngIf="index < 4">
                    <ruum-tag-v1 [name]="label" [size]="size" [clickable]="false" [selected]="isSelected(label)">
                    </ruum-tag-v1>
                </div>
                <div class="item-label mb-1 mr-1" *ngIf="index === 4">
                    <ruum-tag-v1 [size]="size" [name]="'...'" [clickable]="false"></ruum-tag-v1>
                </div>
            </ng-template>
        </div>

        <button
            *ngIf="labels && labels.length === 0"
            class="btn btn-round btn-outline-light pl-2 mt-1 ruum-add-label"
            [class.btn-xs]="size === 'xs'"
            [class.btn-sm]="size === 'sm'"
            [class.btn-lg]="size === 'lg'"
            [class.ml-1]="size === 'sm'"
            type="button"
            requiredPrivilege="tagCreation"
            rejectStrategy="basic"
            [rejectStrategyData]="{ featureName: 'Add label' }"
            (requiredPrivilege.click)="popover.open()"
            [disabled]="isReadOnly"
        >
            <i class="mr-1 icon icon-add"></i>
            <span class="text-tiny font-weight-bold" [class.tiny]="size === 'xs'" [class.small]="size === 'sm' || 'lg'"
                >Add label</span
            >
        </button>

        <ng-template #popoverContent>
            <form [formGroup]="form" (ngSubmit)="addNewLabel(form.get('newLabel').value)" *ngIf="!isReadOnly">
                <div class="d-flex flex-wrap flex-fill popover-body">
                    <div
                        *ngIf="labels.length !== 0"
                        class="d-flex flex-wrap flex-fill pl-3 pr-2 pt-3 pb-2 w-100 labels-list"
                    >
                        <div class="d-flex item-editable-label mb-1 mr-1" *ngFor="let label of labels">
                            <ruum-tag-v1
                                [name]="label"
                                [size]="size"
                                [showRemoveIcon]="true"
                                (click)="removeLabel.emit(label)"
                            >
                            </ruum-tag-v1>
                        </div>
                    </div>
                    <div *ngIf="labels.length !== 0" class="ruum-divider w-100"></div>
                    <div
                        class="d-flex flex-wrap flex-fill flex-column px-3 pb-3 w-100"
                        [class.pt-3]="labels.length === 0"
                        [class.pt-2]="labels.length !== 0"
                    >
                        <div class="form-group mb-0">
                            <label class="form-default-label" for="newLabel">Add labels </label>
                            <input
                                id="newLabel"
                                class="form-control form-control-sm"
                                type="text"
                                placeholder="{{ otheLabels.length ? 'Search or create labels' : 'Create labels' }}"
                                formControlName="newLabel"
                            />
                        </div>
                    </div>
                    <div
                        *ngIf="filteredLabels.length !== 0"
                        class="d-flex flex-wrap flex-fill pl-3 pr-2 pt-1 pb-2 w-100"
                    >
                        <div class="d-flex flex-wrap flex-fill labels-list">
                            <div class="d-flex item-editable-label mb-1 mr-1" *ngFor="let label of filteredLabels">
                                <ruum-tag-v1 [name]="label" [size]="size" (click)="addNewLabel(label)"></ruum-tag-v1>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="filteredLabels.length === 0 && form.get('newLabel').value"
                        class="d-flex flex-wrap flex-fill px-3 pt-1 pb-2 w-100"
                    >
                        <div class="d-flex">
                            <button
                                class="btn btn-xs btn-link-primary pl-0"
                                type="button"
                                (click)="addNewLabel(form.get('newLabel').value)"
                            >
                                <span class="text-tiny font-weight-bold">Create</span>
                            </button>
                            <div class="item-new-label">
                                <ruum-tag-v1
                                    [name]="form.get('newLabel').value"
                                    [size]="size"
                                    (click)="addNewLabel(form.get('newLabel').value)"
                                >
                                </ruum-tag-v1>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-template>
    `,
    styles: [
        `
            .tag-list {
                max-width: 208px;
            }

            .tag-list:hover {
                background: rgba(37, 44, 66, 0.04);
            }

            .item-label {
                max-width: 120px;
            }

            .item-new-label {
                max-width: 160px;
            }

            .item-editable-label {
                max-width: 208px;
            }

            .popover-body {
                width: 232px;
            }

            .labels-list {
                max-height: 212px;
                overflow: auto;
            }

            .ruum-add-label {
                min-width: 91px;
                max-width: 110px;
            }
        `,
    ],
})
export class LobbyListItemLabelsComponent implements OnInit, OnDestroy, OnChanges {
    @HostBinding('class') hostClassList = 'd-flex flex-column';

    @Input()
    labels: string[] = [];

    @Input()
    size: string;

    @Input()
    isReadOnly = false;

    @Output()
    addLabel = new EventEmitter();

    @Output()
    removeLabel = new EventEmitter();

    allLabels: SelectableTag[] = [];

    otheLabels: string[] = [];

    filteredLabels: string[] = [];

    // eslint-disable-next-line
    private _disablePopover = true;

    form: FormGroup = this.formBuilder.group({
        newLabel: ['', [Validators.required]],
    });

    private subscriptions = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private appStoreWrapper: AppStoreWrapper,
        private paywallService: PaywallService,
    ) {}

    ngOnInit() {
        const appStoreSubscription = this.appStoreWrapper.tagsList().subscribe((allLabels) => {
            this.allLabels = allLabels;
            this.otheLabels = this.getOtheLabels(allLabels, this.labels);
            this.filteredLabels = this.getFilteredLabels(this.form.get('newLabel').value, this.otheLabels);
        });

        this.subscriptions.add(appStoreSubscription);

        const formSubscription = this.form.get('newLabel').valueChanges.subscribe((value) => {
            this.filteredLabels = this.getFilteredLabels(value, this.otheLabels);
        });

        this.subscriptions.add(formSubscription);

        const grantedPrivilegesSubscription = this.paywallService
            .grantedPrivileges()
            .pipe(
                concatAll(),
                filter((privilege) => privilege === PaywallPrivilege.TAG_CREATION),
                mapTo(false),
            )
            .subscribe((disablePopover) => {
                this._disablePopover = disablePopover;
            });

        this.subscriptions.add(grantedPrivilegesSubscription);
    }

    ngOnChanges(values) {
        if (values && values.labels && values.labels.currentValue) {
            this.otheLabels = this.getOtheLabels(this.allLabels, values.labels.currentValue);
            this.filteredLabels = this.getFilteredLabels(this.form.get('newLabel').value, this.otheLabels);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get disablePopover(): boolean {
        return this._disablePopover;
    }

    addNewLabel(label) {
        this.addLabel.emit(label);
        this.form.get('newLabel').setValue('');
    }

    isSelected(label): boolean {
        const el = this.allLabels.find((labelItem) => labelItem.tag === label);
        if (el) {
            return el.selected;
        } else {
            return false;
        }
    }

    private getOtheLabels(allLabels, labels = []) {
        return allLabels
            .filter((el) => labels.indexOf(el.tag) === -1)
            .sort((a, b) => (a.tag.toLowerCase() < b.tag.toLowerCase() ? -1 : 1))
            .map((el) => el.tag);
    }

    private getFilteredLabels(filterPattern, labels) {
        if (filterPattern) {
            return labels.filter((label) => label.match(filterPattern));
        } else {
            return labels;
        }
    }
}
