import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import {
    RuumDropdownItem,
    RuumDropdownItemSize,
    RuumDropdownPlacement,
    RuumDropdownSectionList,
} from '../../../shared/ui-components/dropdown/dropdown.model';

@Component({
    selector: 'ruum-participant-options-dropdown',
    template: `
        <ruum-dropdown-wrapper>
            <!-- desktop -->
            <ruum-dropdown-desktop #dropdownDesktop [placement]="placement" [isReadOnly]="isReadOnly">
                <!-- button -->
                <button
                    class="btn {{ componentClass ? componentClass : 'btn-sm btn-link-secondary' }}"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                    type="button"
                    [disabled]="isReadOnly"
                >
                    <span>{{ selectedItem?.name }}</span>
                    <i class="icon icon-cevron-down"></i>
                </button>
                <!-- menu -->
                <ruum-dropdown-section-list
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                    style="width: 276px;"
                    [sectionList]="sectionList"
                    (selectItem)="onSelect($event, dropdownDesktop)"
                >
                </ruum-dropdown-section-list>
            </ruum-dropdown-desktop>

            <!-- mobile -->
            <ruum-dropdown-mobile #dropdownMobile [isReadOnly]="isReadOnly">
                <!-- button -->
                <button
                    class="btn {{ componentClass ? componentClass : 'btn-sm btn-link-secondary' }}"
                    data-content="ruum-dropdown-button"
                    data-test="ruum-dropdown-button"
                    type="button"
                    [disabled]="isReadOnly"
                >
                    <span>{{ selectedItem?.name }}</span>
                    <i class="icon icon-cevron-down"></i>
                </button>
                <!-- menu -->
                <ruum-dropdown-section-list
                    data-content="ruum-dropdown-menu"
                    data-test="ruum-dropdown-menu"
                    [sectionList]="sectionList"
                    [size]="'lg'"
                    (selectItem)="onSelect($event, dropdownMobile)"
                >
                </ruum-dropdown-section-list>
            </ruum-dropdown-mobile>
        </ruum-dropdown-wrapper>
    `,
})
export class RuumParticipantOptionsDropdownComponent implements OnInit {
    @HostBinding('class') hostClassList = 'd-flex';

    @Input()
    selectedItem: RuumDropdownItem;

    @Input()
    sectionList: RuumDropdownSectionList;

    @Input()
    size: RuumDropdownItemSize = 'xs';

    @Input()
    componentClass: string;

    @Input()
    placement: RuumDropdownPlacement = ['bottom-right', 'top-right', 'auto'];

    @Input()
    isReadOnly = false;

    @Output()
    itemChange = new EventEmitter<RuumDropdownItem>();

    ngOnInit() {}

    onSelect(item: RuumDropdownItem, dropdown) {
        dropdown.close();
        this.itemChange.emit(item);
    }
}
