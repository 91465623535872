import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { Column, ColumnValue, TableDirectiveService } from './table.directive.service';

interface ColumnSettings {
    id: string;
    width: number;
}
@Directive({
    selector: '[ruumResizableRow]',
})
export class ResizableRowDirective implements OnDestroy {
    @Input() displayedColumnsIds: string[];
    @Input()
    get columns(): Column[] {
        return this._columns$.getValue();
    }
    set columns(value: Column[]) {
        this._columns$.next(value);
    }

    @Output() columnsResized = new EventEmitter<ColumnValue[]>();

    private _columns$: BehaviorSubject<Column[]> = new BehaviorSubject<Column[]>([]);
    private subscriptions: Subscription[] = [];
    private nextColumnResizePageX: number;
    private nextColumnId: string;
    private newColumnsWidths = {};

    constructor(private element: ElementRef, private tableDirectiveService: TableDirectiveService) {
        this.init();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    init() {
        this.initUnresizableColumnsSubscription();
        this.initHoverEvent();
        this.initResizeEvent();
        this.initResizeSubscription();
    }

    initUnresizableColumnsSubscription(): void {
        const columnsSubscription = this._columns$
            .pipe(
                distinctUntilChanged(),
                filter((columns) => columns.length > 0),
            )
            .subscribe(this.initUnresizableColumns);

        this.subscriptions.push(columnsSubscription);
    }

    initUnresizableColumns = (columns: Column[]) => {
        columns.forEach((column) => {
            if (column.unresizable) {
                this.forbidColumnHighlight(column.fieldId);
            }
        });
    };

    initHoverEvent(): void {
        const headerRowMouseUps = fromEvent(this.element.nativeElement, 'mouseup');
        const headerRowMouseEnters = fromEvent(this.element.nativeElement, 'mouseenter').pipe(
            filter(
                () =>
                    this.tableDirectiveService.getHoveredColumnId() === '' &&
                    this.tableDirectiveService.getCurrentResizeId() === '',
            ),
        );

        const hoverStartsSubscriptions = merge(headerRowMouseUps, headerRowMouseEnters).subscribe(() => {
            this.trackMouseMoveOverHeader();
        });

        this.subscriptions.push(hoverStartsSubscriptions);
    }

    trackMouseMoveOverHeader(): void {
        const headerCellElement = document.querySelectorAll('.cdk-header-cell');
        const headerCellMouseMoves = fromEvent(headerCellElement, 'mousemove');
        const headerRowMouseLeaves = fromEvent(this.element.nativeElement, 'mouseleave');

        const mouseMovesSubscription = headerCellMouseMoves
            .pipe(
                takeUntil(headerRowMouseLeaves),
                finalize(() => {
                    this.tableDirectiveService.setHoveredColumnId('');
                }),
            )
            .subscribe(this.updateHeaderCellHoverInfo);

        this.subscriptions.push(mouseMovesSubscription);
    }

    updateHeaderCellHoverInfo = (mouseMoveEvent: MouseEvent): void => {
        const eventTarget = mouseMoveEvent.currentTarget as HTMLElement;
        const columnId = eventTarget.id;

        this.tableDirectiveService.setHoveredColumnId(columnId);
    };

    initResizeSubscription(): void {
        const resizeSubscription = this.tableDirectiveService
            .getCurrentResizeId$()
            .pipe(distinctUntilChanged())
            .pipe(filter((id) => id !== ''))
            .subscribe((id) => {
                this.resetNewColumnWidth(id);
                this.initColumnResize(id);
            });

        this.subscriptions.push(resizeSubscription);
    }

    resetNewColumnWidth(columnId: string): void {
        const column = this.getColumnById(columnId);

        this.newColumnsWidths[columnId] = column.width;
    }

    initColumnResize(columnId: string): void {
        const rowMouseMoves = fromEvent(this.element.nativeElement, 'mousemove');
        const rowMouseUps = fromEvent(this.element.nativeElement, 'mouseup');
        const rowMouseLeave = fromEvent(this.element.nativeElement, 'mouseleave');

        const columnResizeSubscription = rowMouseMoves
            .pipe(
                takeUntil(merge(rowMouseUps, rowMouseLeave)),
                finalize(() => {
                    const changedColumnsIds = [columnId];

                    if (this.nextColumnId) {
                        changedColumnsIds.push(this.nextColumnId);
                    }

                    this.columnsWidthChanged(changedColumnsIds);
                    this.clearWidthPresavedSettings();
                }),
            )
            .subscribe((event: { pageX: number }) => {
                this.updateWidthOnMouseMove(columnId, event.pageX);
            });

        this.subscriptions.push(columnResizeSubscription);
    }

    clearWidthPresavedSettings() {
        this.nextColumnResizePageX = null;
        this.nextColumnId = null;
        this.newColumnsWidths = {};
    }

    columnsWidthChanged(columnIds: string[]): void {
        const changedColumns = columnIds.map((columnId) => ({
            fieldId: columnId,
            width: this.newColumnsWidths[columnId],
        }));

        this.columnsResized.emit(changedColumns);
    }

    updateWidthOnMouseMove(columnId: string, pageX: number): void {
        const column = this.getColumnById(columnId);
        const moveDistance = pageX - this.tableDirectiveService.getResizePageX();
        const newColumnWidth = column.width + moveDistance;
        const newTableWidth = this.calculateTableWidthOnResize(columnId, newColumnWidth);

        if (newColumnWidth > column.minWidth) {
            const nextColumn = this.getNextColumnById(columnId);

            if (newTableWidth > this.tableDirectiveService.getMinTableWidth()) {
                this.setColumnWidth(column, newColumnWidth);
            } else if (nextColumn) {
                this.setColumnWidth(column, newColumnWidth);
                this.resizeNextColumn(columnId, newColumnWidth, pageX);
            }
        }
    }

    resizeNextColumn(currentColumnId: string, currentColumnNewWidth: number, resizeEventPageX: number): void {
        const nextColumn = this.getNextColumnById(currentColumnId);

        if (!this.nextColumnResizePageX) {
            this.nextColumnResizePageX = resizeEventPageX;
        }

        const nextColumnResizeDistance = resizeEventPageX - this.nextColumnResizePageX;
        const nextColumnNewWidth = nextColumn.width - nextColumnResizeDistance;

        this.nextColumnId = nextColumn.fieldId;

        const currentColumnNewSettings = {
            id: currentColumnId,
            width: currentColumnNewWidth,
        };
        const nextColumnNewSettings = {
            id: nextColumn.fieldId,
            width: nextColumnNewWidth,
        };

        const differenceWithMinTableWidth = this.minTableWidthDifference(
            currentColumnNewSettings,
            nextColumnNewSettings,
        );

        this.setColumnWidth(nextColumn, nextColumnNewWidth + differenceWithMinTableWidth);
    }

    getColumnById(columnId: string): Column {
        return this.columns.find((column) => column.fieldId === columnId);
    }

    getNextColumnById(columnId: string): Column {
        const columnIndex = this.columns.findIndex((column) => column.fieldId === columnId);

        if (columnIndex === this.columns.length - 1) {
            return null;
        }

        return this.columns[columnIndex + 1];
    }

    setColumnWidth(column: Column, width: number): void {
        this.setColumnDOMWidth({
            ...column,
            width,
        });

        this.newColumnsWidths[column.fieldId] = width;
    }

    setColumnDOMWidth(column: Column): void {
        const columnElements = Array.from(document.getElementsByClassName('cdk-column-' + column.fieldId));

        columnElements.forEach((element: HTMLDivElement) => {
            element.style.width = column.width + 'px';
        });
    }

    forbidColumnHighlight(columnId: string): void {
        this.element.nativeElement.childNodes.forEach((childNode: HTMLDivElement) => {
            if (childNode.id === columnId) {
                childNode.className += ' right-devider-hidden';
            }
        });
    }

    initResizeEvent(): void {
        const headerRowMouseDowns = fromEvent(this.element.nativeElement, 'mousedown');

        const startResizeSubscription = headerRowMouseDowns.subscribe((event: { pageX: number }) => {
            const clickedColumnId = this.tableDirectiveService.getCurrentClickId();

            if (clickedColumnId) {
                this.initResizableColumn(event.pageX);
            }
        });

        const headerRowMouseUps = fromEvent(this.element.nativeElement, 'mouseup');
        const headerRowMouseLeaves = fromEvent(this.element.nativeElement, 'mouseleave');

        const endResizeSubscription = merge(headerRowMouseUps, headerRowMouseLeaves).subscribe(() => {
            this.tableDirectiveService.setCurrentResizeId('');
        });

        this.subscriptions.push(startResizeSubscription);
        this.subscriptions.push(endResizeSubscription);
    }

    initResizableColumn(pageX: number): void {
        const resizedColumn = this.getResizedColumn(pageX);

        if (!resizedColumn.unresizable) {
            this.tableDirectiveService.setCurrentResizeId(resizedColumn.fieldId);
            this.tableDirectiveService.setResizePageX(pageX);
        }
    }

    getResizedColumn(pageX: number): Column {
        const isCloseToRightBorder = this.clickPointIsCloseToRightColumnBorder(pageX);

        if (isCloseToRightBorder || this.clickColumnIsFirst()) {
            const resizedColumnId = this.tableDirectiveService.getCurrentClickId();

            return this.getColumnById(resizedColumnId);
        }
        return this.getLeftSibling();
    }

    clickPointIsCloseToRightColumnBorder(pageX: number): boolean {
        const cellBoundaries = this.getClickedCellBoundaries();

        const clickPointIsCloseToRightColumnBorder = Math.abs(pageX - cellBoundaries.right) < cellBoundaries.width / 2;

        return clickPointIsCloseToRightColumnBorder;
    }

    getClickedCellBoundaries(): ClientRect {
        const columnNodes = this.element.nativeElement.childNodes;
        const currentClickId = this.tableDirectiveService.getCurrentClickId();
        let currentColumnNode: HTMLDivElement;

        columnNodes.forEach((columnNode: HTMLDivElement) => {
            if (columnNode.id === currentClickId) {
                currentColumnNode = columnNode;
            }
        });

        return currentColumnNode.getBoundingClientRect();
    }

    getLeftSibling(): Column {
        const currentClickId = this.tableDirectiveService.getCurrentClickId();
        const columnIndex = this.columns.findIndex((column) => column.fieldId === currentClickId);

        if (columnIndex > 0) {
            return this.columns[columnIndex - 1];
        }

        return null;
    }

    getClickColumnIndex(): number {
        const columnNodes = this.element.nativeElement.childNodes;
        const currentClickId = this.tableDirectiveService.getCurrentClickId();
        let columnIndex = -1;

        columnNodes.forEach((columnNode: HTMLDivElement, index: number) => {
            if (columnNode.id === currentClickId) {
                columnIndex = index;
            }
        });

        return columnIndex;
    }

    clickColumnIsFirst(): boolean {
        return this.getClickColumnIndex() === 0;
    }

    calculateTableWidthOnResize(columnId: string, resizedColumnWidth: number): number {
        return this.columns.reduce((tableWidth, column) => {
            if (column.fieldId === columnId) {
                return tableWidth + resizedColumnWidth;
            }
            return tableWidth + column.width;
        }, 0);
    }

    minTableWidthDifference(currentColumn: ColumnSettings, nextColumn: ColumnSettings): number {
        const newTableWidth = this.columns.reduce((tableWidth, column) => {
            if (column.fieldId === currentColumn.id) {
                return tableWidth + currentColumn.width;
            }

            if (column.fieldId === nextColumn.id) {
                return tableWidth + nextColumn.width;
            }

            return tableWidth + column.width;
        }, 0);

        const minTableWidth = this.tableDirectiveService.getMinTableWidth();

        return newTableWidth < minTableWidth ? minTableWidth - newTableWidth : 0;
    }
}
