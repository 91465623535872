import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
    bufferTime,
    catchError,
    concatAll,
    distinctUntilChanged,
    filter,
    map,
    retry,
    startWith,
    take,
    tap,
} from 'rxjs/operators';

export abstract class StoreLoader<T> {
    private load$: Subject<string> = new Subject();
    private readonly MAX_BATCH_SIZE = 25;

    constructor() {
        this.listenToLoad();
    }

    dataList(ids: string[]): Observable<T[]> {
        return combineLatest(ids.map((id) => this.data(id))).pipe(startWith([]));
    }

    data(id: string): Observable<T> {
        if (!id) {
            console.error(`Store Loader invalid id ${id}`);
            return of(<any>{});
        }

        this.maybeLoad(id);
        return this.getStoreData().pipe(
            map((userMap) => userMap[id]),
            filter((userListItem) => !!userListItem),
            distinctUntilChanged(),
        );
    }

    reload(id: string): void {
        this.load$.next(id);
    }

    /** Gets the data from the server */
    protected abstract getData(ids: string[]): Observable<T[]>;

    /** Stores the retrived data. */
    protected abstract storeData(data: T[]): void;

    /** Called when loading an ID from the server fails. */
    protected abstract failedToLoad(id: string): void;

    /** Returns the data that is currently in store. */
    protected abstract getStoreData(): Observable<{ [id: string]: T }>;

    private async maybeLoad(id: string) {
        let storeData: { [id: string]: T };
        this.getStoreData()
            .pipe(
                tap((m) => (storeData = m)),
                take(1),
            )
            .subscribe();
        if (!storeData[id]) {
            this.load$.next(id);
        }
    }

    private listenToLoad() {
        this.load$
            .pipe(
                /** To reduce number of API calls. */
                bufferTime(50),
                filter((ids) => ids.length > 0),
                /** Get unique ids. */
                map((ids) => Object.keys(ids.reduce((obj, key) => (obj[key] = true) && obj, {}))),
                map((ids) =>
                    this.makeCalls(ids).pipe(
                        retry(1),
                        catchError((err) => {
                            console.error(`Error loading ${ids}`, err);
                            this.setErroredIDs(ids);
                            return of([]);
                        }),
                        map((list) => ({ ids, list })),
                    ),
                ),
                concatAll(),
            )
            .subscribe(({ ids, list }) => {
                this.storeData(list);
                this.maybeSetMissing(ids, list);
            });
    }

    private makeCalls(ids: string[]): Observable<T[]> {
        /** Too many IDs create a URL that is too big. */
        const chunks = this.chunk(ids, this.MAX_BATCH_SIZE);
        return combineLatest(chunks.map((idChunk) => this.getData(idChunk))).pipe(map((lists) => this.flatten(lists)));
    }

    private setErroredIDs(ids: string[]): void {
        ids.forEach((id) => {
            this.failedToLoad(id);
        });
    }

    /**
     * If one of the IDs is not returned we have to persist it in the store so that it does not enters a loop trying to retrieve it.
     */
    private maybeSetMissing(ids: string[], rows: T[]): void {
        const missing = ids.filter((id) => !rows.find((row) => this.getId(row) === id));
        missing.forEach((missingId) => {
            console.error(`Error loading ${missingId}. The server didn't return it.`);
            this.setErroredIDs(ids);
        });
    }

    /** The default behaviour is that T has a 'id' property. This method can be overriden if the propery name is different. */
    protected getId(object: T): string {
        return (<any>object).id;
    }

    private flatten(list: any[]) {
        if (!list) {
            return [];
        }
        return [].concat(...list);
    }

    private chunk(list: any[], size = 1) {
        size = Math.max(size, 0);
        const length = list === null ? 0 : list.length;
        if (!length || size < 1) {
            return [];
        }
        let index = 0;
        let resIndex = 0;
        const result = new Array(Math.ceil(length / size));

        while (index < length) {
            result[resIndex++] = list.slice(index, (index += size));
        }
        return result;
    }
}
