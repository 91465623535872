import { EntityAction, RuumAction } from '@ruum/ruum-reducers';
import { PaginatedList } from '../../../common/connectors/paginatedList.model';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { WebhookEntitySyncStateListItem } from '../webhooks.model';
import { WebhooksEntityEventsListReducer } from './events/webhook-entity-events-list.reducer';

export enum WEBHOOK_ENTITIES_LIST_ACTION_TYPES {
    WEBHOOK_ENTITIES_LOADED = 'WEBHOOK_ENTITIES_LOADED',
    SINGLE_WEBHOOK_ENTITY_LOADED = 'SINGLE_WEBHOOK_ENTITY_LOADED',
}

export type WebhookEntitiesLoadedAction = EntityAction<
    'WEBHOOK_ENTITIES_LOADED',
    {
        webhookId: string;
        page: PaginatedList<WebhookEntitySyncStateListItem>;
    }
>;

export type SingleWebhookEntityLoadedAction = EntityAction<
    'SINGLE_WEBHOOK_ENTITY_LOADED',
    {
        webhookId: string;
        entity: WebhookEntitySyncStateListItem;
    }
>;

export function WebhooksEntitiesListReducer(
    currentState: PaginatedList<WebhookEntitySyncStateListItem> = getDefaultPaginatedList(),
    action: RuumAction,
): PaginatedList<WebhookEntitySyncStateListItem> {
    switch (action.type) {
        case WEBHOOK_ENTITIES_LIST_ACTION_TYPES.WEBHOOK_ENTITIES_LOADED:
            return entitiesLoaded(currentState, action);
        case WEBHOOK_ENTITIES_LIST_ACTION_TYPES.SINGLE_WEBHOOK_ENTITY_LOADED:
            return singleEntityLoaded(currentState, action);
        default:
            return forwardActionToChildrenReducers(currentState, action);
    }
}

function entitiesLoaded(
    currentState: PaginatedList<WebhookEntitySyncStateListItem>,
    action: WebhookEntitiesLoadedAction,
): PaginatedList<WebhookEntitySyncStateListItem> {
    const data = action.payload.page;
    if (parseInt(data.currentPage as any, 10) > 1) {
        return {
            ...data,
            rows: currentState.rows.concat(data.rows),
        };
    } else {
        return {
            ...data,
            rows: data.rows,
        };
    }
}

function singleEntityLoaded(
    currentState: PaginatedList<WebhookEntitySyncStateListItem>,
    action: SingleWebhookEntityLoadedAction,
): PaginatedList<WebhookEntitySyncStateListItem> {
    const loadedWebhook = action.payload.entity;
    let added = false;

    const rows = currentState.rows.map((entity) => {
        if (isSameEntity(entity, loadedWebhook)) {
            added = true;
            return loadedWebhook;
        } else {
            return entity;
        }
    });

    if (!added) {
        rows.push(loadedWebhook);
    }

    return {
        ...currentState,
        rows,
    };
}

function isSameEntity(entityA: WebhookEntitySyncStateListItem, entityB: WebhookEntitySyncStateListItem): boolean {
    return entityA.entityId === entityB.entityId;
}

function forwardActionToChildrenReducers(
    currentState: PaginatedList<WebhookEntitySyncStateListItem>,
    action: RuumAction,
): PaginatedList<WebhookEntitySyncStateListItem> {
    if (action.payload && action.payload.entityId !== null) {
        currentState = augmentCurrentStateIfRequired(currentState, action);

        return {
            ...currentState,
            rows: currentState.rows.map((entity) => {
                if (entity.entityId === action.payload.entityId) {
                    return {
                        ...entity,
                        events: WebhooksEntityEventsListReducer(entity.events, action),
                    };
                } else {
                    return entity;
                }
            }),
        };
    } else {
        return currentState;
    }
}

/**
 * If one of the children reducers is firing an action and the parent entity was not yet loaded,
 * we will create a dummy structure so that the data structure in the store is maintained properly
 */

function augmentCurrentStateIfRequired(
    currentState: PaginatedList<WebhookEntitySyncStateListItem>,
    action: RuumAction,
): PaginatedList<WebhookEntitySyncStateListItem> {
    if (actionPayloadHasEntityKeys(action) && entityEntryNotYetCreatedOnStore(currentState, action)) {
        const rows = [
            ...currentState.rows,
            <WebhookEntitySyncStateListItem>{
                webhookId: action.payload.webhookId,
                entityId: action.payload.entityId,
            },
        ];

        return {
            ...currentState,
            rows,
        };
    } else {
        return currentState;
    }
}

function actionPayloadHasEntityKeys(action: RuumAction): boolean {
    return action.payload && !!action.payload.entityId && action.payload && !!action.payload.webhookId;
}

function entityEntryNotYetCreatedOnStore(
    currentState: PaginatedList<WebhookEntitySyncStateListItem>,
    action: RuumAction,
): boolean {
    return (
        currentState.rows.length === 0 ||
        !currentState.rows.find(
            (entity) => entity.webhookId === action.payload.webhookId && entity.entityId === action.payload.entityId,
        )
    );
}
