import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ruum-tabs',
    template: `
        <div class="tabs">
            <button
                *ngFor="let option of options; index as i"
                class="tab-button"
                (click)="onClickTab(option.id, i)"
                [class.active]="activeIndex === i"
            >
                <span>{{ option.text }}</span>
            </button>
            <div class="tab-button placeholder"></div>
        </div>
    `,
    styles: [
        `
            .tabs {
                display: flex;
                border-radius: 8px;
                margin-bottom: 32px;
            }
            .tab-button {
                height: 54px;
                color: #abaeaf;
                background-color: #ffffff;
                flex-grow: 1;
                border: none;
                border-style: solid;
                border-color: #ececec;
                position: relative;
            }
            .tab-button:not(.placeholder) {
                max-width: 140px;
            }
            .tab-button:not(.active) {
                border-width: 0 0 1px;
            }
            .tab-button.active {
                border-width: 0 1px;
                color: #173145;
                border-bottom: 1px solid transparent;
            }
            .tab-button.active:before {
                background-color: #0a6ed1;
                content: '';
                display: block;
                width: calc(100% + 2px);
                height: 2px;
                position: absolute;
                top: 0;
                left: -1px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
    @Output() tabChanged: EventEmitter<number> = new EventEmitter();
    @Input() options: TabOption[] = [];
    @Input() activeIndex = 0;

    onClickTab(optionId, index) {
        if (index !== this.activeIndex) {
            this.tabChanged.emit(optionId);
            this.activeIndex = index;
        }
    }
}

export interface TabOption {
    id: any;
    text: string;
}
