import { RuumAction } from '@ruum/ruum-reducers';
import { getDefaultPaginatedList } from '../../../lobby/lobby.reducer';
import { APPROVAL_LIST_ACTIONS } from './approval-list.actions';
import { ApprovalListReducer } from './approval-list.reducer';
import { ApprovalItemListItem, ApprovalListWrapper } from './approval.model';

function getDefault(): ApprovalListWrapper {
    return {
        root: getDefaultPaginatedList<ApprovalItemListItem>(),
        children: {},
        showingSubapprovals: {},
    };
}

// TODO ApprovalListWrapperReducer
export function ApprovalListWrapperReducer(
    currentState: ApprovalListWrapper = getDefault(),
    action: RuumAction,
): ApprovalListWrapper {
    if (!APPROVAL_LIST_ACTIONS[action.type]) {
        return currentState;
    }

    const newState: ApprovalListWrapper = {
        ...currentState,
        root: ApprovalListReducer(currentState.root, action),
    };

    return newState;
}
