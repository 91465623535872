import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ruum-admin-support-widget',
    template: `
        <ruum-max-content>
            <div class="d-flex flex-column h-100 mh-100 w-100">
                <table>
                    <tr>
                        <td>
                            <a
                                href="https://help.sap.com/viewer/d9b57ff016e941489cff8d1035b9aea5/Latest/en-US/0b07325520a14864a757a6ffa3118291.html"
                                target="_blank"
                                >Create a support ticket</a
                            >
                        </td>
                    </tr>
                </table>
            </div>
        </ruum-max-content>
    `,
    styles: [
        `
            table {
                margin-top: 20px;
                font-weight: bold;
                color: #173145;
            }
            td:first-child {
                width: 200px;
            }
            td {
                padding: 20px 0;
            }
        `,
    ],
})
export class SupportWidgetComponent {
    @HostBinding('class') hostClassList =
        'd-flex flex-column w-100 mw-100 h-100 mh-100 px-2 px-sm-4 px-md-5 px-lg-8 overflow-y';
}
