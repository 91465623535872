import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ACTION_TYPES } from './actions';
import { AppStoreWrapper } from './appStoreWrapper.service';
import { ServiceHelper } from './serviceHelper';

@Injectable({ providedIn: 'root' })
export class CommonService {
    constructor(private serviceHelper: ServiceHelper, private appStoreWrapper: AppStoreWrapper) {}

    setBusy(busy: boolean, componentId?: string) {
        this.serviceHelper.dispatchWithoutPersisting(ACTION_TYPES.SET_BUSY, {
            busy,
            componentId,
        });
    }

    isBusy(componentId: string): Observable<boolean> {
        return this.appStoreWrapper.busyStateOfComponent(componentId);
    }

    setBusyWithoutAuthentication(busy: boolean, componentId?: string) {
        this.serviceHelper.dispatchWithoutUserWithoutPersisting(ACTION_TYPES.SET_BUSY, {
            busy,
            componentId,
        });
    }

    setIEOrEdge(isIE: boolean, isEdge: boolean) {
        this.serviceHelper.dispatchWithoutUserWithoutPersisting(ACTION_TYPES.SET_IE_OR_EDGE, { isIE, isEdge });
    }

    setMsTeamsStage(stage: string) {
        this.serviceHelper.dispatchWithoutUserWithoutPersisting(ACTION_TYPES.SET_MS_TEAMS_STAGE, { stage });
    }

    setSimplifiedView(simplifiedView: boolean) {
        this.serviceHelper.dispatchWithoutUserWithoutPersisting(ACTION_TYPES.SET_SIMPLIFIED_VIEW, { simplifiedView });
    }

    setOffline(offline: boolean) {
        this.serviceHelper.dispatchWithoutUserWithoutPersisting(ACTION_TYPES.SET_OFFLINE, { offline });
    }
}
