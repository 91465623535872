import { Injectable } from '@angular/core';
import {
    RuumActionTypes,
    SetTaskIsMilestoneActionPayload,
    SetTaskStatusAction,
    TaskPriority,
    TaskStatus,
} from '@ruum/ruum-reducers';
import { SelectedProjectService } from '../../../app/common/connectors/project/selectedProject.service';
import { CanvasTasksService } from '../../ruum/canvas/sections/tasks/canvasTasks.service';
import { RuumTasksService } from '../../ruum/tasks/tasks.service';

@Injectable({ providedIn: 'root' })
export class ProjectTaskDetailsService {
    constructor(
        private tasksService: RuumTasksService,
        private canvasTasksService: CanvasTasksService,
        private projectService: SelectedProjectService,
    ) {}

    deleteTask(projectId: string, taskId: string, parentId: string): Promise<any> {
        return this.tasksService
            .askForDeletionConfirmation(taskId)
            .catch(() => 'cancel')
            .then((option) => {
                if (option === 'cancel') {
                    throw new Error('stop');
                }
                return this.tasksService.deleteTask(taskId);
            })
            .then(() => this.canvasTasksService.removeTaskFromCanvas(taskId))
            .catch((err) => {
                if (err.message !== 'stop') {
                    throw err;
                }
            });
    }

    changeStatus(taskId: string, parentId: string, status: TaskStatus) {
        this.projectService.persistAction<SetTaskStatusAction>('SET_TASK_STATUS', { status, taskId });
    }

    changePriority(taskId: string, parentId: string, priority: TaskPriority) {
        this.tasksService.setPriority(taskId, priority);
    }

    changeIsMilestone(taskId: string, parentId: string, isMilestone: boolean) {
        const payload: SetTaskIsMilestoneActionPayload = {
            taskId,
            isMilestone,
        };
        this.projectService.persistAction(RuumActionTypes.SET_TASK_IS_MILESTONE, payload);
    }
}
