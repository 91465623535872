import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class InputValidator {
    private static MEDIUM_LIMIT = 500;
    private static SHORT_LIMIT = 250;
    private static LONG_LIMIT = 1000;

    get shortLimit(): number {
        return InputValidator.SHORT_LIMIT;
    }
    get mediumLimit(): number {
        return InputValidator.MEDIUM_LIMIT;
    }
    get longLimit(): number {
        return InputValidator.LONG_LIMIT;
    }

    static whiteSpace(control: FormControl) {
        const controlValue = typeof control.value === 'string' ? control.value : '';
        const isWhitespace = controlValue.length !== 0 && controlValue.trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { onlywhitespace: true };
    }

    isEmpty(input: string): boolean {
        return input.length === 0;
    }

    isTooLong(input: string, maxLength: number): boolean {
        return input.length > maxLength;
    }

    isWhitespaceOnly(input: string): boolean {
        return input.length !== 0 && input.trim().length === 0;
    }

    getFormControlErrorMessage(inputContol: FormControl | AbstractControl, inputLimit: number): string | undefined {
        if (inputContol.invalid && inputContol.touched) {
            if (inputContol.hasError('required')) {
                return 'The input is required.';
            }
            if (inputContol.hasError('onlywhitespace')) {
                return 'Only whitespace as input is not allowed.';
            }
            if (inputContol.hasError('maxlength')) {
                return `Maximum length of ${inputLimit} characters exceeded.`;
            }
            return 'Invalid input';
        } else {
            return undefined;
        }
    }

    getTextInputErrorMessage(textInput: string, maxlength: number, required: boolean = false): string | undefined {
        if (required) {
            if (this.isEmpty(textInput)) {
                return 'The input is required';
            }
        }
        if (this.isWhitespaceOnly(textInput)) {
            return 'Only whitespace not as input is not allowed.';
        }
        if (this.isTooLong(textInput, maxlength)) {
            return `Maximum length of ${maxlength} characters exceeded.`;
        }
        return undefined;
    }
}
