import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RuumNavbarModule } from './../../common/navbar/navbar.module';
import { LogotypeModule } from './../../shared/ui-components/logotype/logotype.module';
import { AdminProfileMenuModule } from './../profile/profile-menu.module';
import { AdminCompanyInfoComponent } from './company-info.component';
import { AdminNavigationMenuComponent } from './navigation-menu.component';

@NgModule({
    imports: [CommonModule, RouterModule, LogotypeModule, RuumNavbarModule, AdminProfileMenuModule],
    declarations: [AdminCompanyInfoComponent, AdminNavigationMenuComponent],
    exports: [AdminCompanyInfoComponent, AdminNavigationMenuComponent],
})
export class AdminMainLayoutModule {}
