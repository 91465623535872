import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FunctionalRoleListItem } from '../../../common/connectors/functionalRoles/functionalRoles.model';
import { UserListItem } from '../../../common/connectors/readModelConnector.service';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { ComponentSize } from '../../ui-components/ui-components.type';

@Component({
    selector: 'ruum-task-assignees',
    template: `
        <!-- task empty state -->
        <button
            *ngIf="members?.length === 0 && roles?.length === 0"
            aria-label="Assign user to task"
            class="btn btn-sm btn-icon btn-link-gray-24"
        >
            <i class="icon icon-assign-task"></i>
        </button>

        <!-- task members -->
        <div *ngIf="members?.length !== 0" class="d-flex py-1 pl-1">
            <ruum-profile-member-group
                [members]="members"
                [maxShownProfiles]="1"
                [size]="size"
                [disabled]="disabled"
            ></ruum-profile-member-group>
        </div>

        <!-- task roles -->
        <div *ngIf="roles?.length !== 0" class="d-flex py-1 pl-1">
            <ruum-profile-role-group
                [roles]="roles"
                [maxShownProfiles]="1"
                [size]="size"
                [disabled]="disabled"
            ></ruum-profile-role-group>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssigneesComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex', this.componentClass]);
    }

    // Component properties
    @Input() members: UserListItem[] = [];
    @Input() roles: FunctionalRoleListItem[] = [];

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() hover = true;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass = '';

    constructor() {}

    ngOnInit() {}
}
