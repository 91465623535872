import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoResizeTextareaComponent } from './autoresize-textarea.component';
import { InputHighlightDirective } from './input-highlight.directive';

@NgModule({
    declarations: [AutoResizeTextareaComponent, InputHighlightDirective],
    imports: [CommonModule, FormsModule],
    exports: [AutoResizeTextareaComponent, InputHighlightDirective],
})
export class InputModule {}
