import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'autoresize-textarea',
    template: `
        <!-- prettier-ignore -->
        <div
            class="d-flex text-holder text-small"
            [class.py-1]="formControlSize === 'sm'"
            [class.py-2]="formControlSize !== 'sm'"
            [class.px-2]="formControlSize === 'sm'"
            [class.px-3]="formControlSize !== 'sm'"
        >{{ value }}</div>
        <textarea
            class="form-control position-absolute left-0 top-0 h-100"
            #textarea
            [(ngModel)]="ngModel"
            [ngClass]="{ 'border-danger': errorMessage !== undefined }"
            [class.form-control-sm]="formControlSize === 'sm'"
            [class.readOnly]="isReadOnly"
            [ngStyle]="{ 'min-height': formControlSize === 'sm' ? '32px' : '40px' }"
            [placeholder]="placeholder"
            [readonly]="isReadOnly"
            (focus)="focusHandler($event)"
            (blur)="blurHandler($event)"
            (keydown)="keydownHandler($event)"
            (input)="inputHandler($event)"
            attr.aria-label="{{ ariaLabel || placeholder || 'Enter text' }}"
            ngbAutofocus
        >
        </textarea>
    `,
    styles: [
        `
            :host {
                /** yes, this has to be here: https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/ */
                min-width: 0px;
                min-height: 2em;
            }
            .text-holder {
                white-space: pre-wrap;
                word-wrap: break-word;
                word-break: break-word;
                -ms-word-wrap: break-word;
                -ms-word-break: break-word;
                opacity: 0;
                line-height: 24px;
            }
            textarea {
                overflow: hidden;
                line-height: 24px;
            }
            .readOnly {
                border: none;
                cursor: default;
            }
        `,
    ],
})
export class AutoResizeTextareaComponent {
    @HostBinding('class') hostClassList = 'position-relative d-flex flex-fill w-100';

    textValue = '';

    @Input() errorMessage: string | undefined;

    @Output() ngModelChange = new EventEmitter();

    @Input()
    get ngModel() {
        return this.textValue;
    }
    set ngModel(val: string) {
        this.textValue = val || '';
        this.ngModelChange.emit(this.textValue);
    }

    @ViewChild('textarea', { static: false }) textareaEl: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {}

    @Input() placeholder: string;
    @Input() ariaLabel = 'Enter text';
    @Input() formControlSize = 'md'; // 'sm' | 'md' | 'lg'  => (small | medium | large)
    @Input() isReadOnly: string;

    @Output() focusChange = new EventEmitter();
    @Output() blurChange = new EventEmitter();
    @Output() keydownChange = new EventEmitter();
    @Output() inputChange = new EventEmitter();

    focusHandler($event) {
        this.focusChange.emit($event);
    }

    blurHandler($event) {
        if (this.isReadOnly) {
            return;
        }
        this.blurChange.emit($event);
    }

    keydownHandler($event) {
        this.keydownChange.emit($event);
    }

    focusOnTextarea() {
        this.textareaEl.nativeElement.focus();
    }

    blurOnTextarea() {
        this.textareaEl.nativeElement.blur();
    }

    inputHandler(event) {
        // force Detect Change for working in Prose Mirror
        this.inputChange.emit(event);
        this.cdr.detectChanges();
    }

    get value() {
        return !!this.textValue && this.textValue[this.textValue.length - 1] === ''
            ? this.textValue + ''
            : this.textValue || '';
    }
}
