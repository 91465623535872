import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { RuumComment, User } from '@ruum/ruum-reducers';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { InvolvedUsersService } from '../../../../app/common/connectors/users/users.service';
import { AuthService } from '../../../auth/auth.service';
import { AutoResizeTextareaComponent } from '../../../shared/ui-components/input/autoresize-textarea.component';
import { RuumAlertService } from '../../components/alert/alert.service';
import { UserListItem } from '../../connectors/readModelConnector.service';
import { hexColorToRgba, HTTP_URL_REGEX } from '../../utils.service';
import { CommentService } from '../comment.service';

interface TextBlock {
    text: string;
    isAtMention: boolean;
    mail?: string;
    color?: string;
}

@Component({
    selector: 'ruum-comment',
    template: `
        <div *ngIf="false" class="border-top border-primary mt-2" style="height:16px;">
            <span
                class="badge-container text-tiny font-weight-bold badge badge-primary badge-pill text-white"
                style="
        position: relative;
        top: -12px;
        left: 123px;"
                >new messages</span
            >
        </div>
        <div
            #commentElement
            class="d-flex flex-fill"
            [class.ruum-my-comment]="authorIsLoggedUser"
            [class.justify-content-end]="authorIsLoggedUser"
            [class.justify-content-start]="!authorIsLoggedUser"
        >
            <!-- Avatar -->
            <participant-initials
                *ngIf="!authorIsLoggedUser && !noAuthorCircle"
                class="d-flex"
                [participant]="getUser(comment.createdBy) | async"
            >
            </participant-initials>

            <!-- Delete and Edit buttons -->
            <div
                class="ruum-comment-edit-buttons d-flex justify-content-center align-items-center ml-7"
                *ngIf="authorIsLoggedUser && !editMode"
            >
                <button
                    class="btn btn-xs btn-icon btn-link-secondary mr-1"
                    type="button"
                    title="Delete Comment"
                    [disabled]="isReadOnly"
                    (click)="deleteComment()"
                >
                    <i class="icon icon-delete"></i>
                </button>
                <button
                    class="btn btn-xs btn-icon btn-link-secondary mr-1"
                    type="button"
                    title="Edit Comment"
                    [disabled]="isReadOnly"
                    (click)="goToEditMode()"
                >
                    <i class="icon icon-edit"></i>
                </button>
            </div>

            <!-- Comment -->
            <div
                *ngIf="!editMode"
                class="d-flex flex-column rounded px-3 pt-3 pb-2 flex-basis-100"
                [class.ruum-my-message]="authorIsLoggedUser"
                [class.ml-2]="!authorIsLoggedUser"
                [class.ruum-message]="!authorIsLoggedUser"
            >
                <div class="d-flex flex-fill flex-wrap">
                    <ng-template ngFor let-block [ngForOf]="textBlocks$ | async">
                        <span
                            class="text-small text-break-all"
                            *ngIf="block.text !== ''"
                            [title]="block.mail || ''"
                            [class.text-white]="authorIsLoggedUser"
                            [class.interface]="block.isAtMention"
                            [innerHTML]="block.text"
                        >
                        </span>

                        <div class="break-comment flex-basis-100" *ngIf="block.text === ''"></div>
                    </ng-template>
                </div>
                <div class="d-flex flex-fill justify-content-end">
                    <div
                        class="text-tiny"
                        [class.text-secondary]="!authorIsLoggedUser"
                        [class.text-white]="authorIsLoggedUser"
                    >
                        {{ comment.createdAt | dateFormat: 'MMM DD HH:mm' }}
                    </div>
                </div>
            </div>

            <!-- Editable Comment -->
            <div class="d-flex flex-fill" *ngIf="editMode">
                <autoresize-textarea
                    ngDefaultControl
                    #input
                    [(ngModel)]="newCommentText"
                    (keydown.enter)="editComment()"
                    (blurChange)="editComment()"
                >
                </autoresize-textarea>
            </div>
        </div>
    `,
    styles: [
        `
            section b,
            .break-comment {
                display: inline-block;
            }
            .break-comment {
                width: 0px;
                height: 0px;
                overflow: hidden;
            }
            .ruum-my-comment {
            }

            .ruum-message {
                background: rgba(37, 44, 66, 0.04);
            }

            .ruum-my-message {
                background: rgba(37, 44, 66, 0.48);
            }

            .ruum-comment-edit-buttons {
                opacity: 1;
                transition: opacity 150ms ease-in-out;
            }

            .ruum-my-comment:not(:hover) .ruum-comment-edit-buttons {
                opacity: 0;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuumCommentComponent implements OnInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-fill flex-column mb-1';
    @Input() comment: RuumComment;
    @Input() noAuthorCircle = false;
    @Input() isFirstNewComment = false;
    @Input() isReadOnly = false;
    @Output() edit = new EventEmitter<string>();
    @Output() delete = new EventEmitter<void>();

    private author$: Observable<UserListItem>;
    backgroundColor$: Observable<string>;
    textBlocks$: Observable<TextBlock[]>;

    authorIsLoggedUser: boolean;
    editMode = false;
    newCommentText = '';
    @ViewChild('commentElement', { static: false }) private commentElement: ElementRef;
    @ViewChild('input', { static: false }) textArea: AutoResizeTextareaComponent;
    private subscriptions: Subscription[] = [];

    private lightGray = 'rgba(255, 255, 255, 0.8)';

    constructor(
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private ruumAlertService: RuumAlertService,
        private commentService: CommentService,
        private usersService: InvolvedUsersService,
    ) {}

    ngOnInit() {
        this.author$ = this.getUser(this.comment.createdBy);
        this.backgroundColor$ = this.author$.pipe(
            map((author) => {
                if (author && author.id === this.authService.getLoggedUser().id) {
                    return this.lightGray;
                } else {
                    return hexColorToRgba(author.color, 0.08);
                }
            }),
        );
        this.authorIsLoggedUser = this.comment.createdBy === this.authService.getLoggedUser().id;
        this.subscriptions.push(
            this.commentService
                .shouldScrollToComment()
                .pipe(filter((commentId) => this.comment.id === commentId))
                .subscribe(() => this.scrollToComment()),
        );

        this.textBlocks$ = this.getTextBlocks();
    }

    getUser(userId: string): Observable<UserListItem> {
        return this.usersService.dataList([userId]).pipe(map((users) => users[0]));
    }

    private getTextBlocks() {
        const atMentions = combineLatest(this.comment.atMentions.map((userId) => this.getUser(userId)));
        const atMentionsMap: Observable<UserMap> = atMentions.pipe(
            startWith([]),
            map((mentions) =>
                mentions.reduce((list, participant: UserListItem) => {
                    if (participant) {
                        const key = participant.mail.substr(0, participant.mail.indexOf('@'));
                        list[key] = participant;
                    }
                    return list;
                }, {} as any),
            ),
        );
        return atMentionsMap.pipe(map((mentionsMap) => this.commentsSpans(this.comment, mentionsMap)));
    }

    private commentsSpans(comment: RuumComment, participantsMap: UserMap): TextBlock[] {
        const textBlocks: TextBlock[] = [];
        (comment.text || '').split(/\n/).map((line: string) => {
            line.split(/\s/).map((text: string) => {
                textBlocks.push(this.addAtMentionInfo(text, participantsMap));
            });
            textBlocks.push({ text: '\n', isAtMention: false });
        });
        textBlocks.pop();
        return textBlocks;
    }

    private addAtMentionInfo(text: string, participantMap: UserMap): TextBlock {
        const participant = participantMap[text.slice(1)];
        if (participant) {
            return {
                text: '@' + (participant.guessedName || participant.fullName) + '&nbsp;',
                isAtMention: true,
                color: participant.color,
                mail: participant.mail,
            };
        } else {
            const url = text.replace(HTTP_URL_REGEX, '<a href="$&" target="_blank">$&</a>');
            return { text: url + '&nbsp;', isAtMention: false };
        }
    }

    goToEditMode() {
        this.editMode = true;
        this.newCommentText = this.comment.text;
        this.cdr.detectChanges();
        this.textArea.focusOnTextarea();
    }

    editComment() {
        if (this.newCommentText !== this.comment.text) {
            this.edit.emit(this.newCommentText);
        }
        this.editMode = false;
    }

    deleteComment() {
        this.ruumAlertService
            .warning({
                title: `Are you sure you want to remove this comment?`,
                actionText: 'Remove',
            })
            .then(() => {
                this.delete.emit();
            })
            .catch(() => {});
    }

    scrollToComment() {
        if (this.commentElement.nativeElement) {
            this.commentElement.nativeElement.scrollIntoView();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

interface UserMap {
    /** The key is the left part of the email (text before the '@') */
    [key: string]: User;
}
