import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TaskPriority } from '@ruum/ruum-reducers';
import {
    RuumDropdownItem,
    RuumDropdownPlacement,
    RuumDropdownSections,
} from '../../ui-components/dropdown/dropdown.model';
import { componentHelper } from '../../ui-components/ui-components.helper';
import { TaskPriorityService } from './task-priority.service';

@Component({
    selector: 'ruum-task-priority-menu',
    template: `
        <ruum-dropdown
            [placement]="placement"
            [width]="width"
            [sections]="getSections()"
            [selectedItems]="getSelectedItems(priorityId)"
            (selectItem)="onSelect($event)"
            [isReadOnly]="disabled"
            [container]="container"
        >
            <!-- desktop button -->
            <ng-container data-content="ruum-dropdown-button-desktop">
                <ng-content select="[data-content='desktop-button']"></ng-content>
            </ng-container>
            <!-- mobile button -->
            <ng-container data-content="ruum-dropdown-button-mobile">
                <ng-content select="[data-content='mobile-button']"> </ng-content>
            </ng-container>
        </ruum-dropdown>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPriorityMenuComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString([
            'd-flex',
            componentHelper.getCursorClassName(this.disabled),
            this.componentClass,
        ]);
    }

    @Input() disabled = false;
    @Input() priorityId: TaskPriority;
    @Input() placement: RuumDropdownPlacement = ['bottom', 'top', 'auto'];
    @Input() width = '144px';
    @Input() componentClass = '';
    @Input() container = 'body';
    @Output() priorityChanged = new EventEmitter();

    constructor(private taskPriorityService: TaskPriorityService) {}

    ngOnInit() {}

    onSelect(item: RuumDropdownItem) {
        this.priorityChanged.emit(item.id);
    }
    getSections(): RuumDropdownSections {
        const list = this.taskPriorityService.getPriorityList();
        const section = [list];
        return section;
    }
    getSelectedItems(priorityId: TaskPriority) {
        const item = this.taskPriorityService.getPriorityById(priorityId);
        const list = [item];
        return list;
    }
}
