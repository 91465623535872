import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomFieldValueRecord, TableDefinition } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ExternalSystemStore } from '../../../../../common/connectors/externalSystem/externalSystem.store';
import { RuumOnPremiseConnectorAuthHandler } from '../../../../../common/connectors/externalSystem/ruumOnPremiseConnectorAuthHandler.service';
import { TableDataRecordStore } from '../../../../../common/connectors/tableData/tableDataRecords.store';
import { TableDefinitionStore } from '../../../../../common/connectors/tableDefinition/tableDefinition.store';
import { RecordLookupDialogComponent } from '../../../../table-definition/record-lookup-dialog/record-lookup-dialog.component';
import { componentHelper } from '../../../../ui-components/ui-components.helper';
import { ComponentSize, ComponentTheme, ComponentType } from '../../../../ui-components/ui-components.type';
import { CustomFieldRecordData, getCustomFieldRecordData } from './getCustomFieldRecordData';
import { listenToSelectedRecordChange, TableDataRecordListItemDisplay } from './listenToSelectedRecordChange';

@Component({
    selector: 'ruum-custom-field-record',
    template: `
        <button
            *ngIf="!isAuthenticated"
            title="Please click here to login and see business data."
            [disabled]="isReadOnly"
            [ngClass]="authenticateClassList"
            (click)="authenticate()"
        >
            <div class="d-flex flex-fill">
                <span class="text-small font-weight-normal pl-1">Please authenticate...</span>
            </div>
            <i class="icon icon-value-helper-dialog ml-2"></i>
        </button>

        <button *ngIf="isAuthenticated" [ngClass]="recordsClassList" [disabled]="isReadOnly" (click)="openSelect()">
            <div class="d-flex flex-fill minw-0">
                <span *ngIf="selectedRecords.length === 0" class="text-small text-secondary font-weight-normal pl-1"
                    >Select a Record...</span
                >
                <ruum-tag
                    *ngFor="let record of selectedRecords | slice: 0:maxDisplayedSelectedOptions"
                    [name]="record.displayText"
                    [hover]="false"
                ></ruum-tag>
                <ruum-tag
                    *ngIf="selectedRecords.length > maxDisplayedSelectedOptions"
                    [name]="getOtherTagsNumber(selectedRecords.length, maxDisplayedSelectedOptions)"
                    [type]="'link'"
                    [hover]="false"
                    [componentClass]="'px-0'"
                ></ruum-tag>
            </div>
            <i class="icon icon-value-helper-dialog ml-2"></i>
        </button>
    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldRecordComponent implements OnChanges, OnInit, OnDestroy {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex flex-fill minw-0', this.componentClass]);
    }

    // Common properties
    @Input() size: ComponentSize = 'sm';
    @Input() theme: ComponentTheme = 'light';
    @Input() type: ComponentType = 'outline';
    @Input() maxDisplayedSelectedOptions = 1;
    @Input() lightBackground = true;
    @Input() rounded = false;
    @Input() hover = true;
    @Input() active = false;
    @Input() loading = false;
    @Input() componentClass = '';

    @Input() customField: CustomFieldValueRecord;
    @Input() isReadOnly = false;
    @Output() valueChange = new EventEmitter<CustomFieldValueRecord>();

    /** Observable of property 'customField' */
    private currentValue$ = new BehaviorSubject<CustomFieldValueRecord>(undefined);

    private subscriptions: Subscription[] = [];
    private tableDefinition$: Observable<TableDefinition>;
    private systemId: string;

    isAuthenticated: boolean;
    isLoading: boolean;
    selectedRecords: TableDataRecordListItemDisplay[] = [];

    constructor(
        private modalService: NgbModal,
        private extConnectorPremAuthHandler: RuumOnPremiseConnectorAuthHandler,
        private cdr: ChangeDetectorRef,
        private tableDataRecordStore: TableDataRecordStore,
        private tableDefinitionStore: TableDefinitionStore,
        private systemsStore: ExternalSystemStore,
    ) {}

    ngOnInit() {
        this.tableDefinition$ = this.tableDefinitionStore.data(this.customField.tableId);
        const data$ = getCustomFieldRecordData(
            this.currentValue$,
            this.systemsStore,
            this.extConnectorPremAuthHandler,
            this.tableDefinition$,
        );
        this.listenToAuthenticated(data$);

        const subs = listenToSelectedRecordChange(this.tableDataRecordStore, data$).subscribe((selectedRecords) => {
            this.selectedRecords = selectedRecords;
            this.cdr.detectChanges();
        });
        this.subscriptions.push(subs);
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.customField) {
            this.currentValue$.next(change.customField.currentValue);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    get authenticateClassList() {
        return componentHelper.transformClassNameArrayToString([
            'btn-block pr-2 pl-1',
            componentHelper.getDefaultClassName(),
            componentHelper.getThemeClassName('primary', 'outline'),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getDisabledClassName(this.isReadOnly),
            this.getSizeClassName(this.size),
        ]);
    }

    get recordsClassList() {
        return componentHelper.transformClassNameArrayToString([
            'btn-block pr-2 pl-1',
            componentHelper.getDefaultClassName(),
            componentHelper.getThemeClassName(this.theme, this.type),
            componentHelper.getRoundClassName(this.rounded),
            componentHelper.getHoverClassName(this.hover),
            componentHelper.getActiveClassName(this.active),
            componentHelper.getDisabledClassName(this.isReadOnly),
            this.getSizeClassName(this.size),
            this.getBackgroundClassName(this.lightBackground),
        ]);
    }

    getOtherTagsNumber(all: number, shown: number): string {
        const value = all - shown;
        return `+${value > 99 ? 99 : value}`;
    }

    private listenToAuthenticated(valueAndAuthenticated$: Observable<CustomFieldRecordData>) {
        const subs = valueAndAuthenticated$.subscribe(({ isAuthenticated, tableDefinition }) => {
            if (tableDefinition.type === 'external_table') {
                this.systemId = tableDefinition.systemId;
            }
            this.isAuthenticated = isAuthenticated;
            this.cdr.detectChanges();
        });
        this.subscriptions.push(subs);
    }

    async authenticate() {
        if (this.systemId) {
            await this.extConnectorPremAuthHandler.authenticate(this.systemId);
        }
    }

    async openSelect() {
        const instance = this.modalService.open(RecordLookupDialogComponent, {
            size: 'xl',
        });
        const component = instance.componentInstance;
        component.tableId = this.customField.tableId;
        component.recordsKeys = this.customField.value || [];
        component.multiSelect = this.customField.isMultiSelect;
        await this.listenToSelection(instance);
    }

    private async listenToSelection(instance: NgbModalRef) {
        const selectedItems = await instance.result.catch((err) => undefined);
        if (selectedItems) {
            const changedField = {
                ...this.customField,
                value: [...selectedItems],
            };
            this.valueChange.emit(changedField);
        }
    }

    private getSizeClassName(size: string): string {
        return ['btn', size].filter((el) => !!el).join('-');
    }

    private getBackgroundClassName(lightBackground) {
        return lightBackground ? 'bg-extra-light' : '';
    }
}
