import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../common.service';

@Injectable({ providedIn: 'root' })
export class HttpOfflineInterceptor implements HttpInterceptor {
    constructor(private commonService: CommonService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event) => {
                /** TODO: Double check if this ever gets executed. It can probably be remoevd. */
                if (event instanceof HttpResponse && (event.status === 408 || event.status === 0)) {
                    this.commonService.setOffline(true);
                }
                return event;
            }),
        );
    }
}
