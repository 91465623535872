import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { canvasSchema, CanvasStep, copyCanvasStepFields } from '@ruum/ruum-reducers';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { WebsocketConnectionService } from '../../../app/common/connectors/websocketConnection.service';
import { AuthService } from './../../auth/auth.service';
import { AppStoreWrapper } from './../../common/appStoreWrapper.service';
import { LoggerService as Logger } from './../../common/logger.service';
import { CanvasBackendConnector } from './canvas.backendConnector';
import { CanvasStateManagerService } from './canvasStateManager.service';

import { getVersion, receiveTransaction, sendableSteps } from 'prosemirror-collab';
import { Step } from 'prosemirror-transform';

/**
 * ProseMirror collaborative editing implemented according to this guide:
 * http://prosemirror.net/docs/guides/collab/
 *
 * and also based on this discussion:
 * https://discuss.prosemirror.net/t/how-to-handle-edge-case-with-collab-module/288
 *
 * In addition to the points described in the links above it is importante to know the following:
 * 1. There can never be more than one sendSteps or getNewVersion running in prallel.
 * 2. The client should not send new steps if it is waiting for the server to confirm steps which were previously sent.
 * 3. Steps are confirmed when the server notifies the client via websockets that a new version is available, the client
 * 'getNewVersionFromServer' and ProseMirror's collab module takes care or confirming the previously sent steps.
 */
@Injectable({ providedIn: 'root' })
export class CanvasCollabService {
    private DEFAULT_INTERVAL = 150;
    private SEND_STEPS_INTERVAL = 150;
    private GET_NEW_VERSION_INTERVAL = 150;

    private getNewVersionScheduled = false;

    private isSendingSteps = false;
    private isGettingNewVersion = false;
    private isWaitingForConfirmation = false;

    private subscriptions: Subscription[] = [];
    private intervals = [];

    private isOffline$ = new BehaviorSubject<boolean>(false);
    private isOffline = false;

    private failed$ = new Subject<void>();

    /** The number of consecutive errors while trying to send steps to the server. */
    private sendStepsErrorCount = 0;

    /** Emits every time a user different than the logged user changes the canvas. */
    otherUsersChangedCanvas$ = new Subject<void>();

    constructor(
        private canvasBackendConnector: CanvasBackendConnector,
        private logger: Logger,
        private authService: AuthService,
        private appStoreWrapper: AppStoreWrapper,
        private canvasStateManager: CanvasStateManagerService,
        private router: Router,
        private wsConnection: WebsocketConnectionService,
    ) {}
    /**
     * If it is sending data to the central authority.
     * If true it is not safe to leave the ruum.
     */
    isSendingData(): Observable<boolean> {
        return this.canvasStateManager.fullStateChange().pipe(
            map((state) => {
                if (state) {
                    const sendable = sendableSteps(state);
                    return sendable && sendable.steps.length > 0;
                } else {
                    return false;
                }
            }),
        );
    }

    /** Notify other services that the collaboration is in a state it can't recover from. */
    failed(): Observable<void> {
        return this.failed$;
    }

    /**
     * If the canvas is having problems connecting to the Central Authority.
     */
    offline(): Observable<boolean> {
        return this.isOffline$.pipe(distinctUntilChanged());
    }

    isCanvasOffline(): boolean {
        return this.isOffline;
    }

    private setOffline(offline: boolean) {
        this.isOffline = offline;
        this.isOffline$.next(offline);
    }

    startCollaborationForRuum(ruumId: string) {
        this.stopCurrentCollab();
        this.logger.debug('CanvasCollabService', 'start collab for ruum', ruumId);

        const subscription = this.wsConnection
            .listenToDocument(ruumId)
            .subscribe(() => this.getNewVersionFromServer(ruumId));
        this.subscriptions.push(subscription);

        this.sendStepsToServerIfNeeded(ruumId);
        this.proactivelyAsksForConfirmation(ruumId);
        this.scheduleCleanIntervals();
    }

    stopCurrentCollab() {
        this.logger.debug('CanvasCollabService', 'stopCurrentCollab');
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.intervals.forEach((interval) => this.clearAsync(interval));
        this.subscriptions = [];
        this.intervals = [];

        this.isSendingSteps = false;
        this.isGettingNewVersion = false;
        this.isWaitingForConfirmation = false;
        this.getNewVersionScheduled = false;
        this.sendStepsErrorCount = 0;
        this.setOffline(false);
    }

    private clearAsync(zoneTask) {
        if (zoneTask.source === 'setTimeout') {
            clearTimeout(zoneTask);
        } else {
            clearInterval(zoneTask);
        }
    }

    private getNewVersionFromServer(ruumId: string) {
        if (!this.isValidForSelectedRuum(ruumId)) {
            return;
        }

        if (this.isGettingNewVersion) {
            this.scheduleGetNewVersionFromServer(ruumId);
            return;
        }

        this.isGettingNewVersion = true;

        this.getNewCanvasSteps(ruumId)
            .then((canvasSteps) => this.parseStepsAndAddRemoteInfo(canvasSteps))
            .then((stepsAndClientIDs) => this.receiveRemoteSteps(stepsAndClientIDs))
            .then((receivedSteps) => {
                /** if tried to get a new version but no steps were received the confirmation didn't happen yet. */
                if (receivedSteps) {
                    this.isWaitingForConfirmation = false;
                }
                this.isGettingNewVersion = false;
            })
            .catch((err) => {
                /** If there are new steps but the canvas can't handle them the only option is to reaload. */
                this.logger.error('error getting new version from the server', err);
                this.stopCurrentCollab();
                this.redirectToLoginIfNeeded(err);
                this.failed$.next();
            });
    }

    /** Checking just to make sure as we are not keeping a reference to the http subscription and cancelling it when the user leaves the ruum. */
    private isValidForSelectedRuum(scheduledRuumId: string) {
        return this.canvasStateManager.getCurrentFullState() && this.getSelectedRuumId() === scheduledRuumId;
    }

    private getNewCanvasSteps(ruumId: string): Promise<CanvasStep[]> {
        return (
            this.canvasBackendConnector
                .getStepsSince(ruumId, getVersion(this.canvasStateManager.getCurrentFullState()))
                .then((data) => {
                    this.GET_NEW_VERSION_INTERVAL = this.DEFAULT_INTERVAL;
                    return data;
                })
                /**
                 * If there was an HTTP error ignore it, timeouts happens from time to time.
                 */
                .catch((err) => {
                    this.logger.error('HTTP error getting new version from the server', err);
                    this.redirectToLoginIfNeeded(err);
                    this.GET_NEW_VERSION_INTERVAL = this.GET_NEW_VERSION_INTERVAL + 100;
                    /** If this version is so old that the server has not enough steps from memory it should start over. */
                    if (err && err.error && err.error.message === 'not enough steps in memory') {
                        throw err;
                    }
                    return [];
                })
        );
    }

    /**
     * Same thing CanvasComponent.addInfoToLocalSteps does for local steps.
     */
    private parseStepsAndAddRemoteInfo(canvasSteps: CanvasStep[]) {
        const proseMirrorSteps = [];
        const clientIDs = [];

        canvasSteps.forEach((canvasStep) => {
            const parsedStep = Step.fromJSON(canvasSchema, canvasStep.proseMirrorStep);
            copyCanvasStepFields(canvasStep, parsedStep);
            proseMirrorSteps.push(parsedStep);
            clientIDs.push(canvasStep.clientID);
            if (this.authService.getLoggedUser().id !== canvasStep.userId) {
                this.otherUsersChangedCanvas$.next();
            }
        });

        return { proseMirrorSteps, clientIDs };
    }

    private receiveRemoteSteps(stepsAndClientIDs): boolean {
        if (!this.canvasStateManager.getCurrentFullState()) {
            return true;
        }

        const transaction = receiveTransaction(
            this.canvasStateManager.getCurrentFullState(),
            stepsAndClientIDs.proseMirrorSteps,
            stepsAndClientIDs.clientIDs,
        );
        this.addFieldsToRebasedSteps(transaction.steps);
        this.canvasStateManager.dispatchDocTransaction(transaction);
        return stepsAndClientIDs.proseMirrorSteps.length > 0;
    }

    /**
     * If there is a conflict local steps will be rebased and that will make them lose all information which is not
     * serialized by the toJSON method.
     * As only local steps (never remote ones) can be rebased it is safe to set the loggedUser's id to userId and using the current timestamp
     * should be close enough for createdAt.
     */
    private addFieldsToRebasedSteps(steps: any[]) {
        steps.forEach((step) => {
            if (!step.userId) {
                step.userId = this.authService.getLoggedUser().id;
                step.createdAt = new Date().getTime();
            }
        });
    }

    private scheduleGetNewVersionFromServer(ruumId: string) {
        if (this.getNewVersionScheduled) {
            return;
        }
        this.getNewVersionScheduled = true;
        const ref = setTimeout(() => {
            this.getNewVersionScheduled = false;
            this.getNewVersionFromServer(ruumId);
        }, this.GET_NEW_VERSION_INTERVAL);
        this.intervals.push(ref);
    }

    private sendStepsToServer(ruumId: string, sendable) {
        if (this.shouldNotSendSteps()) {
            return Promise.resolve();
        }

        this.isSendingSteps = true;
        this.isWaitingForConfirmation = true;

        const data = {
            version: sendable.version,
            steps: sendable.steps,
            clientID: sendable.clientID,
        };
        return this.canvasBackendConnector
            .sendSteps(ruumId, data)
            .then(() => {
                this.isSendingSteps = false;
                this.SEND_STEPS_INTERVAL = this.DEFAULT_INTERVAL;
                this.sendStepsErrorCount = 0;
                this.setOffline(false);
            })
            .catch((err) => this.handleSendStepsError(err));
    }

    private handleSendStepsError(err) {
        this.isSendingSteps = false;

        /**
         * If there was a conflict, ignore error.
         * This client will be notified about the new version via websockets, it will get the new steps from the server, apply them to the local state
         * and then 'sendStepsToServerIfNeeded' will send the steps which conflicted before again.
         */
        if (err && err.status === 409) {
            this.isWaitingForConfirmation = false;
            return;
        }

        if (this.redirectToLoginIfNeeded(err)) {
            return;
        }

        this.SEND_STEPS_INTERVAL = this.SEND_STEPS_INTERVAL + 300;

        /** If it was a network error we just keep trying until it works. */
        if (err.status === 0 || err.status === 504) {
            if (this.sendStepsErrorCount >= 3) {
                /**
                 * If something goes wrong while sending data go to offline mode until it is able to send data to the server again.
                 * We retry a few more times before going to offline mode.
                 */
                this.setOffline(true);
            }
        } else {
            /** If the server returned an error we try once more and then give up. */
            if (this.sendStepsErrorCount >= 1) {
                this.stopCurrentCollab();
                this.failed$.next();
            }
        }

        this.sendStepsErrorCount++;
    }

    private shouldNotSendSteps() {
        if (this.isSendingSteps) {
            return true;
        } else {
            if (this.isWaitingForConfirmation) {
                /**
                 * It should not send steps to the server while it is waiting for the confirmation of previous steps
                 * unless it was offline. In that case, it should keep trying to send the steps until it is online again.
                 */
                if (this.isOffline || this.sendStepsErrorCount > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    }

    /**
     * Send local steps to the server at every 'SEND_STEPS_INTERVAL' but wait on result
     * of previous request before sending a new one.
     */
    private sendStepsToServerIfNeeded(ruumId: string) {
        const ref = setTimeout(() => {
            if (this.isValidForSelectedRuum(ruumId)) {
                const state = this.canvasStateManager.getCurrentFullState();
                const sendable = sendableSteps(state);
                if (sendable && sendable.steps.length > 0) {
                    this.sendStepsToServer(ruumId, sendable).then(() => this.sendStepsToServerIfNeeded(ruumId));
                } else {
                    this.sendStepsToServerIfNeeded(ruumId);
                }
            } else {
                this.sendStepsToServerIfNeeded(ruumId);
            }
        }, this.SEND_STEPS_INTERVAL);
        this.intervals.push(ref);
    }

    /**
     * Sometimes the client sends steps to the server but the server can't notify the client due to network connectivity issues.
     * When this happens the client will stay waiting for confirmation forever, shouldNotSendSteps() will always return true and
     * local steps will never be sent to the server.
     *
     * In order to avoid that, at every second if the client is waiting for confirmation it proactively asks the server.
     *
     */
    private proactivelyAsksForConfirmation(ruumId: string) {
        const ref = setInterval(() => {
            if (this.isWaitingForConfirmation) {
                this.getNewVersionFromServer(ruumId);
            }
        }, 1000);
        this.intervals.push(ref);
    }

    private scheduleCleanIntervals() {
        const ref = setInterval(() => {
            this.cleanIntervals();
        }, 30000);
        this.intervals.push(ref);
    }

    /**
     * Only keep ZoneTasks that are still running or scheduled.
     */
    private cleanIntervals() {
        this.intervals = this.intervals.filter((zoneTask) => zoneTask.state !== 'notScheduled');
    }

    private redirectToLoginIfNeeded(err): boolean {
        if (err && (err.status === 403 || err.status === 401)) {
            this.router.navigateByUrl('/auth');
            return true;
        }
    }

    private getSelectedRuumId(): string {
        let id;
        this.appStoreWrapper
            .selectedRuum()
            .pipe(take(1))
            .subscribe((ruum) => (id = ruum.id));
        return id;
    }
}
