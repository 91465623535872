import { FocusKeyManager } from '@angular/cdk/a11y';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { RuumAlertService } from '../components/alert/alert.service';
import {
    LobbyListFilters,
    LobbyListItem,
    LobbyListOrderBy,
    LobbyListType,
} from '../connectors/lobbyList/lobby-list.model';
import { OrderedListParams, PaginatedList } from '../connectors/paginatedList.model';
import { PaginatedListLoader } from '../connectors/paginatedListLoader';
import { ReadModelBackendConnector } from '../connectors/readModelConnector.service';
import { SidenavLobbyListItemComponent } from './sidenav-lobby-list-item.component';

export interface SideNavLobbyListitem {
    id: string;
    icon?: string;
    path: string;
    name: string;
    type: string;
    tooltip?: string;
}

const emptyPage = {
    pageSize: 25,
    currentPage: 1,
    totalItems: 0,
    rows: [],
};

@Component({
    selector: 'ruum-sidenav-lobby-list',
    template: `
        <div
            class="d-flex flex-column ruum-sidenav-nav-item-group"
            tabindex="0"
            role="listbox"
            [attr.aria-label]="'ALL RUUMS'"
            [attr.aria-multiselectable]="false"
            [attr.aria-expanded]="!collapsed"
            (keydown)="manage($event)"
        >
            <div
                class="d-flex align-items-center cursor-pointer minw-0 px-3 py-2 ruum-sidenav-nav-item"
                (click)="toggle()"
                (keydown.space)="toggle()"
                (keydown.enter)="toggle()"
            >
                <div class="btn btn-xs btn-icon btn-link-white-64 no-hover">
                    <i class="icon" [class.icon-cevron-right]="collapsed" [class.icon-cevron-down]="!collapsed"></i>
                </div>
                <div class="text-tiny font-weight-bold text-truncate text-white-64">ALL RUUMS</div>
            </div>
            <div *ngIf="!collapsed" class="d-flex flex-fill flex-column overflow-y pb-2" [style.max-height.vh]="50">
                <ruum-sidenav-lobby-list-item
                    *ngFor="let option of options$ | async; trackBy: trackById"
                    [name]="option.name"
                    [title]="option.name"
                    [icon]="option.icon"
                    [path]="option.path"
                    [style.min-height.px]="32"
                ></ruum-sidenav-lobby-list-item>
                <ruum-load-more
                    *ngIf="loading$ | async"
                    [size]="'sm'"
                    [theme]="'white'"
                    [scrollElement]="scrollElement"
                    (loadMore)="loadMore()"
                ></ruum-load-more>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavLobbyListComponent extends PaginatedListLoader<LobbyListItem, LobbyListFilters, LobbyListOrderBy>
    implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') hostClassList = 'd-flex flex-column border-white-16 border-bottom minw-0';

    @ViewChildren(SidenavLobbyListItemComponent) viewChildren: QueryList<SidenavLobbyListItemComponent>;

    options$: Observable<SideNavLobbyListitem[]>;
    loading$: Observable<boolean>;

    collapsed = false;

    private keyManager: FocusKeyManager<SidenavLobbyListItemComponent>;
    private orderBySubject: BehaviorSubject<OrderedListParams<LobbyListOrderBy>> = new BehaviorSubject<
        OrderedListParams<LobbyListOrderBy>
    >({ by: 'changedAt', direction: 'desc' });
    private listType$: BehaviorSubject<string> = new BehaviorSubject<LobbyListType>('projects_and_groups');

    private dataStoreSubject = new BehaviorSubject<PaginatedList<LobbyListItem[]>>(emptyPage);
    private ngOnDestroy$ = new Subject<void>();

    constructor(
        protected alertService: RuumAlertService,
        protected authService: AuthService,
        private elementRef: ElementRef,
        private readModelBackendConnector: ReadModelBackendConnector,
    ) {
        super(alertService, authService);
    }

    ngOnInit() {
        this.setUpObservables();
        this.reload();
        this.dataStoreSubject.next(emptyPage);
        this.loadList();
        this.options$ = this.getOptions();
        this.loading$ = this.getLoading();
    }

    ngAfterViewInit(): void {
        this.keyManager = new FocusKeyManager(this.viewChildren).withWrap();
    }

    ngOnDestroy(): void {
        this.stopLoadingList();
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    get scrollElement() {
        return this.elementRef.nativeElement.querySelector('.overflow-y');
    }

    manage(event) {
        if (event.keyCode === ENTER || event.keyCode === SPACE) {
            this.toggle();
        } else {
            this.keyManager.onKeydown(event);
        }
    }

    toggle() {
        this.collapsed = !this.collapsed;
    }

    loadMore() {
        this.maybeGoToNextPage();
    }

    trackById(index: number, item: SideNavLobbyListitem) {
        return item.id;
    }

    protected getData(
        page: number,
        filters: LobbyListFilters,
        orderBy: OrderedListParams<LobbyListOrderBy>,
    ): Observable<PaginatedList<LobbyListItem>> {
        return this.readModelBackendConnector.getLobbyList(filters, orderBy, page, 25);
    }

    protected getFilters$(): Observable<LobbyListFilters> {
        return combineLatest([this.listType$]).pipe(
            map<any, LobbyListFilters>(([listType, workspaceId]) => ({
                listType,
                workspaceId,
            })),
        );
    }

    protected getOrderBy$(): Observable<OrderedListParams<LobbyListOrderBy>> {
        return this.orderBySubject;
    }

    protected getStoreData$(): Observable<PaginatedList<any>> {
        return this.dataStoreSubject.asObservable();
    }

    private setUpObservables(): void {
        this.getListObservable()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((page: any) => {
                const currentState = this.dataStoreSubject.getValue();
                const rows = page.rows.map((item: LobbyListItem) => {
                    const icon = item.type === `project` ? `file` : `folder-filled`;
                    const path = item.type === `project` ? `/projects/${item.id}` : `/projectgroups/${item.id}`;
                    return {
                        id: item.id,
                        icon,
                        path,
                        name: item.name,
                        type: item.type,
                    };
                });
                this.dataStoreSubject.next({
                    ...page,
                    rows: [...currentState.rows, ...rows],
                });
            });
    }

    private getOptions(): Observable<SideNavLobbyListitem[]> {
        return this.dataStoreSubject.pipe(
            map((currentState: any) => {
                return currentState.rows;
            }),
        );
    }

    private getLoading(): Observable<boolean> {
        return this.hasMore$.asObservable().pipe(startWith(true));
    }
}
