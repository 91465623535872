import { animate, group, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { fadeIn, fadeOut, timingInMedium, timingOutMedium } from '../../../common/animation';
import { TemplateCategoryListItem, TemplateListItem } from '../../../common/connectors/templates/template-list.model';
import { componentHelper } from '../../ui-components/ui-components.helper';

@Component({
    selector: 'ruum-select-template-group',
    template: `
        <h3 id="template-category-{{ category.id }}" class="text-truncate mb-4">
            {{ category.name | ruumTemplateCategoryName }}
        </h3>

        <div class="row">
            <div
                class="col-12"
                [@fadeInFadeOut]
                *ngFor="let template of category.templates | slice: 0:(sliceEnd$ | async); let last = last"
            >
                <ruum-select-template-card
                    [type]="type"
                    [icon]="template.icon"
                    [name]="template.name"
                    [theme]="template.colorTheme"
                    [creator]="template.createdBy | ruumTemplateCreatorName: category.id | async"
                    [categoryId]="category.id"
                    [workspaceId]="template.workspaceId"
                    [workspaceName]="template.workspaceName"
                    [isAdmin]="template.role === 'TemplateAdmin'"
                    [isEditor]="template.role === 'TemplateEditor'"
                    [isViewer]="template.role === 'TemplateViewer'"
                    [componentClass]="last ? '' : 'mb-2'"
                    (click)="clicked.emit(template)"
                >
                </ruum-select-template-card>
            </div>
        </div>

        <div class="d-flex">
            <button
                *ngIf="showMore || category.templates.length > (sliceEnd$ | async)"
                class="btn btn-sm btn-link-secondary px-0"
                (click)="toggleShowMore()"
            >
                <span *ngIf="!showMore">Show more (+{{ category.templates.length - (sliceEnd$ | async) }})</span>
                <i *ngIf="!showMore" class="icon icon-cevron-right"></i>
                <span *ngIf="showMore">Show less</span>
                <i *ngIf="showMore" class="icon icon-cevron-up"></i>
            </button>
        </div>
    `,
    animations: [
        trigger('fadeInFadeOut', [
            transition(':enter', group([animate(timingInMedium, fadeIn)])),
            transition(':leave', group([animate(timingOutMedium, fadeOut)])),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTemplateGroupComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return componentHelper.transformClassNameArrayToString(['d-flex', 'flex-column', this.componentClass]);
    }

    @Input() type = 'ruum';
    @Input() category: TemplateCategoryListItem;

    @Input() hover = false;
    @Input() active = false;
    @Input() disabled = false;
    @Input() componentClass: string;

    @Output() clicked = new EventEmitter<TemplateListItem>();

    sliceEnd$: Observable<number>;
    showMoreSubject = new BehaviorSubject(false);

    get showMore() {
        return this.showMoreSubject.getValue();
    }

    constructor() {}

    ngOnInit() {
        this.sliceEnd$ = this.getSliceEnd();
    }

    toggleShowMore() {
        this.showMoreSubject.next(!this.showMore);
    }

    private getTemplatesNumber(): Observable<number> {
        return of(this.category.templates.length);
    }

    private getSliceEnd(): Observable<number> {
        return combineLatest([this.showMoreSubject.asObservable(), this.getTemplatesNumber()]).pipe(
            map(([showMore, templatesNumber]) => {
                if (showMore) {
                    return templatesNumber;
                }

                return 3;
            }),
        );
    }
}
