import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaywallPrivilege } from '../../paywall/paywall-privilege';
import { PaywallRejectStrategyData, PaywallRejectStrategyId } from '../../paywall/paywall-reject-strategy';
import { DocumentScrollService } from '../documentScroll.service';

const PRIMARY_NAVBAR_HEIGHT = 56;

export interface NavbarTab {
    id: string;
    name: string;
    icon: string;
    path: string | string[];
    tooltipText?: string;

    paywallPrivilege?: PaywallPrivilege;
    paywallRejectStrategy?: PaywallRejectStrategyId;
    paywallRejectStrategyData?: PaywallRejectStrategyData;
}

export interface TaskDetail {
    projectId: string;
    taskId: string;
}

@Injectable({ providedIn: 'root' })
export class NavbarService {
    readonly stickyTop$: Observable<boolean> = new BehaviorSubject<boolean>(false);
    readonly isShrink$: Observable<boolean>;
    readonly backBattonTitle$: Observable<string>;
    readonly taskDetailId$: Observable<TaskDetail>;
    readonly projectId$: Observable<string>;

    private _backBattonTitle$ = new BehaviorSubject<string>(null);
    private _taskDetailId$ = new BehaviorSubject<TaskDetail>(null);
    private _projectId$ = new BehaviorSubject<string>(null);

    constructor(private documentScrollService: DocumentScrollService) {
        this.backBattonTitle$ = this._backBattonTitle$;
        this.taskDetailId$ = this._taskDetailId$;
        this.projectId$ = this._projectId$;
        // declaration of stickyTop Observer
        this.stickyTop$ = this.documentScrollService.scrollObservable$.pipe(
            map((scrollPosition) => scrollPosition.sT > PRIMARY_NAVBAR_HEIGHT),
        );
        // declaration of isShrink Observer
        this.isShrink$ = combineLatest([this.documentScrollService.isScrollUpWithVelocity$(), this.stickyTop$]).pipe(
            map(([scrollUp, stickyTopSource]) => (scrollUp ? false : stickyTopSource)),
        );
    }

    setTaskDetailId(taskDetail: TaskDetail) {
        this._taskDetailId$.next(taskDetail);
    }
    setProjectId(projectId: string) {
        this._projectId$.next(projectId);
    }

    getCommonAlertHeight(): number {
        const commonAlert = document.getElementById('ruum-common-alert');
        const commonAlertHeight = commonAlert ? commonAlert.offsetHeight : 0;
        return commonAlertHeight;
    }

    setBackButtonTitile(title: string) {
        this._backBattonTitle$.next(title);
    }
}
