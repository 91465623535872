import { ProjectGroup, ProjectGroupAction, ProjectGroupReducer } from '@ruum/ruum-reducers';

export const PROJECT_GROUP_WEB_APP_ACTION_TYPE = {
    SELECT_PROJECT_GROUP: 'SELECT_PROJECT_GROUP',
};

export function ProjectGroupReducerWrapper(currentState: ProjectGroup, action: ProjectGroupAction): ProjectGroup {
    switch (action.type) {
        case PROJECT_GROUP_WEB_APP_ACTION_TYPE.SELECT_PROJECT_GROUP:
            return action.payload;
        default:
            return ProjectGroupReducer(currentState, { ...action });
    }
}
