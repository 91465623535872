import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    RuumDropdownItem,
    RuumDropdownList,
    RuumDropdownPlacement,
} from '../../shared/ui-components/dropdown/dropdown.model';

/** Stateless component */
@Component({
    selector: 'ruum-team-member-role-dropdown',
    template: `
        <ruum-team-member-add-to
            [title]="title"
            [selectedItem]="selectedOption"
            [sectionList]="[options]"
            [size]="'sm'"
            (memberChanged)="onSelect($event)"
        ></ruum-team-member-add-to>
    `,
})
export class TeamMemberRoleDropdownComponent implements OnInit {
    @Input()
    placement: RuumDropdownPlacement = ['bottom-right', 'top-right', 'auto'];

    @Input()
    title: string;

    @Input()
    selectedOption: RuumDropdownItem;

    @Input()
    options: RuumDropdownList = [];

    @Output()
    roleSelected = new EventEmitter<RuumDropdownItem['id']>();

    constructor() {}

    ngOnInit() {}

    onSelect(item: RuumDropdownItem) {
        this.roleSelected.emit(item.id);
    }
}
