import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddCustomFieldDefinitionAction, CustomFieldDefinition } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionTypeAndPayload } from '../../actions';
import { AppState } from '../../app.store';
import { getRandomId } from '../../utils.service';
import { ReadModelBackendConnector } from '../readModelConnector.service';
import { StoreLoader } from '../storeLoader.abstract';
import { SelectedWorkspaceService } from '../workspace/selected-workspace.service';
import { calculateDiff } from './calculate-diff-action.util';
import { CustomFieldListItem } from './customFields.model';
import { AddOrReplaceCustomField } from './customFields.reducer';

@Injectable({ providedIn: 'root' })
export class CustomFieldsService extends StoreLoader<CustomFieldListItem> {
    constructor(
        private store: Store<AppState>,
        private readModelBackendConnector: ReadModelBackendConnector,
        private selectedWorkspaceService: SelectedWorkspaceService,
    ) {
        super();
    }

    protected getData(ids: string[]): Observable<CustomFieldListItem[]> {
        return this.readModelBackendConnector
            .getCustomFields({ page: 1, pageSize: ids.length }, { id: ids })
            .pipe(map((page) => page.rows));
    }

    protected storeData(fields: CustomFieldListItem[]): void {
        const add: ActionTypeAndPayload<AddOrReplaceCustomField> = {
            type: 'ADD_OR_REPLACE_CUSTOM_FIELD',
            payload: {
                fields,
            },
        };
        this.store.dispatch(add);
    }

    protected failedToLoad(id: string): void {
        const add: ActionTypeAndPayload<AddOrReplaceCustomField> = {
            type: 'ADD_OR_REPLACE_CUSTOM_FIELD',
            payload: {
                fields: [
                    {
                        id,
                        title: 'Failed to load',
                        type: 'string',
                        sourceType: 'workspace',
                        sourceId: undefined,
                    },
                ],
            },
        };
        this.store.dispatch(add);
    }

    protected getStoreData(): Observable<{ [id: string]: CustomFieldListItem }> {
        return this.store.select('customFields');
    }

    createCustomField(data: Partial<CustomFieldDefinition>) {
        const id = getRandomId('custom_field_');
        const addCustomField = { ...data, id } as CustomFieldDefinition;
        this.selectedWorkspaceService.persistAction<AddCustomFieldDefinitionAction>('ADD_CUSTOM_FIELD_DEFINITION', {
            definition: addCustomField,
        });
    }

    editCustomField(
        id: string,
        dataBefore: Partial<CustomFieldDefinition>,
        data: Partial<CustomFieldDefinition>,
    ): Promise<any> {
        const actions = calculateDiff(id, dataBefore, data);
        return this.selectedWorkspaceService.persistActions(actions);
    }
}
