import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LobbyListItemProject } from '../../../project/project.model';
import { selectedProjectIdSelector } from '../../../project/project.selectors';
import { CanvasService } from '../../../ruum/canvas/canvas.service';
import { CanvasSectionsService } from '../../../ruum/canvas/sections/canvasSections.service';
import { SectionsService } from '../../../ruum/section/sections.service';
import { RuumUIStateService } from '../../../ruum/ui-state/ruumUIState.service';
import { AppState } from '../../app.store';
import { AppStoreWrapper } from '../../appStoreWrapper.service';
import { ServiceHelper } from '../../serviceHelper';
import { RuumMainNavPanelSegmentService } from '../main-nav-panel-segment.service';
import { SidePanelDisplayNode } from '../sidenav.service';

@Component({
    selector: 'ruum-project-main-nav-panel',
    template: `
        <div
            *ngIf="projectId$ | async as projectId"
            class="d-block border-white-16 border-bottom"
            data-help-id="global_sidepanel_ruum_sections"
        >
            <ruum-side-panel-node
                [navigationContext]="'sections'"
                data-content="project-main-nav-section"
                [node]="resolvedSectionsDisplayNodeList$ | async"
                [sectionExpanded]="sectionCollapsed"
                (nodeEvent)="sectionClicked($event)"
                (dropNodeEvent)="nodeDropped($event)"
                (addElement)="addSection()"
                (expandAction)="toggleCollapse()"
                (changeOption)="handleChange($event)"
            ></ruum-side-panel-node>
        </div>
    `,
})
export class RuumProjectMainNavComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private mainNavPanelSectionService: RuumMainNavPanelSegmentService,
        private canvasService: CanvasService,
        private canvasSectionService: CanvasSectionsService,
        private sectionsService: SectionsService,
        private appStoreWrapper: AppStoreWrapper,
        private serviceHelper: ServiceHelper,
        private ruumUIStateService: RuumUIStateService,
        private store: Store<AppState>,
    ) {}

    @HostBinding('class') hostClassList = 'd-block';

    @Input() groupInfo: LobbyListItemProject;
    @Input() isReadOnly = false;
    @Input() isAdmin = false;
    @Input() isArchived = false;

    resolvedSectionsDisplayNodeList$: Observable<SidePanelDisplayNode>;
    sectionCollapsed$: Observable<boolean>;
    currentPath = '';
    private currentLastCanvasId: string;
    projectId$: Observable<string>;
    sectionCollapsed = false;

    private subscription: Subscription;

    ngOnInit() {
        this.projectId$ = this.store.select(selectedProjectIdSelector);
        this.resolvedSectionsDisplayNodeList$ = this.mainNavPanelSectionService.initSectionsResolver();
        this.subscription = this.sectionsService
            .allSectionsCollapsed()
            .pipe(distinctUntilChanged())
            .subscribe((allSectionsCollapsed) => {
                this.sectionCollapsed = allSectionsCollapsed;
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    handleChange(option) {
        const handler = option.split(':');
        switch (handler[0]) {
            case 'template':
                this.createTemplateSection(handler[1]);
                break;
            case 'collapse':
                this.collapseSection(handler[1]);
                break;
            case 'duplicate':
                this.duplicateSection(handler[1]);
                break;
            case 'delete':
                this.deleteSection(handler[1]);
                break;
            default:
                break;
        }
    }

    nodeDropped($event) {
        // swap sections based on drag and drop position
        this.canvasSectionService.moveSectionFromIndexToIndex($event.previousIndex, $event.currentIndex);
    }

    toggleCollapse() {
        if (this.sectionCollapsed) {
            this.sectionCollapsed = false;
            this.sectionsService.expandAllSections();
        } else {
            this.sectionCollapsed = true;
            this.sectionsService.collapseAllSections();
        }
    }

    addSection() {
        if (!this.ruumUIStateService.isAppOffline()) {
            const sectionId = this.serviceHelper.getRandomId('section');
            this.canvasSectionService.addEmptySectionToTheBottom(sectionId);
            this.canvasService.scrollToNodeId(this.currentLastCanvasId);
        }
    }

    sectionClicked(event) {
        const state: RouterState = this.router.routerState;
        const parentRoute: ActivatedRoute =
            state.root.children[0] && state.root.children[0].children[0]
                ? state.root.children[0].children[0]
                : state.root;

        this.router.navigate([{ outlets: { primary: ['canvas'] } }], {
            relativeTo: parentRoute,
            queryParams: { scrollToNodeId: event.id },
        });

        setTimeout(() => this.serviceHelper.scrollToNodeId('canvas', event.id), 100);
    }

    private collapseSection(id) {
        this.sectionsService.collapseSection(id);
    }

    private duplicateSection(id) {
        this.sectionsService.duplicateSection(id);
    }

    private deleteSection(id) {
        this.canvasService.deleteNodeWithId(id);
    }

    private createTemplateSection(handlerElement: any) {}
}
