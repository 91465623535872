import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ComponentSize, ComponentTheme } from '../ui-components.type';

@Component({
    selector: 'ruum-profile-workspace',
    template: `
        <ruum-profile
            [name]="name"
            [initials]="initials"
            [size]="size | ruumProfileSize"
            [theme]="theme"
            [rounded]="rounded"
            [hover]="hover"
            [active]="active"
            [disabled]="disabled"
            [disableTooltip]="disableTooltip"
            [tabindex]="tabindex"
        ></ruum-profile>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileWorkspaceComponent implements OnInit {
    @HostBinding('class') get hostClassList() {
        return ['d-flex', this.componentClass].filter((el) => !!el).join(' ');
    }

    @Input() name = '';

    @Input() size: ComponentSize;

    @Input() hover: boolean;
    @Input() active: boolean;
    @Input() disabled: boolean;
    @Input() tabindex = -1;

    @Input() componentClass: string;
    @Input() disableTooltip = false;

    @Input() theme: ComponentTheme = 'white-16';
    initials = 1;
    rounded = false;

    constructor() {}

    ngOnInit() {}
}
