import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CustomStatus, ProcessBar, Ruum, RuumLink, Variable } from '@ruum/ruum-reducers';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { Lobby, SelectableLobbyListItemStatus, SelectableTag } from '../lobby/lobby.model';
import { AppState } from './../common/app.store';
import { Mail as RuumMail } from './../project/sidepanel/emails/mail.model';
import { Attachment, Email, RuumPoll, RuumTask, RuumUIState } from './../ruum/ruum.model';
import { CommonState } from './common.model';
import { Chats } from './connectors/chats/chat-list.model';
import { PaginatedList } from './connectors/paginatedList.model';
import { FocusState } from './focus/focus.reducer';
import {
    ODataEnterpriseFileUploadOptions,
    ODataEnterpriseParticipant,
    ODataEnterpriseTechnicalUser,
} from './odata/odata.model';

@Injectable({ providedIn: 'root' })
export class AppStoreWrapper {
    constructor(private store: Store<AppState>) {}

    /** Deprecated. User SelectedProjectService instead. */
    selectedRuum(): Observable<Ruum> {
        return this.store.select('selectedProject').pipe(map((wrapper) => wrapper.project));
    }

    sections() {
        return this.selectedRuum().pipe(map((ruum) => ruum.sections));
    }

    tasks(): Observable<RuumTask[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.tasks));
    }

    customStatus(): Observable<CustomStatus[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.customStatus));
    }

    processBars(): Observable<ProcessBar[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.processBars));
    }

    links(): Observable<RuumLink[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.links));
    }

    attachments(): Observable<Attachment[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.attachments));
    }

    mails(): Observable<Email[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.mails));
    }

    polls(): Observable<RuumPoll[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.polls));
    }

    selectedRuumUIState(): Observable<RuumUIState> {
        return this.selectedRuum().pipe(map((ruum) => ruum.uiState));
    }

    variables(): Observable<Variable[]> {
        return this.selectedRuum().pipe(map((ruum) => ruum.variables));
    }

    /** Lobby */

    lobby(): Observable<Lobby> {
        return this.store.select('lobby');
    }

    lobbyList() {
        return this.lobby().pipe(map((lobby) => lobby.lobbyList));
    }

    chats(): Observable<Chats> {
        return this.store.select('chats');
    }

    tagsList(): Observable<SelectableTag[]> {
        return this.lobby().pipe(map((lobby) => lobby.tagList));
    }

    statusList(): Observable<SelectableLobbyListItemStatus[]> {
        return this.lobby().pipe(map((lobby) => lobby.statusList));
    }

    /** Common */
    common(): Observable<CommonState> {
        return this.store.select('common');
    }

    isIEOrEdge(): Observable<boolean> {
        return this.common().pipe(map((common) => common.isIE || common.isEdge));
    }

    isIE() {
        return this.common().pipe(
            map((common) => common.isIE),
            distinctUntilChanged(),
        ) as Observable<boolean>;
    }

    isEdge() {
        return this.common().pipe(
            map((common) => common.isEdge),
            distinctUntilChanged(),
        ) as Observable<boolean>;
    }

    msTeamsStage() {
        return this.common().pipe(
            map((common) => common.msTeamsStage),
            distinctUntilChanged(),
        ) as Observable<string>;
    }

    simplifiedView() {
        return this.common().pipe(
            map((common) => common.simplifiedView),
            distinctUntilChanged(),
        ) as Observable<boolean>;
    }

    busyState() {
        return this.common().pipe(map((common) => common.busy));
    }

    busyStateOfComponent(component: string) {
        return this.busyState().pipe(map((busy) => busy[component]));
    }

    appBusy() {
        return this.busyStateOfComponent('application');
    }

    appOffline(): Observable<boolean> {
        return this.common().pipe(map((common) => common.offline));
    }

    unassignedMailsCounterForUser(): Observable<number> {
        return this.store.select('unassignedMailsCounter');
    }

    unassignedMailsForUserList(): Observable<RuumMail[]> {
        return this.store.select('unassignedMails');
    }

    activities() {
        return this.selectedRuum().pipe(
            filter((ruum) => ruum && !!ruum.activities),
            map((ruum) => ruum.activities),
            distinctUntilChanged(),
        );
    }

    focusState(): Observable<FocusState> {
        return this.store.select('focusState').pipe(map((state) => state));
    }

    getSelectedRuumId(): string {
        let id;
        this.selectedRuum()
            .pipe(take(1))
            .subscribe((ruum) => (id = ruum.id));
        return id;
    }

    // Enterprise stuff

    fileUploadOptions(): Observable<ODataEnterpriseFileUploadOptions> {
        return this.store.pipe(select('selectedEnterprise', 'fileUploadOptions')); // TODO: fix it. ODataEnterpriseFileUploadOptions
    }

    oauthClients(): Observable<any> {
        // TODO: fix type. PaginatedList<ODataEnterpriseOAuthClient>
        return this.store.pipe(select('selectedEnterprise', 'oauthClients'));
    }

    enterpriseName(): Observable<string> {
        return this.store.pipe(select('selectedEnterprise', 'name'));
    }

    enterpriseId(): Observable<string> {
        return this.store.pipe(select('selectedEnterprise', 'id'));
    }

    enterpriseParticipants(): Observable<PaginatedList<ODataEnterpriseParticipant>> {
        return this.store.select('enterpriseParticipantsList');
    }

    enterpriseTechnicalUsers(): Observable<PaginatedList<ODataEnterpriseTechnicalUser>> {
        return this.store.select('enterpriseTechnicalUsersList');
    }
}
