import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
    /** redirecting old links */
    { path: 'login.html', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'signup.html', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'login', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'signup', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'unsubscribe', redirectTo: 'auth/unsubscribe' }, // TODO: verify it
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((mod) => mod.AuthModule),
    },
    {
        path: 'projectgroups',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./lobby/project-group/project-group.module').then((mod) => mod.RuumProjectGroupModule),
    },
    {
        path: 'workspaces',
        canActivate: [AuthGuard],
        loadChildren: () => import('./lobby/workspace/workspace.module').then((mod) => mod.RuumWorkspaceModule),
    },
    {
        path: 'workspace-settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./lobby/workspace-settings/workspace-settings.module').then((mod) => mod.WorkspaceSettingsModule),
    },
    {
        path: 'home/settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./settings/settings.module').then((mod) => mod.RuumSettingsModule),
    },
    {
        path: 'templates',
        canActivate: [AuthGuard],
        loadChildren: () => import('./templates/templates.module').then((mod) => mod.RuumTemplatesModule),
    },
    {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () => import('./report/report.module').then((mod) => mod.RuumReportModule),
    },
    {
        path: 'export',
        canActivate: [AuthGuard],
        loadChildren: () => import('./export/export.module').then((mod) => mod.RuumExportModule),
    },
    {
        path: 'projects',
        canActivate: [AuthGuard],
        loadChildren: () => import('./project/project.module').then((mod) => mod.RuumProjectModule),
    },
    {
        path: 'simplified',
        canActivate: [AuthGuard],
        loadChildren: () => import('./project/simplified-project.module').then((mod) => mod.SimplifiedProjectModule),
    },
    {
        path: 'ruums',
        canActivate: [AuthGuard],
        loadChildren: () => import('./project/great-grand-project.module').then((mod) => mod.GreatGrandProjectModule),
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/admin.module').then((mod) => mod.AdminHomeModule),
    },
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./lobby/home/home.module').then((mod) => mod.RuumHomeModule),
    },
    {
        path: '**',
        redirectTo: '/home/projects',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
