import { isProjectReducerAction, RuumAction, RuumReducer, RuumReducersDecorator } from '@ruum/ruum-reducers';
import { ExternalFileSystemConfigurationsReducerDecorator } from '../../ruum/attachment/file-system-configuration.reducer.decorator';
import { MailsReducerDecorator } from '../../ruum/mail/mail.reducer.decorator';
import {
    BlameUserAction,
    HighlightUnseenCanvasChangesAction,
    PROJECT_WEB_APP_ACTION_TYPES,
    SelectProjectAction,
    SetCanvasHasUnseenChangesAction,
} from './ruumReducerWrapper.actions';
import { BlameMap, SelectedProjectWrapper } from './ruumReducerWrapper.model';

export function RuumReducerWrapper(
    currentState: SelectedProjectWrapper = getDefault(),
    action: RuumAction,
): SelectedProjectWrapper {
    if (
        !isProjectReducerAction(action.type) &&
        !PROJECT_WEB_APP_ACTION_TYPES[action.type] &&
        action.type !== '@ngrx/store/init'
    ) {
        return currentState;
    }

    switch (action.type) {
        case PROJECT_WEB_APP_ACTION_TYPES.SELECT_PROJECT:
            return selectProject(action);
        case PROJECT_WEB_APP_ACTION_TYPES.BLAME_USER:
            return blameUser(currentState, action);
        case PROJECT_WEB_APP_ACTION_TYPES.HIGHLIGHT_UNSEEN_CANVAS_CHANGES:
            return shouldHighlight(currentState, action);
        case PROJECT_WEB_APP_ACTION_TYPES.SET_CANVAS_HAS_UNSEEN_CHANGES:
            return setCanvasHasUnseen(currentState, action);
        case PROJECT_WEB_APP_ACTION_TYPES.SHOW_IMAGE_PREVIEW:
            return setImagePreviewId(currentState, action.payload.imagePreviewId);
        case PROJECT_WEB_APP_ACTION_TYPES.HIDE_IMAGE_PREVIEW:
            return setImagePreviewId(currentState, null);

        default:
            return callRuumReducer(currentState, action);
    }
}

function callRuumReducer(currentState: SelectedProjectWrapper, action: RuumAction): SelectedProjectWrapper {
    /**
     * Will only accept actions that were sent by SelectedProjectService.
     * This is needed because both RuumReducer and WorkspaceReducer accept CustomFieldDefinitionActionTypes.
     */
    if (action.entityType === 'project') {
        return {
            ...currentState,
            project: RuumReducer(currentState.project, { ...action }),
        };
    } else {
        return currentState;
    }
}

function selectProject(action: SelectProjectAction): SelectedProjectWrapper {
    return {
        ...getDefault(),
        project: action.payload,
    };
}

function blameUser(currentState: SelectedProjectWrapper, action: BlameUserAction): SelectedProjectWrapper {
    const change: BlameMap = {};
    change[action.payload.userId] = action.payload.blame;
    const newMap = {
        ...currentState.blameMap,
        ...change,
    };
    return {
        ...currentState,
        blameMap: newMap,
    };
}

function shouldHighlight(
    currentState: SelectedProjectWrapper,
    action: HighlightUnseenCanvasChangesAction,
): SelectedProjectWrapper {
    return {
        ...currentState,
        isHighlightingCanvasChanges: action.payload.shouldHighlight,
    };
}

function setCanvasHasUnseen(
    currentState: SelectedProjectWrapper,
    action: SetCanvasHasUnseenChangesAction,
): SelectedProjectWrapper {
    return {
        ...currentState,
        canvasHasUnseenChanges: action.payload.hasChanges,
    };
}

function setImagePreviewId(currentState: SelectedProjectWrapper, imagePreviewId: string): SelectedProjectWrapper {
    return {
        ...currentState,
        imagePreviewId,
    };
}

function getDefault(): SelectedProjectWrapper {
    return {
        project: RuumReducer(undefined, {} as any),
        blameMap: {},
        canvasHasUnseenChanges: false,
        isHighlightingCanvasChanges: false,
        imagePreviewId: undefined,
    };
}

/**
 * Deprecated. Do not add new features to this.
 *
 * Decorators are usually used for Ruum state which can not be in the snapshot and actions which are not persisted.
 * e.g. whether a section is collapsed or not, etc.
 */
RuumReducersDecorator.decorate('MailsReducer', MailsReducerDecorator);
RuumReducersDecorator.decorate(
    'ExternalFileSystemConfigurationsReducer',
    ExternalFileSystemConfigurationsReducerDecorator,
);
